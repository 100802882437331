import { useState, useCallback } from "react";

const useEditorToToolbarWrapperHelper = () => {
	const [showInitialState, setInitialState] = useState(true);

	const handleInitialState = useCallback((val) => {
		setInitialState(val);
	}, []);

	return {
		showInitialState,
		handleInitialState
	};
};

export default useEditorToToolbarWrapperHelper;
