import { Card, Divider, Grid } from "@mui/material";
import { ComboBox } from "../quoteHeader/common/components";
import { Header } from "./Header";
import { FormControlLabelCheckbox } from "./Common/FormControlLabelCheckbox";
import { PS } from "./constant";
import { RHFTextField } from "./Common/RHFTextField";
import { StyledOption } from "../quoteHeader/common/styledComponents";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

export const OrderPorterSettings = ({ paymentProcessingOptions, isPremiumPartner }) => {
	const columnContainerStyle = {};
	const itemStyles = {
		py: "5px",
		width: "80%"
	};

	const { watch, setValue } = useFormContext();
	const OrderPorterShowLineDetails = watch(PS.OP_SHOW_LINE_DETAILS);

	useEffect(() => {
		if (!OrderPorterShowLineDetails) {
			setValue(PS.OP_SHOW_IMAGE, false);
			setValue(PS.OP_SHOW_ITEM_CB, false);
			setValue(PS.OP_SHOW_QTY, false);
		}
	}, [OrderPorterShowLineDetails]);

	return (
		<>
			<Card sx={{ mx: "5px", my: "5px" }}>
				<Header title="Order Porter Settings" />
				<Divider component="li" />
				<Grid container spacing={2} sx={{ py: "10px", px: "30px" }}>
					{/* FIRST COLUMN */}
					<Grid item md={2} container direction="column" sx={columnContainerStyle}>
						<FormControlLabelCheckbox
							label="Require Signer Confirmation"
							name={PS.IS_SIGNER_CONFIRMATION_REQUIRED}
							id={PS.IS_SIGNER_CONFIRMATION_REQUIRED}
						/>
						<FormControlLabelCheckbox
							label="Use E-Signature"
							name={PS.OP_ESIGN}
							id={PS.OP_ESIGN}
						/>
						<FormControlLabelCheckbox
							label="Show Signature Box"
							name={PS.OP_SHOW_SIGNATURE}
							id={PS.OP_SHOW_SIGNATURE}
						/>
						<FormControlLabelCheckbox
							label="Show Tab Checkboxes / Radio Buttons"
							name={PS.OP_SHOW_TAB_CB}
							id={PS.OP_SHOW_TAB_CB}
						/>
					</Grid>

					{/* SECOND COLUMN */}
					<Grid item md={2} container direction="column" sx={columnContainerStyle}>
						<FormControlLabelCheckbox
							label="Show Line Item Details"
							name={PS.OP_SHOW_LINE_DETAILS}
							id={PS.OP_SHOW_LINE_DETAILS}
						/>
						{OrderPorterShowLineDetails && (
							<>
								<FormControlLabelCheckbox
									label="Show Item Image"
									name={PS.OP_SHOW_IMAGE}
									id={PS.OP_SHOW_IMAGE}
								/>
								<FormControlLabelCheckbox
									label="Show Item Checkboxes / Radio Buttons"
									name={PS.OP_SHOW_ITEM_CB}
									id={PS.OP_SHOW_ITEM_CB}
								/>
								<FormControlLabelCheckbox
									label="Show Editable Item Qty"
									name={PS.OP_SHOW_QTY}
									id={PS.OP_SHOW_QTY}
								/>
							</>
						)}
					</Grid>

					{/* THIRD COLUMN */}
					<Grid item md={2} container direction="column" sx={columnContainerStyle}>
						<RHFTextField
							sx={itemStyles}
							label="Terms and Conditions"
							name={PS.TERMS_AND_CONDITIONS}
							id={PS.TERMS_AND_CONDITIONS}
							multiline={true}
							rows={9}
							InputLabelProps={{ shrink: true }}
						/>
					</Grid>

					{/* FOURTH COLUMN */}
					<Grid item md={2} container direction="column" sx={columnContainerStyle}>
						<ComboBox
							id={PS.OP_PAYMENT_MODE}
							name={PS.OP_PAYMENT_MODE}
							label="Payment Processing"
							options={paymentProcessingOptions}
							renderOption={(props, option) => (
								<StyledOption {...props}>{option}</StyledOption>
							)}
							disableClearable={true}
							sx={{ ...itemStyles, marginBottom: "10px" }}
						/>
						{isPremiumPartner && (
							<>
								<RHFTextField
									sx={itemStyles}
									label="Electronic Payment % Of Quote Total"
									name={PS.OP_DOWNPAYMENT_PERCENT}
									id={PS.OP_DOWNPAYMENT_PERCENT}
									InputLabelProps={{ shrink: true }}
								/>
								<RHFTextField
									sx={itemStyles}
									label="Electronic Payment Minimum Amount"
									name={PS.OP_DOWNPAYMENT_MINIMUM}
									id={PS.OP_DOWNPAYMENT_MINIMUM}
									InputLabelProps={{ shrink: true }}
								/>
								<RHFTextField
									sx={itemStyles}
									label="Electronic Payment Downpayment"
									name={PS.OP_ORDER_PORTER_AMOUNT}
									disabled={true}
									id={PS.OP_ORDER_PORTER_AMOUNT}
									InputLabelProps={{ shrink: true }}
								/>
							</>
						)}
					</Grid>
				</Grid>
			</Card>
		</>
	);
};
