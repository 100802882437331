import { QuotePublish } from "js/jsx/src/classes/quote/publish.jsx";

export default function publish() {

    if (!app.currentQuote) {
        return;
    }

    quosal.ui.react.render(
        <FormPlaceholder message="Loading Quote Publish..." />
        , document.getElementById('quotePublishContainer'));

    var renderPublishPage = function (renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;

        // var formConfiguration = quosal.customization.forms.bindForm(quosal.customization.forms['QuoteEdit'].Configurations[quosal.settings.getValue('QuoteEdit_FormConfiguration') || 'Default'], app.currentQuote);
        //
        // if(formConfiguration == null) {
        //     formConfiguration = quosal.customization.forms.bindForm(quosal.customization.forms['QuoteEdit'].Configurations['Default'], app.currentQuote);
        // }
        // configuration={formConfiguration}
        renderMethod(<QuotePublish />, document.getElementById('quotePublishContainer'));
    };

    var loadPublishPage = function () {
        var userInfo = quosal.util.getUserEmailAndRole();
        var start = new Date().getTime();

        var initializePublishResources = !quosal.publish;
        var prepareForPublishApi = quosal.api.quote.prepareForPublish(app.currentQuote.id, initializePublishResources);
        prepareForPublishApi.finished = function(msg) {
            if (msg.quote) {
                quosal.sell.quote.update(msg.quote);
            }
            quosal.publish = quosal.publish || {};
            quosal.publish.publishCheat = msg.publishCheat;
            quosal.publish.isOnPremUser = msg.isOnPremUser;
            quosal.publish.emailBody = msg.emailBody;
            quosal.publish.emailSubject = msg.emailSubject;
            quosal.publish.orderPorterURL = msg.orderPorterURL;
            quosal.publish.emailBodyNoOrderPorter = msg.emailBodyNoOrderPorter;
            quosal.publish.emailSubjectNoOrderPorter = msg.emailSubjectNoOrderPorter;

            quosal.publish.primaryRepO365EmailAddress = msg.primaryRepO365EmailAddress;
            quosal.publish.insideRepO365EmailAddress = msg.insideRepO365EmailAddress;
            quosal.publish.quoteIsExpired = msg.quoteIsExpired;

            if (!quosal.publish.publishCheat) {
                if (msg.reportForms) {
                    quosal.publish.reportForms = msg.reportForms;
                }
                if (msg.pdfDocuments) {
                    quosal.publish.pdfDocuments = msg.pdfDocuments;
                }
                quosal.publish.adHocPdfDocuments = msg.adHocPdfDocuments || [];
                quosal.publish.productDocuments = msg.productDocuments || [];
                quosal.publish.etilizeDocuments = msg.etilizeDocuments || [];
                quosal.publish.dynamicsSalesLiterature = msg.dynamicsSalesLiterature || [];
                quosal.publish.defaultReportList = msg.defaultReportList || [];

                if (msg.videos) {
                    quosal.publish.videos = msg.videos;
                }
                quosal.publish.defaultVideoList = msg.defaultVideoList || [];
            }

            renderPublishPage(quosal.ui.react.render);
            if (window.appInsights) {
                var elapsed = new Date().getTime() - start;
                window.appInsights.trackPageView("Load - Publish and Deliver",
                    window.location.href,
                    { EmailAddress: userInfo.email, UserRole: userInfo.role },
                    null,
                    elapsed);
            }
        }
        prepareForPublishApi.call();

        $('#pageName').val('quote.publish');
    };

    // var updateEditForm = function () {
    //     renderEditForm();
    // };
    //
    // var changeConfiguration = function () {

    //     updateEditForm();
    // };

    var unbindEvents = function () {
        // quosal.customization.forms.configurationChanged.unbind(changeConfiguration);
        // quosal.customization.forms.configurationUpdated.unbind(changeConfiguration);
        // quosal.sell.quote.onUpdate.unbind(updateEditForm);
        quosal.navigation.onNavigate.unbind(unbindEvents);
    };

    quosal.navigation.onNavigate.bind(unbindEvents);
    // quosal.customization.forms.configurationChanged.bind(changeConfiguration);
    // quosal.customization.forms.configurationUpdated.bind(changeConfiguration);
    // quosal.sell.quote.onUpdate.bind(updateEditForm);

    quosal.util.cookie('quotenav', 'publish');

    loadPublishPage();
}