import { QuoteReviewMain } from "js/jsx/src/classes/quote/quoteReview.jsx";
export default function review() {
    if (!app.currentQuote) {
        return;
    }

    var userInfo = quosal.util.getUserEmailAndRole();
    var start = new Date().getTime();

    quosal.ui.react.render(
        <FormPlaceholder message="Loading Quote Review..."/>,
        document.getElementById('quoteReviewContainer'));

    var renderReviewForm = function(renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;

        renderMethod(<QuoteReviewMain quote={app.currentQuote}
                                      onUpdate={() => renderReviewForm()}/>,
            document.getElementById('quoteReviewContainer'));
    };

    var loadReviewForm = function() {
        renderReviewForm(quosal.ui.react.render);

        $('#pageName').val('quote.review');
    };

    quosal.util.cookie('quotenav', 'review');

    loadReviewForm();
    if (window.appInsights) {
        var elapsed = new Date().getTime() - start;
        window.appInsights.trackPageView("Load - Review",
            window.location.href,
            { EmailAddress: userInfo.email, UserRole: userInfo.role },
            null,
            elapsed);
    }
}