if (!quosal.api.crm)
quosal.api.crm = {};

quosal.api.crm.netsuite = {};


quosal.api.crm.netsuite.getOpportunityUrl = function (idQuoteMain) {
    return quosal.api.call('Crm.Netsuite.GetOpportunityUrl', {
        idQuoteMain: idQuoteMain,
    });
};

quosal.api.crm.netsuite.getOpportunityMetadata = function (idQuoteMain) {
    return quosal.api.call('Crm.NetSuite.GetOpportunityMetadata', {
        idQuoteMain: idQuoteMain,
    });
};

quosal.api.crm.netsuite.getOpportunities = function (criteria, pageNumber, pageSize, additionalParameters) {
    return quosal.api.call('Crm.Netsuite.GetOpportunities', {
        criteria: criteria,
        pageNumber: pageNumber,
        pageSize: pageSize,
        additionalParameters: additionalParameters || {}
    });
};