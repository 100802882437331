/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from "react";

export function useCategoryMenuService() {
	const [catalogs, setCatalogs] = useState([]);
	const [checkedCatalogs, setCheckedCatalogs] = useState([]);
	const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(false);
	const [accordionExpanded, setAccordionExpanded] = useState("");
	const [categoryExpanded, setCategoryExpanded] = useState([]);
	const [categorySelected, setCategorySelected] = useState([]);
	const [lessMoreExpanded, setLessMoreExpanded] = useState({});
	const [lastAccordionSearched, setLastAccordionSearched] = useState([]);

	const handleCatalogChecked = (catalog, checked) =>
		checked
			? setCheckedCatalogs((prev) => [...prev, catalog])
			: setCheckedCatalogs((prev) => prev.filter((item) => item !== catalog));

	const handleExpandingAccordion = (acc) => (event, newExpanded) => {
		setAccordionExpanded(newExpanded ? acc : false);

		if (lastAccordionSearched.accordion === acc) {
			setCategorySelected(lastAccordionSearched.nodeIds);
		} else {
			setCategorySelected([]);
		}
	};

	const handleCategoryToggle = (event, nodeIds) => {
		setCategoryExpanded(nodeIds);
	};

	const handleCategorySelect = (event, nodeIds) => {
		const accordionData = {
			accordion: accordionExpanded,
			nodeIds
		};

		if (!lastAccordionSearched.accordion) {
			setCategorySelected(nodeIds);
		}

		setLastAccordionSearched(accordionData);
	};

	const handleCategoryUnselect = () => {
		setCategorySelected([]);
	};

	const handleLessMoreExpanded = (catalogName) => {
		setLessMoreExpanded({ ...lessMoreExpanded, [catalogName]: !lessMoreExpanded[catalogName] });
	};

	useEffect(() => {
		setIsLoadingCatalogs(true);

		const catalogsInStorage = sessionStorage.getItem("catalogs");

		if (catalogsInStorage) {
			setCatalogs(JSON.parse(catalogsInStorage));
		} else {
			setTimeout(() => {
				setCatalogs(JSON.parse(sessionStorage.getItem("catalogs")));
			}, 6000);
		}
	}, []);

	useEffect(() => {
		if (catalogs && !!catalogs.length) {
			setCheckedCatalogs(catalogs.map((catalog) => catalog.Source));
			setIsLoadingCatalogs(false);
		}
	}, [catalogs]);

	return {
		catalogs,
		checkedCatalogs,
		isLoadingCatalogs,
		accordionExpanded,
		categoryExpanded,
		categorySelected,
		lessMoreExpanded,
		handleLessMoreExpanded,
		handleCategoryToggle,
		handleCategorySelect,
		handleCategoryUnselect,
		handleExpandingAccordion,
		handleCatalogChecked
	};
}
