import { SET_CURRENT_QUOTE, SET_CURRENT_USER, SET_USER_SETTINGS, SET_GLOBAL_SETTINGS } from "../actions";

const createQuoteHeaderInitialState = ({ initialQuoteState, initialUserState, initialUserSettingsState, initialGlobalSettingsState }) => {
	return {
		currentQuote: initialQuoteState,
		currentUser: initialUserState,
		userSettings: initialUserSettingsState,
		globalSettings: initialGlobalSettingsState
	};
};

const quoteHeaderReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CURRENT_QUOTE:
			return {
				...state,
				currentQuote: {
					...state.currentQuote,
					...action.payload
				}
			};
		case SET_CURRENT_USER:
			return {
				...state,
				currentUser: {
					...state.currentUser,
					...action.payload
				}
			};
		case SET_USER_SETTINGS:
			return {
				...state,
				userSettings: {
					...state.userSettings,
					...action.payload
				}
			};
	    case SET_GLOBAL_SETTINGS:
			return {
				...state,
				globalSettings: {
					...state.globalSettings,
					...action.payload
				}
			};		
		default:
			throw Error("Unknown action: " + action.type);
	}
};

export { createQuoteHeaderInitialState, quoteHeaderReducer };
