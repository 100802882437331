quosal.api.core = {};

//======================================================//
//* Identify                                           *//
//======================================================//
//      accessKey: The current users access key.        //
//      webAuthToken: The current users web auth token. //
//======================================================//
quosal.api.core.identify = function (accessKey) {
    return quosal.api.call('Core.Identify', {
        accessKey: accessKey
    });
};