import React from "react";
import { Box, Typography } from "@mui/material";
import { useAppDataContext } from "../globalStates/appDataProvider.jsx";
import ActionButtons from "./actionButtons.jsx";

function DetailedAttribute() {
	const { productDetails } = useAppDataContext();

	return (
		<Box sx={{ width: "100%", height: "100%" }} mt={1}>
			<Typography fontSize={20} mb={2}>
				General Information
			</Typography>
			<Typography fontSize={14} mb={4}>
				{productDetails.description}
			</Typography>
			<ActionButtons product={productDetails} />
		</Box>
	);
}

export default DetailedAttribute;
