import { Box, Button, IconButton, Modal, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { StyledModalBox } from "../styledComponents";

export const BasicModal = ({
	isOpen,
	title,
	modalBody,
	handleClose,
	cancelBtnText = "Cancel",
	saveBtnText = "Save",
	onCancel,
	onSave,
	disableSave
}) => {
	const theme = useTheme();
	return (
		<Modal open={isOpen} onClose={handleClose} sx={{ zIndex: 9996 }}>
			<StyledModalBox>
				<Box display="flex" justifyContent="space-between">
					{title && (
						<Typography fontSize={20} color={theme.palette.text.primary}>
							{title}
						</Typography>
					)}
					<IconButton
						sx={{
							minWidth: 30,
							maxWidth: 30,
							padding: 0,
							color: theme.palette.primary.main
						}}
						onClick={handleClose}
						data-testid="close-modal-btn"
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</Box>
				<Box
					sx={{
						mt: 2,
						fontSize: "14px",
						color: theme.palette.text.primary
					}}
				>
					{modalBody}
				</Box>
				<Box display="flex" justifyContent="space-between" mt={2}>
					<Button
						variant="text"
						sx={{
							padding: 0,
							fontWeight: 600,
							fontSize: "14px",
							color: theme.palette.primary.main,
							textTransform: "none",
							marginLeft: "-8px"
						}}
						onClick={onCancel}
						data-testid="basic-modal-cancel-btn"
					>
						{cancelBtnText}
					</Button>
					<Button
						variant="contained"
						onClick={onSave}
						disabled={disableSave}
						sx={{
							height: "40px",
							borderRadius: "6px",
							fontWeight: 600,
							boxShadow: "none",
							textTransform: "none"
						}}
						data-testid="basic-modal-save-btn"
					>
						{saveBtnText}
					</Button>
				</Box>
			</StyledModalBox>
		</Modal>
	);
};

BasicModal.propTypes = {
	title: PropTypes.string,
	modalBody: PropTypes.node,
	isOpen: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	cancelBtnText: PropTypes.string,
	saveBtnText: PropTypes.string,
	onCancel: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	disableSave: PropTypes.bool
};
