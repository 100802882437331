quosal.api.oauth = {};

quosal.api.oauth.getAccessTokenInformation = function (source) {
    return quosal.api.call('Oauth.GetAccessTokenInformation', {
        source: source
    });
};

quosal.api.oauth.getAccessToken = function (source) {
    return quosal.api.call('Oauth.GetAccessToken', {
        source: source
    });
};

quosal.api.oauth.expireAccessTokenInformation = function (source) {
    return quosal.api.call('Oauth.ExpireAccessTokenInformation', {
        source: source
    });
};

quosal.api.oauth.disconnectFromSSO = function () {
	return quosal.api.call('Oauth.DisconnectFromSSO');
};

quosal.api.oauth.unlinkUserFromSSO = function (userId) {
    return quosal.api.call('Oauth.UnlinkUserFromSSO', {
        userId: userId
    });
};