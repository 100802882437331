import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const ProductCardStyled = styled((props) => <Grid {...props} />)(({ theme }) => ({
    maxHeight: '190px',
    height: '190px',
    minWidth: '240px',
    position: 'relative',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: theme.palette.border.border_subtle2,
    backgroundColor: theme.palette.background.main,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    padding: '16px',
}));

export const SalesTrackTitleStyled = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px',
    color: theme.palette.text.main,
    height: 'fit-content',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
}));

export const SalesTrackActiveStatusStyled = styled(Typography)(({ theme }) => ({
    padding: '0 10px',
    margin: '5px 3px',
    borderRadius: '1000px',
    background: theme.palette.chip.green_background,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.palette.chip.green_text,
}));

export const SalesTrackInactiveStatusStyled = styled(Typography)(({ theme }) => ({
    padding: '0 10px',
    margin: '5px 3px',
    borderRadius: '1000px',
    background: theme.palette.chip.sand_background,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.palette.chip.sand_text,
}));

export const SalesTrackLockedStatusStyled = styled(Typography)(({ theme }) => ({
    padding: '0 10px',
    margin: '5px 3px',
    borderRadius: '1000px',
    background: theme.palette.chip.peach_background,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.palette.chip.peach_text,
}));