import { OutputGridLayoutEditorPage } from "js/jsx/src/classes/settings/outputGridLayoutEditor.jsx";
import { DialogManager } from "js/jsx/src/classes/dialog.jsx";

export default function outputGridLayoutEditor() {

    var loadOutputGridLayoutEditorPage = function () {
        quosal.ui.react.render(<DialogManager />, document.getElementById('dialogManager'));
        quosal.ui.react.render(<OutputGridLayoutEditorPage />, document.getElementById('currentDocument'));
        $('#pageName').val('outputGridLayoutEditor.quosalweb');
    };
    quosal.sell.modules.onAfterLoad.unbind().bind(loadOutputGridLayoutEditorPage);
}