export class SimpleTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {   
            selectedTab:null, 
            selectedComponent:null, 
        }; 

        // setup binds.
        this.componentDidMount = this.componentDidMount.bind(this);
        this.prepForRender = this.prepForRender.bind(this);
        this.activateTab = this.activateTab.bind(this);
        this.renderTabs = this.renderTabs.bind(this);
        this.render = this.render.bind(this);
    }

    componentDidMount() {
        if ((this.state.selectedTab == null || this.state.selectedComponent == null) && this.props.tabList.length > 0) {
            var selectedTab = this.props.tabList[0];
            var selectedComponent = selectedTab.component;
            this.setState({selectedTab:selectedTab, selectedComponent:selectedComponent});
        }
    }

    prepForRender() {
        var me = this;
        this.props.tabList.forEach(function(tab, index){
            tab.index = index;
            if (!tab.id) {
                tab.id = "simpletab_" +  quosal.util.generateGuid();
            }
        })
    }

    activateTab(tab) {
        if (typeof tab == 'string' || tab instanceof String) { // all by name
            tab = this.props.tabList.find(function (t) { return t.name == tab });
        }
        var oldTab = this.state.selectedTab;
        if (oldTab) $("#" + oldTab.id).removeClass('selected');
        this.setState({
            selectedComponent: tab.component,
            selectedTab: tab
        });
        $("#" + tab.id).addClass('selected');
    }

    renderTabs() {
        var me = this;
        var renderedTabs = this.props.tabList.map(function (tab, index) {
            var className = "tab";
            if (me.state.selectedTab == tab || (!me.state.selectedTab && index == 0)) {
                className += " selected"
            }
            return (
                <li className={className} onClick={me.activateTab.bind(me, tab)} id={tab.id} key={index}>{tab.name}
                    <div style={{ marginTop: "-15px", display: "none" }} className="formField" id={"error_" + tab.id} />
                </li>
            )
        });
        return renderedTabs;
    }

    render() {
        this.prepForRender();
        var panel = this.state.selectedComponent;
        if (!panel) {
            if (this.props.tabList.length > 0) {
                var tab = this.state.selectedTab;
                if (!tab) {
                    tab = this.props.tabList[0];
                    tab.index = 0;
                }
                if (tab && tab.renderFn) {
                    panel = tab.renderFn(tab.index);
                }
            }
            else {
                panel = (<div></div>);
            }
        }
        return (
            <div id="simpletab">
                <div className="tabs">
                    <ul>
                        {this.renderTabs()}
                    </ul>
                </div>
                {panel}
            </div>
        );
    }
}
global.SimpleTab = SimpleTab;