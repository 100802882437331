import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from '@mui/material/styles';
import { DATE_FORMAT } from '../constant';

const CustomDatePicker = ({ open, setOpen, value, onChange, inputField, dateFormat = DATE_FORMAT, disabled }) => {
    const theme = useTheme()
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                slots={{ field: inputField }}
                slotProps={{
                    field: { setOpen, },
                    calendarHeader: {
                        sx: {
                            position: "relative",
                            "& .MuiButtonBase-root": {
                                minWidth: "fit-content"
                            },
                            "& .MuiPickersArrowSwitcher-root": {
                                width: 0
                            },
                            "& .MuiPickersCalendarHeader-labelContainer": {
                                margin: "auto"
                            },
                            "& .MuiIconButton-edgeEnd": {
                                position: "absolute",
                                left: 0,
                                top: 0,
                                bottom: 0
                            },
                            "& .MuiIconButton-edgeStart": {
                                position: "absolute",
                                right: 0,
                                top: 0,
                                bottom: 0
                            }
                        }
                    },
                    leftArrowIcon: {
                        sx: { color: theme.palette.icon.secondary, fontSize: "16px" }
                    },
                    rightArrowIcon: {
                        sx: { color: theme.palette.icon.secondary, fontSize: "16px" }
                    },
                    color: theme.palette.primary.main,
                    disabled: disabled

                }}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                value={dayjs(value)}
                onAccept={onChange}
                format={dateFormat}
                showDaysOutsideCurrentMonth={true}
                views={["year", "month", "day"]}
                yearsPerRow={4}
                closeOnSelect={true}
                disabled={disabled}
            />
        </LocalizationProvider>
    );
};


export { CustomDatePicker };
