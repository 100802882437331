/* eslint-disable import/prefer-default-export */
import { DataGridPro } from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";

export const StyledDataGrid = styled(DataGridPro)({
	border: 0,
	"& .MuiDataGrid-columnHeader:focus-within": {
		outline: "none"
	},
	"& .MuiDataGrid-columnHeaders": {
		fontSize: 16,
		fontWeight: 700,
		border: 0
	},
	"& .PrivateSwitchBase-input": {
		width: "100%",
		height: "100%"
	},
	"& .MuiDataGrid-checkboxInput svg": {
		width: 16,
		height: 16
	},
	"& .MuiDataGrid-virtualScroller": {
		overflowX: "hidden"
	},
	"& .MuiToolbar-root.MuiTablePagination-toolbar": {
		alignItems: "baseline"
	}
});
