import * as yup from "yup";

const EMAIL_REGEX = new RegExp(
	/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const editCustDetailsFormValidationSchema = yup.object().shape({
	phone: yup.string().trim().max(30, "Phone must not be greater than 30 characters long"),
	email: yup
		.string()
		.trim()
		.required("Email is a required field")
		.matches(EMAIL_REGEX, "Invalid Email field")
		.max(255, "Email must not be greater than 255 characters long"),
	fullName: yup
		.string()
		.trim()
		.required("Contact is a required field")
		.test("is-valid-fullname", "Contact must have a First Name and Last Name", (value) => {
			const separator = " ";
			const fullNameSubParts = value
				.trim()
				.split(separator)
				.filter((part) => part !== "");
			return fullNameSubParts.length >= 2;
		})
		.max(120, "Contact must not be greater than 120 characters long")
});
