export const ENUM_KEY_WON = 'Won';
export const ENUM_KEY_LOST = 'Lost';
export const ENUM_KEY_NO_DECISION = 'No Decision';
export const ENUM_KEY_DELETED = 'Deleted';
export const ENUM_KEY_ARCHIVED = 'Archived';
export const ENUM_KEY_ACTIVE = 'Active';


export const QUOTE_STATE_COLORS = ({ palette }) => {
    return {
        [ENUM_KEY_WON]: { textColor: palette.chip.indigo_text, backgroundColor: palette.chip.indigo_background },
        [ENUM_KEY_LOST]: { textColor: palette.icon.inverse, backgroundColor: palette.background.inverse },
        [ENUM_KEY_NO_DECISION]: { textColor: palette.text.main, backgroundColor: palette.background.inverse },
        [ENUM_KEY_DELETED]: { textColor: palette.chip.salmon_text, backgroundColor: palette.chip.salmon_background },
        [ENUM_KEY_ARCHIVED]: { textColor: palette.chip.yellow_text, backgroundColor: palette.chip.yellow_background },
        [ENUM_KEY_ACTIVE]: { textColor: palette.chip.green_text, backgroundColor: palette.chip.green_background },
    }
};

export const COMMON_QUOTE_HEADER_STYLE = {
    borderRadius: '1000px',
    p: '0.4rem 0.7rem',
    fontSize: '0.8rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
}

export const EXCLUDED_TAB_GRID_FORMATS = ["Cover Letter", "Pre Outline", "Post Outline", "Quosal Cover Letter", "Executive Summary", "Appendix", "Statement of Work", "SoW", "Scope of Work", "Term Options","Appendix"];