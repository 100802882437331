export class ApprovalRulesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quickselectusr: '0',
            quickselectper: '30',
            quickselectmax: '10000',
            searchtext: '',
            searchActive: true,
            searchInactive: true,
        };

        this.renderNormalRules = this.renderNormalRules.bind(this);
        this.renderQuick = this.renderQuick.bind(this);
        this.searchTextChanged = this.searchTextChanged.bind(this);
        this.flipSearchInActive = this.flipSearchInActive.bind(this);
        this.flipSearchActive = this.flipSearchActive.bind(this);
        this.nothing = this.nothing.bind(this);
        this.switchUpToggle = this.switchUpToggle.bind(this);
    }

    componentDidMount() {
        if (this.props.users && this.props.users.length > 0) {
            this.setState({ quickselectusr: this.props.users[0].id });
        }
    }

    flipSearchActive() {
        this.setState((prev) => {
            if (prev.searchActive) {
                return { searchActive: false, searchInactive: true };
            }
            else {
                return { searchActive: !prev.searchActive };
            }
        });
    }
    flipSearchInActive() {
        this.setState((prev) => {
            if (prev.searchInactive) {
                return { searchInactive: false, searchActive: true };
            }
            else {
                return { searchInactive: !prev.searchInactive };
            }
        });
    }

    renderNormalRules() {
        const me = this;
        let rules = [];
        let haveSome = false;///[\s,]+/
        let str = this.state.searchtext.trim().toLowerCase().split(',');
        for (let i = 0; i < str.length; i++) {
            str[i] = str[i].trim();
        }
        let fnMatches = function (r) {
            if (!me.state.searchActive && r.isActive) return false;
            if (!me.state.searchInactive && !r.isActive) return false;
            if (str.length == 0 || (str.length == 1 && str[0].length == 0)) return true;
            for (let i = 0; i < str.length; i++) {
                if (str[i].length == 0) continue;
                if (r.name.toLowerCase().indexOf(str[i]) >= 0) return true;
                if (r.message.toLowerCase().indexOf(str[i]) >= 0) return true;
            }
            return false;
        }

        haveSome = this.props.rules && this.props.rules.length > 0;
        if (this.props.rules) {
            this.props.rules.forEach(function (r) {
                if (fnMatches(r)) {
                    rules.push(<RuleListRow rule={r} key={r.id} fnEditClick={me.props.fnEditRule} fnCopyClick={me.props.fnCopyRule}
                        fnToggleActive={me.props.fnToggleActive} templates={me.props.templates}
                        users={me.props.users} groups={me.props.groups} />);
                    haveSome = true;
                }
            });
        }

        let thetable = (<table cellPadding='0' cellSpacing='0' className='datagrid'>
            <thead>
                <tr>
                    <th width='20' className='title nosort'></th>
                    <th width='20' className='title nosort'></th>
                    <th width='250' className='title nosort'>Rule</th>
                    <th width='150' className='title nosort'>Approvers</th>
                    <th width='250' className='title nosort'>Templates</th>
                    <th width='80' className='title nosort'>Status</th>
                </tr>
            </thead>
            <tbody>
                {rules}
            </tbody>
        </table>);
        let noitems = (<div data-cy='noRulesUI' key='place' style={{ margin: 'auto', width: '75%', height: '120px', textAlign: 'center' }}>
            <div style={{ marginTop: '30px' }}></div>
            <div style={{ fontSize: '18px', color: '#6e6e6e', marginTop: '48px' }}>Currently there are no approval rules</div>
            <div style={{ marginTop: '18px' }}></div>
            <div data-cy="createRuleWrapper">
                <CwWidgets.CwButton toolTip='CREATE RULES' isStandard={true}
                    onClick={this.props.fnAddNew} value='CREATE RULE' />
            </div>
            <div style={{ marginTop: '18px' }}></div>
        </div>);

        let searchbox = (<div className='formfield' style={{ float: 'left', display: 'inline-block', marginRight: '8px', width: '300px' }}>
            <input id='search' maxLength='100' value={this.state.searchtext} type='text'
                placeholder='Search'
                onChange={(e) => { this.searchTextChanged(e.currentTarget.value) }} />
            <div id='resetfilter' className='icon close disabled' onClick={() => { this.searchTextChanged(''); }} title='Reset filter' style={{ marginLeft: '-12px', width: '12px', height: '12px', cursor: 'pointer' }} />
        </div>);
        let filterboxes = (<span style={{ float: 'right', fontSize: '13px', minWidth: '140px' }}>
            <div className='xformfield approvalrulesfilter' style={{ marginBottom: '4px' }}>
                <span style={{ marginRight: '8px', fontSize: '13px', color: '#4e4e4e' }}>Filter: </span>
                <div style={{ marginRight: '12px', fontWeight: '300', display: 'inline-block' }} onClick={this.flipSearchActive}>
                    <input id='idact' type='checkbox' name='idact' checked={this.state.searchActive} onChange={this.nothing}
                        style={{ position: 'relative', top: '4px' }}
                    />
                    <label forhtml="idact" className="formlabel" style={{ marginLeft: '4px', fontSize: '13px', color: '#4e4e4e' }}> Active</label>
                </div>
                <div style={{ marginRight: '0px', fontWeight: '300', display: 'inline-block' }} onClick={this.flipSearchInActive} >
                    <input id='idinact' type='checkbox' name='idinact' checked={this.state.searchInactive} onChange={this.nothing}
                        style={{ position: 'relative', top: '4px' }} />
                    <label forhtml="idinact" className="formlabel" style={{ marginLeft: '4px', fontSize: '13px', color: '#4e4e4e' }}> Inactive</label>
                </div>
                <span style={{ display: 'none' }}>
                    <input id='search' maxLength='100' value={this.state.searchtext} type='text'
                        placeholder='Text Search' style={{ border: 'none', borderBottom: 'solid 1px #7d7d7d', outline: 'none' }}
                        onChange={(e) => { this.searchTextChanged(e.currentTarget.value) }} />
                    <div id='resetfilter' className='icon close disabled' onClick={() => { this.searchTextChanged(''); }}
                        title='Reset filter' style={{ marginLeft: '-12px', width: '12px', height: '12px', cursor: 'pointer' }} /></span>
            </div>
        </span>);

        return (
            <div data-cy='addRulePanel' className='panel' help='admin/organization-setup/approval/active-rules'>
                <div className='title'><span className='floatleft'>Approval Rules</span>
                    {haveSome ? <div className='toolbar right' style={{ marginRight: 10, position: 'relative', top: '-4px' }}>
                        <div data-cy="addRuleWrapper">
                            <CwWidgets.CwButton toolTip='Create a new Approval Rule' isStandard={true} style={{ display: 'inline-block' }}
                                onClick={this.props.fnAddNew} value='ADD RULE' />
                        </div>
                    </div> : null}
                </div>
                <div className='content' style={{ paddingBottom: '10px' }}>
                    <div style={{ paddingTop: '0px', paddingBottom: '4px', width: '100%' }}>
                        {haveSome ? searchbox : null}
                        {haveSome ? filterboxes : null}
                    </div>
                    {haveSome ? thetable : noitems}
                </div>
            </div>);
    }

    searchTextChanged(val) {
        this.setState({ searchtext: val });
    }

    nothing() {
    }

    renderQuick() {
        const me = this;
        let items = [];
        this.props.users.forEach(function (user) {
            items.push(<option value={user.id} key={user.id}>{user.DisplayName}</option>);
        });

        let thecontent = (null);
        var cook = quosal.util.cookie('approve-set-showquick');
        var doshow = cook == 'show' || String.isNullOrEmpty(cook);
        if (doshow) {
            thecontent = (
                <div className='content' style={{ paddingBottom: '10px' }}>
                    <div><span style={{ fontSize: '13px' }}>Require approval for quotes...</span></div>
                    <div style={{ paddingLeft: '24px', maxHeight: '40px' }}>
                        <div className='formcolumn formfield' style={{ paddingLeft: '0px', paddingTop: '10px', width: '255px' }}>
                            <span style={{ fontSize: '13px', display: 'inline-block' }}>When created by </span>
                            <span className='formselectfieldwrapper' style={{ marginLeft: '8px' }}>
                                <select id={'userx1'} name='userselect' className='formselectfield' style={{ maxWidth: '150px' }}
                                    value={this.state.quickselectusr}
                                    onChange={(e) => { this.setState({ quickselectusr: '' + e.currentTarget.value }); }}   >
                                    {items}
                                </select>
                            </span>
                        </div>

                        <CwWidgets.CwButton
                            disabled={this.state.quickselectusr == "" ? "disabled" : ""}
                            toolTip='Create a new Approval Rule' isStandard={true} className='quaternary'
                            style={{ position: 'relative', top: '5px', marginLeft: '12px' }}
                            onClick={() => {
                                let id = this.state.quickselectusr == '0' ? this.props.users[0].id : this.state.quickselectusr;
                                this.props.addQuick1(id, false);
                            }} value='CREATE' />
                        {false ? <CwWidgets.CwButton toolTip='Create a new Approval Rule' isStandard={true} className='quaternary'
                            onClick={() => { this.props.addQuick1(this.state.quickselectusr, true); }} value='CREATE AND EDIT...' /> : null}
                    </div>
                    <div style={{ paddingLeft: '24px', marginTop: '11px' }}>
                        <div className='formcolumn formfield' style={{ paddingLeft: '0px', width: '255px', position: 'relative', top: '-2px' }}>
                            <span style={{ fontSize: '13px' }}>Discounts exceeds </span>
                            <span><input id='perc' type='text' value={this.state.quickselectper} style={{ width: '50px' }}
                                onChange={(e) => { this.setState({ quickselectper: '' + e.currentTarget.value.replace(/[^/.\d-]/g, '') }); }} size='4' /></span>
                            <span style={{ fontSize: '13px', marginLeft: '8px', position: 'relative', top: '0px' }}>%</span>
                        </div>
                        <CwWidgets.CwButton
                            disabled={/\d/.test(this.state.quickselectper) ? "" : "disabled"}
                            toolTip='Create a new Approval Rule' isStandard={true} className='quaternary'
                            style={{ position: 'relative', top: '-6px', marginLeft: '12px' }}
                            onClick={() => { this.props.addQuick2(this.state.quickselectper, false); }} value='CREATE' />
                        {false ? <CwWidgets.CwButton toolTip='Create a new Approval Rule' isStandard={true} className='quaternary'
                            onClick={() => { this.props.addQuick2(this.state.quickselectper, true); }} value='CREATE AND EDIT...' /> : null}
                    </div>

                    <div style={{ paddingLeft: '24px' }}>
                        <div className='formcolumn formfield' style={{ paddingLeft: '0px', width: '255px', position: 'relative', top: '-2px' }}>
                            <span style={{ fontSize: '13px' }}>Quote total is over </span>
                            <span><input id='maxamt' maxLength='9' type='text' value={this.state.quickselectmax} style={{ width: '55px' }}
                                onChange={(e) => { this.setState({ quickselectmax: '' + e.currentTarget.value.replace(/[^/.\d-]/g, '') }); }}
                                size='4' />
                            </span>
                        </div>

                        <CwWidgets.CwButton
                            disabled={/\d/.test(this.state.quickselectmax) ? "" : "disabled"}
                            toolTip='Create a new Approval Rule' isStandard={true} className='quaternary'
                            style={{ position: 'relative', top: '-6px', marginLeft: '12px' }}
                            onClick={() => { this.props.addQuick3(this.state.quickselectmax, false); }} value='CREATE' />
                    </div>
                </div>);
        }
        let showhide = doshow ? 'icon collapse icons-action' : 'icon expand icons-action';
        let panstyle = doshow ? 'panel' : 'panel nobody';
        return (
            <div className={panstyle} help='admin/organization-setup/approval/quick-rules'>
                <div className='title'><span className='floatleft'>Rule Shortcuts</span>
                    <div className='toolbar right' style={{ marginRight: 0, position: 'relative', top: '0px' }}>

                        <span style={{ marginRight: '10px' }}>

                            <div className={showhide} style={{ float: 'right', position: 'relative', width: '24px', height: '24px' }}
                                onClick={this.handleRemove} onClick={() => { this.switchUpToggle(); }} />

                        </span>
                    </div>
                </div>
                {thecontent}
            </div>);
    }
    switchUpToggle() {
        var doshow = quosal.util.cookie('approve-set-showquick');
        var newval = doshow == 'show' ? 'hide' : 'show';
        quosal.util.cookie('approve-set-showquick', newval, { expires: 365, path: '/' });
        this.forceUpdate();
    }

    render() {
        return (
            <div>
                {!this.props.isLoading ? this.renderNormalRules() : null}
                {this.renderQuick()}
            </div>);
    }
}


export class RuleListRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        let me = this;
        let reviewers = [];
        if (this.props.rule.approvalChain) {
            this.props.rule.approvalChain.sort(function (a, b) {
                if (a.level < b.level) {
                    return -1;
                }
                else if (a.level > b.level) {
                    return 1;
                }
                else {
                    return 0;
                }
            })
            this.props.rule.approvalChain.forEach(function (r) {
                if (r.chaintype == kApprovalSettings.ApproverTypeManager) {
                    reviewers.push('Manager');
                }
                if (r.chaintype == kApprovalSettings.ApproverTypeUser) {
                    let u = me.props.users.lookup(r.chainidentifier);
                    if (u) {
                        reviewers.push(u.DisplayName);
                    }
                    else {
                        reviewers.push('User');
                    }
                }
                if (r.chaintype == kApprovalSettings.ApproverTypeGroup) {
                    let g = me.props.groups.lookup(r.chainidentifier);
                    if (g) {
                        reviewers.push(g.Name);
                    }
                    else {
                        reviewers.push('Group');
                    }
                }
            });
        }
        var templates = this.props.rule.templates.map(x => {
                return me.props.templates.find(t => {
                    if(t.id == x){
                        return t.name
                    }
                }).name; 
            });
        if(templates.length == 0){
            templates.push("All Templates");
        }
        if (templates.length == this.props.templates.length) {
            templates = ["All Templates"];
        }
        let togglestyle = { verticalAlign: 'middle', marginLeft: '3px', position: 'relative', top: '-4px', fontSize: '12px' };
        let text = this.props.rule.isActive ? 'Active' : 'Inactive';
        if (!this.props.rule.isActive) {
            togglestyle.color = '#6e6e6e';
        }

        let sliderClassName = this.props.rule.isActive ? 'checked' : 'unchecked';
        let switchcode = (<span><label className='switch'>
            <input data-cy='isActiveCheckbox' type='checkbox' checked={this.props.rule.isActive}
                onChange={() => { this.props.fnToggleActive(this.props.rule.id, !this.props.rule.isActive) }} />
            <span className={sliderClassName + ' slider round '}></span>
        </label>
            <span style={togglestyle}>{text}</span></span>);

        return (
            <tr data-cy='approvalRuleRow' className='approvalRuleRowItem' key={this.props.rule.id}>
                <td className='content center editButtom' style={{ cursor: 'pointer' }} title='Edit Rule'
                    onClick={() => { this.props.fnEditClick(this.props.rule.id); }} >
                    <div className='icons-action edit' style={{ margin: '5px' }}></div>
                </td>
                <td className='content center copyButtom' style={{ cursor: 'pointer' }} title='Copy Rule'
                    onClick={() => { this.props.fnCopyClick(this.props.rule.id); }} >
                    <div className='icons-action copy' style={{ margin: '5px' }}></div>
                </td>
                <td className='content'><span className='rulename'>{this.props.rule.name}</span><br />
                    <span className='ruledescription'>{this.props.rule.message}</span></td>
                <td className='content'><span className='ruleapprovers'>{reviewers.join(', ')}</span></td>
                <td className='content'>{templates.join(', ')}</td>
                <td className='content approvalSwitchButton'>{switchcode}</td>
            </tr>
        );
    }
}