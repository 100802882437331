import { fetchData } from "../../../../product/newUI/services/fetchData";
import { SET_GLOBAL_SETTINGS } from "../actions";
import { formatUpdateMetricsResponse } from "../utils";

export const getUsers = (payload) => {
	return fetchData("quosalweb/quosalapi/Data.Query", "Data.Query", payload);
};

export const updateData = (payload) => {
	return fetchData("quosalweb/quosalapi/Data.update", "Data.Update", payload);
};

export const updateMetrices = (payload, callback, dispatch) => {
	const updateMetricesApi = quosal.api.call("Quote.ConfigureQuoteHeader", {
		...payload
	});
	updateMetricesApi.finished = function (res) {
		// Update the global settings with the new metrics in global context
		quosal.util.mergeObject(app.settings.global, formatUpdateMetricsResponse(res));
		// The callback will close the modal after the metrics are updated
		dispatch({ type: SET_GLOBAL_SETTINGS, payload: formatUpdateMetricsResponse(res) });
		callback && callback(false);
	}.bind(this);
	updateMetricesApi.call();
};
