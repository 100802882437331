//======================//
//* Core API Functions *//
//======================//
quosal.api = {
    onServerMessage: quosal.events.create(),
    operations: {},
    noOp: function () {},
    generateOpId: function () {
        var guid = null;

        do {
            guid = quosal.util.generateGuid();
        }
        while (quosal.api.operations[guid] != null); //ensure this guid is at least locally unique

        quosal.api.operations[guid] = {}; //reserve this opId

        return guid;
    },
    call: function (apiName, message) {
        if (!message)
            message = {};

        message.action = 'Api'
        message.api = apiName;
        message.opId = quosal.api.generateOpId();
        message.timestamp = new Date();
        //to be implemented by consumer
        message.stateChanged = quosal.api.noOp;
        //to be implemented by consumer
        message.finished = quosal.api.noOp;
        message.error = function (serverMessage) {
            //can be overridden by consumer
            quosal.log.error("API ERROR: " + serverMessage.error);
            Dialog.setIsWorking(false);
            Dialog.open({
                title: 'An Error Has Occurred',
                message: 'The server experienced an error while processing a request. The details of the error are below:' +
                        '<br /><br /><b>' + quosal.util.htmlEncodeAndConvertLineBreaks(serverMessage.error) + '</b><br /><br />',
                sellErrorIds: serverMessage.sellErrorIds,
                messageIsVettedHtml: true,
                links: [
                    {
                        title: 'Reload (recommended)',
                        callback: function () {
                            window.removeEventListener('beforeunload', quosal.events.beforeUnloadFunction);
                            window.onbeforeunload = null;
                            window.location.reload();
                        }
                    }, {
                        title: 'Dismiss',
                        callback: Dialog.closeAll
                    }
                ]
            });
        };
        message.call = function () {
            quosal.net.send(this);
        };
        message.setFlag = function(flag) {
            this.flag = flag;
        };
        message.wait = function () {
        };
        message.localAbort = function () {
            message.call = quosal.api.noOp;
            message.finished = quosal.api.noOp;
            message.stateChanged = quosal.api.noOp;
        };
        message.abort = function () {
            message.localAbort();
            var abortMessage = {
                action: 'AbortOperation',
                api: apiName,
                opId: message.opId,
                timestamp: new Date()
            };
            quosal.net.send(abortMessage);
        };

        quosal.api.operations[message.opId] = message;

        return message;
    }
};

