import { InputAdornment, TextField, useTheme } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const TextInputField = ({ id, name, label, disabled = false, size = "small", ...props }) => {
	const { control } = useFormContext();
	const theme = useTheme();

	const textFieldWidth = {
		width: "276px",
		marginTop: "8px"
	};

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<TextField
					{...props}
					{...field}
					sx={{
						...props.sx,
						...textFieldWidth,
						"& .MuiInputBase-root": {
							fontSize: "14px"
						},
						"& .MuiOutlinedInput-input": {
							padding: "10px 16px"
						},
						"& .Mui-focused .MuiInputBase-input": {
							boxShadow: "none"
						},
						"& .MuiInputBase-input.Mui-disabled": {
							color: theme.palette.text.disabled,
							backgroundColor: theme.palette.background.default
						}
					}}
					id={id}
					label={label}
					variant="outlined"
					disabled={disabled}
					size={size}
					error={!!error}
					helperText={error ? error.message : props.helperText}
					FormHelperTextProps={{
						sx: {
							marginLeft: 0,
							marginRight: 0
						}
					}}
					InputProps={{
						endAdornment: !!error?.message && (
							<InputAdornment position="end">
								<ErrorOutlineIcon color="error" fontSize="small" />
							</InputAdornment>
						)
					}}
					InputLabelProps={{
						shrink: true
					}}
					onBlur={async (e) => {
						await field.onBlur();
						props.onBlur && props.onBlur(e);
					}}
				/>
			)}
		/>
	);
};

export { TextInputField };
