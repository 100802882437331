import { api } from "../common/guidedSelling/api/api.js";
import { ProductCard } from "../common/guidedSelling/product.jsx";
import { SalesTrackFormPage } from "./salesTrackFormPage.jsx";
import { ThemeProvider } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { getDesignTokens } from "../common/guidedSelling/theme/adminGuidedSellingTheme.js";
import { createTheme } from '@mui/material/styles';
import { SalesTracksSearch } from "../common/guidedSelling/components/salestracksSearch.jsx";
import { CardGridContainerStyled } from "../common/guidedSelling/styledComponents/commonStyled.js";

const SALESTRACKS_ACTION = 'salesTracks';
const SALESTRACK_FORM_ACTION = 'salesTrackForm';

export class SalesTracksPage extends React.Component {
    constructor(props) {
        super(props)


        this.state = {
            mode: $('#dark-toggle').prop('checked'),
            isLoading: true,
            pageAction: SALESTRACKS_ACTION,
            salesTracks: [],
            salesTrackForm: [],
            salesTrackSelected: {},
        };

        //Bind this
        this.getAllSalesTracks = this.getAllSalesTracks.bind(this);
        this.handleFormNavigation = this.handleFormNavigation.bind(this);
        this.onError = this.onError.bind(this);
    }

    componentDidMount() {
        var me = this;
        if (window.location.search.includes('justLoggedIn=yes')) {
            $.credentialChecks.start();
        }
        this.componentIsMounted = true;
        this.getAllSalesTracks();
        this.changeThemeMode();
    }


    changeThemeMode() {
        const $checkbox = $('#dark-toggle[type=checkbox]');
        const handleSaveThemeMode = (isChecked) => {
            this.setState({ ...this.state, mode: isChecked });
        }
        $checkbox.on('change', function (e) {
            handleSaveThemeMode(e.currentTarget.checked);
        });
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
        const $checkbox = $('#dark-toggle[type=checkbox]');
        $checkbox.off('change')
    }

    handlePageAction = function (pageAction) {
        this.setState({ ...this.state, pageAction: pageAction });
    }

    getAllSalesTracks() {
        api.getAllSalesTracks("", (msg) => {
            this.setState({ ...this.state, salesTracks: msg.salesTracks });
        });
    }

    handleFormNavigation = function (salesTrack) {
        api.getSalesTrackForm(salesTrack.id, (msg) => {
            this.setState({ ...this.state, salesTrackSelected: salesTrack, pageAction: SALESTRACK_FORM_ACTION, salesTrackForm: msg.sections });
        }, (msg) => {
            this.onError('Error', msg.error);
        })
    }
    
    onError = function (title, message) {
        Dialog.open({
            title: title,
            message: message,
            links: [Dialog.links.ok]
        });
    }

    render() {
        const { salesTracks, salesTrackForm, salesTrackSelected } = this.state;
        const onCancel = () => this.handlePageAction(SALESTRACKS_ACTION);
        const NEW_THEME = createTheme(getDesignTokens(this.state.mode ? 'dark' : 'light'));
        const onFetchResults = (salesTracks) => this.setState({ ...this.state, salesTracks });
        const pagesHandler = {

            [SALESTRACKS_ACTION]: () => <>
                <Header />
                <CardGridContainerStyled direction="column" container>
                    <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                        <SalesTracksSearch onFetchResults={onFetchResults} />
                    </Grid>
                    <Grid item xs={12} container rowSpacing={2.5} direction="row">
                        {salesTracks && 
                        salesTracks.map((product, index) => (
                            <ProductCard key={"guidedSellingProductCard-item-" + index} onSalesRep={this.handleFormNavigation} product={product} />))
                        }
                    </Grid>
                </CardGridContainerStyled>
            </>,

            [SALESTRACK_FORM_ACTION]: () => <>
                <div className="salestracksearch-form-container">
                    <SalesTrackFormPage 
                        salesTrackForm={salesTrackForm} 
                        salesTrackSelected={salesTrackSelected} 
                        onBack={onCancel} onError={this.onError}
                    />
                </div>
            </>,
        }

        return <>
            <ThemeProvider theme={NEW_THEME}>
                <div className="salestracksearch-content">
                    {pagesHandler[this.state.pageAction]()}
                </div>
            </ThemeProvider>
        </>
    }
}

class Header extends React.Component {
    render() {
        return (
            <div className="salestrack-top-header">
                <h2 className="salestracksearch-title">Sales Tracks</h2>
            </div>
        );
    }
}

