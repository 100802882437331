import React from "react";
import { Breadcrumbs } from "@mui/material";

import { useAppDataContext } from "../globalStates/appDataProvider.jsx";
import { BreadcrumbsButton } from "../styledComponents/common.jsx";

function PageBreadcrumbs() {
	const { pathInfo, navigateToPage } = useAppDataContext();
	const { navigatedFrom, navigateTo } = pathInfo;

	const pageName = {
		SEARCH_RESULT_PAGE: "Product Search",
		PRODUCT_CART_PAGE: "Product Cart",
		PRODUCT_DETAILS_PAGE: "Product Details"
	};

	return (
		<Breadcrumbs>
			<BreadcrumbsButton onClick={() => navigateToPage("SEARCH_RESULT_PAGE")}>
				Product Search
			</BreadcrumbsButton>
			{navigatedFrom !== "SEARCH_RESULT_PAGE" && (
				<BreadcrumbsButton onClick={() => navigateToPage(navigatedFrom)}>
					{pageName[navigatedFrom]}
				</BreadcrumbsButton>
			)}
			<BreadcrumbsButton sx={{ color: "text.primary" }}>
				{pageName[navigateTo]}
			</BreadcrumbsButton>
		</Breadcrumbs>
	);
}

export default PageBreadcrumbs;
