quosal.api.customization = {};


//==========================================================//
//* GetGridConfiguration                                   *//
//==========================================================//
//      gridNames:                                          //
//==========================================================//
quosal.api.customization.getGridConfiguration = function (gridNames) {
    return quosal.api.call('Customization.GetGridConfiguration', {
        gridNames: gridNames
    });
};


//==========================================================//
//* SaveGridConfiguration                                  *//
//==========================================================//
//==========================================================//
quosal.api.customization.saveGridConfiguration = function (gridName, configurationName, columns) {
    return quosal.api.call('Customization.SaveGridConfiguration', {
        gridName: gridName,
        configurationName: configurationName,
        columns: columns
    });
};


//==========================================================//
//* DeleteGridConfiguration                                  *//
//==========================================================//
//==========================================================//
quosal.api.customization.deleteGridConfiguration = function (gridName, configurationName) {
    return quosal.api.call('Customization.DeleteGridConfiguration', {
        gridName: gridName,
        configurationName: configurationName
    });
};


//==========================================================//
//* GetFormConfiguration                                   *//
//==========================================================//
//      formNames:                                          //
//==========================================================//
quosal.api.customization.getFormConfiguration = function (formNames) {
    if (typeof formNames === 'string')
        formNames = [formNames];

    return quosal.api.call('Customization.GetFormConfiguration', {
        formNames: formNames
    });
};


//==========================================================//
//* SaveFormConfiguration                                  *//
//==========================================================//
//      form:                                               //
//==========================================================//
quosal.api.customization.saveFormConfiguration = function (form) {
    return quosal.api.call('Customization.SaveFormConfiguration', {
        form: form
    });
};


//==========================================================//
//* DeleteFormConfiguration                                *//
//==========================================================//
//      form:                                               //
//==========================================================//
quosal.api.customization.deleteFormConfiguration = function (formName, configurationName) {
    return quosal.api.call('Customization.DeleteFormConfiguration', {
        formName: formName,
        configurationName: configurationName
    });
};


//==========================================================//
//* GetFieldConfigurations                                 *//
//==========================================================//
//      objectNames:                                        //
//==========================================================//
quosal.api.customization.getFieldConfigurations = function (objectName, fieldName) {
    return quosal.api.call('Customization.GetFieldConfigurations', {
        objectName: objectName,
        fieldName: fieldName
    });
};


//==========================================================//
//* SaveFieldConfiguration                                 *//
//==========================================================//
//      form:                                               //
//==========================================================//
quosal.api.customization.saveFieldConfiguration = function (objectType, objectName, fieldName, fieldRename, fieldNotes, fieldShortName, customDropdownContents, deleteIfEmpty) {
    return quosal.api.call('Customization.SaveFieldConfiguration', {
        objectType: objectType,
        objectName: objectName,
        fieldName: fieldName,
        fieldRename: fieldRename,
        note: fieldNotes,
        fieldShortName: fieldShortName,
        customDropdownContents: customDropdownContents, //array
        deleteIfEmpty: deleteIfEmpty,
    });
};

//==========================================================//
//* GetFieldConfigurationLog                               *//
//==========================================================//
//      form:                                               //
//==========================================================//
quosal.api.customization.GetFieldConfigurationLog = function (objectName, fieldName) {
    return quosal.api.call('Customization.GetFieldConfigurationLog', {
        objectName: objectName,
        fieldName: fieldName
    });
};

//==========================================================//
//* DeleteFieldConfiguration                                 *//
//==========================================================//
//      form:                                               //
//==========================================================//
quosal.api.customization.deleteFieldConfiguration = function (objectType, objectName, fieldName) {
    return quosal.api.call('Customization.DeleteFieldConfiguration', {
        objectType: objectType,
        objectName: objectName,
        fieldName: fieldName,
    });
};

//==========================================================//
//* SaveUserGridColumnWidths                               *//
//==========================================================//
quosal.api.customization.saveUserGridColumnWidths = function (gridName, gridLayoutName, widthsByColumnName) {
    return quosal.api.call('Customization.SaveUserGridColumnWidths', {
        gridName: gridName,
        gridLayoutName: gridLayoutName,
        widthsByColumnName: widthsByColumnName,
    });
};