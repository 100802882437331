import dayjs from "dayjs";
import { DATE_FORMAT } from "../common/constant";

export const convertDateFormat = (date, format = DATE_FORMAT) => {
    return dayjs(date)?.format?.(format) || '';
}

export const parseDateFormat = (format) => {
    return format.replace('yy', 'YYYY').toUpperCase();
}

export const isEoPage = () => { return !!$("#isEoPage").length;}

export const formatUpdateMetricsResponse = (data) => { 
	return {
		EnableOneTimeTotal : data.showOneTimeTotal ,
		EnableOneTimeGrossProfit : data.showOneTimeGrossProfit,
		EnableRecurring : data.showRecurring,
		EnableRecurringGrossProfit : data.showRecurringGrossProfit,
		EnableTotalQuoteValue : data.showTotalQuoteValue,
		EnableDownpayment  : data.showDownpayment
	}
 }
