import { useEffect, useState } from "react";
import { getUsers, queryPayloadGenerator } from "../services";

export function useActiveUsers() {
	const [activeUsers, setActiveUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const getValidUsers = (users) => users.filter((user) => user.EmailAddress);

	useEffect(() => {
		setLoading(true);
		getUsers(
			queryPayloadGenerator("Users", [
				{
					field: "IsInactive",
					operator: "Like",
					value: false
				},
				{
					field: "IsApprovalOnly",
					operator: "Like",
					value: false
				}
			])
		).then((res) => {
			const { response0 = {} } = res;
			const { error } = response0;
			if (error) {
				console.error("Error:", error);
				setLoading(false);
				return;
			}
			setActiveUsers(response0?.resultSets[0] ? getValidUsers(response0?.resultSets[0]) : []);
			setLoading(false);
		});
	}, []);

	return { activeUsers, loading };
}
