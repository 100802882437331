import { Tooltip, Typography, Zoom } from '@mui/material';
import React, { useMemo } from 'react';
import useDarkMode from '../quote/newUI/WidgetToolbar/widgetToolbarHelpers';

const TruncatedText = React.memo(({
    text,
    tooltipTitle,
    className,
    showTooltip = false,
    maxWidth = '100%',
    textColor = "#000000",
    darkModeTextColor = "#FFFFFF"
}) =>
{
    const darkMode = useDarkMode();

    const typographyStyle = useMemo(() => ({
        maxWidth,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: darkMode ? darkModeTextColor : textColor
    }), [ darkMode, maxWidth, textColor, darkModeTextColor ]);

    const content = (
        <Typography
            className={className}
            style={typographyStyle}>
            {text}
        </Typography>
    );

    const contentWithTooltip = (
        <Tooltip
            title={tooltipTitle}
            placement="top-start"
            enterDelay={1000}
            enterNextDelay={1000}
            leaveDelay={200}
            TransitionComponent={Zoom}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        maxWidth: 500,
                        fontSize: '1rem'
                    }
                }
            }}>
            {content}
        </Tooltip >
    );

    return showTooltip ? contentWithTooltip : content;
});

export default TruncatedText;