export class CloudSourceButton extends React.Component {
    constructor(props) {
        super(props);
        this.componentIsMounted = false;
        this.state = {
            expanded: false,
            updating: false
        };

        this.toggleDialog = this.toggleDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.sourceUpdating = this.sourceUpdating.bind(this);
        this.sourceUpdated = this.sourceUpdated.bind(this);
    }

    componentDidMount() {
        this.componentIsMounted = true;
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    static hideDialogOnClick(e) {
        if (CloudSourceButton.activeDialog !== null && CloudSourceButton.activeDialog.childPanel !== null && !CloudSourceButton.activeDialog.childPanel.state.customGroupDialogActive) {
            if (e && e.target) {
                var src = $(e.target);

                if (src.hasClass('panel productsources') || src.parents('.panel.productsources').length > 0) {
                    return;
                }
            }

            $(document.body).unbind('mouseup');

            CloudSourceButton.activeDialog.childPanel.abortPricingLookup();
            CloudSourceButton.activeDialog.setState({ expanded: false });
            CloudSourceButton.previousDialog = CloudSourceButton.activeDialog;
            CloudSourceButton.activeDialog = null;

            setTimeout(function () { CloudSourceButton.previousDialog = null; }, 10);
        }
    }

    toggleDialog(e) {
        if (CloudSourceButton.previousDialog === this) {
            CloudSourceButton.previousDialog = null;
            return;
        }

        var expanded = !this.state.expanded;

        if (expanded) {
            CloudSourceButton.activeDialog = this;

            $(document.body).bind('mouseup', CloudSourceButton.hideDialogOnClick);
        }
        else {
            this.childPanel.abortPricingLookup();
            CloudSourceButton.activeDialog = null;
        }

        this.setState({ expanded: expanded });
    }

    closeDialog() {
        if (this.state.expanded) {
            this.childPanel.abortPricingLookup();
            this.setState({ expanded: false });
            CloudSourceButton.activeDialog = null;
        }
    }

    sourceUpdating() {
        this.setState({ expanded: false, updating: true });
        CloudSourceButton.activeDialog = null;
    }

    sourceUpdated() {
        if (this.componentIsMounted)
            this.setState({ expanded: false, updating: false });
    }

    render() {
        var dialog = null;

        if (this.props.priceSourcesLoaded === false) {
            return <div className="priceSourcesLoading"><Spinner /></div>
        }

        if (this.state.expanded && !this.state.updating) {
            dialog = <CloudSourcePanel ref={(r) => { this.childPanel = r; }} item={this.props.item} className={this.props.className} onClose={this.closeDialog} type={this.props.type} dialog={true}
                sourceUpdating={this.sourceUpdating} sourceUpdated={this.sourceUpdated} locked={this.props.lockpanel}
                attachEvent={this.props.attachEvent} customUpdate={this.props.customUpdate} buttonText={this.props.buttonText} />;
        }
        var extras = {};
        if (!this.props.disabled) {
            extras = { cursor: 'pointer' };
        }
        return (
            <div>
                {this.state.updating ? <Spinner /> :
                    <div className={'pricesourcelookup ' + (this.props.className || '')}
                        onClick={this.props.disabled ? null : this.toggleDialog}
                        style={extras} />}
                {dialog}
            </div>
        );
    }
}
CloudSourceButton.activeDialog = null;
CloudSourceButton.previousDialog = null;

export class CloudSourcePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searching: false,
            updating: false
        };

        this.preventRapidRepeatRequestsTimeoutId = null;
        this.preventRapidRepeatRequestsInterval = 1000;

        // This binding is necessary to make `this` work in the callback
        this.sourceGroupChanged = this.sourceGroupChanged.bind(this);
        this.beginPricingLookup = this.beginPricingLookup.bind(this);
        this.abortPricingLookup = this.abortPricingLookup.bind(this);
        this.priceResultSelected = this.priceResultSelected.bind(this);
        this.updateSource = this.updateSource.bind(this);
        this.doCustomUpdate = this.doCustomUpdate.bind(this);
    }

    sourceGroupChanged(e) {
        if (this.state.searching) {
            try {
                this.abortPricingLookup();
            } catch (ex) {
                quosal.log.error(ex);
            }
        }

        this.beginPricingLookup();
    }

    beginPricingLookup(overrideMfp) {
        if (this.props.hidden || !this.componentIsMounted) {
            return;
        }

        if (this.state.isPreSearch) {
            this.setState({ isPreSearch: false });
        }

        if (this.preventRapidRepeatRequestsTimeoutId) {
            return;
        }
        else {
            this.preventRapidRepeatRequestsTimeoutId = window.setTimeout(function () {
                this.preventRapidRepeatRequestsTimeoutId = null;
            }.bind(this), this.preventRapidRepeatRequestsInterval);
        }
        var sources = this.childSelector.getSelectedSources();
        var mfp = overrideMfp || this.props.item.ManufacturerPartNumber;
        var mfpEtilizeIdPairs = {};
        if (!overrideMfp) {
            mfpEtilizeIdPairs[this.props.item.ManufacturerPartNumber] = this.props.item.EtilizeProductId;
        }

        if (String.isNullOrEmpty(mfp) || mfp.toUpperCase() === 'NEW') {
            return;
        }

        var completePricingApiCall = function () {
            var pricingApi = quosal.api.pricing.lookupPricing([mfp], sources, app.currentQuote ? app.currentQuote.IdQuoteMain : null, true, false, mfpEtilizeIdPairs, true);
            pricingApi.stateChanged = function (msg) {
                if (msg.action === 'PricingResults') {
                    if (!this.componentIsMounted) {
                        return;
                    }

                    var sources = [];
                    if (msg.sources) {
                        sources = msg.sources;
                    }
                    else if (msg.source) {
                        sources = [msg.source];
                    }

                    for (let i = 0; i < sources.length; i++) {
                        this.state.searchingSources.removeAll(s => s.SourceId === sources[i].SourceId);
                    }

                    if (msg.products) {
                        for (let i = 0; i < msg.products.length; i++) {
                            var productId = 0; //0 - indicating this item did not come from Etilize
                            if (this.props.item.EtilizeProductId) { //Quote item
                                productId = this.props.item.EtilizeProductId;
                            }
                            else if (this.props.item.productid) { //Etilize product
                                productId = this.props.item.productid;
                            }
                            //if msg.products[i] does not have a product ID -> the result came from some place other than Etilize/RTP
                            if (productId == 0 || !msg.products[i].productId || productId == msg.products[i].productId) {
                                if (!msg.products[i].error) {
                                    this.state.completedSources.push(msg.products[i]);

                                    if (msg.products[i].images && this.props.onMetadata) {
                                        this.props.onMetadata({
                                            image: msg.products[i].image,
                                            images: msg.products[i].images,
                                            sdesc: msg.products[i].sdesc,
                                            ldesc: msg.products[i].ldesc
                                        });
                                    }
                                }
                            }
                        }
                    }

                    this.forceUpdate();
                }
            }.bind(this);
            pricingApi.finished = function (msg) {
                if (this.componentIsMounted) {
                    this.setState({
                        searching: false,
                        searchingSources: [],
                        completedSources: this.state.completedSources,
                        apiCall: null
                    });
                }
            }.bind(this);

            this.setState({
                searching: true,
                searchingSources: sources,
                completedSources: [],
                apiCall: pricingApi,
                selectedResult: null
            });

            pricingApi.call();
        }.bind(this);
        if (this.state.apiCall) {
            this.abortPricingLookup(completePricingApiCall);
        } else {
            completePricingApiCall();
        }
    }

    abortPricingLookup(callback) {
        if (this.state.apiCall) {
            this.state.apiCall.abort();

            if (this.componentIsMounted)
                this.setState({ searching: false, searchingSources: [], completedSources: this.state.completedSources, apiCall: null }, callback);
        }
    }

    priceResultSelected(result) {
        if (this.state.selectedResult !== result) {
            this.setState({ selectedResult: result });
        }
    }

    doCustomUpdate() {
        var whenCompleted = function (item) {
            if (this._isMounted) {
                this.setState({ updating: false });
            }
            if (this.props.sourceUpdated) {
                this.props.sourceUpdated(item);
            }
        }.bind(this);

        var newValues = {};
        newValues.mfp = this.props.getMfp ? this.props.getMfp() : this.props.item.ManufacturerPartNumber;
        newValues.source = this.state.selectedResult.props.source;
        newValues.cost = this.state.selectedResult.props.pricing.cost;
        newValues.msrp = this.state.selectedResult.props.pricing.msrp;
        newValues.selectedWarehouse = this.state.selectedResult.state.selectedWarehouse;
        newValues.warehouseCode = '';
        newValues.sourceSelectionDetails = this.state.selectedResult.props.pricing.sourceSelectionDetails;
        newValues.pricebook = this.state.selectedResult.props.pricing.pricebook;
        if (this.state.selectedResult.props.pricing.warehouses) {
            for (var i = 0; i < this.state.selectedResult.props.pricing.warehouses.length; i++) {
                var warehouse = this.state.selectedResult.props.pricing.warehouses[i];
                if (warehouse.name === newValues.selectedWarehouse) {
                    newValues.warehouseCode = warehouse.code;
                }
            }
        }
        else {
            newValues.warehouseCode = '';
        }
        this.props.customUpdate(this.props.item, newValues, whenCompleted);
    }

    updateSource() {
        if (this.state.selectedResult === null || this.state.updating) {
            return;
        }
        if (this.props.sourceUpdating) {
            this.props.sourceUpdating(this);
        }
        if (this.props.customUpdate) {
            this.doCustomUpdate();
            if (this._isMounted) {
                this.setState({ updating: false });
            }
            return;
        }
        this.setState({ updating: true });
        var mfp = this.props.getMfp ? this.props.getMfp() : this.props.item.ManufacturerPartNumber;

        var doUpdate = function (item) {
            var updateSourceApi = quosal.api.product.updateSource(
                item.IdQuoteMain,
                item.IdQuoteItems,
                mfp,
                this.state.selectedResult.props.pricing.vp,
                this.state.selectedResult.props.source,
                this.state.selectedResult.props.pricing.cost,
                this.state.selectedResult.props.pricing.msrp,
                this.state.selectedResult.state.selectedWarehouse,
                this.state.selectedResult.props.pricing.sourceSelectionDetails,
                this.state.selectedResult.props.pricing.pricebook);
            updateSourceApi.finished = function (msg) {
                if (msg.quote) {
                    quosal.sell.quote.update(msg.quote);
                }

                if (this.props.sourceUpdated) {
                    this.props.sourceUpdated(msg.item);
                }

                if (this.componentIsMounted) {
                    this.setState({ updating: false });
                }
                if (msg.error && msg.error.length > 0) {
                    $.quosal.dialog.show({ title: 'Update Source', message: msg.error, links: [$.quosal.dialog.links.ok] });
                }
            }.bind(this);
            updateSourceApi.call();
        }.bind(this);

        if (this.props.attachEvent) {
            this.props.attachEvent.call(doUpdate);
        } else {
            doUpdate(this.props.item);
        }
    }

    componentDidMount() {
        this.componentIsMounted = true;

        this.prohibitAutoLookup = (app.currentModule.getActiveSubModule().Type === 'ProductEdit') &&
            !quosal.settings.getValue('AutomaticallyLookUpPricingOnItemEdit');

        if (this.prohibitAutoLookup) {
            this.setState({ isPreSearch: true });
        } else if (!this.props.hidden) {
            this.beginPricingLookup();
            if (this.props.dialog) {
                var currentTab = this.props.item.IdQuoteTabs;
                var scrollX;
                var scrollY;
                if (currentTab) {
                    scrollX = $("#" + currentTab + " #datagridbody");
                    scrollY = $("#" + currentTab + " .sidescroll-subcontainer");
                } else {
                    scrollX = $("#datagridbody");
                    scrollY = $(".sidescroll-subcontainer");
                }

                var style = {};
                if (scrollX && Number(scrollX.scrollTop())) {
                    style.top = $(".productsources").position().top - scrollX.scrollTop()
                }
                if (scrollY && Number(scrollY.scrollLeft())) {
                    style.left = $(".productsources").position().left - scrollY.scrollLeft()
                };

                this.setState({
                    style: style
                });
            }
        }
    }
    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    render() {
        if (this.props.hidden) {
            return <div />;
        }

        var lock = this.props.locked == true;
        var pendingSearches = [];
        var completedSearches = [];
        if (this.state.searching) {
            for (let i = 0; i < this.state.searchingSources.length; i++) {
                pendingSearches.push(<CloudPricingSpinner key={this.state.searchingSources[i].SourceId} source={this.state.searchingSources[i]} />);
            }
        }
        if (this.state.completedSources) {
            for (let i = 0; i < this.state.completedSources.length; i++) {
                var completedSource = this.state.completedSources[i];
                if (!completedSource.error && completedSource.source) {
                    var isSelected = false;
                    var defaultWarehouse = null;
                    if (this.state.selectedResult) {
                        isSelected = (this.state.selectedResult.props.source === completedSource.source);
                    }
                    else if (this.props.item) {
                        isSelected = (this.props.item.Source === completedSource.source.EtilizeName) ||
                            (this.props.item.Source === completedSource.source.DisplayName);
                        if (isSelected && (completedSource.sourceSelectionDetails || this.props.item.SourceSelectionDetails)) {
                            isSelected = (completedSource.sourceSelectionDetails === this.props.item.SourceSelectionDetails);
                        }
                        if (isSelected) {
                            defaultWarehouse = this.props.item.Warehouse;
                        }
                    }
                    completedSearches.push(<CloudPricingResult key={completedSource.source.SourceId + i}
                        source={completedSource.source}
                        pricing={completedSource}
                        onSelect={this.priceResultSelected}
                        isSelected={isSelected}
                        defaultWarehouse={defaultWarehouse} locked={lock} />);
                }
            }
        }

        var style = {};
        if (this.props.dialog) {
            style.position = 'absolute';
        }
        if (this.state.style) {
            quosal.util.mergeObject(style, this.state.style);
        }
        var ready = '';
        var disabled = this.state.selectedResult === null || this.state.updating || lock;
        if (disabled) {
            ready = ' disabled';
        }

        var showSearchButtonInsteadOfUpdate = this.state.isPreSearch && !this.state.updating;

        return (
            <div ref={(r) => { this.childRoot = r; }} className={'panel productsources ' + (this.props.className || '')} style={style}>
                <div className="title">
                    <span>Price Sources</span>
                    {app.currentUser.IsAdministrator && quosal.validation.isPackageLevelAuthorized(app.packageLevels.standard) ?
                        <div className="toolbar">
                            <a href={quosal.util.url('adminsourcegroups.quosalweb', 'quotereturnurl=' + encodeURIComponent(location.href))}>
                                <div className="toolbutton gear" title="Configure Source Groups" />
                            </a>
                        </div>
                        : null}
                </div>
                <div className="content">
                    <form>
                        <div id="pricing">
                            <div className="list">{completedSearches}</div>
                            <div className="pending">{pendingSearches}</div>
                        </div>
                        <div className="sourceGroupContainer">
                            <input type="hidden" id="customSourceGroup" name="customSourceGroup" />
                            <div className="standardformfieldlabel"><label htmlFor="searchmfp" className="standardformlabel">Product Sources</label></div>
                            <SourceGroupSelector ref={(r) => { this.childSelector = r; }} sourcePanel={this} {...this.props} onChange={this.sourceGroupChanged} locked={lock} />
                        </div>
                    </form>
                </div>
                {showSearchButtonInsteadOfUpdate ?
                    <div id="cloudSourceSearchButton" className={"cwbtn right " + ready}
                        onClick={disabled ? null : this.beginPricingLookup.bind(this, null)}>
                        Search
                    </div>
                    :
                    <div id="process" className={"cwbtn right sourceandattach" + ready}
                        onClick={disabled ? null : this.updateSource}>
                        {this.state.updating ? <Spinner style={{ marginBottom: -24 }} /> : ''}
                        {this.state.updating ? 'Updating' : (this.props.buttonText || 'Update')}
                    </div>
                }
                <div id="cancel" className={"cwbtn right cancel" + (this.props.className == "contentgrid" ? "" : lock ? " disabled" : "")}
                    onClick={this.props.className == "contentgrid" ? this.props.onClose : lock ? null : this.props.onClose}>
                    Cancel
                </div>
            </div>
        );
    }
}

class CloudPricingSpinner extends React.Component {
    render() {
        return (
            <div className={'searching ' + this.props.source.SourceId}><Spinner /><div className="sourcename">Searching {this.props.source.FormattedName}...</div></div>
        );
    }
}

class CloudPricingResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedWarehouse: this.chooseDefaultWarehouse()
        };
        this.chooseDefaultWarehouse = this.chooseDefaultWarehouse.bind(this);
        this.selectThis = this.selectThis.bind(this);
        this.selectionChanged = this.selectionChanged.bind(this);
        this.warehouseChanged = this.warehouseChanged.bind(this);
        if (props.isSelected) {
            this.selectThis();
        }
    }

    chooseDefaultWarehouse() {
        var defaultWarehouse = this.props.defaultWarehouse;
        if (this.props.pricing.warehouses && !defaultWarehouse) {
            var bestWarehouse = null;
            for (var i = 0; i < this.props.pricing.warehouses.length; i++) {
                var warehouse = this.props.pricing.warehouses[i];
                if (!bestWarehouse || warehouse.qty > bestWarehouse.qty) {
                    bestWarehouse = warehouse;
                }
            }
            if (bestWarehouse) {
                defaultWarehouse = bestWarehouse.name;
            }
        }
        return defaultWarehouse;
    }
    selectThis() {
        if (this.props.onSelect) {
            this.props.onSelect(this);
        }
    }
    selectionChanged(e) {
        this.selectThis();
    }
    warehouseChanged(e) {
        this.setState({ selectedWarehouse: (e.target.checked ? e.target.value : null) });
    }
    render() {
        var warehouses = [];
        var lockinfo = { disabled: this.props.locked }
        if (this.props.pricing.warehouses) {
            for (var i = 0; i < this.props.pricing.warehouses.length; i++) {
                var warehouse = this.props.pricing.warehouses[i];
                var isChecked = this.props.isSelected && (warehouse.name === this.state.selectedWarehouse);
                warehouses.push(
                    <div className="warehouse" key={warehouse.code} id={warehouse.code}>
                        <input type="radio" name="selectedwarehouse" {...lockinfo} value={warehouse.name} style={{ width: 'inherit' }}
                            onChange={this.warehouseChanged} checked={isChecked} />
                        <div className="name"> {warehouse.name}</div>
                        <div className="available">({warehouse.qty}/{warehouse.backorder})</div>
                    </div>
                );
            }
        }

        var source;
        if (this.props.source.SourceName === 'salesforce' && this.props.pricing && this.props.pricing.pricebook) {
            source = 'SF: ' + this.props.pricing.pricebook;
        }
        else if (this.props.pricing && this.props.pricing.sourceName) {
            source = this.props.pricing.sourceName;
        }
        else {
            source = this.props.source.FormattedDisplayName;
        }
        return (
            <div id={this.props.source.SourceName + "-result"} className="productprice">
                <input type="radio" name="selectedsource" value={this.props.source.SourceId} style={{ width: 'inherit' }} {...lockinfo}
                    onChange={this.selectionChanged} checked={this.props.isSelected} />
                <div className="sourcename" style={{ marginLeft: '4px' }}>{source}: </div>
                <div className="price"> {app.currentQuote.formatCurrency(this.props.pricing.cost)}</div>
                <div className="available">({this.props.pricing.qty}/{this.props.pricing.backorder})</div>
                <div className="warehouses" style={{ display: this.props.isSelected ? 'block' : 'none' }}>
                    {warehouses}
                </div>
            </div>
        );
    }
}

class SourceGroupSelector extends React.Component {
    constructor(props) {
        super(props);
        var state = { customGroupDialogActive: false, priceSourcesLoaded: false };
        var selectedGroupCookie = quosal.util.cookie(this.props.type + '_sourcegroup');

        if (selectedGroupCookie) {
            state.selectedGroup = selectedGroupCookie;
        } else {
            state.selectedGroup = 'all';
        }

        this.getSourceFromMapping = this.getSourceFromMapping.bind(this);
        var sourceFromMapping = this.getSourceFromMapping();
        if (sourceFromMapping) {
            state.selectedGroup = sourceFromMapping;
        }

        this.state = state;

        // This binding is necessary to make `this` work in the callback
        this.showCustomGroupDialog = this.showCustomGroupDialog.bind(this);
        this.getSelectedSources = this.getSelectedSources.bind(this);
        this.getSelectedGroup = this.getSelectedGroup.bind(this);
        this.selectedGroupChanged = this.selectedGroupChanged.bind(this);
        this.selectedGroupUpdated = this.selectedGroupUpdated.bind(this);

        this.isDirty = this.isDirty.bind(this);
        this.clear = this.clear.bind(this);
        this.reset = this.reset.bind(this);
    }

    showCustomGroupDialog() {
        if (this.props.sourcePanel) {
            this.props.sourcePanel.setState({ customGroupDialogActive: true });
        }

        var componentDidMount = function () {
            this.props.selector.setState({ customSourceGroupSelector: this }); // `this` will refer to the instance of CustomSourceGroupSelector that did mount
        };
        var componentWillUnmount = function () {
            this.props.selector.setState({ customSourceGroupSelector: null }); // `this` will refer to the instance of CustomSourceGroupSelector that will unmount
        };
        var customGroupDialog = <CustomSourceGroupSelector {...this.props} selector={this} componentDidMount={componentDidMount} componentWillUnmount={componentWillUnmount} />;

        var closeDialog = function (updateSources) {
            Dialog.close();

            this.selectedGroupUpdated(this.childSelect.value);

            if (updateSources && this.props.sourcePanel) {
                setTimeout(function () {
                    this.props.sourcePanel.setState({ customGroupDialogActive: false });

                }.bind(this), 10);
            }
        }.bind(this);

        var updateCustomGroup = function () {
            if (this.state.customSourceGroupSelector) {
                var sourceGroupsCookie = JSON.stringify(this.state.customSourceGroupSelector.state.selectedSources);
                var sourceListCookie = '';

                for (var i = 0; i < this.state.customSourceGroupSelector.state.selectedSources.length; i++) {
                    sourceListCookie += (sourceListCookie === '' ? '' : ', ') + this.state.customSourceGroupSelector.state.selectedSources[i].FormattedName;
                }

                quosal.util.cookie(this.props.type + '_customsourcegroup', sourceGroupsCookie);
                quosal.util.cookie(this.props.type + '_customsourcelist', sourceListCookie);

                closeDialog(true);
            }
            return true;
        }.bind(this);

        var resetCustomGroup = function () {
            if (this.state.customSourceGroupSelector) {
                this.state.customSourceGroupSelector.reset();
            }
            return true;
        }.bind(this);

        var cancel = function () { closeDialog(false); };
        Dialog.open({
            title: 'Custom Source Group', width: '80%', resizable: false, draggable: true, onClose: cancel,
            links: [
                { title: 'OK', callback: updateCustomGroup },
                { title: 'Reset', callback: resetCustomGroup },
                { title: 'Cancel', callback: cancel }
            ],
            message: customGroupDialog
        });
    }

    getSelectedSources() {
        var sources = null;

        if (this.state.selectedGroup === 'all') {
            if (quosal.sell.product.sources) {
                sources = [...quosal.sell.product.sources];
            }
            else {
                sources = [];
            }
        }
        else if (this.state.selectedGroup === 'custom') {
            sources = JSON.parse(quosal.util.cookie(this.props.type + '_customsourcegroup'));
        }
        else { //saved group selected
            var group = quosal.sell.product.sourceGroups.firstOrNull(s => s.IdProductSourceGroup === this.state.selectedGroup);
            if (group && group.Sources) {
                sources = [...group.Sources];
            } else {
                this.setState({ selectedGroup: 'all' });
                sources = [...quosal.sell.product.sources]; //fallback to all sources
            }
        }

        return sources || [];
    }

    getSelectedGroup() {
        return this.state.selectedGroup;
    }

    selectedGroupChanged(e) {
        var selectedGroup = this.childSelect.value;
        this.selectedGroupUpdated(selectedGroup, function () {
            if (selectedGroup === 'custom' && quosal.util.nullOrEmpty(quosal.util.cookie(this.props.type + '_customsourcelist'))) {
                this.showCustomGroupDialog();
            }
        }.bind(this));
    }

    selectedGroupUpdated(selectedGroup, callback) {
        quosal.util.cookie(this.props.type + '_sourcegroup', selectedGroup);

        this.setState({ selectedGroup: selectedGroup }, function () {
            if (this.props.onChange) {
                this.props.onChange(this);
            }

            if (callback) {
                callback();
            }
        }.bind(this));
    }

    isDirty() {
        return (this.state.selectedGroup || 'all') !== (this.getSourceFromMapping() || 'all');
    }

    clear() {
        this.reset();
    }

    reset() {
        this.selectedGroupUpdated(this.getSourceFromMapping() || 'all');
    }

    getSourceFromMapping() {
        var sourceNameFromMapping = this.props.form &&
            this.props.form.props.alwaysDirtyInputValues &&
            this.props.form.props.alwaysDirtyInputValues['Source'];
        if (sourceNameFromMapping) {
            for (var i = 0; i < quosal.sell.product.sourceGroups.length; i++) {
                var loopSourceGroup = quosal.sell.product.sourceGroups[i];
                if (String.ciEquals(loopSourceGroup.GroupName, sourceNameFromMapping)) {
                    return loopSourceGroup.IdProductSourceGroup;
                }
            }
        }
        return null;
    }

    render() {
        var sourceOptions = [];

        if (!quosal.sell.product.sourceGroups) {
            quosal.sell.product.loadPriceSourcing(false, () => {
                this.setState({
                    priceSourcesLoaded: true
                });
            });
            return <Spinner />
        }

        for (var i = 0; i < quosal.sell.product.sourceGroups.length; i++) {
            sourceOptions.push(<option key={quosal.sell.product.sourceGroups[i].IdProductSourceGroup} value={quosal.sell.product.sourceGroups[i].IdProductSourceGroup}>{quosal.sell.product.sourceGroups[i].GroupName}</option>);
        }

        var customListLabel = null;
        if (this.state.selectedGroup === 'custom') {
            var customList = quosal.util.cookie(this.props.type + '_customsourcelist');

            if (quosal.util.nullOrEmpty(customList)) {
                customList = 'No sources selected';
            }

            customListLabel = <div id="customSourcesList" onClick={this.showCustomGroupDialog}>{customList}</div>;
        }

        return (
            <div>
                <div className="formselectfieldwrapper" style={{ borderWidth: 1 }}>
                    <select name="sourcegroup" id="sourcegroup" className="formselectfield" ref={(r) => { this.childSelect = r; }} value={this.state.selectedGroup} onChange={this.selectedGroupChanged}>
                        <option value="all">All Sources</option>
                        {sourceOptions}
                        <option value="custom">Custom</option>
                    </select>
                    <div id="customSourcesList" style={{ display: 'none' }} />
                </div>
                {customListLabel}
            </div>
        );
    }
}
global.SourceGroupSelector = SourceGroupSelector;

class CustomSourceGroupSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSources: [...this.props.selector.getSelectedSources()]
        };
        this.reset = this.reset.bind(this);
        this.sourceSelected = this.sourceSelected.bind(this);
    }

    componentDidMount() {
        if (this.props.componentDidMount) {
            this.props.componentDidMount.call(this);
        }
    }

    componentWillUnmount() {
        if (this.props.componentWillUnmount) {
            this.props.componentWillUnmount.call(this);
        }
    }

    reset() {
        this.setState({ selectedSources: [] });
    }

    sourceSelected(e) {
        var sourceId = e.target.value;

        if (e.target.checked) {
            var src = quosal.sell.product.sources.firstOrNull((s) => s.SourceId === sourceId);

            if (src) {
                this.state.selectedSources.push(src);
                this.forceUpdate();
            }
        }
        else {
            this.state.selectedSources.removeAll((s) => s.SourceId === sourceId);
            this.forceUpdate();
        }
    }

    render() {
        var options = [];

        for (var i = 0; i < quosal.sell.product.sources.length; i++) {
            var src = quosal.sell.product.sources[i];

            if (this.props.type === 'productsearch' && (src.SourceType === 'RTP' || src.SourceType === 'Retail')) {
                continue;
            }

            options.push(
                <div key={src.SourceId} className="formcheckboxwrapper" style={{ display: 'inline-block' }}>
                    <input type="checkbox" id={'src_' + src.SourceName} value={src.SourceId} onChange={this.sourceSelected} checked={this.state.selectedSources.firstOrNull((s) => s.SourceId === src.SourceId) !== null} />
                    <label htmlFor={'src_' + src.SourceName} className="formlabel">{src.FormattedName}</label>
                </div>
            );
        }

        return (
            <div>
                {options}
            </div>
        );
    }
}