const useStyles = () => {
	const listItemTextStyle = {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap"
	};

	const dragIndicatorStyle = {
		color: "#2E3F80"
	};

	const textFieldStyle = {
		flex: "1",
		"& .MuiInput-root:after": {
			borderBottomColor: "#2E3F80"
		},
		"& .Mui-focused:after": {
			outline: "2px solid #2E3F80"
		}
	};

	const menuCheckboxStyle = {
		"& .MuiSvgIcon-root": { fontSize: 20 },
		m: 0,
		paddingY: 0,
		"&.Mui-checked": {
			color: "#2E3F80"
		}
	};

	const elementsColor = {
		color: "#2E3F80"
	};

	return {
		listItemTextStyle,
		dragIndicatorStyle,
		textFieldStyle,
		menuCheckboxStyle,
		elementsColor
	};
};

export default useStyles;
