import React from "react";
import { GridPagination } from "@mui/x-data-grid-pro";
import { Box, FormControlLabel, MenuItem, Select, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { StyledIconButton, StyledSwitch } from "../styledComponents/common";

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
	const theme = useTheme();

	const lastPageNumber = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

	const pagesToSelect = Array.from({ length: lastPageNumber + 1 }, (_, i) => i + 0);

	const handleSelectPage = (event) => {
		onPageChange(event, event.target.value);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	return (
		<Box sx={{ display: "flex", flexShrink: 0, ml: 2.5 }}>
			<Box
				px={2}
				sx={{
					display: "flex",
					alignItems: "baseline",
					textAlign: "center"
				}}
			>
				<Select
					variant="standard"
					value={page}
					onChange={handleSelectPage}
					disableUnderline
					sx={{ fontSize: 14 }}
					MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
				>
					{pagesToSelect.map((value) => (
						<MenuItem value={value} key={value} sx={{ fontSize: 14 }}>
							{value + 1}
						</MenuItem>
					))}
				</Select>
				{` of ${lastPageNumber + 1} pages`}
			</Box>

			<StyledIconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <ArrowRightIcon /> : <ArrowLeftIcon />}
			</StyledIconButton>
			<StyledIconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <ArrowLeftIcon /> : <ArrowRightIcon />}
			</StyledIconButton>
		</Box>
	);
}

function TablePagination({
	itemsWithPrices,
	handleChangeItemsWithPrices,
	productsToFilter,
	withPriceSwitch = true
}) {
	return (
		<Stack
			direction="row"
			justifyContent={withPriceSwitch ? "space-between" : "flex-end"}
			flexGrow={1}
		>
			{withPriceSwitch && (
				<FormControlLabel
					control={
						<StyledSwitch
							disabled={!productsToFilter}
							checked={itemsWithPrices}
							onChange={({ target }) => handleChangeItemsWithPrices(target.checked)}
							data-testid="pricing-toggle"
						/>
					}
					label="Items With Pricing"
					sx={{ ml: 0 }}
				/>
			)}

			<GridPagination
				labelDisplayedRows={({ from, to, count }) => `${from} - ${to} of ${count} rows`}
				ActionsComponent={TablePaginationActions}
			/>
		</Stack>
	);
}

export default TablePagination;
