import { createContext, useContext, useEffect, useState } from "react";

const GlobalSettingsContext = createContext();

const GlobalSettingsProvider = ({ globalSettings, children }) => {
	const [state, setState] = useState(globalSettings);
	useEffect(() => {
		setState(globalSettings);
	}, [globalSettings]);
	return <GlobalSettingsContext.Provider value={state}>{children}</GlobalSettingsContext.Provider>;
};

const useGlobalSettingsContext = () => {
	const globalSettings = useContext(GlobalSettingsContext);
	if (globalSettings === undefined) {
		console.error("useGlobalSettingsContext must be used inside GlobalSettingsProvider");
	}
	return globalSettings;
};

export { GlobalSettingsProvider, useGlobalSettingsContext };
