/* eslint-disable react/display-name */
import React, { useState, useEffect, memo } from "react";
import {
	Box,
	FormControl,
	MenuItem,
	Modal,
	Select,
	Skeleton,
	InputLabel,
	Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useAppDataContext } from "../globalStates/appDataProvider";
import { ModalBox } from "../styledComponents/productSearchTable";
import { LinkStyledButton, StyledButton, StyledIconButton } from "../styledComponents/common";

import PriceSourcesList from "./priceSourcesList";

const PriceSourcesModal = memo(({ open, onClose, type, handleUpdate, row, hideButtons = [] }) => {
	const { handleAddToQuote, currentTabId, targetTabs, showNotification, addProduct } =
		useAppDataContext();

	const [selectedPriceInfo, setSelectedPriceInfo] = useState({});
	const [targetTab, setTargetTab] = useState(currentTabId);

	const addProductToCart = () => {
		addProduct(
			{
				...row,
				price: selectedPriceInfo.Price,
				source: selectedPriceInfo.DisplaySourceName,
				sourceId: selectedPriceInfo.SourceId
			},
			targetTab
		);
		onClose();
		showNotification("Successfully added", "The item has been added to the Bin");
	};

	const addProductToTheQuote = () => {
		handleAddToQuote([
			{
				...row,
				price: selectedPriceInfo.Price,
				source: selectedPriceInfo.DisplaySourceName,
				sourceId: selectedPriceInfo.SourceId,
				quantity: 1,
				targetTabId: targetTab
			}
		]);
		onClose();
		showNotification("Successfully added", "The item has been added to the Quote");
	};

	useEffect(() => () => setTargetTab(currentTabId), [currentTabId, open]);

	return (
		<Modal open={open} onClose={onClose}>
			<ModalBox>
				<Box display="flex" justifyContent="space-between">
					<Typography fontSize={20}>Price Sources</Typography>
					<StyledIconButton size="small" onClick={onClose}>
						<CloseIcon />
					</StyledIconButton>
				</Box>
				<PriceSourcesList
					handleSelectPriceSource={(priceInfo) => setSelectedPriceInfo(priceInfo)}
					row={row}
				/>

				{type === "grid" && (
					<FormControl sx={{ width: "100%" }}>
						<InputLabel
							id="select-label"
							shrink
							sx={{ backgroundColor: "background.default", px: "5px" }}
						>
							Select Tab
						</InputLabel>
						<Select
							labelId="select-label"
							id="select-target-tab"
							label="Select Tab"
							displayEmpty
							value={targetTab}
							onChange={(e) => setTargetTab(e.target.value)}
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							renderValue={
								targetTab !== ""
									? undefined
									: () => <Box sx={{ opacity: "0.7" }}>Choose an option</Box>
							}
							sx={{ fontSize: 14 }}
						>
							{Array.isArray(targetTabs)
								? targetTabs.map((item) => (
										<MenuItem key={item.value} value={item.value}>
											{item.label}
										</MenuItem>
								  ))
								: Array.from(new Array(5)).map(() => (
										<Skeleton
											key={Math.random().toString(36).slice(2)}
											height={40}
											width="100%"
										/>
								  ))}
						</Select>
					</FormControl>
				)}

				<Box display="flex" justifyContent="space-between" mt={4}>
					<LinkStyledButton
						variant="text"
						sx={{ color: "primary.main" }}
						onClick={onClose}
					>
						Cancel
					</LinkStyledButton>
					{type === "grid" && (
						<Box>
							{!hideButtons.includes("Add to Bin") && (
								<StyledButton
									variant="outlined"
									onClick={addProductToCart}
									sx={{ mr: 1 }}
								>
									Add to Bin
								</StyledButton>
							)}
							<StyledButton
								variant="contained"
								disabled={targetTab === ""}
								onClick={addProductToTheQuote}
							>
								Add to Quote
							</StyledButton>
						</Box>
					)}
					{type === "cart" && (
						<StyledButton
							variant="contained"
							disabled={!row.prices.length}
							onClick={() => {
								handleUpdate(selectedPriceInfo);
								onClose();
							}}
						>
							Update
						</StyledButton>
					)}
				</Box>
			</ModalBox>
		</Modal>
	);
});
export default PriceSourcesModal;
