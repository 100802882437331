import { FormPlaceholder } from "js/jsx/src/classes/forms.jsx"
import { EtilizeSearch } from "js/jsx/src/classes/etilize/etilizeSearch.jsx";

export default function etilize() {

    var start = new Date().getTime();

    quosal.ui.react.render(
        <FormPlaceholder message="Loading Etilize Search..." />
        , document.getElementById('searchFormContainer'));

    var renderDeliverPage = function (renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;

        renderMethod(<EtilizeSearch />, document.getElementById('searchFormContainer'));
    };

    var loadEtilizeSearchPage = function () {
        renderDeliverPage(quosal.ui.react.render);

        $('#pageName').val('cloud.etilize');
        quosal.util.calculateAndTrackPageLoadTimeInAppInsights(start, "Load - Etilize");
    };
    var unbindEvents = function () {
        quosal.navigation.onNavigate.unbind(unbindEvents);
    };
    quosal.navigation.onNavigate.bind(unbindEvents);
    
    loadEtilizeSearchPage();
}
