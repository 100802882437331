export class SideScroller extends React.Component {
    static scrollDistanceFormula(intensity) {
            return 70 + 50 * intensity * intensity;
    }

    constructor(props) {
        super(props);
        this.state = {      
            isRightStripVisible: false,
            isLeftStripVisible: false,
        };

        // This binding is necessary to make `this` work in the callback
        this.checkEdges = this.checkEdges.bind(this);
        this.scrollRight = this.scrollRight.bind(this);
        this.scrollLeft = this.scrollLeft.bind(this);
        this.doScroll = this.doScroll.bind(this);

        this.intensity = 0;
    }
    
    componentDidMount() {
        this.checkEdges();
        $(window).on('resize', this.checkEdges);
        $(this.refs.subcontainer).on('scroll', this.checkEdges);
    }
    componentWillUnmount() {
        $(window).off('resize', this.checkEdges);
        $(this.refs.subcontainer).off('scroll', this.checkEdges);
    }
    checkEdges() {
        var thisScroller = $(this.refs.subcontainer);
        var scrollWidth = thisScroller[0].scrollWidth;
        if (!scrollWidth) { return; }
        
        var max = scrollWidth - thisScroller[0].clientWidth;
        var scrollPosition = Math.ceil(thisScroller.scrollLeft());
        var isLeftStripVisible = (scrollPosition > 0),
            isRightStripVisible = (scrollPosition < max);

        if (this.state.isRightStripVisible !== isRightStripVisible || this.state.isLeftStripVisible !== isLeftStripVisible) {
            this.setState({
                isRightStripVisible: isRightStripVisible,
                isLeftStripVisible: isLeftStripVisible
            });
        }
    }
    scrollRight() {
        this.doScroll(true);
    }
    scrollLeft() {
        this.doScroll(false);
    }
    doScroll(isGoingRightward) {
        var thisScroller = $(this.refs.subcontainer);
        thisScroller.stop();
        this.intensity += 1;
        var resetIntensity = function () { this.intensity = 0; }.bind(this);
        var finalPosition = isGoingRightward
            ? thisScroller.scrollLeft() + SideScroller.scrollDistanceFormula(this.intensity)
            : thisScroller.scrollLeft() - SideScroller.scrollDistanceFormula(this.intensity);
        thisScroller.animate({ scrollLeft: finalPosition }, SideScroller.animationDuration, resetIntensity);
    }
    render() {
        var rightClassName = 'right scroll-strip' + (this.state.isRightStripVisible ? '' : ' hide');
        var leftClassName = 'left scroll-strip' + (this.state.isLeftStripVisible ? '' : ' hide');

        return <div className="sidescroll-container" >
            <div className="right scroll-strip-parent">
                <div className={rightClassName} onClick={this.scrollRight}></div>
            </div>
            <div className="left scroll-strip-parent">
                <div className={leftClassName} onClick={this.scrollLeft}></div>
            </div>
            <div ref="subcontainer" className="sidescroll-subcontainer">{this.props.children}</div>
        </div>;
    }
}

SideScroller.animationDuration= 300;