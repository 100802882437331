import {DualSegment, ApprovalPerson} from "js/jsx/src/classes/admin/Approvals/ApprovalFields.jsx";

export class ApprovalRuleForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDirty: false,
            productfields: [],
            currentValue: {isActive:true,
                            requireset: { id: 'anyone' },
                            notifyorder: { id: 'sequential' }
                        },
            critgroup: 'quote',
            errors: []
        };
        this.errName = 'name';
        this.errMessage = 'message';
        this.kErrorName = 'Rule Name is required';
        this.kErrorMessage = 'Notification Message is required';

        this.renderDetails = this.renderDetails.bind(this);
        this.renderToolbar = this.renderToolbar.bind(this);
        this.renderCriteria = this.renderCriteria.bind(this);
        this.renderApprovers = this.renderApprovers.bind(this);
        this.conformFieldToGroup = this.conformFieldToGroup.bind(this);
        this.doSaveClicked = this.doSaveClicked.bind(this);
        this.doCancelClicked = this.doCancelClicked.bind(this);
        this.doCopyClicked = this.doCopyClicked.bind(this);
        this.formEditPressed = this.formEditPressed.bind(this);
        this.errorForField = this.errorForField.bind(this);
        this.addError = this.addError.bind(this);
        this.nameBlur = this.nameBlur.bind(this);
        this.notificationBlur = this.notificationBlur.bind(this);
    }

    componentDidMount() {
        this.nameInput.focus();
    }

    errorForField(field) {
        let msg = this.state.errors[field];
        return msg ? msg : '';
    }

    addError(field,msg) {
        this.setState((prev, curprops) => {
            prev.errors[field] = msg;
            return { ...prev, errors: prev.errors }; // isn't going to trigger refresh though.
        });
    }

    renderDetails() {
        let msg1 = this.errorForField(this.errName);
        let msg2 = this.errorForField(this.errMessage);
        let note1 = null;
        let note2 = null;
        let extraform = {paddingBottom:'0px'};
        let extralabel1 = null;
        let extralabel2 = null;
        if (msg1.length > 0) {
            extralabel1 = {color:'#ca001a'};
            note1 = <div data-cy='ruleNameError' style={extralabel1}>{msg1}</div>;
        }
        else {
            note1 = <div style={extralabel1}>{'\u00A0'}</div>;
        }
        if (msg2.length > 0) {
            extralabel2 = {color:'#ca001a'};
            note2 = <div data-cy='notificationError' style={extralabel2}>{msg2}</div>;
        }
        else {
            note2 = <div style={extralabel2}>{'\u00A0'}</div>;
        }
        return (<Panel title='Rule Details'>
            <PanelContent>
                <div data-cy='ruleDetailPanel' className='formcolumn' style={{paddingLeft:'0px'}}>
                    <div className='formfieldlabel formlabelfull'><label htmlFor='rulename' className='formlabel' style={extralabel1}>Rule Name *</label></div>
                    <div className='formfield' style={extraform}><input id='rulename' maxLength='100' value={this.props.rule.name} type='text' 
                                title='The name must be unique' placeholder='Unique Rule Name' onKeyPress={this.formEditPressed}
                                ref={(input) => { this.nameInput = input; }}
                                onBlur={this.nameBlur}
                                onChange={(e) => { this.addError(this.errName, ''); this.props.fnFieldUpdate('name', e.currentTarget.value)}} /></div>
                    {note1}
                </div>
                <div className='formcolumn' style={{width:'484px'}}>
                    <div className='formfieldlabel formlabelfull'><label htmlFor='ruledesc' className='formlabel' style={extralabel2}>Notification Message *</label></div>
                    <div className='formfield' style={extraform}><input id='ruledesc' maxLength='100' value={this.props.rule.message} type='text' 
                            title='The criteria necessitates approval' placeholder='Discount greater than 40%' onKeyPress={this.formEditPressed}
                                onBlur={this.notificationBlur}
                                onChange={(e) => { this.addError(this.errMessage, ''); this.props.fnFieldUpdate('message', e.currentTarget.value)}} /></div>
                    {note2}
                </div>
                
                {this.renderRestrictions(false)}

                <div className='formcolumn' style={{paddingLeft:'0px', marginTop:'12px'}}>
                    <div className='formfieldlabel formlabelfull' style={{marginBottom:'8px'}}>
                        <label htmlFor='chkapplyrule' className='formlabel'>Quote Processing Status</label></div>
                    <div className='formfield'>
                        <span title='Apply this Rule to new quotes'>
                            <CwWidgets.CwCheckBox id="chkapplyrule" text="Active" value={this.props.rule.isActive} className='chktst'
                                onChange={(value) => { this.props.fnFieldUpdate('isactive', !this.props.rule.isActive) }} 
                                />
                        </span>
                    </div>
                </div>
            </PanelContent>
        </Panel>);
    };
    
    formEditPressed(e) {
        if (e.key === 'Enter' && this.props.formDirty) {
            this.doSaveClicked();
        }
        if (e.key === 'Escape') {
            this.doCancelClicked();
        }
    }
    
    conformFieldToGroup(group, field) {
        let fields = group == 'quote' ? this.props.quotefields : this.props.itemfields;
        let found = fields.find( (f) => {return f.name.toLowerCase() == field.toLowerCase();} );
        if (!found) {
            for (let i=0;i<fields.length;i++) {
                if (fields[i].common) {
                    return fields[i].name;
                }
            }
            return fields[0].name;
        }
        else {
            return field;
        }
    }

    renderHardCriteria(allText, selectedElements, allElements, updateKey, labelText) {
        let outsidestyle = {paddingLeft:'0px', paddingRight:'28px', maxWidth:'275px', minWidth:'275px', display:'inline-block'};
        let items = [];
        allElements.forEach(function(t) {
            items.push({ id: t.id, name: t.name } );
        });

        return (
            <div className='blankformcolumn' style={outsidestyle}>
                <div className='blankformfieldlabel' style={{marginBottom:'8px'}}>
                    <label htmlFor={updateKey} className='formlabel'>{labelText}</label>
                </div>
                <div className='blankformfieldwrapper' data-cy={labelText}>
                    <CwWidgets.CwMultiSelect
                        items={items}
                        value={selectedElements}
                        onChange={(value) => { this.props.fnFieldUpdate(updateKey, value) }}
                        onCollapse={(value) => { this.props.fnFieldUpdate(updateKey+'-close', value) }}
                        allPillText={allText}
                        selectAllText={allText}
                    />
                </div>
            </div>
        );
    }

    renderTeamCriteria(allText, selectedElements, allElements, updateKey, labelText) {
        let outsidestyle = {paddingLeft:'0px', paddingRight:'28px', maxWidth:'275px', minWidth:'275px', display:'inline-block'};
        let items = [];
        items.push({ id: '1000', name: 'No Team' } );
        allElements.forEach(function(t) {
            items.push({ id: t.id, name: t.name } );
        });

        return (
            <div className='blankformcolumn' style={outsidestyle}>
                <div className='blankformfieldlabel' style={{marginBottom:'8px'}}>
                    <label htmlFor={updateKey} className='formlabel'>{labelText}</label>
                </div>
                <div className='blankformfieldwrapper'>
                    <CwWidgets.CwMultiSelect
                        items={items}
                        value={selectedElements}
                        onChange={(value) => { this.props.fnFieldUpdate(updateKey, value) }}
                        onCollapse={(value) => { this.props.fnFieldUpdate(updateKey+'-close', value) }}
                        allPillText={allText}
                        selectAllText={allText}
                    />
                </div>
            </div>
        );
    }

    renderRestrictions() {
        let templist = this.renderHardCriteria('All Templates', this.props.rule.templates, this.props.templates, 'templates', 'Templates');
        let loclist = this.renderHardCriteria('All Locations', this.props.rule.locations, this.props.locations, 'locations', 'Locations');
        let teamlist = this.renderHardCriteria('All Teams', this.props.rule.teams, this.props.teams, 'teams', 'Teams');
        if (this.props.teams.length == 1)
        {
            teamlist = null;
        }
        return (
            <div className='addRuleForm'>
                {templist}
                {loclist}
                {teamlist}
            </div>
        );
    }

    renderCriteria() {
        let crits = [];
        for (var i=0; i < this.props.rule.criteria.length; i++) {
            var error = this.props.valueErrors && this.props.valueErrors[this.props.rule.criteria[i].id]
            let fieldsToShow = this.props.rule.criteria[i].fieldgroup == 'quote' ? this.props.quotefields : this.props.itemfields;
            
            crits.push(<ApproveCriteriaRow 
                    field={this.props.rule.criteria[i]}
                    fnFieldUpdate={this.props.fnFieldUpdate}
                    fnAddNewCriteria={this.props.fnAddNewCriteria}
                    fnRemoveCriteria={this.props.fnRemoveCriteria}
                    fnEnterKey={this.formEditPressed}
                    isLast={i == this.props.rule.criteria.length-1}
                    isOnly={false/*this.props.rule.criteria.length == 1*/}
                    fields={fieldsToShow} lookupEnums={this.props.lookupEnumsForField} 
                    conformField={this.conformFieldToGroup}
                    displayError={error}
                    rowid={i+1} key={i+1} />);
        }

        return (<Panel title='Criteria'>
            <PanelContent>
                <div data-cy='CriteriaList'>
                    {crits}
                    <div className='formcolumn' style={{paddingLeft:'0px', minHeight:'15px', marginTop:'12px'}}>
                        <div data-cy='AddCritiera' className="action-add-new" style={{marginTop:'0px',minHeight:'15px', fontSize:'13px', verticalAlign: 'middle', fontweight: '500', 
                                        color: '#212121', lineheight:'46px', cursor:'pointer'}} onClick={this.props.fnAddNewCriteria}>
                            <img src='img/svgs/v1.0/Action_AddNew.svg' className='addNewIcon' style={{cursor:'pointer'}}/><span className="addText">Add Criteria</span>
                        </div>
                    </div>
                </div>
            </PanelContent>
        </Panel>);
    }

    renderApprovers() {
        let desc;
        switch (this.props.rule.approvalChain.length) {
            case 0:
            case 1:
                desc = 'Sequentially';
                break;
            case 2:
                desc = 'Sequentially (1 then 2)';
                break;
            default:
                desc = 'Sequentially (1 to '+this.props.rule.approvalChain.length+')';
                break;
        }
        const radioordering = [{ id: 'sequential', name: desc }, { id: 'together', name: 'All at Once' }];

        let revs = [];
        for (var i=0; i < this.props.rule.approvalChain.length; i++) {
            revs.push(<ApproverRuleRow 
                    id={this.props.rule.approvalChain[i].id}
                    chaintype={this.props.rule.approvalChain[i].chaintype}
                    chainidentifier={this.props.rule.approvalChain[i].chainidentifier}
                    level={this.props.rule.approvalChain[i].level}
                    fnFieldUpdate={this.props.fnFieldUpdate}
                    fnAddNewApprover={this.props.fnAddNewApprover}
                    fnRemoveApprover={this.props.fnRemoveApprover}
                    isLast={i == this.props.rule.approvalChain.length-1}
                    isOnly={this.props.rule.approvalChain.length == 1}
                    doingSequential={this.props.rule.revieworder == 'sequential'}
                    groups={this.props.groups} users={this.props.users}
                    errors={this.state.errors}
                    rowid={i+1} key={i+1} />);
        }

        return (<Panel title='Approvers'>
            <PanelContent>
                {revs}
                <div data-cy='addNewApprovers' className='formcolumn action-add-new' style={{paddingLeft:'0px', minHeight:'15px', marginTop:'8px'}}>
                    <div style={{marginTop:'0px',minHeight:'15px', fontSize:'13px', verticalAlign: 'middle', fontweight: '500', 
                                        color: '#212121', lineheight:'46px', cursor:'pointer'}} onClick={this.props.fnAddNewApprover}>
                        <img src='img/svgs/v1.0/Action_AddNew.svg' className='addNewIcon' style={{cursor:'pointer'}}/><span className="addText">Add Approver</span>
                    </div>
                </div>
                
                <div data-cy='approvalSequenceChoices' style={{ maxWidth: '330px', fontSize: '12px', marginTop: '16px', maxHeight:'42px'}}>
                    <div className='formfieldlabel formlabelfull' style={{ marginBottom: '8px' }}><label htmlFor='sequencing' className='formlabel'>Notify Approvers...</label></div>
                    <CwWidgets.CwRadioButtonGroup groupName='sequencing'
                        onChange={(btn) => { this.props.fnFieldUpdate('revieworder', btn.id) }}
                        radioButtons={radioordering} 
                        value={this.props.rule.revieworder == 'sequential' ? radioordering[0] : radioordering[1]} 
                        columns={2} />
                </div>
                
            </PanelContent>
        </Panel>);
    }

    renderToolbar() {
        let savetool = 'Save changes to this rule';
        if (this.props.rule.name.length == 0) {
            savetool += '\r\nNotice: Name is required';
        }
        if (this.props.rule.message.length == 0) {
            savetool += '\r\nNotice: Notification Message is required';
        }
        let isNew = (this.props.rule.id == '0');
        let extraclass = this.props.rule.name.length == 0 || this.props.rule.message.length == 0 ? 'partial' : null;
        return (
            <div className='panel only' help='admin/approval/edit-rule' style={{height:'22px'}}>
                <div data-cy='toolbarPanel' className='title'><span className='floatleft' style={{top:'6px'}} >{isNew ? 'Add Rule' : 'Edit Rule'}</span>
                    <div className='toolbar right' style={{marginRight:'10px', position: 'relative', top:'-4px'}}>
                        {(!isNew) ? <CwWidgets.CwButton toolTip='Make a copy of this rule' isStandard={true} 
                                    onClick={this.doCopyClicked} value='COPY' style={{marginRight:'10px'}} className='secondary' /> : null}
                        <CwWidgets.CwButton toolTip='Cancel changes to this rule' isStandard={true} 
                                    onClick={this.doCancelClicked} value='CANCEL' style={{marginRight:'10px'}} className='secondary' />
                        <CwWidgets.CwButton toolTip={savetool} isStandard={true} 
                                    onClick={this.doSaveClicked} value='SAVE' className={extraclass} />
                    </div>
                </div>
            </div>);
    }

    doCopyClicked() {
        if (this.props.fnMakeCopy) {
            this.props.fnMakeCopy(this.props.rule.id);
        }
    }

    doCancelClicked() {
        if (this.props.formDirty) {
            Dialog.open({
                title: 'Unsaved Changes?',
                message: 'You have unsaved changes. Do you want discard them?',
                closeRequiresButton: true,
                links: [
                {
                    title: 'Discard',
                    callback: () => {Dialog.closeAll(); this.props.fnCancel(); }
                }, 
                {
                    title: 'Cancel',
                    callback: Dialog.closeAll
                }]
            });
        }
        else {
            this.props.fnCancel();
        }
    }

    nameBlur(e) {
        if (!this.props.rule.name || this.props.rule.name.length == 0) {
            this.addError(this.errName, this.kErrorName);
        }
    }

    notificationBlur(e) {
        if (!this.props.rule.message || this.props.rule.message.length == 0) {
            this.addError(this.errMessage, this.kErrorMessage);
        }
    }

    doSaveClicked() {
        let errormsg = '';
        if (this.props.rule.name.length == 0) {
            this.addError(this.errName, this.kErrorName);
            errormsg = 'Please specify a Rule Name before saving.';
        }
        if (this.props.rule.message.length == 0) {
            this.addError(this.errMessage, this.kErrorMessage);
            errormsg = errormsg.length > 0 ? 'Please specify a Rule Name and Notification Message before saving.' 
                                                    : 'Please specify a Notification Message before saving.';
        }

        var invalidApprover = this.props.rule.approvalChain.where(x => x.chaintype === kApprovalSettings.ApproverTypeUser && (x.chainidentifier == undefined || x.chainidentifier == ''));
        
        this.props.rule.criteria.forEach(criteria => {
            var fieldGroupName = criteria.fieldgroup == "quote" ? "QuoteMain" : "QuoteItems";
            var fieldData = quosal.customization.fields.getFieldData(quosal.customization.fields.types.businessObject, fieldGroupName , criteria.field);
            if ( !/\d/.test(criteria.value) && fieldData && fieldData.DataType && (fieldData.DataType == "Double" || fieldData.DataType == "Int32")){
                this.props.addValueError(criteria);
                this.addError("valueError", 'value required');
                errormsg = errormsg.length > 0 ? errormsg: 'Please make sure all rules are valid before saving.'; //This error is not displayed, but is required to prevent the saving / closing of the page.
            }
        });
        
        if (invalidApprover && invalidApprover.length > 0) {
            this.addError('missingApprover', 'Please assign a resource to approve this rule.')
            return false;
        }
        if (errormsg.length > 0) {
            /*Dialog.open({
                title: 'Missing Fields',
                message: errormsg,
                closeRequiresButton: false,
                linkalign: 'right',
                fastload: true,
                links: [{title: 'OK', callback: Dialog.close}]
            });*/
            return false;
        }
        let me = this;
        if (this.props.fnCheckDuplicate(this.props.rule.id, this.props.rule.name) == true) {
            this.addError(this.errName, 'Duplicate Rule name');
            Dialog.open({
                title: 'Duplicate Rule',
                message: 'The rule \'' + this.props.rule.name + '\' already exists. Please use a different name',
                closeRequiresButton: false,
                linkalign: 'right',
                fastload: true,
                links: [{title: 'OK', callback: Dialog.close}]
            });
            return false;
        }
        let chaindup = '';
        for (let i=0; i<this.props.rule.approvalChain.length;i++){
            let iv = this.props.rule.approvalChain[i];
            for (let j=i+1; j<this.props.rule.approvalChain.length;j++){
                let jv = this.props.rule.approvalChain[j];
                if (iv.chaintype == jv.chaintype && iv.chainidentifier == jv.chainidentifier) {
                    chaindup = iv;
                }
            }
        }
        if (chaindup) {
            Dialog.open({
                title: 'Duplicate Approvers',
                message: 'One or more approvers are included more than once. Do you want to save this rule anyway?',
                closeRequiresButton: false,
                linkalign: 'right',
                fastload: true,
                links: [
                    {
                        title: 'Cancel',
                        callback: function () {
                            Dialog.close();
                        }
                    },
                    {
                        title: 'Save',
                        callback: function () {
                            Dialog.close();
                            if (me.props.fnSave) {
                                me.props.fnSave(me.props.rule.id == '0');
                            }
                        }
    
                    }
                ]
            });
            return false;
        }
        else {
            if (this.props.fnSave) {
                this.props.fnSave(this.props.rule.id == '0');
            }
        }

    }

    render() {
        return (
            <div>
                {this.renderToolbar()}
                {this.renderDetails()}
                
                {this.renderCriteria()}
                {this.renderApprovers()}
                
            </div>);
    }
}


export class ApproverRuleRow extends React.Component {
    constructor(props) {
        super(props);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
    }

    render() {
        let extratag = this.props.level == 1 ? 'First' : '';
        let extratool = '';
        if (this.props.level == 1) {
            if (this.props.chaintype == kApprovalSettings.ApproverTypeGroup) {
                extratool = 'First approval email will be sent to this group';
            }
            else if (this.props.chaintype == kApprovalSettings.ApproverTypeUser) {
                extratool = 'First approval email will be sent to this user';
            }
            else {
                extratool = 'First approval email will be sent to the manager';
            }
        }
        if (this.props.isLast) {
            if (this.props.chaintype == kApprovalSettings.ApproverTypeGroup) {
                extratool = 'The final approval email will be sent to this group';
            }
            else if (this.props.chaintype == kApprovalSettings.ApproverTypeUser) {
                extratool = 'The final approval email will be sent to this user';
            }
            else {
                extratool = 'The final approval email will be sent to the manager';
            }
        }
        if (!this.props.doingSequential || this.props.isOnly) {
            extratool = '';
        }
        let second = null;
        let addmore = this.props.isLast ? (<div style={{ float: 'right', position: 'relative', top: '13px', marginRight: '12px' }}
            className='icons-action' onClick={this.props.fnAddNewApprover}>
            <img src='img/svgs/v1.0/Action_AddNew.svg' style={{ height: '20px', width: '20px' }}
                title='Add an additional approval contact' />
        </div>) : null;
        let delclass = this.props.isOnly ? 'icon close icons-action disabled' : 'icon close icons-action';
        let rowheight = '32px';

        let missingApprover = null
        let labelStyle = null;
        let errorMessage = this.props.errors['missingApprover'];
        if (errorMessage && errorMessage.length > 0) {
            labelStyle = { color: '#ca001a' };
            missingApprover = <div data-cy='missingApproverError' style={labelStyle}>{errorMessage}</div>;
        }
        else {
            missingApprover = <div style={labelStyle}>{'\u00A0'}</div>;
        }

        if (this.props.chaintype == kApprovalSettings.ApproverTypeGroup) {
            let items=[];
            this.props.groups.forEach(function(group) {
                items.push(<option value={group.IdApprovalGroups} key={group.IdApprovalGroups}>{group.Name}</option>);
                });
            second = (
                <div className='formcolumn' style={{paddingTop: '8px', minHeight:rowheight}}>
                    <div className='formselectfieldwrapper' style={{ maxWidth: '220px'}}>
                        <select id={'group'+this.props.rowid} name='groupselect' className='formselectfield' 
                                value={this.props.chainidentifier} 
                                onChange={(e) => { this.props.fnFieldUpdate('a'+this.props.rowid+':chainidentifier', e.currentTarget.value) }}>
                            {items}
                        </select> 
                    </div>
                </div>
            );
        }
        else if (this.props.chaintype == kApprovalSettings.ApproverTypeUser) {
            let items=[];
            this.props.users.forEach(function(user) {
                if (user.IsApprover) {
                    items.push(<option value={user.id} key={user.id}>{user.DisplayName}</option>);
                }
                });
            second = (
                <div className='formcolumn' style={{paddingTop: '8px', minHeight:rowheight}}>
                    <div className='formselectfieldwrapper'>
                        <select id={'user'+this.props.rowid} name='userselect' className='formselectfield' 
                                value={this.props.chainidentifier} 
                                onChange={(e) => { this.props.fnFieldUpdate('a'+this.props.rowid+':chainidentifier', e.currentTarget.value) }}>
                            {items}
                        </select>
                    </div>
                    {missingApprover}
                </div>
            );
        }
        else {
            let items=[];
            items.push(<option value='inside' key='inside'>of Inside Rep</option>);
            items.push(<option value='primary' key='primary'>of Primary Rep</option>);
            //items.push(<option value='both' key='both'>of Both Reps</option>);
            second = (
                <div className='formcolumn' style={{paddingTop: '8px', minHeight:rowheight}}>
                    <div className='formselectfieldwrapper'>
                        <select id={'rep'+this.props.rowid} name='repselect' className='formselectfield' 
                                value={this.props.chainidentifier} 
                                onChange={(e) => { this.props.fnFieldUpdate('a'+this.props.rowid+':chainidentifier', e.currentTarget.value) }}>
                            {items}
                        </select>
                    </div>
                </div>
            );
        }

        let selector = (<div className='formcolumn' style={{ paddingLeft: '0px', paddingTop: '8px', minHeight:rowheight, maxWidth:'125px'}}>
                            <div className='formselectfieldwrapper'>
                                <select id='classify' name='classify' className='formselectfield' value={this.props.chaintype} 
                                    onChange={(e) => { this.props.fnFieldUpdate('a'+this.props.rowid+':chaintype', e.currentTarget.value) }}>
                                    <option value='manager'>Manager</option>
                                    <option value='group'>Group</option>
                                    <option value='user'>User</option>
                                </select>
                            </div>
                        </div>);
        return (
            <div className='approverole'>
                <ApprovalPerson level={this.props.level} extrax={extratag} extratool={extratool} />
                
                {selector}
                {second}
                <div data-cy="removeApprover" className={delclass} style={{ float: 'right', position: 'relative', top: '14px' }}
                        onClick={this.handleRemove}
                />
                {/*addmore*/}
            </div>);    // TODO REMOVE EXTRA STUFF
    }

    handleTypeChange(index) {
        let newVal = 0;
        switch (index) {
            case 0:
                newVal = 0;
                break;
            case 1:
                newVal = 1;
                break;
            case 2:
                newVal = 2;
                break;
        }
        this.props.fnFieldUpdate('a'+this.props.rowid+':chaintype', newVal);
    }

    handleRemove() {
        if (this.props.isOnly) {
            return;
        }
        if (!this.props.fnRemoveApprover) {
        }

        let me = this;
        let msg;
        if (this.props.chaintype == kApprovalSettings.ApproverTypeGroup) {
            let name = this.props.groups[0].name;
            this.props.groups.forEach(function(group) {
                if (group.id == me.props.chainidentifier) {
                    name = group.name;
                }
                });
            msg = (<span>Are you sure you want to remove the <b>{name}</b> group from the list of approvers?</span>);
        }
        else if (this.props.chaintype == kApprovalSettings.ApproverTypeUser) {
            let name = this.props.users[0].name;
            this.props.users.forEach(function(user) {
                if (user.id == me.props.chainidentifier) {
                    name = user.DisplayName;
                }
            });
            msg = (<span>Are you sure you want to remove <b>{name}</b> as an approver?</span>);
        }
        else {
            msg = (<span>Are you sure you want to remove the <b>Manager</b> as an approver?</span>);
        }
        
        Dialog.open({
            title: 'Confirm Delete',
            message: msg,
            closeRequiresButton: true,
            linkalign: 'right',
            fastload: true,
            links: [
                {
                    title: 'DELETE', callback: function () {
                        me.props.fnRemoveApprover(me.props.id);
                        Dialog.close();
                    }
                },
                {
                    title: 'CANCEL', callback: function () {
                       Dialog.close();
                    }
                }
            ]
        });
        return false;
    }
}

export class ApproveCriteriaRow extends React.Component {
    constructor(props) {
        super(props);
        this.handleRemove = this.handleRemove.bind(this);
        this.updateFieldGroup = this.updateFieldGroup.bind(this);
        this.configureComparatorByType = this.configureComparatorByType.bind(this);
        this.valueErrorMsg = "Rule is incomplete. Please complete the rule.";
    }

    render() {
        let addmore = this.props.isLast ? 
                (<div data-cy='CriteriaAddMoreLastCriteria' style={{ float: 'right', position: 'relative', top: '9px', marginRight: '12px' }}
                    className='icons-action action-add-new' onClick={this.props.fnAddNewCriteria}>
                    <img src='img/svgs/v1.0/Action_AddNew.svg' style={{ height: '20px', width: '20px' }}
                    title='Add an additional criteria' />
                </div>) : null;
        let delclass = this.props.isOnly ? 'icon close icons-action disabled' : 'icon close icons-action';
        let groupitems = [
            { name: 'QUOTE', value: 'quote' },
            { name: 'ITEM', value: 'item' },
        ];
        let fieldOptions = [];
        for (let i=0;i<this.props.fields.length;i++){
            let f = this.props.fields[i];
            if (f.common) {
                fieldOptions.push(<option key={'c'+f.name+this.props.field.id} value={f.name}>{f.display}</option>);
            }
        }
        fieldOptions.push(<option key='disabled' disabled className='separator'>————————————————————</option>);
        let datatype='String';
        for (let i=0;i<this.props.fields.length;i++){
            let f = this.props.fields[i];
            if (f.name == 'EmbeddedVideoHtml' || f.name == 'HtmlAgreement' || f.name == 'HtmlNotes1' || f.name == 'HtmlNotes2' || f.name == 'ItemNotesHtml') {
                continue;
            }
            fieldOptions.push(<option key={'a'+f.name+this.props.field.id} value={f.name}>{f.display}</option>);
            if (f.name.toLowerCase() == this.props.field.field.toLowerCase()) {
                datatype = f.datatype;
            }
        }
        
        let dataelem = kApprovalSettings.DataMatrix.find( (info) => {return info.datatype.toLowerCase() == datatype.toLowerCase();} );
        let compItems = [];
        let enums = this.props.lookupEnums(this.props.field.fieldgroup, this.props.field.field);
        for (let i=0; i<dataelem.options.length; i++) {
            let desc = kApprovalSettings.DataDescriptions.find((d) => {return d.option == dataelem.options[i];}).value;
            if (enums && dataelem.options[i] != 'eq' && dataelem.options[i] != 'ne') {
                continue;
            }
            compItems.push(<option value={dataelem.options[i]} key={dataelem.options[i]+this.props.field.id}>{desc}</option>);
        }

        let rowstyle= { borderBottom: '1px solid #e6e6e6' };
        if (this.props.rowid == 1000) {
            rowstyle.borderTop = '1px solid #e6e6e6';
        }
        let grouptoggle=(<div className='formcolumn' style={{paddingLeft:'0px', width:'105px', position:'relative', top:'7px'}}>
                <DualSegment name={'dual'+this.props.id}
                    elements={groupitems}
                    selected={this.props.field.fieldgroup == 'quote' ? 0: 1}
                    identity={this.props.field.id}
                    fnChanged={this.updateFieldGroup} />
            </div>);
        let fieldselect=(<div className='formcolumn' style={{paddingLeft:'0px', width:'200px', position:'relative', top:'10px'}}>
                <div className='formselectfieldwrapper' style={{width:'200px', marginLeft:'10px', display:'inline-block', top:'4px'}}>
                    <select id={'compare'+this.props.field.id} name={'compare'+this.props.field.id} className='formselectfield' 
                        value={this.props.field.field} 
                        onChange={(e) => this.props.fnFieldUpdate('c'+this.props.field.id+':field',e.currentTarget.value)}>
                        {fieldOptions}
                    </select>
                </div>
            </div>);
        let compareselect=(<div className='formcolumn' style={{paddingLeft:'20px', width:'125px', position:'relative', top:'10px'}}>
                <div className='formselectfieldwrapper'>
                    <select id={'comparitor'+this.props.field.id} name={'comparitor'+this.props.field.id} className='formselectfield' 
                        value={this.props.field.comparison}
                        onChange={(e) => this.props.fnFieldUpdate('c'+this.props.field.id+':comparison',e.currentTarget.value)}>
                        {compItems}
                    </select>
                </div>
            </div>);
        let compartoinfo=this.configureComparatorByType(this.props.field.fieldgroup, this.props.field.field, 
                                                        this.props.field.id,datatype,this.props.field.value);
        if (datatype.toLowerCase() == 'boolean') {
            compareselect = (<div className='formcolumn' style={{paddingLeft:'30px', fontSize: '13px', width:'40px', position:'relative', top:'13px'}}>
                                <div className='formfield'><span data-cy='booleanCriteriaComparison' style={{display:'inline-block'}}> EQUALS </span>
                                </div>
                            </div>);
        }
        var errorIcon = "";
        if(this.props.displayError){
            errorIcon = <div style={{marginTop:"18px", marginLeft:"-9px"}} className="validation error tip" title={this.valueErrorMsg}></div>
        }

        return (<div data-cy='CriteriaDetail' style={rowstyle}>
            {grouptoggle}
            {fieldselect}
            {compareselect}
            {compartoinfo}
            {errorIcon}
            {!this.props.isLast ? <div className='formcolumn' style={{width:'40px', padding:'0px', marginLeft: '22px', fontSize:'12px', position:'relative', top:'10px'}}>
                <div className='formfield' style={{lineHeight:'22px', verticalAlign:'middle', color:'#616161'}}>AND</div>
            </div>: null}
            <div data-cy='closeIcon' className={delclass} style={{float:'right', position:'relative', top:'10px'}} onClick={this.handleRemove} />
            {addmore}
        </div>);
    }

    configureComparatorByType(group, field, fieldid, datatype, curvalue) {
        let enums = this.props.lookupEnums(group, field);
        if (enums) {
            let options = [];
            for (let i=0;i<enums.length;i++){
                let f = enums[i];
                options.push(<option key={'c'+group+field+i} value={f.Value}>{f.Label}</option>);
            }

            return (
                <div className='formcolumn' style={{paddingLeft:'0px', width:'200px', position:'relative', top:'10px'}}>
                    <div className='formselectfieldwrapper' style={{width:'200px', marginLeft:'10px', display:'inline-block', top:'4px'}}>
                        <select id={'input'+fieldid} className='formselectfield' 
                            value={this.props.field.value} 
                            onChange={(e) => this.props.fnFieldUpdate('c'+this.props.field.id+':value',e.currentTarget.value)}>
                            {options}
                        </select>
                    </div>
                </div>
            );
        }

        if (datatype.toLowerCase() == 'string' || datatype.toLowerCase() == 'double' || datatype.toLowerCase() == 'int32') {
            return (
                <div className='formcolumn' style={{paddingLeft:'10px', width:'200px', position:'relative', top:'9px'}}>
                    <div className='formfield'>
                        <input id={'input'+fieldid} maxLength='255' type='text'
                            onChange={(e) => this.props.fnFieldUpdate('c'+fieldid+':value',e.currentTarget.value)}
                            onKeyPress={(e) => {this.props.fnEnterKey(e); }}
                            value={curvalue}
                        />
                    </div>
                </div>
            );
        }
        else if (datatype.toLowerCase() == 'boolean') {
            const radiobools = [{ id: 'yes', name: 'Yes' }, { id: 'no', name: 'No' }];
            return (
                <div className='formcolumn' style={{paddingLeft:'35px', fontSize: '12px', width:'125px', position:'relative', top:'12px'}}>
                    <div className='formfield'>
                        <CwWidgets.CwRadioButtonGroup groupName={'crittarg'+fieldid}
                            onChange={(btn) => { 
                                let val = btn.id =='yes' ? true : false;
                                this.props.fnFieldUpdate('c'+fieldid+':value',val);
                                }}
                            radioButtons={radiobools} 
                            value={curvalue==true ? radiobools[0] : radiobools[1]} 
                            columns={2} />
                    </div>
                </div>
            );
        }
    }

    updateFieldGroup(ident,val) {
        if (this.props.fnFieldUpdate) {
            this.props.fnFieldUpdate('c'+this.props.field.id+':fieldgroup',val==0?'quote':'item');
            let newfield = this.props.conformField(val==0?'quote':'item', this.props.field.field);
            this.props.fnFieldUpdate('c'+this.props.field.id+':field',newfield);
        }
    }

    fieldToName(name) {
        let result = this.props.fields.find( (f) => {return f.name.toLowerCase() == name.toLowerCase();} );
        return result ? <b>{result.display}</b> : 'this field';
    }

    handleRemove() {
        if (this.props.isOnly) {
            return;
        }
        if (!this.props.fnRemoveCriteria) {
        }

        let name = this.fieldToName(this.props.field.field);
        let me = this;
        let msg = (<span>Are you sure you want to delete {name} from the criteria list?</span>);
        Dialog.open({
            title: 'Confirm Delete',
            message: msg,
            closeRequiresButton: true,
            linkalign: 'right',
            fastload: true,
            links: [
                {
                    title: 'DELETE', callback: function () {
                        me.props.fnRemoveCriteria(me.props.field.id);
                        Dialog.close();
                    }
                },
                {
                    title: 'CANCEL', callback: function () {
                       Dialog.close();
                    }
                }
            ]
        });
        return false;
    }
    
}

