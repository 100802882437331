/* eslint-disable react/display-name */
/* eslint-disable no-unused-expressions */
import React, { forwardRef } from "react";
import Typography from "@mui/material/Typography";
import { useTreeItem } from "@mui/x-tree-view/TreeItem";
import clsx from "clsx";
import { StyledTreeItem } from "../styledComponents/productSearchCategoryMenu";

const CustomContent = forwardRef((props, ref) => {
	const {
		classes,
		className,
		label,
		nodeId,
		icon: iconProp,
		expansionIcon,
		displayIcon,
		onClick
	} = props;

	const {
		expanded,
		disabled,
		selected,
		focused,
		handleExpansion,
		handleSelection,
		preventSelection
	} = useTreeItem(nodeId);

	const icon = iconProp || expansionIcon || displayIcon;

	const handleMouseDown = (event) => {
		preventSelection(event);
	};

	const handleExpansionClick = (event) => {
		handleExpansion(event);
	};

	const handleSelectionClick = (event) => {
		handleSelection(event);
		onClick();
	};

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			className={clsx(className, classes.root, {
				[classes.expanded]: expanded,
				[classes.selected]: selected,
				[classes.focused]: focused,
				[classes.disabled]: disabled
			})}
			onMouseDown={handleMouseDown}
			ref={ref}
		>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
			<div onClick={handleExpansionClick} className={classes.iconContainer}>
				{icon}
			</div>
			<Typography onClick={handleSelectionClick} component="div" className={classes.label}>
				{label}
			</Typography>
		</div>
	);
});

export default function CustomTreeItem(props) {
	return <StyledTreeItem ContentComponent={CustomContent} {...props} />;
}
