import { Alert, Collapse, Typography } from "@mui/material";
import { warningAlertYellow } from "../../styles";
import { useTheme } from "@mui/material/styles";
import { useIdentifyContext } from "../../../../../../../../context/IdentifyContext";

export const IdentityWarningToast = () => {
	const theme = useTheme();
    const { hasIdentifyWarning, applyUpdatedSettings }  = useIdentifyContext();
	
	return (
		<Collapse in={hasIdentifyWarning} sx={{ marginBottom: "8px" }}>
			<Alert
				severity="warning"
				sx={{
					backgroundColor: theme.palette.background.paper,
					color: theme.palette.text.primary,
					marginTop: "8px",
					borderLeft: `3px solid ${warningAlertYellow}`,
					" & .MuiAlert-message": {
						padding: "7px 0px !important",
						width: "100%"
					}
				}}
				contrast="normal"
			>
				<Typography
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center"
					}}
				>
					<span>
						<span style={{ fontWeight: "bold", marginRight: "5px" }}>Warning!</span>
						<span style={{marginRight: "5px"}}>Changes detected. Refresh for the latest information. </span>
						<Button id="restoreButton" className="save"  onClick={applyUpdatedSettings}>Refresh</Button>
					</span>
				</Typography>
			</Alert>
		</Collapse>
	);
};
