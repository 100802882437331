import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledOutlinedCard = styled(Card)(({sx}) => ({
	border: "1px solid #E4E4E7",
	padding: "8px",
	borderRadius: 8,
	height: "100%",
	boxShadow: "unset",
	...sx
}));

export { StyledOutlinedCard };
