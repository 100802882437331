import { useEffect, useReducer } from "react";
import { SET_CURRENT_QUOTE, SET_CURRENT_USER, SET_GLOBAL_SETTINGS, SET_USER_SETTINGS } from "../actions";
import { createQuoteHeaderInitialState, quoteHeaderReducer } from "../reducers";

export function useQuoteHeader(props= {}) {
	const initialQuoteState = props.quote || app.currentQuote;
	const initialUserState = app.currentUser;
	const initialUserSettingsState = app?.settings?.user;
	const initialGlobalSettingsState = app?.settings?.global;
	const [quoteHeaderState, dispatch] = useReducer(
		quoteHeaderReducer,
		{ initialQuoteState, initialUserState, initialUserSettingsState, initialGlobalSettingsState },
		createQuoteHeaderInitialState
	);
	const { currentQuote, currentUser, userSettings, globalSettings} = quoteHeaderState;

	useEffect(() => {
		dispatch({ type: SET_GLOBAL_SETTINGS, payload: app?.settings?.global });
	}, [
		app.settings.global,
		app.settings.global?.EnableOneTimeTotal,
		app.settings.global?.EnableOneTimeGrossProfit,
		app.settings.global?.EnableRecurring,
		app.settings.global?.EnableRecurringGrossProfit,
		app.settings.global?.EnableTotalQuoteValue,
		app.settings.global?.EnableDownpayment,
	]);

	useEffect(() => {
		dispatch({
			type: SET_CURRENT_QUOTE,
			payload: app.currentQuote
		});
	}, [
		app.currentQuote,
		app.currentQuote.ExpirationDate,
		app.currentQuote.QuoteName,
		app?.currentQuote?.BaseCurrency,
		app?.currentQuote?.GrossMarginAmount,
		app?.currentQuote?.RecurringTotal,
		app?.currentQuote.TotalContractValue,
		app?.currentQuote.OrderPorterAmount,
		app?.currentQuote.MainCust.AccountName,
		app?.currentQuote.MainCust.EmailAddress,
		app?.currentQuote.MainCust.FirstName,
		app?.currentQuote.MainCust.LastName,
		app?.currentQuote.MainCust.DayPhone,
		app?.currentQuote.MainCust,
		app?.currentQuote.Owner,
		app?.currentQuote.Owner?.DisplyName,
		app.currentQuote.AllTax,
		app.currentQuote.QuoteTotal,
		app.currentQuote.Subtotal,
		app.currentQuote.InsideRep,
		app.currentQuote.InsideRep?.DisplyName,
		app.currentQuote.OtherUsers,
		app.currentQuote.IsArchive,
		app.currentQuote.QuoteStatus,
		app.currentQuote.IsLocked,
		app.currentQuote.ApprovalStatus,
		app.currentQuote.UseStandardPublishOutput,
		app?.currentQuote?.RecurringGrossMarginAmount,
		app?.currentQuote?.RecurringSubtotal,
		app?.currentQuote?.RecurringAllTax,
		app?.currentQuote?.RecurringCost,
		app?.currentQuote?.SuggestedDiscountAmount,
		app?.currentQuote?.PrintedGrossMargin,
		app?.currentQuote?.PrintedMarkup,
		app?.currentQuote?.RecurringSuggestedDiscountAmount,
		app?.currentQuote?.PrintedRecurringGrossMargin,
		app?.currentQuote?.PrintedRecurringMarkup,
	]);
	
	useEffect(() => {
		dispatch({ type: SET_CURRENT_USER, payload: app.currentUser });
	}, [app.currentUser, app?.currentUser?.IsReadOnly]);

	useEffect(() => {
		dispatch({ type: SET_USER_SETTINGS, payload: app?.settings?.user });
	}, [
		app.settings.user,
		app?.settings?.user?.documentPreviewDisabled,
		app?.settings?.user?.DocumentPreviewVisible,
		app?.settings?.user?.dateFormat
	]);

	return { dispatch, currentQuote, currentUser, userSettings, globalSettings };
}
