import { createContext, useContext, useEffect, useState } from "react";

const UserContext = createContext();

const UserProvider = ({ user, children }) => {
	const [state, setState] = useState(user);
	useEffect(() => {
		setState(user);
	}, [user]);
	return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

const useUserContext = () => {
	const user = useContext(UserContext);
	if (user === undefined) {
		console.error("useUserContext must be used inside UserProvider");
	}
	return user;
};

export { UserProvider, useUserContext };
