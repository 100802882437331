quosal.api.script = {};


quosal.api.script.getScripts = function (scriptType, includeInactive) {
    return quosal.api.call('Script.GetScripts', {
        scriptType: scriptType,
        includeInactive: includeInactive
    });
};


quosal.api.script.runScript = function (scriptType, idQuosalSellScript, idQuoteMain, idQuoteTabs, idQuoteItems) {
    return quosal.api.call('Script.RunScript', {
        scriptType: scriptType,
        idQuosalSellScript: idQuosalSellScript,
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        idQuoteItems: idQuoteItems
    });
};