import { PublishSettings } from "../../classes/quote/newUI/publishSettings";

 
export default function publishSettings() {
    if(!app.currentQuote){
        return;
    }
 
    quosal.ui.react.render(
        <div> <PublishSettings/> </div>
        , document.getElementById('quoteModule'));
        $('#pageName').val('quote.audit_trail');
};