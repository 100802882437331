import { QuoteDeliver } from "js/jsx/src/classes/quote/deliver.jsx";

export default function deliver() {
    if (!app.currentQuote) {
        return;
    }
	const publishAndDeliverModule = quosal.sell.modules.find("quote.publish");

    quosal.ui.react.render(
        <FormPlaceholder message="Loading Quote Deliver..." />
        , document.getElementById('quoteDeliverContainer'));

    var renderDeliverPage = function (renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;

        renderMethod(<QuoteDeliver quote = {app.currentQuote} />, document.getElementById('quoteDeliverContainer'));
    };

    var loadDeliverPage = function () {
        renderDeliverPage(quosal.ui.react.render);

        $('#pageName').val('quote.deliver');
    };

    const updateDeliverPage = function() {
        renderDeliverPage();

        $('#pageName').val('quote.deliver');
    }
    var unbindEvents = function () {
        quosal.navigation.onNavigate.unbind(unbindEvents);
    };

    quosal.navigation.onNavigate.bind(unbindEvents);

    const unloadQuote = function () {
        quosal.sell.quote.onUpdate.unbind(updateDeliverPage);
        publishAndDeliverModule.onBeforeUnload.unbind(unloadQuote);
    };

    publishAndDeliverModule.onBeforeUnload.bind(unloadQuote);
    quosal.sell.quote.onUpdate.unbind(updateDeliverPage).bind(updateDeliverPage);

    loadDeliverPage();
}