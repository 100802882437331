import React, { useState, useEffect } from "react";
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function Notification({ id, isOpen, title, message, type }) {
	const [open, setOpen] = useState(isOpen);

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen, id]);

	return (
		<Snackbar
			key={id}
			open={open}
			autoHideDuration={6000}
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			onClose={() => setOpen(!open)}
		>
			<Alert
				onClose={() => setOpen(!open)}
				severity={type}
				iconMapping={{
					success: <CheckCircleOutlineIcon fontSize="inherit" />
				}}
				sx={{
					mb: 5,
					width: "100%",
					borderLeft: 3,
					borderColor: `${type}.main`
				}}
			>
				<AlertTitle sx={{ color: "inherit" }}> {title}</AlertTitle>
				{message}
			</Alert>
		</Snackbar>
	);
}

export default Notification;
