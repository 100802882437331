import React from "react";

import Popover from "@mui/material/Popover";
import { PricingHelperDialog } from "../priceModifierHelper";
import { useEditorContext } from "./context/editorContext";

function PriceModifierDialog({ id, open, anchorEl, onClose, setChange, modalData }) {
	const { isDisabledQuoteActions } = useEditorContext();
	return (
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center"
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center"
			}}
		>
			<PricingHelperDialog
				priceModifier={modalData.priceModifier}
				itemPrice={modalData.itemPrice}
				itemCost={modalData.itemCost}
				tab={null}
				itemSuggestedPrice={modalData.itemSuggestedPrice}
				fieldName={modalData.fieldName}
				priceModifierDialogSave={modalData.priceModifierDialogSave}
				togglePricingHelper={modalData.togglePricingHelper}
				advancedRoundingEnabled={modalData.advancedRoundingEnabled}
				itemBasePrice={modalData.itemBasePrice}
				priceModifierLevel={modalData.priceModifierLevel}
				quotePriceModifier={modalData.quotePriceModifier}
				cellStyle={modalData.cellStyle}
				onClose={onClose}
				setChanges={setChange}
				isDisabled={isDisabledQuoteActions || modalData.isDisabled}
				isGrid
			/>
		</Popover>
	);
}

export default PriceModifierDialog;
