import {AddOpportunityItems} from "js/jsx/src/classes/crm/addOpportunityItems.jsx";

export class OpportunitySearchTable  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearching: false,
        };
        this.attachOpportunity = this.attachOpportunity.bind(this);
        this.openAttachOpportunityDialog = this.openAttachOpportunityDialog.bind(this);
        this.getDisplayHeaders = this.getDisplayHeaders.bind(this);
        this.displayPager = this.displayPager.bind(this);
        this.displayOpportunities = this.displayOpportunities.bind(this);
    }
    attachOpportunity(opportunity) {
        var opportunityId = opportunity.Id || opportunity.OpportunityId;
        var opp = opportunity;
        var useQuoteTotals = opportunity.UseQuoteTotals;
        var accountId = opportunity.AccountID;
        var contactId = opportunity.ContactID;

        if (useQuoteTotals) {
            var url = quosal.util.url('autotaskattachquote.quosalweb', "contactid=" + contactId, "opportunityid=" + opportunityId, 'accountid=' + accountId)
            quosal.navigation.navigate(url)
        } else {
            var attachOpp = quosal.api.crm.attachOpportunity(app.currentQuote.IdQuoteMain, opportunityId);
            var me = this;
            quosal.ui.showMask(1);
            this.setState({isAttaching: true});
            attachOpp.finished = function (msg) {
                quosal.sell.quote.update(msg.quote);
                if (msg.opportunity.hasItems || quosal.util.getCrmShortName() == "dynamics") {
                    me.openAttachOpportunityDialog(msg.opportunity, opportunityId)
                }
                else {
                    this.redirectToNextLocation();
                }
            }.bind(this);
            attachOpp.call();
            this.setState({isAttaching: true});
        }
    }
    redirectToNextLocation() {
        quosal.ui.hideMask(true);
        var moduleName = 'quote.customer';
        if (app.currentQuote.StatusFlags.HasCustomer) {
            moduleName = 'quote.home';
        }
        app.currentModule.loadSubModule(moduleName, {
            container: 'quoteModule',
            unloadSubModules: true
        });
    }
    openAttachOpportunityDialog(opportunity, opportunityId) {
        var oppId = opportunity.Id || opportunity.OpportunityId || opportunityId;
        var getOppItemApi = quosal.api.crm.getOpportunityItems(oppId);
        var me = this;
        getOppItemApi.finished = function(msg) {
            me.setState({isAttaching: false});
            quosal.ui.hideMask(true);
            if (!msg.items || msg.items.length == 0) {
                me.redirectToNextLocation();
                return;
            }
           AddOpportunityItems.open(oppId, msg.items);
        }
        getOppItemApi.call()
    }
    redirectToQuoteHome() {
        quosal.navigation.navigate(quosal.util.url('quote.dashboard', 'submodules=quote.home'));
    }
    getDisplayHeaders() {
        return this.props.headerNames.map(function(headerObj, index) {
            return <th key={"header_"+index} width={headerObj.width} className="header">{headerObj.name}</th>
        })
    }
    getAddOppHeaders() {

    }
    displayPager() {
        var me = this;
        return this.props.pager.map(function(p, index) {
            var pager = JSON.parse(p);
            var first = pager.PageNumber * pager.PageSize + 1
            var last = first + pager.ResultCount - 1;
            return(
                <div key="pager" className="paging">
                    {pager.PageNumber > 0 && <div onClick={()=> me.props.submitClick(-1)} className="prev"> Previous Page </div>}
                    <div className="resultCount">Viewing results {first} - {last}</div>
                    {pager.ResultCount >= pager.PageSize  && <div onClick={()=> me.props.submitClick(1)} className="next"> Next Page </div>}
                </div>
            )
        })
    }
    displayOpportunities(parentRef) {
        if (this.props.opportunities) {
            var me = this;
            if (!this.props.opportunities) {
                return;
            }
            return this.props.opportunities.map(function(opp, idx) {
                var _me = me;
                var columns = _me.props.headerNames.map(function(header, index) {
                    var opportunity = JSON.parse(opp);
                    if (header.url) {
                        return <td width="50" key={header.propName+index} className="content left"><center><a href="#" onClick={me.attachOpportunity.bind(me, opportunity)}>Attach</a></center></td>
                    }
                    else {
                        if (header.formatMoney) {
                            opportunity[header.propName] = accounting.formatMoney(opportunity[header.propName]);
                        }
                        return <td key={header.name+index} width={header.width} className="center content"><center>{opportunity[header.propName]}</center></td>
                    }
                })
                return(
                <tr key={"opportunitykey_"+idx}>
                    {columns}
                </tr>
                )
            })
        }
    }
    render() {
        var spinnerArgs = {
            lines: 12,
            length: 12,
            width: 6,
            radius: 20
        };
        return (
            <div className="grid col4x3">
            {this.state.isAttaching && <div style={{display: "flex", flex: 1, justifyContent: 'center', alignItems: 'center'}}><Spinner args={spinnerArgs} title="Attaching..." /></div>}
                <div>
                    <div className="panel paging" help="opportunity-search-results">
                        <div className="title">
                            <span>Opportunities</span>
                            {this.props.pager && this.displayPager()}
                        </div>   
                        <div className="content">
                            <center>
                            {this.props.isSearching && <FormPlaceholder message="Searching..." />}
                            {!this.props.isSearching && !this.state.isAttaching &&
                                <table cellPadding="0" cellSpacing="0" className="datagrid nosort">
                                <thead>
                                <tr>
                                    {this.getDisplayHeaders()}
                                </tr>
                                </thead>
                                <tbody>
                                    {this.displayOpportunities()}
                                </tbody>
                                </table>
                            }
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}