import {CustomizerFieldListItem} from "js/jsx/src/classes/forms/customization.jsx";
import {LineItemQuickFilterHeaderInput, LineItemQuickFilterMessage} from "js/jsx/src/classes/quote/lineItemQuickFilter.jsx";
import {PriceModifierHelper} from "js/jsx/src/classes/quote/priceModifierHelper.jsx";
import {EditCustomField} from "js/jsx/src/classes/editCustomFields.jsx";
import {FormFieldInput} from "js/jsx/src/classes/forms.jsx";
import {ConfigMenu, ActionsMenu} from "js/jsx/src/classes/menus.jsx";
import {SideScroller} from "js/jsx/src/classes/sideScroller.jsx";

export class DataGrid extends React.Component {
    static addGrid(grid) {
        if (!DataGrid.activeGrids[grid.gridType]) {
            DataGrid.activeGrids[grid.gridType] = [];
        }

        DataGrid.activeGrids[grid.gridType][grid.gridId] = grid;
    }
    static removeGrid(grid) {
        if (DataGrid.activeGrids[grid.gridType]) {
            delete DataGrid.activeGrids[grid.gridType][grid.gridId];

            if (Object.keys(DataGrid.activeGrids[grid.gridType]).length == 0) {
                delete DataGrid.activeGrids[grid.gridType];
            }
        }
    }
    static getGrids(gridType) {
        if (!gridType) {
            gridType = 'DataGrid';
        }

        if (DataGrid.activeGrids[gridType]) {
            return DataGrid.activeGrids[gridType];
        }
        else {
            return [];
        }
    }
    static updateGrids(gridType, handler, exclude) {
        if (!handler) {
            return;
        }
        if (!gridType) {
            gridType = 'DataGrid';
        }
        if (!DataGrid.activeGrids[gridType]) {
            return;
        }

        for(var i in DataGrid.activeGrids[gridType]) {
            var otherGrid = DataGrid.activeGrids[gridType][i];

            if (typeof otherGrid != 'object') {
                continue;
            }
            if (gridType && otherGrid.gridType != gridType) {
                continue;
            }
            else if (exclude && exclude.where((g) => g.gridId == otherGrid.gridId).length > 0) {
                continue;
            }
            else {
                handler(otherGrid);
            }
        }
    }
    static clearGrids(gridType) {
        if (gridType) {
            delete DataGrid.activeGrids[gridType];
        }
        else {
            DataGrid.activeGrids = {};
        }
    }
    static getInitialLineItemQuickFilterData() {return {inputs: []}}       

    constructor(props) {
        super(props);

        var quickFilter = (this.props.quoteTab && this.props.quoteTab.ShowLineItemQuickFilter === true)
            ? DataGrid.getInitialLineItemQuickFilterData()
            : null;
        var userCanModifyProtectedItem= quosal.util.userCanModifyProtectedItem();
        
        this.state = {   
            editMode: this.props.editMode || false, 
            isRowSelected: [], 
            cutRows: [], 
            copiedRows: [], 
            gridConfigVisible: false, 
            lineItemQuickFilter: quickFilter,
            userCanModifyProtectedItem: userCanModifyProtectedItem,
        };        

        // This binding is necessary to make `this` work in the callback
        this.initializeQuickFilter = this.initializeQuickFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.isCustomizable = this.isCustomizable.bind(this);
        this.selectedShowingRows = this.selectedShowingRows.bind(this);
        this.evaluateRowVisibility = this.evaluateRowVisibility.bind(this);
        this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
        this.editMode = this.editMode.bind(this);
        this.addRows = this.addRows.bind(this);
        this.deleteRows = this.deleteRows.bind(this);
        this.copyOrCutRows = this.copyOrCutRows.bind(this);
        this.cutRows = this.cutRows.bind(this);
        this.copyRows = this.copyRows.bind(this);
        this.pasteRows = this.pasteRows.bind(this);
        this.selectRows = this.selectRows.bind(this);
        this.selectAllRows = this.selectAllRows.bind(this);
        this.handleRowSelectionChange = this.handleRowSelectionChange.bind(this);
        this.selectedRowsChanged = this.selectedRowsChanged.bind(this);
        this.menuItemSelected = this.menuItemSelected.bind(this);
        this.createRow = this.createRow.bind(this);
        this.fieldSelected = this.fieldSelected.bind(this);
        this.getAggregatesForDisplay = this.getAggregatesForDisplay.bind(this);
        this.setScreenFillingHeight = this.setScreenFillingHeight.bind(this);
        this.setScreenFillingHeightWithDelay = this.setScreenFillingHeightWithDelay.bind(this);
        this.selectAllExportRows = this.selectAllExportRows.bind(this);
        // FP 3/24/16 - This is state-like information, but it is just memoized calculation from the official state that determines the hiddenness of rows.
        // That information would be the values in this.state.lineItemQuickFilter.inputs and the contents each row. This is calculated and saved each time render runs.
        // Because it is dependent on more complex but official state, DataGrid never needs to rerender when this changes--this only changes in response to state changes--so it does not have to be part of state.
        // The value at isRowHidden[i] should be true if the row at index i is hidden, and undefined otherwise.
        this.isRowHidden = [];
        // Unlike isRowHidden, which is potentially sparse, rowsThatAreHidden will only contain a list of indexes, representing all the indexes of rows that are hidden. It is a list of indexes, rather than an array of booleans.
        this.rowsThatAreHidden = [];
        this.resizeTimeoutId = null;
    }

    initializeQuickFilter(isTurningOn) {
        isTurningOn = (isTurningOn !== false) && (isTurningOn === true || (this.props.quoteTab && this.props.quoteTab.ShowLineItemQuickFilter === true));
        this.state.lineItemQuickFilter = isTurningOn
            ? DataGrid.getInitialLineItemQuickFilterData()
            : null;
    }
    clearFilters() {
        this.state.lineItemQuickFilter.inputs = [];
        this.forceUpdate();
    }
    isCustomizable() {
        return this.props.customizable && (app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer);
    }
    listOfSelectedRows(onlyVisibleRows) {
        var result = [];
        var keys = Object.keys(this.state.isRowSelected);
        //maybe put something here so that it never includes indices that are >= the number of rows
        for (var i = 0; i < keys.length; i++ ) {
            var intKey = parseInt(keys[i]);
            if (!isNaN(intKey)
                && this.state.isRowSelected[intKey] === true
                && !(onlyVisibleRows === true && this.isRowHidden[intKey])) {
                result.push(intKey);
            }
        }
        return result;
    }
    selectedShowingRows() {
        return this.listOfSelectedRows(true);
    }
    evaluateRowVisibility() {
        var isRowHidden = [];
        var rowsThatAreHidden = [];
        var quickFilterInputs = this.state.lineItemQuickFilter && this.state.lineItemQuickFilter.inputs;
        if (this.props.rows) {
            for (var rowIndex = 0; rowIndex < this.props.rows.length; rowIndex++) {
                if (this.props.rows[rowIndex].IsHiddenItem && !quosal.util.userIsAdminOrMaintainer()) {
                    isRowHidden[rowIndex] = true;
                    rowsThatAreHidden.push(rowIndex);
                }
            }
        }
        if (this.props.rows && quickFilterInputs) {
            loopThroughRows: for (var rowIndex = 0; rowIndex < this.props.rows.length; rowIndex++) {
                // The -1 index is for the row selection checkbox column. It's not an official array index,
                // but it plays nice with incrementing so we don't have to repeat the loop body for this special case.
                for (var headerIndex = -1; headerIndex < this.props.headers.length; headerIndex++) {
                    var thisFilter = quickFilterInputs[headerIndex]
                    if (thisFilter && thisFilter.value) {
                        var value = headerIndex === -1
                            ? (this.state.isRowSelected[rowIndex] ? true : false)
                            : this.props.rows[rowIndex][this.props.headers[headerIndex].FieldName];
                        var cellValue = value;
                        var filterValue = thisFilter.value.trim();
                        var filterType = thisFilter.type;
                        var valid = true;

                        if (filterType === 'boolean') {
                            if (cellValue === true && filterValue === 'unchecked'
                                || cellValue === false && filterValue === 'checked') {
                                valid = false;
                            }
                        } else if (filterType === 'numeric') {
                            var numeralsAndDecimals = function (string, comma) {
                                if (comma) {
                                    string = string.replace('.', '').replace(',', '.');
                                }

                                string = string.replace(/[^0-9.()\-]/g, '');
                                if (string.indexOf('(') == 0 && string.indexOf(')') == string.length - 1) {
                                    string = '-' + string;
                                }
                                string = string.replace(/[()]/g, '');
                                return string;
                            }
                            var filterValueJustTheNumber = numeralsAndDecimals(filterValue); //TODO internationalization
                            var filterOperator = thisFilter.operator;
                            if (filterOperator === 'equal') {
                                valid = (cellValue == filterValueJustTheNumber);
                            } else if (filterOperator === 'lt-equal') {
                                valid = (cellValue <= filterValueJustTheNumber);
                            } else if (filterOperator === 'gt-equal') {
                                valid = (cellValue >= filterValueJustTheNumber);
                            } else if (filterOperator === 'approx-equal') {
                                var ratio = cellValue / filterValueJustTheNumber;
                                if (ratio > 1) {
                                    valid = ratio <= (4 / 3);
                                } else {
                                    valid = ratio >= (3 / 4);
                                }
                            } else {
                                var formattedCellNumber = app.currentQuote.formatCurrency(cellValue);
                                //If they enter just numerals (and possibly a decimal point), then ignore formatting characters (e.g. currency sign, thousand separator) in the cell value
                                if (parseFloat(filterValue) == parseFloat(filterValueJustTheNumber)) {
                                    formattedCellNumber = numeralsAndDecimals(formattedCellNumber); //TODO internationalization
                                }
                                valid = (formattedCellNumber.indexOf(filterValue) >= 0);
                            }
                        } else if (filterType === 'text') {
                            filterValue = filterValue.trim().toLowerCase();
                            var preparedValue = cellValue.trim().toLowerCase();
                            if (preparedValue.indexOf(filterValue) < 0) {
                                valid = false;
                            }
                        }

                        if (!valid) {
                            isRowHidden[rowIndex] = true;
                            rowsThatAreHidden.push(rowIndex);
                            continue loopThroughRows;
                        }
                    }
                }
            } //loopThroughRows
        }
        this.isRowHidden = isRowHidden;
        this.rowsThatAreHidden = rowsThatAreHidden
    }
    UNSAFE_componentWillMount() {
        //transfer props.rows to props.rows
        //delete this.props['rows'];

        this.gridType = this.props.name || 'DataGrid';
        this.gridId = this.gridType + '_' + quosal.util.generateGuid();
        this.componentIsMounted = true;
    }
    componentDidMount() {
        $.quosal.ui.datagrid(this.refs.table);
        //TODO: sorting a row that is cut or copied leads to weird styled helper and no sort feedback
        var me = this;
        if (this.props.sortable) {
            var tbody = $(this.refs.tbody);
            tbody.data('grid', this);

            tbody.sortable({
                axis: 'y',
                cancel: ".disable-sort",
                handle: '.row-dragger',
                helper: function(e, element) {
                    var c = element.clone();
                    c.removeAttr('data-reactid'); //react doesn't like duplicate data-reactid's. not one bit.
                    c.find('*').removeAttr('data-reactid');
                    return c;
                },
                opacity:.7,
                out: function(e, ui) {
                    //TODO: drag/drop hook
                    //$(ui.item.parents('tbody').first()).sortable("cancel");
                },
                start: function (e, ui) {
                    var grid = ui.item.parents('tbody').first().data('grid');
                    grid.sortStart = ui.item.index();
                    var row = grid.props.rows[grid.sortStart];

                    if (grid.state.copiedRows.length > 0 || grid.state.cutRows.length > 0) {
                        grid.setState({cutRows:[], copiedRows:[]});
                    }

                    if (row.childRows) {
                        for(var i = 0; i < row.childRows.length; i++) {
                            var childRow = $('tr#' + row.childRows[i]);
                            childRow.hide();
                        }

                        ui.helper.append('<div class="row-drag-details">+ ' + row.childRows.length + ' detail rows</div>');
                    }
                },
                stop: function (e, ui) {
                    var grid = ui.item.parents('tbody').first().data('grid');
                    var sortStart = grid.sortStart;
                    var sortEnd = ui.item.index();

                    delete grid['sortStart'];

                    //cancel the sort operation or react DOM will become out of sync, do the sorting in react below
                    $(ui.item.parents('tbody').first()).sortable('cancel');

                    //remember which rows were selected
                    var selected = [];
                    var rows = grid.props.rows.clone();

                    var listOfSelectedRows = grid.listOfSelectedRows();
                    for(var i = 0; i < listOfSelectedRows.length; i++) {
                        selected[rows[listOfSelectedRows[i]].id] = true;
                    }

                    //sort the underlying rows array
                    var row = rows.splice(sortStart, 1)[0];
                    rows.splice(sortEnd, 0, row);

                    //check for any child rows that need to be moved (for package headers, etc.)
                    var children = [];
                    if (row.childRows) {
                        for(var cr = 0; cr < row.childRows.length; cr++) {
                            for(var r = 0; r < rows.length; r++) {
                                if (rows[r].id == row.childRows[cr]) {
                                    var removed = rows.splice(r, 1)[0];
                                    children.push(removed);
                                   // grid.props.rows.splice(sortEnd, 0, removed);

                                    var childRow = $('tr#' + row.childRows[cr]);
                                    childRow.show();
                                    break;
                                }
                            }
                        }

                        var childStart = rows.findIndex((q) => {
                            return q.id == row.id;
                        }) + 1;

                        for(var i = 0; i < children.length; i++) {
                            rows.splice(childStart + i, 0, children[i]);
                        }
                    }

                    //re-select previously selected rows
                    grid.state.isRowSelected = [];
                    for(var i = 0; i < rows.length; i++) {
                        if (selected[rows[i].id] === true) {
                            grid.state.isRowSelected[i] = true;
                        }
                    }

                    //re-render
                    me.selectedRowIndexs = row.IdQuoteItems;
                    grid.props.contentGrid.shouldComponentUpdateCheck = true;
                    grid.setState({ isRowSelected: grid.state.isRowSelected }, () => {
                        //don't trigger the sort event unless something changed
                        if (sortStart == sortEnd) {
                            return;
                        }

                        //notify outer scope of sort event
                        if (grid.props.onSort) {
                            grid.props.onSort(grid, row, sortStart, sortEnd, rows);
                        }
                        grid.props.contentGrid.shouldComponentUpdateCheck = false;
                        me.selectedRowIndexs = null;
                    });                  
                }
            });
        } 
        if (this.rowsThatAreHidden.length > 0) {
            var tbody = $(this.refs.tbody);
            tbody.addClass("disable-sort");
        }

        DataGrid.addGrid(this);

        if (this.props.useFixedWidths) {
            this.setScreenFillingHeightWithDelay(1);
            $(window).on('resize', this.setScreenFillingHeightWithDelay);
        }

        if (this.props.onLoad) {
            this.props.onLoad(this);
        }
    }
    componentWillUnmount() {
        if (this.props.useFixedWidths) {
            $(window).off('resize', this.setScreenFillingHeightWithDelay);
        }
        DataGrid.removeGrid(this);
        this.componentIsMounted = false;
    }
    shouldComponentUpdate(){
        try{
            if (this.props.contentGrid){
                return this.props.contentGrid.refs.tabControl.getSelectedTab().tabId == this.props.quoteTab.IdQuoteTabs;
            } else {
                return true;
            }
        } catch(e){
            return true;
        }
    }
    editMode(enabled) {
        this.setState({ editMode: enabled || !this.state.editMode });
    }
    addRows(rows, index) {
        if (!rows.length) {
            rows = [rows];
        }
        if (isNaN(index)) {
            index = rows.length;
        }

        this.props.rows.splice(index, 0, ...rows);

        this.state.isRowSelected.splice(index, 0, ...new Array(rows.length));
        for (var i = 0; i < rows.length; i++) {
            delete this.state.isRowSelected[index + i];
        }

        for (var i = 0; i < this.state.copiedRows.length; i++) {
            if (this.state.copiedRows[i] >= index) {
                this.state.copiedRows[i] += rows.length;
            }
        }
        this.setState({ rows: this.props.rows, isRowSelected: this.state.isRowSelected, copiedRows: this.state.copiedRows });
    }
    deleteRows(selectedOrderedRows) {
        if (!selectedOrderedRows) {
            selectedOrderedRows = this.selectedShowingRows();
        }

        var adjustArrayContents = function (array, numberToRemove) {
            if (array && array.length && typeof numberToRemove === 'number') {
                for (var i = array.length - 1; i >= 0; i--) {
                    if (array[i] == numberToRemove) {
                        array.splice(i, 1);
                    } else if (array[i] > numberToRemove) {
                        array[i]--;
                    }
                }
            }
        }

        var deletedRows = [];
        // iterate in reverse because there's removal in the loop
        for (var i = selectedOrderedRows.length - 1; i >= 0; i--) {
            var rowIndex = selectedOrderedRows[i];
            if (this.state.isRowSelected[rowIndex]) {
                this.state.isRowSelected.splice(rowIndex, 1);
            }

            adjustArrayContents(this.state.copiedRows, rowIndex);
            adjustArrayContents(this.state.cutRows, rowIndex);

            deletedRows.push(this.props.rows[rowIndex]);
        }

        this.setState({
            isRowSelected: this.state.isRowSelected,
            copiedRows: this.state.copiedRows,
            cutRows: this.state.cutRows
        });

        return deletedRows;
    }
    copyOrCutRows(isCopy, selectedRows, callback) {
        var stateToSet = isCopy ? 'copiedRows' : 'cutRows';
        var theOtherOne = isCopy ? 'cutRows' : 'copiedRows';

        if (!selectedRows) {
            selectedRows = this.selectedShowingRows();
        }
        var haveProtected = false;
        if (!this.state.userCanModifyProtectedItem) {
            for (var i=selectedRows.length-1; i >= 0; i--) {
                if (this.props.rows[selectedRows[i]].IsProtectedItem) {
                    haveProtected = true;
                    selectedRows.splice(i,1);
                }
            }
        }

        if (selectedRows.length === 0 && this.state[stateToSet].length === 0) {
            Dialog.open({
                title: 'No items selected',
                message: (haveProtected ? 'You must select one or more unprotected items for this operation.' : 'You must select one or more items for this operation.'),
                links: [Dialog.links.ok]
            });
            if (typeof callback === 'function') {
                callback();
            }
            return;
        }

        DataGrid.updateGrids(this.gridType, (g) => {
            g.setState({ cutRows: [], copiedRows: [] });
        }, [this]);

        this.selectRows([], {changeStateNowInsteadOfSetState: true});

        var stateObject = {};
        stateObject[stateToSet] = selectedRows;
        stateObject[theOtherOne] = [];
        if (typeof callback === 'function') {
            this.setState(stateObject, callback);
        } else {
            this.setState(stateObject);
        }
    }
    cutRows(selectedRows, callback) {
        this.copyOrCutRows(false, selectedRows, callback);
    }
    copyRows(selectedRows, callback) {
        this.copyOrCutRows(true, selectedRows, callback);
    }
    pasteRows(callback) {
        var movedRows = [];
        var newRows = [];
        var fromGrid = null;
        var grids = DataGrid.getGrids(this.gridType);

        //insert rows before the first checkbox that isn't one of the copied/cut rows.
        var insertIndex = Infinity;
        var selectedRows = this.selectedShowingRows();
        if (selectedRows.length > 0) {
            for(var i = 0; i < selectedRows.length; i++) {
                if (selectedRows[i] < insertIndex
                    && this.state.cutRows.indexOf(selectedRows[i]) < 0
                    && this.state.copiedRows.indexOf(selectedRows[i]) < 0) {
                        insertIndex = selectedRows[i];
                }
            }
        }
        if (insertIndex > this.props.rows.length) {
            insertIndex = this.props.rows.length;
        }

        for(var gId in grids) {
            var grid = grids[gId];

            if (!grid.state) {
                continue;
            }

            if (grid.state.cutRows.length > 0 || grid.state.copiedRows.length > 0) {
                fromGrid = grid;

                if (grid.state.cutRows.length > 0) {
                    for (var i = 0; i < grid.state.cutRows.length; i++) {
                        var rowBeingMoved = grid.props.rows[grid.state.cutRows[i]];
                        if (rowBeingMoved.IsPackageHeader) {
                            movedRows.push(rowBeingMoved);
                            var componentRows = grid.props.rows.where((r) => ( r.ParentQuoteItem === rowBeingMoved.IdQuoteItems));
                            if (componentRows) {
                                for (var j = 0; j < componentRows.length; j++)
                                {
                                    movedRows.push(componentRows[j]);
                                }
                            }                            
                        }
                        else {
                            movedRows.push(rowBeingMoved);
                        }
                    }

                    if (!callback) {
                        for (var i = 0; i < grid.state.cutRows.length; i++) {
                            this.props.rows.push(grid.props.rows[grid.state.cutRows[i]]);
                        }

                        for (var i = 0; i < movedRows.length; i++) {
                            var r = grid.props.rows.findIndex((g) => {
                                return g.gridId == movedRows[i].gridId;
                            });

                            if (r >= 0) {
                                grid.props.rows.splice(r, 1);
                            }
                        }
                    }
                    grid.selectRows([], {changeStateNowInsteadOfSetState: true});
                    grid.state.cutRows = [];
                    if (grid !== this) {
                        this.selectRows([], {changeStateNowInsteadOfSetState: true});
                    }
                } else if (grid.state.copiedRows.length > 0) {
                    grid.state.copiedRows.sort();
                    for(var i = 0; i < grid.state.copiedRows.length; i++) {
                        if (!callback) {
                            this.props.rows.push(grid.props.rows[grid.state.copiedRows[i]]);
                        }
                        newRows.push(grid.props.rows[grid.state.copiedRows[i]]);
                        // FSP 4/12/16: If we decide that we want to keep checked rows (besides the copied rows) checked, we'll need this
                        //if (!callback) {
                        //    this.state.isRowSelected.splice(insertIndex, 0, undefined);
                        //    delete this.state.isRowSelected[insertIndex];
                        //}
                    }

                    grid.selectRows([], {changeStateNowInsteadOfSetState: true});
                    if (grid !== this) {
                        this.selectRows([], {changeStateNowInsteadOfSetState: true});
                    }
                }

                if (!callback) {
                    this.setState({ rows: grid.props.rows });
                }

                break;
            }
        }

        //newRows come from copy-pasting. movedRows come from cut-pasting.
        if (callback) {
            callback(fromGrid, this, newRows, movedRows, insertIndex);
        }
    }
    selectRows(isRowSelected, params) {
        if (!params) {
            params = {};
        }
        //calling with no argument selects all non-hidden rows.
        var selectAll = !isRowSelected;
        if (selectAll) {
            isRowSelected = [];
        }

        if (this.props.rows) {
            for (var i = 0; i < this.props.rows.length; i++) {
                if (this.isRowHidden[i]) {
                    //protect hidden rows from from change
                    if (this.state.isRowSelected[i]){
                        isRowSelected[i] = true;
                    } else {
                        delete isRowSelected[i];
                    }
                } else if (selectAll) {
                    //select all showing rows
                    isRowSelected[i] = true;
                }
            }
        }

        if (params.changeStateNowInsteadOfSetState === true) {
            this.state.isRowSelected = isRowSelected;
            this.selectedRowsChanged({changeStateNowInsteadOfSetState: true});
        } else {
            this.setState({isRowSelected: isRowSelected}, this.selectedRowsChanged);
        }
    }
    //for the checkall button in the grid filter
    selectAllRows(event){
        if (event.target.checked) {
            this.selectRows();
        } else {
            this.selectRows([]);
        }
    }
    selectAllExportRows(event) {
        if (this.props.rows) {
            for (var i = 0; i < this.props.rows.length; i++) {
                this.props.rows[i].IsSelected = event.target.checked;
            }
            this.setState({ isRowSelected: true });
            this.props?.selectAllExportRowsCallback?.(event.target.checked)
        }
    }
    //clearSelection: function(rows) {
    //    if(rows) {
    //        for(var i = 0; i < rows; i++) {
    //            delete this.state.isRowSelected[i];
    //        }
    //    } else {
    //        this.setState({ isRowSelected: [] });
    //    }
    //},
    handleRowSelectionChange(rowNumber, e) { //triggered when the user toggles a single row checkbox
        var isRowSelected = this.state.isRowSelected;

        var doSingleCheckboxChange = function () {
            if (!e.currentTarget.checked && isRowSelected[rowNumber]) { // unchecking
                delete isRowSelected[rowNumber];
                // This is the only way lastRowCheckedForShiftClick resets--when you directly uncheck that row.
                if (this.lastRowCheckedForShiftClick === rowNumber) {
                    this.lastRowCheckedForShiftClick = null;
                }
            } else if (e.currentTarget.checked && !isRowSelected[rowNumber]) { // checking
                isRowSelected[rowNumber] = true;
                this.lastRowCheckedForShiftClick = rowNumber;
            }
            this.props.contentGrid.shouldComponentUpdateCheck = true;
            this.setState({ isRowSelected: isRowSelected }, this.selectedRowsChanged);
            
        }.bind(this);

        if (e.nativeEvent.shiftKey) {
            if ('number' === typeof this.lastRowCheckedForShiftClick) {
                var high, low;
                if (this.lastRowCheckedForShiftClick > rowNumber) {
                    high = this.lastRowCheckedForShiftClick;
                    low = rowNumber;
                } else {
                    high = rowNumber;
                    low = this.lastRowCheckedForShiftClick;
                }
                var isRowSelectedCopy = isRowSelected.slice();
                for (var i = low; i <= high; i++) {
                    isRowSelectedCopy[i] = true;
                }
                this.lastRowCheckedForShiftClick = rowNumber;
                this.selectRows(isRowSelectedCopy);
            } else {
                doSingleCheckboxChange();
            }
        } else {
            doSingleCheckboxChange();
        }
    }
    selectedRowsChanged(params) { //triggered whenever a row checkbox changes, as a result of selectRows() or handleRowSelectionChange()
        //SP 3/21/18: This disables the dataRow shouldComponentUpdate check after updating the rows.
        this.props.contentGrid.shouldComponentUpdateCheck = false;
        this.selectedRowIndexs = null;
    }
    menuItemSelected(menu, action) {
        if (action.callback) {
            action.callback(this, action);
        } else if (action.url) {
            quosal.navigation.navigate(action.url);
        }
    }
    createRow() {
        if (this.props.rowCreated) {
            this.props.rowCreated(this);
        } else {
            var newRow = {};

            for(var i = 0; i < this.props.headers.length; i++) {
                newRow[this.props.headers[i].FieldName] = null;
            }

            newRow.id = quosal.util.generateGuid();
            this.props.rows.push(newRow);
            this.forceUpdate();
        }
    }
    fieldSelected(row, field, input) {
        this.selectedField = field;
    }
    getAggregatesForDisplay(headers, items) {
        var aggregates = {};

        if (items != null && items.length > 0) {
            for (var c = 0; c < headers.length; c++) {
                if (headers[c].CanDisplayAggregateTotal && headers[c].DisplayAggregateTotal) {
                    var column = headers[c];
                    //See QuoteContentGrid.getCustomAggregate for an example implementation of an aggregateSummarizer
                    var sum = this.props.aggregateSummarizer ? this.props.aggregateSummarizer(column, items, this) : 0.0;

                    if (!sum) {
                        sum = 0.0;

                        for (var i = 0; i < items.length; i++)
                            sum += items[i][column.FieldName];
                    }
                    sum = accounting.toFixedAuto(sum); //Fix for double type rounding errors
                    var display;
                    var quote = this.props.quote || app.currentQuote;
                    
                    if (!quote) {
                        display = sum.toString();
                    } else if (this.props.nonFormattedFields) {
                        display = this.props.nonFormattedFields.indexOf(column.FieldName) >= 0 ? sum.toString() : quote.formatCurrency(sum);
                    } else {
                        display = quote.formatCurrency(sum);
                    }

                    aggregates[column.FieldName] = display;
                }
            }
        }

        return aggregates;
    }
    setScreenFillingHeight() {
        var windowHeight = $(window).height();
        var refParent = $(this.refs.scrollParent);
        if (refParent && refParent.offset()) {
            var scrollParentTop = refParent.offset().top;
            var distanceFromScrollParentBottomToWindowBottom = 80;
            var height = windowHeight - scrollParentTop - distanceFromScrollParentBottomToWindowBottom;
            if (this.componentIsMounted) {
                this.props.contentGrid.doNotRenderRows = true;
                this.setState({
                    scrollParentHeight: height
                },
                function() {
                    this.props.contentGrid.doNotRenderRows = false;
                }.bind(this));
            }
        }
    }
    setScreenFillingHeightWithDelay(timeoutLength) {
        if (typeof this.resizeTimeoutId === 'number') {
            window.clearTimeout(this.resizeTimeoutId);
        }
        if (typeof timeoutLength !== 'number') {
            timeoutLength = 100;
        }
        this.resizeTimeoutId = window.setTimeout(this.setScreenFillingHeight, timeoutLength);
    }
    shouldRestrictNewItems(selectedTab) {
        return (selectedTab.HasLimitOnNewItems && !quosal.util.userIsAdminOrMaintainer() && !app.currentUser.IsStandardPlus);
    }
    shouldOmitFooter(selectedTab){
        if (!!selectedTab) {
            var isWon = app.currentQuote.QuoteStatus == 'Won';
            var restrictNewItems = this.shouldRestrictNewItems(selectedTab);
            return (isWon || restrictNewItems);
        } else {
            return true;
        }       
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.useFixedWidths) {
            if (!prevState.hasVerticalScroll != !this.state.hasVerticalScroll){
                return;
            }
            var shouldHaveVerticalScroll = this.refs.tbody.scrollHeight > this.state.scrollParentHeight;
            if (shouldHaveVerticalScroll != this.state.hasVerticalScroll) {
                this.setState({hasVerticalScroll: shouldHaveVerticalScroll});
            }
        }

        // Drag and drop sorting is problematic if any rows are hidden. It could be adapted, but would require extra work
        // (e.g. putting in placeholders for sections of absent rows). For now, we're just disabling sortable when rows are hidden.
        if (this.props.sortable) {
            var tbody = $(this.refs.tbody);
            if (!this.isRowHidden || this.isRowHidden.length == 0) {
                tbody.sortable("option", "disabled", false);
            } else {
                    tbody.sortable("disable");
            }
        }
        if (this.refs.sideScroller) {
            this.refs.sideScroller.checkEdges();
        }

        if (this.props.onUpdate) {
            this.props.onUpdate(this);
        }
    }
    render() {
        var useFixedWidths = this.props.useFixedWidths;

        var headerColumns = [];
        var headers = this.props.headers;
        var rows = [];
        var lineItemQuickFilterHeaders = this.state.lineItemQuickFilter ? [] : null;
        var liqfMessageColspan = 0;

        if (!headers && this.props.configuration) {
            var gridLayouts = quosal.customization.grids[this.props.configuration.gridName].Configurations
            var layout = gridLayouts[this.props.configuration.configurationName];
            if (!layout || !layout.Columns) {
                layout = gridLayouts['Default'];
            }
            headers = layout.Columns;
        }

        if (useFixedWidths && this.props.configuration) {
            var gridName = this.props.configuration.gridName;
            var gridLayoutName = this.props.configuration.configurationName;
            if (app.settings.user.gridColumnWidths[gridName] && app.settings.user.gridColumnWidths[gridName][gridLayoutName]) {
                var visibleHeaders = [];
                for (var i = 0; i < headers.length; i++) {
                    if (app.settings.user.gridColumnWidths[gridName][gridLayoutName][headers[i].FieldName] !== 0) {
                        visibleHeaders.push(headers[i]);
                    }
                }
                headers = visibleHeaders;
            }
        }

        var customizer = (this.props.configuration && this.isCustomizable()) ? <DataGridCustomizeButton grid={this} configuration={this.props.configuration} excludeColumns={this.props.excludeColumns} /> : <div style={{width:16, height:16}} />;

        var checkForCustomColumnHeader= false;
        if (this.props.customColumns && this.props.customColumns.length > 0) {
            for(var i = 0; i < this.props.customColumns.length; i++) {
                if (this.props.customColumns[i].replaceSortableColumn) {
                    checkForCustomColumnHeader = true;
                    break;
                }
            }
        }

        const showSelectAllHeader = this.props.customColumns && this.props.customColumns[0]?.isSelectAll && !quosal.util.userCanCopyTabs() &&
            quosal.util.queryString("copyitems") != "true";
        if (showSelectAllHeader) {
            headerColumns.push(<th className="header nosort" {...thProps} key={this.props.name + '_custom_' + 0} style={{textAlign:'center'}} width='16px'>
                <input type="checkbox" onClick={this.selectAllExportRows} readOnly />
            </th>);
        }
        if (this.props.sortable || this.isCustomizable() || checkForCustomColumnHeader) {
            if (useFixedWidths) {
                customizer = <DataGridWidthFixer width={30}>{customizer}</DataGridWidthFixer>
            }
            headerColumns.push(
                <th style={{textAlign:'center'}} className="header nosort" width="16" key={this.props.name + '_sortHeader'}>
                    {customizer}
                </th>);
            if (lineItemQuickFilterHeaders) {
                lineItemQuickFilterHeaders.push(<th className="header nosort" width="1" key={this.props.name + '_liqf_sortColumn'}></th>);
            }
        }

        if (this.props.selectable) {
            var rowMenu = this.props.rowMenu;
            var selectedRows = this.selectedShowingRows();
            if (selectedRows.length === 0) {
                rowMenu = quosal.util.clone(rowMenu);
                var areRowsCopied = this.state.copiedRows.length;
                var areRowsCut = this.state.cutRows.length;
                if (areRowsCopied || areRowsCut) {
                    var actionRenames = rowMenu.actionRenames || {};
                    if (areRowsCopied) {
                        actionRenames['Copy'] = 'Cancel Copy';
                    }
                    if (areRowsCut) {
                        actionRenames['Cut'] = 'Cancel Cut';
                    }
                    rowMenu.actionRenames = actionRenames;
                }
                rowMenu.checkActionDisableCodes = function (areRowsCopied, areRowsCut) {
                    let result = { nothingSelected: true};
                    if (!areRowsCopied) {
                        result.noCopy = true;
                    }
                    if (!areRowsCut) {
                        result.noCut = true;
                    }
                    return result;
                }.bind(null, areRowsCopied, areRowsCut);
            }
            //create checkbox column
            headerColumns.push(<DataGridHeaderMenu key={this.props.name + '_selectableHeader'} menu={rowMenu}
                                                   onClick={this.props.menuItemSelected || this.menuItemSelected}
                                                   useFixedWidths={useFixedWidths} />);
            if (lineItemQuickFilterHeaders) {
                lineItemQuickFilterHeaders.push(<LineItemQuickFilterHeaderInput key={this.props.name + '_liqf_selectableColumn'} grid={this} index={-1} dataType={'Boolean'} />);
            }
        }
        if (this.props.customColumns) {
            //create headers for custom columns
            for(var i = 0; i < this.props.customColumns.length;i++) {
                if (this.props.customColumns[i].replaceSortableColumn) {
                    continue;
                }

                var thContents = this.props.customColumns[i].title;
                var thProps = {};
                if (useFixedWidths) {
                    thContents = (<DataGridWidthFixer key={this.props.name + '_custom_' + i} width={this.props.customColumns[i].width || 30}>
                        {thContents}
                    </DataGridWidthFixer>);
                } else {
                    thProps.width = this.props.customColumns[i].width || 1;
                }
                if (!this.props.customColumns[i].isSelectAll) { 
                    headerColumns.push(<th className="header nosort" {...thProps} key={this.props.name + '_custom_' + i}>{thContents}</th>);
                }

                if (lineItemQuickFilterHeaders) {
                    liqfMessageColspan++;
                    lineItemQuickFilterHeaders.push(<th className="header nosort" width="1" key={this.props.name + '_liqf_custom_' + i}>
                        {(i === 0 && (Object.keys(this.state.lineItemQuickFilter.inputs).length > 0)) ? <div className="toolbutton btn_unfilter" title="Clear all filters" onClick={this.clearFilters} ></div> : null}
                    </th>);
                }
            }
        }
        var customHeaderColumns = headerColumns.map(function(col) {
            try {
                return col.props.children.props.width;
            }
            catch(e) {
                return 30
            }
        });

        var me = this;
        headers = !me.props.excludeColumns ?  headers : headers.filter(function(item) {
            if (me.props.excludeColumns.indexOf(item.FieldName) == -1) {
                return true;
            }
        });

        for (var i = 0; i < headers.length; i++) {
                headerColumns.push(<DataGridHeader key={headers[i].FieldName} grid={this} {...headers[i]} />);

            if (lineItemQuickFilterHeaders) {
                liqfMessageColspan++;
                lineItemQuickFilterHeaders.push(<LineItemQuickFilterHeaderInput key={this.props.name + '_liqf_column_' + i} grid={this} filterControl={this.state.lineItemQuickFilter} index={i} dataType={this.props.headers[i].DataType} />);
            }
        }

        var fieldChanged = function(row, field, input, callback) {
            if (this.props.onChange) {
                return this.props.onChange(this, row, field, input, callback);
            }
        }.bind(this);

        var checkedShowingRows = 0,
            checkedHiddenRows = 0,
            showingRows = 0,
            totalRows = this.props.rows ? this.props.rows.length : 0;

        if (this.props.rows) {
            this.evaluateRowVisibility();
            loopThroughRows: for (var i = 0; i < this.props.rows.length; i++) {
                if (this.isRowHidden[i]) {
                    if (this.state.isRowSelected[i]) {
                        checkedHiddenRows++;
                    }
                    continue loopThroughRows;
                }

                rows.push(<DataGridRow id={this.props.name + '_row_' + this.props.rows[i].id} key={'row' + i + '_' + this.props.rows[i].id} rowNumber={i} headers={headers}
                    row={this.props.rows[i]} metadata={this.props.metadata} onChange={fieldChanged} grid={this} customizeRow={this.props.customizeRow}
                    selected={this.state.isRowSelected[i]} selectionChanged={this.handleRowSelectionChange} customizeCell={this.props.customizeCell}
                    isCopied={this.state.copiedRows && this.state.copiedRows.indexOf(i) >= 0} isCut={this.state.cutRows && this.state.cutRows.indexOf(i) >= 0}
                    configuration={this.props.configuration} isOnAProtectedTab={this.props.quoteTab?this.props.quoteTab.IsProtectedTab:null} />);

                if (this.state.isRowSelected[i]) {
                    checkedShowingRows++;
                }
                showingRows++;
            }
        }
        var aggregateRow = null;

        //Display Aggregates
        for(var i = 0; i < headers.length; i++) {
            if (headers[i].DisplayAggregateTotal) {
                var aggregates = this.getAggregatesForDisplay(headers, this.props.rows);
                var extraColumnCount = this.props.customColumns.length;

                for(var i = 0; i < this.props.customColumns.length;i++) {
                    if (this.props.customColumns[i].replaceSortableColumn) {
                        extraColumnCount--;
                        break;
                    }
                }

                if (this.props.sortable || this.isCustomizable()) { 
                    extraColumnCount++; 
                }
                if (this.props.selectable) { 
                    extraColumnCount++; 
                }

                if (extraColumnCount < this.props.customColumns.length) {
                    extraColumnCount = this.props.customColumns.length;
                }

                aggregateRow = <DataGridAggregateRow grid={this} headers={headers} aggregates={aggregates} extraColumnCount={extraColumnCount} />;
                break;
            }
        }

        var footer = this.shouldOmitFooter(this.props.quoteTab) ? null : <DataGridFooter ref={"dataGridFooter"} key={this.props.name + '_footer'} grid={this} onClick={this.createRow} headers={headers} editable={this.props.editable} customFooter={this.props.customizeFooter ? this.props.customizeFooter(this) : null}  />;
        var tableClass = 'datagrid';
        if (!this.props.sortableHeaders) { 
            tableClass += ' nosort'; 
        }
        if (this.props.name) {
            tableClass += ' ' + this.props.name;
        }
        if (useFixedWidths) {
            tableClass += ' fixedwidth';
            if (this.state.hasVerticalScroll) {
                headerColumns.push(
                    <th className="header nosort" key={'scrollbar_sortHeader'}>
                        <DataGridWidthFixer width={17}></DataGridWidthFixer>
                    </th>
                );
                if (lineItemQuickFilterHeaders) {
                    liqfMessageColspan++;
                    lineItemQuickFilterHeaders.push(<th className="header nosort" key={'liqf_custom_scrollbar'}></th>);
                }
            }
        }

        var tBody = <tbody ref="tbody">{rows}</tbody>;
        if (useFixedWidths) {
            tBody = (
                <tbody><tr><td ref="scrollParent" colSpan={headerColumns.length}>
                    <div className="datagridbody" id="datagridbody" style={{maxHeight: this.state.scrollParentHeight || DataGrid.maxHeight, overflowY:'auto', overflowX:'hidden'}}>
                    <table cellPadding="0" cellSpacing="0" style={{tableLayout: "fixed"}}>
                        <FixedWidthColumnSizer headers={headers} grid={this} tableBody={true} customHeaderColumns={customHeaderColumns} />
                        {tBody}
                    </table>
                </div></td></tr></tbody>
            );
        }

        return (
            <SideScroller ref="sideScroller" grid={this}>
                <table ref="table" cellPadding="0" cellSpacing="0" className={tableClass} style={useFixedWidths ?  {tableLayout: "fixed"} : {} } >
                    {useFixedWidths && <FixedWidthColumnSizer headers={headers} grid={this} customHeaderColumns={customHeaderColumns} /> }
                    <thead>
                        <tr key="mainHeader" ref="thead">{headerColumns}</tr>
                        {this.state.lineItemQuickFilter
                            ? <tr key="filterHeader" className="filterRow">{lineItemQuickFilterHeaders}</tr>
                            : null}
                        {this.state.lineItemQuickFilter
                            ? <LineItemQuickFilterMessage checkAll={this.selectAllRows} checkedRows={checkedShowingRows} showingRows={showingRows} totalRows={totalRows} checkedHiddenRows={checkedHiddenRows} colSpan={liqfMessageColspan} />
                            : null}
                        {aggregateRow}
                    </thead>
                    {tBody}
                    <tfoot ref="tfoot">{footer}</tfoot>
                </table>
            </SideScroller>
        );
    }
}

DataGrid.activeGrids= {};
DataGrid.maxHeight= 300;
global.DataGrid = DataGrid;

class FixedWidthColumnSizer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }
    render(){
        var me = this;

        //Get the size of the table manually. If the padding around the table changes, the offset number will have to be changed.
        //115 is the amount of space outside of the table.
        //customColOffset are the non-user added columns each table has hardcoded to show.

        var customColOffset = 0
        this.props.customHeaderColumns.forEach(function(width) {
            customColOffset += width;
        })
        var paddingAndDefaultColOffset = (115 + customColOffset);
        var screenWidth = window.innerWidth - paddingAndDefaultColOffset;

        //subtract off the width of the vertical scrollbar, if one is visible
        if (this.props.grid.state.hasVerticalScroll) {
            screenWidth -= 17;
        }
        if (quosal.util.cookie('documentPreviewEnabled') == "true") {
            screenWidth -= 85;
        }
        if (app.settings.user.embeddedTheme == "ConnectWise Business Suite") {
            screenWidth -= 88;
        }

        var totalUserColWidth = 0;
        this.props.headers.forEach(function(header){
            var userWidths = app.settings.user.gridColumnWidths[me.props.grid.props.configuration.gridName] && app.settings.user.gridColumnWidths[me.props.grid.props.configuration.gridName][me.props.grid.props.configuration.configurationName];
            var columnWidth = userWidths && userWidths[header.FieldName] || header.ColumnWidth;
            columnWidth = columnWidth == 0 ? 100 : columnWidth;
            totalUserColWidth += columnWidth;
        })
        var cols = []
        this.props.customHeaderColumns.map(function(width, index){
            cols.push(<col key={"col_default_" + index} style={{width: width}} />)
        })
        
        this.props.headers.forEach(function(header, index){
            var userWidths = app.settings.user.gridColumnWidths[me.props.grid.props.configuration.gridName] && app.settings.user.gridColumnWidths[me.props.grid.props.configuration.gridName][me.props.grid.props.configuration.configurationName];
            var columnWidth = userWidths && userWidths[header.FieldName] || header.ColumnWidth;
            columnWidth = columnWidth == 0 ? 100 : columnWidth;
            var streachedWidth =  (columnWidth / totalUserColWidth) * screenWidth ;
            var columnWidth =  streachedWidth > columnWidth ? streachedWidth : columnWidth;
            cols.push( <col key={"col_" + index} style={{width: columnWidth}}/>)
        })
       
        if (this.props.grid.state.hasVerticalScroll && !this.props.tableBody) {
            cols.push(<col key="col_default_scroll" style={{width:"17px"}} />)
        }

        return (
            <colgroup>
            {cols}
            </colgroup>
        )
    }
}

class DataGridHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            height: null
        }; 
    }
    componentDidMount() {
        if (this.props.grid.props.useFixedWidths) {
            this.setState({
                height: $(this.refs.root).height()
            });
        }
    }
    render() {
        var gridConfig = quosal.customization.grids[this.props.grid.props.configuration.gridName];
        var fieldConfig = quosal.customization.fields[gridConfig.ObjectType][gridConfig.ObjectName].fieldConfigurations[this.props.FieldName];

        var useFixedWidths = this.props.grid.props.useFixedWidths;

        if (useFixedWidths) {
            var userWidths = (app.settings.user.gridColumnWidths[this.props.grid.props.configuration.gridName] &&
                app.settings.user.gridColumnWidths[this.props.grid.props.configuration.gridName][this.props.grid.props.configuration.configurationName]);
            var columnWidth = (userWidths && userWidths[this.props.FieldName]) || this.props.ColumnWidth;

            var openWidthEditor = function () {
                UserGridWidthCustomizer.showUserGridWidthCustomizer(this.props.grid.props.configuration, function () {
                    this.props.grid.forceUpdate();
                }.bind(this));
            }.bind(this);

            return (
                <th ref="root" className={'title' + (this.props.Sortable ? '' : ' nosort') + " th_" + this.props.FieldName} style={{position: 'relative'}} >
                    { (this.state.height) ? <div className="resizeImitator" style={{height: '100%'}} onMouseDown={openWidthEditor}></div> : null}
                    <DataGridWidthFixer width={columnWidth}>
                        {this.props.children}
                        {fieldConfig && fieldConfig.FieldShortName || fieldConfig && fieldConfig.FieldRename || this.props.DisplayName}
                    </DataGridWidthFixer>
                </th>
            );
        }
        return (
            <th ref="root" width={this.props.ColumnWidth ? this.props.ColumnWidth : 1} className={'title' + (this.props.Sortable ? '' : ' nosort') + " th_" + this.props.FieldName } >
                {this.props.children}
                {fieldConfig && fieldConfig.FieldShortName || fieldConfig && fieldConfig.FieldRename || this.props.DisplayName}
            </th>
        );
    }
}

class DataGridWidthFixer extends React.Component {
    render() {
        return (
            <div className="widthFixer" style={{width: (this.props.width || 50) - 10 }}>
                {this.props.children}
            </div>
        );
    }
}

class DataGridAggregateRow extends React.Component {
    render() {
        var headers = [];

        for(var i = 0; i < this.props.extraColumnCount; i++) {
                headers.push(
                <th key={'extraSumCol' + i}></th>
            );
        }

        for(var i = 0; i < this.props.headers.length; i++) {
            var column = this.props.headers[i];
            var value = this.props.aggregates[column.FieldName] || '';

            headers.push(
                <th style={{ textAlign: 'right' }} id={'aggregate' + column.FieldName} key={column.FieldName + '_sum'}>{value}</th>
            );
        }
        if (this.props.grid.state.hasVerticalScroll) {
            headers.push(
                <th key={'dataGridAggregateRow_col'}></th>
            )
        }
        return (
            <tr>
                {headers}
            </tr>
        );
    }
}

class DataGridHeaderMenu extends React.Component {
    render() {
        var menuElem = null;

        if (this.props.menu.type == 'ActionsMenu') {
            menuElem = <ActionsMenu key="actionmenu" {...this.props.menu} onClick={this.props.onClick} />;
        }
        else if (this.props.menu.type == 'ActionsMenu') {
            menuElem = <ConfigMenu key="configmenu" {...this.props.menu} onClick={this.props.onClick} />;
        }

        if (this.props.useFixedWidths) {
            menuElem = <DataGridWidthFixer width={30}>{menuElem}</DataGridWidthFixer>
        }
        return (
            <th style={{textAlign:'center'}} width={1} className={'title nosort'}>
                {menuElem}
            </th>
        );
    }
}

class DataGridFooter extends React.Component {
    render() {
        var colSpan = this.props.headers.length;

        if (this.props.grid.props.sortable) {
            colSpan++;
        }
        if (this.props.grid.props.selectable) {
            colSpan++;            
        }
        if (this.props.grid.props.customColumns) {
            colSpan += this.props.grid.props.customColumns.length;
        }
        if (this.props.grid.state.hasVerticalScroll) {
            colSpan++;
        }
        var footerContent;

        if (this.props.customFooter) {
            footerContent = <td className="content" colSpan={colSpan}>{this.props.customFooter}</td>;
        } else if(this.props.editable) {
            footerContent = (
                <td className="content" colSpan={colSpan}>
                    <div className="icons-action add color quickEntryAddBtn" style={{ margin: 5, cursor: 'pointer' }} title="New Row" onClick={this.props.onClick} />
                </td>
            );
        } else {
            <td className="content" colSpan={colSpan}></td>
        }

        return (
            <tr>
                {footerContent}
            </tr>
        );
    }
}

class DataGridRow extends React.Component {
    constructor(props) {
        super(props);

        var checkCurrentItemMatchesSelectedItem = function() {
            return app && app.currentModule && app.currentModule.subModules && app.currentModule.subModules[0] && app.currentModule.subModules[0].Module == "quote.content" && 
            app.currentModule.subModules[0].selectedItem && app.currentModule.subModules[0].selectedItem == props.row.IdQuoteItems
        }

        this.state = {
            highlighted: checkCurrentItemMatchesSelectedItem(),
        };       
        // This binding is necessary to make `this` work in the callback
        this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this); 
    }
    shouldComponentUpdate(nextProps, nextState) {
        try {
            if (this.props.grid.props.contentGrid.shouldComponentUpdateCheck && this.props.grid.selectedRowIndexs) {
                return this.props.grid.selectedRowIndexs.includes(this.props.row.IdQuoteItems);
            }
            else if (this.props.grid.props.contentGrid.doNotRenderRows) {
                return false;
            }
            else {
                return true;
            }
        }
        catch(error) {
            return true;
        }
        // return true; //to always updates the component. If we see any oddities with rows not updating properly, the below code is likely the culprit - TJ 7/27/16
    }
    getValue(fieldName){
        var value = this.props.row[fieldName]
        if (fieldName == "RecurringPriceModifier") {
            if (value == "") {
                value = this.props.row["RecurringCalculatedPriceModifier"];
            }
        }

        return value;        
    }
    render() {
        var columns = [];
        var customSortableColumn;
        var hasCustomSortableColumn = false;

        const showSelectButtons = this.props.grid.props.customColumns && !quosal.util.userCanCopyTabs() && quosal.util.queryString("copyitems") != "true";
        if (showSelectButtons) {
            //create custom columns
            for (var i = 0; i < this.props.grid.props.customColumns.length; i++) {
                var customColumn = this.props.grid.props.customColumns[i];
                if (!customColumn.isSelectAll) {
                    continue;
                }

                var customColumnCell = customColumn.createCell(this);

                if (customColumnCell) {
                    var className = 'content';
                    if (customColumn.extraClass) {
                        className += ' ' + customColumn.extraClass;
                    }
                    className += ' ' + (customColumn.align || 'center');
                    customColumnCell = <ContentCollapser>{customColumnCell}</ContentCollapser>
                    var customColumnTdProps = {};

                    customColumnTdProps.width = 1;

                    columns.push(
                        <td key={this.props.rowNumber + '_custom_' + i}
                            className={className}
                            {...customColumnTdProps} >
                            {customColumnCell}
                        </td>
                    );
                }
            }
        }

        if (this.props.grid.props.customColumns) {
            for(var i = 0; i < this.props.grid.props.customColumns.length; i++) {
                if (this.props.grid.props.customColumns[i].replaceSortableColumn) {
                    customSortableColumn = this.props.grid.props.customColumns[i].createCell(this);
                    hasCustomSortableColumn = true;
                    break;
                }
            }
        }
        if (hasCustomSortableColumn) {
            var tdProps = {};
            
            tdProps.width = this.props.grid.props.customColumns[i].width || 1;
            
            columns.push(<td key="customSortableCol" className={'content ' + (this.props.grid.props.customColumns[i].align || 'center')}
                {...tdProps}>{customSortableColumn}</td>);
        } else if (this.props.row.isUnsaved && (this.props.grid.props.sortable || this.props.grid.isCustomizable())) {
            columns.push(<td key="sortable_ph" />);
        } else if (this.props.grid.props.sortable) {
            columns.push(
                <td key="dragger" width="1" className="content row-dragger" ></td>
            );
        } else if (this.props.grid.isCustomizable()) {
            columns.push(
                <td key="dragger" width="1" className="content" ></td>
            );
        }

        if (this.props.grid.props.selectable && this.props.row.isUnsaved) {
            columns.push(<td key="selectable_ph" />);
        } else if (this.props.grid.props.selectable) {
            //create checkbox column
            var cbClass = 'datagrid-rowselect';
            var tdClass = 'content center';
            var beforeCheckbox = null;

            if (this.props.grid.state.copiedRows.indexOf(this.props.rowNumber) >= 0) {
                cbClass += ' cut-copy';
                tdClass += ' relative';
                beforeCheckbox = <div style={{position: 'absolute', top: 0, left: '-8px'}}>COPIED</div>
            }
            if (this.props.grid.state.cutRows.indexOf(this.props.rowNumber) >= 0) {
                cbClass += ' cut-copy';
                tdClass += ' relative';
                beforeCheckbox = <div style={{position: 'absolute', top: 0, left: '2px'}}>CUT</div>
            }

            var gridActionSelectionCheckbox = <input id={this.props.rowNumber + '_selected'} name={this.props.rowNumber + '_selected'} type="checkbox" checked={this.props.selected ? this.props.selected : false}
                                                     className={cbClass} onChange={this.props.selectionChanged.bind(null, this.props.rowNumber)} />;
           
            columns.push(
                <td key={this.props.rowNumber + '_selectable'} className={tdClass} width="1">
                    {beforeCheckbox}
                    {gridActionSelectionCheckbox}
                </td>
            );            
        }

        if (this.props.grid.props.customColumns) {
            //create custom columns
            for(var i = 0; i < this.props.grid.props.customColumns.length; i++) {
                var customColumn = this.props.grid.props.customColumns[i];
                if (customColumn.replaceSortableColumn) {
                    continue;
                }
                if (customColumn.isSelectAll) {
                    continue;
                }

                var customColumnCell = customColumn.createCell(this);

                if (customColumnCell) {
                    var className = 'content';
                    if (customColumn.extraClass) {
                        className += ' ' + customColumn.extraClass;
                    }
                    className += ' ' + (customColumn.align || 'center');
                    customColumnCell = <ContentCollapser>{customColumnCell}</ContentCollapser>
                    var customColumnTdProps = {};
                   
                    customColumnTdProps.width =  1;

                    columns.push(
                        <td key={this.props.rowNumber + '_custom_' + i}
                            className={className}
                            {...customColumnTdProps} >
                            {customColumnCell}
                        </td>
                    );
                }
            }
        }

        var headers = this.props.headers;
        var colSpan = 1;
        var customStyle = null;
        var gridConfig = quosal.customization.grids[this.props.configuration.gridName];
        var rowClass =  'dataGridRow';
        if (this.props.row.className) {
            rowClass += ' ' + this.props.row.className;
        }

        if (this.props.customizeRow) {
            var customization = this.props.customizeRow(this);
            if(customization) {
                headers = customization.headers || this.props.headers;
                var userBasedColOffset = 0;
                if(app.currentUser.IsContentMaintainer || app.currentUser.IsAdministrator || app.currentUser.IsStandardPlus){
                    userBasedColOffset = 1
                }
                var setColSpan = (customization.shouldCalculateColSpan ? this.props.headers.length : customization.colSpan) + userBasedColOffset;
                colSpan = setColSpan || 1;
                customStyle = customization.style;
            }
        }

        for (var i = 0; i < headers.length; i++) {
            var header = headers[i];

            var value = this.getValue(header.FieldName);

            var cell = null;
            var align = 'left';
            var cellStyle ={}

            if (!this.props.grid.state.editMode || (!header.editable && !this.props.grid.props.editable)) {
                value = quosal.sell.data.formatValue(value, header);
            }

            var editMode = this.props.grid.state.editMode && (header.editable || this.props.grid.props.editable);

            if (editMode && this.props.grid.props.fieldEditable) {
                editMode = this.props.grid.props.fieldEditable(this, header);
            }

            var cellOptions = {};
            if (this.props.customizeCell) {
                cell = this.props.customizeCell({
                    gridRow: this,
                    field: header,
                    displayValue: value,
                    editMode: editMode,
                    cellStyle: cellStyle,
                    outOptions: cellOptions,
                });
            }

            var editableEvenWithoutEditMode = cellOptions.EditableEvenWithoutEditMode;
            if (cellOptions.Value !== undefined) {
                value = cellOptions.Value;
            }

 //         let readOnlyUser = app && app.currentUser && app.currentUser.IsReadOnly; // TODO ER: may need to add read only user check
            var requestQuoteEditMode = app &&
                app.currentQuote
                && app.currentQuote.IsLocked
                && app.currentQuote.IsRequestQuote
                && (quosal.settings.getValue('LockWonQuotes'))
                && quosal.settings.getValue('AllowSourceCostUpdate')
                && (header.FieldName == "Cost" || header.FieldName == "Source");
            
            var canEditField = editMode || editableEvenWithoutEditMode || requestQuoteEditMode;
            if (!cell) {
                if (header.DataType == 'Enum' || canEditField) {
                    var field = {
                        FieldName: header.FieldName,
                        DataType: header.DataType,
                        EnumType: header.EnumType,
                        IsCurrency: header.IsCurrency,
                        IsPercentage: header.IsPercentage,
                        ReadOnly: header.ReadOnly,
                        Value: value
                    };       
                    //need to show the dropdown list text field rather than its value (id), mark it as readonly, but still sets it as a dropdown             
                    if (header.DataType == 'Enum' && !canEditField) {
                        field.ReadOnly = true;
                    }

                    if ((this.props.row.LineType == 'SubHeader' || this.props.row.LineType == 'SubFooter') && field.FieldName == 'IsTotalsIncluded'){
                        field.ReadOnly = true;
                    }

                    var fieldChanged;
                    // EnableEditPackagePrice at work here
                    if (this.props.row.IsPackageHeader
                        && (field.FieldName == 'QuoteItemPrice' || field.FieldName == 'RecurringAmount')
                        && (this.props.row.OverridePackageDetails ||
                            quosal.settings.getValue('enableEditPackagePrice'))
                    ) {
                        fieldChanged = function (field, input, callback) {
                            var idQuoteMain = this.props.grid.props.contentGrid.props.quote.IdQuoteMain;
                            var headerId = this.props.row.IdQuoteItems;
                            var amount = input.value || 0;
                            var isRecurring = field.props.field.FieldName == 'RecurringAmount';
                            var setPackagePriceApi = quosal.api.product.setPackagePrice(idQuoteMain, headerId, amount, isRecurring);
                            setPackagePriceApi.finished = function(msg) {
                                if(msg.quote) {
                                    quosal.sell.quote.update(msg.quote);
                                }
                                callback();
                            };
                            setPackagePriceApi.call();
                        }.bind(this);
                    }
                    // default path
                    else {
                        fieldChanged = function (field, input, callback) {
                            var updateValue = true;

                            if (this.props.onChange) {
                                if(this.props.onChange(this, field, input, callback) === false)
                                    updateValue = false;
                            }

                            //need generic field update util functions for detecting data type etc
                            if(updateValue)
                                this.props.row[field.props.field.FieldName] = input.value; //parseFloat(input.value)
                        }.bind(this);
                    }

                    var fieldFocused = function (field, input) {
                        this.props.grid.fieldSelected(this, field, input);
                    }.bind(this);

                    var fieldKey = 'row_' + this.props.rowNumber + '_field';

                    //This is designed to be the same as the fieldId it would have on Product Edit. Changing this to distinguish between the locales would be okay, but let Felix know because the image editor uses the fieldId.
                    var fieldId = 'ProductEdit' + '_' + header.FieldName;

                    var fieldConfig = quosal.customization.fields.getFieldConfiguration(gridConfig.ObjectType, gridConfig.ObjectName, field.FieldName);
                    var advancedRoundingEnabled = this.props.grid.props.contentGrid && this.props.grid.props.contentGrid.props.quote.EnableAdvancedRounding;

                    if (field.FieldName == 'PriceModifier') {
                        let inputstyle = quosal.util.clone(cellStyle);
                        inputstyle.width = 'calc(100% - 20px)';
                        inputstyle.opacity = this.props.isCopied ? 0.9 : 1; 
                        cellOptions.className += " noFade"
                        cell = <div style={{display: 'inline-block'}}> 
                                    <FormFieldInput parentRow={this} style={inputstyle} autoUpdate={true} key={fieldKey} parentId={fieldKey} field={field} fieldConfig={fieldConfig} fieldId={fieldId} metadata={this.props.metadata}
                                                    onChange={fieldChanged} onFocus={fieldFocused} />
                                    <PriceModifierHelper itemPrice={this.props.row.QuoteItemPrice} itemCost={this.props.row.Cost} itemSuggestedPrice={this.props.row.SuggestedPrice}
                                        itemBasePrice={this.props.row.BasePrice} priceModifier={this.props.row.PriceModifier}
                                        idQuoteItems={this.props.row.IdQuoteItems} idQuoteMain={this.props.row.IdQuoteMain}
                                field={field} advancedRoundingEnabled={advancedRoundingEnabled} inthetable='yes' quotePriceModifier={app.currentQuote.QuotePriceModifier}/>
                                </div>
                    }
                    else if (field.FieldName == 'RecurringPriceModifier') {
                        let inputstyle = quosal.util.clone(cellStyle);
                        inputstyle.width = 'calc(100% - 20px)';
                        inputstyle.opacity = this.props.isCopied ? 0.9 : 1; 
                        cellOptions.className += " noFade"
                        var recurringPriceModifier = this.props.row.RecurringPriceModifier == "" ? this.props.row.RecurringCalculatedPriceModifier : this.props.row.RecurringPriceModifier;
                        cell =  <div style={{display: 'inline'}}> 
                                    <FormFieldInput parentRow={this} style={inputstyle} autoUpdate={true} key={fieldKey} parentId={fieldKey} field={field} fieldConfig={fieldConfig} fieldId={fieldId} metadata={this.props.metadata}
                                                onChange={fieldChanged} onFocus={fieldFocused} />
                                    <PriceModifierHelper itemPrice = {this.props.row.RecurringAmount} itemCost = {this.props.row.RecurringCost} itemSuggestedPrice = {this.props.row.RecurringSuggestedPrice}
                                            itemBasePrice = {this.props.row.RecurringBasePrice} priceModifier = {recurringPriceModifier}
                                            idQuoteItems={this.props.row.IdQuoteItems} idQuoteMain={this.props.row.IdQuoteMain}
                                field={field} advancedRoundingEnabled={advancedRoundingEnabled} quotePriceModifier={app.currentQuote.QuoteRecurringPriceModifier}/>
                                </div>
                    }    
                    else {
                        cell = <FormFieldInput parentRow={this} style={cellStyle} autoUpdate={true} key={fieldKey} parentId={fieldKey} field={field} fieldConfig={fieldConfig} fieldId={fieldId} metadata={this.props.metadata}
                                        onChange={fieldChanged} onFocus={fieldFocused} />
                   }
                } else {
                    value = quosal.sell.data.formatValue(value, header);

                    cell = <div className='readonly' style={cellStyle}>{value}</div>;

                    if (header.DataType == 'Boolean') {
                        cell = <input type="checkbox" disabled checked={value}/>;
                    } else if (header.DataType == 'Byte[]') {
                        cell = <img className="itemthumbnail imagequote" src={value}/>
                    }
                }
            }

            //left align text, right align numbers, center images
            if (header.align) {
                align = header.align;
            }
            else if (header.DataType) {
                if (header.DataType == 'Double'
                    || header.DataType == 'Int32') {
                    align = 'right';
                } else if (header.DataType == 'Byte[]'
                    || header.DataType == 'Boolean') {
                    align = 'center';
                }
            }

            var cellClass = 'content ' + align;

            if (cellOptions.className) {
                cellClass += ' ' + cellOptions.className;
            }

                cellStyle.overflowY = "hidden";

            if (header.FieldName == 'IsSpecialOrder' && this.props.row.IsPackageHeader) {
                cell = '';
            }
            cell = <ContentCollapser>{cell}</ContentCollapser>;
            columns.push(<td name={header.FieldName} key={header.FieldName + this.props.rowNumber} colSpan={colSpan} className={cellClass} style={cellStyle}>{cell}</td>);
        }

        var rowStyle = customStyle || {};
        if (!this.props.row.typeName && !rowStyle.backgroundColor) {
            if (this.props.grid.props.alternateRowColor && (this.props.rowNumber % 2 == 0)) {
                rowStyle.backgroundColor = '#f4f4f4';
            } else {
                rowStyle.backgroundColor = '#fff';
            }
        }

        if (this.props.isCut) {
            rowClass += ' cut';
        } else if (this.props.isCopied) {
            rowClass += ' copy';
        }

        if (this.props.row.typeName) {
            rowClass += ' ' + this.props.row.typeName;
        }

        var mouseOverEvent = null
        if (this.state.highlighted) {
            var me = this;
            rowStyle.backgroundColor = "#EAE8FC";
            mouseOverEvent = ()=>{me.setState({highlighted: false})};
        } 

        return (
            <tr id={this.props.row.id} onMouseOver={mouseOverEvent}  style={rowStyle} data-type={this.props.row.typeName} type={this.props.row.typeName} className={rowClass}>{columns}</tr>
        );
    }
}

class DataGridCustomizeButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
        // This binding is necessary to make `this` work in the callback
        this.showDataGridCustomizer = this.showDataGridCustomizer.bind(this); 
    }
    showDataGridCustomizer() {
        var onDropdownUpdate = function () {
            if (this.props.grid) {
                if (this.props.grid.props.contentGrid) {
                    this.props.grid.props.contentGrid.forceUpdate();
                } 
                this.props.grid.forceUpdate();
            }
            this.forceUpdate();
        }.bind(this);
        DataGridCustomizer.showDataGridCustomizer.call(null, this.props.configuration, this.props.grid.props.editable, onDropdownUpdate, this.props.excludeColumns); 
    }
    render() {
        return (
            <img className="actionEdit" style={{cursor:'pointer'}} src="img/svgs/v1.0/Action_Edit.svg" data-cy="customizeGrid" title="Customize Grid" width='18px' height='18px' onClick={this.showDataGridCustomizer} />
        );
    }
}

export class DataGridCustomizer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            currentConfig: this.loadLayout(this.props.configuration.configurationName || 'Default'),
            layoutChanged: false,
            isSaving: false,
            isDeleting: false,
            savingFields: [],
            currentTabId: ''
        };
        // This binding is necessary to make `this` work in the callback
        this.cancelChanges = this.cancelChanges.bind(this);
        this.loadLayout = this.loadLayout.bind(this);
        this.executeTabsWrapperCallback = this.executeTabsWrapperCallback.bind(this);
        this.selectedConfigurationChanged = this.selectedConfigurationChanged.bind(this);
        this.layoutNameRef = React.createRef();
        this.onLayoutChanged = this.onLayoutChanged.bind(this);
        this.onLayoutNameChanged = this.onLayoutNameChanged.bind(this);
        this.displayTotalChanged = this.displayTotalChanged.bind(this);
        this.readOnlyChanged = this.readOnlyChanged.bind(this);
        this.onFieldInclusionChanged = this.onFieldInclusionChanged.bind(this);
        this.clearSelectedFieldsn = this.clearSelectedFieldsn.bind(this);
        this.resetColumnSize = this.resetColumnSize.bind(this);
        this.resetColumnSizes = this.resetColumnSizes.bind(this);
        this.updateFromRename = this.updateFromRename.bind(this);
        this.deleteLayout = this.deleteLayout.bind(this);
        this.saveLayout = this.saveLayout.bind(this);
        this.makeColumnsResizable = this.makeColumnsResizable.bind(this);
    }

    static showDataGridCustomizer(gridConfiguration, showReadOnly, onDropdownUpdate, excludeColumns) {
        var customizerArgs = {
            configuration: gridConfiguration,
            promptedSaveEvent: quosal.events.create(),
            showReadOnly: showReadOnly,
            onDropdownUpdate: onDropdownUpdate,
            excludeColumns: excludeColumns
        };

        const isQuoteContentWithQuoteItems = customizerArgs.configuration.gridName === 'QuoteContent' && customizerArgs.configuration.fieldList === 'QuoteItems';
        const isCKEditorEnabledForQuoteTabs = DataGridCustomizer.isCKEditorEnabled() && isQuoteContentWithQuoteItems;

        if (isCKEditorEnabledForQuoteTabs) {
            customizerArgs = { ...customizerArgs, currentTabId: arguments[arguments.length - 2]};
        }
        else {
            customizerArgs = { ...customizerArgs, currentTabId: arguments[arguments.length - 1] };
        }

        var setChange = arguments[arguments.length - 1];

        var componentDidMount = function () {
            this.props.customizerArgs.customizer = this; // `this` will refer to the instance of DataGridCustomizer that did mount
            this.setState({currentTabId: this.props.customizerArgs.currentTabId});
        };
        var componentWillUnmount = function () {
            this.props.customizerArgs.customizer = null; // `this` will refer to the instance of DataGridCustomizer that will unmount
        };
        var customizer = <DataGridCustomizer {...customizerArgs} customizerArgs={customizerArgs} componentDidMount={componentDidMount} componentWillUnmount={componentWillUnmount} />;

        var closeDialog = function () {
            if (this.customizer && this.customizer.state.layoutChanged) {
                Dialog.confirmDelete({
                    title:'Changes will be lost',
                    width: '400px',
                    links:[
                        {title: 'Save Changes', callback: function() {
                            var saveError = this.promptedSaveEvent.call();
                            if (saveError) {
                                Dialog.close({callback: function() {
                                    Dialog.open({
                                        title: 'Error Saving Layout',
                                        message: saveError,
                                        links: [{title:'OK', callback: Dialog.close}]
                                    });
                                }});
                            } else {
                                Dialog.closeAll();
                                if (isCKEditorEnabledForQuoteTabs) {
                                    let quoteTab = null;
                                    app.currentQuote.Tabs.map(a => {
                                        if (a.IdQuoteTabs === customizerArgs.currentTabId) {
                                            quoteTab = a;
                                        }
                                    });
                                    quoteTab.GridFormat = this.customizer.state.currentConfig.ConfigurationName;
                                }
                            }
                        }.bind(this)},
                        {title: 'Discard Changes', callback: Dialog.closeAll},
                        {title: "Cancel", callback: Dialog.close}
                    ],
                    message: 'You have unsaved changes. Are you sure you want to close the Customize Grid window?'
                });
                return true;
            } else {
                Dialog.close();
            }
            onDropdownUpdate();
            if (isCKEditorEnabledForQuoteTabs && typeof setChange === 'function') {
                setChange();
            }

        }.bind(customizerArgs);

        Dialog.open({
            title: 'Customize Grid',
            height:'80%',
            width:'80%',
            resizable: true,
            draggable: true,
            onClose: closeDialog,
            links:[{title: 'Finished', callback: closeDialog}],
            message: customizer
        });
    }
    cancelChanges () {
        $(this.refs.gridContainer).find('.gridcolumn').css('width', 'inherit');
        this.setState({ currentConfig: this.loadLayout(this.props.configuration.configurationName || 'Default'), layoutChanged: false });
    }

    static loadLayout (configName, gridName) {
        var allConfigs = quosal.customization.grids[gridName].Configurations;
  
        if (allConfigs == null) {
            quosal.log.error('Invalid grid name: ' + gridName);
            return <div />;
        }
    
        if (allConfigs[configName] == null) {
            configName = 'Default';
        }

        var currentConfig = quosal.util.clone(quosal.customization.grids[gridName].Configurations[configName], 2);
        currentConfig.OriginalName = currentConfig.ConfigurationName;
  
        return currentConfig;
    }

    loadLayout (configName) {
        return DataGridCustomizer.loadLayout(configName, this.props.configuration.gridName);
    }

    static isCKEditorEnabled () {
        return app.currentQuote && quosal.util.isNewEditorEnabled();
    }

    static executeTabsWrapperCallback (callback, layoutName, currentTabId) {
        if (typeof callback === 'function') {
            callback(layoutName, currentTabId);
        }
    }

    executeTabsWrapperCallback (layoutName, currentTabId) {
        DataGridCustomizer.executeTabsWrapperCallback(layoutName, currentTabId);
    }

    selectedConfigurationChanged (e) {
        var newConfig = this.loadLayout(e.currentTarget.value);
        this.setState({ currentConfig: newConfig, layoutChanged: false });

        const isQuoteContentWithQuoteItems = this.state.currentConfig.GridName == 'QuoteContent' && this.state.currentTabId;
        if (DataGridCustomizer.isCKEditorEnabled() && isQuoteContentWithQuoteItems)
        {
            this.executeTabsWrapperCallback(e.currentTarget.value, this.state.currentTabId);
        }
        quosal.customization.grids.configurationChanged.call(newConfig, this.state.currentTabId);
    }

    onLayoutChanged () {
        this.setState({ layoutChanged: true });
    }

    onLayoutNameChanged(e) {
        this.state.currentConfig.ConfigurationName = this.layoutNameRef.current.value;
        this.onLayoutChanged();
    }

    displayTotalChanged(e) {
        var fieldName = e.target.value;
        var column = this.state.currentConfig.Columns.where(c=>c.FieldName == fieldName).firstOrNull();

        if (column != null && (column.DisplayAggregateTotal || column.CanDisplayAggregateTotal)) {
            column.DisplayAggregateTotal = e.target.checked;
            this.setState({layoutChanged: true});
        }
    }
    
    readOnlyChanged(e) {
        var fieldName = e.target.value;
        var column = this.state.currentConfig.Columns.where(c=>c.FieldName == fieldName).firstOrNull();

        if (column != null) {
            column.ReadOnly = e.target.checked;
            this.setState({layoutChanged: true});
        }
    }
   
    onFieldInclusionChanged(e) {
        var includeField = e.target.checked;
        var fieldName = e.target.value;

        if (includeField) {
            var field;
            var canDisplayAggregateTotal;
            if (this.props.configuration.extraConfigurableColumns &&
                this.props.configuration.extraConfigurableColumns[fieldName]) {
                field = this.props.configuration.extraConfigurableColumns[fieldName]
            } else {
                var gridConfig = quosal.customization.grids[this.props.configuration.gridName];
                var fields = quosal.customization.fields[gridConfig.ObjectType][gridConfig.ObjectName];
                var nonAggregateFields = quosal.customization.grids[this.state.currentConfig.GridName].NonAggregateFields;
                var aggregateFieldsWhitelist = quosal.customization.grids[this.state.currentConfig.GridName].AggregateFieldsWhitelist;
                var isWhitelistActive = aggregateFieldsWhitelist && aggregateFieldsWhitelist.length;
                var allFields = fields.standardFields.concat(fields.additionalFields);
                field = allFields.where((f)=>f.FieldName == fieldName).firstOrNull();
                if (field != null) {
                    canDisplayAggregateTotal = (field.DataType == 'Double' || field.DataType == 'Int32') &&
                        (isWhitelistActive ?
                        aggregateFieldsWhitelist.indexOf(fieldName) >= 0 :
                        nonAggregateFields.indexOf(fieldName) < 0);
                }
            }

            if (field != null) {
                var column = {
                    FieldName: fieldName,
                    DisplayName: field.DisplayName,
                    ColumnIndex: this.state.currentConfig.Columns.length,
                    ColumnWidth: 0,
                    DisplayAggregateTotal: false,
                    CanDisplayAggregateTotal: canDisplayAggregateTotal,
                    DataType: field.DataType
                };

                this.state.currentConfig.Columns.push(column);
                this.setState({layoutChanged: true});
            }
        } else {
            var column = this.state.currentConfig.Columns.where((c)=>c.FieldName ==fieldName).firstOrNull();
            var index = this.state.currentConfig.Columns.findIndex((c)=>c.FieldName ==fieldName);

            if (column != null && index >= 0) {
                this.state.currentConfig.Columns.splice(index, 1);

                for(var i = 0; i < this.state.currentConfig.Columns.length; i++) {
                    if (this.state.currentConfig.Columns[i].ColumnIndex > index) {
                        this.state.currentConfig.Columns[i].ColumnIndex--;
                    }       
                }

                this.setState({layoutChanged: true});
            }
        }
    }
    
    clearSelectedFieldsn() {
        this.state.currentConfig.Columns = [];
        this.onLayoutChanged();
    }
   
    resetColumnSize(e) {
        var fieldName = $(e.target).parents('.gridcolumn')[0].id
        var column = this.state.currentConfig.Columns.where((c)=>c.FieldName == fieldName).firstOrNull();
        column.ColumnWidth = 0;

        $(e.target).parents('.gridcolumn').removeAttr('style');

        this.onLayoutChanged();
    }
   
    resetColumnSizes() {
        for(var i = 0; i < this.state.currentConfig.Columns.length; i++) {
            this.state.currentConfig.Columns[i].ColumnWidth = 0;
        }

        $(this.refs.gridContainer).find('.gridcolumn').removeAttr('style')

        this.onLayoutChanged();
    }
     
    updateFromRename() {
        this.forceUpdate();
        var gridConfig = quosal.customization.grids[this.props.configuration.gridName];
        quosal.customization.grids.configurationUpdated.call(gridConfig);
        
    }
    // beginFieldRename: function(e) {
    //     if(this.state.fieldBeingRenamed != null) {
    //         this.endFieldRename(true);
    //     }

    //     var fieldName = e.target.attributes['data-field'].value;

    //     this.setState({fieldBeingRenamed: fieldName}, function() {
    //         this.refs.fieldEditor.select();
    //     });
    // },
    // fieldRenameKeyPress: function(e) {
    //     if(e.which == 13) {
    //         this.endFieldRename();
    //     }
    // },
    // endFieldRename: function(suppressStateChange) {
    //     var newName = this.refs.fieldEditor.value;
    //     var gridConfig = quosal.customization.grids[this.props.configuration.gridName];
    //     var fields = quosal.customization.fields[gridConfig.ObjectType][gridConfig.ObjectName];
    //     var fieldConfig = fields.fieldConfigurations[this.state.fieldBeingRenamed];

    //     var fieldConfigExists = (fieldConfig != null);
    //     var thereIsChange = false;
    //     var renameDiffersFromOriginalName = newName != this.state.fieldBeingRenamed;
    //     if (renameDiffersFromOriginalName) {
    //         if (!fieldConfigExists){
    //             thereIsChange = !String.isNullOrEmpty(newName);
    //         } else {
    //             thereIsChange = (newName != (fieldConfig.FieldRename || ''));
    //             if (thereIsChange && newName == '') {
    //                 newName = '[EMPTY]';
    //             }
    //         }
    //     }
    //     if(thereIsChange) {
    //         //Save field configuration
    //         var gridConfig = quosal.customization.grids[this.props.configuration.gridName];

    //         var fieldBeingRenamed = this.state.fieldBeingRenamed;
    //         var saveFieldApi = quosal.api.customization.saveFieldConfiguration(gridConfig.ObjectType, gridConfig.ObjectName, fieldBeingRenamed, newName, undefined, true);
    //         saveFieldApi.finished = function(msg) {
    //             quosal.customization.fields.updateFieldConfiguration(gridConfig.ObjectType, gridConfig.ObjectName, fieldBeingRenamed, msg.fieldConfig);

    //             this.state.savingFields.splice(this.state.savingFields.indexOf(fieldBeingRenamed), 1);
    //             this.setState({savingFields: this.state.savingFields});

    //             quosal.customization.grids.configurationUpdated.call(gridConfig);
    //         }.bind(this);
    //         this.state.savingFields.push(fieldBeingRenamed);
    //         this.setState({savingFields: this.state.savingFields, fieldBeingRenamed: null});
    //         saveFieldApi.call();
    //     }

    //     if(suppressStateChange !== true) {
    //         this.setState({fieldBeingRenamed: null});
    //     }
    // },
    
    deleteLayout() {
        $(this.refs.gridContainer).sortable('option', 'disabled', true);

        var doDelete = function () {
            this.setState({ isDeleting: true });
            quosal.customization.grids.delete(this.state.currentConfig.GridName, this.state.currentConfig.ConfigurationName, function (msg) {
                var newConfig = this.loadLayout('Default');
                quosal.customization.grids.configurationChanged.call(newConfig);
                $(this.refs.gridContainer).sortable('option', 'disabled', false);
                this.setState({currentConfig: newConfig, isDeleting: false, layoutChanged: false});
                Dialog.close();
            }.bind(this));
        }.bind(this);

        Dialog.confirmDelete({
            message: <span>{'Are you sure you want to delete the layout for this grid called '}<b>{this.state.currentConfig.ConfigurationName}</b>{'?'}</span>,
            callback: doDelete
        });
    }
    
    saveLayout() {
        var newName = this.layoutNameRef.current.value;
        var errorMessage;
        if (quosal.customization.grids[this.props.configuration.gridName].Configurations[newName] != null && quosal.customization.grids[this.props.configuration.gridName].Configurations[newName].IsStandardConfiguration) {
            errorMessage = '"' + newName + '"' + ' is a system defined layout and can not be overridden. Please choose a different name.';
            $.quosal.validation.validateField($(this.refs.layoutName), 'error', errorMessage);
        }

        if (this.state.currentConfig.Columns == null || this.state.currentConfig.Columns.length < 1) {
            errorMessage = 'You must include at least one column to save this layout.';
            $.quosal.validation.validateField($(this.refs.layoutName), 'error', errorMessage);
        }        

        if (errorMessage) {
            $('.error').css('position', 'static');
            return errorMessage;
        }

        $(this.refs.gridContainer).sortable('option', 'disabled', true);

        this.setState({
            isSaving: true,
            layoutChanged: false
        });

        var saveApi = quosal.api.customization.saveGridConfiguration(this.state.currentConfig.GridName, this.state.currentConfig.ConfigurationName, this.state.currentConfig.Columns)
        saveApi.finished = function(msg) {
            quosal.customization.grids.update(msg.result);
            $(this.refs.gridContainer).sortable('option', 'disabled', false);

            const isQuoteContentWithQuoteItems = this.state.currentConfig.GridName == 'QuoteContent' && this.state.currentTabId;

            if (DataGridCustomizer.isCKEditorEnabled() && isQuoteContentWithQuoteItems){
                this.executeTabsWrapperCallback(msg.result.ConfigurationName, this.state.currentTabId);
            }
            this.setState({ currentConfig: this.loadLayout(msg.result.ConfigurationName), isSaving: false, layoutChanged: false });

            //If the name changed as part of the save, trigger the configurationChanged event
            quosal.customization.grids.configurationChanged.call(msg.result, this.state.currentTabId);
        }.bind(this);
        saveApi.call();
    }
    
    makeColumnsResizable() {
        var gridContainer = $(this.refs.gridContainer);

        gridContainer.find('.gridcolumn').resizable({
            handles: 'e',
            resize: function(e, ui) {
                var fieldName = ui.element[0].id;
                var column = this.state.currentConfig.Columns.where((c)=>c.FieldName == fieldName).firstOrNull();
                column.ColumnWidth = ui.size.width;
                //ui.element.find('.columnsize span').text(column.ColumnWidth + 'px');
                this.onLayoutChanged();
            }.bind(this),
            start: function(e, ui) {
                var fieldName = ui.element[0].id;
                var column = this.state.currentConfig.Columns.where((c)=>c.FieldName == fieldName).firstOrNull();
                column.ColumnWidth = ui.size.width;
                this.onLayoutChanged();
            }.bind(this),
            stop: function(e, ui) {
                this.onLayoutChanged();
            }.bind(this)
        });
        gridContainer.find('.ui-resizable-e').css('right', -4);//.css('width', 10);
    }
    
    componentDidUpdate() {
        this.makeColumnsResizable();
    }
   
    componentDidMount() {
        if (this.props.componentDidMount) {
            this.props.componentDidMount.call(this);
        }
        $.quosal.quickfilter.wrapfieldsInit();

        var gridContainer = $(this.refs.gridContainer);

        gridContainer.sortable({
            axis: 'x',
            distance: 10,
            handle: '.column-sorter',
            update: function(e, ui) {
                var fieldName = ui.item[0].id;
                var column = this.state.currentConfig.Columns.where((c)=>c.FieldName == fieldName).firstOrNull();
                var newIndex = ui.item.index();

                gridContainer.sortable('cancel');

                if (column == null) {
                    throw 'Invalid column: ' + fieldName;
}

                var prevIndex = column.ColumnIndex;

                for(var i = 0; i < this.state.currentConfig.Columns.length; i++) {
                    var otherCol = this.state.currentConfig.Columns[i];

                    if (column.FieldName == otherCol.FieldName) {
                        continue;
}

                    if (newIndex > prevIndex) {
                        //column moved forward
                        if (otherCol.ColumnIndex <= newIndex && otherCol.ColumnIndex > prevIndex) {
                            otherCol.ColumnIndex--;
}
                    } else if (newIndex < prevIndex) {
                        //column moved backward
                        if (otherCol.ColumnIndex >= newIndex && otherCol.ColumnIndex < prevIndex) {
                            otherCol.ColumnIndex++;
}
                    } else {
                        return; //nothing changed
                    }
                }

                column.ColumnIndex = newIndex;

                this.state.currentConfig.Columns.sort((a,b)=>a.ColumnIndex - b.ColumnIndex);

                this.onLayoutChanged();
            }.bind(this)
        });
        $(gridContainer).disableSelection();

        this.makeColumnsResizable();
    }
    componentWillUnmount() {
        if (this.props.componentWillUnmount) {
            this.props.componentWillUnmount.call(this);
        }
    }
    render() {
        if (this.state.isUserWidthMode) {
            var returnButtonFunction = function () {
                this.setState({
                    isUserWidthMode: false
                });
            }.bind(this);
            var afterSave = function () {
                this.props.onDropdownUpdate();
                this.setState({
                    layoutChanged: false
                })
            }.bind(this);
            return <UserGridWidthCustomizer promptedSaveEvent={this.props.promptedSaveEvent}
                                            configuration={this.props.configuration}
                                            afterSave={afterSave}
                                            onLayoutChanged={this.onLayoutChanged}
                                            returnButtonFunction={returnButtonFunction} ></UserGridWidthCustomizer>
        }

        $.quosal.validation.clear();

        if (!this.state.currentConfig) {
            quosal.log.error('Invalid grid config');
            return <div />;
        }

        if (this.props.promptedSaveEvent) {
            this.props.promptedSaveEvent.unbind().bind(this.saveLayout);
        }

        var gridName = this.props.configuration.gridName;
        var gridConfig = quosal.customization.grids[gridName];
        var allConfigs = quosal.customization.grids[gridName].Configurations;
        var fields = quosal.customization.fields[gridConfig.ObjectType][gridConfig.ObjectName];
        var currentConfig = this.state.currentConfig;
        var gridColumns = [];
        var inputDisabled = this.state.isSaving || this.state.isDeleting

        //Render column editor
        for(var i = 0; i < currentConfig.Columns.length; i++) {
            var col = currentConfig.Columns[i];

            if (this.props.excludeColumns && Array.isArray(this.props.excludeColumns) && this.props.excludeColumns.indexOf(col.FieldName) != -1) {
                continue;
            }

            var fieldConfig = fields.fieldConfigurations[col.FieldName];
            var fieldObj = fields.allFields.where(s => s.FieldName == col.FieldName)[0];
            var displayName = fieldConfig && fieldConfig.FieldShortName || fieldConfig && fieldConfig.FieldRename || col.DisplayName;
            var editMode = this.state.fieldBeingRenamed == col.FieldName;
            var isSaving = this.state.savingFields.indexOf(col.FieldName) >= 0;
            var fieldLabel = (editMode || isSaving) ? null : <div className="columnname" title={col.FieldName + ' (Click to Rename)'} data-field={col.FieldName} onClick={isSaving ? null : EditCustomField.open.bind(this, gridConfig.ObjectType, gridConfig.ObjectName, fieldObj, this.updateFromRename)}>{displayName}</div>;
            // var fieldEdit = (editMode || isSaving) ? <div><input type="text" ref="fieldEditor" disabled={isSaving} className="columnnameeditor" defaultValue={fieldConfig ? fieldConfig.FieldRename : col.FieldName} onBlur={this.endFieldRename} onKeyPress={this.fieldRenameKeyPress} /></div> : null;
            var colStyle = {minWidth:col.ColumnWidth, height: this.props.showReadOnly ? 75 : 45 };

            gridColumns.push(
                <div key={'COL_' + col.FieldName} className="gridcolumn" id={col.FieldName} style={colStyle}>
                    {col.ColumnWidth > 0 ?
                        <div className="columnsize"><img className="columnsizeclear" src="img/svgs/sell/Action_Clear.svg" title="Reset Column Size" onClick={this.resetColumnSize} />
                            <span title={col.DisplayName + ' minimum width is ' + col.ColumnWidth + ' pixels'}>{col.ColumnWidth}</span></div> : null}
                    <div className="column-sorter" />
                    <div className="columnlabel">
                        {isSaving ? <Spinner style={{float: 'right', marginRight: 3, marginTop: -1}} /> : null}
                        {fieldLabel}
                        {((displayName == col.DisplayName) && !editMode) ? '' : <div className="columnoriginalname">({col.FieldName})</div>}
                        {/*{fieldEdit}*/}
                    </div>
                    {this.props.showReadOnly ?
                    <div className="totals">
                        <label><input type="checkbox" onChange={this.readOnlyChanged} value={col.FieldName}
                                      disabled={inputDisabled} className="displaytotal" checked={col.ReadOnly} />Read-Only</label></div> : null}
                    {col.CanDisplayAggregateTotal ?
                        <div className="totals">
                            <label><input type="checkbox" onChange={this.displayTotalChanged} value={col.FieldName}
                                          disabled={inputDisabled} className="displaytotal" checked={col.DisplayAggregateTotal} />Show Total</label></div> : <div className="totals" />}

                </div>
            );
        }

        var gridFields = [];
        var allFields = fields.standardFields.concat(fields.additionalFields);
        allFields.sort(function(a,b) { return a.FieldName.charCodeAt(0) - b.FieldName.charCodeAt(0) });
        if (this.props.configuration.extraConfigurableColumns) {
            function doValsAlternative(obj) { // Object.values
                var res = [];
                if (!obj) return res;
                for (var i in obj) {
                    if (obj.hasOwnProperty(i)) {
                        res.push(obj[i]);
                    }
                }
                return res;
            }

            allFields.splice(0, 0, ...doValsAlternative(this.props.configuration.extraConfigurableColumns));
        }

        //Render field list
        for(var i = 0; i < allFields.length; i++) {
            var field = allFields[i];
            if (field.IsPrivateField || (this.props.excludeColumns && Array.isArray(this.props.excludeColumns) && this.props.excludeColumns.indexOf(field.FieldName) != -1 )) {
                continue;
            }
            var fieldConfig = fields.fieldConfigurations[field.FieldName];
            var gridConfig = quosal.customization.grids[this.props.configuration.gridName];
            var displayName = fieldConfig && fieldConfig.FieldRename || field.DisplayName;
            var fieldSelected = currentConfig.Columns.where((f)=> f.FieldName == field.FieldName).firstOrNull() != null;
            var fieldState = fieldSelected ? 'CHECKED' : '';
            var onDropdownUpdate = function () {
                if (typeof this.props.onDropdownUpdate === 'function') {
                    this.props.onDropdownUpdate();
                }
                this.forceUpdate();
            }.bind(this);

            gridFields.push(
                <CustomizerFieldListItem key={'FIELD_' + field.FieldName} isDisabled={inputDisabled} className={fieldState}
                                         onChange={this.onFieldInclusionChanged} fieldSelected={fieldSelected} field={field} fieldConfig={fieldConfig}
                                         objectType={gridConfig.ObjectType} objectName={gridConfig.ObjectName} onDropdownUpdate={onDropdownUpdate} />
            );
        }

        //Layout dropdown options
        var selectOptions = [];
        for(var name in allConfigs) {
            selectOptions.push(<option key={name} value={name}>{name}</option>);
        }

        var onUserWidthClick = function (e) {
            this.cancelChanges();
            this.setState({ isUserWidthMode: true });
        }.bind(this);

        return (
            <div className="fluidpanels">
                <Panel title="Manage Layouts">
                    <PanelContent>
                        <div className="formcolumn">
                            <div className="formfieldlabel"><label htmlFor="gridselect" className="standardformlabel">Load Layout</label></div>
                            <div className="formselectfieldwrapper">
                                <select className="formselectfield" ref="selectedConfiguration" disabled={inputDisabled} id="gridselect" onChange={this.selectedConfigurationChanged} value={currentConfig.OriginalName}>{selectOptions}</select>
                            </div>
                            <br />
                            <button disabled={inputDisabled || this.state.currentConfig.IsStandardConfiguration} className="layoutDelete" title={this.state.currentConfig.IsStandardConfiguration ? 'Can not delete system defined layouts.' : ''} onClick={this.deleteLayout}>{this.state.isDeleting ? <Spinner /> : null} {this.state.isDeleting ? 'Deleting Layout...' : 'Delete Layout'}</button>
                        </div>
                        {/* 8113067 - Target Tab Name(s) on edit grid layout doesn't appear to do anything. This can be removed if we never determine it's intent. - TJ 7/20/16
                            <div className="formcolumn">
                                <div className="formfieldlabel"><label htmlFor="TargetTabNames"
                                                                       className="standardformlabel">Target Tab
                                    Name(s)</label></div>
                                <div className="formfield"><input type="text" disabled={inputDisabled}
                                                                  name="TargetTabNames" id="TargetTabNames"
                                                                  title="Target Tab Name(s)"/></div>
                            </div>
                        */}
                        <div className="formcolumn" style={{width:'inherit'}}>
                            <div className="formfieldlabel"><label htmlFor="GridLayoutName" className="standardformlabel">Save Layout As</label></div>
                            <div className="formfield"><input type="text" name="GridLayoutName" id="GridLayoutName" disabled={inputDisabled} title="Grid Layout Name" value={this.state.currentConfig.ConfigurationName} onChange={this.onLayoutNameChanged} ref={this.layoutNameRef || "layoutName"}
                                
                            /></div>
                            <br />
                            <div>
                                <button className="save" disabled={!this.state.layoutChanged || inputDisabled } onClick={this.saveLayout}>{this.state.isSaving ? <Spinner /> : null}{this.state.isSaving ? 'Saving Layout...' : 'Save Layout'}</button>
                                <button className="cancel" disabled={!this.state.layoutChanged || inputDisabled } style={{marginLeft:8}} onClick={this.cancelChanges}><span>Cancel Changes</span></button>
                            </div>
                        </div>
                    </PanelContent>
                </Panel>
                <div  style={{marginTop:20}} />
                <Panel>
                    <PanelTitle>
                        <span>Edit Layout</span>
                        <input type="text" disabled={inputDisabled} className="quicksearch_wrapfields" />
                        <button disabled={inputDisabled} onClick={this.clearSelectedFields} style={{margin:'-4px 0 0 6px'}}>Clear Selected Fields</button>
                        <button disabled={inputDisabled} onClick={this.resetColumnSizes} style={{margin:'-4px 0 0 6px'}}>Reset Column Sizes</button>
                        <button disabled={inputDisabled} onClick={onUserWidthClick} style={{margin:'-4px 0 0 6px'}}>Edit Your Personal Column Widths</button>
                    </PanelTitle>

                    <PanelContent>
                        <div id="gridCustomizer" ref="gridContainer">
                            {gridColumns}
                        </div>
                        <div id="itemfields">
                            {gridFields}
                        </div>
                    </PanelContent>
                </Panel>
            </div>
        );
    }
}

class UserGridWidthCustomizer extends React.Component {
    constructor(props) {
        super(props);
        var savedUserWidths = (
           app.settings.user.gridColumnWidths[this.props.configuration.gridName] &&
           app.settings.user.gridColumnWidths[this.props.configuration.gridName][this.props.configuration.configurationName]
       ) || {};
        var updatedWidths = quosal.util.clone(savedUserWidths);
        this.state = {   
            currentConfig: this.props.currentConfig || DataGridCustomizer.loadLayout(this.props.configuration.configurationName || 'Default', this.props.configuration.gridName),
            isDirty: false,
            savedUserWidths: savedUserWidths,
            updatedWidths: updatedWidths
        };
        // This binding is necessary to make `this` work in the callback
        this.onLayoutChanged = this.onLayoutChanged.bind(this);
        this.makeColumnsResizable = this.makeColumnsResizable.bind(this);
        this.resetColumnSize = this.resetColumnSize.bind(this);
        this.save = this.save.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
    }

    static showUserGridWidthCustomizer(configuration, afterSave) {
        var customizerArgs = {
            promptedSaveEvent: quosal.events.create(),
            configuration: configuration,
            afterSave: afterSave
        };

        var componentDidMount = function () {
            this.props.customizerArgs.customizer = this; // `this` will refer to the instance of UserGridWidthCustomizer that did mount
        };
        var componentWillUnmount = function () {
            this.props.customizerArgs.customizer = null; // `this` will refer to the instance of UserGridWidthCustomizer that will unmount
        };
        var customizer = <UserGridWidthCustomizer {...customizerArgs} customizerArgs={customizerArgs}
                                                    componentDidMount={componentDidMount} componentWillUnmount={componentWillUnmount} ></UserGridWidthCustomizer>

        var closeDialog = function () {
            if (this.customizer && this.customizer.state.isDirty) {
                Dialog.confirmDelete({
                    title:'Changes will be lost',
                    width: '400px',
                    links:[
                        {title: 'Save Changes', callback: function() {
                            this.promptedSaveEvent.call();
                            Dialog.closeAll();
                        }.bind(this)},
                        {title: 'Discard Changes', callback: Dialog.closeAll},
                        {title: "Cancel", callback: Dialog.close}
                    ],
                    message: 'You have unsaved changes. Are you sure you want to close the Edit Column Widths window?'
                });
                return true;
            } else {
                Dialog.close();
            }
        }.bind(customizerArgs);

        Dialog.open({
            title: 'Edit Column Widths',
            height:'40%',
            width:'80%',
            resizable: true,
            draggable: true,
            onClose: closeDialog,
            links:[{title: 'Finished', callback: closeDialog}],
            message: customizer
        });
    }   

    onLayoutChanged() {
        if (this.props.onLayoutChanged) {
            this.props.onLayoutChanged();
        }
        this.setState({isDirty: true});
    }

    makeColumnsResizable() {
        var gridContainer = $(this.refs.container);

        gridContainer.find('.gridcolumn').resizable({
            handles: 'e',
            resize: function(e, ui) {
                var fieldName = ui.element[0].id;
                this.state.updatedWidths[fieldName] = ui.size.width;
                this.setState({updatedWidths: this.state.updatedWidths});
                // var column = this.state.currentConfig.Columns.where((c)=>c.FieldName == fieldName).firstOrNull();
                // column.ColumnWidth = ui.size.width;
                // //ui.element.find('.columnsize span').text(column.ColumnWidth + 'px');
                this.onLayoutChanged();
            }.bind(this),
            start: function(e, ui) {
                var fieldName = ui.element[0].id;
                this.state.updatedWidths[fieldName] = ui.size.width;
                this.setState({updatedWidths: this.state.updatedWidths});
                // var column = this.state.currentConfig.Columns.where((c)=>c.FieldName == fieldName).firstOrNull();
                // column.ColumnWidth = ui.size.width;
                this.onLayoutChanged();
            }.bind(this),
            stop: function(e, ui) {
                var fieldName = ui.element[0].id;
                var width = ui.size.width;
                if (width < UserGridWidthCustomizer.minColumnWidth) {
                    width = (width < UserGridWidthCustomizer.hiddenVsMinWidthThreshold) ? 0 : UserGridWidthCustomizer.minColumnWidth
                }
                this.state.updatedWidths[fieldName] = width;
                this.setState({updatedWidths: this.state.updatedWidths});
                this.onLayoutChanged();
            }.bind(this)
        });
        gridContainer.find('.ui-resizable-e').css('right', -4);//.css('width', 10);
    }

    resetColumnSize(fieldName) {
        delete this.state.updatedWidths[fieldName];
        this.setState({
            updatedWidths: this.state.updatedWidths,
            isDirty: true
        });
        this.onLayoutChanged();
    }

    save() {
        var gridName = this.props.configuration.gridName;
        var gridLayoutName = this.props.configuration.configurationName;

        if (!app.settings.user.gridColumnWidths[gridName]) {
            app.settings.user.gridColumnWidths[gridName] = {}
        }
        app.settings.user.gridColumnWidths[gridName][gridLayoutName] = this.state.updatedWidths;

        var saveAPI = quosal.api.customization.saveUserGridColumnWidths(gridName, gridLayoutName, this.state.updatedWidths);
        saveAPI.call();

        if (this.props.afterSave) {
            this.props.afterSave();
        }

        var savedUserWidths = this.state.updatedWidths;
        var updatedWidths = quosal.util.clone(savedUserWidths);
        this.setState({
            isDirty: false,
            updatedWidths: updatedWidths,
            savedUserWidths: savedUserWidths
        });
    }

    cancelChanges() {
        var updatedWidths = quosal.util.clone(this.state.savedUserWidths);
        this.setState({
            isDirty: false,
            updatedWidths: updatedWidths
        });
    }

    componentDidMount() {
        if (this.props.componentDidMount) {
            this.props.componentDidMount.call(this);
        }

        this.makeColumnsResizable();

        if (this.props.promptedSaveEvent) {
            this.props.promptedSaveEvent.unbind().bind(this.save);
        }
    }

    componentWillUnmount() {
        if (this.props.componentWillUnmount) {
            this.props.componentWillUnmount.call(this);
        }
    }

    render() {
        var gridName = this.props.configuration.gridName;
        var gridConfig = quosal.customization.grids[gridName];
        var fields = quosal.customization.fields[gridConfig.ObjectType][gridConfig.ObjectName];
        var currentConfig = this.state.currentConfig;
        var gridColumns = [];

        for(var i = 0; i < currentConfig.Columns.length; i++) {
            var col = currentConfig.Columns[i];
            var fieldConfig = fields.fieldConfigurations[col.FieldName];
            var displayName = fieldConfig && fieldConfig.FieldShortName || fieldConfig && fieldConfig.FieldRename || col.DisplayName;
            var fieldLabel = <div className="columnname" title={displayName} data-field={col.FieldName} >{displayName}</div>;

            var updatedWidth = this.state.updatedWidths[col.FieldName];
            var savedWidth = this.state.savedUserWidths[col.FieldName];

            var hasSavedWidth = (savedWidth != null);
            var hasUpdatedWidth = (updatedWidth != null);
            var width = hasUpdatedWidth ? updatedWidth : col.ColumnWidth;
            if (hasSavedWidth) {
                hasUpdatedWidth = (savedWidth !== updatedWidth);
            }

            var colStyle = {
                width: width || 60,
                height:  45
            };

            var columnSizeClass = 'columnsize';
            if (hasUpdatedWidth) {
                columnSizeClass += ' updatedValue';
            }
            if (hasSavedWidth) {
                columnSizeClass += ' userValue';
            }

            var widthText = width;
            if (hasUpdatedWidth) {
                if (updatedWidth == null) {
                    widthText = '\u00A0use default\u00A0';
                }
            } else if (!hasSavedWidth) {
                widthText = widthText || ' ';
            }
            widthText = widthText || 'hidden';

            gridColumns.push(
                <div key={'COL_' + col.FieldName} className="gridcolumn" id={col.FieldName} style={colStyle}>
                    <div className={columnSizeClass}>
                        { (updatedWidth != null) ? <img className="columnsizeclear" src="img/svgs/sell/Action_Clear.svg" title="Reset to Default Layout Width" onClick={this.resetColumnSize.bind(this, col.FieldName)} /> : null }
                        <span className="widthSetup" title={col.DisplayName + ' minimum width is ' + col.ColumnWidth + ' pixels'}>{widthText}</span>
                    </div>
                    <div>
                        {fieldLabel}
                        {(displayName == col.DisplayName) ? '' : <div className="columnoriginalname">({col.FieldName})</div>}
                    </div>

                </div>
            );
        }
        return (
            <Panel>
                <PanelTitle>
                    These width selections only apply to you, not other users.
                </PanelTitle>
                <PanelContent>
                    <div id="userGridWidthCustomizer" ref="container">{gridColumns}</div>
                    <br/><br/>
                    <div>
                        <span style={{float:'right'}}><button className='save' disabled={!this.state.isDirty} onClick={this.save} >Save Widths</button>
                        <button style={{marginLeft: 10}} disabled={!this.state.isDirty} onClick={this.cancelChanges} >Cancel Changes</button></span>
                        { this.props.returnButtonFunction ? <button style={{float: 'left'}} onClick={this.props.returnButtonFunction}>Return to Editing Default Layout</button> : null}
                    </div>
                </PanelContent>
            </Panel>
        );
    }
}

UserGridWidthCustomizer.minColumnWidth= 20;
UserGridWidthCustomizer.hiddenVsMinWidthThreshold= 15;

class ContentCollapser extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
        // This binding is necessary to make `this` work in the callback
        this.toggleCollapse = this.toggleCollapse.bind(this);        
    }

    height() { 
        return this.props.height || 200; 
    }

    componentDidMount() {
        var height = this.height();

        if (!this.state.active) {
            var componentHeight = $(this.refs.root).outerHeight(false);
            if (componentHeight > height) {
                this.setState({active: true, isCollapsed: true});
            }
        }
    }

    componentDidUpdate(oldProps, oldState) {
       this.componentDidMount();
    }

    toggleCollapse() {
        var wasCollapsed = this.state.isCollapsed;
        var isBecomingCollapsed = !wasCollapsed;
        this.setState({isCollapsed: isBecomingCollapsed});
    }
    
    render() {
        var height = this.height();
        var style = {};
        var subStyle = {};
        if (this.state.active) {
            style.position = 'relative';
            style.height = '100%';

            subStyle.position = 'relative';
            subStyle.height = 'calc(100% - 10px)';
            subStyle.marginBottom = 10;

            if (this.state.isCollapsed) {
                style.maxHeight = height;
                style.height = height;
            }
            style.position = 'relative';
            style.overflow = 'hidden';
            subStyle.overflowY = 'hidden';
        }

        return (
            this.state.active
                ?
                <div key="active" ref="root" style={style}>
                    <div style={subStyle}>
                    {this.props.children}
                    </div>
                    <CollapseButton isCollapsed={this.state.isCollapsed} toggleCollapse={this.toggleCollapse} style={{position:'absolute'}}></CollapseButton>
                </div>
                :
                <div key="normal" ref="root" >{this.props.children}</div>
        );
    }
}