import { QuoteHeaderContainer } from "../../classes/quote/newUI";
import { DialogManager } from "js/jsx/src/classes/dialog.jsx";

export default function quoteHeaderWrapper(props) {
    const hidePdfViewButton = true;
    quosal.ui.showMask(1);
    const renderQuoteHeader = () => {
        app.currentUser && quosal.ui.react.render(
            <QuoteHeaderContainer
                isLegacyPage={true}
                quote={app.currentQuote}
                hidePdfViewButton={hidePdfViewButton}
            />,
            document.getElementById("quoteHeader")
        );
    };

    const updateQuoteHeader = () => {
        app.currentUser && quosal.ui.react.update(
            <QuoteHeaderContainer isLegacyPage={true} quote={app.currentQuote} hidePdfViewButton={hidePdfViewButton} />,
            document.getElementById("quoteHeader")
        );
    };

    var updateQuote = function () {
        if (app.currentQuote && app.currentUser) {
            quosal.ui.hideMask(true);
            quosal.ui.react.render(<DialogManager />, document.getElementById('dialogManager'));
            updateQuoteHeader();
        }
    };

    var loadDashboard = function () {
        quosal.ui.hideMask(true);
        renderQuoteHeader();
        quosal.ui.react.render(<DialogManager />, document.getElementById('dialogManager'));

    }
    quosal.sell.quote.onUpdate.unbind(updateQuote).bind(updateQuote);
    quosal.sell.modules.onAfterLoad.unbind(updateQuote).bind(updateQuote);
    if (app.currentQuote == null) {
        quosal.ui.react.render(<FormPlaceholder message="Loading Quote..." />, document.getElementById('quoteHeader'));
        const parsed_response = JSON.parse(props) || {};
        const curr_quote = quosal.sell.quote.initQuote({ ...parsed_response })
        app.currentQuote = curr_quote;
        loadDashboard();
    } else {
        loadDashboard();
    }
    document.getElementById('maindialog').addEventListener('click', function (event) {
        if (event.target.matches("#maindialog .links a.link")) {
            $.quosal.dialog.hide();
        }
    });
}

global.quoteHeaderWrapperController = quoteHeaderWrapper;
