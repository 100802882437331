quosal.api.data = {};


//==========================================================//
//* Query                                                  *//
//==========================================================//
//      queries: A collection of one or more data queries.
//==========================================================//
quosal.api.data.query = function (queries) {
    if (!queries.length)
        queries = [queries];
    return quosal.api.call('Data.Query', {
        queries: queries
    });
};


//=================================================================================//
//* Describe                                                                      *//
//=================================================================================//
//      objectType: The type of object to describe. Use BO for BusinessObjects     //
//      objectName: The name of object to describe.                                //
//      includeDataMembers: Whether to include database fields. Default is true    //
//      includeCustomMembers: Whether to include non-data fields. Default is false //
//=================================================================================//
quosal.api.data.describe = function (objectType, objectName, includeDataMembers, includeCustomMembers) {
    var apiCall = quosal.api.call('Data.Describe', {
        objectType: objectType,
        objectName: objectName
    });

    if (includeDataMembers != null)
        apiCall.includeDataMembers = includeDataMembers;

    if (includeCustomMembers != null)
        apiCall.includeCustomMembers = includeCustomMembers;

    return apiCall;
};


//==========================================================//
//* Create                                                 *//
// isDellQuoteOperation: if set to true, the server would expect the
// client to pass encoded data within objects parameter. Defaults to false.
// #9652088 currently only encoding "ItemNotesPlain" and "LongDescription"      
//
//==========================================================//
quosal.api.data.create = function (objects, idQuoteMain, isDellQuoteOperation) {
    if (!objects.length)
        objects = [objects];
    var apiCall= quosal.api.call('Data.Create', {
        objects: objects,
        idQuoteMain: idQuoteMain,
    });
    if (isDellQuoteOperation != null) {
        apiCall.isDellQuoteOperation = isDellQuoteOperation;
    };
    return apiCall;
};


//==========================================================//
//* Delete                                                 *//
//==========================================================//
quosal.api.data.delete = function (queries, idQuoteMain, fullQuoteReturn = false) {
    if (!queries.length)
        queries = [queries];

    return quosal.api.call('Data.Delete', {
        queries: queries,
        idQuoteMain: idQuoteMain,
        fullQuoteReturn
    });
};


//==========================================================//
//* Update                                                 *//
//==========================================================//
quosal.api.data.update = function (updates, idQuoteMain) {
    if (!updates.length)
        updates = [updates];

    return quosal.api.call('Data.Update', {
        updates: updates,
        idQuoteMain: idQuoteMain
    });
};

//==========================================================//
//* Update Partial                                         *//
//==========================================================//
quosal.api.data.updatePartial = function (updates, idQuoteMain) {
    if (!updates.length)
        updates = [updates];

    return quosal.api.call('Data.PartialUpdate', {
        updates: updates,
        idQuoteMain: idQuoteMain
       
    });
};