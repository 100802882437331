import {EditCustomField} from "js/jsx/src/classes/editCustomFields.jsx";

export class RenameAndCustomizeFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterChanges: RenameAndCustomizeFields.searchFilter,
        };

        // This binding is necessary to make `this` work in the callback
        this.getFieldDisplayName = this.getFieldDisplayName.bind(this);
        this.search = this.search.bind(this);
        this.filterFields = this.filterFields.bind(this);
        this.updateFields = this.updateFields.bind(this);
        this.sortFieldArray = this.sortFieldArray.bind(this);
        this.getFields = this.getFields.bind(this);
        this.getFieldRows = this.getFieldRows.bind(this);
        this.sortHeader = this.sortHeader.bind(this);
    }
    static searchTable(filter){

            var upperCaseFilter = filter.toUpperCase();
            var rows = document.querySelector("#remaptable tbody").rows;
                    
            for (var i = 0; i < rows.length; i++) {
                var firstCol = rows[i].cells[0].textContent.toUpperCase();
                var secondCol = rows[i].cells[1].textContent.toUpperCase();
                var thirdCol = rows[i].cells[2].textContent.toUpperCase();

                if (firstCol.indexOf(upperCaseFilter) > -1 || secondCol.indexOf(upperCaseFilter) > -1 || thirdCol.indexOf(upperCaseFilter) > -1) {
                    rows[i].style.display = "";
                } else {
                    rows[i].style.display = "none";
                }
            }
            RenameAndCustomizeFields.searchTerm = filter;
    }
    UNSAFE_componentWillMount(){
        this.setState({fieldArray:this.getFields()},
                        () => {this.setState({filterTerm: RenameAndCustomizeFields.searchTerm}, RenameAndCustomizeFields.searchTable(RenameAndCustomizeFields.searchTerm) );}
        );
    }
    componentDidMount(){
        var sortIconDir = RenameAndCustomizeFields.headerFilter[RenameAndCustomizeFields.headerFilter.colindex] == 1 ? "headerSortDown" : "headerSortUp";
        $($("#remaptable th")[RenameAndCustomizeFields.headerFilter.colindex]).addClass(sortIconDir)
    }
    getFieldDisplayName(fieldName){
          return quosal.customization.fields.getFieldDisplayName("BusinessObject", this.props.objectName, fieldName)
    }
    search(e){
        RenameAndCustomizeFields.searchTable(e.target.value);
        this.setState({filterTerm : RenameAndCustomizeFields.searchTerm});
    }
    filterFields(){
        RenameAndCustomizeFields.searchFilter = !RenameAndCustomizeFields.searchFilter;
        this.setState({fieldArray:this.getFields()},
                    () => {RenameAndCustomizeFields.searchTable(RenameAndCustomizeFields.searchTerm)}
                       
        );
    }
    updateFields(){
        this.setState({fieldArray:this.getFields()});
    }
    sortFieldArray(fieldArray){
        var me = this;
        var dir = RenameAndCustomizeFields.headerFilter[RenameAndCustomizeFields.headerFilter.colindex]

        fieldArray.sort(function(a, b) { 
           
            var aDisplayName = me.getFieldDisplayName(a.field.FieldName);
            var bDisplayName = me.getFieldDisplayName(b.field.FieldName);

            if(RenameAndCustomizeFields.headerFilter.colindex == 1){
                aDisplayName = a.field.FieldName;
                bDisplayName = b.field.FieldName;
            } 
            else if(RenameAndCustomizeFields.headerFilter.colindex == 2){
                
                if(a.fieldConfig.FieldShortName === undefined || a.fieldConfig.FieldShortName === ""){
                    return 1
                }
                if(b.fieldConfig.FieldShortName === undefined || b.fieldConfig.FieldShortName === ""){
                    return -1;
                }
                aDisplayName = a.fieldConfig.FieldShortName;
                bDisplayName = b.fieldConfig.FieldShortName;
            }

            else if(RenameAndCustomizeFields.headerFilter.colindex == 3){
                
                if(a.fieldConfig.Note === undefined || a.fieldConfig.Note === ""){
                    return 1
                }
                if(b.fieldConfig.Note === undefined || b.fieldConfig.Note === ""){
                    return -1;
                }
                aDisplayName = a.fieldConfig.Note;
                bDisplayName = b.fieldConfig.Note;
            }
           
            var textA = aDisplayName.toUpperCase();
            var textB = bDisplayName.toUpperCase();
            return (textA < textB) ? -1 * dir : (textA > textB) ? 1 * dir : 0;
        });
    }
    getFields(){
        var me = this;
        var objectName = this.props.objectName;
        var fieldObj = quosal.schema.BusinessObject[objectName];
        var fields = quosal.customization.fields.BusinessObject[objectName];
        var allFields = fields.standardFields.concat(fields.additionalFields)
       
        var fieldArray = [];
        for(var fieldObj in allFields){
            var field = allFields[fieldObj];

            var fieldConfig = fields.fieldConfigurations[field.FieldName];
           
            if(RenameAndCustomizeFields.searchFilter && !fieldConfig){
                continue;
            }

             if(!fieldConfig){
                fieldConfig = {};
            }
            
            var displayName = fieldConfig && fieldConfig.FieldRename || field.DisplayName;
            if(displayName == undefined){
                continue;
            }
            
            
            fieldArray.push({field:field, fieldConfig:fieldConfig});
        }
        this.sortFieldArray(fieldArray);
        return fieldArray;
    }
    getFieldRows(){
        var me = this;
        return this.state.fieldArray.map(function(fieldObj, index){
            var nochange;
            var displayName = me.getFieldDisplayName(fieldObj.field.FieldName);
            
            if(!fieldObj.fieldConfig.FieldRename){
                nochange = "nochange"
            } 
            nochange += " content fieldname";
            return (
                <tr className="customizefield" style={{margin:"4px 0"}} key={index} onClick={EditCustomField.open.bind(this, "BusinessObject", me.props.objectName, fieldObj.field, me.updateFields)}>
                    <td className={nochange}>{displayName}</td>
                    <td className="content" >{fieldObj.field.DisplayName}</td>
                    <td className="content">{fieldObj.fieldConfig.FieldShortName}</td>
                    <td className="content">{fieldObj.fieldConfig.Note}</td>
                </tr>
            );
        });
    }  
    sortHeader(e){
        var element = $(e.target);
        var column = e.target.cellIndex;
        
        element.siblings().each(function(){
                $(this).removeClass()
            }
        )
        
        element.removeClass(); 
        RenameAndCustomizeFields.headerFilter[column] *= -1;
        RenameAndCustomizeFields.headerFilter.colindex = column;
        
        var sortIconDir = RenameAndCustomizeFields.headerFilter[column] == 1 ? "headerSortDown" : "headerSortUp";
        element.addClass(sortIconDir);
        
        this.updateFields();
       
    }
    render() {
        return (
            <div className="customizefields" id="contentPanelContainer">
                <Panel title="Rename and Customize Fields">
                    <div style={{margin:"10px 0 0 5px"}}>
                        <input type="text" placeholder="Type to filter fields..." id="searchInput" onChange={this.search} value={this.state.filterTerm} />
                        <input type="checkbox" style={{verticalAlign:'middle', marginLeft:8}} checked={RenameAndCustomizeFields.searchFilter} onChange={this.filterFields} /><label className="formlabel" style={{marginLeft:2}}>Show Renamed Only</label>
                    </div>
                    <PanelContent>
                        <table id="remaptable" className="datagrid tablesorter" style={{fontSize: '12px'}}>
                            <colgroup>
                                <col style={{width:"30%"}}/>
                                <col style={{width:"30%"}}/>
                                <col style={{width:"20%"}}/>
                                <col style={{width:"20%"}}/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th onClick={this.sortHeader} width="1">Field Name</th>
                                    <th onClick={this.sortHeader} width="1">Original Name</th>
                                    <th onClick={this.sortHeader} width="1" >Short Name</th>
                                    <th onClick={this.sortHeader} width="1">Reason For Previous Change</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.getFieldRows()}
                            </tbody>
                        </table>
                    </PanelContent>
                </Panel>
            </div>
        );
    }
}

RenameAndCustomizeFields.searchTerm = "";
RenameAndCustomizeFields.searchFilter = false;
RenameAndCustomizeFields.headerFilter = {colindex: 0, 0: 1, 1: -1, 2: -1, 3: -1 };