import moment from "moment/moment";
import { CommonLinkStyled, CommonTypography, CustomWidthTooltip } from "../styledComponents/commonStyled";

export const DEBOUNCE = (callback, delay = 400) => {
    let timer;

    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { callback.apply(this, args); }, delay);
    }
};

const DATE_FORMAT = "MM/DD/yyyy";
export const ELLIPSIS_STYLE = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };

export const SUBMITTED_FORMS_TEXT_SIZE = {
    fontSize: '0.8rem',
};

export const SUBMITTED_FORMS_COMMON_HEADERS = (customRenderHeader, onClick) => {
    return ([
        {
            field: 'formName',
            headerName: 'Form Name',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <CustomWidthTooltip title={params.value}>
                        <CommonLinkStyled sx={{ cursor: "pointer", ...ELLIPSIS_STYLE, ...SUBMITTED_FORMS_TEXT_SIZE }} onClick={() => onClick(params)}>{params.value}</CommonLinkStyled>
                    </CustomWidthTooltip>
                )
            },
            renderHeader: customRenderHeader,
            disableColumnMenu: true,
        },
        {
            field: 'salesTrackName',
            headerName: 'Sales Track Name',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <>
                        <img src={params.row.salesTrackPictureLink || 'images/empty.png'} alt="Submitted Salestrack image preview" className="submittedSalestrack-preview-image" />
                        <CustomWidthTooltip title={params.value}>
                            <CommonTypography sx={{ p: '0.94rem 1rem', ...ELLIPSIS_STYLE, ...SUBMITTED_FORMS_TEXT_SIZE }}>{params.value}</CommonTypography>
                        </CustomWidthTooltip>
                    </>
                )
            },
            renderHeader: customRenderHeader,
            disableColumnMenu: true,
        },
        {
            field: 'submittedDate',
            headerName: 'Date of Submission',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {

                const dateFormatted = moment(params.value).format(DATE_FORMAT);

                return (
                    <>
                        <CommonTypography sx={{ p: '0.94rem 0', ...SUBMITTED_FORMS_TEXT_SIZE }}>{dateFormatted}</CommonTypography>
                    </>
                )
            },
            renderHeader: customRenderHeader,
            disableColumnMenu: true,
        },
        {
            field: 'submittedBy',
            headerName: 'Submitted By',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <CustomWidthTooltip title={params.value}>
                        <CommonTypography sx={{ p: '0.94rem 0', ...ELLIPSIS_STYLE, ...SUBMITTED_FORMS_TEXT_SIZE }}>{params.value}</CommonTypography>
                    </CustomWidthTooltip>
                )
            },
            renderHeader: customRenderHeader,
            disableColumnMenu: true,
        },
    ])
};