// import {MicroUiLoader} from "dist/main"; //uncomment after updating the webpack configs resolve object to include 
//the modified micro ui loader this is a sample route "C:\\microui\\micro-ui-loader-application",
// import {MicroUiLoader} from "micro-ui-loader"; //commenting out until asio udpates to support node 19+

export class MicroUiWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: "",
        };
    }

    componentDidMount() {
        var getAccessToken = quosal.api.oauth.getAccessToken("CWSSO");
        getAccessToken.finished = (msg) => {
            this.setState({ token: msg.accessToken })
        };
        getAccessToken.call();    
    }

    render() {
        return (
            <div id="cpq-test-container">
                <div>Micro ui loader is disabled</div>
                {/* <MicroUiLoader
                    packageKey="cpq-test"
                    divId="cpq-test-container"
                    tokenData={{
                        tokenType:"cwSSO",
                        environment:app.settings.global.MicroUIServiceEnvironment ?? "production",
                        token:this.state.token
                    }}
                /> */}
            </div>
        );
    }
}
global.MicroUiWrapper = MicroUiWrapper;