import { useEffect, useState, useMemo } from "react";
import { produce } from "immer";
import { Box, Checkbox, FormControlLabel, FormGroup, Typography, Grid } from "@mui/material";
import { useUserSettingsContext, useGlobalSettingsContext } from "../context";
import { getPackageLevels } from "../utils";
import { ALERT_MIN_ONE_METRIC_TOBE_SELECT } from "../common/constant";
import { CustomAlert } from "../common/components";

export const QuoteHeaderMetricsCheckboxes = ({
	setShowQhMetrics,
	showQhMetrics,
	setNoMetricSelected,
	noMetricSelected
}) => {
	const subHeading = "Select quote header metrics you want to display";
	const [selectAllMetrics, setSelectAllMetrics] = useState(true);
	const { hideProfit } = useUserSettingsContext();
	const { packageLevel } = useGlobalSettingsContext();
	const packageLevels = getPackageLevels();


	const isNonPremiumPartner = packageLevel !== packageLevels?.premium;
	const metricsCheckboxes = [
		{
			name: "showOneTimeTotal",
			label: "One Time Total",
			checked: showQhMetrics.showOneTimeTotal,
			isHidden: false
		},
		{
			name: "showOneTimeGrossProfit",
			label: "One Time Gross Profit",
			checked: showQhMetrics.showOneTimeGrossProfit,
			isHidden: hideProfit
		},
		{
			name: "showRecurring",
			label: "Recurring",
			checked: showQhMetrics.showRecurring,
			isHidden: false
		},
		{
			name: "showRecurringGrossProfit",
			label: "Recurring Gross Profit",
			checked: showQhMetrics.showRecurringGrossProfit,
			isHidden: hideProfit
		},
		{
			name: "showTotalQuoteValue",
			label: "Total Quote Value",
			checked: showQhMetrics.showTotalQuoteValue,
			isHidden: false
		},
		{
			name: "showDownpayment",
			label: "Down Payment Amount",
			checked: showQhMetrics.showDownpayment,
			isHidden: isNonPremiumPartner
		}
	];

	const tranformMetricChechboxes = useMemo(() => metricsCheckboxes.reduce((acc, metric) => {
		acc[metric.name] = { isHidden: metric.isHidden };
		return acc;
	}, {}), [hideProfit]);

	useEffect(() => {
		Object.entries(showQhMetrics).some(([name, checked]) => checked && !tranformMetricChechboxes[name].isHidden) ? setNoMetricSelected(false) : setNoMetricSelected(true);
	}, [showQhMetrics, tranformMetricChechboxes]);

	const checkBoxStyles = {
		padding: "5px",
		"& .MuiSvgIcon-root": { fontSize: "20px" }
	};

	const handleShowMetricsChange = ({ target }) => {
		setShowQhMetrics(
			produce((draft) => {
				draft[target.name] = target.checked;
			})
		);
	};

	const handleSelectAllMetricsChange = ({ target }) => {
		setSelectAllMetrics(target.checked);
		setShowQhMetrics((prevState) => ({
			...prevState,
			showOneTimeTotal: target.checked,
			showOneTimeGrossProfit: target.checked,
			showRecurring: target.checked,
			showRecurringGrossProfit: target.checked,
			showTotalQuoteValue: target.checked,
			showDownpayment: target.checked
		}));
	};

	const areAllmetricsSelected = Object.values(showQhMetrics).every((value) => value);

	useEffect(() => {
		setSelectAllMetrics(areAllmetricsSelected);
	}, [areAllmetricsSelected]);

	return (
		<Grid container gap="24px" display={"flex"} flexDirection={"column"}>
			<Typography>{subHeading}</Typography>
			<Grid gap="16px" display={"flex"} flexDirection={"column"}>
				{noMetricSelected &&
					(<Grid item data-testid = "test-alert-min">
						<CustomAlert 
						showAlertTitle={false} 
						width="100%"  
						message={ALERT_MIN_ONE_METRIC_TOBE_SELECT} 
						severity="error"
						sx = {{
							boxShadow: "0px 4px 8px 0px #0000001A",
							borderRadius: "6px",
							gap: "4px",
							height: "48px",
						}}
						/>
					</Grid>
				)}

				<Grid item>
					<FormGroup>
						<Box>
							<FormControlLabel
								name="selectAllMetrics"
								checked={selectAllMetrics}
								onChange={handleSelectAllMetricsChange}
								control={<Checkbox sx={checkBoxStyles} />}
								label={<Typography>Select All</Typography>}
								sx={{ marginLeft: "-6px" }}
								data-testid = "select-all-metrics"
							/>
						</Box>
						<Box sx={{ display: "flex", flexFlow: "row wrap", marginTop: "10px" }}>
							{metricsCheckboxes.map(({ name, label, checked, isHidden }, index) =>
								isHidden ? null : (
									<FormControlLabel
										key={name}
										name={name}
										checked={checked}
										onChange={handleShowMetricsChange}
										control={<Checkbox sx={checkBoxStyles} />}
										label={<Typography>{label}</Typography>}
										sx={{ width: "186px", marginLeft: "-6px" }}
										data-testid = {`metrics-checkbox-${index}`}
									/>
								)
							)}
						</Box>
					</FormGroup>
				</Grid>
			</Grid>
		</Grid>
	);
};
