import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCard = styled(Paper)(({ theme, height}) => ({
	display: "flex",
	flexDirection: "column",
	padding: theme.spacing(2),
	height: height,
	color: theme.palette.text.primary,
	margin: 0,
	borderRadius: 8,
	fontSize: 14
}));

export { StyledCard };
