import { SalesTracksForms } from "js/jsx/src/classes/product/salesTracksForms.jsx";

export default function salesTracksForms() {
    var renderPage = function (renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;
        renderMethod(
            <SalesTracksForms />,
            document.getElementById('salesTracksContainer')
        );
    };
    var loadPage = function () {

        renderPage(quosal.ui.react.render);

        $('#pageName').val('product.salestracksforms');
    };

    loadPage();
}