if (!quosal.api.crm)
quosal.api.crm = {};
if(!quosal.api.crm.autotask)
quosal.api.crm.autotask = {};

quosal.api.crm.autotask.getOpportunities = function (criteria, pageNumber, pageSize, additionalParameters) {
    return quosal.api.call('Crm.Autotask.GetOpportunities', {
        criteria: criteria,
        pageNumber: pageNumber,
        pageSize: pageSize,
        additionalParameters: additionalParameters || {}
    });
};

quosal.api.crm.autotask.getOpportunityMetadata = function (additionalParameters) {
    return quosal.api.call('Crm.Autotask.GetOpportunityMetadata', {
        additionalParameters: additionalParameters || {}
    });
};