/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, forwardRef, useImperativeHandle } from "react";

import Popover from "@mui/material/Popover";
import CloseIcon from "@mui/icons-material/Close";
import {
	IconButton,
	Box,
	Checkbox,
	FormControlLabel,
	Tooltip,
	SvgIcon,
	Typography,
	CircularProgress,
	FormControl,
	Select,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	Button
} from "@mui/material";

import usePriceSorceModalContainer from "./PriceSourceModalContainer";
import usePriceSourceModalStyle from "./PriceSourceModalStyle";
// eslint-disable-next-line react/display-name
const PriceSourceModalComponent = forwardRef((props, ref) => {
	const {
		darkMode,
		completedSources,
		searchingSources,
		selectedGroup,
		menuItems,
		dialogClasses,
		customSourceList,
		selectedResult,
		updating,
		dialogOpen,
		customSourceItems,
		selectedCustomSources,
		isOpen,
		modalData,
		setIsOpen,
		setModalData,
		priceResultSelected,
		warehouseChanged,
		handleDropdownChange,
		handleDialogOpen,
		updateSource,
		handleDialogClose,
		customSourcesSelected,
		resetCustomGroup,
		updateCustomGroup
	} = usePriceSorceModalContainer(props);

	const {
		titleBlockStyle,
		priceSourceTitleStyle,
		toolBarStyle,
		inputLabelStyle,
		sourceRadioButtonStyle,
		divStyle,
		selectFieldWrapperStyle,
		sourceGroupSelectionStyle,
		gearIcomStyle,
		actionButtonsStyle,
		cancelButtonStyle,
		updateButtonStyle
	} = usePriceSourceModalStyle(darkMode, selectedResult, updating);

	useImperativeHandle(ref, () => ({
		showModal(isShown, data) {
			setIsOpen(isShown);
			setModalData({ ...modalData, ...data });
		}
	}));

	const { id, anchorEl, grid } = modalData;

	return (
		isOpen && (
			<Box>
				<Popover
					id={id}
					open={isOpen}
					anchorEl={anchorEl}
					onClose={() => setIsOpen(false)}
					sx={{ minWidth: 359, minHeight: 284, maxWidth: 400 }}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
				>
					<Box style={{ minWidth: "359px", borderRadius: "8px", maxWidth: "400px" }}>
						<div className="title" style={titleBlockStyle}>
							<span style={priceSourceTitleStyle}>Price Sources</span>
							{app.currentUser.IsAdministrator &&
							quosal.validation.isPackageLevelAuthorized(
								app.packageLevels.standard
							) ? (
								<div className="toolbar" style={toolBarStyle}>
									<a
										href={quosal.util.url(
											"adminsourcegroups.quosalweb",
											`quotereturnurl=${encodeURIComponent(location.href)}`
										)}
									>
										<Tooltip title="Configure Source Groups">
											<SvgIcon style={gearIcomStyle}>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 48 48"
												>
													<path
														fill="none"
														d="M0 0h48v48H0V0zm0 0h48v48H0V0z"
													/>
													<path d="M41.9 30.1L40 27c-.1-.2-.4-.3-.6-.2l-2.3.9c-.5-.4-1-.7-1.6-.9l-.4-2.4c0-.2-.2-.4-.5-.4h-3.7c-.2 0-.4.2-.5.4l-.4 2.4c-.6.2-1.1.5-1.6.9l-2.3-.9c-.2-.1-.5 0-.6.2l-1.9 3.1c-.1.2-.1.4.1.6l2 1.5c0 .3-.1.6-.1.9s0 .6.1.9l-2 1.5c-.2.1-.2.4-.1.6l1.9 3.1c.1.2.4.3.6.2l2.3-.9c.5.4 1 .7 1.6.9l.4 2.4c0 .2.2.4.5.4h3.7c.2 0 .4-.2.5-.4l.4-2.4c.6-.2 1.1-.5 1.6-.9l2.3.9c.2.1.5 0 .6-.2l1.9-3.1c.1-.2.1-.4-.1-.6l-2-1.5c0-.3.1-.6.1-.9s0-.6-.1-.9l2-1.5c.2-.2.3-.4.1-.6zm-8.9 6c-1.8 0-3.2-1.4-3.2-3.1s1.5-3.2 3.2-3.2c1.8 0 3.2 1.4 3.2 3.2 0 1.8-1.4 3.1-3.2 3.1zM42 8v10c0 1.1-.9 2-2 2s-2-.9-2-2v-8H27v8h-6v-8H10v26h8c1.1 0 2 .9 2 2s-.9 2-2 2H8c-1.1 0-2-.9-2-2V8c0-1.1.9-2 2-2h32c1.1 0 2 .9 2 2z" />
												</svg>
											</SvgIcon>
										</Tooltip>
									</a>
								</div>
							) : null}
						</div>
						<div className="content">
							<form>
								<div id="pricing">
									{completedSources && completedSources.length
										? completedSources.map((source, index) => (
												<Box
													key={`${source.source.SourceName}-result`}
													id={`${source.source.SourceName}-result`}
													className="productprice"
													style={{
														padding: 10,
														maxHeight: "300px",
														overflow: "auto"
													}}
												>
													<input
														type="radio"
														name="selectedsource"
														id={`${source.source.SourceId}_id`}
														value={source.source.SourceId}
														style={sourceRadioButtonStyle}
														onChange={() => priceResultSelected(source)}
														checked={source.isSelected}
													/>
													<Typography
														className="sourcename"
														style={{ marginLeft: "4px" }}
													>
														{source.sourceName}:{" "}
													</Typography>
													<Typography className="price">
														{" "}
														{app.currentQuote.formatCurrency(
															source.pricing.cost
														)}
													</Typography>
													<Typography className="available">
														({source.pricing.qty}/
														{source.pricing.backorder})
													</Typography>
													<FormControl
														className="warehouses"
														style={{
															display: source.isSelected
																? "block"
																: "none"
														}}
													>
														{source.warehouses &&
														source.warehouses.length
															? source.warehouses.map((warehouse) => (
																	<div
																		className="warehouse"
																		key={
																			warehouse.warehouse.code
																		}
																		id={
																			warehouse.warehouse.code
																		}
																		style={{
																			paddingTop: "5px",
																			paddingLeft: "10px",
																			paddingBottom: "5px",
																			paddingRight: "10px"
																		}}
																	>
																		<input
																			type="radio"
																			name="selectedwarehouse"
																			id={`${warehouse.warehouse.name}_id`}
																			value={
																				warehouse.warehouse
																					.name
																			}
																			style={
																				sourceRadioButtonStyle
																			}
																			onChange={(event) =>
																				warehouseChanged(
																					source.source
																						.SourceId,
																					event
																				)
																			}
																			checked={
																				warehouse.isChecked
																			}
																		/>
																		<Typography
																			className="name"
																			style={{
																				marginLeft: "4px"
																			}}
																		>
																			{" "}
																			{
																				warehouse.warehouse
																					.name
																			}
																		</Typography>
																		<Typography className="available">
																			(
																			{
																				warehouse.warehouse
																					.qty
																			}
																			/
																			{
																				warehouse.warehouse
																					.backorder
																			}
																			)
																		</Typography>
																	</div>
															  ))
															: null}
													</FormControl>
												</Box>
										  ))
										: null}
									<div
										className="pending"
										style={{
											padding: 10,
											maxHeight: "300px",
											overflow: "auto"
										}}
									>
										{searchingSources && searchingSources.length
											? searchingSources.map((source) => (
													<Box
														style={{ marginTop: "3px" }}
														key={source.SourceId}
													>
														<CircularProgress
															size={10}
															style={{
																marginRight: "5px",
																marginLeft: "5px"
															}}
														/>
														<span>
															Searching {source.FormattedName}...
														</span>
													</Box>
											  ))
											: null}
									</div>
								</div>
								<div className="sourceGroupContainer">
									<input
										type="hidden"
										id="customSourceGroup"
										name="customSourceGroup"
									/>
									<div className="standardformfieldlabel">
										<label
											htmlFor="searchmfp"
											className="standardformlabel"
											style={inputLabelStyle}
										>
											Product Sources
										</label>
									</div>
									<div style={divStyle}>
										<div
											className="formselectfieldwrapper"
											style={selectFieldWrapperStyle}
										>
											<Select
												value={selectedGroup}
												onChange={handleDropdownChange}
												style={sourceGroupSelectionStyle}
											>
												{!grid.state.priceSourcesLoaded ? (
													<CircularProgress size={20} />
												) : (
													menuItems.map((item) => item)
												)}
											</Select>
										</div>
									</div>
									{selectedGroup === "custom" ? (
										<div
											style={{
												padding: "5px",
												width: "95%",
												overflowWrap: "break-word"
											}}
										>
											<div
												component="div"
												id="customSourcesList"
												className={dialogClasses}
												sx={{
													p: 2,
													whiteSpace: "normal",
													wordWrap: "break-word"
												}}
												onClick={handleDialogOpen}
											>
												{customSourceList}
											</div>
										</div>
									) : null}
								</div>
							</form>
						</div>
						<div style={actionButtonsStyle}>
							<Button
								id="secondaryButtonId"
								style={cancelButtonStyle}
								onClick={() => setIsOpen(false)}
								sx={{
									...(darkMode
										? { color: "var(--dark-mode-blue-color) !important" }
										: { color: "#2E3F80 !important" })
								}}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								id="primaryButtonId"
								style={updateButtonStyle}
								autoFocus
								onClick={updateSource}
								disabled={!selectedResult || updating}
								sx={{
									...(darkMode
										? {
												backgroundColor:
													"var(--dark-mode-blue-color) !important"
										  }
										: { backgroundColor: "#2E3F80 !important" })
								}}
							>
								{updating ? "Updating" : "Update"}
								{updating ? (
									<CircularProgress
										sx={{ color: "white" }}
										style={{ marginLeft: "5px" }}
										size={20}
									/>
								) : (
									""
								)}
							</Button>
						</div>
					</Box>
				</Popover>
				<Dialog
					open={dialogOpen}
					onClose={handleDialogClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					fullWidth
					maxWidth="100%"
					id="CKEditor_deleteDialog"
					className={dialogClasses}
				>
					<DialogTitle sx={{ m: 0, p: 2 }} id="alert-dialog-title">
						Custom Source Group
						<IconButton
							aria-label="close"
							onClick={handleDialogClose}
							sx={{
								position: "absolute",
								right: 8,
								top: 8,
								minWidth: "auto",
								color: (theme) => theme.palette.grey[500]
							}}
						>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						{customSourceItems.map((row, rowIndex) => (
							<Grid container spacing={2} key={`custom${rowIndex}`}>
								{row.map((src, index) =>
									src.SourceId ? (
										<Grid item xs key={src.SourceId + rowIndex}>
											<FormControlLabel
												control={
													<Checkbox
														id={`src_${src.SourceName}`}
														value={src.SourceId}
														onChange={customSourcesSelected}
														checked={
															selectedCustomSources
																? selectedCustomSources.firstOrNull(
																		(s) =>
																			s.SourceId ===
																			src.SourceId
																  ) !== null
																: false
														}
													/>
												}
												label={src.FormattedName}
											/>
										</Grid>
									) : (
										<Grid item xs key={`empty${index}`} />
									)
								)}
							</Grid>
						))}
					</DialogContent>
					<DialogActions>
						<div style={{ flexGrow: 1 }}>
							<Button
								id="secondaryButtonId"
								onClick={handleDialogClose}
								sx={{
									...(darkMode
										? { color: "var(--dark-mode-blue-color) !important" }
										: { color: "#2E3F80 !important" })
								}}
							>
								Cancel
							</Button>
						</div>
						<Button
							className="dialogSecondaryButton"
							variant="outlined"
							onClick={resetCustomGroup}
							sx={{
								...(darkMode
									? { color: "var(--dark-mode-blue-color) !important" }
									: { color: "#2E3F80 !important" })
							}}
						>
							Reset
						</Button>
						<Button
							variant="contained"
							id="primaryButtonId"
							autoFocus
							onClick={updateCustomGroup}
							sx={{
								...(darkMode
									? { backgroundColor: "var(--dark-mode-blue-color) !important" }
									: { backgroundColor: "#2E3F80 !important" })
							}}
						>
							OK
						</Button>
					</DialogActions>
				</Dialog>
			</Box>
		)
	);
});

export default memo(PriceSourceModalComponent);
