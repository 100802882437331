export class OpportunitySearchbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.getSearchCriteria = this.getSearchCriteria.bind(this);
    }
    getSearchCriteria(){
        var me = this;
        return this.props.searchCriteria.map(function(criteria, index){
            return (
                <div key={"searchkey_"+index}>
                    <div className="standardformfieldlabel"><label htmlFor={criteria.fieldName} className="standardformlabel">{criteria.displayName}</label></div>
                    { criteria.fieldType == "input" && <input className="searchinput" type="text" name={criteria.fieldName} id={criteria.fieldName} title={criteria.displayName} onChange={me.props.inputChanged.bind(null, criteria.fieldName)} value={criteria.value}  /> }
                    { criteria.fieldType == "select" && <div className="searchselectfieldwrapper"><select className="searchselectfield" onChange={me.props.inputChanged.bind(null, criteria.fieldName)} value={criteria.value}  name={criteria.fieldName} id={criteria.fieldName} title={criteria.displayName}>{criteria.options}</select> </div> }
                </div>  
             )
        })
    }
    render(){
        return (
            <div className="grid col4x1">
            <div className="panel">
                <div className="title">Search Criteria</div>   

                <div className="content">
                    <input type="hidden" id="pageNumber" name="pageNumber" defaultValue="#PageNumber" />
                    <input type="hidden" id="pageSize" name="pageSize" defaultValue="#PageSize" />
                    <input type="hidden" id="resultCount" name="resultCount" defaultValue="#ResultCount" />
                    {this.getSearchCriteria()}
                    <br />
                    <br />
                    <button onClick={()=>this.props.submitClick(0)} className="btn submit" style={{width:"90px"}} help="opportunity-search-criteria-bottom">Search</button>
                    <button onClick={this.props.resetClick} className="btn" id="reset" style={{width:"90px", marginLeft: "12px"}} help="opportunity-search-criteria-bottom"><div/>Reset</button>
                    <input type="hidden" id="resetFlag" name="resetFlag" defaultValue="false" />
                </div>
            </div>
            </div>
        )
    }
}