import * as React from "react";
import BaseContextMenu from "./baseContextMenu";

function ArrowContextMenu({ quosal, isEditable, contentGrid, gridToolbarStyle }) {
	const importActions = [];

	const selectedTab = () => {
		let selectedTab;
		app.currentQuote.Tabs.map((tab) => {
			if (tab.IdQuoteTabs === contentGrid.state.cKEditorSelectedTabId) {
				selectedTab = tab;
			}
		});

		return selectedTab;
	};

	const isArrowEnabled = () =>
		quosal.settings.getValue("useArrow") &&
		quosal.settings.getValue("useArrowImport") &&
		isEditable;

	const isArrowACSEnabled = () =>
		quosal.settings.getValue("useArrow") &&
		quosal.settings.getValue("useArrowACS") &&
		isEditable;

	if (isArrowEnabled()) {
		const arrowQuoteImportClick = function () {
			quosal.util.goToArrowQuoteSearch(selectedTab().IdQuoteTabs);
		};
		importActions.push({
			id: 1,
			name: "Import Arrow Quote",
			callback: arrowQuoteImportClick
		});
	}

	if (isArrowACSEnabled()) {
		const arrowTermImportClick = function () {
			quosal.util.goToArrowTermImport(selectedTab().IdQuoteTabs);
		};
		importActions.push({
			id: 2,
			name: "Import Financing Offers",
			callback: arrowTermImportClick
		});
	}

	return (
		<>
			{importActions && importActions.length ? (
				<span style={gridToolbarStyle}>
					<BaseContextMenu title="Arrow" quosal={quosal} actions={importActions} />
				</span>
			) : null}
		</>
	);
}

export default ArrowContextMenu;
