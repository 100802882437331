import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const SectionAccordian = ({ id, title, expanded, setExpanded, children }) => {
	const theme = useTheme();
	const handleChange = () => (_, isExpanded) => {
		setExpanded(isExpanded);
	};
	return (
		<Accordion
			sx={{
				boxShadow: "none",
				fontFamily: `"Inter", sans-serif`,
                padding:'4px',
				color: theme.palette.text.main,
                border:`1px solid ${theme.palette.border.border_accordian}`,
				backgroundImage:'none',
				backgroundColor: theme.palette.background.accordian,
                borderRadius:'8px !important',
				"&:before": {
					backgroundColor: "unset"
				}
			}}
			expanded={expanded}
			onChange={handleChange()}
		>
			<AccordionSummary
				sx={{
					padding:'16px',
					"&.MuiButtonBase-root": {
						minHeight: "20px",
						height: "20px"
					}
				}}
				expandIcon={<ExpandMoreIcon fontSize="1rem" />}
				aria-controls="panel-content"
				id={`${id}-panel-header`}
			>
				<Typography sx={{ fontSize: "14px", lineHeight: "20px", letterSpacing: "0.16px" }}>
					{title}
				</Typography>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					padding: "16px"
				}}
			>
				{children}
			</AccordionDetails>
		</Accordion>
	);
};
