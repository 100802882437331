if (!quosal.api.crm)
    quosal.api.crm = {};

quosal.api.crm.dynamics = {};

quosal.api.crm.dynamics.getOpportunities = function (criteria, pageNumber, pageSize, additionalParameters) {
    return quosal.api.call('Crm.Dynamics.GetOpportunities', {
        criteria: criteria,
        pager: {pageNumber: pageNumber, pageSize: pageSize},
        additionalParameters: additionalParameters || {}
    });
};

quosal.api.crm.dynamics.getOpportunityMetadata = function (criteria, pageNumber, pageSize, additionalParameters) {
    return quosal.api.call('Crm.Dynamics.GetOpportunityMetadata', {
        additionalParameters: additionalParameters || {}
    });
};

quosal.api.crm.dynamics.getOpportunity = function (idQuoteMain, dataView) {
    return quosal.api.call('Crm.Dynamics.GetOpportunity', {
        idQuoteMain: idQuoteMain,
        dataView: dataView
    });
};

quosal.api.crm.dynamics.getNewOpportunityItems = function (idQuoteMain, groupingType) {
    return quosal.api.call('Crm.Dynamics.GetNewOpportunityItems', {
        idQuoteMain: idQuoteMain,
        groupingType: groupingType
    });
};