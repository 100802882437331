import { PreviewContentProvider } from "../context/previewContentContext";
import { CPQVirtualEditorInner } from "./CPQVirtualEditorInner";

export function CPQVirtualEditor({ onAutoSaveFinished }) {
    return (
        <PreviewContentProvider
            value={{
                currentQuote: app.currentQuote,
            }}
        >
            <CPQVirtualEditorInner onAutoSaveFinished={onAutoSaveFinished} />
        </PreviewContentProvider>
    );
}
