quosal.multiLevelApprovals = {
    dataDescriptions : [
        {option:'eq', value: '='},  // equals
        {option:'ne', value: '<>'}, // not equal to
        {option:'ct', value: 'contains'}, // contains
        {option:'nc', value: 'does not contain'}, // contains
        {option:'lt', value: '<'},  // less than
        {option:'gt', value: '>'},  // greater than
        {option:'le', value: '<='}, // less than or equal to
        {option:'ge', value: '>='}, // greater than or equal to
        {option:'on', value: 'in list'} // in this list
    ],

   //Use Set here?
    showNotifications: function(approvalRuleMessages){
        const newRules = approvalRuleMessages;
        if(!app.currentQuote){
            return;
        }
        if (!app.currentQuote.activeRules) {
            app.currentQuote.activeRules = [];
        }

        app.currentQuote.activeRules.forEach( function(r) {r.used = false;});
        if (newRules && newRules.length > 0) {
            console.log("Triggered::"+newRules.length,newRules);
            newRules.forEach(function (m){
                let selected = app.currentQuote.activeRules.filter(function(r) {return r.Name == m.Name });
                if (selected.length == 0) {
                    m.used = true;
                    app.currentQuote.activeRules.push(m);
                    MicroGrowl.addNotification({ level: 'warn', msg: m.Message, delay: 3000});
                }
                else {
                    selected[0].used = true;
                }
            });
            
        }
        app.currentQuote.activeRules.forEach( function(r) {
            if (r.used == false) {
                MicroGrowl.addNotification({ level: 'info', msg: '\'' + r.Message + '\' no longer applies', delay: 3000});
            }
        });

        app.currentQuote.activeRules = app.currentQuote.activeRules.filter(function(r) {return r.used == true});
    },

    getStatusElement: function(approvalStatus){
        
        if(approvalStatus == "Approved"){
            return (
                <div className="feedrowapprovalstatus feedrowapprovedcolor">
                    Approved
                </div>
            )
        }
        else if (approvalStatus == "Pending") {
            return (
                <div className="feedrowapprovalstatus feedrowpendingcolor">
                    Pending
                </div>
            )
        }
        else if (approvalStatus == "Auto Approved") {
            return (
                <div className="feedrowapprovalstatus feedrowapprovedcolor">
                    Auto Approved
                </div>
            )
        }
        else if(approvalStatus == "Rejected"){
            return (
                <div className="feedrowapprovalstatus feedrowrejectedcolor">
                    Rejected
                </div>
            )
        }
        else if(approvalStatus == "Requested"){
            return (
                <div className="feedrowapprovalstatus feedrowrequestedcolor">
                    Requested
                </div>
            )
        }
        else if (approvalStatus == "Cancel Request"){
            return (
                <div className="feedrowapprovalstatus feedrowrejectedcolor">
                    Request Canceled
                </div>
            )
        }
        else if (approvalStatus == "Notified"){
            return (
                <div className="feedrowapprovalstatus feedrowrequestedcolor">
                    Notified
                </div>
            )
        }
    }
};
