/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Grid } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import PriceSourcesModal from "../commonComponents/priceSourcesModal";
import { useAppDataContext } from "../globalStates/appDataProvider";
import { StyledButton } from "../styledComponents/common";

function ActionButtons({ product }) {
	const { selectedProducts, productDetails, navigateToPage, addProduct, showNotification } =
		useAppDataContext();
	const [open, setOpen] = useState(false);

	const isProductInBin = !!selectedProducts.find(
		(item) => item.mpn === productDetails.mpn && item.source === productDetails.source
	);

	const handleAddToBin = () => {
		addProduct(productDetails);
		showNotification("Successfully added", "The item has been added to the Bin");
	};

	return (
		<Grid container spacing={1} justifyContent="flex-end">
			<Grid item>
				<StyledButton
					variant="outlined"
					endIcon={<ShoppingCartOutlinedIcon sx={{ fontSize: 16 }} />}
					onClick={() => navigateToPage("PRODUCT_CART_PAGE")}
				>
					View Bin
				</StyledButton>
			</Grid>

			{!isProductInBin && (
				<Grid item>
					<StyledButton
						variant="outlined"
						endIcon={<AddShoppingCartOutlinedIcon sx={{ fontSize: 16 }} />}
						onClick={handleAddToBin}
					>
						Add to Bin
					</StyledButton>
				</Grid>
			)}

			<Grid item>
				<StyledButton
					variant="contained"
					endIcon={<AddOutlinedIcon sx={{ fontSize: 16 }} />}
					onClick={() => setOpen(true)}
				>
					Add to Quote
				</StyledButton>
				<PriceSourcesModal
					open={open}
					onClose={() => setOpen(false)}
					type="grid"
					row={product}
					hideButtons={["Add to Bin"]}
				/>
			</Grid>
		</Grid>
	);
}

export default ActionButtons;
