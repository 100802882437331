import {EditableImage, ImageEditor} from "js/jsx/src/classes/imageEditor.jsx";

export class HomePage extends  React.Component{
    constructor(props){
        super(props)
        this.snapX = 495;
        this.snapY = 210;
        this.marginX = 27;
        this.marginY = 27;
        this.dashboardTimeout;
        this.state = {      
            isLoading: true,
            isEditing: false,
        };
        //Bind this
        this.clearWidgetState = this.clearWidgetState.bind(this);
        this.getDashboardData = this.getDashboardData.bind(this);
        this.getLayoutDataByName = this.getLayoutDataByName.bind(this);
        this.getLayoutObjectForSave = this.getLayoutObjectForSave.bind(this);
        this.getIdHomeLayoutByWidgetName = this.getIdHomeLayoutByWidgetName.bind(this);
        this.getWidgetArray = this.getWidgetArray.bind(this);
        this.delayWidgetDisplay = this.delayWidgetDisplay.bind(this);
        this.editMode = this.editMode.bind(this);
        this.stopEditMode = this.stopEditMode.bind(this);
        this.revertWidgetChanges = this.revertWidgetChanges.bind(this);
        this.saveCurrentLayout = this.saveCurrentLayout.bind(this);
        this.getLoadingWidgets = this.getLoadingWidgets.bind(this);
        this.updateAllWidgetsLocation = this.updateAllWidgetsLocation.bind(this);
    }

    componentDidMount(){
        var me = this;
        if(window.location.search.includes('justLoggedIn=yes')){
            $.credentialChecks.start();
        }
        document.body.style.backgroundColor = "#F3F3F3"
        this.getDashboardData(this.props.layoutName);
        this.componentIsMounted = true;
    }
    componentWillUnmount(){
        this.componentIsMounted = false;
    }

    componentDidUpdate(){            
        if(this.state.isEditing){
            $('#movablepanels .widget').draggable({
                cursorAt: { top: 15, left: 55 },
                stack: "#movablepanels .widget",
                scope: "widgets",
                drag: drag,
                stop: dragstop,
                distance: 10,
            });
        }
    }

    clearWidgetState(){
        this.state.widget = {};
    }
    getDashboardData(layoutName) {
        var start = new Date().getTime();
        var getDataApi = quosal.api.user.getDashboardData(layoutName);
        this.clearWidgetState();
        getDataApi.stateChanged = function(msg){
            if(!this.componentIsMounted){
                return;
            }
            if(msg[msg.action]){
                var data = JSON.parse(msg[msg.action]);
                data.layout = this.getLayoutDataByName(msg.WidgetName);
                this.state.widget[msg.WidgetName] = data;
                this.forceUpdate();
            }
            else if(msg.userWidgetData){
                var parsedWidgetData = JSON.parse(msg.userWidgetData);
                var layoutOptions = JSON.parse(msg.layoutOptions);
                this.setState({isLoading: false, userWidgetData: parsedWidgetData, layoutOptions:layoutOptions})
            }
        }.bind(this)
        getDataApi.finished = function(start) {
            if (!this.componentIsMounted) {
                return;
            }
            if (this.state.isEditing || this.props.isEditing) {
                $.quosal.home.editMode.stop();
                this.editMode();
            } else {
                this.forceUpdate();
            }
            quosal.util.calculateAndTrackPageLoadTimeInAppInsights(start, "Get - Dashboard Data");
            quosal.ui.views.parse(document.getElementById('pagecontainer'));
        }.bind(this, start);
        getDataApi.call();
    }
    getLayoutDataByName(nameToFind){
        if(this.state && this.state.userWidgetData){
            var layout =  this.state.userWidgetData.find(function(widget){
                return widget.WidgetType == nameToFind;
            })
            layout.top = layout.Y * this.snapY;
            layout.left = layout.X * this.snapX;
            layout.height = layout.Height * this.snapY - this.marginY;
            layout.width = layout.Width * this.snapX - this.marginX;
            
            if ((layout.Y + layout.Height) * this.snapY > $('#movablepanels').outerHeight()) {
                $('#movablepanels').css('min-height', (layout.Y + layout.Height) * this.snapY);
            }
    
            return layout;
        } else {
            return null;
        }
    }
    getLayoutObjectForSave(){
 
        var me = this;
        var widgetList = [];
        
        $('#movablepanels .widget').each(function () {
            var tempWidget = {};
            var layout =  me.getLayoutDataByName($(this).attr("id"));
            tempWidget.unused = false;
            tempWidget.x = layout.X;
            tempWidget.y = layout.Y;
            tempWidget.height = Math.round($(this).outerHeight() / me.snapY);
            tempWidget.width = Math.round($(this).outerWidth() / me.snapX);
            tempWidget.widgetType = this.id;
            widgetList.push(tempWidget);
        });
        $('#paneltray .widget').each(function(){
            var tempWidget = {};
            tempWidget.unused = true;
            tempWidget.x = 0;
            tempWidget.y = 0;
            tempWidget.widgetType = this.id;
            widgetList.push(tempWidget);
        })
        if(!quosal.validation.isPackageLevelAuthorized(app.packageLevels.standard)){
            var tempWidget = {};
            tempWidget.unused = true;
            tempWidget.x = 0;
            tempWidget.y = 0;
            tempWidget.widgetType = 'SuccessList';
            widgetList.push(tempWidget);
        }

        return widgetList;
    }
    getIdHomeLayoutByWidgetName(widgetName){
        var layout =  this.state.userWidgetData.find(function(widget){
            return widget.WidgetType == widgetName;
        })
        return layout.IdHomeLayout;
    }
    getWidgetArray(active){
        var me = this;
        var widgetArray = []

        if(this.state.widget.Notifications && (active ? !this.state.widget.Notifications.layout?.Unused : this.state.widget.Notifications.layout?.Unused)){
            var widget = <NotificationWidget parent={this} data={this.state.widget.Notifications} />
            widgetArray.push(<WidgetContainer ref="Notifications" key="display_notificationWidget" parent={this} widget={widget} widgetName="Notifications" widgetTitle="Notifications"/>)
        }
        if(this.state.widget.SuccessList && quosal.validation.isPackageLevelAuthorized(app.packageLevels.standard) && (active ? !this.state.widget.SuccessList.layout?.Unused : this.state.widget.SuccessList.layout?.Unused) ){
            var widget = <SuccessListWidget parent={this} data={this.state.widget.SuccessList} />
            widgetArray.push(<WidgetContainer ref="SuccessList" key="display_successListWidget" parent={this} widget={widget} widgetName="SuccessList" widgetTitle="SuccessList&trade;"/>)
        }
        if(this.state.widget.RecentQuotes && (active ? !this.state.widget.RecentQuotes.layout?.Unused : this.state.widget.RecentQuotes.layout?.Unused) ){
            var widget = <RecentQuoteWidget parent={this} data={this.state.widget.RecentQuotes} />
            widgetArray.push(<WidgetContainer ref="RecentQuotes" key="display_recentQuotesWidget" parent={this} widget={widget} widgetName="RecentQuotes" widgetTitle="Recent Quotes"/>)
        }
        if(this.state.widget.UserInfo && (active ? !this.state.widget.UserInfo.layout?.Unused : this.state.widget.UserInfo.layout?.Unused) ){
            var widget = <UserInfoWidget parent={this} data={this.state.widget.UserInfo} />
            widgetArray.push(<WidgetContainer ref="UserInfo" key="display_userInfoWidget" parent={this} widget={widget} widgetName="UserInfo" widgetTitle="User Info"/>)
        }
        if(this.state.widget.ApprovalInfo && quosal.validation.isPackageLevelAuthorized(app.packageLevels.premium) && app.settings.global.ApprovalMethod 
        && app.settings.global.ApprovalMethod == 'Multilevel' && (active ? !this.state.widget.ApprovalInfo.layout?.Unused : this.state.widget.ApprovalInfo.layout?.Unused)){
            var widget = <ApprovalInfoWidget parent={this} data={this.state.widget.ApprovalInfo} />
            widgetArray.push(<WidgetContainer ref="ApprovalInfo" key="display_approvalInfoWidget" parent={this} widget={widget} widgetName="ApprovalInfo" widgetTitle="Pending Approval Requests"/>)
        }
        if(this.state.widget.PendingMlaApproval && quosal.validation.isPackageLevelAuthorized(app.packageLevels.premium) && app.settings.global.ApprovalMethod 
        && app.settings.global.ApprovalMethod == 'Multilevel' && (active ? !this.state.widget.PendingMlaApproval.layout?.Unused : this.state.widget.PendingMlaApproval.layout?.Unused)){
            var widget = <PendingMlaApprovalWidget parent={this} data={this.state.widget.PendingMlaApproval} />
            widgetArray.push(<WidgetContainer ref="PendingMlaApproval" key="display_PendingMlaApprovalWidget" parent={this} widget={widget} widgetName="PendingMlaApproval" widgetTitle="Pending Manager Approval"/>)
        }
        if(!active){
            this.inactiveWidgetArray = widgetArray;
        }
        return widgetArray
    }
    delayWidgetDisplay(ui){
        ui.draggable.css("display", "none");
        setTimeout(function(){
            ui.draggable.css("display", "inline-block");
            ui.draggable.css("position", "absolute");
        }, 10);
    }
    editMode(){
        var me = this;
        if($(".widget").length > 0){
            $.quosal.home.editMode.start(true);
            $('#movablepanels').addClass("edit");
            $("#paneltray").droppable(
                {
                    drop: function( event, ui ) {
                        var _me = me;
                        if(!ui.draggable.is("#UserInfo")){
                            var layoutData = _me.getLayoutDataByName(ui.draggable.attr("id"))
                            layoutData.Unused = true;
                            layoutData.X = 0;
                            layoutData.Y = 0;
                            ui.draggable.css("display", "inline-block");
                            _me.forceUpdate();
                        }
                    },
                    scope: "widgets",
                    tolerance: "pointer",
                    over: function(event, ui) {
                        var _me = me;
                        if(!ui.draggable.is("#UserInfo") && _me.refs[ui.draggable.attr("id")]){
                            _me.refs[ui.draggable.attr("id")].setUnused();
                            _me.delayWidgetDisplay(ui)
                            
                        } else if(!ui.draggable.is("#UserInfo") && _me.refs["widgetTray"].refs[ui.draggable.attr("id")]){
                            _me.refs["widgetTray"].refs[ui.draggable.attr("id")].setUnused();
                            _me.delayWidgetDisplay(ui)
                        }
                    },
                    out: function(event, ui){
                        var _me = me;
                        if(!ui.draggable.is("#UserInfo") && _me.refs[ui.draggable.attr("id")] && event.target.id == "paneltray"){
                            _me.refs[ui.draggable.attr("id")].setUsed();
                            _me.delayWidgetDisplay(ui)
                        } else if(!ui.draggable.is("#UserInfo") && _me.refs["widgetTray"].refs[ui.draggable.attr("id")] && event.target.id == "movablepanels"){
                            _me.delayWidgetDisplay(ui)
                            _me.refs["widgetTray"].refs[ui.draggable.attr("id")].setUsed();
                        }
                    }
                }
            );
            $("#movablepanels").droppable(
                {
                    drop: function( event, ui ) {
                        var _me = me;
                            var layoutData = _me.getLayoutDataByName(ui.draggable.attr("id"))
                            layoutData.Unused = false;
                            var pos = $(ui.draggable).data('pos');
                            layoutData.X = Math.round(pos.left / _me.snapX);
                            layoutData.Y = Math.round(pos.top / _me.snapY);
                            ui.draggable.css("display", "inline-block");
                            me.updateAllWidgetsLocation();
                            layoutData.Height = Math.round($(ui.draggable).outerHeight() / _me.snapY);
                            layoutData.Width = Math.round($(ui.draggable).outerWidth() / _me.snapX);
                            _me.refs.widgetTray.state.disabledButtons = true;
                            _me.forceUpdate();
                            $(ui.draggable).data('pos', pos);
                            $('#shadow').hide();
                    },
                    scope: "widgets",
                    tolerance: "pointer",
                    over: function(event, ui) {
                        var _me = me;
                        if(!ui.draggable.is("#UserInfo") && _me.refs[ui.draggable.attr("id")] && event.target.id == "movablepanels"){
                            _me.refs[ui.draggable.attr("id")].setUsed();
                            _me.delayWidgetDisplay(ui)
                        } else if(!ui.draggable.is("#UserInfo") && _me.refs["widgetTray"].refs[ui.draggable.attr("id")] && event.target.id == "movablepanels"){
                            _me.delayWidgetDisplay(ui)
                            _me.refs["widgetTray"].refs[ui.draggable.attr("id")].setUsed();
                        }
                    },
                    out: function(event, ui) {
                        var _me = me;
                        if(!ui.draggable.is("#UserInfo") && _me.refs[ui.draggable.attr("id")] && event.target.id == "paneltray"){
                            _me.refs[ui.draggable.attr("id")].setUnused();
                            _me.delayWidgetDisplay(ui)
                            
                        } else if(!ui.draggable.is("#UserInfo") && _me.refs["widgetTray"].refs[ui.draggable.attr("id")] && event.target.id == "movablepanels"){
                            _me.refs["widgetTray"].refs[ui.draggable.attr("id")].setUnused();
                            _me.delayWidgetDisplay(ui)
                        }
                    },
                }
            )
        }
        this.setState({isEditing: true})
    }
    stopEditMode(){
        if(this.componentIsMounted){
            $.quosal.home.editMode.stop();
            $('#movablepanels').removeClass("edit");
            this.setState({isEditing: false})
        }
    }
    revertWidgetChanges(){
        this.stopEditMode();
        this.props.reloadComponent();
    }
    saveCurrentLayout(){
        var me = this;
        var name = $("#presetLayoutText").val();
        this.updateAllWidgetsLocation();
        var saveCurrentLayoutApi = quosal.api.user.saveWidgetLayout(this.getLayoutObjectForSave(), name);
        saveCurrentLayoutApi.finished = function(msg){
            me.setState({layoutOptions: JSON.parse(msg.layoutOptions)});
            me.stopEditMode();
            Dialog.close();
            me.props.reloadComponent({layoutName: $('#presetLayoutText').val()});
        }
        saveCurrentLayoutApi.call()
    }
    getLoadingWidgets(){
        var me = this;
        if(this.state.userWidgetData){
            var widgetMap = Object.keys(this.state.userWidgetData).map(function(key, index){
                if(!me.state.widget[me.state.userWidgetData[key].WidgetType]){
                    var layout = me.getLayoutDataByName(me.state.userWidgetData[key].WidgetType);
                    if(!layout.Unused){
                        return (
                            <div key={"loadingwidget_"+index} style={{position: "absolute", width: "490px", top: layout.top, left: layout.left }}>
                                <div>
                                    <Panel style={{height: layout.height, width: layout.width}}>
                                        <FormPlaceholder message={"Loading " + layout.WidgetType + " Widget"} /> 
                                    </Panel>
                                </div> 
                            </div>
                        )
                    }
                }
            })
            return widgetMap;
        }
    }
    updateAllWidgetsLocation(){
        var me = this;
        $("#movablepanels .widget").each(function(){
            var layout = me.getLayoutDataByName(this.id)
            var pos = $(this).data('pos');
            if(pos){
                layout.X =  Math.round(pos.left / me.snapX);
                layout.Y =  Math.round(pos.top / me.snapY);
            }
        })
    }
    render(){
        var renderContent = [];
        
        if(this.state.isEditing){
            renderContent.push(<WidgetTray key="rc_widgettray" ref="widgetTray" parent={this} />)
        } 

        if(this.state.isLoading){
            renderContent.push(<FormPlaceholder key="rc_formplaceholder" message="Loading Home Page..." />)
        } else{
            renderContent.push(<div id="movablepanels" key="rc_components" style={{position: "relative"}}> {this.getLoadingWidgets()} {this.getWidgetArray(true)} </div>);
        }
       
        return (renderContent)
    }
}
class WidgetContainer extends React.Component {
   constructor(props){
       super(props);
       this.state = {
           unused: this.props.widget.props.data.layout.Unused
        }
        //Bind this
        this.setUnused = this.setUnused.bind(this);
        this.setUsed = this.setUsed.bind(this);
        this.updateUnused = this.updateUnused.bind(this);
        this.removeWidget = this.removeWidget.bind(this);
        this.findWithAttr = this.findWithAttr.bind(this);
        this.getUnusedDisplay = this.getUnusedDisplay.bind(this);
        this.resize = this.resize.bind(this);
   }
    componentDidMount(){
        var me = this;
        $("#"+me.props.widgetName).css("display", "inline-block")
    }
    setUnused(){
        this.setState({unused: true});
    }
    setUsed(){
        this.setState({unused: false});
    }
    updateUnused(){
        this.props.parent.getLayoutDataByName(this.props.widgetName).Unused = true;
        this.props.parent.forceUpdate();
    }
    updateUsed(){
        this.props.parent.getLayoutDataByName(this.props.widgetName).Unused = false;
        this.props.parent.forceUpdate();
    }
    removeWidget(){
        $.quosal.home.editMode.rearrange(ReactDOM.findDOMNode(this), { top: 0, bottom: 0, left: 0, right: 0 });
        this.props.parent.updateAllWidgetsLocation();
        this.updateUnused();
    }
    findWithAttr(array, attr, value) {
        for(var i = 0; i < array.length; i += 1) {
            if(array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }
    getUnusedDisplay(){
        return(
            <div id={this.props.widgetName} className="widget inactive" style={{cursor: "default", lineHeight: "20px", height:"20px", minWidth: "150px", position: "", display:"inline-block" }}>
                <Panel style={{height: "23px"}}>
                    <div className={this.props.icon} /> 
                    <span style={{fontSize: "16px", fontWeight:"bold"}} className="titletext">{this.props.widgetTitle}</span>
                </Panel> 
            </div>
        )
    }
    
    resize(direction){
        var widget = ReactDOM.findDOMNode(this);
        $.quosal.home.editMode.resize(widget, direction, true);
        this.props.parent.updateAllWidgetsLocation();
        this.forceUpdate();
    }
    render(){
        var layout = this.props.parent.getLayoutDataByName(this.props.widgetName);
        return (
            this.state.unused ? this.getUnusedDisplay()
            :
            <div id={this.props.widgetName} className="widget active" style={{width: "490px", left: layout.left, top: layout.top, display:"none"}}>
                <div className="toolbar widgettoolbar">
                    <div onClick={this.props.parent.editMode} className="toolbutton editmode" />
                    {this.props.widgetName != "UserInfo" && <div onClick={this.removeWidget} className="toolbutton editmode_remove" />}
                </div>
                {this.props.widgetName != "UserInfo" && 
                <div className="toolbar widgetresizetoolbar">
                        <div onClick={this.resize.bind(this, -1)} className="toolbutton editmode_resize smaller"></div>
                        <div onClick={this.resize.bind(this, 1)} className="toolbutton editmode_resize larger"></div>
                </div>
                }
                {this.props.widget}
            </div>
        )
    }
}

class ApprovalInfoWidget extends React.Component{
    constructor(props){
        super(props);
        //Bind this
        this.getRows = this.getRows.bind(this);
    }
    getRows(){
        var openQuoteById = function(id){
            var approvalModule = quosal.sell.modules.find('quote.dashboard');
            approvalModule.load({ query: 'idquotemain=' + id + '&submodules=quote.approval'});
        }

        if(this.props.data.length == 0){
            return (
                <tbody><tr><td className="notifyempty">There are no approvals to display</td></tr></tbody>
            )
        }
        return this.props.data.map(function(approvalInfo, index){
            var className = 'fill';
            return ( 
                    <tbody key={"approvalInfoRow_"+index}>
                        <tr className="approvalInfoitem">
                            <td className={className}>
                            <a className="linetitle link" onClick={openQuoteById.bind(this, approvalInfo.idQuoteMain)}><div className="linetitle">{approvalInfo.quoteName}</div></a>
                                <div className="notifytime">{approvalInfo.approvalRequestInfo}</div>
                            </td>
                        </tr>
                        <tr><td className="itemsep" colSpan="4" style={{paddingTop:"5px"}}></td></tr>
                        <tr><td className="sepbottom itemsep" colSpan="4" style={{paddingBottom:"5px"}}></td></tr>
                        <tr><td className="itemsep" colSpan="4" style={{paddingBottom:"5px"}}></td></tr>
                    </tbody>
            )
        })
       
    }
    render(){
        var layout = this.props.parent.getLayoutDataByName("ApprovalInfo");
        return(
            <Panel style={{ height: layout.height, width: layout.width, overflow:"auto"}}>
                 <div className="title">
                    <span className="titletext">Pending Approval Requests</span>
                </div>
                <div className="content"> 
                    <table cellPadding="0" cellSpacing="0" id="approvalInfo_table" className="fill">
                        {this.getRows()}
                    </table>
                </div>
                
            </Panel>
        )
    }
}

class PendingMlaApprovalWidget extends React.Component{
    constructor(props){
        super(props);
        //Bind this
        this.getRows = this.getRows.bind(this);
    }
    getRows(){
        var openQuoteById = function(id){
            var dashboardModule = quosal.sell.modules.find('quote.dashboard');
            dashboardModule.load({ query: 'idquotemain=' + id + '&submodules=quote.home'});
        }

        if(this.props.data.length == 0){
            return (
                <tbody><tr><td className="notifyempty">There are no quotes pending manager approval</td></tr></tbody>
            )
        }
        return this.props.data.map(function(pendingMLAInfo, index){
            var className = 'fill';
            return ( 
                    <tbody key={"pendingMLAInfoRow_"+index}>
                        <tr className="pendingMLAInfoitem">
                            <td className={className}>
                            <a className="linetitle link" onClick={openQuoteById.bind(this, pendingMLAInfo.IdQuoteMain)}><div className="linetitle">{pendingMLAInfo.QuoteName}</div></a>
                            <span style={{float:"right"}}>{quosal.multiLevelApprovals.getStatusElement(pendingMLAInfo.ApprovalStatus)}</span>
                            </td>
                        </tr>
                        <tr><td className="itemsep" colSpan="4" style={{paddingTop:"5px"}}></td></tr>
                        <tr><td className="sepbottom itemsep" colSpan="4" style={{paddingBottom:"5px"}}></td></tr>
                        <tr><td className="itemsep" colSpan="4" style={{paddingBottom:"5px"}}></td></tr>
                    </tbody>
            )
        })
       
    }
    render(){
        var layout = this.props.parent.getLayoutDataByName("PendingMlaApproval");
        return(
            <Panel style={{ height: layout.height, width: layout.width, overflow:"auto"}}>
                 <div className="title">
                    <span className="titletext">Pending Manager Approval</span>
                </div>
                <div className="content"> 
                    <table cellPadding="0" cellSpacing="0" id="pendingMLAInfo_table" className="fill">
                        {this.getRows()}
                    </table>
                </div>
                
            </Panel>
        )
    }
}
class UserInfoWidget extends  React.Component{
    constructor(props) {
        super(props);
        this.state = {
            repPictureUrl: (this.props.data ? this.props.data.repPictureUrl : null), 
            repSignatureUrl: (this.props.data ? this.props.data.repSignatureUrl : null)
        }
        //Bind this
        this.afterImageSave = this.afterImageSave.bind(this);
        this.afterImageDelete = this.afterImageDelete.bind(this);
        this.afterSignatureSave = this.afterSignatureSave.bind(this);
        this.afterSignatureDelete = this.afterSignatureDelete.bind(this);
        this.openSignatureWidget = this.openSignatureWidget.bind(this);
        this.afterImageSave = this.afterImageSave.bind(this);
    }

    afterImageSave(repPictureUrl) {
        this.setState({repPictureUrl: repPictureUrl});
    }
    afterImageDelete() {
        this.setState({repPictureUrl: "images/empty.png"});
    }
    afterSignatureSave(repSignatureUrl) {
        this.setState({repSignatureUrl: repSignatureUrl});
        Dialog.closeAll();
    }
    afterSignatureDelete() {
        this.setState({repSignatureUrl: "images/empty.png"});
    }
    openSignatureWidget() {
        Dialog.open({
            closeRequiresButton: true,
            title: "Submit your Signature",
            minWidth: "425px",
            message: <SignatureWidget saveCallback={this.afterSignatureSave}/>
        })
    }
    render() {
        var data = this.props.data;
        var layout = this.props.parent.getLayoutDataByName("UserInfo");
        var curSymbol = data.currency.curSymbol;
        return (
            <Panel style={{ height: layout.height, width: layout.width}}>
                <div className="title">
                    <span className="titletext">Welcome,  {app.currentUser.FirstName}</span>
                    <div className="toolbar">
                        <a href={quosal.util.url("edituser.quosalweb", "profile=mine")}>
                            <div className="toolbutton edit" title={"Edit " + app.currentUser.FirstName}></div>
                        </a>
                    </div> 
                </div>
            
                <div className="content">
                    <table cellPadding="0" cellSpacing="0" style={{fontSize:"10px"}}>
                        <thead>
                        <tr>
                            <td width="180" style={{verticalAlign: "middle"}}>
                                <div><EditableImage src={this.state.repPictureUrl} afterDeleteCallback={this.afterImageDelete} afterSaveCallback={this.afterImageSave} imageId={'RepPicture'} norescale={true} sizeInPixels={128}/></div>
                            </td>
                            <td width="200" style={{align:"left", verticalAlign: "middle"}}>
                                <table cellPadding="2" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <td className="sepbottom" width="90"></td>
                                            <td className="sepleft sepbottom" width="90" style={{textAlign: "right"}}><strong>This Month</strong></td>
                                            <td className="sepleft sepbottom" width="90" style={{textAlign: "right"}}><strong>Last Month</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="sepbottom" style={{textAlign: "right"}}><strong>Created</strong></td>
                                            <td className="sepleft sepbottom" style={{textAlign: "right"}}>{accounting.formatMoney(data.thismonth.Created, curSymbol)}</td>
                                            <td className="sepleft sepbottom" style={{textAlign: "right"}}>{accounting.formatMoney(data.lastmonth.Created, curSymbol)}</td>
                                        </tr>
                                        <tr>
                                            <td className="sepbottom" style={{textAlign: "right"}}><strong>Delivered</strong></td>
                                            <td className="sepleft sepbottom" style={{textAlign: "right"}}>{accounting.formatMoney(data.thismonth.Delivered, curSymbol)}</td>
                                            <td className="sepleft sepbottom" style={{textAlign: "right"}}>{accounting.formatMoney(data.lastmonth.Delivered, curSymbol)}</td>
                                        </tr>
                                        <tr>
                                            <td className="sepbottom" style={{textAlign: "right"}}><strong>Won</strong></td>
                                            <td className="sepleft sepbottom" style={{textAlign: "right"}}>{accounting.formatMoney(data.thismonth.Won, curSymbol)}</td>
                                            <td className="sepleft sepbottom" style={{textAlign: "right"}}>{accounting.formatMoney(data.lastmonth.Won, curSymbol)}</td>
                                        </tr>
                                        <tr>
                                            <td className="rightcontent" style={{textAlign: "right"}} colSpan="3"> 
                                                <div className="signatureSizer">
                                                    <img onClick={this.openSignatureWidget} className="signature" src={this.state.repSignatureUrl} name="RepSignature" id="RepSignature" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </thead>
                    </table>
                </div>
            </Panel>
        )
    }
}
class RecentQuoteWidget extends React.Component {
    constructor(props){
        super(props)
        //Bind this
        this.getRecentQuoteRows = this.getRecentQuoteRows.bind(this);
    }
    getRecentQuoteRows() {
        var me = this;
        var dashboardModule = quosal.sell.modules.find('quote.dashboard');
        return this.props.data.map(function(row, index){
            return (
                <tbody key={"recentQuoteRow_" + index}>
                    <tr>
                        <td className="" width="160">
                            <a className="linetitle link" href={dashboardModule.getUrl({query: "&idquotemain=" + row.idQuoteMain})}>
                                <div title={row.quoteName} style={{maxHeight:"35px", overflow:"hidden", width: "160px", whiteSpace: "nowrap", textOverflow: "ellipsis"}} className="linetitle">{row.quoteName}</div>
                            </a>
                        </td>
                        <td className="" width="100">
                            <div className="linedetail">
                                <div className="linename">Quote #:</div>
                                <div className="linevalue">{row.quoteReadableId}</div>
                            </div>
                            <div className="linedetail">
                                <div className="linename">Total:</div>
                                <div className="linevalue">{row.quoteTotal}</div>
                            </div>
                        </td>
                        <td className="" width="150">
                            <div className="linedetail">
                                <div className="linename">Recurring:</div>
                                <div className="linevalue">{row.recurringTotal}</div>
                            </div>
                            <div className="linedetail">
                                <div className="linename">Customer:</div>
                                <div className="linevalue">{row.accountNameShort}</div>
                            </div>
                        </td>
                        <td className="" width="32" style={{textAlign:"right"}}>
                            <img className="thumbnail small" src={row.quotePicture} />
                        </td>
                    </tr>
                    <tr><td className="linesep" colSpan="4" style={{paddingTop:"5px"}}></td></tr>
                    <tr><td className="sepbottom linesep" colSpan="4" style={{paddingBottom:"5px"}}></td></tr>
                    <tr><td className="linesep" colSpan="4" style={{paddingBottom:"5px"}}></td></tr>
                </tbody>
            )
        })
       
    }
    render(){
        var layout = this.props.parent.getLayoutDataByName("RecentQuotes");
        return (
            <Panel style={{height: layout.height, width: layout.width}}>
                <div>
                    <div className="title">Recent Quotes</div>
                    <div className="content">
                        <table cellPadding="0" cellSpacing="0" className="fill" style={{fontSize:"10px"}}>
                            {this.getRecentQuoteRows()}
                        </table>
                    </div>
                </div>
            </Panel>
        )
    }
}
class NotificationWidget extends React.Component {
    constructor(props) {
        super(props);
        //Bind this
        this.getRows = this.getRows.bind(this);
    }
    getRows() {
        if (this.props.data.length == 0) {
            return (
                <tbody>
                    <tr>
                        <td className="notifyempty">There are no notifications to display</td>
                    </tr>
                </tbody>
            );
        }
        const getMessageContents = (message, outterIndex) => {
            return message?.split("&").map(function (line, index) {
                if (line) {
                    line = decodeURIComponent(line);
                    var pieces = line.split("=");
                    var subPieces1 = pieces[1]?.split("+");
                    var phrase1 = "";
                    if (subPieces1) {
                        subPieces1.forEach(function (subPiece, index) {
                            phrase1 += subPiece;
                            phrase1 += " ";
                        });
                    }
                    return (
                        <div
                            key={"notificationWidgetMessage_" + outterIndex + "." + index}
                            style={{ fontSize: "10px" }}>
                            <div style={{ display: "inline" }} className="linename">
                                {pieces[0]}:
                            </div>
                            <div style={{ display: "inline" }} className="linevalue">
                                {phrase1}
                            </div>
                        </div>
                    );
                } else {
                    return null;
                }
            });
        };
        return this.props.data.map(function (notification, index) {
            const { reviewed, url, title, message, notifyTime, thumbnail } = notification;
            let className = "fill" + (!reviewed ? " newnotification" : "");
            return (
                <tbody key={"notificationRow_" + index}>
                    <tr className="notificationitem">
                        <td className={className}>
                            <a href={url} className="linetitle">
                                {title}
                            </a>
                            {getMessageContents(message, index)}
                            <div className="notifytime">{notifyTime}</div>
                        </td>
                        <td style={{ textAlign: "right" }}>
                            <img className="thumbnail medium" src={thumbnail || ""} />
                        </td>
                    </tr>
                    <tr>
                        <td className="itemsep" colSpan="4" style={{ paddingTop: "5px" }}></td>
                    </tr>
                    <tr>
                        <td
                            className="sepbottom itemsep"
                            colSpan="4"
                            style={{ paddingBottom: "5px" }}
                        ></td>
                    </tr>
                    <tr>
                        <td className="itemsep" colSpan="4" style={{ paddingBottom: "5px" }}></td>
                    </tr>
                </tbody>
            );
        });
    }
    render() {
        var layout = this.props.parent.getLayoutDataByName("Notifications");
        return (
            <Panel style={{ height: layout.height, width: layout.width }}>
                <div className="title">
                    <span className="titletext">
                        <a href={quosal.util.url("notifications.quosalweb")}>Notifications</a>
                    </span>
                    {app.currentUser.IsAdministrator && (
                        <a href={quosal.util.url("adminopnotifications.quosalweb", "process=yes")}>
                            <div className="toolbutton update" title="Process Notifications"></div>
                        </a>
                    )}
                </div>
                <div className="content">
                    <table cellPadding="0" cellSpacing="0" id="notifications_table" max="#MaxCount">
                        {this.getRows()}
                    </table>
                </div>
            </Panel>
        );
    }
}
class SuccessListWidget extends React.Component {
    constructor(props){
        super(props)
        //Bind this
        this.getRows = this.getRows.bind(this);
    }
    getRows(){
        var getScoreToken = function(scoreQuadrant){
            if (scoreQuadrant == 1)
                return "early";
            if (scoreQuadrant == 2)
                return "great";
            if (scoreQuadrant == 3)
                return "good";
            else
                return "late";
        }

        var getBuckets = function(success, outterIndex){
            return Object.keys(success.scores).map(function(key, index) {
                var  value = success.scores[key];
                var scoreToken = getScoreToken(key);
                if(value == 0){
                    return (
                        <div key={"bucket_"+index+"."+outterIndex} className="successbucket empty">
                            <div> {value} </div>
                        </div>
                    )
                }else {
                    return ( 
                        <div key={"bucket_"+index+"."+outterIndex} className={"successbucket " + scoreToken }>
                            <a href={quosal.util.url("takeaction.quosalweb?", "searchstage=" + success.stageName, "searchstatus=" + value) }>
                                <div>{value}</div>
                            </a>
                        </div>
                    )
                }
            }).reverse();
        }
        var getScoreTokens = function(scores){
            var quoteScoreTokens = [];
            Object.keys(scores).reverse().forEach(function(key){
                if(scores[key] == 1 && quoteScoreTokens.length < 2){
                    quoteScoreTokens.push(getScoreToken(key))
                } else if (scores[key] > 1 && quoteScoreTokens.length == 0){
                    quoteScoreTokens.push(getScoreToken(key));
                    quoteScoreTokens.push(getScoreToken(key));
                } else if (scores[key] > 1 && quoteScoreTokens.length == 1){
                    quoteScoreTokens.push(getScoreToken(key));
                }                
            })

            return quoteScoreTokens;
        }
        var openQuoteById = function(id){
            var dashboardModule = quosal.sell.modules.find('quote.dashboard');
            dashboardModule.load({ query: 'idquotemain=' + id + '&submodules=quote.home'});
        }
        var getActionQuote = function(success, quoteIndex, outterIndex){
            var quote = success.quotes[quoteIndex]
            if(quote.quoteName || quote.accountNameShort){
                return(
                    <table key={"successListQuote_"+outterIndex+"."+ quoteIndex} cellPadding="0" cellSpacing="0" className="fill">
                        <tbody>
                            <tr>
                                <td colSpan="2">
                                    <a className="linetitle link" onClick={openQuoteById.bind(this, quote.idQuoteMain)}><div className="linetitle">{quote.quoteName}</div></a>
                                </td>
                                <td rowSpan="2" width="32"><div className="thumbnail small imagequote" ><img src={quote.quotePicture}/></div></td>
                            </tr>
                            <tr>
                                <td width="100">
                                    <div className="linedetail"><div className="linename">Quote #:</div><div className="linevalue">{quote.quoteReadableId}</div></div>
                                    <div className="linedetail"><div className="linename">Total:</div><div className="linevalue">{quote.quoteTotal}</div></div>
                                </td>
                                <td width="150">
                                    <div className="linedetail"><div className="linename">Recurring:</div><div className="linevalue">{quote.recurringTotal}</div></div>
                                    <div className="linedetail"><div className="linename">Customer:</div><div className="linevalue">{quote.accountNameShort}</div></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )
            } else {
                return <div className="quoteblock-ph">No more quotes in this stage</div>
            }
        }
        return this.props.data.map(function(success, index){
            var scores = getScoreTokens(success.scores);
            return (
                <tbody key={"successListWidget_"+index}> 
                    <tr>
                        <td rowSpan="2"><div className="successstage"><img id={success.stageToken} src={"skins/quosal/images/successevents/gauge_icons/icon-" + success.stageToken + ".png"} /><br />{success.niceStageName}</div></td>
                        <td rowSpan="2" className="sepleft center">{getBuckets(success, index)}</td>
                        <td className="sepleft sepbottom successquote fill">
                            <div className={"icon flag " + scores[0]}></div>
                            {getActionQuote(success, 0, index)}
                        </td>
                    </tr>
                    <tr>
                        <td className="sepleft successquote fill">
                            <div className={"icon flag " + scores[1]}></div>
                            {getActionQuote(success, 1, index)}
                        </td>
                    </tr>
                    <tr><td className="linesep" colSpan="4" style={{paddingTop:"5px"}}></td></tr>
                    <tr><td className="sepbottom linesep" colSpan="4" style={{paddingBottom:"5px"}}></td></tr>
                    <tr><td className="linesep" colSpan="4" style={{paddingBottom:"5px"}}></td></tr>
                </tbody>
            )
        })
    }
    render(){
        var layout = this.props.parent.getLayoutDataByName("SuccessList");
        return (
            <Panel style={{height: layout.height, width: layout.width}}>
                <div>
                    <div className="title">
                        <span className="titletext"><a href={quosal.util.url("takeaction.quosalweb")}>SuccessList&trade;</a></span>
                    </div>
                    <div className="content">
                        <table cellPadding="0" cellSpacing="0" className="fill">
                            {this.getRows()}
                        </table>
                    </div>
                </div>
            </Panel>
        )
    }
}

class WidgetTray extends React.Component {
    constructor(props){
        super(props);

        var buttonState = false;
        if(this.props.parent.props.layoutName == "default" || this.props.parent.props.layoutName == ""){
            buttonState = true;
        }

        this.state = {
            disabledButtons: buttonState
        };
        //Bind this
        this.revertToDefault = this.revertToDefault.bind(this);
        this.setAsDefaultDialog = this.setAsDefaultDialog.bind(this);
        this.deleteDialog = this.deleteDialog.bind(this);
        this.getPanelOptions = this.getPanelOptions.bind(this);
        this.layoutSelectionChanged = this.layoutSelectionChanged.bind(this);
        this.saveAsDialog = this.saveAsDialog.bind(this);
    }
    componentDidUpdate(){            
        var me = this;
        if(this.props.parent.state.isEditing){
            $('#paneltray .widget').draggable({
                cursorAt: { top: 15, left: 55 },
                stack: "#movablepanels .widget",
                scope: "widgets",
                drag: $.quosal.home.editMode.drag,
                stop: {},
                revert: 'invalid',
                distance: 10,
            });
            $(".widget.inactive.ui-draggable.ui-draggable-handle").css("position", "");
        }
        if(this.state.rerender){
            this.props.parent.props.reloadComponent({layoutName: $('#presetLayoutCombobox').val(), isEditing: true})
        }
    }
    shouldComponentUpdate(){
        if($('#presetLayoutCombobox').val() == "unselected" || $('#presetLayoutCombobox').val() == undefined){
            this.state.disabledButtons = true;
        }
        return true;
    }
    componentDidMount(){
        this.props.parent.editMode();
    }
    revertToDefault(){
        var me = this;
        var revertToDefaultLayout = quosal.api.user.revertToDefaultLayout();
        revertToDefaultLayout.finished = function(msg){
            me.props.parent.props.reloadComponent();
        }
        this.props.parent.stopEditMode();
        revertToDefaultLayout.call();
    }
    setAsDefaultDialog(){
        var me = this;
        var setDefaultLayout = function(){
            var setAsDefaultApi = quosal.api.user.setDefaultLayout($('#presetLayoutCombobox').val());
            Dialog.setIsWorking(true);
            setAsDefaultApi.finished = function(msg){
                Dialog.close();
            }
            setAsDefaultApi.call();
        }
        Dialog.open({
            title: 'Set default layout',
            message: 'Would you like to set the default layout for all users to: \'' + $('#presetLayoutCombobox').val() + '\'',
            links: [
                {
                    title: 'Cancel',
                    callback: function () {
                        Dialog.close();
                    }
                },
                {
                    title: 'Confirm',
                    callback: function () {
                        setDefaultLayout();
                    }

                }
            ]
        })
    }
    deleteDialog(){
        var me = this;
        var deleteLayout = function(){
            var deleteLayoutApi = quosal.api.user.deleteWidgetLayout($('#presetLayoutCombobox').val());
            Dialog.setIsWorking(true);
            deleteLayoutApi.finished = function(msg){
                me.props.parent.setState({layoutOptions: JSON.parse(msg.layoutOptions)});
                $("#presetLayoutCombobox").val("unselected");
                Dialog.close();
            }
            deleteLayoutApi.call();
        }
        Dialog.open({
            title: 'Delete layout',
            message: 'Are you sure you want to delete this layout: \'' + $('#presetLayoutCombobox').val() + '\'',
            links: [
                {
                    title: 'Cancel',
                    callback: function () {
                        Dialog.close();
                    }
                },
                {
                    title: 'Delete',
                    callback: function () {
                        deleteLayout();
                    }

                }
            ]
        })
    }
    getPanelOptions(){
        var optionsList = [<option key={"layoutIndex_default"} value='unselected'>Select a layout...</option>]
        this.props.parent.state.layoutOptions
            .sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase())})
            .forEach(function(layoutName, index){
                optionsList.push(<option key={"layoutIndex_"+index} value={layoutName}>{layoutName}</option>)
            })
        return optionsList;

    }
    layoutSelectionChanged(){
        if($('#presetLayoutCombobox').val() == "unselected" || $('#presetLayoutCombobox').val() == undefined){
            this.setState({disabledButtons: true});
        } else {
            this.setState({rerender: true});
        }

    }
    saveAsDialog(){
        var me = this;
        Dialog.open({
            title: 'Save preset layout',
            message: <div>Layout Name <input id="presetLayoutText" type="text" /></div>,
            links: [{
                title: 'Cancel',
                callback: function () {
                    Dialog.close();
                }
            },
            {
                title: 'Save',
                callback: function () {
                   me.props.parent.saveCurrentLayout()
                }
            }]
        })
    }
    render(){
        var selectedLayout = this.props.parent.props.layoutName ? this.props.parent.props.layoutName : "unselected";
        return (
            <Panel style={{height: "125px"}}>
                <div id="formHolder" style={{display: "none"}}>
                    <label htmlFor="name">Layout Name:</label>
                    <input type="text" name="name" />
                </div>
                <div style={{float:"left", width:"500px", position:"relative"}}>
                    <div className="formselectfieldwrapper" style={{display:"inline-block", marginRight: "5px", position: "relative", top: "5px"}}>
                        <select className="formselectfield" onChange={this.layoutSelectionChanged} defaultValue={selectedLayout} name="presetLayout" id="presetLayoutCombobox" title="Home Panel Layout">
                            {this.getPanelOptions()}
                        </select>
                    </div>
                    {app.currentUser.IsAdministrator &&
                        <div  style={{display:"inline-block", position:"absolute", top:"5px"}}>
                            <button style={{margin: "0 5px 0 0"}} onClick={this.deleteDialog} disabled={this.state.disabledButtons} >Delete</button>
                            <button style={{margin: "0 5px 0 0"}} onClick={this.setAsDefaultDialog} disabled={this.state.disabledButtons}>Set As Default</button>
                            <button style={{margin: "0 5px 0 0"}} onClick={this.saveAsDialog}>Save As...</button>
                        </div>
                    }
                </div>
                <div style={{float: "right"}}>
                    <button style={{margin: "5px 5px 0 0"}} className="save" onClick={this.revertToDefault} >Revert to Default</button>
                    <button style={{margin: "5px 5px 0 0"}} className="cancel" onClick={this.props.parent.revertWidgetChanges}>Cancel</button>
                    <button style={{margin: "5px 5px 0 0"}} className="save" onClick={this.props.parent.saveCurrentLayout} >Save</button>
                </div>
                <div style={{clear: "both"}}></div>
                <div style={{height: "50px", position: "relative"}} className="panel" id="paneltray">
                      {this.props.parent.getWidgetArray(false)}
                </div>
                <div id="paneltraytext">Drag panels out of the tray to add them to your dashboard.</div>
            </Panel>
        )
    }
}
class SignatureWidget extends React.Component {
    constructor(props){
        super(props);
        //Bind this
        this.saveSignature = this.saveSignature.bind(this);
        this.resetSignature = this.resetSignature.bind(this);
        this.importSignature = this.importSignature.bind(this);
    }
    componentDidMount(){
        $('#reactJSignature').jSignature();
    }
    saveSignature(){
        var data = $('#reactJSignature').jSignature('getData', 'default')

        var me = this;
        var imageSaveApi = quosal.api.user.saveImage(data);
        imageSaveApi.finished = function (msg) {
            me.props.saveCallback(msg.repSignatureUrl)
        }
        imageSaveApi.call();
    }
    resetSignature(){
        $('.jSignature').jSignature('reset')
    }
    importSignature(){
        var editor = <ImageEditor imageId="Signature" parent={this} afterSaveCallback={this.props.saveCallback}/> 
        Dialog.open({
            title:'Edit Image', height:'620px', width:'760px', resizable: true, draggable: true, onClose: Dialog.close,
            links:[{title: 'Cancel', callback: Dialog.close}],
            message: editor
        });
    }
    render(){
        return (
            <div style={{minWidth: "450px"}}>
                <div>Expected Aspect Ratio of 4:1</div>
                <div style={{marginTop: 5}}>
                    <button onClick={this.importSignature} id="importSignature">Import Signature</button>
                    <p> Or sign in the box below to create your user signature </p>
                    <div style={{border: "1px dotted grey"}} id="reactJSignature"></div>
                    <br />
                    <div style={{float:"left"}}>
                        <button onClick={this.resetSignature}>Reset</button>
                    </div>
                    <div style={{float:"right"}}>
                        <button style={{marginRight: "5px"}} onClick={() => Dialog.close()} className="cancel">Cancel</button>
                        <button onClick={this.saveSignature} className="save">Save</button>
                    </div>
                </div>
            </div>
        )
    }
}