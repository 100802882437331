/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import transformData from "../utils/productsMapData";
import { fetchData } from "../services/fetchData";

// eslint-disable-next-line import/prefer-default-export
export function useSearchService() {
	const [products, setProducts] = useState([]);
	const [isLoadingProducts, setIsLoadingProducts] = useState(false);
	const [RTPPayload, setRTPPayload] = useState(null);
	const [productsToFilter, setProductsToFilter] = useState(null);
	const [isFilteredWithPrice, setIsFilteredWithPrice] = useState(true);
	const [searchSignalKey, setSearchSignalKey] = useState(null);

	const handleSearch = (searchOptions) => {
		setIsLoadingProducts(true);
		setProductsToFilter(null);
		setSearchSignalKey(searchOptions.sourceTypes[0]);
		fetchData(
			"quosalweb/quosalapi/Product.GlobalSearch",
			"Product.GlobalSearch",
			{ signalKey: searchOptions.sourceTypes[0] },
			searchOptions
		).then((resp) => {
			const { response0: { result, error } = [] } = resp;
			if (error || !result.length) {
				setProducts([]);
				setIsLoadingProducts(false);
				return;
			}

			const data = transformData(result);
			setProducts(data);
			setIsLoadingProducts(false);
			setSearchSignalKey(null);
			setRTPPayload(
				data
					.filter((item) => item.masterProductId)
					.map((item) => ({ MPN: item.mpn, SKU: item.sku }))
			);
		});
	};

	const setPrimaryPriceData = (prods) =>
		prods?.map((prod) => {
			if (!prod.prices.length) return prod;
			const priceDataWithOnHand = prod.prices.filter((price) => price.OnHand > 0);
			const dataToUse = priceDataWithOnHand.length ? priceDataWithOnHand : prod.prices;
			const primaryPriceData = dataToUse.reduce((prev, curr) => {
				if (prev.Price < curr.Price) {
					return prev;
				}
				if (prev.Price > curr.Price) {
					return curr;
				}
				if (prev.Price === curr.Price && prev.OnHand === curr.OnHand) {
					return prev.SourceName.includes("RTP") ? prev : curr;
				}
				if (prev.Price === curr.Price && prev.OnHand !== curr.OnHand) {
					return prev.OnHand > curr.OnHand ? prev : curr;
				}
			});

			return {
				...prod,
				onHand: primaryPriceData.OnHand,
				price: primaryPriceData.Price,
				source: primaryPriceData.DisplaySourceName,
				sourceId: primaryPriceData.SourceId,
				stock: primaryPriceData.InStock
			};
		});

	const handleRTPPrices = (prods, rtps) => {
		const productsWithRTPPrices = prods.map((prod) => {
			const matchRTP = rtps.length ? rtps.find((rtp) => prod.mpn === rtp.MPN) : null;

			return matchRTP
				? {
						...prod,
						loadPriceList: false,
						prices: [...prod.prices, ...matchRTP.Prices]
				  }
				: { ...prod, loadPriceList: false };
		});

		const productsWithPrimaryPrice = setPrimaryPriceData(productsWithRTPPrices);
		setProductsToFilter(productsWithPrimaryPrice);
	};

	useEffect(() => {
		if (RTPPayload && !!RTPPayload.length) {
			fetchData("quosalweb/quosalapi/Product.GetRTPrices", "Product.GetRTPrices", {
				rtps: RTPPayload
			}).then((resp) => {
				const { response0: { prices, error } = [] } = resp;
				if (error) {
					console.error("Can't get RTP prices");
					return;
				}
				handleRTPPrices(products, prices);
			});
		}
	}, [RTPPayload]);

	useEffect(() => {
		if (!productsToFilter) return;

		if (isFilteredWithPrice) {
			setProducts(productsToFilter.filter((prod) => !!prod.prices.length));
		} else setProducts(productsToFilter);
	}, [productsToFilter, isFilteredWithPrice]);

	return {
		products,
		isLoadingProducts,
		isFilteredWithPrice,
		productsToFilter,
		searchSignalKey,
		handleSearch,
		setIsFilteredWithPrice
	};
}
