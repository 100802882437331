import {EditAgreementMapping} from "js/jsx/src/classes/settings/editAgreementMapping.jsx";

export class AgreementMappingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quoteTabItems: [],
            nonDefaultMappings: [],
            defaultMapping: { quoteId: null, tabId: null },
            agreementTypes: [],
            mappingToEdit: null,
            isSaving: false,
            editingMode: false
        }

        this.save = this.save.bind(this);
        this.editAgreementMapping = this.editAgreementMapping.bind(this);
        this.addNewAgreementMapping = this.addNewAgreementMapping.bind(this);
        this.deleteAgreementMapping = this.deleteAgreementMapping.bind(this);
        this.updateNonDefaultMappings = this.updateNonDefaultMappings.bind(this);
        this.exitEditMode = this.exitEditMode.bind(this);
        this.defaultQuoteTemplateSelected = this.defaultQuoteTemplateSelected.bind(this);
        this.defaultTabSelected = this.defaultTabSelected.bind(this);
        var initializeAPI = quosal.api.agreementMapping.getAgreementMappingsExtendedData();
        initializeAPI.finished = function (msg) {
            var agreementMappingsExtended = JSON.parse(msg.AgreementMappingsExtended);
            this.setState({
                quoteTabItems: agreementMappingsExtended.quoteTabItems,
                nonDefaultMappings: agreementMappingsExtended.nonDefaultAgreementMappings,
                defaultMapping: { 
                    quoteId: agreementMappingsExtended.defaultAgreementMapping == null ? null : agreementMappingsExtended.defaultAgreementMapping.idQuote,
                    tabId: agreementMappingsExtended.defaultAgreementMapping == null ? null : agreementMappingsExtended.defaultAgreementMapping.idQuoteTab
                },
                agreementTypes: agreementMappingsExtended.agreementTypes,
                initialized: true
            });
        }.bind(this);
        initializeAPI.call();
    }
    defaultQuoteTemplateSelected(e) {
        var selectedQuoteId = e.target.value;
        var defaultQuote = null;
        var tabItems = this.state.quoteTabItems;
        if (tabItems && tabItems.length > 0) {
            defaultQuote = tabItems.find(function (tabItem) {
                return tabItem.quoteId === selectedQuoteId;
            });
        }

        this.setState({
            isDirty: true,
            defaultMapping: {
                quoteId: defaultQuote == null ? null : defaultQuote.quoteId,
                tabId: defaultQuote == null ? null : defaultQuote.tabList[0].id
            }
        });
    }

    defaultTabSelected(e) {
        var selectedTabId = e.target.value;
        this.setState({ isDirty: true, defaultMapping: { quoteId: this.state.defaultMapping.quoteId, tabId: selectedTabId} });
    }
    displayError(title, message)
    {
        Dialog.open({
            title: title,
            message: message,
            links: [Dialog.links.ok]
        });
    }
    save() {
        this.setState({ isSaving: true });

        var displayError = this.displayError;
        if (this.state.defaultMapping.quoteId && this.state.defaultMapping.tabId) {
           
            var addVendorAPI = quosal.api.agreementMapping.saveAgreementMapping(null, null, this.state.defaultMapping.quoteId, this.state.defaultMapping.tabId, true);
            Dialog.setIsWorking();
            addVendorAPI.finished = function (msg) {                
                this.setState({
                    isSaving: false, isDirty: false
                }), 
                Dialog.setIsWorking(false);
                if (!msg.success) {
                    displayError('Error Saving Default Mapping', msg.errorMsg);
                }
            }.bind(this);
            addVendorAPI.call();
        }
        else {
            this.setState({ isSaving: false });
            var message = "No Default Quote Template or Default Item Tab selected.";
            displayError('Error Saving Default Mapping', message);
        }
    }
    updateNonDefaultMappings(mappings) {
        if (mappings) {
            this.setState({ nonDefaultMappings: mappings });
        }
    }
    exitEditMode() {
        this.setState({
            editingMode: false,
            mappingToEdit: null
        });
    }
    addNewAgreementMapping() {
        this.setState({
            editingMode: true,
            isDirty: false
        });    
    }
    editAgreementMapping(row) {
        this.setState({
            editingMode: true,
            isDirty: false,
            mappingToEdit: row,
        });
    }    
    deleteAgreementMapping(idAgreementMapping) {
        var toDelete = this.state.nonDefaultMappings.find(function (item) {
            return item.idAgreementMapping === idAgreementMapping;
        });

        var indexTodelete = this.state.nonDefaultMappings.indexOf(toDelete);
        var displayError = this.displayError;
        var doDelete = function () {
            Dialog.close();
            if (idAgreementMapping) {                
                var addVendorAPI = quosal.api.agreementMapping.deleteAgreementMapping(idAgreementMapping);
                Dialog.setIsWorking();
                addVendorAPI.finished = function (msg) {
                    var nonDefaultMappings = this.state.nonDefaultMappings;
                    if(msg.success)
                    {
                        nonDefaultMappings.splice(indexTodelete, 1);
                    }
                    this.setState({
                        nonDefaultMappings: nonDefaultMappings,
                        isSaving: false, isDirty: false
                    });

                    Dialog.setIsWorking(false);
                    if (!msg.success) {
                        displayError('Error Deleting Agreement Mapping', msg.errorMsg);
                    }
                }.bind(this);
                addVendorAPI.call();
            }
        }.bind(this);
    
        Dialog.confirmDelete({
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete this Agreement Mapping?',
            callback: doDelete
        });
    }    
    render() {

        if (!this.state.initialized) {
            return <FormPlaceholder message={'Loading Agreement Mappings ...' } />;
        }

        if (this.state.editingMode) {
            return (<EditAgreementMapping exitEditMode={this.exitEditMode.bind(this)} nonDefaultMappings={this.state.nonDefaultMappings} agreementTypes={this.state.agreementTypes} quoteTabItems={this.state.quoteTabItems} 
                          mappingToEdit={this.state.mappingToEdit} updateNonDefaultMappings={this.updateNonDefaultMappings} />);
        }

        var titleChildren = (<PanelToolbar style={{ display: 'block', fontSize: '12px' } }><SaveButton id="topSave" style={{ float: 'right', marginRight: 10 }} disabled={!this.state.isDirty  || this.props.isSaving} isSaving={this.state.isSaving} onClick={this.save } /> 
            <Button style={{ float: 'right', marginRight: 10 }} onClick={this.addNewAgreementMapping }>Add</Button></PanelToolbar>);

        var templateOptions = [];
        templateOptions.push(<option key={'option0'} value=''>{''}</option>);
        if (this.state.quoteTabItems && this.state.quoteTabItems.length > 0) {
            for (var i = 0; i < this.state.quoteTabItems.length; i++) {
                templateOptions.push(<option key={'option' + (i + 1)} value={this.state.quoteTabItems[i].quoteId }>{this.state.quoteTabItems[i].quoteName}</option>);
            }
        }

        var tabOptions = [];
        if (this.state.defaultMapping && this.state.defaultMapping != null && this.state.defaultMapping.quoteId != null &&
            this.state.quoteTabItems && this.state.quoteTabItems.length > 0) {
            var selectedQuoteId = this.state.defaultMapping.quoteId;
            var defaultQuote = this.state.quoteTabItems.find(function(tabItem) {
                return tabItem.quoteId == selectedQuoteId;
            });

            if (defaultQuote && defaultQuote != null) {
                for (var i = 0; i < defaultQuote.tabList.length; i++) {
                    tabOptions.push(<option key={'option' + (i)} value={defaultQuote.tabList[i].id}>{defaultQuote.tabList[i].name}</option>);
                }
            }            
        }

        var idx = 0;
        var editAgreementMapping = this.editAgreementMapping;
        var deleteAgreementMapping = this.deleteAgreementMapping;

        var rows = this.state.nonDefaultMappings ? this.state.nonDefaultMappings.map(function (r) {
            idx++;
            var onClickEdit = editAgreementMapping.bind(null, r);
            var onClickDelete = deleteAgreementMapping.bind(null, r.idAgreementMapping);
            return (<tr key={idx }>
                    <td className="content center hidden">{r.idAgreementMapping}</td>
                    <td className="content center hidden">{r.idQuote}</td>
                    <td className="content center hidden">{r.idQuoteTab}</td>
                    <td className="content center hidden">{r.agreementTypeCrmId}</td>
                    <td className="content center"><a onClick={onClickEdit }><div className="icons-action  edit color" title='Edit Agreement Mapping' /></a></td>
                    <td className="content center">{r.agreementType}</td>
                    <td className="content center">{r.quoteName}</td>
                    <td className="content center">{r.tabName}</td>
                    <td className="content center"><a onClick={onClickDelete}><div className="icons-action  delete color" title="'Delete Agreement Mapping'" /></a></td>
                    </tr>);
        }) : null;

        return (
            <div>
            <Panel title="Agreement Mappings" titleChildren={titleChildren } >
                    <PanelContent>
                        <div class="formcolumn">
                            <div class="formfieldlabel"><label for="DefaultQuoteTemplate" class="standardformlabel">Default Quote Template</label></div>
                            <div class="formselectfieldwrapper">
                                <select class="formselectfield" type="text" id='ForeignKey_DefaultQuoteTemplate' 
                                        name='DefaultQuoteTemplate' value={this.state.defaultMapping.quoteId} onKeyPress={this.props.keyPressed} onChange={this.defaultQuoteTemplateSelected}>
                                    {templateOptions}
                                </select>
                            </div>
                        </div>

                        <div class="formcolumn">
                            <div class="formfieldlabel"><label for="DefaultTab" class="standardformlabel">Default Item Tab</label></div>
                            <div class="formselectfieldwrapper">
                                <select class="formselectfield" type="text" id='ForeignKey_DefaultTab'
                                        name='DefaultTab' value={this.state.defaultMapping.tabId} onKeyPress={this.props.keyPressed} onChange={this.defaultTabSelected}>
                                    {tabOptions}
                                </select>
                            </div>
                        </div>
                        <table className="datagrid nosort" style={{ marginTop: '10px' }} cellSpacing={0} cellPadding={0 }>
                            <thead>
                            <tr>
                                <th className="nosort hidden" />
                                <th className="nosort hidden" />
                                <th className="nosort hidden" />
                                <th className="nosort" width='20px' />
                                <th className="center" >Agreement Type</th>
                                <th className="nosort center">Quote Template</th>
                                <th className="nosort center">Item Tab</th>
                                <th className="nosort" width='20px' />
                            </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </PanelContent>
                </Panel>          
            </div>
        );
    }
}
