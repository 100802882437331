
$(document).ready(function () {
    jsignatureHookup();
});

var jsignatureHookup = function () {
    // signature import is apart of the jcrop_ui.js file!
    $('.signature').each(function () {
        if ($(this).attr('src') == 'images/empty.png') {
            var container = $('<div class="signature container">Sign Here</div>');
            $(this).before(container);
            container.append(this);
            $(this).attr('src', '');
            $(this).addClass('empty');
        }
    });
    $('.signature').click(function () {
        $.quosal.ui.displayWindow($('.jSigWindow'));
        $.quosal.ui.showMask();
        $.quosal.ui.positionIn($('.jSigWindow'), $('body'), 'top-center', $(window).height() / 2 - $('.jSigWindow').height() / 2);
        $('#jSigData').val('');
        $('#jSignature').jSignature();
        $("#jSigReset").addClass("disabled");
    });

    $('#jSigReset').click(function () {
        $('.jSignature').jSignature('reset');
        $("#jSigReset").addClass("disabled");
    });
    $("#jSignature").mousedown(function () {
        $("#jSigReset").removeClass("disabled");
    })
    $('#jSignature').change(function () {
        $('#jSigData').val($(this).jSignature('getData', 'default'));
    });

    $('#jSigCancel').click(function () {
        $('#jSignature').jSignature("reset");
        $('#jSignature').html('');
        $('#jSigData').val('');
        $.quosal.ui.hideMask();
        $('.jSigWindow').hide();
    });
    $(".jSigWindow [name='signatureHelp']").click(function () {
        $('.jSigWindow.window').toggleClass('belowmask', true);
        $.quosal.ui.positionIn($('#jSigHelp'), $('body'), 'top-center', ($(window).height() / 2 - $('.jSigWindow').height() / 2) - 10);
        $('#jSigHelp').show();
        $("#mask").css("opacity", .6);
        $("#mask,#jSigHelp").click(function () {
            $('.jSigWindow.window').toggleClass('belowmask', false);
            $('#jSigHelp').hide();
            $("#mask").css("opacity", .3);
            $('#mask').unbind('click');
        });
    });
    $('#sigform').submit(function () {
        $("#imageRelayFrame").one('load', function () {
            var result = JSON.parse($("#imageRelayFrame").contents().find('body').text());

            if (result.error) {
                alert(result.error);
                //$.quosal.dialog.hide();
                //have to fight $.quote.dialog to keep the mask up but remove spinners and tips.
                // very annoying
                $(document.body).children('#april2018spinner_wrap').fadeOut(function () {
                    $(this).remove();
                });
                // lie to prevent tips from showing after ten seconds
                $.quosal.dialog.isVisible = false;
            } else {

                $('#RepSignature').attr('src', result.imageUrl);

                if ($('#RepSignature').hasClass('empty')) {
                    $('#RepSignature').removeClass('empty');
                    var container = $('#RepSignature').parent();
                    container.before($('#RepSignature'));
                    container.remove();
                }

                $('#sigform').find('.btn.save').removeClass('disabled').find('.mid').text('Save');
                $('#jSignature').jSignature("reset");
                $('#jSignature').html('');
                $('#jSigData').val('');
                $.quosal.dialog.hide()
                $('.jSigWindow').hide();
            }
        });
        return true;
    });
}