import { useState } from "react";

// eslint-disable-next-line import/prefer-default-export
export function useNotifications() {
	const [notification, setNotification] = useState({});

	const showNotification = (title, message, type = "success") => {
		setNotification({
			id: new Date().toISOString(),
			isOpen: true,
			title,
			message,
			type
		});
	};

	return { showNotification, notification };
}
