import {CustomerSetupToolbar} from "js/jsx/src/classes/quote/quoteCustomerSetupToolbar.jsx";

export class AttachSite extends React.Component {
    constructor(props) {
        super(props);
        this.selectedSites={"quoteto": {}, "billto":{}, "shipto":{}, "sendto":{} };
        this.state = {
            isSaving: false, 
            sites: this.props.sites, 
            isSearching: false,
        };

        this.doSiteSearch = this.doSiteSearch.bind(this);
        this.selectSite = this.selectSite.bind(this);
        this.getSiteRows = this.getSiteRows.bind(this);
    } 
    doSiteSearch(){
        var getSitesApi = quosal.api.crm.getAdditionalSites(app.currentQuote.IdQuoteMain, $("#SiteName").val(), null);
        var me = this;
        this.setState({isSearching: true})
        getSitesApi.finished = function(msg){
            me.setState({sites: JSON.parse(msg.data.sites), isSearching: false})
        }
        getSitesApi.call();
    }
    selectSite(site){
        var me = this;
        this.selectedSites['quoteto'] = site;
        this.selectedSites['billto'] = site;
        this.selectedSites['shipto'] = site;
        this.selectedSites['sendto'] = site;
        var changeAddressesApi = quosal.api.crm.changeAddresses(app.currentQuote.IdQuoteMain, this.selectedSites["quoteto"],
        this.selectedSites["billto"],this.selectedSites["shipto"],this.selectedSites["sendto"] );
        changeAddressesApi.finished = function(msg){
            quosal.sell.quote.update(msg.quote);
            app.currentModule.loadSubModule('quote.home', {
                container: 'quoteModule',
                unloadSubModules: true
            });
        }
        this.setState({isSaving: true})
        changeAddressesApi.call();
    }
    getSiteRows(){
        var me = this;
        return this.state.sites["Primary"].map(function(site, index){
            var attachedIcon = "skins/quosal/images/" + site.IsAttached + ".png";
            var address = site.Address1 + " " + site.Address2 + ", " + site.City + ", " + site.State + " " + site.PostalCode;
            return (
                <tr key={'siteRow_'+index}>
                    <td className="content"><center><div onClick={me.selectSite.bind(me, site)} className="selectaddress link">Attach</div></center></td>
                    <td className="content"><img className="attachedimage" src={attachedIcon} /></td>
                    <td className="content">{site.SiteName}</td>
                    <td className="content"> {address}</td>
                </tr>
            )
        })
    }
    render(){
        return(
            <div>
                <CustomerSetupToolbar saveCallback={this.saveCallback} isDirty={this.state.isDirty} />
                {this.state.isSaving
                ? 
                <FormPlaceholder message="Saving..." />  
                :
                <div className="fluidpanels">
                    <div className="fluid">
                        <div className="grid col4x1" >
                            <div className="panel" help="customer-sites-search-criteria">
                    
                                <div className="title">
                                    Search Criteria
                                </div>   

                                <div className="content">
                        
                                    <div className="standardformfieldlabel"><label htmlFor="SiteName" className="standardformlabel">Site Name</label></div>
                                    <input className="searchinput" type="text" name="SiteName" id="SiteName" title="Site Name" size="28"/>
                                
                                    <br />
                                    <br />
                                    <button onClick={this.doSiteSearch} className="btn submit">Search</button>
                                </div> 
                            </div>
                        </div>
                        
                        <div className="grid col4x3">
                            <div className="panel paging">
                                <div className="title">
                                        Customer Sites
                                </div>   
                                {this.state.isSearching
                                ? 
                                <FormPlaceholder message="Searching..."/>  
                                :
                                <div className="content">
                                    <table border="0" cellPadding="0" cellSpacing="0" className="datagrid">
                                        <thead>
                                        <tr>
                                            <th width="100" className="title nosort"></th>
                                            <th width="50" className="title nosort">Default</th>
                                            <th width="280" className="title">Site Name</th>
                                            <th width="396" className="title">Address</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {this.getSiteRows()}
                                        </tbody>
                                    </table>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}