export const formatOtherUsersList = (otherUsers = []) => {
	let userList = "";
	if (otherUsers && otherUsers.length > 0) {
		for (var i = 0; i < otherUsers.length; i++) {
			var user = otherUsers[i];
			userList +=
				(userList == "" ? "" : ", ") +
				user.FirstName +
				" " +
				user.LastName +
				" (" +
				user.UserName +
				")";
		}
	}
	return userList;
};

export const getPackageLevels = () => {
	return app?.packageLevels;
};

export const getQuoteHeaderAllowedMatrics = ({
	hideProfit,
	isNonPremiumPartner,
	EnableOneTimeTotal,
	EnableOneTimeGrossProfit,
	EnableRecurring,
	EnableRecurringGrossProfit,
	EnableTotalQuoteValue,
	EnableDownpayment
}) => {
	let metricsAllowed = [];
	if (hideProfit && isNonPremiumPartner) {
		metricsAllowed = [EnableOneTimeTotal, EnableRecurring, EnableTotalQuoteValue];
	} else if (hideProfit && !isNonPremiumPartner) {
		metricsAllowed = [
			EnableOneTimeTotal,
			EnableRecurring,
			EnableTotalQuoteValue,
			EnableDownpayment
		];
	} else if (isNonPremiumPartner && !hideProfit) {
		metricsAllowed = [
			EnableOneTimeTotal,
			EnableOneTimeGrossProfit,
			EnableRecurring,
			EnableRecurringGrossProfit,
			EnableTotalQuoteValue
		];
	} else {
		metricsAllowed = [
			EnableOneTimeTotal,
			EnableOneTimeGrossProfit,
			EnableRecurring,
			EnableRecurringGrossProfit,
			EnableTotalQuoteValue,
			EnableDownpayment
		];
	}
	return metricsAllowed;
};

export const getPublishText =
	(quoteItem) => {
		if (quoteItem.PublishMethod && quoteItem.PublishMethod.indexOf("DOCUSIGN") > -1) {
			return "Emailed DocuSign";
		} else if (quoteItem.PublishMethod === "DOCX") {
			return "Emailed DOCX";
		} else if (quoteItem.PublishMethod === "DOC") {
			return "Emailed DOC";
		} else if (quoteItem.PublishMethod === "RTF") {
			return "Emailed RTF";
		} else if (quoteItem.PublishMethod === "XLS") {
			return "Emailed XLS";
		} else if (quoteItem.PublishMethod === "PDF") {
			return "Emailed PDF";
		} else {
			return quoteItem.IsOrderPorterUploaded ? "View Order Porter" : "Not Published";
		}
	}

export const isPublishItemClickable = (quoteItem) => {
		if(quosal.sell.modules.checkIfLocked("quote.publish", quoteItem) && !quoteItem.IsOrderPorterUploaded) {
			return false;
		}
		if (
			(quoteItem.PublishMethod && quoteItem.PublishMethod.indexOf("DOCUSIGN") > -1) ||
			quoteItem.PublishMethod === "DOCX" ||
			quoteItem.PublishMethod === "DOC" ||
			quoteItem.PublishMethod === "RTF" ||
			quoteItem.PublishMethod === "XLS" ||
			quoteItem.PublishMethod === "PDF"
		) {
			return false;
		} else {
			return true;
		}
	}
