import { Grid, IconButton } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { GridColumnHeaderTitle } from '@mui/x-data-grid';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { CommonSalesTrackTitle, CommonTypography } from '../common/guidedSelling/styledComponents/commonStyled'
import { CommonBreadCrumbs } from '../common/guidedSelling/components/commonBreadCrumbs'
import { getDesignTokens } from '../common/guidedSelling/theme/adminGuidedSellingTheme';
import { api } from '../common/guidedSelling/api/api';
import { SalesTrackFormPage } from './salesTrackFormPage';
import { SUBMITTED_FORMS_COMMON_HEADERS } from '../common/guidedSelling/constants/common';
import { CommonSubmittedFormsDataGrid } from '../common/guidedSelling/components/commonSubmittedFormsDataGrid';

const SALESTRACKS_SUBMITTED_FORMS_ACTION = 'SALESTRACKS_SUBMITTED_FORMS_ACTION';
const SALESTRACK_COMPLETED_FORM_ACTION = 'SALESTRACK_COMPLETED_FORM_ACTION';

export class SalesTracksForms extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submittedForms: [],
            totalForms: 0,
            mode: $('#dark-toggle').prop('checked'),
            sortModel: [],
            pageAction: SALESTRACKS_SUBMITTED_FORMS_ACTION,
            selectedSalestrack: null,
            selectedCompletedForm: null,
            isLoading: false,
        }

        // Bind this
        this.getSubmittedSalesTracksForms = this.getSubmittedSalesTracksForms.bind(this);
        this.changeThemeMode = this.changeThemeMode.bind(this);
    }


    componentDidMount() {
        this.changeThemeMode();
        this.getSubmittedSalesTracksForms()
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
        const $checkbox = $('#dark-toggle[type=checkbox]');
        $checkbox.off('change');
    }

    getSubmittedSalesTracksForms = (sortBy = 'submittedDate', sortDirection = 'desc', sortModel = []) => {
        const { id } = app.currentQuote;
        const params = {
            idQuote: id,
            sortBy: sortBy,
            sortDirection: sortDirection,
            pageNumber: 0,
            pageSize: 0,
        }
        this.setState({ ...this.state, isLoading: true });
        api.getSalesTrackCompletedForms(params, (msg) => {
            const newSubmittedForms = !!msg.results && msg.results.map(submittedFormSelected => { return { ...submittedFormSelected, id: submittedFormSelected.idCompletedForm } });
            this.setState({ ...this.state, submittedForms: newSubmittedForms, totalForms: msg.totalCount, isLoading: false, sortModel: sortModel });
        });
    }

    changeThemeMode() {
        const $checkbox = $('#dark-toggle[type=checkbox]');
        const handleSaveThemeMode = (isChecked) => {
            this.setState({ ...this.state, mode: isChecked });
        }
        $checkbox.on('change', function (e) {
            handleSaveThemeMode(e.currentTarget.checked);
        });
    }

    render() {
        const { QuoteName } = app.currentQuote;
        const { submittedForms, sortModel, totalForms, selectedCompletedForm, selectedSalestrack, isLoading } = this.state;
        const theme = createTheme(getDesignTokens(this.state.mode ? 'dark' : 'light'));
        const onBack = () => this.setState({ ...this.state, pageAction: SALESTRACKS_SUBMITTED_FORMS_ACTION })

        const breadCrumbsData = {
            actualBreadCrumbName: 'Submitted Sales Track Forms',
            breadCrumbs: [
                {
                    name: 'Quote Setup',
                    navigate: () => {
                        app.currentModule.loadSubModule('quote.home', {
                            container: 'quoteModule',
                            unloadSubModules: true
                        });
                    },
                }
            ],
        }

        const completedFormBreadCrumbsData = {
            actualBreadCrumbName: selectedSalestrack?.name,
            breadCrumbs: [
                {
                    name: 'Quote Setup',
                    navigate: () => {
                        app.currentModule.loadSubModule('quote.home', {
                            container: 'quoteModule',
                            unloadSubModules: true
                        });
                    },
                }, {
                    name: 'Submitted Sales Track Forms',
                    navigate: onBack,
                }
            ],
        }

        const customRenderHeader = (params) => {
            const { colDef } = params;
            const isSorting = sortModel.length > 0 && sortModel[0].field === colDef.field;
            return (
                <>
                    <GridColumnHeaderTitle
                        label={colDef.headerName || colDef.field}
                        description={colDef.description}
                        columnWidth={colDef.width}
                    />
                    {!isSorting && (
                        <div className="MuiDataGrid-iconButtonContainer MuiDataGrid-customIconButtonContainer">
                            <IconButton
                                sx={{
                                    minWidth: '28px',
                                    minHeight: '28px',
                                }}
                                size="small"
                            >   
                                <SwapVertIcon className="MuiDataGrid-sortIcon MuiDataGrid-customSortIcon" sx={{ opacity: '1 !important', color: theme.palette.icon.main }} />
                            </IconButton>
                        </div>
                    )}
                </>
            );
        };

        const handleLinkToForm = ({ id, row: { salesTrackName } }) => {
            const urlParams = (new URL(document.location)).searchParams;
            const idQuote = urlParams.get("idquotemain") || "0";

            api.getSalesTrackCompletedForm({ idQuote, idCompletedForm: id }, ({ sections }) => {
                this.setState({ ...this.state, selectedCompletedForm: sections, selectedSalestrack: { name: salesTrackName }, pageAction: SALESTRACK_COMPLETED_FORM_ACTION })
            });
        };

        const columns = [...SUBMITTED_FORMS_COMMON_HEADERS(customRenderHeader, handleLinkToForm)];

        const customFooter = () => <CommonTypography
            sx={{
                borderTop: '1px solid',
                borderColor: theme.palette.border.border_subtle2,
                padding: '18px 0 12px',
                width: '100%',
                textAlign: 'end',
                lineHeight: '18px',
                fontSize: '0.8rem',
                color: theme.palette.text.main,
                letterSpacing: '0.016rem',
            }}
        >{`Total Rows: ${totalForms}`}</CommonTypography>

        const handleSortModelChange = (sortModel) => {
            sortModel.length > 0 ? this.getSubmittedSalesTracksForms(sortModel[0].field, sortModel[0].sort, sortModel) : this.getSubmittedSalesTracksForms();
        };

        const pagesHandler = {
            [SALESTRACKS_SUBMITTED_FORMS_ACTION]: () => <>
                <Grid container direction="row" rowSpacing='1rem' p='1rem 2rem'>
                    <Grid item xs={12}>
                        <CommonBreadCrumbs {...breadCrumbsData} />
                    </Grid>
                    <Grid item xs={12} pt='1rem'>
                        <CommonSalesTrackTitle variant="h2" className="salesTrack-title">{`${QuoteName} - Submitted Sales Track Forms`}</CommonSalesTrackTitle>
                    </Grid>
                    <CommonSubmittedFormsDataGrid
                        theme={theme}
                        submittedForms={submittedForms}
                        columns={columns}
                        sortModel={sortModel}
                        isLoading={isLoading}
                        customFooter={customFooter}
                        handleSortModelChange={handleSortModelChange}
                    />
                </Grid>
            </>,
            [SALESTRACK_COMPLETED_FORM_ACTION]: () => <>
                <Grid container direction="row" rowSpacing='1rem' p='1rem 2rem'>
                    <Grid item xs={12}>
                        <CommonBreadCrumbs {...completedFormBreadCrumbsData} />
                    </Grid>
                    <Grid item xs={12} pt='1rem'>
                        {!!selectedCompletedForm && <SalesTrackFormPage
                            readOnly
                            salesTrackSelected={selectedSalestrack}
                            salesTrackForm={selectedCompletedForm}
                            onBack={onBack} />}
                    </Grid>
                </Grid>
            </>
        };

        return (
            <ThemeProvider theme={theme}>
                {pagesHandler[this.state.pageAction]()}
            </ThemeProvider>
        )
    }
}