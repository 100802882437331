import {RtfLoader} from "js/jsx/src/classes/rtfEditor.jsx";
import { TO_EMAIL_LABEL, TO_EMAIL_LABEL_SIGNER_CONFIRMATION } from "./constants";

export class QuoteDeliver extends React.Component {
    constructor(props) {
        super(props);

        if (!quosal.deliver) {
            return {};
        }
        var quote = app.currentQuote;

        this.state = {
            emailSubject: quosal.deliver.emailSubject,
            emailTo: quote.MainCust.EmailAddress,
            emailCC: quote.PublishEmailCc,
            emailBCC: quote.PublishEmailBcc,
            emailFrom: quote.Owner.EmailAddress,
            emailBody: quosal.deliver.emailBody,
        };
        this.richTextContentChange = this.richTextContentChange.bind(this);
        this.onPreviewPicture404 = this.onPreviewPicture404.bind(this);
        this.updateDeliverForm = this.updateDeliverForm.bind(this);
        this.onFromEmailSelectChange = this.onFromEmailSelectChange.bind(this);
        this.checkSendEmail = this.checkSendEmail.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.displayError = this.displayError.bind(this);
    }

    sendEmail() {
        $('#sendEmailForm').submit();
    }

    checkSendEmail() {
        this.sendEmail();
    }

    isLoading() {
        return !quosal.deliver.file;
    }
    isError() {
        return !!quosal.deliver.error;
    }
    isFileTooLargeForFallbackError() {
        return quosal.deliver.fileTooLargeForFallback;
    }
    richTextContentChange(newContent) {
        this.setState({
            emailBody: newContent
        });
    }
    onPreviewPicture404(index) {
        var stateToAdd = {};
        stateToAdd[this.thumbnailNotAvailableKey(index)] = true;
        this.setState(stateToAdd);
    }
    thumbnailNotAvailableKey(index) {
        return 'thumbnail' + index + 'NotAvailable'
    }
    getSendEmailButton() {
        var sendEmailButton;
        var sizeInMB = (parseInt(quosal.deliver.documentFileSize) / 1000000).toFixed(2);
        if (this.isError()) {
            sendEmailButton = <Button disabled={true} >Cannot Send Email</Button>
        }
        else if (this.isFileTooLargeForFallbackError() == true) {
            var errorMessage = "Published document size is " + sizeInMB + "MB, which exceeds the maximum email size that can be sent through " + app.productBranding + " (20MB). Please reduce the size of your published document by removing additional attachments and / or large images."
            sendEmailButton = <div><div className="standaloneError" title={errorMessage}></div><Button className="button disabled" disabled={true} >Cannot Send Email</Button></div>
        }
        else {
            sendEmailButton = this.isLoading() ? <Spinner></Spinner> :
                <Button onClick={this.checkSendEmail} disabled={this.state.invalidFromEmailSelection} >Send Email</Button>;
        }
        
        sendEmailButton = <div className="toolbar right">{sendEmailButton}</div>;
        return sendEmailButton;
    }
    onFromEmailSelectChange(e) {
        var usingSalesforceEmail = app.settings.user.customerProvider && app.settings.user.customerProvider.toLowerCase() == 'salesforce' && !app.settings.global.DisableSalesforceEmail;
        if ((e.target.value === 'primaryRep') && !(quosal.deliver.primaryRepO365EmailAddress) && quosal.deliver.docType != 'DOCUSIGN' && !usingSalesforceEmail) {
            $.quosal.validation.validateField($('#from'), 'error',
                'This Primary Rep is not set up with O365 under Settings. ' +
                'Please contact them to set this up or proceed using another user.');
            this.setState({ invalidFromEmailSelection: true });
        } else if ((e.target.value === 'insideRep') && !(quosal.deliver.insideRepO365EmailAddress) && quosal.deliver.docType != 'DOCUSIGN' && !usingSalesforceEmail) {
            $.quosal.validation.validateField($('#from'), 'error',
                'This Inside Rep is not set up with O365 under Settings. ' +
                'Please contact them to set this up or proceed using another user.');
            this.setState({ invalidFromEmailSelection: true });
        } else if ((e.target.value === 'currentUser') && !(app.settings.user.O365EmailAddress) && quosal.deliver.docType != 'DOCUSIGN' && !usingSalesforceEmail) {
            $.quosal.validation.validateField($('#from'), 'error',
                'You are not set up with O365 under Settings. ' +
                'Please set this up or proceed using another user.');
            this.setState({ invalidFromEmailSelection: true });
        } else {
            $.quosal.validation.clearField($('#from'));
            this.setState({ invalidFromEmailSelection: false });
        }
    }
    updateDeliverForm(){
        if (quosal.deliver.error) {
            this.displayError();
        }
        this.forceUpdate();
    }
    componentWillUnmount() {
        quosal.sell.quote.onUpdate.unbind(this.updateDeliverForm);
    }
    displayError(){
        var errorLines = quosal.deliver.error.split('\r\n');
        var errorMarkup = [errorLines[0]];
        for (var i = 1; i < errorLines.length; i++) {
            errorMarkup.push(<br key={i} />);
            errorMarkup.push(errorLines[i]);
        }
        Dialog.open({
            title: 'Error in Document Generation',
            message: <span>{errorMarkup}</span>,
            links: [{
                title: 'Go Back to Publish Page',
                url: quosal.util.url('quote.dashboard', 'submodules=quote.publish')
            }]
        })
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (this.props.quote?.IsSignerConfirmationRequired && app?.settings?.global?.EnableSignerConfirmation) {
            if (prevProps?.quote?.MainCust?.EmailAddress != this.props?.quote?.MainCust?.EmailAddress) {
                this.setState({ ...prevState, emailTo: this.props.quote.MainCust.EmailAddress })
            }
        }
    }
    componentDidMount() {
        var handoff = this;

        $('#sendEmailForm').on('submit', function (event) {
            event.preventDefault();
            var me = handoff;
            var sendEmail = function(url, data) {               
                var request = $.ajax({
                    url: url,
                    type: 'post',
                    dataType: "html",
                    data: data,
                    success: function (data, textStatus, jqXHR) {
                        var redirectUrl = jqXHR.getResponseHeader("redirect");
                        if (redirectUrl != null) {
                            window.location.href = redirectUrl;
                            quosal.deliver.deliveredSuccessfully = true;
                        }
                        else if (data.includes("The maximum request length supported is 4MB.")) {
                            quosal.deliver.forceDisableO365 = true;
                        }
                        else {
                            document.open();
                            document.write(data);
                            document.close();
                            quosal.deliver.deliveredSuccessfully = true;
                        }
                 },
                });
                request.done();
            }

            var url = $(this).attr('action');
            var data = $(this).serialize();
            if (app.currentQuote.DocusignEnvelopeId) {
                Dialog.open({
                    message: 'Republishing the quote will invalidate the previous DocuSign request for approval. Do you wish to continue?',
                    closeRequiresButton: true,
                    zIndex: 10100,
                    links: [{
                        title: 'Yes',
                        callback: function () {
                            url += '&invaliddocusign=true';
                            Dialog.close({ callback: function() { Dialog.setIsWorking(); }});
                            sendEmail(url, data);
                        }
                    }, {
                        title: 'No',
                        url: quosal.util.url('quote.dashboard', 'submodules=quote.publish')
                    }]
                });
            }
            else if (app.currentQuote.IsOrderPorterUploaded && app.currentQuote.DeliverDocumentType == 'DOCUSIGN' && app.currentQuote.PublishMethod == 'ORDERPORTER') {
                var opApprovedApi = quosal.api.quote.checkIsOrderPorterApproved(app.currentQuote.OrderPorterPasscode);
                opApprovedApi.finished = function (msg) {
                    if (msg.isOrderPorterApproved) {
                        Dialog.open({
                            message:  "This quote is in an approved state. Do you wish to re-publish and clear the existing approval?",
                            closeRequiresButton: true,
                            zIndex: 10100,
                            links: [
                                {
                                    title: 'Yes',
                                    callback: function() {
                                        url += '&invalidorderporter=true';
                                        Dialog.close({ callback: function() { Dialog.setIsWorking(); }});
                                        sendEmail(url, data);
                                    }
                                },
                                {
                                    title: 'No',
                                    callback: function() {
                                        Dialog.close({ callback: function() { Dialog.setIsWorking(); }});
                                        var opApi = quosal.api.quote.checkOrderPorterApproval(app.currentQuote.IdQuoteMain, true, app.currentQuote.QuoteReadableId, app.currentQuote.Version, app.currentQuote.OrderPorterPasscode);
                                        opApi.finished = function (msg) {
                                            if (msg.isApproved) {
                                                quosal.sell.quote.update(msg.quote);
                                                app.currentModule.loadSubModule('quote.home', { container: 'quoteModule', query: quosal.util.updateQueryString({ idquotemain: msg.quote.IdQuoteMain, submodules:"quote.home" }) });

                                                Dialog.closeAll({   
                                                    skipAnimation: true,
                                                    callback: function () {
                                                        Dialog.open({
                                                            message: 'Your quote has been approved and you can now process it in WIN! section in the quote menu.',
                                                            links: [{
                                                                title: 'OK',
                                                                callback: Dialog.closeAll
                                                            }]
                                                        });
                                                    }
                                                });
                                            }
                                        }
                                        opApi.call();
                                    }
                                }
                            ]
                        });
                    }
                    else {
                        Dialog.open({
                            message: 'Republishing the quote will invalidate the previous Order Porter request for approval. Do you wish to continue?',
                            closeRequiresButton: true,
                            zIndex: 10100,
                            links: [{
                                title: 'Continue',
                                callback: function() {
                                    url += '&invalidorderporter=true';
                                    Dialog.close({ callback: function() { Dialog.setIsWorking(); }});
                                    sendEmail(url, data);
                                }
                            }, {
                                title: 'Cancel',
                                callback: Dialog.closeAll
                            }]
                        });
                    }  
                }
                opApprovedApi.call();
            }
            else {
                sendEmail(url, data);
            }
        });
        if (!quosal.deliver) {
            app.currentModule.unloadSubModule(app.currentModule.getActiveSubModule());
            return;
        }
        quosal.sell.quote.onUpdate.bind(this.updateDeliverForm);
        if (quosal.deliver.error) {
           this.displayError();
           return;
        }
        var usingSalesforceEmail = app.settings.user.customerProvider && app.settings.user.customerProvider.toLowerCase() == 'salesforce' && !app.settings.global.DisableSalesforceEmail;
        if (app.settings.user.UseO365 && !app.settings.user.O365EmailAddress && quosal.deliver.docType != 'DOCUSIGN' && !usingSalesforceEmail) {
            if (!app.settings.user.AllowEmailOnBehalfOfPrimaryRep &&
                !app.settings.user.AllowEmailOnBehalfOfInsideRep) {
                $.quosal.validation.validateField($('#readonlyFromUser'), 'error',
                    'You are not set up with O365 under Settings.');
            } else {
                $.quosal.validation.validateField($('#from'), 'error',
                    'You are not set up with O365 under Settings. ' +
                    'Please set this up or proceed using another user.');
            }
            this.setState({invalidFromEmailSelection: true});
        }
        $('#sendEmailForm').submit(function () {
            if (this.isLoading()) {
                return false;
            }

            for (var i = 0; i < 5; i++) {
                var deliverInput = this.refs['input' + i];
                if (deliverInput && !deliverInput.validate()) {
                    return false;
                }
            }

            Dialog.setIsWorking();
        }.bind(this));
    }
    render() {
        if (!quosal.deliver) {
            return null;
        }
        var rtfUrl = quosal.util.url('RtfEditor.aspx', 'name=deliverEmailBody' + (quosal.deliver.docType == "ORDERPORTER" ? '&isOrderPorterPublish=true' : '') + '&deliverytype=' + quosal.deliver.docType + '&orderPorterTemplate=' + encodeURIComponent(quosal.deliver.orderPorterTemplate));

        var quote = app.currentQuote;
        var previewPictures = [];
        if (quosal.deliver.thumbnails) {
            for (var i = 0; i < 9; i++) {
                if (quosal.deliver.thumbnails[i] && !this.state[this.thumbnailNotAvailableKey(i)]) {
                    previewPictures.push(<span key={i}><img src={quosal.deliver.thumbnails[i]} alt="" border="1px" onError={this.onPreviewPicture404.bind(this, i)} /> </span>);
                }
            }
        }

        var hasOrderPorter = !!quosal.deliver.orderPorterURL;
        var orderPorterLink = !hasOrderPorter ? null : (
            <div>
                <br />
                <a href={quosal.deliver.orderPorterURL + "&novisit=true"} target="_blank">{quosal.deliver.orderPorterURL + "&novisit=true"}</a>
            </div>
        );
        var fromEmailSelect = null;
        if (app.settings.user.UseO365) {
            if (!(app.settings.user.customerProvider && app.settings.user.customerProvider.toLowerCase() == 'salesforce' && !app.settings.global.DisableSalesforceEmail)) {            
                var allowOBOPrimaryRep = app.settings.user.AllowEmailOnBehalfOfPrimaryRep;
                var allowOBOInsideRep = app.settings.user.AllowEmailOnBehalfOfInsideRep;

                if (!allowOBOPrimaryRep && !allowOBOInsideRep) {
                    fromEmailSelect = <div key='from'>
                        <div className="standardformfieldlabel"><label htmlFor="from" className="standardformlabel">From
                            Email</label></div>
                        <div className="standardfield">
                            <input type="hidden" name="fromUser" id="from" value="currentUser"/>
                            <input title="From Address" id="readonlyFromUser" readonly="readonly" value={app.settings.user.O365EmailAddress || 'Requires O365 Email Setup'}/>
                        </div>
                    </div>;
                } else {
                    var fromEmailOptions = [];
                    fromEmailOptions.push(<option key="currentUser" value="currentUser">{app.settings.user.O365EmailAddress || 'Requires O365 Email Setup'}</option>);

                    if (allowOBOPrimaryRep) {
                        fromEmailOptions.push(<option key="primaryRep" value="primaryRep">Primary Rep's Email
                        ({quosal.deliver.primaryRepO365EmailAddress || 'Requires O365 Email Setup'})</option>);
                    }
                    if (allowOBOInsideRep) {
                        fromEmailOptions.push(<option key="insideRep" value="insideRep">Inside Rep's Email
                        ({quosal.deliver.insideRepO365EmailAddress || 'Requires O365 Email Setup'})</option>);
                    }   
                    fromEmailSelect = <div key='from'>
                        <div className="standardformfieldlabel">
                            <label htmlFor="from" className="standardformlabel">From Email</label>
                        </div>
                        <div className="formselectfieldwrapper standardfield" style={{border: 0}}>
                            <select className="formselectfield" name="fromUser" id="from" title="From Address"
                                    onChange={this.onFromEmailSelectChange}
                                    defaultValue="currentUser">
                                {fromEmailOptions}
                            </select>
                        </div>
                    </div>;
                }
            }
        }

        var fromEmail = null;
        if (quosal.deliver.docType == 'DOCUSIGN') {
            fromEmail = <div key='from'>
            <div className="standardformfieldlabel"><label htmlFor="from" className="standardformlabel">From
                Email</label></div>
            <div className="standardfield">
                <input type="hidden" name="fromUser" id="from" value="currentUser"/>
                <input title="From Address" id="readonlyFromUser" readonly="readonly" value="Send Via DocuSign" disabled/>
            </div>
        </div>;
        } else {
            fromEmail = fromEmailSelect || <DeliverPageInput ref="input4" parent={this} key='from' id='from' stateKey='emailFrom' label='From Email' htmlTitle='From Address' isEmail={true} required={true}></DeliverPageInput>;
        }
        var emailHeaderInputs = <div>
            <div className="formcolumn" style={{width: 'calc(100% - 25px)'}}>
                <DeliverPageInput ref="input0" parent={this} key='subject' id='subject' stateKey='emailSubject' label='Subject' required={true}></DeliverPageInput>
            </div>
            <div className="formcolumn" style={{width: 'calc(50% - 25px)'}}>
                <DeliverPageInput ref="input1" parent={this} key='to' id='to' stateKey='emailTo' 
                    label={(app?.settings?.global?.EnableSignerConfirmation && app.currentQuote?.IsSignerConfirmationRequired)? TO_EMAIL_LABEL_SIGNER_CONFIRMATION : TO_EMAIL_LABEL} 
                    style={{ zIndex: '9981' }} 
                    htmlTitle='To Address' 
                    isEmail={true} 
                    hasEmailPicker={quosal.deliver.docType == 'DOCUSIGN' || (app?.settings?.global?.EnableSignerConfirmation && app.currentQuote?.IsSignerConfirmationRequired) ? false: true} 
                    required={true} 
                    isReadOnly = {(app?.settings?.global?.EnableSignerConfirmation && app.currentQuote?.IsSignerConfirmationRequired)}
                    disabledInput={quosal.deliver.docType == 'DOCUSIGN'}>
                </DeliverPageInput>
                <DeliverPageInput ref="input2" parent={this} key='cc' id='cc' stateKey='emailCC' label='CC Emails' style={{ zIndex: '9980' }}  htmlTitle='CC Email Addresses' isEmail={true} hasEmailPicker={true}></DeliverPageInput>
            </div>
            <div className="formcolumn" style={{width: 'calc(50% - 25px)'}}>
                {fromEmail}                
                <DeliverPageInput ref="input3" parent={this} key='bcc' id='bcc' stateKey='emailBCC' label='BCC Emails' htmlTitle='BCC Email Addresses' isEmail={true} hasEmailPicker={true}></DeliverPageInput>
            </div>
        </div> ;

        var previewTitle = (hasOrderPorter ? 'PDF and Order Porter Preview' :
            (quosal.deliver.docType === 'PDF') ? 'PDF Preview' :
            'Document Preview');

        var sendEmailButton = this.getSendEmailButton();       

        return (
            <div id="DeliverId">
                <Panel title={previewTitle} >
                    <PanelContent style={{minHeight: 164}}>
                        { this.isError() ? <center className="prose" >Document generation failed.</center> :
                            this.isLoading() ? <center><Spinner></Spinner></center> :
                            <center>
                                <div
                                    style={{fontSize: '40px' /*FSP 9/14/17: To make spaces between imgs about 10px wide*/}}>
                                    {previewPictures}
                                </div>
                                <br />
                                <a href={quosal.deliver.pdfDownloadLink}
                                   target="_blank">{quosal.deliver.pdfFileName}</a>
                                {orderPorterLink}
                            </center>
                        }
                    </PanelContent>
                </Panel>
                <Panel title="Email Content" titleChildren={sendEmailButton} duplicateTitleButtonsInFooter={true} >
                    <PanelContent>
                        <form id="sendEmailForm" method="post" action={quosal.util.url('email.quosalweb')}>
                            <input type="hidden" name="file" value={quosal.deliver.file} />
                            {emailHeaderInputs}
                            {rtfUrl && <RtfLoader message="Loading Email Editor..." url={rtfUrl} applyChangedContent={this.richTextContentChange} callOnChangeImmediately={true} />}
                            <input type="hidden" name="body" value={this.state.emailBody} />
                            <input type="hidden" name="doctype" value={quosal.deliver.docType} />
                        </form>
                    </PanelContent>
                </Panel>
            </div>
        );
    }
}

window.QuoteDeliver = QuoteDeliver; 

class DeliverPageInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {                       
        }; 

        this.onChange = this.onChange.bind(this);
        this.validate = this.validate.bind(this);
        this.setValue = this.setValue.bind(this);

        this.timeoutId = null;
    }
    onChange(e) {
        this.setValue(e.target.value, function () {
            window.clearTimeout(this.timeoutId);
            this.timeoutId = window.setTimeout(function () {
                this.validate()
            }.bind(this), 200);
        }.bind(this));
    }
    validate() {
        var valid = true;
        if (this.props.required) {
            valid = $.quosal.validation.validateData.blank($('#' + this.props.id), 'error', 'This input is required.')
        }
        if (valid && this.props.isEmail) {
            valid = $.quosal.validation.validateData.email($('#' + this.props.id), 'error', 'Email addresses must be valid.')
        }
        if (valid) {
            $.quosal.validation.clearField($('#' + this.props.id));
        }
        return valid
    }
    setValue(value, callback) {
        var newState = {};
        newState[this.props.stateKey] = value;
        if ('function' === typeof callback) {
            this.props.parent.setState(newState, callback);
        } else {
            this.props.parent.setState(newState);
        }
        this.forceUpdate();
    }
    render() {
        var id = this.props.id;
        var value = this.props.parent.state[this.props.stateKey];
        var label = this.props.label;
        var htmlTitle = this.props.htmlTitle || label;
        var hasEmailPicker = this.props.hasEmailPicker;
        var fieldDivClass = 'standardfield';
        var emailPicker = null;
        var disabledInput = this.props.disabledInput;
        var isReadOnly = this.props.isReadOnly;
        var style = this.props.style || {};
        if (hasEmailPicker) {
            fieldDivClass += ' customerEmailPicker_parent';
            var emailPickerToggle = function () {
                this.setState({emailPickerShowing: !this.state.emailPickerShowing});
            }.bind(this);
            emailPicker = <div key="button" className="customerEmailPicker_btn toolbutton dropdown" onClick={emailPickerToggle}></div>
            if (this.state.emailPickerShowing) {
                emailPicker = [emailPicker];
                emailPicker.push(<DeliverPageEmailPicker key="picker" value={value} toggle={emailPickerToggle} setValue={this.setValue}></DeliverPageEmailPicker>);
            }
        }
        return <div>
            <div className="standardformfieldlabel"><label htmlFor={id} className="standardformlabel">{label}</label></div>
            <div className={fieldDivClass} style={style} >
                <input name={id} id={id} title={htmlTitle} value={value} onChange={this.onChange} disabled={disabledInput} readOnly = {isReadOnly}  />
                {emailPicker}
            </div>
        </div>;
    }
}
class DeliverPageEmailPicker extends React.Component {
    constructor(props) {
            super(props);
            this.state = {
            };       
            this.toggle = this.toggle.bind(this); 
        }
    toggle() {
        this.props.toggle();
    }
    componentDidMount() {
        window.setTimeout(function () {
            $(window).on('click', this.toggle );
        }.bind(this), 0);
    }
    componentWillUnmount () {
        $(window).off('click', this.toggle);
    }
    render() {
        var quote = app.currentQuote;
        var emails = (this.props.value || '').split(/[,;]/);
        var emailSet = {};
        this.emailsCleaned = [];
        this.customerIndex = {};
        var i, j, usageType;
        for (i = 0; i < emails.length; i++) {
            var email = emails[i].trim();
            if (email && !emailSet[email]) {
                emailSet[email] = true;
                this.emailsCleaned.push(email);
                for (j = 0; j < DeliverPageEmailPicker.usageTypes.length; j++) {
                    usageType = DeliverPageEmailPicker.usageTypes[j];
                    if (app.currentQuote.CustomersByType[usageType].EmailAddress == email) {
                        this.customerIndex[usageType] = this.emailsCleaned.length - 1;
                    }
                }
            }
        }

        var listItems = [];
        for (i = 0; i < DeliverPageEmailPicker.usageTypes.length; i++) {
            usageType = DeliverPageEmailPicker.usageTypes[i];
            var customerIndex = this.customerIndex[usageType];
            var hasCustomer = customerIndex !== undefined;
            var onClick = hasCustomer ?
                function (customerIndex, e) {
                    e.stopPropagation();
                    this.emailsCleaned.splice(customerIndex, 1);
                    this.props.setValue(this.emailsCleaned.join(', '));
                }.bind(this, customerIndex) :
                function (usageType, e) {
                    e.stopPropagation();
                    this.emailsCleaned.push(app.currentQuote.CustomersByType[usageType].EmailAddress)
                    this.props.setValue(this.emailsCleaned.join(', '));
                }.bind(this, usageType);
            var liClass = usageType.toLowerCase() + (hasCustomer ? ' selected' : '');
            listItems.push(<li key={usageType} className={liClass} onClick={onClick}>
                <img className={'' + hasCustomer} src={'skins/quosal/images/' + (hasCustomer ? 'True' : 'False') + '.png'}/>
                <span>{usageType.replace('To', ' To')}: {app.currentQuote.CustomersByType[usageType].EmailAddress}</span>
            </li>);
        }

        return (
            <div className="customerEmailPicker_list">
                <ul>
                    {listItems}
                </ul>
            </div>
        );
    }
}

DeliverPageEmailPicker.usageTypes= ['QuoteTo','BillTo','SendTo','ShipTo'];