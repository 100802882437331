quosal.api.user = {};

quosal.api.user.validateUserEmails = function () {
    return quosal.api.call('User.ValidateUserEmails');
}

quosal.api.user.getDashboardData = function (layoutName) {
    return quosal.api.call('User.GetDashboardData', {layoutName: layoutName, isAsyncAction: true});
}

quosal.api.user.getSuccessListWidgetData = function () {
    return quosal.api.call('User.SuccessListWidget');
}

quosal.api.user.saveWidgetLayout = function (layouts, name) {
    return quosal.api.call('User.SaveWidgetLayout', {layouts:layouts, name:name} );
}

quosal.api.user.deleteWidgetLayout = function (name) {
    return quosal.api.call('User.DeleteWidgetLayout', {layoutName: name} );
}
quosal.api.user.setDefaultLayout = function (name) {
    return quosal.api.call('User.SetDefaultLayout', {layoutName: name} );
}

quosal.api.user.revertToDefaultLayout = function (name) {
    return quosal.api.call('User.RevertToDefaultLayout');
}

quosal.api.user.saveImage = function (imageData) {
    return quosal.api.call('User.SaveSignature', {imageData: imageData} );
}

quosal.api.user.impersonateUser = function (userName, deviceId, removeOldSessions) {
    return quosal.api.call('User.ImpersonateUser', {userName: userName, deviceId: deviceId, removeOldSessions: removeOldSessions});
}

quosal.api.user.checkAllowImpersonateUser = function (userName, deviceId) {
    return quosal.api.call('User.CheckAllowImpersonateUser', {userName: userName, deviceId: deviceId});
}

quosal.api.user.registerClientOnSTS = function (registrationToken)
{
    return quosal.api.call('CWOAuthRegistration.DynamicClientRegistration', { registrationToken: registrationToken });
}

quosal.api.user.submitUsers = function (registrationToken)
{
    return quosal.api.call('CWOAuthRegistration.SubmitUsers', { registrationToken: registrationToken});
}
