import {OpportunitySearchbox} from "js/jsx/src/classes/crm/opportunitySearchbox.jsx";
import {OpportunitySearchTable} from "js/jsx/src/classes/crm/opportunitySearchTable.jsx";

export class DynamicsOpportunitySearch extends React.Component {
    constructor(props) {
        super(props);

        this.headerNames = [
            {name:"", propName:"", width:50, url: true}, 
            {name:"Name", propName:"Name", width:140}, 
            {name:"Account", propName:"AccountName", width:100},
            {name:"Description", propName:"Description", width:100}, 
            {name:"Status Reason", propName:"Status", width:80},
            {name:"Amount", propName: "Amount", width:100}, 
        ]

        this.state = {
            criteria: this.getSavedSearchCriteria(), 
            isSearching: false,
        };

        // This binding is necessary to make `this` work in the callback
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.submitClick = this.submitClick.bind(this);
        this.formatReturnedOpportunities = this.formatReturnedOpportunities.bind(this);
        this.getStatusReasons = this.getStatusReasons.bind(this);
        this.getSearchCriteria = this.getSearchCriteria.bind(this);
        this.saveSearchCriteria = this.saveSearchCriteria.bind(this);
        this.resetSearchCriteria = this.resetSearchCriteria.bind(this);
        this.getSavedSearchCriteria = this.getSavedSearchCriteria.bind(this);
    }
    componentDidMount(){
        document.addEventListener("keypress",  this.handleKeyPress)
    }
    componentWillUnmount(){
        document.removeEventListener("keypress",  this.handleKeyPress)
    }
    handleKeyPress(e){
        if(e.keyCode === 13)
        {
           this.submitClick();
        }
    }
    inputChanged(fieldName){
        var value = $("#"+fieldName).val();
        this.state.criteria[fieldName] = value;
        this.forceUpdate();
    }
    submitClick(){
        this.saveSearchCriteria();
        var dynamicsGetOpportunites = quosal.api.crm.dynamics.getOpportunities(this.state.criteria, 0, 100)
        var me = this;
        var onError = function(msg) {
            Dialog.open({
                message: msg.error,
                links: [{
                    title: 'OK',
                    callback: Dialog.close
                }]
            });
            me.setState({isSearching: false})
        
        };
        dynamicsGetOpportunites.error = onError;
        dynamicsGetOpportunites.finished = function (msg) {
            var opportunities = this.formatReturnedOpportunities(msg.data.opportunities);
            this.setState({opportunities:opportunities, isSearching: false});
        }.bind(this);
        dynamicsGetOpportunites.call();
        this.setState({isSearching: true})
    
    }  
    formatReturnedOpportunities(opportunities){
        var me = this;
        if(opportunities){
            return opportunities.map(function(opportunity){
                var parsedOpp = JSON.parse(opportunity);
                for (var key in me.statuses) {
                    if(me.statuses[key] == parsedOpp.StatusCode){
                        parsedOpp.Status = key;
                        break;
                    }
                }
                return JSON.stringify(parsedOpp)
            })
        }
    }
    getStatusReasons(){
        var optionsList = [<option value="all" key={"oppstatus_all"}>ALL</option>, <option value={"100"} key={"oppstatus_AllOpen"}>All Open</option>]
        this.statuses = JSON.parse(this.props.data.statuses);
        for (var key in this.statuses) {
            optionsList.push( <option value={this.statuses[key]} key={"oppstatus_" + key}>{key}</option>)
           
        }
        return optionsList;
    }
    getSearchCriteria(){
        return [
            {fieldName: "opportunityname", displayName:"Opportunity Name", fieldType:"input", value: this.state.criteria["opportunityname"]},
            {fieldName: "status", displayName:"Status Reason", fieldType:"select", value: this.state.criteria["status"], options: this.getStatusReasons()},
            {fieldName: "accountname", displayName:"Account Name", fieldType:"input", value: this.state.criteria["accountname"]},
            {fieldName: "owner", displayName:"Owner", fieldType:"input", value: this.state.criteria["owner"]},
        ]
    }
    saveSearchCriteria(){
       $.cookie('c_dynamicsopportunitysearch_oppname', this.state.criteria.opportunityname, { expires: 14, path: "/" });
       $.cookie('c_dynamicsopportunitysearch_status', this.state.criteria.status, { expires: 14, path: "/" });
       $.cookie('c_dynamicsopportunitysearch_accountname', this.state.criteria.accountname, { expires: 14, path: "/" });
       $.cookie('c_dynamicsopportunitysearch_owner', this.state.criteria.owner, { expires: 14, path: "/" });
    }
    resetCookies(){
        $.cookie('c_dynamicsopportunitysearch_oppname', "", { expires: 14, path: "/" });
        $.cookie('c_dynamicsopportunitysearch_status', "", { expires: 14, path: "/" });
        $.cookie('c_dynamicsopportunitysearch_accountname',"", { expires: 14, path: "/" });
        $.cookie('c_dynamicsopportunitysearch_owner', "", { expires: 14, path: "/" });
    }
    resetSearchCriteria(){
        this.resetCookies();
        this.setState({criteria: this.getSavedSearchCriteria(), opportunities: null})
    }
    getSavedSearchCriteria(){
       var cookie = {}
        try{
            cookie.opportunityname = quosal.util.cookie('c_dynamicsopportunitysearch_oppname');
            cookie.status = quosal.util.cookie('c_dynamicsopportunitysearch_status');
            cookie.accountname = quosal.util.cookie('c_dynamicsopportunitysearch_accountname');
            cookie.owner = quosal.util.cookie('c_dynamicsopportunitysearch_owner');
        }
        catch(e){
            this.resetCookies();
            cookie.opportunityname = "";
            cookie.status = "";
            cookie.accountname = "";
            cookie.owner = "";
        }
      

       return cookie;
    }
    render(){
        var spinnerArgs = {
            lines: 12,
            length: 12,
            width: 6,
            radius: 20
        };
        
        return(
            <div id="DynamicsOpportunitySearchId">
                <OpportunitySearchbox searchCriteria={this.getSearchCriteria()} resetClick={this.resetSearchCriteria} submitClick={this.submitClick} inputChanged={this.inputChanged} />
                <OpportunitySearchTable isSearching={this.state.isSearching} opportunities={this.state.opportunities} headerNames={this.headerNames} />
            </div>
        )
}
}