quosal.api.product = {};


//==========================================================//
//* CreatePackage                                          *//
//==========================================================//
//==========================================================//
quosal.api.product.createPackage = function (partNumber, description, quantity, idQuoteMain, idQuoteTabs, sortOrder, detailIds) {
    return quosal.api.call('Product.CreatePackage', {
        partNumber: partNumber,
        description: description,
        quantity: quantity,
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        sortOrder: sortOrder,
        detailIds: detailIds
    });
};


//==========================================================//
//* Unpackage                                              *//
//==========================================================//
//==========================================================//
quosal.api.product.unpackage = function (idQuoteMain, headerIds) {
    return quosal.api.call('Product.Unpackage', {
        idQuoteMain: idQuoteMain,
        headerIds: headerIds
    });
};


//==========================================================//
//* AddToPackage                                           *//
//==========================================================//
//==========================================================//
quosal.api.product.addToPackage = function (idQuoteMain, headerId, detailIds) {
    return quosal.api.call('Product.AddToPackage', {
        idQuoteMain: idQuoteMain,
        headerId: headerId,
        detailIds: detailIds
    });
};


//==========================================================//
//* SavePackage                                           *//
//==========================================================//
//==========================================================//
quosal.api.product.savePackage = function (idQuoteMain, headerId) {
    return quosal.api.call('Product.SavePackage', {
        idQuoteMain: idQuoteMain,
        headerId: headerId
    });
};

//==========================================================//
//* OverridePackage                                           *//
//==========================================================//
//==========================================================//
quosal.api.product.overridePackage = function (idQuoteMain, headerId, overridePackageId) {
    return quosal.api.call('Product.SavePackage', {
        idQuoteMain: idQuoteMain,
        headerId: headerId,
        overridePackageId: overridePackageId
    });
};


//==========================================================//
//* RemoveFromPackage                                      *//
//==========================================================//
//==========================================================//
quosal.api.product.removeFromPackage = function (idQuoteMain, detailIds) {
    return quosal.api.call('Product.RemoveFromPackage', {
        idQuoteMain: idQuoteMain,
        detailIds: detailIds
    });
};


//==========================================================//
//* SetPackagePrice                                        *//
//==========================================================//
//==========================================================//
quosal.api.product.setPackagePrice = function (idQuoteMain, headerId, amount, isRecurring) {
    return quosal.api.call('Product.SetPackagePrice', {
        idQuoteMain: idQuoteMain,
        headerId: headerId,
        amount: amount,
        isRecurring: isRecurring
    });
};


//==========================================================//
//* Search                                                 *//
//==========================================================//
//==========================================================//
quosal.api.product.search = function (criteria, sources, idQuoteMain, idQuoteTabs, doAutoSort, sortByField, sortDescending, isFindAllLikeThisSearch, findAllLikeThisKeyValue) {
    return quosal.api.call('Product.Search', {
        criteria: criteria,
        sources: sources,
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        doAutoSort: doAutoSort,
        sortByField: sortByField,
        sortDescending: sortDescending,
        isFindAllLikeThisSearch: isFindAllLikeThisSearch,
        findAllLikeThisKeyValue: findAllLikeThisKeyValue
    });
};


//==========================================================//
//* Etilize Search                                         *//
//==========================================================//
//==========================================================//
quosal.api.product.etilizeconfig = function () {
    return quosal.api.call('Product.EtilizeConfiguration', {});
};

quosal.api.product.etilizesearch = function ( idQuoteMain, idQuoteTabs, thekeys, thepagenum, maxresults, thefilters, showsome, 
                                                                    manufacts, thecategories, thefeatures, distributors, catalogs) {
    return quosal.api.call('Product.EtilizeSearch', {
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        keywords: quosal.util.b64EncodeUnicode(thekeys),
        pagenum: thepagenum,
        maxresults: maxresults,
        filters: quosal.util.b64EncodeUnicode(thefilters),
        showsome: showsome,
        manufacturers: manufacts,
        categories: thecategories,
        features: thefeatures,
        distributors: distributors,
        catalogs: catalogs,
    });
};

quosal.api.product.etilizesearchwithpricing = function (idQuoteMain, idQuoteTabs, thekeys, thepagenum, maxresults, thefilters, 
    manufacts, thecategories, thefeatures, distributors) {
    return quosal.api.call('Product.EtilizeSearchWithPricing', {
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        keywords: quosal.util.b64EncodeUnicode(thekeys),
        pagenum: thepagenum,
        maxresults: maxresults,
        filters: quosal.util.b64EncodeUnicode(thefilters),
        manufacturers: manufacts,
        categories: thecategories,
        features: thefeatures,
        distributors: distributors,
    });
};

quosal.api.product.etilizedetails = function (idQuoteMain, productId) {
    return quosal.api.call('Product.EtilizeDetails', {
        idQuoteMain: idQuoteMain,
        productid: productId
    });
};

quosal.api.product.etilizecompares = function (idQuoteMain, productIds) {
    return quosal.api.call('Product.EtilizeCompareLoad', {
        idQuoteMain: idQuoteMain,
        productids: productIds
    });
};
quosal.api.product.attachComparison = function (idQuoteMain, productIds, selectedAttributes) {
    return quosal.api.call('Product.AttachComparison', {
        idQuoteMain: idQuoteMain,
        productids: productIds,
        selected: selectedAttributes
    });
};
quosal.api.product.removeAllComparisons = function (idQuoteMain) {
    return quosal.api.call('Product.DeleteAllComparisons', {
        idQuoteMain: idQuoteMain
    });
};


//==========================================================//
//* Attach                                                 *//
//==========================================================//
//==========================================================//
quosal.api.product.attach = function (itemId, source, vendorName, idQuoteMain, idQuoteTabs, priceLevel) {
    return quosal.api.call('Product.Attach', {
        itemId: itemId,
        source: source,
        vendorName: vendorName,
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        priceLevel: priceLevel
    });
};

//==========================================================//
//* AttachWithEtilizeContent                               *//
//==========================================================//
//==========================================================//
quosal.api.product.attachWithEtilizeContent = function (itemId, source, vendorName, isSpecialOrder, idQuoteMain, idQuoteTabs, priceLevel, insertBefore) {
    return quosal.api.call('Product.AttachWithEtilizeContent', {
        itemId: itemId,
        source: source,
        vendorName: vendorName,
        isSpecialOrder: isSpecialOrder,
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        priceLevel: priceLevel,
        insertBefore: insertBefore
    });
};

quosal.api.product.attachEtilizeProducts = function (idQuoteMain, products, hasMain) {
    return quosal.api.call('Product.AttachEtilizeProducts', {
        idQuoteMain: idQuoteMain,
        products: products,
        attachMainProduct: hasMain ? 'true': 'false'
    });
};

//==========================================================//
//* AttachNonEtilizeProducts                              *//
//==========================================================//
//==========================================================//
quosal.api.product.attachNonRtpProducts = function (idQuoteMain, products) {
    return quosal.api.call('Product.AttachNonRtpProducts', {
        idQuoteMain: idQuoteMain,
        products: products
    });
};

//==========================================================//
//* AttachSalesTrackMappedProducts                         *//
//==========================================================//
//==========================================================//
quosal.api.product.attachSalesTrackMappedProducts = function (attachProductParams) {
    return quosal.api.call('Product.AttachSalesTrackMappedProducts', {
        idQuoteItems: attachProductParams.idQuoteItems,
        idQuoteMain: attachProductParams.idQuoteMain,
        idQuoteTabs: attachProductParams.idQuoteTabs
    });
};

//==========================================================//
//* UpdateSource                                           *//
//==========================================================//
//==========================================================//
quosal.api.product.updateSource = function (idQuoteMain, idQuoteItems, mpn, vpn, source, cost, msrp, warehouse, sourceSelectionDetails, pricebook) {
    return quosal.api.call('Product.UpdateSource', {
        idQuoteMain: idQuoteMain,
        idQuoteItems: idQuoteItems,
        mpn: mpn,
        vpn: vpn,
        source: source,
        cost: cost,
        msrp: msrp,
        warehouse: warehouse,
        sourceSelectionDetails: sourceSelectionDetails,
        pricebook: pricebook
    });
};


//==========================================================//
//* UpdateFromEtilize                                      *//
//==========================================================//
//==========================================================//
quosal.api.product.updateFromEtilize = function (idQuoteMain, idQuoteItems, mfp, vendorPartNumber) {
    return quosal.api.call('Product.UpdateFromEtilize', {
        idQuoteMain: idQuoteMain,
        idQuoteItems: idQuoteItems,
        mfp: mfp,
        vendorPartNumber: vendorPartNumber
    });
};


//==========================================================//
//* ProductPriceHistory                                    *//
//==========================================================//
//==========================================================//
quosal.api.product.priceHistory = function (idQuoteMain, mpn, count, pageSize, currentAccountOnly, wonQuotesOnly) {
    return quosal.api.call('Product.ProductPriceHistory', {
        idQuoteMain: idQuoteMain,
        mpn: mpn,
        count: count,
        pageSize: pageSize,
        currentAccountOnly: currentAccountOnly,
        wonQuotesOnly: wonQuotesOnly
    });
};


//==========================================================//
//* CalculateMargins                                       *//
//==========================================================//
//==========================================================//
quosal.api.product.calculateMargins = function (idQuoteMain, productIds) {
    return quosal.api.call('Product.CalculateMargins', {
        idQuoteMain: idQuoteMain,
        productIds: productIds
    });
};


//==========================================================//
//* QuickItemEntry                                         *//
//==========================================================//
//==========================================================//
quosal.api.product.quickItemEntry = function (manufacturerPartNumber, idQuoteMain, idQuoteTabs, sourceGroupId, insertBefore, noItemOrQuoteDataReturned) {
    return quosal.api.call('Product.QuickItemEntry', {
        manufacturerPartNumber: manufacturerPartNumber,
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        sourceGroupId: sourceGroupId,
        insertBefore: insertBefore,
        noData: noItemOrQuoteDataReturned
    });
};

//==========================================================//
//* CheckProduct                                         *//
//==========================================================//
//==========================================================//
quosal.api.product.checkProduct = function (manufacturerPartNumber, idQuoteMain, idQuoteTabs, sourceGroupId, insertBefore, noItemOrQuoteDataReturned) {
    return quosal.api.call('Product.CheckProduct', {
        manufacturerPartNumber: manufacturerPartNumber,
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        sourceGroupId: sourceGroupId,
        insertBefore: insertBefore,
        noData: noItemOrQuoteDataReturned
    });
};


//==========================================================//
//* Paste                                                  *//
//==========================================================//
//==========================================================//
quosal.api.product.paste = function (idQuoteMain, destinationTab, itemIds, insertBeforeThisItem) {
    return quosal.api.call('Product.Paste', {
        idQuoteMain: idQuoteMain,
        destinationTab: destinationTab,
        itemIds: itemIds,
        insertBeforeThisItem: insertBeforeThisItem
    });
};



//==========================================================//
//* Save Product Search Prefill Mapping Set                *//
//==========================================================//
//==========================================================//
quosal.api.product.saveProductSearchPrefillMappingSet = function (
    idProductSearchPrefillMappingSet, rename, mappings, doAutoSearch,
    doAutoSort, sortDescending, sortByField,
    idQuoteMain, isMappingSetOnQuote, idQuoteTabs, isMappingSetOnTab
) {
    return quosal.api.call('Product.SaveProductSearchPrefillMappingSet', {
        idProductSearchPrefillMappingSet: idProductSearchPrefillMappingSet,
        rename: rename,
        mappings: mappings,
        doAutoSearch: doAutoSearch,
        doAutoSort: doAutoSort,
        sortDescending: sortDescending,
        sortByField: sortByField,
        idQuoteMain: idQuoteMain,
        isMappingSetOnQuote: isMappingSetOnQuote,
        idQuoteTabs: idQuoteTabs,
        isMappingSetOnTab: isMappingSetOnTab,
    });
};


//==========================================================//
//* Delete Product Search Prefill Mapping Set              *//
//==========================================================//
//==========================================================//
quosal.api.product.deleteProductSearchPrefillMappingSet = function (idProductSearchPrefillMappingSet) {
    return quosal.api.call('Product.DeleteProductSearchPrefillMappingSet', {
        idProductSearchPrefillMappingSet: idProductSearchPrefillMappingSet
    });
};

//==========================================================//
//*Cisco*//
//==========================================================//
//==========================================================//

quosal.api.product.getCiscoProductInformation = function (ccwCatalogLookup) {
    return quosal.api.call('Cisco.CCWCatalog', {
        mfp: ccwCatalogLookup.mfp,
        idQuoteMain: ccwCatalogLookup.idQuoteMain,
        idQuoteItem: ccwCatalogLookup.idQuoteItem
    });
};


//==========================================================//
//* Invoice Grouping                                       *//
//==========================================================//
//==========================================================//
quosal.api.product.createInvoiceGroup = function (invoiceGroupName, invoiceGroupRecId, invoiceGroupCustomerDescription, idQuoteMain, idQuoteTabs, detailIds, sortOrder) {
    return quosal.api.call('Product.InvoiceGroup.CreateInvoiceGroup', {
        invoiceGroupName: invoiceGroupName,
        invoiceGroupRecId: invoiceGroupRecId,
        invoiceGroupCustomerDescription: invoiceGroupCustomerDescription,
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        detailIds: detailIds,
        sortOrder: sortOrder
    });
};
quosal.api.product.addToInvoiceGroup = function (idQuoteMain, headerId, detailIds) {
    return quosal.api.call('Product.InvoiceGroup.AddToInvoiceGroup', {
        idQuoteMain: idQuoteMain,
        headerId: headerId,
        detailIds: detailIds
    });
};
quosal.api.product.ungroupInvoiceGroup = function (idQuoteMain, headerIds) {
    return quosal.api.call('Product.InvoiceGroup.UngroupInvoiceGroup', {
        idQuoteMain: idQuoteMain,
        headerIds: headerIds
    });
};
quosal.api.product.removeFromInvoiceGroup = function (idQuoteMain, detailIds) {
    return quosal.api.call('Product.InvoiceGroup.RemoveFromInvoiceGroup', {
        idQuoteMain: idQuoteMain,
        detailIds: detailIds
    });
};

quosal.api.product.insertSectionGrouping = function (idQuoteMain, idQuoteTabs, insertPositions, newItemIds, lineType) {
    return quosal.api.call('Product.InsertSectionGrouping', {
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        insertPositions: insertPositions,
        newItemIds: newItemIds,
        lineType: lineType
    });
};