const payloadGenerator = (idDetails, tableName, values) => {
	return {
		updates: [
			{
				fields: {
					/// In key value form
					// { Name : value }
					...values
				},
				queries: [
					{
						table: tableName,
						where: [
							{
								field: idDetails.name,
								operator: "Equals",
								value: idDetails.value
							}
						]
					}
				]
			}
		]
	};
};

const queryPayloadGenerator = (tableName, where) => {
	return {
		queries: [
			{
				table: tableName,
				where
			}
		]
	};
};

export { payloadGenerator, queryPayloadGenerator };
