import { useState } from "react";

// eslint-disable-next-line import/prefer-default-export
export function useNavigationService() {
	const [currentPage, setCurrentPage] = useState({
		SEARCH_RESULT_PAGE: true,
		PRODUCT_CART_PAGE: false,
		PRODUCT_DETAILS_PAGE: false
	});

	const initGridState = {
		pagination: { paginationModel: { pageSize: 50, page: 0 } }
	};

	const [pathInfo, setPathInfo] = useState({ navigatedFrom: "", navigateTO: "" });

	const [productDetailsTab, setProductDetailsTab] = useState("1");

	const [gridState, saveGridState] = useState({
		productsSearchGrid: initGridState,
		upSellCrossGrid: initGridState
	});

	const navigateToPage = (navigateTo) => {
		Object.entries(currentPage).map(([key]) => {
			if (currentPage[key] === true) setPathInfo({ navigatedFrom: key, navigateTo });
			currentPage[key] = false;
		});

		setCurrentPage({ ...currentPage, ...{ [navigateTo]: true } });
	};

	const setGridState = (productsType, newInitialState) => {
		saveGridState((prev) => ({
			...prev,
			[productsType]: newInitialState
		}));
	};

	const setInitialGridState = (productsType) => {
		saveGridState((prev) => ({
			...prev,
			[productsType]: initGridState
		}));
	};

	return {
		currentPage,
		navigateToPage,
		productDetailsTab,
		setProductDetailsTab,
		gridState,
		setGridState,
		setInitialGridState,
		pathInfo
	};
}
