import React from "react";
import { useQuoteContext } from "./context";
import { QuoteHeaderNavigation } from "./QuoteHeaderNavigation";
import { QuoteStepper } from "../quoteStepper";

export const QuoteNavigationTabs = () => {
	const quote = useQuoteContext();
	const enabledNewStepper =
		app.settings.global.EnableHeaderAccess && app.settings.global.EnableStepper;
	const eoFlowAllowedCrms = ["Connectwise"];	
	const isEoPage = !!$("#isEoPage").length;
	const getQuoteModules = () => {
		const quoteModules = [];
		const quoteNavigationList = isEoPage ? quote.EoNavigation : quote.Navigation;

		for (let i = 0; i < quoteNavigationList.length; i++) {
			const nav = quosal.sell.modules.allModules.firstOrNull(
				(s) => s.Url == quoteNavigationList[i].Url
			);
			if (nav) {
				let navToUse = nav;

				if (
					nav.Module == "quote.publish" &&
					app.settings.global.ApprovalMethod == "Multilevel"
				) {
					if (quote.ApprovalStatus == "Approved" && nav.IsLocked) {
						nav.IsLocked = false;
					} else if (!nav.IsLocked && !app.currentUser.IsAdministrator) {
						if (
							(app.quoteHasTriggeredApprovalRules &&
								quote.ApprovalStatus != "Approved") ||
							quote.ApprovalStatus == "Request Approval"
						) {
							nav.IsLocked = true;
						} else if (quosal && quosal.publish && quosal.publish.publishCheat) {
							nav.IsLocked = true;
						}
					}
				}

				if (nav.Url.indexOf(".quosalweb") > 0) {
					navToUse = quosal.util.clone(nav);
					const url = nav.Url;
					const urlParts = url.split(/[?&]/);
					const newUrl = quosal.util.url(...urlParts);
					navToUse.Url = newUrl;
				}
				quoteModules.push(navToUse);
			}
		}

		return quoteModules;
	};
	const quoteModules = getQuoteModules();

	if (isEoPage && enabledNewStepper && eoFlowAllowedCrms.includes(app.settings.user.customerProvider)) {
		return <QuoteStepper steps={quoteModules} isEoNav={isEoPage} quote={quote}/>;
	}

	return !isEoPage && enabledNewStepper ? (
		<QuoteStepper steps={quoteModules} isEoNav={isEoPage} quote={quote}/>
	) : (
		<QuoteHeaderNavigation steps={quoteModules} isEoNav={isEoPage} quote={quote} />
	);
};
