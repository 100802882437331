import * as React from "react";
import BaseContextMenu from "./baseContextMenu.jsx";

function GreatAmericaContextMenu({ quosal, isEditable, contentGrid, gridToolbarStyle }) {
	const greatAmericaActions = [];

	const tabLink = (href) => {
		let selectedTab;
		app.currentQuote.Tabs.map((tab) => {
			if (tab.IdQuoteTabs == contentGrid.state.cKEditorSelectedTabId) {
				selectedTab = tab;
			}
		});

		const url = quosal.util.url(href, `idquotetabs=${selectedTab.IdQuoteTabs}`);
		quosal.navigation.navigate(url);
	};

	const submitGreatAmericaCreditApplication = () => {
		const me = this;
		const submitCreditApplication = function () {
			const submitGreatAmericaCreditApplicationApi =
				quosal.api.quote.submitGreatAmericaCreditApplication(me.props.quote.IdQuoteMain);
			submitGreatAmericaCreditApplicationApi.finished = function (msg) {
				if (msg.error) {
					Dialog.setIsWorking(false);
					Dialog.open({
						title: "Error Submitting Credit Application",
						message: msg.error,
						links: [{ title: "OK", callback: Dialog.closeAll }]
					});
				} else {
					quosal.sell.quote.updateFromApiResponse(msg);
					Dialog.setIsWorking(false);
					Dialog.close();
				}
			};
			submitGreatAmericaCreditApplicationApi.call();
		};

		Dialog.open({
			title: "Submitting Credit Application to GreatAmerica",
			message: "Are you sure you would like to submit a credit application?",
			closeRequiresButton: true,
			titleId: "submitGreatAmericaCreditApplicationId",
			links: [
				{
					title: "Yes",
					callback() {
						Dialog.setIsWorking(true);
						submitCreditApplication();
					}
				},
				{
					title: "No",
					callback() {
						Dialog.close();
					}
				}
			]
		});
	};

	const initActions = () => {
		const useGreatAmericaRest = quosal.settings.getValue("useGreatAmericaRestIntegration");

		if (quosal.settings.getValue("useGreatAmerica") && isEditable) {
			const gaDefaultId =
				contentGrid.props.quote.GreatAmericaDefault ||
				quosal.settings.getValue("greatAmericaDefaultId");

			if (!gaDefaultId ||
				(quosal.settings.getValue("greatAmericaShowTwoButtons") && isEditable)) {
				greatAmericaActions.push({
					id: 1,
					name: "Import GreatAmerica",
					callback: tabLink.bind(this, "importgreatamerica.quosalweb")
				});
			}

			if (gaDefaultId && isEditable) {
				greatAmericaActions.push({
					id: 2,
					name: "Quick Import GreatAmerica Using Defaults",
					callback: tabLink.bind(
						this,
						`quickgreatamerica.quosalweb?idGreatAmericaDefaultSet=${gaDefaultId}`
					)
				});
			}

			const creditApplicationUsers = quosal.settings.getValue(
				"greatAmericaCreditApplicationUsers"
			);
			if (
				useGreatAmericaRest &&
				quosal.settings.getValue("GreatAmericaLeasingCanSubmitCreditApplication") &&
				creditApplicationUsers.includes(app.currentUser.IdUsers)
			) {
				greatAmericaActions.push({
					id: 3,
					name: "Submit Credit Application",
					callback: submitGreatAmericaCreditApplication
				});
			}
		}
	};

	initActions();

	return (
		<>
			{greatAmericaActions && greatAmericaActions.length ? (
				<span style={gridToolbarStyle}>
					<BaseContextMenu
						title="Great America"
						quosal={quosal}
						actions={greatAmericaActions}
					/>
				</span>
			) : null}
		</>
	);
}

export default GreatAmericaContextMenu;
