import { AutotaskOpportunitySearch } from "js/jsx/src/classes/crm/autotask/autotaskOpportunitySearch.jsx";
import { CWOpportunitySearch } from "js/jsx/src/classes/crm/connectwise/cwOpportunitySearch.jsx";
import { DynamicsOpportunitySearch } from "js/jsx/src/classes/crm/dynamics/dynamicsOpportunitySearch.jsx";
import { NetSuiteOpportunitySearch } from "js/jsx/src/classes/crm/netsuite/netsuiteOpportunitySearch.jsx";
import { SalesForceOpportunitySearch } from "js/jsx/src/classes/crm/salesforce/sfOpportunitySearch.jsx";
import { CustomerSetupToolbar } from "js/jsx/src/classes/quote/quoteCustomerSetupToolbar.jsx";

export default function attachopportunity() {
    var renderEditForm = function (componentToRender) {
        quosal.ui.react.update(<div><CustomerSetupToolbar onUpdate={renderEditForm} submoduleName="quote.attachopportunity" />
            {componentToRender}
        </div>,
            document.getElementById('quoteModule'));
    }
    $('#pageName').val('quote.attachopportunity');

    var doRender = function (componentToRender) {

        quosal.ui.react.render(
            <div><CustomerSetupToolbar onUpdate={renderEditForm.bind(this, componentToRender)} submoduleName="quote.attachopportunity" />
                {componentToRender}
            </div>,
            document.getElementById('quoteModule'));
    }

    var doApiSearch = function (getMetadataApi, componentToRender) {
        quosal.ui.react.render(React.createElement(FormPlaceholder, { message: 'Loading Quote Content...' }), document.getElementById('quoteModule'));
        getMetadataApi.finished = function (msg) {
            componentToRender = React.cloneElement(
                componentToRender,
                { data: msg.data }
            );
            doRender(componentToRender);
        }.bind(this);
        getMetadataApi.call();
    }
    var crmName = quosal.util.getCrmShortName();
    //get needed data to display page if needed (such as dropdown items)
    var comp = null;
    var api = null;
    if (crmName == "cw") {
        comp = <CWOpportunitySearch />
        api = quosal.api.crm.connectwise.getOpportunityMetadata();
    }
    else if (crmName == "dynamics") {
        comp = <DynamicsOpportunitySearch />
        api = quosal.api.crm.dynamics.getOpportunityMetadata();
    }
    else if (crmName == "autotask") {
        comp = <AutotaskOpportunitySearch />;
        api = quosal.api.crm.autotask.getOpportunityMetadata();
    }
    else if (crmName == "netsuite") {
        comp = <NetSuiteOpportunitySearch />;
        api = quosal.api.crm.netsuite.getOpportunityMetadata();
    }
    else if (crmName == "sf") {
        doRender(<SalesForceOpportunitySearch />);
        return;
    }
    if (api) {
        doApiSearch(api, comp);
    } else {
        app.currentModule.unloadSubModules();
        app.currentModule.loadSubModule("quote.customer", {
            container: 'quoteModule',
            unloadSubModules: true
        });
    }
};