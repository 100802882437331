import { QuoteContentGrid } from "js/jsx/src/classes/quote/contentGrid.jsx";

export default function content() {
    if (!app.currentQuote) {
        return;
    }

    var userInfo = quosal.util.getUserEmailAndRole();
    var start = new Date().getTime();

    var quoteContentModule = quosal.sell.modules.find('quote.content');
    if (quoteContentModule.IsLocked == true) {
        quosal.navigation.moduleFallbackRedirect();
        return;
    }

    quosal.ui.react.render(
        <FormPlaceholder message="Loading Quote Content..." />
        , document.getElementById('contentPanelContainer'));

    var contentGrid = null;
    setTimeout(function () {
        quosal.ui.views.parse(document.getElementById('contentPanelContainer'));
        contentGrid = quosal.ui.react.render(
            <QuoteContentGrid quote={app.currentQuote} module={quoteContentModule} />
            , document.getElementById('contentPanelContainer'));
    }, 0);
    
    $('#pageName').val('quote.content');

    if (window.appInsights) {
        var elapsed = new Date().getTime() - start;
        window.appInsights.trackPageView("Load - Prepare Content",
            window.location.href,
            { EmailAddress: userInfo.email, UserRole: userInfo.role },
            null,
            elapsed);
    }

    var updateContentGrid = function () {
        contentGrid = quosal.ui.react.update(
            <QuoteContentGrid quote={app.currentQuote} module={quoteContentModule} />
            , document.getElementById('contentPanelContainer'));
    };

    var unbindEvents = function () {
        //If possible, scroll to the top of the window on navigation
        try {
            window.scroll(0,0);
        } catch (e) {}

        quosal.sell.quote.onUpdate.unbind(updateContentGrid);
        quosal.navigation.onNavigate.unbind(unbindEvents);
    };

    quosal.navigation.onNavigate.bind(unbindEvents);
    if (!quosal.util.isNewEditorEnabled()) {
        quosal.sell.quote.onUpdate.bind(updateContentGrid);
    }

    quosal.sell.scripts.load('OnDemand', ()=>{});

    var unloadModule = function () {
        quoteContentModule.onBeforeUnload.unbind(unloadModule);
        unbindEvents();
    };
    quoteContentModule.onBeforeUnload.bind(unloadModule);

    quosal.util.cookie('quotenav', 'content');
}