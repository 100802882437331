import React, { memo } from "react";

import { CircularProgress, Stack, Typography } from "@mui/material";
import "../../../../../../../css/CKeditor.css";
import useEditorWrapperContainer from "./editorWrapperContainer";
import useEditorWrapperRenderer from "./editorWrapperRenderer";

function EditorWrapper(props) {
	const { expanded, showInitialState, handleInitialState } = props;
	const { drop } = useEditorWrapperContainer(showInitialState, handleInitialState, expanded);
	
	const { initEditorWrapper, editorBody, editorStatus, isLoading } = useEditorWrapperRenderer(
		showInitialState,
		handleInitialState,
		expanded
	);

	return (
		isLoading ? <Stack spacing={2} justifyContent="center" direction="row" py={3}>
		<CircularProgress size={30} />
		<Typography textAlign="center">Loading...</Typography>
	</Stack> :
		<div className="editorCnt" ref={drop}>
			{initEditorWrapper}
			{editorBody}
			{editorStatus}
		</div>
	);
}

export default memo(EditorWrapper);
