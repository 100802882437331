if (!quosal.api.crm)
    quosal.api.crm = {};

quosal.api.crm.salesforce = {}

quosal.api.crm.salesforce.getOpportunities = function (criteria, additionalParameters) {
    return quosal.api.call('Crm.SalesForce.GetOpportunities', {
        criteria: criteria,
        additionalParameters: additionalParameters || {}
    });
};