const useStyle = () => {
	const mainBox = { width: "100%", position: "relative" };
	const gridMenu = { position: "relative", justifyContent: "flex-end" };
	const spinnerStyle = {
		position: "absolute",
		top: "0",
		left: "0",
		backgroundColor: "rgba(255, 255, 255, 0.8)",
		width: "100%",
		height: "100%",
		zIndex: 9,
		justifyContent: "center",
		alignItems: "center"
	};
	const gridMenuStyle = { textAlign: "right" };
	const dropdownStyle = { padding: 0, minWidth: 0 };
	const vertIconStyle = { marginTop: "-12px", fontSize: "1.7rem", cursor: "pointer" };

	return {
		mainBox,
		gridMenu,
		spinnerStyle,
		gridMenuStyle,
		dropdownStyle,
		vertIconStyle
	};
};

export default useStyle;
