import { SalesTracksPage } from "js/jsx/src/classes/product/salesTracks.jsx";

export default function salesTracksSearch() {
    var renderSearchForm = function (renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;
        renderMethod(
            <SalesTracksPage />,
            document.getElementById('salesTracksContainer')
        );
    };
    var loadSearchForm = function () {

        renderSearchForm(quosal.ui.react.render);

        $('#pageName').val('product.salestracks');
    };

    loadSearchForm();
}