$(function () {
    $.credentialChecks =
    {
        addFieldInput: function (form, name, displayName, value, otherParams) {
            if (!otherParams)
                otherParams = {};

            var label = $('<label>');
            label.prop('for', 'LoginCheck_' + name);
            label.addClass('standardformlabel');
            label.text(displayName);
            var formfieldlabel = $('<div>');
            formfieldlabel.addClass('formfieldlabel');
            formfieldlabel.append(label);

            var input = $('<input>');
            input.prop('id', 'LoginCheck_' + name);
            if (otherParams.password)
                input.prop('type', 'password');
            else
                input.prop('type', 'text');
            input.prop('name', name);
            input.val(value);
            var formfield = $('<div>');
            formfield.addClass('formfield');
            formfield.append(input);

            form.append(formfieldlabel).append(formfield);
        },

        addFieldSelect: function (form, name, displayName, options, value) {
            var label = $('<label>');
            label.prop('for', 'LoginCheck_' + name);
            label.addClass('standardformlabel');
            label.text(displayName);
            var formfieldlabel = $('<div>');
            formfieldlabel.addClass('formfieldlabel');
            formfieldlabel.append(label);

            var select = $('<select>');
            select.prop('id', 'LoginCheck_' + name);
            select.prop('name', name);
            select.addClass('formselectfield');

            select.append($('<option value=""></option>'));
            $(options).each(function () {
                if (this.name != undefined && this.value != undefined) {
                    select.append($('<option value="' + this.value + '">' + this.name + '</option>'));
                } else {
                    select.append($('<option>' + this + '</option>'));
                }
            });

            select.val(value);
            var formfield = $('<div>');
            formfield.addClass('formselectfieldwrapper');
            formfield.append(select);

            form.append(formfieldlabel).append(formfield);
        },

        addCrmFieldSelect: function (form, name, displayName, optionsDictionary, value) {
            var label = $('<label>');
            label.prop('for', 'LoginCheck_' + name);
            label.addClass('standardformlabel');
            label.text(displayName);
            var formfieldlabel = $('<div>');
            formfieldlabel.addClass('formfieldlabel');
            formfieldlabel.append(label);

            var select = $('<select>');
            select.prop('id', 'LoginCheck_' + name);
            select.prop('name', name);
            select.addClass('formselectfield');

            select.append($('<option value=""></option>'));

            var options = JSON.parse(optionsDictionary);
            for (var key in options) {
                if (options.hasOwnProperty(key)) {
                    select.append($('<option value="' + key + '">'+ options[key] + '</option>'));
                }
            }
            
            select.val(value);
            var formfield = $('<div>');
            formfield.addClass('formselectfieldwrapper');
            formfield.append(select);

            form.append(formfieldlabel).append(formfield);
        },

        addFieldCheckbox: function (form, name, displayName, value) {
            var hiddenInput = $('<input>');
            hiddenInput.prop('type', 'hidden');
            hiddenInput.prop('name', name);
            hiddenInput.val('false');

            var checkbox = $('<input>');
            checkbox.prop('type', 'checkbox');
            checkbox.prop('name', name);
            checkbox.val('true');
            checkbox.prop('id', 'LoginCheck_' + name);
            checkbox.prop('checked', 'true' == value || true == value);

            var label = $('<label>');
            label.prop('for', 'LoginCheck_' + name);
            label.addClass('standardformlabel');
            label.text(displayName);

            var wrapper = $('<div>');
            wrapper.addClass('formcheckboxwrapper');
            wrapper.append(checkbox).append(label);

            form.append(hiddenInput).append(wrapper);
        },

        addFieldButton: function (form, displayName, value) {
            var button = $('<a>');
            button.attr('href', value);

            var btn = $('<div class="btn blue" style="width: 30%; margin-left: auto; margin-right: auto; display: inline-block;">');
            var btnBorderLeft = $('<div class="left">');
            var btnBorderMid = $('<div class="mid">');
            var label = $('<div>');
            label.text(displayName);
            var btnBorderRight = $('<dvi class="right">');

            btnBorderMid.append(label);
            btn.append(btnBorderLeft).append(btnBorderMid).append(btnBorderRight);
            button.append(btn);

            form.append(button);        
        },

        start: function () {
            var url = 'afterlogin_chooseCRM.quosalweb' + window.location.search + '&noheader=yes';
            $.ajax(url, {
                data: {},
                dataType: 'json',
                error: $.quosal.validation.ajax,
                success: $.credentialChecks.handler
            });
            quosal.net.isCredentialChecking = true;
        },

        showForm: function (form, stage, title, settingsPageUrl) {
            var links = [];            
            if (title != 'An Administrator Must Choose Email Type' && title != 'Email Credentials - O365 Integration')
                links.push({
                    title: 'Save',
                    callback: function () {
                        $.quosal.dialog.quickHide();
                        $.quosal.dialog.loading();

                        var url = 'afterlogin_' + stage + '.quosalweb' + window.location.search + '&noheader=yes&stage=' + stage;
                        $.ajax(url, {
                            type: 'POST',
                            data: form.serialize(),
                            dataType: 'json',
                            error: $.quosal.validation.ajax,
                            success: $.credentialChecks.handler
                        });
                    }
                });
            links.push({
                title: 'Skip',
                callback: function () {
                    $.quosal.dialog.quickHide();
                    $.quosal.dialog.loading();
                    var nextStage = $.credentialChecks.theStageAfter(stage);
                    if (nextStage === 'allClear') {
                        $.quosal.dialog.hide();
                        $.credentialChecks.linkCWOAuthAccount();
                    }
                    else
                        var url = 'afterlogin_' + nextStage + '.quosalweb' + window.location.search + '&noheader=yes&stage=' + nextStage;
                        $.ajax(url, {
                            data: {},
                            dataType: 'json',
                            error: $.quosal.validation.ajax,
                            success: $.credentialChecks.handler
                        });
                }
            });
            links.push({
                title: 'Skip All',
                callback: function () {
                    $.quosal.dialog.hide();
                    $.credentialChecks.linkCWOAuthAccount();
                }
            });

            $.quosal.dialog.show({
                title: title,
                message: form,
                links: links,
                disableDraghandle: true,
            });

            var link = $('<a target="_blank"><div class="toolbutton gear"></div></a>');
            link.prop('href', settingsPageUrl);
            $('#maindialog>div.title').append(link);
        },

        theStageAfter: function (stage) {

            if (stage == 'chooseCRM') {
                return 'crmCredentials';
            } else if (stage == 'crmCredentials') {
                return 'chooseEmail';
            } else if (stage == 'chooseEmail') {
                return 'emailCredentials';
            } else if (stage == 'emailCredentials') {
                return 'orderPorterEmail';
            } else if (stage == 'orderPorterEmail') {
                return 'allClear';
            } else {
                return 'allClear';
            }
        },

        handler: function (data) {
            quosal.net.isCredentialChecking = false;
            if (data.stage == 'chooseCRM') {
                $.credentialChecks.chooseCRM(data);
            } else if (data.stage == 'crmCredentials') {
                $.credentialChecks.crmCredentials(data);
            } else if (data.stage == 'chooseEmail') {
                $.credentialChecks.chooseEmail(data);
            } else if (data.stage == 'emailCredentials') {
                $.credentialChecks.emailCredentials(data);
            } else if (data.stage == 'orderPorterEmail') {
                $.credentialChecks.orderPorterEmail(data);
            } else if (data.stage == 'allClear') {
                $.quosal.dialog.hide();
                $.credentialChecks.linkCWOAuthAccount();
            }
        },

        chooseCRM: function (data) {
            var form = $('<form>');
            if (data.instruction)
                form.append('<p class="instruction">' + data.instruction + '</p>');
            else
                form.append('<p class="instruction">You must enter your customer provider</p>');

            //non admin
            var nonAdminColumn = $('<div class="formcolumn"></div>');
            if (data.CustomerProvider != undefined)
                $.credentialChecks.addCrmFieldSelect(nonAdminColumn, "CustomerProvider", "Customer Provider", data.options, data.CustomerProvider);
            if (!nonAdminColumn.is(':empty'))
                form.append(nonAdminColumn);

            //admin
            var adminColumn = $('<div class="formcolumn adminColumn"></div>');
            if (data.DefaultCustomerProvider != undefined)
                $.credentialChecks.addCrmFieldSelect(adminColumn, "DefaultCustomerProvider", "Default Customer Provider", data.options, data.DefaultCustomerProvider);
            if (!adminColumn.is(':empty')) {
                adminColumn.prepend('<div class="title">Administrative Settings</div>');
                form.append(adminColumn);
            }

            $.credentialChecks.showForm(form, 'chooseCRM', 'Choose CRM', data.settingsPageUrl);
        },

        crmCredentials: function (data) {
            if (data.crm == "connectwise") {
                var form = $('<form>');
                var instruction = $('<p class="instruction">Please enter your ConnectWise Manage credentials.</p>');
                if (typeof data.instruction === 'string')
                    instruction.html(data.instruction);
                if (data.loginError != undefined)
                    instruction.append('<br><b>Login failed with error:<br>' + data.loginError + '</b>');
                form.append(instruction);

                //non admin
                var nonAdminColumn = $('<div class="formcolumn"></div>');
                if (data.ConnectwiseSitename != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "ConnectwiseSitename", "Company", data.ConnectwiseSitename);
                if (data.ConnectwiseUserName != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "ConnectwiseUserName", "User Name", data.ConnectwiseUserName);
                if (data.ConnectwisePassword != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "ConnectwisePassword", "Password", data.ConnectwisePassword, { password: true });
                if (data.ConnectWiseIntegratorName != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "ConnectWiseIntegratorName", "Integrator Username", data.ConnectWiseIntegratorName);
                if (data.ConnectWiseIntegratorPassword != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "ConnectWiseIntegratorPassword", "Integrator Password", data.ConnectWiseIntegratorPassword, { password: true });
                if (!nonAdminColumn.is(':empty'))
                    form.append(nonAdminColumn);

                //admin
                var adminColumn = $('<div class="formcolumn adminColumn"></div>');
                if (data.ConnectWiseDefaultIntegratorUser != undefined)
                    $.credentialChecks.addFieldInput(adminColumn, "ConnectWiseDefaultIntegratorUser", "Default Integrator Username", data.ConnectWiseDefaultIntegratorUser);
                if (data.ConnectWiseDefaultIntegratorPassword != undefined)
                    $.credentialChecks.addFieldInput(adminColumn, "ConnectWiseDefaultIntegratorPassword", "Default Integrator Password", data.ConnectWiseDefaultIntegratorPassword, { password: true });
                if (data.ConnectWiseDefaultIntegratorCompany != undefined)
                    $.credentialChecks.addFieldInput(adminColumn, "ConnectWiseDefaultIntegratorCompany", "Default Company", data.ConnectWiseDefaultIntegratorCompany);
                if (data.ConnectwiseURL != undefined)
                    $.credentialChecks.addFieldInput(adminColumn, "ConnectwiseURL", "ConnectWise URL", data.ConnectwiseURL);
                if (!adminColumn.is(':empty')) {
                    adminColumn.prepend('<div class="title">Administrative Settings</div>');
                    form.append(adminColumn);
                }

                $.credentialChecks.showForm(form, 'crmCredentials', 'ConnectWise Manage Credentials', data.settingsPageUrl);

            } else if (data.crm == "netsuite") {
                var form = $('<form>');
                var instruction = $('<p class="instruction">Please enter your NetSuite credentials.</p>');
                if (typeof data.instruction === 'string')
                    instruction.html(data.instruction);
                if (data.loginError != undefined)
                    instruction.append('<br><b>Login failed with error:<br>' + data.loginError + '</b>');
                form.append(instruction);


                //non admin
                var nonAdminColumn = $('<div class="formcolumn"></div>');
                if (data.NetSuiteAccount != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "NetSuiteAccount", "Account Name", data.NetSuiteAccount);

                if (data.NetSuiteEmailAddress != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "NetSuiteEmailAddress", "Your Email Address", data.NetSuiteEmailAddress);

                if (data.NetsuiteConsumerKey != undefined) {
                    var container = $('<div class="tbaOnly"></div>');
                    $.credentialChecks.addFieldInput(container, "NetsuiteConsumerKey", "NetSuite Consumer Key", data.NetsuiteConsumerKey, { password: true });
                    nonAdminColumn.append(container);
                }
                if (data.NetsuiteConsumerSecret != undefined) {
                    var container = $('<div class="tbaOnly"></div>');
                    $.credentialChecks.addFieldInput(container, "NetsuiteConsumerSecret", "NetSuite Consumer Secret", data.NetsuiteConsumerSecret, { password: true });
                    nonAdminColumn.append(container);
                }
                if (data.NetsuiteTokenId != undefined) {
                    var container = $('<div class="tbaOnly"></div>');
                    $.credentialChecks.addFieldInput(container, "NetsuiteTokenId", "NetSuite Token ID", data.NetsuiteTokenId, { password: true });
                    nonAdminColumn.append(container);
                }
                if (data.NetsuiteTokenSecret != undefined) {
                    var container = $('<div class="tbaOnly"></div>');
                    $.credentialChecks.addFieldInput(container, "NetsuiteTokenSecret", "NetSuite Token Secret", data.NetsuiteTokenSecret, { password: true });
                    nonAdminColumn.append(container);
                }
                if (!nonAdminColumn.is(':empty'))
                    form.append(nonAdminColumn);

                //admin
                var adminColumn = $('<div class="formcolumn adminColumn"></div>');
                if (data.NetsuiteLoginMode != undefined)
                    $.credentialChecks.addFieldSelect(adminColumn, "NetsuiteLoginMode", "NetSuite Login Mode", data.NetsuiteLoginModeOptions, data.NetsuiteLoginMode);
                if (data.DefaultNetSuiteAccount != undefined)
                    $.credentialChecks.addFieldInput(adminColumn, "DefaultNetSuiteAccount", "Default Account Name", data.DefaultNetSuiteAccount);
                        
                if (data.DefaultNetSuiteConsumerKey != undefined) {
                    var container = $('<div class="tbaOnly"></div>');
                    $.credentialChecks.addFieldInput(container, "DefaultNetSuiteConsumerKey", "Default NetSuite Consumer Key", data.DefaultNetSuiteConsumerKey, { password: true });
                    adminColumn.append(container);
                }

                if (data.DefaultNetSuiteConsumerSecret != undefined) {
                    var container = $('<div class="tbaOnly"></div>');
                    $.credentialChecks.addFieldInput(container, "DefaultNetSuiteConsumerSecret", "Default NetSuite Consumer Secret", data.DefaultNetSuiteConsumerSecret, { password: true });
                    adminColumn.append(container);
                }

                if (data.DefaultNetSuiteTokenId != undefined) {
                    var container = $('<div class="tbaOnly"></div>');
                    $.credentialChecks.addFieldInput(container, "DefaultNetSuiteTokenId", "Default NetSuite Token ID", data.DefaultNetSuiteTokenId, { password: true });
                    adminColumn.append(container);
                }

                if (data.DefaultNetSuiteTokenSecret != undefined) {
                    var container = $('<div class="tbaOnly"></div>');
                    $.credentialChecks.addFieldInput(container, "DefaultNetSuiteTokenSecret", "Default NetSuite Token Secret", data.DefaultNetSuiteTokenSecret, { password: true });
                    adminColumn.append(container);
                }

                if (!adminColumn.is(':empty')) {
                    adminColumn.prepend('<div class="title">Administrative Settings</div>');
                    form.append(adminColumn);
                }

                var ssoLink = $('<a target="_blank" href="" >Navigate to NetSuite using SSO with these credentials</a>');
                var ssoLinkWrapper = $('<p class="ssoOnly"></p>');
                ssoLinkWrapper.append(ssoLink);
                ssoLinkWrapper.click(function () {
                    ssoLink.prop('href', $.quosal.util.url('netsuiteSSOredirect.quosalweb', 'noheader=yes', form.serialize()));
                });
                nonAdminColumn.prepend(ssoLinkWrapper);         
			
                $.credentialChecks.showForm(form, 'crmCredentials', 'NetSuite Credentials', data.settingsPageUrl);

            } else if (data.crm == "autotask") {
                var form = $('<form>');
                var instruction = $('<p class="instruction">Please enter your Autotask credentials.</p>');
                if (typeof data.instruction === 'string')
                    instruction.html(data.instruction);
                if (data.loginError != undefined)
                    instruction.append('<br><b>Login failed with error:<br>' + data.loginError + '</b>');
                form.append(instruction);

                var nonAdminColumn = $('<div class="formcolumn"></div>');
                if (data.AutotaskUsername != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "AutotaskUsername", "Your Username", data.AutotaskUsername);
                if (data.AutotaskPassword != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "AutotaskPassword", "Your Password", data.AutotaskPassword, { password: true });
                if (data.AutotaskTrackingIdentifier != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "AutotaskTrackingIdentifier", "Your Tracking Identifier", data.AutotaskTrackingIdentifier);
                if (!nonAdminColumn.is(':empty'))
                    form.append(nonAdminColumn);

                $.credentialChecks.showForm(form, 'crmCredentials', 'Autotask Credentials', data.settingsPageUrl);

            } else if (data.crm == "dynamics") {
                var form = $('<form>');
                var instruction = $('<p class="instruction">Please enter your Dynamics credentials.</p>');
                if (typeof data.instruction === 'string')
                    instruction.html(data.instruction);
                if (data.loginError != undefined)
                    instruction.append('<br><b>Login failed with error:<br>' + data.loginError + '</b>');
                form.append(instruction);

                //non admin
                var nonAdminColumn = $('<div class="formcolumn"></div>');
                if (data.DynamicsUsername != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "DynamicsUsername", "Your Username", data.DynamicsUsername);
                if (data.DynamicsPassword != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "DynamicsPassword", "Your Password", data.DynamicsPassword, { password: true });
                if (!nonAdminColumn.is(':empty'))
                    form.append(nonAdminColumn);

                //admin
                var adminColumn = $('<div class="formcolumn adminColumn"></div>');
                if (data.DynamicsURL != undefined)
                    $.credentialChecks.addFieldInput(adminColumn, "DynamicsURL", "Dynamics Webservice URL", data.DynamicsURL);
                if (data.DynamicsMetadataURL != undefined)
                    $.credentialChecks.addFieldInput(adminColumn, "DynamicsMetadataURL", "Dynamics Metadata URL", data.DynamicsMetadataURL);
                if (data.DynamicsOrganization != undefined)
                    $.credentialChecks.addFieldInput(adminColumn, "DynamicsOrganization", "Dynamics Organization", data.DynamicsOrganization);
                if (data.DynamicsAuthenticationType != undefined)
                    $.credentialChecks.addFieldSelect(adminColumn, "DynamicsAuthenticationType", "Authentication Mode", data.DynamicsAuthenticationTypeOptions, data.DynamicsAuthenticationType);
                if (!adminColumn.is(':empty')) {
                    adminColumn.prepend('<div class="title">Administrative Settings</div>');
                    form.append(adminColumn);
                }

                $.credentialChecks.showForm(form, 'crmCredentials', 'Dynamics Credentials', data.settingsPageUrl);
            }
        }, //END crmCredentials: function (data) {

        chooseEmail: function (data) {
            var form = $('<form>');
            if (data.getAnAdmin) {
                var instruction = $('<p class="instruction"></p>');
                instruction.html(data.getAnAdmin);
                form.append(instruction);

                $.credentialChecks.showForm(form, 'chooseEmail', 'An Administrator Must Choose Email Type', data.settingsPageUrl);
            } else {
                var instruction = $('<p class="instruction">Please enable at least one form of email.</p>');
                if (typeof data.instruction === 'string')
                    instruction.html(data.instruction);
                form.append(instruction);

                //admin
                var adminColumn = $('<div class="formcolumn adminColumn"></div>');
                if (data.UseSMTP != undefined)
                    $.credentialChecks.addFieldCheckbox(adminColumn, "UseSMTP", "Use SMTP", data.UseSMTP);
                if (data.UseExchange != undefined)
                    $.credentialChecks.addFieldCheckbox(adminColumn, "UseExchange", "Use Exchange", data.UseExchange);
                adminColumn.find('#LoginCheck_UseExchange').change(function (e) {
                    if (this.checked)
                        $('#LoginCheck_UseSMTP').prop('checked', false);
                });
                adminColumn.find('#LoginCheck_UseSMTP').change(function (e) {
                    if (this.checked)
                        $('#LoginCheck_UseExchange').prop('checked', false);
                });

                if (data.DisableFallbackEmail != undefined)
                    $.credentialChecks.addFieldCheckbox(adminColumn, "DisableFallbackEmail", "Disable Fallback Quosal Email", data.DisableFallbackEmail);
                if (data.DisableSalesforceEmail != undefined)
                    $.credentialChecks.addFieldCheckbox(adminColumn, "DisableSalesforceEmail", "Disable Salesforce Email", data.DisableSalesforceEmail);
                if (!adminColumn.is(':empty')) {
                    adminColumn.prepend('<div class="title">Administrative Settings</div>');
                    form.append(adminColumn);
                }
                $.credentialChecks.showForm(form, 'chooseEmail', 'Choose Email Type', data.settingsPageUrl);
            }
        },

        emailCredentials: function (data) {
            if (data.emailType == 'exchange') {
                var form = $('<form>');
                var instruction = $('<p class="instruction">Please enter your email credentials.</p>');
                if (typeof data.instruction === 'string')
                    instruction.html(data.instruction);
                if (data.loginError != undefined)
                    instruction.append('<br><b>Email test failed with error:<br>' + data.loginError + '</b>');
                form.append(instruction);

                //non admin
                var nonAdminColumn = $('<div class="formcolumn"></div>');
                if (data.userEmail != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "userEmail", "Your Email Address", data.userEmail);
                if (data.ExchangeUsername != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "ExchangeUsername", "Exchange Username", data.ExchangeUsername);
                if (data.ExchangePassword != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "ExchangePassword", "Exchange Password", data.ExchangePassword, { password: true });
                if (data.ExchangeServer != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "ExchangeServer", "Optional User Override Exchange URL", data.ExchangeServer);
                if (data.ExchangeDomain != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "ExchangeDomain", "Optional User Override Domain", data.ExchangeDomain);
                if (!nonAdminColumn.is(':empty'))
                    form.append(nonAdminColumn);

                //admin
                var adminColumn = $('<div class="formcolumn adminColumn"></div>');
                if (data.ExchangeDefaultServer != undefined)
                    $.credentialChecks.addFieldInput(adminColumn, "ExchangeDefaultServer", "Default Exchange URL", data.ExchangeDefaultServer);
                if (data.ExchangeDefaultDomain != undefined)
                    $.credentialChecks.addFieldInput(adminColumn, "ExchangeDefaultDomain", "Default Domain", data.ExchangeDefaultDomain);
                if (!adminColumn.is(':empty')) {
                    adminColumn.prepend('<div class="title">Administrative Settings</div>');
                    form.append(adminColumn);
                }
                $.credentialChecks.showForm(form, 'emailCredentials', 'Email Credentials - Exchange Server', data.settingsPageUrl);

            } else if (data.emailType == 'smtp') {
                var form = $('<form>');
                var instruction = $('<p class="instruction">Please enter your email credentials.</p>');
                if (typeof data.instruction === 'string')
                    instruction.html(data.instruction);
                if (data.loginError != undefined)
                    instruction.append('<br><b>Email test failed with error:<br>' + data.loginError + '</b>');
                form.append(instruction);

                //non admin
                var nonAdminColumn = $('<div class="formcolumn"></div>');
                if (data.SMTPUserName != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "SMTPUserName", "SMTP Username", data.SMTPUserName);
                if (data.SMTPPassword != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "SMTPPassword", "SMTP Password", data.SMTPPassword, { password: true });
                if (data.SMTPServer != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "SMTPServer", "SMTP Server", data.SMTPServer);
                if (data.SMTPPort != undefined)
                    $.credentialChecks.addFieldInput(nonAdminColumn, "SMTPPort", "Override Port", data.SMTPPort);
                if (data.SMTPIsSSL != undefined)
                    $.credentialChecks.addFieldCheckbox(nonAdminColumn, "SMTPIsSSL", "Is SSL", data.SMTPIsSSL);
                if (!nonAdminColumn.is(':empty'))
                    form.append(nonAdminColumn);

                $.credentialChecks.showForm(form, 'emailCredentials', 'Email Credentials - SMTP Server', data.settingsPageUrl);
            } else if (data.emailType == 'o365') {
                var form = $('<form>');
                var styleDiv = $('<div style="text-align: center;"></div>');
                form.append(styleDiv);
                var instruction = $('<p class="instruction" style="text-align: left;">Unable to validate email integration:</p>');
                if (typeof data.instruction === 'string')
                    instruction.html(data.instruction);
                if (!data.o365Email || data.o365Email === '') {
                    instruction.append('<br><br>' + app.productBranding + ' is not authorized to send emails through your Office O365 account. <br>Please authorize ' + app.productBranding + ' with Microsoft to send email through O365.');                    
                }
                else if (data.loginError != undefined) {
                    instruction.append('<br><b>Email test failed with error:<br>' + data.loginError + '</b>');                
                }
                styleDiv.append(instruction);

                var nonAdminColumn = $('<div class="formcolumn"></div>');
                $.credentialChecks.addFieldButton(nonAdminColumn, 'Authorize ' + app.productBranding + ' with Microsoft', data.microsoftAccessUri);
                styleDiv.append(nonAdminColumn);

                $.credentialChecks.showForm(form, 'emailCredentials', 'Email Credentials - O365 Integration', data.settingsPageUrl);
            }
        },

        orderPorterEmail: function (data) {
            var form = $('<form>');
            var instruction = $('<p class="instruction">Please provide Order Porter email credentials.</p>');
            if (typeof data.instruction === 'string')
                instruction.html(data.instruction);
            form.append(instruction);

            //admin
            var adminColumn = $('<div class="formcolumn adminColumn"></div>');
            if (data.IsCustomOrderPorterEmail != undefined)
                $.credentialChecks.addFieldCheckbox(adminColumn, "IsCustomOrderPorterEmail", "Use These Custom SMTP Credentials", data.IsCustomOrderPorterEmail);
            if (data.OrderPorterHost != undefined)
                $.credentialChecks.addFieldInput(adminColumn, "OrderPorterHost", "SMTP Host", data.OrderPorterHost);
            if (data.OrderPorterPort != undefined)
                $.credentialChecks.addFieldInput(adminColumn, "OrderPorterPort", "SMTP Port", data.OrderPorterPort);
            if (data.OrderPorterUseSsl != undefined)
                $.credentialChecks.addFieldCheckbox(adminColumn, "OrderPorterUseSsl", "Use SSL", data.OrderPorterUseSsl);
            if (data.OrderPorterUsername != undefined)
                $.credentialChecks.addFieldInput(adminColumn, "OrderPorterUsername", "SMTP Username", data.OrderPorterUsername);
            if (data.OrderPorterPassword != undefined)
                $.credentialChecks.addFieldInput(adminColumn, "OrderPorterPassword", "SMTP Password", data.OrderPorterPassword, { password: true });
            if (data.OrderPorterFromAddresss != undefined)
                $.credentialChecks.addFieldInput(adminColumn, "OrderPorterFromAddresss", "SMTP From Email Address", data.OrderPorterFromAddresss);
            if (!adminColumn.is(':empty')) {
                adminColumn.prepend('<div class="title">Administrative Settings</div>');
                form.append(adminColumn);
            }

            $.credentialChecks.showForm(form, 'orderPorterEmail', 'Configure Order Porter Email', data.settingsPageUrl);
        },

        linkCWOAuthAccount: function () {
            var username = app.currentUser.UserName;
            var getUrl = $.quosal.util.url("linkAccount.quosalweb") + "&loginHint=" + encodeURIComponent(username) + "&requestOAuthUrl=true";
            if (app.currentUser.CwOauthSubscriberId==null || app.currentUser.CwOauthSubscriberId == '')
            {
                $.ajax(getUrl, {
                    type: "GET",
                    data: {
                        deviceId: app.deviceId
                    },
                    success: function (data) {
                        var response = $.parseJSON(data);
                        if (response.userNameExists && response.oAuthUrl) {
                            $.quosal.dialog.show({
                                title: "Link Account",
                                message: '<div>' +
                                    '<div>You should have already received your Invitation to create your ConnectWise Single Sign On account.</div>' +
                                    '<div>If you have not please check your email SPAM folder or contact your system administrator.</div>' +
                                    '<div>Do you wish to link to your ConnectWise account at this time?</div>' +
                                    '</div>',
                                links: [
                                    {
                                        title: 'Yes', callback: function () {
                                            window.location.href = response.oAuthUrl;
                                        }
                                    },
                                    {
                                        title: 'No', callback: function () {
                                            $.quosal.dialog.hide();
                                        }
                                    }
                                ]
                            });
                        }
                    }
                });
            }
        }
    }
});