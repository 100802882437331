export class DualSegment extends React.Component {
    constructor(props) {
        super(props);

        this.renderParts = this.renderParts.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(index) {
        if (this.props.fnChanged) {
            this.props.fnChanged(this.props.ident, index);
        }
    }

    renderParts() {
        return (
            this.props.elements.map((elem, i) => {
                if (i === this.props.selected) {
                    return (<li key={i} className='cell selected' onClick={() => this.onChange(i)} >{elem.name}</li>);
                }
                else if (elem.disabled) {
                    return (
                        <li key={i} className='cell unselected' aria-disabled="true">{elem.name}</li>
                    );
                } 
                else {
                    return (<li key={i} className='cell unselected' onClick={() => this.onChange(i)} >{elem.name}</li>);
                }
            })
        );
    }

    render() {
        return (
            <div className="segmented">
                <ul>
                    {this.renderParts()}
                </ul>
            </div>
        );
    }
}

  
export class ApprovalPerson extends React.Component {
    constructor(props) {
        super(props);

        this.render = this.render.bind(this);
    }   

    render() {
        let radius = 11;
        let circle = {
            width: radius*2+'px',
            height:radius*2+'px',
            borderRadius:radius + 'px',
            fontSize:'12px',
            color:'#212121',
            lineHeight:radius*2+'px',
            textAlign:'center',
            background:'#f5f5f5',
            border:'solid 1px #cccccc'
        };
        return (<div className="formcolumn" style={{paddingLeft:'0px', paddingTop:'8px',width:'34px',minHeight:'32px'}}>
            <div style={circle} title={this.props.extratool}>
                {this.props.level}
            </div>
            {this.props.extra ? <div style={{color:'#a0a0a0', fontSize:'10', position:'relative', left:'6px', top:'-17px'}}>{this.props.extra}</div>:null}
        </div>);
    }
}


/*
class CsDropDownItem extends React.Component {

    handleClick = () => {
      if (this.props.onClick) {
        this.props.onClick(this.props.item);
      }
    };
  
    handleMouseEnter = (event) => {
      if (this.props.onMouseEnter) {
        this.props.onMouseEnter(this.props.index, { x: event.clientX, y: event.clientY });
      }
    };
  
    handleMouseDown = () => {
      if (this.props.onMouseDown) {
        this.props.onMouseDown(true);
      }
    };
  
    handleMouseMove = (event) => {
      if (this.props.onMouseMove) {
        this.props.onMouseMove({ x: event.clientX, y: event.clientY });
      }
    }
  
    render() {
      let itemClassName;
      let itemStyle;
      const padding = getMouseMode() ? 8 : 16;
      if (this.props.isPicker) {
        itemClassName = cx(css.dropDownPicker, { [css.highlightedItem]: this.props.isHighlighted });
      }
      else if (this.props.addSeparator) {
        itemClassName = cx(css.dropDownItemWithSeparator, { [css.highlightedItem]: this.props.isHighlighted });
      }
      else {
        itemClassName = cx(css.dropDownItem, { [css.highlightedItem]: this.props.isHighlighted });
      }
      if (this.props.itemHeight) {
        itemStyle = { height: this.props.itemHeight - padding };
      }
      return (
        <div className={itemClassName}
          data-cwid={`${this.props.cwId}_${this.props.item.id}`}
          style={itemStyle}
          onClick={this.handleClick}
          onMouseEnter={this.handleMouseEnter}
          onMouseDown={this.handleMouseDown}
          onMouseMove={this.handleMouseMove}
          ref={(div) => this.div = div} >
          {this.props.item.leftWidget}
          {this.props.item.name}
        </div>
      );
    }
  }*/

/*
  class CsMultiSelect extends React.Component {
    constructor(props) {
      super(props);
  
      this._ignoreBlur = false;
      this._ignoreScroll = false;
      this._performAutoCompleteOnUpdate = false;
      this._performAutoCompleteOnKeyUp = false;
  
      this.state = {
        displayText: '',
        expanded: false,
        highlightedIndex: null,
        filter: true,
        focused: false
      };

      this.shouldItemRender = this.shouldItemRender.bind(this);
      this.filterExcludedItems = this.filterExcludedItems.bind(this);
      this.getFilteredItems = this.getFilteredItems.bind(this);
      this.autoCompleteText = this.autoCompleteText.bind(this);
      this.highlightItemFromMouse = this.highlightItemFromMouse.bind(this);
      this.findName = this.findName.bind(this);
      this.findValue = this.findValue.bind(this);
      this.selectItem = this.selectItem.bind(this);
      this.setIgnoreBlur = this.setIgnoreBlur.bind(this);
      this.handleDropdownEnter = this.handleDropdownEnter.bind(this);
      this.handleDropdownLeave = this.handleDropdownLeave.bind(this);
      this.handleScroll = this.handleScroll.bind(this);
      this.handleKeyDown = this.handleKeyDown.bind(this);
      this.handleKeyUp = this.handleKeyUp.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleInputBlur = this.handleInputBlur.bind(this);
      this.handleInputFocus = this.handleInputFocus.bind(this);
      this.handleTriggerClick = this.handleTriggerClick.bind(this);
      this.closePill = this.closePill.bind(this);
      this.focus = this.focus.bind(this);
  
      this.keyDownHandlers = {
        ArrowDown(event) {
          // Cycle through list of items on arrow down, updating `highlightedIndex` along the way 
          event.preventDefault(); // prevent window scrolling
          const itemsLength = this.getFilteredItems().length;
          if (!itemsLength) return;
          const { highlightedIndex } = this.state;
          let index = (
            highlightedIndex === null ||
            highlightedIndex === itemsLength - 1
          ) ? -1 : highlightedIndex + 1; // Go back to the top when reaching the end
          this._performAutoCompleteOnKeyUp = true;
          this.setState({
            highlightedIndex: index,
            expanded: true,
            filter: true
          });
        },
  
        ArrowUp(event) {
          // Cycle through list of items on arrow up, updating `highlightedIndex` along the way
          event.preventDefault(); // prevent window scrolling
          const itemsLength = this.getFilteredItems().length;
          if (!itemsLength) return;
          const { highlightedIndex } = this.state;
          let index = (
            highlightedIndex === -1 ||
            highlightedIndex === null
          ) ? itemsLength - 1 : highlightedIndex - 1; // Go back to the end when reaching the top
          this._performAutoCompleteOnKeyUp = true;
          this.setState({
            highlightedIndex: index,
            expanded: true,
            filter: true
          });
        },
  
        Backspace() {
          let values = this.props.value;
          if (this.state.displayText === '') {
            if (values.length !== this.filterExcludedItems(this.props.items).length) {
              values.pop();
            } else {
              values.splice(0,values.length); //clear the array
            }
            this.props.onChange(values);
          }
        },
  
        Enter(event) {
          if (this.state.expanded === false) {
            // Menu is closed so there is no selection to accept: do nothing
          }
          else {
            // Text entered, menu item has been highlighted, and Enter is hit: select menu item
            event.preventDefault();
            let item = this.getFilteredItems()[this.state.highlightedIndex];
            this.selectItem(item);
            this.setState({
              highlightedIndex: null
            });
          }
        },
  
        Escape() {
          this.setState({
            highlightedIndex: null,
            expanded: false
          });
        },
      };
    }
  
    UNSAFE_componentWillMount() {
      window.addEventListener('scroll', this.handleScroll, true);
    }
  
    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll, true);
    }
  
    componentWillReceiveProps(nextProps) {
      this._performAutoCompleteOnUpdate = true;
      // If `items` has changed we want to reset `highlightedIndex` since it probably no longer refers to a relevant item
      if (this.props.items !== nextProps.items ||
        // The entries in `items` may have been changed even though the object reference remains the same, double check by seeing if `highlightedIndex` points to an existing item
        this.state.highlightedIndex >= nextProps.items.length) {
        this.setState({ highlightedIndex: null });
      }
    }
  
    componentDidUpdate(prevProps, prevState) {
      if (this.state.expanded === true && prevState.expanded === false) {
        const menuPositions = setMenuPositions(this.cwPillTriggerField.getParentDiv());
        this.setState({
          menuTop: menuPositions.menuTop,
          menuLeft: menuPositions.menuLeft,
          menuWidth: menuPositions.menuWidth,
          inputHeight: menuPositions.inputHeight
        });
      }
  
      if (this.state.expanded && this._performAutoCompleteOnUpdate) {
        this._performAutoCompleteOnUpdate = false;
        this.autoCompleteText();
      }
  
      if (prevState.expanded !== this.state.expanded) {
        if (this.state.expanded) {
          if (this.props.onExpand)
            this.props.onExpand(this.state.expanded);
        }
        else {
          if (this.props.onCollapse)
            this.props.onCollapse(this.state.expanded);
          let items = this.getFilteredItems();
          if (items.length === 0 || this.state.displayText === '') { // If text entered doesn't match any valid items or is empty
            this.setState({
              displayText: ''
            });
          }
        }
      }
    }
    
    shouldItemRender(item, enteredText) {
      if (this.props.filterOnPartialMatch) {
          return item.name.toLowerCase().indexOf(enteredText.toLowerCase()) > -1;
      }
      return enteredText.toLowerCase() === item.name.substring(0, enteredText.length).toLowerCase();
    }
  
    filterExcludedItems(items) {
      if (this.props.excludedItems) {
        items = items.filter((item) => (
          !this.props.excludedItems.includes(item.id)
        ));
      }
  
      return items;
    }
  
    getFilteredItems() {
      let items = this.props.items;
  
      items = this.filterExcludedItems(items);
  
      if (this.shouldItemRender && this.state.filter) {
        items = items.filter((item) => (
          this.shouldItemRender(item, this.state.displayText)
        ));
      }
  
      return items;
    }
  
    autoCompleteText() {
      if (this.state.displayText === '')
        return;
      let { highlightedIndex } = this.state;
      let items = this.getFilteredItems();
      if (items.length === 0)
        return;
      let matchedItem = highlightedIndex !== null ?
        items[highlightedIndex] : items[0];
      let itemValue = matchedItem.name;
      let itemValueDoesMatch = (itemValue.toLowerCase().indexOf(
        this.state.displayText.toLowerCase()
      ) === 0);
      if (itemValueDoesMatch && highlightedIndex === null)
        this.setState({ highlightedIndex: 0 });
    }
  
    highlightItemFromMouse(index){
      this.setState({ highlightedIndex: index });
    };
  
    findName(id, items) {
      let i;
      for (i = 0; i < items.length; i++) {
        if (items[i].id === id) {
          return items[i].name;
        }
      }
    }
  
    findValue(id, values) {
      let i;
      for (i = 0; i < values.length; i++) {
        if (values[i] === id) {
          return i;
        }
      }
      return -1;
    }
  
    selectItem(item) {
      let values = this.props.value.slice();
  
      if (item.id === -1 && values.length !== this.filterExcludedItems(this.props.items).length) { // Select All
        this.getFilteredItems().forEach(function (element) {
          if (this.findValue(element.id, values) === -1) {
            values.push(element.id);
          }
        }, this);
      }
      else if (item.id === -1 && values.length === this.filterExcludedItems(this.props.items).length) { // Deselect All
        this.getFilteredItems().forEach(function (element) {
          let valuePosition = this.findValue(element.id, values);
          if (valuePosition !== -1) {
            values.splice(valuePosition, 1);
          }
        }, this);
      }
      else {
        let selection = item;
        let valuePosition = this.findValue(selection.id, values);
  
        if (valuePosition === -1) {
          values.push(selection.id);
        }
        else {
          values.splice(valuePosition, 1);
        }
      }
  
      this.setState({
        displayText: ''
      });
  
      this.props.onChange(values); // Report values selected
      this.focus();
    };
  
    setIgnoreBlur(ignore){
      this._ignoreBlur = ignore;
    };
  
    handleDropdownEnter(){
      this._ignoreScroll = true;
    };
  
    handleDropdownLeave(){
      this._ignoreScroll = false;
    };
  
    handleScroll(event){
      // Hide dropdown when window is scrolled
      if (this.state.expanded && (event.target === document || !event.target.className.includes('CwDropdownMulti-dropDown'))) {
        this.setState({
          expanded: false,
          prevExpanded: true
        });
      }
    };
  
    handleKeyDown(event) {
      if (this.keyDownHandlers[event.key])
        this.keyDownHandlers[event.key].call(this, event);
    };
  
    handleKeyUp() {
      if (this._performAutoCompleteOnKeyUp) {
        this._performAutoCompleteOnKeyUp = false;
        this.autoCompleteText();
      }
    };
  
    handleChange(displayText) {
      this._performAutoCompleteOnKeyUp = true;
      this.setState({
        displayText: displayText,
        expanded: true,
        filter: true,
      });
    };
  
    handleInputBlur() {
      if (this._ignoreBlur)
        return;
      // IE fires a blur event when clicking the scrollbar - this prevents the dropdown from closing
      if(this.cwDropdown && this.cwDropdown.isMousedOver()) {
        this.cwPillTriggerField.focus();
        return;
      }
      this.setState({
        expanded: false,
        focused: false,
        highlightedIndex: null
      });
      if (this.props.onBlur) {
        this.props.onBlur();
      }
    };
  
    handleInputFocus(){
      if (this._ignoreBlur) {
        this.setIgnoreBlur(false);
        return;
      }
      this.setState({
        focused: true
      });
      if (this.props.onFocus) {
        this.props.onFocus();
      }
    };
  
    handleTriggerClick() {
      // Expand the full unfiltered dropdown if the trigger is clicked
      if (this.props.disabled)
        return;
      this.focus();
      this.setState(
        {
          expanded: true,
          filter: false
        }
      );
    };
  
    closePill (key) {
      if(key.id === 'all'){
        this.selectItem({id: -1}); // deselect all
      }
      else {
        let values = this.props.value;
        let valuePosition = this.findValue(key.id, values);
        values.splice(valuePosition, 1);
        this.props.onChange(values); // Report values selected
      }
      this.focus();
    };
  
    focus() {
      this.cwPillTriggerField.focus();
    }
  
    render() {
      const dropdownSettings = {
        top: this.state.menuTop,
        itemHeight: 31,
        inputHeight: this.state.inputHeight,
        maxItemsPerPage: 10,
        numOfItems: this.getFilteredItems().length,
        numOfExtraItems: 1
      };
  
      const dropdownPosition = calcDropdownPosition(dropdownSettings);
  
      let style = { // Pass calculated position coordinates to dropdown with inline style
        left: this.state.menuLeft,
        top: dropdownPosition.top,
        minWidth: this.state.menuWidth,
        height: dropdownPosition.menuHeight
      };
  
      let values = this.props.value;
      let zeroPosition = this.findValue(0, values);
      if (zeroPosition !== -1) {
        values.splice(zeroPosition, 1);
      }
      let pills = [];
      let allSelected = false;
      let noneSelected = false;
  
      if (values.length === this.filterExcludedItems(this.props.items).length && values.length > 0) {
        pills.push(<CwWidgets.CwPill
                      id='all'
                      key='all'
                      value={this.props.allPillText}
                      onDeletePill={this.closePill}
                      deletePillIcon={this.props.closePillIcon}
                    />);
        allSelected = true;
      }
      else if (values.length === 0) {
        noneSelected = true;
      }
      else {
        values.forEach(function (element) {
          pills.push(
            <CwWidgets.CwPill
              id={element}
              key={element}
              value={this.findName(element, this.props.items) + ' '}
              onDeletePill={this.closePill}
              deletePillIcon={this.props.closePillIcon} />
          );
        }, this);
      }
  
      let cwMultiSelectStyle;
  
      if (this.props.invalid) {
        cwMultiSelectStyle = css.cwMultiSelectStyleInvalid;
      }
      else if (this.props.disabled) {
        cwMultiSelectStyle = css.cwMultiSelectStyleDisabled;
      }
      else {
        cwMultiSelectStyle = this.state.focused ? css.cwMultiSelectStyleFocused : css.cwMultiSelectStyle;
      }
  
      return (
        <CwWidgets.CwPillTriggerField
          cwId={this.props.cwId}
          style={cwMultiSelectStyle}
          ref={(input) => this.cwPillTriggerField = input}
          triggerIcon={this.props.triggerIcon}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          disabled={this.props.disabled}
          invalid={this.props.invalid}
          handleTriggerClick={this.props.handleTriggerClick ? this.props.handleTriggerClick : this.handleTriggerClick}
          pills={this.props.disabled ? [] : pills}
          value={this.state.displayText}
        >
          {this.state.expanded && this.getFilteredItems().length > 0
            ? <CwWidgets.CwDropdownMulti
              items={this.getFilteredItems()}
              value={this.props.value}
              highlightedIndex={this.state.highlightedIndex}
              style={style}
              onClick={this.selectItem}
              onMouseEnter={this.highlightItemFromMouse}
              onMouseDown={this.setIgnoreBlur} // Ignore blur to prevent menu from de-rendering before we can process click
              onMouseUp={this.setIgnoreBlur(false)}
              filter={this.state.filter}
              pickerText={this.props.pickerText}
              allSelected={allSelected}
              noneSelected={noneSelected}
              selectAllText={this.props.selectAllText}
              ref={(dropdown) => this.cwDropdown = dropdown}
            />
            : null}
        </CwWidgets.CwPillTriggerField>
      );
    }
  }
*/


