import { styled } from "@mui/material/styles";
import { Button, IconButton, Paper, Switch, Typography } from "@mui/material";

const headerHeight = 310;

export const Item = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	...theme.typography.body1,
	padding: theme.spacing(3),
	height: `calc(100vh - ${headerHeight}px)`,
	color: theme.palette.text.primary
}));

export const LinkStyledButton = styled(Button)(({ theme }) => ({
	padding: "0 10px",
	fontWeight: 400,
	lineHeight: "normal",
	color: theme.palette.secondary.main
}));

export const BreadcrumbsButton = styled(Button)({
	padding: 0,
	fontWeight: 400,
	fontSize: 14,
	color: "#006A8E"
});

export const StyledButton = styled(Button)({
	padding: "8px 13px",
	lineHeight: "normal"
});

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
	minWidth: 40,
	maxWidth: 40,
	padding: 0,
	color: theme.palette.primary.main,
	"& .MuiSvgIcon-root": { width: 16, height: 16 }
}));

export const StyledSwitch = styled(Switch)(() => ({
	padding: 8,
	"& .MuiSwitch-track": {
		borderRadius: 22 / 2
	},
	"& .MuiSwitch-switchBase": {
		"&.Mui-checked": {
			"& + .MuiSwitch-track": {
				opacity: 1
			}
		},
		"&.Mui-disabled": {
			"& + .MuiSwitch-track": {
				opacity: 0.5
			}
		}
	},
	"& .MuiSwitch-thumb": {
		backgroundColor: "#e0e0e0",
		boxShadow: "none",
		width: 14,
		height: 14,
		margin: 3
	}
}));

export const InfoBox = styled(Typography)({
	borderRadius: "24px",
	fontSize: 12,
	padding: "3px 8px",
	backgroundColor: "#0A75BF",
	color: "#fff"
});
