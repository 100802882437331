import React from "react";
import { Grid, Typography } from "@mui/material";

import { Item } from "../styledComponents/common";
import { useAppDataContext } from "../globalStates/appDataProvider";
import ProductsDataGrid from "../commonComponents/productsDataGrid";
import ProductSearchInput from "./productSearchInput";
import ProductSearchCategoryMenu from "./productSearchCategoryMenu";
// import SmartOptionCards from "./smartOptionsCards.jsx";

function ProductSearchPage() {
	const {
		products,
		isLoadingProducts,
		isFilteredWithPrice,
		productsToFilter,
		setIsFilteredWithPrice
	} = useAppDataContext();

	return (
		<Grid container spacing={2} sx={{ p: "0 5px" }}>
			<Grid item xs={3}>
				<Item>
					<ProductSearchInput />
					<ProductSearchCategoryMenu />
				</Item>
			</Grid>

			<Grid item xs={9}>
				{/* <SmartOptionCards cardsNumber={4} /> */}

				<Item sx={{ overflowY: "auto" }}>
					<Typography fontSize={20} pb={2}>
						Search Results
					</Typography>

					<ProductsDataGrid
						productsList={products}
						isLoading={isLoadingProducts}
						productsType="productsSearchGrid"
						itemsWithPrices={isFilteredWithPrice}
						handleChangeItemsWithPrices={setIsFilteredWithPrice}
						productsToFilter={productsToFilter}
					/>
				</Item>
			</Grid>
		</Grid>
	);
}

export default ProductSearchPage;
