export class Panel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {    
            fullscreen : false,
        };
        // This binding is necessary to make `this` work in the callback
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
    }
    toggleFullscreen() {
        var isBecomingFullscreen = !this.state.fullscreen;
        this.setState({fullscreen: isBecomingFullscreen});
        if (this.props.onFullscreenToggle) {
            this.props.onFullscreenToggle();
        }
        if (isBecomingFullscreen) {
            $('body').addClass('overflowHidden');
            $('#topHeader').addClass('hidden');
        } else {
            $('body').removeClass('overflowHidden');
            $('#topHeader').removeClass('hidden');
        }
        $(window).resize();
    }
    componentWillUnmount() {
        var isFullscreen = this.state.fullscreen;
        if (isFullscreen) {
            $('body').removeClass('overflowHidden');
            $('#topHeader').removeClass('hidden');
        }
    }
    render() {
        var title = (this.props.title || this.props.editable) ? <PanelTitle title={this.props.title} panel={this} {...this.props}>{this.props.titleChildren}</PanelTitle> : null;

        var className = 'panel';
        if (this.props.collapsible) {
            className += ' collapsible';
        }
        if (this.props.allowFullscreen) {
            className += ' allowfull';
        }
        if (this.props.rightContentExpanded) {
            className += ' rightContentExpanded'
        }
        if (this.state.fullscreen) {
            className += ' fullscreen';
        }
        className += ' ' + this.props.className;

        return (
            <div ref="panel" className={className} style={this.props.style} id={this.props.id}>
                {this.props.beforeTitle}
                {title}
                {this.props.children}
                {this.props.collapsible ? <PanelCollapseButton panel={this} /> : null}
                {this.props.duplicateTitleButtonsInFooter ? <div className="footer">{this.props.titleChildren}</div> : null}
            </div>
        );
    }
}
global.Panel = Panel;

export class PanelTitle extends React.Component {
    render() {
        return (
            <div className="title">
                {(this.props.panel && this.props.panel.props.allowFullscreen) ? <div className="fullscreenicon" title="Toggle Fullscreen" onClick={this.props.panel.toggleFullscreen} /> : null}
                {this.props.editable && this.props.onTitleChanged ? <input type="text" className="panel-title-editor" onChange={this.props.onTitleChanged} value={this.props.title} /> :
                <span style={{float:'left'}}>{this.props.title}</span>}
                {this.props.children}
            </div>
        );
    }
}
global.PanelTitle = PanelTitle;

export class PanelToolbar extends React.Component {
    render() {
        return (
            <div className="toolbar " style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}
global.PanelToolbar = PanelToolbar;

export class PanelToolbarButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
        };    
   
        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(e){
        if (this.props.disabled) {
            return;         
        }
        if(this.props.onClick)
            this.props.onClick(e);
        else if(this.props.href)
            quosal.navigation.navigate(this.props.href, {
                target: this.props.target,
                transition: this.props.transition,
                bypassClientNav: this.props.bypassClientNav
            }, this.props.title);
    }
    render() {
        if(this.props.visible === false)
            return <span />;

        var style = this.props.style || {};
        style.fontSize = 14;
        style.fontFamily = "Roboto";
        style.fontWeight = 500;
        var spinner = '';

        if(this.props.image) {
            if(this.props.image == 'spinner')
                spinner = <Spinner />;
            else
                style.backgroundImage = 'url(' + this.props.image + ')';
        }
        var cls = 'toolbutton ' + (this.props.className || '');
        if (this.props.disabled) {
            cls += ' disabled ';
        }

        return (
            <div onClick={this.props.disabled ? null : this.handleClick} className={cls} style={style} title={this.props.title} href={this.props.href} data-cy={this.props.dataCy} id={this.props.id}>
                {spinner}
                {this.props.text}
                {this.props.children}
            </div>
        );
    }
}
global.PanelToolbarButton = PanelToolbarButton;

export class PanelToolbarSeparator extends React.Component {
    render() {
        if(this.props.visible === false)
            return <span />;

        var className = 'vr' + (this.props.className ? ' ' + this.props.className : '');
        return (
            <div className={className}></div>
        );
    }
}
global.PanelToolbarSeparator = PanelToolbarSeparator;

export class PanelContent extends React.Component {
    render() {
        return (
            <div className="content" style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}
global.PanelContent = PanelContent;

export class PanelCollapseButton extends React.Component {
    constructor(props) {
        super(props);
        var cookie = quosal.util.cookie('panel_' + this.props.panel.props.cookie);
        this.state = {  isCollapsed: cookie === 'collapsed'                     
        };

        // This binding is necessary to make `this` work in the callback
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }
    
    toggleCollapse() {
        if(this.isSliding)
            return;

        this.isSliding = true;

        if(this.props.panel.props.onCollapseChanging)
            this.props.panel.props.onCollapseChanging(!this.state.isCollapsed, this);

        quosal.util.cookie('panel_' + this.props.panel.props.cookie, this.state.isCollapsed ? 'expanded' : 'collapsed', {
            expires: 365,
            path: '/'
        })

        if(this.state.isCollapsed) {
            $(this.props.panel.refs.panel).find('.extra').slideDown(function () {
                this.isSliding = false;
                this.setState({isCollapsed: false});

                if(this.props.panel.props.onCollapseChanged)
                    this.props.panel.props.onCollapseChanged(false, this);
            }.bind(this));
        } else {
            $(this.props.panel.refs.panel).find('.extra').slideUp(function () {
                this.isSliding = false;
                this.setState({isCollapsed: true});

                if(this.props.panel.props.onCollapseChanged)
                    this.props.panel.props.onCollapseChanged(true, this);
            }.bind(this));
        }
    }
    render() {
        return (
            <CollapseButton isCollapsed={this.state.isCollapsed} toggleCollapse={this.toggleCollapse}></CollapseButton>
        );
    }
}
global.PanelCollapseButton = PanelCollapseButton;

export class CollapseButton extends React.Component {
    render() {
        var style = this.props.style ? this.props.style : {};
        style.marginBottom = '1px';
        return (
            <div className={'collapsebutton ' + (this.props.isCollapsed ? 'collapsed' : 'expanded')} style={style} onClick={this.props.toggleCollapse}>
                <div className="show">Show</div>
                <div style={{width:20}}></div>
                <div className="moreorless">{this.props.isCollapsed ? 'More' : 'Less'}</div>
            </div>
        );
    }
}
global.CollapseButton = CollapseButton;