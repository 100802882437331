import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const RHFTextField = ({ id, name, label, multiline = false, sx = {}, ...props }) => {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			id={id}
			render={({ field }) => (
				<TextField
					{...props}
					{...field}
					sx={{ ...sx }}
					label={label}
					multiline={multiline}
                    rows={multiline ? props.rows : 1}
					variant="standard"
				/>
			)}
		/>
	);
};

export { RHFTextField };
