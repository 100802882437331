export class QuoteDocumentPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {    
            isGenerating: false,
            magnifyEnabled: false, 
            coords: {x:0, y:0},
        }; 
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.updateMagnifierCoords = this.updateMagnifierCoords.bind(this);
        this.refreshPreview = this.refreshPreview.bind(this);
        this.quoteUpdated = this.quoteUpdated.bind(this);
        this.processServerMessage = this.processServerMessage.bind(this);
    }
    onMouseEnter(e) {
        if(this.state.isGenerating)
            return;

        this.setState({magnifyEnabled:true});
        this.updateMagnifierCoords(e);
        quosal.ui.showMask(1, true);
    }
    onMouseLeave(e) {
        this.setState({magnifyEnabled:false, coords:{x:0, y:0}});
        quosal.ui.hideMask(true);
    }
    onMouseMove(e) {
        if(this.state.isGenerating)
            return;

        this.updateMagnifierCoords(e);
    }
    updateMagnifierCoords(e) {
        var thumbSize = { width: 83, height: 112 };

        //if(this.refs.thumb0) {
        //    thumbSize = {
        //        width: this.refs.thumb0.clientWidth,
        //        height: this.refs.thumb0.clientHeight
        //    };
        //}
        this.setState({
            coords: {
                x: e.pageX,
                y: e.pageY - 98,
            },
            height: this.refs.container.clientHeight,
            thumbSize: thumbSize
        });
    }
    refreshPreview() {
        if(this.state.isGenerating)
            return;

        this.setState({isGenerating: true});
        var publishApi = quosal.api.quote.publish({idQuoteMain: app.currentQuote.IdQuoteMain, isPreview: true});
        publishApi.call();
    }

    processServerMessage(msg) {
        if (msg.action == 'DocumentGenerated') {
            app.currentQuote.Documents = msg.Documents;
            app.currentQuote.IsDocumentPreviewDirty = false;
            if(quosal.sell.product.regeneratePdf){
                quosal.sell.product.generatePdf(app.currentQuote.IdQuoteMain)
            }
            this.setState({isGenerating: false, failure: null});
        } else if (msg.action == 'DocumentGenerating') {
            this.setState({isGenerating: true, failure: null});
        } else if (msg.action == 'DocumentPreviewFailed') {
            app.currentQuote.IsDocumentPreviewDirty = true;
            this.setState({isGenerating: false, failure: msg.reason});
        }
    }

    quoteUpdated() {
        this.forceUpdate();
    }
    componentDidMount() {
        quosal.sell.quote.onUpdate.bind(this.quoteUpdated);
        quosal.api.onServerMessage.bind(this.processServerMessage);
    }
    componentWillUnmount() {
        quosal.sell.quote.onUpdate.unbind(this.quoteUpdated);
        quosal.api.onServerMessage.bind(this.processServerMessage);
    }
    render() {
        var thumbnails = [];
        var spinner = null;
        var linkProps = {};

        if(this.state.isGenerating) {
            thumbnails.push(
                <img key="noPreview" src="img/blank-document.png" title="Generating Preview..." />
            );
            spinner = <Spinner style={{marginTop:-65, marginLeft:30}} title="Generating Preview..." />;
            linkProps.className = 'noclick';
        } else if (app.currentQuote.Documents && app.currentQuote.Documents.PdfThumbnails
            && app.currentQuote.Documents.PdfThumbnails.length > 0) {
            for (var i = 0; i < app.currentQuote.Documents.PdfThumbnails.length; i++) {
                thumbnails.push(
                    <img key={'thumb' + i} ref={'thumb' + i} src={app.currentQuote.Documents.PdfThumbnails[i].DocumentUrl} />
                );
            }
        } else {
            thumbnails.push(
                <img key="noPreview" src="img/no-document.png" title="No preview available. Click the button above to generate preview." />
            );
            linkProps.className = 'noclick';
        }

        if (app.currentQuote.Documents && !this.state.isGenerating && app.currentQuote.Documents.PdfDocument) {
            linkProps.href = app.currentQuote.Documents.PdfDocument.DocumentUrl;
            linkProps.target = '_new';
        }

        var refreshImage = app.currentQuote.IsDocumentPreviewDirty ? 'img/svgs/v1.0/Action_Refresh.svg' : 'img/svgs/v1.0/Action_Refresh.svg';
        if(this.state.failure)
            refreshImage = 'img/svgs/v1.0/Action_Refresh.svg';
        else if(this.state.isGenerating)
            refreshImage = 'spinner';

        var tooltip;
        if(this.state.isGenerating)
            tooltip = 'Generating Preview...';
        else if(this.state.failure)
            tooltip = this.state.failure;
        else
            tooltip = 'Refresh Preview';

        var quoteWonViaDocuSign = this.props.quote.QuoteStatus == 'Won' && this.props.quote.DocusignEnvelopeId && this.props.quote.DeliverDocumentType == 'DOCUSIGN'
        
        return (
            <div>
                {!quoteWonViaDocuSign ? 
                <Panel id="refreshReview" style={{marginLeft:0, padding:'10px 10px 9px 0px'}}>
                    <PanelToolbar>
                        <PanelToolbarButton id="refreshButton" image={refreshImage} title={tooltip} onClick={this.refreshPreview} />
                    </PanelToolbar>
                </Panel> : null}

                <div ref="container" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onMouseMove={this.onMouseMove}>
                    <a {...linkProps}>
                        {thumbnails}
                        {spinner}
                        {this.state.magnifyEnabled ? <QuoteDocumentPreviewHighlighter previewer={this} {...this.props} coords={this.state.coords} maxHeight={this.state.height} thumbSize={this.state.thumbSize} /> : null}
                    </a>
                    {this.state.magnifyEnabled ? <QuoteDocumentMagnifier previewer={this} {...this.props} coords={this.state.coords} maxHeight={this.state.height} thumbSize={this.state.thumbSize} /> : null}
                </div>
            </div>
        );
    }
}

class QuoteDocumentPreviewHighlighter extends React.Component {
    render() {
        var height = this.props.thumbSize.height - 2;
        var top = this.props.coords.y - (height / 2);

        if(top < 0)
            top = 0;
        else if((top + height) > this.props.maxHeight)
            top = this.props.maxHeight - height;

        top += 42;

        return (
            <div className="document-preview-highlighter" style={{top:top, width: this.props.thumbSize.width - 2, height: height}}>
            </div>
        );
    }
}

class QuoteDocumentMagnifier extends React.Component {
    imageLoaded() {
        //this.refs.image.height
        //this.refs.image.naturalHeight
    }
    render() {
        var multiplier = 7;
        var height = this.props.thumbSize.height;
        var top = this.props.coords.y - (height / 2);

        if(top < 0)
            top = 0;
        else if((top + height) > this.props.maxHeight)
            top = this.props.maxHeight - height;

        var pct = top / (this.props.maxHeight - height);

        var margin = (top * -multiplier) - (((this.props.thumbSize.height * multiplier) / 16) * pct);

        var documentUrl = null;
        if(app.currentQuote.Documents && app.currentQuote.Documents.PdfPreview){
            documentUrl = app.currentQuote.Documents.PdfPreview.DocumentUrl;
        }
        
        return (
            <div className="document-magnifier" style={{maxWidth:this.props.thumbSize.width * multiplier, maxHeight: this.props.thumbSize.height * multiplier}}>
                <img ref="image" onLoad={this.imageLoaded} src={documentUrl} style={{marginTop:margin, width: this.props.thumbSize.width * multiplier}} />
            </div>
        );
    }
}