quosal.customization.fields.getBOPropertyOptionList = function (params){
    params = params || {};
    var boName = params.boName;
    var fields = params.fields || quosal.customization.fields['BusinessObject'][boName].allFields;
    var fieldConfigurations = params.fieldConfigurations || quosal.customization.fields['BusinessObject'][boName].fieldConfigurations;
    var fieldNameToLabel = params.fieldNameToLabel || quosal.customization.fields.getFieldNameToDropdownLabelDictionary(fields, fieldConfigurations);
    var optionList = params.initialOptions || [];
    var conditionToIncludeField = ('function' === typeof params.conditionToIncludeField) && params.conditionToIncludeField;
    //#10764442- this change was made to add pricebooks to the prefill mapping product search input options when Salesforce is the CRM
    if (quosal.settings.getValue('customerProvider') === 'SalesForce' && boName == 'QuoteItems' && !('PriceBook' in fieldNameToLabel)) {
        fields.push({ CanCustomizeDropdown: false, DataType: "String", DisplayName: "Pricebook", EnumType: "SalesforcePriceBook", FieldName: "PriceBook", IsEnum: true, IsPrivateField: false, IsStandardField: false });
        fieldNameToLabel.PriceBook = 'Pricebook';
    }

    fields.sort(quosal.customization.fields.sortByDisplayName.bind(null, fieldConfigurations));
    for (var i = 0; i < fields.length; i++) {
        var field = fields[i];
        if (conditionToIncludeField && !conditionToIncludeField(field)) {
            continue;
        }
        optionList.push(<option key={i} value={field.FieldName}>{fieldNameToLabel[field.FieldName]}</option>);
    }

    return optionList;
};

quosal.ui.getOptionsFromListOfString = function (params) {
    var optionList = params.initialOptions || [];
    if (params.options)
    {
        for (var i = 0; i < params.options.length; i++) {
            optionList.push(<option key={i} value={params.options[i]}>{params.options[i]}</option>);
        };
    }
    return optionList;
}

