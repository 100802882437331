import * as React from "react";
import { IngramQuoteImportSetupPrompt } from "js/jsx/src/classes/ingram/ingramQuoteImport.jsx";
import { ParserNav } from "js/jsx/src/controllers/cloud/parseEquote.jsx";
import BaseContextMenu from "./baseContextMenu.jsx";

function ImportContextMenu({ quosal, isEditable, contentGrid, gridToolbarStyle }) {
	const importActions = [];

	const selectedTab = () => {
		let selectedTab;
		app.currentQuote.Tabs.map((tab) => {
			if (tab.IdQuoteTabs == contentGrid.state.cKEditorSelectedTabId) {
				selectedTab = tab;
			}
		});

		return selectedTab;
	};

	const isADIEnabled = () => quosal.settings.getValue("UseADIParser");

	const isDellEnabled = () =>
		quosal.settings.getValue("useDellPasteQuote") ||
		quosal.settings.getValue("useDellPDFQuote");

	const tabLink = (href) => {
		const url = quosal.util.url(href, `idquotetabs=${selectedTab().IdQuoteTabs}`);
		quosal.navigation.navigate(url);
	};

	const openDellParser = () => {
		Dialog.open({
			height: "90%",
			width: "75%",
			title: "Import Dell Equote",
			message: <ParserNav currentTab={selectedTab().IdQuoteTabs} contentGrid={contentGrid} parser="Dell" />,
			messageProps: { style: { overflow: "visible", display: "flex" } },
			closeRequiresButton: true
		});
	};

	const openADIParser = () => {
		Dialog.open({
			height: "90%",
			width: "75%",
			title: "Import ADI Quote",
			message: <ParserNav currentTab={selectedTab().IdQuoteTabs} contentGrid={contentGrid} parser="ADI" />,
			messageProps: {
				style: { overflow: "visible", display: "flex", justifyContent: "center" }
			},
			closeRequiresButton: true
		});
	};

	const initActions = () => {
		if (quosal.validation.isPackageLevelAuthorized(app.packageLevels.standard) &&
			quosal.settings.getValue("EnableIngramMustangQuoteImport") &&
			isEditable
		) {
			const ingramMicroMustangQuoteImportClick = function () {
				const ingramAppId = quosal.settings.getValue("IngramMustangApplicationID");
				const ingramSecretKey = quosal.settings.getValue("IngramMustangSecretKey");
				if (ingramAppId && ingramSecretKey) {
					quosal.util.goToIngramQuoteSearch(selectedTab().IdQuoteTabs);
				} else if (app.currentUser.IsAdministrator) {
					Dialog.open({
						title: "Import Ingram Quote",
						message: <IngramQuoteImportSetupPrompt />
					});
				} else {
					Dialog.open({
						title: "Import Ingram Quote",
						message:
							"Please contact your administrator to set up your Ingram Authentication.",
						links: [Dialog.links.close]
					});
				}
			};
			importActions.push({
				id: 2,
				name: "Import Ingram Quote",
				callback: ingramMicroMustangQuoteImportClick
			});
		}
		if (isADIEnabled()) {
			importActions.push({ id: 3, name: "Import ADI Quote", callback: openADIParser });
		}
		if (isDellEnabled()) {
			importActions.push({ id: 4, name: "Import Dell Quote", callback: openDellParser });			
		}
		if (quosal.settings.getValue("useiQuote") && isEditable) {
			importActions.push({
				id: 5,
				name: "Import HP iQuote",
				callback: tabLink.bind(this, "importiquote.quosalweb")
			});
		}
		if (quosal.settings.getValue("UseTechDataQuotes") && isEditable) {
			importActions.push({
				id: 6,
				name: "Import TD Synnex Quote",
				callback: tabLink.bind(this, "importtdsynnexquote.quosalweb")
			});
		}
		if (quosal.settings.getValue("useAvalara") && isEditable) {
			importActions.push({
				id: 7,
				name: "Import AvaTax",
				callback: tabLink.bind(this, "avatax.quosalweb")
			});
		}
		if (quosal.settings.getValue('UseTechDataUKQuotes') && isEditable) {
            importActions.push({
                id: 8,
                name: "Import Tech Data Quote",
                callback: tabLink.bind(this, 'importtechdataquote.quosalweb')
            }); // should be disabled when {!isEditable}
        }

		const useGreatAmericaRest = quosal.settings.getValue("useGreatAmericaRestIntegration");

		if (quosal.settings.getValue("useGreatAmerica") && isEditable) {
			const gaDefaultId =
				contentGrid.props.quote.GreatAmericaDefault ||
				quosal.settings.getValue("greatAmericaDefaultId");

			if (!gaDefaultId || (quosal.settings.getValue("greatAmericaShowTwoButtons") && isEditable)) {
				if (!useGreatAmericaRest) {
					importActions.push({
						id: 9,
						name: "Import GreatAmerica",
						callback: tabLink.bind(this, "importgreatamerica.quosalweb")
					});
				}

				if (gaDefaultId && isEditable) {
					if (!useGreatAmericaRest) {
						importActions.push({
							id: 10,
							name: "Quick Import GreatAmerica Using Defaults",
							callback: tabLink.bind(
								this,
								`quickgreatamerica.quosalweb?idGreatAmericaDefaultSet=${gaDefaultId}`
							)
						});
					}
				}
			}
		}
	};

	initActions();

	return (
		<>
			{importActions && importActions.length ? (
				<span style={gridToolbarStyle}>
					<BaseContextMenu title="Import" quosal={quosal} actions={importActions} />
				</span>
			) : null}
		</>
	);
}

export default ImportContextMenu;
