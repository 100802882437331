/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { Box, Grid } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";

import { useAppDataContext } from "../globalStates/appDataProvider";
import { StyledTab, StyledTabList } from "../styledComponents/productDetails";
import PageBreadcrumbs from "../commonComponents/pageBreadcrumbs";
import ProductsDataGrid from "../commonComponents/productsDataGrid";
import BasicOverview from "./basicOverview";
import DetailedAttribute from "./detailedAttribute";

function ProductDetailsTabs() {
	const {
		productDetails,
		getUpSellProducts,
		upSellProducts,
		productDetailsTab,
		setProductDetailsTab,
		isLoadingUpSellProducts,
		deleteAllUpSellProducts,
		abortRequestSafe,
		setInitialGridState,
		setGridState,
		upSellProductsWithPricing,
		setUpSellProductsWithPricing,
		upSellProductsToFilter
	} = useAppDataContext();

	const apiRef = useGridApiRef();

	const handleChange = (event, newValue) => {
		setProductDetailsTab(newValue);

		if (newValue !== "3" && apiRef.current !== null && upSellProducts.length) {
			setGridState("upSellCrossGrid", apiRef.current.exportState());
		}
	};

	useEffect(() => {
		getUpSellProducts({ masterProductId: productDetails.masterProductId });
		return () => {
			abortRequestSafe(productDetails.masterProductId, "ANY_REASON");
			deleteAllUpSellProducts();
			setInitialGridState("upSellCrossGrid");
		};
	}, [productDetails.id]);

	return (
		<Box sx={{ width: "100%" }}>
			<TabContext value={productDetailsTab}>
				<Grid container justifyContent="space-between">
					<Grid item xs>
						<StyledTabList onChange={handleChange} aria-label="product details">
							<StyledTab label="Basic Overview" value="1" />
							{productDetails.description && (
								<StyledTab label="Detailed Attribute" value="2" />
							)}
							{productDetails.masterProductId !== null && (
								<StyledTab
									label="Up-Sell/Cross-Sell"
									disabled={!upSellProductsToFilter?.length}
									value="3"
								/>
							)}
						</StyledTabList>
					</Grid>
					<Grid item xs="auto" alignSelf="center">
						<PageBreadcrumbs />
					</Grid>
				</Grid>
				<TabPanel value="1">
					<BasicOverview />
				</TabPanel>
				<TabPanel value="2">
					<DetailedAttribute />
				</TabPanel>
				<TabPanel value="3">
					<ProductsDataGrid
						apiRef={apiRef}
						productsList={upSellProducts}
						isLoading={isLoadingUpSellProducts}
						productsType="upSellCrossGrid"
						upperElementsHeight={500}
						itemsWithPrices={upSellProductsWithPricing}
						handleChangeItemsWithPrices={setUpSellProductsWithPricing}
						productsToFilter={upSellProductsToFilter}
					/>
				</TabPanel>
			</TabContext>
		</Box>
	);
}

export default ProductDetailsTabs;
