import * as React from "react";
import { NewPackageDetails } from "js/jsx/src/classes/product/packages";
import {
	unpackage,
	addToPackage,
	removeFromPackage,
	getCurrentItems,
	getPackageSelector,
	getSelectedGridRowsIndexes
} from "./PackageHelper";

export const defineDisabledActions = (selectedRows, tabId) => {
	const disabledActions = {
		hasInvoiceGroupHeader: false,
		hasBundleHeader: false,
		hasInvoiceGroupItem: false,
		hasBundleItem: false,
		hasLineItem: false,
		hasBundle: false,
		hasInvoiceGroup: false
	};

	const currentItems = getCurrentItems(tabId);
	const selectedGridRowsIndexes = getSelectedGridRowsIndexes(tabId, selectedRows);

	if (selectedRows.length > 0) {
		for (let i = 0; i < selectedGridRowsIndexes.length; i++) {
			const row = currentItems[selectedGridRowsIndexes[i]];
			if (row.IsPackageHeader) {
				if (row.InvoiceGroupingRecid) {
					disabledActions.hasInvoiceGroupHeader = true;
				} else {
					disabledActions.hasBundleHeader = true;
				}
			} else if (row.IsPackageItem) {
				if (row.InvoiceGroupingRecid) {
					disabledActions.hasInvoiceGroupItem = true;
				} else {
					disabledActions.hasBundleItem = true;
				}
			} else {
				disabledActions.hasLineItem = true;
			}
		}

		if (
			currentItems.some((item) => item.IsPackageHeader && !item.InvoiceGroupingRecid) &&
			disabledActions.hasLineItem
		) {
			disabledActions.hasBundle = true;
		}

		if (
			currentItems.some((item) => item.IsPackageHeader && item.InvoiceGroupingRecid) &&
			disabledActions.hasLineItem
		) {
			disabledActions.hasInvoiceGroup = true;
		}
	} else {
		disabledActions.nothingSelected = true;
	}

	return disabledActions;
};

export const createBundle = (
	contentGrid,
	tabId,
	selectedRows,
	setSelectedRows,
	ckeditor,
	ids,
	setChange
) => {
	const currentItems = getCurrentItems(tabId);

	if (selectedRows.length < 1) {
		Dialog.open({
			title: "No items selected",
			message: "You must select one or more items for this operation.",
			links: [Dialog.links.ok]
		});
		return;
	}

	const createPackage = function () {
		let error = false;

		const { packagePartNumber } = contentGrid.newPackageDetails;
		const { packageDescription } = contentGrid.newPackageDetails;
		const { packageQuantity } = contentGrid.newPackageDetails;

		$.quosal.validation.clearField($("#PackagePartNumber"));
		$.quosal.validation.clearField($("#PackageDescription"));
		$.quosal.validation.clearField($("#PackageQuantity"));

		if (packagePartNumber === "") {
			error = true;
			$.quosal.validation.validateField(
				$("#PackagePartNumber"),
				"error",
				"Part Number is required"
			);
		}
		if (packageDescription === "") {
			error = true;
			$.quosal.validation.validateField(
				$("#PackageDescription"),
				"error",
				"Description is required"
			);
		}
		if (isNaN(parseFloat(packageQuantity))) {
			error = true;
			$.quosal.validation.validateField(
				$("#PackageQuantity"),
				"error",
				"Quantity must be a valid number"
			);
		}

		if (!error) {
			const detailIds = [];
			const selectedGridRowsIndexes = [];
			for (let k = 0; k < currentItems.length; k++) {
				for (let n = 0; n < selectedRows.length; n++) {
					if (currentItems[k].IdQuoteItems === selectedRows[n]) {
						selectedGridRowsIndexes.push(k);
					}
				}
			}
			// determine where header should be inserted (smallest index)
			let insert = null;
			for (let m = 0; m < selectedGridRowsIndexes.length; m++) {
				if (insert === null || selectedGridRowsIndexes[m] < insert)
					insert = selectedGridRowsIndexes[m];
			}

			for (let j = 0; j < selectedRows.length; j++) {
				const row = selectedRows[j];
				detailIds.push(row);
			}

			const createPackageApi = quosal.api.product.createPackage(
				packagePartNumber,
				packageDescription,
				packageQuantity,
				app.currentQuote.IdQuoteMain,
				tabId,
				insert,
				detailIds
			);
			createPackageApi.finished = function (msg) {
				if (msg.quote) {
					quosal.sell.quote.update(msg.quote);
					setChange();
					setTimeout(() => {
						if (ids !== null && ids.includes(tabId)) {
							ckeditor?.execute && ckeditor.execute("updateProduct", tabId);
						}
					}, 50);
				}

				setSelectedRows([]);
			};
			Dialog.setIsWorking(true);
			createPackageApi.call();

			contentGrid.newPackageDetails = null;

			Dialog.close();
		}

		return error;
	};

	contentGrid.newPackageDetails = null;

	const packageSelector = <NewPackageDetails valueConsumer={contentGrid} />;
	Dialog.open({
		title: "Bundle Header Details",
		message: packageSelector,
		links: [{ title: "OK", callback: createPackage }, Dialog.links.cancel]
	});
};

const overridePackage = (headerId, overridePackageId, tabId, ckeditor) => {
	const confirmOverridePackage = function () {
		const overridePackageApi = quosal.api.product.overridePackage(
			app.currentQuote.IdQuoteMain,
			headerId,
			overridePackageId
		);
		overridePackageApi.finished = function (msg) {
			Dialog.closeAll();
			setTimeout(() => {
				if (ids !== null && ids.includes(tabId)) {
					ckeditor?.execute && ckeditor.execute("updateProduct", tabId);
				}
			}, 50);
		};

		overridePackageApi.call();
	};

	Dialog.open({
		title: "Override Bundle",
		message:
			"You are about to overwrite a previously saved Bundle. You can prevent this by changing the Manufacturer Part Number of the Bundle header. Do you want to continue?",
		links: [
			{ title: "Yes, Update", callback: confirmOverridePackage },
			{ title: "Cancel", callback: Dialog.closeAll }
		]
	});
};

export const saveBundle = (contentGrid, tabId, ckeditor, ids, setSelectedRows) => {
	const packageSelector = getPackageSelector(contentGrid);
	const confirmPackage = function () {
		Dialog.setIsWorking();
		const headerId = contentGrid.packageHeaderSelection;
		const savePackageApi = quosal.api.product.savePackage(
			app.currentQuote.IdQuoteMain,
			headerId
		);
		savePackageApi.finished = function (msg) {
			if (!String.isNullOrEmpty(msg.duplicateMpfPackageError)) {
				Dialog.setIsWorking(false);

				const overridePackageId = JSON.parse(
					msg.duplicateMpfPackageError
				).duplicateMpfItemConfigurationId;
				overridePackage(headerId, overridePackageId, tabId, ckeditor);
			} else {
				Dialog.close();
			}

			setTimeout(() => {
				if (ids !== null && ids.includes(tabId)) {
					ckeditor?.execute && ckeditor.execute("updateProduct", tabId);
				}
			}, 50);

			setSelectedRows([]);
		};

		savePackageApi.call();
		contentGrid.packageHeaderSelection = null;
	};

	contentGrid.packageHeaderSelection = null;
	Dialog.open({
		title: "Select a Bundle to Save",
		message: packageSelector,
		links: [{ title: "OK", callback: confirmPackage }, Dialog.links.cancel]
	});
};

export const unBundle = (
	contentGrid,
	tabId,
	selectedRows,
	setSelectedRows,
	ckeditor,
	ids,
	setChange
) => {
	unpackage(contentGrid, tabId, selectedRows, setSelectedRows, false, ckeditor, ids, setChange);
};

export const addToBundle = (contentGrid, tabId, selectedRows, setSelectedRows, ckeditor, ids) => {
	addToPackage(contentGrid, tabId, selectedRows, setSelectedRows, false, ckeditor, ids);
};

export const removeFromBundle = (
	contentGrid,
	tabId,
	selectedRows,
	setSelectedRows,
	ckeditor,
	ids
) => {
	removeFromPackage(contentGrid, tabId, selectedRows, setSelectedRows, false, ckeditor, ids);
};

const getUsedInvoiceGroups = () => {
	const options = [];
	for (let i = 0; i < app.currentQuote.Items.length; i++) {
		if (
			app.currentQuote.Items[i].IsPackageHeader &&
			app.currentQuote.Items[i].InvoiceGroupingRecid
		) {
			options.push(app.currentQuote.Items[i].ManufacturerPartNumber);
		}
	}
	return options;
};

export const createInvoiceGroup = (
	contentGrid,
	tabId,
	selectedRows,
	setSelectedRows,
	ckeditor,
	ids,
	setChange
) => {
	const detailIds = [];
	const numberOfSelectedShowingRows = selectedRows.length;
	if (numberOfSelectedShowingRows < 1) {
		Dialog.open({
			title: "No items selected",
			message: "You must select one or more items for this operation.",
			links: [Dialog.links.ok]
		});
		return;
	}

	const currentItems = getCurrentItems(tabId);

	const selectedGridRowsIndexes = [];
	for (let k = 0; k < currentItems.length; k++) {
		for (let n = 0; n < selectedRows.length; n++) {
			if (currentItems[k].IdQuoteItems === selectedRows[n]) {
				selectedGridRowsIndexes.push(k);
			}
		}
	}

	for (let i = 0; i < selectedGridRowsIndexes.length; i++) {
		const row = currentItems[selectedGridRowsIndexes[i]];
		if (row.typeName != null) {
			Dialog.open({
				title: "Invalid Invoice Group Item",
				message:
					"Invoice Groups may only include standard line items, and may not contain other Bundle items or Invoice Group items.",
				links: [Dialog.links.ok]
			});
			return;
		}

		detailIds.push(row.IdQuoteItems);
	}

	const cwGetInvoiceGroups = quosal.api.crm.connectwise.getInvoiceGroups();
	const usedInvoiceGroups = getUsedInvoiceGroups();
	cwGetInvoiceGroups.finished = function (msg) {
		const { invoiceGroupings } = msg.data;
		const dropDownItems = [];
		let selectedOption = true;
		let usedInvoiceGroupMessage = "";
		const usedInvoiceGroupElements = [];
		let modalHeight = 150;
		if (invoiceGroupings) {
			for (let i = 0; i < invoiceGroupings.length; i++) {
				const groupingInfo = JSON.parse(invoiceGroupings[i]);
				if (!usedInvoiceGroups.includes(groupingInfo.Name)) {
					dropDownItems.push(
						<option
							key={`option${i}`}
							value={JSON.stringify({
								groupingId: groupingInfo.Id,
								groupingName: groupingInfo.Name,
								groupingCustomerDescription: groupingInfo.CustomerDescription
							})}
						>
							{groupingInfo.Name}
						</option>
					);

					if (selectedOption === true) {
						contentGrid.setState({
							selectedInvoiceGroupId: groupingInfo.Id,
							selectedInvoiceGroupName: groupingInfo.Name,
							selectedInvoiceGroupCustomerDescription:
								groupingInfo.CustomerDescription
						});
						selectedOption = false;
					}
				}
			}

			if (usedInvoiceGroups.length > 0) {
				usedInvoiceGroupMessage = "Invoice Groups In Use:";
				for (let j = 0; j < usedInvoiceGroups.length; j++) {
					usedInvoiceGroupElements.push(<div>{usedInvoiceGroups[j]}</div>);
					modalHeight += 14;
				}
			}

			if (dropDownItems.length < 1) {
				Dialog.open({
					title: "Create Invoice Group",
					message: (
						<div
							style={{
								display: "inline-block",
								verticalAlign: "text-top",
								width: "275px",
								height: "50px"
							}}
						>
							All Invoice Groups are in use.
						</div>
					),
					closeRequiresButton: false,
					links: [
						{
							title: "OK",
							callback: Dialog.close
						}
					]
				});
			} else {
				Dialog.open({
					title: "Create Invoice Group",
					message: (
						<div
							style={{
								display: "inline-block",
								verticalAlign: "text-top",
								width: "275px",
								height: "50px"
							}}
						>
							Select from a list of Manage groups to create a new invoice group.
							<div className="formselectfieldwrapper">
								<select
									className="formselectfield"
									id="invoiceGroupingDropDown"
									title="Invoice Groupings"
									onChange={contentGrid.invoiceGroupChanged}
								>
									{dropDownItems}
								</select>
							</div>
							<div id="invoicegroupsinusecontainer">
								<h4>{usedInvoiceGroupMessage}</h4>
								<div>{usedInvoiceGroupElements}</div>
							</div>
						</div>
					),
					messageProps: { style: { overflow: "visible", display: "flex" } },
					height: `${modalHeight.toString()}px`,
					width: "300px",
					closeRequiresButton: false,
					links: [
						{
							title: "Save",
							callback: () =>
								addProductsToNewInvoiceGroup(
									contentGrid,
									detailIds,
									app.currentQuote.IdQuoteMain,
									tabId,
									selectedGridRowsIndexes,
									setSelectedRows,
									ckeditor,
									setChange
								)
						},
						Dialog.links.cancel
					]
				});
			}
		} else {
			Dialog.open({
				title: "No Invoice Groups Found",
				message:
					"No invoice groups were found in ConnectWise Manage. Please add invoice groups in ConnectWise Manage, and try again.",
				links: [Dialog.links.ok]
			});
		}
	};

	cwGetInvoiceGroups.call();
};

const addProductsToNewInvoiceGroup = (
	contentGrid,
	idQuoteItems,
	idQuoteMain,
	tabId,
	selectedRows,
	setSelectedRows,
	ckeditor,
	setChange
) => {
	let sortOrder = null;
	for (let i = 0; i < selectedRows.length; i++) {
		if (sortOrder === null || selectedRows[i] < sortOrder) sortOrder = selectedRows[i];
	}

	const createInvoiceGroupApi = quosal.api.product.createInvoiceGroup(
		contentGrid.state.selectedInvoiceGroupName,
		contentGrid.state.selectedInvoiceGroupId,
		contentGrid.state.selectedInvoiceGroupCustomerDescription,
		idQuoteMain,
		tabId,
		idQuoteItems,
		sortOrder
	);

	createInvoiceGroupApi.finished = function (msg) {
		if (msg.quote) {
			quosal.sell.quote.update(msg.quote);
			setChange();
		} else {
			setChange();
		}

		setSelectedRows([]);

		setTimeout(() => {
			if (ids !== null && ids.includes(tabId)) {
				ckeditor?.execute && ckeditor.execute("updateProduct", tabId);
			}
		}, 50);
	};

	createInvoiceGroupApi.call();
	Dialog.close();
};

export const ungroupInvoiceGroup = (
	contentGrid,
	tabId,
	selectedRows,
	setSelectedRows,
	ckeditor,
	ids,
	setChange
) => {
	unpackage(contentGrid, tabId, selectedRows, setSelectedRows, true, ckeditor, ids, setChange);
};

export const addToInvoiceGroup = (
	contentGrid,
	tabId,
	selectedRows,
	setSelectedRows,
	ckeditor,
	ids
) => {
	addToPackage(contentGrid, tabId, selectedRows, setSelectedRows, true, ckeditor, ids);
};

export const removeFromInvoiceGroup = (
	contentGrid,
	tabId,
	selectedRows,
	setSelectedRows,
	ckeditor,
	ids
) => {
	removeFromPackage(contentGrid, tabId, selectedRows, setSelectedRows, true, ckeditor, ids);
};
