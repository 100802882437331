import { Card, Divider, Grid } from "@mui/material";
import { ComboBox } from "../quoteHeader/common/components";
import { Header } from "./Header";
import { FormControlLabelCheckbox } from "./Common/FormControlLabelCheckbox";
import { LEGACY_THEMES, ORDER_PORTER_THEME_OPTIONS, PS } from "./constant";
import { RHFTextField } from "./Common/RHFTextField";
import { StyledOption } from "../quoteHeader/common/styledComponents";

export const PublishSettingsPage = ({ opTemplateOptions, defaultWinDocOptions, isPremiumPartner, isStandardPartner }) => {
	const columnContainerStyle = {};
	const itemStyles = {
		py: "5px",
		width: "80%"
	};

	const getOpStyleOptionName = (style) => {
		if (LEGACY_THEMES.includes(style)) {
			return `${style} (Legacy)`;
		}
		return style;
	};

	return (
		<>
			<Card sx={{ mx: "5px", my: "5px" }}>
				<Header title="Publish Settings" />
				<Divider component="li" />
				<Grid container spacing={2} sx={{ py: "10px", px: "30px" }}>
					{/* FIRST COLUMN */}
					<Grid item md={2} sm={5} container direction="column" sx={columnContainerStyle}>
						<FormControlLabelCheckbox
							label="Print Bundle Header Line"
							name={PS.PACAKGE_SHOW_HEADER}
							id={PS.PACAKGE_SHOW_HEADER}
						/>
						<FormControlLabelCheckbox
							label="Print Bundle Item Lines"
							name={PS.PACAKGE_SHOW_ITEMS}
							id={PS.PACAKGE_SHOW_ITEMS}
						/>
						<FormControlLabelCheckbox
							label="Disable New Editor Output"
							name={PS.USE_STANDARD_PUBLISH_OUTPUT}
							id={PS.USE_STANDARD_PUBLISH_OUTPUT}
						/>
					</Grid>

					{/* SECOND COLUMN */}
					<Grid item md={2} sm={5} container direction="column" sx={columnContainerStyle}>
						<FormControlLabelCheckbox
							label="Print Bundle Header Price"
							name={PS.PACKAGE_PRINT_HEADER_PRICE}
							id={PS.PACKAGE_PRINT_HEADER_PRICE}
						/>
						<FormControlLabelCheckbox
							label="Print Bundle Item Prices"
							name={PS.PACKAGE_PRINT_ITEM_PRICE}
							id={PS.PACKAGE_PRINT_ITEM_PRICE}
						/>
					</Grid>

					{/* THIRD COLUMN */}
					<Grid item md={2} sm={5} container direction="column" sx={columnContainerStyle}>
						<RHFTextField
							sx={itemStyles}
							label="Order Porter Group"
							name={PS.ORDER_PORTER_GROUP}
							id={PS.ORDER_PORTER_GROUP}
							InputLabelProps={{ shrink: true }}
						/>

						<ComboBox
							id={PS.ORDER_PORTER_TEMPLATE}
							name={PS.ORDER_PORTER_TEMPLATE}
							label="Order Porter Template"
							options={opTemplateOptions}
							renderOption={(props, option) => (
								<StyledOption {...props}>{option}</StyledOption>
							)}
							disableClearable={true}
							sx={{ ...itemStyles, marginBottom: "10px" }}
						/>
						<ComboBox
							id={PS.ORDER_PORTER_THEME}
							name={PS.ORDER_PORTER_THEME}
							label={<>Order Porter Style <br/> (For New ConnectWise CPQ Templates)</>}
							disableClearable={true}
							options={ORDER_PORTER_THEME_OPTIONS}
							renderOption={(props, option) => (
								<StyledOption {...props}>
									{getOpStyleOptionName(option)}
								</StyledOption>
							)}
							sx={{ ...itemStyles, marginBottom: "10px",
                                ".MuiInputBase-root": {
                                    paddingTop: "18px"
                                }
                             }}
						/>
					</Grid>

					{/* FOURTH COLUMN */}
					<Grid item md={2} sm={5} container direction="column" sx={columnContainerStyle}>
						<ComboBox
							id={PS.WIN_FORM}
							name={PS.WIN_FORM}
							label="Default WIN! Doc"
							options={defaultWinDocOptions}
							renderOption={(props, option) => (
								<StyledOption {...props}>{option}</StyledOption>
							)}
							disableClearable={true}
							sx={{ ...itemStyles, marginBottom: "10px" }}
						/>
						{isPremiumPartner && (
							<ComboBox
								id={PS.APPROVAL_QUOTE_FORM}
								name={PS.APPROVAL_QUOTE_FORM}
								label="Approval Quote Form"
								options={defaultWinDocOptions}
								renderOption={(props, option) => (
									<StyledOption {...props}>{option}</StyledOption>
								)}
								disableClearable={true}
								sx={{ ...itemStyles, marginBottom: "10px" }}
							/>
						)}
						{(isStandardPartner || isPremiumPartner) && (
							<ComboBox
								id={PS.PEER_REVIEW_DOCUMENT}
								name={PS.PEER_REVIEW_DOCUMENT}
								label="Peer Review Quote Form"
								options={defaultWinDocOptions}
								renderOption={(props, option) => (
									<StyledOption {...props}>{option}</StyledOption>
								)}
								disableClearable={true}
								sx={{ ...itemStyles, marginBottom: "10px" }}
							/>
						)}
					</Grid>
				</Grid>
			</Card>
		</>
	);
};
