import {EditCustomField} from "js/jsx/src/classes/editCustomFields.jsx";

class DropdownCustomizerButton extends React.Component {
    render() {
        var customDropdown = this.props.fieldConfig && this.props.fieldConfig.CustomDropdownContents;
        var className = 'customizerButton' + (customDropdown ? ' active' : '');
        var openAuditWidget = EditCustomField.open.bind(this, this.props.objectType, this.props.objectName, this.props.field, this.props.onUpdate);
        return (
            <span className={className} onClick={openAuditWidget} >Dropdown</span>
        );
    }
}

export class DropdownCustomizer extends React.Component {    
    constructor(props) {
        super(props);

        this.clearDropDownOptions = this.clearDropDownOptions.bind(this);
        this.alphabetizeOptions = this.alphabetizeOptions.bind(this);
        var customDropdown = this.props.fieldConfig && this.props.fieldConfig.CustomDropdownContents || [];
        customDropdown = quosal.util.clone(customDropdown, 1);

        if (!customDropdown[0]) {
            customDropdown[0] = this.getBlankDropdownOption();
        }
        for (var i = 0; i < customDropdown.length; i++) {
            if (!customDropdown[i]) {
                customDropdown[i] = this.getBlankDropdownOption();
            }
            if (customDropdown[i].Label === customDropdown[i].Value) {
                customDropdown[i].Label = '';
            }
        }
        DropdownCustomizer.customDropdown = customDropdown;

        this.state = {
            customDropdown: customDropdown,
            labelBeingEdited: null,
        };        
    }
    getBlankDropdownOption() {
        return { Value: '', Label: '' };
    }
    alphabetizeOptions(){
        function compare(a,b) {
            a = a.Label ? a.Label : a.Value;
            b = b.Label ? b.Label : b.Value;
            if ( a < b)
              return -1;
            if (a > b)
              return 1;
            return 0;
          }
          
          this.state.customDropdown.sort(compare);
          this.forceUpdate();
    }
    clearDropDownOptions(){
            this.setState({customDropdown:[]});
            DropdownCustomizer.customDropdown = '[EMPTY]'
    }
    hideWidget(){
        $('#dropdowncustomizer').hide();
    }
    componentDidMount() {
        if (this.props.componentDidMount) {
            this.props.componentDidMount.call(this);
        }
    }
    componentWillUnmount() {
        if (this.props.componentWillUnmount) {
            this.props.componentWillUnmount.call(this);
        }
        DropdownCustomizer.customDropdown = [];
    }
    render() {
        var inputs = [];
        inputs.push(<div key={'-1'} ><span style={{width: '130px', display:'inline-block'}}>Option value</span><span style={{width: '130px', display:'inline-block'}}>Option label</span></div>);
        var optionCount = this.state.customDropdown.length || 1; //Start out with two inputs so that the user can leave the first one blank if desired.
        for (var i = 0; i <= optionCount; i++){
            var option = this.state.customDropdown[i];
            var onValueChange = function (index, e) {
                var customDropdown = this.state.customDropdown;
                if (!customDropdown[0]) {
                    customDropdown[0] = this.getBlankDropdownOption()
                }

                if (!customDropdown[index]) {
                    customDropdown[index] = this.getBlankDropdownOption()
                }
                customDropdown[index].Value = e.target.value;

                for (var j = customDropdown.length - 1; j >= 1; j--) {
                    var value = customDropdown[j].Value;
                    if (value.trim() == '') {
                        customDropdown.length--;
                    } else {
                        break; //Only delete trailing empty options. Once we find a non-empty one, break.
                    }
                }

                this.setState({customDropdown: customDropdown});
                DropdownCustomizer.customDropdown = customDropdown;
            }.bind(this, i);

            var value = option ? option.Value : '';
            var valueInput = null;
            var valueInputExtraProps = {};
            var labelInput = null;
            var removeInput = null;
            if (i < optionCount) {
                var style = {width: "124px"};

                var label = '';
                if (option) {
                    if ((this.state.labelBeingEdited === i) || option.Label) {
                        label = option.Label;
                    } else {
                        label = option.Value;
                        style.color = 'gray';
                    }
                }
                var onLabelFocus = function (index) { this.setState({labelBeingEdited: index}) }.bind(this, i);
                var onLabelBlur = function () { this.setState({labelBeingEdited: null}) }.bind(this);
                var onLabelChange = function (index, e) {
                    var customDropdown = this.state.customDropdown;
                    if (!customDropdown[index]) {
                        customDropdown[index] = this.getBlankDropdownOption();
                    }
                    customDropdown[index].Label = e.target.value;

                    this.setState({customDropdown: customDropdown});
                    DropdownCustomizer.customDropdown = customDropdown;
                }.bind(this, i);
                labelInput = <input ref={'label' + i} value={label} onChange={onLabelChange} placeholder={value} onFocus={onLabelFocus} onBlur={onLabelBlur} style={style}/>;
                
                var onRemoveClick = function (index, e){
                    var customDropdown = this.state.customDropdown;
                    customDropdown.splice(index, 1);
                    this.setState({customDropdown: customDropdown});
                    DropdownCustomizer.customDropdown = customDropdown;
                }.bind(this, i)
                removeInput = <img src="img/svgs/sell/Action_Clear.svg" onClick={onRemoveClick} style={{width: '18px', height: '18px', cursor: 'pointer', marginBottom: '-5px'}}/>
                if (i === 0) {
                    valueInputExtraProps.placeholder = 'First (default) option';
                    if (this.state.customDropdown && (this.state.customDropdown.length == 0 || (this.state.customDropdown.length > 0 && DropdownCustomizer.customDropdown && DropdownCustomizer.customDropdown[0] && DropdownCustomizer.customDropdown[0].Value && DropdownCustomizer.customDropdown[0].Value === '' && DropdownCustomizer.customDropdown[0].Label === ''))){
                        removeInput = null;
                    }
                }
            } else {
                valueInputExtraProps.placeholder = 'Additional options...';
            }
            valueInput = <input ref={'value' + i} value={value} onChange={onValueChange} {...valueInputExtraProps} style={{width:"124px"}} />;
            inputs.push(<div key={i}>{valueInput}{labelInput}{removeInput}</div>);
        }

        return (
            <Panel title="Customize dropdown" style={{minWidth:"300px"}}>
                <PanelContent>
                    <div>{inputs}</div>
                    <button style={{margin:"15px 5px 0 0"}} onClick={this.hideWidget}>Minimize</button>
                    <button style={{margin:"15px 5px 0 0"}} onClick={this.clearDropDownOptions}>Clear</button>
                    <button style={{margin:"15px 5px 0 0"}} onClick={this.alphabetizeOptions}>Alphabetize</button>
                </PanelContent>
            </Panel>
        );
    }
}

export class CustomizerFieldListItem extends React.Component {
    constructor(props) {
        super(props);

        this.getDisplayNameBeforeOverride = this.getDisplayNameBeforeOverride.bind(this);
        this.getDisplayName = this.getDisplayName.bind(this);
    }
    getDisplayNameBeforeOverride () {
        var fieldConfig = this.props.fieldConfig;
        var field = this.props.field;
        return (fieldConfig && fieldConfig.FieldRename) || field.DisplayName;
    }
    getDisplayName () {
        return this.props.overrideRename || this.getDisplayNameBeforeOverride();
    }
    render() {
        var objectName = this.props.objectName;
        var objectType = this.props.objectType;
        var isDisabled = this.props.isDisabled;
        var className = this.props.className;
        var onChange = this.props.onChange;
        var fieldSelected = this.props.fieldSelected;
        var field = this.props.field;
        var fieldConfig = this.props.fieldConfig;

        var displayName = this.getDisplayName();
        var renamed = displayName != field.DisplayName;
        var id = 'FIELD' + field.FieldName;
        var canCustomizeDropdown = (this.props.canCustomizeDropdown !== false) &&
            (app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer) && field.CanCustomizeDropdown;
        var customDropdown = !canCustomizeDropdown ? null :
            <DropdownCustomizerButton objectName={objectName} objectType={objectType} fieldConfig={fieldConfig} fieldName={field.FieldName}
                                      field={field} displayName={field.DisplayName} onUpdate={this.props.onDropdownUpdate} />;

        return (
            <div key={'FIELD_' + field.FieldName} className={'wrapfield ' + className}>
                <input className="itemfield" disabled={isDisabled} type="checkbox" name="ItemField" value={field.FieldName} id={id} onChange={onChange} checked={fieldSelected} />
                <label htmlFor={id} className={'fieldname ' + (renamed ? 'renamed' : '')}>{displayName}</label>
                {renamed ? <label htmlFor={id} className="originalfieldname">({field.FieldName})</label> : null}
                {customDropdown}
            </div>
        );
    }
}
