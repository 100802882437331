export class ProductPriceHistoryPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            historyItems: [], 
            noMoreResults: false,
            pageSize: 5,
            currentAccountOnly: false,
            wonQuotesOnly: false
        };

        this.handleCurrentAccountOnlyChange = this.handleCurrentAccountOnlyChange.bind(this);
        this.handleWonQuotesOnlyChange = this.handleWonQuotesOnlyChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.resetSearch = this.resetSearch.bind(this);
        this.getResults = this.getResults.bind(this);   
    }
    handleCurrentAccountOnlyChange(e) {
        this.setState({currentAccountOnly: e.target.checked})
        this.resetSearch();
    }
    handleWonQuotesOnlyChange(e) {
        this.setState({wonQuotesOnly: e.target.checked})
        this.resetSearch();
    }
    handlePageSizeChange(e) {
        this.setState({pageSize: e.target.value})
       this.forceUpdate();
    }
    resetSearch() {
        this.setState({
            resetSearch: true,
            noMoreResults: false
        });
        this.forceUpdate();
    }
    getResults() {
        // Fields on a historyItem:
        // url              string
        // customerMatch    bool
        // customer         string
        // quoteName        string
        // quoteReadableId  number
        // version          number
        // source           string
        // cost             string
        // price            string
        // rawPrice         number
        // count            string
        // canLink          bool

        var count = this.state.resetSearch ? 0 : this.state.historyItems.length,
            idQuoteMain = this.props.product.IdQuoteMain,
            mpn = this.props.product.ManufacturerPartNumber;

        var priceHistoryApi = quosal.api.product.priceHistory(idQuoteMain, mpn, count, this.state.pageSize, this.state.currentAccountOnly, this.state.wonQuotesOnly);
        priceHistoryApi.finished = function(msg) {
            this.setState({
                historyItems: this.state.resetSearch ? msg.historyItems : this.state.historyItems.concat(msg.historyItems),
                noMoreResults: msg.noMoreResults,
                lastSearchWasCurrentAccountOnly: this.state.currentAccountOnly,
                isWorking: false,
                resetSearch: false
            });
        }.bind(this);
        this.setState({isWorking: true});
        priceHistoryApi.call();
    }
    render() {
       var mpn = this.props.product.ManufacturerPartNumber;

        var historyListItems = [];
        for (var i = 0; i < this.state.historyItems.length; i++) {
            historyListItems[i] = <ProductPriceHistoryListItem key={i} count={i} historyItem={this.state.historyItems[i]} priceClickCallback={this.props.priceClickCallback}
                                        hoverTitlePrice={this.props.hoverTitlePrice} hoverTitleQuote={this.props.hoverTitleQuote} />;
        }

        var buttonText;
        if (this.state.noMoreResults) {
            buttonText = 'No more results';
        } else if (this.state.historyItems.length === 0 || this.state.resetSearch) {
            buttonText = 'Search';
        } else {
            buttonText = 'Get next ' + this.state.pageSize + ' results';
        }

        var className = 'panel';
        if (this.state.lastSearchWasCurrentAccountOnly) {
            className += ' qhop_currentAccountOnly';
        }

        return (
            <div id="quoteHistoryOfProducts" className={className} >
                <link type="text/css" href="css/quoteHistoryOfProducts.css" rel="stylesheet" />
                <div className="title">Product Price History</div>
                <div className="content">
                    <label className="formfieldlabel">Manufacturer Part Number</label>
                    <div className="formfield">
                        <input type="text" className="mpnDisplay" value={mpn} readOnly/>
                    </div>
                    <div className="formcheckboxwrapper">
                        <input id="qhop_currentAccountOnly" value={this.state.currentAccountOnly} onChange={this.handleCurrentAccountOnlyChange} type="checkbox" disabled={this.state.isWorking}/>
                        <label htmlFor="qhop_currentAccountOnly" className="formfieldlabel">Search Current Account History Only</label>
                    </div>
                    <div className="formcheckboxwrapper">
                        <input id="qhop_wonQuotesOnly" value={this.state.wonQuotesOnly} onChange={this.handleWonQuotesOnlyChange} type="checkbox" disabled={this.state.isWorking}/>
                        <label htmlFor="qhop_wonQuotesOnly" className="formfieldlabel">Search Won Quotes Only</label>
                    </div>
                    <div className="formselectfieldwrapper">
                        <select className="formselectfield disable_exempt" onChange={this.handlePageSizeChange} disabled={this.state.isWorking} id="qhop_pageSize">
                            <option value="5">5 results at a time</option>
                            <option value="10">10 results at a time</option>
                            <option value="20">20 results at a time</option>
                            <option value="50">50 results at a time</option>
                        </select>
                    </div>
                    <button className="btn getNext active" onClick={this.getResults} disabled={this.state.isWorking || this.state.noMoreResults} >{buttonText}</button>
                </div>
                <div className="results">
                    {(this.state.historyItems.length > 0 || this.state.noMoreResults) ?<div className="separator" />:null}
                    <div className="content" style={{paddingTop:'5px'}}>
                    {(this.state.historyItems.length > 0) ? <ProductPriceHistorySummary historyItems={this.state.historyItems}/> : null}
                    {(this.state.historyItems.length > 0) ? <ul>{historyListItems}</ul> : null}
                    {(this.state.historyItems.length == 0 && this.state.noMoreResults) ? <ul><li style={{fontStyle:'italic'}}>No price history available.</li></ul> : null}
                    </div>
                </div>
            </div>
        );
    }
}

class ProductPriceHistoryListItem extends React.Component {
    render() {
        var historyItem = this.props.historyItem;
        var defLinkMsg = 'View quote in separate tab';

        var priceClickCallback = (typeof this.props.priceClickCallback === 'function') ? function () {
            this.props.priceClickCallback(historyItem.rawPrice);
        }.bind(this) : null;

        var liContents = [];
        liContents.push(<div key="count" className="count"><label>Result #: </label>{historyItem.count}</div>);
        liContents.push(<div key="customer" className="resultLine customer"><label>Acct: </label><span className="account">{historyItem.customer}</span></div>);
        liContents.push(<div key="quoteName" className="resultLine quoteName"><label>Quote</label> 
                            #<span className="quoteName">{historyItem.quoteReadableId}</span> v<span className="quoteName">{historyItem.version}</span>: <br />
                            <span className="quoteName">{historyItem.quoteName}</span></div>);
        
        liContents.push(<div key="source" className="resultLine source"><label>Source: </label> {historyItem.source}</div>);
        liContents.push(<div key="cost" className="cost"><label>Cost: </label><span className="money">{historyItem.cost}</span></div>);
        var priceDiv = <div key="price" data-price={historyItem.rawPrice} className="price"><label>Price: </label><span className="money">{historyItem.price}</span></div>;
        liContents.push(priceDiv);

        var url = quosal.util.url('quote.dashboard', 'idquotemain=' + historyItem.idQuoteMain, 'idquotetabs=' + historyItem.idQuoteTabs, 'submodules=quote.content');
        if (historyItem.canLink) {
            liContents.push(<div key="actions" className="resultLine historyactions">
                <a target="_blank" href={url}><span className='historybutton' title={this.props.hoverTitleQuote ? this.props.hoverTitleQuote : defLinkMsg}>VIEW</span></a>
                <span className='historybutton' onClick={priceClickCallback} title={this.props.hoverTitlePrice} >ATTACH</span>
            </div>);
        }
        else {
            liContents.push(<div key="actions" className="resultLine historyactions">
                <span className='historybutton disabled'>VIEW</span>
                <span className='historybutton' onClick={priceClickCallback} title={this.props.hoverTitlePrice} >ATTACH</span>
            </div>);
        }
        return <li>{liContents}</li>;
    }
}

class ProductPriceHistorySummary extends React.Component {
    render() {
        var historyItems = this.props.historyItems;
        var min = false;
        var minElt;
        var max = false;
        var maxElt;
        var totalPrice = 0;
        var omittedCount = 0;
        for (var i = 0; i < historyItems.length; i++) {
            var historyItem = historyItems[i];
            var price = historyItem.rawPrice;
            if (price <= 0) {
                omittedCount++;
            } else {
                if (min === false || min > price) {
                    min = price;
                    minElt = historyItem;
                }
                if (max === false || max < price)
                {
                    max = price;
                    maxElt = historyItem;
                }
                totalPrice += price;
            }
        }
        var includedCount = historyItems.length - omittedCount;
        var average = (totalPrice && includedCount)
            ? Math.round(totalPrice / includedCount * 100) / 100
            : 0;
        if (min === false) min = 0;
        if (max === false) max = 0;

        var formatMoney = function (amount) {
            if (accounting != null)
                return accounting.formatMoney(amount);
            return amount;
        };
        average = formatMoney(average);
        min = formatMoney(min);
        max = formatMoney(max);

        var resultsWere = omittedCount === 1 ? 'result was' : 'results were';

        return (
            <table id="qhop_message" className="message">
                <tbody>
                <tr><td></td><td></td><td className="label">Max: </td><td className="money"><span className="money" id="qhop_message_maxPrice">{max}</span></td></tr>
                <tr><td className="label"><div>Historical:</div></td><td><span className="total" id="qhop_message_total">{historyItems.length}</span></td>
                    <td className="label">Avg: </td><td className="money"><span className="money" id="qhop_message_averagePrice">{average}</span></td></tr>
                <tr><td></td><td></td><td className="label">Min: </td><td className="money"><span className="money" id="qhop_message_minPrice">{min}</span></td></tr>
                { (omittedCount > 0) ?
                    <tr><td className="statsCautionMessage" colSpan={4}>{omittedCount} {resultsWere} omitted from the min and average for not having a positive price.</td></tr>
                    : null }
                </tbody>
            </table>
        );
    }
}