import { api } from "../../common/guidedSelling/api/api";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getDesignTokens } from "../../common/guidedSelling/theme/adminGuidedSellingTheme";
import { Grid, IconButton } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { CommonLinkStyled, CommonSalesTrackTitle, CommonTypography, CustomWidthTooltip, SubmittedFormsContainerStyled, SubmittedFormsSearchbarStyled } from "../../common/guidedSelling/styledComponents/commonStyled";
import { COMMON_QUOTE_HEADER_STYLE, ENUM_KEY_NO_DECISION, QUOTE_STATE_COLORS } from "./constants/submittedFormsConstants";
import { ELLIPSIS_STYLE, SUBMITTED_FORMS_COMMON_HEADERS, SUBMITTED_FORMS_TEXT_SIZE } from "../../common/guidedSelling/constants/common";
import { CommonSubmittedFormsDataGrid } from "../../common/guidedSelling/components/commonSubmittedFormsDataGrid";
import { GridColumnHeaderTitle } from '@mui/x-data-grid';
import { SubmittedFormsSearch } from "../../common/guidedSelling/components/submittedFormsSearch";
import { SalesTrackFormPage } from "../../product/salesTrackFormPage";
import { CommonBreadCrumbs } from "../../common/guidedSelling/components/commonBreadCrumbs";

const SALESTRACKS_SUBMITTED_FORMS_ACTION = 'SALESTRACKS_SUBMITTED_FORMS_ACTION';
const SALESTRACK_COMPLETED_FORM_ACTION = 'SALESTRACK_COMPLETED_FORM_ACTION';
export class SubmittedFormsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submittedForms: [],
            totalForms: 0,
            mode: $('#dark-toggle').prop('checked'),
            sortModel: [],
            pageAction: SALESTRACKS_SUBMITTED_FORMS_ACTION,
            selectedSalestrack: null,
            selectedCompletedForm: null,
            isLoading: false,
            filter: undefined,
        }

        // Bind this
        this.getSubmittedSalesTracksForms = this.getSubmittedSalesTracksForms.bind(this);
        this.changeThemeMode = this.changeThemeMode.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filter !== this.state.filter) {
            this.getSubmittedSalesTracksForms();    
        }
    }

    componentDidMount() {
        this.changeThemeMode();
        this.getSubmittedSalesTracksForms()
    }

    changeThemeMode() {
        const $checkbox = $('#dark-toggle[type=checkbox]');
        const handleSaveThemeMode = (isChecked) => {
            this.setState({ ...this.state, mode: isChecked });
        }
        $checkbox.on('change', function (e) {
            handleSaveThemeMode(e.currentTarget.checked);
        });
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
        const $checkbox = $('#dark-toggle[type=checkbox]');
        $checkbox.off('change');
    }

    getSubmittedSalesTracksForms = (sortBy = 'submittedDate', sortDirection = 'desc', sortModel = []) => {
        const params = {
            sortBy: sortBy,
            sortDirection: sortDirection,
            pageNumber: 0,
            pageSize: 0,
            filter: this.state.filter,
        }
        this.setState({ ...this.state, isLoading: true });
        api.getSalesTrackCompletedForms(params, (msg) => {
            const newSubmittedForms = !!msg.results && msg.results.map(submittedFormSelected => { return { ...submittedFormSelected, id: submittedFormSelected.idCompletedForm } });
            this.setState({ ...this.state, submittedForms: newSubmittedForms, totalForms: msg.totalCount, isLoading: false, sortModel: sortModel });
        });
    }

    render() {
        const theme = createTheme(getDesignTokens(this.state.mode ? 'dark' : 'light'));
        const { submittedForms, sortModel, totalForms, selectedCompletedForm, selectedSalestrack, isLoading, filter } = this.state;    

        const onBack = () => this.setState({ ...this.state, pageAction: SALESTRACKS_SUBMITTED_FORMS_ACTION });

        const completedFormBreadCrumbsData = {
            actualBreadCrumbName: selectedSalestrack?.name,
            breadCrumbs: [
                {
                    name: 'Submitted Sales Track Forms',
                    navigate: onBack,
                },
            ],
        };

        const customRenderHeader = (params) => {
            const { colDef } = params;
            const isSorting = sortModel.length > 0 && sortModel[0].field === colDef.field;
            return (
                <>
                    <GridColumnHeaderTitle
                        label={colDef.headerName || colDef.field}
                        description={colDef.description}
                        columnWidth={colDef.width}
                    />
                    {!isSorting && (
                        <div className="MuiDataGrid-iconButtonContainer MuiDataGrid-customIconButtonContainer">
                            <IconButton sx={{
                                minWidth: '28px',
                                minHeight: '28px',
                                }}
                                size="small"
                            >
                                <SwapVertIcon className="MuiDataGrid-sortIcon MuiDataGrid-customSortIcon" sx={{ opacity: '1 !important', color: theme.palette.icon.main }} />
                            </IconButton>
                        </div>
                    )}
                </>
            );
        };

        const customFooter = () => <CommonTypography
            sx={{
                borderTop: '1px solid',
                borderColor: theme.palette.border.border_subtle2,
                padding: '18px 0 12px',
                width: '100%',
                fontSize: '0.8rem',
                textAlign: 'end',
                lineHeight: '18px',
                color: theme.palette.text.main,
                letterSpacing: '0.016rem',
            }}
        >{`Total Rows: ${totalForms}`}</CommonTypography>

        const handleSortModelChange = (sortModel) => {
            sortModel.length > 0 ? this.getSubmittedSalesTracksForms(sortModel[0].field, sortModel[0].sort, sortModel) : this.getSubmittedSalesTracksForms();
        };

        const getColumns = () => {

            const handleLinkToForm = ({ id, row: { salesTrackName, idQuote } }) => {

                api.getSalesTrackCompletedForm({ idQuote, idCompletedForm: id }, ({ sections }) => {
                    this.setState({ ...this.state, selectedCompletedForm: sections, selectedSalestrack: { name: salesTrackName }, pageAction: SALESTRACK_COMPLETED_FORM_ACTION })
                });
            };

            const commonColumns = [...SUBMITTED_FORMS_COMMON_HEADERS(customRenderHeader, handleLinkToForm)]; 
            const newColumns = [
                {
                field: 'quoteName',
                headerName: 'Quote Name',
                flex: 1,
                minWidth: 150,
                    renderCell: (params) => {
                        const nextUrl = quosal.util.url('quote.dashboard', 'idquotemain=' + params.row.idQuote, 'submodules=quote.home');
                        return (
                            <CustomWidthTooltip title={params.value}>
                                <CommonLinkStyled
                                    sx={{ cursor: "pointer", ...ELLIPSIS_STYLE, ...SUBMITTED_FORMS_TEXT_SIZE }}
                                    href={nextUrl}
                                >{params.value}</CommonLinkStyled>
                            </CustomWidthTooltip>
                    )
                },
                renderHeader: customRenderHeader,
                disableColumnMenu: true 
                },
                {
                    field: 'quoteStatus',
                    headerName: 'Quote Status',
                    flex: 1,
                    minWidth: 150,
                    renderCell: (params) => {

                        const quoteStateColor = QUOTE_STATE_COLORS(theme);

                        const getStyles = () => {
                            if (params.value === ENUM_KEY_NO_DECISION) {
                                return {
                                    ...COMMON_QUOTE_HEADER_STYLE,
                                    border: '1px solid',
                                    borderColor: quoteStateColor[params.value].backgroundColor,
                                    background: theme.palette.background.main,
                                    color: quoteStateColor[params.value].textColor,
                                }
                            } else {
                                return {
                                    ...COMMON_QUOTE_HEADER_STYLE,
                                    background: quoteStateColor[params.value].backgroundColor,
                                    color: quoteStateColor[params.value].textColor,
                                }
                            }
                        }

                        return (
                            <>
                                <CommonTypography sx={{ ...getStyles(), ...SUBMITTED_FORMS_TEXT_SIZE } }>{params.value}</CommonTypography>
                            </>
                        )
                    },
                    renderHeader: customRenderHeader,
                    disableColumnMenu: true 
                },
            ]

            return [...commonColumns, ...newColumns];
        }

        const columns = getColumns();

        const onSearch = (filter) => this.setState({ ...this.state, filter });

        const shouldDisplaySearchInput = (filter !== undefined || submittedForms.length > 0);

        const pagesHandler = {
            [SALESTRACKS_SUBMITTED_FORMS_ACTION]: () => <>
                <Grid container direction="row" rowSpacing='1rem' p='1rem 2rem'>
                    <Grid item xs={12} pt='1rem'>
                        <CommonSalesTrackTitle variant="h2" className="salesTrack-title">{`Submitted Sales Track Forms`}</CommonSalesTrackTitle>
                    </Grid>
                    <SubmittedFormsContainerStyled item xs={12} pt='1rem'>
                        { shouldDisplaySearchInput && <SubmittedFormsSearchbarStyled>
                            <SubmittedFormsSearch onSearch={onSearch} />
                        </SubmittedFormsSearchbarStyled> }
                        <CommonSubmittedFormsDataGrid
                            theme={theme}
                            submittedForms={submittedForms}
                            columns={columns}
                            sortModel={sortModel}
                            isLoading={isLoading}
                            customFooter={customFooter}
                            handleSortModelChange={handleSortModelChange}
                            isSearch={filter !== undefined}
                        />
                    </SubmittedFormsContainerStyled>
                </Grid>
            </>,
            [SALESTRACK_COMPLETED_FORM_ACTION]: () => <>
                <Grid container direction="row" rowSpacing='1rem' p='1rem 2rem'>
                    <Grid item xs={12}>
                        <CommonBreadCrumbs {...completedFormBreadCrumbsData} />
                    </Grid>
                    <Grid item xs={12} pt='1rem'>
                        {!!selectedCompletedForm && <SalesTrackFormPage
                            readOnly
                            salesTrackSelected={selectedSalestrack}
                            salesTrackForm={selectedCompletedForm}
                            onBack={onBack} />}
                    </Grid>
                </Grid>
            </>
        };

        return <ThemeProvider theme={theme}>{pagesHandler[this.state.pageAction]()}</ThemeProvider>
    }
}