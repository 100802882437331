import React from "react";
import { Grid, Typography } from "@mui/material";

import { Item } from "../styledComponents/common.jsx";
import ProductPriceHistory from "./productPriceHistory.jsx";
import ProductDetailsTabs from "./productDetailsTabs.jsx";

function ProductDetailsPage() {
	return (
		<Grid container spacing={2} sx={{ p: "0 5px" }}>
			<Grid item xs={4}>
				<Item>
					<Typography fontSize={20} mb={2}>
						Product Price History
					</Typography>
					<ProductPriceHistory />
				</Item>
			</Grid>
			<Grid item xs={8}>
				<Item
					sx={{
						overflowY: "auto"
					}}
				>
					<Typography fontSize={20} mb={2}>
						Product Details
					</Typography>
					<ProductDetailsTabs />
				</Item>
			</Grid>
		</Grid>
	);
}

export default ProductDetailsPage;
