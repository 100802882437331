import React, { memo, useCallback, useRef } from "react";

import { Add, DragIndicator, ExpandLess, ExpandMore, Tune } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";
import {
	Box,
	Checkbox,
	CircularProgress,
	Collapse,
	FormControlLabel,
	IconButton,
	InputAdornment,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
	Tooltip,
	Typography,
	styled
} from "@mui/material";

import TruncatedText from "../../../forms/truncatedText";
import { useEditorContext } from "../context/editorContext";
import useEditorWrapperHelper from "../EditorWrapper/editorWrapperHelpers";
import ReactFloatingScroll from "../FloatingScroll/FloatingScroll";
import useDraggableExpandableContainer from "./draggableExpandableContainer";
import useStyles from "./styles";

const StyledContent = styled(Box)(({ theme }) => ({
	marginTop: 0,
	padding: theme.spacing(2),
	backgroundColor: theme.palette.background.default,
	borderRadius: theme.shape.borderRadius,
	textOverflow: "ellipsis",
	overflow: "hidden"
}));

function DraggableExpandable({
	id,
	tabItem,
	onRemove,
	onMove,
	open,
	duplicated,
	updateOpenTab,
	setDuplicated,
	setChange,
	content,
	expanded,
	openCustomizeGrid,
	setLoading
}) {
	const {
		anchorEl,
		contentGrid,
		darkMode,
		disabledAddNewTab,
		drag,
		drop,
		duplicateTab,
		duplicateTabAsRadioOption,
		getTabNumber,
		getTabColor,
		gridRows,
		handleCheckBoxClick,
		handleClick,
		handleEditTab,
		handleKeyUp,
		handleMenuClick,
		handleMenuClose,
		handleOnBlur,
		handleRenameTab,
		handleSelectedTabIds,
		handleSelectNewTab,
		handleSheetMenuClick,
		handleSheetMenuClose,
		handleUpdateAllCosts,
		isActive,
		isDragging,
		isOptional,
		isPrinted,
		isSpreadsheetRefreshing,
		isStandardUser,
		isStandardUserPlus,
		isTabSelected,
		isTotalsIncluded,
		listClasses,
		optionGroupLabel,
		orderPorterRequired,
		removeZeroQtyItems,
		renameTab,
		scrollToSelectableTab,
		setTabName,
		sheetAnchorEl,
		showShortMenu,
		tabGroupLabel,
		tabName,
		textInput,
		updateSpreadsheet
	} = useDraggableExpandableContainer(
		id,
		tabItem,
		onMove,
		open,
		duplicated,
		updateOpenTab,
		setDuplicated,
		setChange,
		setLoading
	);

	const currentRef = useRef();
	const { 
		isDisabledQuoteActions,
		selectedTabs,
		isQuoteTemplate,
		shouldDisplayTabNumbers,
		setSelectedTabsToDroppedTabs,
		gridFormats 
	} = useEditorContext();

	const {
		insertItemsByTabs,
		deleteItemsByTabs
	} = useEditorWrapperHelper();

	const isMenuItemDisabled = disabledAddNewTab || isDisabledQuoteActions;

	const {
		listItemTextStyle,
		dragIndicatorStyle,
		textFieldStyle,
		menuCheckboxStyle,
		elementsColor
	} = useStyles();



	const handleFewTabsDragContext = (node) => {
		const selectedTabIds = selectedTabs.map((tab) => tab.tabId);
		if (selectedTabIds !== null && selectedTabIds.length > 1) {
			if (selectedTabIds !== null && selectedTabIds.length > 1) {
				return drag(drop(node));
			}
			return null;
		};
	};

	const createContent = useCallback(() => {
		const data = insertItemsByTabs([tabItem]);
		if (data !== "") {
			app.currentQuote.HTMLContentForAutoSaving = data;
			setSelectedTabsToDroppedTabs();
			contentGrid.forceUpdate();
		}
	}, [tabItem, insertItemsByTabs, contentGrid]);

	const deleteContent = useCallback(() => {
		const data = deleteItemsByTabs([tabItem]);
		if (data !== "") {
			app.currentQuote.HTMLContentForAutoSaving = data;
			setSelectedTabsToDroppedTabs();
			contentGrid.forceUpdate();
		}
	}, [tabItem, insertItemsByTabs, contentGrid]);

	return (
		<Box>
			<ListItem
				className={listClasses}
				ref={(node) => drag(drop(node))}
				style={{
					opacity: isDragging || isActive ? 0.5 : 1,
					textOverflow: "ellipsis",
					overflow: "hidden",
					marginBottom: open === id ? 0 : undefined,
					borderRadius: open === id ? "10px 10px 0 0" : undefined
				}}
			>
				<Box
					display="flex"
					alignItems="center"
					width="100%">
					{quosal.util.isNewPreviewContentVisible() && !isMenuItemDisabled &&
						<FormControlLabel
							control={
								<Checkbox
									checked={isTabSelected}
									onChange={isQuoteTemplate ? () => handleSelectedTabIds(isTabSelected) : isTabSelected ? deleteContent : createContent}
									sx={{
										"&.Mui-checked": {
											color: "#2E3F80"
										},
										padding: "0px",
										visibility: gridFormats.includes(tabItem?.TabName?.trim()) ? "hidden" : "visible"
									 }}
								/>
							}
							sx={{
								marginRight: "0px",
								marginLeft: "-3px"
							}}
						/>
					}
					<IconButton
						size="small"
						ref={(node) => handleFewTabsDragContext(node)}
						edge="start"
						onClick={handleClick}
						disabled={disabledAddNewTab}
						style={{ ...dragIndicatorStyle, opacity: isDragging || isActive ? 0.5 : 1 }}
					>
						{!disabledAddNewTab &&
							<DragIndicator />
						}
					</IconButton>
					{(duplicated || renameTab) && open === id ? (
						<TextField
							draggable
							onDragStart={(event) => event.preventDefault()}
							inputRef={textInput}
							onBlur={handleOnBlur}
							label="Name"
							onKeyUp={handleKeyUp}
							variant="standard"
							value={tabName}
							onChange={(e) => setTabName(e.target.value)}
							InputLabelProps={{ style: elementsColor }}
							sx={{ ...textFieldStyle }}
						/>
					) : (
						(disabledAddNewTab ? (
							<ListItemText
								sx={{ color: darkMode ? "#FFFFFF" : "#000000" }}
								primary={tabName}
								primaryTypographyProps={{
									...listItemTextStyle
								}}
							/>
					
						) : (
							<>
								<ListItemText
									sx={{
										fontStyle: isOptional ? "italic" : "normal",
										cursor: "pointer",
										color: darkMode ? "#FFFFFF" : "#000000",
									}}
									onClick={(e) => {
										handleClick();
										scrollToSelectableTab(e, tabItem?.IdQuoteTabs)
									}
									}
									primary={
										<Box sx={{
											display: 'flex',
											alignItems: 'flex-start',
											justifyContent: 'flex-start',
											marginLeft: '-3px'
										}}>
											{shouldDisplayTabNumbers && (
												<InputAdornment
													position="start"
													variant="standard"
													sx={{
														"height": "100%",
													}}
												>
													<Typography
														sx={{
															"margin": "auto",
															color: darkMode ? (getTabColor() === "" || getTabColor() === "black") ? "#FFFFFF" : "#000000" : "#000000",
															fontWeight: "400"
														}}>
														{getTabNumber(tabItem) + ")"}
													</Typography>
												</InputAdornment>
											)}
											<TruncatedText
												text={tabName}
												tooltipTitle={tabName}
												showTooltip={true}
												maxWidth="100%"
												className="tabName"
												darkModeTextColor={getTabColor() === "" || getTabColor() === "black" ? "#FFFFFF" : "#000000"}
											/>
										</Box>
									}
								/>
								{tabGroupLabel}
								{optionGroupLabel}
							</>
						))
					)}

					<div className="tabActions">
						<ListItemSecondaryAction sx={{ display: "flex" }}>
							{tabItem?.HasSpreadsheet && (


								<div className="spreadsheetMenu">
									<IconButton
										size="small"
										edge="end"
										disabled={disabledAddNewTab}
										onClick={handleSheetMenuClick}
									>
										<WindowOutlinedIcon />
									</IconButton>
									<Menu
										id="action-menu"
										anchorEl={sheetAnchorEl}
										keepMounted
										open={Boolean(sheetAnchorEl)}
										onClose={handleSheetMenuClose}
										disableScrollLock
										anchorOrigin={{
											vertical: "bottom",
											horizontal: !expanded ? "right" : "left"
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: !expanded ? "left" : "right"
										}}
									>
										<MenuItem
											onClick={() => contentGrid.launchSpreadsheet(id)}>
											Launch Spreadsheet
										</MenuItem>
										{!isSpreadsheetRefreshing ? (
											<MenuItem
												onClick={updateSpreadsheet}>Refresh</MenuItem>
										) : (
											<MenuItem>
												<div style={{ marginRight: "5px" }}>
													Refreshing
												</div>
												<CircularProgress size={20} />
											</MenuItem>
										)}
									</Menu>
								</div >)}
								{disabledAddNewTab ? (
									<IconButton
										size="small"
										edge="end"
										color="primary"
										onClick={handleSelectNewTab}
										disabled={isDisabledQuoteActions}
										sx={{ ...elementsColor }}
									>
										<Add />
									</IconButton>
								) : (
									<>
										<Tooltip
											title="Tab Actions">
											<span>
												<IconButton
													aria-controls="action-menu"
													aria-haspopup="true"
													size="small"
													edge="end"
													onClick={handleMenuClick}
												>
													<Tune />
												</IconButton>
											</span>
										</Tooltip>
										{showShortMenu ? (
											<Menu
												id="action-menu"
												anchorEl={anchorEl}
												keepMounted
												open={Boolean(anchorEl)}
												onClose={handleMenuClose}
												disableScrollLock
												anchorOrigin={{
													vertical: "bottom",
													horizontal: !expanded ? "right" : "left"
												}}
												transformOrigin={{
													vertical: "top",
													horizontal: !expanded ? "left" : "right"
												}}
											>
												<MenuItem
													disabled={isMenuItemDisabled}
													onClick={handleEditTab}>
													Tab Details
												</MenuItem>
												<MenuItem
													disabled={isMenuItemDisabled}
													onClick={handleMenuClose}>
													Show Line Item Quick Filter
												</MenuItem>
												<MenuItem
													disabled={isMenuItemDisabled}
													onClick={duplicateTab}>
													Duplicate Tab
												</MenuItem>
												<MenuItem
													disabled={isMenuItemDisabled}
													onClick={duplicateTabAsRadioOption}>
													Duplicate Tab as Radio Option
												</MenuItem>
											</Menu>
										) : (
											<Menu
												id="action-menu"
												anchorEl={anchorEl}
												keepMounted
												open={Boolean(anchorEl)}
												onClose={handleMenuClose}
												disableScrollLock
												anchorOrigin={{
													vertical: "bottom",
													horizontal: !expanded ? "right" : "left"
												}}
												transformOrigin={{
													vertical: "top",
													horizontal: !expanded ? "left" : "right"
												}}
											>
												<MenuItem
													disabled={isMenuItemDisabled}
													onClick={handleRenameTab}>
													Rename Tab
												</MenuItem>
												{isStandardUser || isStandardUserPlus ? null : (
													<MenuItem
														disabled={isMenuItemDisabled}
														onClick={() => {
															handleMenuClose();
															openCustomizeGrid(tabItem?.IdQuoteTabs);
														}}
													>
														Edit Grid Layout
													</MenuItem>
												)}
												<MenuItem
													onClick={handleEditTab}>
													Edit Tab
												</MenuItem>
												<MenuItem
													disabled={isMenuItemDisabled}
													onClick={() => {
														removeZeroQtyItems(tabItem?.IdQuoteTabs);
													}}
												>
													Remove 0 Quantity Items
												</MenuItem>
												<MenuItem
													disabled={!gridRows.length || isMenuItemDisabled}
													onClick={() => handleUpdateAllCosts(tabItem?.IdQuoteTabs)}
												>
													Update All Costs
												</MenuItem>
												<MenuItem
													disabled={isMenuItemDisabled}>
													<FormControlLabel
														control={
															<Checkbox
																checked={isOptional}
																onChange={() =>
																	handleCheckBoxClick(
																		"IsOptional",
																		isOptional
																	)
																}
																sx={{ ...menuCheckboxStyle }}
															/>
														}
														label="Is Deselected Options"
													/>
												</MenuItem>
												<MenuItem
													disabled={isMenuItemDisabled}>
													<FormControlLabel
														control={
															<Checkbox
																checked={isPrinted}
																onChange={() =>
																	handleCheckBoxClick("IsPrinted", isPrinted)
																}
																sx={{ ...menuCheckboxStyle }}
															/>
														}
														label="Is Printed"
													/>
												</MenuItem>
												<MenuItem
													disabled={isMenuItemDisabled}>
													<FormControlLabel
														control={
															<Checkbox
																checked={isTotalsIncluded}
																onChange={() =>
																	handleCheckBoxClick(
																		"IsTotalsIncluded",
																		isTotalsIncludedformat
																	)
																}
																sx={{ ...menuCheckboxStyle }}
															/>
														}
														label="Is Totals Included"
													/>
												</MenuItem>
												<MenuItem
													disabled={isMenuItemDisabled}>
													<FormControlLabel
														control={
															<Checkbox
																checked={orderPorterRequired}
																onChange={() =>
																	handleCheckBoxClick(
																		"OrderPorterRequired",
																		orderPorterRequired
																	)
																}
																sx={{ ...menuCheckboxStyle }}
															/>
														}
														label="Option Locked"
													/>
												</MenuItem>
												{quosal.settings.getValue("canSpreadsheet") ? (
													<MenuItem
														disabled={isMenuItemDisabled}
														onClick={() => contentGrid.launchSpreadsheet(id)}>
														Tab Spreadsheet
													</MenuItem>
												) : null}
												<MenuItem
													disabled={isMenuItemDisabled}
													onClick={duplicateTab}>
													Duplicate Tab
												</MenuItem>
												<MenuItem
													disabled={isMenuItemDisabled}
													onClick={duplicateTabAsRadioOption}>
													Duplicate Tab as Radio Option
												</MenuItem>
											</Menu>
										)}
										<IconButton
											size="small"
											edge="end"
											onClick={() => onRemove(true, id)}
											disabled={isMenuItemDisabled}
										>
											<DeleteOutlineOutlinedIcon />
										</IconButton>
										<IconButton
											size="small"
											edge="end"
											onClick={handleClick}>
											{open === id ? <ExpandLess /> : <ExpandMore />}
										</IconButton>
									</>
								)
							}
						</ListItemSecondaryAction>
					</div>
				</Box>
			</ListItem>
			<Collapse in={open === id} timeout="auto" unmountOnExit>
				<ReactFloatingScroll contentRef={currentRef} innerScrollableClass="MuiDataGrid-virtualScroller">
					<StyledContent ref={currentRef}>
						{content}</StyledContent>
				</ReactFloatingScroll>
			</Collapse>
		</Box >
	);
};

export default memo(DraggableExpandable);
