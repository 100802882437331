import { Box } from "@mui/material";
import { StyledOutlinedCard } from "../styledComponents";

export const CardContainer = ({ children, width, height = "100%", sx, styleOutlineOverrides }) => {
	return (
		<>
			<Box
				sx={{
					width,
					height,
					...sx
				}}
			>
				<StyledOutlinedCard variant="outlined" sx={styleOutlineOverrides}>{children}</StyledOutlinedCard>
			</Box>
		</>
	);
};
