import { DialogManager } from "js/jsx/src/classes/dialog.jsx";
import { TaxServiceMicroUiWrapper } from "js/jsx/src/classes/microUi/taxServiceMicroUiWrapper.jsx";

export default function taxServiceSetup() {

    var loadTaxServicesPage = function () {

        quosal.ui.react.render(<DialogManager />, document.getElementById('dialogManager'));
        quosal.ui.react.render(<TaxServiceMicroUiWrapper />,
            document.getElementById('currentDocument')
        );
        $('#pageName').val('taxservicesetup.quosalweb');
    };
    quosal.sell.modules.onAfterLoad.bind(loadTaxServicesPage);
}