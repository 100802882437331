import { AgreementMappingPage } from "js/jsx/src/classes/settings/agreementMapping.jsx";
import { DialogManager } from "js/jsx/src/classes/dialog.jsx";

export default function agreementMapping() {

    var loadVendorsPage = function () {

        quosal.ui.react.render(<DialogManager />, document.getElementById('dialogManager'));
        quosal.ui.react.render(<AgreementMappingPage />, document.getElementById('currentDocument'));
        $('#pageName').val('agreementMapping.quosalweb');
    };

    quosal.sell.modules.onAfterLoad.unbind().bind(loadVendorsPage);
}