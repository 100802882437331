const getAllSalesTracks = function(filter, success, fail) {
    var initGuidedSellingAPI = quosal.api.admin.InitGuidedSelling(filter);
        initGuidedSellingAPI.finished = function (msg) {
            if (msg.error.length > 0) {
                fail && fail(msg);
            } else {
                success && success(msg);
            }
        }.bind(this);
        initGuidedSellingAPI.call();
}
const getSalesTrackById = function(id, success, fail) {
    var getSalesTrackAPI = quosal.api.admin.GetSalesTrack(id);
    getSalesTrackAPI.finished = function (msg) {
        if (msg.error.length > 0) {
            fail && fail(msg);
        } else {
            success && success(msg);
        }
    }.bind(this);
    getSalesTrackAPI.call();
}
const postSalesTrack = function (salesTrack, success, fail) {
    var postSalesTracksAPI = quosal.api.admin.PostSalesTracks(salesTrack);
    postSalesTracksAPI.finished = function (msg) {
        if (msg.error.length > 0) {
            fail && fail(msg);
        } else {
            success && success(msg);
        }
    }.bind(this);
    postSalesTracksAPI.call();
}
const createSalesTrack = function (salesTrack, success, fail) {
    postSalesTrack(salesTrack, success, fail);
}
const updateSalesTrack = function (salesTrack, success, fail) {
    postSalesTrack(salesTrack, success, fail);
}
const deleteSalesTrack = function (salesTrack, success, fail) {
    var deleteSalesTracksAPI = quosal.api.admin.DeleteSalesTrack(salesTrack);
    deleteSalesTracksAPI.finished = function (msg) {
        if (msg.error.length > 0) {
            fail && fail(msg);
        } else {
            success && success(msg);
        }
    };
    deleteSalesTracksAPI.call();
}
const createSalesTrackForm = function (salesTrackForm, success, fail) {
    var postSalesTrackFormAPI = quosal.api.admin.PostSalesTrackForm(salesTrackForm);
    postSalesTrackFormAPI.finished = function (msg) {
        if (msg.error.length > 0) {
            fail && fail(msg);
        } else {
            success && success(msg);
        }
    };
    postSalesTrackFormAPI.call();
}
const getSalesTrackForm = function (id, success, fail) {
    var getSalesTrackFormAPI = quosal.api.admin.GetSalesTrackForm(id);
    getSalesTrackFormAPI.finished = function (msg) {
        if (msg.error.length > 0) {
            fail && fail(msg);
        } else {
            success && success(msg);
        }
    };
    getSalesTrackFormAPI.call();
}

const postSalesTrackCompletedForm = function(salesTrackCompletedForm, success, fail) {
    let postSalesTrackCompletedFormAPI = quosal.api.admin.PostSalesTrackCompletedForm(salesTrackCompletedForm);
    postSalesTrackCompletedFormAPI.finished = function (msg) {
        if (msg.error && msg.error.length > 0) {
            fail && fail(msg);
        } else {
            success && success(msg);
        }
    }
    postSalesTrackCompletedFormAPI.call();
}

const attachSalesTrackMappedProducts = function(attachProductParams, success, fail) {
    let attachSalesTrackMappedProducts = quosal.api.product.attachSalesTrackMappedProducts(attachProductParams);
    attachSalesTrackMappedProducts.finished = function(msg) {
        if(msg.error.length > 0) {
            fail && fail(msg);
        } else {
            success && success(msg);
        }
    }.bind(this);
    attachSalesTrackMappedProducts.call();
}

const validateManufacturerPartNumber = function (manufacturerPartNumber, success, fail) {
    let validateManufacturerPartNumber = quosal.api.admin.ValidateManufacturerPartNumber(manufacturerPartNumber);
    validateManufacturerPartNumber.finished = function (msg) {
        if (msg.error.length > 0) {
            fail && fail(msg);
        } else {
            success && success(msg);
        }
    }.bind(this);
    validateManufacturerPartNumber.call();
}

const getSalesTrackCompletedForms = function (bodyGetSubmittedSalesTracks, success, fail) {
    let postSalesTrackCompletedFormAPI = quosal.api.admin.GetSalesTrackCompletedForms(bodyGetSubmittedSalesTracks);
    postSalesTrackCompletedFormAPI.finished = function (msg) {
        if (msg.error && msg.error.length > 0) {
            fail && fail(msg);
        } else {
            success && success(msg);
        }
    }
    postSalesTrackCompletedFormAPI.call();
}

const getSalesTrackCompletedForm = function (bodyGetSubmittedForm, success, fail) {
    let postSalesTrackCompletedFormAPI = quosal.api.admin.GetSalesTrackCompletedForm(bodyGetSubmittedForm);
    postSalesTrackCompletedFormAPI.finished = function (msg) {
        if (msg.error && msg.error.length > 0) {
            fail && fail(msg);
        } else {
            success && success(msg);
        }
    }
    postSalesTrackCompletedFormAPI.call();
}

const getAllQuoteTemplates = function (success, fail) {
	let getQuoteTemplates = quosal.api.quote.getQuoteTemplates();
	getQuoteTemplates.finished = function (msg) {
		if (msg.error && msg.error.length > 0) {
			fail && fail(msg);
		} else {
			success && success(msg);
		}
	};
	getQuoteTemplates.call();
};

export const api = {
    getAllSalesTracks,
    getSalesTrackById,
    createSalesTrack,
    updateSalesTrack,
    deleteSalesTrack,
    createSalesTrackForm,
    postSalesTrackCompletedForm,
    attachSalesTrackMappedProducts,
    validateManufacturerPartNumber,
    getSalesTrackForm,
    getSalesTrackCompletedForms,
    getSalesTrackCompletedForm,
    getAllQuoteTemplates
}