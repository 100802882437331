import {ApprovalRulesList} from "js/jsx/src/classes/admin/Approvals/ApprovalRulesList.jsx";
import {ApprovalRuleForm} from "js/jsx/src/classes/admin/Approvals/ApprovalRuleForm.jsx";

export class ApprovalRules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {      
            mode: 'list',
            users: [],  // init
            groups: [], // init
            quotefields: [],    // init
            itemfields: [],     // init
            isDirty: false,
            changes: 0,
            isLoading: true,
            withBlank: false     // TODO REMOVE
        }; 

        this.valueErrors = {};
        this.handlers = [];

        this.addNewRule = this.addNewRule.bind(this);
        this.editRule = this.editRule.bind(this);
        this.copyRule = this.copyRule.bind(this);
        this.ruleFormSaved = this.ruleFormSaved.bind(this);
        this.ruleFormCanceled = this.ruleFormCanceled.bind(this);
        this.duplicateRule = this.duplicateRule.bind(this);
        this.rulebyId = this.rulebyId.bind(this);
        this.updateActiveRule = this.updateActiveRule.bind(this);
        this.addBlankApprover = this.addBlankApprover.bind(this);
        this.removeApprover = this.removeApprover.bind(this);
        this.criteriaFromId = this.criteriaFromId.bind(this);
        this.addBlankCriteria = this.addBlankCriteria.bind(this);
        this.removeCriteria = this.removeCriteria.bind(this);
        this.lookupUser = this.lookupUser.bind(this);
        this.lookupGroup = this.lookupGroup.bind(this);
        this.lookupFieldData = this.lookupFieldData.bind(this);
        this.checkDuplicate = this.checkDuplicate.bind(this);
        this.prepareThenSave = this.prepareThenSave.bind(this);
        this.onNavigation = this.onNavigation.bind(this);
        this.onWindowUnloading = this.onWindowUnloading.bind(this);
        this.reset = this.reset.bind(this);
        this.reSortRules = this.reSortRules.bind(this);
        this.addQuick1 = this.addQuick1.bind(this);
        this.addQuick2 = this.addQuick2.bind(this);
        this.addQuick3 = this.addQuick3.bind(this);
        this.quickAddUniqueAndOptNavigate = this.quickAddUniqueAndOptNavigate.bind(this);
        this.setRuleActiveState = this.setRuleActiveState.bind(this);
        this.lookupEnumsForField = this.lookupEnumsForField.bind(this);
        this.conformCompareValue = this.conformCompareValue.bind(this);
    }

    componentDidMount() {        
        let me = this;
        ApprovalRules.hookUpNavigationActions.call(this);
        
        let api = quosal.api.admin.InitApprovalRules();
        Dialog.setIsWorking(true);
        api.finished=function(msg) {
            let enumerations = [];
            let fields = me.getItemFields(enumerations);
            let quotes = me.getQuoteFields(enumerations);
            let entries = [];
            msg.QuoteStatusList.forEach(function(str) {
                entries.push({Value:str, Label:str});
                });
            enumerations.push({field:"quote.QuoteStatus", values:entries });
            entries = [];
            msg.ApprovalStatusOptions.forEach(function(str) {
                entries.push({Value:str, Label:str});
                });
            enumerations.push({field:"quote.ApprovalStatus", values:entries });
            entries = [];
            msg.InvoicePostStatusOptions.forEach(function(str) {
                entries.push({Value:str, Label:str});
                });
            enumerations.push({field:"quote.InvoicePostStatus", values:entries });

            entries = [];
            msg.users.forEach(function(u) {
                entries.push({Value:u.id, Label:u.UserName});
                });
            enumerations.push({field:"quote.Owner", values:entries });
            enumerations.push({field:"quote.InsideRep", values:entries });
            let fnSortByName = function(recA, recB) {
                let a = recA.name ? recA.name : recA.Name;
                let b = recB.name ? recB.name : recB.Name;
                if (!a || !b) return 0;
                a = a.toLowerCase();
                b = b.toLowerCase();
                let res = 0;
                if (a < b) {
                    res = -1;
                }
                if (a > b) {
                    res = 1;
                }
                return res;
            }
            msg.templates.sort(fnSortByName);
            msg.locations.sort(fnSortByName);
            msg.teams.sort(fnSortByName);
            msg.groups.sort(fnSortByName);
            msg.rules.sort(fnSortByName);
            msg.teams.unshift({id: '00000000-0000-0000-0000-000000000000', name:'No Team'});
            
            msg.users.lookup = this.lookupUser;
            msg.groups.lookup = this.lookupGroup;
            
            me.setState({
                users:msg.users,
                quotestatus: msg.QuoteStatusList,
                itemfields: fields,
                quotefields: quotes,
                enumerations: enumerations,
                rules:msg.rules,
                templates: msg.templates,
                locations: msg.locations,
                teams: msg.teams,
                groups: msg.groups,
                isLoading: false,
                originalRules:  JSON.parse(JSON.stringify(msg.rules))
            });
            Dialog.setIsWorking(false);
        }.bind(this);
        api.call();
    }
    componentWillUnmount() {
        ApprovalRules.unhookNavigationActions.call(this);
    }
    
    static onNavigation(navState) {
        if (!this) {
            return;
        }
        var runOnNavigation = function () {
            if (this.state.isDirty && !this.props.noSave) {
                var resumeNavigation = function (navState) {
                    if (typeof this.reset === 'function') {
                        this.reset();
                    }
                    Dialog.closeAll();

                    navState.resumeNavigation();
                }.bind(this, navState);

                var saveChanges = function (navState) {
                    //if (this.props.onSubmit) this.props.onSubmit();
                    this.ruleFormSaved(this.state.currentrule.id == '0');

                    resumeNavigation();
                }.bind(this, navState);

                Dialog.open({
                    title: 'Save Changes?',
                    message: 'Do you want to save changes before leaving this page?',
                    closeRequiresButton: true,
                    links: [{
                        title: 'Save',
                        callback: saveChanges
                    }, {
                        title: 'Discard',
                        callback: resumeNavigation
                    }, {
                        title: 'Cancel',
                        callback: Dialog.closeAll
                    }]
                });

                if (navState.cancelNavigation)
                    navState.cancelNavigation();
            }
        }.bind(this);

        if (this.prepareThenSave) {
            this.prepareThenSave(runOnNavigation);
        } else {
            runOnNavigation();
        }
    }

    static onWindowUnloading(e) {
        if (!this.props) return;
        if(!this.props.noSave && this.state.isDirty) {
            if(!e) e = {};
            e.preventDefault();
            e.cancelBubble = true;
            e.returnValue = 'There are unsaved changes that will be lost, are you sure you want to navigate away?';
            return e.returnValue;
        }
    }

    static hookUpNavigationActions() {
        quosal.events.beforeWindowUnload.bind(this.onWindowUnloading, null);
        // quosal.navigation.onBeforeNavigate.bind(this.onNavigation);
        // quosal.sell.modules.onBeforeLoad.bind(this.onNavigation);
    }

    static unhookNavigationActions() {
        quosal.events.beforeWindowUnload.unbind(this.onWindowUnloading);
        // quosal.navigation.onBeforeNavigate.unbind(this.onNavigation);
        // quosal.sell.modules.onBeforeLoad.unbind(this.onNavigation);
    }
    
    onNavigation(navState) {
        ApprovalRules.onNavigation.call(this, navState);
    }
    onWindowUnloading(e) {
        ApprovalRules.onWindowUnloading.call(this, e);
    }

    reset() {
        this.state.isDirty = false;
    }
    prepareThenSave(saveCallback) {
        /*var beforeSaveFunctions = [];
        var fields = this.getFields();
        for(var i = 0; i < fields.length; i++) {
            var input = fields[i];

            if (typeof input.beforeSave === 'function') {
                beforeSaveFunctions.push(input.beforeSave);
            }
        }
        var countdown = beforeSaveFunctions.length;
        var executeBeforeSaveFunctions = function recursiveExecuteBeforeSaveFunctions (callback) {
            if (countdown > 0) {
                countdown--;
                beforeSaveFunctions[countdown](recursiveExecuteBeforeSaveFunctions.bind(null, callback));
            } else {
                callback();
            }
        }.bind(null, saveCallback);
        executeBeforeSaveFunctions();*/

        saveCallback();
    }

    /* ------------------------------------- */
    addValueError(criteria){
        this.valueErrors[criteria.id] = criteria;
    }

    clearValueError(){
        this.valueErrors = {};
    }

    addNewRule() {
        let crit={
            id: quosal.util.generateGuid(),
            sequence: 1, fieldgroup: 'quote', field: 'AccountName',
            comparison: 'eq', value: ''};
            
        let entry = {
            id: '0',
            name: '',
            message: '',
            locations: [],
            templates: [],
            teams: [],
            revieworder: 'sequential',
            isActive: false,
            criteria: [crit],
            approvalChain: [{level: 1, id: quosal.util.generateGuid(), chaintype:kApprovalSettings.ApproverTypeManager, chainidentifier: 'inside'}]
        };
        this.state.templates.forEach(function(t) {
            entry.templates.push(t.id);
        });
        this.state.locations.forEach(function(t) {
            entry.locations.push(t.id);
        });
        this.state.teams.forEach(function(t) {
            entry.teams.push(t.id);
        });
        
        this.setState({mode: 'new', currentrule: entry, isDirty:false});
    }

    setRuleActiveState(id, toActive) {
        let api = quosal.api.admin.enableDisableRule(id, toActive);
        api.finished=function(msg) {}.bind(this);
        api.call();

        if (this.state.rules) {
            for (let i = 0; i < this.state.rules.length; i++) {
                if (this.state.rules[i].id == id) {
                    this.state.rules[i].isActive = toActive;
                    this.setState((prevState) => {
                        return {changes: prevState.changecount + 1};
                      });
                    break;
                }
            }
        }
    }

    rulebyId(id) {
        if (this.state.rules) {
            for (let i = 0; i < this.state.rules.length; i++) {
                if (this.state.rules[i].id == id) {
                    return this.state.rules[i];
                }
            }
        }
        return undefined;
    }

    duplicateRule(id) {
        let source = JSON.parse(JSON.stringify(this.rulebyId(id)));

        if (!source) {
            return null;
        }

        let copy = {
            id: source.id,
            name: source.name,
            message: source.message,
            revieworder: source.revieworder,
            isActive: source.isActive,
            criteria: source.criteria.slice(0),
            approvalChain: source.approvalChain.slice(0)
        };
        copy.locations = source.locations ? source.locations.slice(0) : [];
        copy.templates = source.templates ? source.templates.slice(0) : [];
        copy.teams = source.teams ? source.teams.slice(0) : [];

        if (!copy.locations || copy.locations.length == 0) {
            copy.locations = [];
            this.state.locations.forEach(function(t) {
                copy.locations.push(t.id);
                });
        }
        if (!copy.templates || copy.templates.length == 0) {
            copy.templates = [];
            this.state.templates.forEach(function(t) {
                copy.templates.push(t.id);
                });
        }
        if (!copy.teams || copy.teams.length == 0) {
            copy.teams = [];
            this.state.teams.forEach(function(t) {
                copy.teams.push(t.id);
                });
        }
        return copy;
    }

    editRule(id) {
        let dup = this.duplicateRule(id);
        if (dup) {
            this.setState({mode:'edit', currentrule: dup, isDirty:false});
        }
        else {
        }
    }
    copyRule(id) {
        let dup = this.duplicateRule(id);
        this.clearValueError();
        if (dup) {
            dup.id = '0';
            dup.isActive = false;
            dup.name = dup.name  + ' - copy';
            this.setState({mode:'edit', currentrule: dup, isDirty:true});
        }
    }
    
    reSortRules(therules) {
        if (!therules) {
            therules = this.state.rules;
        }
        therules.sort(function(recA, recB) {
            let a = recA.name.toLowerCase();
            let b = recB.name.toLowerCase();
            let res = 0;
            if (a < b) {
                res = -1;
            }
            if (a > b) {
                res = 1;
            }
            return res;
        });
    }

    ruleFormSaved(isNew) {
        /*if (this.state.withBlank) {
            let remain = this.state.currentrule.criteria.filter(crit => crit.field != '%blank%');
            this.state.currentrule.criteria = remain;
        }*/
        let listTemplates = this.state.currentrule.templates;
        let listLocations = this.state.currentrule.locations;
        let listTeams = this.state.currentrule.teams;

        listTemplates = listTemplates.length == this.state.templates.length ? [] : listTemplates;
        listLocations = listLocations.length == this.state.locations.length ? [] : listLocations;
        listTeams = listTeams.length == this.state.teams.length ? [] : listTeams;

        let api = quosal.api.admin.saveApprovalRule(this.state.currentrule.id, this.state.currentrule.name, this.state.currentrule.message,
            this.state.currentrule.isActive, this.state.currentrule.revieworder == 'sequential' ? 0 : 1, 
            listTemplates, listLocations, listTeams, 
            this.state.currentrule.criteria, this.state.currentrule.approvalChain);
        api.finished=function(msg) {
            if (this.state.currentrule.id == '0') {
                this.state.currentrule.id = msg.rule.IdApprovalRules;
                this.state.rules.push(this.state.currentrule);
                this.state.originalRules.push(this.state.currentrule)
            }
            else {
                for (let i = 0; i < this.state.rules.length; i++) {
                    if (this.state.rules[i].id == this.state.currentrule.id) {
                        this.state.rules[i] = this.state.currentrule;
                        this.state.originalRules[i] = JSON.parse(JSON.stringify(this.state.currentrule));
                        if (this.state.currentrule.message.indexOf('[DELETE THIS]')>0) {
                            this.state.rules.splice(i,1);
                            this.state.originalRules.splice(i,1);
                        }
                        break;
                    }
                }
            }

            this.reSortRules();
            this.setState({mode: 'list', isDirty:false});
            $.quosal.dialog.quickFullHide();
        }.bind(this);
        $.quosal.dialog.loading();
        api.call();
    }
    
    ruleFormCanceled(isNew){
        this.clearValueError();
        this.setState({mode: 'list', isDirty:false, rules: JSON.parse(JSON.stringify(this.state.originalRules))});
    }

    criteriaFromId(id) {
        if (id.length == 0) {
            return null;
        }
        for (let i=0; i<this.state.currentrule.criteria.length; i++) {
            if (this.state.currentrule.criteria[i].id == id) {
                return this.state.currentrule.criteria[i];
            }
        }
        return null;
    }

    updateActiveRule(field, value) {
        let dtype;
        switch (field) {
            case 'name': 
                this.state.currentrule.name = value;
                break;
            case 'message':
                this.state.currentrule.message = value;
                break;
            case 'locations':
                this.state.currentrule.locations = value;
                break;
            case 'locations-close':
                if (!this.state.currentrule.locations || this.state.currentrule.locations.length == 0) {
                    value = [];
                    this.state.locations.forEach(function(t) {
                        value.push(t.id);
                        });
                    this.state.currentrule.locations = value;
                }
                break;
            case 'templates':
                this.state.currentrule.templates = value;
                break;
            case 'templates-close':
                if (!this.state.currentrule.templates || this.state.currentrule.templates.length == 0) {
                    value = [];
                    this.state.templates.forEach(function(t) {
                        value.push(t.id);
                        });
                    this.state.currentrule.templates = value;
                }
                break;
            case 'teams':
                this.state.currentrule.teams = value;
                break;
            case 'teams-close':
                if (!this.state.currentrule.teams || this.state.currentrule.teams.length == 0) {
                    value = [];
                    this.state.teams.forEach(function(t) {
                        value.push(t.id);
                        });
                    this.state.currentrule.teams = value;
                }
                break;
            case 'revieworder':
                this.state.currentrule.revieworder = value;
                break;
            case 'isactive':
                this.state.currentrule.isActive = value;
                break;
            default:
                if (field[0] == 'c') {
                    let pos = field.indexOf(':');
                    if (pos > 0) {
                        let idCriteria = field.slice(1,pos);
                        let rowfield = field.slice(pos+1);
                        let element = this.criteriaFromId(idCriteria);
                        if (element) {
                            if(element.id in this.valueErrors && value != ''){
                                delete this.valueErrors[element.id]
                            }

                            switch (rowfield) {
                                case 'fieldgroup':
                                    element.fieldgroup = value;
                                    break;
                                case 'field':
                                    let oldlookup=this.lookupEnumsForField(element.fieldgroup, element.field);
                                    element.field = value;
                                    
                                    dtype = this.lookupFieldData(element.fieldgroup, element.field);
                                    var foundEnumValues = this.lookupEnumsForField(element.fieldgroup, element.field);
                                    let dataelem = kApprovalSettings.DataMatrix.find( (info) => {return info.datatype.toLowerCase() == dtype.toLowerCase();} );
                                    
                                    if (dataelem.options.indexOf(element.comparison) < 0) {
                                        element.comparison = 'eq';
                                    }

                                    if(foundEnumValues){
                                        element.value = foundEnumValues[0].Value;
                                    }
                                    else if (dtype == 'Boolean') {
                                        if (element.value !== true && element.value !== false) {
                                            element.value = false;
                                        }
                                    }
                                    //wipe out the current input value when changing field
                                    else if (dtype == 'Double' || dtype == 'String' || dtype == 'Int32') {
                                        element.value = '';
                                    }
                                    if (oldlookup && !foundEnumValues) {
                                        element.value = dtype == 'Boolean' ? false : '';
                                    }
                                    break;
                                case 'comparison':
                                    element.comparison = value;
                                    break;
                                case 'value':
                                    dtype = this.lookupFieldData(element.fieldgroup, element.field);
                                    element.value = this.conformCompareValue(value, dtype);
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                } 
                else if (field[0] == 'a') {
                    let apos = field.indexOf(':');
                    if (apos > 0) {
                        let arow = parseInt(field.slice(1,apos));
                        let arowfield = field.slice(apos+1);
                        if (arow > 0 && arow <= this.state.currentrule.approvalChain.length) {
                            switch (arowfield) {
                                case 'chaintype':
                                    this.state.currentrule.approvalChain[arow-1].chaintype = value;
                                    if(value == kApprovalSettings.ApproverTypeManager){
                                        this.state.currentrule.approvalChain[arow-1].chainidentifier = "inside";
                                    } else if(value == kApprovalSettings.ApproverTypeGroup){
                                        let defaultId = null;
                                        if(this.state.groups.length > 0){
                                            defaultId = this.state.groups[0].id;
                                        }
                                        this.state.currentrule.approvalChain[arow-1].chainidentifier = defaultId;
                                    }
                                    else if(value == kApprovalSettings.ApproverTypeUser){
                                        let defaultUserName = null;
                                        let defaultUserIndex = this.state.users.findIndex(function(user) {
                                            return user.IsApprover;
                                        });
                                        if(defaultUserIndex > -1){
                                            defaultUserName = this.state.users[defaultUserIndex].id;
                                        }
                                        this.state.currentrule.approvalChain[arow-1].chainidentifier = defaultUserName;
                                    }
                                    else{
                                        this.state.currentrule.approvalChain[arow-1].chainidentifier = null;

                                    }
                                    break;
                                case 'chainidentifier':
                                    this.state.currentrule.approvalChain[arow-1].chainidentifier = value;
                                    break;
                                default:
                                    break;
                            }
                            
                        }
                    }
                }
                break;
        }
        this.setState((prevState) => {
            return {changes: prevState.changecount + 1, isDirty: true};
          });
    }

    conformCompareValue(value, dType) {
        switch (dType) {
            case 'Boolean':
                if (value !== true && value !== false) {
                    let t = value.toLowerCase();
                    if (t == 'yes' || t == 'true' || t=='1') {
                        value = true;
                    } else if (t == 'no' || t == 'false' || t=='0') {
                        value = false;
                    }
                }
                break;
            case 'Double':
                value = value.replace(/[^\d.-]/g,'');   // TODO internationalize
                break;
            case 'String':
                //nothing
                break;
            case 'Int32':
                value = value.replace(/[^\d-]/g,'');
                break;
        }
        return value;
    }

    lookupFieldData(group, name) {
        let list = (group == 'item') ? this.state.itemfields : this.state.quotefields;
        let field = list.find( (f) => {return f.name.toLowerCase() == name.toLowerCase();} );
        if (field) {
            return field.datatype;
        }
        else {
            return 'String';
        }
    }

    addBlankApprover() {
        let record;
        let len = this.state.currentrule.approvalChain.length;
        if (len > 0) {
            record = {
                level: len+1,
                id: quosal.util.generateGuid(),
                chaintype: this.state.currentrule.approvalChain[len-1].chaintype,
                chainidentifier: this.state.currentrule.approvalChain[len-1].chainidentifier
            };
        }
        else {
            record = {level: 1, id: quosal.util.generateGuid(), chaintype:kApprovalSettings.ApproverTypeManager, chainidentifier: 'inside'};
        } 

        this.state.currentrule.approvalChain.push(record);
        this.setState((prevState) => {
            return {changes: prevState.changecount + 1, isDirty: true};
          });
    }

    removeApprover(id) {
        let remain = this.state.currentrule.approvalChain.filter(rev => rev.id != id);
        this.state.currentrule.approvalChain = remain;
        this.state.currentrule.approvalChain.forEach(function(approver, i) {
            approver.level = i+1;
            });
        this.setState((prevState) => {
            return {changes: prevState.changecount + 1, isDirty: true};
          });
    }

    addBlankCriteria() {
        let record;
        let len = this.state.currentrule.criteria.length;
        if (len > 0) {
            var fieldGroup = this.state.currentrule.criteria[len-1].fieldgroup;
            var field = this.state.currentrule.criteria[len-1].field;
            var foundValues = this.lookupEnumsForField(fieldGroup, field);
            var value = foundValues ? foundValues[0].Value : '';
            record = {
                sequence: len+1, 
                id: quosal.util.generateGuid(), 
                fieldgroup: fieldGroup, 
                field: field, 
                comparison: 'eq', 
                value: value
            };
        }
        else {
            record = {sequence: 1, id: quosal.util.generateGuid(), fieldgroup: 'quote', field: 'AccountName', comparison: 'eq', value: ''}
        }

        this.state.currentrule.criteria.push(record);
        this.setState((prevState) => {
            return {changes: prevState.changecount + 1, isDirty: true};
          });
    }
    removeCriteria(id) {
        let remain = this.state.currentrule.criteria.filter(crit => crit.id != id);
        this.state.currentrule.criteria = remain;
        this.setState((prevState) => {
            return {changes: prevState.changecount + 1, isDirty: true};
          });
    }

    checkDuplicate(id, name) {
        for (let i=0; i<this.state.rules.length; i++) {
            if (this.state.rules[i].name.trim().toLowerCase() == name.trim().toLowerCase() && this.state.rules[i].id != id) {
                return true;
            }
        }
        return false;
    }

    render() {
        if(this.state.isLoading){
            return(<div></div>);
        }
        else if (this.state.mode == 'edit' || this.state.mode == 'new') {
            return (<ApprovalRuleForm fnSave={this.ruleFormSaved} fnCancel={this.ruleFormCanceled} rule={this.state.currentrule} 
                        templates={this.state.templates} locations={this.state.locations} teams={this.state.teams}
                        groups={this.state.groups} users={this.state.users} formDirty={this.state.isDirty}
                        fnFieldUpdate={this.updateActiveRule} fnAddNewApprover={this.addBlankApprover}
                        fnRemoveApprover={this.removeApprover} fnCheckDuplicate={this.checkDuplicate} lookupEnumsForField={this.lookupEnumsForField}
                        itemfields={this.state.itemfields} quotefields={this.state.quotefields} fieldenums={this.state.enumerations}
                        fnMakeCopy={this.copyRule} fnAddNewCriteria={this.addBlankCriteria} fnRemoveCriteria={this.removeCriteria}
                        addValueError={this.addValueError} valueErrors={this.valueErrors}
            />);
        }
        else {
            return (<ApprovalRulesList fnAddNew={this.addNewRule} fnEditRule={this.editRule} 
                        fnCopyRule={this.copyRule} fnToggleActive={this.setRuleActiveState}
                        addQuick1={this.addQuick1} addQuick2={this.addQuick2}  addQuick3={this.addQuick3}
                        fnToggleQuick={this.toggleQuick}
                        rules={this.state.rules} groups={this.state.groups} users={this.state.users} templates={this.state.templates} isLoading={this.state.isLoading} />);
        }
    }

    addQuick1(usr, andnav) {
        let u = this.lookupUser(usr);
        let name = u ? u.DisplayName: 'user';
        let entry = {
            name: 'Review ' + name,
            message: 'Always review ' + name,
            criteria: [{
                        id: '0',
                        sequence: 1, 
                        fieldgroup: 'quote', field: 'Owner',
                        comparison: 'eq', value: usr} ],
        };
        this.quickAddUniqueAndOptNavigate(entry, andnav);
    }
    addQuick2(perc, andnav) {
        let entry = {
            name: 'Discount more than ' + perc,
            message: 'Always review discount greater than ' + perc + "%",
            criteria: [{
                        id: '0',
                        sequence: 1,
                        fieldgroup: 'quote', field: 'DiscountAmount',
                        comparison: 'ge', value: perc} ],
        };
        this.quickAddUniqueAndOptNavigate(entry, andnav);
    }

    addQuick3(amount, andnav) {
        let entry = {
            name: 'Quote total is over ' + amount,
            message: 'Always review totals greater than ' + amount,
            criteria: [{
                        id: '0',
                        sequence: 1, 
                        fieldgroup: 'quote', field: 'QuoteTotal',
                        comparison: 'ge', value: amount} ],
        };
        this.quickAddUniqueAndOptNavigate(entry, andnav);
    }
    
    quickAddUniqueAndOptNavigate(entry, andnav) {
        if (this.checkDuplicate(0, entry.name)) {
            let count = 0;
            do {
                count++;
            } while (this.checkDuplicate(0, entry.name + "-" + count));
            entry.name = entry.name + "-" + count;
        }
        entry.id = '0';
        entry.revieworder = 'sequential';
        entry.isActive = false;
        entry.templates = [];
        entry.approvalChain = [{level: 1, id: entry.id, chaintype:kApprovalSettings.ApproverTypeManager, chainidentifier: 'inside'}];

        //(id, name, notification, isActive, approvalorder, templates, locations, teams, criteria, approvers)
        let api = quosal.api.admin.saveApprovalRule('0', entry.name, entry.message,
                             entry.isActive, entry.revieworder == 'sequential' ? 0 : 1, 
                             [], [], [], 
                             entry.criteria, entry.approvalChain);
        api.finished=function(msg) {
            entry.id = msg.rule.IdApprovalRules;
            this.state.rules.push(entry);
            this.state.originalRules.push(entry);
            this.reSortRules();
            this.reSortRules(this.state.originalRules);
            this.setState({mode: 'list', isDirty:false});
        }.bind(this);
        api.call();

        // entry.id = '0';
        // this.setState({mode: 'new', currentrule: entry, isDirty:true});
    }

    lookupUser(id) {
        return this.state.users.find( (u) => {return u.id == id;} );
    }
    
    lookupGroup(id) {
        return this.state.groups.find( (g) => {return g.id == id;} );
    }
    
    lookupEnumsForField(group, field) {
        let key = group.toLowerCase() + '.' + field.toLowerCase();
        let found = this.state.enumerations.find( (f) => {return f.field.toLowerCase() == key;} );
        if (found) {
            return found.values;
        }
        else {
            return undefined;
        }
    }

    getItemFields(enumerations){
        let quoteItemFieldData = quosal.customization.fields[quosal.customization.fields.types.businessObject]['QuoteItems'];
        let fieldConfigs = quoteItemFieldData.fieldConfigurations;
        let masterlist = this.processFieldList(quoteItemFieldData, fieldConfigs, kApprovalSettings.CommonItem, 'item', enumerations, quoteItemFieldsHidden);
        return masterlist;
    }

    getQuoteFields(enumerations){
        let quoteFieldData = quosal.customization.fields[quosal.customization.fields.types.businessObject]['QuoteMain'];
        let fieldConfigs = quoteFieldData.fieldConfigurations;
        let masterlist = this.processFieldList(quoteFieldData, fieldConfigs, kApprovalSettings.CommonQuote, 'quote', enumerations, quoteMainFieldsHidden);

        return masterlist;
    }
    
    processFieldList(list, config, common, group, enumerations, fieldsToHide) {
        let masterlist = [];
        let processlist = function (inputFields) {
            for (let i = 0; i < inputFields.length; i++) {
                let field = inputFields[i];
                if (field.DataType == 'Byte[]' || field.IsPrivateField || fieldsToHide.includes(field.FieldName)) {
                    continue;
                }
                if (field.DataType == 'DateTime') {
                    continue; // later
                }

                let fieldConfig = config[field.FieldName];
                let displayName = fieldConfig && fieldConfig.FieldRename || field.DisplayName;
                let isCommon = common.indexOf(field.FieldName) >= 0;

                if (field.IsEnum == true) {
                    if (quosal.metadata.enums[field.EnumType]) {
                        enumerations.push({field:group+"."+field.FieldName, values:quosal.metadata.enums[field.EnumType] });
                    }
                }

                masterlist.push({
                    fieldgroup: group,
                    datatype: field.DataType,
                    name: field.FieldName,//id
                    display: displayName,
                    common: isCommon,
                    cancustomize: field.CanCustomizeDropdown,
                });
            }
        }.bind(this);
        processlist(list.standardFields.where(function (f) { return !f.IsPrivateField; }));
        processlist(list.additionalFields.where(function (f) { return !f.IsPrivateField; }));
        masterlist.sort(function(a, b) {
                                let res = 0;
                                if (a.display < b.display) {
                                    res = -1;
                                }
                                if (a.display > b.display) {
                                    res = 1;
                                }
                                return res;
                            });
        return masterlist;
    }
}
const quoteItemFieldsHidden = ['CrmReference', 'DisableRemaps', 'ExternalReference'];
const quoteMainFieldsHidden = ['ApiStatus', 'ApprovalStatus', 'PackagePrintHeaderPrice', 'PackagePrintItemPrice', 'PackageShowHeader', 'PackageShowItems', 'CacheDurationTime',
    'ConversionRate', 'ConvertedCurrency', 'CreateNotes', 'CrmProjectId', 'CrmSalesOrderId', 'CrmServiceTicketId', 'DefaultOppType', 'DefaultPriceList', 'DefaultReportList', 
    'DefaultSuccessProfile', 'DefaultTermPeriods', 'DefaultRecurringTermType', 'DefaultVideoList', 'DeliverDocumentType', 'EnableAdvancedRounding', 'ExternalReference',
    'GreatAmericaDefault', 'GridFormat', 'HasContactDate', 'IsArchive', 'IdCRMOpportunity', 'IdSourceCampaign', 'IncludeZeroSuggestedInDiscount', 'InvoicePostUserId',
    'IsAccepted', 'IsApproved', 'IsComplete', 'IsLost', 'IsOrderPorterCacheable', 'IsOrderPorterFirst', 'IsOrderPorterUploaded', 'IsRequestQuote', 'IsRequestTemplate', 'IsSent',
    'IsStandardModification', 'ItemsHaveQuoteId', 'LockStatus', 'LostReason', 'ModifyUserName', 'ConnectwiseOpGroup', 'OrderPorterApproved', 'OrderPorterAuthnet', 
    'OrderPorterCache', 'OrderPorterDbIndex', 'OrderPorterEmailSig', 'OrderPorterEsign', 'OrderPorterFilteredIp', 'OrderPorterFullpayment', 'OrderPorterGeneratedPdf',
    'OrderPorterInitialsSig', 'OrderPorterModified', 'OrderPorterPasscode', 'OrderPorterPaypal', 'OrderPorterRequestPasscode', 'OrderPorterShowImage', 'OrderPorterShowItemCb',
    'OrderPorterShowLineDetails', 'OrderPorterShowQty', 'OrderPorterShowSignature', 'OrderPorterShowTabCb', 'OrderPorterSignedIp', 'OrderPorterTemplate', 'OrderPorterTemplateDestination',
    'OrderPorterTerms', 'OrderPorterTheme', 'OrderPorterVisits', 'OrderStatus', 'PayfabricGatewayAcctName', 'PdfMergePosition', 'PeerReviewDocument', 'InvoicePostStatus',
    'ProductSearchPrefillMappingSet', 'PublishNumber', 'PublishEmailBcc', 'PublishEmailCc', 'QuickbooksTemplate', 'QuosalPurchasingNotes', 'QuoteAudioHashcode', 'QuoteAudioType',
    'QuoteStatus', 'QuoteVideoHashcode', 'QuoteVideoType', 'RemoteApprovalPacket', 'RemoteOpportunityId', 'RemoteTemplateName', 'ReportUris', 'RequestId', 'RequestedBy', 'RequiresNameChange',
    'ReviewEmailPeerEmails', 'SuccessDisabled', 'TemplateId', 'TrialTag', 'UserId', 'VersionComment', 'VersionedBy', 'WinTabFail', 'WinTabPass', 'WinTabWarning', 'DocusignEnvelopeId'];
global.ApprovalRules = ApprovalRules;