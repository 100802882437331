export class LineItemQuickFilterHeaderInput extends React.Component {
    render() {
        //props:
        //dataType
        //  "Boolean"
        //  "Double"
        //  "String"
        //  "Byte[]"
        //grid
        //key

        if (this.props.dataType === "Boolean") {
            return <LineItemQuickFilterCheckboxFilter {...this.props} filterValue={null} />;
        } else if (this.props.dataType === "Double" || this.props.dataType === "Int32") {
            return <LineItemQuickFilterNumericFilter {...this.props} filterValue={null} operator={null} />
        } else if(this.props.dataType === "String") {
            return <LineItemQuickFilterTextFilter {...this.props} filterValue={null} />
        } else {
            return <th className="nosort"></th>;
        }
    }
}

class LineItemQuickFilterCheckboxFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };       
        this.handleChange = this.handleChange.bind(this); 
    }
    handleChange() {
        var newValue = this.refs.filterInput.value;
        if (!newValue) {
            delete this.props.grid.state.lineItemQuickFilter.inputs[this.props.index];
        } else {
            this.props.grid.state.lineItemQuickFilter.inputs[this.props.index] = {
                type: 'boolean',
                value: newValue
            };
        }
        this.props.grid.forceUpdate();
    }
    render() {
        var inputState = this.props.grid.state.lineItemQuickFilter.inputs[this.props.index];
        var filterValue = inputState ? inputState.value : '';

        var className = 'nosort' + (filterValue == '' ? '' : ' activeFilter');

        return <th key={this.props.key + '_th'} className={className}>
            <div className="formselectfieldwrapper booleanFilter">
                <select ref="filterInput" value={filterValue} className="formselectfield " onChange={this.handleChange} disabled={false} >
                    <option value="">&nbsp;</option>
                    <option value="checked">&nbsp;[&#10003;]</option>
                    <option value="unchecked">&nbsp;[&nbsp;&nbsp;]</option>
                </select>
            </div>
        </th>;
    }
}
class LineItemQuickFilterNumericFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            uncommittedValue: null
        }; 
        this.handleChange = this.handleChange.bind(this); 
        this.handleOperatorChange = this.handleOperatorChange.bind(this);
        this.handleChangeAfterTimeout = this.handleChangeAfterTimeout.bind(this);

        this.timeoutID= null;
    }
    handleChange() {
        var newValue = this.refs.filterInput.value,
            newOperator = this.refs.filterInputOperator.value;
        this.setState({uncommittedValue: null});
        if (!newValue && !newOperator) {
            delete this.props.grid.state.lineItemQuickFilter.inputs[this.props.index];
        } else {
            this.props.grid.state.lineItemQuickFilter.inputs[this.props.index] = {
                type: 'numeric',
                operator: newOperator,
                value: newValue
            };
        }
        this.props.grid.forceUpdate();
    }
    handleOperatorChange() {
        this.refs.filterInput.select();
        this.handleChange();
    }
    handleChangeAfterTimeout(event) {
        var newValue = this.refs.filterInput.value;
        this.setState({uncommittedValue: newValue});

        window.clearTimeout(this.timeoutID);
        this.timeoutID = window.setTimeout(function() {
            this.handleChange(event);
            this.timeoutID = null;
        }.bind(this), 300);
    }
    render() {
        var inputState = this.props.grid.state.lineItemQuickFilter.inputs[this.props.index];
        var committedValue = inputState ? inputState.value : '';
        var filterValue = this.state.uncommittedValue !== null ? this.state.uncommittedValue : committedValue;
        var operator = inputState ? inputState.operator : '';
        var className = 'nosort numeric' + (committedValue || operator ? ' activeFilter' : '');

        return <th key={this.props.key + '_th'} className={className}>
            <div className="formselectfieldwrapper numericFilterOperator">
                <select ref="filterInputOperator" value={operator} className="formselectfield " onChange={this.handleOperatorChange} disabled={false} >
                    <option value="">&nbsp;*&nbsp;&nbsp;(string match)</option>
                    <option value="equal">&nbsp;=</option>
                    <option value="lt-equal">&nbsp;&le;</option>
                    <option value="gt-equal">&nbsp;&ge;</option>
                    <option value="approx-equal">&nbsp;&#8776;&nbsp;&nbsp;(approximately equal)</option>
                </select>
            </div>
            <input ref="filterInput" className="numericFilter " type="text" value={filterValue} onChange={this.handleChangeAfterTimeout} disabled={false} />
        </th>;
    }
}
class LineItemQuickFilterTextFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {    
            uncommittedValue: null
        }; 
        this.handleChange = this.handleChange.bind(this); 
        this.handleChangeAfterTimeout = this.handleChangeAfterTimeout.bind(this);

        this.timeoutID= null;
    }   
    handleChange(event) {
        var newValue = this.refs.filterInput.value;
        this.setState({uncommittedValue: null});
        if (!newValue) {
            delete this.props.grid.state.lineItemQuickFilter.inputs[this.props.index];
        } else {
            this.props.grid.state.lineItemQuickFilter.inputs[this.props.index] = {
                type: 'text',
                value: newValue
            };
        }
        this.props.grid.forceUpdate();
    }
    handleChangeAfterTimeout(event) {
        var newValue = this.refs.filterInput.value;
        this.setState({uncommittedValue: newValue});

        window.clearTimeout(this.timeoutID);
        this.timeoutID = window.setTimeout(function() {
            this.handleChange(event);
            this.timeoutID = null;
        }.bind(this), 300);
    }
    render() {
        var inputState = this.props.grid.state.lineItemQuickFilter.inputs[this.props.index];
        var committedValue = inputState ? inputState.value : '';
        var filterValue = this.state.uncommittedValue !== null ? this.state.uncommittedValue : committedValue;
        var className = 'nosort' + (committedValue ? ' activeFilter' : '');

        return <th key={this.props.key + '_th'} className={className}>
            <input ref="filterInput" className="textFilter " type="text" value={filterValue} onChange={this.handleChangeAfterTimeout} disabled={false} />
        </th>;
    }
}

export class LineItemQuickFilterMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };       
        this.createMessageHTML = this.createMessageHTML.bind(this); 
    }
    createMessageHTML() {
        var checkedRows = this.props.checkedRows,
            showingRows = this.props.showingRows,
            totalRows = this.props.totalRows,
            checkedHiddenRows = this.props.checkedHiddenRows;
        return {__html:
            '<span class="count">' + checkedRows + '</span> checked <span>/</span> <span>' + showingRows + '</span> showing <span>/</span> <span>' + totalRows + '</span> total in tab'
            + ((checkedHiddenRows == 0) ? '' : ' &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(In addition, <span>' + checkedHiddenRows + '</span> ' + ((checkedHiddenRows == 1) ? 'is' : 'are') + ' checked but hidden. No action will apply.)')
        };
    }
    render() {
        return <tr key="filterMessageHeader" className="liqf_tr_message">
            <th /><th className="checkAllHeader" ><input type="checkbox" onChange={this.props.checkAll} checked={this.props.checkedRows} /></th>
            <th colSpan={this.props.colSpan /*extends through the rest of the columns*/} >
                <div className="liqf_div_message" dangerouslySetInnerHTML={this.createMessageHTML()}/>
            </th>
        </tr>;
    }
}