import SyncIcon from '@mui/icons-material/Sync';
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useAppQuoteContext } from "../../../../../../../../context/AppQuoteContext";
import { AppTheme } from "../../../../../common/theme";

export const QuoteTotalWarning = () => {
    return (
        <AppTheme>
            <QuoteTotalWarningInner />
        </AppTheme>
    )
};


const QuoteTotalWarningInner = () => {
    const theme = useTheme();
    const { manualSyncEnabled, hasManualSyncWarning, updateQuoteTotal, updatingQuote } = useAppQuoteContext();
    const background = hasManualSyncWarning ? theme.palette.quote_step.activeStepColor : theme.palette.quote_step.lockedColor;
    const cursor = hasManualSyncWarning ? "pointer" : "default";
    const disabled = !hasManualSyncWarning;
    const title = hasManualSyncWarning ? "Your quote totals are out of sync." : "Your quote totals are updated.";
    const color = hasManualSyncWarning ? theme.palette.quote_stepper.activeFontColor : "white";
    const chipText = updatingQuote ? "Recalculating" : hasManualSyncWarning ? "Recalculate" : "Quote Synced";  
    
    return ( manualSyncEnabled ? (
        <Tooltip style={{marginLeft: "10px"}} title={title}>
                <Chip onClick={disabled ? undefined : updateQuoteTotal} style={{alignSelf: "end", marginLeft: "auto", background, cursor }}
                    label={
                        <div style={{ display: "flex", alignItems: "center", color }}>
                            {chipText}
                            <div style={{ marginLeft: "5px" }}>
                                {updatingQuote ?
                                    <CircularProgress style={{ marginLeft: "5px" }} size={15} /> :
                                    hasManualSyncWarning ?
                                        <SyncIcon /> : null}
                            </div>
                        </div>
                    }
                />
        </Tooltip> ) : null
    );
};
