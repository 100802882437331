import { fetchData } from "../../../product/newUI/services/fetchData";


export const getDefaultWinDOcOptions = (payload) => {
	return fetchData("quosalweb/quosalapi/Quote.DefaultWinDoc", "Quote.DefaultWinDoc", payload);
};

export const getOpTemplateOptions = (payload) => {
	return fetchData("quosalweb/quosalapi/Quote.OrderPorterTemplate", "Quote.OrderPorterTemplate", payload);
};

export const getPaymentProcessingOptions = (payload) => {
	return fetchData("quosalweb/quosalapi/Quote.PaymentProcessing", "Quote.PaymentProcessing", payload);
};