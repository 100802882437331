import { renderToStaticMarkup } from 'react-dom/server';
import { IdentityWarningToast } from './quote/newUI/quoteHeader/common/components/IdentityWarningToast';
import { AppTheme } from './common/theme';
class QuosalBIIcon extends React.Component {
    toggleBIMenu(){
        $('#BIDashboardsLinks').toggle();
    }
    render() {
        var multipleDashboards = this.props.dashboards.length > 1;
        return (
            <div className="menuitem" title="Sell Intel" id="BIContainerReact">
                { multipleDashboards 
                ? 
                <div id="BIMenuContainer" className="leftsidebarlinks">
                    <div className="menulogo sidebarBI"><CwWidgets.CwImage src="img/svgs/sell/Application_SellIntel.svg" isInlineSVG="true"/></div>
                    <div className="leftsidebartitle">Sell Intel</div>
                </div>
                : 
                <a href={quosal.util.url('powerbidashboard.quosalweb')} className="leftsidebarlinks">
                    <div className="menulogo sidebarBI"><CwWidgets.CwImage src="img/svgs/sell/Application_SellIntel.svg" isInlineSVG="true"/></div>
                    <div className="leftsidebartitle">Sell Intel</div>
                </a>
                }
            </div>)
    }
}

class BISideMenu extends React.Component {
    componentDidMount(){
      var dashboardsList = this.props.dashboards;
        for(var i = 0; i < dashboardsList.length; i++){
            var anchor = quosal.util.url('powerbidashboard.quosalweb', 'idPowerBiTileset=' + dashboardsList[i].id);
            $('#BIDashboardsLinks').append('<div id="' + i + '" class="sidebarChildMenuItem"></div>');
            $('#BIDashboardsLinks > #' +i+'.sidebarChildMenuItem').append('<a href="' + anchor + '" style="display:block">' + dashboardsList[i].name + '</a>')
        }
    }
    render(){
        return (
            <div id="BIDashboardsLinks" className="sidebarChildMenu" />
        )
    }
}

class CatalogLoginForm extends React.Component {
   render(){
       return(
            <form className="catalogLoginForm" action={app.settings.user.catalogLoginUrl} target="_blank" method="get" style={{display:'none'}}>
                <input type="hidden" name="username" value={app.currentUser.EmailAddress} />
                {app.currentUser.IsAdministrator ? <input type="hidden" name="special" value="yes" /> : null}
                <input type="hidden" name="quosalcatalog-accesskey" value={app.settings.user.catalogAccessKey} />
                <input type="hidden" name="cdkey" value={app.entity.CDKey} />
                <input type="hidden" name="firstname" value={app.currentUser.FirstName} />
                <input type="hidden" name="lastname" value={app.currentUser.LastName} />
            </form>
       )
   }
}

export class LeftHeader extends React.Component {
    constructor(props) {
        super(props);

        this.collapseToggle = this.collapseToggle.bind(this);
        this.initToggle = this.initToggle.bind(this);

        this.state = {
        };
    }
    toggleHelpMenu(){
        //check for display, hide if it is shown, display if hidden.
      $('#helpLinks').toggle();
    } 
    getDashboardsList() {
        //I have to add an api method... this is relying on an input being swapped in by c# code.
        var views = $('#quosalbiviews').val();
        if (!views)
            return undefined;
        var json = $.parseJSON(views);
        if (json.tiles.length <= 1)
            return 0;
        return json.tiles.map(function (tile) {
            return {
                name: tile.name,
                anchor: $.quosal.util.url('powerbidashboard.quosalweb', 'idPowerBiTileset=' + tile.id)
            };
        });
    }
    initToggle() {
        this.state.collapsed = !quosal.util.cookie('cwSell_leftPanelIsCollapsed');
        this.collapseToggle();
    }
    collapseToggle(){
        if (this.state.collapsed){
            quosal.util.cookie('cwSell_leftPanelIsCollapsed', '');
            $('#mainContent').removeClass('leftBarCollapsed');
            this.setState({collapsed: false});
        } else {
            quosal.util.cookie('cwSell_leftPanelIsCollapsed', 'collapsed');
            $('#mainContent').addClass('leftBarCollapsed');
            this.setState({collapsed: true});
        }
    }
    componentDidMount(){
        var closeAllMenus = function closeAllMenus(e) {
            $('.sidebarChildMenu').css('display', 'none');
            $('#profileMenu').css('display', 'none');
            $(document.body).off('click', closeAllMenus);

            $('.hoveredMenuItem').removeClass('hoveredMenuItem');

        };
        var handleClickOnMenu = function (e) {
            e.stopPropagation();
        };
        var addClickClose = function () {
            $('body').on('click', closeAllMenus);
            $('.sidebarChildMenu').on('click', handleClickOnMenu);
        };
        var toggleMenu = function (container, menu, e) {
            if ($(menu).is(':hidden')) {
                closeAllMenus();
                $(menu).show();
                e.stopPropagation();
                addClickClose();
            }
            else {
                $(menu).hide();
            }
            $(container).toggleClass("hoveredMenuItem");
        };
        $('#helpSidebar .leftsidebarlinks').on('click', toggleMenu.bind(null, "#helpSidebar", "#helpLinks"));
        $('#BIContainerReact .leftsidebarlinks').on('click', toggleMenu.bind(null, "#BIContainerReact", "#BIDashboardsLinks"));

        $.quosal.sell.goToCatalog = function () {
            $('form.catalogLoginForm').unbind().first().submit();
        };
        $('#quosalCatalogLink').click($.quosal.sell.goToCatalog);
        $('#quosalHelpLink').click($.quosal.help.goToHelpPage);
        quosal.navigation.parse(this.refs.root);

        this.initToggle();
    }
    render(){
        var supportChatAssistURL = this.props.overrideDummyData || app.settings.global.clientSettings.SupportChatAssistURL;
        var supportSellPortalURL = this.props.overrideDummyData || app.settings.global.clientSettings.SupportSellPortalURL;
        var useCatalog = this.props.overrideDummyData ? false : app.settings.user.useCatalog;
        var isAdmin = this.props.overrideDummyData || app.currentUser.IsAdministrator;
        var isPremiumAuthorized = this.props.overrideDummyData || quosal.validation.isPackageLevelAuthorized(app.packageLevels.premium);
        var isStandardAuthorized = this.props.overrideDummyData || quosal.validation.isPackageLevelAuthorized(app.packageLevels.standard);
        var buildInfo1 = this.props.overrideDummyData || (app.settings.global.isLegacyPremiumPackageLevel ? '' : app.getPackageLevelDispayName(app.settings.global.packageLevel));
        var buildInfo2 = this.props.overrideDummyData || app.settings.global.buildInfo;


        var user = app.settings.user;
        var isQuosalOne = app.entity && app.entity.IsQuosalOne;
        var dashboardsList = app.settings.user.powerBIDashboards && app.settings.user.powerBIDashboards.tiles;
        var useBI = dashboardsList;
        var multipleBIDashboards = useBI ? dashboardsList.length > 1 : false;
        var collapseClassName = this.state.collapsed ? 'collapsed' : '';

        return (
            <div>
                <div id="leftSidebarCollapseToggle" className={collapseClassName} onClick={this.collapseToggle}>
                    { this.state.collapsed ?
                        <svg className="openSvgIcon" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 48 48" focusable="false" viewBox="0 0 48 48">
                            <g>
                                <path d="M33.4 25.4l-16 16c-.4.4-.9.6-1.4.6s-1-.2-1.4-.6c-.8-.8-.8-2 0-2.8L29.2 24 14.6 9.4c-.8-.8-.8-2 0-2.8.8-.8 2-.8 2.8 0l16 16c.8.8.8 2 0 2.8z"></path>
                                <path d="M0 0h48v48H0z" fill="none"></path>
                            </g>
                        </svg>
                        : <svg className="closeSvgIcon" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 48 48" focusable="false" viewBox="0 0 48 48">
                            <g>
                                <path d="M33.4 38.6c.8.8.8 2 0 2.8-.4.4-.9.6-1.4.6s-1-.2-1.4-.6l-16-16c-.8-.8-.8-2 0-2.8l16-16c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8L18.8 24l14.6 14.6z"></path>
                                <path d="M0 0h48v48H0z" fill="none"></path>
                            </g>
                        </svg>
                    }
                </div>
                <div id="leftSidebar" ref="root" className={collapseClassName} >
                    <div id="menuitems">
                        {useCatalog? <CatalogLoginForm /> : null}
                        <a name="Home" href={quosal.util.url('home')} className="leftsidebarlinks"><div className="menuitem" title="Home"><div className="menulogo sidebarDashboard"><CwWidgets.CwImage src="img/svgs/v1.0/Application_Dashboard.svg" isInlineSVG="true"/></div><div className="leftsidebartitle">Dashboard</div></div></a>
                        {useBI && !isQuosalOne? <QuosalBIIcon dashboards = {dashboardsList} /> : null}
                        <a name="Open Quote" href={quosal.util.url('quote.search', 'idquotemain')} className="leftsidebarlinks"><div className="menuitem" title="Open Quote"><div className="menulogo sidebarQuotes"><CwWidgets.CwImage src="img/svgs/v1.0/Action_OpenQuote.svg" isInlineSVG="true"/></div><div className="leftsidebartitle">Quotes</div></div></a>
                        {isQuosalOne ? null :
                            <a name="SuccessList" href={quosal.util.url('takeaction.quosalweb')} className="leftsidebarlinks">
                                <div className="menuitem" title="SuccessList">
                                    <div className="menulogo sidebarSuccessList"><CwWidgets.CwImage src="img/svgs/v1.0/Menu_SuccessList.svg" isInlineSVG="true"/></div>
                                    <div className="leftsidebartitle">SuccessList</div>
                                </div>
                            </a>
                        }
                        { useCatalog && isStandardAuthorized ?
                        <div className="menuitem" title="Catalog"><div id="quosalCatalogLink" className="leftsidebarlinks"><div className="menulogo sidebarCatalog"><CwWidgets.CwImage src="img/svgs/sell/Application_Catalog.svg" isInlineSVG="true"/></div><div className="leftsidebartitle">Catalog</div></div></div>
                        : null }
                        <div className="menuitem" title="Help" id="helpSidebar">
                            <div className="leftsidebarlinks"><div className="menulogo sidebarHelp"><CwWidgets.CwImage src="img/svgs/v1.0/Application_Help.svg" isInlineSVG="true"/></div><div className="leftsidebartitle">Help</div>
                            </div>
                        </div>
                        
                       <a name="Settings" href={quosal.util.url('adminhome.quosalweb')} className="leftsidebarlinks"><div className="menuitem" title="Settings"><div className="menulogo sidebarSettings"><CwWidgets.CwImage src="img/svgs/v1.0/Menu_Settings.svg" isInlineSVG="true"/></div><div className="leftsidebartitle">Settings</div></div></a>

                    </div>
                    <div className="buildInfo">
                        {buildInfo1}<br />
                        {buildInfo2}
                    </div>
                </div>
                <div id="leftSidebarChildMenu">
                    <div id="helpLinks" className="sidebarChildMenu">
                        <div className="sidebarChildMenuItem"><a target="_blank" href={supportChatAssistURL}>ConnectWise Chat</a></div>
                        <div className="sidebarChildMenuItem"><a target="_blank" href={supportSellPortalURL}>Support</a></div>
                        <div className="sidebarChildMenuItem"><a target="_blank" href="https://docs.connectwise.com/ConnectWise_Sell/Sell_Release_Notes">Release Notes</a></div>
                        <div className="sidebarChildMenuItem"><a target="_blank" id="quosalHelpLink" title="ConnectWise University" href="#">Documentation</a></div>
                        <div className="sidebarChildMenuItem"><a target="_blank" title="Enhancement Forum" href="https://university.connectwise.com/university/authentication/default.aspx?ReturnUrl=%2FUniversity%2Fauthentication%2FUserEchoSSO.ashx%3FReturnUrl%3Dproduct.connectwise.com%2Fforums%2F6-sell-enhancements%2F#">Feedback</a></div>
                    </div>
                    {multipleBIDashboards && !isQuosalOne ? <BISideMenu dashboards = {dashboardsList}/> : null}
                </div>
            </div>
        )
    }
}
global.LeftHeader = LeftHeader;

export class TopHeader extends React.Component {
    componentDidMount() {
        const enable = 1;
        const disable = 0;

        $('#dark-toggle').prop('checked', $('body').hasClass('darkMode'));

        var closeAllMenus = function closeAllMenus(e) {
            $('.sidebarChildMenu').css('display', 'none');
            $('#profileMenu').css('display', 'none');
            $(document.body).off('click', closeAllMenus);
            
            $('.hoveredMenuItem').removeClass('hoveredMenuItem');
        };
        var handleClickOnMenu = function (e) {
            e.stopPropagation();
        };
        var toggleProfileMenu = function (e) {
            var menu = '#profileMenu';
            if ($(menu).is(':hidden')) {
                closeAllMenus();
                $(menu).show();
                e.stopPropagation();
                $('body').on('click', closeAllMenus);
                $(menu).on('click', handleClickOnMenu);
            }
            else {
                $(menu).hide();
            }
        }

        $('#headerProfilePic').on('click', toggleProfileMenu);
        $('.profileEditArrow').on('click', toggleProfileMenu);

        var handleOnClick = function () {
            if ($('#dark-toggle').prop('checked')) {
                $.quosal.ui.updateDarkMode(enable);
            } else {
                $.quosal.ui.updateDarkMode(disable);
            }
        }
        $('#dark-toggle').on('click', handleOnClick);

        quosal.navigation.parse(this.refs.root);
    }

    render() {
        var user = app.settings.user;
        var newNotificationsCount = parseInt(app.settings.user.notifyCount, 10);
        var disableNewQuoteEmbedded = !user.showNewQuoteInSkipLayout;
        var addLicenceUrl = app.settings.global.cwAddLicensesURL;
        var enableWalkMeButton = app.featureFlags.EnableWalkMeButton;
        var originalUserUserId = app.originalUserUserId;

        return (
            <div style={{width: '100%'}} ref="root">
                <div className="headerLeft">
                    <a href={quosal.util.url('home')}>
                        <div className="headerLogo"><img style={{width: '88px', height: '48px'}}
                                                         src="skins/quosal/images/post-koala-logos/logo-white.png"/>
                        </div>
                    </a>
                    {app.currentUser.IsReadOnly || (disableNewQuoteEmbedded && $.queryString('skiplayout')=='yes') ? null:
                        <a href={quosal.util.url('quote.picktemplate')}>
                            <div className="topHeaderLink">
                                <img src="img/svgs/v1.0/Action_AddNew.svg" className="headerIcon"/>
                                <span>New Quote</span>
                            </div>
                        </a>
                    }
                </div>
                <div className="headerRight">
                    <QuickQuoteSearch cwUnifiedTheme={true}></QuickQuoteSearch>
                    {enableWalkMeButton ?
                    <div className="headerRightContent walkMeButton">
                            <div id="walkme" title="Launch Help Center" ><img src="img/svgs/sell/Default_Board.svg" isinlinesvg="true" className="walkMeButtonImg"/></div>
                        </div>
                    : null
                    }
                    <div className="headerRightContent notificationsHeader">
                        <a href={quosal.util.url('notifications.quosalweb')}>
                            <div className="notifierContainer">
                                {!isNaN(newNotificationsCount) && newNotificationsCount > 0 ?
                                <div id="notifier" 
                                    title="You have new notifications"><CwWidgets.CwImage id="notifierImg" src="img/svgs/sell/Application_NewNotification.svg" isInlineSVG="true"/>
                                        <div id="notifycount" className="hidden notifycount">{newNotificationsCount}</div>
                                    </div> :
                                            <div id="notifier" className="inactive"
                                    title="You have no new notifications"><CwWidgets.CwImage id="notifierImg" src="img/svgs/v1.0/Application_Notification.svg" isInlineSVG="true"/>
                                        <div id="notifycount" className="hidden notifycount">0</div>
                                    </div> }
                            </div>
                        </a>
                    </div>                     
                    <div className="headerRightContent profilePic" id="headerProfilePic">
                        <img src={app.settings.user.repPicture} className="rounded-rep" name="RepPicture"/>
                    </div>
                    <div className="headerRightContent profileEditArrow">
                    </div>
                    <div id="profileMenu">
                        <div className="profileMenuSubtitle">{app.currentUser.FirstName}</div>
                        <a href={quosal.util.url('edituser.quosalweb', 'profile=mine')}>
                            <div className="profileMenuItem">My Profile</div>
                        </a>
                        <DarkModeSwitch/>
                        {
                            originalUserUserId && 
                            <a name="EndImpersonation" href={quosal.util.url('deviceid.quosalweb') + '&originalUserId=' + originalUserUserId}>
                                <div className="profileMenuItem">End Impersonation</div>
                            </a>
                        }
                        {addLicenceUrl ? <a href={addLicenceUrl} target="_blank">
                            <div className="profileMenuItem">Purchase Licenses</div>
                        </a> : null}
                        <a href={quosal.util.url('logout.quosalweb')}>
                            <div className="profileMenuItem">Log Out</div>
                        </a>
                    </div>

                </div>
            </div>
        )
    }
}

export class QuosalHeader extends React.Component {
    componentDidMount() {
        var count = parseInt(app.settings.user.notifyCount, 10);
        if (!isNaN(count) && count > 0) {
            $('#notifycount').html(count);
            $('#notifierImg').attr('src', 'img/svgs/sell/Application_NewNotification.svg');
            $.quosal.updates.notifications();
        }

        $('#liquidmenu').click(function () {
            var menu = $('.menuContainer');
            if ($(menu).hasClass('hideliquid')) {
                $.cookie('c_liquidmenu-btn', 'visible', { expires: 14 });
                $('.menuContainer').removeClass("hideliquid");
            } else {
                $.cookie('c_liquidmenu-btn', 'hidden', { expires: 14 });
                $('.menuContainer').addClass("hideliquid");
            }
        });


        var dashboards = app.settings.user.powerBIDashboards;
        var serverMode = app.settings.user.serverMode.indexOf('QA') >= 0 ? 'QA' : app.settings.user.serverMode;
        var match;
        if (serverMode == 'RELEASE')
            match = location.pathname.toLowerCase().indexOf('/quosalweb/') >= 0;
        else
            match = location.pathname.toLowerCase().indexOf(serverMode.toLowerCase()) >= 0;

        if (!match || serverMode != 'RELEASE') {
            if (!match) {
                $('#serverModeLabel').css('background-color', '#f33');
                $('#serverModeLabel').attr('title', 'Your app server mode and browser URL do not match and may result in unexpected behavior.');
            }

            $('#serverModeLabel').text(serverMode + app.settings.global.buildInfo.replace('BUILD', '').replace('(', '').replace(')', ''));
            $('#serverModeLabel').show();
        }
        //todo the below needs to be only on old header, and can probably be cleaned up
        $.quosal.ui.menu = function (data, container) {
            if (!data || !data.nodes) {
                return;
            }

            var userSettings = {};

            try {
                userSettings = JSON.parse($('#userOptions').val());
            } catch (ex) { }

            var detectLiquidMenuElement = null;

            var menuStrip = $('<div class="menustrip" />');

            var createSubMenu = function (parentMenuItem, nodes) {
                var subMenu = $('<div class="submenu" />');

                for (var i = 0; i < nodes.length; i++) {
                    var menuNode = nodes[i];

                    if (menuNode.omitQuosalOne && userSettings.isQuosalOne)
                        continue;

                    var menuItem = $('<div class="submenuitem" />');

                    if (menuNode.image) {
                        menuItem.append('<img src="' + menuNode.image + '" />');
                    } else if (menuNode.icon) {
                        menuItem.append('<div class="icon ' + menuNode.icon + '" />');
                    }
                    menuItem.append('<div class="title">' + menuNode.name + '</div>');
                    menuItem.data('node', menuNode);

                    if (menuNode.click) {
                        menuItem.click(function (e) {
                            e.stopImmediatePropagation();

                            var node = $(this).data('node');
                            node.click(this);
                        });
                    }

                    if (menuNode.anchor) {
                        var newAnchor;
                        if (menuNode.anchor_newtab) {
                            newAnchor = $('<a target="_blank" href="' + menuNode.anchor + '" />');
                        } else {
                            newAnchor = $('<a name="' + menuNode.name + '" href="' + menuNode.anchor + '" />');
                        }
                        newAnchor.append(menuItem);
                        menuItem = newAnchor;
                    }
                    if (menuNode.name == 'darkMode') {
                        menuItem.html(renderToStaticMarkup(<DarkModeSwitch/>));
                    }
                    subMenu.append(menuItem);

                    menuItem.mouseenter(function (e) {
                        // ER 1/11/18
                        //if ($(this).children('.icon').length > 0) {
                        //    $(this).children('.icon').css('background-image', $(this).children('.icon').css('background-image').replace('.png', '-hover.png'));
                        //}
                        //if ($(this).children('img.more').length > 0) {
                        //    $(this).children('img.more').attr('src', $(this).children('img.more').attr('src').replace('.png', '-hover.png'));
                        //}
                    });
                    menuItem.mouseleave(function (e) {
                        // ER 1/11/18
                        //if ($(this).children('.icon').length > 0) {
                        //    $(this).children('.icon').css('background-image', $(this).children('.icon').css('background-image').replace('-hover.png', '.png'));
                        //}
                        //if ($(this).children('img.more').length > 0) {
                        //    $(this).children('img.more').attr('src', $(this).children('img.more').attr('src').replace('-hover.png', '.png'));
                        //}
                    });

                    if (menuNode.class)
                        menuItem.addClass(menuNode.class);

                    if (menuNode.children) {
                        menuItem.append('<img src="skins/quosal/images/menu/more-menu.png" class="more" />');
                        createSubMenu(menuItem, menuNode.children);

                        menuItem.hoverIntent({
                            timeout: 300,
                            over: function () {
                                if (detectLiquidMenuElement.is(':hidden')) {
                                    var m = $(this).children('.submenu');
                                    m.css('left', $(this).position().left + $(this).outerWidth());
                                    m.css('top', $(this).position().top);

                                    m.show();
                                }
                            },
                            out: function () {
                                if (detectLiquidMenuElement.is(':hidden')) {
                                    var m = $(this).children('.submenu');
                                    m.hide();
                                }
                            }
                        });

                        menuItem.click(function (e) {
                            if (detectLiquidMenuElement.is(':visible')) {
                                e.stopImmediatePropagation();
                                var m = $(this).children('.submenu');

                                if (m.hasClass('visible'))
                                    m.removeClass('visible');
                                else
                                    m.addClass('visible');

                            }
                        });
                    }
                }

                parentMenuItem.append(subMenu);
            };

            for (var i = 0; i < data.nodes.length; i++) {
                var menuNode = data.nodes[i];

                if (menuNode.omitQuosalOne && userSettings.isQuosalOne)
                    continue;

                if (menuNode.name == 'vr' || menuNode.name == 'hr') {
                    continue;
                }

                if (menuNode.hidden) {
                    continue;
                }

                var menuItem;
                if (menuNode.title)
                    var menuItem = $('<div class="menuitem" title="' + menuNode.title + '" />');
                else
                    var menuItem = $('<div class="menuitem" />');

                if (menuNode.image) {
                    var str = '<img src="' + menuNode.image + '"';
                    if (menuNode.imageWidth)
                    {
                        str += ' width="' + menuNode.imageWidth + '"';
                    }
                    if (menuNode.imageHeight)
                    {
                        str += ' height="' + menuNode.imageHeight + '"';
                    }
                    if (menuNode.imagefill)
                    { 
                        // this property will not be respected for .svg files. 
                        str += ' fill="' + menuNode.imagefill + '"';
                    }
                    str += ' />';
                    menuItem.append(str);
                } 
                else if (menuNode.cwImage) {
                    // // ER 1/11/18: menuNode.cwImage type should not be used as it has a bug currently. The issue issue is  with menuItem.append(str) JQuery method creating
                    // a non existing <cwwidgets.wwimage /> DOM object instead of <CwWidgets.CwImage />. 
                    var str = '<CwWidgets.CwImage class="cwImage" src="' + menuNode.cwImage + '"';
                    if (menuNode.imageWidth)
                    {
                        str += ' width="' + menuNode.imageWidth + '"';
                    }
                    if (menuNode.imageHeight)
                    {
                        str += ' height="' + menuNode.imageHeight + '"';
                    }      
                    if (menuNode.imagefill)
                    {
                        str += ' fill="' + menuNode.imagefill + '"';
                    }
                    if(menuNode.isInlineSVG)
                    {
                        str += ' isInlineSVG="' + menuNode.isInlineSVG + '"';
                    }
                    str += ' />';
                    menuItem.append(str);
                }else if (menuNode.icon) {
                    menuItem.append('<div class="icon ' + menuNode.icon + '" />');
                }
                menuItem.append('<div class="title">' + menuNode.name + '</div>');
                if (menuNode.children) {
                    menuItem.append('<img src="skins/quosal/images/menu/more-menu.png" class="more" />');
                }

                var menuItemAndClone = menuItem;
                if (menuNode.rightSideButton) {
                    menuItem.addClass('narrowMenuOnly');
                    menuItemAndClone = menuItemAndClone.add($(menuNode.rightSideButton));
                }

                // ER 1/11/18
                //menuItem.mouseenter(function () {
                //    $(this).children('img').each(function () {
                //        $(this).attr('src', $(this).attr('src').replace('.png', '-hover.png'));
                //    });
                //});
                //menuItem.mouseleave(function () {
                //    $(this).children('img').each(function () {
                //        $(this).attr('src', $(this).attr('src').replace('-hover.png', '.png'));
                //    });
                //});

                menuItemAndClone.hoverIntent({
                    timeout: 300,
                    over: function () {
                        if (detectLiquidMenuElement.is(':hidden')) {
                            var m = $(this).children('.submenu');
                            m.slideDown('fast');
                        }
                    },
                    out: function () {
                        if (detectLiquidMenuElement.is(':hidden')) {
                            var m = $(this).children('.submenu');
                            m.slideUp('fast');
                        }
                    }
                });
                //            menuItem.click(function () {
                //                debugger
                //                if (detectLiquidMenuElement.is(':visible')) {
                //                    var m = $(this).children('.submenu');

                //                    if (m.hasClass('visible'))
                //                        m.removeClass('visible');
                //                    else
                //                        m.addClass('visible');
                //                }
                //            });


                if (menuNode.anchor && !menuNode.children) {
                    var newAnchor;
                    if (menuNode.anchor_newtab) {
                        newAnchor = $('<a target="_blank" href="' + menuNode.anchor + '" />');
                    } else {
                        newAnchor = $('<a name="' + menuNode.name + '" href="' + menuNode.anchor + '" />');
                    }
                    newAnchor.append(menuItem);
                    menuItem = newAnchor;
                } else {
                    menuItemAndClone.click(function () {
                        if (detectLiquidMenuElement.is(':visible')) {
                            var m = $(this).children('.submenu');

                            if (m.hasClass('visible'))
                                m.removeClass('visible');
                            else
                                m.addClass('visible');
                        }

                    });

                    if (menuNode.click) {
                        menuItemAndClone.click(menuNode.click);
                    }
                }

                if (menuNode.class)
                    menuItem.addClass(menuNode.class);

                menuStrip.append(menuItem);

                if (menuNode.children && menuNode.children.length > 1) {
                    createSubMenu(menuItemAndClone, menuNode.children);
                }
            }
            menuStrip.children('.menuitem').last().addClass('liquidmenu');        
           
            container.append(menuStrip);

            detectLiquidMenuElement = $('#header .menuitem.narrowMenuOnly').first();

        };

        $.quosal.sell.goToCatalog = function () {
            $('form.catalogLoginForm').unbind().first().submit();
        };

        // ER 1/11/18 temporarly need to use an icon with the fill color set in the svg file. This is because svg files don't obey fill color by default.
        // CwWidgets.CwImage has solved the coloring issue in React, but the widget cannot be used with the nodes array below, because menuItem.append(str) JQuery method will create
        // a non existing <cwwidgets.wwimage /> DOM object instead of <CwWidgets.CwImage />.
        var mainMenuNodes = [];
        var imageWidth = '24px';
        var imageHeight = '24px';
        var addLicenceUrl = app.settings.global.cwAddLicensesURL;
        var isReadOnlyUser = app.currentUser.IsReadOnly;
        var originalUserUserId = app.originalUserUserId;

        mainMenuNodes.push(
            {
                name: 'Dashboard',
                image: 'img/svgs/sell/Application_Dashboard_LightGrey.svg',
                imageWidth: imageWidth,
                imageHeight: imageHeight,
                title: 'Home',
                anchor: $.quosal.util.url('home', 'NO_QUOTE_PARAMS')
            }
        );
        if (!isReadOnlyUser) {
            mainMenuNodes.push(
                {
                    name: 'New Quote',
                    image: 'img/svgs/sell/Action_AddNew_LightGrey.svg',
                    imageWidth: imageWidth,
                    imageHeight: imageHeight,
                    title: 'New Quote',
                    anchor: $.quosal.util.url('quote.picktemplate', 'NO_QUOTE_PARAMS')
                }
            );
        }
        mainMenuNodes.push(
            {
                name: 'Open Quote',
                image: 'img/svgs/sell/Action_OpenQuote_LightGrey.svg',
                imageWidth: imageWidth,
                imageHeight: imageHeight,
                title: 'Open Quote',
                anchor: quosal.util.url('quote.search', 'idquotemain')
            }
        );
        mainMenuNodes.push(
            {
                name: 'SuccessList',
                image: 'img/svgs/sell/Menu_SuccessList_LightGrey.svg',
                imageWidth: imageWidth,
                imageHeight: imageHeight,
                title: 'SuccessList',
                anchor: $.quosal.util.url('takeaction.quosalweb', 'NO_QUOTE_PARAMS'),
                omitQuosalOne: true
            }
        );
        mainMenuNodes.push(
            {
                name: 'Notifications',
                image: 'img/svgs/sell/Application_Notification_LightGrey.svg',
                imageWidth: imageWidth,
                imageHeight: imageHeight,
                title: 'Notifications',
                anchor: $.quosal.util.url('notifications.quosalweb', 'NO_QUOTE_PARAMS'),
                class: 'narrowMenuOnly',
                omitQuosalOne: true
            }
        );
        mainMenuNodes.push(
            {
                name: 'Sell Intel',
                image: 'img/svgs/sell/Application_SellIntel_LightGrey.svg',
                imageWidth: imageWidth,
                imageHeight: imageHeight,
                title: 'Sell Intel',
                anchor: $.quosal.util.url('powerbidashboard.quosalweb', 'NO_QUOTE_PARAMS'),
                children: function () {
                    if (!dashboards)
                        return undefined;
                    if (dashboards.length <= 1)
                        return undefined;
                    return dashboards.tiles.map(function (tile) {
                        return {
                            name: tile.name,
                            anchor: $.quosal.util.url('powerbidashboard.quosalweb', 'idPowerBiTileset=' + tile.id)
                        };
                    });
                }(),
                hidden: !dashboards//!($('#quosalbiviews').val())
            }
        );
        mainMenuNodes.push(
            {
                name: 'Settings',
                image: 'img/svgs/sell/Menu_Settings_LightGrey.svg',
                imageWidth: imageWidth,
                imageHeight: imageHeight,
                title: 'Settings',
                anchor: $.quosal.util.url('adminhome.quosalweb', 'NO_QUOTE_PARAMS')
            }
        );
        mainMenuNodes.push(
            {
                name: 'Help',
                image: 'img/svgs/sell/Application_Help_LightGrey.svg',
                imageWidth: imageWidth,
                imageHeight: imageHeight,
                rightSideButton: '#fullscreenHeader_help',
                children: [
                    {
                        name: 'ConnectWise University',
                        anchor: app.settings.global.clientSettings.ConnectwiseUniversityURL,
                        click: $.quosal.help.goToHelpPage,
                        anchor_newtab: true,
                        class: 'narrowMenuOnly'
                    },
                    {
                        name: 'Support Portal',
                        anchor: app.settings.global.clientSettings.SupportSellPortalURL,
                        anchor_newtab: true
                    },
                    {
                        name: 'Release Notes',
                        anchor: 'https://docs.connectwise.com/ConnectWise_Sell/Sell_Release_Notes',
                        anchor_newtab: true
                    },
                    {
                        name: 'Live Chat',
                        anchor: app.settings.global.clientSettings.SupportChatAssistURL,
                        anchor_newtab: true,
                        class: 'narrowMenuOnly'
                    },
                    {
                        name: 'About',
                        click: function () {
                            if (window.Dialog && window.quosal && window.quosal.settings) { //snap update
                                Dialog.open({
                                    title: 'About ' + app.productBranding,
                                    message: '(SNAP) ' + quosal.settings.getValue('serverMode') + ' ' + quosal.settings.getValue('buildInfo'),
                                    links: [Dialog.links.ok]
                                });
                            }
                        }
                    }
                ]
            }
        );
        var userDropdown = [
            {
                name: 'My Profile',
                anchor: $.quosal.util.url('edituser.quosalweb', 'profile=mine', 'NO_QUOTE_PARAMS')
            },
            {
                name: 'darkMode'
            },
            {
                name: 'Sign Out',
                anchor: $.quosal.util.url('logout.quosalweb', 'NO_QUOTE_PARAMS')
            }
        ];
        if (addLicenceUrl) {
            userDropdown.splice(1, 0, {
                name: 'Purchase Licenses',
                anchor: addLicenceUrl,
                anchor_newtab: true
            });
        }
        if (originalUserUserId) {
            userDropdown.splice(1, 0, {
                name: 'End Impersonation',
                anchor: $.quosal.util.url('deviceid.quosalweb', 'NO_QUOTE_PARAMS') + '&originalUserId=' + originalUserUserId
            });
        }
        mainMenuNodes.push(
            {
                name: 'User',
                image: 'img/svgs/sell/Flag_Customer_LightGrey.svg',
                imageWidth: imageWidth,
                imageHeight: imageHeight,
                rightSideButton: '#fullscreenHeader_name',
                children: userDropdown
            }
        );
        $.quosal.sell.mainmenu = {
            refresh: function () {
                $.quosal.ui.menu(this, $('#header .menuContainer'));
            },
            nodes: mainMenuNodes
        };

        $.quosal.sell.mainmenu.refresh();
        quosal.navigation.parse(ReactDOM.findDOMNode(this));
        $('.headerQuickQuoteSearch').find('input[type=text]').blur();
        $('#quosalCatalogLink').click($.quosal.sell.goToCatalog);

        const enable = 1;
        const disable = 0;

        $('#dark-toggle[type="checkbox"]').prop('checked', $('body').hasClass('darkMode'));


        $('#dark-toggle[type="checkbox"]').change(function () {
            if ($(this).is(':checked')){
                $.quosal.ui.updateDarkMode(enable);
            } else {
                $.quosal.ui.updateDarkMode(disable);
            }
        });
    }

    render(){
        var quote = this.props.quote || app.currentQuote;
        var user = this.props.user || app.settings.user;
        var isAdmin = app.currentUser.IsAdministrator;
        var newNotificationsCount = parseInt(app.settings.user.notifyCount, 10);
        var enableWalkMeButton = app.featureFlags.EnableWalkMeButton;
        var theme = app.settings.user.embeddedTheme;

            return (
                <>
                <div id="header" style={{fontSize: '20px'}}>
                        <div className="left" style={{}}>
                            <a className="headerHomeLogoLink" href={quosal.util.url('home')}
                               style={{paddingRight: '10px'}}>
                                <div style={{display: 'inline-block'}}>
                                    <img id="applogo" src={app.settings.user.logoUrl}
                                         className="logo small" />
                                    <div id="serverModeLabel" style={{height:'10px',backgroundColor:'Yellow',display:'none',padding:'1px 1px 0 1px',fontSize:'9px',fontWeight:'bold',color:'#333',textAlign:'center'}}></div>
                                </div>
                            </a>

                        <QuickQuoteSearch mobileClass={true}></QuickQuoteSearch>

                            <div className="menuContainer hideliquid"></div>
                            <div id="liquidmenu" className="liquidmenu" title="Main Menu">
                            </div>
                        </div>
                        <div className="right" style={{position: 'absolute', right: '10px', top: '0px'}}> {/*temp style*/}
                            <div className="userInformation">
                            <QuickQuoteSearch></QuickQuoteSearch>

                                <a href={quosal.util.url('edituser.quosalweb', 'idquotemain', 'profile=mine')} className="smallmenu username" style={{display:'inline-block'}}>
                                    <div className="thumbnail small">
                                        <img style={{height:'50px', width:'50px'}} src={app.settings.user.repPicture} name="RepPicture" />
                                    </div>
                                </a>

                                <div id="fullscreenHeader_name" className="info" >
                                {app.currentUser.FirstName}
                                </div>
                                <div className="vr"></div>
                                <div id="fullscreenHeader_help" className="info">
                                    Help
                                </div>
                            </div>
                            <div className="statusIcons">
                                { app.settings.user.useCatalog && quosal.validation.isPackageLevelAuthorized(app.packageLevels.standard) ?
                                    <div>
                                    <CatalogLoginForm/>
                                    <div className="image">
                                        <div id="quosalCatalogLink" className="quosalCatalogLink" title={app.productBranding + " Catalog"}><CwWidgets.CwImage src="img/svgs/sell/Application_Catalog.svg" isInlineSVG="true"/></div>
                                    </div>
                                    </div> 
                                    : null
                                }
                                <div className="image" >
                                    <a href={quosal.util.url('notifications.quosalweb', 'idquotemain')}>
                                        {!isNaN(newNotificationsCount) && newNotificationsCount > 0 ? 
                                        <div id="notifier" className="fullscreenMode notifier"
                                        title="You have new notifications"><CwWidgets.CwImage id="notifierImg" src="img/svgs/sell/Application_NewNotification.svg" isInlineSVG="true"/>
                                            <div id="notifycount" className="hidden notifycount">{newNotificationsCount}</div>
                                        </div> :
                                                <div id="notifier" className="fullscreenMode inactive notifier"
                                        title="You have no new notifications"><CwWidgets.CwImage id="notifierImg" src="img/svgs/v1.0/Application_Notification.svg" isInlineSVG="true"/>
                                            <div id="notifycount" className="hidden notifycount">0</div>
                                        </div> }
                                    </a>
                            </div>
                            {enableWalkMeButton ?
                                <div className="image">
                                    <div id="walkme" className="walkMeButtonUnderline" title="Launch Help Center"><CwWidgets.CwImage src="img/svgs/sell/Default_Board.svg" isinlinesvg="true" fill="#f5f5f5" className="walkMeButtonImg"/></div>
                                </div>
                                : null
                            }
                            </div>
                            {/*todo javascript for liquid menu*/}
                            <div id="liquidmenu" className="liquidmenu" title="Main Menu"></div>
                        </div>
                </div>
                <AppTheme>
                    <IdentityWarningToast />
                </AppTheme>
                </>
            )
        }

}

class QuickQuoteSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };    
   
        // This binding is necessary to make `this` work in the callback
        this.doSearch = this.doSearch.bind(this);
    }
    doSearch(e) {
        e.preventDefault();
        var input =  encodeURI(this.refs.input.value);
        var api = quosal.api.quote.quickSearch(input)
        api.finished = function (msg) {
            if (msg.idQuoteMain) {
                var dashboardModule = quosal.sell.modules.find('quote.dashboard');
                dashboardModule.load({
                    query: 'idquotemain=' + msg.idQuoteMain + '&submodules=quote.content',
                    forceNavigation: true
                });
            } else {
                var quoteSearchModule = quosal.sell.modules.find('quote.search');
                quoteSearchModule.load({
                    query: 'nocookieload=yes&quick=y&searchquotename=' + input,
                    forceNavigation: true
                });
            }
        }
        api.call();
    }
    render() {
        return (this.props.cwUnifiedTheme ?
            (<form className="headerRightContent" onSubmit={this.doSearch} >
                <input ref="input" type="text" name="input" placeholder="Search"
                       className="quickquotesearch disable_exempt"/>
                <input type="submit" className="headerSearch disable_exempt" />
            </form>) :
            (<form className={'headerQuickQuoteSearch' + (this.props.mobileClass ? ' mobile' : '')} onSubmit={this.doSearch} >
                <input ref="input" type="text" name="input" className="disable_exempt" />
                <div onClick={this.doSearch} className="icons-action search disable_exempt" style={{marginRight:'5px'}} ><CwWidgets.CwImage src="img/svgs/v1.0/Action_Search.svg" isInlineSVG="true" style={{fill:'#f5f5f5', width:'20px', height:'20px'}} /></div>
            </form>)
        );
    }
}

class DarkModeSwitch extends React.Component {
    render() {
        return (
            <div className="theme-switch-wrapper profileMenuItem">
                <span className="title" >Dark Mode</span>
                <label className="dark-toggle theme-switch">
                    <input id="dark-toggle" className="dark-toggle-checkbox" type="checkbox" />
                    <span className="dark-toggle-switch dark-toggle-slider dark-toggle-round"></span>
                </label>
            </div>
        );
    }
}
