import { Grid, Button, Typography } from '@mui/material';
import { ProductCardStyled, SalesTrackActiveStatusStyled, SalesTrackInactiveStatusStyled, SalesTrackTitleStyled } from './styledComponents/productSyled';

export class ProductCard extends React.Component {


    render () {
        const { product, kebab, menuOpen, toggleMenu, onEdit, onDelete, onSalesRep } = this.props;
        const { id, name, description, state, pictureLink } = product;

        /**
        * Returns the text, truncated by the number of characters selected.
        *
        * @param {string} textToTruncate The text you want to truncate.
        * @param {number} characterNumber The maximum number of characters before truncating the text.
        * @return {string} Return the truncated text with ellipsis at the end.
        */
        const autoTruncateText = (textToTruncate, characterNumber) => textToTruncate.length > characterNumber 
            ? textToTruncate.substring(0, characterNumber) + "..." 
            : textToTruncate;        

        const handleClick = () => {
            if (!!onSalesRep) {
                onSalesRep(product);
            } else {
                onEdit(product)
            }
        }

        const handleClickStopPropagation = (e, onClickEvent) => {
            e.stopPropagation();
            onClickEvent();
        }

        return (
            <Grid item container xs={3} sm={12} md={6} lg={4} xl={3} justifyContent='center'>
                <ProductCardStyled item container xs={12} direction="row" justifyContent="space-between" alignItems="flex-start" m='0 10px' className={`${!!onSalesRep ? " salestrackitem-card-pointer" : ""}`} onClick={handleClick}>
                    <Grid item container direction="row" justifyContent="space-between" xs={12} >
                        <Grid item xs={10}>
                            <SalesTrackTitleStyled variant="h2" className='salestrackitem-name' >{name}</SalesTrackTitleStyled>
                        </Grid>
                        <Grid item container direction="row" justifyContent="flex-end" xs={2}>
                            {kebab && 
                                <img src="img/icons/ellipsis_vertical.png" alt="menu" className="salestrackitem-menuicon" onClick={(e) => handleClickStopPropagation(e,() => toggleMenu(id)) } />
                            }
                        </Grid>

                </Grid>
                {kebab && menuOpen &&
                <Grid item className="salestrackitem-menu">
                            <Button variant='text' color="text" className="salestrackitem-menu-item" onClick={(e) => handleClickStopPropagation(e, () => onEdit(product))}>Edit</Button>
                            <Button variant='text' color="text" className="salestrackitem-menu-item" onClick={(e) => handleClickStopPropagation(e, () => onDelete(product))}>Delete</Button>
                </Grid>
                }
                <Grid item xs={12} className="salestrackitem-status">
                    {
                        state ? 
                        <SalesTrackActiveStatusStyled variant="p" id="status-text--active" className="status-text--active" >Active</SalesTrackActiveStatusStyled>
                        :
                        <SalesTrackInactiveStatusStyled variant="p" id="status-text--inactive" className="status-text--inactive" >Inactive</SalesTrackInactiveStatusStyled>
                    }
                       
                </Grid>
                <Grid item xs={12} className="salestrackitem-details">
                    <img src={pictureLink || 'images/empty.png'} alt={name} className="salestrackitem-image" />
                    <Typography variant='span' className="salestrackitem-description">{autoTruncateText(description, 95)}</Typography>
                    {description && <Typography variant='span' className="salestrackitem-tooltiptext">{description}</Typography>}
                </Grid>
            </ProductCardStyled>
            </Grid>
        );
    }
}