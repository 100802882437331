/* eslint-disable react/display-name */
import React, { forwardRef, useImperativeHandle, useMemo, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import { useEditorContext } from "../../context/editorContext";
import useDarkMode from "../../WidgetToolbar/widgetToolbarHelpers";
import useNewRecordContainer from "./newRecordContainer";

const NewRecordModalComponent = forwardRef((props, ref) => {
	const { contentGrid: grid } = useEditorContext();
	const [isOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState({});
	
	const darkMode = useDarkMode();

	// styles start
	const titleStyle = { m: 0, p: 2 };
	const loadingStyle = { color: "white" };
	const titleIconStyle = {
		position: "absolute",
		right: 8,
		top: 8,
		minWidth: "auto",
		color: (theme) => theme.palette.grey[500]
	};
	const actionsWrapperStyle = { flexGrow: 1 };
	const actionsButtonStyle = { marginRight: "5px", textTransform: "none", };
	// styles end

	useImperativeHandle(ref, () => ({
		showModal(isShown, data) {
			setModalData({ ...modalData, ...data });
			setIsOpen(isShown);
		}
	}));

	const onPopoverClose = () => {
		setIsOpen(false);
	};

	const {
		handleClose,
		handleTextFieldChange,
		handleDropdownChange,
		handleNumberChange,
		onQuantityBlur,
		handleCurrencyChange,
		onPriceFocus,
		onPriceBlur,
		handleCheck,
		handleSubmit,
		getGroupOptions,
		dialogClasses,
		productError,
		textFieldValue,
		dropdownValue,
		showGearIcon,
		numberValue,
		currencyValue,
		loadingCheckProduct,
		loadingAddProduct
	} = useNewRecordContainer({ ...props, ...modalData, onPopoverClose });

    const shouldDisplayQuickAddSourcing = useMemo(() => {
        return quosal.settings.getValue('enableQuickAddSourcing') &&
            quosal.validation.isPackageLevelAuthorized(app.packageLevels.standard);
    }, []);

	const GearIcon = () => {
		return (
			<IconButton
				edge="start"
				onClick={() => {
					window.location.href = quosal.util.url(
						"adminsourcegroups.quosalweb",
						`quotereturnurl=${encodeURIComponent(location.href)}`
					);
				}}
				sx={{
					p: "8px",
					minWidth: "2rem",
				}}>
				<Tooltip
					title="Configure Source Groups">
					<SettingsIcon
						fontSize="large"
						sx={{
							borderRadius: "50%", // Ensure no border radius
						}}/>
				</Tooltip>
			</IconButton>
		);
	};
	
	const menuItems = (() => {
		let primaryGroup;
		const options = [];
		const groups = getGroupOptions();

		for (let i = 0; i < groups.length; i++) {
			if (groups[i].IsPrimary) {
				primaryGroup = groups[i];
				options.unshift(
					<MenuItem
						key={primaryGroup.IdProductSourceGroup}
						selected
						value={primaryGroup.IdProductSourceGroup}
					>
						{`${primaryGroup.GroupName} (global default)`}
					</MenuItem>
				);
			} else {
				options.push(
					<MenuItem
						key={groups[i].IdProductSourceGroup}
						value={groups[i].IdProductSourceGroup}
					>
						{groups[i].GroupName}
					</MenuItem>
				);
			}
		}

		if (primaryGroup) {
			options.splice(
				1,
				0,
				<MenuItem key="allkey" value="all">
					All Sources
				</MenuItem>
			);
			options.splice(
				2,
				0,
				<MenuItem disabled key="separator1key" className="separator">
					————————————————————
				</MenuItem>
			);
		} else {
			options.unshift(
				<MenuItem disabled key="separator2key" className="separator">
					————————————————————
				</MenuItem>
			);
			options.unshift(
				<MenuItem key="all2Key" value="all">
					All Sources (global default)
				</MenuItem>
			);
		}
		return options;
	})();

	return (
		<Dialog
			open={isOpen}
			onClose={onPopoverClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			id="CKEditor_deleteDialog"
			className={dialogClasses}
			maxWidth="md">
			<DialogTitle
				id="alert-dialog-title"
				sx={titleStyle}>
				Add Product
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={titleIconStyle}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid
					container
					spacing={2}>
					<Grid
						item
						xs={12}>
						<Typography
							variant="subtitle1">
							Product Part #
						</Typography>
							<TextField
								helperText={productError && !textFieldValue && "*Required"}
								error={productError && !textFieldValue}
								className="validationHelperText"
								value={textFieldValue}
								onChange={handleTextFieldChange}
								fullWidth={true}
								margin="normal"
								inputProps={{
									style: {
										WebkitBoxShadow: "0 0 0 1000px transparent inset"
									}
								}}
								InputProps={{
									style: {
										boxSizing: "border-box",
									},
									endAdornment: (!shouldDisplayQuickAddSourcing && showGearIcon) && (
										<InputAdornment
											position="end"
											variant="standard">

												<GearIcon />

										</InputAdornment>
									)
								}}
							/>
					</Grid>
					{shouldDisplayQuickAddSourcing && (
						<Grid
							item
							xs={12}>
							<Typography 
								variant="subtitle1">
								Add Part # from
							</Typography>
							<FormControl
								fullWidth 
								margin="normal">
								<Select
									value={dropdownValue || modalData.defaultDropdownValue || "all"}
									onChange={handleDropdownChange}
									IconComponent={() => null}
									endAdornment={showGearIcon && (

										<GearIcon />

									)}>
									{!grid.state.priceSourcesLoaded ? (
										<CircularProgress size={20} />
									) : (
										menuItems.map((item) => item)
									)}
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Typography variant="subtitle1">Quantity</Typography>
						<TextField
							value={numberValue}
							onChange={handleNumberChange}
							onBlur={onQuantityBlur}
							fullWidth
							margin="normal"
							InputProps={{
								inputProps: {
									inputMode: "numeric",
									pattern: "[0-9]*"
								}
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="subtitle1">Price</Typography>
						<TextField
							value={currencyValue}
							onChange={handleCurrencyChange}
							onFocus={onPriceFocus}
							onBlur={onPriceBlur}
							fullWidth
							margin="normal"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<div style={actionsWrapperStyle}>
					<Button
						onClick={handleClose}
						variant="outlined"
						className="dialogSecondaryButton"
						id="secondaryButtonId"
						sx={{
							...(darkMode
								? { color: "var(--dark-mode-blue-color) !important" }
								: { color: "#2E3F80 !important" })
						}}
					>
						Cancel
					</Button>
				</div>
				<Button
					onClick={handleCheck}
					className="dialogSecondaryButton"
					disabled={loadingCheckProduct || loadingAddProduct}
					variant="outlined"
					sx={{
						...(darkMode
							? { color: "var(--dark-mode-blue-color) !important" }
							: { color: "#2E3F80 !important" })
					}}
				>
					<div style={actionsButtonStyle}>Check</div>
					{loadingCheckProduct ? <CircularProgress size={20} /> : null}
				</Button>
				<Button
					variant="contained"
					onClick={()=>handleSubmit(false)}
					className="primaryButton"
					disabled={loadingCheckProduct || loadingAddProduct}
					autoFocus
					sx={{
						...(darkMode
							? { backgroundColor: "var(--dark-mode-blue-color) !important" }
							: { backgroundColor: "#2E3F80 !important" })
					}}
				>
					<div
						style={{
							...actionsButtonStyle
						}}
					>
						Add
					</div>
					{loadingAddProduct ? <CircularProgress sx={loadingStyle} size={20} /> : null}
				</Button>
				<Button
					variant="contained"
					onClick={()=>handleSubmit(true)}
					className="primaryButton"
					disabled={loadingCheckProduct || loadingAddProduct}
					autoFocus
					sx={{
						...(darkMode
							? { backgroundColor: "var(--dark-mode-blue-color) !important" }
							: { backgroundColor: "#2E3F80 !important" })
					}}
				>
					<div
						style={{
							...actionsButtonStyle
						}}
					>
						Add and Close
					</div>
					{loadingAddProduct ? <CircularProgress sx={loadingStyle} size={20} /> : null}
				</Button>
			</DialogActions>
		</Dialog>
	);
});

export default NewRecordModalComponent;
