import { SalesTracksPage } from "js/jsx/src/classes/settings/guidedSelling/salesTracks.jsx";
import { DialogManager } from "js/jsx/src/classes/dialog.jsx"

export default function salesTracks(){
    var loadSalesTracksPage = function () {
        const renderComponents = () => {
            quosal.ui.react.render(<DialogManager />, document.getElementById('dialogManager'))
            quosal.ui.react.render(<SalesTracksPage />, document.getElementById('currentDocument'));
        };

        renderComponents();
        $('#pageName').val('guidedsellingsetup.quosalweb');
        $('div.menu-level.menu-level1.selected div.menulabel').unbind('click').click((e) => {
            e.stopPropagation(); 
            renderComponents();
        });
    };
    quosal.sell.modules.onAfterLoad.unbind().bind(loadSalesTracksPage);
}