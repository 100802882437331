export class Warning extends React.Component {
    render() {
        return (
         <div className="warningBlock">
            <div className="warningIcon">
                <CwWidgets.CwImage src="img/svgs/v1.0/Status_Warning.svg" isInlineSVG="true" style={{ width: "20px", height: "20px", fill: '#eb7b18' }} />
            </div>
            <p className="notificationMessage">
                {this.props.message}
            </p>           
        </div>);
    }
}
