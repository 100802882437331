quosal.setQuoteStatus = {
    deleted: function(idQuoteMain, doNavigate, callback) {
        Dialog.open({
            closeRequiresButton: true,
            message: <FormPlaceholder message="Deleting Quote..." />
        });

        var updateApi = quosal.api.data.update({
            fields: {
                QuoteStatus: 'Deleted'
            },
            queries: [{
                table: 'QuoteMain',
                where: [{
                    field: 'IdQuoteMain',
                    operator: 'Equals',
                    value: idQuoteMain
                }]
            }]
        }, idQuoteMain);
        updateApi.finished = function (msg) {
            if(msg.action == "FailedToDeleteQuoteTemplateResponse"){
                if(msg.error == "onlyTemplate"){
                    this.showCannotDeleteDialog(msg.approvalRules);
                } else if(msg.error == "multiTemplate"){    
                    this.showConfirmDeleteDialog(msg.approvalRules, idQuoteMain);
                } else {
                    this.showUnknownErrorDialog();
                }
                return;
            }
            if(callback){
                callback();
            } 
            if(doNavigate) {
                quosal.navigation.navigate(quosal.util.url('home'));
                Dialog.closeAll();
            } else {
                Dialog.closeAll();
            }
        }.bind(this);
        updateApi.call();
    },
    deleteTemplateAndProvidedApprovalRuleTemplates(approvalRules, idQuoteMain){
        Dialog.setIsWorking(true);
        var approvalRuleTemplateIds = approvalRules.map(x => x.idApprovalRulesTemplates);
        var deleteTemplate = quosal.api.approval.RemoveTemplateFromApprovalRule(approvalRuleTemplateIds, idQuoteMain);
        deleteTemplate.finished = function (msg) {
            Dialog.setIsWorking(false);
            quosal.navigation.navigate(quosal.util.url('home'));
            Dialog.closeAll();

        };
        deleteTemplate.call();
    },
    showConfirmDeleteDialog: function(approvalRules, idQuoteMain){
        var names = approvalRules.map((x,i) => <p key={"approvalRule_" + i} style={{marginLeft:"15px"}}>{x.name}</p>);
        Dialog.open({
            title: 'Confirm Delete',
            closeRequiresButton: true,
            message:  <div> This template is associated with one or more Multi-Level Approval Rules. Deleting the template will remove that requirement from these
            rule(s): {names} This will not affect any quotes previously created from this template. Are you sure you want to delete this template?</div>,
            links: [{title: "Yes, Delete", callback: this.deleteTemplateAndProvidedApprovalRuleTemplates.bind(this, approvalRules, idQuoteMain)}, {title: "No, Cancel", callback: Dialog.closeAll}  ]
        });
    },
    showCannotDeleteDialog: function(approvalRules){
        var names = approvalRules.map((x,i) => <p key={"approvalRule_" + i} style={{marginLeft:"15px"}}>{x.name}</p>);
        Dialog.open({
            title: 'Unable to Delete',
            closeRequiresButton: true,
            message: <div>Unable to delete template. This template is the last template of rule(s): {names} 
            You will need to remove this template from these rules in order to delete this template.</div>,
            links:  [{title: "OK", callback: Dialog.closeAll} ]
        });
    },
    showUnknownErrorDialog: function(){
        Dialog.open({
            title: 'Unknown Error',
            closeRequiresButton: true,
            message: <div>An error has occurred. Please try navigating to the Approval Rules page and remove this template manually from all rules and then try the delete action again.</div>,
            links:  [{title: "OK", callback: Dialog.closeAll} ]
        });
    }
}