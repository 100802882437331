quosal.api.eula = {};
//Note the API to display the EULA is called from the Identify call

// Called after the Eula is signed
quosal.api.eula.saveSignedEula = function () {
    return quosal.api.call('Eula.SaveSignedEula', {});
};


quosal.api.eula.unsignEula = function () {
    return quosal.api.call('Eula.UnsignEula', {});
};