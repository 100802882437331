
import {QuoteNavigation} from  "js/jsx/src/classes/quote/navigation.jsx";
import {EditableImage} from "js/jsx/src/classes/imageEditor.jsx";

export class QuoteHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {                  
            documentPreviewEnabled: quosal.util.shouldDocumentPreviewBeOpen()
        }; 
        this.selfRef = React.createRef();
        // This binding is necessary to make `this` work in the callback
        this.toggleDocumentPreviewer = this.toggleDocumentPreviewer.bind(this);
        this.toggleQuoteImageSize = this.toggleQuoteImageSize.bind(this);
    }
    toggleDocumentPreviewer() {
        const toggledPreviewValue = !this.state.documentPreviewEnabled;
        this.setState({documentPreviewEnabled: toggledPreviewValue});
        quosal.settings.saveUserSetting({
            key: 'DocumentPreviewVisible',
            value: toggledPreviewValue,
            isUserSetting: true
        }
    );
        
        quosal.sell.quote.showDocumentPreviewer(toggledPreviewValue);
        if (toggledPreviewValue && document.getElementById("refreshButton")) {
            document.getElementById("refreshButton").click();
        }
    }
   
    toggleQuoteImageSize(isCollapsing) {
        var size = isCollapsing ? '32px' : '64px';

        $(ReactDOM.findDOMNode(this.selfRef)).find('.imgWrapper .thumbnail').css({height: 'initial', width: 'initial'});
        $(ReactDOM.findDOMNode(this.selfRef)).find('.imgWrapper .thumbnail, .imgWrapper .thumbnail img:first').animate({
            maxHeight: size,
            maxWidth: size
        }, this.forceUpdate.bind(this));
    }
    componentDidMount() {
        quosal.sell.quote.showDocumentPreviewer(this.state.documentPreviewEnabled);
        quosal.navigation.parse(this.selfRef);
        //loadImageEditors($(this.refs.imgContainer));

        var cookie = quosal.util.cookie('panel_quoteheader');
        if(cookie === 'collapsed') {
            $(ReactDOM.findDOMNode(this.selfRef)).find('.extra').hide();
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        return true;
        //return this.props.quote.BoVersion !== nextProps.quote.BoVersion;
    }

    getLockedDownBannerElement(quote, isEoPage){
        //TODO: When mlaEnabled setting is passed to front end, add additional check here
        if (!isEoPage && quote.IsLocked && !quote.IsArchive && !quote.IsTemplate && quote.QuoteStatus == 'Archived') {
            return (
                <div className="quote_mode_banner locked">
                    LOCKED DOWN - ARCHIVED QUOTE
                </div>
            )
        } else if(!isEoPage && quote.IsLocked && !quote.IsArchive && !quote.IsTemplate && quote.QuoteStatus != 'Archived') {
            if(app.settings.global.ApprovalMethod == 'Multilevel' && !isEoPage && quote.IsLocked && !quote.IsArchive && !quote.IsTemplate && quote.ApprovalStatus != '') {
                if(quote.ApprovalStatus == "Request Approval"){
                    return (
                        <div className="quote_mode_banner locked">
                        LOCKED DOWN - PENDING APPROVAL
                        </div>
                    )
                }
                else if (quote.ApprovalStatus == "Approved"){
                    return (
                        <div className="quote_mode_banner approved">
                        LOCKED DOWN - APPROVED
                        </div>
                    )
                }
            } 
            else {
                return(
                    <div className="quote_mode_banner locked">
                        LOCKED DOWN
                    </div>
                )
            }
         }
    }
    render() {
        var isEoPage = !!($("#isEoPage").length);

        var quote = this.props.quote || app.currentQuote;     
        if(!quote){
            return <div/>
        }
        if (quote.IsTemplate && !(app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer)) {
            return <div className="prose">Standard users may not edit quote templates.</div>
        }            
        var multiUserWarning = '';
        var quoteModules = [];

        var quoteNavigationList = isEoPage ? quote.EoNavigation : quote.Navigation;

        for(var i = 0; i < quoteNavigationList.length; i++) {
            var nav = quosal.sell.modules.allModules.firstOrNull(s=>s.Url == quoteNavigationList[i].Url);
            if(nav) {
                var navToUse = nav;

                if (nav.Module == "quote.publish" && app.settings.global.ApprovalMethod == 'Multilevel') {
                    if (quote.ApprovalStatus == "Approved" && nav.IsLocked) {
                        nav.IsLocked = false;
                    }
                    else if (!nav.IsLocked && !app.currentUser.IsAdministrator) {
                        if((app.quoteHasTriggeredApprovalRules && quote.ApprovalStatus != "Approved") || quote.ApprovalStatus == "Request Approval") {
                            nav.IsLocked = true;
                        }
                        else if(quosal && quosal.publish && quosal.publish.publishCheat){
                            nav.IsLocked = true;
                        }
                    } 
                }
    
                if (nav.Url.indexOf('.quosalweb') > 0) {
                    navToUse = quosal.util.clone(nav);
                    var url = nav.Url;
                    var urlParts = url.split(/[?&]/);
                    var newUrl = quosal.util.url(...urlParts);
                    navToUse.Url = newUrl;
                }
                quoteModules.push(navToUse);
            }
        }
        var getPublishText = function () {
            if (quote.PublishMethod && quote.PublishMethod.indexOf('DOCUSIGN') > -1 ) {
                return "Email DocuSign"
            }
            else if (quote.PublishMethod == "DOCX") {
                return "Email DOCX"
            }
            else if (quote.PublishMethod == "DOC") {
                return "Email DOC"
            }
            else if (quote.PublishMethod == "RTF") {
                return "Email RTF"
            }
            else if (quote.PublishMethod == "XLS") {
                return "Email XLS"
            }
            else if (quote.PublishMethod == "PDF") {
                return "Email PDF"
            }
            else {
                return <a target={quote.IsOrderPorterUploaded ? "_blank" : ""} href={quote.OrderPorterLink + "&novisit=true"}>{quote.IsOrderPorterUploaded ? 'View Order Porter' : 'Not Published'}</a>
            }
           
        }

        if (quote.OtherUsers && quote.OtherUsers.length > 0) {
            var userList = '';
            for (var i = 0; i < quote.OtherUsers.length; i++) {
                var user = quote.OtherUsers[i];
                userList += (userList == '' ? '' : ', ') + user.FirstName + ' ' + user.LastName +
                    ' (' + user.UserName + ')';
            }

            multiUserWarning = (
                <div id="multipleUsersOnQuoteWarning">
                    <div style={{textAlign:'center', fontWeight:'bold'}}>
                        Warning: <span id="multipleUsersOnQuoteCount">{quote.OtherUsers.length}</span> other user(s) are also on this quote: <span id="multipleUsersOnQuoteList">{userList}</span>
                    </div>
                </div>
            );
        }

        var quoteImageSize = 64;
        var cookie = quosal.util.cookie('panel_quoteheader');
        if(cookie === 'collapsed')
            quoteImageSize = 32;
            
        var hideProfit = quosal.settings.getValue('hideProfit');
        return (
            <div>
                <Panel ref={selfRef => this.selfRef = selfRef} id="headerpanel" className="panel collapsable" collapsible={true} onCollapseChanging={this.toggleQuoteImageSize} cookie="quoteheader">
                {!isEoPage && quote.IsArchive && !quote.IsTemplate ?
                <div className="quote_mode_banner archive">
                    HISTORICAL VERSION
                </div> : '' }
                
               {this.getLockedDownBannerElement(quote, isEoPage)}


                {!isEoPage && quote.QuoteStatus == 'Deleted' && !quote.IsTemplate ?
                <div className="quote_mode_banner deleted">
                    DELETED VERSION
                </div> : ''}

                {!isEoPage && quote.IsTemplate && quote.IsArchive ?
                <div className="quote_mode_banner archive">
                    HISTORICAL TEMPLATE
                </div> : ''}

                {!isEoPage && quote.IsTemplate && quote.IsDeleted?
                <div className="quote_mode_banner deleted">
                DELETED TEMPLATE
                </div> : ''}
                    
                {!isEoPage && quote.IsTemplate && !quote.IsArchive && !quote.IsDeleted?
                <div className="quote_mode_banner template">
                    EDITING TEMPLATE
                </div> : ''}

                <div className="headerrow helprow" style={{fontSize:'0px', maxWidth:'100%', position: 'relative'}}>
                    <div id="fullrow" style={{width:'10%', textAlign: 'center', verticalAlign: 'top', display:'inline-block'}}>
                        <div className="imgcol headercol">
                                <EditableImage src={quote.OrderPorterPreviewPicture} disabled={quote.IsLocked || app.currentUser.IsReadOnly} imageId={'QuotePicture'} norescale={true} IdQuoteMain={quote.IdQuoteMain} sizeInPixels={quoteImageSize}/>
                        </div>
                    </div>

                    <div id="fullrow" style={{width:'90%', display:'inline-block'}}>
                        <div id="fullrow" className="grid col1-1">
                            <div style={{display:'inline-block', width: 'inherit'}}>
                                <div className="headercol" style={{textAlign:'left', width: 'inherit'}}>
                                    <div className="quotetitle">{quote.QuoteName} - #{quote.QuoteReadableId} v{quote.Version}</div>
                                    {!isEoPage ?
                                    <div className="headercol" style={{paddingRight:'6px', float:'right'}}>
                                        <QuoteStatusIndicators />
                                                
                                        {quosal.util.shouldShowPdfPreviewOption() ?
                                        <div onClick={this.toggleDocumentPreviewer} className="quoteheader-icon" style={{cursor:'pointer'}} >
                                            <div id="document-preview" className="document-preview" title={(this.state.documentPreviewEnabled ? 'Hide' : 'Show') + ' Document Previewer'} />
                                        </div> : null}
                                    </div> : ''}
                                </div>
                            </div>
                            </div>

                            <div>
                                <div style={{display:'inline-block'}}>
                                    <div className="extra">
                                        <div className="headercol">
                                            <div><div className="linename">Customer:</div><div id="customerAccountName" title={quote.AccountName} className="linevalue">{quosal.util.trunc(quote.AccountName, 20)}</div></div>
                                            <div><div className="linename">Contact:</div><div id="customerContact" className="linevalue">{quote.MainCust.FirstName} {quote.MainCust.LastName}</div></div>
                                            <div><div className="linename">Phone:</div><div id="customerPhone" className="linevalue">{quote.MainCust.DayPhone}</div></div>
                                            <div><div className="linename">Email:</div><div title={quote.MainCust.EmailAddress} className="linevalue"><a id="customerEmail" href={'mailto:' + quote.MainCust.EmailAddress}>{quosal.util.trunc(quote.MainCust.EmailAddress, 20)}</a></div></div>
                                            <div><div className="linename">Website:</div><div id="customerWebsite" title={quote.MainCust.Website} className="linevalue">{quosal.util.trunc(quote.MainCust.Website, 20)}</div></div>
                                        </div>
                                        <div className="headercol">
                                            <div><div className="linename">Primary Rep:</div><div className="linevalue">{quote.Owner.DisplayName}</div></div>
                                            <div><div className="linename">Inside Rep:</div><div className="linevalue">{quote.InsideRep.DisplayName}</div></div>
                                            <div><div className="linename">Created:</div><div id="createdate" className="linevalue">{quote.CreateDate}</div></div>
                                            <div><div className="linename">Expiration Date:</div><div className="linevalue">{quote.ExpirationDate}</div></div>
                                            <div><div className="linename">Publish Method: <div className="linevalue">
                                                {getPublishText()}
                                            </div></div></div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{textAlign:'right', float:'right', display:'inline-block'}}>
                                    <div className="headercol">
                                        <div><div className="linename large">Total:</div><div id="quotetotal" className="linevalue large">{quote.formatCurrency(quote.QuoteTotal)}</div></div>
                                        <div className="extra">
                                            <div><div className="linename">Subtotal:</div><div id="quotesubtotal" className="linevalue">{quote.formatCurrency(quote.Subtotal)}</div></div>
                                            <div><div className="linename">Est. Tax:</div><div id="quotetax" className="linevalue">{quote.formatCurrency(quote.AllTax)}</div></div>
                                            { hideProfit ? null : <div><div className="linename">Cost:</div><div id="quoteCost" className="linevalue">{quote.formatCurrency(quote.QuoteCost)}</div></div> }
                                            { hideProfit ? null : <div><div className="linename">Sugg:</div><div id="quoteSuggested" className="linevalue">{quote.formatCurrency(quote.SuggestedTotal)}</div></div> }
                                        </div>
                                    </div>
                                    <div className="headercol">
                                        <div><div className="linename large">Recurring:</div><div id="recurringtotal" className="linevalue large">{quote.formatCurrency(quote.RecurringTotal)}</div></div>
                                        <div className="extra">
                                            <div><div className="linename">Subtotal:</div><div id="recurringsubtotal" className="linevalue">{quote.formatCurrency(quote.RecurringSubtotal)}</div></div>
                                            <div><div className="linename">Est. Tax:</div><div id="recurringtax" className="linevalue">{quote.formatCurrency(quote.RecurringAllTax)}</div></div>
                                            { hideProfit ? null : <div><div className="linename">Cost:</div><div id="recurringCost" className="linevalue">{quote.formatCurrency(quote.RecurringCost)}</div></div> }
                                            { hideProfit ? null : <div><div className="linename">Sugg:</div><div id="recurringSuggested" className="linevalue">{quote.formatCurrency(quote.RecurringSuggestedTotal)}</div></div> }
                                         </div>
                                    </div>
                                    { hideProfit ? null : <div className="headercol">
                                        <div><div className="linename large">Gross Profit:</div><div id="grossMarginAmount" className="linevalue large">{quote.formatCurrency(quote.GrossMarginAmount)}</div></div>
                                        <div className="extra">
                                            <div><div className="linename">Gross Margin:</div><div id="grossMargin" className="linevalue">{quote.PrintedGrossMargin}</div></div>
                                            <div><div className="linename">Markup:</div><div id="quoteMarkup" className="linevalue">{quote.PrintedMarkup}</div></div>
                                            <div><div className="linename">Sugg Disc Amt:</div><div id="quoteDiscountAmount" className="linevalue">{quote.formatCurrency(quote.SuggestedDiscountAmount)}</div></div>
                                            <div><div className="linename">Sugg Disc:</div><div id="quoteDiscounPct" className="linevalue">{quote.PrintedSuggestedDiscountPercent}</div></div>
                                        </div>
                                    </div> }
                                   { hideProfit ? null :<div className="headercol">
                                       <div><div className="linename large">Recurring Gross Profit:</div><div id="grossRecurringMarginAmount" className="linevalue large">{quote.formatCurrency(quote.RecurringGrossMarginAmount)}</div></div>     
                                       <div className="extra"> 
                                            <div><div className="linename">Gross Margin:</div><div id="recurringGrossMargin" className="linevalue">{quote.PrintedRecurringGrossMargin}</div></div>
                                            <div><div className="linename">Markup:</div><div id="recurringMarkup" className="linevalue">{quote.PrintedRecurringMarkup} </div></div>
                                        <div><div className="linename">Sugg Disc Amt:</div><div id="recurringDiscountAmount" className="linevalue">{quote.formatCurrency(quote.RecurringSuggestedDiscountAmount)}</div></div>
                                        <div><div className="linename">Sugg Disc:</div><div id="recurringDiscounPct" className="linevalue">{quote.PrintedRecurringSuggestedDiscountPercent}</div></div>
                                       </div>
                                   </div> }
                                   </div>                            
                            </div>
                        </div>
                    </div>
                </Panel>

                <div id="quoteNavigationContainer">
                    <QuoteNavigation steps={quoteModules} isEoNav = { isEoPage? true : false}/>
                </div>
                {multiUserWarning}
            </div>
        )
    }
}

global.QuoteHeader = QuoteHeader;

export class QuoteStatusIndicators extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allowOpportunity: quosal.util.crmAllowsOpportunityCheck()
        };
    }

   navigateTo(url) {
        var state = { rand: quosal.util.generateGuid() };
        quosal.navigation.navigate(url, state);
    }

    //Unused right now, but will be used after quote notifications are full setup.
    static canGoToQuoteNotifications(quote) {
        if (!quote || !quote.QuoteName) {
            quote = app.currentQuote;
        }
        return (currentModule !== 'quote.notifications');
    }

    static goToOpportunity (quote) {
        if (!quote || !quote.QuoteName) {
            quote = app.currentQuote;
        }

        if(quote.IsTemplate){
            return
        }

        var nextModule;
        var disallowCustomerAndOppSearch = quosal.settings.getValue("disallowCustomerSearch");
        var hasCustomer = quote.AccountName != "";
        if(!quote.IdCRMOpportunity && !hasCustomer && !disallowCustomerAndOppSearch){
            nextModule = 'quote.attachOpportunity';
        } else {
            nextModule = 'crm.opportunity';
        }

        if (app.currentModule.Url === 'quote.dashboard') {
            app.currentModule.loadSubModule('quote.customer', {
                container: 'quoteModule',
                unloadSubModules: true,
                query: 'nextModule=' + nextModule
            });
        } else {
            var dashboardModule = quosal.sell.modules.find('quote.dashboard');
            dashboardModule.load({query: 'idquotemain=' + quote.IdQuoteMain + '&submodules=quote.customer&nextModule=' + nextModule});
        }
    }
    static loadEditCustomer (quote) {
        if (!quote || !quote.QuoteName) {
            quote = app.currentQuote;
        }

        var nextSubModule;
        if(quote.IsTemplate){
            if(quote.StatusFlags.HasCustomer){
                nextSubModule = 'quote.customerdetails';
            } else {
                nextSubModule = 'quote.customersearch';
            }
        } else {
            nextSubModule = 'quote.customer';
        }

        if (app.currentModule.Url === 'quote.dashboard') {
            app.currentModule.unloadSubModules();
            app.currentModule.loadSubModule(nextSubModule, {
                container: 'quoteModule',
                unloadSubModules: true,
            });
        } else {
            var dashboardModule = quosal.sell.modules.find('quote.dashboard');
            dashboardModule.load({query: 'idquotemain=' + quote.IdQuoteMain + '&submodules=' + nextSubModule});
        }
    }
    render () {
        var quote = this.props.quote || app.currentQuote;
        
        let notificationStatus = null;
        var newNotificationsCount = parseInt(app.settings.user.notifyCount, 10);

        var dynamicNotifyWrapper = newNotificationsCount > 9 ?
                                       newNotificationsCount > 99 ?
                                       "LARGE_notifyWrapper_jsx" :
                                       "MEDIUM_notifyWrapper_jsx" :
                                       "SMALL_notifyWrapper_jsx";

        var dynamicNotifyText =  newNotificationsCount > 9 ?
                                     newNotificationsCount > 99 ?
                                     "LARGE_notifyText_jsx" :
                                     "MEDIUM_notifyText_jsx" :
                                     "SMALL_notifyText_jsx";

        if (!quote.IsTemplate) {
            notificationStatus = (
               <div className="quoteheader-icon" style={{cursor:"pointer", contain: "layout"}}>
                    <div onClick={this.navigateTo.bind(this, quosal.util.url('quote_notifications.quosalweb', 'idquotemain=' + quote.IdQuoteMain))} className="image"  style={{contain: "layout"}}>
                        { 
                        !isNaN(newNotificationsCount) && newNotificationsCount > 0 ? 
                        <div id="notifier"
                            className="fullscreenMode notifier"
                            title="You have new notifications">
                            <div id="notifyWrapper" className={"notifyWrapper " + dynamicNotifyWrapper}>
                                <div id="notifyText" className={dynamicNotifyText}>
                                        {newNotificationsCount}
                                </div>
                            </div>
                        </div>
                        :
                        <div id="notifier" 
                            className="fullscreenMode inactive notifier"
                            title="You have no new notifications">
                        </div>  
                        }
                    </div>
               </div>
            );
        }
        var statusFlags = this.props.statusFlags || quote.StatusFlags;
        var customerButtonProps = {};
        if (statusFlags.CustomerUnknown) {
            customerButtonProps.className = 'quote-customer-active';
            customerButtonProps.title = 'Go to Customer';
        } else if (statusFlags.HasCustomer) {
            customerButtonProps.className = 'quote-customer-active';
            customerButtonProps.title = 'Customer is Attached';
        } else {
            customerButtonProps.className = 'quote-customer-inactive';
            customerButtonProps.title = 'No Customer Attached';
        }

        var opportunityButtonProps = null;
        if (this.state.allowOpportunity) {
            opportunityButtonProps = {};
            if (quote.IdCRMOpportunity) {
                opportunityButtonProps.className = 'quote-opportunity-active';
                opportunityButtonProps.title = quote.IdCRMOpportunity + ' is Attached';
            } else {
                opportunityButtonProps.className = 'quote-opportunity-inactive';
                opportunityButtonProps.title = 'No Opportunity Attached';
            }
        }
        let customerlink = null;
        if (!quote.IsTemplate) {
            customerlink = (<div onClick={QuoteStatusIndicators.loadEditCustomer.bind(null, quote)} className="quoteheader-icon" style={{cursor:'pointer'}}>
                <div id="customer-status" className={customerButtonProps.className} title={customerButtonProps.title} ></div>
            </div>);
        }

        let showOpportunityStatus = this.state.allowOpportunity && !quote.IsTemplate;

        return (
            <span style={this.props.style || {}}>
                {statusFlags.IsCompleted ?
                    <div className="quoteheader-icon" >
                        <div id="quote-status" className="quote-completed" title="This Quote is Approved!"></div>
                    </div> : ''}

                {statusFlags.IsEditable ?
                    <div className="quoteheader-icon" >
                        <div id="quote-status" className="quote-editable" title="Currently Editable"></div>
                    </div> : ''}

                {statusFlags.IsLocked ?
                    <div className="quoteheader-icon" >
                        <div id="quote-status" className="quote-locked" title="This quote is not editable."></div>
                    </div> : ''}

               {/* Hide the notifications icon, since that feature isn't fully implemented yet. */} 
               {/* {notificationStatus} */}

                {customerlink}

                {showOpportunityStatus ? <div onClick={QuoteStatusIndicators.goToOpportunity.bind(null, quote)} className="quoteheader-icon" style={{cursor:'pointer'}}>
                        <div id="opportunity-status" className={opportunityButtonProps.className} title={opportunityButtonProps.title} ></div>
                    </div> : ''}
            </span>
        );
    }
}
