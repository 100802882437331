import SvgSpinner from "js/lib/spin";
import {setMuiLicense} from '@cw-ui/utils';
import { AppGlobalContainer } from "./context/AppGlobalContainer";

global.windowMask = null;
global.quosal = {};
quosal.browser = {};
quosal.customization = {};
quosal.data = {};
quosal.events = {};
quosal.log = {};
quosal.metadata = {};
quosal.multiLevelApprovals = {};
quosal.navigation = {};
quosal.net = {};
quosal.priceModifier = {};
quosal.ui = {};
quosal.ui.dialog = {};
quosal.ui.react = {};
quosal.ui.spinners = {};
quosal.ui.views = {cache: {}};
quosal.util = {};
quosal.validation = {};

//======================//
//* Quosal.Validation  *//
//======================//

//SP 2/8/18 9791108: this logic relies on app.packageLevels being ordered from most to least authorization.
quosal.validation.isPackageLevelAuthorized = function(queryPackageLevel) {
    for (var key in app.packageLevels) {
        var loopPackageLevel = app.packageLevels[key];
        if (loopPackageLevel == app.settings.global.packageLevel) {
            return true;
        }
        if (loopPackageLevel == queryPackageLevel) {
            break;
        }
    }
    return false;
};

//===============//
//* Quosal.Log  *//
//===============//
quosal.log.debug = function (msg) {
    if (console) {
        if (typeof console.debug === 'function') {
            console.debug(msg);
        } else if (typeof console.log === 'function') {
            console.log(msg);
        }
    }
};

quosal.log.info = function (msg) {
    if (console && typeof console.info === 'function') {
        console.info(msg);
    }
};

quosal.log.warn = function (msg) {
    if (console && typeof console.warn === 'function') {
        console.warn(msg);
    }
};

quosal.log.error = function (msg) {
    if (console && typeof console.error === 'function') {
        console.error(msg);
    }
};

//========================//
//* Quosal.Events        *//
//========================//
quosal.events.create = function () {
    var e = {
        handlers: [],
        bind: function (handler, index) {
            if (index !== null && index !== undefined) {
                this.handlers.splice(index, 0, handler);
            } else {
                this.handlers.push(handler);
            }
            return this;
        },
        unbind: function (handler) {
            if (handler) {
                if (this.handlers.indexOf(handler) >= 0) {
                    this.handlers.splice(this.handlers.indexOf(handler), 1);
                }
            } else {
                this.handlers = [];
            }
            return this;
        },
        call: function () {
            var results = [];

            for (var i = 0; i < this.handlers.length; i++) {
                var result = this.handlers[i].apply(this, arguments);

                if (result !== undefined) {
                    results.push(result);
                }
            }

            if (results.length == 0) {
                return;
            } else if (results.length == 1) {
                return results[0];
            } else {
                return results;
            }
        }
    };

    return e;
};

quosal.events.beforeWindowUnload = quosal.events.create();

quosal.events.beforeUnloadFunction = function beforeUnloadFunction (e) {
    var results = quosal.events.beforeWindowUnload.call(e);

    if (!e.isDefaultPrevented) { //TODO: is this sufficient detection for 'browser is about to navigate away'? - TJ
        quosal.navigation.userNavigating = true;
    }

    return results;
};

window.addEventListener('beforeunload', quosal.events.beforeUnloadFunction);

//==============//
//* Quosal.Net *//
//==============//
quosal.net.activeAjaxCalls = [];
quosal.net.onAjaxSend = quosal.events.create();
quosal.net.send = function (message) {
    if (quosal.util.debugEnabled) {
        quosal.log.debug(message);
    }
    if (typeof window.checkCWSSOLogin !== "undefined") {
        window.checkCWSSOLogin();
    }
    quosal.util.mergeObject(message, searchToObject(location.search));
    quosal.net.onAjaxSend.call(message);
    var xhr = $.ajax({
        type: "POST",
        url: "quosalweb/quosalapi/" + message.api,
        data: JSON.stringify(message),
        dataType: 'JSON',
        contentType: 'application/json'
    });
    quosal.net.addAjaxCall(message, xhr);
    xhr.done(function(response) {
        var specialResponses = {};
        var normalResponses = {};
        const approvalRuleMessages = [];
        var hasApprovalResponse = false;
        var activeApprovalRules = [];
  
        if (typeof app !== 'undefined' && app.currentQuote && app.currentQuote.activeRules) {
            activeApprovalRules = app.currentQuote.activeRules;
        }
        if (typeof app !== 'undefined' && typeof app.quoteHasTriggeredApprovalRules === 'undefined') {
            app.quoteHasTriggeredApprovalRules = false;
        }
        if (typeof response === 'object' && response !== null) {
            Object.keys(response).forEach(function(respKey) {
                var jsonResp = response[respKey];
                if (jsonResp.approvalRuleMessages) {
                    approvalRuleMessages.push.apply(approvalRuleMessages, jsonResp.approvalRuleMessages);
                    hasApprovalResponse = true;
                    if (typeof app !== 'undefined') {
                        app.quoteHasTriggeredApprovalRules = approvalRuleMessages.length > 0;
                    }
                }

                if (jsonResp.opId) {
                    normalResponses[respKey] = jsonResp;
                } else {
                    specialResponses[respKey] = jsonResp;
                }
            });
        }
        var responseCount =  Object.keys(normalResponses).length;
        var naturalCollator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
        var orderedKeys = Object.keys(normalResponses).sort(naturalCollator.compare);

        if (responseCount > 0) {
            for (var x = 0; x < responseCount - 1; x++) {
                var tempResponse = normalResponses[orderedKeys[x]];
                quosal.net.removeAjaxCall(message);
                if (tempResponse.action == 'ApiError') {
                    message.error(tempResponse);
                } else {
                    message.stateChanged(tempResponse);
                }
            }
            var lastResponse = normalResponses[orderedKeys.slice(-1)[0]];
            quosal.net.removeAjaxCall(message);

            if (lastResponse.action == 'ApiError') {
                message.error(lastResponse);
            } else {
                if (message && typeof message.finished === 'function') {
                    message.finished(lastResponse);
                }
            }
        }

        var specialResponseCount =  Object.keys(specialResponses).length;
        var naturalCollator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
        var specialOrderedKeys = Object.keys(specialResponses).sort(naturalCollator.compare);
        if (specialResponseCount > 0) {
            for (var x = 0; x < specialResponseCount; x++) {
                var tempResponse = specialResponses[specialOrderedKeys[x]];
                quosal.net.removeAjaxCall(message);
                quosal.api.onServerMessage.call(tempResponse);
            }
        }

        if (typeof app !== 'undefined' && app.currentQuote) {
            app.currentQuote.activeRules = activeApprovalRules;          
        }

        if (hasApprovalResponse) {
            quosal.multiLevelApprovals.showNotifications(approvalRuleMessages);
        }
    });
    xhr.fail(function(xhr, textStatus, error) {
        quosal.net.removeAjaxCall(message);
        if (xhr.status == 401) {
            location.href = 'login.quosalweb' + location.search + '&referralurl=' + encodeURIComponent(location.href);
            return;
        }
        var serverMessage = {};
        serverMessage.error = error;
        serverMessage.stack = xhr.responseText;
        if (serverMessage.error == "abort") {
            return;
        }
        if (xhr.status == 400) {
            serverMessage.error = xhr.responseText;
        }
        message.error(serverMessage);
    });
};

quosal.net.addAjaxCall = function(message, xhr) {
    quosal.net.activeAjaxCalls.push({message:message, xhr:xhr});
};

quosal.net.removeAjaxCall = function(response) {
    quosal.net.activeAjaxCalls = quosal.net.activeAjaxCalls.filter(function(e) { return e.message.opId !== response.opId });
    quosal.net.activeAjaxCalls = quosal.net.activeAjaxCalls.filter(function(e) { return e.message.opId !== response.opIdSpecialMessage });    
};

quosal.net.abortAjaxCall = function(opId) {
    var ajaxCall = null;
    quosal.net.activeAjaxCalls.some(function(call) {
        if (call.message.opId == opId) {
            ajaxCall = call;
            return true;
        }
    });
    if (ajaxCall) {
        quosal.net.removeAjaxCall(ajaxCall.message);
        ajaxCall.xhr.abort();
    }
};

function searchToObject(search) {
    return search.substring(1).split("&").reduce(function(result, value) {
      var parts = value.split('=');
      if (parts[0]) {
        result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
      }
      return result;
    }, {});
}

//===============//
//* Quosal.Data *//
//===============//
quosal.data.MAX_INTEGER = 2147483647;
quosal.data.MAX_DECIMAL = 99999999999999;
quosal.data.getDecimalPrecision = function (value) {
    var intValue = Math.round(value * 10000);
    var precision;

    if (intValue % 100 == 0) {
        precision = 2;
    } else if (intValue % 10 == 0) {
        precision = 3;
    } else {
        precision = 4;
    }

    return precision;
};

//==================//
//* Quosal.Browser *//
//==================//
quosal.browser.isIOS = navigator.userAgent.toLowerCase().indexOf('ipad') >= 0
    || navigator.userAgent.toLowerCase().indexOf('iphone') >= 0;

//===============//
//* Quosal.Util *//
//===============//
quosal.util.timers = {};

quosal.util.beginTimer = function (timerId) {
    timerId = timerId || quosal.util.generateGuid();

    quosal.util.timers[timerId] = {
        id: timerId,
        start: new Date()
    };

    return timerId;
};

quosal.util.endTimer = function (timerId) {
    var timer = quosal.util.timers[timerId];
    delete quosal.util.timers[timerId];

    if (timer) {
        timer.end = new Date();
        timer.elapsed = (timer.end.getTime() - timer.start.getTime()) / 1000;
    }

    return timer;
};

quosal.util.generateGuid = function () {
    var d = new Date().getTime();

    var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });

    return guid;
};

quosal.util.isIntegar = function(num) {
    return (num ^ 0) === num;
};

quosal.util.trunc = function (textOrElem, length) {
    if (typeof (textOrElem) == 'string') {
        if (textOrElem.length > length) {
            return textOrElem.substr(0, length) + '...';
        } else {
            return textOrElem;
        }
    } else {
        //DOM element
        var text = $(textOrElem).text();

        if (text.length > length) {
            text = text.substr(0, length) + '...';
        }

        $(textOrElem).text(text);
    }
};

quosal.util.nullOrEmpty = function (text) {
    if (text == null || text.length == null || text.length == 0) {
        return true;
    } else {
        return false;
    }
};

quosal.util.lastIndexOf = function(array, property, value) {
    for (var i = array.length - 1; i >= 0; i--) {
        if (array[i][property] === value) {
        return i;
        }
    }
    return -1;
};

/*
 *  First argument is the path following /quosalweb/ with no query. All other arguments are taken as query arguments.
 *  There is a set of parameters that are automatically supplied if present in the current query string:
 *      ['webauthtoken', 'accesskey', 'idquotemain', 'skiplayout', 'noheader', 'debug']
 *  One implication of this is that you never need to supply arguments for the webauthtoken or accesskey parameters.
 *
 *  example:
 *      quosal.util.url('quote.dashboard', 'submodules=quote.content', 'idquotemain=' + id)
 *  would produce a url like this:
 *      quote.dashboard?accesskey=<accesskey>&idquotemain=<idquotemain>&submodules=quote.content
 */
quosal.util.url = function (path) {
    var url = path;
    var sep = path.indexOf('?') >= 0 ? '&' : '?';
    var mandatoryKeys = ['accesskey', 'idquotemain', 'skiplayout', 'noheader', 'debug'];

    var argumentKeySet = {};
    for (var i = 1; i < arguments.length; i++) {
        var argumentString = arguments[i].toString();
        var argumentTokens = argumentString.split('=');
        argumentKeySet[argumentTokens[0].toLowerCase()] = true;

        if (argumentTokens.length > 1) {
            url += sep + argumentString;
            sep = '&';
        }
    }

    for (var i = 0; i < mandatoryKeys.length; i++) {
        if ((argumentKeySet[mandatoryKeys[i]] !== true) && !quosal.util.nullOrEmpty(quosal.util.queryString(mandatoryKeys[i]))) {
            url += sep + mandatoryKeys[i] + '=' + quosal.util.queryString(mandatoryKeys[i]);
            sep = '&';
        }
    }

    return url;
};

quosal.util.clone = function (obj, maxDepth) {
    if (!maxDepth) {
        maxDepth = 0;
    }
    
    var cloneProp = function (prop, depth) {
        if (typeof prop === 'object' && depth > 0) {
            if (Array.isArray(prop)) {
                var arrayClone = [];
                for (var i = 0; i < prop.length; i++) {
                    if (typeof prop[i] == 'object') {
                        arrayClone.push(cloneProp(prop[i], depth));
                    } else {
                        arrayClone.push(prop[i]);
                    }
                }

                return arrayClone;
            } else {
                var objClone = {};
                for (var i in prop) {
                    objClone[i] = cloneProp(prop[i], depth - 1);
                }
                return objClone;
            }
        } else {
            return prop;
        }
    };

    if (typeof obj === 'object') {
        if (Array.isArray(obj)) {
            var clone = [];

            for (var i = 0; i < obj.length; i++) {
                var result = cloneProp(obj[i], maxDepth);

                if (result != null) {
                    clone.push(result);
                }
            }

            return clone;
        } else {
            var clone = {};

            for (var i in obj) {
                var result = cloneProp(obj[i], maxDepth);

                if (result != null) {
                    clone[i] = result;
                }
            }

            return clone;
        }
    } else {
        return obj;
    }
};

quosal.util.isEqualWith = function (obj1, obj2, ignoreKeys) {
    return _.isEqualWith(obj1, obj2, function (obj1Value, obj2Value, key) {
        return ignoreKeys.includes(key) ? true : undefined;   
    });
}

quosal.util.compare = function (obj1, obj2, depth) {
    if ((obj1 == null && obj2 != null) || (obj2 == null && obj1 != null)) {
        return false;
    }

    if (typeof obj1 !== typeof obj2) {
        return false;
    }

    var keys1 = Object.keys(obj1);
    var keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    keys1.sort();
    keys2.sort();

    for (var i = 0; i < keys1.length; i++) {
        if (keys1[i] !== keys2[i]) {
            return false;
        }

        //TODO: child object comparison based on depth? This currently only works with primitive data types

        if (obj1[keys1[i]] !== obj2[keys2[i]]) {
            return false;
        }
    }

    return true;
};

quosal.util.parseUri = function (str) {
    if (!quosal.util.parseUri.options) {
        quosal.util.parseUri.options = {
            strictMode: false,
            key: ["source", "protocol", "authority", "userInfo", "user", "password", "host", "port", "relative", "path", "directory", "file", "query", "anchor"],
            q: {
                name: "queryKey",
                parser: /(?:^|&)([^&=]*)=?([^&]*)/g
            },
            parser: {
                strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
                loose: /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/
            }
        };
    }

    if (str.indexOf('://') < 0) {
        var dir = '/';
        var pathParts = location.pathname.split('/');
        for (var i = 0; i < pathParts.length - 1; i++) {
            if (!String.isNullOrEmpty(pathParts[i])) {
                dir += pathParts[i] + '/';
            }
        }

        str = location.protocol + '//' + location.host + dir + str;
    }

    var o = quosal.util.parseUri.options,
        m = o.parser[o.strictMode ? "strict" : "loose"].exec(str),
        uri = {},
        i = 14;

    while (i--) uri[o.key[i]] = m[i] || "";

    uri[o.q.name] = {};
    uri[o.key[12]].replace(o.q.parser, function ($0, $1, $2) {
        if ($1) uri[o.q.name][$1] = $2;
    });
    uri.getOrigin = function () {
        return this.protocol + '://' + this.host;
    };
    uri.toRelativeUrl = function () {
        var qs = '?';

        for (var i in this.queryKey) {
            qs += (qs == '?' ? '' : '&') + i + '=' + this.queryKey[i];
        }

        return this.file + qs;
    };

    return uri;
};

quosal.util.parseQueryString = function (url) {
    var hash = {};

    if (quosal.util.nullOrEmpty(url)) {
        url = location.search;
    }

    if (!quosal.util.nullOrEmpty(url)) {
        if (url.indexOf('?') > 0) {
            url = '?' + url.split('?')[1];
        } else if (url.indexOf('?') < 0) {
            url = '?' + url;
        }

        var pairs = url.substr(1, url.length - 1).split('&');

        for (var i = 0; i < pairs.length; i++) {
            var keyVal = pairs[i].split('=');

            if (keyVal.length > 1) {
                hash[keyVal[0]] = keyVal[1];
            } else if (keyVal.length == 1) {
                hash[keyVal[0]] = '';
            }
        }
    }
    return hash;
};

quosal.util.updateQueryString = function (newPairs) {
    var q = quosal.util.parseQueryString();

    for (var i in newPairs) {
        q[i] = newPairs[i];
    }

    var qs = '?';

    for (var i in q) {
        qs += (qs == '?' ? '' : '&') + encodeURIComponent(i) + '=' + encodeURIComponent(q[i]);
    }

    return qs;
};

quosal.util.queryString = function (key, url) {
    if (!url) {
        url = location.search;
    }

    var parts = url.split('?');
    var query = parts[0];

    if (parts.length > 1) {
        query = parts[1];
    }

    var urlHash = quosal.util.parseQueryString('?' + query);

    return urlHash[key];
};

quosal.util.cookie = function (key, value, options) {
    if (value || value === '') {
        try {
            //cookie value should be saved as encoded value, but be read as decoded value
            value = decodeURIComponent(value);
        }
        catch { }
        let newCookie = key + '=' + encodeURIComponent(value) + ';';
        if (!options) {
            newCookie += 'expires=Session;path=/;secure=true;samesite=none;';
        }
        else {
            $.map(options, function(value, key) {
                if (key == 'expires') {
                    const d = new Date();
                    d.setTime(d.getTime() + (value * 24 * 60 * 60 * 1000));
                    value = d.toUTCString();
                }
                newCookie += key + '=' + value + ';';
            });
            if (!newCookie.includes('secure')) {
                newCookie += 'secure=true;';
            }
            if (!newCookie.includes('samesite')) {
                newCookie += 'samesite=none;';
            }
            if (!newCookie.includes('path')) {
                newCookie += 'path=/;';
            }
            if (!newCookie.includes('expires')) {
                newCookie += 'expires=Session;';
            }
        }

        document.cookie = newCookie;
        return value;
    } else {
        let name = key + "=";
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let c = cookies[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                let result = c.substring(name.length, c.length);
                try {
                    result = decodeURIComponent(result);
                }
                catch { }
                return result;
            }
        }

        return null;
    }
};

quosal.util.color = {};
quosal.util.color.generate = function (args) {
    if (!args) {
        args = {};
    }
    if (!args.a) {
        args.a = 1.0;
    }
    if (!args.seed) {
        args.seed = Math.floor(Math.random() * 2147483647);
    }

    var rand = Math.seed(args.seed);

    var getColor = function () {
        if (args.min) {
            return args.min + Math.floor((255 - args.min) * rand());
        } else if (args.max) {
            return Math.floor(args.max * rand());
        } else {
            return Math.floor(255 * rand());
        }
    };

    var r = args.r || getColor();
    var g = args.g || getColor();
    var b = args.b || getColor();

    return 'rgba(' + r + ',' + g + ',' + b + ',' + args.a + ')';
};

quosal.util.color.pallette = {};
quosal.util.color.pallette.create = function (colors) {
    return {
        colors: colors,
        currentIndex: 0,
        currentColor: function () {
            return this.colors[this.currentIndex];
        },
        nextColor: function () {
            var color = this.currentColor();

            this.currentIndex++;

            if (this.currentIndex >= this.colors.length) {
                this.currentIndex = 0;
            }

            return color;
        },
        previousColor: function () {
            var color = this.currentColor();

            this.currentIndex--;

            if (this.currentIndex < 0) {
                this.currentIndex = this.colors.length - 1;
            }

            return color;
        },
        reset: function () {
            this.currentIndex = 0;
        }
    };
};

quosal.util.color.pallette.default = quosal.util.color.pallette.create([
    '#ff0000', '#ff6600', '#009999', '#00cc00', '#660066', '#0099ff', '#000066',
    '#ff0066', '#006633', '#660033', '#ffcc00', '#00ffff', '#9900cc', '#0000cc',
    '#cc66cc', '#99cc99', '#990000', '#9999cc', '#ff9933', '#99cccc'
]);

quosal.util.htmlEncode = function (value) {
    return $('<div/>').text(value).html();
};

quosal.util.htmlEncodeAndConvertLineBreaks = function (value) {
    var result = quosal.util.htmlEncode(value);
    return result.split('\r\n').join('<br>').split('\n').join('<br>');
};

quosal.util.htmlDecode = function (value) {
    return $('<div/>').html(value).text();
};
//quosal.util.debugEnabled is currently used client side for more verbose logging, but could be refactored into a more
//thought out system for client side logging / debugging - TJ 7/27/16
quosal.util.debugEnabled = quosal.util.queryString('debug') == 'true';
quosal.util.isIPad = (navigator && (typeof navigator.userAgent === 'string') && navigator.userAgent.indexOf('iPad') >= 0);
quosal.util.mergeObject = function (destinationObject, objectToAdd) {
    for (var key in objectToAdd) {
        destinationObject[key] = objectToAdd[key];
    }
    return destinationObject;
};

quosal.util.callbackManagers = {};
quosal.util.getCallbackManager = function (verb, args) {
    if ('string' !== typeof verb) {
        return;
    }

    args = args || {};
    var callbackManager = quosal.util.callbackManagers[verb];
    if (!callbackManager) {
        quosal.util.callbackManagers[verb] = callbackManager = {};
        callbackManager.verb = verb;
        callbackManager.list = [];
        callbackManager.waiting = false;
        callbackManager.originalTimeout = args.timeout || 512;
        callbackManager.timeout = callbackManager.originalTimeout;
        callbackManager.timeoutExponent = args.timeoutExponent || 1.5;
        callbackManager.getNextTimeout = function (callbackManager) {
            var result = callbackManager.timeout;
            callbackManager.timeout *= callbackManager.timeoutExponent;
            // if (callbackManager.timeout > 60000) {  // going to keep trying every minute
            //     callbackManager.timeout = 60000;
            // }
            return result;
        }.bind(null, callbackManager);
        callbackManager.stopWaiting = function (callbackManager, keepCallbacks) {
            var Dialog = Dialog || null;
            if (Dialog && Dialog.ready()) {
                Dialog.close({dialogId: callbackManager.verb, skipAnimation: true});
            }
            if (!keepCallbacks) {
                callbackManager.list = [];
                delete callbackManager.currentApiCall;
            }
            callbackManager.waiting = false;
            callbackManager.timeout = callbackManager.originalTimeout;
        }.bind(null, callbackManager);
        callbackManager.runAll = function (callbackManager) {
            while (callbackManager.list.length > 0) {
                var callback = callbackManager.list.shift();
                quosal.log.debug('About to perform a callback for ' + callbackManager.verb);
                callback();
            }
            callbackManager.stopWaiting();
        }.bind(null, callbackManager);
    }
    return callbackManager;
};

quosal.util.objectAssign = function() {
    if (!Object.assign) {
        Object.defineProperty(Object, 'assign', {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function(target) {
            'use strict';
            if (target === undefined || target === null) {
                throw new TypeError('Cannot convert first argument to object');
            }
            var to = Object(target);
            for (var i = 1; i < arguments.length; i++) {
                var nextSource = arguments[i];
                if (nextSource === undefined || nextSource === null) {
                    continue;
                }
                nextSource = Object(nextSource);
        
                var keysArray = Object.keys(Object(nextSource));
                for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
                    var nextKey = keysArray[nextIndex];
                    var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
                    if (desc !== undefined && desc.enumerable) {
                        to[nextKey] = nextSource[nextKey];
                    }
                }
            }
            return to;
            }
        });
    }
};

quosal.util.getCrmShortName = function() {
    var customerProvider = quosal.settings.getValue("customerProvider");
    var crm = "";
    if (customerProvider.ciEquals("connectwise")) {
        crm = "cw";
    } else if (customerProvider.ciEquals("salesforce")) {
        crm = "sf";
    } else if (customerProvider.ciEquals("autotask")) {
        crm = "autotask";
    } else if (customerProvider.ciEquals("dynamics")) {
        crm = "dynamics";
    } else if (customerProvider.ciEquals("netsuite")) {        
        crm = "netsuite";
    } else if (customerProvider.ciEquals("quotedcustomers")) {
        crm = "quotedcustomers";
    } else if (customerProvider.ciEquals("liteservices")) {
        crm = "liteservices";
    }
    return crm;
},

quosal.util.b64EncodeUnicode = function (str) {
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
};

quosal.util.b64DecodeUnicode = function(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
};

quosal.util.areCustomersPresent = function () {
    var editableCustomerFields = [
        { id: "AccountName", name: "Account Name", isRequiredField: true },
        { id: "AccountNumber", name: "Account Number", isRequiredField: false },
        { id: "Website", name: "Website", isRequiredField: false },
        { id: "Description", name: "Account Description", isRequiredField: false },
        { id: "PriceLevelName", name: "Price Level", isRequiredField: false },
        { id: "PaymentTerms", name: "Payment Terms", isRequiredField: false },
        { id: "Address1", name: "Address 1", isRequiredField: false },
        { id: "Address2", name: "Address 2", isRequiredField: false },
        { id: "City", name: "City", isRequiredField: false },
        { id: "State", name: "State", isRequiredField: false },
        { id: "PostalCode", name: "Postal Code", isRequiredField: false },
        { id: "Country", name: "Country", isRequiredField: false },
        { id: "Title", name: "Title", column: 1, isRequiredField: false },
        { id: "FirstName", name: "First Name", column: 1, isRequiredField: true },
        { id: "LastName", name: "Last Name", column: 1, isRequiredField: true },
        { id: "JobTitle", name: "Job Title", column: 1, isRequiredField: false },
        { id: "EmailAddress", name: "Email", column: 2, isRequiredField: true },
        { id: "DayPhone", name: "Day Phone", column: 2, isRequiredField: false },
        { id: "MobilePhone", name: "Mobile Phone", column: 2, isRequiredField: false }
    ];
    return app.currentQuote.Customers.some(function (customer) {
        return editableCustomerFields.some(function (field) {
            return customer[field.id] != "";
        });
    });
};

quosal.util.areCustomersValid = function() {
    var requiredFields = ["AccountName", "EmailAddress", "FirstName", "LastName"];

    return app.currentQuote.Customers.every(function(customer){
        var noError = true;
        requiredFields.every(function(field){
            if (field == "LastName" && !app.settings.user.RequireLastNameForBillAndShipTo && (customer.UsageType == "Bill To" || customer.UsageType == "Ship To")) {
                noError = true;
            } else if (field == "EmailAddress" && !quosal.util.isValidEmail(customer[field])) {
                noError = false;
            } else if (customer[field] == '') {
                noError = false;
            }
            return noError;
        });
        return noError;
    });
};

quosal.util.navigateToCustomerSetupSubmodule = function() {
    if (!app.currentQuote) {
        return;
    }
    var disallowCustomerAndOppSearch = quosal.settings.getValue("disallowCustomerSearch");
    var customerSelectionMode = quosal.settings.getValue("customerSelectionMode");
    var hasCustomer = app.currentQuote.StatusFlags.HasCustomer;
    if (quosal.util.queryString("nextmodule") === 'crm.opportunity') {
        app.currentModule.loadSubModule('crm.opportunity', {container: 'quoteModule', suppressHistory: true });
    } else if (customerSelectionMode.toLowerCase() == "opportunity" && !disallowCustomerAndOppSearch && quosal.util.queryString("nextmodule") == "quote.attachOpportunity") {
        if (app.currentQuote.IdCRMOpportunity) {
            app.currentModule.loadSubModule('crm.opportunity', {container: 'quoteModule', suppressHistory: true});
        } else {
            app.currentModule.loadSubModule('quote.attachopportunity', {container: 'quoteModule', suppressHistory: true});
        }
    } else if (quosal.util.queryString("nextmodule") == "quote.attachOpportunity") {
        app.currentModule.loadSubModule('quote.attachopportunity', {container: 'quoteModule', suppressHistory: true});
    } else if (disallowCustomerAndOppSearch || hasCustomer || quosal.util.areCustomersPresent()) {
        app.currentModule.loadSubModule('quote.customerdetails', {container: 'quoteModule', suppressHistory: true});
    } else {
        app.currentModule.loadSubModule('quote.customersearch', {container: 'quoteModule', suppressHistory: true});
    }
};

quosal.util.crmAllowsOpportunityCheck = function () {
    var currentCrm = quosal.settings.getValue("customerProvider");
    if (currentCrm == "") {
        return false;
    }

    if (currentCrm.ciEquals("quotedcustomers")) {
        return false;
    }

    if (currentCrm.ciEquals("quosal")) {
        return false;
    }

    if (app.currentUser.IsReadOnly) {
        return false;
    }

    if (app.currentQuote && app.currentQuote.QuoteStatus == "Archived") {
        return false;
    }
    return true;
},

quosal.util.crmAllowCustomerSearchCheck = function () {
    if (quosal.util.getCrmShortName() == "") {
        return false;
    }
    return true;
};

quosal.util.userHasEditQuoteAuthority = function () {
    if (quosal.settings.getValue('approversHaveEditQuoteAuthority') && app.currentUser.IsApprover) {
        return true;
    }

    return false;
};

quosal.util.userCanCopyTabs = function() {
    return quosal.util.queryString("copytabs") == "true";
}

quosal.util.userCanModifyProtectedPrice = function () {
    return quosal.util.userIsAdminOrMaintainer() || app.currentUser.IsPriceChanger || quosal.util.userHasEditQuoteAuthority() || app.currentUser.IsStandardPlus; //add standard plus as part of quote to invoice ticket #9754564
};

quosal.util.userCanModifyProtectedItem = function () {
    return quosal.util.userIsAdminOrMaintainer() || quosal.util.userHasEditQuoteAuthority() || app.currentUser.IsStandardPlus; 
};

quosal.util.userCanModifyProtectedTab = function () {
    return quosal.util.userIsAdminOrMaintainer() || quosal.util.userHasEditQuoteAuthority() || app.currentUser.IsStandardPlus;
};

quosal.util.userIsAdminOrMaintainer = function () {
    if (app.currentUser?.IsContentMaintainer || app.currentUser?.IsAdministrator) {
        return true;
    }
    return false;
};

quosal.util.isProtectedPriceItem = function (item) {
    var protectedPriceItems = ["QuoteItemPrice", "PriceModifier", "BasePrice", "SuggestedPrice", "Cost", "CostModifier", "OverridePriceModifier",
                               "RecurringPrice", "RecurringPriceModifier", "RecurringBasePrice", "RecurringSuggestedPrice", "RecurringCost",
                               "RecurringCostModifier", "RecurringAmount", "IsProtectedPrice"];

    var index = protectedPriceItems.indexOf(item);

    if (index < 0) {
        return false;
    }

    return true;
};

quosal.util.isNewEditorPreviewContentEnabled = function (initQuote) {
    const quote = initQuote || app?.currentQuote;
    if (quote) {
        return quote.IsCkeditorEnabled && 
        quote.IsCkeditorPreviewEnabled 
    }

    return false;
};

quosal.util.isNewEditorEnabled = function (initQuote) {
    const quote = initQuote || app?.currentQuote;
    if (quote) {
        return quote.IsCkeditorEnabled;
    }

    return false;
};

quosal.util.isPDFPreviewAllowedViaNewEditor = function(initQuote) {
    const quote = initQuote || app?.currentQuote;
    if (quote) {
        return (quote.UseStandardPublishOutput && quosal.util.isNewEditorEnabled(quote)) || 
            !quosal.util.isNewEditorPreviewContentEnabled(quote)
    } 
    return false;
}

quosal.util.isPreviewContentOpened = function() {
    return app.settings.user.DocumentPreviewVisible;
}

quosal.util.shouldShowPdfPreviewOption = function(quote) {
    return !app.settings.user.documentPreviewDisabled && quosal.util.isPDFPreviewAllowedViaNewEditor(quote);    
}

quosal.util.isNewPreviewContentVisible = function() {
    return quosal.util.isPreviewContentOpened() && quosal.util.isNewEditorPreviewContentEnabled();
}

quosal.util.shouldDocumentPreviewBeOpen = (quote)=> {
    return app.settings.user.DocumentPreviewVisible &&
        quosal.util.shouldShowPdfPreviewOption(quote) && 
        quosal.util.isPDFPreviewAllowedViaNewEditor(quote);
};

quosal.util.userUseModificationTagToUpdate = function () {
    return !quosal.util.userIsAdminOrMaintainer();
};

quosal.util.quoteReadOnlyMode = function() {
    return (app.currentQuote.IsLocked || app.currentQuote.IsArchive || app.currentQuote.QuoteStatus == "Won");
};

quosal.util.isValidEmail = function (email) {
    var mailformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (mailformat.test(email)) {
        return true;
    }
    else {
        return false;
    }
};

quosal.util.getItemsByTabId = function (quote) {
    var result = {};
    if (quote && quote.Items && quote.Items.length) {
        for (var i = 0; i < quote.Items.length; i++) {
            var item = quote.Items[i];
            if (!result[item.IdQuoteTabs]) {
                result[item.IdQuoteTabs] = [];
            }
            result[item.IdQuoteTabs].push(item);
        }
    }
    return result;
};

quosal.util.getTabByTabId = function (quote, tabId) {
    if (quote && quote.Tabs && quote.Tabs.length) {
        for (var i = 0; i < quote.Tabs.length; i++) {
            var tab = quote.Tabs[i];
            if (tab.IdQuoteTabs == tabId) {
               return tab;
           }
        }
    }
    return {};
};

quosal.util.getItemById = function (quote, itemId) {
    if (quote && quote.Items && quote.Items.length) {
        for (var i = 0; i < quote.Items.length; i++) {
            var item = quote.Items[i];
            if (item.IdQuoteItems == itemId) {
                return item;
            }
        }
    }
    return {};
};

quosal.util.getPackageItems = function (quote, itemId) {
    return quote.Items.filter(function(item) {
        return item.ParentQuoteItem === itemId
    });
}

quosal.util.getTabByItemId = function (quote, idQuoteItem) {
    var tabId = quote.Items.find( function(item) {return item.IdQuoteItems == idQuoteItem}).IdQuoteTabs;
    return quote.Tabs.find(function(tab) {return tab.IdQuoteTabs == tabId});
};

quosal.util.getVisibleTabs = function (initQuote) {
    const quote = initQuote || app?.currentQuote;
    if (quote) {
        return quote.Tabs.filter((tab) => !(tab?.IsHidden && !quosal.util.userIsAdminOrMaintainer()));
    }
    return [];
}

quosal.util.pageURLToPageNameMapping = {"home": "Home", "quote.picktemplate": "New Quote", "quote.search": "Open Quote"};

quosal.util.getPageNameFromUrl = function() {
    var pageUrl = window.location.pathname.split('/').pop();
    return quosal.util.pageURLToPageNameMapping[pageUrl];
};

quosal.util.findWithAttr = function(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
};

quosal.util.getUserEmailAndRole = function() {
    var role = "Unknown";
    var emailAddress = "Unknown";
    if (typeof app !== "undefined" && app.currentUser) {
        emailAddress = app.currentUser.EmailAddress;
        role = "Standard";
        if (app.currentUser.IsAdministrator) {
            role = "Admin";
        } else if (app.currentUser.IsContentMaintainer) {
            role = "Content Maintainer";
        }
    }
    var userInfo = { email: emailAddress, role: role };
    return userInfo;
};

quosal.util.calculateAndTrackPageLoadTimeInAppInsights = function(startTime, logName) {
    if (window.appInsights) {
        var userInfo = quosal.util.getUserEmailAndRole();
        var elapsed = new Date().getTime() - startTime;
        window.appInsights.trackPageView(logName,
            window.location.href,
            { EmailAddress: userInfo.email, UserRole: userInfo.role },
            null,
            elapsed);
    }
}

quosal.util.isSpreadSheetOverride = function() {
    return !String.isNullOrEmpty(window?.app?.settings?.user?.tabSpreadSheetIFrameUrl);
}

quosal.util.getSpreadsheetUrl = function(idQuoteTabs) {
    var spreadSheetUrl = '';
    idQuoteTabs = String.isNullOrEmpty(idQuoteTabs) ? quosal.util.queryString('idquotetabs') : idQuoteTabs;

    if (quosal.util.isSpreadSheetOverride()) {
        spreadSheetUrl = app.settings.user.tabSpreadSheetIFrameUrl + "?noheader=yes";
    }
    else {
        spreadSheetUrl = "Spreadsheet.aspx?noheader=no";
    }
        
    return quosal.util.url(spreadSheetUrl, 'idquotetabs=' + idQuoteTabs)
}

quosal.util.goToProductSearch = function(idQuoteTabs, insertBefore) {
    let queryString = 'idquotetabs=' + idQuoteTabs;
    if (insertBefore) {
        queryString = queryString + '&insertbefore=' + insertBefore;
    }
    app.currentModule.loadSubModule('product.search', {
        container: 'quoteModule',
        query: queryString
    });
}

quosal.util.goToSalesTracksSearch = function(idQuoteTabs, insertBefore) {
    app.currentModule.loadSubModule("product.salestracks", {
        container: "quoteModule",
        query: "idquotetabs=" + idQuoteTabs
    });
}

quosal.util.goToEtilizeSearch = function(idQuoteTabs) {
    app.currentModule.loadSubModule('cloud.etilize', {
        container: 'quoteModule',
        query: 'idquotetabs=' + idQuoteTabs
    });
}
quosal.util.goToIngramQuoteSearch = function(idQuoteTabs) {
    app.currentModule.loadSubModule('ingram.ingramQuoteSearch', {
        container: 'quoteModule',
        query: 'idquotetabs=' + idQuoteTabs
    });
}
quosal.util.getProtectedBundleMessage = function(manufacturerPartNumber, isInvoiceGroup) {
    let bundleNounUppercase = (isInvoiceGroup ? 'Invoice group' : 'Bundle');
    let bundleNounLowercase = (isInvoiceGroup ? 'invoice group' : 'bundle');
    return (bundleNounUppercase + ' [' + manufacturerPartNumber +
        '] is protected, and it cannot be altered. If you need a change made to this ' + bundleNounLowercase +
        ', please speak with a system administrator');
}

quosal.util.goToArrowQuoteSearch = function(idQuoteTabs) {
    app.currentModule.loadSubModule('arrow.arrowQuoteSearch', {
        container: 'quoteModule',
        query: 'idquotetabs=' + idQuoteTabs
    });
}

quosal.util.goToArrowTermImport = function(idQuoteTabs) {
    app.currentModule.loadSubModule('arrow.arrowTermImport', {
        container: 'quoteModule',
        query: 'idquotetabs=' + idQuoteTabs
    });
}

//=====================//
//* Quosal.Navigation *//
//=====================//
quosal.navigation.onNavigate = quosal.events.create();
quosal.navigation.onBeforeNavigate = quosal.events.create();
quosal.navigation.callbacks = {};
quosal.navigation.allowNavigation = function (state) {
    if (!state) {
        state = {};
    }
    if (state.bypassCancelCheck || state.data?.bypassCancelCheck) {
        return true;
    }
    
    state.cancelNavigation = function () {
        this.canceled = true;
    }.bind(state);

    quosal.navigation.onBeforeNavigate.call(state);
    if (state.canceled === true) {
        return false;
    }

    return true;
};

quosal.navigation.moduleFallbackRedirect = function () {
    quosal.ui.hideMask(true);
    var moduleName = 'quote.customer';
    if (app.currentQuote.StatusFlags.HasCustomer) {
        moduleName = 'quote.home';
    }
    app.currentModule.loadSubModule(moduleName, {
        container: 'quoteModule',
        unloadSubModules: true,
        suppressHistory: true
    });
}

quosal.navigation.clientNavigate = function (r, state) {
    if (app.history.browserBackOrForward) {
        window.location.reload();
    } else {
        app.history.browserBackOrForward = true;
        if (!state) {
            state = History.getState();
        }

        if (!state.bypassBeforeLoadEvent) {
            state.resumeNavigation = function (state) {
                state.bypassBeforeLoadEvent = true;
                quosal.navigation.clientNavigate(state);
            }.bind(null, r, state);

            if (!quosal.navigation.allowNavigation(state)) {
                return;
            }
        }

        if (quosal.navigation.callbacks[state.data.rand]) {
            state.callback = quosal.navigation.callbacks[state.data.rand];
            delete quosal.navigation.callbacks[state.data.rand];
        }

        if (state.target == '_blank') {
            //SP 06/17: Opening a blank window returns a window object. Opening a window at our desired url returns undefined window.
            // as a test to see if popups are enabled we try to open a testwindow, and if it is not null we can open any window.
            var testWindow = window.open("", '_blank', 'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=10, height=10, visible=none', '');

            if (testWindow == null) {
                alert('This function requires popups to be enabled.');
            }
            else {
                testWindow.close();
                window.open(url, '_blank');
            }
            return;
        }

        if (state.hash.indexOf('.quosal') >= 0 || state.data.bypassClientNav) { //bypass client nav for non nextgen urls
            $.quosal.dialog.loading();
            location.href = state.hash;
            return;
        }

        quosal.util.queryHash = null;

        var target = '#' + (state.data.target || 'currentDocument');

        var root = $(target);
        if (root.length == 0) {
            root = $('#currentDocument');
        }

        if (window.event && window.event.currentTarget == window || state.data && state.data.isQuosalHyperlinkNavigate) {
            quosal.sell.modules.reload();
        }

        quosal.navigation.onNavigate.call(state);

        var viewArgs = {};
        if (state.callback) {
            viewArgs.callback = state.callback;
        }

        if (!state.data.suppressViewLoad) {
            var navUrl = state.data.overrideUrl || state.hash;
            quosal.ui.views.load(navUrl, root, viewArgs);
        }
    }
};

quosal.navigation.goToTab = function(tabId, itemId) {
    if (!tabId) {
        throw new Error('Tab Id required')
    }
    
    var quoteContentModule = quosal.sell.modules.find('quote.content');
    quoteContentModule && quoteContentModule.setState({ selectedTab: tabId});
    quoteContentModule.selectedItem = itemId;
    app.currentModule.loadSubModule("quote.content", {
        container: 'quoteModule',
        unloadSubModules: true,
        keepItemId: true
    });
};

quosal.navigation.trackAppInsights = function(title) {
    //Customer Setup is a routing module. It should not be tracked
    title = title || app.productBranding;
    if (title !== "Customer Setup") {
        var role = "Unknown";
        var emailAddress = "Unknown";
        if (typeof app !== "undefined" && app.currentUser) {
            emailAddress = app.currentUser.EmailAddress;
            role = "Standard";
            if (app.currentUser.IsAdministrator) {
                role = "Admin";
            } else if (app.currentUser.IsContentMaintainer) {
                role = "Content Maintainer";
            }
        }
        window.appInsights.trackPageView(title,
            window.location.href,
            {
                EmailAddress: emailAddress,
                UserRole: role
            }
        );
    }
};

quosal.navigation.navigate = function (url, state, title) {
    if (!state) {
        state = {};
    }
    if (window.appInsights) {
        quosal.navigation.trackAppInsights(title);
    }
    if (app && app.currentQuote && app.currentQuote.QuoteName && (title !== "Open Quote" && title !== "Home")) {
        title = app.currentQuote.QuoteName;
    } else if (title === "Home") {
        title=app.productBranding;
    } else if (title) {
        title = title;
    } else if (app && app.currentModule && app.currentModule.Name) {
        title = app.currentModule.Name;
    } else {
        title = "Connectwise";
    }
    state.rand = quosal.util.generateGuid();
    var getType = {};
    var urlIsFunction = getType.toString.call(url) === '[object Function]';
    var persistentParameters = ['skiplayout'];
    for (var i = 0; i < persistentParameters.length; i++) {
        var parameterKey = persistentParameters[i];
        var keyRegex = new RegExp('[\?&]' + parameterKey + '=', 'i');
        if (!keyRegex.test(url)) {
            var parameterValue = quosal.util.queryString(parameterKey);
            if (parameterValue && !urlIsFunction) {
                url += '&' + parameterKey + '=' + parameterValue;
            }
        }
    }

    if (!state.bypassBeforeLoadEvent) {
        state.resumeNavigation = function (url, state, title) {
            state.bypassBeforeLoadEvent = true;
            quosal.navigation.navigate(url, state, title);
        }.bind(null, url, state, title);

        if (!quosal.navigation.allowNavigation(state)) {
            return;
        }
    }

    if (state.callback) {
        quosal.navigation.callbacks[state.rand] = state.callback;
    }
    //SP 06/17: Opening a blank window returns a window object. Opening a window at our desired url returns undefined window. 
    // as a test to see if popups are enabled we try to open a testwindow, and if it is not null we can open any window.
    if (state.target == '_blank') {
        var testWindow = window.open("", '_blank', 'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=10, height=10, visible=none', '');

        if (testWindow == null) {
            alert('This function requires popups to be enabled.');
        }
        else {
            testWindow.close();
            if (urlIsFunction) { 
                url();
            } else {
                window.open(url, '_blank');
            }
        }
        return;
    } else if (urlIsFunction) { 
        url();
    } else if (url.indexOf('.quosal') >= 0 || url.indexOf('.aspx') >= 0 || state.bypassClientNav || !location.containsOrigin(url)) { //bypass client nav for non nextgen urls
        if (quosal.net.isCredentialChecking) {
            $.quosal.dialog.quickHide();
            $.quosal.dialog.loading();
            return;
        }
        quosal.navigation.cleanUpBeforeNavigation();
        $.quosal.dialog.loading();
        var url_new = url;
        var target_module = quosal.sell.modules.find(url?.split?.("?")?.[0]);
        if (target_module?.IsLegacyModule) {
            url_new = url + "&_suid=1";
        }
        //IsLegacyModule tells whether its a legacy module or not. If its a legacy module, and we had added .js in it 
        //like we have done to embed new quote header(react) in legacy page, then we need to add _suid=1 to the url to 
        //make sure that the page is not loaded twice, as histor.js was doing that behavior by explicitly adding _suid to
        // the url. To avod that we are adding _suid first hand
        location.href = url_new;
        return;
    } else {
        app.history.browserBackOrForward = false;
        History[state.suppressHistory ? 'replaceState' : 'pushState'](state, title, url);
    }
};

quosal.navigation.parse = function (root) {
    if (!root) {
        throw 'Must specify a DOM element.';
    }

    //TODO: CODE SMELL. Click events should be owned by React components themselves, period. Nothing good can come of this. (JA 2/19/19) Bugs caused by this function: #19932
    $(root).find('a').unbind('click');
    $(root).find('a').click(quosal.navigation.hyperlink);
    //TODO: hook form submits?
};

quosal.navigation.hyperlink = function (e) {
    var state = {};

    var targetsNewTab = $(e.currentTarget).attr('target') === '_blank';
    if (targetsNewTab) {
        return;
    }

    var url = $(e.currentTarget).attr('href');

    if (String.isNullOrEmpty(url)) { //this usually leads to a bad page load, should we prevent it instead? -TJ 7/28/16
        return true;
    }

    if (!state.bypassBeforeLoadEvent) {
        state.resumeNavigation = function (e) {
            state.bypassBeforeLoadEvent = true;
            quosal.navigation.hyperlink(e);
        }.bind(null, e);

        if (!quosal.navigation.allowNavigation(state)) {
            return;
        }
    }

    if (!String.prototype.endsWith) {
        String.prototype.endsWith = function(searchString, position) {
            var subjectString = this.toString();
            if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
              position = subjectString.length;
            }
            position -= searchString.length;
            var lastIndex = subjectString.indexOf(searchString, position);
            return lastIndex !== -1 && lastIndex === position;
        };
      }
      
    // FSP 6321894 6/14/17: I'm embedding a snap page into a legacy page. If the url of the current page is legacy-style, don't try to transition snap-style.
    if (location.pathname && location.pathname.endsWith('.quosalweb')) {
        return true;
    }

    if (url.indexOf('.quosalweb') >= 0) {
        quosal.navigation.cleanUpBeforeNavigation();
    }

    if (!location.containsOrigin(url)) { //non-origin links are not handled with client navigation
        return true;
    }

    e.preventDefault();

    var title = null;

    if ($(e.currentTarget).attr('target')) {
        state.target = $(e.currentTarget).attr('target');
    }

    if ($(e.currentTarget).attr('name')) {
        title = $(e.currentTarget).attr('name');
    } else {
        title = $(e.currentTarget).text();
    }
    state.isQuosalHyperlinkNavigate = true;
    quosal.navigation.navigate(url, state, title);
};

quosal.navigation.cleanUpBeforeNavigation = function () {
    quosal.navigation.userNavigating = true;

    for (var cleanUpFunction in quosal.navigation.cleanUpFunctionsBeforeNavigation) {
        quosal.navigation.cleanUpFunctionsBeforeNavigation[cleanUpFunction]();
    }
    quosal.navigation.cleanUpFunctionsBeforeNavigation = {};
};

quosal.navigation.cleanUpFunctionsBeforeNavigation = {};

quosal.navigation.punchoutRedirect = function(requestId, punchout, idquotetabs, cXmlString, csrfToken) {
    if (Dialog) {
        Dialog.setIsWorking(true);
    }
    var url = quosal.util.url('previewpunchoutimport.quosalweb', "requestid=" + requestId, "punchout=" + punchout, "idquotetabs=" + idquotetabs);
    url = url + "&_suid=1";
    var f = document.createElement("form");
    f.setAttribute('method',"post");
    f.setAttribute('action', url);

	var i = document.createElement("input");
	i.type = "hidden";
	i.setAttribute('value', csrfToken);
    i.setAttribute('name', "csrf-token");
    f.appendChild(i);
	
	i = document.createElement("input");
    i.type = "hidden";
    i.setAttribute('value', cXmlString);
    i.setAttribute('name', "cXmlString");
    f.appendChild(i);

    document.getElementsByTagName('body')[0].appendChild(f);
    f.submit();
};

quosal.navigation.productConfigurator = function(idquotetabs, catalogServiceURL) {
    const url = quosal.util.url(catalogServiceURL, 'idquotetabs=' + idquotetabs);
    const csrf_token = document.getElementsByTagName('meta')['csrf-token'].content;

    var form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', url);

    const csrfTokenInput = document.createElement('input');
    csrfTokenInput.type = 'hidden';
    csrfTokenInput.setAttribute('value', csrf_token);
    csrfTokenInput.setAttribute('name', 'csrf-token');
    form.appendChild(csrfTokenInput);

    document.getElementsByTagName('body')[0].appendChild(form);
    form.submit();    
}

//===============//
//* Quosal.UI   *//
//===============//
quosal.ui.datepicker = function (elem, changeHandler) {
    $(elem).parent().find('.clearDatePicker').remove();

    $(elem).datepicker({
        onSelect: changeHandler
    });

    if ($(elem).hasClass('clearable')) {
        //clearable datepicker
        var clear = $('<img class="fieldicon clearDatePicker" title="Clear Date" src="img/svgs/sell/Action_Clear.svg" />');
        $(elem).next('img').after(clear);
        clear.click(function (e) {
            var target = $(this).prev('img').prev('.datepicker.clearable');
            target.val('');
            target.change();

            if (changeHandler) {
                changeHandler();
            }
        });
        $(elem).change(function () {
            if ($(this).val() == '') {
                $(this).addClass('clear');
            } else {
                $(this).removeClass('clear');
            }
        });
        $(elem).change();
    }

    $(elem).parent().find('img.fieldicon').click(function () {
        $(this).prev('.datepicker').first().focus();
    });
};

quosal.ui.destroyDatepicker = function (elem) {
    $(elem).parent().find('.clearDatePicker').remove();
    $(elem).datepicker('destroy');
};

quosal.ui.reflect = function (root) {
    var reflect = function (elem) {
        elem = $(elem);

        if (elem.attr('reflect') != 'content') {
            //reflect attributes
            elem.each(function () {
                for (var i = 0; i < this.attributes.length; i++) {
                    if (this.attributes[i].value.indexOf('{') == 0 && this.attributes[i].value.indexOf('}') == this.attributes[i].value.length - 1) {
                        var path = this.attributes[i].value.substring(1, this.attributes[i].value.length - 1);
                        var val = Function('return ' + path)();
                        this.attributes[i].value = val;
                    }
                }
            });
        }

        //reflect content
        if (elem.attr('reflect') != 'self') {
            var html = elem.html();
            var result = '';

            var last = 0;
            var start = html.indexOf('{', 0);

            while (start >= 0) {
                var end = html.indexOf('}', start);

                if (end > start) {
                    var path = html.substring(start + 1, end);
                    var val = Function('return ' + path)();

                    result += html.substring(last, start);

                    if (val != null) {
                        result += val;
                    }

                    last = end + 1;
                    start = html.indexOf('{', last);
                } else {
                    break; //forgot a closing tag?
                }
            }

            result += html.substring(last, html.length);

            elem.html(result);
        }
    };

    if (!root) {
        root = document.body;
    }

    $(root).find('[reflect]').each(function (e, el) {
        try {
            reflect(el);
        } catch (ex) {
            quosal.log.error(ex);
        }
    });
};

quosal.ui.toggleTags = function (root) {
    var toggle = function (elem) {
        elem = $(elem);

        if (elem.attr('showif')) {
            var path = elem.attr('showif');
            var result = Function('return ' + path)();

            if (!result) {
                elem.remove();
            }
        } else if (elem.attr('hideif')) {
            var path = elem.attr('hideif');
            var result = Function('return ' + path)();

            if (result) {
                elem.remove();
            }
        }
    };

    if (!root) {
        root = document.body;
    }

    $(root).find('[showif],[hideif]').each(function (i, el) {
        try {
            toggle(el);
        } catch (ex) {
            quosal.log.error(ex);
        }
    });
};

quosal.ui.showMask = function (opacity, dontRemoveScroll) {
    if (!opacity) {
        opacity = .3;
    }

    if (!dontRemoveScroll) {
        $("body").css('overflow', 'hidden');
    }
    windowMask = $('#mask');
    windowMask.stop().fadeTo("slow", opacity);
};

quosal.ui.hideMask = function (immediate) {
    if (immediate) {
        $('#mask').stop().hide();
    } else {
        $('#mask').stop().fadeOut();
    }
    $("body").css('overflow', 'auto');
};

//========================//
//* Quosal.UI.React      *//
//========================//
quosal.ui.react.components = [];
quosal.ui.react.isUpdating = false;
quosal.ui.react.updateStart = null;
quosal.ui.react.updateIds = null;
quosal.ui.react.AppGlobalContainerComponent = AppGlobalContainer;
quosal.ui.react.render = function (component, container, reload=false) {
    if (!container) {
        return null;
    }
    try {
        if (!quosal.ui.react.AppGlobalContainer) {
            quosal.ui.react.AppGlobalContainer = React.createElement(AppGlobalContainer, null, null);
            ReactDOM.render(quosal.ui.react.AppGlobalContainer, document.getElementById("identifyContext"));
        }
    } catch { }

    if (component) {
        setMuiLicense();
        return quosal.ui.react.renderChild(component, container, reload);
    } else {
        return null;
    }
};

quosal.ui.react.createClass = function (classDefinition) {
    classDefinition.timedUpdate = function (updateName) {
        var timerId = quosal.util.beginTimer(updateName);
        //quosal.log.info('Update [' + timerId + '] started.');

        this.forceUpdate(function () {
            var timer = quosal.util.endTimer(timerId);
            quosal.log.info('Update [' + timerId + '] finished in ' + timer.elapsed + ' seconds.');
        }.bind(this));
    };
    return React.createClass(classDefinition);
};

quosal.ui.react.update = function (component, container) {
    try {
        if (!quosal.ui.react.AppGlobalContainer) {
            quosal.ui.react.AppGlobalContainer = React.createElement(AppGlobalContainer, null, null);
            ReactDOM.render(quosal.ui.react.AppGlobalContainer, document.getElementById("identifyContext"));
        }
    } catch { }

    return quosal.ui.react.renderChild(component, container);
};

quosal.ui.react.unmount = function (container) {
    try {
        if (!quosal.ui.react.AppGlobalContainer) {
            quosal.ui.react.AppGlobalContainer = React.createElement(AppGlobalContainer, null, null);
            ReactDOM.render(quosal.ui.react.AppGlobalContainer, document.getElementById("identifyContext"));
        }
    
        quosal.ui.react.removeChild(container);
    } catch (ex) {
        quosal.log.error(ex);
    }
};

quosal.ui.react.beginUpdate = function (objectIds) {
    quosal.log.info('React Update Started.');

    quosal.ui.react.isUpdating = true;
    quosal.ui.react.updateStart = new Date();

    if (typeof ids === 'string') {
        objectIds = [objectIds];
    }

    quosal.ui.react.updateIds = objectIds;

    //return quosal.util.generateGuid(); //TODO: to support multiple concurrent local updates, we could store each batch by an id
};

quosal.ui.react.endUpdate = function (updateId) {
    quosal.ui.react.isUpdating = false;

    var elapsed = new Date().getTime() - quosal.ui.react.updateStart.getTime();
    quosal.ui.react.updateStart = null;

    quosal.log.info('React Update: ' + (elapsed / 1000) + ' seconds, ' + quosal.ui.react.updateIds.length + ' updates.');

    quosal.ui.react.updateIds = null;
};

quosal.ui.react.isObjectUpdating = function (objectId, updateId) {
    return quosal.ui.react.updateIds && quosal.ui.react.updateIds.indexOf(objectId) >= 0;
};

//========================//
//* Quosal.UI.Spinners   *//
//========================//
quosal.ui.spinners.loading = function (customArgs) {
    var args = {
        lines: 13, // The number of lines to draw
        length: 13, // The length of each line
        width: 5, // The line thickness
        radius: 15, // The radius of the inner circle
        corners: 1, // Corner roundness (0..1)
        rotate: 0, // The rotation offset
        color: '#0093D0', // #rgb or #rrggbb
        speed: 1, // Rounds per second
        trail: 60, // Afterglow percentage
        shadow: false, // Whether to render a shadow
        hwaccel: false, // Whether to use hardware acceleration
        className: 'spinner', // The CSS class to assign to the spinner
        zIndex: 1e4, // The z-index (defaults to 2000000000)
        top: 'auto', // Top position relative to parent in px
        left: 'auto' // Left position relative to parent in px
    };

    if (customArgs) {
        for (var i in customArgs) {
            args[i] = customArgs[i];
        }
    }

    var spinner = new SvgSpinner(args).spin();

    return $(spinner.el);
};
quosal.ui.spinners.icon = function (customArgs) {
    var args = {
        lines: 12, // The number of lines to draw
        length: 4, // The length of each line
        width: 2, // The line thickness
        radius: 2, // The radius of the inner circle
        corners: 1, // Corner roundness (0..1)
        rotate: 0, // The rotation offset
        color: '#0093D0', // #rgb or #rrggbb
        speed: 1, // Rounds per second
        trail: 60, // Afterglow percentage
        shadow: false, // Whether to render a shadow
        hwaccel: false, // Whether to use hardware acceleration
        className: 'spinner icon', // The CSS class to assign to the spinner
        zIndex: 9989, 
        top: 'auto', // Top position relative to parent in px
        left: 'auto' // Left position relative to parent in px
    };

    if (customArgs) {
        for (var i in customArgs) {
            args[i] = customArgs[i];
        }
    }

    var spinner = new SvgSpinner(args).spin();

    //if (navigator.appName == 'Microsoft Internet Explorer') {
    //    $(spinner.el).css('top', 9); //lame
    //}

    return $(spinner.el);
};

//=====================//
//* Quosal.UI.Views   *//
//=====================//
quosal.ui.views.load = function (viewName, callbackOrParent, options) {
    if (callbackOrParent == null) {
        throw 'Must specify a callback or parent element to attach view to.';
    }

    if (!options) {
        options = {};
    }

    $.ajax(viewName + (viewName.indexOf('?') >= 0 ? '&' : '?') + 'view=true', {
        dataType: 'html',
        success: function (html) {
            var view = $('<div>' + html + '</div>');
            quosal.ui.views.parse(view);

            if (typeof callbackOrParent == 'function') {
                callbackOrParent(view);
            } else {
                callbackOrParent = $(callbackOrParent);

                quosal.ui.react.unmount(callbackOrParent[0]);

                callbackOrParent.html(view);

                if (options.callback) {
                    options.callback(view);
                }
            }
        }
    });
};
quosal.ui.views.parse = function (view) {
    if (!view) {
        view = document.body;
    }

    quosal.ui.toggleTags(view);
    quosal.ui.reflect(view);
    quosal.navigation.parse(view);

    $(view).find('view').each(function () {
        var src = $(this).attr('src');
        var cacheType = 'template';
        if ($(this).attr('cache')) {
            cacheType = $(this).attr('cache');
        }

        var cacheKey = $(this).attr('src');
        if ($(this).attr('cachekey')) {
            cacheKey = $(this).attr('cachekey');
        }

        quosal.ui.views.load(src, this);
    });
};


//=====================//
//* Quosal.UI.Dialog  *//
//* DEPRECATED - USE REACT COMPONENT (Dialog.open..., etc) *//
//=====================//
quosal.ui.dialog.show = function (params) {
    quosal.ui.dialog.isVisible = true;

    $('.spinner').remove();
    quosal.ui.showMask();

    var dialog = $('#maindialog');
    dialog.find('.validation').remove();
    dialog.find('.spinner').remove();
    dialog.find('.link').remove();
    if (dialog.hasClass('ui-draggable')) {
        dialog.draggable('destroy');
    }
    dialog.find('.drag-handle').empty();

    if (params.width) {
        dialog.css('width', params.width);
    } else {
        dialog.css('width', '');
    }

    if (params.height) {
        dialog.css('height', params.height);
    } else {
        dialog.css('height', '');
    }

    var obj = {
        elem: dialog,
        resetLinks: function () {
            dialog.find('.links').empty();

            if (params.links) {
                for (var i = 0; i < params.links.length; i++) {
                    var link = $('<a class="link">' + params.links[i].title + '</a>');

                    dialog.find('.links').append(link);

                    if (params.links[i].url) {
                        link.attr('href', params.links[i].url);

                        if (!params.links[i].target) {
                            link.click(function () {
                                $.quosal.dialog.hide();
                                $.quosal.dialog.loading();
                            });
                        }
                    }
                    if (params.links[i].target) {
                        link.attr('target', params.links[i].target);
                    }
                    if (params.links[i].callback) {
                        link.data('callback', params.links[i].callback);
                        link.on('click', function (e) {
                            if ($(this).data('callback') == null || $(this).data('callback').apply(this, e) != true) {
                                $(this).data('callback', null);
                                link.unbind();
                            }
                        });
                    }
                    if (params.links[i].script) {
                        link.data('script', params.links[i].script);                        
                        link.on('click', function (e) {
                            const scriptData = Function('return ' + $(this).data('script'))();
                            if ($(this).data('script') == null || scriptData != true) {
                                $(this).data('script', null);
                            }
                        });
                    }
                }
            }
        }
    };

    var icon = null;
    var loadingScreen = $('#loadingscreen').val();

    if (params.loadingScreen) {
        loadingScreen = params.loadingScreen;
    }

    if (loadingScreen == null || loadingScreen == '') {
        loadingScreen = 'spinner';
    }

    if (loadingScreen == 'spinner') {
        if (params.icon) {
            if (params.icon == 'wait') {
                icon = $.quosal.dialog.spinners.loading();
                icon.css('position', 'fixed');
                icon.hide();
                if ($.queryString('skiplayout') == 'yes') {
                    icon.css('top', Math.min(400, $(window).height() / 2 - 32));
                } else {
                    icon.css('top', $(window).height() / 2 - 32);
                }
                icon.css('left', $(window).width() / 2);

                $(document.body).append(icon);

                icon.fadeIn(300);

                $(window).resize(function () {
                    if ($.queryString('skiplayout') == 'yes') {
                        icon.css('top', Math.min(400, $(window).height() / 2 - 32));
                    } else {
                        icon.css('top', $(window).height() / 2 - 32);
                    }
                    icon.css('left', $(window).width() / 2);
                });
            }
            else if (typeof params.icon == 'string') {
                icon = $('<div class="validation ' + params.icon + ' tip dialog"></div>');
                dialog.prepend(icon);
            } else {
                icon = params.icon;
                dialog.prepend(icon);
            }
        }
    }

    if (params.title) {
        dialog.find('.title').html(params.title);
    } else {
        dialog.find('.title').html('');
    }

    dialog.find('.message').empty();

    var totalMessage = params.message || '';
    if (params.sellErrorIds && params.sellErrorIds.length) {
        totalMessage = totalMessage + '<br/><br/><span class=\"sellErrorId\">SellErrorID: ' + params.sellErrorIds.join(', ') + '</span>';
    }
    dialog.find('.message').html(totalMessage);

    if (params.resizable) {
        dialog.resizable();

        //TODO: max/restore resize buttons
        var close = $('<img title="Close" class="dialogbtn close" src="../img/svgs/v1.0/Action_Close.svg" />');
        var maximize = $('<img title="Maximize" class="dialogbtn maximize" src="../img/svgs/v1.0/Action_Maximize.svg" />');
        dialog.find('.drag-handle').append(close);
        dialog.find('.drag-handle').append(maximize);

        if (params.onClose) {
            close.click(params.onClose);
        } else {
            close.click(function () {
                quosal.ui.dialog.hide();
            });
        }
    } else {
        if (dialog.hasClass('ui-resizable')) {
            dialog.resizable('destroy');
        }
    }

    if (params.draggable) {
        dialog.draggable({handle: '.drag-handle'});
    } else {
        if (dialog.hasClass('ui-draggable')) {
            dialog.draggable('destroy');
        }
    }

    obj.resetLinks();

    if (!params.draggable) {
        $(window).resize(function () {
            if ($.queryString('skiplayout') == 'yes') {
                $.quosal.ui.positionIn(dialog, $(document.body), 'top-center', Math.min(200, $(window).outerHeight() / 2 - dialog.outerHeight() / 2));
            } else {
                $.quosal.ui.positionIn(dialog, $(document.body), 'top-center', $(window).outerHeight() / 2 - dialog.outerHeight() / 2);
            }

            if (icon != null && icon.hasClass('spinner')) {
                icon.css('margin-left', (dialog.width() / 2) - (icon.width() / 2));
            }
        });
    }

    $.quosal.ui.displayWindow($('#maindialog'), false);

    if ($.queryString('skiplayout') == 'yes') {
        $.quosal.ui.positionIn(dialog, $(document.body), 'top-center', Math.min(200, $(window).outerHeight() / 2 - dialog.outerHeight() / 2));
    } else {
        $.quosal.ui.positionIn(dialog, $(document.body), 'top-center', $(window).outerHeight() / 2 - dialog.outerHeight() / 2);
    }

    return obj;
};

quosal.ui.dialog.error = function (message, params) {
    quosal.ui.dialog.show({
        title: 'An Error Occurred',
        message: message,
        links: [quosal.ui.dialog.links.ok]
    });
};

quosal.ui.dialog.hide = function (callback) {
    quosal.ui.dialog.isVisible = false;
    if (quosal.ui.dialog.timeout) {
        clearTimeout(quosal.ui.dialog.timeout);
        quosal.ui.dialog.timeout = null;
    }
    $(document.body).children('.spinner').fadeOut(function () {
        $(this).remove();
    });
    $('#maindialog, #mask').stop().fadeOut(null, callback);
};

quosal.ui.dialog.links = {
    ok: {
        title: 'OK', callback: function () {
            $.quosal.dialog.hide();
        }
    },
    close: {
        title: 'Close', callback: function () {
            $.quosal.dialog.hide();
        }
    },
    cancel: {
        title: 'Cancel', callback: function () {
            $.quosal.dialog.hide();
        }
    },
    no: {
        title: 'No', callback: function () {
            $.quosal.dialog.hide();
        }
    }
};

quosal.ui.dialog.confirmDelete = function (callback, message, params) {
    message = (typeof message === 'undefined') ? 'Are you sure you want to delete?' : message;
    if (!params.icon) {
        params.icon = 'warning';
    }
    if (!params.title) {
        params.title = 'Confirm Delete';
    }
    if (!params.message) {
        params.message = message;
    }
    if (!params.links) {
        params.links = [
            {title: 'Yes, Delete', callback: callback},
            quosal.ui.dialog.links.cancel
        ];
    }
    quosal.ui.dialog.show(params);
};

//========================//
//* Quosal.Clipboard     *//
//========================//
quosal.clipboard = {data: {}};
quosal.clipboard.add = function (key, value) {
    if (quosal.clipboard.data[key]) {
        quosal.clipboard.data[key] = $.extend(quosal.clipboard.data[key], value);
    }
    else {
        quosal.clipboard.data[key] = value;
    }
};

quosal.clipboard.set = function (key, value) {
    quosal.clipboard.data[key] = value;
    quosal.clipboard.save();
};

quosal.clipboard.get = function (key) {
    return quosal.clipboard.data[key];
};

quosal.clipboard.remove = function (key) {
    delete quosal.clipboard.data[key];
    quosal.clipboard.save();
};

quosal.clipboard.save = function () {
    $.cookie('clipboard', JSON.stringify(quosal.clipboard.data));
};

quosal.clipboard.load = function () {
    if ($.cookie('clipboard')) {
        quosal.clipboard.data = JSON.parse($.cookie('clipboard'));
    }
};

quosal.clipboard.load();

//=======================//
//* Extension Methods   *//
//=======================//
Array.prototype.where = function (condition) {
    var result = [];
    for (var i = 0; i < this.length; i++) {
        if (condition(this[i], i)) {
            result.push(this[i]);
        }
    }

    return result;
};

Array.prototype.clone = function () {
    var clone = this.length === 1 ? [this[0]] : Array.apply(null, this);
    return clone;
};

Array.prototype.findIndex = function (condition) {
    for (var i = 0; i < this.length; i++) {
        if (condition(this[i])) {
            return i;
        }
    }

    return -1;
};

Array.prototype.removeAll = function (q) {
    var removed = [];

    if (typeof q == 'function') {
        var i = 0;

        while (i < this.length) {
            if (q(this[i], i)) {
                removed.push(this.splice(i, 1)[0]);
            } else {
                i++;
            }
        }
    } else {
        for (var i = 0; i < q.length; i++) {
            if (this.indexOf(q[i]) >= 0) {
                removed.push(this.splice(this.indexOf(q[i]), 1)[0]);
            }
        }
    }

    return removed;
};

Array.prototype.firstOrNull = function (q) {
    var sourceArray = this;

    if (q) {
        sourceArray = this.where(q);
    }

    if (sourceArray.length > 0) {
        return sourceArray[0];
    }
    else {
        return null;
    }
};

Math.seed = function (s) {
    return function () {
        s = Math.sin(s) * 99999;
        return s - Math.floor(s);
    };
};

if (!String.prototype.endsWith) { // Polyfill code from Mozilla 8/18/17 https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith#Polyfill
    String.prototype.endsWith = function (searchStr, Position) {
        // This works much better than >= because
        // it compensates for NaN:
        if (!(Position < this.length)) {
            Position = this.length;
        } else {
            Position |= 0; // round position
        }

        return this.substr(Position - searchStr.length,
                searchStr.length) === searchStr;
    };
}

String.isNullOrEmpty = function (s) {
    return quosal.util.nullOrEmpty(s);
};

String.prototype.hashCode = function () {
    var hash = 0;
    if (this.length == 0) return hash;
    for (var i = 0; i < this.length; i++) {
        var code = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + code;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
};

String.ciEquals = function (string1, string2) {
    return (string1 ? string1.toLowerCase() : null) == (string2 ? string2.toLowerCase() : null);
};

String.prototype.ciEquals = function (otherSting) {
    return String.ciEquals(this, otherSting);
};

location.currentModule = function () {
    var parts = location.pathname.split('/');
    return parts[parts.length - 1];
};

location.subModules = function () {
    var subModules = quosal.util.queryString('submodules');
    subModules = subModules ? subModules.split(',') : [];
    return subModules;
};

location.fullPath = function () {
    var virtualD = location.pathname.split('/')[1]; //0 is a blank string because of how js splits
    var origin = location.origin || (location.protocol + '//' + (location.host || location.hostname));
    return origin + '/' + virtualD + '/';
};

location.containsOrigin = function (url) {
    var url1 = quosal.util.parseUri(url);
    var url2 = quosal.util.parseUri(location.href);

    return url1.getOrigin() == url2.getOrigin();
};

Date.prototype.toCompanyFormat = function () {
    var sep = '/';
    var format = quosal.settings.getValue('dateFormat', 'm/d/yy');
    if (format.indexOf('-') >= 0) {
        sep = '-';
    }
    else if (format.indexOf('.') >= 0) {
        sep = '.';
    }

    var tokens = format.split(sep);
    if (tokens.length != 3) {
        tokens = ['m', 'd', 'yy']; //invalid format detected? revert to default?
    }

    var result = '';

    for (var i = 0; i < tokens.length; i++) {
        if (tokens[i] == 'm') {
            result += (i > 0 ? sep : '') + (this.getMonth() + 1);
        } else if (tokens[i] == 'd') {
            result += (i > 0 ? sep : '') + this.getDate();
        } else if (tokens[i] == 'yy') {
            result += (i > 0 ? sep : '') + this.getFullYear();
        } else {
            //invalid token?
        }
    }

    return result;
};

//=============================//
//* Quosal.Customization      *//
//=============================//
quosal.customization.fields = {
    types: {
        businessObject: 'BusinessObject',
        interface: 'Interface'
    },
    register: function (fields) {
        if (!quosal.customization.fields.configurations) {
            quosal.customization.fields.configurations = {};
        }

        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];

            if (!quosal.customization.fields.configurations[field.ObjectType]) {
                quosal.customization.fields.configurations[field.ObjectType] = {};
            }

            if (!quosal.customization.fields.configurations[field.ObjectType][field.ObjectName]) {
                quosal.customization.fields.configurations[field.ObjectType][field.ObjectName] = {};
            }

            quosal.customization.fields.configurations[field.ObjectType][field.ObjectName][field.FieldName] = field;
        }
    },
    load: function (objType, objName, callback) {
        if (!quosal.customization.fields[objType]) {
            quosal.customization.fields[objType] = {};
        }

        if (!quosal.customization.fields[objType][objName]) {
            quosal.customization.fields[objType][objName] = quosal.schema.describe(objType, objName);
        }

        if (objType == this.types.businessObject && objName == "QuoteItems" && app.settings.user.hiddenQuoteItemFields.length > 0) {
            this.updateCustomizationFieldsPermission(objType, objName, app.settings.user.hiddenQuoteItemFields);
        }

        if (callback) {
            callback(quosal.customization.fields[objType][objName]);
        }
    },
    updateCustomizationFieldsPermission: function(objType, objName, fieldsToUpdate) {
        var fieldsType = ["additionalFields", "allFields", "standardFields"];
        for (var i = 0; i < fieldsToUpdate.length; i++) {
            var field = $.trim(fieldsToUpdate[i]);
            for (var j = 0; j < fieldsType.length; j++) {
                var type = fieldsType[j];
                var fields = quosal.customization.fields[objType][objName][type];
                if (fields) {
                    var elemIndex = fields.findIndex(function (x) {
                        return x.FieldName === field;
                    });
                    if (elemIndex >= 0 ) {
                        quosal.customization.fields[objType][objName][type][elemIndex].IsPrivateField = true;
                        quosal.customization.fields[objType][objName][type][elemIndex].ReadOnly = true;
                    }      
                }
            }
        }
    },
    getFieldConfiguration: function (objType, objName, fieldName) {
        var fields = quosal.customization.fields[objType][objName];
        if (fields) {
            return fields.fieldConfigurations[fieldName];
        } else {
            return null;
        }
    },
    getFieldData: function(objType, objName, fieldName) {
        try {
            var fields = quosal.customization.fields[objType][objName]["allFields"];
            var index = quosal.util.findWithAttr(fields, "FieldName", fieldName);
            var fieldData = fields[index];
            return fieldData;
        } catch(exception) {
            return null;
        }
    },
    getFieldDisplayName: function (objType, objName, fieldName) {
        var fields = quosal.customization.fields[objType][objName];
        if (fields) {
            var config = fields.fieldConfigurations[fieldName];

            if (config) {
                return config.FieldRename;
            } else {
                var f = fields.standardFields.firstOrNull(function (s) {
                        return s.FieldName == fieldName;
                    }) ||
                    fields.additionalFields.firstOrNull(function (s) {
                        return s.FieldName == fieldName;
                    });

                if (f) {
                    return f.DisplayName;
                } else {
                    return fieldName;
                }
            }
        } else {
            return fieldName;
        }
    },
    updateFieldConfiguration: function (objType, objName, fieldName, newFieldConfig) {
        if (newFieldConfig) {
            quosal.customization.fields[objType][objName].fieldConfigurations[fieldName] = newFieldConfig;
        } else {
            delete quosal.customization.fields[objType][objName].fieldConfigurations[fieldName];
        }
    },
    sortByDisplayName: function (fieldConfigs, a, b) {
        var aFieldConfig = fieldConfigs[a.FieldName];
        var bFieldConfig = fieldConfigs[b.FieldName];
        var aValue = aFieldConfig && aFieldConfig.FieldRename || a.DisplayName;
        var bValue = bFieldConfig && bFieldConfig.FieldRename || b.DisplayName;
        return (aValue < bValue) ? -1 : (aValue == bValue) ? 0 : 1;
    },
    getFieldNameToDropdownLabelDictionary: function (fields, fieldConfigurations) {
        var fieldNameToLabel = {};

        var field;
        var name;
        var label;

        for (var i = 0; i < fields.length; i++) {
            field = fields[i];
            name = field.FieldName;
            var displayName = field.DisplayName;
            var rename = fieldConfigurations[name] && fieldConfigurations[name].FieldRename;
            rename = (displayName === rename) ? '' : rename;
            label = ((rename)
                ? rename + ' ' + '(' + displayName + ')'
                : displayName);
            fieldNameToLabel[name] = label;
        }

        return fieldNameToLabel;
    }
};

quosal.customization.grids = {
    configurationUpdated: quosal.events.create(),
    configurationChanged: quosal.events.create(),
    configurationDeleted: quosal.events.create(),
    register: function (grids) {
        if (grids) {
            for (var i = 0; i < grids.length; i++) {
                quosal.customization.grids[grids[i].GridName] = grids[i];

                var allConfigs = grids[i].Configurations;
                grids[i].Configurations = {};

                for (var c = 0; c < allConfigs.length; c++) {
                    allConfigs[c].BoVersion = 0; //indicates if this has been modified client-side
                    grids[i].Configurations[allConfigs[c].ConfigurationName] = allConfigs[c];
                }

                quosal.customization.fields.load(grids[i].ObjectType, grids[i].ObjectName);
            }
        }
    },
    load: function (gridNames, callback) {
        if (typeof gridNames == 'string') {
            gridNames = [gridNames];
        }

        var getGrids = quosal.api.customization.getGridConfiguration(gridNames);
        getGrids.finished = function (msg) {
            quosal.customization.grids.register(msg.grids);

            if (callback) {
                callback(msg.grids);
            }
        };
        getGrids.call();
    },
    update: function (grid) {
        grid.BoVersion = quosal.customization.grids[grid.GridName].Configurations[grid.ConfigurationName] ?
            quosal.customization.grids[grid.GridName].Configurations[grid.ConfigurationName].BoVersion + 1 : 0;
        quosal.customization.grids[grid.GridName].Configurations[grid.ConfigurationName] = grid;
        quosal.customization.grids.configurationUpdated.call(grid);
    },
    delete: function (gridName, configurationName, callback) {
        var deleteApi = quosal.api.customization.deleteGridConfiguration(gridName, configurationName);
        deleteApi.finished = function (msg) {
            if (msg.deleted) {
                if (quosal.customization.grids[msg.gridName].Configurations[msg.configurationName] != null) {
                    delete quosal.customization.grids[msg.gridName].Configurations[msg.configurationName];
                }

                quosal.customization.grids.configurationDeleted.call(msg.gridName, msg.configurationName);
            }

            if (callback) {
                callback(msg);
            }
        }.bind(this);
        deleteApi.call();
    },
    changeConfig: function (gridName, selectedConfiguration, callback) {
        quosal.settings.save({
            key: gridName + '_GridConfiguration',
            value: selectedConfiguration,
            isUserSetting: false
        }, callback);
    }
};

quosal.customization.forms = {
    configurationUpdated: quosal.events.create(),
    configurationChanged: quosal.events.create(),
    bindForm: function (form, object) {
        var clone = quosal.util.clone(form, 2);

        if (clone && clone.Fields) {
            for (var i = 0; i < clone.Fields.length; i++) {
                var field = clone.Fields[i];
                field.Value = object[field.FieldName];
            }
        }

        return clone;
    },
    formConfigurationFix: function (formName, configurationName) {
        Dialog.open({
            title: 'Warning', height: 'auto', width: '240px',
            links: [{
                title: 'OK',
                callback: Dialog.close
            }],
            message: "The form configuration '" + configurationName + "' had an error.\n The default configuration has been loaded."
        });
        quosal.customization.forms.changeConfig.call(this, formName, 'Default', null);
        if (formName == "QuoteSearch") {
            return {
                formName: 'QuoteSearch',
                configurationName: 'Default'
            };
        } else if (formName == "ProductSearch") {
            return {
                formName: 'ProductSearch',
                configurationName: 'Default'
            };
        } else {
            return quosal.customization.forms.bindForm(quosal.customization.forms[formName].Configurations['Default'], app.currentQuote);
        }
    },
    register: function (forms) {
        if (forms) {
            forms.sort(function (a, b) {
                return a.FormName.charCodeAt(0) - b.FormName.charCodeAt(0);
            });

            for (var i = 0; i < forms.length; i++) {
                var form = forms[i];
                quosal.customization.forms[form.FormName] = form;

                var allConfigs = form.Configurations;
                form.Configurations = {};

                for (var c = 0; c < allConfigs.length; c++) {
                    var config = allConfigs[c];

                    quosal.customization.fields.load(config.ObjectType, config.ObjectName);

                    var fieldCustomization = quosal.customization.fields[config.ObjectType][config.ObjectName];

                    if (!fieldCustomization) {
                        fieldCustomization = quosal.customization.fields[config.ObjectType][config.ObjectName] = {
                            additionalFields: [],
                            fieldConfigurations: {},
                            privateFields: [],
                            isPrivateField: {},
                            standardFields: []
                        };
                    }

                    // FSP 9/7/16: A config might be using a field that has been designated private since the config was saved. Nonetheless, private fields should never show; this removes them.
                    if (config.Fields) {
                        for (var f = config.Fields.length - 1; f >= 0; f--) {
                            if (fieldCustomization.isPrivateField[config.Fields[f].FieldName]) {
                                config.Fields.splice(f, 1);
                            }
                        }
                    }

                    form.Configurations[config.ConfigurationName] = config;

                    if (form.Metadata) {
                        config.Metadata = form.Metadata;
                    }

                    if (form.Widgets) {
                        config.Widgets = form.Widgets;
                    }

                    quosal.customization.forms.loadWidgets(config);

                    config.bind = function (object) {
                        return quosal.customization.forms.bindForm(this, object);
                    }.bind(config);

                    if (config.ConfigurationName == 'Default') {
                        //Create temporary field configs for fields that are renamed in the default layout and haven't been explicitely renamed. Ticket #8132326 - TJ
                        // TODO FSP 3/30/17: These "temporary" field configs bleed into the configurations that the whole app uses, causing name display changes beyond the form.

                        for (var f = 0; f < config.Fields.length; f++) {
                            var defaultField = config.Fields[f];
                            var fieldConfig = fieldCustomization.fieldConfigurations[defaultField.FieldName];

                            if (!fieldConfig) {
                                fieldCustomization.fieldConfigurations[defaultField.FieldName] = {
                                    FieldName: defaultField.FieldName,
                                    FieldRename: defaultField.LabelName,
                                    ObjectType: quosal.customization.fields.types.businessObject,
                                    ObjectName: config.ObjectName
                                };
                            }
                        }
                    }
                }
            }
        }
    },
    load: function (formNames, callback) {
        if (typeof formNames == 'string') {
            formNames = [formNames];
        }

        var getForms = quosal.api.customization.getFormConfiguration(formNames);
        getForms.finished = function (msg) {
            quosal.customization.forms.register(msg.forms);

            if (callback) {
                callback(msg.forms);
            }
        };
        getForms.call();
    },
    loadWidgets: function (formConfig) {
        if (!formConfig.Fields) {
            return;
        }

        for (var f = 0; f < formConfig.Fields.length; f++) {
            if (formConfig.Widgets && formConfig.Widgets[formConfig.Fields[f].FieldName]) {
                formConfig.Fields[f].WidgetType = formConfig.Widgets[formConfig.Fields[f].FieldName].WidgetType;
                formConfig.Fields[f].WidgetParameters = formConfig.Widgets[formConfig.Fields[f].FieldName].WidgetParameters;

                if (formConfig.Fields[f].WidgetType == 'Enum') {
                    formConfig.Fields[f].DataType = 'Enum';
                    formConfig.Fields[f].EnumType = formConfig.Fields[f].WidgetParameters;
                }
            }
        }
    },
    update: function (formConfig) {
        formConfig.Metadata = quosal.customization.forms[formConfig.FormName].Metadata;
        formConfig.Widgets = quosal.customization.forms[formConfig.FormName].Widgets;
        quosal.customization.forms[formConfig.FormName].Configurations[formConfig.ConfigurationName] = formConfig;
        quosal.customization.forms.loadWidgets(formConfig);
        quosal.customization.forms.configurationUpdated.call(formConfig);
    },
    changeConfig: function (formName, selectedConfiguration, callback) {
        quosal.settings.save({
            key: formName + '_FormConfiguration',
            value: selectedConfiguration,
            isUserSetting: false
        }, callback);
    },
    deleteBadConfig: function (formName, configurationName) {
        var deleteApi = quosal.api.customization.deleteFormConfiguration(formName, configurationName);
        deleteApi.finished = function () {
            delete quosal.customization.forms[formName].Configurations[configurationName];
        }.bind(this);
        deleteApi.call();
    },
    options: {
        Boolean: [
            {Label: ""},
            {Value: "true", Label: "True"},
            {Value: "false", Label: "False"}
        ]
    }
};

quosal.customization.metadata = {
    getValueOfTag: function(formName, fieldName, tagName) {
        if (quosal.formFieldMetadata &&
            quosal.formFieldMetadata[formName] &&
            quosal.formFieldMetadata[formName].Metadata &&
            quosal.formFieldMetadata[formName].Metadata[fieldName]
        ) {
            return quosal.formFieldMetadata[formName].Metadata[fieldName][tagName];  
        } else {
            return null;
        }
    }
};

//=============================//
//* Quosal.Settings           *//
//=============================//
quosal.settings = {
    load: function (keys, callback) {
        var settingsApi = quosal.api.settings.getSettings(keys);
        settingsApi.finished = function (msg) {
            quosal.settings.apply(msg.settings);

            if (callback) {
                callback();
            }
        };
        settingsApi.call();
    },
    save: function (settings, callback) {
        var settingsApi = quosal.api.settings.saveSettings(settings);
        settingsApi.finished = function (msg) {
            quosal.settings.apply(msg.settings);

            if (callback) {
                callback();
            }
        };
        settingsApi.call();
    },
    saveUserSetting: function (settings, callback) {
        var settingsApi = quosal.api.settings.saveUserSettings(settings);
        settingsApi.finished = function (msg) {
            quosal.settings.applyUserSetting(msg.settings);
            if (callback) {
                callback();
            }
        };
        settingsApi.call();
    },
    applyUserSetting: function (settings) {
        for (var i = 0; i < settings.length; i++) {
            app.settings.user[settings[i].Key] = settings[i]?.Value?.toLowerCase() === "true";
        }
    },
    apply: function (settings) {
        for (var i = 0; i < settings.length; i++) {
            //TODO: should user settings always win over global if both are detected? 
            app.settings[settings[i].Key] = settings[i];
        }
    },
    getValue: function (key, defaultValue) {
        if (app.settings[key] != null) {
            return app.settings[key].Value;
        }
        else if (app.settings.user[key] != null) {
            return app.settings.user[key];
        }
        else if (app.settings.global[key] != null) {
            return app.settings.global[key];
        }
        else {
            return defaultValue;
        }
    }
};

//=========================//
//* Quosal.PriceModifier *//
//=======================//
quosal.fullModifierRegex = /^(PRICEOVERRIDE|TABDEFAULTS|((M|G|L|B)-?\d+\.?\d*)?((N|R|U)((-[1-9])|[0-4]))?)$/i
quosal.costModifierRegex = /^(C-?\d+\.?\d*)?$/i;
                           
quosal.priceModifier = {
    priceModes: ['Normal', 'Markup', 'Gross Margin', 'Base Price Discount', 'List Price Discount'],

    targetPriceControlTypes: {
        Normal: 0,
        TargetMargin: 1,
        TargetMarkup: 2,
        TargetDiscount: 3,
        TargetListDiscount: 4
    },
    getPriceValues: function(tab, priceModifierType) {
        var priceObj = {};
        var discountValue = '';
        var discountLabel = '';
        var priceControlType = 0;
        var currentMode = 'Normal';
        var roundingMethod = "";
        var roundingPrecision = 2;

        if (priceModifierType == 'PriceModifier') {
            if (tab.IsTargetMarkup) {
                currentMode = 'Markup';
                discountLabel = 'Target Markup';
                discountValue = tab.TargetMarkup;
                priceControlType = 2;
            } else if (tab.IsTargetMargin) {
                currentMode = 'Gross Margin';
                discountLabel = 'Target Margin';
                discountValue = tab.TargetMargin;
                priceControlType = 1;
            } else if (tab.IsTargetDiscount) {
                currentMode = 'Base Price Discount';
                discountLabel = 'Discount %';
                discountValue = tab.TargetDiscount;
                priceControlType = 3;
            } else if (tab.IsTargetListDiscount) {
                currentMode = 'List Price Discount';
                discountLabel = 'Discount %';
                discountValue = tab.TargetDiscount;
                priceControlType = 4;
            }
            roundingMethod = tab.OnetimeRoundingMethod;
            roundingPrecision = tab.OnetimeRoundingPrecision;
        } else if (priceModifierType == 'RecurringPriceModifier') {
            if (tab.RecurringTargetPriceControlType == quosal.priceModifier.targetPriceControlTypes.TargetMarkup) {
                currentMode = 'Markup';
                discountLabel = 'Target Markup';
                discountValue = tab.RecurringTargetPriceControlValue;
            } else if (tab.RecurringTargetPriceControlType == quosal.priceModifier.targetPriceControlTypes.TargetMargin) {
                currentMode = 'Gross Margin';
                discountLabel = 'Target Margin';
                discountValue = tab.RecurringTargetPriceControlValue;
            } else if (tab.RecurringTargetPriceControlType == quosal.priceModifier.targetPriceControlTypes.TargetDiscount) {
                currentMode = 'Base Price Discount';
                discountLabel = 'Discount %';
                discountValue = tab.RecurringTargetPriceControlValue;
            } else if (tab.RecurringTargetPriceControlType == quosal.priceModifier.targetPriceControlTypes.TargetListDiscount) {
                currentMode = 'List Price Discount';
                discountLabel = 'Discount %';
                discountValue = tab.RecurringTargetPriceControlValue;
            }
            priceControlType = tab.RecurringTargetPriceControlType;
            roundingMethod = tab.RecurringRoundingMethod;
            roundingPrecision = tab.RecurringRoundingPrecision;
        }
        if (!roundingMethod) {
            roundingMethod = 'Bankers';
        }
        priceObj.priceModifierType = priceModifierType;
        priceObj.currentMode = currentMode;
        priceObj.discountLabel = discountLabel;
        priceObj.discountValue = discountValue;
        priceObj.priceControlType = priceControlType;
        priceObj.roundingMethod = roundingMethod;
        priceObj.roundingPrecision = roundingPrecision;
        return priceObj;
    },
    modifierMatches : {
        "CostModifier": {regexMatch: quosal.costModifierRegex, errorMessage: "Invalid Cost Modifier. Please use C as your modifier, followed by a number."}, 
        "RecurringCostModifier": {regexMatch: quosal.costModifierRegex, errorMessage: "Invalid Recurring Cost Modifier. Please use C as your modifier."},
        "PriceModifier": {regexMatch: quosal.fullModifierRegex, errorMessage: "Invalid Price Modifier. Please use M (Markup) G (Gross Margin) L (List Price Discount) or B (Base Price Discount) as your modifier, followed by a number."}, 
        "RecurringPriceModifier": {regexMatch: quosal.fullModifierRegex, errorMessage: "Invalid Recurring Price Modifier. Please use M (Markup) G (Gross Margin) or L (List Price Discount) as your modifier, followed by a number."},
        "OverridePriceModifier": {regexMatch: quosal.fullModifierRegex, errorMessage: "Invalid Override Price Modifier. Please use M (Markup) G (Gross Margin) L (List Price Discount) or B (Base Price Discount) as your modifier, followed by a number."},
        "RecurringCalculatedPriceModifier": {regexMatch: quosal.fullModifierRegex, errorMessage: "Invalid Recurring Price Modifier. Please use M (Markup) G (Gross Margin) or L (List Price Discount) as your modifier, followed by a number."}
    },
    
    validateModifierPattern : function(input, fieldName, validationString) {
        var modifierObj = quosal.priceModifier.modifierMatches[fieldName];
        var matchRegex = modifierObj && modifierObj.regexMatch;

        if (validationString && validationString.match(matchRegex) == null) {
            $.quosal.validation.validateField(input, 'borderErrorNoIcon', modifierObj.errorMessage)
            return false;
        } else {
            $.quosal.validation.clearField($(input));
            return true;
        }
    }
};                            