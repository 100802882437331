import * as React from "react";
import BaseContextMenu from "./baseContextMenu.jsx";

function ScriptContextMenu({ quosal, contentGrid, gridToolbarStyle }) {
	const scripts = [];

	const initActions = () => {
		const scripts = quosal.sell.scripts.getScripts("OnDemand");
		if (scripts && scripts.length > 0) {
			const runScript = function (script) {
				const selectedTab = contentGrid.refs.tabControl.getSelectedTab();
				let idQuoteItems;
				const { grid } = selectedTab.gridLoader;

				if (grid) {
					const selectedRows = Object.keys(grid.state.isRowSelected);
					if (selectedRows.length > 0) {
						const currentItem = grid.props.rows[selectedRows[0]];

						if (currentItem) idQuoteItems = currentItem.IdQuoteItems;
					}
				}

				const runApi = quosal.api.script.runScript(
					script.ScriptType,
					script.IdQuosalSellScript,
					contentGrid.props.quote.IdQuoteMain,
					selectedTab.item.IdQuoteTabs,
					idQuoteItems
				);
				runApi.finished = function (msg) {
					if (!String.isNullOrEmpty(msg.scriptLog)) {
						Dialog.open({
							draggable: true,
							resizable: true,
							title: "Script Results",
							message: React.createElement("div", {
								dangerouslySetInnerHTML: {
									__html: msg.scriptLog.replace("\r\n", "<br />")
								}
							}),
							links: [Dialog.links.ok]
						});
					}

					if (!String.isNullOrEmpty(msg.launchURL)) {
						const win = window.open(msg.launchURL, msg.urlTarget);

						if (!win) alert("This function requires popups to be enabled.");
					}

					if (msg.quote) {
						// eslint-disable-next-line
						this.state.scriptRunning = null;
						quosal.sell.quote.update(msg.quote);
					} else {
						this.setState({ scriptRunning: null });
					}
				}.bind(this);
				runApi.call();

				this.setState({ scriptRunning: script.Name });
			}.bind(this);

			if (scripts.length > 1) {
				const scriptActions = [];
				for (let i = 0; i < scripts.length; i++) {
					scriptActions.push({
						id: i,
						name: `Run Script: ${scripts[i].Name}`,
						callback: runScript.bind(this, scripts[i])
					});
				}
			}
		}
	};

	initActions();

	return (
		<>
			{scripts && scripts.length ? (
				<span style={gridToolbarStyle}>
					<BaseContextMenu title="Avaliable Scripts" quosal={quosal} actions={scripts} />
				</span>
			) : null}
		</>
	);
}

export default ScriptContextMenu;
