function usePriceSorceModalStyle(darkMode, selectedResult, updating) {
	const titleBlockStyle = {
		display: "flex",
		alignItems: "center",
		height: 40,
		padding: 10,
		borderBottomWidth: "1px",
		borderBottomStyle: "solid",
		borderBottomColor: "#cccccc"
	};
	const priceSourceTitleStyle = {
		font: "Roboto",
		fontWeight: 400,
		fontSize: 20,
		lineHeight: "24px"
	};
	const toolBarStyle = { display: "inline-block", marginLeft: 10, marginBottom: 2 };
	const inputLabelStyle = {
		fontFamily: "Roboto",
		fontWeight: 500,
		fontSize: 14,
		lineHeight: "24px",
		color: "#333333",
		marginLeft: 10
	};
	const sourceRadioButtonStyle = { padding: 10, accentColor: "#5198b0", width: "inherit" };
	const divStyle = {
		height: 50,
		width: 359,
		borderBottomWidth: "1px",
		borderBottomStyle: "solid",
		borderBottomColor: "#cccccc"
	};
	const selectFieldWrapperStyle = {
		height: 40,
		width: 340,
		border: "none",
		backgroundPosition: "97%"
	};
	const sourceGroupSelectionStyle = {
		height: 35,
		width: 330,
		marginLeft: 10,
		border: "1px solid #5198b0",
		borderRadius: "4px",
		padding: "8px 15px",
		fontFamily: "Roboto",
		fontWeight: 400,
		fontSize: 14,
		lineHeight: "24px",
		...(darkMode ? { color: "white" } : { color: "black" })
	};
	const gearIcomStyle = {
		...(darkMode ? { fill: "#FFF" } : { fill: "#333333" }),
		marginTop: 3
	};
	const actionButtonsStyle = {
		display: "flex",
		padding: "16px",
		justifyContent: "space-between"
	};
	const cancelButtonStyle = {
		width: 154,
		height: 48,
		fontFamily: "Roboto",
		fontWeight: 500,
		fontSize: 20,
		lineHeight: "40px",
		paddingLeft: 35,
		color: "#2E3F80"
	};
	const updateButtonStyle = {
		...((!selectedResult || updating) && { pointerEvents: "none", opacity: 0.38 }),
		width: 154,
		height: 48,
		fontFamily: "Roboto",
		fontWeight: 500,
		fontSize: 20,
		lineHeight: "40px",
		backgroundColor: "#2E3F80"
	};
	return {
		titleBlockStyle,
		priceSourceTitleStyle,
		toolBarStyle,
		inputLabelStyle,
		sourceRadioButtonStyle,
		divStyle,
		selectFieldWrapperStyle,
		sourceGroupSelectionStyle,
		gearIcomStyle,
		actionButtonsStyle,
		cancelButtonStyle,
		updateButtonStyle
	};
}

export default usePriceSorceModalStyle;
