import { QuoteEditForm } from "js/jsx/src/classes/quote/quoteEditForm.jsx";

export default function quoteHome() {
    if (!app.currentQuote) {
        return;
    }
    var userInfo = quosal.util.getUserEmailAndRole();
    var start = new Date().getTime();

    quosal.ui.react.render(
        <FormPlaceholder message="Loading Quote Home..."/>,
        document.getElementById('quoteFormContainer'));

    var renderEditForm = function(renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;

        var formConfiguration = quosal.customization.forms.bindForm(
            quosal.customization.forms['QuoteEdit'].Configurations[quosal.settings.getValue(
                    'QuoteEdit_FormConfiguration') ||
                'Default'],
            app.currentQuote);
        if (formConfiguration == null || !formConfiguration.Panels) {
            formConfiguration = quosal.customization.forms.formConfigurationFix('QuoteEdit',
                quosal.settings.getValue('QuoteEdit_FormConfiguration'));

        }
        renderMethod(<QuoteEditForm configuration={formConfiguration} quote={app.currentQuote}
                                    onUpdate={() => renderEditForm()}/>,
            document.getElementById('quoteFormContainer'));
    };

    var loadEditForm = function() {
        renderEditForm(quosal.ui.react.render);

        $('#pageName').val('quote.home');
    };

    var updateEditForm = function() {
        renderEditForm();
    };

    var changeConfiguration = function() {
        updateEditForm();
    };

    var unbindEvents = function() {
        quosal.customization.forms.configurationChanged.unbind(changeConfiguration);
        quosal.customization.forms.configurationUpdated.unbind(changeConfiguration);
        quosal.sell.quote.onUpdate.unbind(updateEditForm);
        quosal.navigation.onNavigate.unbind(unbindEvents);
    };
    if (quosal.sell.modules.find('quote.home').IsLocked && !app.currentQuote.IsTemplate) {
        app.currentModule.loadSubModule('quote.customer',
            {
                container: 'quoteModule',
                unloadSubModules: true,
                query: location.search
            });
    } else {
        quosal.navigation.onNavigate.bind(unbindEvents);
        quosal.customization.forms.configurationChanged.bind(changeConfiguration);
        quosal.customization.forms.configurationUpdated.bind(changeConfiguration);
        quosal.sell.quote.onUpdate.bind(updateEditForm);

        quosal.util.cookie('quotenav', 'home');
        loadEditForm();
    }
    if (window.appInsights){
        var elapsed = new Date().getTime() - start;
        window.appInsights.trackPageView("Load - Quote Setup",
            window.location.href,
            { EmailAddress: userInfo.email, UserRole: userInfo.role },
            null,
            elapsed);
    }
}
