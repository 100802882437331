import { Box, styled } from "@mui/material";

export const ModalBox = styled(Box)(({ theme }) => ({
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 623,
	backgroundColor: theme.palette.background.main,
	borderRadius: 8,
    boxShadow: '0px 4px 8px 0px #0000001A',
	padding: 24
}));
