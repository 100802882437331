/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

export const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
	border: 0,
	backgroundColor: "transparent",
	"&:not(:last-child)": {
		borderBottom: 0
	},
	"&:before": {
		display: "none"
	},
	"& .MuiButtonBase-root-MuiAccordionSummary-root": {
		padding: 0
	}
}));

export const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
		{...props}
	/>
))(({ theme }) => ({
	padding: 0,
	"& .MuiTypography-root": {
		fontSize: "14px",
		color: "#1E1E1E"
	},
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)"
	},
	"& .MuiAccordionSummary-content": {
		margin: 0,
		marginLeft: theme.spacing(1)
	}
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
	padding: "0 16px 16px",
	textAlign: "left"
}));

export const StyledTreeItem = styled(TreeItem)(() => ({
	padding: 0,
	"& .MuiTreeView-root": {
		height: "100%"
	},
	"& .MuiTreeItem-content": {
		width: "auto",
		padding: "5px 0",
		"& .MuiTreeItem-label": {
			fontSize: "14px",
			letterSpacing: "0.16px"
		}
	}
}));
