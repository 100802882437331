import { FormPlaceholder } from "js/jsx/src/classes/forms.jsx"
import { ArrowQuoteImportPage } from "js/jsx/src/classes/arrow/arrowQuoteImport.jsx"

export default function arrowQuoteSearch() {
    quosal.ui.react.render(
        <FormPlaceholder message="Loading Arrow Quote Search..." />
        , document.getElementById('arrowQuoteSearchContainer'));
    var renderArrowQuoteSearchPage = function (renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;

        var idQuoteTabs = quosal.util.queryString('idquotetabs');
        var idQuoteMain = quosal.util.queryString('idquotemain');
        renderMethod(<ArrowQuoteImportPage defaultDestinationTab={idQuoteTabs} idQuoteMain ={idQuoteMain}/>,
            document.getElementById('arrowQuoteSearchContainer'));
    };

    var loadArrowQuoteSearchPage = function () {
        renderArrowQuoteSearchPage(quosal.ui.react.render);

        $('#pageName').val('arrow.arrowQuoteSearch');
    };
    loadArrowQuoteSearchPage();
}