import { Box, styled } from "@mui/material";

export const StyledModalBox = styled(Box)(({ theme }) => ({
	boxSizing: "border-box",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 640,
	backgroundColor: theme.palette.background.default,
	borderRadius: 8,
    boxShadow: '0px 1px 4px 0px #0000001A',
	padding: 16
}));
