const ciscoSettingKeys = {
    EnableCcwCatalog: 'EnableCcwCatalog',
    CiscoAccessTokenExpiryUtcDateTime: 'CiscoAccessTokenExpiryUtcDateTime',
    CiscoRefreshTokenExpiryUtcDateTime: 'CiscoRefreshTokenExpiryUtcDateTime'
};
const utcText = 'UTC';
export class CiscoSetup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enableCcwCatalog: false,
            isLoading: true,
            accessTokenExpirationTime: '',
            refreshTokenExpirationTime: '',
            isAuthorizationValid: false
        };
        this.saveSettingsChanges = this.saveSettingsChanges.bind(this);
        this.toggleCccwCatalogSetting = this.toggleCccwCatalogSetting.bind(this);
        this.initializeSettings = this.initializeSettings.bind(this);
        this.ciscoAuthorize = this.ciscoAuthorize.bind(this);
        this.removeAuthorization = this.removeAuthorization.bind(this);
    }
    componentDidMount()
    {
        this.initializeSettings();
    }
    initializeSettings()
    {
        var globalSettings = [];
        globalSettings.push(ciscoSettingKeys.EnableCcwCatalog);

        var getGlobalSettings = quosal.api.settings.getSettings(globalSettings);
        getGlobalSettings.finished = (msg) => {
            var newState = { isLoading: false };
            msg.settings.map((setting) => {
                if (setting.Key === ciscoSettingKeys.EnableCcwCatalog) {
                    newState.enableCcwCatalog = (setting.Value && setting.Value.toLowerCase() === "true");
                }
            });
            this.setState(newState);
        };
        getGlobalSettings.call();

        var getAccessToken = quosal.api.oauth.getAccessTokenInformation("Cisco");
        getAccessToken.finished = (msg) => {
            var newState = { isLoading: false };
            var currentLocalTime = new Date(Date.now());
            var accessTokenLocalExpDt, refreshTokenLocalExpDt;

            if (msg.accessTokenExpirationTime) {
                accessTokenLocalExpDt = new Date(msg.accessTokenExpirationTime + ' ' + utcText);
                newState.accessTokenExpirationTime = msg.accessTokenExpirationTime;
            }

            if (msg.refreshTokenExpirationTime) {
                refreshTokenLocalExpDt = new Date(msg.refreshTokenExpirationTime + ' ' + utcText);
                newState.refreshTokenExpirationTime = msg.refreshTokenExpirationTime;
            }

            if ((accessTokenLocalExpDt && currentLocalTime < accessTokenLocalExpDt) ||
                (refreshTokenLocalExpDt && currentLocalTime < refreshTokenLocalExpDt)) {
                newState.isAuthorizationValid = true;
            }
            else {
                newState.isAuthorizationValid = false;
            }

            this.setState(newState);
        };
        getAccessToken.call();
    }

    saveSettingsChanges() {
        var settings = [];
        this.setState({ isLoading: true });
        settings.push({
            key: ciscoSettingKeys.EnableCcwCatalog,
            value: this.state.enableCcwCatalog,
            isUserSetting: false
        });
        var saveSettings = quosal.api.settings.saveSettings(settings);

        saveSettings.finished = (msg) => {
            this.setState({ isLoading: false });
            if (msg.settings) {
                return;
            }
        };
        saveSettings.call();
    }

    ciscoAuthorize() {
        var ciscoAuthorization = quosal.api.ciscoIntegration.authorize();
        ciscoAuthorization.call();
        ciscoAuthorization.finished = (msg) => {
            if (msg.redirectUrl) {
                window.location.href = msg.redirectUrl;
            }
        };
    }

    removeAuthorization() {
        var removAuthorizationApi = quosal.api.oauth.expireAccessTokenInformation("Cisco");
        removAuthorizationApi.call();
        removAuthorizationApi.finished = (msg) => {
            this.setState({
                accessTokenExpirationTime: '',
                refreshTokenExpirationTime: '',
                isAuthorizationValid: false
            });    
        };
    }
    toggleCccwCatalogSetting() {
        if (this.state.enableCcwCatalog === true) {
            this.setState(
                { enableCcwCatalog: false }
            );
        }
        else {
            this.setState(
                { enableCcwCatalog: true }
            );
        }
    }
    render() {
        var titleChildren = (
            <div className="toolbar right">
                <Button className="cancel" onClick={this.initializeSettings} style={{ marginRight: "10px" }}> Cancel </Button>
                <Button className="save" onClick={this.saveSettingsChanges}> Save </Button>
            </div>);
        return (<div>
            <Panel title="Cisco Authorization" titleChildren={titleChildren}>
                <PanelContent>
                    {this.state.isAuthorizationValid ?
                        <div>
                            <Button onClick={this.removeAuthorization}>Remove Authorization</Button>
                        </div>:
                        <div>
                            <Button onClick={this.ciscoAuthorize}> Authorize </Button>
                        </div>}
                        <div>
                            <div className="formfield" style={{ marginTop: "10px" }}>
                                Access Token Expiration Time (UTC) : {this.state.accessTokenExpirationTime}
                            </div>
                            <div className="formfield">
                                Refresh Token Expiration Time (UTC) : {this.state.refreshTokenExpirationTime}
                            </div>
                        </div>
                </PanelContent>
            </Panel>

            <Panel title="Enable Cisco APIs">
                {this.state.isLoading ? <Spinner /> :
                    < PanelContent >
                        <div className="formcolumn">
                            <div className="formcheckboxwrapper">
                                <input type="checkbox" name="EnableCCWCatalog" id="EnableCCWCatalog" checked={this.state.enableCcwCatalog} onChange={this.toggleCccwCatalogSetting} />
                                <label htmlFor="EnableCCWCatalog" className="standardformlabel">Enable CCW Catalog</label>
                            </div>
                        </div>
                    </PanelContent>}
            </Panel>
        </div>);
    }
}