import {OpportunitySearchbox} from "js/jsx/src/classes/crm/opportunitySearchbox.jsx";
import {OpportunitySearchTable} from "js/jsx/src/classes/crm/opportunitySearchTable.jsx";

export class SalesForceOpportunitySearch extends React.Component {
    constructor(props) {
        super(props);
        this.headerNames = [
           {name:"", propName:"", width:40, url: true}, 
           {name:"Name", propName: "Name", width: 140}, 
           {name:"Opportunity Owner", propName: "OpportunityOwner", width: 100},
           {name:"Account", propName: "AccountName", width: 100},
           {name:"Account Number", propName: "AccountNumber", width: 100},
           {name:"City", propName: "City", width: 100},
           {name:"Type", propName:"Type", width: 60}, 
           {name:"Stage", propName: "StageName", width:60}, 
           {name:"Amount", propName:"Amount", width:60, formatMoney: true},
        ]
        this.state = {
            criteria: this.getSavedSearchCriteria(), 
            isSearching: false,
        };

        // This binding is necessary to make `this` work in the callback
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.submitClick = this.submitClick.bind(this);
        this.formatReturnedOpportunities = this.formatReturnedOpportunities.bind(this);
        this.saveSearchCriteria = this.saveSearchCriteria.bind(this);
        this.resetSearchCriteria = this.resetSearchCriteria.bind(this);
        this.getSavedSearchCriteria = this.getSavedSearchCriteria.bind(this);
        this.getSearchCriteria = this.getSearchCriteria.bind(this);
    }
    componentDidMount(){
        document.addEventListener("keypress",  this.handleKeyPress)
    }
    componentWillUnmount(){
        document.removeEventListener("keypress",  this.handleKeyPress)
    }
    handleKeyPress(e){
        if(e.keyCode === 13)
        {
           this.submitClick();
        }
    }
    inputChanged(fieldName){
        var value = $("#"+fieldName).val();
        this.state.criteria[fieldName] = value;
        this.forceUpdate();
    }
    submitClick(pageNumberOffset){
        if(quosal.util.isIntegar(pageNumberOffset) && pageNumberOffset == 0){
            this.state.criteria.pageNumber = 0;
        }
        else if(quosal.util.isIntegar(pageNumberOffset))
        {
            this.state.criteria.pageNumber += pageNumberOffset;
        }
        this.saveSearchCriteria();
        var sfGetOpportunites = quosal.api.crm.salesforce.getOpportunities(this.state.criteria)
        var me = this;
        var onError = function(msg) {
            Dialog.open({
                message: msg.error,
                links: [{
                    title: 'OK',
                    callback: Dialog.close
                }]
            });
            me.setState({isSearching: false})
        
        };
        sfGetOpportunites.error = onError;
        sfGetOpportunites.finished = function (msg) {
            var opportunities = this.formatReturnedOpportunities(msg.data.opportunities);
            this.setState({opportunities:opportunities, pager: msg.data.pager, isSearching: false});
        }.bind(this);
        sfGetOpportunites.call();
        this.setState({isSearching: true})
    
    }  
    formatReturnedOpportunities(opportunities){
        var me = this;
        if(opportunities){
            return opportunities.map(function(opportunity){
                var parsedOpp = JSON.parse(opportunity);
                for (var key in me.statuses) {
                    if(me.statuses[key] == parsedOpp.StatusCode){
                        parsedOpp.Status = key;
                        break;
                    }
                }
                return JSON.stringify(parsedOpp)
            })
        }
    }
    saveSearchCriteria(){
        $.cookie('c_sfopportunitysearch_oppname', this.state.criteria.opportunityName, { expires: 14, path: "/" });
        $.cookie('c_sfopportunitysearch_accountname', this.state.criteria.accountname, { expires: 14, path: "/" });
        $.cookie('c_sfopportunitysearch_stagename', this.state.criteria.stagename, { expires: 14, path: "/" });
        $.cookie('c_sfopportunitysearch_opptype', this.state.criteria.opportunityType, { expires: 14, path: "/" });
    }
    resetSearchCriteria(){
        this.resetCookies();
        this.setState({criteria: this.getSavedSearchCriteria(), opportunities: null})
    }
    resetCookies(){
        $.cookie('c_sfopportunitysearch_oppname', "", { expires: 14, path: "/" });
        $.cookie('c_sfopportunitysearch_accountname',"", { expires: 14, path: "/" });
        $.cookie('c_sfopportunitysearch_opptype', "", { expires: 14, path: "/" });
        $.cookie('c_sfopportunitysearch_stagename', "", { expires: 14, path: "/" });
    }
    getSavedSearchCriteria(){
        var cookie = {}
        try{
            cookie.opportunityName = quosal.util.cookie('c_sfopportunitysearch_oppname');
            cookie.accountname = quosal.util.cookie('c_sfopportunitysearch_accountname');
            cookie.stagename = quosal.util.cookie('c_sfopportunitysearch_stagename');
            cookie.opportunityType = quosal.util.cookie('c_sfopportunitysearch_opptype');
            cookie.pageNumber = 0;
            cookie.pageSize = 50;
        }
       catch(e){
        this.resetCookies();
        cookie.opportunityName = "";
        cookie.accountname = "";
        cookie.stagename = "";
        cookie.opportunityType = "";
       }

        return cookie;
    }

    getSearchCriteria(){
        return [
            {fieldName: "opportunityName", displayName:"Opportunity Name", fieldType:"input", value: this.state.criteria["opportunityName"]},
            {fieldName: "accountname", displayName:"Account Name", fieldType:"input", value: this.state.criteria["accountname"]},
            {fieldName: "opportunityType", displayName:"Type", fieldType:"input", value: this.state.criteria["opportunityType"]},
            {fieldName: "stagename", displayName:"Stage Name", fieldType:"input", value: this.state.criteria["stagename"]},
        ]
    }
    render(){
        var spinnerArgs = {
            lines: 12,
            length: 12,
            width: 6,
            radius: 20
        };
            
        return(
            <div id="SfOpportunitySearchId">
                <OpportunitySearchbox searchCriteria={this.getSearchCriteria()} resetClick={this.resetSearchCriteria} submitClick={this.submitClick} inputChanged={this.inputChanged} />
                <OpportunitySearchTable submitClick={this.submitClick} isSearching={this.state.isSearching} opportunities={this.state.opportunities} pager={this.state.pager} headerNames={this.headerNames} />
            </div>
            )
    }
}