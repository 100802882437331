import React, { useState } from "react";

import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
	Autocomplete,
	Box,
	InputAdornment,
	FormHelperText,
	OutlinedInput,
	Stack
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";

import { useAppDataContext } from "../globalStates/appDataProvider";

function CustomFormHelperText({ isValid }) {
	const { focused } = useFormControl();

	return (
		<Box>
			{focused && !isValid && (
				<Stack direction="row" sx={{ alignItems: "center" }}>
					<InfoIcon sx={{ color: "secondary.light" }} size="small" />
					<FormHelperText sx={{ color: "text.secondary" }}>
						Please type at least two characters to start search
					</FormHelperText>
				</Stack>
			)}
		</Box>
	);
}

function ProductSearchInput() {
	const {
		checkedCatalogs,
		isLoadingCatalogs,
		isLoadingProducts,
		handleSearch,
		handleCategoryUnselect
	} = useAppDataContext();
	const theme = useTheme();

	const [valid, setValid] = useState(false);

	const style = {
		fontSize: "14px",
		lineHeight: "18px",
		borderRadius: "6px",
		backgroundColor: theme.palette.mode === "light" && "#F6F6F9",
		"& .MuiOutlinedInput-notchedOutline": {
			border: 0
		},
		"& .MuiOutlinedInput-root .MuiAutocomplete-input": {
			py: "4px"
		}
	};

	const searchHistory = JSON.parse(localStorage.getItem("srchHistory")) || [];

	const onChange = (event_, value) => {
		const isValid = value
			.trim()
			.split(/\s+/)
			.some((word) => word.length >= 2);

		if (isValid) {
			setValid(true);
		} else {
			setValid(false);
		}
	};

	const updateSearchHistory = (searchValue) => {
		const newSearchHistory =
			// eslint-disable-next-line no-nested-ternary
			searchValue === "" || searchHistory.includes(searchValue)
				? searchHistory
				: searchHistory.length === 10
				? [...searchHistory.slice(1), searchValue]
				: [...searchHistory, searchValue];

		localStorage.setItem("srchHistory", JSON.stringify(newSearchHistory));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const searchValue = new FormData(e.currentTarget).get("keywords").trim();

		if (!valid) return;

		const keywords =
			// searchValue.split(/\s+/).length === 1
			// 	? [searchValue] :
			[searchValue, ...searchValue.split(/\s+/)];

		updateSearchHistory(searchValue);
		handleCategoryUnselect();

		handleSearch({
			categoryName: "",
			categoryId: "",
			sourceTypes: checkedCatalogs,
			searchKeys: keywords
		});
	};

	return (
		<Box component="form" onSubmit={handleSubmit} width="100%">
			<Autocomplete
				fullWidth
				freeSolo
				disableClearable
				autoHighlight
				options={searchHistory}
				onInputChange={onChange}
				disabled={isLoadingCatalogs || isLoadingProducts}
				renderInput={(params) => (
					<FormControl ref={params.InputProps.ref} sx={{ width: "100%" }}>
						<OutlinedInput
							placeholder="Product Search"
							name="keywords"
							type="search"
							fullWidth
							sx={style}
							endAdornment={
								<InputAdornment
									component="button"
									data-testid="input-icon-btn"
									type="submit"
									onSubmit={handleSubmit}
									disablePointerEvents={!valid}
									position="end"
									sx={{
										px: 1,
										mr: 0,
										mt: "4px",
										bgcolor: "transparent",
										border: "none",
										minWidth: 30
									}}
								>
									<SearchIcon sx={{ fontSize: 18 }} color="#888888" />
								</InputAdornment>
							}
							inputProps={{
								...params.inputProps,
								maxLength: 100
							}}
						/>
						<CustomFormHelperText isValid={valid} />
					</FormControl>
				)}
			/>
		</Box>
	);
}

export default ProductSearchInput;
