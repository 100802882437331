
class Spinner extends React.Component {
    componentDidMount() {
        $(this.refs.container).append(quosal.ui.spinners.icon(this.props.args));
    }
    render() {
        return (
            <div className="spinner-container icon" ref="container" style={this.props.style} title={this.props.title}></div>
        );
    }
}
global.Spinner = Spinner;

class SpinnerApril2018 extends React.Component {
    render() {
        var spinnerStyle = {};
       
        if (this.props.small){
            spinnerStyle = {
                borderWidth : "1.5px",
                borderStyle : "solid",
                minHeight : 0,
                minWidth : 0,
                padding : "2.5px",
            }
        }
        if(this.props.color){
            spinnerStyle.borderBottomColor = this.props.color;
            spinnerStyle.borderTopColor = this.props.color;
        }
     
        return (
            <div id="april2018spinner_wrap" style={this.props.style}>
                <div className="april2018spinner_loading april2018spinner_outer" style={spinnerStyle}>
                    <div className="april2018spinner_loading april2018spinner_inner" style={spinnerStyle}>
                    </div>
                </div>
            </div>
        );
    }
}
global.SpinnerApril2018 = SpinnerApril2018;