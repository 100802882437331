import * as React from "react";
import BaseContextMenu from "./baseContextMenu.jsx";

function PunchoutSitesContextMenu({ quosal, contentGrid, gridToolbarStyle }) {
	const siteActions = [];

	const initActions = () => {
		if (
			quosal.settings.getValue("enblePunchoutIntegration") &&
			quosal.settings.getValue("punchoutSites")
		) {
			const sites = quosal.settings.getValue("punchoutSites");

			const createPunchoutUrl = function (site) {
				let selectedTab;
				app.currentQuote.Tabs.map((tab) => {
					if (tab.IdQuoteTabs == contentGrid.state.cKEditorSelectedTabId) {
						selectedTab = tab;
					}
				});
				const url = quosal.util.url(
					"createpunchout.quosalweb",
					`requestid=${quosal.util.generateGuid()}`,
					`punchout=${site.IdcxmlPunchoutSites}`,
					`idquotetabs=${selectedTab.IdQuoteTabs}`
				);
				const punchoutWindow = window.open(url, "_blank");
				Dialog.open({
					title: `Accessing ${site.Name} Punchout`,
					closeRequiresButton: true,
					width: "75%",
					message: `You will now be redirected to ${site.Name} punchout in a new browser tab.
                    Navigating away from this page will invalidate the current Punchout session.
                    Pressing Cancel Punchout will cancel your current Punchout session and allow you to edit your quote.`,
					links: [
						{
							title: "Cancel Punchout",
							callback() {
								punchoutWindow.close();
								Dialog.close();
							}
						}
					]
				});
			};

			if (sites.length > 0) {
				for (let i = 0; i < sites.length; i++) {
					siteActions.push({
						id: i,
						name: sites[i].Name,
						callback: createPunchoutUrl.bind(this, sites[i])
					});
				}
			}
		}
	};

	initActions();

	return (
		<>
			{siteActions && siteActions.length ? (
				<span style={gridToolbarStyle}>
					<BaseContextMenu title="Punchout Sites" quosal={quosal} actions={siteActions} />
				</span>
			) : null}
		</>
	);
}

export default PunchoutSitesContextMenu;
