/* eslint-disable no-undef */
import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import RemoveShoppingCartTwoToneIcon from "@mui/icons-material/RemoveShoppingCartTwoTone";

import { useAppDataContext } from "../globalStates/appDataProvider";

import { Item, StyledButton } from "../styledComponents/common";
import PageBreadcrumbs from "../commonComponents/pageBreadcrumbs";
import ProductItem from "./productItem";

function ProductSearchProductCart() {
	const {
		selectedProducts,
		updateProduct,
		handleAddToQuote,
		deleteAllProducts,
		showNotification
	} = useAppDataContext();

	const hasValidationError = () => {
		const validProducts = selectedProducts.map((product) => {
			if (product.quantityError) {
				return false;
			}

			if (!product.quantity) {
				updateProduct({ quantityError: "Incorrect value" }, product.id, product.source);
				return false;
			}

			if (!product.targetTabId) {
				updateProduct({ tabError: "Incorrect value" }, product.id, product.source);
				return false;
			}

			return true;
		});

		const hasError = validProducts.some((valid) => !valid);

		if (hasError) showNotification("Invalid values", "Please correct input values", "error");

		return hasError;
	};

	const addToQuote = () => {
		if (hasValidationError()) return;

		handleAddToQuote(selectedProducts);

		deleteAllProducts();
		showNotification("Successfully added", "The items have been added to the Quote");
	};

	return (
		<Grid container sx={{ p: "0 5px" }}>
			<Grid item xs>
				<Item>
					{/* TOP */}
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						mb={2}
					>
						<Stack direction="row" alignItems="center" spacing={2}>
							<Typography fontSize={20}>Product Cart</Typography>
							<StyledButton
								variant="contained"
								sx={{ padding: "11px" }}
								onClick={addToQuote}
								disabled={!selectedProducts.length}
							>
								Add to Quote
							</StyledButton>
						</Stack>
						<PageBreadcrumbs />
					</Stack>

					{/* PRODUCTS */}
					<Box sx={{ overflowY: "auto" }}>
						{selectedProducts.length ? (
							selectedProducts.map((product) => (
								<ProductItem
									key={product.id + product.price + product.source}
									product={product}
								/>
							))
						) : (
							<Box sx={{ margin: "150px auto 0", textAlign: "center" }}>
								<RemoveShoppingCartTwoToneIcon
									sx={{ fontSize: 190, opacity: 0.2 }}
								/>
								<Typography fontSize={20} sx={{ margin: "10px 0" }}>
									Empty Cart!
								</Typography>
								<Typography fontSize={20} sx={{ color: "#8E8E8E" }}>
									Please add some products to the Cart
								</Typography>
							</Box>
						)}
					</Box>
				</Item>
			</Grid>
		</Grid>
	);
}

export default ProductSearchProductCart;
