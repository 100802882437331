import { createContext, useContext, useEffect, useState } from "react";
import { useOpportunitySyncContext } from "./OpportunitySyncContext";

const QuoteContext = createContext();

const QuoteProvider = ({ quote, children }) => {
	const { callCwOppSync } = useOpportunitySyncContext();
	const [state, setState] = useState(quote);

	useEffect(() => {
		setState(quote);
		callCwOppSync();
	}, [quote]);
	return <QuoteContext.Provider value={state}>{children}</QuoteContext.Provider>;
};

const useQuoteContext = () => {
	const quote = useContext(QuoteContext);
	if (quote === undefined) {
		console.error("useQuoteContext must be used inside QuoteProvider");
	}
	return quote;
};

export { QuoteProvider, useQuoteContext };
