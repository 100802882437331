import { useCallback } from "react";

import { getCurrentItems } from "../PackageHelper";
import { useEditorContext } from "../context/editorContext";
import { getCKEditorTabsIds } from "../helpers/getCKEditorTabsIds";

const useEditorWrapperHelper = () => {	
	const { ckeditor, gridFormats } = useEditorContext();

	const handleChangeEditorAfterAddProduct = useCallback((editor) => {
		const addProductTabId = sessionStorage.getItem("addProductTabId");
		if (addProductTabId !== null) {
			editor.execute("updateProduct", addProductTabId);
			sessionStorage.removeItem("addProductTabId");
		}

		const productSearchTabId = sessionStorage.getItem("productSearchTabId");
		if (productSearchTabId !== null) {
			editor.execute("updateProduct", productSearchTabId);
			sessionStorage.removeItem("productSearchTabId");
		}

		const etilizeSearchTabId = sessionStorage.getItem("etilizeSearchTabId");
		if (etilizeSearchTabId !== null) {
			editor.execute("updateProduct", etilizeSearchTabId);
			sessionStorage.removeItem("etilizeSearchTabId");
		}
	}, []);

	// Update the "Status: Saving..." info.
	const displayStatus = useCallback((editor) => {
		const pendingActions = editor.plugins.get("PendingActions");
		const statusIndicator = document.querySelector("#editor-status");

		pendingActions.on("change:hasAny", (evt, propertyName, newValue) => {
			if (newValue) {
				statusIndicator.classList.add("busy");
			} else {
				statusIndicator.classList.remove("busy");
				let CKEdata = editor.data.get();
				CKEdata = CKEdata.replaceAll("font-weight:bold", "font-weight:inherit");
				app.currentQuote.HTMLContentForAutoSaving = CKEdata;
			}
		});
	}, []);

	const insertItemsByTabs = useCallback( (addableTabs) => {
		if (!ckeditor?.execute) {
			return;
		}
		for (let j = 0; j < addableTabs.length; j++) {
			const currentTab = addableTabs[j];
			if (gridFormats.includes(currentTab.GridFormat) && currentTab.TabName !== "Term Options") {
				insertItemNotesHtml(ckeditor, currentTab);
			} else {
				ckeditor.execute("insertProduct", currentTab.IdQuoteTabs);
			}
			ckeditor.execute("input", { text: " " });
		} 
		ckeditor.execute("updateQuoteSummaryTables");
		return ckeditor.getData();
	}, [ckeditor, gridFormats]);

	const deleteItemsByTabs = useCallback( (tabs) => {
		if (!ckeditor?.execute) {
			return;
		}
		for (let j = 0; j < tabs.length; j++) {
			const currentTab = tabs[j];
			ckeditor.execute("deleteProduct", currentTab.IdQuoteTabs);
		} 
		return ckeditor.getData();
	}, [ckeditor, gridFormats]);

	const insertItemNotesHtml = (ckeditor, tab) => {
		const currentTabItems = getCurrentItems(tab.IdQuoteTabs);
		for (let i = 0; i < currentTabItems.length; i++) {
			if (currentTabItems[i].ItemNotesHtml) {
				let itemNotesHtml = currentTabItems[i].ItemNotesHtml + "<br>";
				const itemNotesView = ckeditor.data.processor.toView(
					itemNotesHtml
				);
				const itemNotesModel = ckeditor.data.toModel(itemNotesView);
				ckeditor.model.insertContent(itemNotesModel, ckeditor.model.document.selection);
			}
		}
	};

	const canDropTable = useCallback(
		(tabId) => {
			const currentTab = app.currentQuote.Tabs.find((tab) => 
				tab.IdQuoteTabs === tabId
			);

			const droppedTabIds = getCKEditorTabsIds(app.currentQuote?.HTMLContentForAutoSaving);
			if (droppedTabIds !== null && droppedTabIds.includes(currentTab?.IdQuoteTabs)) {
				return false;
			}

			return true;
		},
		[app.currentQuote.Tabs, getCKEditorTabsIds, app.currentQuote?.HTMLContentForAutoSaving]
	);

	return {
		handleChangeEditorAfterAddProduct,
		displayStatus,
		insertItemsByTabs,
		deleteItemsByTabs,
		canDropTable,
	};
};

export default useEditorWrapperHelper;
