// FIXME: get rid of local storage when CKEditor autosave method will work correct
// for now in editor.data.get() `Loading...` instead of product table data, check <DataGridBoilerplateEditor />

export const widgetsKeys = [
	"summaryQuote",
	"customer_signature",
	"docu_signature",
	"sales_signature",
	"notRecurringQuote",
	"recurringQuote",
	"optionalQuote",
	"selectedPaymentOptions"
];

export function storeWithExpiry(key, value) {
	const now = new Date().getTime();
	const thirtyDaysExpiration = now + 30 * 24 * 60 * 60 * 1000; // 30 days

	const dataToStore = {
		value,
		expiresAt: thirtyDaysExpiration
	};

	localStorage.setItem(key, JSON.stringify(dataToStore));
}

export function storeCleaner() {
	const now = new Date().getTime();
	const localStorageSize = JSON.stringify(localStorage).length;
	const maxSize = 4 * 1024 * 1024; // 4MB

	// remove expired
	for (let i = 0; i < localStorage.length; i++) {
		const storageKey = localStorage.key(i);
		const storedData = JSON.parse(localStorage.getItem(storageKey));

		if (storedData && storedData.expiresAt && now > storedData.expiresAt) {
			localStorage.removeItem(storageKey);
		}
	}

	// remove 5 last items if local storage size > 80%
	if (localStorageSize >= maxSize) {
		const keysToRemove = [];

		for (let i = 0; i < localStorage.length; i++) {
			const storageKey = localStorage.key(i);
			const storedData = JSON.parse(localStorage.getItem(storageKey));

			if (storedData && storedData.expiresAt) {
				keysToRemove.push({
					key: storageKey,
					expiresAt: storedData.expiresAt
				});
			}
		}

		keysToRemove.sort((a, b) => a.expiresAt - b.expiresAt);

		for (let i = 0; i < 5 && i < keysToRemove.length; i++) {
			localStorage.removeItem(keysToRemove[i].key);
		}
	}

	// remove all widgets data
	widgetsKeys.forEach((key) => {
		localStorage.removeItem(key);
	});
}

// FIXME: get rid of local storage when CKEditor autosave method will work correct
// for now in editor.data.get() `Loading...` instead of product table data, check <DataGridBoilerplateEditor />
