export const getCKEditorTabsIds = (data) => {
	const pattern = /data-id="([^"]*)"/g;
	const matches = [];
	let match;

	while ((match = pattern.exec(data)) !== null) {
		matches.push(match[1]);
	}

	return matches;
};
