const transformData = (data) =>
	data?.map(({ Product, Prices, MasterProductId, EtilizeProductId }) => ({
		id: Product.ManufacturerPartNumber + Product.Source,
		mpn: Product.ManufacturerPartNumber,
		description: Product.LongDescription,
		shortDescription: Product.ShortDescription,
		price: Product.Price,
		cost: Product.Cost,
		loadPriceList: !!MasterProductId,
		source: Prices.length
			? Prices.find((item) => item.SourceName === Product.Source)?.DisplaySourceName ||
			  Prices[0].DisplaySourceName
			: Product.Source,
		sourceId: Prices.length
			? Prices.find((item) => item.SourceName === Product.Source)?.SourceId
			: null,
		manufacturer: Product.SourceManufacturerName,
		stock: Product.InStock,
		onHand: Product.OnHand,
		image: Product.ThumbnailUrl,
		prices: Prices,
		sku: Product.Sku,
		masterProductId: MasterProductId,
		etilizeProductId: EtilizeProductId
	}));

export default transformData;
