export class PackageSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.initialValue,
        };
        this.handleChange = this.handleChange.bind(this);

    }
    componentDidMount() {
        this.setValueConsumer(this.props.initialValue);
    }
    setValueConsumer(value) {
        if (this.props.valueConsumer) {
            this.props.valueConsumer.packageHeaderSelection = value;
        }
    }
    handleChange(e) {
        var value = e.target.value;
        this.setState({value: value});
        this.setValueConsumer(value);
    }
    render() {
        var options = [];

        return (
            <div id="packageSelector" className="formcolumn" style={{marginTop:'10px'}}>
                <div className="formfieldlabel"><label className="formlabel">{this.props.isInvoiceGroupSelector ? 'Select an Invoice Group' : 'Select a Bundle'}</label></div>
                <div className="formselectfieldwrapper"><select ref="select" className="formselectfield" title="Bundles" onChange={this.handleChange}>{this.props.options}</select></div>
            </div>
        );
    }
}

export class NewPackageDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            packagePartNumber: '',
            packageDescription: '',
            packageQuantity: '',
        };
        this.setValueConsumer = this.setValueConsumer.bind(this);
        this.handlePackagePartNumberChange = this.handlePackagePartNumberChange.bind(this);
        this.handlePackageDescriptionChange = this.handlePackageDescriptionChange.bind(this);
        this.handlePackageQuantityChange = this.handlePackageQuantityChange.bind(this);
    }

    componentDidMount() {
        this.setValueConsumer();
    }
    setValueConsumer() {
        if (this.props.valueConsumer) {
            if (!this.props.valueConsumer.newPackageDetails) {
                this.props.valueConsumer.newPackageDetails = {};
            }
            this.props.valueConsumer.newPackageDetails.packagePartNumber = this.state.packagePartNumber;
            this.props.valueConsumer.newPackageDetails.packageDescription = this.state.packageDescription;
            this.props.valueConsumer.newPackageDetails.packageQuantity = this.state.packageQuantity;
        }
    }
    handlePackagePartNumberChange(e) {
        this.setState({packagePartNumber: e.target.value}, this.setValueConsumer);
    }
    handlePackageDescriptionChange(e) {
        this.setState({packageDescription: e.target.value}, this.setValueConsumer);
    }
    handlePackageQuantityChange(e) {
        this.setState({packageQuantity: e.target.value}, this.setValueConsumer);
    }
    render() {
        return (
            <div id="packagedetails" className="formcolumn" style={{marginTop:'10px'}} >
                <div className="formfieldlabel"><label className="formlabel" >Bundle Part Number</label></div>
                <div className="formfield"><input type="text" title="Bundle Part Number" ref="packagePartNumber" id="PackagePartNumber" maxLength="255" value={this.state.packagePartNumber} onChange={this.handlePackagePartNumberChange} /></div>

                <div className="formfieldlabel"><label className="formlabel" >Bundle Description</label></div>
                <div className="formfield"><input type="text" title="Bundle Description" ref="packageDescription" id="PackageDescription" maxLength="255" value={this.state.packageDescription} onChange={this.handlePackageDescriptionChange} /></div>

                <div className="formfieldlabel"><label className="formlabel" >Bundle Quantity</label></div>
                <div className="formfield"><input type="text" title="Bundle Quantity" ref="packageQuantity" id="PackageQuantity" maxLength="8" value={this.state.packageQuantity} onChange={this.handlePackageQuantityChange} /></div>
            </div>
        );
    }
}
