import { CardGridContainerStyled } from "../styledComponents/commonStyled";
import { DataGrid } from '@mui/x-data-grid';

const SVG_NO_FORMS = 'img/svgs/sell/SalesRep_Submitted_Forms_No_Form_To_Display.svg';
const SVG_NO_FORMS_DARK = 'img/svgs/sell/SalesRep_Submitted_Forms_No_Form_To_Display_Dark.svg';
const SVG_NO_RESULTS = 'img/svgs/sell/SalesRep_Submitted_Forms_No_Results.svg';
const SVG_NO_RESULTS_DARK = 'img/svgs/sell/SalesRep_Submitted_Forms_No_Results_Dark.svg';

export class CommonSubmittedFormsDataGrid extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { theme, submittedForms, columns, sortModel, isLoading, customFooter, handleSortModelChange, isSearch } = this.props;

        return <> {!!columns &&
            < CardGridContainerStyled
                item
            container
            xs={12}
            p='1rem'
            mt='1rem'
            justifyContent={submittedForms.length === 0 ? "center" : 'flex-start'}
            alignItems={submittedForms.length === 0 ? "center" : 'flex-start'}
            sx={{
                backgroundColor: theme.palette.background.main,
                minHeight: '550px',
                '.salestrack-no-form-image': {
                    width: '40%',
                },
                '& .submittedSalestrack-preview-image': {
                    width: '2rem',
                    height: '2rem',
                    objectFit: 'contain',
                },
                '& .actions': {
                    color: theme.palette.text.secondary,
                },
                '& .textPrimary': {
                    color: theme.palette.text.main,
                },
                '& .MuiDataGrid-root': {
                    border: 'none',
                    height: '550px',
                    width: '100%',
                    "& .MuiDataGrid-iconButtonContainer": {
                        display: 'none',
                        "& .MuiIconButton-root": {
                            minWidth: '28px',
                            minHeight: '28px',
                        },
                        "& .MuiDataGrid-sortIcon": {
                            color: theme.palette.icon.main,
                        },
                    },
                    "& .MuiDataGrid-customIconButtonContainer": {
                        display: 'flex',
                    },
                    "& .MuiDataGrid-columnHeader--sorted": {
                        "& .MuiDataGrid-iconButtonContainer": {
                            display: 'flex',
                        },
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontSize: '0.8rem',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '18px',
                        color: theme.palette.text.main
                    },
                },
            }}
            >
            {submittedForms.length === 0 && !isLoading ?
                <img
                    src={
                        isSearch
                            ? theme.palette.mode === "dark"
                                ? SVG_NO_RESULTS_DARK
                                : SVG_NO_RESULTS
                            : theme.palette.mode === "dark"
                            ? SVG_NO_FORMS_DARK
                            : SVG_NO_FORMS
                        }
                    alt="Submitted sales tracks forms no form to display image"
                    className="salestrack-no-form-image"
                />
                :
                <DataGrid
                    rows={submittedForms}
                    columns={columns}
                    sortModel={sortModel}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    loading={isLoading}
                    slots={{
                        footer: customFooter,
                    }}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                />
            }
        </CardGridContainerStyled>
    }
        </>
    }
}