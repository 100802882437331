import React from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, IconButton, SvgIcon } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { PasteExcel } from "js/jsx/src/classes/quote/pasteExcel.jsx";
import useDarkMode from "../WidgetToolbar/widgetToolbarHelpers";

function ContentGridActionButtons({
	isEditable,
	contentGrid,
	restrictNewItems,
	applicationMode,
	disabledElementStyle,
	isStandardUserAndProtectedTab,
	disableNewProductCreationStyle,
	isStandardUserAndDisableNewProductCreation,
	isStandardUserAndLockItemEdit,
	disableStandardUserAndLockStyle,
	isHistoricalQuote,
	isArchivedQuote,
	isPendingApprovalQuote,
	handleChangeAfterAction,
	ckeditor,
	selectedTab,
}) {
	const darkMode = useDarkMode();
	const theme = useTheme();
	
	const tabLink = (href) => {
		const url = quosal.util.url(href, `idquotetabs=${selectedTab.IdQuoteTabs}`);
		quosal.navigation.navigate(url);
	};

	const addProductInCKEditor = () => {
		ckeditor?.execute?.("updateProduct", selectedTab.IdQuoteTabs);
	};

	const showPasteQuote = () => {
		Dialog.open({
			height: "82%",
			width: "92%",
			title: "Paste Quote",
			message: React.createElement(PasteExcel, {
				currentTab: selectedTab.IdQuoteTabs,
				currentQuote: app.currentQuote.IdQuoteMain,
				handleChangeAfterAction,
				addProductInCKEditor
			}),
			messageProps: { style: { overflow: "visible", display: "flex" } },
			closeRequiresButton: true
		});
	};

	const etilizeSearchLink = () => {

		app.currentModule.loadSubModule("cloud.etilize", {
			container: "quoteModule",
			query: `idquotetabs=${selectedTab.IdQuoteTabs}`
		});
	};

	

	const productSearchLink = () => {
		const insertBefore = contentGrid.state.insertBefore ? contentGrid.state.insertBefore : "";

		let queryString = `idquotetabs=${selectedTab.IdQuoteTabs}`;
		if (insertBefore) {
			queryString = `${queryString}&insertbefore=${insertBefore}`;
		}
		app.currentModule.loadSubModule("product.search", {
			container: "quoteModule",
			query: queryString
		});
	};
	const salesTracksSearchLink = () => {
		app.currentModule.loadSubModule("product.salestracks", {
			container: "quoteModule",
			query: `idquotetabs=${selectedTab.IdQuoteTabs}`
		});
	};
	const catalogProductConfiguratorLink = function(href) {
		quosal.navigation.productConfigurator(selectedTab.IdQuoteTabs, href);
	};

	return (
		<Grid container justifyContent="center" spacing={1} wrap="nowrap" className="toolbarGrid">
			<Grid item xs="auto">
				<Tooltip title="Create New Product">
					<IconButton
						size="small"
						edge="start"
						className="quoteToolbarButton"
						disabled={
							!isEditable ||
							restrictNewItems ||
							isStandardUserAndProtectedTab ||
							isStandardUserAndDisableNewProductCreation ||
							isStandardUserAndLockItemEdit ||
							isHistoricalQuote ||
							isArchivedQuote ||
							isPendingApprovalQuote
						}
						onClick={contentGrid.createProduct.bind(contentGrid, null, null)}
					>
						<SvgIcon
							className="toolbarIcon"
							style={{
								...disabledElementStyle,
								...disableNewProductCreationStyle,
								...disableStandardUserAndLockStyle
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								focusable="false"
								viewBox="0 0 48 48"
							>
								<g>
									<path
										d="M40 24c0 1.1-.9 2-2 2H26v12c0 1.1-.9 2-2 2s-2-.9-2-2V26H10c-1.1 0-2-.9-2-2s.9-2 2-2h12V10c0-1.1.9-2 2-2s2 .9 2 2v12h12c1.1 0 2 .9 2 2z"
										fill={theme.palette.icon.primary}
									/>
									<path d="M0 0h48v48H0z" fill="none" />
								</g>
							</svg>
						</SvgIcon>
					</IconButton>
				</Tooltip>
			</Grid>
			<Grid item xs="auto">
				<Tooltip title="Create New Comment Line">
					<IconButton
						size="small"
						edge="start"
						className="quoteToolbarButton"
						disabled={
							!isEditable ||
							isStandardUserAndProtectedTab ||
							isHistoricalQuote ||
							isArchivedQuote ||
							isPendingApprovalQuote
						}
						onClick={contentGrid.createComment}
					>
						<SvgIcon className="toolbarIcon" style={disabledElementStyle}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								focusable="false"
								viewBox="0 0 48 48"
							>
								<g>
									<path
										d="M40 8H8c-1.1 0-2 .9-2 2v24c0 1.1.9 2 2 2h6v6c0 .8.5 1.5 1.2 1.8.3.2.5.2.8.2.5 0 1-.2 1.4-.6l7.4-7.4H40c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-2 24H24c-.1 0-.3 0-.4 0-.1 0-.1 0-.2 0s-.1 0-.2.1c-.1 0-.1.1-.2.1s-.1.1-.2.1c-.1.1-.2.2-.3.3L18 37.2V34c0-1.1-.9-2-2-2h-6V12h28v20zM14 18c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2s-.9 2-2 2H16c-1.1 0-2-.9-2-2zm0 8c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2s-.9 2-2 2H16c-1.1 0-2-.9-2-2z"
										fill={theme.palette.icon.primary}
									/>
									<path d="M0 0h48v48H0z" fill="none" />
								</g>
							</svg>
						</SvgIcon>
					</IconButton>
				</Tooltip>
			</Grid>
			<Grid item xs="auto">
				<Tooltip title="Product Search">
					<IconButton
						size="small"
						edge="start"
						className="quoteToolbarButton"
						disabled={
							!isEditable ||
							isStandardUserAndProtectedTab ||
							isHistoricalQuote ||
							isArchivedQuote ||
							isPendingApprovalQuote
						}
						onClick={productSearchLink}
					>
						<SvgIcon className="toolbarIcon" style={disabledElementStyle}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
								<path fill="none" d="M0 0h48v48H0V0zm0 0h48v48H0V0z" />
								<path
									d="M36.7 34.5c.8-1.2 1.3-2.7 1.3-4.3 0-4.3-3.5-7.8-7.8-7.8s-7.8 3.5-7.8 7.8S26 38 30.2 38c1.6 0 3.1-.5 4.3-1.3l4.4 4.4c.3.3.7.4 1.1.4s.8-.1 1.1-.4c.6-.6.6-1.5 0-2.1 0 0-4.4-4.5-4.4-4.5zm-11.2-4.3c0-2.6 2.1-4.8 4.8-4.8s4.8 2.1 4.8 4.8-2.2 4.8-4.9 4.8-4.7-2.2-4.7-4.8zM42 8v10c0 1.1-.9 2-2 2s-2-.9-2-2v-8H27v8h-6v-8H10v26h8c1.1 0 2 .9 2 2s-.9 2-2 2H8c-1.1 0-2-.9-2-2V8c0-1.1.9-2 2-2h32c1.1 0 2 .9 2 2z"
									fill={theme.palette.icon.primary}
								/>
							</svg>
						</SvgIcon>
					</IconButton>
				</Tooltip>
			</Grid>
			{quosal.settings.getValue("showCatalogConfigurator", false) ? (
				<Grid item xs="auto">
					<Tooltip title="Product Configurator">
						<IconButton
							size="small"
							edge="start"
							className="quoteToolbarButton"
							disabled={
								!isEditable ||
								isStandardUserAndProtectedTab ||
								isHistoricalQuote ||
								isArchivedQuote ||
								isPendingApprovalQuote
							}
							onClick={catalogProductConfiguratorLink.bind(this, quosal.settings.getValue('catalogServiceUrl') + '&currency=' + app.currentQuote.BaseCurrency + 
								'&applicationMode=' + applicationMode)}
						>
							<SvgIcon className="toolbarIcon" style={disabledElementStyle}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
									<path fill="none" d="M0 0h48v48H0V0zm0 0h48v48H0V0z" />
									<path
										d="M41.9 30.1L40 27c-.1-.2-.4-.3-.6-.2l-2.3.9c-.5-.4-1-.7-1.6-.9l-.4-2.4c0-.2-.2-.4-.5-.4h-3.7c-.2 0-.4.2-.5.4l-.4 2.4c-.6.2-1.1.5-1.6.9l-2.3-.9c-.2-.1-.5 0-.6.2l-1.9 3.1c-.1.2-.1.4.1.6l2 1.5c0 .3-.1.6-.1.9s0 .6.1.9l-2 1.5c-.2.1-.2.4-.1.6l1.9 3.1c.1.2.4.3.6.2l2.3-.9c.5.4 1 .7 1.6.9l.4 2.4c0 .2.2.4.5.4h3.7c.2 0 .4-.2.5-.4l.4-2.4c.6-.2 1.1-.5 1.6-.9l2.3.9c.2.1.5 0 .6-.2l1.9-3.1c.1-.2.1-.4-.1-.6l-2-1.5c0-.3.1-.6.1-.9s0-.6-.1-.9l2-1.5c.2-.2.3-.4.1-.6zm-8.9 6c-1.8 0-3.2-1.4-3.2-3.1s1.5-3.2 3.2-3.2c1.8 0 3.2 1.4 3.2 3.2 0 1.8-1.4 3.1-3.2 3.1zM42 8v10c0 1.1-.9 2-2 2s-2-.9-2-2v-8H27v8h-6v-8H10v26h8c1.1 0 2 .9 2 2s-.9 2-2 2H8c-1.1 0-2-.9-2-2V8c0-1.1.9-2 2-2h32c1.1 0 2 .9 2 2z"
										fill={theme.palette.icon.primary}
									/>
								</svg>
							</SvgIcon>
						</IconButton>
					</Tooltip>
				</Grid>
			) : null}
			{quosal.settings.getValue("enableGuidedSelling", false) ? (
				<Grid item xs="auto">
					<Tooltip title="Guided Selling">
						<IconButton
							size="small"
							edge="start"
							className="quoteToolbarButton"
							disabled={!isEditable}
							onClick={salesTracksSearchLink}
						>
							<SvgIcon className="toolbarIcon" style={disabledElementStyle}>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g id="Style=Outlined">
										<g id="Vector">
											<path
												d="M4.25 6H6.25V9H16.25V6H18.25V11H20.25V6C20.25 4.9 19.35 4 18.25 4H14.07C13.65 2.84 12.55 2 11.25 2C9.95 2 8.85 2.84 8.43 4H4.25C3.15 4 2.25 4.9 2.25 6V20C2.25 21.1 3.15 22 4.25 22H10.25V20H4.25V6ZM11.25 4C11.8 4 12.25 4.45 12.25 5C12.25 5.55 11.8 6 11.25 6C10.7 6 10.25 5.55 10.25 5C10.25 4.45 10.7 4 11.25 4Z"
												fill={theme.palette.icon.primary}
											/>
											<path
												d="M20.25 12.5L14.76 18L11.75 15L10.25 16.5L14.76 21L21.75 14L20.25 12.5Z"
												fill={theme.palette.icon.primary}
											/>
										</g>
									</g>
								</svg>
							</SvgIcon>
						</IconButton>
					</Tooltip>
				</Grid>
			) : null}
			{!app.currentQuote.IsLocked &&
			!app.currentUser.IsReadOnly &&
			quosal.settings.getValue("usePasteExcel") ? (
				<Grid item xs="auto">
					<Tooltip title="Paste Quote">
						<IconButton
							size="small"
							edge="start"
							className="quoteToolbarButton"
							disabled={
								!isEditable ||
								restrictNewItems ||
								isStandardUserAndProtectedTab ||
								isStandardUserAndDisableNewProductCreation ||
								isHistoricalQuote ||
								isArchivedQuote ||
								isPendingApprovalQuote
							}
							onClick={showPasteQuote}
						>
							<SvgIcon
								className="toolbarIcon"
								style={{
									...disabledElementStyle,
									...disableNewProductCreationStyle
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									focusable="false"
									viewBox="0 0 48 48"
								>
									<g>
										<path
											d="M43.4 21.6l-5-5c-.1-.1-.3-.2-.4-.3V6c0-1.1-.9-2-2-2H10c-1.1 0-2 .9-2 2v32c0 1.1.9 2 2 2h12v2c0 .5.2 1 .6 1.4s.9.6 1.4.6h18c1.1 0 2-.9 2-2V23c0-.5-.2-1-.6-1.4zM12 36V8h4v2c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V8h4v8H24c-1.1 0-2 .9-2 2v18H12zm14 4V20h8v4c0 1.1.9 2 2 2h4v14H26z"
											fill={theme.palette.icon.primary}
										/>
										<path d="M0 0h48v48H0z" fill="none" />
									</g>
								</svg>
							</SvgIcon>
						</IconButton>
					</Tooltip>
				</Grid>
			) : null}
            {quosal.settings.getValue("useEtilize") ? (
                <Grid item xs="auto">
                    <IconButton
                        size="small"
                        edge="start"
                        className="quoteToolbarButton"
                        disabled={
                            !isEditable ||
                            isStandardUserAndProtectedTab ||
                            isHistoricalQuote ||
                            isArchivedQuote
                        }
                        onClick={etilizeSearchLink}
                        sx={{
                            ...disabledElementStyle, color: "rgb(51, 51, 51)",
                            opacity:
                                darkMode && (
                                    !isEditable ||
                                    isStandardUserAndProtectedTab ||
                                    isHistoricalQuote ||
                                    isArchivedQuote
                                ) ? 0.3 : 1,
                            "&.MuiButtonBase-root:hover": {
                                bgcolor: "transparent"
                            }
                        }}
                    >
                        Etilize Search
                    </IconButton>
                </Grid>
            ) : null}
		</Grid>
	);
}

export default ContentGridActionButtons;
