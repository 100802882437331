import React, { createContext, useCallback, useContext, useRef, useState } from "react";
const ALLOWEDCRMS = ['Connectwise'];
const OpportunitySyncContext = createContext();
const useOpportunitySyncContext = () => useContext(OpportunitySyncContext);

const checkIfSyncEnabled = () => {
	const currentCRM = typeof window.quosal?.settings?.getValue === 'function' &&
		quosal.settings.getValue('customerProvider') || '';
    const isAutoSyncAllowed = ALLOWEDCRMS.includes(currentCRM);
    const syncEnabled = window.app?.settings?.global?.EnableAutoSyncOpportunity && window.app?.settings?.global?.ConnectWiseEnableOpportunityAutoSync && isAutoSyncAllowed;
	return syncEnabled;
};

const OpportunitySyncProvider = ({ children }) => {
	const syncEnabled = checkIfSyncEnabled();
	const [lastUpdateTime, setLastUpdateTime] = useState(null);
	const [syncing, setSyncing] = useState(false);
	const [syncFinished, setSyncFinished] = useState(false);
	const [syncError, setSyncError] = useState(null);
	const callSyncRef = useRef(false);

	const callCwOppSync = useCallback(() => {
        if (!syncEnabled || window.location.href.includes('submodules=quote.customer,crm.opportunity')) {
			return;
		}
		if (syncing) {
			callSyncRef.current = true;
			return;
		} 

		setSyncing(true);
		let saveOpportunityAPI = quosal.api.crm.connectwise.autoSaveOpportunity(app.currentQuote.IdQuoteMain);
		saveOpportunityAPI.finished = function (msg) {
			setSyncFinished(true);
			setLastUpdateTime(new Date().toLocaleString());
			if (msg.error) {
				setSyncError(msg.error);
			} else {
				setSyncError(null);
			}
			if (callSyncRef.current) {
				callSyncRef.current = false;
				setTimeout(() => {
					callCwOppSync();
				});
			} else {
				setSyncing(false);
			}
		}
			
		saveOpportunityAPI.call();
	}, [syncing]);

	const value = {
		syncing,
		setSyncing,
		callCwOppSync,
		syncError,
		syncFinished,
		lastUpdateTime,
        syncEnabled    
	}
	
	return <OpportunitySyncContext.Provider value={value}>{children}</OpportunitySyncContext.Provider>;
};

export { OpportunitySyncProvider, useOpportunitySyncContext, checkIfSyncEnabled };
