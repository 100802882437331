import AuditTrail from "../../classes/quote/newUI/auditTrail/AuditTrail";

export default function auditTrail() {
  if (!app.currentQuote) {
    return;
  }

  quosal.ui.react.render(<AuditTrail />, document.getElementById('quoteModule'));
  $('#pageName').val('quote.audit_trail');
};
