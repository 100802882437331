
export class DateTimeInput extends React.Component {
    componentDidMount() {
        if (!this.props.readOnly) {
            var setValue = this.props.setValue;

            if (this.props.inputElementProps.dateFormat){           
                $(this.refs.input).datepicker({onSelect: function (dateText) { setValue(dateText, {alwaysUpdate: true}); },  dateFormat: this.props.inputElementProps.dateFormat });
            }
            else{
                $(this.refs.input).datepicker({onSelect: function (dateText) { setValue(dateText, {alwaysUpdate: true}); }});
            }        
        }
    }
    getValue(e) {
        return e.target.value;
    }
    render() {
        var className = "datepicker";
        var fieldIcon = null;
        let defaultInputElementProps = {};
        if (!this.props.readOnly && !this.props.inputElementProps.onChange) {
            defaultInputElementProps.onChange = (e) => this.props.setValue(e.target.value);
        }
        if (this.props.inputElementProps.value) {
            var onClick = function (e) {
                this.props.setValue('', {alwaysUpdate: true});
            }.bind(this);
            fieldIcon = <img onClick={onClick} className="fieldicon clearDatePicker" title="Clear Date" src="img/svgs/sell/Action_Clear.svg" />
        } else {
            var onClick = function (e) {
                $(this.refs.input).focus();
            }.bind(this);
            fieldIcon = <img onClick={onClick} className="fieldicon" title="Edit Date" src="img/svgs/v1.0/Action_ExpandDown.svg" />;
        }
        return <div data-cy={this.props.id} className="datepickerWrapper">
            <input className={className} id={this.props.id} {...this.props.inputElementProps} {...defaultInputElementProps} ref="input" type="text"/>
            {fieldIcon}
        </div>
    }
}

export class DateTimeRangeInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        // This binding is necessary to make `this` work in the callback
        this.setMinValue = this.setMinValue.bind(this);
        this.setMaxValue = this.setMaxValue.bind(this);
        this.getValue = this.getValue.bind(this);
        this.fillBlankInputWithOtherInputValue = this.fillBlankInputWithOtherInputValue.bind(this);
    }
    getValue(e) {
        return NumericRangeInput.getValue.call(this, e, this.refs.max.refs.input.value, this.refs.min.refs.input.value);
    }
    fillBlankInputWithOtherInputValue(e, callback) {
        return NumericRangeInput.fillBlankInputWithOtherInputValue.call(this, e, this.refs.max.refs.input.value, this.refs.min.refs.input.value, callback);
    }
    setMinValue(value, params) {
        this.props.setValue(value + ';' + this.refs.max.refs.input.value);
    }
    setMaxValue(value, params) {
        this.props.setValue(this.refs.min.refs.input.value + ';' + value);
    }
    render() {
        var propsForMinAndMaxInputs = NumericRangeInput.getPropsForMinAndMaxInputs.call(this, this.props.inputElementProps, 'datepicker');

        return (
            <div id={this.props.id} className="dateRange" style={{display:'inline-block', width:'100%'}}>
                <DateTimeInput inputElementProps={propsForMinAndMaxInputs.minInputElementProps} setValue={this.setMinValue} ref="min" />
                <span>to</span>
                <DateTimeInput inputElementProps={propsForMinAndMaxInputs.maxInputElementProps} setValue={this.setMaxValue} ref="max" />
            </div>
        );
    }
}

export class NumericRangeInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        // This binding is necessary to make `this` work in the callback
        this.fillBlankInputWithOtherInputValue = this.fillBlankInputWithOtherInputValue.bind(this);
        this.getValue = this.getValue.bind(this);
    }

    static getValue(e, existingMaxValue, existingMinValue) {
        var newValue = e.target.value;
        var minValue, maxValue;

        if ($(e.target).data('is-range-min')) {
            minValue = newValue;
            maxValue = existingMaxValue;
        } else if ($(e.target).data('is-range-max')) {
            minValue = existingMinValue;
            maxValue = newValue;
        }
        return minValue + ';' + maxValue;
    }

    static fillBlankInputWithOtherInputValue(e, existingMaxValue, existingMinValue, callback){
        var valueOfClickedInput = e.target.value;
        var minValue, maxValue;

        if (!valueOfClickedInput) {
            var otherValue = null;
            if ($(e.target).data('is-range-min')) {
                otherValue = existingMaxValue;
            } else if ($(e.target).data('is-range-max')) {
                otherValue = existingMinValue;
            }
            if (otherValue) {
                if (typeof callback === 'function') {
                    this.props.setValue(otherValue + ';' + otherValue, {
                        callback: function () {
                            callback(e)
                        }.bind(this, e)
                    });
                } else {
                    this.props.setValue(otherValue + ';' + otherValue);
                }
            }
        }
    }

    static getPropsForMinAndMaxInputs(inputElementProps, className) {
        inputElementProps.className = ((className + ' ') || '') + 'range';
        inputElementProps.placeholder = "Any";
        var onFocus = inputElementProps.onFocus;
        inputElementProps.onFocus = function (e) {
            this.fillBlankInputWithOtherInputValue(e, onFocus);
        }.bind(this);

        var minValue = '', maxValue = '';
        var value = inputElementProps.value;
        if (typeof value === 'string') {
            var valueSplits = value.split(';');
            if (valueSplits.length === 2) {
                minValue = valueSplits[0];
                maxValue = valueSplits[1];
            }
        }

        var leftInputElementProps = inputElementProps;
        var rightInputElementProps = quosal.util.clone(leftInputElementProps);

        leftInputElementProps['data-is-range-min'] = 1;
        leftInputElementProps.value = minValue;

        rightInputElementProps['data-is-range-max'] = 1;
        rightInputElementProps.value = maxValue;

        return {
            minInputElementProps: leftInputElementProps,
            maxInputElementProps: rightInputElementProps
        };
    }
    getValue(e) {
        return NumericRangeInput.getValue.call(this, e, this.refs.max.value, this.refs.min.value);
    }
    fillBlankInputWithOtherInputValue(e, callback) {
        return NumericRangeInput.fillBlankInputWithOtherInputValue.call(this, e, this.refs.max.value, this.refs.min.value, callback);
    }
    render() {
        var propsForMinAndMaxInputs = NumericRangeInput.getPropsForMinAndMaxInputs.call(this, this.props.inputElementProps);

        return (
            <div id={this.props.id} className="numericRange" style={{display:'inline-block', width:'100%'}}>
                <input data-cy="numbericInput1" {...propsForMinAndMaxInputs.minInputElementProps} ref="min" type="text" />
                <span>to</span>
                <input data-cy="numbericInput2" {...propsForMinAndMaxInputs.maxInputElementProps} ref="max" type="text" />
            </div>
        );
    }
}