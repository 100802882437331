import { api } from "../../classes/common/guidedSelling/api/api.js";
import { SalesTrackFormPage } from "../../classes/product/salesTrackFormPage.jsx";
import { getDesignTokens } from "../../classes/common/guidedSelling/theme/adminGuidedSellingTheme.js";
import { createTheme } from "@mui/material";
import {  ThemeProvider } from '@mui/material/styles';
import { DialogManager } from "js/jsx/src/classes/dialog.jsx";
import { LeftHeader, TopHeader, QuosalHeader } from "js/jsx/src/classes/header.jsx";


export default function templatesalestrack(){
    const opportunityInfo = JSON.parse(sessionStorage.getItem('quoteOpportunityInfo'));
    const salesTrackForTemplate = JSON.parse(sessionStorage.getItem('salesTrackForTemplate'));
    quosal.sell.quote.update(app.currentQuote);
    const salesTrackSelected = {
        name: salesTrackForTemplate?.name,
        state:salesTrackForTemplate?.state,
        id: salesTrackForTemplate?.id,
    };
    const salesTrackId = quosal.util.queryString("salestrackid");
    const idquotemain = quosal.util.queryString("idquotemain");
    const mode =  $("#dark-toggle").prop("checked") || app?.currentUser?.HasDarkmodeEnabled;
    const NEW_THEME = createTheme(getDesignTokens(mode ? 'dark' : 'light'));


    const onError = (msg) => {}
    const navigateToDashboard=() => {
        const dashboardModule = quosal.sell.modules.find('quote.dashboard');
        let nextModule = ""
        const customerSelectionMode = quosal.settings.getValue("customerSelectionMode");
        if (customerSelectionMode.toLowerCase() == "opportunity") {
            nextModule = "&nextmodule=quote.attachOpportunity";
        }
        sessionStorage.removeItem('quoteOpportunityInfo');
        sessionStorage.removeItem('salesTrackForTemplate');
        sessionStorage.removeItem('newQuote');
        dashboardModule.load({ query: 'idquotemain=' + idquotemain + nextModule + opportunityInfo + "&submodules=quote.home" });

    }
    const renderPageLayout = () => {
        quosal.ui.hideMask(true);
        const headerTheme = app.settings.user.embeddedTheme;
        if (headerTheme === "ConnectWise Business Suite") {
            quosal.ui.react.render(<LeftHeader />, document.getElementById('leftSidebarContainer'));
            quosal.ui.react.render(<TopHeader />, document.getElementById('topHeader'));
        } else if ($.queryString('skiplayout') != 'yes') {
            quosal.ui.react.render(<QuosalHeader quote={app.currentQuote}
                user={app.currentUser} />, document.getElementById('topHeader'));
        }
        quosal.ui.react.render(<DialogManager />, document.getElementById('dialogManager'));
    }
    const renderSalesTrackForm = (salesTrackForm) => {
        renderPageLayout();
        quosal.ui.react.render(
            <div className="salestracksearch-form-container">
                <ThemeProvider theme={NEW_THEME}>
                    <SalesTrackFormPage
                        isNewQuote = {true}
                        salesTrackForm={salesTrackForm}
                        salesTrackSelected={salesTrackSelected}
                        onBack={navigateToDashboard} onError={onError}
                        navigateToDashboard={navigateToDashboard}
                    />
                </ThemeProvider>
            </div>
            ,
            document.getElementById("salesTrackFormContainer")
        );
    }

    var loadSalesTrackForm = function () {
        api.getSalesTrackForm(salesTrackId, (msg) => {
            renderSalesTrackForm(msg.sections);
        },()=>{
            renderPageLayout();
        });
   
    }
    loadSalesTrackForm();
}

global.templatesalestrack = templatesalestrack;
