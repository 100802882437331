import React, { useId, memo } from "react";
import { Box } from "@mui/material";
import {
	InfoOutlined as InfoOutlinedIcon,
	DrawOutlined as DrawOutlinedIcon,
	SummarizeOutlined as SummarizeOutlinedIcon,
	RepeatOutlined as RepeatOutlinedIcon,
	PaidOutlined as PaidOutlinedIcon,
	HandshakeOutlined as HandshakeOutlinedIcon
} from "@mui/icons-material";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import ToolbarWidget from "./ToolbarWidget.jsx";
import { widgetsKeys } from "../helpers/localStorageManager.js";
import useDarkMode from "./widgetToolbarHelpers";

function WidgetToolbar({ handleInitialState }) {
	const darkMode = useDarkMode();
	const widgets = [
		{
			id: useId(),
			title: "Widgets Toolbar",
			icon: <NotesOutlinedIcon sx={{ cursor: "default" }} />,
			isDividerNext: true
		},
		{
			id: widgetsKeys[0],
			title: "Quote Information",
			icon: <InfoOutlinedIcon />,
			isDividerNext: false
		},
		{
			id: widgetsKeys[1],
			title: "Customer Signature",
			icon: <DrawOutlinedIcon />,
			isDividerNext: false
		},
		{
			id: widgetsKeys[2],
			title: "DocuSign Signature",
			icon: <DrawOutlinedIcon />,
			isDividerNext: false
		},
		{
			id: widgetsKeys[3],
			title: "Sales Signature",
			icon: <DrawOutlinedIcon />,
			isDividerNext: true
		},
		{
			id: widgetsKeys[4],
			title: "Quote Summary/Expenses",
			icon: <SummarizeOutlinedIcon />,
			isDividerNext: false
		},
		{
			id: widgetsKeys[5],
			title: "Recurring Summary",
			icon: <RepeatOutlinedIcon />,
			isDividerNext: false
		},
		{
			id: widgetsKeys[6],
			title: "Optional Summary",
			icon: <PaidOutlinedIcon />,
			isDividerNext: false
		},
		{
			id: widgetsKeys[7],
			title: "Summary of Selected Payment Options",
			icon: <HandshakeOutlinedIcon />,
			isDividerNext: false
		}
	];

	const boxStyle = {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		height: "100%",
		boxShadow: "0 0 10px rgba(128, 128, 128, 0.3)",
		...(darkMode
			? { backgroundColor: "rgba(18, 18, 18, 0.38);" }
			: { backgroundColor: "white" }),
		width: "45px",
		justifyContent: "flex-start"
	};

	const content = widgets.map(({ id, title, icon, isDividerNext }) => (
		<ToolbarWidget
			id={id}
			key={`${id}key`}
			data-testid={id}
			title={title}
			icon={icon}
			isDividerNext={isDividerNext}
			handleInitialState={handleInitialState}
		/>
	));

	return <Box sx={boxStyle}> {content} </Box>;
}
export default memo(WidgetToolbar);
