export class AddOpportunityItems extends React.Component {
    constructor(props) {
        super(props);
        this.headerNamesConnectwise = [
            { name: "Item ID", propName: "Id", width: 10 },
            { name: "Description", propName: "Description", width: 100 },
            { name: "Cost", propName: "Cost", width: 10, formatMoney: true },
            { name: "Price", propName: "Price", width: 10, formatMoney: true },
            { name: "Quantity", propName: "Quantity", width: 10 },
            { name: "Extended Price", propName: "ExtendedPrice", width: 10, formatMoney: true },
        ]
        this.headerNames = [
            { name: "Quantity", propName: "Quantity", width: 10 },
            { name: "Description", propName: "Description", width: 100 },
            { name: "Price", propName: "Price", width: 10, formatMoney: true },
        ]
        this.state = {
        };

        this.getDisplayHeaders = this.getDisplayHeaders.bind(this);
        this.getDisplayItems = this.getDisplayItems.bind(this);
        this.getTabSelector = this.getTabSelector.bind(this);
    }
    static selectedTabChanged(e) {
        AddOpportunityItems.selectedItemsTab = e.target.value;
    }

    static open(opportunityId, opportunityItems) {
        var nextStep = function () {
            Dialog.closeAll();

            app.currentModule.loadSubModule('quote.home', {
                container: 'quoteModule',
                unloadSubModules: true
            });
        };

        var doImport = function () {
            Dialog.open({
                closeRequiresButton: true,
                title: "",
                message: <FormPlaceholder message="Importing..." />
            })

            var selectedItemsTab = AddOpportunityItems.selectedItemsTab || app.currentQuote.Tabs[0].IdQuoteTabs;
            var idQuoteMain = quosal.util.queryString('idquotemain');
            var importItemsApi = quosal.api.crm.addItemsFromOpportunity(idQuoteMain, AddOpportunityItems.opportunityId, { idQuoteTabs: selectedItemsTab });
            var onError = function (msg) {
                Dialog.closeAll({
                    skipAnimation: true,
                    callback: function () {
                        Dialog.open({
                            message: msg.error,
                            links: [{
                                title: 'OK',
                                callback: quosal.navigation.navigate(quosal.util.url('quote.dashboard', 'submodules=quote.home'))

                            }]
                        });
                    }.bind(this)
                });
            };

            importItemsApi.error = onError;
            importItemsApi.finished = function (msg) {
                quosal.sell.quote.update(msg.quote);
                nextStep();
            };
            importItemsApi.call();
        };

        var addOppItem = <AddOpportunityItems opportunityItems={opportunityItems} />
        AddOpportunityItems.opportunityId = opportunityId;
        Dialog.open({
            closeRequiresButton: true,
            title: "",
            height: '80%',
            width: '80%',
            message: addOppItem,
            links: [{
                title: 'Yes, Import',
                callback: doImport
            }, {
                title: 'No',
                callback: nextStep
            }]
        })
    }
    getDisplayHeaders() {
        var headerNames = this.headerNames;
        if (quosal.util.getCrmShortName() == "cw") {
            headerNames = this.headerNamesConnectwise;
        }
        return headerNames.map(function (headerObj, index) {
            return <th key={"header_" + index} width={headerObj.width} className="header">{headerObj.name}</th>
        })
    }
    getDisplayItems() {
        if (this.props.opportunityItems) {
            var me = this;
            return this.props.opportunityItems.map(function (oppItem, idx) {
                var _me = me;
                var headerNames = me.headerNames;
                if (quosal.util.getCrmShortName() == "cw") {
                    headerNames = me.headerNamesConnectwise;
                }
                var columns = headerNames.map(function (header, index) {
                    if (header.formatMoney) {
                        if (typeof app != "undefined" && typeof app.currentQuote != "undefined" && typeof app.currentQuote.formatCurrency != "undefined") {
                            oppItem[header.propName] = app.currentQuote.formatCurrency(oppItem[header.propName]);
                        } else {
                            oppItem[header.propName] = accounting.formatMoney(oppItem[header.propName]);
                        }
                    }
                    return <td key={header.name + index} width={header.width} className="center content"><center>{oppItem[header.propName]}</center></td>
                })
                return (
                    <tr key={"opportunitykey_" + idx}>
                        {columns}
                    </tr>
                )
            })
        }
    }
    getTabSelector() {
        var crmProvider = quosal.settings.getValue('customerProvider');

        if (!'Connectwise'.ciEquals(crmProvider) && app.currentQuote.Tabs.length > 0) {

            var tabOptions = [];
            for (var i = 0; i < app.currentQuote.Tabs.length; i++) {
                tabOptions.push(<option key={app.currentQuote.Tabs[i].IdQuoteTabs}
                    value={app.currentQuote.Tabs[i].IdQuoteTabs}>{app.currentQuote.Tabs[i].TabName}</option>)
            }
            return (
                <div className="formfieldwrapper">
                    <label htmlFor="tabSelection" className="formlabel">Tab Routing Options</label>
                    <div className="formselectfieldwrapper">
                        <select id="tabSelection" key="tabSelection" onChange={AddOpportunityItems.selectedTabChanged} className="formselectfield">{tabOptions}</select>
                    </div>
                </div>
            )
        }
    }
    render() {
        return (
            <Panel title="Would you like to import products from this opportunity?">
                <PanelContent>
                    <div>
                        {this.getTabSelector()}
                        <div className="content">
                            <center>
                                <table cellPadding="0" cellSpacing="0" className="datagrid nosort">
                                    <thead>
                                        <tr>
                                            {this.getDisplayHeaders()}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.getDisplayItems()}
                                    </tbody>
                                </table>
                            </center>
                        </div>
                    </div>
                </PanelContent>
            </Panel>
        )
    }
}

AddOpportunityItems.selectedItemsTab = null;