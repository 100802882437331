import { Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IngramQuoteImportSetupPrompt } from "js/jsx/src/classes/ingram/ingramQuoteImport.jsx";
import { NewPackageDetails, PackageSelector } from "js/jsx/src/classes/product/packages.jsx";
import { CloudSourceButton } from "js/jsx/src/classes/quote/cloudSourcing.jsx";
import { MassUpdateFieldSelector } from "js/jsx/src/classes/quote/massUpdateFieldSelector.jsx";
import { ModificationTag } from "js/jsx/src/classes/quote/modificationTag.jsx";
import { PasteExcel } from "js/jsx/src/classes/quote/pasteExcel.jsx";
import { PickTabTemplate } from "js/jsx/src/classes/quote/pickTabTemplate.jsx";
import { RtfEditor, RtfLoader } from "js/jsx/src/classes/rtfEditor.jsx";
import { TermOptionsTab } from "js/jsx/src/classes/tab/termOptionsTab.jsx";
import { VisualTab } from "js/jsx/src/classes/tab/visualTab.jsx";
import { DataGrid, DataGridCustomizer } from "js/jsx/src/classes/tables.jsx";
import { TabControl } from "js/jsx/src/classes/tabs.jsx";
import { ParserNav } from "js/jsx/src/controllers/cloud/parseEquote.jsx";
import { useAppQuoteContext } from "../../../../context/AppQuoteContext.jsx";
import CPQEditorComponent from "./newUI/CPQEditorMain/";
import { destructHTMLstring } from "./newUI/Helpers.jsx";
import { QuoteTotalWarning } from "./newUI/quoteHeader/common/components/QuoteTotalWarning.jsx";
import ArrowContextMenu from "./newUI/toolbar/arrowContextMenu.jsx";
import ContentGridActionButtons from "./newUI/toolbar/ContentGridActionButtons.jsx";
import GreatAmericaContextMenu from "./newUI/toolbar/greatAmericaContextMenu.jsx";
import ImportContextMenu from "./newUI/toolbar/importContextMenu.jsx";
import MoreContextMenu from "./newUI/toolbar/moreContextMenu.jsx";
import PunchoutSitesContextMenu from "./newUI/toolbar/punchoutSitesContextMenu.jsx";
import ScriptContextMenu from "./newUI/toolbar/scriptContextMenu.jsx";
import { MassTabDelete } from "./quoteMassTabDelete.jsx";
import { RestoreDeletedTabs } from "./quoteRestoreDeletedTabs.jsx";

export const QuoteContentGrid = (props) => {
    const {manualSyncEnabled, partialItemUpdate, setNeedsManualSync} = useAppQuoteContext();
    let openTab = sessionStorage?.getItem("cpq_open_tab");
    if(app.currentQuote.Tabs.find((tab) => tab.IdQuoteTabs === openTab) === undefined) {
        openTab = app.currentQuote.Tabs[0].IdQuoteTabs;
    }

    return <QuoteContentGridInner {...props} manualSyncEnabled={manualSyncEnabled} partialItemUpdate={partialItemUpdate} 
        setNeedsManualSync={setNeedsManualSync} openTab={openTab} />;
}

class QuoteContentGridInner extends React.Component {
	constructor(props) {
		super(props);
        this.fieldChangeTimeoutId = null;
        this.fieldChangeUpdates = [];
        this.itemsToQuickAdd = [];
        this.updateApiRequests = [];

        var userCanModifyProtectedPrice = quosal.util.userCanModifyProtectedPrice();
        var userCanModifyProtectedItem = quosal.util.userCanModifyProtectedItem();
        var userCanModifyProtectedTab = quosal.util.userCanModifyProtectedTab();
        var userUseModificationTagToUpdate = quosal.util.userUseModificationTagToUpdate();

        this.state = {
            userCanModifyProtectedPrice: userCanModifyProtectedPrice,
            userCanModifyProtectedItem: userCanModifyProtectedItem,
            userCanModifyProtectedTab: userCanModifyProtectedTab,
            userUseModificationTagToUpdate: userUseModificationTagToUpdate,
            tabIsDeleting: false,
            priceSourcesLoaded: quosal.sell.product.sourceGroups != null ? true : false,
            cKEditorSelectedTabId: this.props.openTab || app.currentQuote.Tabs[0].IdQuoteTabs,
            CKEditorRowsToDelete: false,
            changeAfterAction: false,
            selectedRows: [],
            insertBefore: null,
            isDarkMode: $('#dark-toggle[type=checkbox]').prop('checked'),
            rightContentExpanded: false,
            ckeditor: null,
            ckEditorRenderKey: 1
        };

        this.handleCKEditorSelectedTabId = this.handleCKEditorSelectedTabId.bind(this);
        this.handleChangeAfterAction = this.handleChangeAfterAction.bind(this);
        this.handleSetCKEditor = this.handleSetCKEditor.bind(this);
        this.fieldChanged = this.fieldChanged.bind(this);
        this.makeFieldChangeApiCall = this.makeFieldChangeApiCall.bind(this);
        this.rowSorted = this.rowSorted.bind(this);
        this.insertSectionGrouping = this.insertSectionGrouping.bind(this);
        this.calculateMargins = this.calculateMargins.bind(this);
        this.getFixRowOrderUpdates = this.getFixRowOrderUpdates.bind(this);
        this.fixRowOrder = this.fixRowOrder.bind(this);
        this.toggleTabOption = this.toggleTabOption.bind(this);
        this.duplicateTab = this.duplicateTab.bind(this);
        this.tabSorted = this.tabSorted.bind(this);
        this.fixTabOrder = this.fixTabOrder.bind(this);
        this.tabCreated = this.tabCreated.bind(this);
        this.renameTab = this.renameTab.bind(this);
        this.toggleLineItemQuickFilter = this.toggleLineItemQuickFilter.bind(this);
        this.deleteTab = this.deleteTab.bind(this);
        this.removeZeroQuantityItemsCWMenu = this.removeZeroQuantityItemsCWMenu.bind(this);
        this.removeZeroQuantityItems = this.removeZeroQuantityItems.bind(this);
        this.createProduct = this.createProduct.bind(this);
        this.createComment = this.createComment.bind(this);
        this.rowCreated = this.rowCreated.bind(this);
        this.preserveUnsavedRows = this.preserveUnsavedRows.bind(this);
        this.massUpdateField = this.massUpdateField.bind(this);
        this.createPackage = this.createPackage.bind(this);
        this.getPackageSelector = this.getPackageSelector.bind(this);
        this.savePackage = this.savePackage.bind(this);
        this.overridePackage = this.overridePackage.bind(this);
        this.addToPackage = this.addToPackage.bind(this);
        this.unpackage = this.unpackage.bind(this);
        this.removeFromPackage = this.removeFromPackage.bind(this);
        this.pasteRows = this.pasteRows.bind(this);
        this.deleteRows = this.deleteRows.bind(this);
        this.getCustomAggregate = this.getCustomAggregate.bind(this);
        this.customizeCell = this.customizeCell.bind(this);
        this.gridConfigurationChanged = this.gridConfigurationChanged.bind(this);
        this.onFullscreenToggle = this.onFullscreenToggle.bind(this);
        this.isContentGridToolbarMenuEditable = this.isContentGridToolbarMenuEditable.bind(this);
        this.isUACAvailable = this.isUACAvailable.bind(this);
        this.updateQuoteWithItemFromDB = this.updateQuoteWithItemFromDB.bind(this);
        this.selectTabByIndex = this.selectTabByIndex.bind(this);
        this.createInvoiceGroup = this.createInvoiceGroup.bind(this);
        this.invoiceGroupChanged = this.invoiceGroupChanged.bind(this);
        this.addProductsToNewInvoiceGroup = this.addProductsToNewInvoiceGroup.bind(this);
        this.addToInvoiceGroup = this.addToInvoiceGroup.bind(this);
        this.getInvoiceGroupSelector = this.getInvoiceGroupSelector.bind(this);
        this.getUsedInvoiceGroups = this.getUsedInvoiceGroups.bind(this);
        this.ungroupInvoiceGroup = this.ungroupInvoiceGroup.bind(this);
        this.removeFromInvoiceGroup = this.removeFromInvoiceGroup.bind(this);
        this.getInsertPositionId = this.getInsertPositionId.bind(this);
        this.getCurrentDataGrid = this.getCurrentDataGrid.bind(this);
        this.updateGridWithQuickAdd = this.updateGridWithQuickAdd.bind(this);
        this.launchSpreadsheet = this.launchSpreadsheet.bind(this);
        this.forceUpdate = this.forceUpdate.bind(this);
        this.calculateTaxAsio = this.calculateTaxAsio.bind(this);
        this.buildNotesDialog = this.buildNotesDialog.bind(this);
        this.darkModeChanged = this.darkModeChanged.bind(this);
        this.restoreDeletedTab = this.restoreDeletedTab.bind(this);
        this.rerenderContentGrid = this.rerenderContentGrid.bind(this);
        this.updatePreviewVisible = this.updatePreviewVisible.bind(this);
        this.updateProductPreview = this.updateProductPreview.bind(this);

        if(!quosal.sell.product.sourceGroups){
            quosal.sell.product.loadPriceSourcing(false,  () => {
                this.setState({
                    priceSourcesLoaded: true
                });
            });
        }
    }

    handleCKEditorSelectedTabId(tabId) {
        sessionStorage.setItem('cpq_open_tab', tabId);
        this.setState({ cKEditorSelectedTabId: tabId });
    }

    handleChangeAfterAction(change) {
        this.setState({ changeAfterAction: !change });
    }

    handleSetCKEditor(editor) {
        this.setState({ ckeditor: editor });
    }

    handleSelectedRows(rows) {
        this.setState({ selectedRows: rows });
    }

    handleInsertBefore(value) {
        this.setState({ insertBefore: value });
    }

    handleRightContentExpanded(value) {
        this.setState({ rightContentExpanded: value });
    }

    handleLeftContentExpanded(value) {
        this.setState({ leftContentExpanded: value, ckEditor: {} });
    }

    updateProductPreview(tabId) {
        this.state.ckeditor?.execute && this.state.ckeditor.execute("updateProduct", tabId || this.state.cKEditorSelectedTabId);
    }

    isUACAvailable(tabId) {
        var items = [];
        var tabs = [];
        var itemsByTabId = quosal.util.getItemsByTabId(app.currentQuote);
        if (tabId) {
            var tab = quosal.util.getTabByTabId(app.currentQuote, tabId);
            items = itemsByTabId[tabId] || [];
            tabs = [tab];
        } else {
            items = app.currentQuote.Items || [];
            tabs = app.currentQuote.Tabs || [];
        }

        if (items.length > 0) {
            var isAllItemsHidden = items.every(function (item) {
                return item.IsHiddenItem;
            });
            var isAllTabsHidden = tabs.every(function (tab) {
                var tabItems = itemsByTabId[tab.IdQuoteTabs];
                if (tabItems && tabItems.length > 0) {
                    return tab.IsHidden
                } else {
                    return true;
                }
            });
            if (app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer) {
                return true;
            }
            else if (app.currentUser.IsStandardPlus && !isAllItemsHidden && !isAllTabsHidden) {
                return true;
            }
            else if (!tabs.every(function (tab) {
                if (tab.IsHidden || tab.IsProtectedTab) {
                    return true;
                }
                var tabItems = itemsByTabId[tab.IdQuoteTabs];
                if (!tabItems || tabItems.length == 0) {
                    return true;
                }
                return tabItems.every(function (item) {
                    return item.IsProtectedItem || item.IsHiddenItem
                })
            })) {
                return true;
            }
            else {
                return false;
            }
        } else {
            return false;
        }
    }

    updateGridWithQuickAdd() {
        this.itemsToQuickAdd && this.itemsToQuickAdd.forEach(item => {
            this.getDataGridByTabId(item.newRow.IdQuoteTabs).addRows([item.newRow], item.newRow.SortOrder);
        });
    }

    forceUpdateQuote(quoteId) {
        var forceUpdateApi = quosal.api.quote.forceUpdate(quoteId);
        forceUpdateApi.finished = function (msg) {
            quosal.sell.quote.updateFromApiResponse(msg);
        }.bind(this);
        forceUpdateApi.call();
    }

    fieldChanged(grid, row, field, input, callback) {
        var maxAllowedValue = null;

        if (field.props.field.FieldName == 'QuoteItemPrice' ||
            field.props.field.FieldName == 'SuggestedPrice' ||
            field.props.field.FieldName == 'RecurringPrice' ||
            field.props.field.FieldName == 'RecurringSuggestedPrice') {
            var newValue = app.currentQuote.parseCurrency(field.state.newValue);
            if (newValue > quosal.data.MAX_INTEGER) {
                maxAllowedValue = quosal.data.MAX_INTEGER;
            }
        } else if (field.props.field.DataType == 'Double') {
            var newValue = app.currentQuote.parseCurrency(field.state.newValue);
            if (newValue > quosal.data.MAX_DECIMAL) {
                maxAllowedValue = quosal.data.MAX_DECIMAL;
            }
        } else if (field.props.field.DataType == 'Int32') {
            var newValue = app.currentQuote.parseCurrency(field.state.newValue);
            if (newValue > quosal.data.MAX_INTEGER) {
                maxAllowedValue = quosal.data.MAX_INTEGER;
            }
        }

        if (maxAllowedValue !== null) {
            var fieldName = quosal.customization.fields.getFieldDisplayName('BusinessObject', 'QuoteItems', field.props.field.FieldName);

            Dialog.open({
                title: 'Value Too Large',
                message: 'The value ' + field.state.newValue + ' exceeds the maximum allowed value of ' + maxAllowedValue + ' for field: ' + fieldName,
                links: [Dialog.links.ok]
            });
            field.reset(function (input) {
                input.blur();
            }.bind(this, input));

            return false;
        }

        var fields = {};
        fields[field.props.field.FieldName] = field.state.newValue;

        this.fieldChangeUpdates.push({
            fields: fields,
            queries: [{
                table: 'QuoteItems',
                where: [{
                    field: 'IdQuoteItems',
                    operator: 'Equals',
                    value: row.props.row.IdQuoteItems
                }]
            }]
        });
        this.makeFieldChangeApiCall(callback, row.props.row);

        return true;
    }

    updateQuoteWithItemFromDB(itemId) {
        var searchApi = quosal.api.data.query({
            table: "QuoteItems",
            where: [
                {
                    field: "IdQuoteItems",
                    operator: 'Equals',
                    value: itemId
                },
                {
                    field: "IdQuoteMain",
                    operator: 'Equals',
                    value: app.currentQuote.IdQuoteMain
                }
            ],
            media: true
        });
        searchApi.finished = function (msg) {
            if (msg && msg.resultSets && msg.resultSets[0] && msg.resultSets[0][0]) {
                var itemFromDB = msg.resultSets[0][0];

                var foundItem = app.currentQuote.Items.find(function (item) {
                    return item.IdQuoteItems == itemFromDB.IdQuoteItems
                });
                quosal.util.mergeObject(foundItem, itemFromDB);
                this.shouldComponentUpdateCheck = true;
                quosal.sell.quote.update(app.currentQuote);
                this.shouldComponentUpdateCheck = false;
            }
        }
        searchApi.call();
    }

    makeFieldChangeApiCall(afterUpdateCallback, row) {
        if (this.fieldChangeUpdates && this.fieldChangeUpdates.length > 0) {
            var selectedTab = this.refs.tabControl.getSelectedTab();

            if(this.props.manualSyncEnabled) {
                this.props.partialItemUpdate(row, this.fieldChangeUpdates, this.updateProductPreview);
                afterUpdateCallback && afterUpdateCallback();
                this.fieldChangeUpdates = [];
                return;
            }
            var afterUpdateCallback = afterUpdateCallback;
            var updateApi = quosal.api.data.update(this.fieldChangeUpdates, this.props.quote.IdQuoteMain);
            this.updateApiRequests.push(updateApi.opId);


            updateApi.setFlag('fieldChanged');
            
            var dataGrid = this.refs['dataGrid_' + selectedTab.tabId]
            updateApi.finished = function (msg) {
                var callback = afterUpdateCallback;
                if (this.fieldChangeUpdates.length == 0 && msg.partialResponse) {
                    if (msg.partialResponse.items) {
                        dataGrid.selectedRowIndexs = Object.keys(msg.partialResponse.items);
                    }

                    this.shouldComponentUpdateCheck = true;
                    quosal.sell.quote.updatePartial(msg.partialResponse);

                    // If updating items on multiple tabs, need to render them for all these tabs, not just the current one. Example: Factor QTY Multiplier(Item), Grouping Code(Tab)
                    if (msg.partialResponse.items && msg.partialResponse.tabs && Object.keys(msg.partialResponse.tabs).length > 1) {
                        var ref = this.refs;
                        var tabKeys = Object.keys(msg.partialResponse.tabs);
                        tabKeys.forEach(function (key) {
                            var dataGridRef = ref['dataGrid_' + key];
                            if (dataGridRef && ref) {
                                dataGridRef.forceUpdate();
                            }
                        });
                    };

                    this.shouldComponentUpdateCheck = false;

                    if (msg.partialResponse.partialQuote && msg.partialResponse.partialQuote.ShouldGeneratePdfAfterSave) {
                        quosal.sell.product.generatePdf(app.currentQuote.IdQuoteMain, msg.opId);
                    }
                }
                if (this.fieldChangeUpdates.length == 0 && msg.quote) {
                    this.preserveUnsavedRows(msg.quote);
                    quosal.sell.quote.update(msg.quote);
                }

                this.updateApiRequests.splice(this.updateApiRequests.indexOf(msg.opId), 1);
                var updateQuote = this.updateApiRequests.length == 0;
                if (updateQuote) {
                    this.forceUpdateQuote(this.props.quote.IdQuoteMain);
                }

                if (callback) {
                    callback();
                }

            }.bind(this);

            updateApi.call();
        }

        this.fieldChangeUpdates = [];
        this.fieldChangeTimeoutId = null;
        if (dataGrid) {
            dataGrid.selectedRowIndexs = null;
        }
    }

    rowSorted(grid, row, startIndex, endIndex, orderedRows) {
        this.fixRowOrder(orderedRows);
    }
    insertSectionGrouping(sectionType, grid, menuItem) {
        var tab = this.refs.tabControl.getSelectedTab().item;
        var selectedRows = grid.selectedShowingRows();

        if (selectedRows.length == 0) {
            if (sectionType == 'SubHeader')
                selectedRows = [0];
            else if (sectionType == 'SubFooter')
                selectedRows = [this.props.quote.Items.where(s => s.IdQuoteTabs == tab.IdQuoteTabs).length - 1];
            else if (sectionType == 'Comment')
                selectedRows = [this.props.quote.Items.where(s => s.IdQuoteTabs == tab.IdQuoteTabs).length];
        }

        var tabItems = this.props.quote.Items.where(s => s.IdQuoteTabs == tab.IdQuoteTabs);
        var newItems = [];
        var newItemIds = [];
        var insertPositions = [];

        for (var i = 0; i < selectedRows.length; i++) {
            var rowNumber = selectedRows[i];

            var newItem = {
                table: 'QuoteItems',
                isUnsaved: true,
                IdQuoteMain: this.props.quote.IdQuoteMain,
                IdQuoteTabs: tab.IdQuoteTabs,
                id: quosal.util.generateGuid(),
                LineType: sectionType,
                IsTotalsIncluded: false
            };
            newItemIds.push(newItem.id);

            if (sectionType == 'SubHeader') {
                newItem.SortOrder = rowNumber;
                newItem.LongDescription = 'New Section';
            } else if (sectionType == 'SubFooter') {
                newItem.SortOrder = rowNumber + 1;
                newItem.LongDescription = 'Section Subtotal';
            } else if (sectionType == 'Comment') {
                newItem.SortOrder = rowNumber;
            }
            insertPositions.push(newItem.SortOrder);

            var quoteInsertIndex = this.props.quote.Items.findIndex(s => s.IdQuoteTabs == tab.IdQuoteTabs && s.SortOrder == rowNumber);

            newItems.push(newItem);
            tabItems.splice(newItem.SortOrder, 0, newItem);

            this.props.quote.Items.splice(quoteInsertIndex, 0, newItem);
        }

        grid.selectRows([]);
        this.forceUpdate(this.updateGridWithQuickAdd);

        var insertSectionGroupingApi = quosal.api.product.insertSectionGrouping(this.props.quote.IdQuoteMain, tab.IdQuoteTabs, insertPositions, newItemIds, sectionType);
        insertSectionGroupingApi.finished = function (msg) {
            this.setState({ isInsertSectionGrouping: false });
            quosal.sell.quote.updateFromApiResponse(msg);
        }.bind(this);
        this.setState({ isInsertSectionGrouping: true });
        insertSectionGroupingApi.call();
    }

    calculateMargins(grid) {
        var selectedRows = grid.selectedShowingRows();
        var idQuoteMain = this.props.quote.IdQuoteMain,
            productIds = [];
        for (var i = 0; i < selectedRows.length; i++) {
            productIds.push(grid.props.rows[selectedRows[i]].IdQuoteItems);
        }

        var calculateMarginsApi = quosal.api.product.calculateMargins(idQuoteMain, productIds);
        calculateMarginsApi.finished = function (msg) {
            var message = (
                <table className="datagrid">
                    <thead>
                    <tr>
                        <th className="title nosort">Total Price</th>
                        <th className="title nosort">Total Cost</th>
                        <th className="title nosort">Gross Profit</th>
                        <th className="title nosort">Gross Margin %</th>
                        <th className="title nosort">Markup %</th>
                        <th className="title nosort">Total Suggested</th>
                        <th className="title nosort">Discount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="content right">{msg.price}</td>
                        <td className="content right">{msg.cost}</td>
                        <td className="content right">{msg.profitAmount}</td>
                        <td className="content right">{msg.marginPercent}</td>
                        <td className="content right">{msg.markupPercent}</td>
                        <td className="content right">{msg.suggestedPrice}</td>
                        <td className="content right">{msg.discountPercent}</td>
                    </tr>
                    </tbody>
                </table>
            );

            Dialog.open({
                title: 'Product Margins',
                message: message,
                links: [Dialog.links.ok],
                draggable: true
            });
        }.bind(this);
        calculateMarginsApi.call();
    }

    getFixRowOrderUpdates(orderedRows, updates) {
        if (!updates)
            updates = [];

        for (var i = 0; i < orderedRows.length; i++) {
            if (orderedRows[i].SortOrder != i) {
                orderedRows[i].SortOrder = i;

                updates.push({
                    fields: {
                        SortOrder: i
                    },
                    queries: [{
                        table: 'QuoteItems',
                        where: [{
                            field: 'IdQuoteItems',
                            operator: 'Equals',
                            value: orderedRows[i].IdQuoteItems
                        }]
                    }]
                })
            }
        }
        return updates;
    }

    fixRowOrder(orderedRows, updates, params) {
        var userInfo = quosal.util.getUserEmailAndRole();
        var start = new Date().getTime();
        if (!params) {
            params = {};
        }
        updates = this.getFixRowOrderUpdates(orderedRows, updates);

        if (updates.length > 0) {
            var updateApi = quosal.api.data.update(updates, this.props.quote.IdQuoteMain);
            //TODO: on error move rows back?
            updateApi.setFlag('fixRowOrder');
            updateApi.finished = function (msg) {
                this.setState({ isInsertSectionGrouping: false });
                quosal.sell.quote.updateFromApiResponse(msg);
                if (window.appInsights) {
                    var elapsed = new Date().getTime() - start;
                    window.appInsights.trackPageView("Prepare Content - Update Row Order", window.location.href, {
                        EmailAddress: userInfo.email,
                        UserRole: userInfo.role
                    }, null, elapsed);
                }
            }.bind(this);

            updateApi.call();

            return true;
        } else if (params.alwaysUpdate) {
            this.setState({ isInsertSectionGrouping: false });
            this.forceUpdate();
        } else {
            this.setState({ isInsertSectionGrouping: false });
        }

        return false;
    }

    toggleTabOption(optionName, menu, menuItem) {
        var currentValue = menuItem.tab[optionName] || false;
        var newValue = !currentValue;

        menuItem.tab[optionName] = newValue;

        if (newValue) {
            menuItem.icon = 'img/icons/checked.png';
        } else {
            menuItem.icon = 'img/icons/unchecked.png';
        }

        menuItem.isSaving = true;

        menu.forceUpdate();

        var updateFields = {};
        updateFields[optionName] = newValue;

        var updateApi = quosal.api.data.update({
            fields: updateFields,
            queries: [{
                table: 'QuoteTabs',
                where: [{
                    field: 'IdQuoteTabs',
                    operator: 'Equals',
                    value: menuItem.tab.IdQuoteTabs
                }]
            }]
        }, menuItem.tab.IdQuoteMain);
        updateApi.finished = function (msg) {
            menuItem.isSaving = false;
            quosal.sell.quote.updateFromApiResponse(msg);
        }.bind(this);
        updateApi.call();
    }

    duplicateTab(asRadioOption, menu, menuItem) {
        var otherTabs = this.props.quote.Tabs.where(s => s.TabNumber > menuItem.tab.TabNumber);
        for (var i = 0; i < otherTabs.length; i++) {
            otherTabs[i].TabNumber++;
        }
        var selectedTabIndex = this.refs.tabControl.getSelectedTab().uiIndex;
        this.refs.tabControl.refs["button" + selectedTabIndex].state.isLoading = true;
        this.refs.tabControl.refs["button" + selectedTabIndex].forceUpdate();
        // var tabClone = quosal.util.clone(menuItem.tab, 1);
        // tabClone.isUnsaved = true;
        // tabClone.TabName = asRadioOption ? menuItem.tab.TabName + " Option" : 'Copy of ' + menuItem.tab.TabName;
        // tabClone.IdQuoteTabs = quosal.util.generateGuid();
        // tabClone.TabNumber = menuItem.tab.TabNumber + 1;
        // this.props.quote.Tabs.push(tabClone);
        //
        // this.props.quote.Tabs.sort(function (a, b) {
        //     return a.TabNumber - b.TabNumber;
        // });

        var duplicateApi = quosal.api.quote.duplicateTab(menuItem.tab.IdQuoteMain, menuItem.tab.IdQuoteTabs, asRadioOption);
        duplicateApi.finished = function (msg) {
            quosal.sell.quote.updateFromApiResponse(msg);

            this.refs.tabControl.refs["button" + selectedTabIndex].state.isLoading = false;
            this.refs.tabControl.refs["button" + selectedTabIndex].forceUpdate();
            var copiedTab = this.refs.tabControl.refs["button" + (selectedTabIndex + 1)];

            this.selectTabByIndex(selectedTabIndex + 1)
            copiedTab.props.item.editMode = true
            copiedTab.forceUpdate();

        }.bind(this);
        duplicateApi.call();
    }

    tabSorted(tabControl, tab, startIndex, endIndex) {
        var currentIndex = this.props.quote.Tabs.findIndex((s) => s.IdQuoteTabs == tab.item.IdQuoteTabs);
        if (currentIndex != endIndex) {
            this.props.quote.Tabs.splice(endIndex, 0, this.props.quote.Tabs.splice(currentIndex, 1)[0]);
        }

        this.fixTabOrder();
    }

    rerenderContentGrid() {
        var quoteContentModule = quosal.sell.modules.find('quote.content')
        quosal.ui.react.render(
            <QuoteContentGrid quote={app.currentQuote} module={quoteContentModule} />
            , document.getElementById('contentPanelContainer'));
    }

    fixTabOrder() {
        var userInfo = quosal.util.getUserEmailAndRole();
        var start = new Date().getTime();
        var updates = [];

        for (var i = 0; i < app.currentQuote.Tabs.length; i++) {
            var tabNumber = i + 1;
            if (app.currentQuote.Tabs[i].TabNumber != tabNumber) {
                app.currentQuote.Tabs[i].TabNumber = tabNumber;

                updates.push({
                    fields: {
                        TabNumber: tabNumber
                    },
                    queries: [{
                        table: 'QuoteTabs',
                        where: [{
                            field: 'IdQuoteTabs',
                            operator: 'Equals',
                            value: this.props.quote.Tabs[i].IdQuoteTabs
                        }]
                    }]
                });
            }
        }

        if (updates.length > 0) {
            var updateApi = quosal.api.data.update(updates, this.props.quote.IdQuoteMain);
            //TODO: on error move tabs back?
            updateApi.setFlag('fixTabOrder');
            updateApi.finished = function (msg) {
                quosal.sell.quote.updateFromApiResponse(msg);

                // render quote content grid to solve sequence number issue (AVA-13884 Sequence Numbers Update on Incorrect Tab)
                this.rerenderContentGrid();

                if (window.appInsights) {
                    var elapsed = new Date().getTime() - start;
                    window.appInsights.trackPageView("Prepare Content - Change Tab Order", window.location.href, {
                        EmailAddress: userInfo.email,
                        UserRole: userInfo.role
                    }, null, elapsed);
                }
            }.bind(this);
            updateApi.call();
            this.forceUpdate(this.updateGridWithQuickAdd);
            return true;
        }
        return false;
    }

    tabCreated(tabControl) {

        var newTab = {
            table: 'QuoteTabs',
            IdQuoteTabs: quosal.util.generateGuid(),
            isUnsaved: true,
            IdQuoteMain: this.props.quote.IdQuoteMain,
            TabName: 'New Tab',
            TabNumber: tabControl.props.tabs.length
        };

        this.props.quote.Tabs.push(newTab);
        this.forceUpdate(this.updateGridWithQuickAdd);

        var createApi = quosal.api.data.create(newTab, this.props.quote.IdQuoteMain);
        createApi.finished = function (msg) {
            quosal.sell.quote.update(msg.quote);
            //var newTab = msg.results[0];
            //this.props.quote.Tabs.push(newTab);
            //this.forceUpdate();
        }.bind(this);
        createApi.call();
    }

    renameTab(menu, menuItem) {
        menuItem.tab.editMode = true;
        var buttonIndex = this.refs.tabControl.getSelectedTab().uiIndex;
        this.refs.tabControl.refs["button" + buttonIndex].forceUpdate();
    }

    editTab(menu, menuItem) {
        app.currentModule.loadSubModule('tab.edit', {
            container: 'quoteModule',
            query: 'idquotetabs=' + menuItem.tab.IdQuoteTabs
        });
    }

    toggleLineItemQuickFilter(menu, menuItem) {
        var updates = [];
        var filterIsTurningOn = !menuItem.tab.ShowLineItemQuickFilter;

        this.refs['dataGrid_' + menuItem.tab.IdQuoteTabs].initializeQuickFilter(filterIsTurningOn);
        updates.push({
            fields: {
                ShowLineItemQuickFilter: filterIsTurningOn
            },
            queries: [{
                table: 'QuoteTabs',
                where: [{
                    field: 'IdQuoteTabs',
                    operator: 'Equals',
                    value: menuItem.tab.IdQuoteTabs
                }]
            }]
        });

        var updateApi = quosal.api.data.update(updates, this.props.quote.IdQuoteMain);
        updateApi.finished = function (msg) {
            quosal.sell.quote.updateFromApiResponse(msg);
        }.bind(this);

        updateApi.call();
    }

    deleteTab(menu, menuItem) {
        if (this.props.quote.Tabs.length == 1) {
            Dialog.open({
                title: 'Unable to Delete Tab',
                message: 'You can not delete the last tab on this quote.',
                links: [Dialog.links.ok]
            });
            return;
        }
        var haveProtectedItems = false;
        if (!this.state.userCanModifyProtectedItem) {
            for (var i = 0; i < this.props.quote.Items.length; i++) {
                if (this.props.quote.Items[i].IdQuoteTabs == menuItem.tab.IdQuoteTabs && this.props.quote.Items[i].IsProtectedItem)
                    haveProtectedItems = true;
            }
        }
        if (haveProtectedItems) {
            Dialog.open({
                title: 'Unable to Delete Tab',
                message: 'You can not delete a tab with protected items.',
                links: [Dialog.links.ok]
            });
            return;
        }

        var doDelete = function () {
            var userInfo = quosal.util.getUserEmailAndRole();
            var start = new Date().getTime();

            Dialog.close();
            this.setState({ tabIsDeleting: true })
            this.props.quote.Tabs.removeAll((t) => t.IdQuoteTabs == menuItem.tab.IdQuoteTabs);


            var selectedTab = this.refs.tabControl.getSelectedTab();
            var selectedTabId = this.refs.tabControl.getSelectedTab().tabId;
            var startIndex = selectedTab.uiIndex;

            //When deleting a tab, remove it from the list of selected tabs
            delete this.refs.tabControl.state.tabsSelected[selectedTabId];
            //When deleting a tab, select the next tab to the left
            var index = startIndex - 1;
            //If the left most tab was already selected, index stays 0
            index = index > 0 ? index : 0;
            this.refs.tabControl.setState({ selectedTabIndex: index });

            //If the left most tab is deleted, select the tab to the right
            app.currentModule.subModules[0].setState({ selectedTab: this.refs.tabControl.props.tabs[startIndex == 0 ? 1 : index].tabId });
            this.selectTabByIndex(startIndex == 0 ? 1 : index);
            this.forceUpdate(this.updateGridWithQuickAdd);

            var itemIds = [];
            for (var i = 0; i < this.props.quote.Items.length; i++) {
                if (this.props.quote.Items[i].IdQuoteTabs == menuItem.tab.IdQuoteTabs)
                    itemIds.push(this.props.quote.Items[i].IdQuoteItems);
            }

            //TODO: Clean up once tab restore is released
            let deleteApi = quosal.api.data.delete([
                    {
                        table: 'QuoteItems',
                        where: [{
                            field: 'IdQuoteItems',
                            operator: 'In',
                            value: itemIds
                        }]
                    },
                    {
                        table: 'QuoteTabs',
                        where: [{
                            field: 'IdQuoteTabs',
                            operator: 'Equals',
                            value: menuItem.tab.IdQuoteTabs
                        }]
                    }
                ], this.props.quote.IdQuoteMain);

            
            deleteApi.finished = function (msg) {
                this.setState({ tabIsDeleting: false });
                Dialog.setIsWorking(false);
                quosal.sell.quote.updateFromApiResponse(msg);
                if (window.appInsights) {
                    var elapsed = new Date().getTime() - start;
                    window.appInsights.trackPageView("Prepare Content - Delete Tab", window.location.href, {
                        EmailAddress: userInfo.email,
                        UserRole: userInfo.role
                    }, null, elapsed);
                }
            }.bind(this);

            deleteApi.call();
            Dialog.setIsWorking(true);
        }.bind(this);

        Dialog.confirmDelete({
            title: 'Confirm Delete Tab',
            message: 'Are you sure you want to delete this tab?',
            callback: doDelete
        });
    }

    removeZeroQuantityItemsCWMenu(menuItem, tab) {
        var idQuoteTabs = null;
        if (tab) {
            idQuoteTabs = tab.IdQuoteTabs;
            tab.isUnsaved = true;
        } else {
            for (var i = 0; i < this.props.quote.Tabs.length; i++) {
                this.props.quote.Tabs[i].isUnsaved = true;
            }
        }
        this.forceUpdate(this.updateGridWithQuickAdd);
        var removeItemsApi = quosal.api.quote.removeZeroQuantityItems(this.props.quote.IdQuoteMain, idQuoteTabs);
        removeItemsApi.finished = function (msg) {
            if (msg.quote)
                quosal.sell.quote.update(msg.quote);
        }.bind(this);
        removeItemsApi.call();
    }

    removeZeroQuantityItems(menu, menuItem) {
        var idQuoteTabs = null;

        if (menuItem.tab) {
            idQuoteTabs = menuItem.tab.IdQuoteTabs;
            menuItem.tab.isUnsaved = true;
        } else {
            for (var i = 0; i < this.props.quote.Tabs.length; i++) {
                this.props.quote.Tabs[i].isUnsaved = true;
            }
        }

        this.forceUpdate(this.updateGridWithQuickAdd);

        var removeItemsApi = quosal.api.quote.removeZeroQuantityItems(this.props.quote.IdQuoteMain, idQuoteTabs);
        removeItemsApi.finished = function (msg) {
            if (msg.quote)
                quosal.sell.quote.update(msg.quote);
        }.bind(this);
        removeItemsApi.call();
    }

    createProduct(e, lineType) {
        var selectedTab = app.currentQuote.Tabs[0];

        if (quosal.util.isNewEditorEnabled()) {
            const currentTabId = sessionStorage.getItem('cpq_open_tab');
            
            app.currentQuote.Tabs.map(a => {
                if (a.IdQuoteTabs == currentTabId) {
                    selectedTab = a;
                }
            });
        }
        else {
            selectedTab = this.refs.tabControl.getSelectedTab().item;
        }
        var quantity = lineType == "Comment" ? 0 : 1;
        var createApi = quosal.api.data.create({
            table: 'QuoteItems',
            IdQuoteMain: this.props.quote.IdQuoteMain,
            IdQuoteTabs: selectedTab.IdQuoteTabs,
            ManufacturerPartNumber: (lineType == null ? 'NEW' : ''),
            LineType: lineType,
            Quantity: quantity,
            SortOrder: this.props.quote.Items.where(s => s.IdQuoteTabs == selectedTab.IdQuoteTabs).length,
            IsTaxable: selectedTab.IsTaxable
        }, app.currentQuote.IdQuoteMain);
        createApi.finished = function (msg) {
            if (msg.quote)
                quosal.sell.quote.update(msg.quote);

            if (msg.results && msg.results.length > 0) {
                var newProduct = msg.results[0];

                app.currentModule.loadSubModule(productDetailsModule.Url, {
                    container: 'quoteModule',
                    query: 'itemid=' + newProduct.IdQuoteItems
                });
            }
        }.bind(this);
        createApi.call();

        var productDetailsModule = quosal.sell.modules.find('product.edit');

        this.setState({ loadingMessage: 'Creating New ' + (lineType == 'Comment' ? 'Comment' : 'Item') + '...' });
    }

    createComment(e) {
        this.createProduct(e, 'Comment');
    }

    selectTabByIndex(index) {
        if (index == 0) {
            $("#contentGridTabs").tabs("option", "active", 1);
        }
        index = index > 0 ? index : 0;
        $("#contentGridTabs").tabs("option", "active", index);
    }

    rowCreated(grid, callback) {
        var newRow = {
            table: 'QuoteItems',
            id: quosal.util.generateGuid(),
            isUnsaved: true,
            IdQuoteMain: this.props.quote.IdQuoteMain,
            IdQuoteTabs: grid.props.quoteTab.IdQuoteTabs,
            ManufacturerPartNumber: 'NEW',
            Quantity: 1,
            SortOrder: grid.props.rows.length,
            IsTaxable: grid.props.quoteTab.IsTaxable
        };

        this.props.quote.Items.push(newRow);
        this.forceUpdate();

        var createApi = quosal.api.data.create(newRow, this.props.quote.IdQuoteMain);
        createApi.finished = function (msg) {
            this.preserveUnsavedRows(msg.quote, newRow);
            quosal.sell.quote.update(msg.quote);
        }.bind(this);

        createApi.call();
    }

    preserveUnsavedRows(newQuote, newRow) {
        var unsaved = this.props.quote.Items.where(s => s.isUnsaved);
        for (var i = 0; i < unsaved.length; i++) {
            if (!newRow || unsaved[i].id != newRow.id) {
                newQuote.Items.push(unsaved[i]);
            }
        }
    }

    selectAllRows(grid, action) {
        //TODO: check current selection for 'none' or 'all' selected and don't allow 'Select All' if everything is selected, and visa-versa.
        if (action.checked) {
            grid.selectRows([]);
            action.checked = false;
            action.icon = 'img/icons/unchecked.png';
            action.title = 'Select All';
        } else {
            grid.selectRows();
            action.checked = true;
            action.icon = 'img/icons/checked.png';
            action.title = 'Deselect All';
        }
    }

    massUpdateField(grid, callback) {
        var selectedRows = quosal.util.isNewEditorEnabled() ? grid.selectedShowingRows : grid.selectedShowingRows();

        var haveProtected = false;
        if (!this.state.userCanModifyProtectedItem) {
            for (var i = selectedRows.length - 1; i >= 0; i--) {
                if (grid.props.rows[selectedRows[i]].IsProtectedItem) {
                    haveProtected = true;
                    selectedRows.splice(i, 1);
                }
            }
        }

        var numberOfSelectedShowingRows = selectedRows.length;
        if (numberOfSelectedShowingRows < 1) {
            Dialog.open({
                title: 'No items selected',
                message: (haveProtected ? 'You must select one or more unprotected items for this operation.' : 'You must select one or more items for this operation.'),
                links: [Dialog.links.ok]
            });
            return;
        }

        Dialog.open({
            title: 'Update Field',
            message: <MassUpdateFieldSelector grid={grid} quote={this.props.quote}
                idQuoteMain={this.props.quote.IdQuoteMain}
                count={numberOfSelectedShowingRows}
                handleChangeAfterAction={this.handleChangeAfterAction.bind(this)}
                updateProductInCKEditor={callback}
            />,

            messageProps: { style: { overflow: 'visible', display: 'flex' } },
            height: '441px',
            width: '546px',
            closeRequiresButton: false,
        });
    }

    createPackage(grid, action) {
        var selectedRows = grid.selectedShowingRows();
        if (selectedRows.length < 1) {
            Dialog.open({
                title: 'No items selected',
                message: 'You must select one or more items for this operation.',
                links: [Dialog.links.ok]
            });
            return;
        }
        for (var i = 0; i < selectedRows.length; i++) {
            if (grid.props.rows[selectedRows[i]].typeName != null) {
                Dialog.open({
                    title: 'Invalid Package Item',
                    message: 'Packages may only include standard line items, and may not contain other package items.',
                    links: [Dialog.links.ok]
                });
                return;
            }
        }

        var createPackage = function () {
            var error = false;

            var packagePartNumber = this.newPackageDetails.packagePartNumber
            var packageDescription = this.newPackageDetails.packageDescription;
            var packageQuantity = this.newPackageDetails.packageQuantity;

            $.quosal.validation.clearField($('#PackagePartNumber'));
            $.quosal.validation.clearField($('#PackageDescription'));
            $.quosal.validation.clearField($('#PackageQuantity'));

            if (packagePartNumber == '') {
                error = true;
                $.quosal.validation.validateField($('#PackagePartNumber'), 'error', 'Part Number is required');
            }
            if (packageDescription == '') {
                error = true;
                $.quosal.validation.validateField($('#PackageDescription'), 'error', 'Description is required');
            }
            if (isNaN(parseFloat(packageQuantity))) {
                error = true;
                $.quosal.validation.validateField($('#PackageQuantity'), 'error', 'Quantity must be a valid number');
            }

            if (!error) {
                var detailIds = [];

                //determine where header should be inserted (smallest index)
                var insert = null;
                for (var i = 0; i < selectedRows.length; i++) {
                    if (insert === null || selectedRows[i] < insert)
                        insert = selectedRows[i];
                }

                for (var i = 0; i < selectedRows.length; i++) {
                    var row = grid.props.rows[selectedRows[i]];
                    row.isUnsaved = true;
                    detailIds.push(row.IdQuoteItems);
                }

                var createPackageApi = quosal.api.product.createPackage(
                    packagePartNumber,
                    packageDescription,
                    packageQuantity,
                    this.props.quote.IdQuoteMain,
                    grid.props.quoteTab.IdQuoteTabs,
                    insert,
                    detailIds
                );
                createPackageApi.finished = function (msg) {
                    if (msg.quote) {
                        quosal.sell.quote.update(msg.quote);
                    }
                }.bind(this);
                Dialog.setIsWorking(true);
                createPackageApi.call();

                this.newPackageDetails = null;
                grid.setState({ isRowSelected: [] });

                Dialog.close();
            }

            return error;
        }.bind(this);

        this.newPackageDetails = null;

        var packageSelector = <NewPackageDetails valueConsumer={this} />;
        Dialog.open({
            title: 'Bundle Header Details', message: packageSelector,
            links: [{ title: 'OK', callback: createPackage },
            Dialog.links.cancel
            ]
        });
    }

    getPackageSelector() {
        var options = [];
        var initialValue = null;
        for (var i = 0; i < this.props.quote.Items.length; i++) {
            if (this.props.quote.Items[i].IsPackageHeader && !this.props.quote.Items[i].InvoiceGroupingRecid) {
                if (initialValue === null) {
                    initialValue = this.props.quote.Items[i].IdQuoteItems;
                }
                options.push(
                    <option key={i}
                        value={this.props.quote.Items[i].IdQuoteItems}>{this.props.quote.Items[i].ManufacturerPartNumber}</option>
                );
            }
        }
        return <PackageSelector options={options} initialValue={initialValue} valueConsumer={this} />;
    }

    getInvoiceGroupSelector() {
        var initialValue = null;
        var options = [];
        for (var i = 0; i < this.props.quote.Items.length; i++) {
            if (this.props.quote.Items[i].IsPackageHeader && this.props.quote.Items[i].InvoiceGroupingRecid) {
                if (initialValue === null) {
                    initialValue = this.props.quote.Items[i].IdQuoteItems;
                }
                options.push(
                    <option key={i}
                        value={this.props.quote.Items[i].IdQuoteItems}>{this.props.quote.Items[i].ManufacturerPartNumber}</option>
                );
            }
        }
        return <PackageSelector options={options} initialValue={initialValue} valueConsumer={this} isInvoiceGroupSelector = "true"/>;
    }

    getUsedInvoiceGroups() {
        var options = [];
        for (var i = 0; i < this.props.quote.Items.length; i++) {
            if (this.props.quote.Items[i].IsPackageHeader && this.props.quote.Items[i].InvoiceGroupingRecid) {
                options.push(this.props.quote.Items[i].ManufacturerPartNumber);
            }
        }
        return options;
    }

    savePackage(grid, action) {
        var packageSelector = this.getPackageSelector();
        var confirmPackage = function () {
            Dialog.setIsWorking();
            var headerId = this.packageHeaderSelection;
            var savePackageApi = quosal.api.product.savePackage(this.props.quote.IdQuoteMain, headerId);
            savePackageApi.finished = function (msg) {
                if (!String.isNullOrEmpty(msg.duplicateMpfPackageError)) {
                    Dialog.setIsWorking(false);

                    var overridePackageId = JSON.parse(msg.duplicateMpfPackageError).duplicateMpfItemConfigurationId;
                    this.overridePackage(headerId, overridePackageId);
                }
                else {
                    Dialog.close();
                }

            }.bind(this);

            savePackageApi.call();

            this.packageHeaderSelection = null;
            grid.setState({ isRowSelected: [] });
        }.bind(this);

        this.packageHeaderSelection = null;
        Dialog.open({
            title: 'Select a Bundle to Save', message: packageSelector, links: [
                { title: 'OK', callback: confirmPackage }, Dialog.links.cancel
            ]
        });
    }

    overridePackage(headerId, overridePackageId) {
        var confirmOverridePackage = function () {
            var overridePackageApi = quosal.api.product.overridePackage(this.props.quote.IdQuoteMain, headerId, overridePackageId);
            overridePackageApi.finished = function (msg) {
                Dialog.closeAll();
            }.bind(this);

            overridePackageApi.call();

        }.bind(this);

        Dialog.open({
            title: 'Override Bundle',
            message: 'You are about to overwrite a previously saved Bundle. You can prevent this by changing the Manufacturer Part Number of the Bundle header. Do you want to continue?',
            links: [
                { title: 'Yes, Update', callback: confirmOverridePackage },
                { title: 'Cancel', callback: Dialog.closeAll }
            ]
        });
    }

    addToPackage(grid, action, isInvoiceGroup) {
        isInvoiceGroup = (isInvoiceGroup === true);
        var selectedRows = grid.selectedShowingRows();
        if (selectedRows.length < 1) {
            Dialog.open({
                title: 'No items selected',
                message: 'You must select one or more items for this operation.',
                links: [Dialog.links.ok]
            });
            return;
        }

        var detailIds = [];
        // Collect detail ids. Give a warning and return if any of them are package headers.
        for (var i = 0; i < selectedRows.length; i++) {
            if (grid.props.rows[selectedRows[i]].IsPackageHeader) {
                Dialog.open({
                    title: isInvoiceGroup ? 'Invalid Invoice Group Item' : 'Invalid Bundle Item',
                    message: isInvoiceGroup ? 'You can not add a bundle or Invoice Group header to another Invoice Group. You must first unselect any bundles and Invoice Groups before proceeding.':
                        'You can not add a bundle header to another bundle. You must first unselect any bundles before proceeding.',
                    links: [Dialog.links.ok]
                });
                return;
            } else {
                detailIds.push(grid.props.rows[selectedRows[i]].IdQuoteItems);
            }
        }

        var packageSelector = isInvoiceGroup ? this.getInvoiceGroupSelector(): this.getPackageSelector();

        var confirmPackage = function () {
            var headerId = this.packageHeaderSelection;
            var headerItem = quosal.util.getItemById(app.currentQuote, headerId);
            if (headerItem.IsProtectedItem == true) {
                Dialog.open({
                    title: 'Cannot Add Item',
                    message: quosal.util.getProtectedBundleMessage(headerItem.ManufacturerPartNumber, isInvoiceGroup),
                    links: [{
                        title: "Ok",
                        callback: function () { Dialog.closeAll() }
                    }]
                });
                return;
            }

            var addToPackageApi = (isInvoiceGroup
                ? quosal.api.product.addToInvoiceGroup
                : quosal.api.product.addToPackage
            )(this.props.quote.IdQuoteMain, headerId, detailIds);

            addToPackageApi.finished = function (msg) {
                this.setState({ quote: quosal.sell.quote.update(msg.quote) });
                quosal.sell.quote.update(msg.quote);
            }.bind(this);
            Dialog.setIsWorking(true);
            addToPackageApi.call();

            this.packageHeaderSelection = null;
            grid.setState({ isRowSelected: [] });
            Dialog.close();
        }.bind(this);

        this.packageHeaderSelection = null;
        Dialog.open({
            title: isInvoiceGroup ? 'Select an Invoice Group' :'Select a Bundle', message: packageSelector, links: [
                { title: 'OK', callback: confirmPackage }, Dialog.links.cancel
            ]
        });
    }

    unpackage(grid, action, isInvoiceGroup) {
        isInvoiceGroup = (isInvoiceGroup === true);
        var selectedRows = grid.selectedShowingRows();
        if (selectedRows.length < 1) {
            Dialog.open({
                title: 'No items selected',
                message: 'You must select one or more items for this operation.',
                links: [Dialog.links.ok]
            });
            return;
        }

        let unbundleVerbUppercase = (isInvoiceGroup ? 'Ungroup' : 'Unbundle');
        let bundleNounLowercase = (isInvoiceGroup ? 'invoice group' : 'bundle');

        var headerIds = [];

        for (var i = 0; i < selectedRows.length; i++) {
            let loopIterationRow = grid.props.rows[selectedRows[i]];
            if (loopIterationRow.IdItemConfigurations == null
                || loopIterationRow.IdItemConfigurations == '') {
                Dialog.open({
                    title: (isInvoiceGroup ? 'Invalid Invoice Group Header' : 'Invalid Bundle Header'),
                    message: unbundleVerbUppercase + ' operation may only target one or more ' + bundleNounLowercase + ' headers.',
                    links: [Dialog.links.ok]
                });
                return;
            } else if (loopIterationRow.IsProtectedItem == true) {
                Dialog.open({
                    title: 'Cannot ' + unbundleVerbUppercase,
                    message: quosal.util.getProtectedBundleMessage(headerItem.ManufacturerPartNumber, isInvoiceGroup),
                    links: [Dialog.links.ok]
                });
                return;
            } else {
                headerIds.push(loopIterationRow.IdQuoteItems);
            }
        }

        for (var i = 0; i < selectedRows.length; i++) {
            grid.props.rows[selectedRows[i]].isUnsaved = true;
        }

        var unpackageApi = (isInvoiceGroup
            ? quosal.api.product.ungroupInvoiceGroup
            : quosal.api.product.unpackage
        )(this.props.quote.IdQuoteMain, headerIds);

        unpackageApi.finished = function (msg) {
            if (msg.quote) {
                quosal.sell.quote.update(msg.quote)
            }
        }.bind(this);

        unpackageApi.call();

        grid.setState({ isRowSelected: [] });
    }

    ungroupInvoiceGroup(grid, action) {
        this.unpackage(grid, action, true);
    }

    removeFromPackage(grid, action, isInvoiceGroup) {
        isInvoiceGroup = (isInvoiceGroup === true);
        var selectedRows = grid.selectedShowingRows();
        if (selectedRows.length < 1) {
            Dialog.open({
                title: 'No items selected',
                message: 'You must select one or more items for this operation.',
                links: [Dialog.links.ok]
            });
            return;
        }

        var detailIds = [];

        for (var i = 0; i < selectedRows.length; i++) {
            var headerId = grid.props.rows[selectedRows[i]].ParentQuoteItem;
            var headerItem = quosal.util.getItemById(app.currentQuote, headerId);
            if (headerId == null
                || headerId == '') {
                Dialog.open({
                    title: isInvoiceGroup ? 'Invalid Invoice Group Detail' : 'Invalid Bundle Detail',
                    message: (isInvoiceGroup ? 'Remove from invoice group operation may only target one or more invoice group detail' :
                        'Remove from bundle operation may only target one or more bundle detail.'),
                    links: [Dialog.links.ok]
                });
                return;

            } else if (headerItem && headerItem.IsProtectedItem == true) {
                Dialog.open({
                    title: 'Cannot Remove Item',
                    message: quosal.util.getProtectedBundleMessage(headerItem.ManufacturerPartNumber, isInvoiceGroup),
                    links: [Dialog.links.ok]
                });
                return;
            } else {
                detailIds.push(grid.props.rows[selectedRows[i]].IdQuoteItems);
            }
        }

        var removeFromPackageApi = (isInvoiceGroup
            ? quosal.api.product.removeFromInvoiceGroup
            : quosal.api.product.removeFromPackage
        )(this.props.quote.IdQuoteMain, detailIds);

        removeFromPackageApi.finished = function (msg) {
            Dialog.setIsWorking(false);
            this.setState({ quote: quosal.sell.quote.update(msg.quote) });
        }.bind(this);
        Dialog.setIsWorking(true);
        removeFromPackageApi.call();

        grid.setState({ isRowSelected: [] });
    }

    removeFromInvoiceGroup(grid, action) {
        this.removeFromPackage(grid, action, true);
    }

    cutRows(grid, action) {
        grid.cutRows();
    }

    copyRows(grid, action) {
        grid.copyRows();
    }

    pasteRows(grid, action) {
        grid.pasteRows((fromGrid, toGrid, newRows, movedRows, sortOrder) => {
            if (newRows.length < 1 && movedRows.length < 1) {
                Dialog.open({
                    title: 'No Rows Selected',
                    message: 'No rows were selected for the paste operation.',
                    links: [Dialog.links.ok]
                });
                return;
            }

            var firstIndexOfThisTabInQuoteItems = null;
            var isOnThisTabAndSaveTheFirstSuchIndex = function (item, index) {
                var result = item.IdQuoteTabs == toGrid.props.quoteTab.IdQuoteTabs;
                if (result && (firstIndexOfThisTabInQuoteItems === null)) {
                    firstIndexOfThisTabInQuoteItems = index;
                }
                return result;
            };

            var tabItems = this.props.quote.Items.where(isOnThisTabAndSaveTheFirstSuchIndex);
            tabItems.sort((a, b) => a.SortOrder - b.SortOrder);
            var insertBeforeThisItem = '';
            if (sortOrder < 0 || sortOrder >= tabItems.length) {
                sortOrder = tabItems.length; // default insert position is at the bottom of the tab.
            } else {
                insertBeforeThisItem = tabItems[sortOrder].IdQuoteItems;
            }
            // If the tab is empty, insert the items at the end of all quote items. This is okay to do because correct
            // tab order within the quote items is not guaranteed (e.g. it is incorrect immediately after a tab has been moved).
            var insertIndex = firstIndexOfThisTabInQuoteItems === null ? this.props.quote.Items.length
                : sortOrder + firstIndexOfThisTabInQuoteItems;

            if (newRows.length > 0) {
                var createList = [];
                var idsOfItemsToCopy = []

                for (var i = 0; i < newRows.length; i++) {
                    var newItem = quosal.util.clone(newRows[i]);
                    newItem.table = 'QuoteItems';
                    newItem.IdQuoteTabs = toGrid.props.quoteTab.IdQuoteTabs;
                    newItem.SortOrder = sortOrder + i;
                    newItem.isUnsaved = true;
                    newItem.className += ' unsaved';
                    createList.push(newItem);
                    idsOfItemsToCopy.push(newRows[i].IdQuoteItems);
                }

                grid.addRows(createList, sortOrder);

                var pasteApi = quosal.api.product.paste(this.props.quote.IdQuoteMain, toGrid.props.quoteTab.IdQuoteTabs, idsOfItemsToCopy, insertBeforeThisItem);
                pasteApi.finished = function (msg) {
                    if (msg.quote) {
                        quosal.sell.quote.update(msg.quote);
                    }
                };
                pasteApi.call();
            }
            else if (movedRows.length > 0) {
                var updates = [];
                var movedRowIds = {};

                for (var i = 0; i < movedRows.length; i++) {
                    movedRowIds[movedRows[i].IdQuoteItems] = true;
                }

                var shouldRemove = function (item, index) {
                    if (movedRowIds[item.IdQuoteItems] === true) {
                        // For every removed element that came before the insertIndex, adjust the insertIndex.
                        if (index < insertIndex) {
                            insertIndex--;
                        }
                        return true;
                    }
                    return false;
                };
                this.props.quote.Items.removeAll(shouldRemove);

                for (var i = 0; i < movedRows.length; i++) {
                    movedRows[i].SortOrder = sortOrder + i;
                    movedRows[i].IdQuoteTabs = toGrid.props.quoteTab.IdQuoteTabs;
                    updates.push({
                        fields: {
                            SortOrder: sortOrder + i,
                            IdQuoteTabs: toGrid.props.quoteTab.IdQuoteTabs
                        },
                        queries: [{
                            table: 'QuoteItems',
                            where: [{
                                field: 'IdQuoteItems',
                                operator: 'Equals',
                                value: movedRows[i].IdQuoteItems
                            }]
                        }]
                    });
                }

                this.props.quote.Items.splice(insertIndex, 0, ...movedRows);
                if (toGrid !== fromGrid) {
                    var fromGridUpdates = this.getFixRowOrderUpdates(this.props.quote.Items.where((s) => ((s.IdQuoteTabs == fromGrid.props.quoteTab.IdQuoteTabs) && (movedRowIds[s.IdQuoteItems] !== true))));
                    updates = updates.concat(fromGridUpdates);
                }
                this.fixRowOrder(this.props.quote.Items.where((s) => (s.IdQuoteTabs == toGrid.props.quoteTab.IdQuoteTabs || (movedRowIds[s.IdQuoteItems] === true))), updates);

            }
        });
    }

    deleteRows(grid, action) {
            var selectedRows = grid.selectedShowingRows();
            var haveProtected = false;
            if (!this.state.userCanModifyProtectedItem) {
                for (var i = selectedRows.length - 1; i >= 0; i--) {
                    if (grid.props.rows[selectedRows[i]].IsProtectedItem) {
                        haveProtected = true;
                        selectedRows.splice(i, 1);
                    }
                }
            }

            if (selectedRows.length < 1) {
                Dialog.open({
                    title: 'No items selected',
                    message: (haveProtected ? 'You must select one or more unprotected items for this operation.' : 'You must select one or more items for this operation.'),
                    links: [Dialog.links.ok]
                });
                return;
            }

            var doDelete = function () {
                Dialog.close();

                var deletedRows = grid.deleteRows(selectedRows);

                var rowIds = [];
                for (var i = 0; i < deletedRows.length; i++) {
                    rowIds.push(deletedRows[i].IdQuoteItems);
                }

                this.props.quote.Items.removeAll((s) => rowIds.indexOf(s.IdQuoteItems) >= 0);
                this.forceUpdate(this.updateGridWithQuickAdd);

                var deleteApi = quosal.api.data.delete({
                    table: 'QuoteItems',
                    where: [{
                        field: 'IdQuoteItems',
                        operator: 'In',
                        value: rowIds
                    }]
                }, this.props.quote.IdQuoteMain);
                deleteApi.finished = function (msg) {
                    quosal.sell.quote.updateFromApiResponse(msg);
                }.bind(this);
                deleteApi.call();
            }.bind(this);

            
            var isPackageHeaderSelected = false;
            var totalPackagesSelected = 0;
            var componentParentIds = new Map();
            for (var i = 0; i < selectedRows.length; i++) {
                if (grid.props.rows[selectedRows[i]].IsPackageHeader) {
                    isPackageHeaderSelected = true;
                    totalPackagesSelected++;
                    var components = grid.props.rows.filter(item => item.ParentQuoteItem == grid.props.rows[selectedRows[i]].IdQuoteItems);
                    for (var j = 0; j < components.length; j++) {
                        if (!selectedRows.includes(components[j].SortOrder)) {
                            selectedRows.push(components[j].SortOrder);
                        }
                    }
                } else if (grid.props.rows[selectedRows[i]].ParentQuoteItem) {
                    var parentQuoteItem = grid.props.rows[selectedRows[i]].ParentQuoteItem;
                    if (componentParentIds.has(parentQuoteItem)) {
                        var value = componentParentIds.get(parentQuoteItem);
                        componentParentIds.set(parentQuoteItem, value + 1)
                    } else {
                        componentParentIds.set(grid.props.rows[selectedRows[i]].ParentQuoteItem, 1);
                    }
                }
            }
            if (componentParentIds.size > 0) {
                for (let [key, value] of componentParentIds) {
                    var components = grid.props.rows.filter(item => item.ParentQuoteItem == key);
                    if (components.length == value) {
                        var bundleHeader = grid.props.rows.filter(item => item.IdQuoteItems == key);
                        if (!selectedRows.includes(bundleHeader[0].SortOrder)) {
                            selectedRows.push(bundleHeader[0].SortOrder);
                            isPackageHeaderSelected = true;
                            totalPackagesSelected++;
                        }
                    }
                }
            }
            selectedRows.sort((a, b) => a - b);

            //AVA-16453 Improper Spelling in Bundle Header Deletion Dialog
            //This is included here because the variables were previously declared with a value of 1, and they are now being calculated based on the item.
            var pluralS = (selectedRows.length > 1) ? 's' : ''; 
            var pluralPackages = (totalPackagesSelected > 1) ? 's' : '';
            var deleteMessage = 'Are you sure you want to delete ' + selectedRows.length + ' item' + pluralS
                + (isPackageHeaderSelected ? ', including ' + totalPackagesSelected + ' bundle' + pluralPackages : '')
                + '?'
                + (haveProtected ? ' Protected items will not be deleted.' : '');

            var mfpList = [];
            for (var item of selectedRows) {
                mfpList.push(grid.props.rows[item].ManufacturerPartNumber);
            }
            var listitems = [];
            for (var i = 0; i < mfpList.length; i++) {
                listitems.push(<div key={i}>{mfpList[i]}</div>);
            }
            var message = (<div> {deleteMessage}
                <br/>
                {listitems}
            </div>);

            Dialog.confirmDelete({
                title: 'Confirm Delete Item' + pluralS,
                message: message,
                callback: doDelete
            });
    }

    createInvoiceGroup(grid, action) {
        var selectedRows = grid.selectedShowingRows();
        var detailIds = [];
        var numberOfSelectedShowingRows = selectedRows.length;
        if (numberOfSelectedShowingRows < 1) {
            Dialog.open({
                title: 'No items selected',
                message: ('You must select one or more items for this operation.'),
                links: [Dialog.links.ok]
            });
            return;
        }

        for (var i = 0; i < selectedRows.length; i++) {
            var row = grid.props.rows[selectedRows[i]];
            if (row.typeName != null) {
                Dialog.open({
                    title: 'Invalid Invoice Group Item',
                    message: 'Invoice Groups may only include standard line items, and may not contain other Bundle items or Invoice Group items.',
                    links: [Dialog.links.ok]
                });
                return;
            }
            else {
                detailIds.push(row.IdQuoteItems);
            }
        }

        var cwGetInvoiceGroups = quosal.api.crm.connectwise.getInvoiceGroups();
        var usedInvoiceGroups = this.getUsedInvoiceGroups();
        cwGetInvoiceGroups.finished = function (msg) {
            var invoiceGroupings = msg.data.invoiceGroupings;
            var dropDownItems = [];
            var menuLinks = [];
            var selectedOption = true;
            var usedInvoiceGroupMessage = '';
            var usedInvoiceGroupElements = [];
            var modalHeight = 150;
            if (invoiceGroupings) {
                for (var i = 0; i < invoiceGroupings.length; i++) {
                    var groupingInfo = JSON.parse(invoiceGroupings[i]);
                    if (!usedInvoiceGroups.includes(groupingInfo["Name"])) {
                        dropDownItems.push(<option key={'option' + i} value={JSON.stringify({
                            groupingId: groupingInfo["Id"], groupingName: groupingInfo["Name"],
                            groupingCustomerDescription: groupingInfo["CustomerDescription"]
                        })}>{groupingInfo["Name"]}</option>);

                        if (selectedOption == true) {
                            this.setState({ selectedInvoiceGroupId: groupingInfo["Id"], selectedInvoiceGroupName: groupingInfo["Name"], selectedInvoiceGroupCustomerDescription: groupingInfo["CustomerDescription"] });
                            selectedOption = false;
                        }
                    }
                }

                if (usedInvoiceGroups.length > 0) {
                    usedInvoiceGroupMessage = 'Invoice Groups In Use:';
                    for (var i = 0; i < usedInvoiceGroups.length; i++) {
                        usedInvoiceGroupElements.push(<div>{usedInvoiceGroups[i]}</div>)
                        modalHeight += 14;
                    }
                }

                if (dropDownItems.length < 1) {
                    Dialog.open({
                        title: 'Create Invoice Group',
                        message: (<div style={{ display: 'inline-block', verticalAlign: 'text-top', width: '275px', height: '50px' }}>All Invoice Groups are in use.
                        </div>),
                        closeRequiresButton: false,
                        links: [{
                            title: 'OK',
                            callback: Dialog.close
                        }],
                    });
                }
                else {
                    Dialog.open({
                        title: 'Create Invoice Group',
                        message: (<div style={{ display: 'inline-block', verticalAlign: 'text-top', width: '275px', height: '50px' }}>Select from a list of Manage groups to create a new invoice group.
                            <div className='formselectfieldwrapper'>
                                <select className='formselectfield' id={'invoiceGroupingDropDown'} title={'Invoice Groupings'} onChange={this.invoiceGroupChanged} >
                                    {dropDownItems}
                                </select>
                            </div>
                            <div id='invoicegroupsinusecontainer'>
                                <h4>{usedInvoiceGroupMessage}</h4>
                                <div>{usedInvoiceGroupElements}</div>
                            </div>
                        </div>),
                        messageProps: { style: { overflow: 'visible', display: 'flex' } },
                        height: modalHeight.toString() + 'px',
                        width: '300px',
                        closeRequiresButton: false,
                        links: [{ title: 'Save', callback: () => this.addProductsToNewInvoiceGroup(detailIds, this.props.quote.id, grid, selectedRows) }, Dialog.links.cancel],
                    });
                }
            }
            else {
                Dialog.open({
                    title: 'No Invoice Groups Found',
                    message: ('No invoice groups were found in ConnectWise Manage. Please add invoice groups in ConnectWise Manage, and try again.'),
                    links: [Dialog.links.ok]
                });
                return;
            }
        }.bind(this);

        cwGetInvoiceGroups.call();
    }

    invoiceGroupChanged(e) {
        var groupJson = JSON.parse(e.target.value);
        this.setState({ selectedInvoiceGroupId: groupJson["groupingId"], selectedInvoiceGroupName: groupJson["groupingName"], selectedInvoiceGroupCustomerDescription: groupJson["groupingCustomerDescription"] });
    }

    addProductsToNewInvoiceGroup(idQuoteItems, idQuoteMain, grid, selectedRows) {

        var sortOrder = null;
        for (var i = 0; i < selectedRows.length; i++) {
            if (sortOrder === null || selectedRows[i] < sortOrder)
                sortOrder = selectedRows[i];
        }

        var createInvoiceGroupApi = quosal.api.product.createInvoiceGroup(
            this.state.selectedInvoiceGroupName,
            this.state.selectedInvoiceGroupId,
            this.state.selectedInvoiceGroupCustomerDescription,
            idQuoteMain,
            grid.props.quoteTab.IdQuoteTabs,
            idQuoteItems,
            sortOrder
        )

        createInvoiceGroupApi.finished = function (msg) {
            if (msg.quote) {
                quosal.sell.quote.update(msg.quote);
            } else {
                this.forceUpdate();
            }
        }.bind(this);

        createInvoiceGroupApi.call();

        grid.setState({ isRowSelected: [] });
        Dialog.close();
    }

    addToInvoiceGroup(grid, action) {
        this.addToPackage(grid, action, true);
    }

    getCustomAggregate(column, items, grid) {
        var quoteTab = grid.props.quoteTab;

        if (column.FieldName == 'GrossMargin') {
            return quoteTab.GrossMargin;
        }
        else if (column.FieldName == 'Markup') {
            return quoteTab.Markup;
        }
        else if (column.FieldName == 'RecurringGrossMargin') {
            return quoteTab.RecurringGrossMargin;
        }
        else if (column.FieldName == 'RecurringMarkup') {
            return quoteTab.RecurringMarkup;
        }
        else {
            var sum = 0.0;

            for (var i = 0; i < items.length; i++) {
                if (items[i].IsOptional == false && items[i].IsTotalsIncluded && items[i].LineType != "Comment")
                    sum += items[i][column.FieldName];
            }

            return sum;
        }
    }

    editGrid(grid, action) {
        grid.editMode(!grid.state.editMode);
    }

    // The standard kind of grid cell is an editable cell in edit mode, or just the value displayed in a div if not.
    // If a standard cell should be produced, this function simply needs to return null.
    // If a component is returned by this, then it will be used as the contents of the cell.
    // Styles added to cellStyle will be applied to the cell.
    // outOptions allows configuration options to be passed back without building the entire cell. Options:
    //      EditableEvenWithoutEditMode (Boolean): Signals that the standard editable cell should be produced even without edit mode.
    //      Value: Replace the cell value with this value.

    buildNotesDialog (gridRow, field, displayValue, editMode, setNotes) {
        var saveAndCloseNotes = function () {
            doSaveNotes(this, true);
        }
        var saveNotes = function () {
            doSaveNotes(this, false);
            return true

        }
        var doSaveNotes = function (dialog, saveAndClose) {
            Dialog.setIsWorking();
            dialog.props.gridRow.props.row.isUnsaved = true;
            dialog.props.gridRow.forceUpdate();

            if (quosal.htmlEditorCallback) {
                quosal.htmlEditorCallback();
                delete quosal.htmlEditorCallback;
            }

            var html = $(dialog.refs.message).find('iframe').contents().find('#rtfNoteHtmlContainer').html();

            var updateApi = quosal.api.data.update({
                fields: {
                    ItemNotesHtml: html
                },
                queries: [{
                    table: 'QuoteItems',
                    where: [{
                        field: 'IdQuoteItems',
                        operator: 'Equals',
                        value: dialog.props.idQuoteItems
                    }]
                }]
            }, dialog.props.idQuoteMain);

            updateApi.finished = function (msg) {
                quosal.sell.quote.updateFromApiResponse(msg);
                const notes = destructHTMLstring(html);
                setNotes && setNotes(notes);
                this.updateProductPreview();
                if (saveAndClose) {
                    Dialog.close();
                } else {
                    var editor = RtfEditor.getInstance('itemnotes');
                    Dialog.setIsWorking(false);
                    editor.isDirty = false;
                }
            }.bind(this);

            updateApi.call();

        }.bind(this);

        var closeNotes = function () {
            var editor = RtfEditor.getInstance('itemnotes');

            if (editor.isDirty) {
                Dialog.confirmDelete({
                    title: 'Changes will be lost',
                    width: '300px',
                    links: [
                        { title: 'Yes, close.', callback: Dialog.closeAll },
                        { title: "No, I'm not done.", callback: Dialog.close }
                    ],
                    message: 'You have unsaved changes. Are you sure you want to close the Edit Notes window?'
                });
            } else {
                Dialog.close();
            }
        };

        var notesChanged = function (params) {
            params = params || {};
            this.isDirty = true;
            if (typeof params.callback === 'function') {
                params.callback();
            }
        };

        var showNotesDialog = function (gridRow, field, displayValue, editMode) {
            var rtfUrl = quosal.util.url('RtfEditor.aspx', 'itemid=' + gridRow.props.row.IdQuoteItems);

            Dialog.open({
                title: 'Edit Item Notes',
                height: '80%',
                width: '80%',
                resizable: true,
                draggable: true,
                onClose: closeNotes, //save changes dialog?
                links: [
                    { title: 'Save', callback: saveNotes },
                    { title: 'Save And Close', callback: saveAndCloseNotes },
                    Dialog.links.cancel
                ],
                message: <RtfLoader message="Loading Notes Editor..." url={rtfUrl}
                                    style={{ width: '100%', height: '100%' }} name="itemnotes"
                                    onChange={notesChanged} />,
                idQuoteItems: gridRow.props.row.IdQuoteItems,
                idQuoteMain: this.props.quote.IdQuoteMain,
                gridRow: gridRow
            });
        }.bind(this, gridRow, field, displayValue, editMode);

        return showNotesDialog;
    }


    customizeCell(params) {
        if (!params) {
            params = {};
        }
        if (!params.outOptions) {
            outOptions = {};
        }
        var gridRow = params.gridRow;
        var field = params.field;
        var displayValue = params.displayValue;
        var editMode = params.editMode;
        var cellStyle = params.cellStyle;
        var outOptions = params.outOptions;
        var protectAll = gridRow.props.row.IsProtectedItem && !this.state.userCanModifyProtectedItem;
        var modTagMode = gridRow.props.row.EnforceItemModTags && (quosal.util.userUseModificationTagToUpdate() || quosal.util.quoteReadOnlyMode() || field.ReadOnly);

        if (gridRow.props.row.isAggregateRow) {
            if (gridRow.props.row[field.FieldName]) {
                return <div style={{ color: '#fff' }}>{gridRow.props.row[field.FieldName]}</div>
            } else {
                return <div />;
            }
        }

        if (field.FieldName === 'QuoteItemPrice') { //TJ: Is this the most accurate check for an override price?... gridRow.props.row.PriceModifier === 'PRICEOVERRIDE'
            if (gridRow.props.row.PriceModifier == 'PRICEOVERRIDE') {
                cellStyle.fontStyle = 'italic';
                cellStyle.fontWeight = 'bold';
                return null;
            }
        }

        if (field.FieldName === 'RecurringAmount') {
            if (outOptions) {
                outOptions.Value = gridRow.props.row.RecurringPrice;
                displayValue = gridRow.props.row.RecurringPrice;
            }
            if (gridRow.props.row.RecurringPriceModifier == 'PRICEOVERRIDE') {
                cellStyle.fontStyle = 'italic';
                cellStyle.fontWeight = 'bold';
                return null;
            }
        }

        if (field.FieldName === 'LongDescription' && gridRow.props.row.IsOptional) {
            outOptions.className = 'optional-field';
        }
        if ((field.FieldName === 'LongDescription' || field.FieldName === 'ManufacturerPartNumber') && gridRow.props.row.IsPackageItem) {
            cellStyle.marginLeft = '30px';
            cellStyle.width = 'calc(100% - 34px)';
        }

        if (field.FieldName == 'ItemNotesHtml') {
            if (modTagMode) { //Modification Tage Mode
                return <ModificationTag itemQuoteId={this.props.quote.IdQuoteMain} itemQuoteItemId={gridRow.props.row.IdQuoteItems}
                                        itemField={field.FieldName} itemFieldContent={displayValue} />
            }

            else if (editMode && !field.ReadOnly) { //Edit Mode
                var showNotesDialog = this.buildNotesDialog(gridRow, field, displayValue, editMode);
                var style = {};

                if (String.isNullOrEmpty(displayValue)) {
                    if (gridRow.props.row.LineType === 'Comment')
                        displayValue = '(click to add a comment)';
                    else
                        displayValue = '(click to add notes)';

                    style.fontStyle = 'italic';
                }

                style["width"] = "100%";
                style["overflowY"] = "auto";
                return (
                    <div title="Edit Notes" style={style} className="edit-notes-link"
                         onClick={gridRow.props.row.isUnsaved ? null : showNotesDialog}
                         dangerouslySetInnerHTML={{ __html: displayValue }}></div>
                );
            }
            else {   // Readonly Mode
                return <div dangerouslySetInnerHTML={{ __html: displayValue }}></div>
            }

        }

        if (field.FieldName == "IsModTagModified") {
            field.ReadOnly = true;
        }

        if (field.FieldName == "EnforceItemModTags" && quosal.util.userUseModificationTagToUpdate()) {
            field.ReadOnly = true;
        }

        if (field.FieldName == "IsPhaseItem" && (!gridRow.props.row.IsPackageHeader || gridRow.props.row.InvoiceGroupingRecid)) {
            var productId = "ProductEdit_IsPhaseItem_" + gridRow.props.row.id;
            var style = {};
            style["overflowY"] = "hidden";
            return <div>
                <input id={productId} type="checkbox" style={style} name="IsPhaseItem" value="true" disabled />
            </div>;
        }

        if (app.settings.global.UseAsioTaxService) {
            if (field.FieldName === 'TaxCode' ||
                field.FieldName === 'TaxRate' ||
                field.FieldName === 'Tax') {

                field.ReadOnly = true;
            }
        }

        var quantityEditableWithoutEditMode = !gridRow.props.row.isUnsaved && !this.props.quote.IsLocked && !app.currentUser.IsReadOnly;
        var isNonActiveQtyField = false;
        var isQtyField = false;
        if (field.FieldName == 'Quantity') {
            // Quantity Field is editable on Bundle Header and Invoice Group Components
            // Quantity Field is read only on Bundle Components and Invoice Group Header
            if ((gridRow.props.row.IsPackageItem && !gridRow.props.row.InvoiceGroupingRecid)
                || (gridRow.props.row.IsPackageHeader && gridRow.props.row.InvoiceGroupingRecid)
                || protectAll) {
                isNonActiveQtyField = true;
            } else {
                isQtyField = true;
            }
        } else if (field.FieldName == 'PackageQty') {
            if (gridRow.props.row.IsPackageItem) {
                isQtyField = true;
            } else {
                isNonActiveQtyField = true;
            }
        }

        if (isNonActiveQtyField) {
            if (field.FieldName == 'Quantity') {
                // var className = 'content' + (field.align ? ' ' + field.align : '');
                return <div>{displayValue}</div>;
            } else {
                return <div></div>;
            }
        } else if (quantityEditableWithoutEditMode && isQtyField && this.props.quote.QuoteStatus != 'Won' && !protectAll) {
            if (outOptions) {
                outOptions.EditableEvenWithoutEditMode = true;
            }
            return null;
        }

        if (gridRow.props.row.IsPackageHeader && !gridRow.props.row.InvoiceGroupingRecid) {
            // Quantity, pictures, booleans, dates, Z_custom fields, dimensions, Part #, descriptions get normal (editable) treatment on package headers.
            if (isQtyField
                || field.DataType === 'Byte[]'
                || field.DataType === 'Boolean'
                || field.DataType === 'DateTime'
                || (field.FieldName
                    && (field.FieldName === 'LongDescription'
                        || field.FieldName === 'ShortDescription'
                        || field.FieldName === 'ManufacturerPartNumber'
                        || field.FieldName === 'OptionGroup'
                        || field.FieldName.indexOf('Z_custom') === 0
                        || field.FieldName.indexOf('Dimension') === 0))
            ) {
                return null;
            }
            if ((field.FieldName === 'QuoteItemPrice' || field.FieldName === 'RecurringAmount')
                && (gridRow.props.row.OverridePackageDetails || quosal.settings.getValue('enableEditPackagePrice'))
            ) {
                return null;
            }

            if ((field.FieldName === 'PriceModifier' || field.FieldName == "RecurringAmount")
                && (gridRow.props.row.OverridePackageDetails)) {
                return null;
            }

            if (field.DataType === 'Double' && displayValue != null && typeof displayValue === 'number') {
                displayValue = accounting.toFixedAuto(displayValue);

                if (field.IsCurrency && app.currentQuote) {
                    displayValue = app.currentQuote.formatCurrency(displayValue);
                }
            }

            return <div>{displayValue}</div>;
        }
        else if (gridRow.props.row.IsPackageHeader && gridRow.props.row.InvoiceGroupingRecid) {
            if (field.FieldName && (field.FieldName === 'ManufacturerPartNumber'
                || field.FieldName === 'PriceModifier' || field.FieldName === 'RecurringPriceModifier'
                || field.FieldName === 'RecurringCalculatedPriceModifier' || field.FieldName === 'OverridePriceModifier'
                || field.FieldName === 'Quantity' || field.FieldName === 'CostModifier' || field.FieldName === 'RecurringCostModifier'))
            {
                return <div>{displayValue}</div>;
            }
            else if (field.DataType === 'Double' && displayValue != null && typeof displayValue === 'number') {
                displayValue = accounting.toFixedAuto(displayValue);

                if (field.IsCurrency && app.currentQuote) {
                    displayValue = app.currentQuote.formatCurrency(displayValue);
                }
                return <div>{displayValue}</div>;
            }
            else{
                return null;
            }
        }
        return null;
    }

    gridConfigurationChanged(gridConfig) {
        var isNewUI = quosal.util.isNewEditorEnabled();
        var tabCtrl = this.refs.tabControl;
        var tabId = arguments[arguments.length-1];
        var selectedTabId = isNewUI ? tabId : tabCtrl ? tabCtrl.getSelectedTab().item.IdQuoteTabs : null;

        var updateApi = quosal.api.data.update({
            fields: {
                GridFormat: gridConfig.ConfigurationName
            },
            queries: [{
                table: 'QuoteTabs',
                where: [{
                    field: 'IdQuoteTabs',
                    operator: 'Equals',
                    value: selectedTabId,
                }]
            }]
        }, this.props.quote.IdQuoteMain);

        updateApi.finished = function (msg) {
            quosal.sell.quote.updateFromApiResponse(msg);
        }.bind(this);

        if (isNewUI && arguments.length > 1) {
            updateApi.call();
            return;
        }

        if (tabCtrl) {
            var tab = tabCtrl.getSelectedTab().item;
            if (tab.GridFormat != gridConfig.ConfigurationName) {
                updateApi.call();
            } else {
                this.forceUpdate();
            }
        }
    }

    componentDidMount() {
        quosal.customization.grids.configurationUpdated.bind(this.gridConfigurationChanged);
        quosal.customization.grids.configurationChanged.bind(this.gridConfigurationChanged);
        if (this.refs.contentGridToolbar) {
            this.refs.contentGridToolbar.forceUpdate();
        }
        quosal.navigation.cleanUpFunctionsBeforeNavigation['QuoteContentGrid'] = function () {
            if (this.fieldChangeTimeoutId) {
                window.clearTimeout(this.fieldChangeTimeoutId);
            }

            this.makeFieldChangeApiCall();
        }.bind(this);

        $('#dark-toggle[type=checkbox]').on('change', this.darkModeChanged)
    }

    UNSAFE_componentWillUnmount() {
        delete quosal.navigation.cleanUpFunctionsBeforeNavigation['QuoteContentGrid'];

        quosal.customization.grids.configurationUpdated.unbind(this.gridConfigurationChanged);
        quosal.customization.grids.configurationChanged.unbind(this.gridConfigurationChanged);

        if (this.props.onDispose)
            this.props.onDispose(this);

        $('#dark-toggle[type=checkbox]').off('change', this.darkModeChanged)
    }

    darkModeChanged(event) {
        this.setState({ isDarkMode: event.target.checked });
    }

    onFullscreenToggle() {
        if (this.refs.contentGridToolbar) {
            this.refs.contentGridToolbar.forceUpdate();
        }
    }

    isContentGridToolbarMenuEditable(selectedTab) {
        return !this.props.quote.IsLocked && !app.currentUser.IsReadOnly && (selectedTab != null && (!selectedTab.IsProtectedTab || this.state.userCanModifyProtectedTab))
    }

    shouldRestrictNewItems(selectedTab) {
        return (selectedTab.HasLimitOnNewItems && !quosal.util.userIsAdminOrMaintainer() && !app.currentUser.IsStandardPlus);
    }

    getCurrentDataGrid() {
        var tabs = this.refs.tabControl;
        var selectedTab = tabs.getSelectedTab().item;
        return this.refs['dataGrid_' + selectedTab.IdQuoteTabs];
    }

    getDataGridByTabId(tabId) {
        return this.refs['dataGrid_' + tabId];
    }

    getInsertPositionId(params) {
        params = params || {};
        var tabs = this.refs.tabControl;
        var selectedTab = tabs.getSelectedTab().item;           
        let dataGrid = this.refs['dataGrid_' + selectedTab.IdQuoteTabs];

        var selectedRows = dataGrid.selectedShowingRows();
        if (selectedRows.length > 0) {
            if (params.returnIndexAndId) {
                return {
                    id: dataGrid.props.rows[selectedRows[0]].IdQuoteItems,
                    index: selectedRows[0],
                    dataGrid: dataGrid
                };
            } else {
                return dataGrid.props.rows[selectedRows[0]].IdQuoteItems;
            }
        }
        return null;
    }

    checkActionDisableCodes(idQuoteTabs) {
        let results = {};
        let dataGrid = this.refs['dataGrid_' + idQuoteTabs];

        var selectedRows = dataGrid.selectedShowingRows();

        if (selectedRows.length > 0) {
            for (var i = 0; i < selectedRows.length; i++) {
                let row = dataGrid.props.rows[selectedRows[i]]
                if (row.IsPackageHeader) {
                    if (row.InvoiceGroupingRecid) {
                        results.hasInvoiceGroupHeader = true;
                    } else {
                        results.hasBundleHeader = true;
                    }
                } else if (row.IsPackageItem) {
                    if (row.InvoiceGroupingRecid) {
                        results.hasInvoiceGroupItem = true;
                    } else {
                        results.hasBundleItem = true;
                    }
                } else {
                    results.hasUngroupedItem = true;
                }
            }
        } else {
            results.nothingSelected = true;
        }
        return results;
    }

    launchSpreadsheet(tabId) {
        var spreadSheetUrl = quosal.util.getSpreadsheetUrl(tabId);

        if(quosal.util.isSpreadSheetOverride())
        {
            var spreadSheetWindow = window.open(spreadSheetUrl, '_blank');
            var spreadSheetCloseListener = function(e) {
                if (spreadSheetUrl.startsWith(e.origin) && e.data == 'spreadSheetDone') {
                    var openQuoteApi = quosal.api.quote.openQuote(app.currentQuote.IdQuoteMain);
                    openQuoteApi.finished = function (msg) {
                        quosal.sell.quote.update(msg.quote);
                        Dialog.setIsWorking(false);
                        Dialog.close();
                    };
                    openQuoteApi.call();
                    Dialog.setIsWorking(true);
                    window.removeEventListener('message', spreadSheetCloseListener);
                } else {
                    return;
                }
            }

            Dialog.open({
                title: `Accessing Spreadsheet`,
                closeRequiresButton: true,
                width: "75%",
                message: `You will now be redirected to the spreadsheet in a new browser tab. 
                Navigating away from this page or pressing 'Cancel Spreadsheet' will 
                cause any changes made to the spreadsheet to be disregarded. `,
                links:[
                    {title:'Cancel Spreadsheet', callback: function () {
                            spreadSheetWindow.postMessage('removeUnloadAndClose',"*");
                            Dialog.close()
                            window.removeEventListener('message', spreadSheetCloseListener);
                        }}
                ]
            });

            window.addEventListener('message', spreadSheetCloseListener);
        }
        else
        {
            quosal.navigation.navigate(spreadSheetUrl);
        }
    }

    calculateTaxAsio() {
        var calculateTaxApi = quosal.api.quote.calculateTax(this.props.quote.IdQuoteMain);
        calculateTaxApi.finished = function(msg) {
            if (msg.error) {
                Dialog.setIsWorking(false);
                Dialog.open({
                    title: 'Error Calculating Taxes',
                    message: msg.error,
                    links: [{title: 'OK', callback: Dialog.closeAll}]
                })
            }
            else {
                quosal.sell.quote.updateFromApiResponse(msg);
                Dialog.setIsWorking(false);
                Dialog.close();
            }
        }.bind(this);
        calculateTaxApi.call();

        Dialog.open({
            title: 'Calculating Taxes..',
            closeRequiresButton: true,
            titleId: 'calculatingTaxId',
        })

        Dialog.setIsWorking(true);
    }
    
    updatePreviewVisible() {
        quosal.settings.saveUserSetting({
            key: 'DocumentPreviewVisible',
            value: !quosal.util.isPreviewContentOpened(),
            isUserSetting: true
        }, this.forceUpdate);
        
    }

    restoreDeletedTab() {
        var getDeletedTabs = quosal.api.data.query({
            table: 'QuoteTabs',
            where: [
                {
                    field: "DeleteQuoteReadableId",
                    operator: 'Equals',
                    value: this.props.quote.QuoteReadableId
                },
            ],
        });
        
        getDeletedTabs.finished = function(msg) {
            if (msg.error) {
                Dialog.setIsWorking(false);
                Dialog.open({
                    title: 'Error Deleting Tabs',
                    closeRequiresButton: true,
                    message: msg.error,
                    links: [{title: 'OK', callback: Dialog.closeAll}]
                })
            }
            else {
                Dialog.setIsWorking(false);
                Dialog.open({
                    closeRequiresButton: true,
                    title: 'Restore Tabs',
                    message: <RestoreDeletedTabs data={msg.resultSets[0]} />,
                    links: [{title: 'CLOSE', callback: ()=> {
                        this.rerenderContentGrid();
                        Dialog.closeAll();
                    }}]
                })
            }
        }.bind(this);
        getDeletedTabs.call();

        Dialog.open({
            title: 'Finding tabs..',
            closeRequiresButton: true,
            titleId: 'findingtabsId',
        })

        Dialog.setIsWorking(true);
    }


    render() {
        if (this.state.loadingMessage) {
            return (
                <FormPlaceholder
                    message={this.state.loadingMessage} />
            );
        }

        if (app.currentQuote.Tabs.every(function (tab) { return tab.IsHidden }) && (!app.currentUser.IsAdministrator && !app.currentUser.IsContentMaintainer)) {
            return (
                <Panel>
                    <PanelContent>
                        <span style={{ fontSize: 12 }}>All tabs on this quote are hidden. Please check with an administrator if you believe this was done in error</span>
                    </PanelContent>
                </Panel>
            )
        }

        var gridLayouts = quosal.customization.grids['QuoteContent'].Configurations;

        var quoteHasPackages = false;
        var quoteHasInvoiceGroups = false;
        var tabs = [];

        if (this.props.quote.Items) {
            var tabOrder = {};
            for (var i = 0; i < this.props.quote.Tabs.length; i++) {
                var tab = this.props.quote.Tabs[i];
                tabOrder[tab.IdQuoteTabs] = parseInt(tab.TabNumber);
            }
            this.props.quote.Items.sort((a, b) => (tabOrder[a.IdQuoteTabs] - tabOrder[b.IdQuoteTabs]) || (a.SortOrder - b.SortOrder)); // ER 12/15/17: The order will change on 0 return value, due to a Javascript(?) bug.

            for (var i = 0;
                 (i < this.props.quote.Items.length) && !(quoteHasPackages && quoteHasInvoiceGroups);
                 i++
            ) {
                if (this.props.quote.Items[i].IsPackageHeader) {
                    if (this.props.quote.Items[i].InvoiceGroupingRecid) {
                        quoteHasInvoiceGroups = true;
                    } else {
                        quoteHasPackages = true;
                    }
                }
            }
        }

        this.props.quote.Tabs.sort((a, b) => a.TabNumber - b.TabNumber);

        //tab coloring
        quosal.util.color.pallette.default.reset();
        var tabGroupColors = {};
        var optionGroupColors = {};

        var tabGroups = [];
        var optionGroups = [];

        for (var t = 0; t < this.props.quote.Tabs.length; t++) {
            var quoteTab = this.props.quote.Tabs[t];

            if (!quosal.util.nullOrEmpty(quoteTab.TabGroup) && tabGroups.indexOf(quoteTab.TabGroup) < 0) {
                tabGroups.push(quoteTab.TabGroup);
                //tabGroups[quoteTab.TabGroup] = quosal.util.color.pallette.default.nextColor(); // quosal.util.generateColor({max:180, seed: quoteTab.TabGroup.hashCode()});
            }

            if (!quosal.util.nullOrEmpty(quoteTab.OrderPorterGroup) && optionGroups.indexOf(quoteTab.OrderPorterGroup) < 0) {
                optionGroups.push(quoteTab.OrderPorterGroup);
                //optionGroups[quoteTab.OrderPorterGroup] = quosal.util.color.pallette.default.nextColor();//quosal.util.generateColor({seed: quoteTab.OrderPorterGroup.hashCode()});
            }
        }

        tabGroups.sort();
        optionGroups.sort();

        for (var i = 0; i < tabGroups.length; i++) {
            tabGroupColors[tabGroups[i]] = quosal.util.color.pallette.default.nextColor();
        }
        for (var i = 0; i < optionGroups.length; i++) {
            optionGroupColors[optionGroups[i]] = quosal.util.color.pallette.default.nextColor();
        }

        var lastTabNumber = 0;

        var canAccessItemEdit = (this.state.userCanModifyProtectedItem || !(quosal.settings.getValue('LockStandardUsersOutOfItemEdit')));

        for (var t = 0; t < this.props.quote.Tabs.length; t++) {
            //iterate through each tab
            var quoteTab = this.props.quote.Tabs[t];

            if (quoteTab.IsHidden && !quosal.util.userIsAdminOrMaintainer()) {
                continue;
            }

            var isEditable = !this.props.quote.IsLocked && !app.currentUser.IsReadOnly && (!quoteTab.IsProtectedTab || this.state.userCanModifyProtectedTab);
            var isWon = this.props.quote.QuoteStatus == 'Won';

            //create the items grid
            var items = [];

            if (this.props.quote.Items) {
                items = this.props.quote.Items.where(function (item) {
                    return item.IdQuoteTabs == quoteTab.IdQuoteTabs;
                });
            }

            var tabHasPackages = false;
            var headers = {};

            for (var i = 0; i < items.length; i++) {
                if (items[i].IsPackageHeader) {
                    items[i].childRows = [];
                    headers[items[i].id] = items[i];

                    if (!items[i].InvoiceGroupingRecid) {
                        tabHasPackages = true;
                    }
                }
            }

            var tabHasProtectedItems = false;
            //Prepare rows for grid by adding metadata to differentiate row types
            for (var i = 0; i < items.length; i++) {
                if (items[i].IsProtectedItem && !this.state.userCanModifyProtectedItem)
                    tabHasProtectedItems = true;
                if (items[i].IsPackageHeader) {
                    if (items[i].InvoiceGroupingRecid) {
                        items[i].className = 'isInvoiceGroupHeader';
                    }
                    else {
                        items[i].className = 'isPackageHeader';
                    }
                    items[i].typeName = 'header';
                } else if (items[i].IsPackageItem) {
                    if (items[i].InvoiceGroupingRecid) {
                        items[i].className = 'isNotTotalsIncluded invoiceGroupDetail';
                    }
                    else {
                        items[i].className = 'isNotTotalsIncluded';
                    }
                    items[i].typeName = 'detail';
                    items[i].parentRow = headers[items[i].ParentQuoteItem];
                    if (headers[items[i].ParentQuoteItem])
                        headers[items[i].ParentQuoteItem].childRows.push(items[i].id);
                } else if (items[i].LineType == 'SubHeader') {
                    items[i].className = 'isNotTotalsIncluded subHeader';
                    items[i].typeName = 'standard';
                } else if (items[i].LineType == 'SubFooter') {
                    items[i].className = 'isNotTotalsIncluded subFooter';
                    items[i].typeName = 'standard';
                } else if (!items[i].IsTotalsIncluded) {
                    items[i].className = 'isNotTotalsIncluded';
                } else if (items[i].LineType == 'Comment') {
                    items[i].className = 'isNotTotalsIncluded';
                } else if (items[i].IsTotalsIncluded) {
                    items[i].className = '';
                }
            }

            if (quoteTab.GridFormat == 'Quosal Default Layout' || String.isNullOrEmpty(quoteTab.GridFormat)) {
                quoteTab.GridFormat = 'Default'; //Legacy support, translate old default name to new default name - TJ
            }

            var layout = gridLayouts[quoteTab.GridFormat];
            if (!layout || !layout.Columns) {
                layout = gridLayouts['Default'];
            }

            var customColumns = [];
            if (canAccessItemEdit) {
                customColumns.push({
                    width: 50,
                    createCell: function (quoteTab, row) {
                        var isEditable = !this.props.quote.IsLocked && !app.currentUser.IsReadOnly;
                        if (quoteTab.IsProtectedTab && !this.state.userCanModifyProtectedTab) {
                            isEditable = false;
                        }
                        if (row.props.row.IsProtectedItem && !this.state.userCanModifyProtectedItem) {
                            isEditable = false;
                        }
                        var linkType = 'Edit';

                        if (!isEditable) {
                            //View Mode link (C3 in grid)
                            linkType = 'View';
                        }

                        if (row.props.row.isAggregateRow) {
                            return null;
                        }

                        if (row.props.row.isUnsaved) {
                            return <Spinner />;
                        }

                        var navigateToProductEdit = function () {
                            app.currentModule.loadSubModule('product.edit', {
                                container: 'quoteModule',
                                query: 'itemid=' + row.props.row.IdQuoteItems + '&idquotetabs=' + row.props.row.IdQuoteTabs
                            });
                        };

                        return <a onClick={navigateToProductEdit} className="link">
                            <div className={'icons-action ' + linkType.toLowerCase() + ' color'} style={{ margin: 5 }}
                                 title={linkType + ' Product'}></div>
                        </a>;
                    }.bind(this, quoteTab)
                });
            }
            if (isEditable || (app.currentQuote.IsRequestQuote && quosal.settings.getValue('AllowSourceCostUpdate'))) {
                customColumns.push({
                    extraClass: 'noFade',
                    createCell: function (quoteTab, row) {
                        var isEditable = !app.currentUser.IsReadOnly && (!this.props.quote.IsLocked || (app.currentQuote.IsRequestQuote && quosal.settings.getValue('AllowSourceCostUpdate')));
                        if (quoteTab.IsProtectedTab && !this.state.userCanModifyProtectedTab) {
                            isEditable = false;
                        }
                        if (row.props.row.IsProtectedItem && !this.state.userCanModifyProtectedItem) {
                            isEditable = false;
                        }
                        var isLocked = false;
                        var readonly = null;
                        if (row.props.row.ExternalQuoteNumber && row.props.row.ExternalQuoteNumber.length > 0) {
                            readonly = true;
                        }

                        if (!isEditable) // cloud
                        {
                            return <div />;
                        }

                        if (row.props.row.isAggregateRow)
                            return null;

                        if (row.props.row.isUnsaved)
                            return <div style={{ width: 16, height: 10 }} />;

                        if (row.props.row.LineType == 'Comment') {
                            return null;
                        } else if (row.props.row.LineType == 'SubHeader' || row.props.row.LineType == 'SubFooter') {
                            return <div />;
                        } else {
                            var cls = "contentgrid";
                            if (isLocked)
                                cls += " disabled"
                            return <CloudSourceButton item={row.props.row} className={cls} lockpanel={readonly}
                                                      type="cloudsourcing" disabled={isLocked} priceSourcesLoaded={this.state.priceSourcesLoaded} />;
                        }
                    }.bind(this, quoteTab)
                });
            }

            var customizeRow = function (row) {
                var getConfig = function () {
                    var config = quosal.customization.grids.QuoteContent.Configurations[row.props.grid.props.configuration.configurationName || 'Default'];
                    return config || quosal.customization.grids.QuoteContent.Configurations.Default;
                };
                var getColumns = function () {
                    var config = getConfig();
                    if (config) {
                        var columns = config.Columns;
                        var gridName = config.GridName;
                        var gridLayoutName = config.ConfigurationName;
                        if (app.settings.user.gridColumnWidths[gridName] && app.settings.user.gridColumnWidths[gridName][gridLayoutName]) {
                            var visibleColumns = [];
                            for (var i = 0; i < columns.length; i++) {
                                if (app.settings.user.gridColumnWidths[gridName][gridLayoutName][columns[i].FieldName] !== 0) {
                                    visibleColumns.push(columns[i]);
                                }
                            }
                            columns = visibleColumns;
                        }
                        return columns
                    }
                    return null;
                };

                if (row.props.row.isAggregateRow) {
                    var columns = getColumns();

                    if (columns) {
                        return {
                            headers: columns,
                            style: {
                                backgroundColor: '#8b8b8b!important',
                                color: '#fff',
                                border: '1px solid #666'
                            }
                        };
                    }
                } else if (row.props.row.LineType == 'Comment') {
                    var config = getConfig();

                    if (config) {
                        var headers = config.Columns.where(s => s.FieldName == 'ItemNotesHtml');
                        if (headers.length == 0) //TJ: ItemNotes may not be part of this grid layout so we must fabricate it, issue ticket #7896742 was stemming from here
                            headers.push({
                                FieldName: 'ItemNotesHtml',
                                DisplayName: '',
                                DataType: 'String'
                            });
                        return {
                            headers: headers,
                            colSpan: config.Columns.length + 2, // FSP 6/13/16: This magic number (the +2) probably corresponds to the two "custom columns,"
                            // the cloud column and the edit button column. TODO: un-magic the number; connect to its logical source
                            shouldCalculateColSpan: true
                        };
                    }
                } else if (row.props.row.IsPackageHeader) {
                    var columns = getColumns();

                    if (columns) {
                        var headers = columns;

                        return {
                            headers: headers
                        };
                    }
                }
                return null;
            };

            var selectedTabId = this.props.module.state.selectedTab || quosal.util.queryString('idquotetabs');
            var selectedTab = this.props.quote.Tabs.firstOrNull(s => s.IdQuoteTabs == selectedTabId);
            if (!selectedTab) selectedTab = this.props.quote.Tabs[0];
            var restrictNewItems = this.shouldRestrictNewItems(selectedTab);

            var customizeFooter = (isEditable && !isWon) ? function (grid) {
                return <QuickAddFooter grid={grid} contentGrid={this} priceSourcesLoaded={this.state.priceSourcesLoaded} />; // restrictNew={restrictNewItems}
            }.bind(this) : null;

            var rowActions = [
                {
                    title: 'Select All',
                    icon: 'img/icons/unchecked.png',
                    callback: this.selectAllRows,
                    keepMenuOpen: true
                },
                { title: 'Update Field...', callback: this.massUpdateField, visible: canAccessItemEdit,
                    actionDisableCodes: ['nothingSelected', 'hasBundleHeader', 'hasInvoiceGroupHeader'] },
                {
                    title: 'Bundles',
                    submenu: [
                        { title: 'Create Bundle...', callback: this.createPackage,
                            actionDisableCodes: ['nothingSelected', 'hasBundleHeader', 'hasBundleItem', 'hasInvoiceGroupHeader', 'hasInvoiceGroupItem']  },
                        { title: 'Save Bundle...', callback: this.savePackage, visible: tabHasPackages,
                            actionDisableCodes: ['nothingSelected', 'hasBundleItem', 'hasInvoiceGroupHeader', 'hasInvoiceGroupItem', 'hasUngroupedItem'] },
                        { title: 'Add to Bundle...', callback: this.addToPackage, visible: quoteHasPackages,
                            actionDisableCodes: ['nothingSelected', 'hasBundleHeader', 'hasBundleItem', 'hasInvoiceGroupHeader', 'hasInvoiceGroupItem'] },
                        { title: 'Remove from Bundle', callback: this.removeFromPackage, visible: tabHasPackages,
                            actionDisableCodes: ['nothingSelected', 'hasBundleHeader', 'hasInvoiceGroupHeader', 'hasInvoiceGroupItem', 'hasUngroupedItem'] },
                        { title: 'Unbundle', callback: this.unpackage, visible: tabHasPackages,
                            actionDisableCodes: ['nothingSelected', 'hasBundleItem', 'hasInvoiceGroupHeader', 'hasInvoiceGroupItem', 'hasUngroupedItem'] }
                    ]
                },
                {
                    title: 'Invoice Grouping',
                    submenu: [
                        { title: 'Create Group...', callback: this.createInvoiceGroup,
                            actionDisableCodes: ['nothingSelected', 'hasBundleHeader', 'hasBundleItem', 'hasInvoiceGroupHeader', 'hasInvoiceGroupItem'] },
                        { title: 'Add Items to Group...', callback: this.addToInvoiceGroup, disabled: !quoteHasInvoiceGroups,
                            actionDisableCodes: ['nothingSelected', 'hasBundleHeader', 'hasBundleItem', 'hasInvoiceGroupHeader', 'hasInvoiceGroupItem'] },
                        { title: 'Remove Items from Group', callback: this.removeFromInvoiceGroup,
                            actionDisableCodes: ['nothingSelected', 'hasBundleHeader', 'hasBundleItem', 'hasInvoiceGroupHeader', 'hasUngroupedItem'] },
                        { title: 'Ungroup', callback: this.ungroupInvoiceGroup,
                            actionDisableCodes: ['nothingSelected', 'hasBundleHeader', 'hasBundleItem', 'hasInvoiceGroupItem', 'hasUngroupedItem'] }
                    ],
                    visible: (
                        quosal.settings.getValue('EnableInvoiceGrouping')
                        && String.ciEquals(quosal.settings.getValue('customerProvider'), 'Connectwise')
                        && String.ciEquals(quosal.settings.getValue('ConnectWiseAPIMode'), 'REST')
                    )
                },
                { title: 'Cut', callback: this.cutRows,
                    actionDisableCodes: ['noCut', 'hasBundleItem', 'hasInvoiceGroupItem'] },
                { title: 'Copy', callback: this.copyRows,
                    actionDisableCodes: ['noCopy', 'hasBundleItem', 'hasInvoiceGroupHeader', 'hasInvoiceGroupItem'] },
                { title: 'Paste', callback: this.pasteRows,
                    actionDisableCodes: ['hasBundleItem', 'hasInvoiceGroupItem'] },
                { title: 'Insert Section Header', callback: this.insertSectionGrouping.bind(this, 'SubHeader'), disabled: this.state.isInsertSectionGrouping,
                    actionDisableCodes: ['hasBundleItem', 'hasInvoiceGroupItem'] },
                { title: 'Insert Spacer(s)', callback: this.insertSectionGrouping.bind(this, 'Comment'), disabled: this.state.isInsertSectionGrouping,
                    actionDisableCodes: ['hasBundleItem', 'hasInvoiceGroupItem'] },
                { title: 'Insert Section Footer', callback: this.insertSectionGrouping.bind(this, 'SubFooter'), disabled: this.state.isInsertSectionGrouping,
                    actionDisableCodes: ['hasBundleHeader', 'hasInvoiceGroupHeader'] },
                { title: 'Calculate Margins', callback: this.calculateMargins,
                    actionDisableCodes: ['nothingSelected'] },
                { title: 'Delete Item(s)', callback: this.deleteRows, visible: !isWon,
                    actionDisableCodes: ['nothingSelected'] },
            ];

            var gridConfiguration = {
                gridName: 'QuoteContent',
                configurationName: quoteTab.GridFormat,
                fieldList: 'QuoteItems',
                boVersion: layout.BoVersion
            };

            var tabEditable = (!quoteTab.IsProtectedTab || this.state.userCanModifyProtectedTab);
            var actVisible = this.state.userCanModifyProtectedTab || !quoteTab.IsProtectedTab;
            var actAvailable = !app.currentUser.IsReadOnly && (!this.props.quote.IsLocked && (!quoteTab.IsProtectedTab || this.state.userCanModifyProtectedTab));
            var isQuoteArchived = app.currentQuote.QuoteStatus == 'Archived';
            // var anyTabWithProtected = false;
            // if (!this.state.userCanModifyProtectedItem) {
            //     for (var i = 0; i < this.props.quote.Items.length; i++) {
            //         if (this.props.quote.Items[i].IsProtectedItem)
            //             anyTabWithProtected = true;
            //     }
            // }

            var onDropdownUpdate = function () {
                this.forceUpdate();
            }.bind(this);

            var tabActions = // double check these settings
                [
                    {
                        title: 'Rename Tab',
                        callback: this.renameTab,
                        tab: quoteTab,
                        visible: actVisible,
                        disabled: !actAvailable
                    },
                    {
                        title: 'Edit Grid Layout',
                        callback: DataGridCustomizer.showDataGridCustomizer.bind(null, gridConfiguration, true, onDropdownUpdate),
                        visible: (actVisible && quosal.util.userIsAdminOrMaintainer() && !isQuoteArchived)
                    },
                    {
                        title: tabEditable ? 'Edit Tab' : 'Tab Details',
                        callback: this.editTab,
                        tab: quoteTab,
                        visible: true,
                        disabled: false
                    },
                    {
                        title: quoteTab.ShowLineItemQuickFilter ? 'Hide Line Item Quick Filter' : 'Show Line Item Quick Filter',
                        callback: this.toggleLineItemQuickFilter,
                        tab: quoteTab,
                        visible: true,
                        disabled: this.props.quote.IsLocked || app.currentUser.IsReadOnly
                    },
                    {
                        title: 'Remove 0 Quantity Items',
                        callback: this.removeZeroQuantityItems,
                        tab: quoteTab,
                        visible: actVisible,
                        disabled: !actAvailable
                    },
                    {
                        title: 'Update All Costs',
                        url: quosal.util.url('updatecosts.quosalweb', 'idquotetabs=' + quoteTab.IdQuoteTabs, 'tabonly=true'),
                        tab: quoteTab,
                        visible: actVisible,
                        disabled: !actAvailable || !this.isUACAvailable(quoteTab.IdQuoteTabs)
                    },
                    {
                        title: 'Is Deselected Option',
                        icon: quoteTab.IsOptional ? 'img/icons/checked.png' : 'img/icons/unchecked.png',
                        callback: this.toggleTabOption.bind(this, 'IsOptional'),
                        keepMenuOpen: true,
                        tab: quoteTab,
                        visible: actVisible,
                        disabled: !actAvailable
                    },
                    {
                        title: 'Is Printed',
                        icon: quoteTab.IsPrinted ? 'img/icons/checked.png' : 'img/icons/unchecked.png',
                        callback: this.toggleTabOption.bind(this, 'IsPrinted'),
                        keepMenuOpen: true,
                        tab: quoteTab,
                        visible: actVisible,
                        disabled: !actAvailable
                    },
                    {
                        title: 'Is Totals Included',
                        icon: quoteTab.IsTotalsIncluded ? 'img/icons/checked.png' : 'img/icons/unchecked.png',
                        callback: this.toggleTabOption.bind(this, 'IsTotalsIncluded'),
                        keepMenuOpen: true,
                        tab: quoteTab,
                        visible: actVisible,
                        disabled: !actAvailable
                    },
                    {
                        title: 'Option Locked',
                        icon: quoteTab.OrderPorterRequired ? 'img/icons/checked.png' : 'img/icons/unchecked.png',
                        callback: this.toggleTabOption.bind(this, 'OrderPorterRequired'),
                        keepMenuOpen: true,
                        tab: quoteTab,
                        visible: actVisible,
                        disabled: !actAvailable
                    },
                    {
                        title: 'Tab Spreadsheet',
                        bypassClientNav: true,
                        callback: () => this.launchSpreadsheet(this.refs.tabControl.getSelectedTab().item.IdQuoteTabs),
                        visible: actVisible && quosal.settings.getValue('canSpreadsheet') && quosal.validation.isPackageLevelAuthorized(app.packageLevels.premium),
                        disabled: isQuoteArchived
                    },
                    {
                        title: 'Duplicate Tab',
                        callback: this.duplicateTab.bind(this, false),
                        tab: quoteTab,
                        visible: true,
                        disabled: this.props.quote.IsLocked || app.currentUser.IsReadOnly || tabHasProtectedItems
                    },
                    {
                        title: 'Duplicate Tab as Radio Option',
                        callback: this.duplicateTab.bind(this, true),
                        tab: quoteTab,
                        visible: true,
                        disabled: this.props.quote.IsLocked || app.currentUser.IsReadOnly || tabHasProtectedItems
                    },
                    {
                        title: 'Delete Tab',
                        callback: this.deleteTab,
                        tab: quoteTab,
                        visible: tabEditable,
                        disabled: !actAvailable || tabHasProtectedItems || this.state.tabIsDeleting,
                        spinner: this.state.tabIsDeleting
                    },
                ]

            var rowMenuComponentDidMount = function () {
                if (!QuoteContentGrid.tabAndItemMenusByTabId[this.props.tabId]) {
                    QuoteContentGrid.tabAndItemMenusByTabId[this.props.tabId] = {};
                }
                QuoteContentGrid.tabAndItemMenusByTabId[this.props.tabId].itemMenu = this; // `this` is meant to be the ActionsMenu, where this function shotabMenuuld be executed in componentDidMount
            };
            var rowMenuComponentWillUnmount = function () {
                delete QuoteContentGrid.tabAndItemMenusByTabId[this.props.tabId].itemMenu;
            };
            var rowMenu = {
                type: 'ActionsMenu',
                title: 'Line Item Actions',
                name: 'massupdate',
                tabId: quoteTab.IdQuoteTabs,
                actions: rowActions,
                checkActionDisableCodes: this.checkActionDisableCodes.bind(this, quoteTab.IdQuoteTabs),
                componentDidMount: rowMenuComponentDidMount,
                componentWillUnmount: rowMenuComponentWillUnmount,
                transition: "slidefade"
            };

            var tabMenuComponentDidMount = function () {
                if (!QuoteContentGrid.tabAndItemMenusByTabId[this.props.tabId]) {
                    QuoteContentGrid.tabAndItemMenusByTabId[this.props.tabId] = {};
                }
                QuoteContentGrid.tabAndItemMenusByTabId[this.props.tabId].tabMenu = this; // `this` is meant to be the ActionsMenu, where this function should be executed in componentDidMount
            };
            var tabMenuComponentWillUnmount = function () {
                delete QuoteContentGrid.tabAndItemMenusByTabId[this.props.tabId].tabMenu;
            };
            var tabMenu = {
                type: 'ActionsMenu',
                title: 'Tab Actions',
                name: 'tabupdate',
                tabId: quoteTab.IdQuoteTabs,
                actions: tabActions,
                componentDidMount: tabMenuComponentDidMount,
                componentWillUnmount: tabMenuComponentWillUnmount,
                transition: "slidefade"
            };

            var isFieldEditable = function (gridRow, field) {
                if (this.props.quote.QuoteStatus === 'Won') {
                    //Service Ticket #7627326 Only cost fields should be editable on a won quote
                    if (field.FieldName !== 'Cost' && field.FieldName !== 'RecurringCost') {
                        return false;
                    } else {
                        return true;
                    }
                }
                else {
                    if (gridRow.props.grid.props.quoteTab.IsProtectedTab && !this.state.userCanModifyProtectedTab) {
                        if (field.FieldName === 'IsPrinted') {
                            //Can only modify IsPrinted on Protected Tabs
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    if (gridRow.props.row.IsProtectedItem && !this.state.userCanModifyProtectedItem) {
                        return false;
                    }
                    if (gridRow.props.row.IsProtectedPrice && !this.state.userCanModifyProtectedPrice) {
                        if (quosal.util.isProtectedPriceItem(field.FieldName)) {
                            return false;
                        }
                    }
                    if (field.FieldName == "IsProtectedItem" && !this.state.userCanModifyProtectedItem) {
                        return false;
                    }
                    if (field.FieldName == "IsProtectedPrice" && !this.state.userCanModifyProtectedPrice) {
                        return false;
                    }
                    if (field.FieldName === 'RampPeriods') {
                        return gridRow.props.row.IsRampable;
                    }
                    if (field.FieldName === 'DefermentPeriods') {
                        return gridRow.props.row.IsDeferrable;
                    }
                    if (field.FieldName === 'ManufacturerPartNumber') {
                        return !restrictNewItems;
                    }
                }

                return true;
            }.bind(this);

            var visualTab = this.props.quote.VisualQuotes && this.props.quote.VisualQuotes.firstOrNull((s) => String.ciEquals(s.TabName, quoteTab.TabName));
            var gridLoader = {};
            var gridLoadCallback = function (gridLoader, grid) {
                gridLoader.grid = grid;
            }.bind(this, gridLoader);

            var panel;
            if (quoteTab.TabName === 'Term Options') {
                // TODO term options!
                panel = <TermOptionsTab editable={isEditable} tab={quoteTab} contentGrid={this} />;
            } else if (visualTab) {
                panel = <VisualTab quote={this.props.quote} tab={quoteTab} visualTab={visualTab} contentGrid={this}
                                   controls={this.props.quote.VisualControls.where((s) => s.IdVisualTab == visualTab.IdVisualTab)}
                                   hotSpots={this.props.quote.VisualHotSpots.where((s) => s.IdVisualTab == visualTab.IdVisualTab)} />;
            } else {
                var useFixedWidths = true;
                // TODO: this is for regular tabs only. E.g. term options and visual quotes need special tabs.
                var grid = <DataGrid ref={'dataGrid_' + quoteTab.IdQuoteTabs} name={'QuoteContent'}
                                     key={'QuoteContent' + t}
                                     quoteTab={quoteTab}
                                     editable={isEditable} editMode={isEditable} fieldEditable={isFieldEditable}
                                     selectable={isEditable}
                                     contentGrid={this} rowMenu={rowMenu} headers={layout.Columns} rows={items}
                                     configuration={gridConfiguration}
                                     aggregateSummarizer={this.getCustomAggregate} customizable={isEditable}
                                     nonFormattedFields={QuoteContentGrid.nonFormattedFields} sortable={isEditable}
                                     headersSortable={true}
                                     alternateRowColor={false} onChange={this.fieldChanged} onSort={this.rowSorted}
                                     customColumns={customColumns} rowCreated={this.rowCreated}
                                     customizeCell={this.customizeCell}
                                     customizeRow={customizeRow} customizeFooter={customizeFooter}
                                     onLoad={gridLoadCallback} onUpdate={gridLoadCallback}
                                     useFixedWidths={useFixedWidths} />;

                if (quoteTab.HasSpreadsheet && !app.currentUser.IsReadOnly) {
                    panel = (
                        <div>
                            <TabSpreadsheetControl launchSpreadsheet={this.launchSpreadsheet} tab={quoteTab} grid={grid} contentGrid={this} />
                            {grid}
                        </div>
                    )
                } else {
                    panel = grid;
                }
            }

            var tabStyle = {};
            var labelStyle = {};
            var preLabel = [];

            if (quoteTab.IsOptional)
                labelStyle.fontStyle = 'italic';
            else
                labelStyle.fontWeight = 'bold';

            if (tabGroupColors[quoteTab.TabGroup]) {
                //tabStyle.backgroundColor = tabGroupColors[quoteTab.TabGroup]; //colors the tab button
                var quantityMasterLabel = quoteTab.IsGroupQuantityMaster ? ' (Qty master)' : ''
                var tabGroupLabel = quosal.util.trunc(quoteTab.TabGroup + quantityMasterLabel, 21);
                var tabGroupTitle = 'Tab Group: ' + quoteTab.TabGroup + quantityMasterLabel;
                preLabel.push(<div key="groupName" title={tabGroupTitle} className="grouplabel"
                                   style={{ color: tabGroupColors[quoteTab.TabGroup] }}>{tabGroupLabel}</div>);
            }
            if (optionGroupColors[quoteTab.OrderPorterGroup]) {
                tabStyle.boxShadow = '0 -10px ' + optionGroupColors[quoteTab.OrderPorterGroup];
                preLabel.push(<div key="optionName" title={'OrderPorter Group: ' + quoteTab.OrderPorterGroup}
                                   className="optiongrouplabel">{quosal.util.trunc(quoteTab.OrderPorterGroup, 24)}</div>);
            }

            tabs.push({
                tabId: quoteTab.IdQuoteTabs,
                tabName: quoteTab.TabName,
                item: quoteTab,
                menu: tabMenu,
                panel: panel,
                index: quoteTab.TabNumber,
                tabStyle: tabStyle,
                labelStyle: labelStyle,
                preLabel: preLabel,
                gridLoader: gridLoader,
                uiIndex: tabs.length
            });

            if (quoteTab.TabNumber > lastTabNumber)
                lastTabNumber = quoteTab.TabNumber;
        }

        if (!this.props.quote.IsLocked && !app.currentUser.IsReadOnly) {
            //newtab tab
            tabs.push({
                tabId: 'newtab',
                tabContent: <div className="icons-action add color" />,
                sortable: false,
                onClick: this.tabCreated,
                index: lastTabNumber + 1
            });
        }
        this.props.module.setState({ selectedTab: selectedTab.IdQuoteTabs });

        var isEditable = this.isContentGridToolbarMenuEditable(selectedTab)

        $.widget("ui.tabs", $.ui.tabs, {
            _tabKeydown: function (event) {
                //This prevents tabs from taking over keyboard events, causing tab rename to function improperly
                //Project Issue Ticket #8016059 - Not able to type space when renaming tab
            }
        });

        var tabOptions = {
            selectedTabId: selectedTabId,
            beforeActivate: function (tabControl, e, ui) {
                var target = e.currentTarget ? e.currentTarget : e.target ? e.target : null;
                if (target && target.hash == '#newtab') {
                    var me = this;
                    e.preventDefault();
                    e.stopImmediatePropagation();

                    var goToTab = function (tabName) {
                        var quoteNavigationModuleIndex = -1;
                        var selectedTabId = "";
                        var subModule = null;
                        if (app && app.currentModule && app.currentModule.Type == "QuoteDashboard") {
                            quoteNavigationModuleIndex = quosal.util.findWithAttr(app.currentModule.subModules, "Type", "QuoteNavigation");
                            subModule = app.currentModule.subModules[quoteNavigationModuleIndex];
                        }
                        if (tabName) {
                            var index = quosal.util.lastIndexOf(me.refs.tabControl.props.tabs, "tabName", tabName);
                            var tab = me.refs.tabControl.props.tabs[index];
                            selectedTabId = tab.tabId;
                        } else if (quoteNavigationModuleIndex > -1) {
                            selectedTabId = app.currentQuote.Tabs[app.currentQuote.Tabs.length - 1].IdQuoteTabs;
                        }

                        if (subModule && subModule.state && subModule.state.selectedTab) {
                            subModule.state.selectedTab = selectedTabId
                        }

                        var tabsSelected = me.refs.tabControl.state.tabsSelected;
                        tabsSelected[selectedTabId] = selectedTabId;
                        me.refs.tabControl.setState({ tabsSelected: tabsSelected })
                        me.refs.tabControl.fixTabs();

                        Dialog.closeAll();
                    }
                    Dialog.open({
                        height: '82%',
                        width: "92%",
                        title: 'Choose Tab Template',
                        message: <PickTabTemplate goToTab={goToTab} />,
                        closeRequiresButton: true,
                        links: [{
                            title: 'Finished',
                            callback: goToTab
                        }]
                    });
                    //this.tabCreated(tabControl); //Could add this back to allow for creation of an 'empty' tab
                    return true;
                } else {
                    var selected = tabControl.getSelectedTab();
                    this.props.module.setState({ selectedTab: selected.tabId });
                    if(this.refs.contentGridToolbar) {
                        this.refs.contentGridToolbar.state.isEditable = this.isContentGridToolbarMenuEditable(selected.item);
                        this.refs.contentGridToolbar.state.restrictNewItems = this.shouldRestrictNewItems(selected.item);
                        this.refs.contentGridToolbar.forceUpdate();
                    }
                    return true;
                }
            }.bind(this),
            activate: function (e, ui) {
                //$.quosal.ui.datagrids.calculateTitleWidths();
                //$.liqf.init($(ui.tab).attr('href'));
                //$(document.activeElement).blur(); //FA - This is mainly because IE's selected element is bright blue, and it's unsightly for it to happen automatically
            }.bind(this)
        };

        var getCopyTemplateAsNewVersionUrl = function () {
            return function () {
                app.currentModule.loadSubModule('quote.picktemplate', {
                    query: 'versionidquotemain=' + app.currentQuote.IdQuoteMain,
                    container: 'quoteModule',
                });
            }
        }

        var massDeleteDialog = function () {
            Dialog.open({
                title: 'Delete Tabs',
                message: <MassTabDelete rerenderContentGrid={this.rerenderContentGrid} />,
                messageProps: { style: { overflow: 'visible', display: 'flex' } },
                closeRequiresButton: true,
            });
        }.bind(this);

        var unableMassDeleteDialog = function () {
            Dialog.open({
                title: 'Unable to Delete Tabs',
                message: <div> You can not delete the last tab on this quote. </div>,
                messageProps: { style: { overflow: 'visible', display: 'flex' } },
                links: [Dialog.links.ok]
            });
        }.bind(this);

        var canCopyQuote = quosal.util.userIsAdminOrMaintainer() || app.currentUser.IsStandardPlus || !quosal.settings.getValue('DisableCopyQuote');
        var isQuoteArchived = app.currentQuote.QuoteStatus == 'Archived';

        var quoteActions = [];
        if (app.settings.global.UseAsioTaxService && this.props.quote.QuoteStatus != 'Won') {
            quoteActions.push({
                id: 1,
                name: 'Calculate Tax',
                callback: this.calculateTaxAsio
            });
        }
        if (app.currentUser.IsAdministrator && !isQuoteArchived) {
            quoteActions.push({
                id: 2,
                name: 'Configure Price Sources',
                url: quosal.util.url('adminrealtime.quosalweb', 'quotereturnurl=' + encodeURIComponent(location.href))
            });
        }

        if (app.currentQuote.UseStandardPublishOutput && !(app.currentQuote.QuoteStatus == 'Won' && app.currentQuote.DocusignEnvelopeId && app.currentQuote.DeliverDocumentType == 'DOCUSIGN')) {
            quoteActions.push({
                id: 3,
                name: 'Preview PDF',
                url: quosal.util.url('previewpdf.quosalweb')+"&_suid=1",
                target: "_blank"
            });
        }

        if (app.currentUser.IsQuosalAdmin) {
            quoteActions.push({
                id: 4,
                name: 'Preview Order Porter',
                url: quosal.util.url('previeworderporter.quosalweb'),
                target: "_blank"
            });
        }

        if (!app.currentUser.IsReadOnly) {
            if (!isQuoteArchived) {
                quoteActions.push({
                    id: 5,
                    name: 'Export to Excel ',
                    url: quosal.util.url('downloadexcel.quosalweb'),
                });

                quoteActions.push({
                    id: 6,
                    name: 'Export to CSV ',
                    url: quosal.util.url('pickcsv.quosalweb')
                });
                quosal.util.isNewEditorEnabled() && quoteActions.push({
                    id: 12,
                    name: 'Mass Delete Tabs ',
                    callback: quosal.util.getVisibleTabs().length > 1 ? 
                        massDeleteDialog : 
                        unableMassDeleteDialog,
                });
                quosal.util.isNewEditorEnabled() && app.settings.global?.EnableTabRestore && quoteActions.push({ id: 13, name: 'Restore Deleted Tab', callback: this.restoreDeletedTab });
            }
        }

        if (!this.props.quote.IsLocked && !app.currentUser.IsReadOnly) { // instead of hiding when locked, we should set it to disabled, but CwWidgets.CwMenuButton currently doesn't support disabling drop down items
            if (canCopyQuote) {
                quoteActions.push({
                    id: 7,
                    name: 'Copy Tabs from Another Quote ',
                    url: function () {
                        quosal.sell.modules.find("quote.copyitem").Name = "Copy Tabs";
                        app.currentModule.loadSubModule('quote.copyitem', {
                            container: 'quoteModule',
                            query: 'copytabs=true'
                        });
                    }
                });
                quoteActions.push({
                    id: 8,
                    name: 'Copy Items from Another Quote ',
                    url: function () {
                        quosal.sell.modules.find("quote.copyitem").Name = "Copy Items";
                        app.currentModule.loadSubModule('quote.copyitem', {
                            container: 'quoteModule',
                            query: 'copyitems=true'
                        });
                    }
                });
            }
                       
            quoteActions.push({ id: 9, name: 'Copy Template as New Version ', url: getCopyTemplateAsNewVersionUrl() });
            quoteActions.push({ id: 10, name: 'Remove 0 Qty Items ', callback: this.removeZeroQuantityItems });
            { this.isUACAvailable() && quoteActions.push({ id: 11, name: 'Update All Costs', url: quosal.util.url('updatecosts.quosalweb') }) };
        }
        quoteActions = quoteActions.sort((a, b) => a.id - b.id);
        let titleChildren;

        const dark = {
            palette: {
                mode: "dark",
                icon: {
                    primary: "#FAFAFA",
                },
            },
        };

        const light = {
            palette: {
                mode: "light",
                icon: {
                    primary: "#2E3F80",
                },
            },
        };

        if (quosal.util.isNewEditorEnabled()) {
            let quoteSelectedTab;
            app.currentQuote.Tabs.map(tab => {
                if (tab.IdQuoteTabs == this.state.cKEditorSelectedTabId) {
                    quoteSelectedTab = tab;
                }
            });

            var applicationMode = $(location).attr('pathname');
            applicationMode.indexOf(1);
            applicationMode.toLowerCase();
            applicationMode = applicationMode.split("/")[1];

            const isEditable = this.isContentGridToolbarMenuEditable(quoteSelectedTab);
            const isStandardUser = !app.currentUser.IsContentMaintainer && !app.currentUser.IsAdministrator && !app.currentUser.IsStandardPlus;
            const isStandardUserAndProtectedTab = isStandardUser && quoteSelectedTab?.IsProtectedTab;
            const isHistoricalQuote = this.props.quote.IsArchive && !this.props.quote.IsTemplate;
            const isLockedDownQuote = this.props.quote.IsLocked && !this.props.quote.IsArchive && !this.props.quote.IsTemplate;
            const isArchivedQuote = isLockedDownQuote && this.props.quote.QuoteStatus == 'Archived';
            const isPendingApprovalQuote = isLockedDownQuote && this.props.quote.ApprovalStatus == 'Request Approval';
            const disabledElementStyle = isStandardUserAndProtectedTab || isHistoricalQuote || isArchivedQuote || isPendingApprovalQuote || !isEditable
                ? { pointerEvents: 'none', opacity: 0.3, color: '#C0C0C0' }
                : {};
            const gridToolbarStyle = { paddingTop: '23px', paddingLeft: '24px', display: "flex", ...disabledElementStyle };
            const isStandardUserAndDisableNewProductCreation = isStandardUser && quoteSelectedTab?.HasLimitOnNewItems;
            const disableNewProductCreationStyle = isStandardUserAndDisableNewProductCreation ? { pointerEvents: 'none', opacity: 0.38 } :  {}
            const isLockStandardUsersOutOfItemEdit = quosal.settings.getValue('LockStandardUsersOutOfItemEdit');
            const isStandardUserAndLockItemEdit = isStandardUser && isLockStandardUsersOutOfItemEdit;
            const disableStandardUserAndLockStyle = isStandardUserAndLockItemEdit ? { pointerEvents: 'none', opacity: 0.38 } :  {}

            titleChildren = (
                <ThemeProvider theme={this.state.isDarkMode ? createTheme(dark) : createTheme(light)}>
                    <div className='toolbar prepareContentToolbar'>
                        <Grid justifyContent="center" container spacing={4} wrap="nowrap" className="toolbarGrid">
                            <Grid item>
                                <ContentGridActionButtons
                                    isEditable={isEditable}
                                    quoteActions={quoteActions}
                                    restrictNewItems={restrictNewItems} contentGrid={this}
                                    applicationMode={applicationMode}
                                    isStandardUserAndProtectedTab={isStandardUserAndProtectedTab}
                                    disabledElementStyle={disabledElementStyle}
                                    disableNewProductCreationStyle={disableNewProductCreationStyle}
                                    isStandardUserAndDisableNewProductCreation={isStandardUserAndDisableNewProductCreation}
                                    isStandardUserAndLockItemEdit={isStandardUserAndLockItemEdit}                                                
                                    disableStandardUserAndLockStyle={disableStandardUserAndLockStyle}
                                    isHistoricalQuote={isHistoricalQuote}
                                    isArchivedQuote={isArchivedQuote}
                                    isPendingApprovalQuote={isPendingApprovalQuote}
                                    handleChangeAfterAction={this.handleChangeAfterAction.bind(this)}
                                    ckeditor={this.state.ckeditor}
                                    selectedTab={quoteSelectedTab}
                                    />
                            </Grid>
                            <Grid item>
                                <Grid item container
                                    spacing={3}
                                    justifyContent="center"
                                    wrap="nowrap"
                                    className="toolbarGrid">
                                    {!app.currentQuote.IsLocked && !app.currentUser.IsReadOnly 
                                        ? <>
                                            <ScriptContextMenu contentGrid={this} quosal={quosal} gridToolbarStyle={{ ...gridToolbarStyle, paddingLeft: '0px'}} />
                                            <PunchoutSitesContextMenu isEditable={isEditable} contentGrid={this} quosal={quosal} gridToolbarStyle={gridToolbarStyle} />
                                            <GreatAmericaContextMenu isEditable={isEditable} contentGrid={this} quosal={quosal} gridToolbarStyle={gridToolbarStyle} />
                                            <ArrowContextMenu isEditable={isEditable} contentGrid={this} quosal={quosal} gridToolbarStyle={gridToolbarStyle} />
                                            <ImportContextMenu isEditable={isEditable} contentGrid={this} quosal={quosal} gridToolbarStyle={gridToolbarStyle} />
                                            </>
                                    : null}
                                    <MoreContextMenu
                                        quosal={quosal}
                                        actions={quoteActions}
                                        handleChangeAfterAction={this.handleChangeAfterAction.bind(this)}
                                        isArchivedQuote={isArchivedQuote}/>
                                    <QuoteTotalWarning />
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </ThemeProvider>
            );
        }
        else {
            titleChildren = (
                <ContentGridToolbar ref="contentGridToolbar" isEditable={isEditable} quoteActions={quoteActions} quote={this.props.quote}
                                    restrictNewItems={restrictNewItems}
                                    contentGrid={this}
                                    style={{ fontSize: '12px' }} />
            );
        }

        let mainPanel = null;

        if (quosal.util.isNewEditorEnabled()) {
            mainPanel = (<Panel ref="panel" id="prepareContentPanel"
                className={this.state.isDarkMode ? 'overflow darkMode' : 'overflow'} title={' '} titleChildren={titleChildren} rightContentExpanded={this.state.rightContentExpanded}>
                <PanelContent>
                    <ThemeProvider theme={this.state.isDarkMode ? createTheme(dark) : createTheme(light)}>
                        <CPQEditorComponent
                            ckEditorRenderKey={this.state.ckEditorRenderKey}
                            handleSetCKEditor={this.handleSetCKEditor.bind(this)}
                            handleCKEditorSelectedTabId={this.handleCKEditorSelectedTabId.bind(this)}
                            buildNotesDialog={this.buildNotesDialog.bind(this)}
                            contentGrid={this}
                            rowActions={rowActions} />
                    </ThemeProvider>
                </PanelContent>
            </Panel>);
        }
        else {
            mainPanel = (<Panel ref="panel" id="prepareContentPanel"
                className={'overflow'} title={' '} titleChildren={titleChildren} allowFullscreen={true} onFullscreenToggle={this.onFullscreenToggle}>
                <PanelContent>
                    <TabControl ref="tabControl" tabs={tabs} appModule={this.props.module} options={tabOptions} onSort={this.tabSorted}
                        numberTabs={true} />
                </PanelContent>
            </Panel>);
        }

        return mainPanel;
    }
}
global.QuoteContentGrid = QuoteContentGrid;

QuoteContentGrid.nonFormattedFields = ['GrossMargin', 'Markup', 'Quantity', 'ItemHeight', 'OnHand', 'OnHandWarehouse1', 'OnHandWarehouse2'
    , 'PackageQty', 'ItemLength', 'ItemWidth', 'ItemCube', 'UomWeight', 'TotalWeight', 'Z_customDecimal1', 'Z_customDecimal2'
    , 'Z_customDecimal3', 'Z_customDecimal4', 'Z_customDecimal5', 'Z_customDecimal6', 'Z_customDecimal7', 'Z_customDecimal8'
    , 'Z_customDecimal9', 'Z_customDecimal10'];
QuoteContentGrid.tabAndItemMenusByTabId = {};

class ActionsMenuProxy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        if (QuoteContentGrid.tabAndItemMenusByTabId[this.props.tabId]) {
            var actionMenu = QuoteContentGrid.tabAndItemMenusByTabId[this.props.tabId][this.props.menuType];

            if (actionMenu) {
                actionMenu.toggleDropdown({ proxyButton: this.refs.button });
            }
        }
    }
    render() {
        var className = "";
        if (this.props.className) {
            className += ' ' + this.props.className;
        }
        return <div ref="button" style={{ display: 'inline-block' }} {...this.props} className={className}>
            <CwWidgets.CwButton value={this.props.label} toolTip={this.props.title} onClick={this.handleClick}
                                icon={this.props.icon} rightIcon={this.props.rightIcon} /></div>;
    }
}
global.ActionsMenuProxy = ActionsMenuProxy;

export class ContentGridToolbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditable: this.props.isEditable,
            restrictNewItems: this.props.restrictNewItems,
        };
        this.moreSelectionChanged = this.moreSelectionChanged.bind(this);
    }
    moreSelectionChanged(selectedItem) {
        if (selectedItem.callback) {
            selectedItem.callback(this.props.menu, selectedItem);
        }
        else if (selectedItem.url) {
            var state = { rand: quosal.util.generateGuid() };
            if (selectedItem.target)
                state.target = selectedItem.target;
            if (selectedItem.bypassClientNav)
                state.bypassClientNav = selectedItem.bypassClientNav;
            quosal.navigation.navigate(selectedItem.url, state);
        }
    }
    render() {
        var isEditable = this.state.isEditable;
        var restrictNewItems = this.state.restrictNewItems;

      var productSearchLink = function () {
        var selectedTab = null;

        var tabs = this.props.contentGrid.refs.tabControl;
        var selectedTab = tabs.getSelectedTab().item;
        let insertBefore = this.props.contentGrid.getInsertPositionId();
        quosal.util.goToProductSearch(
            selectedTab.IdQuoteTabs,
            insertBefore
        );
      }.bind(this);

        var salesTracksSearchLink = function () {
            var tabs = this.props.contentGrid.refs.tabControl;
            var selectedTab = tabs.getSelectedTab().item;
            let insertBefore = this.props.contentGrid.getInsertPositionId();

            quosal.util.goToSalesTracksSearch(selectedTab.IdQuoteTabs, insertBefore);
        }.bind(this);

        var etilizeSearchLink = function () {
            var tabs = this.props.contentGrid.refs.tabControl;
            var selectedTab = tabs.getSelectedTab().item;

            quosal.util.goToEtilizeSearch(selectedTab.IdQuoteTabs);
        }.bind(this);

        var tabLink = function (href) {
            var url = quosal.util.url(href, 'idquotetabs=' + this.props.contentGrid.refs.tabControl.getSelectedTab().item.IdQuoteTabs);
            quosal.navigation.navigate(url);
        };

        const catalogProductConfiguratorLink = function(href) {
            quosal.navigation.productConfigurator(this.props.contentGrid.refs.tabControl.getSelectedTab().item.IdQuoteTabs, href);
        }

        var showPasteQuote = function () {
            Dialog.open({
                height: '82%',
                width: "92%",
                title: 'Paste Quote',
                message: React.createElement(PasteExcel, {
                    currentTab: this.props.contentGrid.refs.tabControl.getSelectedTab().item.IdQuoteTabs,
                    currentQuote: app.currentQuote.IdQuoteMain,
                    updateContentGrid: this.props.contentGrid.refs.tabControl.fixTabs,
                }),
                messageProps: { style: { overflow: 'visible', display: 'flex' } },
                closeRequiresButton: true,
            });
        }.bind(this);

        const isADIEnabled = function () {
            return quosal.settings.getValue('UseADIParser')
        };

        let openADIParser = function() {
            Dialog.open({
                height: '90%',
                width: "75%",
                title: 'Import ADI Quote',
                message: <ParserNav
                    currentTab={this.props.contentGrid.refs.tabControl.getSelectedTab().item.IdQuoteTabs} parser={"ADI"} />,
                messageProps: { style: { overflow: 'visible', display: 'flex', justifyContent: 'center' } },
                closeRequiresButton: true
            });
        }.bind(this);

        var isDellEnabled = function () {
            return quosal.settings.getValue('useDellPasteQuote') || quosal.settings.getValue('useDellPDFQuote')
        };

        var openDellParser = function () {
            Dialog.open({
                height: '90%',
                width: "75%",
                title: 'Import Dell Equote',
                message: <ParserNav
                    currentTab={this.props.contentGrid.refs.tabControl.getSelectedTab().item.IdQuoteTabs} parser={"Dell"} />,
                messageProps: { style: { overflow: 'visible', display: 'flex' } },
                closeRequiresButton: true,
                // links: [{
                //     title: 'Cancel',
                //     callback: Dialog.close
                // }]
            });
        }.bind(this);

        var submitCreditApplication = function (source) {
            var me = this;
            var creditApplicationSource = source;
            var doSubmit = function () {
                var submitCreditApplicationApi;

                switch (creditApplicationSource) {
                    case "GreatAmerica":
                        submitCreditApplicationApi = quosal.api.quote.submitGreatAmericaCreditApplication(me.props.quote.IdQuoteMain);
                        break;
                }

                submitCreditApplicationApi.finished = function (msg) {
                    Dialog.close({ dialogId: creditApplicationSource + 'CreditApplicationId', skipAnimation: true });            
                    if (msg.error) {            
                        Dialog.open({
                            title: 'Error Submitting Credit Application',
                            message: msg.error,
                            links: [{ title: 'OK', callback: Dialog.closeAll }]
                        })
                    }
                    else {
                        quosal.sell.quote.updateFromApiResponse(msg);
                        Dialog.open({
                            title: 'Credit Application Submitted',
                            message: "Credit Application submitted successfully",
                            links: [{ title: 'OK', callback: Dialog.closeAll }]
                        })
                    }
                }
                submitCreditApplicationApi.call();
            }

            Dialog.open({
                dialogId: creditApplicationSource + 'CreditApplicationId',
                title: 'Submitting Credit Application to ' + creditApplicationSource,
                message: 'Are you sure you would like to submit a credit application?',
                closeRequiresButton: true,
                titleId: 'submit' + creditApplicationSource +'CreditApplicationId',
                links: [
                    {
                        title: 'Yes',
                        callback: function () {
                            Dialog.setIsWorking(true);
                            doSubmit();
                        }
                    },
                    {
                        title: 'No',
                        callback: function () {
                            Dialog.close();
                        }
                    }
                ]
            })
        }.bind(this);

        //punchout sites
        var punchoutButton = null;
        if (quosal.settings.getValue('enblePunchoutIntegration') && quosal.settings.getValue('punchoutSites')) {
            var sites = quosal.settings.getValue('punchoutSites');

            var createPunchoutUrl = function (site) {
                var selectedTab = this.props.contentGrid.refs.tabControl.getSelectedTab();
                var url = quosal.util.url('createpunchout.quosalweb', 'requestid=' + quosal.util.generateGuid(), 'punchout=' + site.IdcxmlPunchoutSites, 'idquotetabs=' + selectedTab.item.IdQuoteTabs);
                var punchoutWindow = window.open(url, '_blank');
                Dialog.open({
                    title: `Accessing ${site.Name} Punchout`,
                    closeRequiresButton: true,
                    width: "75%",
                    message: `You will now be redirected to ${site.Name} punchout in a new browser tab.
                    Navigating away from this page will invalidate the current Punchout session.
                    Pressing Cancel Punchout will cancel your current Punchout session and allow you to edit your quote.`,
                    links:[
                        {title:'Cancel Punchout', callback: function () {
                                punchoutWindow.close();
                                Dialog.close()
                            }}
                    ]
                });
            };

            if (sites.length > 0) {
                var siteActions = [];
                for (var i = 0; i < sites.length; i++) {
                    siteActions.push({
                        id: i,
                        name: sites[i].Name,
                        callback: createPunchoutUrl.bind(this, sites[i])
                    });
                }

                punchoutButton =
                    <div style={{ display: 'inline-block' }}><CwWidgets.CwMenuButton value="PUNCHOUT SITES"
                                                                                     items={siteActions}
                                                                                     disabled={!isEditable}
                                                                                     onDropDownItemClick={this.moreSelectionChanged} />
                    </div>;
            }
        }

        // import buttons
        var importActions = [];

        if (quosal.validation.isPackageLevelAuthorized(app.packageLevels.standard)
            && quosal.settings.getValue('EnableIngramMustangQuoteImport') && isEditable) {
            var ingramMicroMustangQuoteImportClick = function () {
                var ingramAppId = quosal.settings.getValue('IngramMustangApplicationID');
                var ingramSecretKey = quosal.settings.getValue('IngramMustangSecretKey');
                if (ingramAppId && ingramSecretKey) {
                    quosal.util.goToIngramQuoteSearch(
                        this.props.contentGrid.refs.tabControl.getSelectedTab().item.IdQuoteTabs);
                } else if (app.currentUser.IsAdministrator) {
                    Dialog.open({
                        title: 'Import Ingram Quote',
                        message: <IngramQuoteImportSetupPrompt />
                    });
                } else {
                    Dialog.open({
                        title: 'Import Ingram Quote',
                        message: 'Please contact your administrator to set up your Ingram Authentication.',
                        links: [Dialog.links.close]
                    })
                }
            }.bind(this);
            importActions.push({
                id: 2,
                name: "Import Ingram Quote",
                callback: ingramMicroMustangQuoteImportClick
            });
        }
        if (isADIEnabled()) {
            importActions.push({ id: 3, name: "Import ADI Quote", callback: openADIParser });
        }
        if (isDellEnabled()) {
            importActions.push({ id: 4, name: "Import Dell Quote", callback: openDellParser }); // should be disabled when {!isEditable}
        }
        if (quosal.settings.getValue('useiQuote') && isEditable) {
            importActions.push({
                id: 5,
                name: "Import HP iQuote",
                callback: tabLink.bind(this, 'importiquote.quosalweb')
            }); // should be disabled when {!isEditable}
        }
        if (quosal.settings.getValue('UseTechDataQuotes') && isEditable) {
            importActions.push({
                id: 6,
                name: "Import TD Synnex Quote",
                callback: tabLink.bind(this, 'importtdsynnexquote.quosalweb')
            }); // should be disabled when {!isEditable}
        }
        if (quosal.settings.getValue('useAvalara') && isEditable) {
            importActions.push({ id: 7, name: "Import AvaTax", callback: tabLink.bind(this, 'avatax.quosalweb') }); // should be disabled when {!isEditable}
        }
        if (quosal.settings.getValue('UseTechDataUKQuotes') && isEditable) {
            importActions.push({
                id: 8,
                name: "Import Tech Data Quote",
                callback: tabLink.bind(this, 'importtechdataquote.quosalweb')
            }); // should be disabled when {!isEditable}
        }

        //GreatAmerica
        var greatAmericaActions = [];
        var useGreatAmericaRest = quosal.settings.getValue('useGreatAmericaRestIntegration');

        if (quosal.settings.getValue('useGreatAmerica') && isEditable) {
            var gaDefaultId = this.props.contentGrid.props.quote.GreatAmericaDefault || quosal.settings.getValue('greatAmericaDefaultId');

            if ((!gaDefaultId || quosal.settings.getValue('greatAmericaShowTwoButtons') && isEditable)) {  // should be disabled when !isEditable but CwWidgets.CwMenuButton currently doesn't support disabling drop down items                
                if (useGreatAmericaRest) {
                    greatAmericaActions.push({
                        id: 1,
                        name: "Import GreatAmerica",
                        callback: tabLink.bind(this, 'importgreatamerica.quosalweb')
                    });
                }
                else {
                    importActions.push({
                        id: 9,
                        name: "Import GreatAmerica",
                        callback: tabLink.bind(this, 'importgreatamerica.quosalweb')
                    });
                }
            }

            if (gaDefaultId && isEditable) {  // should be disabled when !isEditable but CwWidgets.CwMenuButton currently doesn't support disabling drop down items
                if (useGreatAmericaRest) {
                    greatAmericaActions.push({
                        id: 2,
                        name: "Quick Import GreatAmerica Using Defaults",
                        callback: tabLink.bind(this, 'quickgreatamerica.quosalweb?idGreatAmericaDefaultSet=' + gaDefaultId)
                    });
                }
                else {
                    importActions.push({
                        id: 10,
                        name: "Quick Import GreatAmerica Using Defaults",
                        callback: tabLink.bind(this, 'quickgreatamerica.quosalweb?idGreatAmericaDefaultSet=' + gaDefaultId)
                    });
                }
            }

            var creditApplicationUsers = quosal.settings.getValue('greatAmericaCreditApplicationUsers');
            if (useGreatAmericaRest && quosal.settings.getValue('GreatAmericaLeasingCanSubmitCreditApplication') && creditApplicationUsers.includes(app.currentUser.IdUsers)) {
                greatAmericaActions.push({
                    id: 3,
                    name: "Submit Credit Application",
                    callback: function() {
                        submitCreditApplication('GreatAmerica');
                    }
                })
            }
        }

        //Arrow
        let arrowActions = [];
        if (quosal.settings.getValue('useArrow') && isEditable) {
            if (quosal.settings.getValue('useArrowImport')) {
                var arrowQuoteImportClick = function () {
                    quosal.util.goToArrowQuoteSearch(
                        this.props.contentGrid.refs.tabControl.getSelectedTab().item.IdQuoteTabs);
                }.bind(this);
                arrowActions.push({
                    id: 1,
                    name: "Import Arrow Quote",
                    callback: arrowQuoteImportClick
                });
            }

            if (quosal.settings.getValue('useArrowACS')) {  
                var arrowTermImportClick = function () {
                    quosal.util.goToArrowTermImport(
                        this.props.contentGrid.refs.tabControl.getSelectedTab().item.IdQuoteTabs);
                }.bind(this);

                arrowActions.push({
                    id: 2,
                    name: "Import Financing Offers",
                    callback: arrowTermImportClick
                });
            }
        }
        
        //on demand scripts
        var scriptsButton = null;

        if (this.state.scriptRunning) {
            scriptsButton = <Spinner title={'Running Script: ' + this.state.scriptRunning} />;
        } else {
            var scripts = quosal.sell.scripts.getScripts('OnDemand');
            if (scripts && scripts.length > 0) {
                var runScript = function (script) {
                    var selectedTab = this.props.contentGrid.refs.tabControl.getSelectedTab();
                    var idQuoteItems;
                    var grid = selectedTab.gridLoader.grid;

                    if (grid) {
                        var selectedRows = Object.keys(grid.state.isRowSelected);
                        if (selectedRows.length > 0) {
                            var currentItem = grid.props.rows[selectedRows[0]];

                            if (currentItem)
                                idQuoteItems = currentItem.IdQuoteItems;
                        }
                    }

                    var runApi = quosal.api.script.runScript(script.ScriptType, script.IdQuosalSellScript, this.props.contentGrid.props.quote.IdQuoteMain, selectedTab.item.IdQuoteTabs, idQuoteItems);
                    runApi.finished = function (msg) {
                        if (!String.isNullOrEmpty(msg.scriptLog)) {
                            Dialog.open({
                                draggable: true,
                                resizable: true,
                                title: 'Script Results',
                                //message: msg.scriptLog,
                                //Is the below line safe? this could in theory allow a script author to execute html (and possibly javascript) from this dialog - TJ 8/18/16
                                message: React.createElement('div', { dangerouslySetInnerHTML: { __html: msg.scriptLog.replace('\r\n', '<br />') } }),
                                links: [Dialog.links.ok]
                            });
                        }

                        if (!String.isNullOrEmpty(msg.launchURL)) {

                            var win = window.open(msg.launchURL, msg.urlTarget);

                            if (!win)
                                alert('This function requires popups to be enabled.');
                        }

                        if (msg.quote) {
                            this.state.scriptRunning = null;
                            quosal.sell.quote.update(msg.quote);
                        } else {
                            this.setState({ scriptRunning: null });
                        }
                    }.bind(this);
                    runApi.call();

                    this.setState({ scriptRunning: script.Name });
                }.bind(this);

                if (scripts.length > 1) {
                    var scriptActions = [];
                    for (var i = 0; i < scripts.length; i++) {
                        // ER: Old style drop down
                        //scriptActions.push({
                        //    title: 'Run Script: ' + scripts[i].Name,
                        //    callback: runScript.bind(this, scripts[i])
                        //});
                        scriptActions.push({
                            id: i,
                            name: 'Run Script: ' + scripts[i].Name,
                            callback: runScript.bind(this, scripts[i])
                        });
                    }

                    if (isEditable) {
                        scriptsButton =
                            <div style={{ display: 'inline-block' }}><CwWidgets.CwMenuButton value="AVAILABLE SCRIPTS"
                                                                                             items={scriptActions}
                                                                                             onDropDownItemClick={this.moreSelectionChanged} />
                            </div>;
                        // ER: old style dropdown
                        //    scriptsButton = <ActionsMenu value="AVAILABLE SCRIPTS" actions={scriptActions} cwMenu="true" />;
                    }
                } else if (isEditable) {
                    scriptsButton =
                        <CwWidgets.CwButton icon="img/svgs/v1.0/Object_Scripts.svg"
                                            toolTip={'Run Script: ' + scripts[0].Name}
                                            onClick={runScript.bind(this, scripts[0])} />;
                }
            }
        }
        // ER: old style dropdown
        //var moreButton = <ActionsMenu  value="MORE" actions={this.props.quoteActions} cwMenu="true" />;
        //var importButton = importActions && importActions.length > 0 ? <ActionsMenu value="IMPORT" actions={importActions} cwMenu="true" disabled={!isEditable}/> : null ;
        var moreButton = <div style={{ display: 'inline-block' }}><CwWidgets.CwMenuButton value="MORE"
                                                                                          items={this.props.quoteActions}
                                                                                          onDropDownItemClick={this.moreSelectionChanged} />
        </div>;
        var greatAmericaButton = greatAmericaActions && greatAmericaActions.length > 0 ?
            <div id='prepareContentGreatAmericaActionsDropdown' style={{ display: 'inline-block' }}><CwWidgets.CwMenuButton value='GREATAMERICA' items={greatAmericaActions}
                                                                                                                            onDropDownItemClick={this.moreSelectionChanged} />
            </div> : null;
        var arrowButton = arrowActions && arrowActions.length > 0 ?
            <div id='prepareContentArrowActionsDropdown' style={{ display: 'inline-block '}}><CwWidgets.CwMenuButton value='ARROW' items={arrowActions}
                onDropDownItemClick={this.moreSelectionChanged} />
            </div> : null;
        var importButton = importActions && importActions.length > 0 ?
            <div id="prepareContentImportActionsDropdown" style={{ display: 'inline-block' }}><CwWidgets.CwMenuButton value="IMPORT" disabled={!isEditable} items={importActions}
                                                                                                                      onDropDownItemClick={this.moreSelectionChanged} />
            </div> : null;

        var applicationMode = $(location).attr('pathname');
        applicationMode.indexOf(1);
        applicationMode.toLowerCase();
        applicationMode = applicationMode.split("/")[1];

        var children = (
            <span>
                <CwWidgets.CwButton toolTip="Create New Product" className="PrepareContentClass"
                                    onClick={this.props.contentGrid.createProduct.bind(this.props.contentGrid, null, null)}
                                    icon="img/svgs/v1.0/Action_AddNew.svg" disabled={!isEditable || restrictNewItems} />
                <CwWidgets.CwButton toolTip="Create New Comment Line" onClick={this.props.contentGrid.createComment}
                                    icon="img/svgs/v1.0/Action_Comment.svg" disabled={!isEditable} />
                <CwWidgets.CwButton toolTip="Product Search" onClick={productSearchLink} target="quoteModule"
                    icon="img/svgs/sell/Action_SearchProduct.svg" disabled={!isEditable} />
                {quosal.settings.getValue('enableGuidedSelling', false) && <CwWidgets.CwButton toolTip="Guided Selling" onClick={salesTracksSearchLink} target="quoteModule"
                    icon="img/svgs/sell/Guided_Selling_Icon.svg" disabled={!isEditable} />}
                {quosal.settings.getValue('showCatalogConfigurator', false) ?
                    <CwWidgets.CwButton toolTip="Product Configurator" icon="img/svgs/sell/Action_ConfigureProduct.svg"
                                        onClick={catalogProductConfiguratorLink.bind(this, quosal.settings.getValue('catalogServiceUrl') + '&currency=' + app.currentQuote.BaseCurrency + '&applicationMode=' + applicationMode)}
                                        bypassClientNav={true} disabled={!isEditable} /> : null}
                {!app.currentQuote.IsLocked && !app.currentUser.IsReadOnly && quosal.settings.getValue('usePasteExcel') ?
                    <CwWidgets.CwButton toolTip="Paste Quote" onClick={showPasteQuote}
                                        icon="img/svgs/v1.0/Action_Paste.svg" disabled={!isEditable || restrictNewItems}
                    /> : null}
                {quosal.settings.getValue('useEtilize') ?
                    <CwWidgets.CwButton toolTip="Etilize Search" value="ETILIZE SEARCH"
                                        onClick={etilizeSearchLink}
                                        disabled={!isEditable} /> : null}

                {!app.currentQuote.IsLocked && !app.currentUser.IsReadOnly ? <div style={{ display: 'inline-block' }}  >
                    {scriptsButton}
                    {punchoutButton}
                    {greatAmericaButton}
                    {arrowButton}
                    {importButton}
                </div> : null
                }
                {moreButton}
                <QuoteTotalWarning />
            </span>
        )

        var itemActionMenu = null,
            tabActionMenu = null,
            className = 'toolbar';

        return (
            <div className={className} style={this.props.style}>
                {children}
                {itemActionMenu}
                {tabActionMenu}
            </div>
        );
    }
}

class QuickAddFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mpn: null,
            sourceGroups: null,
            loadingPriceSource: false,
        };
        this.rowCreated = this.rowCreated.bind(this);
        this.performQuickAdd = this.performQuickAdd.bind(this);
        this.sourceGroupChanged = this.sourceGroupChanged.bind(this);
        this.mpnChanged = this.mpnChanged.bind(this);
        this.mpnKeyPressed = this.mpnKeyPressed.bind(this);
        this.quickAddOpenQuote = this.quickAddOpenQuote.bind(this);
    }
    rowCreated(grid, callback) {
        var newRow = {
            table: 'QuoteItems',
            id: quosal.util.generateGuid(),
            isUnsaved: true,
            IdQuoteMain: this.props.quote.IdQuoteMain,
            IdQuoteTabs: grid.props.quoteTab.IdQuoteTabs,
            ManufacturerPartNumber: 'NEW',
            Quantity: 1,
            SortOrder: grid.props.rows.length,
            IsTaxable: grid.props.quoteTab.IsTaxable
        };

        this.props.quote.Items.push(newRow);
        this.forceUpdate();

        var createApi = quosal.api.data.create(newRow, this.props.quote.IdQuoteMain);
        createApi.finished = function (msg) {
            this.props.contentGrid.preserveUnsavedRows(msg.quote, newRow);
            quosal.sell.quote.update(msg.quote);
        }.bind(this);

        createApi.call();
    }

    quickAddOpenQuote(idQuoteMain){
        var openQuoteApi = quosal.api.quote.openQuote(idQuoteMain);
        openQuoteApi.finished = function (msg) {
            if(this.props.contentGrid.itemsToQuickAdd.length == 0){
                if(this.props.contentGrid.props.manualSyncEnabled) {
                    msg.partialResponse = msg.partialResponse || {};
                    msg.partialResponse.newItems = msg.quote.Items;
                    app.currentQuote.Items = [];
                    msg.quote = null;
                    quosal.sell.quote.updateFromApiResponse(msg);
                    this.props.contentGrid.props.setNeedsManualSync(true);
                } else {
                    quosal.sell.quote.update(msg.quote);
                }
            }
        }.bind(this);
        openQuoteApi.call();
    }

    performQuickAdd() {
        var mpn = (this.state.mpn || '').trim();

        let insertPositionInfo = this.props.contentGrid.getInsertPositionId({returnIndexAndId: true});
        let insertIndex = insertPositionInfo ? insertPositionInfo.index : this.props.grid.props.rows.length;

        var newRow = {
            table: 'QuoteItems',
            id: quosal.util.generateGuid(),
            isUnsaved: true,
            IdQuoteMain: this.props.contentGrid.props.quote.IdQuoteMain,
            IdQuoteTabs: this.props.grid.props.quoteTab.IdQuoteTabs,
            ManufacturerPartNumber: mpn,
            Quantity: 1,
            IsTotalsIncluded: true,
            SortOrder: insertIndex,
            IsTaxable: this.props.grid.props.quoteTab.IsTaxable
        };

        this.props.contentGrid.getCurrentDataGrid().addRows([newRow], insertIndex);

        let insertBefore = insertPositionInfo && insertPositionInfo.id;

        var quickAdd = quosal.api.product.quickItemEntry(mpn,
            this.props.contentGrid.props.quote.IdQuoteMain,
            this.props.grid.props.quoteTab.IdQuoteTabs,
            this.props.grid.props.quoteTab.PrimarySourceGroup,
            insertBefore, true);

        quickAdd.newRow = newRow
        this.props.contentGrid.itemsToQuickAdd.push(quickAdd);

        quickAdd.finished = function (msg) {
            this.props.contentGrid.itemsToQuickAdd.find(x => {
                return x.opId == msg.opId
            }).isOpFinished = true;

            var isFinished = this.props.contentGrid.itemsToQuickAdd.every(x => {
                return x.isOpFinished == true;
            });

            if (isFinished) {
                    this.quickAddOpenQuote(this.props.contentGrid.props.quote.IdQuoteMain);
                this.props.contentGrid.itemsToQuickAdd = [];
            }
        }.bind(this);

        quickAdd.call();
        this.setState({ mpn: null });
    }
    sourceGroupChanged() {
        this.props.grid.props.quoteTab.PrimarySourceGroup = this.refs.sourceGroupSelector.value;

        var updateApi = quosal.api.data.update({
            fields: { PrimarySourceGroup: this.props.grid.props.quoteTab.PrimarySourceGroup },
            queries: [{
                table: 'QuoteTabs',
                where: [{
                    field: 'IdQuoteTabs',
                    operator: 'Equals',
                    value: this.props.grid.props.quoteTab.IdQuoteTabs
                }]
            }]
        }, this.props.contentGrid.props.quote.IdQuoteMain);
        updateApi.finished = function (msg) {
            this.setState({loadingPriceSource: false});
            quosal.sell.quote.updateFromApiResponse(msg);
            this.props.contentGrid.updateGridWithQuickAdd();
        }.bind(this);
        this.setState({loadingPriceSource: true});
        updateApi.call();

        this.forceUpdate();
    }
    mpnChanged(e) {
        this.setState({ mpn: e.target.value });
    }
    mpnKeyPressed(e) {
        if (e.which == 13 && !String.isNullOrEmpty(this.state.mpn)) {
            this.performQuickAdd();
        }
    }

    render() {
        var primaryGroup;
        var options = [];

        var groups = quosal.util.clone(quosal.sell.product.sourceGroups);
        if (groups && groups.length)
        {
            groups.sort(function(a, b) {
                var textA = a.GroupName ? $.trim(a.GroupName.toUpperCase()) : '';
                var textB = b.GroupName ? $.trim(b.GroupName.toUpperCase()) : '';
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        }
        for (var i = 0; i < groups.length; i++) {
            if (groups[i].IsPrimary) {
                primaryGroup = groups[i];
                options.unshift(
                    <option key={primaryGroup.IdProductSourceGroup}
                            value={primaryGroup.IdProductSourceGroup}>{primaryGroup.GroupName + ' (global default)'}</option>
                );
            } else {
                options.push(
                    <option key={groups[i].IdProductSourceGroup}
                            value={groups[i].IdProductSourceGroup}>
                        {groups[i].GroupName}</option>
                )
            }
        }

        if (primaryGroup) {
            options.splice(1, 0,
                <option key="allkey" value={'all'}>All Sources</option>
            );
            options.splice(2, 0,
                <option disabled={true} key="separator1key" className="separator">————————————————————</option>
            );
        } else {
            options.unshift(
                <option disabled={true} key="separator2key" className="separator">————————————————————</option>
            );
            options.unshift(
                <option key="all2Key" value={'all'}>All Sources (global default)</option>
            );
        }

        var sourceGroupLoading = !this.props.priceSourcesLoaded || this.state.loadingPriceSource;

        var addButton = null;
        if (sourceGroupLoading || String.isNullOrEmpty(this.state.mpn)) {
            var title =  sourceGroupLoading ?
                "Source groups must finish loading before new items can be added" :
                "Enter a Part Number to add a new product";

            addButton = <div className="toolbutton icons-action add nocolor disabled quickEntryAddBtn"
                             style={{ margin: 10, verticalAlign: 'middle' }}
                             title={title} />;
        } else {
            addButton = <div className="toolbutton icons-action add color quickEntryAddBtn"
                             style={{ margin: 10, cursor: 'pointer', verticalAlign: 'middle' }} title="Add Part"
                             onClick={this.performQuickAdd} />;
        }
        return (
            <div ref="root">
                {
                    addButton
                }
                <input ref="mpn" type="text" style={{ width: 150, verticalAlign: 'middle' }}
                       value={this.state.mpn === null ? "" : this.state.mpn} disabled={sourceGroupLoading ? "disabled" : ""}
                       onChange={this.mpnChanged}
                       onKeyDown={this.mpnKeyPressed} />
                {
                    quosal.settings.getValue('enableQuickAddSourcing') ? (
                        <span style={{ margin: 10, verticalAlign: 'middle' }}>Add Part # from</span>
                    ) : (
                        <span style={{ margin: 10, verticalAlign: 'middle' }}>Add Part #</span>
                    )
                }
                {
                    quosal.settings.getValue('enableQuickAddSourcing') ? (
                        <div style={{ display: 'inline-block', whitespace: 'nowrap', verticalAlign: 'middle' }}>
                            {sourceGroupLoading ? <Spinner></Spinner> :
                                <select ref="sourceGroupSelector" value={this.props.grid.props.quoteTab.PrimarySourceGroup}
                                        onChange={this.sourceGroupChanged} title="Tab Source Group">
                                    {options}
                                </select>
                            }
                        </div>
                    ) : (
                        ''
                    )
                }
                {
                    //sp 3/12/18 9880756: Make Source Groups a STANDARD feature
                    (app.currentUser.IsAdministrator && quosal.validation.isPackageLevelAuthorized(app.packageLevels.standard)) ? (
                        <a href={quosal.util.url('adminsourcegroups.quosalweb', 'quotereturnurl=' + encodeURIComponent(location.href))}>
                            <div style={{ marginLeft: 4, verticalAlign: 'middle' }} className="toolbutton gear"
                                 title="Configure Source Groups"></div>
                        </a>
                    ) : (
                        ''
                    )
                }
            </div>
        )
    }
}

class TabSpreadsheetControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpdating: false
        };
        this.updateSpreadsheet = this.updateSpreadsheet.bind(this);
    }

    updateSpreadsheet() {
        this.setState({ isUpdating: true });

        var updateApi = quosal.api.quote.updateFromSpreadsheets(this.props.contentGrid.props.quote.IdQuoteMain);
        updateApi.finished = function (msg) {
            quosal.sell.quote.update(msg.quote);

            if (this.componentIsMounted) {
                this.setState({ isUpdating: false });
            }
        }.bind(this);
        updateApi.call();
    }
    componentDidMount() {
        this.componentIsMounted = true;
    }
    componentWillUnmount() {
        this.componentIsMounted = false;
    }
    render() {
        return (
            <div className="panel toolbar top" style={{ backgroundColor: '#004400', width: 'calc(100% - 10px)', margin: 0, border: 0 }}>
                <div className="left">
                    <div className="toolbutton">
                        <a className="link" onClick={() => this.props.launchSpreadsheet(this.props.tab.IdQuoteTabs)} style={{ color: 'white', fontSize: '15px' }}>
                            <img src="img/icons/tab_spreadsheet.png" /> Launch Spreadsheet</a>
                    </div>
                </div>

                <div className="right">
                    {
                        this.state.isUpdating ?
                            <div style={{ position: "absolute", marginLeft: "-25px", marginTop: "10px" }}><SpinnerApril2018 small={true} color="white" /></div> :
                            <div className="toolbutton update-white" title="Refresh Spreadsheet" onClick={this.updateSpreadsheet}></div>
                    }
                </div>
            </div>
        )
    }
}
