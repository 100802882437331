import { ProductSearch } from "js/jsx/src/classes/product/productSearch.jsx";
import ProductSearchMain from "js/jsx/src/classes/product/newUI/productSearchMain.jsx";

export default function search() {
    var renderSearchForm = function (renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;

        var gridConfiguration = {
            gridName: 'ProductSearch',
            configurationName: quosal.settings.getValue('ProductSearch_GridConfiguration') || 'Default',
            fieldList: 'QuoteItems'
        };

        var formConfiguration = {
            formName: 'ProductSearch',
            configurationName: quosal.settings.getValue('ProductSearch_FormConfiguration') || 'Default'
        };

        var productSearchFormConfiguration = quosal.customization.forms['ProductSearch'].Configurations[quosal.settings.getValue('ProductSearch_FormConfiguration')]

        if (!productSearchFormConfiguration.Panels || productSearchFormConfiguration.Panels.length == 0) {
            formConfiguration = quosal.customization.forms.formConfigurationFix('ProductSearch', quosal.settings.getValue('ProductSearch_FormConfiguration'));
        }

        if (quosal.settings.getValue('UseUnifiedProductSearch')) {
            renderMethod(
              <ProductSearchMain />,
              document.getElementById("searchFormContainer"),
            );
          } else {
            renderMethod(
              <ProductSearch
                gridConfiguration={gridConfiguration}
                formConfiguration={formConfiguration}
              />,
              document.getElementById("searchFormContainer"),
            );
          }
    };

    var changeGridConfiguration = function (gridConfig) {
        quosal.customization.grids.changeConfig(gridConfig.GridName, gridConfig.ConfigurationName, function () {
            renderSearchForm();
        });
    };

    var updateGridConfiguration = function () {
        renderSearchForm();
    };

    var changeFormConfiguration = function () {
        renderSearchForm();
    };

    var loadSearchForm = function () {

        renderSearchForm(quosal.ui.react.render);

        $('#pageName').val('product.search');
    };

    var unbindEvents = function () {
        quosal.customization.grids.configurationChanged.unbind(changeGridConfiguration);
        quosal.customization.grids.configurationUpdated.unbind(updateGridConfiguration);
        quosal.customization.forms.configurationChanged.unbind(changeFormConfiguration);
        quosal.customization.forms.configurationUpdated.unbind(changeFormConfiguration);
        quosal.navigation.onNavigate.unbind(unbindEvents);
    };

    quosal.navigation.onNavigate.bind(unbindEvents);
    quosal.customization.grids.configurationChanged.bind(changeGridConfiguration);
    quosal.customization.grids.configurationUpdated.bind(updateGridConfiguration);
    quosal.customization.forms.configurationChanged.bind(changeFormConfiguration);
    quosal.customization.forms.configurationUpdated.bind(changeFormConfiguration);

    loadSearchForm();
}