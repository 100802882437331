if (!quosal.api.crm)
    quosal.api.crm = {};

quosal.api.crm.attachOpportunity = function (idQuoteMain, opportunityId, additionalParameters) {
    return quosal.api.call('Crm.AttachOpportunity', {
        idQuoteMain: idQuoteMain,
        opportunityId: opportunityId,
        additionalParameters: additionalParameters || {}
    });
};

quosal.api.crm.removeOpportunity = function (idQuoteMain, additionalParameters) {
    return quosal.api.call('Crm.RemoveOpportunity', {
        idQuoteMain: idQuoteMain,
        additionalParameters: additionalParameters || {}
    });
};

quosal.api.crm.getOpportunityItems = function (opportunityId) {
    return quosal.api.call('Crm.GetOpportunityItems', {
        opportunityId: opportunityId
    });
};

quosal.api.crm.changeAddresses = function (idQuoteMain, quoteTo, billTo, shipTo, sendTo) {
    return quosal.api.call('Crm.ChangeAddresses', {
        idQuoteMain: idQuoteMain,
        quoteTo: quoteTo,
        billTo: billTo,
        shipTo: shipTo,
        sendTo: sendTo
    });
};

quosal.api.crm.addItemsFromOpportunity = function (idQuoteMain, opportunityId, additionalParameters) {
    return quosal.api.call('Crm.AddItemsFromOpportunity', {
        idQuoteMain: idQuoteMain,
        opportunityId: opportunityId,
        additionalParameters: additionalParameters || {}
    });
};


quosal.api.crm.getAdditionalSites = function (idQuoteMain, siteName, idQuoteTabs) {
    return quosal.api.call('Crm.GetAdditionalSites', {
        idQuoteMain: idQuoteMain,
        siteName: siteName,
        idQuoteTabs: idQuoteTabs
    });
};

quosal.api.crm.attachCustomer = function (idQuoteMain, opportunityId, customerArray, addressId, attachAllCustomerCheck, additionalParameters) {
    return quosal.api.call('Crm.AttachCustomer', {
        idQuoteMain: idQuoteMain,
        opportunityId: opportunityId,
        customerArray: customerArray,
        addressId: addressId,
        attachAllCustomerCheck: attachAllCustomerCheck,
        additionalParameters: additionalParameters || {}
    });
};