import React from "react";
import {
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	Typography,
	Stack
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { useAppDataContext } from "../globalStates/appDataProvider";
import { abortRequestSafe } from "../services/fetchData";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails
} from "../styledComponents/productSearchCategoryMenu";
import { StyledButton } from "../styledComponents/common";
import CustomTreeItem from "./treeItem";

export default function ProductSearchCategoryMenu() {
	const {
		catalogs,
		checkedCatalogs,
		isLoadingCatalogs,
		isLoadingProducts,
		accordionExpanded,
		categoryExpanded,
		categorySelected,
		lessMoreExpanded,
		searchSignalKey,
		handleLessMoreExpanded,
		handleCategoryToggle,
		handleCategorySelect,
		handleExpandingAccordion,
		handleCatalogChecked,
		handleSearch
	} = useAppDataContext();
	const theme = useTheme();

	const numberCategories = 6;

	const onSearch = (options) => {
		abortRequestSafe(searchSignalKey);
		handleSearch(options);
	};

	return (
		<Box sx={{ my: 2, mx: 0, pr: 2, overflowY: "auto" }}>
			{isLoadingCatalogs ? (
				<Stack
					spacing={2}
					justifyContent="center"
					alignItems="center"
					direction="row"
					py={3}
				>
					<CircularProgress size={30} />
					<Typography textAlign="center" fontSize={16}>
						Loading...
					</Typography>
				</Stack>
			) : (
				<FormGroup>
					{Array.isArray(catalogs) &&
						!!catalogs.length &&
						catalogs.map((catalog) => (
							<Accordion
								key={catalog.Name + catalog.Source}
								expanded={accordionExpanded === catalog.Name}
								onChange={handleExpandingAccordion(catalog.Name)}
							>
								<AccordionSummary
									key={catalog.Source + catalog.Name}
									aria-controls="panel1d-content"
									id="panel1d-header"
								>
									<FormControlLabel
										sx={{ marginRight: 0 }}
										control={
											<Checkbox
												checked={
													checkedCatalogs &&
													checkedCatalogs.includes(catalog.Source)
												}
												disabled={isLoadingCatalogs || isLoadingProducts}
												onChange={(e) =>
													handleCatalogChecked(
														catalog.Source,
														e.target.checked
													)
												}
											/>
										}
									/>
									<Typography
										sx={{
											my: "auto",
											lineHeight: "normal",
											fontWeight: 600,
											color: `${theme.palette.text.primary}!important`
										}}
									>
										{catalog.Name === "Master Catalog"
											? "ConnectWise Catalog"
											: catalog.Name}
									</Typography>
								</AccordionSummary>
								<AccordionDetails key={catalog.Source + catalog.Name}>
									<TreeView
										aria-label="navigator"
										defaultCollapseIcon={<ExpandMoreIcon />}
										defaultExpandIcon={<ChevronRightIcon />}
										expanded={categoryExpanded}
										selected={categorySelected}
										onNodeToggle={handleCategoryToggle}
										onNodeSelect={handleCategorySelect}
									>
										{Array.isArray(catalog.Categories) &&
											catalog.Categories.map((category, indx) => {
												if (
													indx < numberCategories ||
													lessMoreExpanded[catalog.Name]
												) {
													return (
														<CustomTreeItem
															key={category.Id + category.Name}
															nodeId={indx.toString()}
															label={category.Name}
															onClick={() =>
																onSearch({
																	categoryName: category.Name,
																	categoryId: category.Id,
																	sourceTypes: [catalog.Source],
																	searchKeys: []
																})
															}
														>
															{Array.isArray(
																category.SubCategories
															) &&
																category.SubCategories.map(
																	(subcategory, idx) => (
																		<CustomTreeItem
																			key={
																				subcategory.Id +
																				subcategory.Name
																			}
																			nodeId={`${indx}${idx}`}
																			label={subcategory.Name}
																			onClick={() =>
																				onSearch({
																					categoryName:
																						subcategory.Name,
																					categoryId:
																						subcategory.Id,
																					sourceTypes: [
																						catalog.Source
																					],
																					searchKeys: []
																				})
																			}
																		/>
																	)
																)}
														</CustomTreeItem>
													);
												}
											})}
									</TreeView>
								</AccordionDetails>
								{catalog.Categories.length > numberCategories && (
									<Stack
										direction="row"
										alignItems="baseline"
										justifyContent="center"
									>
										<StyledButton
											variant="text"
											sx={{
												p: 0,
												fontSize: 16,
												lineHeight: "22px",
												"& .MuiButton-endIcon": { alignSelf: "baseline" }
											}}
											onClick={() => handleLessMoreExpanded(catalog.Name)}
											endIcon={
												<ArrowForwardIosSharpIcon
													sx={{
														transform: lessMoreExpanded[catalog.Name]
															? "rotate(270deg)"
															: "rotate(90deg)",
														padding: lessMoreExpanded[catalog.Name]
															? "8px 10px 0px 0px"
															: "0px 0px 8px 10px"
													}}
												/>
											}
										>
											{lessMoreExpanded[catalog.Name] ? "Less" : "More"}
										</StyledButton>
									</Stack>
								)}
							</Accordion>
						))}
				</FormGroup>
			)}
		</Box>
	);
}
