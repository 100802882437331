function showDOMElement(elementId) {
	if(document.getElementById(elementId)) document.getElementById(elementId).style.display = "block";
}
function hideDOMElement(elementId) {
	if(document.getElementById(elementId)) document.getElementById(elementId).style.display = "none";
}
function addCSSPropery(elementId, properyName, properyValue) {
	if(document.getElementById(elementId)) document.getElementById(elementId).style[properyName] = properyValue;
}
export { showDOMElement, hideDOMElement, addCSSPropery };

