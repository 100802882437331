import {FormFieldInput} from "js/jsx/src/classes/forms.jsx";
import { Dialog } from "js/jsx/src/classes/dialog.jsx";

// ******************************************************************************************
//                           ReviewComments
// ******************************************************************************************
class ReviewComments extends React.Component {
    render() {
        var idx = 0;
        if (this.props.availableComments && this.props.availableComments.length > 0) {
        var rows = this.props.availableComments.map(function(r) {
            var sev = "validation custom "+ r.Severity;
            idx++;
            return (<tr key={idx}>
                <td className="content"><div className={sev} /></td>
                <td className="content">{r.CreateDate}</td>
                <td className="content center">{r.QuoteVersion}</td>
                <td className="content">{r.FirstName} {r.LastName}</td>
                <td className="content">{r.Subject}</td>
                <td className="content">{r.Content}</td>
                </tr>);
            });

        var grid = (<table className="datagrid nosort" cellSpacing={0} cellPadding={0}>
            <tbody><tr>
                <th className="header" style={{width: 22}} />
                <th className="header">Date</th>
                <th className="header">Version</th>
                <th className="header">Name</th>
                <th className="header">Subject</th>
                <th className="header">Comments</th>
                </tr>
                {rows}
            </tbody></table>);

        return (
              <div className="grid col1-1">
                <Panel ref="historypanel" id="historypanel" className="panel collapsable" help="review-history" collapsible={true} 
                                onCollapseChanging={this.toggleReview} cookie="revhistoryswap" >
                  <div className="title">Review History</div>
                  <div className="content extra" id='historygrids'>
                    {grid}
                  </div>
                </Panel>
              </div>
            );
    }
    else {
        return null;
    }   
  }
}

// ******************************************************************************************
//                           ReviewScreenInfo
// ******************************************************************************************

class ReviewScreenInfo extends React.Component {
    render() {
        return (<div>
            {this.props.isArchive && <div className="disablesavecatch" />}
            {this.props.isNoReview && <div className="disablesavecatch" />}
            <input id="helpRoot" defaultValue="quote-home/review" type="hidden" />
            <input id="successmessage" defaultValue={this.props.successMessage} type="hidden" />
            <input id="approvalstatus" defaultValue={this.props.approvalStatus} type="hidden" />
        </div>);
    }
}

// ******************************************************************************************
//                           CustomReviewTopPanel
// ******************************************************************************************
class CustomReviewTopPanel extends React.Component {
    render() {
        if (this.props.available) {
            return (
                <div className="panel editable">
                    <div className="title">{this.props.blockname}</div>
                    <div className="content">{this.props.block}</div>
                </div>
            );
        }
        else {
            return null;
        }
    }
}

// ******************************************************************************************
//                           CustomReviewBottomPanel
// ******************************************************************************************
class CustomReviewBottomPanel extends React.Component {
    render() {
        if (this.props.available) {
            return (
                <div className="panel editable" >
                    <div className="title">{this.props.blockname}</div>
                    <div className="content">{this.props.block}</div>
                </div>
            );
        }
        else {
            return null;
        }    
    }
}

// ******************************************************************************************
//                           ReviewBody
// ******************************************************************************************
class ReviewBody extends React.Component {
    constructor(props) {
        super(props);

        this.requestMode = {
            REVIEW: 'review',
            APPROVE: 'approval',
        }
        this.onlyPeerReviewAllowed = (
            !quosal.validation.isPackageLevelAuthorized(app.packageLevels.premium) ||
            !(app.settings.user.ApprovalMethod && app.settings.user.ApprovalMethod !== 'None') ||
            (app.settings.global.MLAEnableApproved || (app.settings.global.ApprovalMethod && (app.settings.global.ApprovalMethod == 'Multilevel' 
            || app.settings.global.ApprovalMethod == 'None')))
        );
        this.lastQuote = null;

        this.state = {  
            currentMode: this.requestMode.REVIEW,
            peerreviewsubject: "",
            peerreviewbody: "",
            peerreviewquoteform: "",
            peerrevieworderporter: "",
            peerreviewmethod: "",
            approvalreviewsubject: "",
            approvalreviewbody: "",
            approvalreviewquoteform: "",
            approvalrevieworderporter: "",
            approvalreviewmethod: "",
            justloadedfromdb: false,
            peerTo: "",
            approvers: {},
            primeapprove: "",
            selectedapprove: "",
            isLocked: false,
            NoReview: false,
            noPeerReview: false,
            isLoadingRTF: true,
            isLoading: false,
            isEditorOutput: quosal.util.isNewEditorPreviewContentEnabled() ? true : false
        };

        // This binding is necessary to make `this` work in the callback
        this.reloadServerProperties = this.reloadServerProperties.bind(this);
        this.requestBtnClick = this.requestBtnClick.bind(this);
        this.resubmitWithFallback = this.resubmitWithFallback.bind(this);
        this.handleRequestCompletion = this.handleRequestCompletion.bind(this);
        this.finishRequestUI = this.finishRequestUI.bind(this);
        this.emailChanged = this.emailChanged.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.approvalTypeChange = this.approvalTypeChange.bind(this);
        this.onChangeUpdate = this.onChangeUpdate.bind(this);
    }

    onChangeUpdate(event) {
        this.setState({
            isEditorOutput: event.target.checked
        });
    }

    componentDidMount() {
        this.componentIsMounted = true;
        this.reloadServerProperties();
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    reloadServerProperties() {
        var me = this;
        var quote = this.props.quote || app.currentQuote;
        QuoteReviewAPIHelper.loadReviewConfigs(quote.IdQuoteMain, function(data) {
            if (me.componentIsMounted) {
                me.setState({
                    peerreviewsubject: data.peerreviewsubject,
                    peerreviewbody: data.peerreviewbody,
                    peerreviewquoteform: data.peerreviewquoteform,
                    peerrevieworderporter: data.peerrevieworderporter,
                    peerreviewmethod: data.peerreviewmethod,
                    peerTo: data.peerreviewemails,
                    approvalreviewsubject: data.approvalreviewsubject,
                    approvalreviewbody: data.approvalreviewbody,
                    approvalreviewquoteform: data.approvalreviewquoteform,
                    approvalrevieworderporter: data.approvalrevieworderporter,
                    approvalreviewmethod: data.approvalreviewmethod,
                    approvers: data.approvers,
                    primeapprove: data.primaryapprover[0],
                    selectedapprove: data.primaryapprover[0].FullName + ": " + data.primaryapprover[0].EmailAddress,
                    approverequired: data.ApprovalRequiredBeforePublishing == 'true', 
                    auxemailtext: data.ApprovalOrderPorterEmailText2,
                    isLocked: data.isLocked == 'true',
                    NoReview: data.NoReview == 'true',
                    noPeerReview: data.NoPeerReview == 'true',
                    justloadedfromdb: true
                });
                if (me.state.currentMode == "review") {
                    me.refs.sendto.value = data.peerreviewemails;
                }
            }
        }); 
    }

    componentDidUpdate() {
	    if (this.state.justloadedfromdb) {
	        var doApprove = !this.onlyPeerReviewAllowed &&
                ((this.state.approverequired) || (this.state.noPeerReview) || (this.state.NoReview) || (this.state.isLocked) || (this.props.needauth));                
            if (doApprove) {
                this.refs.subject.value = this.state.approvalreviewsubject;
                this.refs.rtfedit.refs.input.refs.editor.setHtml(this.state.approvalreviewbody);
                this.setState({currentMode: this.requestMode.APPROVE});
            }
            else {
                this.refs.subject.value = this.state.peerreviewsubject;
                this.refs.rtfedit.refs.input.refs.editor.setHtml(this.state.peerreviewbody);
                this.setState({currentMode: this.requestMode.REVIEW});
            }
            this.setState({justloadedfromdb: false});
        }
    }

    requestBtnClick() {
        if (this.state.justloadedfromdb || !this.componentIsMounted) {
            return;
        }
        var me = this;
        var quote = this.props.quote || app.currentQuote;
        var sSubject = this.refs.subject.value;
        var sTo = this.refs.sendto.value;
        var sMsg = this.refs.rtfedit.refs.input.refs.editor.getHtml();
        if (this.validateForm(quote, sTo, sSubject) == false) {
            return;
        }
        var sTitle;
        var sStatusLine;
        if (this.state.currentMode === this.requestMode.REVIEW) {
            sTitle = 'Requesting Review';
            sStatusLine = 'Requesting peer review...';
        }
        else {
            sTitle = 'Requesting Approval';
            sStatusLine = 'Requesting Manager Approval...';
        }
        Dialog.open({
            //icon: 'spinner',
            title: sTitle,
            message: (<span><Spinner style={{marginTop: 5}}/>{sStatusLine}</span>),
            closeRequiresButton: true,
            links: [
            ]
        });

        if (this.state.currentMode === this.requestMode.REVIEW) {
            QuoteReviewAPIHelper.requestAReview(quote.IdQuoteMain, sTo, sSubject, sMsg, false, "", this.handleRequestCompletion, this.state.isEditorOutput);
        }
        else {
            QuoteReviewAPIHelper.requestAnApproval(quote.IdQuoteMain, sTo, sSubject, sMsg, false, "", this.handleRequestCompletion, this.state.isEditorOutput);
        }
    }

    resubmitWithFallback(pdfPath) {
        var quote = this.props.quote || app.currentQuote;
        var sSubject = this.refs.subject.value;
        var sTo = this.refs.sendto.value;
        var sMsg = this.refs.rtfedit.refs.input.refs.editor.getHtml();

        this.setState({ forceFallback: true, isLoading: true })
        Dialog.closeAll();
        if (this.state.currentMode === this.requestMode.REVIEW) {
            QuoteReviewAPIHelper.requestAReview(quote.IdQuoteMain, sTo, sSubject, sMsg, true, pdfPath, this.handleRequestCompletion);
        }
        else {
            QuoteReviewAPIHelper.requestAnApproval(quote.IdQuoteMain, sTo, sSubject, sMsg, true, pdfPath, this.handleRequestCompletion);
        }
    }

    handleRequestCompletion(data) {
        if (this.props.RequestCompleted) {
            this.props.RequestCompleted(data);
        }
        this.lastQuote = data.quote;

        if (data.error.length > 0) {
            if (data.error.includes("PDF too large for any delivery")) {
                this.setState({ isLocked: true });
                var errorLanguage1 = "Published document size is " + data.pdfFileSize + "MB, which exceeds the maximum email size that can be sent through " + app.productBranding + " (20MB).";
                var errorLanguage2 = "Please reduce the size of your request by removing additional attachments and/or large images.";
                Dialog.setIsWorking(false);
                Dialog.open({
                    title: 'Unable To Send Email From ' + app.productBranding,
                    message: <div><span>{errorLanguage1}</span><br></br><br></br><span>{errorLanguage2}</span></div>,
                    closeRequiresButton: true,
                    links: [{
                        title: 'OK',
                        callback: this.finishRequestUI
                    }]
                });
            }
            else {
                Dialog.open({
                    title: 'Request Failed',
                    message: quosal.util.htmlEncodeAndConvertLineBreaks(data.error),
                    messageIsVettedHtml: true,
                    closeRequiresButton: true,
                    links: [{ title: 'OK', callback: this.finishRequestUI }]
                });
            }
        }
        else if (data.emailError.length > 0) {
            Dialog.open({
                title: 'Email Failed',
                message: (<span>Sending your email failed for the following reason: <b> {data.emailError} </b>
                    <br /><br />Please correct this issue and try again.</span>),
                closeRequiresButton: true,
                links: [{ title: 'OK', callback: this.finishRequestUI }]
            });
        }
        else {
            Dialog.open({
                title: 'Request Sent',
                message: (<span>{data.SuccessMessage}</span>),
                closeRequiresButton: true,
                links: [{title: 'OK',callback: this.finishRequestUI}]
            });
        }

        this.setState({ isLoading: false })
    }

    finishRequestUI() {
        Dialog.closeAll();
        if (this.lastQuote) {
            quosal.sell.quote.update(this.lastQuote);
        }
    }

    emailChanged(evt) {
        if (this.state.currentMode === this.requestMode.REVIEW) {
            if ($.quosal.validation.validateData.email($('#sendto'), 'error', 'The email address was not valid.')) {
                $.quosal.validation.clearField($('#sendto'));
            }
        }
    }

    validateForm(quote, email, subject) {
        var isValid = true;

        if (this.state.IsArchive) {
            return false;
        }

        if (!($.quosal.validation.validateData.blank($('#subject'), 'error', 'Please add a subject to your email.'))) {
            isValid = false;
        }
        else {
            $.quosal.validation.clearField($('#subject'));
        }

        if (this.state.currentMode === this.requestMode.APPROVE) {
            if ($('#approvalstatus').val() == 'Request Approval') {}
        }
        else {
            if (!$.quosal.validation.validateData.blank($('#sendto'), 'error', 'Please add a recipient to your email')) {
                isValid = false;
            }
            else if (!($.quosal.validation.validateData.email($('#sendto'), 'error', 'The email address was not valid.'))) {
                isValid = false;
            }
            else {
                $.quosal.validation.clearField($('#sendto'));
            }
        }
        return isValid;
    }

    approvalTypeChange(evt) {
        if (this.state.justloadedfromdb || this.onlyPeerReviewAllowed)
            return;

        if (evt.target.value == 'peerreview' && this.state.currentMode == this.requestMode.APPROVE) { // to peer
            this.setState({
                currentMode: this.requestMode.REVIEW,
                approvalreviewsubject: this.refs.subject.value,
                selectedapprove: this.refs.sendto.value,
                approvalreviewbody: this.refs.rtfedit.refs.input.refs.editor.getHtml()
            });

            this.refs.subject.value = this.state.peerreviewsubject;
            this.refs.sendto.value = this.state.peerTo;
            this.refs.rtfedit.refs.input.refs.editor.setHtml(this.state.peerreviewbody);
        }
        else if (evt.target.value == 'managerapproval' && this.state.currentMode == this.requestMode.REVIEW) { //to mgr
            this.setState({
                currentMode: this.requestMode.APPROVE,
                peerreviewsubject: this.refs.subject.value,
                peerTo: this.refs.sendto.value,
                peerreviewbody: this.refs.rtfedit.refs.input.refs.editor.getHtml()
            });
            this.refs.subject.value = this.state.approvalreviewsubject;
            this.refs.sendto.value = this.state.apprselectedapproveoveTo;
            this.refs.rtfedit.refs.input.refs.editor.setHtml(this.state.approvalreviewbody);
        }
        // else. no change happened.
    }

    render() {
        var spinner = this.state.isLoading == true ? <div><div className='spinnercontainerFullPage'><center><SpinnerApril2018 key={'spinner'} ></SpinnerApril2018></center></div>
            <div>
                <div className="windowMask" style={{ display: 'block', zIndex: '9000' }}></div>
            </div></div> : null;
        var quote = this.props.quote || app.currentQuote;

        var reviewBtnClass = 'btn3 review';
        if (this.state.isLocked) {
            reviewBtnClass = 'btn3 review disabled';
        }

        var btnText;
        if (this.state.currentMode === this.requestMode.REVIEW) {
            btnText = 'Request Review';
        }
        else {
            btnText = 'Request Approval';
        }
        
        var htmlTitle = (<div>
            <div className="title">
                {spinner}
                <div className="toolbar right" id="ReviewId">
                {(this.state.NoReview==false && this.state.isLocked==false && !this.props.isArchived)
                    && (<Button type="request" ref="btnRequest1" id="btnRequest1" className={reviewBtnClass}
                            disabled={this.state.isLocked===true} onClick={this.requestBtnClick}
                            visible='true' >{btnText}</Button>)}

                  {(this.state.NoReview==true || this.state.isLocked==true)
                    && (<div className="standardformfieldlabel" target="formreview"><i>You can no longer request a review on this quote.</i></div>)}
                </div>
                Request Review
            </div>            
        </div>);
        
        var htmlAuthReason = null;
        var htmlAuthReason2 = null;
        var netval = this.props.authorizationreason ? ('' + this.props.authorizationreason).trim() : '';
        if (netval.length > 0) {
            if (this.props.needauth) {
                if (this.state.apprselectedapproveoveTo == '' || this.state.apprselectedapproveoveTo == 'none') {
                    htmlAuthReason = (<div>
                        <div className="infobox notice">
                            <span className="infobox message"><strong>Approval Needed: </strong>You have no approving manager configured, contact your administrator for assistance.</span>
                        </div>    
                    </div>);
                }
                else {
                    htmlAuthReason = (<div>
                        <div className="infobox notice">
                            <span className="infobox message"><strong>Approval Needed: </strong>{this.props.authorizationreason}</span>
                        </div>    
                    </div>);
                }
            }
            else {
                htmlAuthReason2 = (<div>
                    <div className="infobox noticeless">
                        <span className="infobox message"><strong>Approval Information: </strong>{this.props.authorizationreason}</span>
                    </div>
                </div>);
            }
        }
        var rPeer;
        var rApprove;
        var htmlReviewType = null;
        if (!this.onlyPeerReviewAllowed) {
            if (this.state.currentMode == this.requestMode.REVIEW) {
                var t1 = this.state.noPeerReview == true || this.state.NoReview == true || this.state.isLocked == true;
                if (this.state.noPeerReview == true || this.state.NoReview == true || this.state.isLocked == true) {
                    rPeer = (
                        <input name="approvaltype" id="peerreview" onChange={this.approvalTypeChange} value="peerreview"
                               className="disable_exempt"
                               checked={true} disabled type="radio"/>);
                    rApprove = <input name="approvaltype" id="managerapproval" onChange={this.approvalTypeChange}
                                      value="managerapproval" className="disable_exempt"
                                      checked={false} disabled type="radio"/>;
                }
                else {
                    rPeer = (
                        <input name="approvaltype" id="peerreview" onChange={this.approvalTypeChange} value="peerreview"
                               className="disable_exempt" disabled={this.state.isLoadingRTF}
                               checked={true} type="radio"/>);
                    rApprove = <input name="approvaltype" id="managerapproval" onChange={this.approvalTypeChange}
                                      value="managerapproval" disabled={this.state.isLoadingRTF}
                                      checked={false} className="disable_exempt" type="radio"/>;
                }
            }
            else {
                var t1 = this.state.noPeerReview == true || this.state.NoReview == true || this.state.isLocked == true;

                if (this.state.noPeerReview == true || this.state.NoReview == true || this.state.isLocked == true) {
                    rPeer = (
                        <input name="approvaltype" id="peerreview" onChange={this.approvalTypeChange} value="peerreview"
                               className="disable_exempt"
                               checked={false} disabled type="radio"/>);
                    rApprove = <input name="approvaltype" id="managerapproval" onChange={this.approvalTypeChange}
                                      value="managerapproval" className="disable_exempt"
                                      checked={true} disabled type="radio"/>;
                }
                else {
                    rPeer = (
                        <input name="approvaltype" id="peerreview" onChange={this.approvalTypeChange} value="peerreview"
                               checked={false} className="disable_exempt" disabled={this.state.isLoadingRTF} type="radio"/>);
                    rApprove = <input name="approvaltype" id="managerapproval" onChange={this.approvalTypeChange}
                                      value="managerapproval" disabled={this.state.isLoadingRTF} className="disable_exempt"
                                      checked={true} type="radio"/>;
                }
            }

            htmlReviewType = (
                <div className="content">
                    {htmlAuthReason}
                    <div className="horizontalform compact">
                    <span className="formcheckboxwrapper">
                        {rPeer}
                        <label htmlFor="peerreview" className="formlabel">Request Peer Review</label>
                    </span>
                    <span className="formcheckboxwrapper">
                        {rApprove}
                        <label htmlFor="managerapproval" className="formlabel">Request Manager Approval</label>
                    </span>
                    </div>
                </div>
            );
        } 

        var rtf1Info = {
            FieldName: 'rtfreview',
            DataType: 'String',
            name: "rtfreview",
            WidgetType: 'rtfeditor',
            WidgetParameters: '{"urlParameters": ["review"]}'
        };

        var componentDidMountRTF = function (formFieldInput) {
           formFieldInput.prepareThenSave = function (saveCallback) {
                if (typeof this.beforeSave === 'function') {
                    this.beforeSave(saveCallback);
                } else {
                    saveCallback();
                }
            }.bind(formFieldInput);
        };

        var emailLabel = "Send To Emails (use a comma for multiple addresses)";
        var emailAddr = this.state.peerTo;
        var emailSubj = this.state.peerreviewsubject;
        var emailElem = (<div className="standardfield">
                <input ref="sendto" name="sendto" id="sendto" type="text" defaultValue={emailAddr} onChange={this.emailChanged}/>
            </div>);
        if (this.state.currentMode === this.requestMode.APPROVE) {
            emailAddr = this.state.selectedapprove;
            var thelist = this.state.approvers != null ? this.state.approvers.map(function (a) {
                var combo = a.FullName + ": " + a.EmailAddress;
                return (<option key={combo} value={combo}>{combo}</option>);
            }) : {};
            emailLabel = "Approver Email";
            emailSubj = this.state.mgrSubject;
            emailElem = (<div className="formselectfieldwrapper" style={{width: 400}}>
                <select ref="sendto" className="formselectfield" name="sendto" id="sendto" title="Email" defaultValue={emailAddr}>{
                    thelist}</select>
            </div>);
        }

        var me = this;
        return (
            <div className="grid col1-1">
                <div id="requestPanel" className="panel" >
                    {htmlTitle}
                    {htmlReviewType}
                    <div className="content review">
                        <div className="standardformfieldlabel">
                            <label htmlFor="sendto" className="standardformlabel">{emailLabel}</label>
                        </div>
                        {emailElem}
                        <div className="standardformfieldlabel">
                            <label htmlFor="subject" className="standardformlabel">Subject</label>
                        </div>
                        <div className="standardfield">
                            <input name="subject" id="subject" ref="subject" onChange={this.subjectChanged} defaultValue={emailSubj} type="text" />
                        </div>
                        <FormFieldInput ref="rtfedit" name="rtfreview" field={rtf1Info} review='review' rtfCallback={function(){me.setState({isLoadingRTF: false})}}
                                                    style={{maxWidth: '100%', height: '95%'}}
                                                    defaultValue="test" />

                        <div style={{paddingTop: 10, textAlign: 'right'}}>
                        </div>
                    </div>
                    {htmlAuthReason2}
                    <div className="footer">
                        <div className="right">
                            {quosal.util.isNewEditorPreviewContentEnabled() && (<div style={{ display: 'inline', verticalAlign: 'middle' }}>
                                <input type="checkbox"
                                    checked={this.state.isEditorOutput}
                                    onChange={this.onChangeUpdate} />
                                <label style={{ marginRight: '10px', fontSize: '15px' }}>Editor Output</label>
                            </div>)}
                        {(this.state.NoReview==false && this.state.isLocked==false) && (<Button type="request" ref="btnRequest2" id="btnRequest2" className={reviewBtnClass} disabled={this.state.isLocked===true}
                                onClick={this.requestBtnClick} visible='true'>{btnText}</Button>)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// ******************************************************************************************
//                           QuoteReviewMain
// ******************************************************************************************
export class QuoteReviewMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {    
            hasCustomTop: false, 
            hasCustomBottom: true,
            tophtml : '',
            bothtml : '',
            authorizationreason: '',
            needauth: false,
            isLocked: false,
            isArchive: false,
            successMessage: '',
            availableComments: {},
        }; 
        // This binding is necessary to make `this` work in the callback
        this.reloadServerData = this.reloadServerData.bind(this);
        this.requestOccurred = this.requestOccurred.bind(this);
    }

    UNSAFE_componentWillMount() {}

    componentDidMount() {
        this.componentIsMounted = true;
        this.reloadServerData(true);
        QuoteReviewAPIHelper.updateSpreadSheet(app.currentQuote.IdQuoteMain, function(data){
            quosal.sell.quote.update(data.quote);
        })
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    reloadServerData(firstTime) {
        var quote = this.props.quote || app.currentQuote;
        var me = this;
        QuoteReviewAPIHelper.loadReviewHistory(quote.QuoteReadableId, quote.IdQuoteMain, function(data) {
            if (me.componentIsMounted) {
                me.setState({
                    availableComments: data.availableComments,
                    authorizationreason: data.authorizationreason,
                    isArchive: data.isArchive,
                    isLocked: data.isLocked,
                    needauth: data.authisrequired == 'true',
                });
            }
            if (firstTime) {
                    var cookie = quosal.util.cookie('panel_revhistoryswap');
                    if (cookie === 'collapsed') {
                        $('#historygrids').hide();
                    }
                }
        });
        QuoteReviewAPIHelper.loadReviewScripts(quote.IdQuoteMain, function(data) {
            if (me.componentIsMounted) {
                me.setState({
                    hasCustomTop: data.topscriptname && data.topscriptname.length > 0,
                    hasCustomBottom: data.bottomscriptname && data.bottomscriptname.length > 0,
                    tophtml : data.topscriptthml,
                    bothtml : data.bottomscripthtml
                });
            }
        }); 
    }

    requestOccurred(data) {
        this.reloadServerData(false);
        this.setState({
            successMessage: data.SuccessMessage
        });
        
    }

    render() {
        var quote = this.props.quote || app.currentQuote;
        return(
            <div>
                <CustomReviewTopPanel ref="scripttop" available={this.state.hasCustomTop} block={this.state.tophtml} blockname={this.state.topscriptname} />
                <ReviewScreenInfo ref="screeninfo" archived={this.state.isArchive} isNoReview={this.state.isNoReview} successMessage={this.state.successMessage} approvalStatus={quote.ApprovalStatus} />
                <ReviewComments ref="comments" availableComments={this.state.availableComments} />
                <ReviewBody ref="reviewbody" authorizationreason={this.state.authorizationreason} needauth={this.state.needauth} isArchived={this.state.IsArchive} RequestCompleted={this.requestOccurred}/>
                <CustomReviewBottomPanel ref="scriptbottom" available={this.state.hasCustomBottom} block={this.state.bothtml} blockname={this.state.bottomscriptname} />

            </div>
        )
    }
}

class QuoteReviewAPIHelper {
    static loadReviewHistory(readable,mainid, callback) {
        var makecall = quosal.api.quote.loadReviewHistory(readable,mainid);
        makecall.finished = function(msg) {
            QuoteReviewAPIHelper.handleLoadReviewHistory(msg, callback);
        };
        makecall.call();
    }
    static handleLoadReviewHistory(msg, callback) {
        callback(msg);
    }
    static updateSpreadSheet(quoteId, callback) {
        var makecall = quosal.api.quote.updateFromSpreadsheets(quoteId);
        makecall.finished = function(msg){
            QuoteReviewAPIHelper.handleUpdateSpreadSheet(msg, callback);
        }
        makecall.call()
    }
    static handleUpdateSpreadSheet(msg, callback) {
        callback(msg);
    }
    static loadReviewScripts(quoteID, callback) {
        var makecall = quosal.api.quote.loadReviewPageScripts(quoteID);
        makecall.finished = function(msg) {
            QuoteReviewAPIHelper.handleLoadReviewScripts(msg, callback);
        };
        makecall.call();
    }
    static handleLoadReviewScripts(msg, callback) {
        callback(msg);
    }

    static loadReviewConfigs(mainid, callback) {
        var makecall = quosal.api.quote.loadReviewConfigs(mainid);
        makecall.finished = function(msg) {
            if (msg.asioTaxServiceErrorMessage) {
                Dialog.open({
                    title: 'Error Calculating Taxes',
                    message: msg.asioTaxServiceErrorMessage,
                    links: [{title: 'OK', callback: Dialog.closeAll}]
                })
            }
            if (msg.quote) {
                quosal.sell.quote.updateFromApiResponse(msg);
            }
            QuoteReviewAPIHelper.handleLoadReviewConfigs(msg, callback);
        };
        makecall.call();
    }
    static handleLoadReviewConfigs(msg, callback) {
        callback(msg);
    }

    static requestAReview(mainid, emails, subject, body, forceFallback, pdfFile, callback, isEditorOutput) {
        var makecall = quosal.api.quote.requestReview(mainid, emails, subject, body, forceFallback, pdfFile, isEditorOutput);
        makecall.finished = function(msg) {
            QuoteReviewAPIHelper.handlerequestAReview(msg, callback);
        };
        makecall.call();
    }

    static handlerequestAReview(msg, callback) {
        callback(msg);
    }
    
    static requestAnApproval(mainid, emails, subject, body, forceFallback, pdfFile, callback, isEditorOutput) {
        var makecall = quosal.api.quote.requestApproval(mainid, emails, subject, body, forceFallback, pdfFile, isEditorOutput);
        makecall.finished = function(msg) {
            QuoteReviewAPIHelper.handlerequestAnApproval(msg, callback);
        };
        makecall.call();
    }

    static handlerequestAnApproval(msg, callback) {
        callback(msg);
    }
}