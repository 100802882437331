import { PublishSettingsPage } from "./PublishSettingsPage.jsx";
import { FormProvider, useForm } from "react-hook-form";
import { DispatchProvider } from "../quoteHeader/context/DispatchContext.js";
import { SaveCancelBar } from "./SaveCancelBar.jsx";
import { useQuoteHeader } from "../quoteHeader/customHooks/useQuoteHeader.js";
import { OrderPorterSettings } from "./OrderPorterSettings.jsx";
import {
	getDefaultWinDOcOptions,
	getOpTemplateOptions,
	getPaymentProcessingOptions
} from "./services.js";
import { useEffect, useState } from "react";
import { AppTheme } from "../../../common/theme/AppTheme.jsx";
import { Loader } from "../quoteHeader/common/components/Loader.jsx";
import { getPackageLevels } from "../quoteHeader/utils/common.js";

export const PublishSettings = () => {
	const { dispatch, currentQuote } = useQuoteHeader(app.currentQuote);
	const { IdQuoteMain } = currentQuote;

	const [defaultWinDocOptions, setDefaultWinDocOptions] = useState([]);
	const [opTemplateOptions, setOpTemplateOptions] = useState([]);
	const [paymentProcessingOptions, setPaymentProcessingOptions] = useState([]);
	const [loading, setLoading] = useState(false);

	const packageLevels = getPackageLevels();
	const isPremiumPartner = app.settings.global.packageLevel === packageLevels?.premium;
	const isStandardPartner = app.settings.global.packageLevel === packageLevels?.standard;

	const fetchDropdownOptions = async () => {
		const [resp1, resp2, res3] = await Promise.all([
			getDefaultWinDOcOptions({ extension: "xrpt" }),
			getOpTemplateOptions({}),
			getPaymentProcessingOptions({})
		]);

		// sets the state once call is completed
		setDefaultWinDocOptions(resp1.response0.formMetaData.map((item) => item.Value));
		setOpTemplateOptions(resp2.response0.formMetaData);
		setPaymentProcessingOptions(res3.response0.formMetaData);
	};

	useEffect(() => {
		fetchDropdownOptions();
	}, []);

	const defaultValues = {
		PackageShowHeader: currentQuote?.PackageShowHeader,
		PackageShowItems: currentQuote?.PackageShowItems,
		UseStandardPublishOutput: currentQuote?.UseStandardPublishOutput,
		PackagePrintHeaderPrice: currentQuote?.PackagePrintHeaderPrice,
		PackagePrintItemPrice: currentQuote?.PackagePrintItemPrice,
		OrderPorterGroup: currentQuote?.OrderPorterGroup,
		OrderPorterTemplate: currentQuote?.OrderPorterTemplate,
		OrderPorterTheme: currentQuote?.OrderPorterTheme,
		WinForm: currentQuote?.WinForm,
		ApprovalQuoteForm: currentQuote?.ApprovalQuoteForm,
		PeerReviewDocument: currentQuote?.PeerReviewDocument,
		OrderPorterEsign: currentQuote?.OrderPorterEsign,
		IsSignerConfirmationRequired: currentQuote?.IsSignerConfirmationRequired,
		OrderPorterShowSignature: currentQuote?.OrderPorterShowSignature,
		OrderPorterShowTabCb: currentQuote?.OrderPorterShowTabCb,
		OrderPorterShowLineDetails: currentQuote?.OrderPorterShowLineDetails,
		OrderPorterShowImage: currentQuote?.OrderPorterShowImage,
		OrderPorterShowItemCb: currentQuote?.OrderPorterShowItemCb,
		OrderPorterShowQty: currentQuote?.OrderPorterShowQty,
		OrderPorterPaymentMode: currentQuote?.OrderPorterPaymentMode,
		OrderPorterDownpaymentPercent: currentQuote?.OrderPorterDownpaymentPercent,
		OrderPorterDownpaymentMinimum: currentQuote.formatCurrency(
			currentQuote?.OrderPorterDownpaymentMinimum
		),
		OrderPorterAmount: currentQuote.formatCurrency(currentQuote?.OrderPorterAmount),
		TermsAndConditions: currentQuote?.TermsAndConditions
	};

	useEffect(() => {
		methods.setValue(
			"OrderPorterAmount",
			currentQuote.formatCurrency(currentQuote?.OrderPorterAmount)
		);
	}, [defaultValues.OrderPorterAmount]);

	const methods = useForm({
		mode: "onBlur",
		defaultValues: {
			...defaultValues
		}
	});

	return (
		<>
			<AppTheme>
				<DispatchProvider dispatch={dispatch}>
					<FormProvider {...methods}>
						<SaveCancelBar IdQuoteMain={IdQuoteMain} setLoading={setLoading} />
						<PublishSettingsPage
							defaultWinDocOptions={defaultWinDocOptions}
							opTemplateOptions={opTemplateOptions}
							isPremiumPartner={isPremiumPartner}
							isStandardPartner={isStandardPartner}
						/>
						<OrderPorterSettings
							paymentProcessingOptions={paymentProcessingOptions}
							isPremiumPartner={isPremiumPartner}
						/>
						<Loader loading={loading} loadingText={"Saving"} />
					</FormProvider>
				</DispatchProvider>
			</AppTheme>
		</>
	);
};
