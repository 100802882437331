import {ApprovalRules} from "js/jsx/src/classes/admin/approvals/ApprovalRules.jsx"

export default function approvalrules(){
    var loadApprovalRulesPage = function () {

        quosal.ui.react.render(<DialogManager/>, document.getElementById('dialogManager'));
        quosal.ui.react.render(<ApprovalRules />, document.getElementById('currentDocument'));
        $('#pageName').val('approvalrules.quosalweb');
    };
    quosal.sell.modules.onAfterLoad.unbind().bind(loadApprovalRulesPage);
}