import React, { Component } from 'react';
import { produce } from "immer";
import { arrayItemUpdater, DATA_TYPE_INITIAL_STATE, ENUM_DATA_NAME, ENUM_KEY_CHECKBOX, ENUM_KEY_DATE, ENUM_KEY_NUMBER, ENUM_KEY_RADIO, ENUM_KEY_SELECT, ENUM_KEY_TEXT, QUESTION_STRUCTURE, randomId, SECTION_STRUCTURE } from '../constants/createFormConstants';
import { COLLAPSE_ALL_SECTION, EDIT_DYNAMIC_QUES_CONDITION, EXPAND_ALL_SECTION, REMOVE_DYNAMIC_QUES_CONDITION, SET_EXPAND_SECTION } from '../actions/formActions';

const FormContext = React.createContext();

class FormProvider extends Component {

    state = {
        sections: [{ ...SECTION_STRUCTURE },],
        isEdited: false,
        isSendable: true,
        expandSections:[]
    }

    formActions = {
            ['section-name']: (value, sectionIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionUpdated = { ...sectionArray[sectionIndex], name: value, }
                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);

                this.setState({ ...this.state, isEdited:true, sections: sectionArrayUpdated });
            },
            ['common']: (value, fieldName, sectionIndex, questionIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                let questionUpdated;
                if (fieldName === 'isRequired' && questionSelected.answerDataType === ENUM_DATA_NAME[ENUM_KEY_CHECKBOX] && value === false) {
                    questionUpdated = { ...questionSelected, [fieldName]: value, hasMinOptions: false, minOptions: '' };
                } else {
                    questionUpdated = { ...questionSelected, [fieldName]: value };
                }

                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);

                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };

                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);

                this.setState({ ...this.state, isEdited:true, sections: sectionArrayUpdated });
            },
            ['answerDataType']: (value, sectionIndex, questionIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                const isDynamicQuestionApplicable =
					(value === ENUM_DATA_NAME[ENUM_KEY_RADIO] &&
						questionSelected.answerDataType === ENUM_DATA_NAME[ENUM_KEY_SELECT]) ||
					(value === ENUM_DATA_NAME[ENUM_KEY_SELECT] &&
						questionSelected.answerDataType === ENUM_DATA_NAME[ENUM_KEY_RADIO]);
                let questionUpdated;
                const basicQuestionStructure = {
					question: questionSelected.question,
					isRequired: questionSelected.isRequired,
					isActive: questionSelected.isActive,
					isDynamicQuestion: isDynamicQuestionApplicable
						? questionSelected.isDynamicQuestion
						: false,
					tooltip: questionSelected.tooltip,
					sortOrder: questionSelected.sortOrder,
					questionId: questionSelected.questionId,
				};
                switch (value) {
                    case ENUM_DATA_NAME[ENUM_KEY_TEXT]:
                        questionUpdated = { ...basicQuestionStructure, answerDataType: value };
                        break;
                    case ENUM_DATA_NAME[ENUM_KEY_DATE]:
                        questionUpdated = { ...basicQuestionStructure, answerDataType: value, ...DATA_TYPE_INITIAL_STATE['DATE_TYPE'] };
                        break;
                    case ENUM_DATA_NAME[ENUM_KEY_NUMBER]:
                        if (!!questionSelected.isMultiplier && questionSelected.isMultiplier.length > 0) {
                            questionUpdated = { ...questionSelected, answerDataType: value };
                        } else {
                            questionUpdated = { ...basicQuestionStructure, answerDataType: value, ...DATA_TYPE_INITIAL_STATE['NUMBER_RANGE'] };
                        }
                        break;
                    case ENUM_DATA_NAME[ENUM_KEY_SELECT]:
                    case ENUM_DATA_NAME[ENUM_KEY_RADIO]:
                        if (!!questionSelected?.answers?.length && (questionSelected?.answers[0]?.answer !== '' || questionSelected?.answers[1]?.answer !== '')) {
                            questionUpdated = { ...questionSelected, answerDataType: value };
                        } else {
                            questionUpdated = { ...basicQuestionStructure, answerDataType: value, ...DATA_TYPE_INITIAL_STATE['OPTION_TYPE'] };
                        }
                        break;
                    case ENUM_DATA_NAME[ENUM_KEY_CHECKBOX]:
                        if (
							!!questionSelected?.answers?.length &&
							(questionSelected?.answers[0]?.answer !== "" ||
								questionSelected?.answers[1]?.answer !== "")
						) {
							questionUpdated = {
								...questionSelected,
								answerDataType: value,
								minOptions: "",
								hasMinOptions: false,
								isDynamicQuestion: false
							};
							if (questionUpdated.dynamicQuesConditions) {
								delete questionUpdated.dynamicQuesConditions;
							}
						} else {
							questionUpdated = {
								...basicQuestionStructure,
								answerDataType: value,
								...DATA_TYPE_INITIAL_STATE["CHECKBOX-TYPE"]
							};
						}
                        break;
                };

                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);

                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };

                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);

                this.setState({ ...this.state, isEdited:true, sections: sectionArrayUpdated });
            },
            ['validate-duplicated-answer']: (sectionIndex, questionIndex, answerIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                const answerArray = questionSelected.answers;
                const answersToCompare = answerArray.map(answer => answer.answer);
                const arrayToCompare = new Set(answersToCompare);   
                const answerUpdated = { ...answerArray[answerIndex], isRepeated: (answerArray.length !== arrayToCompare.size), isEmpty: false };

                const newAnswerArray = arrayItemUpdater(answerArray, answerIndex, answerUpdated);

                const questionUpdated = { ...questionSelected, answers: newAnswerArray };

                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);

                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };

                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);

                this.setState({ ...this.state, sections: sectionArrayUpdated });
            },
            ['validate-empty-answer']: (sectionIndex, questionIndex, answerIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                const answerArray = questionSelected.answers;
                const answerUpdated = { ...answerArray[answerIndex], isEmpty: true };

                const newAnswerArray = arrayItemUpdater(answerArray, answerIndex, answerUpdated);

                const questionUpdated = { ...questionSelected, answers: newAnswerArray };

                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);

                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };

                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);

                this.setState({ ...this.state,  sections: sectionArrayUpdated });
            },
            ['answers']: (value, sectionIndex, questionIndex, answerIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                const answerArray = questionSelected.answers;
                const answerUpdated = { ...answerArray[answerIndex], answer: value };

                const newAnswerArray = arrayItemUpdater(answerArray, answerIndex, answerUpdated);

                const questionUpdated = { ...questionSelected, answers: newAnswerArray };

                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);

                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };

                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);

                this.setState({ ...this.state,isEdited:true, sections: sectionArrayUpdated });
            },
            ['edit-manufacturer']: (value, sectionIndex, questionIndex, answerIndex, manufacturerIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                const answerArray = questionSelected.answers;
                const productArray = answerArray[answerIndex].products;
                const newProduct = { ...productArray[manufacturerIndex], manufacturerPartNumber: value }
                const newProductArray = arrayItemUpdater(productArray, manufacturerIndex, newProduct);
                const answerUpdated = { ...answerArray[answerIndex], products: newProductArray };
                const newAnswerArray = arrayItemUpdater(answerArray, answerIndex, answerUpdated);

                const questionUpdated = { ...questionSelected, answers: newAnswerArray };

                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);

                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };

                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);

                this.setState({ ...this.state,isEdited:true, sections: sectionArrayUpdated });
            },
            ['manufacturerPartNumber']: (value, sectionIndex, questionIndex, answerIndex, manufacturerIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                const answersArray = questionSelected.answers;
                const answerSelected = answersArray[answerIndex];
                const mappedAnswersArray = questionSelected.mappedAnswers || [];
                const productArray = answersArray[answerIndex].products;
                const id = !!productArray[manufacturerIndex].productId ? productArray[manufacturerIndex].productId : randomId();
                const newProduct = { ...productArray[manufacturerIndex], productId: id };
                const newProductArray = arrayItemUpdater(productArray, manufacturerIndex, newProduct); 
                const newMappedAnswer = { answer: answerSelected.answer, manufacturerPartNumber: value, sectionIndex, questionIndex, answerIndex, productId: id, id: id };
                let newMappedAnswersArray = [];

                if (!!productArray[manufacturerIndex].productId) {
                    if (mappedAnswersArray.some(answer => answer.productId === id)) {
                        newMappedAnswersArray = mappedAnswersArray.map(mappedAnswer => mappedAnswer.productId === id ? newMappedAnswer : mappedAnswer);
                    } else {
                        newMappedAnswersArray = [...mappedAnswersArray, newMappedAnswer];
                    }
                } else {
                    newMappedAnswersArray = [...mappedAnswersArray, newMappedAnswer];
                }
                
                const answerUpdated = { ...answerSelected, products: newProductArray };
                const newAnswerArray = arrayItemUpdater(answersArray, answerIndex, answerUpdated);
                const questionUpdated = { ...questionSelected, answers: newAnswerArray, mappedAnswers: newMappedAnswersArray };
                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);
                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };
                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);
                this.setState({ ...this.state,isEdited:true, sections: sectionArrayUpdated });
            },
            ['add-manufacturerPartNumber']: (value, sectionIndex, questionIndex, answerIndex, manufacturerIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                const answersArray = questionSelected.answers;
                const answerSelected = answersArray[answerIndex];
                const mappedAnswersArray = questionSelected.mappedAnswers || [];
                const productArray = answersArray[answerIndex].products;
                const id = randomId();
                const newProduct = { manufacturerPartNumber: value, productId: id };
                const newProductArray = [...productArray, newProduct];
                const newMappedAnswer = { answer: answerSelected.answer, manufacturerPartNumber: value, sectionIndex, questionIndex, answerIndex, productId: id, id: id };
                const newMappedAnswersArray = [...mappedAnswersArray, newMappedAnswer];

                const answerUpdated = { ...answerSelected, products: newProductArray };
                const newAnswerArray = arrayItemUpdater(answersArray, answerIndex, answerUpdated);
                const questionUpdated = { ...questionSelected, answers: newAnswerArray, mappedAnswers: newMappedAnswersArray };
                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);
                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };
                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);
                this.setState({ ...this.state,isEdited:true, sections: sectionArrayUpdated });
            },
            ['removeManufacturerPartNumber']: ({ sectionIndex, questionIndex, answerIndex, productId, mapIndex }) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex];
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                const answersArray = questionSelected.answers;
                const answerSelected = answersArray[answerIndex];
                const manufacturerPartNumberArray = answerSelected.products || [];
                const newManufacturerPartNumberArray = manufacturerPartNumberArray.filter((manufacturerNumber) => manufacturerNumber.productId !== productId);
                const answerUpdated = { ...answerSelected, products: newManufacturerPartNumberArray };
                const mappedAnswersArray = questionSelected.mappedAnswers || [];
                const newMappedAnswersArray = mappedAnswersArray.length > 0 && mappedAnswersArray.filter((mappedAnswer, index) => index !== mapIndex);
                const newAnswerArray = arrayItemUpdater(answersArray, answerIndex, answerUpdated);

                const questionUpdated = { ...questionSelected, answers: newAnswerArray, mappedAnswers: newMappedAnswersArray };

                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);

                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };

                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);
                this.setState({ ...this.state, isEdited: true, sections: sectionArrayUpdated });
            },
            ['swapManufacturerPartNumber']: ({ sectionIndex, questionIndex, answerIndex, mapIndex, newRow, row, lastRowModified }) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex];
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                const answersArray = questionSelected.answers;
                const answersArrayUpdated = answersArray.map((answer, index) => {
                const answerIndexToCompare = Object.keys(lastRowModified).length > 0 ? lastRowModified.answerIndex : row.answerIndex;
                    if (answerIndexToCompare === index) {
                        const productIdToCompare = Object.keys(lastRowModified).length > 0 ? lastRowModified.productId : row.productId;
                        const manufacturerPartNumberArray = answer.products;
                        const newProductAray = manufacturerPartNumberArray.filter(product => product.productId !== productIdToCompare);
                        return { ...answer, products: newProductAray };
                    } else {
                        return answer;
                    }
                });
                const answerSelected = answersArrayUpdated[answerIndex];
                const manufacturerPartNumberArray = answerSelected?.products || [];
                const newProductArray = manufacturerPartNumberArray[0]?.manufacturerPartNumber !== '' ? [...manufacturerPartNumberArray, { manufacturerPartNumber: newRow.manufacturerPartNumber, productId: newRow.productId }] : [{ ...manufacturerPartNumberArray[0], manufacturerPartNumber: newRow.manufacturerPartNumber, productId: newRow.productId }];
                const answerUpdated = { ...answerSelected, products: newProductArray };
                const mappedAnswersArray = questionSelected.mappedAnswers;
                const newMappedAnswersArray = arrayItemUpdater(mappedAnswersArray, mapIndex, { ...newRow, manufacturerPartNumber: newRow.manufacturerPartNumber, answerIndex: answerIndex });
                const newAnswerArray = arrayItemUpdater(answersArrayUpdated, answerIndex, answerUpdated);
                const questionUpdated = { ...questionSelected, answers: newAnswerArray, mappedAnswers: newMappedAnswersArray };
                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);
                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };
                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);
                this.setState({ ...this.state, isEdited: true, sections: sectionArrayUpdated });
            },
            ['add-answer']: (sectionIndex, questionIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                const answerArray = questionSelected.answers;
                const newAnswer = { answer: '', sortOrder: (questionSelected.answers.length + 1), products: [], isRepeated: false };

                const questionUpdated = { ...questionSelected, answers: [...answerArray, newAnswer] };

                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);

                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };

                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);

                this.setState({ ...this.state,isEdited:true, sections: sectionArrayUpdated });
            },
            ['add-question']: (sectionIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const newId = randomId();
                const sectionUpdated = { ...sectionArray[sectionIndex], questions: [...questionArray, { ...QUESTION_STRUCTURE, sortOrder: (questionArray.length + 1), questionId: newId }] };

                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);

                const arrayWithNewQuestionOrder = this.updateQuestionOrder(sectionArrayUpdated);
                    
                this.setState({ ...this.state,isEdited:true, sections: this.updateMultipliers(arrayWithNewQuestionOrder) });
            },
            ['add-section']: () => {
                const sectionArray = this.state.sections;
                const newId = randomId();
                const newSectionArray = [...sectionArray, {...SECTION_STRUCTURE, questions:[{...SECTION_STRUCTURE.questions[0],questionId:randomId()}],sortOrder:(sectionArray.length + 1), sectionId:newId}];
                const arrayWithNewQuestionOrder = this.updateQuestionOrder(newSectionArray);
                const newExpandSectionsArray= [...this.state.expandSections];
                newExpandSectionsArray.push({ sectionId:newId, isExpanded:true });
                this.setState({ ...this.state,isEdited:true, sections: this.updateMultipliers(arrayWithNewQuestionOrder), expandSections:newExpandSectionsArray});
            },
            ['remove-section']: (sectionIndex) => {
                const sectionArray = [...this.state.sections];
                const newSectionArray = sectionArray.length > 1 && sectionArray.filter((section, index) => index !== sectionIndex);
                const newSectionArrayWithOrderUpdated = newSectionArray.map((section, index) => { return { ...section, sortOrder: (index + 1) } });

                const arrayWithNewQuestionOrder = this.updateQuestionOrder(newSectionArrayWithOrderUpdated);
                const newExpandSectionsArray = [...this.state.expandSections];
                newExpandSectionsArray.splice(sectionIndex, 1);
                this.setState({ ...this.state,isEdited:true, sections: this.updateMultipliers(arrayWithNewQuestionOrder), expandSections:newExpandSectionsArray });
            },
            ['remove-question']: (sectionIndex, questionIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const newQuestionArray = questionArray.length > 1 && questionArray.filter((question, index) => index !== questionIndex);
                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };

                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);

                const arrayWithNewQuestionOrder = this.updateQuestionOrder(sectionArrayUpdated);

                this.setState({ ...this.state,isEdited:true, sections: this.updateMultipliers(arrayWithNewQuestionOrder) });
            },
            ['remove-answer']: (sectionIndex, questionIndex, answerIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                const answerArray = questionSelected.answers;
                const newAnswerArray = answerArray.length > 1 && answerArray.filter((answer, index) => index !== answerIndex);
                const newAnswerArrayWithOrderUpdated = newAnswerArray.map((answer, index) => { return { ...answer, sortOrder: (index + 1) } });

                const questionUpdated = { ...questionSelected, answers: newAnswerArrayWithOrderUpdated };

                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);

                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };

                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);

                this.setState({ ...this.state,isEdited:true, sections: sectionArrayUpdated });
            },
            ['addEmptyMultiplier']: (sectionIndex, questionIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                const questionUpdated = { ...questionSelected, multipliers: [...questionSelected.multipliers, { questionSortOrder: '', questionId: '' }], isMultiplier: true };

                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);

                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };

                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);

                this.setState({ ...this.state,isEdited:true, sections: sectionArrayUpdated });
            },
            ['edit-numeric-multipliers']: (value, options, sectionIndex, questionIndex, multiplierIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                const multipliersArray = questionSelected.multipliers;
                const optionSelected = options.find((element) => element.value === value);
                const newMultiplier = { questionSortOrder: value, questionId: optionSelected.id, numberQuestionSortOrder: questionSelected.sortOrder, numberQuestionId: questionSelected.questionId }
                const newMultipliersArray = arrayItemUpdater(multipliersArray, multiplierIndex, newMultiplier);

                const questionUpdated = { ...questionSelected, multipliers: newMultipliersArray };

                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);

                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };

                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);

                this.setState({ ...this.state,isEdited:true, sections: sectionArrayUpdated });
            },
            ['delete-multiplier']: (sectionIndex, questionIndex, multiplierIndex) => {
                const sectionArray = [...this.state.sections];
                const sectionSelected = sectionArray[sectionIndex]
                const questionArray = sectionSelected.questions;
                const questionSelected = questionArray[questionIndex];
                const multipliersArray = questionSelected.multipliers;

                const newMultipliersArray = multipliersArray.filter((multiplier, index) => index !== multiplierIndex)

                const questionUpdated = { ...questionSelected, multipliers: newMultipliersArray, isMultiplier: newMultipliersArray.length === 0 ? false : questionSelected.isMultiplier };

                const newQuestionArray = arrayItemUpdater(questionArray, questionIndex, questionUpdated);

                const sectionUpdated = { ...sectionArray[sectionIndex], questions: newQuestionArray, };

                const sectionArrayUpdated = arrayItemUpdater(sectionArray, sectionIndex, sectionUpdated);

                this.setState({ ...this.state, isEdited:true, sections: sectionArrayUpdated });
            },
            [REMOVE_DYNAMIC_QUES_CONDITION]: ({ sectionIndex, questionIndex, dynamicQuestionId }) => {
               this.setState(
					produce((draft) => {
                        draft.isEdited = true;
						const question = draft.sections[sectionIndex].questions[questionIndex];
						question.dynamicQuesConditions = question.dynamicQuesConditions.filter(
							(condition) => condition.dynamicQuestionId !== dynamicQuestionId
						);
					})
				);
            },
            [EDIT_DYNAMIC_QUES_CONDITION]: ({ sectionIndex, questionIndex, updatedRow }) => {
                this.setState(
					produce((draft) => {
                        draft.isEdited = true;
						const question = draft.sections[sectionIndex].questions[questionIndex];
						question.dynamicQuesConditions = question.dynamicQuesConditions.map(
							(condition) =>
								condition.dynamicQuestionId === updatedRow.dynamicQuestionId
									? {
											...condition,
											answer: updatedRow.answer,
											operation: updatedRow.operation,
											question: updatedRow.thenDisplay.question,
											questionId: updatedRow.thenDisplay.questionId
									  }
									: condition
						);
					})
				);
             },
             [SET_EXPAND_SECTION]:({sectionId, isExpanded})=>{     
               this.setState(
					produce((draft) => {
                        const sectionExpandState = draft.expandSections.find(
							(section) => section.sectionId === sectionId
						);
						if (sectionExpandState) {
							sectionExpandState.isExpanded = isExpanded;
						}
					})
				); 
             },
             [EXPAND_ALL_SECTION]: () => {
                this.setState(
					produce((draft) => {
						draft.expandSections.forEach((section) => {
							section.isExpanded = true;
						});
					})
				);
             },
             [COLLAPSE_ALL_SECTION]: () => {
                    this.setState(
						produce((draft) => {
							draft.expandSections.forEach((section) => {
								section.isExpanded = false;
							});
						})
					);
             },
    }

    setForm = (newForm) => {
        this.setState(newForm);
    }



    updateQuestionOrder = (sectionsToUpdate) => {
        const sectionsArray = [...sectionsToUpdate];
        let questionsNumber = 0;

        const newSectionsArray = sectionsArray.map((sectionSelected) => {

            const newQuestionArray = sectionSelected.questions.map((questionSelected) => {
                questionsNumber++;
                return { ...questionSelected, sortOrder: questionsNumber };
            });

            return { ...sectionSelected, questions: newQuestionArray };
        });

        return newSectionsArray;
    }

    updateMultipliers = (sectionsToUpdate) => {
        const sectionsArray = [...sectionsToUpdate];
        const allSectionsQuestions = this.getQuestions(sectionsArray);

        const newSectionsArray = sectionsArray.map((sectionSelected) => {

            const newQuestionArray = sectionSelected.questions.map((questionSelected) => {
                if (questionSelected.answerDataType === ENUM_DATA_NAME[ENUM_KEY_NUMBER]) {
                    const newMultipliers = questionSelected.multipliers.map((multiplierSelected) => {
                        if (multiplierSelected.questionSortOrder !== '') {
                            const answerUpdated = allSectionsQuestions.find((element) => element.questionId === multiplierSelected.questionId);
                            const questionNumberUpdated = allSectionsQuestions.find((element) => element.questionId === multiplierSelected.numberQuestionId);
                            return { ...multiplierSelected, questionSortOrder: answerUpdated.sortOrder, numberQuestionSortOrder: questionNumberUpdated.sortOrder };
                        } else {
                            return multiplierSelected;
                        }
                    });
                    return { ...questionSelected, multipliers: newMultipliers };
                }
                return { ...questionSelected };
            });
            return { ...sectionSelected, questions: newQuestionArray };
        });

        return newSectionsArray;
    }

    getAllQuestionWithoutNumericField = (sections = this.state.sections) => {
        let questionsArray = [];

        for (let sectionSelected of sections) {
            const questions = sectionSelected.questions;
            for (let questionSelected of questions) {
                (questionSelected.answerDataType === ENUM_DATA_NAME[ENUM_KEY_CHECKBOX] || questionSelected.answerDataType === ENUM_DATA_NAME[ENUM_KEY_RADIO] || questionSelected.answerDataType === ENUM_DATA_NAME[ENUM_KEY_SELECT]) && questionsArray.push({ value: questionSelected.sortOrder, label: `${questionSelected.sortOrder}. ${questionSelected.question}`, id: questionSelected.questionId });
            }
        };
        return questionsArray;
    }

    getQuestions = (sections = this.state.sections) => {
        let questionsArray = [];

        for (let sectionSelected of sections) {
            const questions = sectionSelected.questions;
            for (let questionSelected of questions) {
                questionsArray.push(questionSelected);
            }
        };
        return questionsArray;
    }

    getAllQuestionWithMultipliers = () => {
        const { sections } = this.state;
        let multipliersArray = [];

        for (let sectionSelected of sections) {
            const questions = sectionSelected.questions;
            for (let questionSelected of questions) {
                if (questionSelected.answerDataType === ENUM_DATA_NAME[ENUM_KEY_NUMBER]) {
                    multipliersArray = [...multipliersArray, ...questionSelected.multipliers];
                }
            }
        };

        const newMultipliersArrayWithoutDuclicate = new Set(multipliersArray)
        return newMultipliersArrayWithoutDuclicate;
    }



    render() {
        const { children } = this.props;
        const { sections, isEdited, isSendable } = this.state;
        const { formActions, setForm, getAllQuestionWithoutNumericField, getAllQuestionWithMultipliers } = this;

        return (
            <FormContext.Provider value={{ formState: this.state, sections, isEdited, isSendable, formActions, setForm, getAllQuestionWithoutNumericField, getAllQuestionWithMultipliers }}>
                { children }
            </FormContext.Provider>
        );
    }

}

export default FormContext;

export { FormProvider };
