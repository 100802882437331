import {DropdownCustomizer} from "js/jsx/src/classes/forms/customization.jsx";
import {FormFieldValidationTooltip} from "js/jsx/src/classes/forms.jsx";

export class EditCustomField extends React.Component {
    constructor(props) {
        super(props);

        var isDirty = false;
        var fieldInfo = this.props.fieldInfo;
        var fieldConfig = quosal.customization.fields.getFieldConfiguration(this.props.fieldObjType, this.props.fieldObjName, fieldInfo.FieldName);
        var displayName = fieldInfo.DisplayName;
        if (fieldConfig && fieldConfig.FieldRename) {
            displayName = fieldConfig.FieldRename;
        }

        var fieldShortName = (fieldConfig && fieldConfig.FieldShortName) || "";

        this.state = {
            fieldName: displayName,
            fieldShortName: fieldShortName,
            fieldConfig: fieldConfig,
            noteField: "",
            noteError: "",
        };

        // This binding is necessary to make `this` work in the callback
        this.handleTextChanged = this.handleTextChanged.bind(this);
        this.noteFocus = this.noteFocus.bind(this);
        this.noteBlur = this.noteBlur.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.toggleDropdownVisibility = this.toggleDropdownVisibility.bind(this);
        this.resetFieldName = this.resetFieldName.bind(this);
    }
    static open(objType, objName, field, updateCallback) {
            var auditComp;

            var saveChanges = function saveChanges() {
                return auditComp.saveChanges();
            };

            var dialogClose = function dialogClose() {
                Dialog.close();
            };
            var loadAuditTrail = quosal.api.customization.GetFieldConfigurationLog(objName, field.FieldName)
            loadAuditTrail.finished = function loadAuditTrailFinished(msg) {
                Dialog.open({
                    height: '50%',
                    width: "60%",
                    title:'Customize Field',
                    message: <EditCustomField updateCallback={updateCallback} fieldObjType={objType} fieldObjName={objName} fieldInfo={field} 
                                fieldConfigurationLog={msg.FieldConfigurationLog} ref={comp => auditComp = comp} />,
                    closeRequiresButton: true,
                    links: [{
                        title: 'Save',
                        callback: saveChanges
                    }, {
                        title: 'Cancel',
                        callback: dialogClose
                    }]
                });
            }
            loadAuditTrail.call();
    }
    handleTextChanged(e) {
        if (e.target.id == "fieldName") {
            this.setState({ fieldName: e.target.value });
        }
        else if (e.target.id == "fieldShortName") {

            this.setState({ fieldShortName: e.target.value })
        }
        else if (e.target.id == "note") {
            this.setState({ noteField: e.target.value })
        }

        $('#fieldName').removeClass('redborder');
        this.isDirty = true;
    }
    noteFocus() {
        if (this.state.noteError) {
            $(".tooltip").show();
        }
    }
    noteBlur() {
        if (this.state.noteError) {
            $(".tooltip").hide();
        }
    }
    UNSAFE_componentWillMount(){
        document.addEventListener("keydown", this._handleKeyDown);
    }
    componentWillUnmount(){
        this.props.updateCallback();
        document.removeEventListener("keydown", this._handleKeyDown);
    }
    saveChanges(callback) {
        var note = this.state.noteField
        if ($.trim(note) == '') {
            $('#note').addClass('redborder');
            this.setState({ noteError: <FormFieldValidationTooltip className="noteError" validation={{ message: "Description field required" }} field={this} /> });
            return true;
        }

        var rename = this.state.fieldName; 
        var shortName = this.state.fieldShortName; 
        var dropdowns = ['EMPTY'];
        if (DropdownCustomizer.customDropdown && DropdownCustomizer.customDropdown.length > 0 && (DropdownCustomizer.customDropdown.length > 1 || (DropdownCustomizer.customDropdown[0].Value || DropdownCustomizer.customDropdown[0].Label))){
            dropdowns = DropdownCustomizer.customDropdown;
        }
        var saveFieldApi = quosal.api.customization.saveFieldConfiguration(this.props.fieldObjType, this.props.fieldObjName, this.props.fieldInfo.FieldName, rename, note, shortName, dropdowns, true);
        Dialog.setIsWorking(true);
        saveFieldApi.finished = function (msg) {
            quosal.customization.fields.updateFieldConfiguration(this.props.fieldObjType, this.props.fieldObjName, this.props.fieldInfo.FieldName, msg.fieldConfig);
            Dialog.setIsWorking(false);
            Dialog.close();
        }.bind(this);
        saveFieldApi.call();
    }
    toggleDropdownVisibility(){
        $("#dropdowncustomizer").toggle();
    }
    resetFieldName() {
        this.setState({
            fieldName: this.props.fieldInfo.DisplayName,
            noteField: 'Reset to Default'
        });
    }
    _handleKeyDown(e) {
        switch(e.keyCode) {
            case 13: 
                this.saveChanges();
                break;
            case 27:
                Dialog.close();
                break;
            default:
                break;
        }
        
    }
    toggleViewHistory(){
        $("#auditPanel").toggle();
    }
    render() {
        var customizer;
        var dropdownHeader;
        var dropdownEditButton;
        var dropdownCol;
        var dropdownTD;
        var hasDropdownFields = false;
        if (this.props.fieldInfo.CanCustomizeDropdown) {
            customizer = (<div id="dropdowncustomizer" style={{display:'none'}}><DropdownCustomizer objectName={this.props.fieldObjName} objectType={this.props.fieldInfo.FieldName} 
                            fieldConfig={this.state.fieldConfig} fieldName={this.props.fieldInfo.FieldName} field={this.props.fieldInfo} displayName={this.props.fieldInfo.DisplayName} />
                          </div>);
            dropdownHeader = <th>Dropdown Fields</th>
            dropdownCol = <col style={{ width: "auto" }} />;
            dropdownTD = <td />
            hasDropdownFields = true;

             dropdownEditButton = <td><button onClick={this.toggleDropdownVisibility}>Edit Dropdowns</button></td>
        }

        var resetFieldNameButton;
        if (this.state.fieldConfig && this.state.fieldConfig.FieldRename && this.state.fieldConfig.FieldRename != this.props.fieldInfo.DisplayName) {
            resetFieldNameButton = <tr><td><button onClick={this.resetFieldName}>Reset Field name to Default</button></td></tr>
        }
        var auditRows;
        var auditHistoryButton;
        if (this.props.fieldConfigurationLog && this.props.fieldConfigurationLog.length > 0) {
            var dropdownText;
            auditHistoryButton = <button style={{margin:"5px 0 15px 5px"}} onClick={this.toggleViewHistory}>View History</button>;
            auditRows = this.props.fieldConfigurationLog.map(function (field, index) {
                if (hasDropdownFields) {
                    dropdownText = (field.CustomDropdownContents || []).reduce(function (output, val) {
                        output.push(val.Label + " (" + val.Value + ")");
                        return output;
                    }, []).join(", ");
                    dropdownText = <td style={{width:'calc(100% - 30px)'}}>{dropdownText}</td>
                }
                return (
                    <tr className="auditrow" key={index}>
                        <td style={{width:'calc(100% - 30px)'}} className="fieldname">{field.FieldRename}</td>
                        <td style={{width:'calc(100% - 30px)'}}>{field.FieldShortName}</td>
                        {dropdownText}
                        <td style={{width:'calc(100% -30px)'}}>{field.Note}</td>
                        <td style={{width:'calc(100% - 30px)'}}>{field.UserName}</td>
                        <td style={{width:'calc(100% - 30px)'}}>{field.ModifiedDate ? field.ModifiedDate : ""}</td>
                    </tr>
                );
            });
        }
        var panelTitle = 'Edit Field (' + this.props.fieldInfo.DisplayName + ')';

        return (
            <div style={{textAlign:"left"}}>
                <div className="col">
                    {customizer}
                </div>
                <Panel title={panelTitle}>
                    <PanelContent>
                        <table id="editfield">
                            <colgroup>
                                <col style={{ width: "35%" }} />
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "33%" }} />
                                <col style={{ width: "auto" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th><label>Field Name</label></th>
                                    <th><div title="If set, table headers will dispay short name instead of field name"><label>Short Name</label></div></th>
                                    <th><div title="* limit 255 characters"><label>Reason For Change</label></div></th>
                                    {dropdownHeader}
                                    <th><label /></th>
                                    <th><label /></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><input id="fieldName" style={{width:'calc(100% - 30px)'}} type='text' onChange={this.handleTextChanged} value={this.state.fieldName} /></td>
                                    <td><input id="fieldShortName" style={{width:'calc(100% - 30px)'}} type='text' onChange={this.handleTextChanged} value={this.state.fieldShortName} /></td>
                                    <td style={{ position: 'relative' }}>
                                        <textarea style={{width:'calc(100% - 30px)'}} id="note" onChange={this.handleTextChanged} onFocus={this.noteFocus} onBlur={this.noteBlur} value={this.state.noteField} type='text' />
                                        {this.state.noteError}
                                    </td>
                                    {dropdownEditButton}
                                </tr>
                                {resetFieldNameButton}
                            </tbody>
                        </table>
                    </PanelContent>
                </Panel>
                {auditHistoryButton}
                <Panel style={{display:'none'}} id="auditPanel" title="Audit Trial">
                    <PanelContent>
                    <table id="auditTable">
                            <colgroup>
                                <col style={{ width: "20%" }} />
                                <col style={{ width: "10%" }} />
                                {dropdownCol}
                                <col style={{ width: "30%" }} />
                                <col style={{ width: "10%" }} />
                                <col style={{ width: "10%" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th><label>Field Name</label></th>
                                    <th><div title="If set, table headers will dispay short name instead of field name"><label>Short Name</label></div></th>
                                    {dropdownHeader}
                                    <th><label>Reason For Change</label></th>
                                    <th><label>User</label></th>
                                    <th><label>Date</label></th>
                                </tr>
                            </thead>
                            <tbody>
                                {auditRows}
                                <tr className="auditrow originalname">
                                    <td className="fieldname">{this.props.fieldInfo.DisplayName}</td>
                                    <td />
                                    <td>Original Name</td>
                                    <td />
                                    {dropdownTD}
                                    <td />
                                </tr>
                            </tbody>   
                    </table>
                    </PanelContent>
                </Panel>
            </div>
        );
    }
}