quosal.api.visualQuote = {};


quosal.api.visualQuote.getTabItems = function (idVisualHotSpot, idQuoteMain) {
    return quosal.api.call('VisualQuote.GetTabItems', {
        idVisualHotSpot: idVisualHotSpot,
        idQuoteMain: idQuoteMain
    });
};


quosal.api.visualQuote.saveTabItems = function (idVisualHotSpot, idQuoteMain, selectedItemIds, unSelectedItemMfps) {
    return quosal.api.call('VisualQuote.SaveTabItems', {
        idVisualHotSpot: idVisualHotSpot,
        idQuoteMain: idQuoteMain,
        selectedItemIds: selectedItemIds,
        unSelectedItemMfps: unSelectedItemMfps
    });
};
