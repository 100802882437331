import { useTheme, Typography, Divider, Stack } from "@mui/material";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { QuoteName } from "./QuoteName";
import { BannerComponent } from "./BannerComponent";
import { useQuoteContext } from "../context";
import { Loader } from "../common/components";
import { payloadGenerator, updateData } from "../services";
import { getPublishText, isPublishItemClickable } from "../utils";
import { LaunchOutlined } from "@mui/icons-material";
import { QuoteImage } from "./QuoteImage";

export const QuoteNameContainer = () => {
	const [focus, setFocus] = useState(false);
	const [disableUnderline, setDisableUnderline] = useState(true);
	const theme = useTheme();
	const nameRef = useRef(null);
	const quote = useQuoteContext();
	const { QuoteName: name, IdQuoteMain } = quote;
	const [quoteName, setQuoteName] = useState(name);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setDisableUnderline(!focus);
	}, [focus]);

	useEffect(() => {
		setQuoteName(app.currentQuote.QuoteName);
	}, [app.currentQuote.QuoteName]);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [quoteName]);

	const handleBlur = () => {
		if (quoteName !== app.currentQuote.QuoteName) {
			setLoading(true);
			updateData(
				payloadGenerator({ name: "IdQuoteMain", value: IdQuoteMain }, "QuoteMain", {
					QuoteName: quoteName
				})
			).then(async (res) => {
				const { response0: { error } = [] } = res;
				const { response0 } = res;
				if (error) {
					console.error("Error:", error);
					setQuoteName(app.currentQuote.QuoteName);
					setLoading(false);
					return;
				}
				quosal.sell.quote.updateFromApiResponse(response0);
				const errorElement =  document.querySelector("#QuoteEdit_QuoteName")?.previousElementSibling;
				setQuoteName(quoteName);
				if(errorElement){
					errorElement.style.display = 'none';
				}
				setLoading(false);
			});
		}
		setFocus(false);
	};

	const handleClickOutside = (e) => {
		if (nameRef.current && !nameRef.current.contains(e.target)) {
			setFocus(false);
			handleBlur();
			setDisableUnderline(true);
		}
	};
	const publishQuote = (quoteData) => {
		window.open(
			`${quoteData.OrderPorterLink}&novisit=true`,
			quoteData.IsOrderPorterUploaded ? "_blank" : "_self"
		);
	};

	const onPublishStatusClick = useCallback(() => {
		if (isPublishItemClickable(quote)) {
			publishQuote(quote);
		}
	}, [quote]);

	return (
		<>
			<Stack
				divider={<Divider orientation="vertical"
					sx={{
						display: { sm: "none", xs: "none", md: "none", lg: "none", xl: "block" },
						color: theme.palette.divider,
						background: theme.palette.divider,
						height: "16px",
						margin: "8px",
					}} />}
				direction={{ lg: "column", xl: "row" }}

				sx={{
					height: { lg: "82px", xl: "41px" },
					alignItems: "center",
					backgroundColor: theme.palette.background.paper,
					alignItems: { lg: "start", xl: "center" },
					justifyContent: { lg: "center", xl: "start" },
				}}
			>
				<Stack direction={"row"} alignItems={"center"} >
					<QuoteImage />
					<Stack ref={nameRef}>
						<QuoteName
							setFocus={setFocus}
							setQuoteName={setQuoteName}
							focus={focus}
							quoteName={quoteName}
							setDisableUnderline={setDisableUnderline}
							disableUnderline={disableUnderline}
							handleBlur={handleBlur}
						/>


					</Stack>
				</Stack>

				<Stack direction={"row"} alignItems={"center"} gap="8px" >
					<Stack direction={'row'} onClick={onPublishStatusClick}
						sx={{
							whiteSpace: "nowrap",
							flexWrap: "nowrap",
							alignItems: "center",
							display: "flex",
							padding: { lg: "4px 0 4px 13px", xl: "4px 0 4px 0px" }
						}}
						gap="8px">

						<Typography
							data-testid="publish-quote"
							sx={{
								fontWeight: "600",
								lineHeight: "18px",
								fontSize: "14px",
								letterSpacing: "0.32px",
								color: isPublishItemClickable(quote) ? theme.palette.primary.main : theme.palette.text.disabled,
								cursor: "pointer"
							}} title={getPublishText(quote)}>
							{getPublishText(quote)}
						</Typography>
						<LaunchOutlined sx={{ color: isPublishItemClickable(quote) ? theme.palette.primary.main : theme.palette.text.disabled, fontSize:16 }} />
					</Stack>
					<BannerComponent />

				</Stack>
			</Stack>
			<Loader loading={loading} loadingText={"Saving"} />
		</>
	);
};
