import { createContext, useContext } from "react";

const DispatchContext = createContext();

const DispatchProvider = ({ dispatch, children }) => {
	return <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>;
};

const useDispatchContext = () => {
	const dispatch = useContext(DispatchContext);
	if (dispatch === undefined) {
		console.error("useDispatchContext must be used inside dispatchProvider");
	}
	return dispatch;
};

export { DispatchProvider, useDispatchContext };