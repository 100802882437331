import { createContext, useEffect, useState, useCallback } from "react";

const IdentifyContext = createContext();
export const useIdentifyContext = () => React.useContext(IdentifyContext);


export const IdentifyProvider = ({updateChildren, children}) => {
    const [hasIdentifyWarning, setHasIdentifyWarning] = useState(false);

    let firstLoadTime = null;
    if (!firstLoadTime) {
        firstLoadTime = performance.now();
    }
    
    useEffect(() => {
        $(function () {
            app.callIdentifyApi({}, setHasIdentifyWarning);
        });
    }, []);
   
    useEffect(() => {
        if (hasIdentifyWarning && (performance.now() - firstLoadTime) < 2000) {
            updateChildren();
            setHasIdentifyWarning(false);
        }
    }, [hasIdentifyWarning]);

    const applyUpdatedSettings = useCallback(() => {
        updateChildren();
        setHasIdentifyWarning(false);
    }, [updateChildren]);

    return <IdentifyContext.Provider value={{
        hasIdentifyWarning,
        applyUpdatedSettings
    }}>
        {children}
    </IdentifyContext.Provider>;
};

IdentifyProvider.Consumer = IdentifyContext.Consumer;


