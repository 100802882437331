import React from "react";

import { AppDataProvider } from "./globalStates/appDataProvider";
import ProductSearchPage from "./productSearchPage/productSearchPage";
import ProductSearchProductCart from "./productCartPage/productSearchProductCart";
import ProductDetailsPage from "./productDetailsPage/productDetailsPage";
import Theme from "./styles/theme";
import "./styles/main.css";

function ProductSearchMain() {
	return (
		<Theme>
			<AppDataProvider>
				<AppDataProvider.Consumer>
					{({ currentPage }) => {
						if (currentPage.PRODUCT_CART_PAGE) {
							return <ProductSearchProductCart />;
						}
						if (currentPage.PRODUCT_DETAILS_PAGE) {
							return <ProductDetailsPage />;
						}
						if (currentPage.SEARCH_RESULT_PAGE) {
							return <ProductSearchPage />;
						}
					}}
				</AppDataProvider.Consumer>
			</AppDataProvider>
		</Theme>
	);
}

export default ProductSearchMain;
