import { useState, useEffect, useContext, useRef } from "react";
import { Grid, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelIcon from "@mui/icons-material/Close";
import { GridRowModes, DataGrid, GridActionsCellItem,GridRowEditStopReasons,GridRowEditStartReasons } from "@mui/x-data-grid";

import { ConfirmationModal } from "../../../../common/guidedSelling/components";
import FormContext from "../../contexts/configureFormContext";
import { EDIT_DYNAMIC_QUES_CONDITION, REMOVE_DYNAMIC_QUES_CONDITION } from "../../actions/formActions";
import { thenDisplayQuestionFormatter } from "./common";
import { EditThenDisplayValueCell } from "./EditThenDisplayValueCell";
import { EditInputValueCell } from "./EditInputValueCell";
import { EditOperationCell } from "./EditOperationCell";

export const DynamicQuestionConditionsTable = ({
	dynamicQuestionLists,
	isActive,
	currentSectionIndex,
	currentQuestionIndex,
	currentQuestion,
	isDynamicQuestion
}) => {
	const [stateData, setStateData] = useState({
		rows: [],
		rowModesModel: {},
		isValid: true,
		invalidRowId: "",
		lastRowModified: {},
		isEditing: false,
		answerIndex: "",
	});

	const formContext = useContext(FormContext);
	const theme = useTheme();
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
	const [currentCondition, setCurrentCondition] = useState(null);
	const disableEdit = !isActive || !isDynamicQuestion;
	const [isConditionRepeated, setIsConditionRepeated] = useState(false);
	const isConditionRepeatedRef= useRef(false); 
	const isSaveBtnDisabled = disableEdit || isConditionRepeated ;
	const [activeEditModeRow, setActiveEditModeRow] = useState(null);

	const handleIsConditionRepeated = val =>  isConditionRepeatedRef.current = val ;
	
	const deleteConditionConfirmationText = (
		<>
			Do you want to delete this Dynamic Condition? <br />
			This action cannot be undone.
		</>
	);

	const { formActions, formState: { sections } } = formContext;
	const questions = sections.flatMap((section) => section.questions);

	const formatedDQuestionList = dynamicQuestionLists.map(
		({ answer, dynamicQuestionId, operation, questionId }) => {
			const question = questions.find((question) => question.questionId === questionId);
			if (!question) {
				console.error("Question not found for dynamicQuestionId:", dynamicQuestionId);
			}
			const questionText = question?.question || "";
			const sortOrder = question?.sortOrder || "";

			return {
				answer,
				dynamicQuestionId,
				operation,
				thenDisplay: { questionId, sortOrder, question: questionText }
			};
		}
	);

	useEffect(() => {
		setStateData((prevState)=>({ ...prevState, rows: formatedDQuestionList }));
	}, [JSON.stringify(formatedDQuestionList)]);

	const { rows, rowModesModel, lastRowModified, invalidRowId, isValid, isEditing } = stateData;

	const inputQuestionOptions = sections
	.flatMap((section) => section.questions)
	.filter((question) => question.sortOrder !== currentQuestion.sortOrder && question.isActive)
	.map((question) => ({
		label: `${question.sortOrder}. ${question.question}`,
		value: {
			questionId: question.questionId,
			question: question.question,
			sortOrder: question.sortOrder
		}
	}));

	const handleRowEditStart = (params, event) => {
		if (params.reason === GridRowEditStartReasons.printableKeyDown || params.reason === GridRowEditStartReasons.deleteKeyDown) {
			event.defaultMuiPrevented = true;
		}
		if (params.reason === GridRowEditStartReasons.cellDoubleClick) {
			editableRow(params.id);
		}
		if (params.reason === GridRowEditStartReasons.enterKeyDown) {
			editableRow(params.id);
		}
	};

	const handleRowEditStop = (params, event) => {
		if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
			handleCancelClick(params.id)()
		}
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true;
		}
		if (params.reason === GridRowEditStopReasons.enterKeyDown) {
			if (isConditionRepeatedRef.current) {
				event.defaultMuiPrevented = true;
			} else {
				handleSaveClick(params.id)()
			}
		}
	};

	const handleEditClick = (id) => () => {
		setActiveEditModeRow(id);
		isEditing === false &&
			setStateData((prevState)=>({
				...prevState,
				rowModesModel: { ...rowModesModel, [id]: { mode: GridRowModes.Edit } },
				isEditing: true
			}));
	};

	const handleSaveClick = (id) => () => {
		setActiveEditModeRow(null)
		setStateData((prevState)=>({
			...prevState,
			isEditing: false,
			rowModesModel: { ...rowModesModel, [id]: { mode: GridRowModes.View } },
		}));
	};

	const handleCancelClick = (id) => () => {
		setActiveEditModeRow(null)
		if (isConditionRepeated) {
			setIsConditionRepeated(false);
		}
		if (Object.keys(lastRowModified).length > 0) {
			const newRowsArray = rows.map((row) =>
				row.id === invalidRowId ? { ...lastRowModified } : row
			);
			setStateData((prevState)=>({
				...prevState,
				rows: newRowsArray,
				rowModesModel: {
					...rowModesModel,
					[id]: { mode: GridRowModes.View, ignoreModifications: true }
				},
				invalidRowId: "",
				isValid: true,
				lastRowModified: {},
				isEditing: false
			}));
		} else {
			setStateData((prevState)=>({
				...prevState,
				rowModesModel: {
					...rowModesModel,
					[id]: { mode: GridRowModes.View, ignoreModifications: true }
				},
				invalidRowId: "",
				isValid: true,
				lastRowModified: {},
				isEditing: false
			}));
		}

		const editedRow = rows.find((row) => row.dynamicQuestionId === id);
		if (editedRow?.isNew) {
			setStateData((prevState)=>({
				...prevState,
				rows: rows.filter((row) => row.id !== id)
			}));
		}
	};

	const processRowUpdate = (updatedRow) => {
		setStateData((prevState)=>({
			...prevState,
			rows: rows.map((row) => {
				if (row.dynamicQuestionId === updatedRow.dynamicQuestionId) {
					formActions[EDIT_DYNAMIC_QUES_CONDITION]({
						sectionIndex: currentSectionIndex,
						questionIndex: currentQuestionIndex,
						updatedRow
					});
					return updatedRow;
				}
				return row;
			})
		}));
		return updatedRow;
	};

	const handleRowModesModelChange = (newRowModesModel) => {
		setStateData((prevState)=>({
			...prevState,
			rowModesModel: newRowModesModel
		}));
	};

	const renderSelectEditOperation = (params) => {
		return <EditOperationCell {...params}/>
	};

	const extendedProps = {
		error: !isValid,
		invalidrowid: invalidRowId,
		formatedDQuestionList,
		handleIsConditionRepeated,
		setIsConditionRepeated
	}

	const renderEditInputValue = (params) => {
		const newParams = {
			...params,
			...extendedProps,
			currentQuestion,
		};
		return <EditInputValueCell {...newParams}/>;
	};

	const renderEditThenDisplayValue = (params) => {
		const newParams = {
			...params,
			...extendedProps,
			inputQuestionOptions,
		};
		return <EditThenDisplayValueCell {...newParams}/>;
	};

	const onConditionDelete = (currentRow) => {
		setCurrentCondition(currentRow);
		setIsOpenDeleteModal(true);
	};

	const closeDeleteConditionModal = () => {
		setIsOpenDeleteModal(false);
	};

	const isCellEditable = (rowId) => { 
		if (activeEditModeRow) {
			if (rowId === activeEditModeRow) {
				return true;
			} else {
				return false;
			}
		}
		return true;
	}

	const editableRow = rowId =>  handleEditClick(rowId)()

	const handleConditionDelete = () => {
		formActions[REMOVE_DYNAMIC_QUES_CONDITION]({
			sectionIndex: currentSectionIndex,
			questionIndex: currentQuestionIndex,
			dynamicQuestionId: currentCondition.dynamicQuestionId
		});
		closeDeleteConditionModal();
	};

	const columns = [
		{
			field: "operation",
			headerName: "Operation",
			disabled: true,
			flex: 1,
			renderEditCell: renderSelectEditOperation,
			editable: !disableEdit,
			disableColumnMenu: disableEdit,
			sortable: !disableEdit,
			valueFormatter:(value, row, column, apiRef) => {
				if (value) {
					return value.charAt(0).toUpperCase() + value.slice(1);
				} else {
					return row?.operation.charAt(0).toUpperCase() + row?.operation.slice(1);
				}
			}
		},
		{
			field: "answer",
			headerName: "Input Value",
			flex: 1,
			align: "left",
			headerAlign: "left",
			disabled: true,
			disableColumnMenu: disableEdit,
			sortable: !disableEdit,
			editable: !disableEdit,
			renderEditCell: renderEditInputValue
		},
		{
			field: "thenDisplay",
			headerName: "Then Display",
			flex: 2,
			align: "left",
			disabled: true,
			headerAlign: "left",
			disableColumnMenu: disableEdit,
			sortable: !disableEdit,
			editable: !disableEdit,
			renderEditCell: renderEditThenDisplayValue,
			valueGetter: (value, row, column, apiRef) => ({
				questionId: row?.thenDisplay?.questionId,
				question: row?.thenDisplay?.question,
				sortOrder: row?.thenDisplay?.sortOrder
			}),
			valueFormatter:(value, row, column, apiRef) => {
				if (value) {
					return thenDisplayQuestionFormatter(value.sortOrder, value.question);
				}
				else {
					return thenDisplayQuestionFormatter(row?.sortOrder, row?.question);
				}
			}
		},
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 100,
			cellClassName: "actions",
			align: "right",
			getActions: ({ id, row }) => {
				const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={<SaveOutlinedIcon />}
							label="Save"
							//hide edit button on duplicate condition
						    disabled={isSaveBtnDisabled}
							sx={{
								color: "button_primary.main",
								minWidth: "32px",
								minHeight: "32px"
							}}
							onClick={handleSaveClick(id)}
						/>,
						<GridActionsCellItem
							icon={<CancelIcon />}
							label="Cancel"
							disabled={disableEdit}
							sx={{
								color: "button_danger.main",
								minWidth: "32px",
								minHeight: "32px"
							}}
							onClick={handleCancelClick(id)}
						/>
					];
				}

				return [
					<GridActionsCellItem
						disabled={isEditing || disableEdit}
						icon={<EditIcon />}
						label="Edit"
						sx={{
							color: "button_primary.main",
							minWidth: "32px",
							minHeight: "32px"
						}}
						onClick={handleEditClick(id)}
					/>,
					<GridActionsCellItem
						disabled={isEditing || disableEdit}
						icon={<DeleteIcon />}
						label="Delete"
						sx={{
							color: "button_danger.main",
							minWidth: "32px",
							minHeight: "32px"
						}}
						onClick={() => onConditionDelete(row)}
						data-testid="delete-condition"
					/>
				];
			}
		}
	];

	return (
		<Grid
			item
			xs={12}
			sx={{
				marginTop: "10px",
				"& .actions": {
					color: "text.secondary"
				},
				"& .textPrimary": {
					color: "text.primary"
				},
				"& .MuiDataGrid-row--editing ": {
					"& .MuiDataGrid-cell": {
						backgroundColor: theme.palette.layer.layer1 + " !important"
					}
				},
				"& .MuiDataGrid-cell--editable": {
					padding: "0",
					"& .MuiInputBase-root": {
						height: "100%",
						"& .MuiInputBase-input": {
							padding: "0 10px",
							border: "1px solid",
							borderRadius: "6px",
							color: theme.palette.border.border_strong1,
							borderColor: theme.palette.border.border_strong1,
							background: theme.palette.layer.layer2
						}
					},
					"& .MuiInputBase-input": {
						height: "100%"
					}
				},
				"& .Mui-error": {
					border: `2px solid #ec0001`,
					color: "#750f0f"
				},
				"& .MuiDataGrid-root": {
					color: disableEdit ? theme.palette.text.disabled : theme.palette.text.main
				}
			}}
		>
			<DataGrid
				rows={rows}
				columns={columns}
				editMode="row"
				getRowId={(row) => row?.dynamicQuestionId}
				rowModesModel={rowModesModel}
				onRowModesModelChange={handleRowModesModelChange}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				hideFooterPagination
				hideFooterSelectedRowCount
				hideFooter
				isCellEditable={(params) => {
					return isCellEditable(params.id);
				}}
				sx={{
					border: 0,
					background: "layer.layer1",
					"& .MuiDataGrid-columnHeaders": {
						borderTop: "none",
					},
					"& .MuiDataGrid-columnHeaderTitle": {
						fontWeight: "600",
					},
					"& .MuiDataGrid-cell.MuiDataGrid-cell--editing ": {
						padding: "4px",
						background: theme.palette.layer.layer1
					},
					"& .MuiDataGrid-cell.MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within ":
						{
							outline: "none"
						}
				}}
				onRowEditStart={handleRowEditStart}
			/>
			<ConfirmationModal
				isOpen={isOpenDeleteModal}
				handleClose={closeDeleteConditionModal}
				title="Delete"
				body={deleteConditionConfirmationText}
				cancelBtnText="Cancel"
				confirmBtnText="Delete"
				onCancel={closeDeleteConditionModal}
				onConfirm={handleConditionDelete}
			/>
		</Grid>
	);
};
