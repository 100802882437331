quosal.api.agreementMapping = {};


quosal.api.agreementMapping.getAgreementMappingsExtendedData = function () {
    return quosal.api.call('AgreementMapping.GetAgreementMappingsExtendedData', {
    });
};

quosal.api.agreementMapping.deleteAgreementMapping = function (idAgreementMapping) {
    return quosal.api.call('AgreementMapping.DeleteAgreementMapping', {
        idAgreementMapping: idAgreementMapping
    });
};

quosal.api.agreementMapping.saveAgreementMapping = function (agreementMappingId, agreementTypeId, quoteId, tabId, isDefault) {
    return quosal.api.call('AgreementMapping.SaveAgreementMapping', {
        agreementMappingId: agreementMappingId,
        agreementTypeId: agreementTypeId,
        quoteId: quoteId,
        tabId: tabId,
        isDefault: isDefault
    });
};
