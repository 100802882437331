import { useEffect, useCallback, useRef } from "react";

export const useResizerContainer = (onResize) => {
	const mousePressState = useRef(false);
	const MIN_WIDTH_PERCENT = 25;
	const leftResizeRef = useRef(null);

	const handleMouseDown = useCallback((e) => {
		e.preventDefault();
		mousePressState.current = true;
	}, []);

	const handleMouseUp = useCallback(() => {
		mousePressState.current = false;
	}, []);

	const handleMouseMove = useCallback((e) => {
		if (mousePressState.current) {
			const ratio = window.devicePixelRatio;
			const movementX = parseFloat((e.movementX / ratio).toFixed(1));
			onResize(movementX, MIN_WIDTH_PERCENT);
		}
		else {
			return;
		}
	}, [onResize]);

	useEffect(() => {
		if (leftResizeRef.current) {
			leftResizeRef.current.addEventListener('mousedown', handleMouseDown);
		}
			window.addEventListener('mousemove', handleMouseMove);
			window.addEventListener('mouseup', handleMouseUp);

		return () => {
			if (leftResizeRef.current) {
				leftResizeRef.current.removeEventListener('mousedown', handleMouseDown);			
			}

			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('mouseup', handleMouseUp);
		}
	}, [handleMouseDown, handleMouseUp, handleMouseMove]);

	return {
		leftResizeRef,
		handleMouseDown
	};
};