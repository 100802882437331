import { DialogManager } from "js/jsx/src/classes/dialog.jsx";
import { MicroUiWrapper } from "js/jsx/src/classes/microUi/microUiWrapper.jsx";

export default function microUiSetup() {

    var loadMicroUiPage = function () {

        quosal.ui.react.render(<DialogManager />, document.getElementById('dialogManager'));
        quosal.ui.react.render(<MicroUiWrapper/>,
            document.getElementById('currentDocument')
        );
        $('#pageName').val('microuisetup.quosalweb');
    };
    quosal.sell.modules.onAfterLoad.unbind().bind(loadMicroUiPage);
}