import { Box, Card, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { payloadGenerator, updateData } from "../quoteHeader/services";
import { useState } from "react";
import { ConfirmationDailog } from "../../../common/components/ConfirmationDailog";
import { CommonButton } from "../../../common/components/CommonButton";

export const SaveCancelBar = ({ IdQuoteMain, setLoading }) => {
	const {
		getValues,
		formState: { isDirty },
		reset
	} = useFormContext();
	const [open, setOpen] = useState(false);

	const theme = useTheme();

	const handleSave = () => {
		setLoading(true);

		updateData(
			payloadGenerator({ name: "IdQuoteMain", value: IdQuoteMain }, "QuoteMain", {
				...getValues()
			})
		).then(async (res) => {
			const { response0: { error } = [] } = res;
			const { response0 } = res;
			if (error) {
				console.error("Error:", error);
				setLoading(false);
				return;
			}
			quosal.sell.quote.updateFromApiResponse(response0);
			reset(getValues());
			setLoading(false);
		});
	};

	const confirmationBody = (
		<div>You will lose any unsaved changes, are you sure you want to cancel ?&nbsp;</div>
	);

	return (
		<Card sx={{ mx: "5px", mb: "5px" }}>
			<Box display="flex" justifyContent="flex-end" sx={{ my: "5px", pr: "5px" }}>
				<CommonButton
					id="edit-salestrack-cancel-button"
					variant="outlined"
					onClick={() => setOpen(true)}
					size="small"
					disabled={!isDirty}
					sx={{ mr: "10px" }}
				>
					Cancel
				</CommonButton>
				<CommonButton
					id="edit-salestrack-save-button"
					variant="contained"
					size="small"
					disabled={!isDirty}
					onClick={handleSave}
				>
					Save
				</CommonButton>
				<ConfirmationDailog
					isOpen={open}
					handleClose={() => setOpen(false)}
					title="Confirm Cancel"
					body={confirmationBody}
					sx={{ backgroundColor: theme.palette.background.paper }}
					cancelBtnText="No"
					confirmBtnText="Yes"
					confirmBtnColor="primary"
					onCancel={() => setOpen(false)}
					onConfirm={() => {
						reset();
						setOpen(false);
					}}
				/>
			</Box>
		</Card>
	);
};
