import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { CommonSelect } from "../../../../common/guidedSelling/components/commonSelect";
import { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import FormContext from "../../contexts/configureFormContext";
import { disabledIconWhite } from "../../../../common/guidedSelling/theme";
import { randomId } from "../../constants/createFormConstants";
import { CustomWidthTooltip } from "../../../../common/guidedSelling/styledComponents/commonStyled";
import { DUPLICATE_DYNAMIC_CONDITION_TOOLTIP } from "../../constants";
import { thenDisplayQuestionFormatter } from "./common";

const SECTION_SUB_HEADING = "IF THE ANSWER";
const OPERATION_OPTIONS = [
	{
		label: "Equals",
		value: "equals"
	}
];

export const AddDynamicQuesCondition = ({
	currentSectionIndex,
	currentQuestionIndex,
	currentQuestion,
	isActive,
	isDynamicQuestion
}) => {
	const theme = useTheme();
	const [operation, setOperation] = useState("");
	const [inputValue, setInputValue] = useState("");
	const [linkedQuestion, setLinkedQuestion] = useState(null);
	const [isConditionRepeated, setIsConditionRepeated] = useState(false);
	const formContext = useContext(FormContext);
	const {
		formActions,
		formState: { sections }
	} = formContext;
	const { answers } = currentQuestion;
	const isSaveBtnDisabled = !operation || !inputValue || !linkedQuestion || isConditionRepeated;

	// Select dropdowns options
	const INPUT_VALUE_OPTIONS = answers.map(({ answer }) => ({
		label: answer,
		value: answer
	}));
	const INPUT_QUESTION_OPTIONS = sections
		.flatMap((section) => section.questions)
		.filter((question) => question.sortOrder !== currentQuestion.sortOrder && question.isActive)
		.map((question) => ({
			label: `${question.sortOrder}. ${question.question}`,
			value: {
				questionId: question.questionId,
				question: question.question,
				sortOrder: question.sortOrder
			}
		}));

	useEffect(() => {
		if (
			inputValue &&
			INPUT_VALUE_OPTIONS.length &&
			!INPUT_VALUE_OPTIONS.find((option) => option.value === inputValue)
		) {
			setInputValue("");
		}
	}, [inputValue, JSON.stringify(INPUT_VALUE_OPTIONS)]);

	useEffect(() => {
		if (
			linkedQuestion &&
			!INPUT_QUESTION_OPTIONS.find(
				({ value }) => value.questionId === linkedQuestion.questionId
			)
		) {
			setLinkedQuestion(null);
		}
	}, [linkedQuestion, JSON.stringify(INPUT_QUESTION_OPTIONS)]);

	useEffect(() => {
		const allDropdownsSelected = operation && inputValue && linkedQuestion;
		if (allDropdownsSelected && currentQuestion?.dynamicQuesConditions?.length > 0) {
			const isDuplicate = currentQuestion.dynamicQuesConditions.some(
				(condition) =>
					condition.operation === operation &&
					condition.answer === inputValue &&
					linkedQuestion &&
					condition.questionId === linkedQuestion.questionId
			);
			setIsConditionRepeated(isDuplicate);
		}
	}, [
		operation,
		inputValue,
		linkedQuestion,
		JSON.stringify(currentQuestion?.dynamicQuesConditions)
	]);

	const handleOperationChange = (e) => {
		setOperation(e.target.value);
	};

	const handleSelectInputValueChange = (e) => {
		setInputValue(e.target.value);
	};

	const handleSelectQuestionChange = (e) => {
		setLinkedQuestion(e.target.value);
	};

	const resetAddDynamicConditionFormSatate = () => {
		setOperation("");
		setInputValue("");
		setLinkedQuestion(null);
	};

	const addDynamicQuesCondition = () => {
		const newDynamicQuesConditions = [
			...currentQuestion.dynamicQuesConditions,
			{
				operation,
				answer: inputValue,
				questionId: linkedQuestion.questionId,
				question: linkedQuestion.question,
				dynamicQuestionId: randomId()
			}
		];
		formActions["common"](
			newDynamicQuesConditions,
			"dynamicQuesConditions",
			currentSectionIndex,
			currentQuestionIndex
		);
		resetAddDynamicConditionFormSatate();
	};

	const disableSelect = !isActive || !isDynamicQuestion;
	const columns = [
		{
			field: "operation",
			flex: 1,
			renderCell: () => {
				return <CommonSelect
					defaultValue="Choose an option"
					id={"select-operation"}
					disabled={disableSelect}
					label="Select Operation"
					name="operation"
					handleChange={handleOperationChange}
					options={OPERATION_OPTIONS}
					value={operation}
					data-testid="select-operation"
				/>
			}
		},
		{
			field: "answer",
			flex: 1,
			renderCell: () => {
				return <CommonSelect
					defaultValue="Choose an option"
					id={"input-value"}
					disabled={disableSelect}
					label="Select Input Value"
					name="inputValue"
					handleChange={handleSelectInputValueChange}
					options={INPUT_VALUE_OPTIONS}
					value={inputValue}
					renderValue={(value) => (value === "" ? "Choose an option" : value)}
					data-testid="select-input-value"
				/>
			}
		},
		{
			field: "thenDisplay",
			flex: 2,
			renderCell: () => {
				return <CommonSelect
					defaultValue="Choose an option"
					id={"linked-question"}
					disabled={disableSelect}
					label="Then Display Input Label/Question"
					name="linkedQuestion"
					handleChange={handleSelectQuestionChange}
					options={INPUT_QUESTION_OPTIONS}
					value={linkedQuestion}
					renderValue={(value) =>
						value
							? thenDisplayQuestionFormatter(value.sortOrder, value.question)
							: "Choose an option"
					}
					data-testid="select-question"
				/>
			}
		},
		{
			field: "actions",
			width: 100,
			renderCell: () => {
				return <CustomWidthTooltip
					title={isConditionRepeated ? DUPLICATE_DYNAMIC_CONDITION_TOOLTIP : ""}
					placement="top"
				>
					<Box
						component="div"
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<IconButton
							sx={{
								minWidth: "40px",
								minHeight: "40px",
								borderRadius: "6px",
								border: isSaveBtnDisabled
									? "none"
									: `1px solid ${theme.palette.button_primary.main}`,
								backgroundColor: isSaveBtnDisabled
									? `${theme.palette.button_primary.disable} !important`
									: "unset"
							}}
							onClick={addDynamicQuesCondition}
							disabled={isSaveBtnDisabled}
							data-testid="add-condition-btn"
						>
							<SaveOutlinedIcon
								fontSize="small"
								sx={{
									color: isSaveBtnDisabled
										? `${disabledIconWhite} !important`
										: `${theme.palette.button_primary.main}`
								}}
							/>
						</IconButton>
					</Box>
				</CustomWidthTooltip>
			}
		}
	];
	const rows = [{ id: 1 }]
	return (
		<Grid container flexDirection={"column"} sx={{flexWrap:"nowrap"}}>
			<Typography
				sx={{
					fontSize: "14px",
					lineHeight: "20px",
					letterSpacing: "0.16px",
					marginBottom: "20px",
					color: disableSelect ? theme.palette.text.disabled : theme.palette.text.main
				}}
			>
				{SECTION_SUB_HEADING}
			</Typography>
			<Grid item xs={12}>
				<DataGrid 	
				rows={rows}
				columns={columns}
				columnHeaderHeight={0}
				getRowId={(row) => row?.id}
				hideFooterPagination
				hideFooterSelectedRowCount
				hideFooter
				disableVirtualization
				isCellEditable={() => false}
				sx = {{
					'&, [class^=MuiDataGrid]': { border: 'none !important', backgroundColor: 'transparent', outline: 'none' }, 
					"& .MuiDataGrid-row.Mui-selected": { backgroundColor: 'transparent' },
					"& .MuiDataGrid-row.Mui-selected:hover": { backgroundColor: 'transparent' },
					"& .MuiDataGrid-row:hover": { backgroundColor: 'transparent' },
					"& .MuiDataGrid-cell:focus-within" : { outline: 'none' },
					"& .MuiFormControl-root" : {marginTop: '10px'},
				}}
				>
				</DataGrid>
			</Grid>
		</Grid>
	);
};
