import * as React from "react";
import BaseContextMenu from "./baseContextMenu.jsx";

function MoreContextMenu({ quosal, actions, handleChangeAfterAction, isArchivedQuote }) {
	return (
		<div style={{ paddingTop: "23px", paddingLeft: "24px" }}>
			{actions && actions.length ? (
				<BaseContextMenu
					title="More"
					quosal={quosal}
					actions={actions}
					handleChangeAfterAction={handleChangeAfterAction}
					isArchivedQuote={isArchivedQuote}
				/>
			) : null}
		</div>
	);
}

export default MoreContextMenu;
