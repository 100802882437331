import {EtilizeDetailsMain} from "js/jsx/src/classes/etilize/DetailsMain.jsx";
import {EtilizeCompare} from "js/jsx/src/classes/etilize/EtilizeCompare.jsx";
import {EtilizeSearchBar} from "js/jsx/src/classes/etilize/etilizeSearchBar.jsx";
import {EtilizeSearchResults} from "js/jsx/src/classes/etilize/etilizeSearchResults.jsx";

/*global React:object quosal:object */
export class EtilizeSearch extends React.Component {
    constructor(props) {
        super(props);
        this.maxPendingAttach = 12;
        this.childBar = null;
        this.childTable = null;
        this.modeStyle = {
            INIT: 'init',
            RESET: 'reset',
            SEARCH: 'search',
            DETAILS: 'details',
            COMPARE: 'compare',
            FINISH: 'finish'
        };
        this.state = { 
            lastKeywords: '',
            selectedProductIds: [],
            productsWithPricing: [],
            selectedProducts: [],
            compareGroups: null,
            pagestack: [],
            currentPage: 1,
            pagesize: 50,
            currentPartialProduct: [],
            currentPrices: [],
            keywords: '',
            showsome: 'showall',
            selectedManufacturers: [],
            selectedCategories: [],
            selectedCatalogs: [],
            activefilters: [],
            selectedDistributors: [],
            mode: this.modeStyle.INIT,
            mainconfig: [],
            priceSourcesLoaded: false
        }; 
        this.getCurrentTab = this.getCurrentTab.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.executeSearch = this.executeSearch.bind(this);
        this.executeClassicSearch = this.executeClassicSearch.bind(this);
        this.executeSearchWithPricing = this.executeSearchWithPricing.bind(this);
        this.executeSearchClicked = this.executeSearchClicked.bind(this);
        this.availabilityChanged = this.availabilityChanged.bind(this);
        this.keywordsChanged = this.keywordsChanged.bind(this);
        this.resetButtonPressed = this.resetButtonPressed.bind(this);
        this.resetSearch = this.resetSearch.bind(this);
        this.renderSearch = this.renderSearch.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.moreResults = this.moreResults.bind(this);
        this.compareProducts = this.compareProducts.bind(this);
        this.loadComparibleProducts = this.loadComparibleProducts.bind(this);
        this.adjustProductSelection = this.adjustProductSelection.bind(this);
        this.viewRowDetails = this.viewRowDetails.bind(this);
        this.diveDeeper = this.diveDeeper.bind(this);
        this.setDetailToProduct = this.setDetailToProduct.bind(this);
        this.startNewSearch = this.startNewSearch.bind(this);
        this.returnToSearch = this.returnToSearch.bind(this);
        this.filterManufacturer = this.filterManufacturer.bind(this);
        this.removeManufacturer = this.removeManufacturer.bind(this);
        this.filterCategory = this.filterCategory.bind(this);
        this.removeCategory = this.removeCategory.bind(this);
        this.filterbyAttribute = this.filterbyAttribute.bind(this);
        this.removeAttribute = this.removeAttribute.bind(this);
        this.filterDistributor = this.filterDistributor.bind(this);
        this.filterCatalogs = this.filterCatalogs.bind(this);
        this.pagesizeChanged = this.pagesizeChanged.bind(this);
        this.showSomeChanged = this.showSomeChanged.bind(this);
        this.renderDetails = this.renderDetails.bind(this);
        this.renderCompare = this.renderCompare.bind(this);
        this.render = this.render.bind(this);
        this.fullToPartial = this.fullToPartial.bind(this);
        this.getConfigs = this.getConfigs.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.setProducts = this.setProducts.bind(this);
    }

    getCurrentTab() {
        var tab = null;
        if (quosal.util.queryString('idquotetabs')) {
            tab = app.currentQuote.Tabs.where(t => t.id === quosal.util.queryString('idquotetabs')).firstOrNull();
        }
        return tab;
    }

    getConfigs() {
        if (this.state.mainconfig) {
            return this.state.mainconfig;
        }
        else {
            var setApi = quosal.api.product.etilizeconfig();

            setApi.finished = function (msg1) {
                this.setState({ mainconfig: msg1.config[0] });
            }

            setApi.call();
            return this.state.mainconfig;
        }
    }
    componentDidMount() {
        if(!quosal.sell.product.sourceGroups){
            quosal.sell.product.loadPriceSourcing(false, () => {
                this.setState({
                    priceSourcesLoaded: true
                });
            });
        } else {
            this.setState({priceSourcesLoaded: true});
        }

        if (this.state.mode === this.modeStyle.INIT) {
            
            var setApi = quosal.api.product.etilizeconfig();
            setApi.finished = function (msg1) {
                this.setState({ mainconfig: msg1.config[0] });
                var last = quosal.util.cookie('c_etilizesearchs_keywords');
                var page = quosal.util.cookie('c_etilizesearchs_pagenum');
                if (String.isNullOrEmpty(page)) {
                    page = 1;
                }
                var perpage = quosal.util.cookie('c_etilizesearchs_max_search_filter');
                if (String.isNullOrEmpty(perpage)) {
                    perpage = 50;
                }
                var filters = quosal.util.cookie('c_etilizesearchs_filters');
                var showthese = quosal.util.cookie('c_etilizesearchs_show');
                var dists = quosal.util.cookie('c_etilizesearchs_distributors');
                var catalogs = quosal.util.cookie('c_etilizesearchs_catalogsourcefilter');
    
                var tcat = quosal.util.cookie('c_etilizesearchs_catalogsourcefilter');
                if (catalogs && catalogs.length > 0) {
                    catalogs = catalogs.split('~');
                }
                else {
                    catalogs = [];
                }
    
                if (dists && dists.length > 0) {
                    dists = dists.split('~');
                }
                else {
                    dists = [];
                }
    
                if (this.childTable) {
                    this.childTable.setState({
                        searchmsg: {
                            count: 0,
                            error: 'Use the filters on the left to begin your search'
                        },
                        searching: false
                    });
                }
    
                let settings = [];
                let config = msg1.config[0];
                this.setState({ mainconfig: config });
                if (config.EtilizeDefaultCatalog == 'North America') {

                    if (config.UseSynnex) { settings.push('etd-' + config.EtilizeSynnexKey); }
                    if (config.UseTechData) { settings.push('etd-' + config.EtilizeTechDataKey); }
                    if (config.UseIngram) { settings.push('etd-' + config.EtilizeIngramKey); }
                    if (config.UseDNH) { settings.push('etd-' + config.EtilizeDnHKey); }
                }
                else if (config.EtilizeDefaultCatalog == 'Europe') {
                    if (config.UseTechDataUK) { settings.push('etd-' + config.EtilizeTechDataUKKey); }
                }
                   
                
                var api = quosal.api.settings.getSettings(settings);
                api.finished = function (msg) {
                    let dDefault = [];
                    for (var iDist = 0; iDist < msg.settings.length; iDist++) {
                        if (msg.settings[iDist].Value === 'true') {
                            dDefault.push(msg.settings[iDist].Key.substring(4));
                        }
                    }
                    if (dDefault.length > 0) {
                        dists = dDefault;
                    }
    
                    this.setState({
                        keywords: last,
                        showtype: showthese,
                        selectedCatalogs: catalogs,
                        selectedDistributors: dists,
                        currentpage: page,
                        pagesize: perpage,
                        mode: this.modeStyle.SEARCH
                    });
                }.bind(this);
                api.call();
            }.bind(this);
            setApi.call();
        }
    }

    keywordsChanged(e) {
        this.setState({ keywords: e });
    }
    executeSearchClicked(newSearch,partial) {
        if (!this.state.keywords || this.state.keywords.length === 0) {
            Dialog.open({
                message: 'Please enter keywords to search with.',
                links: [Dialog.links.ok]
            });
            return;
        }
        this.executeSearch(newSearch, partial);
    }

    executeSearchWithPricing(newSearch, partial) {
        if (!this.childBar) {
            return;
        }
        var start = new Date().getTime();
        var keywords = this.state.keywords;
        var nPage = this.state.currentPage;
        var nCount = this.state.pagesize;
        var filters = this.state.activefilters;
        var showsome = this.state.showsome;
        var dists = this.state.selectedDistributors;
        var catalogs = this.state.selectedCatalogs;
        var manufacts = [];
        var categories = [];
        var features = this.state.currentFeatures;

        if (this.state.selectedCategories) {
            this.state.selectedCategories.forEach(function (c) {
                categories.push(c.Id);
            });
        }
        if (this.state.selectedManufacturers) {
            this.state.selectedManufacturers.forEach(function (m) {
                manufacts.push(m.ID);
            });
        }

        if (newSearch || this.state.lastKeywords !== keywords) {
            this.setState(
                {
                    productsWithPricing: []
                }
            )
            nPage = 1;
        }

        this.setState({
            lastKeywords: keywords,
            selectedProductIds: [],
            currentPage: nPage
        });

        quosal.util.cookie('c_etilizesearchs_keywords', keywords, { expires: 14, path: '/' });
        quosal.util.cookie('c_etilizesearchs_pagenum', nPage, { expires: 14, path: '/' });
        quosal.util.cookie('c_etilizesearchs_show', showsome, { expires: 14, path: '/' }); //'showall'
        quosal.util.cookie('c_etilizesearchs_max_search_filter', nCount, { expires: 14, path: '/' });
        quosal.util.cookie('c_etilizesearchs_catalogsourcefilter', catalogs.join('~'), { expires: 14, path: '/' });

        if (partial === true) {
            this.setState(
                {
                    productsWithPricing: []
                }
                );
            keywords = '';  // cheap way to do reset
        }

        var encoded = '';
        for (var i = 0; i < filters.length; i++) {
            encoded = encoded + filters[i].Name + '·' + filters[i].Value + '·' + filters[i].Id + '·' + filters[i].ParentId + '·' + filters[i].compare + '·' + filters[i].compareto + '¶';
        }
        filters = encoded;
        var searchApi = quosal.api.product.etilizesearchwithpricing(
            app.currentQuote.IdQuoteMain,
            quosal.util.queryString('idquotetabs'),
            keywords,
            nPage, nCount,
            filters,
            manufacts, categories, features,
            dists
        );
        searchApi.finished = function (msg) {
            if (msg) {
                var products = [];
                if (this.state.productsWithPricing) {
                    products = this.state.productsWithPricing;
                    if (msg.products) {
                        products.push(...msg.products);
                    }
                }
                this.setState(
                    {
                        currentPage: msg.currentPage,
                        productsWithPricing: products
                    });
            }

            if (msg.count === -1) {
                msg.error = 'Use the filters on the left to begin your search';
            }
            this.childBar.setState({ searchinfo: msg, searching: false });
            this.childTable.setState({
                searchmsg: msg,
                searching: false,
                addOnSearching: false,
                sortColumn: 0,
                sortSource: '',
                selectedProducts: [],
                compareMsg: null
            });
            quosal.util.calculateAndTrackPageLoadTimeInAppInsights(start, "Etilize - Search Product With Pricing");
        }.bind(this);
        searchApi.call();

        this.childBar.setState({ searching: true });
        var message = app.productBranding + " is searching Etilize and cross-referencing your vendor price integrations to find products that have relevant pricing and availability. This can take up to 30 seconds."
        if (nPage === 1) {
            this.childTable.setState({
                searchmsg: { count: 0, error: message },
                priceinfo: {},
                priceSeeds: [],
                pricingComplete: false,
                searching: true
            });
        }
        else {
            this.childTable.setState({
                addOnSearching: true
            });
        }
    }

    executeSearch(newSearch, partial) {
        var config = this.getConfigs();
        if (config && config.EtilizePrefilterResults == true) {
            this.executeSearchWithPricing(newSearch, partial);
        }
        else {
            this.executeClassicSearch(newSearch, partial);
        }
    }

    executeClassicSearch(newSearch, partial) {
        if (!this.childBar) {
            return;
        }
        var start = new Date().getTime();
        var keywords = this.state.keywords;
        var nPage = this.state.currentPage;
        var nCount = this.state.pagesize;
        var filters = this.state.activefilters;
        var showsome = this.state.showsome;
        var dists = this.state.selectedDistributors;
        var catalogs = this.state.selectedCatalogs;
        var manufacts = [];
        var categories = [];
        var features = this.state.currentFeatures;

        if (this.state.selectedCategories) {
            this.state.selectedCategories.forEach(function (c) {
                categories.push(c.Id);
            });
        }
        if (this.state.selectedManufacturers) {
            this.state.selectedManufacturers.forEach(function (m) {
                manufacts.push(m.ID);
            });
        }

        // console.log('SRCH[key]:' + keywords);
        // console.log('SRCH[pag]:' + nPage);
        // console.log('SRCH[flt]:', filters);
        // console.log('SRCH[shw]:' + showsome);
        // console.log('SRCH[dst]:', dists);
        // console.log('SRCH[clg]:', catalogs);
        // console.log('SRCH[mfg]:', manufacts);
        // console.log('SRCH[cat]:', categories);

        if (newSearch || this.state.lastKeywords !== keywords) {
            nPage = 1;
        }

        this.setState({
            lastKeywords: keywords,
            selectedProductIds: [],
            currentPage: nPage
        });

        quosal.util.cookie('c_etilizesearchs_keywords', keywords, {expires: 14,path: '/' });
        quosal.util.cookie('c_etilizesearchs_pagenum', nPage, { expires: 14, path: '/' });
        quosal.util.cookie('c_etilizesearchs_show', showsome, { expires: 14, path: '/' }); //'showall'
        quosal.util.cookie('c_etilizesearchs_max_search_filter', nCount, { expires: 14, path: '/' });
        quosal.util.cookie('c_etilizesearchs_catalogsourcefilter', catalogs.join('~'), { expires: 14, path: '/' });

        if (partial === true) {
            keywords = '';  // cheap way to do reset
        }

        var encoded = '';
        for (var i = 0; i < filters.length; i++) {
            encoded = encoded + filters[i].Name + '·' + filters[i].Value + '·' + filters[i].Id + '·' + filters[i].ParentId + '·' + filters[i].compare + '·' + filters[i].compareto + '¶';
        }
        filters = encoded;
        var searchApi = quosal.api.product.etilizesearch(
            app.currentQuote.IdQuoteMain,
            quosal.util.queryString('idquotetabs'),
            keywords,
            nPage, nCount,
            filters, showsome,
            manufacts, categories, features,
            dists, catalogs
        );
        searchApi.finished = function (msg) {
            if (msg) {
                msg.config = msg.config[0];
                msg.prices = msg.prices[0];
            }

            if (msg.count === -1) {
                msg.error = 'Use the filters on the left to begin your search';
            }
            this.childBar.setState({ searchinfo: msg, searching: false });
            this.childTable.setState({
                searchmsg: msg,
                searching: false,
                sortColumn: 0,
                sortSource: '',
                selectedProducts: [],
                compareMsg: null
            });
            this.setState({ mainconfig: msg.config });

            this.childTable.seedLookupTasks();
            this.childTable.doNewPricingLookup();
            quosal.util.calculateAndTrackPageLoadTimeInAppInsights(start, "Etilize - Search Product");
        }.bind(this);
        searchApi.call();

        this.childBar.setState({ searching: true });
        this.childTable.setState({
            searchmsg: { count: 0 },
            priceinfo: {},
            priceSeeds: [],
            pricingComplete: false,
            searching: true
        });
    }

    availabilityChanged(priced, stocked) {
        if (this.childBar) {
            this.childBar.slyChangeTotals(priced, stocked);
        }
    }
    
    resetButtonPressed(){
        this.resetSearch(false);
    }
    resetSearch(partial) {
        if (this.childTable) {
            this.childTable.setState({
                searchmsg:{
                    count:0,
                    error: 'Use the filters on the left to begin your search'},
                searching:false
            });
        }

        var keywords = '';
        if (partial === true) {
            keywords = this.state.keywords;
            quosal.util.cookie('c_etilizesearchs_keywords', '', { expires: 14, path: '/' });
        }
        quosal.util.cookie('c_etilizesearchs_pagenum', '1', { expires: 14, path: '/' });
        quosal.util.cookie('c_etilizesearchs_filters', '', { expires: 14, path: '/' });
        quosal.util.cookie('c_etilizesearchs_show', '', { expires: 14, path: '/' });
        quosal.util.cookie('c_etilizesearchs_max_search_filter', '50', { expires: 14, path: '/' });
        quosal.util.cookie('c_etilizesearchs_distributors', '', { expires: 14, path: '/' });
        quosal.util.cookie('c_etilizesearchs_catalogsourcefilter', '', { expires: 14, path: '/' });

        let settings = [];
        var config = this.state.mainconfig;
        if (config.UseSynnex) { settings.push('etd-'+config.EtilizeSynnexKey); }
        if (config.UseTechData) { settings.push('etd-'+config.EtilizeTechDataKey);}
        if (config.UseIngram) { settings.push('etd-'+config.EtilizeIngramKey);}
        if (config.UseDNH) { settings.push('etd-'+config.EtilizeDnHKey);}
        if (config.UseTechDataUK) { settings.push('etd-'+config.EtilizeTechDataUKKey);}

        var api = quosal.api.settings.getSettings(settings);
        api.finished = function (msg) {
            let dists = [];
            for (var iDist = 0; iDist < msg.settings.length; iDist++) {
                if (msg.settings[iDist].Value === 'true') {
                    dists.push(msg.settings[iDist].Key.substring(4));
                }
            }

            this.setState( {
                currentpage: 1,
                keywords: keywords,
                activefilters: [],
                selectedManufacturers: [],
                selectedCategories: [],
                selectedDistributors: dists,
                selectedCatalogs: []
            });
        }.bind(this);
        api.call();
    }

    renderSearch() {
        return (
            <div>
                <EtilizeSearchBar ref={(r) => { this.childBar = r; }} configs={this.getConfigs()} phase={this.state.mode} onSearch={this.executeSearchClicked} onReset={this.resetButtonPressed} showChanged={this.showSomeChanged}
                                        keyChanged={this.keywordsChanged} keywords={this.state.keywords}
                                        currentFeatures={this.state.currentFeatures} currentFilters={this.state.activefilters}
                                                addFilter={this.filterbyAttribute} remFilter={this.removeAttribute} pagesize={this.state.pagesize}
                                                filterCategory={this.filterCategory} removeCategory={this.removeCategory} currentCategories={this.state.selectedCategories}
                                                pagesizeChanged={this.pagesizeChanged}
                                                configs={this.getConfigs()}
                                                filterDistributor={this.filterDistributor} currentDistributors={this.state.selectedDistributors}
                                                filterCatalogs={this.filterCatalogs} currentCatalogs={this.state.selectedCatalogs}
                                                priceSourcesLoaded={this.state.priceSourcesLoaded}
                    selectedManufacturers={this.state.selectedManufacturers} addManufacturerFilter={this.filterManufacturer} remManufacturerFilter={this.removeManufacturer} />
                <EtilizeSearchResults ref={(r) => { this.childTable = r; }} phase={this.state.mode} configs={this.getConfigs()} productsWithPricing={this.getProducts()}
                    onPrev={this.prevPage} onNext={this.nextPage} onMore={this.moreResults} onCompare={this.compareProducts} onCompareCheck={this.adjustProductSelection}
                    pricingCompleted={this.availabilityChanged} selectedProducts={this.state.selectedProductIds} setProducts={this.setProducts}
                                        rowClicked={this.viewRowDetails} />
            </div>
        );
    }

    getProducts() {
        return this.state.productsWithPricing;
    }
    setProducts(products) {
        this.setState({productsWithPricing: products})
    }

    prevPage() {
        var p = this.state.currentPage-1;
        this.setState( {
            currentPage: p
        }, () => this.executeSearch(false, false));
    }
    nextPage() {
        var p = this.state.currentPage+1;
        this.setState( {
            currentPage: p
        }, () => this.executeSearch(false, false));
    }
    moreResults() {
        var p = this.state.currentPage + 1;
        this.setState({
            currentPage: p
        }, () => this.executeSearch(false, false));
    }
    compareProducts() {
        this.loadComparibleProducts(() => {
            this.setState( {
                mode: this.modeStyle.COMPARE
            });
        });
    }

    loadComparibleProducts(thenThis) {
        var me = this;
        var api = quosal.api.product.etilizecompares(
                    app.currentQuote.IdQuoteMain,
                    this.state.selectedProductIds
                    );
        api.finished=function(msg) {
            me.setState({
                selectedProducts: msg.products,
                compareGroups: msg.compare
            });
            if (thenThis) {
                thenThis();
            }
        }.bind(this);
        api.call();        
    }

    adjustProductSelection(id, add) {
        this.setState(function(oldState, props) {
            var ids = oldState.selectedProductIds.filter(e => e !== id);
            if (add) {
                ids.push(id);
            }
            return {selectedProductIds: ids};
          });
    }

    viewRowDetails(e, prod, knownPrices) {
        var stack = this.state.pagestack;
        stack.push({mode: this.state.mode, product: this.state.currentPartialProduct, prices: this.state.currentPrices});
        this.setState({
            currentPartialProduct: [prod],
            currentPrices: knownPrices,
            mode: this.modeStyle.DETAILS,
            pagestack: stack
        });
    }

    diveDeeper(product,imgurl) {
        var part = this.fullToPartial(product);
        part.imageUrl = imgurl;
        var stack = this.state.pagestack;
        stack.push({mode: this.state.mode, product: this.state.currentPartialProduct, prices: this.state.currentPrices});
        this.setState({
            currentPartialProduct: [part],
            mode: this.modeStyle.DETAILS,
            pagestack: stack
        }, () => this.setDetailToProduct(part));
    }

    setDetailToProduct(p) {
        if (this.childDetails) {
            this.childDetails.loadInNewProduct(p);
            this.childDetails.forceUpdate();
        }
    }

    startNewSearch(){
        this.setState( {
            currentPage: 1,
            mode: this.modeStyle.SEARCH,
            pagestack: []
        }, () => this.resetSearch(true));
    }

    returnToSearch() {
        var me = this;
        var stack = this.state.pagestack;
        var last = stack.pop();
        if (last && last.mode !== this.modeStyle.SEARCH) {
            if (last.mode === this.modeStyle.DETAILS) {
                this.setState( {
                    currentPartialProduct: last.product,
                    currentPrices: last.prices,
                    mode: last.mode,
                    pagestack: stack
                }, () => me.setDetailToProduct(last.product[0]));
            }
            else {
                this.setState( {
                    mode: last.mode,
                    pagestack: stack
                } );
            }
        }
        else {
            this.setState( {
                currentPage: 1,
                mode: this.modeStyle.SEARCH,
                pagestack: stack
            }, () => this.executeSearch(true, false));
        }
    }

    filterManufacturer(id, name) {
        var m = this.state.selectedManufacturers.slice(0);
        var me = this;
        if (!m.includes(id)) {
            m.push({ ID: id, Name: name });
        }
        this.setState(
            {
                selectedManufacturers: m
            }, () => me.executeSearch(true, false)
        );
    }
    removeManufacturer(id) {
        var m = this.state.selectedManufacturers.filter(e => e.ID !== id);
        var me = this;
        this.setState(
            {
                selectedManufacturers: m
            },
            () => me.executeSearch(true, false)
        );
    }

    filterCategory(id, name) {
        var m = this.state.selectedCategories.slice(0);
        var me = this;
        if (!m.includes(id)) {
            m.push({ Id: id, Name: name });
        }
        this.setState(
            {
                selectedCategories: m
            },
            () => me.executeSearch(true, false)
        );
    }
    removeCategory(id) {
        var m = this.state.selectedCategories.filter(e => e.Id !== id);
        var me = this;
        this.setState(
            {
                selectedCategories: m
            },
            () => me.executeSearch(true, false)
        );
    }

    filterbyAttribute(e, attr) {
        if (e.type !== 'change') {
            return;
        }
        var feature = attr;
        var entry;
        var cmpElem = document.getElementById('fi' + feature.Id);
        var cmpValue = '' + cmpElem.options[cmpElem.selectedIndex].attributes.name.value;

        if (feature.AttributeType.toLowerCase() === 'numeric') {
            var elem = document.getElementById('cmp' + feature.Id);
            var item = elem.options[elem.selectedIndex];
            var cmp = item.value;
            if (cmp === 'gt') {
                // only these 3 options are supported
                cmp = '>=';
            } else if (cmp === 'lt') {
                cmp = '<=';
            } else {
                cmp = ':';
            }

            entry = {
                Name: cmp + ' ' + cmpValue,
                Value: e.target.value,
                Id: e.target.value,
                ParentId: feature.Id,

                compare: item.value,
                compareto: cmpValue
            };
        } else {
            entry = {
                Name: ': ' + cmpValue,
                Value: e.target.value,
                Id: e.target.value,
                ParentId: feature.Id,

                compare: 'sel',
                compareto: cmpValue
            };
        }

        var el = cmpElem.options[0];
        entry.Title = el.value.substring(0, el.value.lastIndexOf(' '));

        var m = this.state.activefilters.slice(0);
        m.push(entry);
        this.setState(
            {
                activefilters: m
            },
            () => this.executeSearch(true)
        );
    }
    
    removeAttribute(id) {
        var m = this.state.activefilters.filter(e => e.Id !== id);
        this.setState(
            {
                activefilters: m
            },
            () => {
                this.forceUpdate();
                this.executeSearch(true, false);
            }
        );
    }
        
    filterDistributor(e, key) {
        var d;
        if (e.currentTarget.checked === true) {
            d = this.state.selectedDistributors.slice(0);
            if (!d.includes(key)) {
                d.push(key);
            }
        }
        else {
            d = this.state.selectedDistributors.filter(x => x !== key);
        }
        this.setState(
            {
                selectedDistributors: d
            }
        );
    }
    filterCatalogs(e, key) {
        var d;
        if (e.currentTarget.checked === true) {
            d = this.state.selectedCatalogs.slice(0);
            if (!d.includes(key)) {
                d.push(key);
            }
        }
        else {
            d = this.state.selectedCatalogs.filter(x => (x !== key));
        }
        this.setState(
            {
                selectedCatalogs: d
            }
        );
    }

    pagesizeChanged(e) {
        this.setState({ pagesize: e.target.value });
    }
    
    showSomeChanged(e) {
        this.setState({ showsome: e.target.value });
        var priced = e.target.value === 'showpricing' ? true : false;
        var stocked = e.target.value === 'showinstock' ? true : false;
        if (this.childTable) {
            this.childTable.setState({
                onlyPriced: priced,
                onlyStocked:stocked
            });
        }
    }

    renderDetails() {
        return (<EtilizeDetailsMain ref={(r) => {this.childDetails = r;}}
                        visibleProducts={this.state.currentPartialProduct} 
                        lastPrices={this.state.currentPrices} 
                        config={this.state.mainconfig} 
                        previousClick={this.returnToSearch} 
                        startAgainFn={this.startNewSearch}
                        deeperProduct={this.diveDeeper} />);
    }

    renderCompare() {
        return <EtilizeCompare products={this.state.selectedProducts} compares={this.state.compareGroups} detailFn={this.diveDeeper} returnFn={this.returnToSearch} />;
    }

    render() {
        if (this.state.mode === this.modeStyle.INIT || this.state.mode === this.modeStyle.SEARCH) {
            return this.renderSearch();
        }
        else if (this.state.mode === this.modeStyle.DETAILS) {
            return this.renderDetails();
        }
        else if (this.state.mode === this.modeStyle.COMPARE) {
            return this.renderCompare();
        }
        return <span />;
    }

    fullToPartial(product) {
        var url = '';
        if (product.ProductImages) {
            url = product.ProductImages[0];
        }
        var part = {
            Description1: product.Description1,
            Description2: product.Description2,
            Description3: product.Description3,
            ManufacturerPartNumber: product.ManufacturerPartNumber,
            bestPrice: 0,
            imageUrl: url,
            productid: product.Id,
            skus: product.Skus
        };
        return part;
    }
}