/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, FormControl, Radio, RadioGroup, Typography } from "@mui/material";
import { StyledFormControlLabel } from "../styledComponents/productSearchTable";

function PriceSourcesList({ handleSelectPriceSource, row }) {
	const { onHand, prices, price, source, sourceId, stock } = row;
	const [selectedPriceSource, setSelectedPriceSource] = useState(source);
	const selectDefaultSource = () =>
		prices.length
			? prices.find((item) => item.DisplaySourceName === source)
			: {
					DisplaySourceName: source,
					InStock: stock,
					OnHand: onHand,
					Price: price,
					SourceId: sourceId,
					SourceName: source
			  };

	const updateSourceName = (sourceName) => {
		const selectedPriceInfo = prices.find(
			(item) => item.SourceName === sourceName || item.DisplaySourceName === sourceName
		);
		setSelectedPriceSource(sourceName);
		handleSelectPriceSource(selectedPriceInfo);
	};

	useEffect(() => {
		const defaultSourceInfo = selectDefaultSource();
		handleSelectPriceSource(defaultSourceInfo);
	}, [row]);

	useEffect(() => {
		setSelectedPriceSource(source);
	}, [source]);

	return (
		<Box sx={{ mb: 2, maxHeight: 260, overflowY: "auto" }}>
			{!prices.length ? (
				<Typography fontSize={16} py={1}>
					Price not available
				</Typography>
			) : (
				<FormControl>
					<RadioGroup
						aria-labelledby="price-radio-buttons-group"
						name="controlled-radio-buttons-group"
						value={selectedPriceSource}
						onChange={(e) => updateSourceName(e.target.value)}
					>
						{prices.map(
							(el) =>
								typeof el === "object" &&
								el !== null && (
									<StyledFormControlLabel
										key={el.DisplaySourceName}
										value={el.DisplaySourceName}
										control={<Radio />}
										label={`${
											el.DisplaySourceName
										}: ${app.currentQuote.formatCurrency(el.Price)} (${
											el.OnHand
										})`}
									/>
								)
						)}
					</RadioGroup>
				</FormControl>
			)}
		</Box>
	);
}

export default PriceSourcesList;
