quosal.api.cloud = {};

//==========================================================//
//* Parse Equote                                      *//
//==========================================================//
//==========================================================//
quosal.api.cloud.parseEquote = function (idQuoteMain, idQuoteTabs, equoteHtml, parseMode, parser) {
    return quosal.api.call('Cloud.ParseEquote', {
        IdQuoteMain: idQuoteMain,
        IdQuoteTabs: idQuoteTabs,
        dellhtml: equoteHtml,
        ParseMode: parseMode,
        Parser: parser
    });
};
