import { useEffect, useState, useCallback } from "react";
import transformData from "../utils/productsMapData";
import { fetchData, abortRequestSafe } from "./fetchData";

// eslint-disable-next-line import/prefer-default-export
export function useUpSellCrossService() {
	const [upSellProducts, setUpSellProducts] = useState([]);
	const [isLoadingUpSellProducts, setIsLoadingUpSellProducts] = useState(false);
	const [upSellProductsToFilter, setUpSellProductsToFilter] = useState(null);
	const [upSellProductsWithPricing, setUpSellProductsWithPricing] = useState(true);

	const getUpSellProducts = (masterProductId) => {
		setIsLoadingUpSellProducts(true);
		fetchData(
			"quosalweb/quosalapi/Product.ProductDetails",
			"Product.ProductDetails",
			{ signalKey: masterProductId.masterProductId },
			masterProductId
		).then((resp) => {
			const { response0: { result, error } = [] } = resp;
			if (error) {
				setUpSellProducts([]);
				setIsLoadingUpSellProducts(false);
				return;
			}
			const data = transformData(result);
			setUpSellProductsToFilter(data);
			setIsLoadingUpSellProducts(false);
		});
	};

	useEffect(() => {
		if (!upSellProductsToFilter) return;

		if (upSellProductsWithPricing) {
			setUpSellProducts(upSellProductsToFilter.filter((prod) => !!prod.prices.length));
		} else setUpSellProducts(upSellProductsToFilter);
	}, [upSellProductsWithPricing, upSellProductsToFilter]);

	const deleteAllUpSellProducts = useCallback(() => setUpSellProducts([]), []);

	return {
		upSellProducts,
		isLoadingUpSellProducts,
		upSellProductsWithPricing,
		upSellProductsToFilter,
		getUpSellProducts,
		deleteAllUpSellProducts,
		abortRequestSafe,
		setUpSellProductsWithPricing,
		setUpSellProductsToFilter
	};
}
