import { CwOpportunity } from "js/jsx/src/classes/crm/connectwise/cwOpportunity.jsx";

export default function opportunity() {

    quosal.ui.react.render(
        <FormPlaceholder message="Loading Opportunity..." />
        , document.getElementById('opportunityContainer'));

    var renderOpportunityPage = function (renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;
        var opportunityContainer = document.getElementById('opportunityContainer');

        var crmName = quosal.util.getCrmShortName();
        if (crmName === 'cw') {
            renderMethod(<CwOpportunity />, opportunityContainer);
        } else if (crmName === 'dynamics') {
            quosal.navigation.navigate(quosal.util.url(crmName + 'opportunity.quosalweb', 'idquotemain=' + app.currentQuote.IdQuoteMain, 'nav=customer'));
        } else if (crmName === 'autotask') {
            quosal.navigation.navigate(quosal.util.url(crmName + 'opportunity.quosalweb', 'idquotemain=' + app.currentQuote.IdQuoteMain, 'nav=customer'));
        } else if (crmName === 'netsuite') {
            quosal.navigation.navigate(quosal.util.url(crmName + 'opportunity.quosalweb', 'idquotemain=' + app.currentQuote.IdQuoteMain, 'nav=customer'));
        } else if (crmName === 'sf') {
            quosal.navigation.navigate(quosal.util.url(crmName + 'opportunity.quosalweb', 'idquotemain=' + app.currentQuote.IdQuoteMain, 'nav=customer'));
        }
    };

    var loadOpportunityPage = function () {
        renderOpportunityPage(quosal.ui.react.render);

        $('#pageName').val('crm.opportunity');
    };

    var unbindEvents = function () {
        //onNavigate event hookups would be turned off here if there were any.

        quosal.navigation.onNavigate.unbind(unbindEvents);
    };

    //onNavigate event hookups would go here if there were any.

    quosal.navigation.onNavigate.bind(unbindEvents);

    loadOpportunityPage();
}