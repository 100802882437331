import React from "react";
import TextField from "@mui/material/TextField";
import { useQuoteContext, useUserContext } from "../context";
import { Box, useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { text_ellipsis } from "../styles";

export const QuoteName = ({
	setFocus,
	disableUnderline,
	focus,
	handleBlur,
	setQuoteName,
	quoteName
}) => {
	const { QuoteReadableId, Version, IsLocked } = useQuoteContext();
	const { IsReadOnly } = useUserContext();
	const isEditAllowedState = !IsLocked && !IsReadOnly;

	const theme = useTheme();

	const quoteNameStyle = {
		fontFamily: "'Inter' , sans-serif",
		fontWeight: 400,
		fontSize: "20px",
		lineHeight: "28px"
	};
	const shouldTruncate = quoteName?.length > 35;
	return (
		<>
			<Box sx={{ display: "flex" }}>
				{!focus ? (
					<Tooltip
						disableHoverListener={!shouldTruncate}
						title={quoteName}
						placement="bottom"
						sx={{ zIndex: 9997, position: "fixed" }}
					>
						<Box
							onClick={() => isEditAllowedState && setFocus(true)}
							sx={{
								...quoteNameStyle,
								maxWidth: "390px",
								...text_ellipsis,
								"&:hover": {
									cursor: isEditAllowedState ? "pointer" : "default"
								}
							}}
							data-testid= { isEditAllowedState ? "quote-name-tooltip" : "quote-name-tooltip-disabled"}
						>
							{quoteName}
						</Box>
					</Tooltip>
				) : (
					<TextField
						InputProps={{
							disableUnderline: disableUnderline
						}}
						inputProps={{
							maxLength: 255
						}}
						size="medium"
						id="standard-basic"
						variant="standard"
						onChange={(e) => setQuoteName(e.target.value)}
						onBlur={handleBlur}
						value={quoteName}
						disabled={disableUnderline}
						fullWidth={false}
						sx={{
							"& .MuiInputBase-input.Mui-disabled": {
								WebkitTextFillColor: theme.palette.text.input
							},

							"& .MuiInputBase-input": {
								backgroundColor: "transparent",
								WebkitTextFillColor: theme.palette.text.input,
								width: "390px !important",
								...quoteNameStyle,
								padding: "0px !important"
							},

							"$ .MuiInput-underline:before": {
								borderBottomColor: theme.palette.text.input
							}
						}}
						data-testid="quote-name-input"
					/>
				)}

				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						...quoteNameStyle
					}}
				>
					<Box
						sx={{ maxWidth: "170px", whiteSpace: "nowrap" }}
					>{`\u00A0- #${QuoteReadableId} v${Version}`}</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						marginLeft: "11px",
					}}
				>
					<span>
						<CreateOutlinedIcon
							onClick={() => isEditAllowedState && setFocus((v) => !v)}
							color="disabled"
							sx={{
								width: "12px",
								height: "12px",
								border: "1px",
								margin: "auto",
								cursor: isEditAllowedState ? "pointer" : "default",
								color: isEditAllowedState
									? theme.palette.text.input
									: theme.palette.text.disabled
							}}
							data-testid= {isEditAllowedState ? "quote-name-edit-pen" : "quote-name-edit-pen-disabled"}
							id = "quoteNameEditIcon"
						/>
					</span>
				</Box>
			</Box>
		</>
	);
};
