import { useCallback } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "ckeditor";
import { usePreviewContentContext } from "../context/previewContentContext";

export function CPQVirtualEditorInner({ onAutoSaveFinished }) {
    const { dogleConfigs, styleConfigs, kickStyleConfig, isLoading } = usePreviewContentContext();

    const handleEditorReady = useCallback(
        async (editor) => {
            await editor.config._config.autosave.save(editor);
            onAutoSaveFinished && onAutoSaveFinished();
        },
        [onAutoSaveFinished]
    );

    return (
        <div style={{ display: "none" }}>
            {isLoading ? <div /> :
                <CKEditor
                    onReady={handleEditorReady}
                    onError=""
                    onChange={() => { }}
                    editor={DecoupledEditor}
                    data={app.currentQuote?.HTMLContentForAutoSaving}
                    disabled={true}
                    config={{ dogleConfigs, styleConfigs, kickStyleConfig }}
                />
            }
        </div>
    );
}


