quosal.api.image = {};


//==========================================================//
//* SaveImage                                              *//
//==========================================================//
//==========================================================//
quosal.api.image.saveImage = function (args) {
    return quosal.api.call('Image.SaveImage', args);
};
// FSP 3/3/16: This isn't currently used, because the means of conveying the image to the server was tricky and not done through the web socket.
quosal.api.image.uploadImage = function (args) {
    return quosal.api.call('Image.UploadImage', args);
};
quosal.api.image.deleteImage = function (args) {
    return quosal.api.call('Image.DeleteImage', args);
};
