import { createContext, useContext, useEffect, useState } from "react";

const UserSettingsContext = createContext();

const UserSettingsProvider = ({ userSettings, children }) => {
	const [state, setState] = useState(userSettings);
	useEffect(() => {
		setState(userSettings);
	}, [userSettings]);
	return <UserSettingsContext.Provider value={state}>{children}</UserSettingsContext.Provider>;
};

const useUserSettingsContext = () => {
	const userSettings = useContext(UserSettingsContext);
	if (userSettings === undefined) {
		console.error("useUserSettingsContext must be used inside UserSettingsProvider");
	}
	return userSettings;
};

export { UserSettingsProvider, useUserSettingsContext };
