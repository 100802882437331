import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const SingleAccordian = ({ id, title, expanded, setExpanded, children }) => {
	const theme = useTheme();
	const handleChange = () => (event, isExpanded) => {
		setExpanded(isExpanded);
	};

	return (
		<Accordion
			sx={{
				boxShadow: "none",
				fontFamily: `"Inter", sans-serif`,
				margin: "16px 0px",
				color: theme.palette.text.main,
				"&:before": {
					backgroundColor: "unset"
				}
			}}
			expanded={expanded}
			onChange={handleChange()}
		>
			<AccordionSummary
				sx={{
					backgroundColor: theme.palette.background.accordian_summary,
					"&.MuiButtonBase-root": {
						minHeight: "38px",
						height: "38px"
					}
				}}
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel-content"
				id={`${id}-panel-header`}
			>
				<Typography sx={{ fontSize: "14px", lineHeight: "18px", letterSpacing: "0.16px" }}>
					{title}
				</Typography>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					backgroundColor: theme.palette.background.accordian_details,
					padding: "16px 48px 16px 16px"
				}}
			>
				{children}
			</AccordionDetails>
		</Accordion>
	);
};
