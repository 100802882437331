import React, { useEffect, useState } from 'react';
import { Grid, Stack, Typography } from "@mui/material";
import { getAuditTrails } from './services';
import { useQuoteHeader } from '../quoteHeader/customHooks';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { NO_RECORDS_FOUND } from './constants';
import { AppTheme } from '../../../common/theme';


const AuditTrail = () => {
    const [auditTrailArray, setAuditTrailArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const { currentQuote: quote } = useQuoteHeader();

    const columns = [
        {
            id: "0",
            field: "EventDate",
            headerName: "Date",
            disableColumnMenu: true,
            width: 200,
            valueGetter: (value) => ({
                raw: new Date(value),
                display: new Date(value).toLocaleString()
            }),
			valueFormatter: (value) => value.display,
            sortComparator: (v1, v2) => v1.raw - v2.raw
        },
        {
            id: "1",
            field: "EventInfo",
            headerName: "Event",
            disableColumnMenu: true,
            flex: 1,
            valueGetter: (value, row) => {
                return value || row?.ContentText
            }
        },
        {
            id: "2",
            field: "FirstName",
            headerName: "First",
            disableColumnMenu: true,
            width: 200
        },
        {
            id: "3",
            field: "LastName",
            headerName: "Last",
            disableColumnMenu: true,
            width: 200
        },
        {
            id: "4",
            field: "QuoteReadableId",
            headerName: "Number",
            disableColumnMenu: true,
            width: 100,
            valueGetter: (value,row) => {
                return `${value} v${row?.QuoteVersion}`
            }
        },
        {
            id: "5",
            field: "QuoteTotal",
            headerName: "Total",
            disableColumnMenu: true,
            width: 100
        },
        {
            id: "6",
            field: "QuoteStatus",
            headerName: "Status",
            disableColumnMenu: true,
            width: 100
        },
        {
            id: "7",
            field: "OrderPorterApproved",
            headerName: "Approved",
            disableColumnMenu: true,
            width: 100,
            valueGetter: (value) => value ? "True" : "False",
        },
        {
            id: "8",
            field: "InvoicePostStatus",
            headerName: "Post",
            disableColumnMenu: true,
            width: 100
        }
    ];

    useEffect(() => {
        if (!quote) return;

        const getAuditTrailData = async () => {
            const { response0 } = await getAuditTrails({
                "QuoteReadableId": quote?.QuoteReadableId,
                "criteria": { "OrderBy": "modify_date DESC" },
            });
            if (!response0?.error) {
                const { results } = response0;
                setAuditTrailArray(results?.map((item) => JSON.parse(item)));
                setLoading(false)
            } else {
                setAuditTrailArray([]);
                setLoading(false)
            }
        }
        setLoading(true);
        getAuditTrailData()
    }, [quote]);



    return (
        <AppTheme>
            <Grid container >
                <Typography sx={{ padding: '10px 0', fontSize:"15px",fontWeight:'bold' }}>Quote Audit Trail</Typography>
                <Grid
                    sx={{
                        minHeight: loading ?  '50vh' : "auto",
                        width: '100%',
                        padding: '10px 0'
                    }}
                >
                    <DataGridPro
                        data-testid="test-audit-trail-grid"
                        filterMode="client"
                        density="compact"
                        columns={columns}
                        rows={auditTrailArray}
                        getRowId={(row) => row.IdQuoteAuditTrail}
                        getRowHeight={()=> 'auto'}
                        sx={(theme) => ({
                            borderColor: theme.palette.quote_step.lockedFontColor,
                            '& .MuiDataGrid-columnHeaderTitle': {
                                color: 'primary.main',
                                fontWeight: 'bold'
                            },
                            '& .MuiDataGrid-row': {
                                border: `1px solid  ${theme.palette.quote_step.lockedFontColor}`,
                            },
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                        })}
                        disableVirtualization
                        loading={loading}
                        slotProps={{
                          loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                          },
                        }}
                        slots={{
                            noRowsOverlay: () => <Stack data-testid="test-audit-trail-grid-no-data" height="100%" alignItems="center" justifyContent="center">
                                {!loading && NO_RECORDS_FOUND || ''}
                            </Stack>,
                        }}
                    />
                </Grid>
            </Grid>
        </AppTheme>
    );
};

export default AuditTrail;
