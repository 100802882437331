import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const CommonButton = styled((props) => (<Button sx={{ textTransform: 'none' }} {...props} />))(({ variant, theme }) => {
    const { main } = theme.palette.button_primary;

    switch (variant) {
        case "outlined":
            return ({
                color: main,
                borderColor: main,
                '&:hover': {
                    borderColor: main,
                },
            })
        case "contained":
            return ({
                color: "#FFF",
                borderColor: main,
                backgroundColor: main,                
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                    borderColor: main,
                },
            })
        case "ghost":
            return ({
                color: theme.palette.text.main,
                backgroundColor: theme.palette.background.ghost,
            })
    }
});
