import {ApprovalSettings} from "js/jsx/src/classes/admin/approvals/ApprovalSettings.jsx"

export default function approvalsetup(){

    var loadApprovalSetupPage = function () {

        let initApprovalSetupApi = quosal.api.approval.InitApprovalSetup();
        initApprovalSetupApi.finished = function(msg){
            quosal.ui.react.render(<DialogManager />, document.getElementById('dialogManager'));
            if (!MicroGrowl.singleton) {
                quosal.ui.react.render(<MicroGrowl />, document.getElementById('notifierholder'));
            }
            quosal.ui.react.render(<ApprovalSettings approvalMode={msg.approvalMode} 
                mlaEnableApproved={msg.mlaEnableApproved} 
                communicateMethod={msg.communicateMethod} 
                allowMLAManualWinBeforeApproval = {msg.allowMLAManualWinBeforeApproval}
                requireComments={msg.requireComments}/>, document.getElementById('currentDocument'));
            $('#pageName').val('approvalsetup.quosalweb');
        }
        initApprovalSetupApi.call();
    };
    
    quosal.sell.modules.onAfterLoad.unbind().bind(loadApprovalSetupPage);
}