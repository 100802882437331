import { useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const DefaultStepIcon = () => {
	const theme = useTheme();
	return (
		<div
			style={{
				width: 20,
				height: 20,
				borderRadius: "50%",
				backgroundColor: theme.palette.quote_stepper.inactive,
				border: `1px solid ${theme.palette.quote_stepper.inactiveBorder}`,
			}}
		/>
	);
};

const CompletedStepIcon = () => {
	const theme = useTheme();
	return (
		<div
			style={{
				width: 20,
				height: 20,
				borderRadius: "50%",
				backgroundColor: theme.palette.quote_stepper.completed,
				border: `1px solid ${theme.palette.quote_stepper.completedBorder}`,
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
		>
			<EditIcon sx={{ fontSize: 14, color: theme.palette.quote_stepper.completedBorder }} />
		</div>
	);
};

const ActiveStepIcon = () => {
	const theme = useTheme();
	return (
		<div
			style={{
				width: 20,
				height: 20,
				borderRadius: "50%",
				backgroundColor: theme.palette.quote_stepper.active,
				border: `1px dashed ${theme.palette.quote_stepper.activeBorder}`,
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
		>
			<div
				style={{
					width: 12,
					height: 12,
					borderRadius: "50%",
					backgroundColor: theme.palette.quote_stepper.activeBorder
				}}
			/>
		</div>
	);
};

const DisabledStepIcon = () => {
	const theme = useTheme();
	return (
		<div
			style={{
				width: 20,
				height: 20,
				borderRadius: "50%",
				backgroundColor: theme.palette.quote_stepper.disabled
			}}
		/>
	);
};

export const StepIcon = ({ isActive, isDisabled, isCompleted }) => {
	if (isDisabled) {
		return <DisabledStepIcon />;
	}

	if (isCompleted) {
		return <CompletedStepIcon />;
	}

	return <>{isActive ? <ActiveStepIcon /> : <DefaultStepIcon />}</>;
};
