export class ApprovalSettings extends React.Component {
    constructor(props) {
        super(props);
        this.count = 10;
        this.state = {      
            originalApprovalMode: this.props.approvalMode,
            mlaEnableApproved: this.props.mlaEnableApproved,
            approvalmode: this.props.approvalMode,
            forexport: true,
            forthresh: false,
            formargin: false,
            approveextra: true,
            amtthresh: 0,
            amtmargin: 10,
            communicatemode: this.props.communicateMethod,
            requireComments: this.props.requireComments,
            allowMLAManualWinBeforeApproval: this.props.allowMLAManualWinBeforeApproval
        }; 

        this.componentDidMount = this.componentDidMount.bind(this);
        this.doNotify = this.doNotify.bind(this);
        this.handleNotifyClick = this.handleNotifyClick.bind(this);
        this.updateMode = this.updateMode.bind(this);
        this.renderToolbar = this.renderToolbar.bind(this);
        this.renderSettings = this.renderSettings.bind(this);
        this.renderMode = this.renderMode.bind(this);
        this.updateCommunicate = this.updateCommunicate.bind(this);
        this.doSaveClicked = this.doSaveClicked.bind(this);
        this.doSaveApiCall = this.doSaveApiCall.bind(this);
        this.saveNone = this.saveNone.bind(this);
        this.saveOP = this.saveOP.bind(this);
        this.saveMLA = this.saveMLA.bind(this);
        this.resetMLA = this.resetMLA.bind(this);       
    }   

    hideDisableApprovalTitle() {
        if (this.state.originalApprovalMode != 'Order Porter') {
            $('.disableApprovalWrapper').removeAttr('title');
        }
    }

    componentDidMount() {  
        this.hideDisableApprovalTitle();      
    }

    componentDidUpdate() {
        this.hideDisableApprovalTitle();
    }

    updateMode(id) {
        this.setState({approvalmode: id});
        this.isDirty = true;
    }

    updateCommunicate(id) {
        this.setState({communicatemode: id});
        this.isDirty = true;
    }

    doSaveClicked(){
        if(this.state.approvalmode == "Multilevel" && !this.state.mlaEnableApproved){
            Dialog.open({
                title: 'Confirm Permanent Change',
                message: 'After you click Save, you cannot revert to the Order Porter approval process.',
                closeRequiresButton: true,
                links: [{
                    title: 'Yes, Save',
                    callback: this.doSaveApiCall
                },{
                    title: 'Cancel',
                    callback: Dialog.closeAll
                }]
            });
        } else {
            this.doSaveApiCall();
        }
    }

    doSaveApiCall(){
        Dialog.setIsWorking();
        let saveApi = quosal.api.approval.SaveApprovalSetup(this.state.approvalmode, this.state.communicatemode, this.state.requireComments, this.state.allowMLAManualWinBeforeApproval);
        saveApi.finished = function(msg){
            this.isDirty = false;
            this.setState({originalApprovalMode: msg.approvalMode, mlaEnableApproved: msg.mlaEnableApproved});
            Dialog.setIsWorking(false);
            Dialog.closeAll();
        }.bind(this);
         saveApi.call();
    }

    saveNone(){
        this.state["approvalmode"] = "None";
        this.doSaveApiCall();
    }

    saveOP(){
        this.state["approvalmode"] = "Order Porter";
        this.doSaveApiCall();
    }

    saveMLA(){
        this.state["approvalmode"] = "Multilevel";
        this.doSaveApiCall();
    }

    resetMLA(){
        Dialog.setIsWorking();
        let saveApi = quosal.api.approval.SaveApprovalSetup("reset");
        saveApi.finished = function(msg){
            this.isDirty = false;
            this.setState({originalApprovalMode: msg.approvalMode, mlaEnableApproved: msg.mlaEnableApproved});
            Dialog.setIsWorking(false);
            Dialog.closeAll();
        }.bind(this);
         saveApi.call();
    }

    renderToolbar() {
        return (
            <div className='panel only' help='admin/approval/approval-setup' style={{height:'22px'}}>
                <div className='title'><span className='floatleft' style={{top:'6px'}} ></span>
                    <div className='toolbar right' id='approvalSetupButtons' style={{marginRight:'10px', position: 'relative', top:'-4px'}}>
                        <CwWidgets.CwButton toolTip='Cancel changes to the Approval settings' isStandard={true} 
                                    onClick={this.doCancelClicked} value='CANCEL' style={{marginRight:'10px'}} className='secondary' />
                        <CwWidgets.CwButton toolTip='Save changes to Approval settings' isStandard={true} 
                                    onClick={this.doSaveClicked} value='SAVE'  
                                    disabled={!this.isDirty}/>
                    </div>
                </div>
            </div>);
    }

    //currently these options do not do anything as they are hard-coded values, hiding them to avoid confusion (might add their functions later on)
    renderSettings(){
        /*let me = this;
        //, border:'1px solid green'

        let threshhold = [];
        if (this.state.approvalmode == 'Order Porter') {
            threshhold.push(<div className='formfield' style={{width:'400px', fontSize: '12px', marginTop:'8px'}}>
            <CwWidgets.CwCheckBox id="chkAmountMargin" text="Use Approval Margin Threshold" value={this.state.forthresh} className='chktst'
                    onChange={(value) => { me.setState({forthresh: value}); }} 
                    />
            </div>);
            if (this.state.forthresh) {
                threshhold.push(<div id="approvalAmountSlider">
                    <div className="hoverinfoarea">
                        <div className="formfieldlabel"><label for="ApprovalMarginThreshhold" className="formlabel">Quote Margin Less Than</label></div>
                        <div className="formfield" style={{marginTop:'4px'}}><input type="text" name="ApprovalMarginThreshhold" id="ApprovalMarginThreshhold" 
                                            onChange={(e) => { me.setState({amtmargin: e.target.value }); }}
                                            value={this.state.amtmargin} /></div>
                    </div>
                </div>);
            }
        }       

        let margin = [];
        if (this.state.approvalmode == 'Order Porter') {
            margin.push(<div className='formfield' style={{width:'400px', fontSize: '12px', marginTop:'8px'}}>
                <CwWidgets.CwCheckBox id="chkAmountThreshold" text="Use Approval Total Threshold" value={this.state.formargin} className='chktst'
                        onChange={(value) => { me.setState({formargin: value}); }} 
                        />
            </div>);
            //parseFloat(e.target.value)
            if (this.state.formargin) {
                margin.push(<div id="approvalAmountSlider">
                    <div class="hoverinfoarea">
                        <div class="formfieldlabel"><label for="ApprovalAmountThreshhold" class="formlabel">Quote Total Greater Than</label></div>
                        <div class="formfield" style={{marginTop:'4px'}}><input type="text" name="ApprovalAmountThreshhold" id="ApprovalAmountThreshhold"
                                onChange={(e) => { me.setState({amtthresh: e.target.value }); }}
                                value={this.state.amtthresh} /></div>
                    </div>
                </div>);
            }
        }
        */

        if (this.state.approvalmode == 'Multilevel') {        
            const radiocommunicate = [{ id: 'InsideRep', name: 'Inside Rep' }, { id: 'PrimaryRep', name: 'Primary Rep' }, { id: 'Both', name: 'Both' }];
            let selectedcommunicate = radiocommunicate[2];
            for (let i=0;i<radiocommunicate.length;i++) {
                if (radiocommunicate[i].id == this.state.communicatemode) {
                    selectedcommunicate = radiocommunicate[i];
                }
            }

            let communicate = (<div className='formcolumn' style={{paddingLeft:'0px', width:'400px', display:'block'}}>
                <div className='formfieldlabel formlabelfull'><label htmlFor='approvalmode' className='formlabel'>When a Multi-Level Notification Is Sent, Notify...</label></div>
                <div className='formfield' style={{width:'400px', fontSize: '12px', marginTop: '12px'}}>
                    <CwWidgets.CwRadioButtonGroup groupName='communicatemode'
                        radioButtons={radiocommunicate} 
                        value={selectedcommunicate}
                        onChange={(btn) => { this.updateCommunicate(btn.id) }}
                        columns={3} />
                </div>
                <div className='formfield' style={{width:'400px', fontSize: '12px'}}>
                <CwWidgets.CwCheckBox id="chkForRequireComment" text="Require Comments during Multi-level Approvals" value={this.state.requireComments} className='chktst'
                                onChange={(value) => { this.setState({requireComments: value}); this.isDirty = true; }} />
                </div>
                <div className='formfield' style={{width:'400px', fontSize: '12px'}}>
                <CwWidgets.CwCheckBox id="chkForManualWin" text="Allow Standard Users to Manually Win before requesting MLA Approval" value={this.state.allowMLAManualWinBeforeApproval} className='chktst'
                                onChange={(value) => { this.setState({allowMLAManualWinBeforeApproval: value}); this.isDirty = true; }} />
                </div>
            </div>);

            return (
                <div data-cy="multiLevelOptions" className='formcolumn' style={{paddingLeft:'0px'}}>                                    
                    {communicate}
                </div>
            );
        }
        /*
        add this back to the return return statement when they have been implemented
            <div className='formfield' style={{width:'400px', fontSize: '12px'}}>
                        <CwWidgets.CwCheckBox id="chkForExport" text="Require Appprovals for Export" value={this.state.forexport} className='chktst'
                                onChange={(value) => { me.setState({forexport: value}); }} 
                            />
                    </div>
                    <div className='formfield' style={{width:'400px', fontSize: '12px'}}>
                        <CwWidgets.CwCheckBox id="chkExtraApprover" text="Approvers have Extra Quote edit authority" value={this.state.approveextra} className='chktst'
                            onChange={(value) => { me.setState({approveextra: value}); }} 
                        />
                    </div>

                    {threshhold}
                    {margin}
        */
    }

    renderMode() {
        const radioordering = {
            'Multilevel': { id: 'Multilevel', name: 'Multi-Level', checked: false }, 
            'None': { id: 'None', name: 'Disable Approvals', checked: false}, 
            'Order Porter': { id: 'Order Porter', name: 'Order Porter (Legacy)', checked: false }
        };

        if (this.state.originalApprovalMode == 'Order Porter'){
            radioordering['None'].disabled = true;
        }

        try {
            radioordering[this.state.approvalmode].checked = true;
        } catch{}

        return (
            <Panel title='Approval Setup'>
                <PanelContent>
                    <div className='formcolumn' style={{paddingLeft:'0px', width:'400px', display:'block'}}>
                        <div className='formfieldlabel formlabelfull'><label htmlFor='approvalmode' className='formlabel'>Quote Approval Mode</label></div>
                        <div className='formfield' style={{width:'400px', fontSize: '12px', marginTop: '12px'}}>
                            <div className="mlaApprovalWrapper">
                                <CwWidgets.CwRadioButton groupName='approvalmode'
                                    value={radioordering['Multilevel']}
                                    onChange={(btn) => { this.updateMode(btn.id) }}
                                    disabled= {radioordering['Multilevel'].disabled} />
                            </div>
                            <div className="disableApprovalWrapper" title="To disable Manager Approvals through Order Porter, please go to Settings > Quote Preparations > Quote Setup, and deselect 'Requires Approval'" >
                            <CwWidgets.CwRadioButton groupName='approvalmode'
                                value={radioordering['None']}
                                onChange={(btn) => { this.updateMode(btn.id) }}
                                disabled={radioordering['None'].disabled} />
                            </div>
                            {this.state.originalApprovalMode != "Multilevel" && !this.state.mlaEnableApproved &&
                                <div className="opApprovalWrapper">
                                    <CwWidgets.CwRadioButton groupName='approvalmode'
                                        value={radioordering['Order Porter']}
                                        onChange={(btn) => { this.updateMode(btn.id) }}
                                        disabled={radioordering['Order Porter'].disabled} />
                                </div>
                            }
                        </div>
                    </div>
                    {this.renderSettings()}
                </PanelContent>
            </Panel>
        );
    };

    render() {
        let sty = {cursor: 'pointer', fontSize:'14px', margin:'4px', border:'1px lightgray solid '};
        return (
            <div>
                {this.renderToolbar()}
                {this.renderMode()}

<br /> <br />
                { app.currentUser.IsQuosalAdmin &&
                <div>
                    <Panel title='Testing of Approver Mode Saving'>
                    <button style={sty} onClick={this.saveMLA} >Save MLA</button>
                    <button style={sty} onClick={this.saveNone} >Save None</button>
                    <button style={sty} onClick={this.saveOP} >Save OrderPorter</button>
                    <button style={sty} onClick={this.resetMLA} >Reset MLA Enabled Flag</button>
                    </Panel>
                    <Panel title='Testing of Notifications'>
                    <div className="notificationTesting" style={sty} onClick={()=>{this.doNotify(1)} }>Notify Warning</div>
                    <div className="notificationTesting" style={sty} onClick={()=>{this.doNotify(2)} }>Notify Info</div>
                    <div className="notificationTesting" style={sty} onClick={()=>{this.doNotify(3)} }>Notify Success 0</div>
                    <div className="notificationTesting" style={sty} onClick={()=>{this.doNotify(4)} }>Notify Error</div>
                    <div className="notificationTesting" style={sty} onClick={()=>{this.doNotify(5)} }>Notify Success 1</div>
                    </Panel>
                </div>
                }
              
            </div>            
          );
    }

    handleNotifyClick(note) {
        return true;
    }

    doNotify(version) {
        switch (version) {
            case 1:
            MicroGrowl.addNotification({ id: 1001, level: 'warn', msg: 'Just a warning here ' + this.count, delay: 8000, noteClicked:this.handleNotifyClick });
            break;
            case 2:
            MicroGrowl.addNotification({ id: 1002, level: 'info', msg: 'Here is some info ' + this.count, delay: 4000, noteClicked:this.handleNotifyClick });
            break;
            case 3:
            MicroGrowl.addNotification({ level: 'success', msg: 'Very Very good ' + this.count, delay: 0 });
            break;
            case 4:
            MicroGrowl.addNotification({ level: 'error', 
                        msg: 'Aww. Too bad. But this is a very long message that may wrap or truncate.' + this.count +
                        ' There might even be a limit to how much text can be displayed here', 
                        delay: 7500, closebox:false, noteClicked:this.handleNotifyClick });
            break;
            default:
            MicroGrowl.addNotification({ id: 2001, level: 'success', msg: 'Very good ' + this.count, delay: 0, noteClicked:this.handleNotifyClick });
            break;
        }
        
        this.count += 2;
    }

}
global.ApprovalSettings = ApprovalSettings;


global.kApprovalSettings = {};
kApprovalSettings.DataMatrix = [
        {datatype: 'String', options: ['eq', 'ne', 'ct', 'nc']},
        {datatype: 'Boolean', options: ['eq'/*, 'ne'*/]},
        {datatype: 'DateTime', options: ['eq', 'ne', 'lt', 'gt', 'le', 'ge']},
        {datatype: 'Int32', options: ['eq', 'ne', 'lt', 'gt', 'le', 'ge']},
        {datatype: 'Double', options: ['eq', 'ne', 'lt', 'gt', 'le', 'ge']}
    ];
kApprovalSettings.DataDescriptions = quosal.multiLevelApprovals.dataDescriptions

kApprovalSettings.CommonItem = ['Cost', 'CostModifier', 'ManufacturerPartNumber', 'QuoteItemPrice', 'Quantity',
                        'RecurringCost', 'Discount', 'DiscountAmount', 'ExtendedPrice', 'GrossMargin',
                        'IsModTagModified', 'ProductCategory', 'RecurringMargin', 'RecurringTotal'];
kApprovalSettings.CommonQuote = ['QuoteCost', 'AccountName', 'ManufacturerPartNumber', 'QuoteItemPrice', 'Quantity',
                        'RecurringCost', 'Discount', 'DiscountAmount', 'ExtendedPrice', 'GrossMargin', 'IsSent', 'Markup', 'OrderStatus',
                        'IsModTagModified', 'Owner', 'ProductCategory', 'RecurringMargin', 'RecurringTotal', 'QuoteTotal', 'PublishNumber'];
kApprovalSettings.ApproverTypeManager = 'manager';
kApprovalSettings.ApproverTypeGroup = 'group';
kApprovalSettings.ApproverTypeUser = 'user';