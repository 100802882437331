import { styled } from '@mui/material/styles';
import { Box, FormControlLabel } from '@mui/material';

export const SalestrackCardStyled = styled(Box)(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    width: '360px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '4px',
    border: 'none',
    boxShadow: '0px 4px 8px 0px #0000001A',
    backgroundColor: theme.palette.background.main,
    padding: '20px',
    gap: '16px',
    flex: 10, 
    height: 'fit-content', 
    marginRight: '10px',
}));

export const SalestrackFormCharacterCounterStyled = styled(Box)(({ theme }) => ({
    zIndex: 1000,
    position: 'absolute',
    top: '-.6em',
    right: '35px',
    padding: '0 5px',
    backgroundColor: theme.palette.background.main,
    color: theme.palette.text.main,
    fontFamily: 'Roboto, Helvetica, Arial , sans-serif',
    fontWeight: 400,
    fontSize: '0.9rem',
}));

export const SalestrackFormMainLabelStyled = styled(Box)(({ theme }) => ({
    fontSize: '20px',
    lineHeight: '20px',
    color: theme.palette.text.main,
}));

export const SalestrackFormCardPreviewImageStyled = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: 'none',
    boxShadow: '0px 4px 8px 0px #0000001A',
    backgroundColor: theme.palette.background.main,
    padding: '20px',
    margin: '0 10px 20px',
    width: '360px',
    height: '360px',
    '& img': {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
    }
}));

export const SalestrackFormCardImageStyled = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: theme.palette.background.main,
    color: theme.palette.text.main,
    boxShadow: '0px 4px 8px 0px #0000001A',
    padding: '20px',
    margin: '0 10px 20px',
    display: 'flex',
    position: 'relative',
    width: '360px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    '& label': {
        fontSize: '11px',
    },
}));

export const SalestrackFormLinkToFormStyled = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: theme.palette.background.main,
    color: theme.palette.text.main,
    boxShadow: '0 0 6px #b9b9b9',
    padding: '20px',
    margin: '0 5px 10px',
    display: 'flex',
    position: 'relative',
    width: '360px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',    
}));

export const SalestrackFormFilePreviewStyled = styled(Box)(({ theme }) => ({
    width: '88%',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
    borderRadius: '4px',
    backgroundColor: theme.palette.background.secondary,
    '& p': {
        color: theme.palette.text.main,
    }
}));

export const SalestrackFormRadioStyled = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ theme }) => ({
    ".MuiRadio-colorPrimary": {
      color: theme.palette.icon.main,
      "&.Mui-checked": {
        color: theme.palette.icon.main,
        },
    },
  }));