import { fetchData } from "../services/fetchData";

// eslint-disable-next-line import/prefer-default-export
export function useAddToQuote() {
	const createPayload = (products) => {
		const rtpPayload = products
			.filter((product) => product.etilizeProductId)
			?.map((rtpProd) => ({
				productid: rtpProd.etilizeProductId,
				source: rtpProd.sourceId,
				cost: rtpProd.price.toString(),
				thumbnail: rtpProd.image,
				quantity: rtpProd.quantity || 1,
				destination: rtpProd.targetTabId
			}));

		const nonRtpPayload = products
			.filter((product) => !product.etilizeProductId)
			?.map((nonRtp) => ({
				itemId: nonRtp.sourceId,
				source: nonRtp.source,
				vendorName: nonRtp.source,
				quantity: nonRtp.quantity || 1,
				idQuoteTabs: nonRtp.targetTabId,
				priceLevel: null,
				insertBefore: null
			}));

		return {
			rtpPayload: rtpPayload.length ? rtpPayload : null,
			nonRtpPayload: nonRtpPayload.length ? nonRtpPayload : null
		};
	};

	const handleAddToQuote = async (products) => {
		const { rtpPayload, nonRtpPayload } = createPayload(products);

		const promises = [];

		if (rtpPayload) {
			const attachRtp = fetchData(
				"quosalweb/quosalapi/Product.AttachEtilizeProducts",
				"Product.AttachEtilizeProducts",
				{ products: rtpPayload, attachMainProduct: true }
			).then((resp) => {
				const { response0: { quote, error } = [] } = resp;
				if (error) {
					console.error("Error:", error);
					return;
				}
				return quote;
			});

			promises.push(attachRtp);
		}

		if (nonRtpPayload) {
			const attachNonRtp = fetchData(
				"quosalweb/quosalapi/Product.AttachNonRtpProducts",
				"Product.AttachNonRtpProducts",
				{ products: nonRtpPayload }
			).then((resp) => {
				const { response0: { result, quote, error } = [] } = resp;
				if (error) {
					console.error("Error:", error);
					return;
				}

				return { result: result.map((prod) => prod.Item), quote };
			});

			promises.push(attachNonRtp);
		}

		await Promise.all(promises)
			.then((results) => {
				if (results.length === 2) {
					const [quote, nonRtpResult] = results;
					const updatedQuote = {
						...quote,
						Items: [...quote.Items, ...nonRtpResult.result]
					};

					quosal.sell.quote.update(updatedQuote);
				} else {
					const quote = results[0].quote || results[0];
					quosal.sell.quote.update(quote);
				}
			})
			.catch((error) => {
				// Handle errors if needed
				console.error(error);
			});
	};

	return { handleAddToQuote };
}
