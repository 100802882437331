import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ModalBox } from "../styledComponents/productSearchTable.jsx";
import { StyledIconButton } from "../styledComponents/common.jsx";

function BasicModal({ isOpen, title, body, buttons, handleClose }) {
	return (
		<Modal open={isOpen} onClose={() => handleClose(!isOpen)}>
			<ModalBox>
				<Box display="flex" justifyContent="space-between">
					<Typography fontSize={20}>{title}</Typography>
					<StyledIconButton size="small" onClick={() => handleClose(!isOpen)}>
						<CloseIcon />
					</StyledIconButton>
				</Box>
				<Typography sx={{ mt: 2 }}>{body}</Typography>
				<Box display="flex" justifyContent="flex-end" mt={4}>
					{buttons}
				</Box>
			</ModalBox>
		</Modal>
	);
}

export default BasicModal;
