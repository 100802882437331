import React from "react";
import { Chip, Divider, useTheme } from "@mui/material";

import { useQuoteContext } from "../context";

export const BannerComponent = () => {
	const theme = useTheme();
	const { IsArchive, IsTemplate, QuoteStatus, IsLocked, ApprovalStatus } = useQuoteContext();
	const isEoPage = !!document.getElementById("isEoPage")?.length;
	const notAcrchived =
		!isEoPage && IsLocked && !IsArchive && !IsTemplate && QuoteStatus !== "Archived";
	const divider = <Divider orientation="vertical"
		sx={{
			display: { lg: "block", xl: "none" },
			color: theme.palette.divider,
			background: theme.palette.divider,
			height: "16px",
			margin: "8px",
		}} />
	const isBannerVisible = (!isEoPage && IsArchive && !IsTemplate) ||
		(!isEoPage && IsLocked && !IsArchive && !IsTemplate && QuoteStatus === "Archived")
		|| notAcrchived ||
		(!isEoPage && QuoteStatus === "Deleted" && !IsTemplate) ||
		(!isEoPage && IsTemplate && !IsArchive && QuoteStatus !== "Deleted") ||
		(!isEoPage && IsTemplate && IsArchive) ||
		(!isEoPage && IsTemplate && QuoteStatus === "Deleted")
	return (
		<>
			{isBannerVisible && divider}

			{!isEoPage && IsArchive && !IsTemplate && (
				<ChipComp
					label="Historical Version"
					background={theme.palette.background.chipHT}
					textColor={theme.palette.text.chipHT}
				/>
			)}

			{!isEoPage && IsLocked && !IsArchive && !IsTemplate && QuoteStatus === "Archived" && (
				<ChipComp
					label="Locked Down - Archived Quote"
					background={theme.palette.background.chipLD}
					textColor={theme.palette.text.chipLD}
				/>
			)}

			{notAcrchived ? (
				app.settings.global.ApprovalMethod === "Multilevel" &&
				!isEoPage &&
				IsLocked &&
				!IsArchive &&
				!IsTemplate &&
				ApprovalStatus !== "" ? (
					ApprovalStatus === "Request Approval" ? (
						<ChipComp
							label="Locked Down - Pending Approval"
							background={theme.palette.background.chipLD}
							textColor={theme.palette.text.chipLD}
						/>
					) : ApprovalStatus === "Approved" ? (
						<ChipComp
							label="Locked Down - Approved"
							background={theme.palette.background.chipLD}
							textColor={theme.palette.text.chipLD}
						/>
					) : (
						""
					)
				) : (
					<ChipComp
						label="Locked Down"
						background={theme.palette.background.chipLD}
						textColor={theme.palette.text.chipLD}
					/>
				)
			) : (
				""
			)}

			{!isEoPage && QuoteStatus === "Deleted" && !IsTemplate && (
				<ChipComp
					label="Deleted Version"
					background={theme.palette.background.chipDT}
					textColor={theme.palette.text.chipDT}
				/>
			)}

			{!isEoPage && IsTemplate && IsArchive && (
				<ChipComp
					label="Historical Template"
					background={theme.palette.background.chipHT}
					textColor={theme.palette.text.chipHT}
				/>
			)}

			{!isEoPage && IsTemplate && QuoteStatus === "Deleted" && (
				<ChipComp
					label="Deleted Template"
					background={theme.palette.background.chipDT}
					textColor={theme.palette.text.chipDT}
				/>
			)}

			{!isEoPage && IsTemplate && !IsArchive && QuoteStatus !== "Deleted" && (
				<ChipComp
					label="Editing Template"
					background={theme.palette.background.chipET}
					textColor={theme.palette.text.chipET}
				/>
			)}
		</>
	);
};

const ChipComp = ({ label, background, textColor }) => {
	return (
		<Chip
			key={label}
			label={label}
			color="secondary"
			sx={{
				height: "24px",
				marginRight: "8px",
				backgroundColor: background,
				color: textColor
			}}
		/>
	);
};
