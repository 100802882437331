import React, { useCallback, useState } from "react";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Box, Chip, Divider, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import { useQuoteContext } from "../context";
import { useOpportunitySyncContext } from "../context/OpportunitySyncContext";
import { checkIfSyncEnabled } from "../context/OpportunitySyncContext";

export const Opportunity = ({ isVisible=false }) => {
	const theme = useTheme();
	let opportunityButtonProps = null;
	const quote = useQuoteContext();
	const isEoPage = window.location.href.includes('electonicorder') || window.location.href.includes('eo-opportunity');
	const syncEnabled = checkIfSyncEnabled();

	const [allowOpportunity, setAllowOpportunity] = useState(
		quosal.util.crmAllowsOpportunityCheck()
	);

	const showOpportunityStatus = allowOpportunity && !quote.IsTemplate;

	if (allowOpportunity) {
		opportunityButtonProps = {};
		if (quote.IdCRMOpportunity) {
			opportunityButtonProps.className = "quote-opportunity-active";
			opportunityButtonProps.howerText = quote.IdCRMOpportunity + " is Attached";
			opportunityButtonProps.title = "Opportunity Details";
		} else {
			opportunityButtonProps.className = "quote-opportunity-inactive";
			opportunityButtonProps.howerText = "No Opportunity Attached";
			opportunityButtonProps.title = "Attach Opportunity";
		}
	}

	const goToOpportunity = useCallback(
		(quoteData) => {
			if (!quoteData || !quoteData.QuoteName) {
				quoteData = app.currentQuote;
			}
			if (quoteData.IsTemplate) {
				return;
			}
			let nextModule;
			const disallowCustomerAndOppSearch = quosal.settings.getValue("disallowCustomerSearch");
			const hasCustomer = quoteData.AccountName != "";
			if (!quoteData.IdCRMOpportunity && !hasCustomer && !disallowCustomerAndOppSearch) {
				nextModule = "quote.attachOpportunity";
			} else {
				nextModule = "crm.opportunity";
			}

			if (app.currentModule.Url === "quote.dashboard") {
				app.currentModule.loadSubModule("quote.customer", {
					container: "quoteModule",
					unloadSubModules: true,
					query: "nextModule=" + nextModule
				});
			} else {
				const href = quosal.util.url(
					"quote.dashboard",
					`submodules=quote.customer` + `,${nextModule}`,
					"idquotemain=" + quoteData.IdQuoteMain
				);
				window.open(href, "_self");
			}
		},
		[quote]
	);

	return (
		<>
			{!isEoPage && isVisible && showOpportunityStatus && (
				<>
				<Box
					sx={{
						display: "flex",
						cursor: "pointer",
						alignItems: "center",
					}}
					gap={1}
					onClick={() => goToOpportunity(quote)}
				>
					<Typography
						title={opportunityButtonProps?.howerText}
						sx={{
							fontWeight: 600,
							lineHeight: "18px",
							fontSize: "14px",
							color: theme.palette.primary.main
						}}
					>
						{opportunityButtonProps?.title}
					</Typography>
					{opportunityButtonProps?.icon}
					{allowOpportunity && quote.IdCRMOpportunity ? (
						<MonetizationOnIcon
							sx={{ fontSize: "16px", color: theme.palette.primary.main }}
						/>
					) : (
						<CurrencyExchangeIcon
							sx={{ fontSize: "16px", color: theme.palette.primary.main }}
						/>
					)}
					{!syncEnabled && 
						<Divider
							orientation="vertical"
							sx={{
								color: theme.palette.divider,
								background: theme.palette.divider,
								height: "16px"
							}}
							flexItem
						/>
					}
				</Box>
				<OpportunitySync />
				</>
			)}
		</>
	);
};

Opportunity.propTypes = {
	isVisible: PropTypes.bool
};

const OpportunitySync = () => {
	const theme = useTheme();
	const { syncing, syncError, syncFinished, lastUpdateTime, syncEnabled } = useOpportunitySyncContext();

	return syncEnabled ? <div style={{display: "flex", alignItems:"center", marginLeft:"8px"}}>
		{syncFinished ? 
			<Tooltip title={`Last update time: ${lastUpdateTime}\r\n${syncError ? syncError : ""}`}>
				<Chip
					label = {syncError ? "Sync Failed" : "Sync Successful"}
					color = {syncError ? "error" : "success"}
					sx={{
						height: "24px",
						marginRight: "8px"
					}}
				/>
			</Tooltip> :
		syncing && 
			<Chip
				label = {<div>Sync In Progress</div>}
				sx={{
					height: "24px",
					marginRight: "8px",
					backgroundColor: theme.palette.background.chipNotification,
					color: theme.palette.text.chipHT
				}}
			/>
		}
		<Divider orientation="vertical"
			sx={{
				display: { sm: "none", xs: "none", md: "none", lg: "none", xl: "block" },
				color: theme.palette.divider,
				background: theme.palette.divider,
				height: "16px",
				margin: "8px"
			}} flexItem />
	</div> : null;
}
