global._notes = [];
export class MicroGrowl extends React.Component {
    constructor(props) {
        super(props);
        MicroGrowl.singleton = null;
        this.state = {      
            changes: 0
        };
        this.uid = 5300;
        this.levels = ['info', 'warn', 'error', 'success'];
        this.WARN = 'warn';
        this.INFO = 'info';
        this.ERROR = 'error';
        this.SUCCESS = 'success';

       /* this.holder = null;*/

        this.handleRemovedNotification = this.handleRemovedNotification.bind(this);
        this.addANotification = this.addANotification.bind(this);
        this.markChange = this.markChange.bind(this);
    }

    static addNotification(params) { 
        if (MicroGrowl.singleton){
            params.closebox = params.closebox != undefined ? params.closebox : true;
            params.delay = params.delay != undefined ? params.delay : 3000;
            params.level = MicroGrowl.singleton.levels.indexOf(params.level) > -1 ? params.level : MicroGrowl.singleton.WARN;
            MicroGrowl.singleton.addANotification(params);
        }
    }

    componentDidMount() {
        MicroGrowl.singleton = this;
        /*if (this.holder === null) {
			this.holder = ReactDOM.findDOMNode(this);
		}*/
    };
    componentWillUnmount() {
        MicroGrowl.singleton = null;
    }
    markChange() {
        this.setState((prevState, props) => {
            return {changes: prevState.changes+1};
          });
    }

    handleRemovedNotification(uid) {
        _notes = _notes.filter(function(ele) {
            return ele.uid !== uid;
        });
        this.markChange();
	};

	addANotification(note) {
		try {
			if (note.level) {
                if (this.levels.indexOf(note.level) == -1) {
					throw "Invalid level supplied";
				} else {
					note.uid = this.uid;
					note.ref = "notification-"+this.uid;
					this.uid += 1;
                    note.timeout = false;

                    _notes.push(note);
                    this.markChange();
				}
			}
        }
        catch (ex) {
			console.log('Error adding notification: '+ex);
		}
    };


    render() {
        let me = this;
        
		if(_notes.length == 0 ) {
			return <div className="growl-wrapper empty"></div>;
        }
		let isMore = null;
        let count = 0;
        let maxshow = this.props.maxShown ? this.props.maxShown : 8;
		if (_notes.length > maxshow) {
			var amt = _notes.length - maxshow;
			isMore = <li key='more-still'><span>{amt} more</span></li>
        }
		return (
			<div className='growl-wrapper'>
			  <ul>
				{_notes.map(function(n) {					
					count += 1;
					if (count >= maxshow) {
						return '';
					}
                    else {
						return <MicroSingleGrowl key={n.uid} ref={n.ref} notification={n} 
                            onDidRemove={me.handleRemovedNotification} />
					}
				})}
				{isMore}
			  </ul>
			</div>
        );
    }
}
MicroGrowl.singleton = null;
global.MicroGrowl = MicroGrowl;

export class MicroSingleGrowl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {      
            remove: 'false'
        };
        this.animations = true;
        
        this.componentDidMount = this.componentDidMount.bind(this);
        this.setRemove = this.setRemove.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.whichTransitionEvent = this.whichTransitionEvent.bind(this);
        this.handleMainElementClicked = this.handleMainElementClicked.bind(this);
    }
    
    componentDidMount() {
		if (this.props.notification.timeout === false) {
			this.startTimer();
		}

		if (this.animations) {
			var me = this;
            var ele = ReactDOM.findDOMNode(this);
            var transitionEvent = this.whichTransitionEvent();
			if (transitionEvent) {
				ele.addEventListener(transitionEvent, function() {
					if (me.state.remove == 'click' || me.state.remove == 'timer') {
						me.props.onDidRemove(me.props.notification.uid);
					}
				});
            }
            else {
				// Force animations to false bc this browser doesn't support them... 
				console.log('Animations disabled. Browser does not support.');
				this.animations = false;
			}
        }
    }

    setRemove(fast) {
        // Just in case this was triggered some other way than the timeout itself.
		clearTimeout(this.props.notification.timeout);

		if (!this.animations || fast) {
			this.props.onDidRemove(this.props.notification.uid);
        }
        else {
			this.setState({ remove: fast ? 'click' : 'timer' });
		}
    };

    startTimer() {
		let note = this.props.notification;
        let me = this;
        if (note.delay == 0 || !Number.isInteger(note.delay)) {
            note.delay = 3000;
        }
		note.timeout = setTimeout(function() {
						me.setRemove(true);
					}, note.delay);
    };

    handleMainElementClicked(note) {
        if (note && note.noteClicked) {
            if (note.noteClicked(note)) {
                this.setRemove(true);
            }
        }
    }

    render() {
        let cname = "growl " + this.props.notification.level;
        let me = this;
        if(this.state.remove == 'timer' || this.state.remove == 'click') {
			cname = cname + " removing";
        }
        let closebox = null;
        if (this.props.notification.closebox==true) {
            let closestyle = {
                    marginLeft:'-12px', paddingRight:'3px', top:'4px', position:'relative',
                    width:'12px', height:'12px', float:'right', cursor:'pointer'};
            closebox = (<div data-cy="closeAction" className='growl-action close' 
                            onClick={() => {me.setRemove(true)}} title='Close Notification' 
                            style={closestyle} />);
        }
        let extraMainStyle = this.props.notification.noteClicked ? {cursor: 'pointer'} : null;
        let ico = 'i';
        switch (this.props.notification.level) {
            case 'info':
                ico = <div className='growl-icon info' />;
                break;
            case 'warn':
                ico = <div className='growl-icon warn' />;
                break;
            case 'error':
                ico = <div className='growl-icon error' />;
                break;
            case 'success':
                ico = <div className='growl-icon success' />;
                break;
            default:
                throw new Error("MicroGrowl unkown message level");
        }
        return (
            <li className={cname}  onClick={ () => {this.handleMainElementClicked(this.props.notification)} } style={extraMainStyle}>
                <span className='growl-message'>
                    <span data-cy='growlMessage'>{ico}{this.props.notification.msg}</span>
                    {closebox}
                </span>
            </li>);
    }

    whichTransitionEvent(){
        var t;
        var el = document.createElement('fakeelement');
        var transitions = {
          'transition':'transitionend',
          'OTransition':'oTransitionEnd',
          'MozTransition':'transitionend',
          'WebkitTransition':'webkitTransitionEnd'
        }
    
        for (t in transitions){
            if( el.style[t] !== undefined ){
                return transitions[t];
            }
        }
    }
    
}
