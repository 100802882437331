import {QuantityEditorDialog} from "js/jsx/src/classes/quote/quantityEditor.jsx";

export class VisualTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };    
   
        // This binding is necessary to make `this` work in the callback
        this.backgroundImageLoaded = this.backgroundImageLoaded.bind(this); 
        this.getPositioningStyle = this.getPositioningStyle.bind(this); 
        this.checkboxChanged = this.checkboxChanged.bind(this); 
        this.textControlKeyDown = this.textControlKeyDown.bind(this); 
        this.textControlLostFocus = this.textControlLostFocus.bind(this); 
        this.getControlUpdateInfo = this.getControlUpdateInfo.bind(this); 
        this.submitControlChanges = this.submitControlChanges.bind(this); 
        this.controlChangeUpdateApiRef = null
    }
    backgroundImageLoaded(e) {
        var state = {
            isLoaded: true
        };

        var bottomRight = this.props.controls.firstOrNull(function (s) {
            return s.LabelName && s.LabelName.toLowerCase() == 'bottom right';
        });

        if (bottomRight) {
            var requestedHeight = VisualTab.width * (this.refs.bgImage.height / this.refs.bgImage.width);
            state.containerWidth = VisualTab.width;
            state.containerHeight = requestedHeight;
            state.xZoom = VisualTab.width / bottomRight.PosX2;
            state.yZoom = requestedHeight / bottomRight.PosY2;
        } else {
            state.xZoom = 1;
            state.yZoom = 1;
            state.containerHeight = this.refs.bgImage.naturalHeight;
            state.containerWidth = this.refs.bgImage.naturalWidth;
        }

        this.setState(state);
    }
    getPositioningStyle(control) {
        var style = {
            position: 'absolute',
            margin: 0,
            padding: 0,
            left: control.PosX * this.state.xZoom,
            top: control.PosY * this.state.yZoom,
            width: (control.PosX2 - control.PosX) * this.state.xZoom,
            height: (control.PosY2 - control.PosY) * this.state.yZoom,
            fontSize: Math.round(control.FontSize * this.state.yZoom),  
        };
        return style;
    }
    checkboxChanged(e) {
        var updates = [];
        var controlIds = [];

        if(e.target.type.toLowerCase() == 'radio') {
            var radioGroup = $('input[name="' + e.target.attributes.name.value + '"]');

            //set all other radio options to the opposite value
            for(var i = 0; i < radioGroup.length; i++) {
                if(radioGroup[i].id != e.target.id) {
                    updates.push(this.prepareControlChange(this.getControlUpdateInfo(radioGroup[i].value), !e.target.checked));
                    controlIds.push(radioGroup[i].id);
                }
            }

            //update the radio that was selected
            updates.push(this.prepareControlChange(this.getControlUpdateInfo(e.target.value), e.target.checked));
            controlIds.push(e.target.id);
        } else {
            //toggle checkbox
            updates.push(this.prepareControlChange(this.getControlUpdateInfo(e.target.attributes.name.value), e.target.checked));
            controlIds.push(e.target.id);
        }

        this.submitControlChanges(updates, controlIds);
    }
    textControlKeyDown (e) {
        if (e.which == 13) {
            var updateFields = this.getControlUpdateInfo(e.target.attributes.name.value);
            var update = this.prepareControlChange(updateFields, e.target.value);

            if(update)
                this.submitControlChanges(update, [e.target.id]);
            else {
                //revert value
                e.target.value = updateFields.bo[updateFields.propertyName];
            }
        }
    }
    textControlLostFocus(e) {
        var updateFields = this.getControlUpdateInfo(e.target.attributes.name.value);
        var update = this.prepareControlChange(updateFields, e.target.value);

        if(update)
            this.submitControlChanges(update, [e.target.id]);
        else {
            //revert value
            e.target.value = updateFields.bo[updateFields.propertyName];
        }
    }
    getControlUpdateInfo(name) {
        var tokens = name.split('.');
        var objectType = tokens[1];

        var updateFields = {
            propertyName: tokens[2],
            objectId: tokens[3]
        };

        //TODO: update BO on client before update call
        if (objectType == 'Quote') {
            updateFields.updateTable = 'QuoteMain';
            updateFields.idField = 'IdQuoteMain';
            updateFields.bo = this.props.quote;
        } else if (objectType == 'Term') {
            updateFields.updateTable = 'QuoteFinancing';
            updateFields.idField = 'IdQuoteFinancing';
            updateFields.bo = this.props.quote.Terms.firstOrNull((s)=>s.IdQuoteFinancing == updateFields.objectId);
        } else if (objectType == 'Tab') {
            updateFields.updateTable = 'QuoteTabs';
            updateFields.idField = 'IdQuoteTabs';
            updateFields.bo = this.props.quote.Tabs.firstOrNull((s)=>s.IdQuoteTabs == updateFields.objectId);
        } else if (objectType == 'Item') {
            updateFields.updateTable = 'QuoteItems';
            updateFields.idField = 'IdQuoteItems';
            updateFields.bo = this.props.quote.Items.firstOrNull((s)=>s.IdQuoteItems == updateFields.objectId);
        }

        return updateFields;
    }
    prepareControlChange(updateInfo, value) {
        if(updateInfo.bo) {
            if(typeof updateInfo.bo[updateInfo.propertyName] === 'number') {
                value = parseFloat(value);
                if(isNaN(value))
                    return null;
            }
            updateInfo.bo[updateInfo.propertyName] = value;
        }

        var fieldUpdates = {};
        fieldUpdates[updateInfo.propertyName] = value;

        var updateDetails = {
            fields: fieldUpdates,
            queries: [{
                table: updateInfo.updateTable,
                where: [{
                    field: updateInfo.idField,
                    operator: 'Equals',
                    value: updateInfo.objectId
                }]
            }]
        };

        return updateDetails;
    }
    submitControlChanges(updates, controlIds) {
        var updatingControls = this.state.updatingControls || [];
        for(var i = 0; i < controlIds.length; i++) {
            updatingControls.push(controlIds[i]);
        }

        this.setState({updatingControls: updatingControls});
        var getPartialQuote = this.controlChangeUpdateApiRef ? false : true;
        var updateApi = quosal.api.data.update(updates, this.props.quote.IdQuoteMain);
        
        updateApi.finished = function (msg) {
            this.state.updatingControls = [];
            quosal.sell.quote.updateFromApiResponse(msg);
            this.controlChangeUpdateApiRef = null;
        }.bind(this);

        if(this.controlChangeUpdateApiRef){
            this.controlChangeUpdateApiRef.abort();
        }
        updateApi.call();
        this.controlChangeUpdateApiRef = updateApi;
        
    }
    render() {
        var containerWidth = this.state && this.state.containerWidth ? this.state.containerWidth : VisualTab.width;
        var containerHeight = this.state && this.state.containerHeight ? this.state.containerHeight : VisualTab.height;
        var visualControls = [];
        var visualHotSpots = [];
        var showTooltipEvent = quosal.events.create();
        var hideTooltipEvent = quosal.events.create();
        
        var sortedHotSpots = [] 
        for(var i = 0; i < this.props.hotSpots.length; i ++){ //sort the hotspots so item editing controls are rendered last
            if(this.props.hotSpots[i].ManufacturerPartNumber && this.props.hotSpots[i].IsItemInput != null){
                sortedHotSpots.push(this.props.hotSpots[i]);
            } else {
                sortedHotSpots.unshift(this.props.hotSpots[i]);
            }
        }

        if (this.state && this.state.isLoaded) { //TODO: Show loading message instead?
            for (var i = 0; i < this.props.controls.length; i++) {
                var control = this.props.controls[i];

                visualControls.push(
                    <VisualControl key={control.IdVisualDisplayLabel} quote={this.props.quote} visualTab={this} control={control}
                                   disabled={this.state.updatingControls && this.state.updatingControls.indexOf(control.IdVisualDisplayLabel) >= 0}
                                   textControlKeyDown={this.textControlKeyDown}
                                   textControlLostFocus={this.textControlLostFocus}
                                   checkboxChanged={this.checkboxChanged}
                                   showTooltipEvent={showTooltipEvent} hideTooltipEvent={hideTooltipEvent} />
                );
            }

        for (var i = 0; i < sortedHotSpots.length; i++) {
            var hotSpot = sortedHotSpots[i];

                visualHotSpots.push(
                    <VisualHotSpot key={hotSpot.IdvVsualDisplayDetail} quote={this.props.quote} visualTab={this} hotSpot={hotSpot}
                                   textControlKeyDown={this.textControlKeyDown}
                                   showTooltipEvent={showTooltipEvent} hideTooltipEvent={hideTooltipEvent} />
                );
            }
        } else {
            //Show spinner while waiting for background image to load
            return (
                <div>
                    <FormPlaceholder message="Loading Visual Quote..." />
                    <img ref="bgImage" style={{visibility:'hidden', top:0, left:0, width: containerWidth}}
                         src={this.props.visualTab.TabPicture} onLoad={this.backgroundImageLoaded} />
                </div>
            );
        }

        return (
            <div style={{position:'relative', top:0, left:0, width: containerWidth, height: containerHeight}}>
                <img ref="bgImage" style={{position:'absolute', top:0, left:0, width: containerWidth}}
                     src={this.props.visualTab.TabPicture} />
                {visualControls}
                {visualHotSpots}
            </div>
        );
    }
}

VisualTab.width = 940;
VisualTab.height = 0;

class VisualControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };    
   
        // This binding is necessary to make `this` work in the callback
        this.getControlId = this.getControlId.bind(this); 
        this.getControlValue = this.getControlValue.bind(this);
        this.setTooltip = this.setTooltip.bind(this);
        this.clearTooltip = this.clearTooltip.bind(this);
    }
    getControlId(control) {
        var id;
        var bo;

        if (control.ObjectType == 'Quote') {
            id = this.props.quote.IdQuoteMain;
            bo = this.props.quote;
        } else if (control.ObjectType == 'Term') {
            var term = this.props.quote.Terms.firstOrNull((s)=>s.Name == control.FinancingName);

            if (term) {
                id = term.IdQuoteFinancing;
                bo = term;
            }
        } else if (control.ObjectType == 'Tab') {
            var tab = this.props.quote.Tabs.firstOrNull((s)=>s.TabName == control.TabName);

            if (tab) {
                id = tab.IdQuoteTabs;
                bo = tab;
            }
        } else if (control.ObjectType == 'Item') {
            for (var t = 0; t < this.props.quote.Tabs.length; t++) {
                var tab = this.props.quote.Tabs[t];
                var tabItems = this.props.quote.Items.where((s)=>s.IdQuoteTabs == tab.IdQuoteTabs);

                for (var i = 0; i < tabItems.length; i++) {
                    if (tabItems[i].ManufacturerPartNumber == control.ManufacturerPartNumber) {
                        id = tabItems[i].IdQuoteItems;
                        bo = tabItems[i];
                        break;
                    }
                }

                if (bo)
                    break;
            }
        }

        if (!id || !bo)
            return null;

        return {
            id: id,
            bo: bo,
            value: this.getControlValue(control, bo)
        };
    }
    getControlValue(control, bo) {
        var value = bo[control.PropertyName];

        if (control.FormatString && control.ControlType != 'CheckBox') {
            //TODO: This is not very verbose. It supports currency, and basic string replacement and will break down if customers try other formatting
            //I've seen some checkbox types with FormatString {0:I} not sure what this should format to?
            value = control.FormatString
                .replace('{0}', value)
                .replace('{0:yn}', value ? 'Yes' : 'No')
                .replace('{0:C}', this.props.quote.formatCurrency(value));
        }

        return value;
    }
    setTooltip(text) {
        this.setState({tooltip:text});
    }
    clearTooltip() {
        this.setState({tooltip:null})
    }
    render() {
        var control = this.props.control;
        var controlId = this.getControlId(control);
        var element;
        var style = this.props.visualTab.getPositioningStyle(control);

        if(controlId || (control.ControlType == 'Label' && control.LabelName == 'ToolTip')) {
            if (control.TextAlignment == 'Right')
                style.textAlign = 'right';
            else if (control.TextAlignment == 'Center')
                style.textAlign = 'center';
            else if (control.TextAlignment == 'Justify')
                style.textAlign = 'justify';

            if (!String.isNullOrEmpty(control.FontColor)) {
                style.color = control.FontColor;
            }

            if (control.ControlType == 'Label' && control.LabelName == 'ToolTip') {
                //Special tooltip label
                this.props.showTooltipEvent.unbind().bind(this.setTooltip);
                this.props.hideTooltipEvent.unbind().bind(this.clearTooltip);

                element =
                    <div key={control.IdVisualDisplayLabel} className="value"
                         style={style}>{this.state ? this.state.tooltip : ''}</div>
            } else {
                var controlName = 'visualquote.' + control.ObjectType + '.' + control.PropertyName + '.' + controlId.id;

                if (control.ControlType == 'TextInput') {
                    if (!String.isNullOrEmpty(control.FontColor)) {
                        var bgColor = new Color(control.FontColor);
                        bgColor.invert();
                        style.backgroundColor = bgColor.toString();
                    }

                    element = <input type="text" key={control.IdVisualDisplayLabel} id={control.IdVisualDisplayLabel}
                                     style={style} name={controlName}
                                     defaultValue={controlId.value} onKeyPress={this.props.textControlKeyDown}
                                     onBlur={this.props.textControlLostFocus} disabled={this.props.disabled}/>
                } else if (control.ControlType == 'CheckBox') {
                    if (control.RadioGroup) {
                        var radioName = 'visualquote.radio.' + this.props.visualTab.props.tab.TabName + control.RadioGroup;

                        element = <input type="radio" id={control.IdVisualDisplayLabel} name={radioName} style={style}
                                         checked={controlId.value} value={controlName} disabled={this.props.disabled} 
                                         onChange={this.props.checkboxChanged}/>;
                    } else {
                        element =
                            <input type="checkbox" id={control.IdVisualDisplayLabel} name={controlName} style={style} disabled={this.props.disabled}
                                   checked={controlId.value} onChange={this.props.checkboxChanged} value="True"/>;
                    }
                } else if (control.ControlType == 'Label') {
                    element =
                        <div key={control.IdVisualDisplayLabel} className="value" style={style}>{controlId.value}</div>
                }
            }
        }

        return (
            <div>
                {element}
            </div>
        )
    }
}


class VisualHotSpot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };    
   
        // This binding is necessary to make `this` work in the callback
        this.getHotSpotItem = this.getHotSpotItem.bind(this);
        this.itemEditHotspotClicked = this.itemEditHotspotClicked.bind(this);
        this.checkOverlappingDiv = this.checkOverlappingDiv.bind(this);
        this.tabTemplateMultiHotspotClicked = this.tabTemplateMultiHotspotClicked.bind(this);
        this.tabTemplateHotspotClicked = this.tabTemplateHotspotClicked.bind(this);
        this.onQuantityEditorChange = this.onQuantityEditorChange.bind(this);
        this.closeQuantityDialog = this.closeQuantityDialog.bind(this);
        this.showTooltip = this.showTooltip.bind(this);
        this.hideTooltip = this.hideTooltip.bind(this);
    }
    static parseFieldList(inputString) {
            var split1 = inputString.split(',');
            var split2 = [];
            var split3 = [];

            for(var i = 0; i < split1.length; i++) {
                var tokens = split1[i].split(';');

                for(var t = 0; t < tokens.length; t++) {
                    split2.push(tokens[t].trim());
                }
            }

            for(var i = 0; i < split2.length; i++) {
                var tokens = split2[i].split('\r\n');

                for(var t = 0; t < tokens.length; t++) {
                    split3.push(tokens[t].trim());
                }
            }

            return split3;
    }
    getHotSpotItem() {
        var item = this.props.quote.Items.firstOrNull((s)=>s.ManufacturerPartNumber == this.props.hotSpot.ManufacturerPartNumber);

        if(!item) {
            Dialog.open({
                title: 'Item Not Found',
                message: 'Unable to locate an item with MFP ' + this.props.hotSpot.ManufacturerPartNumber + ' on this quote. Please have an admin define where the item is supposed to come from in Create under Options -> Visual Quoting -> Maintain Visual Quotes.',
                links: [{
                    title: 'OK',
                    callback: Dialog.closeAll
                }]
            });
        }

        return item;
    }
    itemEditHotspotClicked() {
        var item = this.getHotSpotItem();

        if(!item)
            return;

        if(this.props.hotSpot.InputFields) {
            var saveItemEvent = quosal.events.create();

            Dialog.open({
                title: 'Edit Item',
                message: <VisualItemEditor quote={this.props.quote} item={item} hotSpot={this.props.hotSpot} saveItemEvent={saveItemEvent} />,
                links: [{
                    title: 'Save',
                    callback : function() { saveItemEvent.call(); }
                }, {
                    title: 'Cancel',
                    callback: Dialog.closeAll
                }]
            });
        } else {
            if(this.state && this.state.showQuantityEditor) {
                this.closeQuantityDialog();
            } else {
                this.setState({showQuantityEditor: true});
            }
        }
    }
    checkOverlappingDiv(e) {
        var cursorX = e.pageX;
        var cursorY = e.pageY;
        var elementsClicked = [];
        $('.hotspotDiv').each(function (index, element) {
            var offset = $(element).offset();
            var xRangeMin = offset.left;
            var xRangeMax = offset.left + $(element).outerWidth();
            var yRangeMin = offset.top;
            var yRangeMax = offset.top + $(element).outerHeight();
            if (cursorX >= xRangeMin && cursorX <= xRangeMax && cursorY >= yRangeMin && cursorY <= yRangeMax) {
                elementsClicked.push(element);
            } 
        });
        if (elementsClicked.length > 1) { 
            this.tabTemplateMultiHotspotClicked(elementsClicked); 
            e.stopPropagation();
        }//else the click event continues to the top most element with an onClick event
        
    }
    tabTemplateMultiHotspotClicked(elementsClicked) { //calls getTabItems and saveTabItems on each hotSpot in elementsClicked adding all line items automatically
        this.props.visualTab.setState({visualTabIsSaving: true});

        var idQuoteMain = this.props.quote.IdQuoteMain; 
        var element = elementsClicked.pop();

        var tabItemsApi = quosal.api.visualQuote.getTabItems(element.id, idQuoteMain);
        tabItemsApi.error = function(msg) {
            this.props.visualTab.setState({visualTabIsSaving: false});
            Dialog.open({
                title: 'Error Locating Tab Template',
                message: 'The source template tab cannot be found and was not copied. Please have an admin define where the tab is supposed to come from in Create under Options -> Visual Quoting -> Maintain Visual Quotes.',
                links: [{
                    title: 'OK',
                    callback: Dialog.closeAll
                }]
            });
        }.bind(this);
        tabItemsApi.finished = (function (msg) {
            var selectedIds = [];

            for (var j = 0; j < msg.tabItems.length; j++) {
                selectedIds.push(msg.tabItems[j].IdQuoteItems);
            }

            var saveApi = quosal.api.visualQuote.saveTabItems(element.id, idQuoteMain, selectedIds);
            saveApi.finished = (function (msg) {
                quosal.sell.quote.update(msg.quote);
                if(elementsClicked.length > 0){
                    this.tabTemplateMultiHotspotClicked(elementsClicked); //calls the function again, with the same list without the popped element
                } else { //if there are no more hotSpots in the list
                    this.props.visualTab.setState({visualTabIsSaving: false});
                    if((this.props.hotSpot.ManufacturerPartNumber && this.props.hotSpot.IsItemInput)){ //if the top most item is a quantity editor
                        this.itemEditHotspotClicked();
                    }
                }
            }).bind(this);
            saveApi.call();
        }).bind(this);
            tabItemsApi.call();
    }
    tabTemplateHotspotClicked() {
        var tabItemsApi = quosal.api.visualQuote.getTabItems(this.props.hotSpot.IdvVsualDisplayDetail, this.props.quote.IdQuoteMain);
        tabItemsApi.error = function(msg) {
            Dialog.open({
                title: 'Error Locating Tab Template',
                message: 'The source template tab cannot be found and was not copied. Please have an admin define where the tab is supposed to come from in Create under Options -> Visual Quoting -> Maintain Visual Quotes.',
                links: [{
                    title: 'OK',
                    callback: Dialog.closeAll
                }]
            });
        }.bind(this);
        tabItemsApi.finished = function(msg) {
            var submitItemsEvent = quosal.events.create();
            var checkAllEvent = quosal.events.create();
            var uncheckAllEvent = quosal.events.create();
            var hasSelectedItems = msg.selectedMfps.length > 0;
            for(var i = 0; i < msg.tabItems.length; i++) {
                if(msg.selectedMfps.indexOf(msg.tabItems[i].ManufacturerPartNumber) >= 0) {
                    msg.tabItems[i].selected = true;
                }
            }

            var links = [{
                title: 'OK',
                callback: function() { submitItemsEvent.call(); }
            }, {
                title: 'Cancel',
                callback: Dialog.closeAll
            }];

            if(!this.props.hotSpot.ItemsMutuallyExlusive) {
                links.push({
                    title: 'Check All',
                    callback: function() { checkAllEvent.call(); return true; }
                });
                links.push({
                    title: 'Uncheck All',
                    callback: function() { uncheckAllEvent.call(); return true; }
                });
            }
            var sortedtItems = msg.tabItems.sort(function(a, b){return a.SortOrder - b.SortOrder});
            Dialog.open({
                title: 'Select the item' + (this.props.hotSpot.ItemsMutuallyExlusive ? '' : 's') + ' you would like to include on this tab',
                message: <VisualTabItemsList visualHotSpot={this} hotSpot={this.props.hotSpot} quote={this.props.quote} hasSelectedItems={hasSelectedItems}
                                             items={sortedtItems} tab={msg.tab} tabItemSelectionChanged={this.tabItemSelectionChanged}
                                             submitItemsEvent={submitItemsEvent} checkAllEvent={checkAllEvent} uncheckAllEvent={uncheckAllEvent} />,
                links: links,
                height: '80%',
                width: window.innerWidth * .3 < 410 ? 410 : '30%'
            });
        }.bind(this);
        tabItemsApi.call();
    }
    onQuantityEditorChange(newQuantity) {
        var item = this.getHotSpotItem();

        if(item && newQuantity !== item.Quantity) {
            item.Quantity = newQuantity;

            var updateApi = quosal.api.data.update({
                fields: { Quantity: newQuantity },
                queries:[{
                    table: 'QuoteItems',
                    where: [{
                        field: 'IdQuoteItems',
                        operator: 'Equals',
                        value: item.IdQuoteItems
                    }]
                }]
            }, this.props.quote.IdQuoteMain);
            updateApi.finished = function(msg) {
                quosal.sell.quote.updateFromApiResponse(msg);
            };
            updateApi.call();
        }

        this.closeQuantityDialog();
    }
    closeQuantityDialog() {
        $(document.body).unbind('mouseup');
        this.setState({ showQuantityEditor: false});
    }
    showTooltip() {
        this.props.showTooltipEvent.call(this.props.hotSpot.Tooltip);
    }
    hideTooltip() {
        this.props.hideTooltipEvent.call();
    }
    render() {
        var borderCaptionStyle = {
            display: "table-cell",
            verticalAlign: "middle",
            textAlign: "center"
        };
    var hotSpot = this.props.hotSpot;
    var style = this.props.visualTab.getPositioningStyle(hotSpot);
        if(hotSpot.ShowBorder){
            style.border = "solid black 2px"
            style.background = "rgb(200, 200, 200)";
            style.background = "rgba(200, 200, 200, 0.5)";
        };
        var caption = "";
        if(hotSpot.BorderCaption){
            style.display="table";
            caption = hotSpot.BorderCaption;
        };
        if(this.props.visualTab.state.visualTabIsSaving){
            style.cursor = "wait";
        };
    var element = null;
    var quantityEditor = null;

    if(this.state && this.state.showQuantityEditor) {
        var qtyEditorStyle = {
            left: (style.left + (((hotSpot.PosX2 - hotSpot.PosX) / 2) * this.props.visualTab.state.xZoom)) - (QuantityEditorDialog.width() / 2),
            top: (style.top - QuantityEditorDialog.height()),
            marginTop: -6,
            marginLeft: -8
        };
        quantityEditor = <QuantityEditorDialog style={qtyEditorStyle} onChange={this.onQuantityEditorChange} item={this.getHotSpotItem()}
            quantityField="Quantity" pointer="down" cancelDialog={this.closeQuantityDialog} />;

        $(document.body).bind('mouseup', function(e) {
            if(e.target.className == 'dialog quantity')
                return;

            var dialog = $(e.target).parents('.dialog.quantity').first();

            if(dialog.length > 0) {
                return;
            } else {
                this.closeQuantityDialog();

                if(e.target.id == this.props.hotSpot.id)
                    e.stopImmediatePropagation();
            }
        }.bind(this));
        } 
        /*  depending on tabs present(in quote) and options set(in VQ editor) each hotSpot needs to behave slightly differently
            if a multi hotSpot click occurs all tabs involved will be added with all line items (no dialog to choose pops up) 
            This is to support how create handled multiple hotSpots being clicked at once
        */
        if(this.props.visualTab.state.visualTabIsSaving){ //if a multi-hotspot save is occuring, all hotspots have no onClick events
            element = <a className="link"><div id={hotSpot.id} style={style}><div style={borderCaptionStyle}>{caption}</div></div></a>
        }else if(!this.props.quote.Tabs.firstOrNull((s)=>s.TabName == hotSpot.TabTemplateName)){ //if the tab the hotSpot is referencing is not in the quote, onClick should try to add the needed tab
            element = <a className="link" onClick={this.tabTemplateHotspotClicked}><div id={hotSpot.id} style={style} className="hotspotDiv" onClick={this.checkOverlappingDiv} ><div style={borderCaptionStyle}>{caption}</div></div></a>
        }else if(hotSpot.ManufacturerPartNumber && hotSpot.IsItemInput) { //will cause the hotSpot to pop open a quantity editor when clicked
            element = <a className="link" onClick={this.itemEditHotspotClicked}><div id={hotSpot.id} style={style} onClick={this.checkOverlappingDiv}><div style={borderCaptionStyle}>{caption}</div></div></a>
        } else if(hotSpot.TargetUrl) { //if the hotSpot has a url set, in the stand alone editor, go there when clicked
            element = <a target="_blank" href={hotSpot.TargetUrl}><div id={hotSpot.id} style={style}><div style={borderCaptionStyle}>{caption}</div></div></a>
        }else if(hotSpot.TabTemplateName) { //if no other situations, but the hotSpot is linked to a tab, open the add tab dialog
            element = <a className="link" onClick={this.tabTemplateHotspotClicked}><div id={hotSpot.id} style={style} className="hotspotDiv" onClick={this.checkOverlappingDiv}> <div style={borderCaptionStyle}>{caption}</div></div></a>
        } else { //else there is no onClick events for the hotSpot
            element = <a className="link"><div id={hotSpot.id} style={style}><div style={borderCaptionStyle}>{caption}</div></div></a>
        }

    return (
        <div onMouseOver={this.props.hotSpot.Tooltip ? this.showTooltip : null} onMouseOut={this.props.hotSpot.Tooltip ? this.hideTooltip : null}>
                    {quantityEditor}
                    {element}
                </div>
            );
        }
    }


class VisualItemEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };    
   
        // This binding is necessary to make `this` work in the callback
        this.saveItem = this.saveItem.bind(this); 
    }
    saveItem() {
        var fieldUpdates = {};

        for(var ref in this.refs) {
            if(ref.indexOf('fieldInput') == 0) {
                var input = this.refs[ref];
                var fieldName = input.attributes.name.value;
                var fieldValue = input.type == 'checkbox' ? input.checked : input.value;
                var originalValue = this.props.item[fieldName];

                if(typeof originalValue == 'number') {
                    fieldValue = parseFloat(fieldValue);

                    if(isNaN(fieldValue))
                        continue;
                }

                if(fieldValue !== originalValue)
                    fieldUpdates[fieldName] = fieldValue;
            }
        }

        if(Object.keys(fieldUpdates).length > 0) {
            this.setState({isSaving: true});

            var updateApi = quosal.api.data.update({
                fields: fieldUpdates,
                queries: [{
                    table: 'QuoteItems',
                    where:[{
                        field: 'IdQuoteItems',
                        operator: 'Equals',
                        value: this.props.item.IdQuoteItems
                    }]
                }]
            }, this.props.quote.IdQuoteMain);
            updateApi.finished = function(msg) {
                quosal.sell.quote.updateFromApiResponse(msg);
                Dialog.closeAll();
            };
            updateApi.call();
        } else {
            Dialog.closeAll();
        }
    }
    render() {
        this.props.saveItemEvent.unbind().bind(this.saveItem);

        var fieldList = VisualHotSpot.parseFieldList(this.props.hotSpot.InputFields);

        var fieldElements1 = [];
        var fieldElements2 = [];

        for(var i = 0; i < fieldList.length; i++) {
            if(fieldList[i].indexOf('ItemNotes') == 0)
                continue;
            if(fieldList[i].indexOf('Thumbnail') >= 0 ||
                fieldList[i].indexOf('Picture') >= 0 ||
                fieldList[i].indexOf('Image') >= 0)
                continue;

            var fieldElements = i % 2 == 0 ? fieldElements1 : fieldElements2;

            var fieldValue = this.props.item[fieldList[i]];
            if(fieldValue === undefined)
                continue;

            var fieldConfig = quosal.customization.fields.getFieldConfiguration(quosal.customization.fields.types.businessObject, 'QuoteItems', fieldList[i]);
            var fieldName = fieldConfig && fieldConfig.FieldRename || fieldList[i];
            var fieldId = this.props.item.IdQuoteItems + '_' + fieldList[i];

            if(typeof fieldValue == 'boolean') {
                fieldElements.push(
                    <div key={fieldId} className="formfieldwrapper">
                        <div className="formcheckboxwrapper">
                            <input type="checkbox" name={fieldList[i]} ref={'fieldInput' + i} defaultChecked={fieldValue} id={fieldId} disabled={this.state && this.state.isSaving} />
                            <label htmlFor={fieldId} className="formlabel">{fieldName}</label>
                        </div>
                    </div>
                );
            } else {
                fieldElements.push(
                    <div key={fieldId} className="formfieldwrapper">
                        <div className="formfieldlabel">
                            <label htmlFor={fieldId} className="formlabel">{fieldName}</label>
                        </div>
                        <div className="formfield">
                            <input type="text" name={fieldList[i]} ref={'fieldInput' + i} defaultValue={fieldValue} id={fieldId} disabled={this.state && this.state.isSaving} />
                        </div>
                    </div>
                );
            }
        }

        return (
            <div style={{maxWidth:600}}>
                <div style={{fontWeight:'bold', fontSize:'14px', marginBottom:10}}>{this.props.item.ManufacturerPartNumber}: {this.props.item.LongDescription}</div>
                <img src={this.props.item.Thumbnail} style={{verticalAlign:'top', marginRight:10, width:80}} />
                <div style={{display:'inline-block', verticalAlign:'top', width:'calc(100% - 100px)'}}>{quosal.util.trunc(this.props.item.ItemNotes, 300)}</div>
                <div style={{marginTop:20}}>
                    <div className="formcolumn">{fieldElements1}</div>
                    <div className="formcolumn">{fieldElements2}</div>
                </div>
                {this.state && this.state.isSaving ? <FormPlaceholder message="Saving Changes..." /> : null}
            </div>
        );
    }
}


class VisualTabItemsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            selectedItems: []
        }; 
        // This binding is necessary to make `this` work in the callback
        this.submitItems = this.submitItems.bind(this);
        this.toggleAllSelection = this.toggleAllSelection.bind(this);
        this.radioSelectionChanged = this.radioSelectionChanged.bind(this);
    }   
    submitItems() {
        this.setState({isSaving:true});

        var selectedIds = [];
        var unSelectedMfps = [];

        for(var i = 0; i < this.props.items.length; i++) {
            if(this.props.items[i].selected) {
                selectedIds.push(this.props.items[i].IdQuoteItems);
            } else {
                unSelectedMfps.push(this.props.items[i].ManufacturerPartNumber);
            }
        }

        var saveApi = quosal.api.visualQuote.saveTabItems(this.props.hotSpot.id, this.props.quote.IdQuoteMain, selectedIds, unSelectedMfps);
        saveApi.finished = function(msg) {
            Dialog.closeAll();
            quosal.sell.quote.update(msg.quote);
        }.bind(this);
        saveApi.call();
    }
    toggleAllSelection(selected) {
        for(var i = 0; i < this.props.items.length; i++) {
            this.props.items[i].selected = selected;
        }
        this.forceUpdate();
    }
    radioSelectionChanged(tabItem) {
        for(var i = 0; i < this.props.items.length; i++) {
            if(this.props.items[i].IdQuoteItems != tabItem.props.item.IdQuoteItems) {
                this.props.items[i].selected = !tabItem.props.item.selected;
            }
        }
        this.forceUpdate();
    }
    render() {
        //hook events to the external dialog links
        this.props.submitItemsEvent.unbind().bind(this.submitItems);
        this.props.checkAllEvent.unbind().bind(function(){ this.toggleAllSelection(true); }.bind(this));
        this.props.uncheckAllEvent.unbind().bind(function(){ this.toggleAllSelection(false); }.bind(this));
        
        var HotSpotTabQuoteId = null;
        for(var i = 0; i < app.currentQuote.Tabs.length; i++) {
            if (app.currentQuote.Tabs[i].TabName == this.props.tab.TabName) {
                HotSpotTabQuoteId = app.currentQuote.Tabs[i].IdQuoteTabs;
                break;
            }
        }
     
        var overrideCheckboxCheckedBehavior = false;
        for(var i = 0; i < app.currentQuote.Items.length; i++) {
            for(var j = 0; j < this.props.items.length; j++) {
                if( app.currentQuote.Items[i].IdQuoteTabs == HotSpotTabQuoteId && app.currentQuote.Items[i].ManufacturerPartNumber == this.props.items[j].ManufacturerPartNumber) {
                    overrideCheckboxCheckedBehavior = true;
                    break;
                }
            }
            if(overrideCheckboxCheckedBehavior){
                break;
            }
            
        }
        
        var itemsList = [];
        
        for(var i = 0; i < this.props.items.length; i++) {
            itemsList.push(
                <VisualTabSelectableItem key={this.props.items[i].IdQuoteItems} quote={this.props.quote} overrideCheckboxCheckedBehavior={overrideCheckboxCheckedBehavior}
                                         hotSpot={this.props.hotSpot} item={this.props.items[i]} hasSelectedItems={this.props.hasSelectedItems}
                                         radioSelectionChanged={this.radioSelectionChanged} HotSpotTabQuoteId={HotSpotTabQuoteId}/>
            )
        }

        return (
            <div>
                <div>
                    {itemsList}
                </div>
                {this.state && this.state.isSaving ? <FormPlaceholder style={{marginTop:20}} message={'Saving Item' + (this.props.hotSpot.ItemsMutuallyExlusive ? '' : 's') + '...'} /> : null}
            </div>
        );
    }
}


class VisualTabSelectableItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };    
   
        // This binding is necessary to make `this` work in the callback
        this.selectionChanged = this.selectionChanged.bind(this); 
    }
    selectionChanged(e) {
        this.props.item.selected = e.target.checked;

        if(this.props.hotSpot.ItemsMutuallyExlusive) {
            this.props.radioSelectionChanged(this);
        } else {
            this.forceUpdate();
        }
    }
    UNSAFE_componentWillMount() {
        if(this.props.hotSpot.SelectTabItems && !this.props.hasSelectedItems && !this.props.overrideCheckboxCheckedBehavior && !this.props.hotSpot.ItemsMutuallyExlusive ){
            this.props.item.selected = true;
        }
    }
    render() {
        var hotSpot = this.props.hotSpot;
        var checkbox;
        var itemElement;
        var checked;
        if(this.props.overrideCheckboxCheckedBehavior && this.props.item.selected == undefined){
            checked = false;
            for(var i = 0; i < app.currentQuote.Items.length; i++) {
                if( app.currentQuote.Items[i].IdQuoteTabs == this.props.HotSpotTabQuoteId && app.currentQuote.Items[i].ManufacturerPartNumber == this.props.item.ManufacturerPartNumber) {
                    checked = true;
                    this.props.item.selected = true;
                    break;
                }
            }
        }

        if(hotSpot.ItemsMutuallyExlusive) {
            checkbox = <input id={this.props.item.IdQuoteItems} type="radio" value={this.props.item.IdQuoteItems} defaultChecked={checked} checked={this.props.item.selected} onChange={this.selectionChanged} name="selection" />;
        } else {
            checkbox = <input id={this.props.item.IdQuoteItems} type="checkbox" value={this.props.item.IdQuoteItems} defaultChecked={checked} checked={this.props.item.selected} onChange={this.selectionChanged} name="selection" />;
        }

        if(hotSpot.SearchResultFields) {
            //custom layout
            var fieldList = VisualHotSpot.parseFieldList(hotSpot.SearchResultFields);
            var elementList = [];
            var imageElement = null;
            var hasImage = false;

            for(var i = 0; i < fieldList.length; i++) {
                var fieldName = fieldList[i];
                var element = null;
                var label = null;
                var isImage = fieldName.indexOf('Thumbnail') >= 0 || fieldName.indexOf('Picture') >= 0 || fieldName.indexOf('Image') >= 0;

                if(isImage && hasImage)
                    continue; //only allow one image element

                var isCurrency = fieldName.indexOf('Price') >= 0 || fieldName.indexOf('Cost') >= 0 || fieldName.indexOf('Amount') >= 0 || (fieldName.indexOf('Tax') >= 0 && fieldName.indexOf('Rate') < 0);

                //Translate field names
                if(fieldName == 'Price') {
                    fieldName = 'OverridePrice';
                } else if(fieldName.indexOf('ItemNotes') == 0) {
                    fieldName = 'ItemNotes';
                }

                var fieldConfig = quosal.customization.fields.getFieldConfiguration(quosal.customization.fields.types.businessObject, 'QuoteItems', fieldName);
                var displayName = fieldConfig && fieldConfig.FieldRename || fieldName;
                var fieldValue = this.props.item[fieldName];

                if(isCurrency) {
                    fieldValue = this.props.quote.formatCurrency(fieldValue);
                }

                if(fieldName != 'LongDescription' && fieldName != 'ItemNotes' && !isImage) {
                    label = displayName + ': ';
                }

                if(isImage) {
                    imageElement = <img src={fieldValue} className="thumbnail medium" style={{float:'left', marginRight:10}} />;
                    hasImage = true;
                } else {
                    element = fieldValue;
                }

                elementList.push(
                    <div key={'custom_' + fieldName}>
                        {label}{element}
                    </div>
                );
            }

            itemElement = (
                <div>
                    <div style={{display:'inline-block', verticalAlign:'top', marginLeft:5, maxWidth:600}}>
                        {imageElement}
                        {elementList}
                    </div>
                </div>
            )
        } else {
            //default layout
            itemElement = (
                <div>
                    <img className="thumbnail medium" src={this.props.item.Thumbnail} style={{display:'inline-block', verticalAlign:'top'}} />
                    <div style={{display:'inline-block', verticalAlign:'top', marginLeft:5}}>
                        Manufacturer Part Number: {this.props.item.ManufacturerPartNumber}<br />
                        {this.props.item.LongDescription}<br />
                        Cost: {this.props.quote.formatCurrency(this.props.item.Cost)} / Price: {this.props.quote.formatCurrency(this.props.item.QuoteItemPrice)}
                    </div>
                </div>
            )
        }

        return (
            <div className="formcheckboxwrapper">
                {checkbox}
                <label htmlFor={this.props.item.IdQuoteItems} style={{borderBottom:'1px solid #ccc', padding:'10px 5px', width:'calc(100% - 40px)'}}>
                    {itemElement}
                </label>
            </div>
        );
    }
}