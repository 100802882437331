import {ActiveDisableButton} from "js/jsx/src/classes/etilize/etilizeSearchResults.jsx";
import {CloudSourceButton} from "js/jsx/src/classes/quote/cloudSourcing.jsx";
import {QuickNote} from "js/jsx/src/classes/quicknote.jsx";
import {TriCheckbox} from "js/jsx/src/classes/tricheckbox.jsx";

export class EtilizeCompare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tri: 'unchecked',
            messages: [],
            nextmsg: 1
        }; 
        // var expProps = {
        //     products: [],
        //     compares: []
        // };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.attachThisProduct = this.attachThisProduct.bind(this);
        this.renderProductImages = this.renderProductImages.bind(this);
        this.renderComparisonRows = this.renderComparisonRows.bind(this);
        this.renderProductDescriptions = this.renderProductDescriptions.bind(this);
        this.allCheckChanged = this.allCheckChanged.bind(this);
        this.compareRowChecked = this.compareRowChecked.bind(this);
        this.render = this.render.bind(this);
        this.attachComparisonClicked = this.attachComparisonClicked.bind(this);
        this.removeAllComparisons = this.removeAllComparisons.bind(this);
        this.closeNotify = this.closeNotify.bind(this);
        this.render = this.render.bind(this);
    }

    componentDidMount() {
    }

    attachThisProduct(item, newData, callback) {
        item.isAttaching = true;

        var info = {};
        info.productid = item.Id;
        info.source = newData.source.SourceId;
        info.cost = ''+newData.cost;
        info.msrp = ''+newData.msrp;
        info.warehouse = newData.selectedWarehouse;
        info.thumbnail = '';
        info.destination = quosal.util.queryString('idquotetabs');
        var products = [];
        products.push(info);

        var attachApi = quosal.api.product.attachEtilizeProducts(
            app.currentQuote.IdQuoteMain,
            products,
            true
        );
        attachApi.finished = function(resItem, msg) {
            resItem.isAttaching = false;
            quosal.sell.quote.updateFromApiResponse(msg, app.currentQuote.IsManualTotal);

            if (callback) {
                callback(msg.item);
            }
        }.bind(this, item);
        attachApi.call();
    }

    deeperClick(evt, product) {
        if (this.props.detailFn) {
            let img = 'https://content.etilize.com/Thumbnail/' + product.Id + '.jpg';
            this.props.detailFn(product,img);
        }
    }


    renderProductImages() {
        let prodImgs = [];
        let prodcount = (this.props.products.length <= 4) ? this.props.products.length : 4;
        let percent = Math.round(100 / prodcount) + '%';
        for (let i = 0; i < prodcount; i++) {
            let p = this.props.products[i];
            let img = 'https://content.etilize.com/Thumbnail/' + p.Id + '.jpg';
            prodImgs.push(<td key={"compareHeaderKey"+i} className="compareheader comparecol" style={{width:percent, verticalAlign:'top', textAlign:'center'}}>
                                <div>
                                    <img data-productid={p.Id} className='compareimg' src={img} alt={p.ManufacturerPartNumber+' thumbnail'} onClick={(e) => this.deeperClick(e, p)} />
                                </div>
                                <CloudSourceButton item={p} className='contentgrid' type='etilizesearch' style={{cursor:'pointer'}}
                                    buttonText='Attach' customUpdate={this.attachThisProduct}
                                />
                            </td>);
        }
        return (
            <tr key={"prodImg" + i}>
                <td colSpan="2" style={{borderTop: '1px solid #AEAEAE'}} className="comparecorner">
                    <div className="comparedesccol">
                        <div style={{textAlign:'left', fontSize:'12px', fontWeight:'bold', padding:'4px'}}>Attach Comparison</div>
                        <div style={{textAlign:'left', marginLeft:'25px'}}>
                            <ActiveDisableButton 
                                key='addcompare' 
                                onClick={this.attachComparisonClicked} 
                                disabled={false}
                                unavailable={this.state.tri === 'unchecked'}
                            >Attach</ActiveDisableButton>
                            <Button key='removecompare' onClick={this.removeAllComparisons} style={{marginLeft:'15px'}}>Remove All</Button>
                            <div>
                            <br />
                            <span className='howtocompare'>Select the product attributes to include on the comparison document from the list below.</span>
                            </div>
                        </div>
                    </div>
                </td>
                {prodImgs}
            </tr>
        );
    }

    compareRowChecked(attr) {
        attr.isSelected = attr.isSelected ? false: true;
        let allYes = true;
        let allNo = true;
        
        for (let gi = 0; gi < this.props.compares.length; gi++) {
            let group = this.props.compares[gi];
            for (let ai = 0; ai < group.Attributes.length; ai++)
            {
                if (group.Attributes[ai].isSelected) {
                    allNo = false;
                }
                if (!group.Attributes[ai].isSelected) {
                    allYes = false;
                }
            }
        }
        if (allNo) {
            this.setState({tri: 'unchecked'});
        } else if (allYes) {
            this.setState({tri: 'checked'});
        } else {
            this.setState({tri: 'indeterminate'});
        }
        this.forceUpdate();
    }

    renderComparisonRows() {
        function dangerousMarkup(str) {
            return {__html: str};
        }
        var rows = [];
        for (var gi = 0; gi < this.props.compares.length; gi++) {
            var group = this.props.compares[gi];
            let prodcount = (this.props.products.length <= 4) ? this.props.products.length : 4;
            var header = (<tr key={'cmphead_'+group.Name}><td colSpan={prodcount+2}><div className='comparegroup'>{group.Name}</div></td></tr>);
            rows.push(header);
            for (let ai = 0; ai < group.Attributes.length; ai++)
            {
                var attr = group.Attributes[ai];
                var cols = [];
                if (!attr.isSelected) {
                    attr.isSelected = false;
                }
                cols.push(<td className='comparecheckcol compareattr' key={'chkcol_'+attr.Name+ai} >
                                <input name={'cattr_'+attr.Name} className='attrcheck' key={'chk_'+attr.Name} type='checkbox' 
                                    onChange={this.compareRowChecked.bind(this, attr)} 
                                    checked={attr.isSelected} 
                                />
                            </td>);
                cols.push(<td style={{verticalAlign:'top'}} key={'clbl_'+attr.Name} className='compareattr'>{attr.Name}</td>);

                if (attr.Values) {
                    var icol = 0;
                    let limit = attr.Values.length > 4 ? 4 : attr.Values.length;
                    for (let vi = 0; vi < limit; vi++)
                    {
                        var value = attr.Values[vi];
                        var c1;
                        c1 = (icol % 2 === 0) ? "comparecol oddcol" : "comparecol evencol";
                        c1 += (attr.IsDifferent ? ' diffcol' : '');
                        
                        cols.push(<td style={{verticalAlign:'top'}} key={'cattr_'+attr.Name+icol} className={c1} dangerouslySetInnerHTML={dangerousMarkup(value)} />);
                        icol++;
                    }
                }
                
                var row = (<tr key={'attr_'+attr.Name}>{cols}</tr>);
                rows.push(row);
            }
        }
        return rows;
    }

    renderProductDescriptions() {
        let prodBlock = [];
        let prodcount = (this.props.products.length <= 4) ? this.props.products.length : 4;
        for (let i = 0; i < prodcount; i++) {
            let p = this.props.products[i];
            prodBlock.push(<td className='comparecol' style={{verticalAlign:'top'}}>
                                <div className='etilizepartnum'>{p.ManufacturerPartNumber}</div>
                                <div data-productid={p.Id} className='etilizedesc1 comparedesc1' onClick={(e) => this.deeperClick(e, p)}>{p.Description3}</div>
                                <div>{p.Description1}</div>
                            </td>);
        }
        return (
            <tr>
                <td className='comparecheckcol compareattr' style={{verticalAlign: 'bottom'}} title='Select all/none of comparison attributes'>
                        <span style={{fontSize:'9px', color: 'gray'}}>all</span><br />
                        <TriCheckbox state={this.state.tri} onChange={this.allCheckChanged} />
                </td>
                <td className='compareattr' style={{verticalAlign:'top'}}>Product Description</td>
                {prodBlock}
            </tr>
        );
    }
    
    setAllCheckboxes(toTrue) {
        for (let gi = 0; gi < this.props.compares.length; gi++) {
            let group = this.props.compares[gi];
            for (let ai = 0; ai < group.Attributes.length; ai++)
            {
                group.Attributes[ai].isSelected = toTrue;
            }
        }
    }

    allCheckChanged(s) {
        if (this.state.tri === 'indeterminate') {
            this.setAllCheckboxes(true);
            this.setState({tri: 'checked'});
        }
        else if (this.state.tri === 'checked') {
            this.setAllCheckboxes(false);
            this.setState({tri: 'unchecked'});
        }
        else {
            this.setAllCheckboxes(true);
            this.setState({tri:'checked'});
        }
    }
    
    closeNotify(messageid) {
        let msgs = this.state.messages.filter(item => item.id !== messageid);
        this.setState({messages: msgs});
    }

    attachComparisonClicked(evt) {
        var ids = [];
        let prodcount = (this.props.products.length <= 4) ? this.props.products.length : 4;
        if (this.state.tri === 'unchecked') {
            Dialog.open({
                message: 'Select at least one product attribute to include on the comparison document.',
                links: [Dialog.links.ok]
            });
            return;
        }
        for (let i = 0; i < prodcount; i++) {
            ids.push('' + this.props.products[i].Id);
        }
        var attrs = [];
        for (let gi = 0; gi < this.props.compares.length; gi++) {
            let group = this.props.compares[gi];
            for (let ai = 0; ai < group.Attributes.length; ai++)
            {
                var attr = group.Attributes[ai];
                if (attr.isSelected) {
                    attrs.push(attr.Name);
                }
            }
        }

        var attachApi = quosal.api.product.attachComparison(app.currentQuote.IdQuoteMain, ids, attrs);
        attachApi.finished = function(msg) {
            this.pushAMessage('Your comparison has been attached to this quote.');
        }.bind(this);
        attachApi.call();
    }

    removeAllComparisons(evt) {
        var attachApi = quosal.api.product.removeAllComparisons(app.currentQuote.IdQuoteMain);
        attachApi.finished = function(msg) {
            if (msg.error.length === 0) {
                if (msg.comparisonsremoved === 1) {
                    this.pushAMessage("Removed 1 comparison from this quote.");
                }
                else {
                    this.pushAMessage("Removed " + msg.comparisonsremoved + " comparisons from this quote.");
                }
            }
            else {
                this.pushAMessage(msg.error);
            }
        }.bind(this);
        attachApi.call();
    }

    pushAMessage(noteText) {
        let msgs = this.state.messages;
        let newMsg = {
            id: this.state.nextmsg,
            text: noteText,
            autoClose: {
                timeLimit: 10000,
                start: Date.now()
            }
        };
        msgs.push(newMsg);
        this.setState({messages: msgs, nextmsg: this.state.nextmsg+1});
    }

    render() {
        let notifications = null;
        let me = this;
        if (this.state.messages.length > 0) {
            notifications = [];
            for (let a=0; a<this.state.messages.length; a++) {
                let note=this.state.messages[a];
                let node = (
                    <QuickNote
                        cwId={'note'+note.id}
                        status={CwWidgets.CwNotificationStatus.SUCCESSFUL}
                        statusIcon='img/svgs/v1.0/Status_Success.svg'
                        onClose={() => {me.closeNotify(note.id);}}
                        className='etilizenotify info'
                        message={note.text}
                    />);
                notifications.push(node);
            }
        }
        return (
            <div className='grid col4x4'>
                <div className='panel' id='etilizedetails'>
                    <div id='errormessage' className='hidden' >#ErrorMessage</div>
                    <div className='title'>
                        <span style={{float:'left'}} >Product Comparison</span>
                        <span className='toolbar' style={{float:'right'}} ><Button key='return' onClick={this.props.returnFn} style={{float:'right'}} >Close</Button></span>
                    </div>

                    <br />
                    {notifications}
                    <div style={{marginTop:'5px'}}>
                    <table id='detailstable' cellPadding={0} cellSpacing={0} >
                        <tbody>
                            {this.renderProductImages()}
                            {this.renderProductDescriptions()}
                            {this.renderComparisonRows()}
                        </tbody>
                    </table>
                    </div>

                    <div style={{marginTop:'20px'}}>
                        <div id='etilizelink' width={166}>
                            <a href='http://www.etilize.com?utm_source=client&utm_medium=banner&utm_campaign=logoLink' rel="noopener noreferrer" target='_blank'><img src='https://content.etilize.com/logoprogram/gfk-powered-by-logo-small.png' width={166} height={22} alt='product information' /></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
