export class ApprovalGroups extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            groups: [],     
            currentgroup: {id:'0', name:'', members:[] },
            isList: true,
            users: [],
            isDirty: false,
            changecount: 0,
            isLoading: true
        }; 

        this.switchToAdd = this.switchToAdd.bind(this);
        this.getGroupMemberName = this.getGroupMemberName.bind(this);
        this.editGroup = this.editGroup.bind(this);
        this.canceledit = this.canceledit.bind(this);
        this.savegroup = this.savegroup.bind(this);
        this.userRowClicked = this.userRowClicked.bind(this);
        this.nameChanged = this.nameChanged.bind(this);
        this.isDuplicateName = this.isDuplicateName.bind(this);
        this.onNavigation = this.onNavigation.bind(this);
        this.onWindowUnloading = this.onWindowUnloading.bind(this);
        this.reset = this.reset.bind(this);
        this.setupGroupsFromData = this.setupGroupsFromData.bind(this);
        this.updateInternalList = this.updateInternalList.bind(this);
        this.rebuildGroupMemberships = this.rebuildGroupMemberships.bind(this);        
    }

    componentDidMount(){
        let me = this;
        ApprovalGroups.hookUpNavigationActions.call(this);
        let api = quosal.api.admin.initApprovalGroups();
        Dialog.setIsWorking(true);
        api.finished=function(msg) {
            me.setupGroupsFromData(msg.groups, msg.users);
            this.setState({isLoading: false});
            Dialog.setIsWorking(false);
        }.bind(this);
        api.call();
    }

    getGroupMemberName(user) {
        var result;
        if (user.PresentationName && user.PresentationName !== '') {
            result = user.PresentationName;
        }
        else if ((user.FirstName && user.FirstName !== '') || (user.LastName && user.LastName !== '')) {
            result = user.FirstName + ' ' + user.LastName;
            result = result.trim();
        } 
        else {
            result = user.UserName;
        }

        return result;
    }

    setupGroupsFromData(groups, users) {
        if (!groups) {
            this.setState({groups:[], users:users});
            return;
        }
        let gs = [];

        let id2Name = (id) => {
            let result = '';
            users.forEach((u) => {
                if (u.id == id) {
                    result = this.getGroupMemberName(u);
                }
            });
            return result;
        }

        groups.forEach(function(g) {
            let members = [];
            let humans = [];
            g.UserIds.forEach(function(u) {
                members.push(u);
                humans.push(id2Name(u));
            });

            let entry = {
                id: g.IdApprovalGroups,
                name: g.Name, 
                members: members, 
                humans: humans
            };
            gs.push(entry);
        });
        this.setState({groups:gs, users:users});
    }

    componentWillUnmount() {
        ApprovalGroups.unhookNavigationActions.call(this);
    }
    
    static onNavigation(navState) {
        if (!this) {
            return;
        }
        var runOnNavigation = function () {
            if (this.state.isDirty && !this.props.noSave) {
                var resumeNavigation = function (navState) {
                    if (typeof this.reset === 'function') {
                        this.reset();
                    }
                    Dialog.closeAll();

                    navState.resumeNavigation();
                }.bind(this, navState);

                var saveChanges = function (navState) {
                    this.savegroup();

                    resumeNavigation();
                }.bind(this, navState);

                Dialog.open({
                    title: 'Save Changes?',
                    message: 'Do you want to save changes before leaving this page?',
                    closeRequiresButton: true,
                    links: [{
                        title: 'Save',
                        callback: saveChanges
                    }, {
                        title: 'Discard',
                        callback: resumeNavigation
                    }, {
                        title: 'Cancel',
                        callback: Dialog.closeAll
                    }]
                });

                if (navState.cancelNavigation)
                    navState.cancelNavigation();
            }
        }.bind(this);

        if (this.prepareThenSave) {
            this.prepareThenSave(runOnNavigation);
        } else {
            runOnNavigation();
        }
    }

    static onWindowUnloading(e) {
        if (!this.props) return;
        if(!this.props.noSave && this.state.isDirty) {
            if(!e) e = {};
            e.preventDefault();
            e.cancelBubble = true;
            e.returnValue = 'There are unsaved changes that will be lost, are you sure you want to navigate away?';
            return e.returnValue;
        }
    }

    static hookUpNavigationActions() {
        quosal.events.beforeWindowUnload.bind(this.onWindowUnloading, null);
    }

    static unhookNavigationActions() {
        quosal.events.beforeWindowUnload.unbind(this.onWindowUnloading);
    }
    
    onNavigation(navState) {
        ApprovalGroups.onNavigation.call(this, navState);
    }
    onWindowUnloading(e) {
        ApprovalGroups.onWindowUnloading.call(this, e);
    }

    reset() {
        this.state.isDirty = false;
    }
    prepareThenSave(saveCallback) {
        saveCallback();
    }

    switchToAdd() {
        this.rebuildGroupMemberships('00-00-00');
        this.setState({isList: false, currentgroup:  {id:'0', name:'', members:[], humans: [] } });
    }

    editGroup(group) {
        let members = group.members.slice(0);
        let humans = group.humans.slice(0);
        let copy = {
            id: group.id,
            name: group.name,
            members: members,
            humans: humans
        };
        this.rebuildGroupMemberships(group.id);
        this.setState({isList: false, currentgroup: copy});
    }
    
    rebuildGroupMemberships(groupid) {
        let me = this;
        me.state.users.forEach(function(u) {
            let membership = [];
            me.state.groups.forEach(function(g) {
                if (g.id != groupid) {
                    g.members.forEach(function(m) {
                        if (u.id == m) {
                            membership.push(g.name);
                        }
                    });
                }
            });
            u.other = membership;
        });
    }

    canceledit() {
        this.setState({isList: true, currentgroup: null, isDirty: false });
    }

    savegroup() {
        let api2 = quosal.api.admin.saveApprovalGroup(this.state.currentgroup.id, this.state.currentgroup.name.trim(), this.state.currentgroup.members);
        api2.finished=function(msg) {
            this.updateInternalList(msg.group.IdApprovalGroups);
        }.bind(this);
        api2.call();
    }

    updateInternalList(theID) {
        let me = this;
        let users = [];
        this.state.users.forEach((u) => {
            if (u.IsApprover && !u.IsInactive) {
                let sel = me.state.currentgroup.members.indexOf(u.id) >= 0;
                if (sel) {      
                    var memberName = this.getGroupMemberName(u);              
                    users.push(memberName);
                }
            }
        });
        this.state.currentgroup.humans = users;

        if (this.state.currentgroup.id == '0') {
            this.state.currentgroup.id = theID;
            this.state.groups.push(this.state.currentgroup);
        }
        else {
            for (let i = 0; i < this.state.groups.length; i++) {
                if (this.state.groups[i].id == this.state.currentgroup.id) {
                    this.state.groups[i] = this.state.currentgroup;
                    break;
                }
            }
        }

        this.setState({isList: true, currentgroup:  {id:'0', name:'', members:[] }, isDirty: false});
    }

    userRowClicked(id) {
        let res;
        if (this.state.currentgroup.members.indexOf(id) >= 0) {
            res = this.state.currentgroup.members.filter(rowid => rowid != id);
        }
        else {
            res = this.state.currentgroup.members.slice(0);
            res.push(id);
        }
        this.state.currentgroup.members = res;
        this.setState((prevState) => {
            return {changecount: prevState.changecount + 1, isDirty: true};
          });
    }
    nameChanged(e) {
        this.state.currentgroup.name = e.target.value;
        this.setState((prevState) => {
            return {changecount: prevState.changecount + 1, isDirty: true};
          });
    }
    
    isDuplicateName() {
        for (let i = 0; i < this.state.groups.length; i++) {
            if (this.state.groups[i].name.toUpperCase().trim() == this.state.currentgroup.name.toUpperCase().trim()
                && this.state.groups[i].id != this.state.currentgroup.id) {
                    return true;
                }
        }
        return false;
    }

    render() {
        if(this.state.isLoading){
            return(<div></div>);
        }
        let noitems = (<div key='place' style={{margin:'auto', width:'75%', height:'120px', textAlign: 'center'}}>
            <div style={{marginTop:'30px'}}></div>
            <div data-cy="noApprovalGroups" style={{fontSize:'18px', color:'#6e6e6e', marginTop:'48px'}}>Currently there are no approval groups</div>
            <div style={{marginTop:'18px'}}></div>
                <CwWidgets.CwButton toolTip='CREATE GROUP' className="createGroupButton" isStandard={true}
                    onClick={this.switchToAdd} value='CREATE GROUP' />
            <div style={{marginTop:'18px'}}></div>
        </div>);
        let info = (<div>
            <div className='panel' help='admin/organization-setup/approval/active-rules'>
                <div className='title'><span className='floatleft'>Approval Groups</span>
                </div>
                <div className='content' style={{ paddingBottom: '10px' }}>
                   {noitems}
                </div>
            </div>
        </div>);

        if (this.state.isList) {
            this.theform = null;
            if (this.state.groups.length > 0) {
                return <ApprovalGroupListing addfn={this.switchToAdd} editfn={this.editGroup} groups={this.state.groups} />;
            }
            else {
                return info;
            }
        }
        else {
            let okSave = this.state.currentgroup.members.length > 0 && this.state.currentgroup.name.length > 0;
            return (<ApprovalGroupForm group={this.state.currentgroup} fnSave={this.savegroup} fnCancel={this.canceledit} 
                    fnNameChanged={this.nameChanged} fnUserRowClicked={this.userRowClicked} users={this.state.users} canSave={okSave}
                    ref={(ctrl) => { this.theform = ctrl; }} fnCheckForDuplicate={this.isDuplicateName} formDirty={this.state.isDirty}
            />);
        }
    }
  }
  global.ApprovalGroups = ApprovalGroups;

 export class ApprovalGroupForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {     
            sortcol: 1,
            sortorder:true,
            errors: []
        }; 
        this.errName = 'name';
        this.errMembers = 'members';
        this.kErrorName = 'Group Name is required';
        this.kErrorMembers = 'Group Members are required';

        this.renderToolbar = this.renderToolbar.bind(this);
        this.renderDetails = this.renderDetails.bind(this);
        this.renderUserList = this.renderUserList.bind(this);
        this.setSort = this.setSort.bind(this);
        this.renderSortColumn = this.renderSortColumn.bind(this);
        this.reSort = this.reSort.bind(this);
        this.rowClicked = this.rowClicked.bind(this);
        this.nameChanged = this.nameChanged.bind(this);
        this.doCancelButton = this.doCancelButton.bind(this);
        this.doSaveClicked = this.doSaveClicked.bind(this);
        this.isDuplicate = this.isDuplicate.bind(this);
        this.nameEditPressed = this.nameEditPressed.bind(this);
        this.errorForField = this.errorForField.bind(this);
        this.addError = this.addError.bind(this);
        this.nameBlur = this.nameBlur.bind(this);
    }
    
    componentDidMount() {
        this.nameInput.focus();
    }

    errorForField(field) {
        let msg = this.state.errors[field];
        return msg ? msg : '';
    }

    addError(field,msg) {
        this.setState((prev, curprops) => {
            prev.errors[field] = msg;
            return { ...prev, errors: prev.errors }; // isn't going to trigger refresh though.
        });
    }

    reSort() {
        let me = this;
        this.props.users.sort(function(a, b) {
            if (me.state.sortcol == 1) {
                a = a.FirstName.toUpperCase();
                b = b.FirstName.toUpperCase();
            }
            if (me.state.sortcol == 2) {
                a = a.LastName.toUpperCase();
                b = b.LastName.toUpperCase();
            }
            if (me.state.sortcol == 3) {
                a = a.UserName.toUpperCase();
                b = b.UserName.toUpperCase();
            }
            let res = 0;
            if (a < b) {
                res = -1;
            }
            if (a > b) {
                res = 1;
            }
            if (!me.state.sortorder) {
                res *= -1;
            }
            return res;
        });

        this.forceUpdate();
    }
    setSort(col) {
        let me = this;
        if (this.state.sortcol == col) {
            this.setState((state) => ({
                sortorder: !state.sortorder
              }), () => me.reSort() );
        }
        else {
            this.setState((state) => ({
                sortcol: col,
                sortorder: true
              }), () => me.reSort() );
        }
    }
    renderSortColumn(col) {
        if (this.state.sortcol != col) { return null; }
        let elem = (!this.state.sortorder) ? <img src='skins/quosal/images/icons/asc.gif' alt='ascending' style={{position:'relative', top:'-2px'}} /> 
                        : <img src='skins/quosal/images/icons/desc.gif' alt='descending' style={{position:'relative', top:'-2px'}} />;
        return elem;
    }
    rowClicked(id) {
        this.props.fnUserRowClicked(id);
        this.addError(this.errMembers, this.props.group.members.length == 0 ? this.kErrorMembers : '');
    }

    renderToolbar() {
        let title = this.props.group.id == '0' ? 'Add Approval Group' : 'Edit Approval Group';
        let saveTool = this.props.canSave ? 'Save changes to this group' : 'Enter a name and select members before saving this group';
        // disabled & incomplete !this.props.canSave || !this.props.formDirty
        let extraclass = !this.props.canSave || !this.props.formDirty ? 'partial' : null;
        return (
            <div className='panel only' help='admin/organization-setup/approval/edit-rule' style={{height:'22px'}}>
                <div className='title'><span className='floatleft' style={{top:'6px'}} >{title}</span>
                    <div className='toolbar right' style={{marginRight:'10px', position: 'relative', top:'-4px'}}>
                        <CwWidgets.CwButton toolTip='Cancel changes to this group' isStandard={true} onClick={this.doCancelButton} value='CANCEL' style={{marginRight:'10px'}} className='secondary' />
                        <CwWidgets.CwButton toolTip={saveTool} isStandard={true} onClick={this.doSaveClicked} value='SAVE' className={extraclass} />
                    </div>
                </div>
            </div>);
    }

    doCancelButton(){
        if (this.props.formDirty) {
            if (confirm('You will lose any unsaved changes, are you sure you want to cancel?') == false) {
                return;
            }
        }
        if (this.props.fnCancel) {
            this.props.fnCancel();
        }
    }

    isDuplicate(){
        return this.props.fnCheckForDuplicate();
    }

    doSaveClicked() {
        let errormsg = '';
        if (this.props.group.name.length == 0) {
            this.addError(this.errName, this.kErrorName);
            errormsg = 'Please specify a Group Name before saving.';
        }
        if (this.props.group.members.length == 0) {
            this.addError(this.errMembers, this.kErrorMembers);
            errormsg = errormsg.length > 0 ? 'Please specify the Group Name and Members before saving.' : 'Please specify the Group Members before saving.';
        }
        if (errormsg.length > 0) {
           /* Dialog.open({
                title: 'Missing Fields',
                message: errormsg,
                closeRequiresButton: false,
                linkalign: 'right',
                fastload: true,
                links: [{title: 'OK', callback: Dialog.close}]
            });*/
            return false;
        }
        if (this.isDuplicate() == true) {
            this.addError(this.errName, 'Duplicate name');
            return false;
        }

        if (this.props.fnSave) {
            this.props.fnSave();
        }
    }

    renderDetails() {
        let msg = this.errorForField(this.errName);
        let note = null;
        let extraform = {paddingBottom:'0px'};
        let extralabel = null;
        if (msg.length > 0) {
            extralabel = {color:'#ca001a'};
            note = <div data-cy='errorMessage' style={extralabel}>{msg}</div>;
        }
        else {
            note = <div style={extralabel}>{'\u00A0'}</div>;
        }
        return (<Panel title='Details'>
            <PanelContent>
                <div className='formcolumn' style={{paddingLeft:'0px', height:'46px'}}>
                    <div className='formfieldlabel formlabelfull'><label htmlFor='groupname' className='formlabel' style={extralabel}>Name *</label></div>
                    <div className='formfield' style={extraform}><input id='groupname' maxLength='50'
                        ref={(input) => { this.nameInput = input; }} value={this.props.group.name} 
                            placeholder='Group Name' type='text' onChange={this.nameChanged} onBlur={this.nameBlur}
                            onKeyPress={this.nameEditPressed}/></div>
                    {note}
                </div>
            </PanelContent>
        </Panel>);
    }
    nameEditPressed(e) {
        if (e.key === 'Enter' && this.props.group.name && this.props.formDirty) {
            this.doSaveClicked();
        }
        if (e.key === 'Escape') {
            this.doCancelButton();
        }
    }
    nameChanged(e) {
        this.props.fnNameChanged(e);
        this.addError(this.errName,'');
    }
    nameBlur(e) {
        if (!this.props.group.name || this.props.group.name.length == 0) {
            this.addError(this.errName, this.kErrorName);
        }
    }
    
    renderUserList() {
        let me = this;
        let users = [];
        this.props.users.forEach(function(u, index) {
            if ( (u.IsApprover && !u.IsInactive) || me.props.group.members.indexOf(u.id) >= 0) {
                let sel = me.props.group.members.indexOf(u.id) >= 0;
                users.push(<ApprovalMemberRow key={index} ukey={u.id} selectClicked={me.rowClicked}
                                first={u.FirstName} last={u.LastName} username={u.UserName} othermembership={u.other == null ? null : u.other.join(', ')} 
                                checked={sel} />);
            } 
        });
        let msg = this.errorForField(this.errMembers);
        return (
            <div>
                <div className='panel' help='admin/organization-setup/approval/active-rules'>
                    <div className='title'><span data-cy='groupMemberTitle' className='floatleft'>Group Members<span style={{fontSize:'10px',color:'#ca001a', lineHeight:'8px', fontWeight:'normal'}}><br />{msg}</span></span>
                    </div>
                    <div className='content' style={{paddingBottom:'10px'}}>
                        <center>
                        <table data-cy='groupDisplayTable' cellPadding='0' cellSpacing='0' className='datagrid'>
                                <thead>
                                    <tr>
                                        <th width='20' className='title nosort'></th>
                                        <th width='200' className='title nosort' onClick={() => me.setSort(1)}>First{this.renderSortColumn(1)}</th>
                                        <th width='200' className='title nosort' onClick={() => me.setSort(2)}>Last{this.renderSortColumn(2)}</th>
                                        <th width='200' className='title nosort' onClick={() => me.setSort(3)}>User Name{this.renderSortColumn(3)}</th>
                                        <th width='200' className='title nosort'>Other Memberships</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users}
                                </tbody>
                        </table>
                        </center>
                    </div>
                </div>
            </div>
          );
    }

    render() {
        return (<div>
            {this.renderToolbar()}
            {this.renderDetails()}
            {this.renderUserList()}
        </div>);
    }
  }

export class ApprovalMemberRow extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    render() {
        return (
            <tr key={this.props.ukey}>
                <td key={'chkx' + this.props.ukey} width='20' className='content center' >
                    <input key={'chk' + this.props.ukey} style={{ width: '20px' }} name={'user-' + this.props.ukey}
                        id={'user-' + this.props.ukey} checked={this.props.checked} type='checkbox' onChange={this.handleClick} />
                </td>
                <td key={'first' + this.props.ukey} width='200' className='content left'>{this.props.first}</td>
                <td key={'last' + this.props.ukey} width='200' className='content left'>{this.props.last}</td>
                <td key={'user' + this.props.ukey} width='200' className='content left'>{this.props.username}</td>
                <td key={'other' + this.props.ukey} width='200' className='content left'>{this.props.othermembership}</td>
            </tr>
        );
    }
    handleClick() {
        if (this.props.selectClicked) {
            this.props.selectClicked(this.props.ukey);
        }
    }
}

export class ApprovalGroupListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {      
            something: []
        }; 
    }

    renderRow(group) {
        return (<tr key={group.name}>
            <td width='20' className='content center' onClick={ ()=> {this.props.editfn(group); }}  style={{cursor: 'pointer'}} >
                <div className='icons-action edit' style={{margin:'5px'}} ></div>
            </td>
            <td width='250' className='content'>{group.name}</td>
            <td width='400' className='content'>{group.humans.join(', ')}</td>
        </tr>);
    }

    render() {
        let rows = [];
        for (let i = 0; i < this.props.groups.length; i++) {
            rows.push(this.renderRow(this.props.groups[i]));
        }
        return (
            <div>
                <div className='panel' help='admin/organization-setup/approval/active-rules'>
                    <div className='title'><span className='floatleft'>Approval Groups</span>
                        <div className='toolbar right'>
                            <CwWidgets.CwButton toolTip='Create a new Approval Group' isStandard={true} onClick={this.props.addfn} value='ADD GROUP'
                                className='addGroup' style={{ float: 'right', marginRight: 10, position: 'relative', top: '-4px' }} />
                        </div>
                    </div>
                    <div className='content' style={{ paddingBottom: '10px' }}>
                        <center>
                            <table cellPadding='0' cellSpacing='0' className='datagrid'>
                                <thead>
                                    <tr>
                                        <th width='20' className='title nosort'></th>
                                        <th width='250' className='title nosort'>Name</th>
                                        <th width='400' className='title nosort'>Members</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows}
                                </tbody>
                            </table>
                        </center>
                    </div>
                </div>
            </div>
        );
    }
}
