import {ActiveDisableButton} from "js/jsx/src/classes/etilize/etilizeSearchResults.jsx";

/*global React:object quosal:object $:object */
export class EtilizeSearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            showAllCategories: false,
            showAllManufacturers: false,
            showAllFeatures: false,
            showAllDistributors: true,
            showAllCatalogs: false,
            priced: 0,
            stocked: 0,
            searchinfo: {}
        }; 
        this.componentDidMount = this.componentDidMount.bind(this);
        this.showCategoriesClicked = this.showCategoriesClicked.bind(this);
        this.showManufacturersClicked = this.showManufacturersClicked.bind(this);
        this.showFeaturesClicked = this.showFeaturesClicked.bind(this);
        this.showDistributorsClicked = this.showDistributorsClicked.bind(this);
        this.showCatalogsClicked = this.showCatalogsClicked.bind(this);
        this.renderCategories = this.renderCategories.bind(this);
        this.renderManufacturers = this.renderManufacturers.bind(this);
        this.singleFeatureInfo = this.singleFeatureInfo.bind(this);
        this.renderFeatures = this.renderFeatures.bind(this);
        this.renderDistributors = this.renderDistributors.bind(this);
        this.renderCatalogs = this.renderCatalogs.bind(this);
        this.renderActiveSearchElements = this.renderActiveSearchElements.bind(this);
        this.slyChangeTotals = this.slyChangeTotals.bind(this);
        this.renderSearchCriteria = this.renderSearchCriteria.bind(this);
        this.renderFilterSet = this.renderFilterSet.bind(this);
        this.makeDefDistMsg = this.makeDefDistMsg.bind(this);
        this.showDefaultsDialog = this.showDefaultsDialog.bind(this);
        this.handleDialogSave = this.handleDialogSave.bind(this);
        this.render = this.render.bind(this);
        this.keywordPressed = this.keywordPressed.bind(this);
        // var ExpectedProps = {
        //     searching: 'bool',
        //     phase: 'phase',
        //     onSearch: 'fn',
        //     searchinfo: {}
        // };
    }

    componentDidMount() {
    }
    showCategoriesClicked() {
        this.setState({
            showAllCategories: !this.state.showAllCategories
        });
    }
    showManufacturersClicked() {
        this.setState({
            showAllManufacturers: !this.state.showAllManufacturers
        });
    }
    showFeaturesClicked() {
        this.setState({
            showAllFeatures: !this.state.showAllFeatures
        });
    }
    showDistributorsClicked() {
        this.setState({
            showAllDistributors: !this.state.showAllDistributors
        });
    }
    showCatalogsClicked() {
        this.setState({
            showAllCatalogs: !this.state.showAllCatalogs
        });
    }

    renderCategories() {
        var rows = [];
        var me = this;
        var count = 0;
        var hasProducts = this.state.searchinfo.count > 0;
        if (this.state.searchinfo.categories) {
            this.state.searchinfo.categories.forEach(function(c, index) {
                var used = false;
                if (me.props.currentCategories) {
                    me.props.currentCategories.forEach(function(filter) {
                        if (filter.Id == c.Id) {
                            used = true;
                        }
                    });
                }
                if (!used && (count <= 5 || me.state.showAllCategories)) {
                    var label = hasProducts ? '(' + c.Count + ')' : '';
                    var elem = (
                        <div
                            key={'cat' + index}
                            className='categorylink'
                            id={c.Id}
                            data-parentid={c.ParentId}
                            data-type='category'
                            onClick={() => me.props.filterCategory('' + c.Id, c.Name)}
                            name={c.Name}>
                            {c.Name}
                            {label}
                        </div>
                    );
                    rows.push(elem);
                    count++;
                }
            });
            if (count === 0) {
                rows.push(
                    <div
                        key='cat00'
                        id='nocategories'
                        style={{ paddingLeft: '8px', fontStyle: 'italic', color: '#7d7d7d' }}>
                        n/a
                    </div>
                );
            }
        } else {
            rows.push(
                <div
                    key='cat00'
                    id='allcategories'
                    style={{ paddingLeft: '8px', fontStyle: 'italic', color: '#7d7d7d' }}>
                    all
                </div>
            );
        }
        var moreText = this.state.showAllCategories ? 'show fewer' : 'show all';
        var showMore =
            this.state.searchinfo.categories && this.state.searchinfo.categories.length > 5 ? (
                <div id='morecategories' className='morebutton' onClick={this.showCategoriesClicked}>
                    {moreText}
                </div>
            ) : (
                <div />
            );
        return (
            <div id='categories'>
                {rows}
                {showMore}
            </div>
        );
    }

    renderManufacturers() {
        var rows = [];
        var me = this;
        var count = 0;
        if (this.state.searchinfo.manufacturers) {
            this.state.searchinfo.manufacturers.forEach(function(m, index) {
                var used = false;
                if (me.props.selectedManufacturers) {
                    me.props.selectedManufacturers.forEach(function(filter) {
                        if (filter.ID == m.ID) {
                            used = true;
                        }
                    });
                }
                if (!used && (count <= 5 || me.state.showAllManufacturers)) {
                    var elem = (
                        <div
                            key={'man' + index}
                            className='manufacturerlink'
                            id={m.ID}
                            onClick={() => me.props.addManufacturerFilter('' + m.ID, m.Name)}
                            name={m.Name}>
                            {m.Name} ({m.Count})
                        </div>
                    );
                    rows.push(elem);
                    count++;
                }
            });

            if (count === 0) {
                rows.push(
                    <div
                        key='man00'
                        id='nomanufacturers'
                        style={{ paddingLeft: '8px', fontStyle: 'italic', color: '#7d7d7d' }}>
                        n/a
                    </div>
                );
            }
        } else {
            rows.push(
                <div
                    key='man00'
                    id='allmanufacturers'
                    style={{ paddingLeft: '8px', fontStyle: 'italic', color: '#7d7d7d' }}>
                    all
                </div>
            );
        }
        var moreText = this.state.showAllManufacturers ? 'show fewer' : 'show all';
        var showMore =
            this.state.searchinfo.manufacturers && this.state.searchinfo.manufacturers.length > 5 ? (
                <div id='moremanufacturers' className='morebutton' onClick={this.showManufacturersClicked}>
                    {moreText}
                </div>
            ) : (
                <div />
            );
        return (
            <div id='manufacturers'>
                {rows}
                {showMore}
            </div>
        );
    }

    singleFeatureInfo(feature) {
        var sublist = [];
        var me = this;
        this.state.searchinfo.features.forEach(function(sub) {
            if (sub.ParentId === feature.Id) {
                var elem = (
                    <option
                        className='featurelistitem'
                        id={'f' + sub.ParentId + 'c' + sub.Id}
                        key={'f' + sub.ParentId + 'c' + sub.Id}
                        value={sub.Id}
                        data-parentname={feature.Name}
                        name={sub.Name}>
                        {sub.Name} ({sub.Count})
                    </option>
                );
                sublist.push(elem);
            }
        });
        if (feature.AttributeType.toLowerCase() === 'numeric') {
            return (
                <div key={'feat' + feature.Id} className='featurelink' id={feature.Id} name={feature.Name}>
                    <div className='formselectfieldwrapper filteroperand etilizefilterpad filterwrap'>
                        <select className='formselectfield filterselect' id={'cmp' + feature.Id} ref={'cmp' + feature.Id} style={{fontSize:'10px',height:'16px'}}>
                            <option value='eq'>==</option>
                            <option value='gt'>&gt;=</option>
                            <option value='lt'>&lt;=</option>
                        </select>
                    </div>
                    <div className='formselectfieldwrapper numericfeaturelist etilizefilterpad filterwrap'>
                        <select
                            className='formselectfield filterselect'
                            onChange={e => me.props.addFilter(e, feature)}
                            id={'fi' + feature.Id}
                            ref={'fi' + feature.Id} style={{fontSize:'10px',height:'16px'}}>
                            <option id={feature.Id} key={'f' + feature.Id} >
                                {feature.Name} ({feature.Count})
                            </option>
                            {sublist}
                        </select>
                    </div>
                </div>
            );
        }
        
        return (
            <div key={'feat' + feature.Id} className='featurelink' id={feature.Id} name={feature.Name}>
                <div className='formselectfieldwrapper featurelist etilizefilterpad filterwrap'>
                    <select
                        className='formselectfield filterselect' style={{fontSize:'10px',height:'16px'}}
                        onChange={e => me.props.addFilter(e, feature)}
                        id={'fi' + feature.Id}
                        ref={'fi' + feature.Id}>
                        <option id={feature.Id} key={'f' + feature.Id}>
                            {feature.Name} ({feature.Count})
                        </option>
                        {sublist}
                    </select>
                </div>
            </div>
        );
    }

    renderFeatures() {
        var rows = [];
        var showall = this.state.showAllFeatures;
        var count = 0;
        var me = this;
        if (this.state.searchinfo.features) {
            this.state.searchinfo.features.forEach(function(f) {
                if (f.ParentId === 0) {
                    var m = me.props.currentFilters.filter(e => e.ParentId === f.Id);
                    if (m.length === 0) {
                        if (count < 5 || showall) {
                            var elem;

                            count++;
                            elem = me.singleFeatureInfo(f);
                            rows.push(elem);
                        }
                    }
                }
            });
            if (count === 0) {
                rows.push(
                    <div
                        key='feat00'
                        id='nofeatures'
                        style={{ paddingLeft: '8px', fontStyle: 'italic', color: '#7d7d7d' }}>
                        n/a
                    </div>
                );
            }
        } else {
            var infotext = 'all';
            if (this.props.currentFeatures && this.props.currentFeatures.length > 0) {
                infotext = 'n/a';
            }
            rows.push(
                <div
                    key='feat00'
                    id='allfeatures'
                    style={{ paddingLeft: '8px', fontStyle: 'italic', color: '#7d7d7d' }}>
                    {infotext}
                </div>
            );
        }
        var moreText = showall ? 'show fewer' : 'show all';
        var showMore =
            this.state.searchinfo.features && this.state.searchinfo.features.length > 5 ? (
                <div id='morefeatures' className='morebutton' onClick={this.showFeaturesClicked}>
                    {moreText}
                </div>
            ) : (
                <div />
            );
        return (
            <div id='features'>
                {rows}
                {showMore}
            </div>
        );
    }

    renderDistributors() {
        var config = this.props.configs;
        var rows = [];
        var index = 0;
        var me = this;
        if (config.UseSynnex) {
            index++;
            let isChecked = me.props.currentDistributors.includes(config.EtilizeSynnexKey);
            let row = (
                <div className='etilizecheckboxwrapper' key='includesynnex' style={{paddingBottom:'0px'}}>
                    <input
                        key={'dist' + index}
                        type='checkbox'
                        name='sourcefilter'
                        id='includesynnex'
                        value={config.EtilizeSynnexKey}
                        checked={isChecked}
                        onChange={e=> me.props.filterDistributor(e, '' + config.EtilizeSynnexKey)}
                    />
                    <label htmlFor='includesynnex' className='formlabel'>
                        TD SYNNEX
                    </label>
                </div>
            );
            rows.push(row);
        }
        if (config.UseIngram) {
            index++;
            let isChecked = me.props.currentDistributors.includes(config.EtilizeIngramKey);
            let row = (
                <div className='etilizecheckboxwrapper' key='includeingram' style={{paddingBottom:'0px'}}>
                    <input
                        key={'dist' + index}
                        type='checkbox'
                        name='sourcefilter'
                        id='includeingram'
                        value={config.EtilizeIngramKey}
                        checked={isChecked}
                        onChange={e=> me.props.filterDistributor(e, '' + config.EtilizeIngramKey)}
                    />
                    <label htmlFor='includeingram' className='formlabel'>
                        Ingram Micro
                    </label>
                </div>
            );
            rows.push(row);
        }
        if (config.UseDNH) {
            index++;
            let isChecked = me.props.currentDistributors.includes(config.EtilizeDnHKey);
            let row = (
                <div className='etilizecheckboxwrapper' key='includednh' style={{paddingBottom:'0px'}}>
                    <input
                        key={'dist' + index}
                        type='checkbox'
                        name='sourcefilter'
                        id='includednh'
                        value={config.EtilizeDnHKey}
                        checked={isChecked}
                        onChange={e=> me.props.filterDistributor(e, '' + config.EtilizeDnHKey)}
                    />
                    <label htmlFor='includednh' className='formlabel'>
                        D&amp;H
                    </label>
                </div>
            );
            rows.push(row);
        }
     
        if (index === 0) {
            rows.push(
                <div
                    key='dist00'
                    id='alldistributors'
                    style={{ paddingLeft: '8px', fontStyle: 'italic', color: '#7d7d7d' }}>
                    all
                </div>
            );
        }
        var moreText = this.state.showAllDistributors ? 'show fewer' : 'show all';
        var showMore =
            this.state.searchinfo.distributors && this.state.searchinfo.distributors.length > 5 ? (
                <div id='moredistributors' className='morebutton' onClick={this.showDistributorsClicked}>
                    {moreText}
                </div>
            ) : (
                <div />
            );
        return (
            <div id='distributors'>
                {rows}
                {showMore}
            </div>
        );
    }

    renderCatalogs() {
        var rows = [];
        var me = this;
        if (this.state.searchinfo.catalogs && this.state.searchinfo.catalogs.length > 0) {
            this.state.searchinfo.catalogs.forEach(function(src, index) {
                var isChecked = me.props.currentCatalogs.includes(src);
                if (index <= 5 || me.state.showAllCatalogs || isChecked) {
                    var elem = (
                        <div className='etilizecheckboxwrapper' key={'log' + index} style={{paddingBottom:'0px'}}>
                            <input
                                key={'logi' + index}
                                type='checkbox'
                                name='catalogsourcefilter'
                                id={'include' + src}
                                value={src}
                                checked={isChecked}
                                onChange={e=> me.props.filterCatalogs(e, '' + src)}
                            />
                            <label htmlFor={'include' + src} className='formlabel'>
                                {src}
                            </label>
                        </div>
                    );
                    rows.push(elem);
                }
            });
        } else {
            rows.push(
                <div key='log00' id='allcatalogs' style={{ paddingLeft: '8px', fontStyle: 'italic', color: '#7d7d7d' }}>
                    all
                </div>
            );
        }
        var moreText = this.state.showAllCatalogs ? 'show fewer' : 'show all';
        var showMore =
            this.state.searchinfo.catalogs && this.state.searchinfo.catalogs.length > 5 ? (
                <div id='morecatalogs' className='morebutton' onClick={this.showCatalogsClicked}>
                    {moreText}
                </div>
            ) : (
                <div />
            );
        return (
            <div id='catalogsources'>
                {rows}
                {showMore}
            </div>
        );
    }

    renderActiveSearchElements() {
        var rows = [];
        var me = this;
        if (this.props.currentCategories) {
            for (let i = 0; i < this.props.currentCategories.length; i++) {
                let cat = this.props.currentCategories[i];
                let row = (
                    <div
                        className='filterlink'
                        id={cat.Id}
                        key={'c' + cat.Id}
                        data-type='category'
                        data-parentid={cat.ParentId}
                        name={cat.Name}
                        onClick={() => me.props.removeCategory('' + cat.Id)}>
                        <CwWidgets.CwImage src={'img/svgs/sell/Action_Clear.svg'} isInlineSVG={false} style={{height:'12px', width:'12px'}}/>
                        Category: {cat.Name}
                    </div>
                );
                rows.push(row);
            }
        }

        if (this.props.selectedManufacturers) {
            for (let i = 0; i < this.props.selectedManufacturers.length; i++) {
                let man = this.props.selectedManufacturers[i];
                let row = (
                    <div
                        className='filterlink'
                        id={man.ID}
                        key={'m' + man.ID}
                        data-type='manufacturer'
                        name={man.Name}
                        onClick={() => me.props.remManufacturerFilter('' + man.ID)}>
                        <CwWidgets.CwImage src={'img/svgs/sell/Action_Clear.svg'} isInlineSVG={false} style={{height:'12px', width:'12px'}}/>
                        Manufacturer: {man.Name}
                    </div>
                );
                rows.push(row);
            }
        }

        if (this.props.currentFilters) {
            for (let i = 0; i < this.props.currentFilters.length; i++) {
                let feat = this.props.currentFilters[i];
                let row = (
                    <div
                        className='filterlink'
                        id={feat.Id}
                        key={'f' + feat.Id + '-' + i}
                        data-type='feature'
                        name={feat.Name}
                        onClick={() => me.props.remFilter('' + feat.Id)}>
                        <CwWidgets.CwImage src={'img/svgs/sell/Action_Clear.svg'} isInlineSVG={false} style={{height:'12px', width:'12px'}}/>
                        {feat.Title}
                        {feat.Name}
                    </div>
                );
                rows.push(row);
            }
        }

        if (rows.length > 0) {
            return <div id='filtersList'>{rows}</div>;
        }
        
        return (
            <div id='filtersList' style={{ paddingLeft: '8px', fontStyle: 'italic', color: '#7d7d7d' }}>
                none
            </div>
        );
    }

    slyChangeTotals(numPriced, numStocked) {
        $('#showpricing').html('Only Items With Pricing (' + numPriced + ')');
        $('#showinstock').html('Only Items In Stock (' + numStocked + ')');
    }

    renderSearchCriteria() {
        var hideFilterFields = (this.props.configs && this.props.configs.EtilizePrefilterResults) ?? false;
        return (
            <div id='etilizefilters'>
                <div style={{ marginTop: '10px' }} />
                <div className='formfieldlabel'>Keywords</div>
                <div className='formfield'>
                <CwWidgets.CwTextField
                    name='keywords'
                    id='keywords'
                    value={this.props.keywords}
                    invalid={!this.props.keywords || this.props.keywords.length===0}
                    type='text'
                    onChange={this.props.keyChanged}
                    onKeyPress={this.keywordPressed} />
                
                </div>
                <div className="etilizeFormFields" hidden={hideFilterFields}>
                    <div className='formfieldlabel'>Number of Results</div>
                    <div className='formselectfieldwrapper'>
                        <select
                            id='maxresults'
                            name='maxresults'
                            className='formselectfield'
                            value={this.props.pagesize}
                            onChange={this.props.pagesizeChanged}>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={200}>200</option>
                        </select>
                    </div>
                    <div className='formfieldlabel'>Filter Results</div>
                    <div className='formselectfieldwrapper'>
                        <select
                            id='showfilter'
                            name='showfilter'
                            className='formselectfield'
                            onChange={this.props.showChanged}>
                            <option id='showall' value='showall'>
                                Show All Items
                            </option>
                            <option id='showpricing' value='showpricing'>
                                Only Items With Pricing ({this.state.priced})
                            </option>
                            <option id='showinstock' value='showinstock'>
                                Only Items In Stock ({this.state.stocked})
                            </option>
                        </select>
                    </div>
                </div>
                <br />
                <div className='etilizefilterpad'>
                    <ActiveDisableButton
                        key='search'
                        onClick={this.props.onSearch}
                        unavailable={(!this.props.priceSourcesLoaded || !this.props.keywords || this.props.keywords.length === 0) ? 'true': 'false'}
                        disabled={!this.props.priceSourcesLoaded || this.state.searching} 
                        loadingpricesources={(!this.props.priceSourcesLoaded).toString()}>
                        Search
                    </ActiveDisableButton>
                    <Button
                        key='reset'
                        onClick={this.props.onReset}
                        disabled={this.state.searching}
                        style={{ marginLeft: '24px' }}>
                        Reset
                    </Button>
                </div>
            </div>
        );
    }

    renderFilterSet() {
        var catalogInfo = <div />;
        if (this.state.searchinfo.catalogs && this.state.searchinfo.catalogs.length > 0) {
            catalogInfo = (
                <div>
                    <div className='fieldgroupheader'>Catalog Sources</div> {this.renderCatalogs()}
                </div>
            );
        }

        var distInfo = <div />;
        if (
            this.props.configs &&
            this.props.configs.EtilizeDefaultCatalog === 'North America'
        ) {
            distInfo = (
                <div>
                    <div className='fieldgroupheader'>Distributors</div>
                    {this.renderDistributors()}
                </div>
            );
        }
        return (
            <div id='etilizefilter_b'>
                <div className='fieldgroupheader'>Active Filters</div>
                {this.renderActiveSearchElements()}
                <div className='fieldgroupheader'>Categories</div>
                {this.renderCategories()}
                <div className='fieldgroupheader'>Manufacturers</div>
                {this.renderManufacturers()}
                <div className='fieldgroupheader'>Features</div>
                {this.renderFeatures()}

                {distInfo}
                {catalogInfo}
            </div>
        );
    }

    makeDefDistMsg(dists) {
        var config = this.props.configs;
        
        let checks = [];
        let isActive = false;
        if (config.EtilizeDefaultCatalog == 'North America') {
            if (config.UseSynnex) {
                isActive = dists.includes(config.EtilizeSynnexKey);
                checks.push(<div key='fbwD1' className="formcheckboxwrapper" style={{ display: 'inline-block' }}>
                    <input key='ddsynnex' id="synnex" value="synnex" type="checkbox" defaultChecked={isActive} ref={(r) => { this.chkdefSyn = r; }} />
                    <label key='ddlsynnex' htmlFor="synnex" className="formlabel">TD SYNNEX</label>
                </div>);
            }
            if (config.UseIngram) {
                isActive = dists.includes(config.EtilizeIngramKey);
                checks.push(<div key='fbwD3' className="formcheckboxwrapper" style={{ display: 'inline-block' }}>
                    <input key='ddingram1' id="ingram1" value="ingram1" type="checkbox" defaultChecked={isActive} ref={(r) => { this.chkdefIn = r; }} />
                    <label key='ddlingram1' htmlFor="ingram1" className="formlabel">Ingram Micro</label>
                </div>);
            }
            if (config.UseDNH) {
                isActive = dists.includes(config.EtilizeDnHKey);
                checks.push(<div key='fbwD5' className="formcheckboxwrapper" style={{ display: 'inline-block' }}>
                    <input key='dddnh' id="dnh" value="dnh" type="checkbox" defaultChecked={isActive} ref={(r) => { this.chkdefDH = r; }} />
                    <label key='ddldnh' htmlFor="dnh" className="formlabel">D &amp; H</label>
                </div>);
            }
        }
        else if (config.EtilizeDefaultCatalog == 'Europe') {
            if (config.UseTechDataUK) {
                isActive = dists.includes(config.EtilizeTechDataUKKey);
                checks.push(<div key='fbwD6' className="formcheckboxwrapper" style={{ display: 'inline-block' }}>
                    <input key='ddtechdatauk' id="techdatauk" value="techdatauk" type="checkbox" defaultChecked={isActive} ref={(r) => { this.chkdefTK = r; }} />
                    <label key='ddltechdatauk' htmlFor="techdatauk" className="formlabel">Tech Data UK</label>
                </div>);
            }
        }

        let message = (<div><div className='dialogsubheader'>Choose the distributors that will be used by default.</div>{checks}</div>);
        return message;
    }

    handleDialogSave() {
        let config = this.props.configs;
        let selectedCount = 0;
        let selectFn = function(sets, chk, key) {
            let isSelected = false;
            if (chk && chk.checked) {
                isSelected = chk.checked;
                if (isSelected) {
                    selectedCount++;
                }
            }
            sets.push({
                key: 'etd-'+key,
                value: (isSelected) ? 'true' : '',
                isUserSetting: false
            });
        };
        
        let settings = [];
        selectFn(settings, this.chkdefSyn, config.EtilizeSynnexKey);
        selectFn(settings, this.chkdefTD, config.EtilizeTechDataKey);
        selectFn(settings, this.chkdefIn, config.EtilizeIngramKey);
        //selectFn(settings, this.chkdefII, config.EtilizeIngramImpulseKey);
        selectFn(settings, this.chkdefDH, config.EtilizeDnHKey);
        selectFn(settings, this.chkdefTK, config.EtilizeTechDataUKKey);

        if (selectedCount <= 3) {    
            var saveApi = quosal.api.settings.saveSettings(settings);
            saveApi.finished = function (msg) {
                Dialog.close();
            }.bind(this);
            Dialog.setIsWorking(true);
            saveApi.call();
        }
        else {
            Dialog.open({
                title: 'Etilize Distributors',
                resizable: false,
                draggable: true,
                newUI: true,
                closeRequiresButton: true,
                messageProps: { style: { overflow: 'visible', display: 'flex' } },
                links:[
                    {title: 'OK', callback: Dialog.close}
                ],
                message: 'Please select three (3) or fewer distributors before saving.'
            });
            return true;
        }
    }

    showDefaultsDialog() {
        let me = this;
        let settings = [];
        var config = this.props.configs;

        if (config && config.EtilizeDefaultCatalog == 'North America') {
            if (config.UseSynnex) { settings.push('etd-' + config.EtilizeSynnexKey); }
            if (config.UseTechData) { settings.push('etd-' + config.EtilizeTechDataKey); }
            if (config.UseIngram) { settings.push('etd-' + config.EtilizeIngramKey); }
            //if (config.UseIngramImpulse) { settings.push('etd-'+config.EtilizeIngramImpulseKey);}
            if (config.UseDNH) { settings.push('etd-' + config.EtilizeDnHKey); }
        }
        else if (config && config.EtilizeDefaultCatalog == 'Europe') {
            if (config.UseTechDataUK) { settings.push('etd-' + config.EtilizeTechDataUKKey); }
        }

        var api = quosal.api.settings.getSettings(settings);
        api.finished = function (msg) {
            let dists = [];
            for (var iDist = 0; iDist < msg.settings.length; iDist++) {
                if (msg.settings[iDist].Value === 'true') {
                    dists.push(msg.settings[iDist].Key.substring(4));
                }
            }

            let message = me.makeDefDistMsg(dists);
            Dialog.open({
                title: 'Etilize Defaults',
                width: '400px',
                resizable: false,
                draggable: true,
                newUI: true,
                closeRequiresButton: true,
                messageProps: { style: { overflow: 'visible', display: 'flex' } },
                links:[
                    {title: 'SAVE', callback: me.handleDialogSave},
                    {title: 'CANCEL', callback: Dialog.close}
                ],
                message: message
            });

        }
        api.call();
    }

    render() {
        let child = null;
        var showDefaultEtilizeDistributorsDialog = this.state.searchinfo &&
            this.props.configs &&
            this.props.configs.EtilizeDefaultCatalog != 'Australia';
        if (app.currentUser.IsAdministrator && showDefaultEtilizeDistributorsDialog ) {
            child = (
                <PanelToolbar style={{float:'right', marginTop:'-3px'}}>
                    <PanelToolbarButton title='Set defaults' className='notfullscreen' 
                        onClick={this.showDefaultsDialog} image='img/svgs/v1.0/Menu_Settings.svg' />
                </PanelToolbar>);
        }
        return (
            <div className='grid col4x1' id="EtilizeSearchId">
                <Panel id='search_criteria' title='Search Criteria' titleChildren={child}>
                    {this.renderSearchCriteria()}
                </Panel>

                <Panel editable={false} id='filters'>
                    {this.renderFilterSet()}
                </Panel>
            </div>
        );
    }

    keywordPressed(e) {
        if (e.key === 'Enter' && this.props.keywords && this.props.keywords.length > 0) {
            this.props.onSearch(false);
        }
    }
}
