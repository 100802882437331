/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Box, Grid, MenuItem, TextField, Typography } from "@mui/material";

import { useAppDataContext } from "../globalStates/appDataProvider";
import { BreadcrumbsButton, StyledButton, LinkStyledButton } from "../styledComponents/common";

import BasicModal from "../commonComponents/basicModal";
import PriceSourcesModal from "../commonComponents/priceSourcesModal";

function ProductItem({ product }) {
	const {
		id,
		image,
		mpn,
		description,
		price,
		prices,
		loadPriceList,
		source,
		sourceId,
		targetTabId,
		quantity,
		tabError,
		quantityError
	} = product;

	const {
		productsToFilter,
		updateProduct,
		deleteProduct,
		navigateToPage,
		setProductInfo,
		setProductDetailsTab
	} = useAppDataContext();

	const [priceSourceInfo, setPriceSourceInfo] = useState({
		Price: price,
		SourceId: sourceId,
		SourceName: source,
		DisplaySourceName: source
	});
	const [qnt, setQnt] = useState(quantity);
	const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
	const [isOpenPriceSources, setIsOpenPriceSources] = useState(false);

	const updatePriceSourceInfo = (sourceInfo) => {
		setPriceSourceInfo(sourceInfo);
		updateProduct(
			{
				source: sourceInfo.DisplaySourceName,
				sourceId: sourceInfo.SourceId,
				price: sourceInfo.Price
			},
			id,
			source
		);
	};

	const handleSelectTargetTab = (tabId) => {
		updateProduct({ targetTabId: tabId, tabError: null }, id, source);
	};

	const handleQuantityChange = ({ target }) => {
		const value = target.value < 0 ? 0 : target.value;
		setQnt(value);
		updateProduct(
			{
				quantity: Number(value),
				quantityError: value > 9999 ? "Exceeds the max value of 9999" : null
			},
			id,
			source
		);
	};

	const handleDeleteProduct = () => {
		deleteProduct(id, source);
		setIsOpenRemoveModal(false);
	};

	const handleMPNClick = () => {
		setProductInfo(product);
		navigateToPage("PRODUCT_DETAILS_PAGE");
		setProductDetailsTab("1");
	};

	useEffect(() => {
		const updatedProduct = productsToFilter?.find((prod) => prod.mpn === mpn);

		if (loadPriceList && updatedProduct && productsToFilter) {
			updateProduct(
				{
					prices: updatedProduct.prices,
					price: updatedProduct.price,
					source: updatedProduct.source,
					sourceId: updatedProduct.sourceId,
					loadPriceList: updatedProduct.loadPriceList
				},
				id,
				source
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadPriceList, mpn, productsToFilter, source, updateProduct]);

	return (
		<Grid
			container
			sx={{
				p: 2,
				mb: 2,
				borderRadius: "4px",
				border: "1px solid  #F1F1F1",
				boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.10)"
			}}
		>
			<Grid item xs="auto" mr={3}>
				<img style={{ width: 200 }} src={image || "images/empty.png"} alt={description} />
			</Grid>
			<Grid container item xs direction="column" spacing={2} alignContent="flex-start">
				<Grid item>
					<Typography fontSize={20} sx={{ mb: 0.5 }}>
						Manufacturer Part Number
					</Typography>
					<BreadcrumbsButton
						sx={{
							fontSize: 16,
							mb: 1.5
						}}
						onClick={handleMPNClick}
					>
						{mpn}
					</BreadcrumbsButton>
					<Typography fontSize={20} sx={{ mb: 0.5 }} gutterBottom>
						Product Description
					</Typography>
					<Typography variant="body2" sx={{ width: "70%" }}>
						{description}
					</Typography>
				</Grid>

				<Grid container item alignItems="center">
					<Grid item xs="auto" mr={2}>
						<Typography fontSize={20}>Price</Typography>
					</Grid>
					<Grid item xs="auto" mr={2}>
						<Typography fontSize={20}>
							{app.currentQuote.formatCurrency(priceSourceInfo.Price)}
						</Typography>
					</Grid>
					{!!prices.length && (
						<Grid item xs="auto" mr={1}>
							<Typography fontSize={20}>{priceSourceInfo.SourceName}</Typography>
						</Grid>
					)}
					<Grid item xs="auto">
						{loadPriceList ? (
							<Typography sx={{ fontSize: 16, fontWeight: 600, color: "error.main" }}>
								Loading...
							</Typography>
						) : (
							<LinkStyledButton
								variant="text"
								sx={{ color: "primary.main", fontWeight: 600 }}
								onClick={() => setIsOpenPriceSources(true)}
							>
								Change Price Source
							</LinkStyledButton>
						)}

						<PriceSourcesModal
							open={isOpenPriceSources}
							onClose={() => setIsOpenPriceSources(false)}
							type="cart"
							row={product}
							handleUpdate={updatePriceSourceInfo}
						/>
					</Grid>
				</Grid>

				<Grid container item justifyContent="space-between" alignItems="center">
					<Grid item container alignItems="center" xs="auto">
						<Grid item xs="auto" mr={1}>
							<SelectTextField
								tabError={tabError}
								targetTabId={targetTabId}
								selectTab={handleSelectTargetTab}
							/>
						</Grid>
						<Grid item xs="auto">
							<TextField
								type="number"
								inputProps={{ min: 0 }}
								value={qnt}
								onChange={handleQuantityChange}
								label="Quantity"
								size="small"
								sx={{
									width: "15ch",
									"& .MuiFormHelperText-root": {
										position: "absolute",
										top: 35,
										width: "max-content",
										ml: 0
									}
								}}
								error={!!quantityError}
								helperText={quantityError || " "}
								InputLabelProps={{
									shrink: true
								}}
							/>
						</Grid>
					</Grid>
					<Grid item xs="auto">
						<StyledButton
							variant="outlined"
							sx={{ mr: 1, padding: "11px" }}
							onClick={() => setIsOpenRemoveModal(true)}
						>
							Remove
						</StyledButton>
					</Grid>
				</Grid>
			</Grid>
			<BasicModal
				isOpen={isOpenRemoveModal}
				handleClose={(val) => setIsOpenRemoveModal(val)}
				title="Confirm Delete Item"
				body="Are you sure you want to delete item?"
				buttons={
					<>
						<LinkStyledButton
							variant="text"
							sx={{ mr: 1, color: "primary.main" }}
							onClick={() => setIsOpenRemoveModal(false)}
						>
							Cancel
						</LinkStyledButton>
						<StyledButton variant="contained" onClick={handleDeleteProduct}>
							Delete
						</StyledButton>
					</>
				}
			/>
		</Grid>
	);
}

function SelectTextField({ tabError, targetTabId, selectTab }) {
	const { targetTabs } = useAppDataContext();
	return (
		<Box
			component="form"
			sx={{
				"& .MuiTextField-root": { width: "35ch" }
			}}
			autoComplete="off"
		>
			<div>
				<TextField
					id="outlined-select-tabs"
					select
					label="Target Tab"
					size="small"
					value={targetTabId}
					onChange={(e) => selectTab(e.target.value)}
					error={!!tabError}
					helperText={tabError || " "}
					sx={{
						"& .MuiFormHelperText-root": {
							position: "absolute",
							top: 35,
							width: "100%"
						}
					}}
					inputProps={{ "data-testid": "target-tab-select" }}
				>
					{Array.isArray(targetTabs) ? (
						targetTabs.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))
					) : (
						<MenuItem key="" value="" />
					)}
				</TextField>
			</div>
		</Box>
	);
}

export default ProductItem;
