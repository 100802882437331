import { FormPlaceholder } from "js/jsx/src/classes/forms.jsx"
import { ArrowTermPage } from "js/jsx/src/classes/arrow/arrowTerm.jsx"

export default function arrowTermImport() {
    quosal.ui.react.render(
        <FormPlaceholder message="Loading Arrow Term..." />
        , document.getElementById('arrowTermContainer'));
    var renderArrowTermImportPage = function (renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;

        var idQuoteTabs = quosal.util.queryString('idquotetabs');
        var idQuoteMain = quosal.util.queryString('idquotemain');
        renderMethod(<ArrowTermPage defaultDestinationTab={idQuoteTabs} idQuoteMain={idQuoteMain} />,
            document.getElementById('arrowTermContainer'));
    };

    var loadArrowTermImportPage = function () {
        renderArrowTermImportPage(quosal.ui.react.render);

        $('#pageName').val('arrow.arrowTermImport');
    };
    loadArrowTermImportPage();
}