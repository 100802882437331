export default function customer() {
    if(!app.currentQuote){
        return;
    }

    quosal.ui.react.render(
        <FormPlaceholder message="Loading Customer Setup..." />
        , document.getElementById('quoteModule'));

    quosal.util.navigateToCustomerSetupSubmodule();
};