import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Link, Stepper, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { QuoteNavigationStep } from "./QuoteNavigationStep";
import { createSteps } from "./quoteStepperHelpers";

export const QuoteStepper = ({ quote, steps, isEoNav, containerStyles = {} }) => {
	const navigationRef = useRef();
	const theme = useTheme();
	const [stepArray, setStepArray] = useState([]);
	const activeStepNumber = stepArray.findIndex((step) => step.isActive);
	const returnToQuote = () => {
		quosal.navigation.navigate(steps[0]?.Url);
	};

	useEffect(() => {
		quosal.navigation.parse(navigationRef.current);
	}, []);

	useEffect(() => {
		setStepArray(createSteps({ steps, quote, isEoNav }));
	}, [isEoNav, steps, quote, theme]);

	return (
		<div
			ref={navigationRef}
			style={{ margin: "-4px 0px 16px 5px", display: "flex", ...containerStyles }}
		>
			{isEoNav && steps[0] && (
				<Box
					sx={{
						width: "160px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<Box
						sx={{
							width: "20px",
							height: "20px",
							borderRadius: "50% 50%",
							border: `1px solid ${theme.palette.quote_stepper.completedBorder}`,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginRight: "5px",
							cursor: "pointer"
						}}
						onClick={returnToQuote}
					>
						<KeyboardArrowLeftIcon sx={{color: theme.palette.quote_stepper.completedBorder}}/>
					</Box>
					<Link
						component="button"
						sx={{
							padding: 0,
							fontSize: "16px",
							fontWeight: 400,
							minHeight: "20px",
							minWidth: "124px",
							marginRight: "5px",
							color: theme.palette.text.link
						}}
						underline="none"
						onClick={returnToQuote}
					>
						{steps[0]?.Name}
					</Link>
				</Box>
			)}
			<Box sx={{ width: "100%" }}>
				<Stepper nonLinear>
					{stepArray.map((stepProps) =>
						//? Here intentianally called the component as a function in order to remove extra connector line from first step.
						QuoteNavigationStep({
							...stepProps,
							quote,
							activeStepNumber: activeStepNumber
						})
					)}
				</Stepper>
			</Box>
		</div>
	);
};
