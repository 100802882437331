import { styled } from '@mui/material/styles';
import { Grid, IconButton, Switch, TextField, Link, Typography, InputLabel, InputAdornment, Tooltip, Box, OutlinedInput } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { tooltipClasses } from '@mui/material/Tooltip';
import { inputLabelClasses } from '@mui/material/InputLabel';

export const SalesTrackTopBarStyled = styled(Box)(({ theme }) => ({
    width: '97%',
    height: '88px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '5px auto',
    padding: '0 14px',
}));

export const CardGridContainerStyled = styled(Grid)(({ theme }) => ({
    width: '97%',
    backgroundColor: theme.palette.background.main,
    padding: '10px',
    margin: '16px auto',
    border: '1px #F1F1F1 solid',
    borderRadius: 4,
}));

export const DeletButtonStyled = styled(IconButton)(({ theme }) => ({
    background: theme.palette.layer.layer1,
    borderRadius: '6px',
    minWidth: '32px',
    minHeight: '32px',
}));

export const CommonLinkStyled = styled((props) => (
    <Link underline="hover" color="inherit" {...props} />)
)(({ theme }) => ({
    color: theme.palette.link_primary.main,
    '&:hover': {
        color: theme.palette.link_primary.hover
    },
}));

export const CommonInputTextStyled = styled((props) => (
    <TextField
        InputLabelProps={{
            shrink: true,
        }}
        InputProps={{
            endAdornment: props.error && <InputAdornment color='button_danger.main' position="end"> <ErrorOutlineRoundedIcon sx={{ color: 'button_danger.main', fontSize: '16px' }} /></InputAdornment>,
            inputProps: props.haslimit ? {
                min: props.minlimit,
                max: props.maxlimit,
                'data-testid':props['data-testid']
            } : {'data-testid':props['data-testid']},
        }}
        size='small'
        fullWidth
        {...props} />
))(({ theme }) => ({
    color: theme.palette.text.main,
    '& .MuiFormLabel-asterisk': {
        color: "red"
    },
    '& .MuiOutlinedInput-input': {
        backgroundColor: theme.palette.background.main,
        boxShadow: 'none !important',
    },
    '& :-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, ' + theme.palette.background.main,
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: '#394E9E',
        },
    },
    [`& .${inputLabelClasses.shrink}`]: {
        color: theme.palette.text.secondary,
        '& .MuiFormLabel-root-MuiInputLabel-root.Mui-disabled': {
            color: theme.palette.text.disabled,
        }
    },
    [`& .${inputLabelClasses.shrink}.Mui-disabled`]: {
        color: theme.palette.text.disabled,
    },
}));

export const CommonInputLabelStyled = styled((props) => (
    <InputLabel
        shrink={true}
        {...props} />
))(({ }) => ({
    "& .MuiFormLabel-asterisk": {
        color: "red"
    }
}));

export const SimpleSalesTrackTitleStyled = styled(Typography)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '29px',
    color: theme.palette.text.main,
}));

export const CommonSalesTrackTitle = styled(Typography)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '22px',
    lineHeight: '28px',
    color: theme.palette.text.main,
}));

export const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 35,
    height: 20,
    padding: 0,
    marginLeft: '14px',
    marginRight: '10px',
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.button_primary.main,
                opacity: 1,
                border: 0
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5
            }
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff"
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color: '#ECEDEE',
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity:  0.7,
        }
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 16,
        height: 16
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: "#8D8E98",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500
        })
    }
}));

export const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: '500px',
    },
});

export const CommonDatePickerStyled = styled(DatePicker)(({ theme }) => ({
    '& .MuiInputBase-input:focus': {
        boxShadow: 'none',
    },
}));

export const CommonTypography = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
}));

export const SubmittedFormsContainerStyled = styled(Grid)(({ theme }) => ({
    marginTop: '20px',
    backgroundColor: theme.palette.background.main,
    border: '1px #F1F1F1 solid',
    borderRadius: 4,
}));

export const SubmittedFormsSearchbarStyled = styled(Box)(({ theme }) => ({
    width: '97%',
    margin: '0 auto', 
    display: 'flex', 
    justifyContent: 'flex-end'
}));

export const SearchInputStyled = styled(OutlinedInput)(({ theme }) => ({
    width: '266px', 
    height: '40px', 
    paddingRight: 0,
    backgroundColor: theme.palette.background.ghost,
    '& .MuiInputBase-input': {
        boxShadow: 'none !important',
    },
    '& .MuiIconButton-root': {
        minWidth: '40px',
        '& .MuiSvgIcon-root': {
            width: '16px',
        }
    }
}));