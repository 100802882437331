export class VendorsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vendors: []
        }

        this.editVendor = this.editVendor.bind(this);
        this.deleteVendor = this.deleteVendor.bind(this);
        this.addNewVendor = this.addNewVendor.bind(this);
        this.initializeColumnSorting = this.initializeColumnSorting.bind(this);
        this.updateColumnSorting = this.updateColumnSorting.bind(this);
        // ER: 1/11/19 Query the old Vendors table  for now for testing purposes. We may end up having to create a new table, it's not yet decided. 
        var initializeAPI = quosal.api.data.query({
            table: 'Vendors',
        });
        initializeAPI.finished = function (msg) {
            var results = msg.resultSets[0];
            results.sort(function (a, b) {
                return (a.Name === b.Name ? 0 : a.Name > b.Name ? 1 : -1);
            });
            this.setState({
                vendors: results,
                initialized: true
            }, this.initializeColumnSorting);
        }.bind(this);
        initializeAPI.call();
    }
    initializeColumnSorting() {
        $.quosal.ui.datagrid(this.refs.table);
    }
    updateColumnSorting() {
        $(this.refs.table).trigger('update');
    }
    editVendor(vendor) {
    }
    deleteVendor(vendor) {
    }
    addNewVendor() {
        // // ER: 1/11/19: someting like the code below. Not yet implemented.
        //var addVendorAPI = quosal.api.vendor.create();
        //Dialog.setIsWorking();
        //addVendorAPI.finished = function (msg) {
        //    this.state.vendors.push(msg.newVendor)
        //    this.setState({ vendors: this.state.vendors }, function () {
        //        this.updateColumnSorting();
        //        this.refs[this.state.vendors.length - 1].onEditClick();
        //    }.bind(this));
        //    Dialog.setIsWorking(false);
        //}.bind(this);
        //addVendorAPI.call();
    }
    render() {

        if (!this.state.initialized) {
            return <FormPlaceholder message={'Loading Vendors ...' } />;
        }

        var idx = 0;
        var editVendor = this.editVendor;
        var deleteVendor = this.deleteVendor;

        var rows = this.state.vendors.map(function (r) {
            idx++;
            var onClickEdit = editVendor.bind(null, r);
            var onClickDelete = deleteVendor.bind(null, r);
            var onLinkToManage = deleteVendor.bind(null, r);
            var svgSrc = "img/svgs/sell/False.svg"
            return (<tr key={idx }>
                    <td className="content center"><a onClick={onClickEdit}><div className="icons-action  edit color" title='Edit Vendor' /></a></td>
                    <td className="content center"><a onClick={onClickDelete}><div className="icons-action  delete color" title="'Delete Vendor'" /></a></td>
                    <td className="content center">{r.VendorName}</td>
                    <td className="content center"><Button onClick={onLinkToManage}>Link</Button></td>
                    <td className="content center"><img src={svgSrc} class="img-truefalse" /></td>
                    </tr>);
        });

        var titleChildren = (<div className="toolbar right"><Button onClick={this.addNewVendor }>Add</Button></div>);

        return (
            <div>                
                <Panel title="Vendors" titleChildren={titleChildren } >
                    <PanelContent>
                        <table className="datagrid nosort" cellSpacing={0} cellPadding={0 }>
                            <thead>
                            <tr>
                                <th className="nosort" width='20px' />
                                <th className="nosort" width='20px' />
                                <th className="center" width="100%">Name</th>
                                <th className="nosort center">Linked to Manage</th>
                                <th className="nosort center">Pricing & Availability</th>
                            </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </PanelContent>
                </Panel>
            </div>
        );
    }
}
