/* global React:object */

export class GridColumn extends React.Component {
    render() {
        var className = 'grid col' + this.props.size;
        if (this.props.className) {
            className += ' ' + this.props.className;
        }
        return (
            <div className={className} style={this.props.style} id={this.props.id}>
                {this.props.children}
            </div>
        );
    }
}
