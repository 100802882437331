/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect, useState, useMemo } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";

import { useAppDataContext } from "../globalStates/appDataProvider";
import PriceSourcesModal from "../commonComponents/priceSourcesModal";
import { LinkStyledButton } from "../styledComponents/common";
import ActionButtons from "./actionButtons";

function BasicOverview() {
	const { productDetails, setProductInfo, productsToFilter, updateProduct } = useAppDataContext();
	const { mpn, manufacturer, price, shortDescription, source, image, loadPriceList, prices } =
		productDetails;
	const [open, setOpen] = useState(false);

	const modifiedProductDetails = useMemo(
		() => ({ ...productDetails, quantity: 1 }),
		[productDetails, productDetails.quantity]
	);

	useEffect(() => {
		if (productDetails?.id && productsToFilter) {
			const updatedProduct = productsToFilter.find(
				(prod) => prod.id === productDetails.id || prod.mpn === productDetails.mpn
			);
			setProductInfo(updatedProduct);
			updateProduct(
				{
					prices: updatedProduct.prices,
					price: updatedProduct.price,
					source: updatedProduct.source,
					sourceId: updatedProduct.sourceId,
					loadPriceList: updatedProduct.loadPriceList
				},
				productDetails.id,
				productDetails.source
			);
		}
	}, [productsToFilter]);

	return (
		<Box sx={{ width: "100%" }}>
			<Grid container spacing={3} mb={3}>
				<Grid item xs="auto">
					<img style={{ width: 300 }} src={image || "images/empty.png"} alt={mpn} />
				</Grid>
				<Grid item xs>
					<Typography fontSize={36} fontWeight={300} mb={2}>
						{mpn}
					</Typography>
					{manufacturer && (
						<Typography fontSize={20} mb={2}>
							{`Manufacturer: ${manufacturer}`}
						</Typography>
					)}
					<Grid container spacing={2} justifyContent="flex-start" mb={3}>
						<Grid item alignSelf="center">
							<Typography fontSize={24} pr={1}>
								{app.currentQuote.formatCurrency(price)}
							</Typography>
						</Grid>
						{!!prices.length && (
							<Grid item xs="auto" ml={1}>
								<Typography fontSize={24}>{source}</Typography>
							</Grid>
						)}

						<Grid item xs="auto">
							<Divider orientation="vertical" />
						</Grid>
						<Grid item alignSelf="center">
							{loadPriceList ? (
								<Typography
									sx={{ fontSize: 16, fontWeight: 600, color: "error.main" }}
								>
									Loading...
								</Typography>
							) : (
								<LinkStyledButton
									variant="text"
									sx={{ fontSize: 16, fontWeight: 600, color: "primary.main" }}
									onClick={() => setOpen(true)}
								>
									Price Source
								</LinkStyledButton>
							)}

							<PriceSourcesModal
								open={open}
								onClose={() => setOpen(false)}
								type="grid"
								row={modifiedProductDetails}
							/>
						</Grid>
					</Grid>
					<Divider />
					<Typography fontWeight={700} my={2}>
						Descriptions- High-impact performance & design
					</Typography>
					<Typography fontSize={14}>{shortDescription}</Typography>
				</Grid>
			</Grid>
			<ActionButtons product={modifiedProductDetails} />
		</Box>
	);
}

export default BasicOverview;
