import { useState } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { IconButton, useTheme } from "@mui/material";
import { BasicModal } from "../common/components";
import { QuoteHeaderMetricsCheckboxes } from "./QuoteHeaderMetricsCheckboxes";
import { useGlobalSettingsContext } from "../context";
import { updateMetrices } from "../services/services";
import { useDispatchContext } from "../context/DispatchContext";

export const QuoteHeaderCustomization = () => {
	const theme = useTheme();
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [noMetricSelected, setNoMetricSelected] = useState(false);

	const closeModal = () => {
		setIsOpenModal(false);
		resetMetricsValues();
	};

	const {
		EnableOneTimeTotal,
		EnableOneTimeGrossProfit,
		EnableRecurring,
		EnableRecurringGrossProfit,
		EnableTotalQuoteValue,
		EnableDownpayment
	} = useGlobalSettingsContext();

	const dispatch = useDispatchContext();

	const intialState = {
		showOneTimeTotal: EnableOneTimeTotal,
		showOneTimeGrossProfit: EnableOneTimeGrossProfit,
		showRecurring: EnableRecurring,
		showRecurringGrossProfit: EnableRecurringGrossProfit,
		showTotalQuoteValue: EnableTotalQuoteValue,
		showDownpayment: EnableDownpayment
	};

	const [showQhMetrics, setShowQhMetrics] = useState(intialState);

	const resetMetricsValues = () => {
		setShowQhMetrics((prevState) => ({
			...prevState,
			...intialState
		}));
	};

	const onModalSave = () => updateMetrices(showQhMetrics, setIsOpenModal, dispatch)

	return (
		<>
			<IconButton
				data-testid="quote-header-customization-btn"
				sx={{ marginLeft: "8px", minWidth: "13px", minHeight: "13px", padding: 0 }}
				onClick={() => {
					setIsOpenModal(true);
				}}
			>
				<SettingsOutlinedIcon
					sx={{ fontSize: "16px", color: theme.palette.primary.main }}
				/>
			</IconButton>
			<BasicModal
				isOpen={isOpenModal}
				handleClose={closeModal}
				title="Quote Header Customization"
				modalBody={
					<QuoteHeaderMetricsCheckboxes
						noMetricSelected={noMetricSelected}
						setNoMetricSelected={setNoMetricSelected}
						setShowQhMetrics={setShowQhMetrics}
						showQhMetrics={showQhMetrics}
					/>
				}
				onCancel={closeModal}
				onSave={onModalSave}
				disableSave={noMetricSelected}
			/>
		</>
	);
};
