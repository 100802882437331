/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DensityLargeOutlinedIcon from "@mui/icons-material/DensityLargeOutlined";
import DensityMediumOutlinedIcon from "@mui/icons-material/DensityMediumOutlined";
import DensitySmallOutlinedIcon from "@mui/icons-material/DensitySmallOutlined";

import { useAppDataContext } from "../globalStates/appDataProvider";
import { NoRowsOverlay, StyledTooltip, InStockCell } from "../styledComponents/productSearchTable";
import { StyledDataGrid } from "../styledComponents/dataGrid";
import { LinkStyledButton } from "../styledComponents/common";
import ProductsDataGridToolbar from "./productsDataGridToolbar";
import PriceSourcesModal from "./priceSourcesModal";
import TablePagination from "./tablePagination";

function ProductsDataGrid({
	productsList,
	isLoading,
	productsType,
	apiRef,
	itemsWithPrices,
	productsToFilter,
	handleChangeItemsWithPrices,
	upperElementsHeight = 390
}) {
	const [rowSelectionModel, setRowSelectionModel] = useState([]);
	const [open, setOpen] = useState(false);
	const [modalRow, setModalRow] = useState({});
	const {
		targetTabs,
		selectedProducts,
		addProduct,
		addProducts,
		navigateToPage,
		setProductInfo,
		setProductDetailsTab,
		setUpSellProductsToFilter,
		setGridState,
		updateProduct,
		gridState,
		showNotification
	} = useAppDataContext();

	const handleOpenCart = (gridApiRef) => {
		setGridState(productsType, gridApiRef.current.exportState());
		navigateToPage("PRODUCT_CART_PAGE");
	};

	const handleOpenProductDetails = (product) => {
		setProductDetailsTab("1");
		setUpSellProductsToFilter([]);
		setProductInfo(product);
		navigateToPage("PRODUCT_DETAILS_PAGE");
	};

	const handleAddProducts = () => {
		if (rowSelectionModel.length) {
			const selectedGridProducts = [];
			const countItems = rowSelectionModel.length === 1 ? "item has" : "items have";
			rowSelectionModel.forEach((selectedProduct) => {
				const foundItem = productsList.find((item) => item.id === selectedProduct);
				selectedGridProducts.push(foundItem);
			});
			addProducts(selectedGridProducts);
			showNotification("Successfully added", `The ${countItems} been added to the Bin`);
		}
	};

	const handleAddProduct = (row) => {
		addProduct(row);
		showNotification("Successfully added", "The item has been added to the Bin");
	};

	const columns = useMemo(
		() => [
			{
				field: "mpn",
				headerName: "MPN",
				flex: "auto",
				headerAlign: "center",
				renderCell: ({ value, tabIndex, row }) => {
					const hasImage = row.image;

					return (
						<div>
							{hasImage ? (
								<StyledTooltip
									title={<img src={row.image} alt={value} width={150} />}
									placement="right-start"
								>
									<LinkStyledButton
										onClick={() => handleOpenProductDetails(row)}
										tabIndex={tabIndex}
									>
										{value}
									</LinkStyledButton>
								</StyledTooltip>
							) : (
								<LinkStyledButton
									onClick={() => handleOpenProductDetails(row)}
									tabIndex={tabIndex}
								>
									{value}
								</LinkStyledButton>
							)}
						</div>
					);
				}
			},
			{
				field: "description",
				headerName: "Description",
				flex: 1.3,
				headerAlign: "center"
			},
			{
				field: "price",
				headerName: "Price",
				flex: 0.7,
				headerAlign: "center",
				renderCell: ({ value, row }) =>
					productsType === "productsSearchGrid" && !row.prices.length
						? null
						: app.currentQuote.formatCurrency(value)
			},
			{
				field: "loadPriceList",
				headerName: "Price List",
				flex: 0.8,
				headerAlign: "center",
				renderCell: ({ value, row }) =>
					productsType === "productsSearchGrid" && value ? (
						<Typography sx={{ color: "error.main" }}>Loading...</Typography>
					) : (
						<LinkStyledButton
							variant="text"
							onClick={() => {
								setModalRow(row);
								setOpen(true);
							}}
						>
							Show Price Sources
						</LinkStyledButton>
					)
			},
			{
				field: "source",
				headerName: "Source",
				flex: 0.7,
				headerAlign: "center",
				renderCell: ({ row, value }) =>
					productsType === "productsSearchGrid" && !row.prices.length ? null : value
			},
			{
				field: "manufacturer",
				headerName: "Manufacturer",
				flex: 0.8,
				headerAlign: "center"
			},
			{
				field: "stock",
				headerName: "Stock",
				flex: 0.7,
				headerAlign: "center",
				renderCell: ({ row, value }) =>
					!!row.prices.length && value ? <InStockCell>In Stock</InStockCell> : <Box />
			},
			{
				field: "onHand",
				headerName: "On Hand",
				flex: 0.6,
				headerAlign: "center",
				renderCell: ({ row, value }) =>
					productsType === "productsSearchGrid" && !row.prices.length ? null : value
			},
			{
				field: "actions",
				headerName: "Actions",
				flex: 0.6,
				headerAlign: "center",
				type: "actions",
				getActions: ({ row }) => [
					<GridActionsCellItem
						key={new Date().toISOString()}
						icon={<AddShoppingCartOutlinedIcon sx={{ width: 16, height: 16 }} />}
						label={`Add ${row.mpn} to Bin`}
						sx={{ color: "primary.main" }}
						onClick={() => {
							handleAddProduct(row);
						}}
					/>
				]
			}
		],
		[targetTabs]
	);

	useEffect(() => {
		if (productsToFilter && !!selectedProducts.length) {
			const productsToUpdate = selectedProducts.filter((prod) => prod.loadPriceList);
			productsToUpdate?.map((item) => {
				const updatedProduct = productsToFilter.find((prod) => prod.mpn === item.mpn);

				if (updatedProduct) {
					updateProduct(
						{
							prices: updatedProduct.prices,
							price: updatedProduct.price,
							source: updatedProduct.source,
							sourceId: updatedProduct.sourceId,
							loadPriceList: updatedProduct.loadPriceList
						},
						item.id,
						item.source
					);
				}
			});
		}
	}, [productsToFilter, selectedProducts, updateProduct]);

	return (
		<>
			<Box
				sx={{
					height: `calc(100vh - ${upperElementsHeight}px)`,
					width: "100%",
					overflow: "hidden"
				}}
			>
				{isLoading ? (
					<Stack
						spacing={2}
						justifyContent="center"
						alignItems="center"
						direction="row"
						py={3}
					>
						<CircularProgress size={30} />
						<Typography textAlign="center" fontSize={16}>
							Loading...
						</Typography>
					</Stack>
				) : (
					<StyledDataGrid
						rows={productsList}
						columns={columns}
						checkboxSelection
						onRowSelectionModelChange={(newRowSelectionModel) => {
							setRowSelectionModel(newRowSelectionModel);
						}}
						rowSelectionModel={rowSelectionModel}
						disableRowSelectionOnClick
						disableColumnMenu
						hideFooterSelectedRowCount
						pagination={!!productsToFilter || !!productsList.length}
						initialState={gridState[productsType]}
						pageSizeOptions={[30, 50, 100]}
						slots={{
							noRowsOverlay: NoRowsOverlay,
							toolbar: ProductsDataGridToolbar,
							pagination: TablePagination,
							openFilterButtonIcon: FilterAltOutlinedIcon,
							densityStandardIcon: DensityMediumOutlinedIcon,
							densityComfortableIcon: DensityLargeOutlinedIcon,
							densityCompactIcon: DensitySmallOutlinedIcon
						}}
						slotProps={{
							toolbar: {
								handleAddProducts,
								handleOpenCart,
								productsType
							},
							pagination: {
								itemsWithPrices,
								handleChangeItemsWithPrices,
								productsToFilter
							}
						}}
						apiRef={apiRef}
					/>
				)}
			</Box>

			<PriceSourcesModal
				open={open}
				onClose={() => setOpen(false)}
				type="grid"
				row={modalRow}
			/>
		</>
	);
}

export default ProductsDataGrid;
