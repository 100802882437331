/* eslint-disable import/prefer-default-export */
import { styled } from "@mui/material/styles";
import { Tab } from "@mui/material";
import TabList from "@mui/lab/TabList";

export const StyledTabList = styled(TabList)(({ theme }) => ({
	borderBottom: 2,
	borderColor: theme.palette.mode === "dark" ? theme.palette.grey[300] : "#F1F1F1"
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
	textTransform: "none",
	color: theme.palette.text.primary,
	fontWeight: 400,
	"&.Mui-selected": {
		fontWeight: 600
	},
	"&.Mui-disabled": {
		opacity: 0.5
	}
}));
