import {OpportunitySearchbox} from "js/jsx/src/classes/crm/opportunitySearchbox.jsx";
import {OpportunitySearchTable} from "js/jsx/src/classes/crm/opportunitySearchTable.jsx";

export class NetSuiteOpportunitySearch  extends React.Component {
    constructor(props) {
        super(props);

        this.headerNames = [
           {name:"", propName:"", width:50, url: true}, 
           {name:"Title", propName:"Title", width:140}, 
           {name:"Account", propName:"CompanyName", width:100},
           {name:"Status", propName:"StatusName", width:100}, 
           {name:"Amount", propName: "ProjectedTotal", width:90, formatMoney: true}, 
           {name:"Cost", propName:"EstimatedCost", width:90, formatMoney: true},
        ]

        this.state = {
            criteria: this.getSavedSearchCriteria(),
            isSearching: false,
        };

        // This binding is necessary to make `this` work in the callback
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.submitClick = this.submitClick.bind(this);
        this.formatReturnedOpportunities = this.formatReturnedOpportunities.bind(this);
        this.getStatusReasons = this.getStatusReasons.bind(this);
        this.saveSearchCriteria = this.saveSearchCriteria.bind(this);
        this.resetSearchCriteria = this.resetSearchCriteria.bind(this);
        this.getSavedSearchCriteria = this.getSavedSearchCriteria.bind(this);
        this.getSearchCriteria = this.getSearchCriteria.bind(this);
    }
    componentDidMount(){
        document.addEventListener("keypress",  this.handleKeyPress)
    }
    componentWillUnmount(){
        document.removeEventListener("keypress",  this.handleKeyPress)
    }
    handleKeyPress (e){
        if(e.keyCode === 13)
        {
           this.submitClick();
        }
    }
    inputChanged(fieldName){
        var value = $("#"+fieldName).val();
        this.state.criteria[fieldName] = value;
        this.forceUpdate();
    }
    submitClick(){
        this.saveSearchCriteria();
        var me = this;
        var netsuiteGetOpportunites = quosal.api.crm.netsuite.getOpportunities(this.state.criteria, 0, 100)
        var onError = function(msg) {
            Dialog.open({
                message: msg.error,
                links: [{
                    title: 'OK',
                    callback: Dialog.close
                }]
            });
            me.setState({isSearching: false})
        
        };
        netsuiteGetOpportunites.error = onError;
        netsuiteGetOpportunites.finished = function (msg) {
            var opportunities = this.formatReturnedOpportunities(msg.data.opportunities);
            this.setState({opportunities:opportunities, isSearching: false});
        }.bind(this);
        netsuiteGetOpportunites.call();
        this.setState({isSearching: true})
    
    }  
    formatReturnedOpportunities(opportunities){
        var me = this;
        if(opportunities){
            return opportunities.map(function(opportunity){
                var parsedOpp = JSON.parse(opportunity);
                for (var key in me.statuses) {
                    if(me.statuses[key] == parsedOpp.StatusCode){
                        parsedOpp.Status = key;
                        break;
                    }
                }
                return JSON.stringify(parsedOpp)
            })
        }
    }
    getStatusReasons(){
        var optionsList = [<option value="" key={"oppstatus_all"}>ALL</option>]
        this.statuses = JSON.parse(this.props.data.statuses);
        for (var key in this.statuses) {
            optionsList.push( <option value={key} key={"oppstatus_" + key}>{this.statuses[key]}</option>)
           
        }
        return optionsList;
    }
    saveSearchCriteria(){
       $.cookie('c_netsuiteopportunitysearch_title', this.state.criteria.opportunitytitle, { expires: 14, path: "/" });
       $.cookie('c_netsuiteopportunitysearch_status', this.state.criteria.opportunitystatus, { expires: 14, path: "/" });
       $.cookie('c_netsuiteopportunitysearch_accountname', this.state.criteria.accountname, { expires: 14, path: "/" });
       $.cookie('c_netsuiteopportunitysearch_firstname', this.state.criteria.firstname, { expires: 14, path: "/" });
       $.cookie('c_netsuiteopportunitysearch_lastname', this.state.criteria.lastname, { expires: 14, path: "/" });
       $.cookie('c_netsuiteopportunitysearch_repfirstname', this.state.criteria.repfirstname, { expires: 14, path: "/" });
       $.cookie('c_netsuiteopportunitysearch_replastname', this.state.criteria.replastname, { expires: 14, path: "/" });
    }
    resetCookies(){
        $.cookie('c_netsuiteopportunitysearch_title', "", { expires: 14, path: "/" });
        $.cookie('c_netsuiteopportunitysearch_ status',"", { expires: 14, path: "/" });
        $.cookie('c_netsuiteopportunitysearch_accountname', "", { expires: 14, path: "/" });
        $.cookie('c_netsuiteopportunitysearch_firstname', "", { expires: 14, path: "/" });
        $.cookie('c_netsuiteopportunitysearch_lastname', "", { expires: 14, path: "/" });
        $.cookie('c_netsuiteopportunitysearch_repfirstname', "", { expires: 14, path: "/" });
        $.cookie('c_netsuiteopportunitysearch_replastname', "", { expires: 14, path: "/" });
    }
    resetSearchCriteria(){
        this.resetCookies();
        this.setState({criteria: this.getSavedSearchCriteria(), opportunities: null})
    }
    getSavedSearchCriteria(){
       var cookie = {}
        try{
            cookie.opportunitytitle = quosal.util.cookie('c_netsuiteopportunitysearch_title');
            cookie.opportunitystatus = quosal.util.cookie('c_netsuiteopportunitysearch_status');
            cookie.accountname = quosal.util.cookie('c_netsuiteopportunitysearch_accountname');
            cookie.firstname = quosal.util.cookie('c_netsuiteopportunitysearch_firstname');
            cookie.lastname = quosal.util.cookie('c_netsuiteopportunitysearch_lastname');
            cookie.repfirstname = quosal.util.cookie('c_netsuiteopportunitysearch_repfirstname');
            cookie.replastname = quosal.util.cookie('c_netsuiteopportunitysearch_replastname');
        }
      catch(e){
        this.resetCookies();
        cookie.opportunitytitle = "";
        cookie.opportunitystatus ="";
        cookie.accountname = "";
        cookie.firstname = "";
        cookie.lastname = "";
        cookie.repfirstname = "";
        cookie.replastname = "";
      }

       return cookie;
    }

    getSearchCriteria(){
        return [
            {fieldName: "opportunitytitle", displayName:"Opportunity Title", fieldType:"input", value: this.state.criteria["opportunitytitle"]},
            {fieldName: "opportunitystatus", displayName:"Status", fieldType:"select", value: this.state.criteria["opportunitystatus"], options: this.getStatusReasons()},
            {fieldName: "accountname", displayName:"Company Name", fieldType:"input", value: this.state.criteria["accountname"]},
            {fieldName: "firstname", displayName:"Contact First Name", fieldType:"input", value: this.state.criteria["firstname"]},
            {fieldName: "lastname", displayName:"Contact Last Name", fieldType:"input", value: this.state.criteria["lastname"]},
            {fieldName: "repfirstname", displayName:"Sales Rep First Name", fieldType:"input", value: this.state.criteria["repfirstname"]},
            {fieldName: "replastname", displayName:"Sales Rep Last Name", fieldType:"input", value: this.state.criteria["replastname"]},
        ]
    }
    render(){
        var spinnerArgs = {
            lines: 12,
            length: 12,
            width: 6,
            radius: 20
        };
        
        return(
            <div id="NSOpportunitySearchId">
                <OpportunitySearchbox searchCriteria={this.getSearchCriteria()} resetClick={this.resetSearchCriteria} submitClick={this.submitClick} inputChanged={this.inputChanged} />
                <OpportunitySearchTable isSearching={this.state.isSearching} opportunities={this.state.opportunities} headerNames={this.headerNames} />
            </div>
        )
    }
}