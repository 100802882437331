export class QuickNote extends React.Component {
    constructor(props) {
        super(props);
        //this.getSelectedTab = this.getSelectedTab.bind(this);
    }

    componentDidMount() {
        
    }
    componentWillUnmount(){
        if (this.props.timerContainer && this.props.timerContainer.autoClose.timer) {
            window.clearTimeout(this.props.timerContainer.autoClose.timer);
            this.props.timerContainer.autoClose.timer = undefined;
        }
    }

    mainDivStyle(){
        let style={
            display: 'block',
            fill: '#60b006',
           // width: '100px',
            height: '18px',
            fontSize: '11px',
            fontWeight: 'bold',
            padding: '4px',
            //backgroundColor: '#ecfcd6',
            borderColor: '#60b006',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '4px',
            marginTop: '3px'
        }
        return style;
    }

    startCounter() {
        if (!this.props.timerContainer) {
            return;
        }
        let ac = this.props.timerContainer.autoClose;
        ac.percentDone = 0;
        if (ac.timer) {
            window.clearTimeout(ac.timer);
        }

        ac.timer = window.setInterval(function() {
            let elapsed = Date.now() - ac.start;
            if (elapsed > ac.timeLimit) {
                window.clearTimeout(ac.timer);
                ac.timer = undefined;

                if (this.props.onClose) {
                    this.props.onClose();
                }
            }
            else {
                ac.percentDone = (elapsed * 100) / ac.timeLimit;
                this.forceUpdate();
            }
        }.bind(this), 250
        );
    }

    render() {
        if (this.props.timerContainer && this.props.timerContainer.autoClose && !this.props.timerContainer.autoClose.timer) {
            this.startCounter();
        }
        let radius = 0;
        let fadecolor = "#ecfcd6";
        if (this.props.timerContainer && this.props.timerContainer.autoClose) {
            let v = this.props.timerContainer.autoClose.percentDone;
            radius = 9 * (1.0 - (v / 100.00));
            if (radius < 0.00) {
                radius = 0;
            }
            fadecolor = this.findColorBetween("#40e006","#ecfcd6",this.props.timerContainer.autoClose.percentDone);
        }
        
        return (
            <div style={this.mainDivStyle()}>
                <div style={{float:'left', marginTop:'1px', height:'20px', verticalAlign:'middle', marginLeft: '4px', marginRight: '4px'}}>
                    <img src="img/svgs/v1.0/Status_Success.svg" height='18px' width='18px' />
                </div>
                <div style={{float:'left', marginTop:'2px', height:'22px', verticalAlign:'middle'}}>
                    {this.props.message}
                </div>
                
                <div style={{float:'right', marginTop:'2px', height: '10px', width: '10px', marginRight: '10px'}}>
                    <div className="toolbutton" title='Close notification' onClick={this.props.onClose} >
                        <img src="img/svgs/v1.0/Action_Close.svg" />
                    </div>
                </div>
                {(radius > 0) ? <div style={{float:'right', marginTop:'0px'}}>
                    <svg width='18' height='18'>
                        <circle cx={9} cy={9} r={radius} fill="#ecfcd6" />
                    </svg>
                </div> : null}
            </div>
        );
    }

    hex2Rgb(h) {
        let match = h.replace(/#/,'').match(/.{1,2}/g);
        return {
            r: parseInt(match[0], 16),
            g: parseInt(match[1], 16),
            b: parseInt(match[2], 16)
        };
    }

    findColorBetween(left, right, percentage) {
        let c1 = this.hex2Rgb(left);
        let c2 = this.hex2Rgb(right);
        let c = {};
        c.r = Math.round(c1.r + (c2.r - c1.r) * percentage / 100);
        c.g = Math.round(c1.g + (c2.g - c1.g) * percentage / 100);
        c.b = Math.round(c1.b + (c2.b - c1.b) * percentage / 100);
        return '#' + ('0'+c.r.toString(16)).substr(-2) + ('0'+c.g.toString(16)).substr(-2) + ('0'+c.b.toString(16)).substr(-2);
    }

    /*renderExample() {
        return (
            <div data-cwid="note1" class="CwNotification-wrap etilizenotify info CwNotification-successful">
                <div class="CwFlexbox-wrap CwFlexbox-verticalAlign">
                    <div>
                        <div class="isvg loaded CwNotification-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48" focusable="false" viewBox="0 0 48 48">
                                <g>
                                    <path d="M0 0h48v48H0z" fill="none" />
                                    <path d="M6.6 23.6c.8-.8 2-.8 2.8 0l8.6 8.6 20.6-20.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-22 22c-.4.4-.9.6-1.4.6s-1-.2-1.4-.6l-10-10c-.8-.8-.8-2 0-2.8z" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="CwNotification-text">Added some kind of comparison.  1</div>
                    <div>
                        <div class="isvg loaded CwNotification-icon CwImage-cursor">
                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48" focusable="false" viewBox="0 0 48 48">
                                <g>
                                    <path d="M0 0h48v48H0z" fill="none" />
                                    <path d="M8.6 37.1L21.2 24 8.6 10.9c-.8-.8-.7-2.1 0-2.8.8-.8 2.1-.7 2.8 0l12.6 13 12.6-13c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8L26.8 24l12.7 13.1c.8.8.7 2.1 0 2.8-.4.4-.9.6-1.4.6-.5 0-1-.2-1.4-.6L24 26.9l-12.6 13c-.4.4-.9.6-1.4.6-.5 0-1-.2-1.4-.6-.8-.7-.8-2 0-2.8z" />
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        );
    }*/
}

