import { useEffect } from "react";
import { Box, Button, useTheme } from "@mui/material";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import AddIcon from "@mui/icons-material/Add";
import { AddSectionButtonStyled } from "../../styledComponents/createFormStyled";

export const FormHeaderActions = ({ formActions, expandAllSections, handleExpandCollapseAll, expandSections,setExpandAllSections }) => {
	const theme = useTheme();
	const areAllSectionsExpanded = expandSections.map((section) => section.isExpanded).every((isExpanded) => isExpanded);

	useEffect(() => {
		setExpandAllSections(areAllSectionsExpanded);
	},[areAllSectionsExpanded])

	return (
		<Box>
			<Button
				variant="contained"
				data-testid="expand-collapse-all-btn"
				onClick={handleExpandCollapseAll}
				sx={{
					width: "134px",
					height: "32px",
					backgroundColor: theme.palette.background.ghost,
					borderRadius: "6px",
					color: theme.palette.text.main,
					boxShadow: "none",
					textTransform: "none",
					fontWeight: 600,
					marginRight: "10px",
					"&:hover": {
						backgroundColor: theme.palette.background.ghost,
						boxShadow: "none"
					}
				}}
			>
				{expandAllSections  ? (
					<>
						Collapse all
						<UnfoldLessIcon sx={{ marginLeft: "8px", fontSize: "16px" }} />
					</>
				) : (
					<>
						Expand all
						<UnfoldMoreIcon sx={{ marginLeft: "8px", fontSize: "16px" }} />
					</>
				)}
			</Button>
			<AddSectionButtonStyled
				id={`createform-add-section-button`}
				endIcon={<AddIcon sx={{ fontSize: "16px" }} />}
				onClick={() => formActions["add-section"]()}
			>
				Add a Section
			</AddSectionButtonStyled>
		</Box>
	);
};
