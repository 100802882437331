//gear style config dropdown menu, generally used for quick nav to config/admin pages
export class ConfigMenu extends React.Component {
    render() {
        return (
            <div className="toolbutton configbtn" menu="quotecontentconfig">
                <img src="img/svgs/v1.0/Menu_System.svg" />
            </div>
        );
    }
}


//lightning bolt style action menu, generally used for tab and grid actions
export class ActionsMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            expanded: false,
        };
         
        this.menuItemMap = {};

        // This binding is necessary to make `this` work in the callback
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.closeAllSubmenus = this.closeAllSubmenus.bind(this);
    }
    //TODO: Bug when switching tabs and opening another context menu while the active one is hidden
    static hideMenuOnClick(e) {
        if(ActionsMenu.activeContextMenu != null){
            if(e.target) {
                var menu = $(e.target);
                if(!menu.hasClass('menuitem'))
                    menu = $(e.target).parents('.menuitem').first();

                if(menu.hasClass('menuitem')) {
                    var action = ActionsMenu.activeContextMenu.menuItemMap[menu[0].attributes.name.value];

                    if(action && (action.keepMenuOpen || action.submenu)) {
                        ActionsMenu.activeContextMenu.closeAllSubmenus();

                        if ('function' === typeof ActionsMenu.activeContextMenu.props.checkActionDisableCodes) {
                            window.setTimeout(function () {
                                this.setState({
                                    actionDisableCodes: this.props.checkActionDisableCodes()
                                });
                            }.bind(ActionsMenu.activeContextMenu), 1);
                        }

                        return;
                    }
                }
            }
            document.removeEventListener("mouseup", ActionsMenu.hideMenuOnClick);

            if(ActionsMenu.activeContextMenu.componentIsMounted) {
                ActionsMenu.activeContextMenu.closeAllSubmenus();
                ActionsMenu.activeContextMenu.setState({ expanded: false });
            }

            ActionsMenu.previousContextMenu = ActionsMenu.activeContextMenu;
            ActionsMenu.activeContextMenu = null;

            setTimeout(function(){ActionsMenu.previousContextMenu = null;}, 10);
        }
    }
    UNSAFE_componentWillMount() {
        if (this.props.actions) {
            for (var i = 0; i < this.props.actions.length; i++) {
                //this.props.actions[i].title = quosal.util.generateGuid();
                this.menuItemMap[this.props.actions[i].title] = this.props.actions[i];
            }
        }
    }
    componentDidMount() {
        this.componentIsMounted = true;
        if (this.props.componentDidMount) {
            this.props.componentDidMount.call(this);
        }
    }
    UNSAFE_componentWillUnmount() {
        this.componentIsMounted = false;
        if (this.props.componentWillUnmount) {
            this.props.componentWillUnmount.call(this);
        }
    }
    toggleDropdown(params) {
        if(!params) params = {};
        if(ActionsMenu.previousContextMenu == this){
            ActionsMenu.previousContextMenu = null;
            return;
        }

        if(!this.props.actions || this.props.actions.length == 0)
        {
            // Don't expand empty dropdown
            return;
        }

        let expand = !this.state.expanded;
        let newState = {
            expanded: expand,
            proxyButton: params.proxyButton || null
        }

        if (expand && ('function' === typeof this.props.checkActionDisableCodes)) {
            newState.actionDisableCodes = this.props.checkActionDisableCodes();
        }

        this.setState(newState);

        if(expand) {
            this.closeAllSubmenus();

            ActionsMenu.activeContextMenu = this;

            document.addEventListener('mouseup', ActionsMenu.hideMenuOnClick);
        } else
            ActionsMenu.activeContextMenu = null;
    }
    closeAllSubmenus() {
        if (this.submenuCloseHandlers
            && this.submenuCloseHandlers.length) {
            for (var i = 0; i < this.submenuCloseHandlers.length; i++){
                this.submenuCloseHandlers[i]();
            }
        }
    }
    render() {
        //TODO: the slide-down transition effect stopped working since changing the dropdown to always be present (to persist menu item state) could revisit animating it in the future

        var dropdownStyle = {};
        if (this.state.proxyButton) {
            dropdownStyle.position = 'fixed';
            var proxyButton = $(this.state.proxyButton);
            var proxyButtonOffset = proxyButton.offset();
            dropdownStyle.top = proxyButtonOffset.top - window.pageYOffset;
            dropdownStyle.left = proxyButtonOffset.left + proxyButton.outerWidth(false);
            dropdownStyle.margin = 0;
        }
        var menuHangsFromTab = this.props.name == 'tabupdate' && !this.state.proxyButton;

        var className = 'menu-container';
        if(this.props.className){
            className += ' ' + this.props.className
        }
        if (this.props.iconHasBackground) {
            className += ' iconHasBackground';
        }

        var button = this.props.cwMenu == "true" ?  <CwWidgets.CwButton value={this.props.value} toolTip={this.props.title} onClick={this.toggleDropdown} icon={this.props.icon} rightIcon={this.props.rightIcon || 'img/svgs/v1.0/Action_Expand_Light.svg'} /> :
                        <MenuButton key="btn" onClick={this.toggleDropdown} title={this.props.title || 'Actions'} icon={this.props.icon} protected={this.props.protected} menuName={this.props.name} />;

         return (
            <div id={this.props.menuName} className={className} style={this.props.style}>
                {button}
                <MenuDropdown key={this.props.name + '_dropdown'} actions={this.props.actions} menuHangsFromTab={menuHangsFromTab}
                              style={dropdownStyle} expanded={this.state.expanded} onClick={this.props.onClick}
                              menu={this}
                              actionRenames={this.props.actionRenames}
                              actionDisableCodes={this.state.actionDisableCodes} />
            </div>
        );
    }
}

ActionsMenu.activeContextMenu= null;
ActionsMenu.previousContextMenu= null;

class MenuButton extends React.Component {
    render() {
        var style = {};
        if(this.props.icon) {
            style.backgroundImage = 'url(' + this.props.icon + ')';
            style.opacity = 1;
        }

        var className = (this.props.menuName || 'context') + '-actions actionMenuButton';

        return (
            <div title={(this.props.protected ? 'Protected ' : '' ) + this.props.title} style={style} className={className} id={this.props.menuName + '_btn'} onClick={this.props.onClick}>
                <img key="actionsimg" className={this.props.protected ? 'protected' : 'action'} />
            </div>
        );
    }
}


class MenuDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidUpdate(oldProps, oldState) {
        if (this.props.expanded && !oldProps.expanded) {
            var hangFromRight = false;
            var dropdown = $(this.refs.root);
            var dropdownWidth = dropdown.outerWidth(false);
            if (this.props.menuHangsFromTab) {
                var offsetParentWidth = dropdown.offsetParent().outerWidth(false);
                if (offsetParentWidth > dropdownWidth) {
                    hangFromRight = true;
                }
            }
            if (!hangFromRight) {
                var screenWidth = $(window).width();
                var rightEdge = dropdownWidth + dropdown.offset().left;
                if (rightEdge > screenWidth + window.pageXOffset) {
                    hangFromRight = true;
                }
            }
            if (hangFromRight) {
                this.setState({hangFromRight: true});
            }

            var dropdownHeight = dropdown.outerHeight(false);
            var bottomEdgeOfWindow = $(window).height() + window.pageYOffset;
            var bottomEdgeOfDropdown = dropdownHeight + dropdown.offset().top;
            var difference = bottomEdgeOfDropdown - bottomEdgeOfWindow
            if (difference > 0) {
                this.setState({avoidHangingOffBottomByThisMuch: difference});
            }
        }
        if (!this.props.expanded && oldProps.expanded) {
            this.setState({hangFromRight: false, avoidHangingOffBottomByThisMuch: false});
        }
    }
    render() {
        var menuItems = [];

        if(this.props.actions) {
            for (var i = 0; i < this.props.actions.length; i++) {
                if(this.props.actions[i].visible == false)
                    continue;
                menuItems.push(<MenuItem key={this.props.name + '_menuitem_' + i} action={this.props.actions[i]} dropdown={this}
                                         menu={this.props.menu} onClick={this.props.onClick}
                                         actionRenames={this.props.actionRenames}
                                         actionDisableCodes={this.props.actionDisableCodes}
                />);
            }
        }


        var style = this.props.style ? jQuery.extend({}, this.props.style) : {}; // Cloning the style object because React complained: "Warning: `div` was passed a style object that has previously been mutated."
        style.display = this.props.expanded ? 'inherit' : 'none';
        if (this.props.expanded) {
            if (this.state.hangFromRight) {
                style.right = 0;
                style.left = 'initial';
            }
            if (this.state.avoidHangingOffBottomByThisMuch) {
                style.marginTop = -this.state.avoidHangingOffBottomByThisMuch;
            }
        }

        return (
            <div ref="root" key={this.props.name + '_menu'} className={(this.props.menuName || 'context') + '-menu'} style={style}>
                {menuItems}
            </div>
        );
    }
}


class MenuItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        
        this.toggleState = 0;
        if (this.props.action.toggleStates)
        {
            this.state = {
                toggleState: 0
            };
        }

        // This binding is necessary to make `this` work in the callback
        this.handleAction = this.handleAction.bind(this);
    }

    handleAction(action) {
        if (this.props.action.isSaving)
            return;

        if (this.props.action.toggleStates) {
            var toggleState = this.state.toggleState + 1;

            if(toggleState == this.props.action.toggleStates.length)
                toggleState = 0;

            this.setState({toggleState: toggleState});
        }

        if (action.submenu) {
            this.setState({
                submenuExpanded: true
            });
        } else if (this.props.onClick) {
            this.props.onClick(this.props.menu, action);
        } else if (action.callback) {
            action.callback(this.props.menu, action);
        } else if (action.url) {
            var state = { rand: quosal.util.generateGuid() };

            if (action.target)
                state.target = action.target;

            if (action.bypassClientNav)
                state.bypassClientNav = action.bypassClientNav;

            quosal.navigation.navigate(action.url, state);
        }
    }
    registerSubmenu () {
        if (this.props.menu) {
            this.props.menu.submenuCloseHandlers = this.props.menu.submenuCloseHandlers || [];
            this.props.menu.submenuCloseHandlers.push(function () {
                this.setState({
                    submenuExpanded: false
                });
            }.bind(this));
        }
    }
    render() {
        var action = this.props.action.toggleStates ? this.props.action.toggleStates[this.state.toggleState] : this.props.action;
        var actionHandler = this.handleAction.bind(this, action);
        var icon;
        var extraClass = '';

        let title = action.title;
        if (this.props.actionRenames && (title in this.props.actionRenames)) {
            title = this.props.actionRenames[action.title];
        }
        let disabled = action.disabled
        if (action.actionDisableCodes && this.props.actionDisableCodes) {
            for (let disableCodeIndex = 0; disableCodeIndex < action.actionDisableCodes.length; disableCodeIndex++) {
                if (this.props.actionDisableCodes[action.actionDisableCodes[disableCodeIndex]]) {
                    disabled = true;
                }
            }
        }

        if (disabled == true) {
            actionHandler = null;
            extraClass = ' disabled';
        }

        if (action.isSaving || action.spinner) {
            var spinnerArgs = {
                lines: 10,
                length: 3,
                radius: 1,
            };
            if(action.isSaving && action.spinnerArgs){
                action.spinnerArgs.color = '#8DEC8A';
            } 
                

            icon = <Spinner args={spinnerArgs} />;
        } else {
            icon = <img src={action.icon} />;
        }

        var rightSideIcon = '';
        if (action.submenu) {
            rightSideIcon = (<img src="img/svgs/v1.0/Action_Forward.svg" className="menuitem-arrow"
                                 style={{float:'right', height:15, margin:'0 -2px 0 0', padding:0}} />);
        }

        return (
            <div name={title} key={title} onClick={actionHandler} className={'menuitem ' + (action.className || '') + extraClass}>
                {icon}
                <span>{title}</span>{rightSideIcon}
                {action.submenu && <ActionSubmenu submenuActions={action.submenu}
                                                  expanded={this.state.submenuExpanded}
                                                  menu={this.props.menu}
                                                  onClick={this.props.onClick}
                                                  registerSubmenu={this.registerSubmenu.bind(this)}
                                                  actionRenames={this.props.actionRenames}
                                                  actionDisableCodes={this.props.actionDisableCodes}
                />}
            </div>
        );
    }
}

class ActionSubmenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            top: 0,
            left: 0
        };
    }
    componentDidMount() {
        this.props.registerSubmenu();
    }
    componentDidUpdate(prevProps) {
        if (this.props.expanded && !prevProps.expanded) {
            var parentMenuItem = $(this.refs.submenu.refs.root).parent();
            this.setState({
                top: parentMenuItem.position().top,
                left: parentMenuItem.outerWidth()
            });
        }
    }
    render() {
        var style = {
            top: this.state.top || 0,
            left: this.state.left || 0
        };
        return (
            <MenuDropdown ref="submenu"
                          actions={this.props.submenuActions}
                          style={style}
                          menu={this.props.menu}
                          onClick={this.props.onClick}
                          expanded={this.props.expanded}
                          actionRenames={this.props.actionRenames}
                          actionDisableCodes={this.props.actionDisableCodes} />
            );
    }
}