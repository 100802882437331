import React from "react";
import {
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	useGridApiContext
} from "@mui/x-data-grid-pro";
import { Button, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { StyledButton } from "../styledComponents/common";

function ProductsDataGridToolbar({ handleAddProducts, handleOpenCart, productsType }) {
	const apiRef = useGridApiContext();
	const theme = useTheme();

	const toolbarButtonStyle = {
		py: "8px",
		pl: "13px",
		fontSize: 14,
		fontWeight: 600,
		color: "#1E1E1E",
		backgroundColor: theme.palette.mode === "dark" ? "#acacae" : "#F6F6F9",
		"&:hover": {
			backgroundColor: theme.palette.mode === "dark" ? "#F6F6F9" : "#acacae"
		},
		"& .MuiSvgIcon-root": { pl: 1 }
	};

	return (
		<GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
			<Stack direction="row" spacing={1}>
				<GridToolbarFilterButton
					sx={{
						display: "flex",
						flexDirection: "row-reverse",
						...toolbarButtonStyle
					}}
				/>
				<GridToolbarDensitySelector
					sx={{
						display: "flex",
						flexDirection: "row-reverse",
						...toolbarButtonStyle
					}}
				/>
				<Button onClick={() => handleOpenCart(apiRef)} sx={toolbarButtonStyle}>
					View Bin
					<ShoppingCartOutlinedIcon />
				</Button>
				<StyledButton onClick={handleAddProducts} variant="contained">
					Add to Bin
				</StyledButton>
			</Stack>
		</GridToolbarContainer>
	);
}

export default ProductsDataGridToolbar;
