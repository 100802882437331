import { useFormContext } from "react-hook-form";
import { Box, Stack, Typography } from "@mui/material";
import { useQuoteContext } from "../../context";
import { ComboBox, Loader, TextInputField } from "../../common/components";
import {
	CUSTOMER_FIELD_HELPER_TEXT,
	CUST_DETAILS_NOTE,
	DETAILS_NOT_SAVED_MSG,
	INSIDE_REP_SAVED_MSG,
	PRIMARY_REP_SAVED_MSG,
	QUOSAL_ADMIN_USERNAME,
	QUOTE_DETAILS_HEADING
} from "../../common/constant";
import { payloadGenerator, updateData } from "../../services";
import { useActiveUsers } from "../../customHooks";
import { StyledOption } from "../../common/styledComponents";

export const EditCustomerDetailsForm = ({ toast, setToast }) => {
	const BoxBottomMargin = {
		marginBottom: "16px"
	};
	const { activeUsers, loading } = useActiveUsers();
	const { MainCust, CustomersByType, IdQuoteMain, Owner, InsideRep } = useQuoteContext();
	const isCustomerAdded = MainCust?.AccountName !== "";
	const methods = useFormContext();
	const activeUsersWithoutQuosalAdmin = activeUsers.filter(
		(user) => user.UserName !== QUOSAL_ADMIN_USERNAME
	);
	const primaryRepOptions =
		Owner?.UserName === QUOSAL_ADMIN_USERNAME ? activeUsers : activeUsersWithoutQuosalAdmin;
	const insideRepOptions =
		InsideRep?.UserName === QUOSAL_ADMIN_USERNAME ? activeUsers : activeUsersWithoutQuosalAdmin;
	const isValidField = (fieldName) => {
		const fieldState = methods.getFieldState(fieldName);
		return !fieldState.error;
	};

	const getFieldValue = (fieldName) => {
		const formState = methods.getValues();
		return typeof formState[fieldName] === "string"
			? formState[fieldName].trim()
			: formState[fieldName];
	};

	const getValidRepName = (rep) => {
		if (!rep) {
			return "";
		}
		let repDisplayName = "";
		if (rep.FirstName && rep.LastName) {
			repDisplayName = `${rep.FirstName} ${rep.LastName}`;
		} else if (!rep.FirstName && !rep.LastName) {
			repDisplayName = `${rep.EmailAddress}`;
		} else if (rep.FirstName && !rep.LastName) {
			repDisplayName = `${rep.FirstName} (${rep.EmailAddress})`;
		} else if (!rep.FirstName && rep.LastName) {
			repDisplayName = `${rep.LastName} (${rep.EmailAddress})`;
		}
		return repDisplayName;
	};

	const updateCustomerDetails = (fieldName, formValue) => {
		const idDetails = {
			name: "IdQuoteCustomers",
			value: CustomersByType?.QuoteTo?.IdQuoteCustomers
		};

		updateData(payloadGenerator(idDetails, "QuoteCustomers", formValue)).then(async (res) => {
			const { response0 } = res;
			const { error } = response0;
			if (error) {
				console.error("Error:", error);
				toast && setToast(null);
				setToast({ type: "error", message: DETAILS_NOT_SAVED_MSG });
				return;
			}
			quosal.sell.quote.updateFromApiResponse(response0);
			toast && setToast(null);
			setToast({ type: "success", message: `${fieldName} saved successfully.` });
		});
	};

	const updatePrimaryRep = (primaryRep) => {
		const idDetails = {
			name: "IdQuoteMain",
			value: IdQuoteMain
		};
		updateData(
			payloadGenerator(idDetails, "QuoteMain", {
				Owner: primaryRep?.id
			})
		).then(async (res) => {
			const { response0 } = res;
			const { error } = response0;
			if (error) {
				console.error("Error:", error);
				toast && setToast(null);
				setToast({ type: "error", message: DETAILS_NOT_SAVED_MSG });
				return;
			}
			quosal.sell.quote.updateFromApiResponse(response0);
			toast && setToast(null);
			setToast({ type: "success", message: PRIMARY_REP_SAVED_MSG });
		});
	};

	const updateInsideRep = (insideRep) => {
		const idDetails = {
			name: "IdQuoteMain",
			value: IdQuoteMain
		};
		updateData(
			payloadGenerator(idDetails, "QuoteMain", {
				InsideRep: insideRep?.id
			})
		).then(async (res) => {
			const { response0 } = res;
			const { error } = response0;
			if (error) {
				console.error("Error:", error);
				toast && setToast(null);
				setToast({ type: "error", message: DETAILS_NOT_SAVED_MSG });
				return;
			}
			quosal.sell.quote.updateFromApiResponse(response0);
			toast && setToast(null);
			setToast({ type: "success", message: INSIDE_REP_SAVED_MSG });
		});
	};

	const handlePhoneBlur = () => {
		if (isValidField("phone") && getFieldValue("phone") !== MainCust?.DayPhone) {
			updateCustomerDetails("Phone", { DayPhone: getFieldValue("phone") });
		}
	};

	const handleContactBlur = () => {
		if (
			isValidField("fullName") &&
			getFieldValue("fullName") !== `${MainCust?.FirstName} ${MainCust?.LastName}`
		) {
			const contact = getFieldValue("fullName")
				.trim()
				.split(/\s+(.+)/, 2);
			updateCustomerDetails("Contact", { FirstName: contact[0], LastName: contact[1] });
		}
	};

	const handleEmailBlur = () => {
		if (isValidField("email") && getFieldValue("email") !== MainCust?.EmailAddress) {
			updateCustomerDetails("Email", { EmailAddress: getFieldValue("email") });
		}
	};

	const handlePrimaryRepBlur = () => {
		const primaryRepVal = getFieldValue("primaryRep");
		if (primaryRepVal && primaryRepVal?.DisplayName !== Owner?.DisplayName) {
			updatePrimaryRep(primaryRepVal);
		}
	};

	const handleInsideRepBlur = () => {
		const insideRepVal = getFieldValue("insideRep");
		if (insideRepVal && insideRepVal?.DisplayName !== InsideRep?.DisplayName) {
			{
				updateInsideRep(insideRepVal);
			}
		}
	};

	return (
		<>
			<Box sx={{ marginBottom: "20px" }}>
				<Typography sx={{ width: "392px" }}>{CUST_DETAILS_NOTE}</Typography>
			</Box>
			<Box sx={{ ...BoxBottomMargin }}>
				<Stack direction="row" spacing={2}>
					<Box>
						<TextInputField
							id="customer"
							name="customer"
							label="Customer"
							disabled={true}
							helperText={CUSTOMER_FIELD_HELPER_TEXT}
							required={true}
						/>
					</Box>
					<Box>
						<TextInputField
							id="phone"
							name="phone"
							label="Phone"
							onBlur={handlePhoneBlur}
							disabled={!isCustomerAdded}
						/>
					</Box>
				</Stack>
			</Box>
			<Box sx={{ ...BoxBottomMargin }}>
				<Stack direction="row" spacing={2}>
					<Box>
						<TextInputField
							id="fullName"
							name="fullName"
							label="Contact"
							onBlur={handleContactBlur}
							disabled={!isCustomerAdded}
							placeholder="FirstName LastName"
							required={true}
						/>
					</Box>
					<Box>
						<TextInputField
							id="email"
							name="email"
							label="Email"
							onBlur={handleEmailBlur}
							disabled={!isCustomerAdded}
							required={true}
						/>
					</Box>
				</Stack>
			</Box>
			<Box>
				<Typography
					sx={{
						fontSize: "16px",
						fontWeight: 400,
						lineHeight: "22px",
						marginBottom: "20px"
					}}
				>
					{QUOTE_DETAILS_HEADING}
				</Typography>
				<Stack direction="row" spacing={2}>
					<ComboBox
						id="primaryRep"
						name="primaryRep"
						label="Primary Rep"
						options={primaryRepOptions}
						onBlur={handlePrimaryRepBlur}
						renderOption={(props, option) => (
							<StyledOption {...props}>{getValidRepName(option)}</StyledOption>
						)}
						getOptionLabel={(option) => getValidRepName(option)}
						disableClearable={true}
						required={true}
					/>
					<ComboBox
						id="insideRep"
						name="insideRep"
						label="Inside Rep"
						options={insideRepOptions}
						onBlur={handleInsideRepBlur}
						renderOption={(props, option) => (
							<StyledOption {...props}>{getValidRepName(option)}</StyledOption>
						)}
						getOptionLabel={(option) => getValidRepName(option)}
						disableClearable={true}
						required={true}
					/>
				</Stack>
			</Box>
			<Loader
				sx={{ position: "absolute", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				loading={loading}
			/>
		</>
	);
};
