//TODO: implement rtf control as standalone component. Currently it is implemented as:
//1) A custom form widget (see FormFieldInput.render / FormFieldInput.rtfEditorLoaded in forms.js)
//2) A custom grid popup in Prepare Content (see QuoteContentGrid.customizeCell in contentGrid.js)

export class RtfLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            visible: false
        };
        // This binding is necessary to make `this` work in the callback
        this.editorLoaded = this.editorLoaded.bind(this);
    }

    editorLoaded() {
        this.setState({visible:true});
        if(this.props.editorLoadedCallback){
            this.props.editorLoadedCallback();
        }
    }
    render() {
        return (
                <div style={this.props.style}>
                    {this.state.visible ? null : <FormPlaceholder message={this.props.message || 'Loading Editor...'} style={{padding:80}} />}
                    <RtfEditor ref="editor" visible={this.state.visible} onLoad={this.editorLoaded} {...this.props} />
                </div>
        );
    }
}

export class RtfEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            rtfId: quosal.util.generateGuid()
        };
        // This binding is necessary to make `this` work in the callback
        this.getEditorContent = this.getEditorContent.bind(this);
        this.disable = this.disable.bind(this);
        this.enable = this.enable.bind(this);
        this.getHtml = this.getHtml.bind(this);
        this.getText = this.getText.bind(this);
        this.setHtml = this.setHtml.bind(this);
        this.setText = this.setText.bind(this);
        this.editorLoaded = this.editorLoaded.bind(this);
    }
    static getInstance(name) {
            return RtfEditor.instances[name];
        }
    static preload() {
            //This simply ensures that the .aspx page has been hit before being loaded into UI to reduce wait time for AppPool spin up
            $.ajax(quosal.util.url('RtfEditor.aspx'), {
                dataType: 'html',
                success: function(result) {
                    //TODO: cache and reuse singleton?
                }
            });
    }
   
    componentDidMount() {
        if(RtfEditor.instances[this.props.name] != null)
            throw 'Duplicate RtfEditor detected, RtfEditors must have a unique name prop.';

        RtfEditor.instances[this.props.name] = this;

        if(this.props.formField){
            this.state.lastFieldValue = this.props.formField.props.field.Value
        }
    }
    componentWillUnmount() {
        delete RtfEditor.instances[this.props.name];
    }
    getEditorContent() {
        return $(this.refs.input).contents().find('#ASPxHtmlEditor1_DesignIFrame').contents().find('.dxheDesignViewArea_Moderno');
    }
    disable() {
        var content = this.getEditorContent();
        content.removeAttr('contenteditable');
        $(this.refs.input).contents().find('#ASPxHtmlEditor1_TBRow').hide();
        $(this.refs.input).contents().find('#ASPxHtmlEditor1_SBarCell').hide();
    }
    enable() {
        var content = this.getEditorContent();
        content.attr('contenteditable', true);
        $(this.refs.input).contents().find('#ASPxHtmlEditor1_TBRow').show();
        $(this.refs.input).contents().find('#ASPxHtmlEditor1_SBarCell').show();
    }
    getHtml() {
        var container = $(this.refs.input).contents().find('#rtfNoteHtmlContainer')[0];
        var plainText = container ? container.innerHTML : "";

        return plainText;
    }
    getText() {
        return $(this.getEditorContent()).text();
    }
    setHtml(html, suppressChangeEvent) {
        if(this.state.isLoaded) {
            var content = this.getEditorContent();

            if(!html && this.htmlUpdate)
                html = this.htmlUpdate.content;

            if(suppressChangeEvent == null && this.htmlUpdate)
                suppressChangeEvent = this.htmlUpdate.suppressChangeEvent;

            content.html(html);
            // #9421632 SM - Automatically added notes not saving because RTF container is not created
            if($(this.refs.input).contents().find('#rtfNoteHtmlContainer'))
                $(this.refs.input).contents().find('#rtfNoteHtmlContainer')[0].innerHTML = html;

            if(this.htmlUpdate)
                delete this.htmlUpdate;

            if (!suppressChangeEvent && this.props.onChange)
                this.props.onChange();
        } else {
            this.htmlUpdate = {
                content: html,
                suppressChangeEvent: suppressChangeEvent
            };
        }
    }
    setText(text, suppressChangeEvent) {
        $(this.getEditorContent()).text(text);

        if(!suppressChangeEvent && this.props.onChange)
            this.props.onChange();
    }
    editorLoaded() {
        this.state.isLoaded = true;

        if(this.htmlUpdate) {
            this.setHtml();
        }


        if(this.props.onChange || this.props.applyChangedContent) {
            // var content = this.getEditorContent();

            var callback = function() {
                if (this.props.onChange) {
                    this.props.onChange.call(this);
                } else if (this.props.applyChangedContent) {
                    this.props.applyChangedContent(this.getHtml());
                }
            }.bind(this);

            if (this.props.disabled) {
                //this.disable();
                //content.removeAttr('contenteditable');
                //$(this.refs.input).contents().find('#ASPxHtmlEditor1_TBRow').hide();
                //$(this.refs.input).contents().find('#ASPxHtmlEditor1_SBarCell').hide();
            } else {
                // FSP 8/17/16: This element doesn't actually have any organically valid 'change' event--in fact it's a
                // <table> element. But adding this event hook lets us access the callback from ASP.Net's javascript
                // event system for the editor by finding this element and calling .change() on it.
                $(this.refs.input).contents().find('#ASPxHtmlEditor1').change(callback);
            }
            if (this.props.callOnChangeImmediately) {
                callback();
            }
        }


        if(this.props.onLoad)
            this.props.onLoad(this);
    }
    render() {
        if(this.props.formField && this.props.formField.props.field) {

            if(this.state.lastFieldValue !== undefined && this.state.lastFieldValue != this.props.formField.props.field.Value) {
                this.setHtml(this.props.formField.props.field.Value, true);
            }

            this.state.lastFieldValue = this.props.formField.props.field.Value;
        }

        if(this.props.disabled) {
            this.disable();
        } else {
            this.enable();
        }

        var style = this.props.style ? quosal.util.clone(this.props.style) : {width:'100%', minHeight:650};

        // FSP 7/12/16 8135435: I'm leaving the below commented line as documentation of a strange bug and a reminder not to add it back.
        // The editor did not load correctly (just for IE) when this display=none style was used here. The cosmetic consequence
        // of not using this style is not a very big deal--the outline of an empty box appears a little earlier than it would otherwise.
        // if(this.props.visible === false) style.display = 'none';

        var url = this.props.url + '&rtfid=' + this.state.rtfId;

        return (
            <iframe ref="input" style={style} id={this.props.name} name={this.props.name} src={url} frameBorder="0" seamless onLoad={this.editorLoaded}></iframe>
        );
    }
}

RtfEditor.instances= [];