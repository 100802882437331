import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledLabelSm = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.secondary,
	lineHeight: "18px",
    letterSpacing: ".16px",
}));

export { StyledLabelSm };
