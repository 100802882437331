import { Breadcrumbs, Link, Typography } from '@mui/material';

export class CommonBreadCrumbs extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const { breadCrumbs, actualBreadCrumbName } = this.props;

        return (
            <Breadcrumbs aria-label="breadcrumb">
                {
                    breadCrumbs.map((breadCrumb, index) =>
                        <Link id={'breadcrumb-'+index} key={breadCrumb.name + '-breadcrumb-' + index} underline="hover" className="salestrack-breadcrumb-link" onClick={breadCrumb.navigate} href="#">
                            {
                                breadCrumb.name
                            }
                        </Link>
                    )
                }
                <Typography color="text.primary">{actualBreadCrumbName}</Typography>
            </Breadcrumbs>
        )
    }
}