import { Box, Typography, useTheme } from "@mui/material";

export const Header = ({ title }) => {
	const theme = useTheme();
	return (
		<Box display="flex" justifyContent="flex-start" sx={{ my: "3px", pr: "5px" }}>
			<Typography
				sx={{
					fontWeight: "bold",
					fontSize: "12px",
					color: theme.palette.text.input,
					margin: "10px"
				}}
			>
				{title}
			</Typography>
		</Box>
	);
};
