/* eslint-disable import/prefer-default-export */
import { useCallback, useEffect, useState } from "react";
import { fetchData } from "../services/fetchData";

export function useProductDetails() {
	const [productDetails, setProductDetails] = useState({});
	const [wonQuotesOnly, setWonQuotesOnly] = useState(false);
	const [currentAccountOnly, setCurrentAccountOnly] = useState(false);
	const [isHistorySearchStarted, setIsHistorySearchStarted] = useState(false);
	const [history, setHistory] = useState([]);
	const [isLoadingHistory, setIsLoadingHistory] = useState(false);
	const [historyGradeState, setHistoryGradeState] = useState({
		initialState: {
			pagination: { paginationModel: { pageSize: 5, page: 0 } }
		}
	});

	const handleSearchStarted = () => setIsHistorySearchStarted(true);

	const changeHistoryGradeState = (newInitialState) => {
		setHistoryGradeState((prev) => ({
			...prev,
			initialState: newInitialState
		}));
	};

	const setInitialGradeState = () => {
		setHistoryGradeState({
			initialState: {
				pagination: { paginationModel: { pageSize: 5, page: 0 } }
			}
		});
	};

	const handleWonQuoteChange = (value) => {
		setWonQuotesOnly(value);
	};

	const handleCurrentAccountChange = (value) => {
		setCurrentAccountOnly(value);
	};

	const setProductInfo = useCallback((product) => {
		setProductDetails(product);
	}, []);

	const transformData = (data) =>
		data?.map(
			({ count, quoteReadableId, idQuoteMain, version, source, price, rawPrice, cost }) => ({
				id: count,
				quoteId: quoteReadableId,
				idQuoteMain,
				version,
				source,
				cost,
				rawPrice,
				price
			})
		);

	const handleSearchHistory = (searchOptions) => {
		setIsLoadingHistory(true);
		fetchData(
			"quosalweb/quosalapi/Product.ProductPriceHistory",
			"Product.ProductPriceHistory",
			searchOptions
		).then((resp) => {
			const { response0: { historyItems, error } = [] } = resp;
			if (error) {
				setHistory([]);
				setIsLoadingHistory(false);
				return;
			}
			const data = transformData(historyItems);
			setHistory(data);
			setIsLoadingHistory(false);
		});
	};

	useEffect(() => {
		setHistory([]);
		setIsHistorySearchStarted(false);
		setWonQuotesOnly(false);
		setCurrentAccountOnly(false);
		setInitialGradeState();
	}, [productDetails]);

	return {
		history,
		isLoadingHistory,
		productDetails,
		isHistorySearchStarted,
		wonQuotesOnly,
		currentAccountOnly,
		historyGradeState,
		handleSearchHistory,
		setProductInfo,
		handleSearchStarted,
		handleWonQuoteChange,
		handleCurrentAccountChange,
		changeHistoryGradeState
	};
}
