import { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editCustDetailsFormValidationSchema } from "./editCustDetailsFormValidationSchema";
import { SideFlyout, ToastNotification } from "../../common/components";
import { EditCustomerDetailsForm } from "./EditCustomerDetailsForm";
import { useQuoteContext } from "../../context";
import { CUSTOMER_DETAILS_HEADING } from "../../common/constant";

const EditCustomerDetails = ({ showEditCustDetails, hideEditCustomerDetails }) => {
	const [toast, setToast] = useState(null);
	const { MainCust, Owner, InsideRep } = useQuoteContext();
	const isCustomerAdded = MainCust?.AccountName !== "";

	const methods = useForm({
		mode: "onBlur",
		defaultValues: {
			customer: MainCust?.AccountName,
			email: MainCust?.EmailAddress,
			fullName:
				MainCust?.FirstName || MainCust?.LastName
					? `${MainCust?.FirstName} ${MainCust?.LastName}`
					: "",
			phone: MainCust?.DayPhone,
			primaryRep: Owner,
			insideRep: InsideRep
		},
		resolver: yupResolver(editCustDetailsFormValidationSchema)
	});

	useEffect(() => {
		methods.reset({
			customer: MainCust?.AccountName,
			email: MainCust?.EmailAddress,
			fullName:
				MainCust?.FirstName || MainCust?.LastName
					? `${MainCust?.FirstName} ${MainCust?.LastName}`
					: "",
			phone: MainCust?.DayPhone,
			primaryRep: Owner,
			insideRep: InsideRep
		});
	}, [
		MainCust?.AccountName,
		MainCust?.EmailAddress,
		MainCust?.FirstName,
		MainCust?.LastName,
		MainCust?.DayPhone,
		Owner?.DisplayName,
		InsideRep?.DisplayName
	]);

	const handleSideFlyoutClose = () => {
		if (!isCustomerAdded) {
			hideEditCustomerDetails(true);
		} else {
			const isFormValid = Object.keys(methods?.formState?.errors).length === 0;
			hideEditCustomerDetails(isFormValid);
		}
	};

	return (
		<>
			<FormProvider {...methods}>
				<SideFlyout
					title={CUSTOMER_DETAILS_HEADING}
					open={showEditCustDetails}
					onClose={handleSideFlyoutClose}
				>
					<EditCustomerDetailsForm toast={toast} setToast={setToast} />
				</SideFlyout>
			</FormProvider>
			<ToastNotification toast={toast} setToast={setToast} />
		</>
	);
};

export { EditCustomerDetails };
