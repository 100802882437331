import { useCallback, useEffect, useRef, useState } from "react";

import { useDrop } from "react-dnd";
import { useEditorContext } from "../context/editorContext";
import { getCKEditorTabsIds } from "../helpers/getCKEditorTabsIds";
import useEditorWrapperHelper from "./editorWrapperHelpers";

const useEditorWrapperContainer = (showInitialState, handleInitialState, expanded) => {
	const [style, setStyle] = useState({});
	const { ckeditor, setCKEditor, contentGrid, isDisabledQuoteActions, selectedTabs,
		 setSelectedTabs, gridFormats, setSelectedTabsToDroppedTabs, quote} = useEditorContext();
	const {
		handleChangeEditorAfterAddProduct,
		displayStatus,
		canDropTable,
		insertItemsByTabs
	} = useEditorWrapperHelper();

	
	const { HTMLContentForAutoSaving } = quote;

	const handleInitState = (function () {
		if (isDisabledQuoteActions) {
			return false;
		}
		return showInitialState;
	})();
	const initStyle = { display: handleInitState ? "block" : "none", ...style };
	const wrapperHeight = { height: expanded ? "81vh" : "53vh" };

	const postDeletingProductInner = useCallback(
		(editor) => {
			const editorData = editor.data.get();
			app.currentQuote.HTMLContentForAutoSaving = editorData;
			setSelectedTabsToDroppedTabs();
			contentGrid.forceUpdate();
		},
		[contentGrid, app.currentQuote.HTMLContentForAutoSaving]
	);

	const debounceTimerRef = useRef(null);
	const postDeletingProduct = useCallback((editor) => {
		clearTimeout(debounceTimerRef.current);
		debounceTimerRef.current = setTimeout(() => postDeletingProductInner(editor), 500);
	}, [ postDeletingProductInner ]);

	useEffect(() => {
		return () => {
			clearTimeout(debounceTimerRef.current);
		};
	}, []);

	const handleEditorReady = useCallback(
		(editor) => {
			displayStatus(editor);
			setCKEditor(editor);
			handleChangeEditorAfterAddProduct(editor);
			editor.model.change( writer => {
				writer.setSelection( 
					editor.model.document.getRoot(), 'end' 
				 );
			} );
			const toolbarContainer = document.querySelector(".document-editor__toolbar");
			toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
		},
		[displayStatus, handleChangeEditorAfterAddProduct, setCKEditor]
	);

	const [, drop] = useDrop(
		() => ({
			accept: ["sortableItem"],
			drop: (item, monitor) => {
				const didDrop = monitor.didDrop();
				if (didDrop) {
					return;
				}

				const alreadyDroppedTabIds = getCKEditorTabsIds(app.currentQuote.HTMLContentForAutoSaving);
				let data = "";
				const selectedTabIds = item?.id ? new Set([item.id]) : new Set();
				if (selectedTabs !== null) {
					selectedTabs.forEach((tab) => {
						selectedTabIds.add(tab.tabId);
					});
				}
				alreadyDroppedTabIds.forEach((tabId) => {
					selectedTabIds.delete(tabId);
				});

				const addableTabs = app.currentQuote.Tabs.filter((tab) => selectedTabIds.has(tab.IdQuoteTabs));
				data = insertItemsByTabs(addableTabs);
				
				if (data !== "") {
					app.currentQuote.HTMLContentForAutoSaving = data;
					contentGrid.forceUpdate();
				}
				
				ckeditor.editing.view.focus();
				setSelectedTabsToDroppedTabs();
				handleInitialState(false);
			},
			canDrop: (item) => canDropTable(item.id)
		}),
		[app.currentQuote.HTMLContentForAutoSaving, ckeditor, contentGrid, gridFormats, handleInitialState, insertItemsByTabs, selectedTabs, setSelectedTabs]
	);

	useEffect(() => {
		handleInitialState(!HTMLContentForAutoSaving);
	}, [HTMLContentForAutoSaving]);

	useEffect(() => {
		const handleResize = () => {
			const screenWidth = window.innerWidth;
			// fullhd
			if (screenWidth >= 1920 && screenWidth < 2560) {
				setStyle({ top: "14%", left: "12.41%" });
			}
			// 2k
			if (screenWidth >= 2560 && screenWidth < 3840) {
				setStyle({ top: "20%", left: "17.81%" });
			}
			// 4k
			if (screenWidth >= 3840) {
				setStyle({ top: "17%", left: "30.01%" });
			}
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return {
		style,
		gridFormats,
		handleInitState,
		ckeditor,
		setCKEditor,
		handleEditorReady,
		postDeletingProduct,
		drop,
		initStyle,
		wrapperHeight
	};
};

export default useEditorWrapperContainer;
