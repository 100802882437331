export class CustomerSetupToolbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };    
   
        // This binding is necessary to make `this` work in the callback
        this.saveCallback = this.saveCallback.bind(this);
        this.cancelCallback = this.cancelCallback.bind(this);
        this.searchCustomerNavigate = this.searchCustomerNavigate.bind(this);
        this.warnOnNavigate = this.warnOnNavigate.bind(this);
        this.searchOpportunity = this.searchOpportunity.bind(this);
        this.removeCustomers = this.removeCustomers.bind(this);
        this.opportunityDetailNavigate = this.opportunityDetailNavigate.bind(this);
        this.onNavigation = this.onNavigation.bind(this);
        this.hookUpNavigationActions = this.hookUpNavigationActions.bind(this);
        this.unhookNavigationActions = this.unhookNavigationActions.bind(this);
        this.removeOpportunity = this.removeOpportunity.bind(this);
        this.createCustomerActions = this.createCustomerActions.bind(this);
        this.createOpportunityActions = this.createOpportunityActions.bind(this);
    }
    canNavigateToOpp(){
        var customerProvider = quosal.settings.getValue("customerProvider");
        var customer = customerProvider.toLowerCase();
        if (app.currentQuote.IdCRMOpportunity && (customer == "connectwise" || customer == "netsuite"))
        {
            return true;
        }
        return false;
                
    }
    createCustomer(){
        app.currentModule.unloadSubModule(app.currentModule.getActiveSubModule());
        app.currentModule.loadSubModule('quote.customerdetails', {container: 'quoteModule'});
    }
    componentWillUnmount() {
        this.unhookNavigationActions();
    }
    componentDidMount() {
        this.hookUpNavigationActions();
    }
    
    saveCallback() {
       return this.props.saveCallback();
    }
    cancelCallback(){
        this.props.cancelCallback();
    }
    searchCustomerNavigate(){
        this.warnOnNavigate("quote.customersearch");
     
    }
    warnOnNavigate(url, legacyStyleNavigation = false){
        var me = this;
        var resumeNavigation = function () {
            Dialog.closeAll();
            if(legacyStyleNavigation){
                quosal.navigation.navigate(url);
            }
            else
            {
                app.currentModule.unloadSubModule(app.currentModule.getActiveSubModule());
                typeof(me?.props?.setIsDirty) === 'function' && me.props.setIsDirty(false);
                app.currentModule.loadSubModule(url, {container: 'quoteModule', bypassCancelCheck: true});
            }
        }
        var save = function(){
            var saveStatus = me.saveCallback();
            if(saveStatus){
                resumeNavigation();
            }
            else{
                Dialog.open({
                    title: 'Could Not Save Changes',
                    message: 'Required fields were missing information. Discard changes and leave this page? ',
                    closeRequiresButton: true,
                    links: [{
                        title: 'Discard',
                        callback: resumeNavigation
                    }, {
                        title: 'Cancel',
                        callback: Dialog.closeAll
                    }]
                })
            }
        }
        if(this.props.isDirty){
            Dialog.open({
                title: 'Save Changes?',
                message: 'Do you want to save changes before leaving this page?',
                closeRequiresButton: true,
                links: [{
                    title: 'Save',
                    callback: save
                }, {
                    title: 'Discard',
                    callback: resumeNavigation
                }, {
                    title: 'Cancel',
                    callback: Dialog.closeAll
                }]
            });
        }
        else
        {
            resumeNavigation();
        }
    }
    searchOpportunity(){
        this.warnOnNavigate("quote.attachopportunity");
    }
    removeCustomers(){
        var removeCustomersApi = quosal.api.quote.removeCustomers(app.currentQuote.IdQuoteMain);
        var me = this;
        removeCustomersApi.finished = function(msg){
            quosal.sell.quote.update(msg.quote);
            if (me.props.onUpdate) {
                me.props.onUpdate();
            } else {
                quosal.navigation.navigate(quosal.util.url('quote.dashboard', 'submodules=quote.customer'));
            }

            if(!quosal.settings.getValue("canCreateCustomers") && me.props.submoduleName == "quote.customerdetails"){
                quosal.util.navigateToCustomerSetupSubmodule();
            }
        }
        removeCustomersApi.call();
    }
    getOpenOpportunityUrl(){
        var customerProvider = quosal.settings.getValue("customerProvider");

        if (customerProvider.toLowerCase() == "connectwise")
        {
            var cwHostUri = quosal.settings.getValue("connectWiseHostURL");
            var oppurl = "";
            if (app.currentQuote.IdCRMOpportunity && cwHostUri)
            {
                if (cwHostUri.includes("http://"))
                {
                    oppurl = "http://";
                }
                else
                {
                    oppurl = "https://";
                }
                var fullurl =  oppurl + cwHostUri + "/v4_6_release/services/system_io/router/openrecord.rails?locale=en_US&companyName=" +
                    encodeURIComponent(quosal.settings.getValue("connectwiseSitename")) + "&recordType=OpportunityFV&recid=" + app.currentQuote.IdCRMOpportunity + "";
                    window.open(fullurl,'_blank');  
            }
        }
        else if (customerProvider.toLowerCase() == "netsuite" && app.currentQuote.IdCRMOpportunity)
        {
            var getNetsuiteOpportunityUrl = quosal.api.crm.netsuite.getOpportunityUrl(app.currentQuote.IdQuoteMain);
            getNetsuiteOpportunityUrl.finished = function(msg){
                window.open(msg.url,'_blank');  
            }
            getNetsuiteOpportunityUrl.call();
        }
    }
    opportunityDetailNavigate() {
        this.warnOnNavigate('crm.opportunity');
    }
    onNavigation(navState) {
        if (!this) {
            return;
        }
        var runOnNavigation = function (bypassCancelCheck) {
            if (this.props.isDirty && !bypassCancelCheck) {
                var resumeNavigation = function (navState) {
                    if (typeof this.props.resetState === 'function') {
                        this.props.resetState();
                    }
                    Dialog.closeAll();
                    
                    navState.resumeNavigation();
                }.bind(this, navState);

                var saveChanges = function (navState) {
                    Dialog.setIsWorking(true);
                    this.saveCallback();
                    resumeNavigation();
                }.bind(this, navState);

                Dialog.open({
                    title: 'Save Changes?',
                    message: 'Do you want to save changes before leaving this page?',
                    closeRequiresButton: true,
                    links: [{
                        title: 'Save',
                        callback: saveChanges
                    }, {
                        title: 'Discard',
                        callback: resumeNavigation
                    }, {
                        title: 'Cancel',
                        callback: Dialog.closeAll
                    }]
                });

                if (navState.cancelNavigation)
                    navState.cancelNavigation();
            }
        }.bind(this);

        if (this.prepareThenSave) {
            this.prepareThenSave(runOnNavigation);
        } else {
            runOnNavigation(navState.bypassCancelCheck);
        }
    }
    hookUpNavigationActions() {
        quosal.navigation.onBeforeNavigate.bind(this.onNavigation);
        quosal.sell.modules.onBeforeLoad.bind(this.onNavigation);
    }
    unhookNavigationActions() {
        quosal.navigation.onBeforeNavigate.unbind(this.onNavigation);
        quosal.sell.modules.onBeforeLoad.unbind(this.onNavigation);
    }
    removeOpportunity(){
        var doDelete = function(){
            Dialog.setIsWorking(true);
            removeOpportunityAPI.call();
        }
        
        Dialog.open({
            title:'Confirm Removal',
            links:[{title: 'Yes, Remove', callback: doDelete}, Dialog.links.cancel],
            message: "Are you sure you want to remove the opportunity from this quote? This will not delete the opportunity from your CRM."
        });
        var removeOpportunityAPI = quosal.api.crm.removeOpportunity(app.currentQuote.IdQuoteMain)
        removeOpportunityAPI.finished = function(msg){
            quosal.sell.quote.update(msg.quote);
            this.forceUpdate();
            Dialog.close();
            if (this.props.afterRemoveOpportunity) {
                this.props.afterRemoveOpportunity();
            }
        }.bind(this)
        
       
    }
    removeCustomerCheck(){
        return true;
    }
    removeOppCheck(){
            var isAdmin = app.currentUser.IsAdministrator;
            var onlyAdminsCanDeleteOpps = quosal.settings.getValue('onlyAdminsCanDeleteOpps');
            return isAdmin || !onlyAdminsCanDeleteOpps;
    }
    menuSelectionChanged(selectedItem){
            if(selectedItem.callback) {
                selectedItem.callback();
            } else if(selectedItem.url) {
                quosal.navigation.navigate(selectedItem.url, {
                    target: selectedItem.target,
                    transition: selectedItem.transition,
                    bypassClientNav: selectedItem.bypassClientNav
                }, selectedItem.name);
            }
    }
    createCustomerActions(){
        var hasCustomer = app.currentQuote.AccountName != "";        
        var allowCustomerSearch = quosal.util.crmAllowCustomerSearchCheck();
        var disallowCustomerAndOppSearch = quosal.settings.getValue("disallowCustomerSearch");
        var canCreateCustomers = quosal.settings.getValue("canCreateCustomers");
        var canDeleteCustomer = app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer;
        var isLockedDown = app.currentQuote.IsLocked || app.currentUser.IsReadOnly;
        var customerActions = [];

        if(!isLockedDown && !this.props.attachCallback && allowCustomerSearch && !disallowCustomerAndOppSearch)
        {
            customerActions.push({
                id: 1,
                name: 'Search Customers',
                callback: this.searchCustomerNavigate
            });
        }

        if(hasCustomer && this.props.submoduleName != "quote.customerdetails")
        {
            customerActions.push({
                id: 2,
                name: 'Edit Customer',
                callback: this.createCustomer
            });
        }

        if(!isLockedDown && !hasCustomer && canCreateCustomers && this.props.submoduleName != "quote.customerdetails")
        {
            customerActions.push({
                id: 3,
                name: 'Create Customer',
                callback: this.createCustomer
            });
        }

        if(!isLockedDown && hasCustomer && canDeleteCustomer)
        {
            customerActions.push({
                id: 4,
                name: 'Remove Customer',
                callback: this.removeCustomers
            });
        }

        return customerActions;
    }
    createOpportunityActions(){
        var hasOpportunity = app.currentQuote.IdCRMOpportunity != "";
        var hasCustomer = app.currentQuote.AccountName != "";
        var allowOpportunity = quosal.util.crmAllowsOpportunityCheck();
        var disallowCustomerAndOppSearch = quosal.settings.getValue("disallowCustomerSearch");
        var attachOrRemoveOpportunityFromLockedQuote = quosal.settings.getValue('attachOrRemoveOpportunityFromLockedQuote');
        var isLockedDown = app.currentQuote.IsLocked || app.currentUser.IsReadOnly;
        var isConnectwise = String.ciEquals(quosal.settings.getValue('customerProvider'), 'Connectwise');

        var opportunityActions = [];

        if(hasOpportunity && allowOpportunity && (this.props.submoduleName != 'crm.opportunity'))
        {
            opportunityActions.push({
                id: 1,
                name: 'Edit Opportunity',
                callback: this.opportunityDetailNavigate
            });
        }

        if(hasOpportunity && allowOpportunity && this.canNavigateToOpp())
        {
            opportunityActions.push({
                id: 2,
                name: 'Open Opportunity in CRM',
                callback: this.getOpenOpportunityUrl
            });
        }

        if ((!isLockedDown || (attachOrRemoveOpportunityFromLockedQuote && isConnectwise))
            && hasOpportunity && allowOpportunity && this.removeOppCheck())
        {
            opportunityActions.push({
                id: 3,
                name: 'Remove Opportunity',
                callback: this.removeOpportunity
            });
        }

        if ((!isLockedDown || (attachOrRemoveOpportunityFromLockedQuote && isConnectwise))
            && !hasOpportunity && allowOpportunity && this.props.submoduleName != "quote.attachopportunity"
            && !disallowCustomerAndOppSearch)
        {
            opportunityActions.push({
                id: 4,
                name: 'Search Opportunity',
                callback: this.searchOpportunity
            });
        }

        if (!hasOpportunity && allowOpportunity && hasCustomer && (this.props.submoduleName != 'crm.opportunity'))
        {
            opportunityActions.push({
                id: 5,
                name: 'Create Opportunity',
                callback: this.opportunityDetailNavigate
            });
        }

        return opportunityActions;
    }
    render(){
        if (this.props.isBottomToolbar) {
            return(
                <Panel>
                    <PanelToolbar style={{display:'block', fontSize:'12px'}}>
                        {this.props.saveCallback ? <SaveButton style={{float:'right', marginRight:10}} disabled={!(this.props.isDirty || this.props.saveButtonAlwaysActive) || this.props.isSaving} isSaving={this.props.isSaving} onClick={this.saveCallback} visible={this.props.isEditable} /> : null}
                        {this.props.cancelCallback ? <Button type="cancel" style={{float:'right', marginRight:10}} disabled={!this.props.isDirty} onClick={this.cancelCallback} visible={this.props.isEditable}>Cancel</Button> : null}
                        {this.props.attachCallback ? <Button style={{float:"right"}} onClick={this.props.attachCallback} disabled={this.props.isAttachDisabled}>Attach</Button> : null}
                    </PanelToolbar>
                </Panel>
            )
        }

        var customerActions = this.createCustomerActions();
        var opportunityActions = this.createOpportunityActions();
        var customerButton =  customerActions && customerActions.length > 0 ? <div style={{display:'inline-block'}} ><CwWidgets.CwMenuButton value="CUSTOMER" items={customerActions} onDropDownItemClick={this.menuSelectionChanged} /></div> : null
        var opportunityButton = opportunityActions && opportunityActions.length > 0 ? <div style={{display:'inline-block'}} ><CwWidgets.CwMenuButton value="OPPORTUNITY" items={opportunityActions} onDropDownItemClick={this.menuSelectionChanged} /></div> : null

        return(
            <Panel>
                <PanelToolbar style={{display:'block', fontSize:'12px'}}>
                    {customerButton}
                    {opportunityButton}
                    {this.props.saveCallback ? <SaveButton id="topSave" style={{float:'right', marginRight:10}} disabled={!(this.props.isDirty || this.props.saveButtonAlwaysActive) || this.props.isSaving} isSaving={this.props.isSaving} onClick={this.saveCallback} visible={this.props.isEditable} /> : null}
                    {this.props.cancelCallback ? <Button id="topCancel" type="cancel" style={{float:'right', marginRight:10}} disabled={!this.props.isDirty} onClick={this.cancelCallback} visible={this.props.isEditable}>Cancel</Button> : null}
                    {this.props.attachCallback ? <Button id="topAttach" style={{float:"right"}} onClick={this.props.attachCallback} disabled={this.props.isAttachDisabled}>Attach</Button> : null}

                </PanelToolbar>
            </Panel>
        )
    }
}