import {CustomerSetupToolbar} from "js/jsx/src/classes/quote/quoteCustomerSetupToolbar.jsx";
import {GridColumn} from "js/jsx/src/classes/grids.jsx";

export class QuoteCustomerSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {      
            criteria: this.getCwSavedSearchCriteria(),
            customerAddresses: [],
            isSearching: false,
            isAttaching: false,
            page: 1,
            customers: null,
            localSorting: true,
            crm: "",
            defaultBillingCustomer: []
        }; 

        // This binding is necessary to make `this` work in the callback
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.attachCustomer = this.attachCustomer.bind(this);
        this.hasMixedCompanies = this.hasMixedCompanies.bind(this);
        this.preAttachCustomerAPIcall = this.preAttachCustomerAPIcall.bind(this);
        this.attachCustomerAPI = this.attachCustomerAPI.bind(this);
        this.saveCwSavedSearchCriteria = this.saveCwSavedSearchCriteria.bind(this);
        this.resetCwSavedSearchCriteria = this.resetCwSavedSearchCriteria.bind(this);
        this.removeCustomerAddress = this.removeCustomerAddress.bind(this);
        this.customerCheckedState = this.customerCheckedState.bind(this);
        this.handleCustomerCheckBoxClick = this.handleCustomerCheckBoxClick.bind(this);
        this.updateCustomerAddresses = this.updateCustomerAddresses.bind(this);
        this.getAttachDisabledState = this.getAttachDisabledState.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.setActiveGear = this.setActiveGear.bind(this);
        this.submitClick = this.submitClick.bind(this);
        this.findDefaultBillingCustomer = this.findDefaultBillingCustomer.bind(this);
        this.doTheSearch = this.doTheSearch.bind(this);
        this.displayCustomers = this.displayCustomers.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.determineSubmitSort = this.determineSubmitSort.bind(this);
        this.saveCookieAndSearch = this.saveCookieAndSearch.bind(this);
        this.sortFields = this.sortFields.bind(this);
        this.resortTable = this.resortTable.bind(this);
        this.renderGridHeader = this.renderGridHeader.bind(this);
    }
    
    componentDidMount(){
        document.addEventListener('keypress',  this.handleKeyPress)
    }
    componentWillUnmount(){
        document.removeEventListener('keypress',  this.handleKeyPress)
    }
    handleKeyPress(e){
        if(e.keyCode === 13)
        {
           this.submitClick();
        }
    }

    attachCustomer(accountId, contactId){
		this.state.customerAddresses.push({ customer: {ContactID:contactId, AccountID:accountId}, contactType: null});
		if (this.state.crm == 'Connectwise') {
			for (var i = 0; i < this.state.defaultBillingCustomer.length; i++) {
				if (this.state.defaultBillingCustomer[i].customer.AccountID == accountId) {
					this.state.customerAddresses.push({ customer: {ContactID: this.state.defaultBillingCustomer[i].customer.ContactID, AccountID: accountId}, contactType: 'Bill To'});
				}
			}
		}
        this.preAttachCustomerAPIcall();
    }
    hasMixedCompanies(){
        var me = this;
        var addressId = this.state.customerAddresses[0].customer.AccountID;
        var sameAddress = true;
        me.state.customerAddresses.forEach(function(address){
            if(address.customer.AccountID != addressId){
                sameAddress =  false;
            }
        })
        return !sameAddress;
    }
    preAttachCustomerAPIcall(){
        var me = this;
        if(this.hasMixedCompanies()){
            var continueToAttachment = function(){
                Dialog.close();
                me.attachCustomerAPI();
            }
            Dialog.open({
                title: 'Warning - Contact Selection',
                message: <div><div>You have selected contact roles for your quote from 2 or more different accounts in your CRM.</div>
                        <div>If this is intended, please press continue. </div></div>,
                links: [
                    {title:'Continue', callback:continueToAttachment},
                    {title: 'Cancel', callback: Dialog.close}
                ]
            });
        }
        else {
			this.attachCustomerAPI();
        }
    }

    fillInMissingRoles(customerArray, attachAllCustomer) {
        var billToCustomer = undefined;
        if (this.state.crm == 'Connectwise') {
            billToCustomer = customerArray.find(customer => customer.contactType == 'Bill To');
        }
        if (attachAllCustomer === undefined) {
            return customerArray;
        }

        var addressTypes = ['Quote To', 'Bill To', 'Ship To', 'Send To']
        if (!attachAllCustomer) {	
            var selectedTypes = customerArray.map(customer => addressTypes.find(address => customer.contactType == address));
            var primaryAddress = '';
    
            if (selectedTypes.indexOf(addressTypes[0]) != -1) {
                primaryAddress = customerArray[selectedTypes.indexOf(addressTypes[0])];
            }
            else if (selectedTypes.indexOf(addressTypes[1]) != -1) {
                primaryAddress = customerArray[selectedTypes.indexOf(addressTypes[1])];
    
            }
            else if (selectedTypes.indexOf(addressTypes[2]) != -1) {
                primaryAddress = customerArray[selectedTypes.indexOf(addressTypes[2])];
    
            }
            else if (selectedTypes.indexOf(addressTypes[3]) != -1) {
                primaryAddress = customerArray[selectedTypes.indexOf(addressTypes[3])];
    
            }
            var contactTypesToAdd = [];
            addressTypes.forEach(function(addressType){
                if (selectedTypes.indexOf(addressType) == -1 ) {
                    contactTypesToAdd.push(addressType);
                }
            })
             
            contactTypesToAdd.forEach(function(contactType){
                var newAddress = quosal.util.clone(primaryAddress);
                newAddress.contactType = contactType
                customerArray.push(newAddress)
            })
            return customerArray
        } else {
            return addressTypes.map(function(type) {							
				var newAddress;
				if (billToCustomer === undefined || type != 'Bill To') {
					newAddress = quosal.util.clone(attachAllCustomer);
					newAddress.contactType = type;
				}
				else {
					newAddress = quosal.util.clone(billToCustomer);
					newAddress.contactType = type;
				}
                return newAddress;
            })
        }        
    }

    attachCustomerAPI() {
        var opportunityId = quosal.util.queryString('opportunityid');
        var idQuoteMain = app.currentQuote.IdQuoteMain;
        quosal.ui.showMask(1);
        var customerArray = this.state.customerAddresses.map(function(cust) {
            return {contactId: cust.customer.ContactID, accountId:cust.customer.AccountID, contactType:cust.contactType}
        })		
        var attachAllCustomer = customerArray.find(customer => customer.contactType == null);
        var attachAllCustomerCheck;
        if (attachAllCustomer === undefined || !attachAllCustomer) {
            attachAllCustomerCheck = false;
        }
        else {
            attachAllCustomerCheck = true;
        }
		
        customerArray = this.fillInMissingRoles(customerArray, attachAllCustomer);
        var attachCustomerAPI = quosal.api.crm.attachCustomer(idQuoteMain, opportunityId, customerArray, null, attachAllCustomerCheck)
        attachCustomerAPI.finished = function (msg) {
            quosal.ui.hideMask(true);			
            quosal.sell.quote.update(msg.quote);
            app.currentModule.unloadSubModules();						
            quosal.navigation.navigate(msg.nextUrl);
        }.bind(this);
        attachCustomerAPI.call();
        this.setState({isAttaching: true});
    }

    saveCwSavedSearchCriteria() {
        $.cookie('c_customersearch_firstname', this.state.criteria.firstName, { expires: 14, path: '/' });
        $.cookie('c_customersearch_lastname', this.state.criteria.lastName, { expires: 14, path: '/' });
        $.cookie('c_customersearch_accountname', this.state.criteria.accountName, { expires: 14, path: '/' });
        $.cookie('c_customersearch_orderby', this.state.criteria.orderby, { expires: 14, path: '/' });
    }

    resetCwSavedSearchCriteria() {
        $.cookie('c_customersearch_firstname', '', { expires: 14, path: '/' });
        $.cookie('c_customersearch_lastname','', { expires: 14, path: '/' });
        $.cookie('c_customersearch_accountname', '', { expires: 14, path: '/' });
        $.cookie('c_customersearch_orderby', '', { expires: 14, path: '/' });
        this.state.criteria.orderby = '';
        
        this.setState({criteria: this.getCwSavedSearchCriteria(), customerAddresses: [], customers: null});
    }

    removeCustomerAddress(comp, contactType) {
        var me = this;
        $.each(me.state.customerAddresses, function(i){
            if(me.state.customerAddresses[i].contactType === contactType) {
                me.state.customerAddresses.splice(i,1);
                return false;
            }
        });
        if (comp) {
            comp.forceUpdate();
        }
        this.forceUpdate();
    }

    customerCheckedState(contactType, customer) {
        var currentCustomerForContactType = this.state.customerAddresses.reduce(function(newValue, cust){
            if (contactType == cust.contactType) {
                return cust;
            }
            else if (newValue) {
                return newValue;
            }
        }, null)
        if (currentCustomerForContactType && currentCustomerForContactType.customer.ContactID == customer.ContactID) {
            return true;
        }
        else {
            return false;
        }
    }

    handleCustomerCheckBoxClick(contactType, customer, checkboxId) {
        var checkboxElement = $('#'+checkboxId)[0];
        var isChecked = checkboxElement.checked;
        if (isChecked) {
            this.updateCustomerAddresses(contactType, customer);
        }
        else {
            this.removeCustomerAddress(null, contactType);
            this.forceUpdate();
        }
    }

    updateCustomerAddresses(contactType, customer) {
        var updated = false;
		var billTo = false;
        for (var i=0; i<this.state.customerAddresses.length; i++) {
            if (this.state.customerAddresses[i].contactType == contactType) {
                this.state.customerAddresses[i].customer = customer;
                updated = true;
            }
			if (this.state.customerAddresses[i].contactType == 'Bill To') {
				billTo = true;
			}
        }
        if (updated == false) {
            this.state.customerAddresses.push({ contactType: contactType, customer: customer });			
        }
		if (contactType != 'Bill To' && !billTo && this.state.crm == 'Connectwise') {
			for (var i = 0; i < this.state.defaultBillingCustomer.length; i++) {
				if (this.state.defaultBillingCustomer[i].customer.AccountID == customer.AccountID) {
					this.state.customerAddresses.push({ contactType: this.state.defaultBillingCustomer[i].contactType, customer: this.state.defaultBillingCustomer[i].customer});
					break;
				}
			}
		}
        this.forceUpdate();
    }

    getAttachDisabledState() {
        return this.state.customerAddresses.length == 0;
    }

    getCwSavedSearchCriteria() {
        var cookie = {}

        cookie.firstName = quosal.util.cookie('c_customersearch_firstname');
        cookie.firstName = cookie.firstName == 'First Name' ? '' : cookie.firstName;

        cookie.lastName = quosal.util.cookie('c_customersearch_lastname');
        cookie.lastName = cookie.lastName == 'Last Name' ? '' : cookie.lastName;

        cookie.accountName = quosal.util.cookie('c_customersearch_accountname');
        cookie.accountName = cookie.accountName == 'Account Name' ? '' : cookie.accountName;
        
        cookie.orderby = quosal.util.cookie('c_customersearch_orderby');
        if (!cookie.orderby) {
            cookie.orderby = '';
        }

        cookie.count = 100;
        cookie.offset = 0;

        return cookie;
    }

    inputChanged(fieldName) {
        var value = $('#'+fieldName).val();
        this.state.criteria[fieldName] = value;
        this.forceUpdate();
    }
    setActiveGear(gearRef, e) {
        if (this.gearRef && this.gearRef.state.isOptionsVisible) {
            if (this.gearRef.componentIsMounted) {
                this.gearRef.setState({isOptionsVisible: false})
            }
        }  
        this.gearRef = gearRef;
    }
    determineSubmitSort() {
        if (!this.state.criteria.orderby || this.state.criteria.orderby.length == 0) {
            if (this.state.criteria.accountName && this.state.criteria.accountName.length > 0) {
                this.state.criteria.orderby = 'Company_Name ASC';
            }
            else if (this.state.criteria.firstName && this.state.criteria.firstName.length > 0) {
                this.state.criteria.orderby = 'First_Name ASC'; 
            }
            else if (this.state.criteria.lastName && this.state.criteria.lastName.length > 0) {
                this.state.criteria.orderby = 'Last_Name ASC'; 
            }
            else {
                this.state.criteria.orderby = '';
            }
        }
    }

    submitClick() {
        this.determineSubmitSort();
        this.setState({page: 1}, () => {
            this.saveCookieAndSearch();
        } );
    }

    saveCookieAndSearch() {
        this.saveCwSavedSearchCriteria();
        this.doTheSearch();
    }
		
	findDefaultBillingCustomer() {
		var me = this;
		this.state.customers.map(function(cust) {
			var customer = JSON.parse(cust);
			if (me.state.crm == 'Connectwise') {
				if (customer.DefaultBillingContact) {
					me.state.defaultBillingCustomer.push({ contactType: 'Bill To', customer: customer });
				}
			}
		});
	}
	
    doTheSearch() {
        this.state.criteria.offset = (this.state.page-1) * this.state.criteria.count;
        var cwGetCustomers = quosal.api.crm.connectwise.getCustomers(this.state.criteria)
        cwGetCustomers.finished = function (msg) {
            if (!msg.customers) {
                msg.customers = [];
            }
            let doLocal = msg.crm != 'Connectwise';
            if (doLocal) {
                this.sortFields();
            }
            this.setState({ customers: msg.customers, isSearching: false, total: msg.total, localSorting: doLocal, crm: msg.crm, defaultBillingCustomer: [] });
			if (msg.crm == 'Connectwise') {
				this.findDefaultBillingCustomer();			
			}
        }.bind(this);
        cwGetCustomers.call();
        this.setState({isSearching: true})
    }

    prevPage() {
        var p = this.state.page-1;
        this.setState( {
            page: p
        }, () => this.saveCookieAndSearch());
    }

    nextPage() {
        var p = this.state.page+1;
        this.setState( {
            page: p
        }, () => this.saveCookieAndSearch());
    }

    displayCustomers() {
        if (this.state && this.state.customers && Object.keys(this.state.customers).length > 0) {
            var me = this;
            return this.state.customers.map(function(cust, index) {
                var customer = JSON.parse(cust);
                return (
                <tr key={'customerkey_'+index}>
                    <td className='content'><a className='attachAll' href='#' onClick={() => me.attachCustomer(customer.AccountID, customer.ContactID)}>Attach</a></td>
                    <td style={{textAlign:'center' }} className='content'><input id={'quoteToBox' + index} type='radio' style={{width:'16px', minWidth:'16px', minHeight:'16px', height:'16px', marginLeft:'5px'}} onChange={() => me.handleCustomerCheckBoxClick('Quote To', customer, 'quoteToBox' + index)} checked={me.customerCheckedState.bind(me,'Quote To', customer).call()} /></td>
                    <td style={{textAlign:'center' }} className='content'><input id={'billToBox' + index} type='radio' style={{width:'16px', minWidth:'16px', minHeight:'16px', height:'16px', marginLeft:'5px'}} onChange={() => me.handleCustomerCheckBoxClick('Bill To', customer, 'billToBox' + index)} checked={me.customerCheckedState.bind(me,'Bill To', customer).call()}/></td>
                    <td style={{textAlign:'center' }} className='content'><input id={'sendToBox' + index} type='radio' style={{width:'16px', minWidth:'16px', minHeight:'16px', height:'16px', marginLeft:'5px'}} onChange={() => me.handleCustomerCheckBoxClick('Send To', customer, 'sendToBox' + index)} checked={me.customerCheckedState.bind(me,'Send To', customer).call()}/></td>
                    <td style={{textAlign:'center' }} className='content'><input id={'shipToBox' + index} type='radio' style={{width:'16px', minWidth:'16px', minHeight:'16px', height:'16px', marginLeft:'5px'}} onChange={() => me.handleCustomerCheckBoxClick('Ship To', customer, 'shipToBox' + index)} checked={me.customerCheckedState.bind(me,'Ship To', customer).call()}/></td>
                    <td width='266' className='content'>{customer.AccountName}</td>
                    <td width='120' className='content'>{customer.FirstName}</td>
                    <td width='120' className='content'>{customer.LastName}</td>
                    <td width='120' className='content'>{customer.AdditionalInfo}</td>
                </tr>
                )
            })
        }
    }

    reloadModule() {
        app.currentModule.loadSubModule('quote.customersearch', {
            container: 'quoteModule',
            bypassBeforeLoadEvent: true
        });
    }

    resortTable(e) {
        var column = e.target.cellIndex;
        var ob = this.state.criteria.orderby;
        var sortFn = function(column) {
            if (ob.indexOf(column) < 0) {
                return column +' ASC';
            }
            else {
                if (ob.indexOf('ASC') >= 0) {
                    return column +' DESC';
                }
                else {
                    return column +' ASC';
                }
            }
        }.bind(this);

        if (column == 5) {
            this.state.criteria.orderby = sortFn('Company_Name');
        }
        else if (column == 6) {
            this.state.criteria.orderby = sortFn('First_Name');
        }
        else if (column == 7) {
            this.state.criteria.orderby = sortFn('Last_Name');
        }
        else if (column == 8) {
            this.state.criteria.orderby = sortFn('Company_ID');
        }
        else {
            this.state.criteria.orderby = '';
        }
        if (this.state.localSorting) {
            this.sortFields();
        }
        else {
            this.saveCookieAndSearch();
        }
    }

    sortFields() { // non-db
        var sortableColumnHeaderKeys = [null, null, null, null, null, 'AccountName', 'FirstName', 'LastName', 'AdditionalInfo'];
        var ob = this.state.criteria.orderby;
        var colIndex = 0;
        var dir = 1;
        if (ob.indexOf('Company_Name') >= 0) {
            colIndex = 5;
            dir = (ob.indexOf('ASC') >= 0) ? 1 : -1;
        }
        if (ob.indexOf('First_Name') >= 0) {
            colIndex = 6;
            dir = (ob.indexOf('ASC') >= 0) ? 1 : -1;
        }
        if (ob.indexOf('Last_Name') >= 0) {
            colIndex = 7;
            dir = (ob.indexOf('ASC') >= 0) ? 1 : -1;
        }
        if (ob.indexOf('Company_ID') >= 0) {
            colIndex = 8;
            dir = (ob.indexOf('ASC') >= 0) ? 1 : -1;
        }
        
        if (!this.state.customers) {
            return;
        }
        this.state.customers.sort(function(a,b) { 
            var aDisplayName = JSON.parse(a)[sortableColumnHeaderKeys[colIndex]]
            var bDisplayName = JSON.parse(b)[sortableColumnHeaderKeys[colIndex]]
            
            var textA = aDisplayName ? $.trim(aDisplayName.toUpperCase()) : '';
            var textB = bDisplayName ? $.trim(bDisplayName.toUpperCase()) : '';
            return (textA < textB) ? -1 * dir : (textA > textB) ? 1 * dir  : 0;
        });
        this.forceUpdate();
    }
    
    renderGridHeader() {
        let headers = [];
        let actionStyle = {fontSize:'12px', textAlign:'center', borderLeft:'none', borderRight:'none'};
        headers.push(<th key="header_attach" width='20' style={actionStyle} className='nosort'>Attach All</th>);
        headers.push(<th key="header_quoteto" width='20' style={actionStyle} className='nosort'>Quote To</th>);
        headers.push(<th key="header_billto" width='20' style={actionStyle} className='nosort'>Bill To</th>);
        headers.push(<th key="header_sendto" width='20' style={actionStyle} className='nosort'>Send To</th>);
        headers.push(<th key="header_shipto" width='20' style={actionStyle} className='nosort'>Ship To</th>);
        
        var ob = this.state.criteria.orderby;
        let aClass = 'header';
        if (ob.indexOf('Company_Name') >= 0) {
            aClass = (ob.indexOf('ASC') >= 0) ? 'headerSortDown' : 'headerSortUp';
        }
        headers.push(<th onClick={this.resortTable} width='266' className={aClass} key='header_company_name'>Company Name</th>);

        aClass = 'header';
        if (ob.indexOf('First_Name') >= 0) {
            aClass = (ob.indexOf('ASC') >= 0) ? 'headerSortDown' : 'headerSortUp';
        }
        headers.push(<th onClick={this.resortTable} width='120' className={aClass} key='header_first_name'>First Name</th>);

        aClass = 'header';
        if (ob.indexOf('Last_Name') >= 0) {
            aClass = (ob.indexOf('ASC') >= 0) ? 'headerSortDown' : 'headerSortUp';
        }
        headers.push(<th onClick={this.resortTable} width='120' className={aClass} key='header_last_name'>Last Name</th>);

        aClass = 'header';
        if (ob.indexOf('Company_ID') >= 0) {
            aClass = (ob.indexOf('ASC') >= 0) ? 'headerSortDown' : 'headerSortUp';
        }
        headers.push(<th onClick={this.resortTable} width='120' className={aClass} key='header_additional_info'>Additional Info</th>);

        return (<thead>
            <tr key="gridHeaders">
                {headers}
            </tr>
        </thead>);
    }

    renderNavigation() {
        if (!this.state.total || this.state.total == -1) {
            return <div />
        }
        const perPage = this.state.criteria.count;

        var maxPage = Math.floor(this.state.total % perPage > 0 
            ? this.state.total / perPage + 1 
            : this.state.total / perPage);

        var lower = (this.state.page - 1) * perPage + 1;
        var upper = this.state.page * perPage > this.state.total 
            ? this.state.total 
            : this.state.page * perPage;
        var status = lower + '-' + upper + ' of ' + this.state.total;

        var prevPage = <span />;
        if (this.state.page > 1) {
            prevPage = (
                <span>
                    <span id='prevpage' onClick={this.prevPage}>
                        Previous Page
                    </span>{' '}
                </span>
            );
        }
        var nextPage = <span />;
        if (this.state.page < maxPage) {
            nextPage = (
                <span>
                    {' '}
                    <span id='nextpage' onClick={this.nextPage}>
                        Next Page
                    </span>
                </span>
            );
        }

        return (<span id='paging' style={{marginRight: '10px'}}>
            {prevPage}
            <span id='resultcount'>{status}</span>
            {nextPage}
        </span>);
    }
    
    render() {
        var spinnerArgs = {
            lines: 12,
            length: 12,
            width: 6,
            radius: 20
        };
        let navigation = this.renderNavigation();
        let content;
        if(this.state && this.state.customers && Object.keys(this.state.customers).length > 0){
            content = (<table id='remaptable' className='datagrid tablesorter'>
                            {this.renderGridHeader()}
                            <tbody>
                                {this.displayCustomers()}
                            </tbody>
                        </table>);
        }
        else {
            if (this.state.customers) {
                content = (<div className='form-placeholder'>No customers match the current Search Criteria...</div>);
            }
            else {
                content = (<div className='form-placeholder'>Use the Search Criteria on the left to begin your search...</div>);
            }
        }
        return( 
        <div>
            {this.state.isAttaching && <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}><Spinner args={spinnerArgs} title='Attaching...' /></div>}
            <CustomerSetupToolbar key="rc_customersetuptoolbar" onUpdate={this.reloadModule} isSaving={this.state.isAttaching} attachCallback={this.preAttachCustomerAPIcall} isAttachDisabled={this.getAttachDisabledState()}/>
            <GridColumn key="rc_grid4x1" className='grid col4x1'>
            <input type='hidden' id='pageName'></input>
                <Panel title='Search Criteria'>
                <div className='content' id="CustomerSearchId">
                         <div className='standardformfieldlabel'><label htmlFor='accountName' className='standardformlabel'>Company Name</label></div>
                         <input type='text' name='accountName' id='accountName' title='Company Name' className='searchinput' onChange={this.inputChanged.bind(this, 'accountName')} value={this.state.criteria.accountName || ''} />
     
                         <div className='standardformfieldlabel'><label htmlFor='firstname' className='standardformlabel'>First Name</label></div>
                         <input type='text' name='firstname' id='firstName' title='First Name' className='searchinput' onChange={this.inputChanged.bind(this, 'firstName')} value={this.state.criteria.firstName || ''} />
            
                         <div className='standardformfieldlabel'><label htmlFor='lastname' className='standardformlabel'>Last Name</label></div>
                         <input type='text' name='lastname' id='lastName' title='Last Name' className='searchinput' onChange={this.inputChanged.bind(this, 'lastName')} value={this.state.criteria.lastName || ''} /> 
                         <br />
                         <br />
                         <button onClick={this.submitClick} className='btn submit' style={{width:'90px'}} >Search</button>
                         <button onClick={this.resetCwSavedSearchCriteria} className='btn' id='reset' style={{width:'90px', marginLeft:'12px'}} >Reset</button>
                     </div> 
            </Panel>
            {<SelectedCustomerDisplay currentSelectedCustomers={this.state.customerAddresses} removeCustomerAddress={this.removeCustomerAddress} isAttachDisabled={this.getAttachDisabledState()} key='selectedCustomersToDispay' attachCallback={this.preAttachCustomerAPIcall} customers={this.state.customerAddresses}/>}
            </GridColumn>
            <GridColumn key="rc_grid4x3" className='grid col4x3'>
                <Panel>
                    <div className='title'> 
                        {navigation}
                        <span>Customers</span>
                    </div>
                    <div className='content'>
                        {this.state.isSearching ? <FormPlaceholder message='Searching...' /> : content}
                    </div>
                </Panel>
            </GridColumn>
        </div>
        )
    }
}

class SelectedCustomerDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };    
   
        // This binding is necessary to make `this` work in the callback
        this.displayCustomers = this.displayCustomers.bind(this);
        this.removeCustomerAddress = this.removeCustomerAddress.bind(this);
        this.update = this.update.bind(this);
    }
    displayCustomers(){
        var usageTypes = [{usageType:'Quote To', displayName:'Quote To'}, {usageType:'Bill To', displayName:'Bill To'}, {usageType:'Send To', displayName:'Send To'}, {usageType:'Ship To', displayName:'Ship To'}];
        var me = this;
        var primaryCustomer = null;
        //SP: Order taken from QuosalQuote.RepairCustomers() repair order
        if(!primaryCustomer){
            primaryCustomer = this.props.currentSelectedCustomers.reduce(function(foundValue, item){
                if(item.contactType =='Quote To'){
                    return item
                }
                else if(foundValue){
                    return foundValue
                }
            }, null)
        }
        if(!primaryCustomer){
            primaryCustomer = this.props.currentSelectedCustomers.reduce(function(foundValue, item){
                if(item.contactType =='Bill To'){
                    return item
                }
                else if(foundValue){
                    return foundValue
                }
            }, null)
        }
        if(!primaryCustomer){
            primaryCustomer = this.props.currentSelectedCustomers.reduce(function(foundValue, item){
                if(item.contactType =='Ship To'){
                    return item
                }
                else if(foundValue){
                    return foundValue
                }
            }, null)
        }
        if(!primaryCustomer){
            primaryCustomer = this.props.currentSelectedCustomers.reduce(function(foundValue, item){
                if(item.contactType =='Send To'){
                    return item
                }
                else if(foundValue){
                    return foundValue
                }
            }, null)
        }
        if(!primaryCustomer){
            primaryCustomer = {};
        }
        else{
            primaryCustomer = primaryCustomer.customer;
        }
        return usageTypes.map(function(typeObj, index) {
            var cust = me.props.customers.reduce(function(foundValue, c){
                if(c.contactType == typeObj.usageType){
                    return c;
                }
                else{
                    return foundValue
                }
            }, null);
            var customer = {};
            var customerStyle = {};
            if(cust){
                customer = cust.customer;
            } else{
                customerStyle.color = 'gray';
            }
            customerStyle.display = 'block';
            var companyName = customer.AccountName || (primaryCustomer.AccountName ? primaryCustomer.AccountName : '');
            var customerFirstName = customer.FirstName || (primaryCustomer.FirstName ? primaryCustomer.FirstName : '');
            var customerLastName = customer.LastName || (primaryCustomer.LastName ? primaryCustomer.LastName : '');
            var customerName = customerFirstName + ' ' + customerLastName;
            return (
                <tr height='38' key={'customerkey_'+index}>
                    <td className='content'>{cust && <div className='toolbutton actionclose' style={{padding: '0', margin:'0', backgroundImage: 'url(img/svgs/v1.0/Action_Close.svg)'}} onClick={me.removeCustomerAddress.bind(me, typeObj.usageType)}></div>}</td>
                    <td className='content'>{typeObj.displayName}</td>
                    <td className='content'>
                        <label style={customerStyle} title={companyName} >{companyName}</label>
                        <label style={customerStyle} title={customerName}>{customerName}</label>
                    </td>
                </tr>
            )
        })
    }
    
    removeCustomerAddress(usageType) {
        this.props.removeCustomerAddress(this, usageType)
    }
    update() {
        this.forceUpdate();
    }
    render() {
        return (
            <Panel style={{overflow:'hidden'}} title='Selected Customers'>
               <table className='datagrid'>
               <thead>
                   <tr>
                       <th width='25' className='header nosort'></th>
                       <th width='80' className='header nosort'></th>
                       <th width='200' className='header nosort'>Customer</th>
                   </tr>
               </thead>
               <tbody>
                    {this.displayCustomers()}
               </tbody>
           </table>
           <button disabled={this.props.isAttachDisabled} onClick={this.props.attachCallback} style={{float: 'right', marginTop:'10px'}}>Attach</button>
            </Panel>
        )
    }
}