import { Grid, Card, Stack, Typography, useTheme } from "@mui/material";
import { CardContainer } from "../common/components/CardContainer";
import { useGlobalSettingsContext, useQuoteContext, useUserSettingsContext } from "../context";
import { useMemo } from "react";
import { text_ellipsis } from "../styles";
import Divider from '@mui/material/Divider';
import { AssessmentOutlined, FunctionsOutlined, EventRepeatOutlined , MonetizationOnOutlined, PriceCheck } from "@mui/icons-material";
import { getPackageLevels } from "../utils";

export const QuoteStatistics = ({  isCollapsed }) => {
	const quote = useQuoteContext();
	const theme = useTheme();

	const { hideProfit } = useUserSettingsContext();
	const { packageLevel, 
			EnableOneTimeTotal,
			EnableOneTimeGrossProfit,
			EnableRecurring,
			EnableRecurringGrossProfit,
			EnableTotalQuoteValue,
			EnableDownpayment 
	} = useGlobalSettingsContext();
	const packageLevels = getPackageLevels();
	const isNonPremiumPartner = packageLevel !== packageLevels?.premium;

	const quoteStatisticsData = useMemo(() => [
		{
			key:"gross_profit",
			isHidden: hideProfit || !EnableOneTimeGrossProfit,
			value: [{
				title: "One Time Gross Profit",
				value: quote.formatCurrency(quote.GrossMarginAmount),
				icon: <AssessmentOutlined fontSize="medium" htmlColor={theme.palette.icon.primary} />,
				id: "test-one-time-gross-profit",
				other_values: [
					{ sub_title: "Gross margin", subtitle_value: quote.PrintedGrossMargin, id: "test-one-time-gross-margin" },
					{ sub_title: "Markup", subtitle_value: quote.PrintedMarkup, id:"test-one-time-markup" },
					{ sub_title: "Disc Amt", subtitle_value: quote.formatCurrency(quote.SuggestedDiscountAmount), id: "test-one-time-discount" },
				]
			}]
		},
		{
			key:"recurring",
			isHidden: !EnableRecurring,
			value: [{
				title: "Recurring",
				value: quote.formatCurrency(quote.RecurringTotal),
				icon: <EventRepeatOutlined fontSize="medium" htmlColor={theme.palette.icon.primary} />,
				id: "test-recurring",
				other_values: [
					{ sub_title: "Subtotal", subtitle_value: quote.formatCurrency(quote.RecurringSubtotal), id: "test-recurring-subtotal" },
					{ sub_title: "Est Tax", subtitle_value: quote.formatCurrency(quote.RecurringAllTax), id: "test-recurring-est-tax" },
					{ sub_title: "Cost", subtitle_value: quote.formatCurrency(quote.RecurringCost), isHidden: hideProfit, id:"test-recurring-cost"},
				]
			}]
		},
		{
			key:"recurring_profit",
			isHidden: hideProfit || !EnableRecurringGrossProfit ,
			value: [
				{
					title: "Recurring Gross Profit",
					value: quote.formatCurrency(quote.RecurringGrossMarginAmount),
					icon: <EventRepeatOutlined fontSize="medium" htmlColor={theme.palette.icon.primary} />,
					id: "test-recurring-gross-profit",
					isHidden: hideProfit || !EnableRecurringGrossProfit,
					other_values: [
						{ sub_title: "Gross Margin", subtitle_value: quote.PrintedRecurringGrossMargin, id:"test-recurring-profit-gross-margin"},
						{ sub_title: "Markup", subtitle_value: quote.PrintedRecurringMarkup, id: "test-recurring-profit-markup"},
						{ sub_title: "Disc Amt", subtitle_value: quote.formatCurrency(quote.RecurringSuggestedDiscountAmount), id:"test-recurring-profit-discount" },
					]
				}
			]
		},
		{
			key: "down_payment_contract_value",
			isHidden: !(!isCollapsed && (EnableTotalQuoteValue || EnableDownpayment)),
			value:
				[
					{
						title: "Total Quote Value",
						value: `${quote.formatCurrency(quote?.TotalContractValue)}`,
						id: `test-total-quote-value`,
						isHidden: !EnableTotalQuoteValue,
						icon: <MonetizationOnOutlined fontSize="medium" htmlColor={theme.palette.icon.primary} />,
					},
					{
						title: "Down Payment Amount",
						value: `${quote.formatCurrency(
							!quote?.OrderPorterAmount ? 0 : quote?.OrderPorterAmount
						)}`,
						id:`test-down-payment-amount`,
						icon: <PriceCheck fontSize="medium" htmlColor={theme.palette.icon.primary} />,
						isHidden: isCollapsed || isNonPremiumPartner || !EnableDownpayment
					}
				]
		},
		{
			key:"total_quote_value",
			isHidden: !isCollapsed || !EnableTotalQuoteValue,
			value: [
				{
					title: "Total Quote Value",
					value: `${quote.formatCurrency(quote?.TotalContractValue)}`,
					id: `test-total-quote-value`,
					icon: <MonetizationOnOutlined fontSize="medium" htmlColor={theme.palette.icon.primary} />,
				}
			]
		},
		{
			key:"downpayment",
			isHidden: !isCollapsed || isNonPremiumPartner || !EnableDownpayment,
			value: [
				{
					title: "Down Payment Amount",
					value: `${quote.formatCurrency(
						!quote?.OrderPorterAmount ? 0 : quote?.OrderPorterAmount
					)}`,
					id:`test-down-payment-amount`,
					icon: <PriceCheck fontSize="medium" htmlColor={theme.palette.icon.primary} />,
				}
			]
		}
	], [quote.GrossMarginAmount,
		quote?.OrderPorterAmount,
		quote?.TotalContractValue,
		quote?.RecurringTotal,
		quote?.RecurringGrossMarginAmount,
		quote?.RecurringSubtotal,
		quote?.RecurringAllTax,
		quote?.RecurringCost,
		quote?.SuggestedDiscountAmount,
		quote?.PrintedGrossMargin,
		quote?.PrintedMarkup,
		quote?.RecurringSuggestedDiscountAmount,
		quote?.PrintedRecurringGrossMargin,
		quote?.PrintedRecurringMarkup,
		quote.QuoteTotal,
		quote?.BaseCurrency,
		hideProfit,
		isCollapsed,
		EnableOneTimeTotal,
		EnableOneTimeGrossProfit,
		EnableRecurring,
		EnableRecurringGrossProfit,
		EnableTotalQuoteValue,
		EnableDownpayment 
	]);
	const numberOfDivs = useMemo(() => quoteStatisticsData.filter((data) => !data.isHidden).length, [quoteStatisticsData]);
	return (
		<Stack direction={'row'}  gap="8px" 
			sx={{
				flexWrap: "nowrap",
				flex: numberOfDivs,
				minWidth: {
					md: '100%',
					lg: 'auto',
					xl: 0
				}
			}}>
			{quoteStatisticsData.map((data) => !data.isHidden && <CardContainer
				key={data.key}
				sx={{
					flex: data.flex ?? 1,
					minHeight: isCollapsed ? "54px" : "128px",
					height: "auto",
					minWidth: { 
						md: isCollapsed ? "100px" : "180px", 
						lg: "180px", 
						xl: isCollapsed ? "185px" : "230px" 
					},
				}}
				styleOutlineOverrides={{ display: "flex", flex: "1", padding: "8px 16px", height: "100%" }}>
				<QuoteCard isCollapsed={isCollapsed} data={data.value} />
			</CardContainer> || null)}
		</Stack>

	)

}

export const OneTimeTotal = () => {
	const quote = useQuoteContext();
	return (
		<CardContainer
			key={'quote_total'}
			sx={{
				flex: 1,
				minHeight: "54px",
				height: "auto",
				minWidth: {
					md: '90px',
					lg: '200px',
					xl: '300px'
				},
			}}
			styleOutlineOverrides={{ display: "flex", flex: "1", padding: "8px 16px", height: "100%" }}>
			<QuoteCard isCollapsed={true} data={
				[{
					title: "One Time Total",
					isHidden: false,
					value: quote.formatCurrency(quote.QuoteTotal),
					id: "test-one-time-total",
					icon: <FunctionsOutlined fontSize="medium" sx = {{color:'icon.primary'}}/>,
				}]}
			/>
		</CardContainer>
	)
}


export const QuoteCard = ({ data, isCollapsed }) => {
	const theme = useTheme();
	return(
		<Card
			sx={{
				boxShadow: "none",
				width:"100%",
				display: "flex",
				flexDirection:"column",
				height:  "100%",
				padding:"1px",
				gap:"6px",
				justifyContent: "center",
			}}>
		
			{data && data?.filter(d=>!d.isHidden).map(({ title, value, icon, other_values, id }, index) => {
				return <Stack key={`title-stack-${title}`} direction="column">
					<Grid container gap={"8px"}>
					{index === 0 ? null : <Grid item xs={12} ><Divider /></Grid>}
					<Grid item container  sx={{ display: "flex", alignItems: "center",flexWrap:"nowrap",whiteSpace:"nowrap" }} gap="12px" >
						<Grid item>{icon}</Grid>
						<Grid item sx={{display:"flex", flexDirection:"column",	...text_ellipsis}} gap="4px">						
						<Typography
							sx={{
								fontSize: "12px",
								height: "16px",
								whiteSpace: "nowrap",
								width: "100%",
								letterSpacing: "0.32px",
								textAlign: "left",
								color: theme.palette.text.secondary,
								...text_ellipsis
							}}>
							{title}
						</Typography>
							<Typography
							data-testid={value}
							title={value}
							id={id}
							sx={{
								fontWeight: 400,
								lineHeight: "22px",
								height: "18px",
								fontSize: "16px",
								width: "100%",
								color: value < 0
									? theme.palette.text.error
									: theme.palette.text.primary,
								...text_ellipsis
							}}
						>
							{value}
						</Typography>
						</Grid>			
					</Grid>
					{!isCollapsed && <Grid container item sx={{ marginLeft: "36px",flexDirection:"column" }} gap="4px">
						{other_values && other_values.map(({ sub_title, subtitle_value, isHidden, id }) => 
						{
							if(isHidden) return null;
							return <Grid item key={`other_val-${sub_title}`} >
							<Typography  sx={{
								fontWeight: 400,
								lineHeight: "18px",
								fontSize: "14px",
								letterSpacing: "0.16px",
								textAlign: "left",
								height: "18px",
								color: value < 0
									? theme.palette.text.error
									: theme.palette.text.primary,
								...text_ellipsis
							}}>
								{sub_title}:&nbsp;&nbsp;<span data-testid={subtitle_value} id={id} title={subtitle_value}>{subtitle_value}</span>
							</Typography>
						</Grid>})}
					</Grid> || null}
				</Grid>
			</Stack>})}

		</Card>
	)
}
