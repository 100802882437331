import { Close, Search } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { SearchInputStyled } from "../styledComponents/commonStyled";
import { DEBOUNCE } from "../constants/common";

const minSearchTextLength = 3;

export class SubmittedFormsSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: '',
        }
    }

    render() {
        const { filter } = this.state;
        const { onSearch } = this.props;
        const debouncedFilter = DEBOUNCE((filter, callback) => onSearch && onSearch(filter));
        const updateFilter = (filter) => {
            this.setState({ ...this.state, filter });
            if (!filter || filter.length >= minSearchTextLength) {
                debouncedFilter(filter);
            }
        };
        const onChange = (e) => updateFilter(e.target.value);
        const onClear = () => updateFilter('');

        return <>
            <SearchInputStyled
                value={filter}
                onChange={onChange}
                placeholder="Search input text"
                endAdornment={<>
                    <InputAdornment position="end">
                        { !!filter && <IconButton onClick={onClear}>
                            <Close />
                        </IconButton> }
                    </InputAdornment>
                    <InputAdornment position="end">
                        <IconButton>
                            <Search />
                        </IconButton>
                    </InputAdornment>
                </>}
            />
        </>
    }
}