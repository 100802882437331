import React from "react";
import { Box, Checkbox, FormGroup, FormControlLabel, Grid, Typography } from "@mui/material";
import { StyledButton } from "../styledComponents/common.jsx";
import { useAppDataContext } from "../globalStates/appDataProvider";
import PriceHistoryResults from "./priceHistoryResults.jsx";

function ProductPriceHistory() {
	const {
		productDetails,
		isHistorySearchStarted,
		wonQuotesOnly,
		currentAccountOnly,
		handleSearchHistory,
		handleSearchStarted,
		handleWonQuoteChange,
		handleCurrentAccountChange
	} = useAppDataContext();

	const handleSearch = (e) => {
		e.preventDefault();

		handleSearchStarted();

		handleSearchHistory({
			count: 0,
			pageSize: 5,
			mpn: productDetails.mpn,
			currentAccountOnly,
			wonQuotesOnly
		});
	};

	return (
		<Box>
			<Box component="form" onSubmit={handleSearch} sx={{ width: "100%" }}>
				<FormGroup>
					<FormControlLabel
						checked={wonQuotesOnly}
						onChange={(e) => handleWonQuoteChange(e.target.checked)}
						control={<Checkbox />}
						label={<Typography fontWeight={600}>Search won quotes only</Typography>}
					/>
					<FormControlLabel
						checked={currentAccountOnly}
						onChange={(e) => handleCurrentAccountChange(e.target.checked)}
						control={<Checkbox />}
						label={
							<Typography fontWeight={600}>
								Search current account history only
							</Typography>
						}
					/>
				</FormGroup>
				<Grid container item justifyContent="flex-end" mt={2}>
					<StyledButton type="submit" onSubmit={handleSearch} variant="contained">
						Search
					</StyledButton>
				</Grid>
			</Box>
			{isHistorySearchStarted && <PriceHistoryResults />}
		</Box>
	);
}

export default ProductPriceHistory;
