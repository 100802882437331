import { api } from "../common/guidedSelling/api/api.js";
import { EXCLUDED_TAB_GRID_FORMATS } from "../settings/guidedSelling/constants/submittedFormsConstants.js";

export class PickTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.selectedTemplates = [];
        var templates = this.getParsedTemplateList(this.props.quoteTemplateList);
        var favoriteTempaltesParsed = this.parseFavoriteTemplateString(this.props.favoriteTemplateString);
        var isAdmin = app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer;
        var headerFilter = isAdmin ? { colindex: 2, 0: -1, 1: -1, 2: 1, 3: -1, 4: -1, 5: -1 } : { colindex: 1, 0: -1, 1: 1, 2: -1, 3: -1, 4: -1 };

        this.state = {
            favoriteTemplates: favoriteTempaltesParsed,
            quoteTemplateList: templates,
            headerFilter: headerFilter,
            isDirty: false,
            isLoading: false,
            isEditing: false,
            isSaving: false,
            templatesToUpdate: {},
            newTemplateGroups: [],
            filterTerm: "",
            salesTrackForm: []
        };
        this.formatFavoritesForSave = this.formatFavoritesForSave.bind(this);
        this.search = this.search.bind(this);
        this.getTemplateByIdQuoteMain = this.getTemplateByIdQuoteMain.bind(this);
        this.sortFields = this.sortFields.bind(this);
        this.sortHeader = this.sortHeader.bind(this);
        this.addTemplateToFavorites = this.addTemplateToFavorites.bind(this);
        this.getFavoriteIcon = this.getFavoriteIcon.bind(this);
        this.getFieldRows = this.getFieldRows.bind(this);
        this.openCopyVersionOfQuote = this.openCopyVersionOfQuote.bind(this);
        this.openNewQuote = this.openNewQuote.bind(this);
        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.getSalesTrackForm = this.getSalesTrackForm.bind(this);
    }
    getParsedTemplateList(templateList) {
        var parsedList = [];
        templateList.forEach(function (rawTemplate, index) {
            var template = JSON.parse(rawTemplate);
            parsedList.push(template)
        })
        return parsedList;
    }
    parseFavoriteTemplateString(templateString) {
        return templateString.split(',');
    }
    formatFavoritesForSave() {
        return this.state.favoriteTemplates.join(',');
    }
    componentDidMount() {
        this.componentIsMounted = true;
        this.sortFields();
    }
    componentWillUnmount() {
        this.componentIsMounted = false;
    }
    search(e) {
        this.setState({ filterTerm: e.currentTarget.value });
    }
    getTemplateByIdQuoteMain(idQuoteMain) {
        var me = this;
        return me.state.quoteTemplateList.find(function (templateObj) {
            return templateObj.idQuoteMain == idQuoteMain
        })
    }
    getAdminTemplateUrl() {
        var versionidquotemain = quosal.util.queryString("versionidquotemain");
        var params = ""
        if (versionidquotemain) {
            params += "versionidquotemain=" + versionidquotemain +
                "&quotereturnurl=" + encodeURIComponent(window.location.href)
        }
        return quosal.util.url("adminedittemplates.quosalweb", params);
    }
    sortFields() {
        var me = this;
        var isAdmin = app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer;
        var sortableColumnHeaderKeys = isAdmin ? [null, null, "templateGroup", null, "name", "note"] : [null, "templateGroup", null, "name", "note"];
        var colIndex = this.state.headerFilter.colindex;
        var dir = this.state.headerFilter[colIndex]

        if (!this.state.quoteTemplateList) {
            return;
        }
        this.state.quoteTemplateList.sort(function (a, b) {
            var aFavorite = !me.state.favoriteTemplates.includes(a.idQuoteMain);
            var bFavorite = !me.state.favoriteTemplates.includes(b.idQuoteMain);

            var aDisplayName = a[sortableColumnHeaderKeys[colIndex]]
            var bDisplayName = b[sortableColumnHeaderKeys[colIndex]]

            var aValue = aDisplayName ? $.trim(aDisplayName.toUpperCase()) : null;
            var bValue = bDisplayName ? $.trim(bDisplayName.toUpperCase()) : null;
            if (aFavorite > bFavorite) {
                return 1;
            } else if (aFavorite < bFavorite) {
                return -1;
            } else {
                if (!aValue && bValue) {
                    return 1
                }
                if (!bValue && aValue) {
                    return -1
                }
                if (aValue > bValue && (aValue && bValue)) {
                    return 1 * dir;
                } else if (aValue < bValue && (aValue && bValue)) {
                    return -1 * dir
                } else {
                    var aDisplayName = a.name;
                    var bDisplayName = b.name;

                    var aName = aDisplayName ? $.trim(aDisplayName.toUpperCase()) : null;
                    var bName = bDisplayName ? $.trim(bDisplayName.toUpperCase()) : null;
                    if (!aName) {
                        return 1
                    }
                    if (!bName) {
                        return -1
                    }
                    if (aName > bName) {
                        return 1;
                    } else if (aName < bName) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            }

        });
        this.forceUpdate();
    }
    sortHeader(e) {
        var element = $(e.target);
        var column = e.target.cellIndex;

        element.siblings().each(function () {
            if (this.className != "nosort")
                $(this).removeClass()
        }
        )
        element.removeClass();
        this.state.headerFilter[column] *= -1;
        this.state.headerFilter.colindex = column;

        var sortIconDir = this.state.headerFilter[column] == 1 ? "headerSortDown" : "headerSortUp";
        element.addClass(sortIconDir);

        this.sortFields();

    }
    addTemplateToFavorites(idQuoteMain, e) {
        e.stopPropagation();
        var me = this;
        var favorteIndex = this.state.favoriteTemplates.indexOf(idQuoteMain);
        if (favorteIndex == -1) {
            this.state.favoriteTemplates.push(idQuoteMain);
            this.forceUpdate();
        }
        if (favorteIndex > -1) {
            this.state.favoriteTemplates.splice(favorteIndex, 1);
            this.forceUpdate();
        }
        var saveFavoriteApi = quosal.api.settings.saveUserSettings({
            key: 'TemplateFavorites',
            value: this.formatFavoritesForSave()
        });
        saveFavoriteApi.finished = function (msg) {
        }
        saveFavoriteApi.call();
    }
    getFavoriteIcon(template) {
        var me = this;
        var styleObj = { backgroundPosition: "center", height: "40px", position: "absolute", left: "40px" };
        if (this.state.isSaving) {
            return <Spinner style={styleObj} />
        }
        else {
            if (this.state.favoriteTemplates.indexOf(template.idQuoteMain) > -1) {
                return <div onClick={(e) => me.addTemplateToFavorites(template.idQuoteMain, e)} className="toolbutton favorited" title="Favorite"></div>
            } else {
                return <div onClick={(e) => me.addTemplateToFavorites(template.idQuoteMain, e)} className="toolbutton favorite" title="Favorite"></div>
            }
        }
    }
    getFieldRows() {
        var me = this;
        var isAdmin = app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer;
        return this.state.quoteTemplateList.map(function (template, index) {
            var tdStyle = { padding: "5px 0 5px 5px", border: "solid 1px #cccccc" };
            var textStyle = { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" };
            var filterTerm = me.state.filterTerm.toUpperCase();
            var note = template.note ? template.note.toUpperCase() : "";
            var name = template.name ? template.name.toUpperCase() : "";
            var templateGroup = template.templateGroup ? template.templateGroup.toUpperCase() : "";

            var versionIdQuoteMain = quosal.util.queryString("versionidquotemain");
            var rowClickEvent;

            if (versionIdQuoteMain) {
                rowClickEvent = () => me.openCopyVersionOfQuote(template);
            } else if (me.state.isEditing) {
                rowClickEvent = () => {
                    var dashboardModule = quosal.sell.modules.find('quote.dashboard');
                    dashboardModule.load({ query: 'idquotemain=' + template.idQuoteMain + '&submodules=quote.home' });
                }

            } else {
                rowClickEvent = () => me.openNewQuote(template);
            }

            if (filterTerm == "" || (filterTerm != "" && (note.includes(filterTerm) || templateGroup.includes(filterTerm) || name.includes(filterTerm)))) {
                return (
                    <tr className="customizefield" style={{ margin: "4px 0" }} key={index}>
                        <td onClick={rowClickEvent} style={{ textAlign: "center" }}><div className={me.state.isEditing ? "toolbutton edit color" : "toolbutton add"} ></div></td>
                        <td style={{ textAlign: "center", border: "solid 1px #cccccc" }}>{me.getFavoriteIcon(template)}</td>
                        <td onClick={rowClickEvent} style={tdStyle} title={template.templateGroup}>{template.templateGroup}</td>
                        <td onClick={rowClickEvent} style={{ textAlign: "center", border: "solid 1px #cccccc", height: "48px", width: '48px' }}><img style={{ maxHeight: "48px", maxWidth: "48px" }} src={template.imageUrl} /></td>
                        <td onClick={rowClickEvent} style={tdStyle} title={template.name}><div style={textStyle}>{template.name}</div></td>
                        <td onClick={rowClickEvent} style={tdStyle} title={template.note}><div style={textStyle}>{template.note}</div></td>
                    </tr>
                );
            }
        });
    }
    openCopyVersionOfQuote(template) {
        var idQuoteMain = quosal.util.queryString("versionidquotemain");
        var copyQuoteApi = quosal.api.quote.copyQuoteAsNewVersion(idQuoteMain, template.idQuoteMain)
        var me = this;
        copyQuoteApi.finished = function (msg) {
            quosal.sell.quote.update(msg.quote);
            quosal.ui.hideMask(true);
            me.setState({ isLoading: false });
            app.currentModule.loadSubModule('quote.content', {
                container: 'quoteModule',
                query: quosal.util.updateQueryString({ idquotemain: msg.quote.IdQuoteMain, submodules: "quote.content" })
            });
        }
        copyQuoteApi.call();
        quosal.ui.showMask(1);
        this.setState({ isLoading: true });
    }
    navigateToDashboard(quote, opportunityInfo) {
        quosal.sell.quote.update(quote);
        const dashboardModule = quosal.sell.modules.find('quote.dashboard');
        let nextModule = ""
        const customerSelectionMode = quosal.settings.getValue("customerSelectionMode");
        if (customerSelectionMode.toLowerCase() == "opportunity") {
            nextModule = "&nextmodule=quote.attachOpportunity";
        }
        dashboardModule.load({ query: 'idquotemain=' + quote?.IdQuoteMain + nextModule + opportunityInfo + "&submodules=quote.home" });
    }   

    getSalesTrackTabId = (tabList) => {
        if (tabList?.length) {
            const visibleTabs = tabList.filter((tab) => !tab.IsHidden);
            if (visibleTabs.length) {
                const productTabs = visibleTabs.filter((tab) => !EXCLUDED_TAB_GRID_FORMATS.includes(tab?.GridFormat));
                return productTabs[0]?.IdQuoteTabs || visibleTabs[0]?.IdQuoteTabs  || "";
            } else {
                const productTabs = tabList.filter((tab) => !EXCLUDED_TAB_GRID_FORMATS.includes(tab?.GridFormat));
                return productTabs[0]?.IdQuoteTabs || tabList[0]?.IdQuoteTabs  || "";
            }
        } else {
            return "";
        }
    }

    getSalesTrackForm(quote, tabs = [], templateId, opportunityInfo) {
        api.getAllSalesTracks("", (msg) => {
            const salesTrackForm = msg.salesTracks.find((salesTrack) => salesTrack?.quoteTemplateIds.includes(templateId));
            if (salesTrackForm) {
                const salesTrackFormModule = quosal.sell.modules.find('quote.templatesalestrack');
                sessionStorage.setItem("salesTrackForTemplate", JSON.stringify({
                    name: salesTrackForm?.name,
                    state: salesTrackForm?.state,
                    id: salesTrackForm?.id,
                }));     
                sessionStorage.setItem("quoteOpportunityInfo", JSON.stringify(opportunityInfo));  
                quosal.sell.quote.initQuote(quote);
                app.currentQuote = quote;    
                const tabId = this.getSalesTrackTabId(tabs);
                salesTrackFormModule.load({
                    query: 'idquotemain=' +  quote?.IdQuoteMain +
                    '&salesTrackId=' + salesTrackForm.id +
                    '&idquotetabs=' + tabId 
                });
            } else {
                this.navigateToDashboard(quote, opportunityInfo);
            }
        });
    }

    openNewQuote(template) {
        var opportunityid = quosal.util.queryString('opportunityid');
        var opportunityInfo = "";
        if (opportunityid) {
            var oppusername = quosal.util.queryString("sfusername");
            if (!oppusername) {
                oppusername = quosal.util.queryString("cwusername");
            }
            if (!oppusername) {
                oppusername = quosal.util.queryString("nsemail");
            }
            if (!oppusername) {
                oppusername = quosal.util.queryString("dynusername");
            }

            var oppString = "&opportunityid=" + opportunityid + "&oppusername=" + oppusername;

            if (quosal.util.queryString("recordid"))
                oppString += "&recordid=" + quosal.util.queryString("recordid");
            if (quosal.util.queryString("objecttype"))
                oppString += "&objecttype=" + quosal.util.queryString("objecttype");
            if (quosal.util.queryString("sfserver"))
                oppString += "&sfserver=" + quosal.util.queryString("sfserver");
            if (quosal.util.queryString("sfurl"))
                oppString += "&sfurl=" + quosal.util.queryString("sfurl");
            if (quosal.util.queryString("sfsession"))
                oppString += "&sfsession=" + quosal.util.queryString("sfsession");
            if (quosal.util.queryString("sfusername"))
                oppString += "&sfusername=" + quosal.util.queryString("sfusername");

            opportunityInfo = oppString;
        }
        const self = this;
        var copyQuoteApi = quosal.api.quote.copyQuote(template.idQuoteMain, "", true)
        copyQuoteApi.finished = function (msg) {
            if(quosal.settings.getValue('enableGuidedSelling', false))
            {
                self.getSalesTrackForm(msg.quote, msg?.quote?.Tabs, template.idQuoteMain, opportunityInfo);
            } else {
                self.navigateToDashboard(msg.quote, opportunityInfo);
            }
        }
        copyQuoteApi.call();
        this.setState({ isLoading: true })
        quosal.ui.showMask(1);
    }
    toggleEditMode() {
        this.setState({ isEditing: !this.state.isEditing });
    }
    render() {
        var isAdmin = app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer;
        var isCopyingAsNewVersion = !!quosal.util.queryString("versionidquotemain");
        var spinnerArgs = {
            lines: 12,
            length: 12,
            width: 6,
            radius: 20
        };
        return (
            <div>
                {this.state.isLoading && <div style={{ display: "flex", flex: 1, justifyContent: 'center', alignItems: 'center' }}><Spinner args={spinnerArgs} title="Loading..." /></div>}
                {!this.state.isLoading &&
                    <div>
                        <div className="content" id="picktempalteContent">
                            <div className="customizefields" id="contentPanelContainer">
                                <Panel>
                                    <div className="title" style={{ border: "0px" }}>{this.state.isEditing ? "Edit Template" : "New Quote"}</div>
                                    <div style={{ margin: "5px 5px 0 5px" }}>
                                        <input className="filterTemplates" type="text" placeholder="Type to filter templates" id="searchInput" onChange={this.search} value={this.state.filterTerm} />
                                        {isAdmin && !isCopyingAsNewVersion &&
                                            <div style={{ float: "right", marginTop: "4px" }}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={this.state.isEditing} onChange={this.toggleEditMode} />
                                                    <span className="slider round"></span>
                                                </label>
                                                <span style={{ verticalAlign: "bottom", marginLeft: "3px" }}>Edit</span>
                                            </div>
                                        }
                                    </div>
                                    <PanelContent>
                                        <table id="remaptable" className="datagrid tablesorter" style={{ fontSize: '12px', tableLayout: "fixed" }}>

                                            <colgroup>
                                                <col style={{ width: "5%" }} />
                                                <col style={{ width: "5%" }} />
                                                <col style={{ width: "20%" }} />
                                                <col style={{ width: "5%" }} />
                                                <col style={{ width: "30%" }} />
                                                <col style={{ width: "30%" }} />
                                            </colgroup>

                                            <thead>
                                                <tr>
                                                    <th className="nosort" width="1">{this.state.isEditing ? "Edit" : "New"}</th>
                                                    <th className="nosort" width="1">Favorite</th>
                                                    <th className="headerSortDown" onClick={this.sortHeader} width="1">Template Group</th>
                                                    <th width="1" className="nosort"></th>
                                                    <th onClick={this.sortHeader} width="1">Name</th>
                                                    <th onClick={this.sortHeader} width="1" >Notes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.getFieldRows()}
                                            </tbody>
                                        </table>
                                    </PanelContent>
                                </Panel>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}