import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import accounting from "js/lib/accounting";
import color from "js/lib/color"

import "js/lib/spin"
import "js/lib/historyjs/history"
import "js/lib/historyjs/history.adapter.native"
import "js/lib/offline/offline-language-english.css"
import "js/lib/imageEditor/imageEditor.css"
import "js/lib/imageEditor/jquery.jcrop.min.css"
import "js/lib/jSignature/jSignatureCSS.css"
import "js/lib/imageEditor/jquery.color.js"
import "js/lib/imageEditor/jquery.jcrop.min.js"
import "js/lib/imageEditor/jcrop_ui.js"
import "js/lib/jquery/cookies.js"
import "js/lib/jSignature/jSignature.min.js"
import "js/lib/jSignature/jSignatureUI.js"
import 'js/lib/cw-widgets-umd-2.2.5/css/main.css'
import "js/lib/fingerprintjs2.js"

const CwWidgets = require('js/lib/cw-widgets-umd-2.2.5/js/main.min.js');

global.React = React;
global.CwWidgets = CwWidgets;
global.ReactDOM = ReactDOM;
global.accounting = accounting;
global.Moment = Moment;
global.Color = color;
