import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
const Loader = ({ loading, loadingText = "Loading", ...props }) => {
	const theme = useTheme();
	return (
		<Backdrop
			sx={{
				color: theme.palette.primary.contrastText,
				zIndex: 999,
				display: "flex",
				flexDirection: "column",
				...(props.sx || {})
			}}
			open={loading}
		>
			<CircularProgress size={30} />
			<Typography textAlign="center" fontSize={16}>
				{loadingText}
			</Typography>
		</Backdrop>
	);
};

export { Loader };
