if (!quosal) quosal = {};
if (!quosal.schema) quosal.schema = {};

quosal.schema.getFields = function (objType, objName) {
    var obj = quosal.schema[objType];
	
    var fieldsNoShow = {};
    if (!quosal.validation.isPackageLevelAuthorized(app.packageLevels.standard)){
        fieldsNoShow["EnforceItemModTags"] = true;
        fieldsNoShow["IsModTagModified"] = true;
    }

    if (!quosal.validation.isPackageLevelAuthorized(app.packageLevels.premium) || app.settings.global.MLAEnableApproved || (app.settings.global.ApprovalMethod && 
        (app.settings.global.ApprovalMethod == 'Multilevel' || app.settings.global.ApprovalMethod == 'None'))) {
		fieldsNoShow["ApprovalMargin"] = true;
		fieldsNoShow["ApprovalMode"] = true;
		fieldsNoShow["ApprovedByUserId"] = true;
		fieldsNoShow["ApprovalComment"] = true;
		fieldsNoShow["ApprovalAmount"] = true;
		fieldsNoShow["ApprovalQuoteForm"] = true;
		fieldsNoShow["ApprovalReason"] = true;
		fieldsNoShow["RequiresApproval"] = true;
		fieldsNoShow["ApprovalOnChange"] = true;
		fieldsNoShow["ApprovalPriceMinimum"] = true;
		fieldsNoShow["ApprovalMarginMinimum"] = true;
		fieldsNoShow["ApprovalContentHash"] = true;
	}
	
    if (obj) obj = obj[objName];

    if (!obj)
        return [];

    var keys = Object.keys(obj);
    var fields = [];

    for (var i = 0; i < keys.length; i++) {
        if (!fieldsNoShow[obj[keys[i]].FieldName]){
            fields.push(obj[keys[i]]);
        }
    }

    return fields;
};
quosal.schema.getStandardFields = function (objType, objName) {
    var obj = quosal.schema[objType];
    if (obj) obj = obj[objName];

    if (!obj)
        return [];

    var keys = Object.keys(obj);
    var fields = [];

    for (var i = 0; i < keys.length; i++) {
        if (obj[keys[i]].IsStandardField)
            fields.push(obj[keys[i]]);
    }

    return fields;
};
quosal.schema.getAdditionalFields = function (objType, objName) {
    var obj = quosal.schema[objType];
    if (obj) obj = obj[objName];

    if (!obj)
        return [];

    var keys = Object.keys(obj);
    var fields = [];

    for (var i = 0; i < keys.length; i++) {
        if (obj[keys[i]].IsStandardField)
            fields.push(obj[keys[i]]);
    }

    return fields;
};
quosal.schema.describe = function (objType, objName) {
    var allFields = quosal.schema.getFields(objType, objName);

    var description = {
        allFields: allFields,
        standardFields: allFields.where(function(s) { return s.IsStandardField }),
        additionalFields: allFields.where(function(s) { return !s.IsStandardField }),
        privateFields: allFields.where(function(s) { return s.IsPrivateField }),
        isPrivateField: {},
        fieldConfigurations: (quosal.customization.fields.configurations[objType] && quosal.customization.fields.configurations[objType][objName]) || {}
    };

    for(var i = 0; i < description.privateFields.length; i++) {
        description.isPrivateField[description.privateFields[i].FieldName] = true;
    }

    return description;
};