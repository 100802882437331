import { Alert, Collapse, Typography } from "@mui/material";
import { warningAlertYellow } from "../../styles";
import { useTheme } from "@mui/material/styles";

export const OtherUsersWarningToast = ({ isMultipleUser, otherUsers, otherUsersList }) => {
	const theme = useTheme();
	return (
		<Collapse in={isMultipleUser} sx={{ marginBottom: "8px" }}>
			<Alert
				severity="warning"
				sx={{
					backgroundColor: theme.palette.background.paper,
					color: theme.palette.text.primary,
					borderLeft: `3px solid ${warningAlertYellow}`,
					width: "100%",
					height: "48px",
					" & .MuiAlert-message": {
						padding: "7px 0px !important"
					}
				}}
				contrast="normal"
			>
				<Typography
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center"
					}}
				>
					<span>
						<span style={{ fontWeight: "bold" }}>Warning!</span>
						<span>{` ${otherUsers.length} other `}</span>
						{otherUsers.length > 1 ? "users are" : "user is"} also on this quote:{" "}
						{otherUsersList}
					</span>
				</Typography>
			</Alert>
		</Collapse>
	);
};
