import { AttachSite } from "js/jsx/src/classes/crm/attachSite.jsx";

export default function attachSite() {
    
    var renderEditForm = function () {
        quosal.ui.react.update(<AttachSite onUpdate={() => renderEditForm()} />, document.getElementById('quoteModule'));
    }
    var getSitesApi = quosal.api.crm.getAdditionalSites(app.currentQuote.IdQuoteMain, null, null);
    $('#pageName').val('crm.attach-site');
    quosal.ui.react.render(React.createElement(FormPlaceholder, { message: 'Loading Quote Content...' }), document.getElementById('quoteModule'));
    getSitesApi.finished = function (msg) {
        quosal.ui.react.render(<AttachSite sites={JSON.parse(msg.data.sites)} onUpdate={renderEditForm} />,
            document.getElementById('quoteModule'));
    }

    getSitesApi.call();
}