import { FormPlaceholder } from "js/jsx/src/classes/forms.jsx"
import { IngramQuoteImportPage } from "js/jsx/src/classes/ingram/ingramQuoteImport.jsx"

export default function ingramQuoteSearch() {
    quosal.ui.react.render(
        <FormPlaceholder message="Loading Ingram Quote Search..." />
        , document.getElementById('ingramQuoteSearchContainer'));
    var renderIngramQuoteSearchPage = function (renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;



        var idQuoteTabs = quosal.util.queryString('idquotetabs');
        var idQuoteMain = quosal.util.queryString('idquotemain');
        renderMethod(<IngramQuoteImportPage defaultDestinationTab={idQuoteTabs} idQuoteMain ={idQuoteMain}/>,
            document.getElementById('ingramQuoteSearchContainer'));
    };

    var loadIngramQuoteSearchPage = function () {
        renderIngramQuoteSearchPage(quosal.ui.react.render);

        $('#pageName').val('ingram.ingramQuoteSearch');
    };
    loadIngramQuoteSearchPage();
}