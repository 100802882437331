

/****************************************************************************/
/*
    How "live" image submissions work in javascript;
        Summary:  We can hav users select files from their computer's directory be adding an input type = file.
        But we cannot access this file from javascript or even know it's true path name. The file can be submitted to the server however,
        where we can read it's path name and file contents. Unfortunatly we must submit the page in order to get it to the server.
        (AJAX calls are out of the question due to to javascript security limitations.) One quick and dirty way to get the job done is an Iframe submit.
        The idea is that there is a hidden iframe on the page that can submit forms while the main page appears active. All you need to do is set the form's target 
        to the name of the iframe and listen for the iframes load event once the form is submitted.
        
    How jCrop works in Quosal Sell:
        Files uploaded from user: We do not have access to user files unless they are procced by the server first. This means that image cropping takes place in few steps:
        1. Upload the original file to the server.
        2. Read the file's contents and host it temporarily.
        3. Crop the temporary image.
        4. Send the results to the server to have the picture save in the proper place with the changes applied.

        There are two form submissions each with two seperate handlers.
        1. The handler that uploads the file to the server initially (From step 1) is called thumbpreview.quosalweb, and the corresponding form id is "uploadThumbForm"
        2. The handler that uploads the crop changes to the server (from step 4) is "thumbsave.quosalweb" and the corresponding form form id is "saveThumbForm"
            - This hander will save the image to the user/quote field based on the name parameter the image being modified. 
     
*/

//object tied to an instance of a crop. Instantied when a crop is called.
function jCropWrapper(cropParams) {
    this.fixedAspectRatio = cropParams.fixedAspectRatio;
    this.imageEdited = cropParams.imageEdited;
    this.currentSRC = cropParams.originalSRC;
    this.originalSRC = cropParams.originalSRC;
    this.originalImg = cropParams.originalImg;
    this.heightParameter = cropParams.heightParameter;
    this.widthParameter = cropParams.widthParameter;
    this.imgID = cropParams.imgID;
    this.autoSave = cropParams.autoSave;
    this.imageTitle = cropParams.pictureTitle;
    this.jCropAPI = null;
    this.norescale = cropParams.norescale
    var self = this;

    this.resetCropParameters = function () {
        // update form data
        $("input[name='url']").val('');
        $("input[name='filePath']").val('');
        //update input data
        $('#userURL').val('');
        $('#fileSelector').val('');
        // update object data
        this.currentSource = '';
        this.originalSRC = '';
        this.originalImg = '';
        this.imageEdited = false;
        $('.imageContainer.resizer').html('<img id="target"/>');
    };
    this.checkImageDimensions = function (imageID) {
        var width = $('#' + imageID).width();
        var height = $('#' + imageID).height();
        if ((width > this.widthParameter) || (height > this.heightParameter)) {
            var aspectRatio = (width / height);
            if ((width / this.widthParameter) > (height / this.heightParameter)) {
                $("input[name='scale']").val(this.widthParameter / width);
                $('#' + imageID).width(this.widthParameter);
                $('#' + imageID).height(this.widthParameter / aspectRatio);
            } else {
                $("input[name='scale']").val(this.heightParameter / height);
                $('#' + imageID).height(this.heightParameter);
                $('#' + imageID).width((this.heightParameter * aspectRatio));
            }
        } else {
            $("input[name='scale']").val(1);
        }
    };

    this.resetCropWithNewURL = function (newImageSRC) {
        if (this.jCropAPI) {
            this.jCropAPI.release();
            this.jCropAPI.destroy();
        }
        $('.imageContainer.resizer').html('');
        $('#fileCancel').hide();
        $('#fileSelector').val('');
        if (newImageSRC == '') {
            this.imageEdited = false;
            $('#urlCancel').hide()
            this.currentSRC = this.originalSRC;
            $("input[name='url']").val('');
        }
        else {
            $('#urlCancel').show()
            this.imageEdited = true;
            $("input[name='url']").val(newImageSRC);
            this.currentSRC = newImageSRC;
        }

        $("input[name='filePath']").val('');
        $('.imageContainer.resizer').html('<img id="target"/>');
        $('.jcrop-preview').attr('src', this.currentSRC);
        $('.jcrop-preview,.preview').css({
            "height": 128,
            "width": 128,
            "margin-left": 0,
            "margin-top": 0
        });

        $('#target').attr('src', this.currentSRC).on("load", function () {
            self.checkImageDimensions("target");
            self.startCrop();
            if ($.queryString('skiplayout') == 'yes') {
                $.quosal.ui.positionIn($('#imageEditorWindow'), $('body'), 'top-center', 100);
            } else {
                $.quosal.ui.positionIn($('#imageEditorWindow'), $('body'), 'top-center', (($(window).height() - $('#imageEditorWindow').height()) / 2));
            }
        });
    };

    this.startCrop = function () {
        if ($('#target').attr('src') == 'images/empty.png') {
            $('.jcrop-preview,.preview').css({
                "height": 128,
                "width": 128,
                "margin-left": 0,
                "margin-top": 0
            });
            $("#imageAspectRatioDisplay").html("0.00");
            $('#imageEditorSave').addClass("disabled");

        }
        else {
            $('#imageEditorSave').removeClass("disabled");
            $('#target').Jcrop({
                onChange: this.updatePreview,
                onSelect: this.updatePreview
            },
            function () {
                self.jCropAPI = this;
                self.jCropAPI.setOptions({ bgColor: 'white' });
                $('#saveThumbForm input[name=wOriginal]').val(self.jCropAPI.getBounds()[0]);
                $('#saveThumbForm input[name=hOriginal]').val(self.jCropAPI.getBounds()[1]);
                self.jCropAPI.release();

                if (!self.fixedAspectRatio) {
                    self.jCropAPI.setOptions({ aspectRatio: 0 });
                    self.jCropAPI.animateTo([0, 0, self.jCropAPI.getBounds()[0], self.jCropAPI.getBounds()[1]]);
                }
                else {
                    self.jCropAPI.setOptions({ aspectRatio: 1 });
                    if (self.jCropAPI.getBounds()[0] > self.jCropAPI.getBounds()[1]) {
                        self.jCropAPI.animateTo([0, 0, self.jCropAPI.getBounds()[1], self.jCropAPI.getBounds()[1]]);
                    } else {
                        self.jCropAPI.animateTo([0, 0, self.jCropAPI.getBounds()[0], self.jCropAPI.getBounds()[0]]);
                    }
                }
            });
        }
    };

    this.updatePreview = function (c) {
        if (parseInt(c.w) > 0) {
            // Aspect Ratio calculations that enable the image preview to preserve the aspect ratio of the image.
            var aspectRatio = (c.w / c.h);
            $("#imageAspectRatioDisplay").html((aspectRatio).toFixed(2));
            var rx;
            var ry;
            var boundx = self.jCropAPI.getBounds()[0];
            var boundy = self.jCropAPI.getBounds()[1];
            if (c.w > c.h) {
                rx = (128 / c.w);
                ry = ((128 / aspectRatio) / c.h);
                $('.preview').css('width', 128);
                $('.preview').css('height', (128 / aspectRatio));
            }
            else {
                rx = ((128 * aspectRatio) / c.w);
                ry = (128 / c.h);
                $('.preview').css('height', 128);
                $('.preview').css('width', (128 * aspectRatio));
            }
            $('#preview').css({
                width: Math.round(rx * boundx) + 'px',
                height: Math.round(ry * boundy) + 'px',
                marginLeft: '-' + Math.round(rx * c.x) + 'px',
                marginTop: '-' + Math.round(ry * c.y) + 'px'
            });

            if (self.originalSRC == self.currentSRC && Math.abs(c.x2 - boundx) <= 1 && Math.abs(c.y2 - boundy) <= 1 && c.x == 0 && c.y == 0) {
                $('#imageEditorSave').addClass("disabled");
            }
            else {
                $('#imageEditorSave').removeClass("disabled");
            }
        }
        self.updateCoords(c);
    };
    // Update the crop coordinates for the actual crop.
    this.updateCoords = function (c) {
        $("input[name='x1']").val(Math.round(c.x));
        $("input[name='y1']").val(Math.round(c.y));
        $("input[name='x2']").val(Math.round(c.x2));
        $("input[name='y2']").val(Math.round(c.y2));
        $("input[name='w']").val(Math.round(c.w));
        $("input[name='h']").val(Math.round(c.h));
    };

    //initialization

    if ((this.originalSRC) == 'images/empty.png') {
        $('#imageEditorDelete').addClass("disabled");
        $("#imageEditorDelete").attr("title", "There is no image saved to the database.");
    }
    else {
        $('#imageEditorDelete').removeClass("disabled");
        $("#imageEditorDelete").attr("title", "Delete current image.");
    }
    $('.jcrop-preview').attr('src', this.originalSRC);
    $('#imageEditorWindow').show();
    $.quosal.ui.showMask();
    if ($.queryString('skiplayout') == 'yes') {
        $.quosal.ui.positionIn($('#imageEditorWindow'), $('body'), 'top-center', 100);
    } else {
        $.quosal.ui.positionIn($('#imageEditorWindow'), $('body'), 'top-center', (($(window).height() - $('#imageEditorWindow').height()) / 2));
    }
    var printedImageTitle = "";
    var imageTip = "";
    if (this.imageTitle.toLowerCase() == "reppicture") {
        printedImageTitle = "Rep Picture";
    }
    else if (this.imageTitle.toLowerCase() == "quotepicture") {
        printedImageTitle = "Quote Picture";
    }
    else if (this.imageTitle.toLowerCase() == "productpicture") {
        printedImageTitle = "Product Picture";
    }
    else if (this.imageTitle.toLowerCase() == "logo") {
        printedImageTitle = "Logo";
    }
    else if (this.imageTitle.toLowerCase() == "locationpicture") {
        printedImageTitle = "Location Picture";
    }
    else if (this.imageTitle.toLowerCase() == "tabpicture-picture") {
        printedImageTitle = "Tab Picture";
    }
    else if (this.imageTitle.toLowerCase() == "quotepicture-picture") {
        printedImageTitle = "Quote Picture";
    }
    else if (this.imageTitle.toLowerCase() == "quotetopicture") {
        printedImageTitle = "Customer Picture";
    }
    else if (this.imageTitle.toLowerCase() == "signature") {
        printedImageTitle = "Signature";
        imageTip = "The recommended aspect ratio (width to height) for signatures is approximately 4."
    }
    else {
        printedImageTitle = "Picture";
    }
    $(".imageTitleHelp").html(printedImageTitle + " information:");
    if (this.norescale) {
        $('#imageEditorWindow .tip').html(printedImageTitle + " Preview <br /><br />" + "This image will not be compressed to ensure highest possible quality.");
        $(".imageDataHelp").html("This image will not be compressed to ensure highest possible quality. <br /> <br />" + imageTip);
        //$.quosal.validation.validateField("imageTitle", "question", "This image will not be compressed to maintain image quality.");
    }
    else {
        $('#imageEditorWindow .tip').html(printedImageTitle + " Preview <br /><br />" + "This image will be compressed for optimum storage size.");
        $(".imageDataHelp").html("This image will be compressed for optimum storage size. Image resolution will be reduced to approximately 128 by 128 depending on aspect ratio.<br /> <br />" + imageTip);
        //$.quosal.validation.validateField("imageTitle", "question", "This image will be compressed for optimum storage size. Image quality may degrade.");
    }
    $("#imageEditor").find("[name='imageTitle']").html(printedImageTitle);
    //set load event for image
    $('#target').one('load', function () {
        self.checkImageDimensions("target");
        self.startCrop();
        if ($.queryString('skiplayout') == 'yes') {
            $.quosal.ui.positionIn($('#imageEditorWindow'), $('body'), 'top-center', 100);
        } else {
            $.quosal.ui.positionIn($('#imageEditorWindow'), $('body'), 'top-center', (($(window).height() - $('#imageEditorWindow').height()) / 2));
        }
    });
    $('#imageEditorSave').addClass("disabled");
    $('#target').attr('src', this.originalSRC);
}

function loadImageEditors() {
    function wrapEditableImages() {
        $('.thumbnail.editable').each(function () {
            $(this).before('<div class="imgWrapper"></div>');
            $(this).prev('.imgWrapper').prepend($(this));
            $(this).parent('.imgWrapper').append('<img class="imgOverlay" src="img/svgs/v1.0/Action_Edit.svg"/>');
        });
    };

    // BEGIN NORMAL FLOW
    $('#uploadThumbForm').attr('action', $('#uploadThumbForm').attr('action') + location.search + '&noheader=yes&nofooter=yes');
    $('#saveThumbForm').attr('action', $('#saveThumbForm').attr('action') + location.search + '&noheader=yes&nofooter=yes');
    wrapEditableImages();
    $('#fileCancel').hide();
    $('#urlCancel').hide();

    var cropInstance = null;

    //
    function restoreImageEditorState() {
        // reset ThumbnailSave form
        $("input[name='autoSave']").val(true);
        $("input[name='selectedThumbnail']").val("");
        $("input[name='scale']").val("");
        $("input[name='filePath']").val("");
        $("input[name='x1']").val("");
        $("input[name='y1']").val("");
        $("input[name='x2']").val("");
        $("input[name='y2']").val("");
        $("input[name='h']").val("");
        $("input[name='url']").val("");
        $("input[name='w']").val("");
        $("input[name='norescale']").val("");
        $("input[name='wOriginal']").val("");
        $("input[name='hOriginal']").val("");
        $("input[name='originalSource']").val("");
        $("input[name='pictureSelect']").val("");
        //reset all input fields on image editor
        $("#fileSelector").val("");
        $("#fileCancel").hide();
        $("#urlCancel").hide();
        $("#userURL").val("");

        // reset current display
        $(".imageContainer.resizer").html("<img id=\"target\"/>");
    }
    // When the overlay is clicked, reveal the image editor
    $('.imgWrapper').click(function () {
        $(this).children('.thumbnail.editable').each(function () {
            if ($(this).hasClass('norescale'))
                $('#saveThumbForm input[name=norescale]').val('true');
            else
                $('#saveThumbForm input[name=norescale]').val('');
        });
        var originalImg = $(this).parent().find('img').first();
        var imgID = $(this).find('img').first().attr('id');
        var originalSRC = originalImg.attr('src');
        var autosave;
        if (originalImg.attr('autosave') == 'false') {
            autoSave = false;
            $("input[name='url']").val(originalSRC);
        } else {
            $("input[name='url']").val('');
            autoSave = true;
        }
        $('#saveThumbForm input[name=originalSource]').val(originalSRC);
        $('#imageEditor input[name=selectedThumbnail]').val($(this).parent().find('img').attr('name'));
        var fixedAspectRatio = false;
        if ($("[name='fixAspectRatio']").prop('checked')) {
            fixedAspectRatio = true;
        }
        cropInstance = new jCropWrapper({
            fixedAspectRatio: fixedAspectRatio,
            imageEdited: false,
            originalSRC: originalSRC,
            originalImg: originalImg,
            //heightParameter: 480,
            //widthParameter: 695,
            norescale: $('#saveThumbForm input[name=norescale]').val(),
            heightParameter: 400,
            widthParameter: 600,
            imgID: imgID,
            autoSave: autoSave,
            pictureTitle: $('#imageEditor input[name=selectedThumbnail]').val()
        });

    });

    $("#importSignature").click(function () {
        $('#jSignature').jSignature("reset");
        $('#jSignature').html('');
        $('#jSigData').val('');
        $('.jSigWindow').hide();
        $('#saveThumbForm input[name=originalSource]').val($("img.signature").first());
        $('#imageEditor input[name=selectedThumbnail]').val("Signature");
        norescale: $('#saveThumbForm input[name=norescale]').val(true);
        var img, src, fixedAspectRatio;
        if ($("[name='fixAspectRatio']").prop('checked')) {
            fixedAspectRatio = true;
        }
        else {
            fixedAspectRatio = false;
        }
        if (!$("img.signature").first().hasClass('empty')) {
            img = $("img.signature").first();
            src = $("img.signature").first().attr('src');
        }
        else {
            img = null;
            src = "images/empty.png"
        }
        cropInstance = new jCropWrapper({
            fixedAspectRatio: fixedAspectRatio,
            imageEdited: false,
            originalSRC: src,
            originalImg: img,
            //heightParameter: 480,
            //widthParameter: 695,
            norescale: $('#saveThumbForm input[name=norescale]').val(),
            heightParameter: 400,
            widthParameter: 600,
            autoSave: true,
            pictureTitle: "Signature"
        });
    });

    //On hover, display the img Overlay
    $('.imgWrapper').hover(
		function () {
		    $(this).children('.imgOverlay').show();
		},
		function () {
		    $(this).children('.imgOverlay').hide();
		}
	);

    $("[name='fixAspectRatio']").change(function () {
        if (cropInstance.jCropAPI) {
            if ($(this).prop('checked')) {
                if (cropInstance.jCropAPI.getBounds()[0] > cropInstance.jCropAPI.getBounds()[1]) {
                    cropInstance.jCropAPI.animateTo([0, 0, cropInstance.jCropAPI.getBounds()[1], cropInstance.jCropAPI.getBounds()[1]]);
                } else {
                    cropInstance.jCropAPI.animateTo([0, 0, cropInstance.jCropAPI.getBounds()[0], cropInstance.jCropAPI.getBounds()[0]]);
                }
                cropInstance.jCropAPI.setOptions({ aspectRatio: 1 });
            } else {
                cropInstance.jCropAPI.animateTo([0, 0, cropInstance.jCropAPI.getBounds()[0], cropInstance.jCropAPI.getBounds()[1]]);
                cropInstance.jCropAPI.setOptions({ aspectRatio: 0 });
            }
        }
    });


    // If the mask or cancel button are clicked, cancel the jcrop and the image editor
    $("#imageEditorCancel").click(function () {
        if (cropInstance.jCropAPI) {
            cropInstance.jCropAPI.release();
            cropInstance.jCropAPI.destroy();
        }
        cropInstance.resetCropParameters();
        $('.imageContainer resizer').html('');
        $.quosal.ui.hideMask();
        $('.window').hide();
        restoreImageEditorState();
        cropInstance = null;
        return false;
    });

    $('#urlCancel').click(function () {
        $('#userURL').val('');
        $("input[name='url']").val('');
        cropInstance.resetCropWithNewURL('');
        $(this).hide();
    });

    $('#fileCancel').click(function () {
        $('#fileSelector').val('');
        $("input[name='filePath']").val('');
        cropInstance.resetCropWithNewURL('');
        $(this).hide();
    });

    //URL Handler
    $('#userURL').on('paste', function () {
        setTimeout(function () {
            $('#fileSelector').val('');
            $("input[name='filePath']").val('');
            $('#fileCanel').hide();
            cropInstance.resetCropWithNewURL($('#userURL').val());
        }, 100);
    });
    $('#userURL').keyup(function (e) {
        if (e.which == 13) {
            $('#fileSelector').val('');
            $("input[name='filePath']").val('');
            $('#fileCanel').hide();
            cropInstance.resetCropWithNewURL($('#userURL').val());
        }
    });

    $('[name=imageHelp]').click(function () {
        $('.window').toggleClass('belowmask', true);
        $.quosal.ui.positionIn($('#imageEditorHelp'), $('body'), 'top-center', ($(window).height() / 2 - $('#imageEditorWindow').height() / 2) - 5);
        $('#imageEditorHelp').show();
        $("#mask").css("opacity", .6);
        $("#mask,#imageEditorHelp").click(function () {
            $('.window').toggleClass('belowmask', false);
            $('#imageEditorHelp').hide();
            $("#mask").css("opacity", .3);
            $('#mask').unbind('click');
        });
    });

    // file validator and upload
    $('#fileSelector').change(function () {
        var fileToUpload = $(this).val();
        if (!fileToUpload) {
            $('#fileSelector').val('');
            $("input[name='filePath']").val('');
            cropInstance.resetCropWithNewURL('');
            $('#fileCancel').hide();
            return;
        }
        var fileType = fileToUpload.slice(fileToUpload.length - 3, fileToUpload.length).toLowerCase();
        var acceptableFileType = false;
        $('#fileCancel').show();
        $('.warning').html('');
        $('#userURL').val('');
        $('#urlCancel').hide();
        // if the file is good
        if ((fileType == 'jpg') || (fileType == 'gif') || (fileType == 'png')) {
            acceptableFileType = true;
            $("input[name='filePath']").val('true');
        }
        // if the file is bad
        if ((!acceptableFileType) && (fileType != '')) {
            $('.warning').append('<text>Warning! File Type is not the correct format. Please upload a png, gif, or jpg.</text>');
            return false;
        }
        // if no file was chosen
        if (fileType == '') {
            return false;
        }

        var cropperForm = $('#uploadThumbForm');

        //register form load event
        $('#imageRelayFrame').one('load', function () {
            var result = JSON.parse($('#imageRelayFrame').contents().find('body').text());
            if (result.error) {
                alert(result.error);

                $.quosal.dialog.hide();
                $.quosal.ui.showMask();
                $('.window').toggleClass('belowmask', false);
            } else {
                if (cropInstance.jCropAPI) {
                    cropInstance.jCropAPI.destroy();
                }
                $('#preview').attr('src', result.tempImage);
                $('#preview').attr('height', 128);
                $('#preview').attr('width', 128);
                $('.imageContainer.resizer').html('<img id="target"/>');
                $("input[name='filePath']").val(result.tempImage);
                $('#target').one('load', function () {
                    cropInstance.checkImageDimensions("target");
                    cropInstance.startCrop();
                    if ($.queryString('skiplayout') == 'yes') {
                        $.quosal.ui.positionIn($('#imageEditorWindow'), $('body'), 'top-center', 100);
                    } else {
                        $.quosal.ui.positionIn($('#imageEditorWindow'), $('body'), 'top-center', (($(window).height() - $('#imageEditorWindow').height()) / 2));
                    }

                });
                $('#target').attr('src', result.tempImage);
                cropInstance.currentSRC = result.tempImage;
                $.quosal.forms.saveCatch();

                $.quosal.dialog.hide();
                $.quosal.ui.showMask();
                $('.window').toggleClass('belowmask', false);
            }
        });
        $('.window').toggleClass('belowmask', true);
        cropperForm.submit();
    });

    $('#imageEditorDelete').click(function () {
        if ($(this).hasClass("disabled")) {
            return false;
        }
        $('.window').toggleClass('belowmask', true);
        $.quosal.dialog.show({
            title: 'Confirm Delete', message: "Are you sure you want to delete this image?",
            links: [
                    {
                        title: 'Yes',
                        callback: function () {
                            $.ajax('thumbclear.quosalweb' + location.search + '&noheader=yes', {
                                type: 'post',
                                dataType: 'json',
                                data: $('#saveThumbForm').serialize(),
                                error: $.quosal.validation.ajax,
                                success: function (result) {
                                    if (result.error) {
                                        $.quosal.dialog.show({ title: 'Error', message: result.error, links: [$.quosal.dialog.links.ok] });
                                    } else {
                                        //$("[name=" + cropInstance.originalImg.prop('name') + "]").each(function () {
                                        //$(this).attr('src', 'images/empty.png');
                                        //});
                                        $(cropInstance.originalImg).attr('src', "images/empty.png");
                                        cropInstance.originalImg.trigger('imagechange');
                                        $('#Thumbnail').val(result.imageUrl);
                                        if (cropInstance.jCropAPI) {
                                            cropInstance.jCropAPI.destroy();
                                        }
                                        if (cropInstance.imageTitle.toLowerCase() == "signature") {
                                            $('.signature').each(function () {
                                                if ($(this).attr('src') == 'images/empty.png') {
                                                    var container = $('<div class="signature container">Sign Here</div>');
                                                    $(this).before(container);
                                                    container.append(this);
                                                    $(this).attr('src', '');
                                                    $(this).addClass('empty');
                                                }
                                            });
                                            $('.signature').click(function () {
                                                $.quosal.ui.displayWindow($('.jSigWindow'));
                                                $.quosal.ui.showMask();
                                                $.quosal.ui.positionIn($('.jSigWindow'), $('body'), 'top-center', $(window).height() / 2 - $('.jSigWindow').height() / 2);
                                                $('#jSigData').val('');
                                                $('#jSignature').jSignature();
                                            });
                                        }
                                        cropInstance.resetCropParameters();
                                        restoreImageEditorState();
                                        cropInstance = null;
                                        $('.imageContainer resizer').html('');
                                        $.quosal.ui.hideMask();
                                        $('.window').hide();
                                        $.quosal.dialog.hide()
                                        $('.window').toggleClass('belowmask', false);
                                        return false;

                                    }
                                }
                            });
                        }
                    },
                    {
                        title: 'No',
                        callback: function () {
                            $('.window').toggleClass('belowmask', false);
                            $('#maindialog,').stop().fadeOut(null);
                        }
                    }
            ]
        });
    });

    $('#saveThumbForm').submit(function () {
        if (cropInstance.autoSave) {
            $("input[name='autoSave']").val('true');
        }
        else {
            $("input[name='autoSave']").val('false');
        }

        $('#imageRelayFrame').one('load', function () {
            $.quosal.forms.isSaving = false;
            var result = JSON.parse($('#imageRelayFrame').contents().find('body').text());
            if (result.error) {
                alert(result.error);
                $('.window').toggleClass('belowmask', false);

                $.quosal.dialog.hide();
                $.quosal.ui.showMask();
            } else {
                //cropInstance.originalImg.attr('src', result.imageUrl);
                if (cropInstance.imageTitle.toLowerCase() == "signature") {
                    $('#RepSignature').attr('src', result.imageUrl);
                    if ($('#RepSignature').hasClass('empty')) {
                        $('#RepSignature').removeClass('empty');
                        var container = $('#RepSignature').parent();
                        container.before($('#RepSignature'));
                        container.remove();
                    }
                }
                else {
                    //$("[name=" + cropInstance.originalImg.prop('name') + "]").each(function () {
                    //$(this).attr('src', result.imageUrl);
                    //});
                    $(cropInstance.originalImg).attr('src', result.imageUrl);
                    cropInstance.originalImg.trigger('imagechange');
                    $('#Thumbnail').val(result.imageUrl);
                }
                if (cropInstance.jCropAPI) {
                    cropInstance.jCropAPI.destroy();
                }
                $('.imageContainer resizer').html('');
                if ($.quosal.dialog.isVisible) {
                    $.quosal.dialog.hide();
                }
                restoreImageEditorState();
                cropInstance = null;
                $('.window').hide();
                $('.window').toggleClass('belowmask', false);
            }
            $('#saveThumbForm .btn.save').toggleClass('disabled', false);
            $('#saveThumbForm .btn.save .mid').text('Save');
        });
        $('.window').toggleClass('belowmask', true);
        $.quosal.dialog.loading();
        return true;
    });
}

$(document).ready(function () {
    if (!document.alreadyCalled) {
        loadImageEditors();
    }
    document.alreadyCalled = true;
});