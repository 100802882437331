/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/prefer-default-export */
import { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export function useSelectedProducts() {
	const [selectedProducts, setSelectedProducts] = useState([]);
	const getUrl = new URL(document.URL);
	const currentTabId = new URLSearchParams(getUrl.search).get("idquotetabs");

	const addProduct = useCallback((product, targetId = currentTabId) => {
		setSelectedProducts((prev) => {
			const filteredByMPN = prev.filter((item) => item.mpn === product.mpn);
			let filteredBySource = [];

			if (filteredByMPN.length) {
				filteredBySource = filteredByMPN.filter((item) => item.source === product.source);
			}

			if (filteredByMPN.length && !filteredBySource.length) {
				return [
					...prev,
					{
						...product,
						id: uuidv4(),
						targetTabId: targetId,
						quantity: 1,
						tabError: null,
						quantityError: null
					}
				];
			}

			if (filteredByMPN.length && filteredBySource.length) {
				return prev.map((prod) =>
					prod.mpn === filteredBySource[0].mpn &&
					prod.price === filteredBySource[0].price &&
					prod.source === filteredBySource[0].source
						? { ...prod, ...{ quantity: prod.quantity + 1, targetTabId: targetId } }
						: prod
				);
			}
			return [
				...prev,
				{
					...product,
					id: uuidv4(),
					targetTabId: targetId,
					quantity: 1,
					tabError: null,
					quantityError: null
				}
			];
		});
	}, []);

	const addProducts = useCallback((products) => {
		products.forEach((product) => {
			addProduct(product);
		});
	}, []);

	const deleteProduct = useCallback((productId, productSource) => {
		setSelectedProducts((prev) =>
			prev.filter(({ id, source }) =>
				id === productId
					? source !== productSource
					: id !== productId || source !== productSource
			)
		);
	}, []);

	const deleteAllProducts = useCallback(() => setSelectedProducts([]), []);

	const updateProduct = useCallback((dataToUpdate, productId, productSource) => {
		setSelectedProducts((prev) =>
			prev.map((product) =>
				product.id === productId && product.source === productSource
					? { ...product, ...dataToUpdate }
					: product
			)
		);
	}, []);

	return {
		selectedProducts,
		currentTabId,
		addProduct,
		addProducts,
		updateProduct,
		deleteProduct,
		deleteAllProducts
	};
}
