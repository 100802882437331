quosal.api.ingramQuote = {};

quosal.api.ingramQuote.searchQuotes = function (fromDate, toDate) {
    return quosal.api.call('Ingram.QuoteSearch', {
        fromDate: fromDate,
        toDate: toDate
    });
};

quosal.api.ingramQuote.previewQuoteDetails = function (quoteNumber) {
    return quosal.api.call('Ingram.PreviewQuoteDetails', {
        quoteNumber: quoteNumber
    });
};

quosal.api.ingramQuote.importQuote = function (ingramQuoteNumber, idQuoteMain, idQuoteTabs, suggestedItemGuids, useIngramExpirationDate) {
    return quosal.api.call('Ingram.ImportQuote', {
        ingramQuoteNumber: ingramQuoteNumber,
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        suggestedItemGuids: suggestedItemGuids,
        useIngramExpirationDate: useIngramExpirationDate
    });
};