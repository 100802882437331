import { CwAttachSiteMultiple } from "js/jsx/src/classes/crm/attachSiteMultiple.jsx";

export default function attachsites() {

    var renderEditForm = function () {
        quosal.ui.react.update(<CwAttachSiteMultiple onUpdate={() => renderEditForm()} />, document.getElementById('quoteModule'));
    }
    $('#pageName').val('crm.attach-sites');
    var getSitesApi = quosal.api.crm.getAdditionalSites(app.currentQuote.IdQuoteMain, null, null);
    quosal.ui.react.render(React.createElement(FormPlaceholder, { message: 'Loading Quote Content...' }), document.getElementById('quoteModule'));
    getSitesApi.finished = function (msg) {
        return quosal.ui.react.render(<CwAttachSiteMultiple sites={JSON.parse(msg.data.sites)} onUpdate={renderEditForm} />,
            document.getElementById('quoteModule'));
    }.bind(this);
    getSitesApi.call();
}