quosal.api.outputGridLayout = {};

//==========================================================//
//* CreateOutputGridLayout                                 *//
//==========================================================//
//==========================================================//
quosal.api.outputGridLayout.create = function () {
    return quosal.api.call('OutputGridLayout.CreateOutputGridLayout', {
    });
};

//==========================================================//
//* SaveOutputGridLayout                                   *//
//==========================================================//
//==========================================================//
quosal.api.outputGridLayout.save = function (fields, columns) {
    return quosal.api.call('OutputGridLayout.SaveOutputGridLayout', {
        IdOutputGridLayout: fields.IdOutputGridLayout,
        columns: columns,
        Name: fields.Name,
        IsActive: fields.IsActive,
        Notes: fields.Notes
    });
};

//==========================================================//
//* DeleteOutputGridLayout                                 *//
//==========================================================//
//==========================================================//
quosal.api.outputGridLayout.delete = function (IdOutputGridLayout) {
    return quosal.api.call('OutputGridLayout.DeleteOutputGridLayout', {
        IdOutputGridLayout: IdOutputGridLayout
    });
};

//==========================================================//
//* CopyOutputGridLayout                                   *//
//==========================================================//
//==========================================================//
quosal.api.outputGridLayout.copy = function (IdOutputGridLayout) {
    return quosal.api.call('OutputGridLayout.CopyOutputGridLayout', {
        IdOutputGridLayout: IdOutputGridLayout
    });
};

//==========================================================//
//* PreviewOutputGridLayout                                *//
//==========================================================//
//==========================================================//
quosal.api.outputGridLayout.preview = function (IdOutputGridLayout, quoteForm, searchQuoteNumber, wholeDigitCount, fractionalDigitCount) {
    return quosal.api.call('OutputGridLayout.PreviewOutputGridLayout', {
        IdOutputGridLayout: IdOutputGridLayout,
        quoteForm: quoteForm,
        searchQuoteNumber: searchQuoteNumber,
        wholeDigitCount: wholeDigitCount,
        fractionalDigitCount: fractionalDigitCount
    });
};