import {DateTimeInput} from "js/jsx/src/classes/forms/specialInputs.jsx";
import {Form} from "js/jsx/src/classes/forms.jsx";

export class ModificationTag extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var readOnly = quosal.util.quoteReadOnlyMode();
        var data = ModificationTagHelper.parseModificationTag(this.props.itemFieldContent, readOnly);
        var modTagComponent;
        if (data.error || readOnly){
            modTagComponent = <div dangerouslySetInnerHTML={{ __html: data.modifiedNotes }}></div>
        }
        else if (data.modificationTagList.length > 0) {

            modTagComponent = <div className='pointer-on-hover' onClick={() => ModificationTagInput.open(
                this.props.itemQuoteId, this.props.itemQuoteItemId, this.props.itemField, this.props.itemFieldContent, this.props.itemNavigationObject)} >
                <div className='modification-tag-edit' ></div>
                <div dangerouslySetInnerHTML={{ __html: data.modifiedNotes }}></div>
            </div>
        }
        else // No tag
        {
            modTagComponent = <div dangerouslySetInnerHTML={{ __html: this.props.itemFieldContent }}></div>
        }

        return (modTagComponent);
    }
};

class ModificationTagInput extends React.Component {
    constructor(props) {
        super(props);
        var currentModificationTagList = ModificationTagHelper.parseModificationTag(this.props.itemFieldContent).modificationTagList;
        var newModificationTagList = JSON.parse(JSON.stringify(currentModificationTagList));
        var modifiedContent = this.props.itemFieldContent;
        var newChanges = [];
        this.state = {
            newModificationTagList: newModificationTagList,
            currentModificationTagList: currentModificationTagList
        };

        this.saveChanges = this.saveChanges.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setValue = this.setValue.bind(this);
        this.validSettings = this.validSettings.bind(this);
    }

    static open(quoteId, quoteItemId, quoteItemField, quoteItemFieldContent, quoteItemNavigationObject) {
        var modificationTagComp;

        var saveChanges = function () {
            return modificationTagComp.saveChanges();
        }

        var dialogClose = function () {
            Dialog.close();
        }

        Dialog.open({
            height: '50%',
            width: "450px",
            title: 'Modification Tag',
            message: <ModificationTagInput itemQuoteId={quoteId}
                itemQuoteItemId={quoteItemId} itemField={quoteItemField} itemFieldContent={quoteItemFieldContent} itemNavigationObject={quoteItemNavigationObject}
                ref={comp => modificationTagComp = comp} />,
            closeRequiresButton: true,
            links: [{
                title: 'Save',
                callback: saveChanges
            }, {
                title: 'Cancel',
                callback: dialogClose
            }]
        });
    }
    handleInputChange(e) {
        var data = this.state.newModificationTagList;
        ModificationTagHelper.syncModificationTagList(data, e.target.id, e.target.value);
    }
    setValue(tagName, value, params) {
        var data = this.state.newModificationTagList;
        ModificationTagHelper.syncModificationTagList(data, tagName, value);
        this.setState({newModificationTagList: data});
    }
    validSettings() {
        var isValid = true;
        this.state.newModificationTagList.map(function (element) {
            var elementId = element.modificationTag.TagName;
            $.quosal.validation.clearField($('#' + elementId));
            isValid = $.quosal.validation.validateData.blank($('#' + elementId), 'error', 'Value cannot be empty') && isValid;
            if (element.modificationTag.FieldType === "Number") {
                isValid = $.quosal.validation.validateData.numeric($('#' + elementId), 'error', 'Value must be a number') && isValid;
            }
            if (element.modificationTag.FieldType === "Text") {
                isValid = $.quosal.validation.modificationTag.tag($('#' + elementId), 'error', '[[ or ]] are not allowed in the default value') && isValid;
            }
        })
        return isValid;
    }
    saveChanges(callback) {
        var content = this.props.itemFieldContent;
        var regexp  = /\[\[((.|\s)*?)\]\]/g;
       
        var match;
        var isModTagModified = false;
        var isDirty = false;

        if (!this.validSettings()) {
            return true;
        }

        // Determine if mod tag default value is changed 
        this.state.currentModificationTagList.forEach(element => {
            var currentValue = element.currentTagValue;
            var defaultValue = element.defaultTagValue;
            var data = this.state.newModificationTagList.where(x => x.modificationTag.TagName.toLowerCase() == element.modificationTag.TagName.toLowerCase()).firstOrNull();
            var newValue = data ? data.currentTagValue : "";

            if (data.modificationTag.FieldType == "Currency"){
                newValue = app.currentQuote.parseCurrency(newValue);

                newValue = app.currentQuote.formatCurrency(newValue);
                ModificationTagHelper.syncModificationTagList(this.state.newModificationTagList, data.modificationTag.TagName, newValue);
            }

            if (currentValue != newValue) {
                isDirty = true;
            }

            if (newValue != defaultValue) {
                isModTagModified = true;
            }
        });

        if (!isDirty) {
            Dialog.close();
            return;
        }

        while (match = regexp.exec(content)) {
            var tagKeyValue = match[1];
            var n = tagKeyValue.indexOf(":");
            var tagKey = n == -1 ? tagKeyValue : tagKeyValue.substring(0, n).trim();

            var data = this.state.newModificationTagList.where(x => x.modificationTag.TagName.toLowerCase() == tagKey.toLowerCase()).firstOrNull();
            if (data) {
                var obj = {"DefaultValue" : data.defaultTagValue, "CurrentValue": data.currentTagValue}
                var replaceValue = "[[" + data.modificationTag.TagName + ":" + JSON.stringify(obj) + "]]";             
                content = content.replace(match[0], replaceValue);
            }
        }

        var updatedValues = { ItemNotesHtml: content, IsModTagModified: isModTagModified };
        var updateApi = quosal.api.data.update({
            fields: updatedValues,
            queries: [{
                table: 'QuoteItems',
                where: [{
                    field: 'IdQuoteItems',
                    operator: 'Equals',
                    value: this.props.itemQuoteItemId
                }]
            }]
        }, this.props.itemQuoteId);
        updateApi.finished = function (msg) {
            quosal.sell.quote.updateFromApiResponse(msg);
            Dialog.close();
        }.bind(this);

        updateApi.call();
    }
    componentDidMount() {
        if (this.props.itemNavigationObject){        
            Form.unhookNavigationActions.call(this.props.itemNavigationObject)
        }
        
    }
    componentWillUnmount() {
        if (this.props.itemNavigationObject){       
            Form.hookUpNavigationActions.call(this.props.itemNavigationObject);
        }      
    }
    render() {
        var _this = this;
        var inputFields = [];

        this.state.newModificationTagList.map(function (element, index) {
            inputFields.push(<div key={"prompt" + index}>{element.modificationTag.TagDisplayPrompt}</div>)

            switch (element.modificationTag.FieldType) {
                case "Dropdown":
                    var dropdownContents = <select class="formselectfield" key={index} id={element.modificationTag.TagName} defaultValue={quosal.util.htmlDecode(element.currentTagValue)} onChange={_this.handleInputChange}>
                        {
                            element.modificationTag.TagDropdownContents.split(',').map(function (item, i) {
                                return (<option key={"option" + index + "_" + i} value={item.trim()}>{item.trim()}</option>);
                            })}
                    </select>;
                    inputFields.push(<div class="formselectfieldwrapper" key={"dropdown" + index} >{dropdownContents}</div>)
                    break;
                case "Date":
                    var inputElementProps = {
                        readOnly: true,
                        name: element.modificationTag.TagName,
                        id: element.modificationTag.TagName,
                        onChange: _this.handleInputChange.bind(_this),
                        value: element.currentTagValue,
                        dateFormat: ModificationTagHelper.getUserLongDateFormat()
                    };
                    inputFields.push(<div key={"date" + index}> <DateTimeInput key={index} id={element.modificationTag.TagName} inputElementProps={inputElementProps} setValue={_this.setValue.bind(_this, element.modificationTag.TagName)} ref="input" /> </div>)
                    break;
                default:
                     inputFields.push(<div key={"text" + index}><input className="modification-tag-field" type="text" key={index} id={element.modificationTag.TagName} onChange={_this.handleInputChange} defaultValue={quosal.util.htmlDecode(element.currentTagValue)} /></div>)
                    break;
            }
            inputFields.push(<br key={"br" + index} />)
        });

        return (
            <div key="modificationTagInputs" id="modificationTagInputs">{inputFields}
            </div>
        )
    }
};

// ####################################################################################################
// Modification tag helper 
// ####################################################################################################
class ModificationTagHelper {
    static parseModificationTag(rawData, readOnly) {

        var modificationTag = quosal.metadata.modificationTags.firstOrNull();
        var regexp = /\[\[((.|\s)*?)\]\]/g;
        var match;
        var modifiedNotes = rawData;
        var modificationTagList = [];
        var error = false;

        while (match = regexp.exec(rawData)) {

            var tagKeyValue = match[1];
            var n = tagKeyValue.indexOf(":");
            var tagName = n === -1 ? tagKeyValue : tagKeyValue.substring(0, n).trim();

            modificationTag = quosal.metadata.modificationTags.where(x => x.TagName.toLowerCase() == tagName.toLowerCase()).firstOrNull();

            if (modificationTag) {

                try{
                    var tagValue = n === -1 ? "" : tagKeyValue.substring(n + 1, tagKeyValue.length).trim();
                    var defaultTagValue = tagValue;
                    var currentTagValue = tagValue;
    
                    if (tagValue) {
                        var values = JSON.parse(tagValue);
    
                        if (values)
                        {
                            defaultTagValue = values.DefaultValue;
                            currentTagValue = values.CurrentValue;
                        }
                        
                    }
                    else
                    {
                        defaultTagValue = quosal.util.htmlEncode(modificationTag.TagDefaultValue);
                        currentTagValue = quosal.util.htmlEncode(modificationTag.TagDefaultValue);
                    }
    
                    var replaceValue = readOnly ? currentTagValue : "<span class='modification-tag'>" + currentTagValue + "</span>";
    
                    modifiedNotes = modifiedNotes.replace(match[0], replaceValue);
    
                    var dup = modificationTagList.where(x => x.modificationTag.TagName.toLowerCase() === tagName.toLowerCase()).firstOrNull();
    
                    if (!readOnly && dup == null) {
                        modificationTagList.push({ modificationTag: modificationTag, defaultTagValue: defaultTagValue, currentTagValue: currentTagValue });
                    }

                }
                catch (err)
                {
                    modifiedNotes = "<span class='validation error'></span>Error occurred during parsing data. Please contact your administrator to verify that content is in a correct format."
                    error = true;
                }           
            }
        }

        return { modifiedNotes: modifiedNotes, modificationTagList: modificationTagList , error: error};
    }

    static syncModificationTagList(list, tagName, tagValue) {

        var objIndex = list.findIndex(obj => obj.modificationTag.TagName.toLowerCase() === tagName.toLowerCase());

        if (objIndex >= 0 && list[objIndex].currentTagValue != tagValue.trim()) {
            list[objIndex].currentTagValue = quosal.util.htmlEncode(tagValue.trim());
            return true;
        }

        return false;
    }

    static getUserLongDateFormat() {
        var useLongDateFormat = 'MM dd, yy';
        var userDateFormat = app.settings.user.dateFormat.replace('m', 'MM');

        var separators = ['.', '\\/', '-'];
        var arr = userDateFormat.split(new RegExp('[' + separators.join('') + ']', 'g'));

        if (arr && arr.length > 0) {

            if (arr[0].indexOf('y') < 0) {
                useLongDateFormat = arr[0] + ' ' + arr[1] + ', ' + arr[2];
            }
            else {
                useLongDateFormat = arr[1] + ' ' + arr[2] + ', ' + arr[0];
            }
            return useLongDateFormat;
        }
    }
}