import { api } from "../api/api";
import { Autocomplete, Grid, IconButton, TextField, Typography } from "@mui/material";
import { DEBOUNCE } from "../constants/common";
import SearchIcon from '@mui/icons-material/Search';

export class SalesTracksSearch extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchPlaceHolder: "",
            options: [],
            results: [],
            value: null,
        };
    }

    render() {
        const { onFetchResults } = this.props;
        const { options, value, searchPlaceHolder } = this.state;

        const onFocus = () => this.setState({ ...this.state, searchPlaceHolder: "Search input text" });
        const onBlur = () => this.setState({ ...this.state, searchPlaceHolder: "" });
        const debouncedFetch = DEBOUNCE((request, callback) => {
            api.getAllSalesTracks(request, (msg) => {
                callback(msg.salesTracks);
            });
        });
        const fetchResults = (request, callback) => debouncedFetch(request, callback);
        const updateSuggestionsList = (InputValue) => {
            fetchResults(InputValue, (fetchedResults) => {
                let newOptions = [];
    
                if (value) {
                    newOptions = [value];
                }
    
                newOptions = (!!InputValue && fetchedResults) ? [...newOptions, ...fetchedResults] : [];
                this.setState({ ...this.state, options: newOptions });
    
                if (!InputValue || InputValue.length >= 3) {
                    onFetchResults && onFetchResults(fetchedResults);
                }
            });
        }

        return <>
            <div>
                <Autocomplete
                    className="salestrack-search-input"                        
                    fullWidth
                    disableClearable
                    popupIcon={null}
                    freeSolo={true}
                    blurOnSelect={true}
                    getOptionLabel={(option) => option.name || option}
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={value}
                    sx={{ height: "40px" }}
                    noOptionsText="No results"                                                
                    onChange={(event, newValue) => {
                        this.setState({ ...this.state,
                            options: newValue.name ? [newValue, ...options] : options,
                            value: newValue,
                        });
                        // newValue can be an object or a single string value.
                        updateSuggestionsList(newValue.name || newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                        updateSuggestionsList(newInputValue);
                    }}
                    renderInput={(params) => (
                        <div className="salestrack-search-input-container">
                            <TextField {...params} placeholder={searchPlaceHolder} onBlur={onBlur} onFocus={onFocus} />
                            <IconButton disabled sx={{ minWidth: '20px' }}>
                                <SearchIcon sx={{ width: '16px' }}/>
                            </IconButton>
                        </div>
                    )}
                    renderOption={(props, { name }) => {
                        return (
                        <li {...props}>
                            <Grid container alignItems="center">
                                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                    <Typography variant="body2" color="text.secondary">
                                        { name }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                        );
                    }}
                />
            </div>
        </>
    }
}