import {OpportunitySearchbox} from "js/jsx/src/classes/crm/opportunitySearchbox.jsx";
import {OpportunitySearchTable} from "js/jsx/src/classes/crm/opportunitySearchTable.jsx";

export class AutotaskOpportunitySearch  extends React.Component {
    constructor(props) {
        super(props);

        this.headerNames = [
           {name:"", propName:"", width:50, url:true}, 
           {name:"ID", propName:"Id", width:50}, 
           {name:"Title", propName:"Title", width:130},
           {name:"Stage", propName:"Stage", width:80}, 
           {name:"Status", propName:"Status", width:50},
           {name:"Amount", propName: "Amount", width:100}, 
        ]

        this.state = {
            criteria: this.getSavedSearchCriteria(), 
            isSearching: false,
        };
        // This binding is necessary to make `this` work in the callback
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.submitClick = this.submitClick.bind(this);
        this.getOpportunityStatuses = this.getOpportunityStatuses.bind(this);
        this.getOpportunityStageNames = this.getOpportunityStageNames.bind(this);
        this.getSearchCriteria = this.getSearchCriteria.bind(this);
        this.saveSearchCriteria = this.saveSearchCriteria.bind(this);
        this.resetSearchCriteria = this.resetSearchCriteria.bind(this);
    }
    componentDidMount(){
        document.addEventListener("keypress",  this.handleKeyPress)
    }
    componentWillUnmount(){
        document.removeEventListener("keypress",  this.handleKeyPress)
    }
    handleKeyPress(e){
        if(e.keyCode === 13)
        {
           this.submitClick();
        }
    }
    inputChanged(fieldName){
        var value = $("#"+fieldName).val();
        this.state.criteria[fieldName] = value;
        this.forceUpdate();
    }
    submitClick(){
        this.saveSearchCriteria();
        var me = this;
        var autotaskGetOpportunites = quosal.api.crm.autotask.getOpportunities(this.state.criteria)
        var onError = function(msg) {
            Dialog.open({
                message: msg.error,
                links: [{
                    title: 'OK',
                    callback: Dialog.close
                }]
            });
            me.setState({isSearching: false})
        
        };
        autotaskGetOpportunites.error = onError;
        autotaskGetOpportunites.finished = function (msg) {
            this.setState({opportunities: msg.data.opportunities, isSearching: false});
        }.bind(this);
        autotaskGetOpportunites.call();
        this.setState({isSearching: true})
    
    }  
    getOpportunityStatuses(){
        var optionsList = [<option key={"oppstatus_all"}>ALL</option>]
        var statuses = JSON.parse(this.props.data.statuses);
       optionsList.push(statuses.map(function(status, index){
            return <option value={status.Id ?? status.value} key={"opptstatus_" + index}>{status.Value ?? status.label}</option>
        }))
        return optionsList;
    }
    getOpportunityStageNames(){
        var optionsList = [<option key={"oppstage_all"}>ALL</option>]
        var stages = JSON.parse(this.props.data.stageNames);
       optionsList.push(stages.map(function(stage, index){
            return <option value={stage.Id ?? stage.value} key={"oppstage_" + index}>{stage.Value ?? stage.label}</option>
        }))
        return optionsList;
    }
    getSearchCriteria(){
        return [
            {fieldName: "title", displayName:"Opportunity Title", fieldType:"input", value: this.state.criteria["title"]},
            {fieldName: "accountName", displayName:"Account Name", fieldType:"input", value: this.state.criteria["accountName"]},
            {fieldName: "status", displayName:"Status", fieldType:"select", value: this.state.criteria["status"], options: this.getOpportunityStatuses()},
            {fieldName: "stage", displayName:"Stage Name", fieldType:"select", value: this.state.criteria["stage"], options: this.getOpportunityStageNames()},
            {fieldName: "salesRep", displayName:"Rep Name", fieldType:"input", value: this.state.criteria["salesRep"]},
        ]

    }
    saveSearchCriteria(){
        $.cookie('c_autotaskopportunitysearch_title', this.state.criteria.title, { expires: 14, path: "/" });
        $.cookie('c_autotaskopportunitysearch_account', this.state.criteria.accountName, { expires: 14, path: "/" });
        $.cookie('c_autotaskopportunitysearch_status', this.state.criteria.status, { expires: 14, path: "/" });
        $.cookie('c_autotaskopportunitysearch_stage', this.state.criteria.stage, { expires: 14, path: "/" });
        $.cookie('c_autotaskopportunitysearch_rep', this.state.criteria.salesRep, { expires: 14, path: "/" });
    }
    resetCookies(){
        $.cookie('c_autotaskopportunitysearch_title', "", { expires: 14, path: "/" });
        $.cookie('c_autotaskopportunitysearch_account',"", { expires: 14, path: "/" });
        $.cookie('c_autotaskopportunitysearch_status', "", { expires: 14, path: "/" });
        $.cookie('c_autotaskopportunitysearch_stage', "", { expires: 14, path: "/" });
        $.cookie('c_autotaskopportunitysearch_rep', "", { expires: 14, path: "/" });
    }
    resetSearchCriteria(){
        this.resetCookies();
        this.setState({criteria: this.getSavedSearchCriteria(), opportunities: null})
    }
    getSavedSearchCriteria(){
        var cookie = {}
        try{
            cookie.title = quosal.util.cookie('c_autotaskopportunitysearch_title');
            cookie.accountName = quosal.util.cookie('c_autotaskopportunitysearch_account');
            cookie.status = quosal.util.cookie('c_autotaskopportunitysearch_status');
            cookie.stage = quosal.util.cookie('c_autotaskopportunitysearch_stage');
            cookie.salesRep = quosal.util.cookie('c_autotaskopportunitysearch_rep');
        }
        catch(e){
            this.resetCookies();
            cookie.title = "";
            cookie.accountName = "";
            cookie.status = "";
            cookie.stage = "";
            cookie.salesRep = "";
        }
        return cookie;
    }
    render(){
        var spinnerArgs = {
            lines: 12,
            length: 12,
            width: 6,
            radius: 20
        };
        
        return(
            <div id="AutotaskOpportunitySearchId">
                <OpportunitySearchbox searchCriteria={this.getSearchCriteria()} resetClick={this.resetSearchCriteria} submitClick={this.submitClick} inputChanged={this.inputChanged} />
                <OpportunitySearchTable isSearching={this.state.isSearching} opportunities={this.state.opportunities} headerNames={this.headerNames} />
            </div>
        )
    }
}