import { QuoteCustomerSearch } from "js/jsx/src/classes/quote/quoteCustomerSearch.jsx";

export default function customersearch() {
    if (!app.currentQuote) {
        return;
    }

    quosal.ui.react.render(
        <FormPlaceholder message="Loading Customer Search..." />
        , document.getElementById('quoteModule'));

    var renderEditForm = function () {
        quosal.ui.react.update(<QuoteCustomerSearch quote={app.currentQuote}
            onUpdate={() => renderEditForm()} />, document.getElementById('quoteModule'));
    }
    quosal.ui.react.render(
        <QuoteCustomerSearch onUpdate={renderEditForm} quote={app.currentQuote} />,
        document.getElementById('quoteModule'));
    $('#pageName').val('quote.customersearch');
}