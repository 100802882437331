import { ProductEditForm } from "js/jsx/src/classes/product/productEditForm.jsx";

export default function edit() {

    var renderEditForm = function (renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;

        var product = app.currentQuote.Items.firstOrNull((s) => s.IdQuoteItems == quosal.util.queryString('itemid')) || {};
        var quoteTab = app.currentQuote.Tabs.firstOrNull(s => s.IdQuoteTabs == product.IdQuoteTabs);
        var formConfiguration = quosal.customization.forms.bindForm(quosal.customization.forms['ProductEdit'].Configurations[quosal.settings.getValue('ProductEdit_FormConfiguration') || 'Default'], product);

        if (formConfiguration == null || !formConfiguration.Panels) {
            formConfiguration = quosal.customization.forms.formConfigurationFix('ProductEdit', quosal.settings.getValue('ProductEdit_FormConfiguration'));
        }

        renderMethod(<ProductEditForm configuration={formConfiguration} product={product} quoteTab={quoteTab}
            onUpdate={() => renderEditForm()} />, document.getElementById('fluid_product-edit'));
    };

    var loadEditForm = function () {
        
        renderEditForm(quosal.ui.react.render);

        $('#pageName').val('product.edit');
    };

    var updateEditForm = function () {
        renderEditForm();
    };

    var changeFormConfiguration = function () {
        updateEditForm();
    };

    var unbindEvents = function () {
        quosal.customization.forms.configurationChanged.unbind(changeFormConfiguration);
        quosal.customization.forms.configurationUpdated.unbind(changeFormConfiguration);
        quosal.sell.quote.onUpdate.unbind(updateEditForm);
        quosal.navigation.onNavigate.unbind(unbindEvents);
    };

    quosal.navigation.onNavigate.bind(unbindEvents);
    quosal.customization.forms.configurationChanged.bind(changeFormConfiguration);
    quosal.customization.forms.configurationUpdated.bind(changeFormConfiguration);
    quosal.sell.quote.onUpdate.bind(updateEditForm);

    loadEditForm();
}