quosal.api.pricing = {};


//==========================================================//
//* GetPriceSourcing                                          *//
//==========================================================//
//==========================================================//
quosal.api.pricing.getPriceSourcing = function () {
    return quosal.api.call('Pricing.GetPriceSourcing');
};

//==========================================================//
//* LookupPricing                                          *//
//==========================================================//
//==========================================================//
quosal.api.pricing.lookupPricing = function (mfps, sources, idQuoteMain, forceEtilizeMetadata, lowestPriceOnly, mfpEtilizeIdPairs = null, skipEtilizeImageSearch = false) {
    return quosal.api.call('Pricing.LookupPricing', {
        mfps: mfps,        
        sources: sources,
        idQuoteMain: idQuoteMain,
        forceEtilizeMetadata: forceEtilizeMetadata,
        lowestPriceOnly: lowestPriceOnly,
        mfpEtilizeIdPairs: mfpEtilizeIdPairs,
        skipEtilizeImageSearch: skipEtilizeImageSearch
    });
};

quosal.api.pricing.etilizePrices = function (mfps, source, idQuoteMain) {
    return quosal.api.call('Pricing.EtilizePricing', {
        mfps: mfps,
        source: source,
        idQuoteMain: idQuoteMain
    });
};

quosal.api.pricing.etilizePricesWithSkus = function (mfps, skus, source, idQuoteMain, productIds) {
    return quosal.api.call('Pricing.EtilizePricing', {
        mfps: mfps,
        skus: skus,
        source: source,
        idQuoteMain: idQuoteMain,
        productIds: productIds
    });
};

quosal.api.pricing.etilizePricesWithSkusProductsId = function (mfps, skus, source, idQuoteMain, productIds) {
    return quosal.api.call('Pricing.EtilizePricing', {
        mfps: mfps,
        skus: skus,
        source: source,
        idQuoteMain: idQuoteMain,
        productIds: productIds
    });
};

quosal.api.pricing.updateCosts = function (param) {
    return quosal.api.call('Pricing.UpdateCosts', param);
};


quosal.api.pricing.abortOperation = function (opIdToCancel) {
    return quosal.api.call('Pricing.AbortOperation', { opIdToCancel: opIdToCancel });
};

