import { Step, StepButton, StepLabel, useTheme } from "@mui/material";
import { StepIcon } from "./StepIcon";

export const QuoteNavigationStep = ({ stepNumber, isActive, step, activeStepNumber, ...props }) => {
	const default_customerProvider = "Connectwise";
	const customerProvider = app.settings.user.customerProvider;
	const theme = useTheme();
	const { IsLocked, Name: stepLabel, Url } = step;
	const navigateToStep = () => {
		if (!IsLocked) {
			if (Url.indexOf(".quosalweb") >= 0) {
				quosal.navigation.navigate(Url);
			} else {
				if (app.currentModule.Type !== "QuoteDashboard") {
					if (customerProvider !== default_customerProvider && Url === "crm.eo-opportunity") {
						const url = quosal.util.url('electronicorder.quosalweb', 'idquotemain=' + props.quote.IdQuoteMain);
						quosal.navigation.navigate(url);
					} else {
						const href = quosal.util.url(
							"quote.dashboard",
							`submodules=${Url}`,
							"idquotemain=" + props.quote.IdQuoteMain
						);
						const a = document.createElement("a");
						a.href = href;
						document.body.appendChild(a);
						a.click();
					}
				} else {
					app.currentModule.loadSubModule(Url, {
						container: "quoteModule",
						unloadSubModules: true,
						query: "idquotemain=" + props.quote.IdQuoteMain + `&submodules=${Url}`
					});
				}
			}
		}
	};
	return (
		<Step key={stepLabel} data-testid="test-step" active={false} disabled={IsLocked}>
			<StepButton
				color="inherit"
				onClick={navigateToStep}
				data-testid="test-navigate"
				sx={{
					margin: 0,
					padding: 0,
					boxSizing: "border-box",
					cursor:'pointer',
				}}
			>
				<StepLabel
					sx={{
						"& .MuiStepLabel-label": {
							lineHeight: "22px",
							fontSize: 16,
							fontWeight: isActive ? 600 : 400,
							color: IsLocked
								? theme.palette.quote_stepper.disabledText
								: theme.palette.text.primary
						}
					}}
					StepIconComponent={() => (
						<StepIcon
							isCompleted={stepNumber < activeStepNumber}
							isActive={isActive}
							isDisabled={IsLocked}
						/>
					)}
				>
					{stepLabel}
				</StepLabel>
			</StepButton>
		</Step>
	);
};
