export const PS = {
	PACAKGE_SHOW_HEADER: "PackageShowHeader",
	PACAKGE_SHOW_ITEMS: "PackageShowItems",
	USE_STANDARD_PUBLISH_OUTPUT: "UseStandardPublishOutput",
	PACKAGE_PRINT_HEADER_PRICE: "PackagePrintHeaderPrice",
	PACKAGE_PRINT_ITEM_PRICE: "PackagePrintItemPrice",
	ORDER_PORTER_GROUP: "OrderPorterGroup",
	ORDER_PORTER_TEMPLATE: "OrderPorterTemplate",
	ORDER_PORTER_THEME: "OrderPorterTheme",
	WIN_FORM: "WinForm",
	APPROVAL_QUOTE_FORM: "ApprovalQuoteForm",
	PEER_REVIEW_DOCUMENT: "PeerReviewDocument",

	IS_SIGNER_CONFIRMATION_REQUIRED: "IsSignerConfirmationRequired",
	OP_ESIGN: "OrderPorterEsign",
	OP_SHOW_SIGNATURE: "OrderPorterShowSignature",
	OP_SHOW_TAB_CB: "OrderPorterShowTabCb",

	OP_SHOW_LINE_DETAILS: "OrderPorterShowLineDetails",
	OP_SHOW_IMAGE: "OrderPorterShowImage",
	OP_SHOW_ITEM_CB: "OrderPorterShowItemCb",
	OP_SHOW_QTY: "OrderPorterShowQty",

	TERMS_AND_CONDITIONS: "TermsAndConditions",

	OP_PAYMENT_MODE: "OrderPorterPaymentMode",
	OP_DOWNPAYMENT_PERCENT: "OrderPorterDownpaymentPercent",
	OP_DOWNPAYMENT_MINIMUM: "OrderPorterDownpaymentMinimum",
	OP_ORDER_PORTER_AMOUNT: "OrderPorterAmount"
};

export const ORDER_PORTER_THEME_OPTIONS = [
	"Aperture",
	"Banderole",
	"Boxx",
	"Carbon",
	"Chrome",
	"Circuit",
	"Cloud",
	"Columns",
	"Connect",
	"Default",
	"Empire",
	"Glass",
	"Hive",
	"Icon",
	"Origami",
	"Parallel",
	"Pixel",
	"Point",
	"Press",
	"Ribbon",
	"Ripple",
	"Silver",
	"Tornado",
	"Whirl"
];

export const ORDER_PORTER_THEME_OPTIONS_LABELs = [
	"Aperture (Legacy)",
	"Banderole (Legacy)",
	"Boxx",
	"Carbon (Legacy) ",
	"Chrome (Legacy)",
	"Circuit",
	"Cloud",
	"Columns",
	"Connect (Legacy)",
	"Default",
	"Empire",
	"Glass",
	"Hive",
	"Icon",
	"Origami (Legacy)",
	"Parallel",
	"Pixel",
	"Point",
	"Press (Legacy)",
	"Ribbon (Legacy)",
	"Ripple",
	"Silver",
	"Tornado",
	"Whirl"
];

export const LEGACY_THEMES = [
    "Aperture",
    "Banderole",
    "Carbon",
    "Chrome",
    "Connect",
    "Origami",
    "Press",
    "Ribbon"
];
