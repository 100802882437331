import {ActionsMenu} from "js/jsx/src/classes/menus.jsx";

// FSP 7/15/16: 8152123 Confirmed by the Snap meeting this morning, we don't want to see the old style quantity editor anywhere.
//              Just in case we ever change our minds, I don't want to throw away this code, but it is completely unused as of now.
export class QuantityEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            expanded: false
        }; 

        // This binding is necessary to make `this` work in the callback
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
    }
    static hideEditorOnClick(e) {
            if(QuantityEditor.activeEditor != null){
                if(e && e.target) {
                    var src = $(e.target);

                    if(src.hasClass('dialog quantity') || src.parents('.dialog.quantity').length > 0) {
                        return;
                    }
                }

                $(document.body).unbind('mouseup');

                QuantityEditor.activeEditor.setState({ expanded: false });
                QuantityEditor.previousEditor = ActionsMenu.activeEditor;
                QuantityEditor.activeEditor = null;

                setTimeout(function(){QuantityEditor.previousEditor = null;}, 10);
            }
    }
    onChange(newValue) {
        QuantityEditor.hideEditorOnClick();

        if(this.props.item[this.props.quantityField] != newValue) {
            if (this.props.onChange)
                this.props.onChange(this.props.item, newValue);
            else {
                this.props.item[this.props.quantityField] = newValue;
                this.forceUpdate();
            }
        }
    }
    onClick(e) {
        if(QuantityEditor.previousEditor == this){
            QuantityEditor.previousEditor = null;
            return;
        }

        var expanded = !this.state.expanded;
        this.setState({expanded: expanded});

        if(expanded) {
            QuantityEditor.activeEditor = this;

            $(document.body).bind('mouseup', QuantityEditor.hideEditorOnClick);
        } else
            QuantityEditor.activeEditor = null;
    }
    render() {
        var editor = null;

        if(this.state.expanded)
            editor = <QuantityEditorDialog onChange={this.onChange} item={this.props.item} quantityField={this.props.quantityField} />;

        return (
            <div>
                <div className="editor quantity" onClick={this.onClick}>
                    <div className="value">{this.props.item[this.props.quantityField]}</div>
                    <div className="icon numpad color"></div>
                </div>
                {editor}
            </div>
        );
    }
}
QuantityEditor.activeEditor= null;
QuantityEditor.previousEditor= null;

export class QuantityEditorDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {    
            initialState: true
        }; 

        // This binding is necessary to make `this` work in the callback
        this.onKeyDown = this.onKeyDown.bind(this); 
        this.changeQuantity = this.changeQuantity.bind(this);
        this.quantityButtonPressed = this.quantityButtonPressed.bind(this);
        this.validateQuantity = this.validateQuantity.bind(this);
        this.clearQuantity = this.clearQuantity.bind(this);
    }
    static width(){ return  135;}
    static height(){ return 140;}   
    onKeyDown(e) {
        if(e.keyCode == 13) {
            this.validateQuantity(this.refs.quantityInput);
        } else if(e.keyCode == 27) {
            if(this.props.cancelDialog) {
                this.props.cancelDialog(this);
            }
        } else if(this.state.initialState) {
            this.setState({initialState: false});
        }
    }
    changeQuantity(e) {
        if(e.target.value == 'OK')
            this.validateQuantity(this.refs.quantityInput);
        else
            this.validateQuantity(e.target);
    }
    quantityButtonPressed(e) {
        var val = e.target.value;

        if(this.state.initialState) {
            this.refs.quantityInput.value = val;

            this.setState({initialState: false});
        } else {
            this.refs.quantityInput.value += val;
        }

        this.refs.quantityInput.focus();
    }
    validateQuantity(target) {
        var val = parseFloat(target.value.replace(',', '.'));

        if(isNaN(val)) {
            target.value = this.props.item[this.props.quantityField];
            return;
        }

        if (this.props.onChange) {
            this.props.onChange(val);
        }
    }
    clearQuantity() {
        this.refs.quantityInput.value = 0;
        this.refs.quantityInput.select();

        this.setState({initialState: true});
    }
    componentDidMount() {
        this.refs.quantityInput.select();
    }
    render() {
        return (
            <div className="dialog quantity" style={this.props.style}>
                <div className="formfieldlabel"><label htmlFor="NewQuantity" className="formlabel">Enter a Quantity</label></div>
                <div className="formfield">
                    <input type="text" ref="quantityInput" name="NewQuantity" id="NewQuantity" title="Quote Name" maxLength="8"
                           onFocus={this.textFocused} onKeyDown={this.onKeyDown} defaultValue={this.props.item[this.props.quantityField]} />
                </div>
                <div style={{display:'inline-block', verticalAlign:'top'}}>
                    <input type="button" className="quantity-editor-btn" value="1" onClick={this.quantityButtonPressed} />
                    <input type="button" className="quantity-editor-btn" value="2" onClick={this.quantityButtonPressed} />
                    <input type="button" className="quantity-editor-btn" value="3" onClick={this.quantityButtonPressed} /><br />
                    <input type="button" className="quantity-editor-btn" value="4" onClick={this.quantityButtonPressed} />
                    <input type="button" className="quantity-editor-btn" value="5" onClick={this.quantityButtonPressed} />
                    <input type="button" className="quantity-editor-btn" value="6" onClick={this.quantityButtonPressed} /><br />
                    <input type="button" className="quantity-editor-btn" value="7" onClick={this.quantityButtonPressed} />
                    <input type="button" className="quantity-editor-btn" value="8" onClick={this.quantityButtonPressed} />
                    <input type="button" className="quantity-editor-btn" value="9" onClick={this.quantityButtonPressed} /><br />
                    <input type="button" className="quantity-editor-btn" style={{width:46}} value="0" onClick={this.quantityButtonPressed} />
                    <input type="button" className="quantity-editor-btn" value="." onClick={this.quantityButtonPressed} />
                </div>
                <div style={{display:'inline-block', verticalAlign:'top'}}>
                    <input type="button" style={{width:60, marginLeft:5, marginBottom:6, height:24}} value="OK" onClick={this.changeQuantity} /><br />
                    <input type="button" style={{width:60, marginLeft:5, marginBottom:6, height:24}} value="Clear" onClick={this.clearQuantity} /><br />
                    <input type="button" style={{width:60, marginLeft:5, marginBottom:6, height:24}} value="Cancel" onClick={this.props.cancelDialog} />
                </div>
                <div className={'point ' + this.props.pointer || 'left'}></div>
            </div>
        );
    }
}