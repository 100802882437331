import { COMMON_GRID_PADDING, EQUALS_LIMITS_VALUES_ERROR_MESSAGE, MANDATORY_FIELD_HELPER_TEXT, MAX_INT_VALUE, MAX_VALUE_ERROR_MESSAGE, MIN_NUMERIC_VALUE } from "../../../constants/createFormConstants";
import { Grid, FormControlLabel, Checkbox } from '@mui/material'
import { CommonInputTextStyled, CustomWidthTooltip } from "../../../../../common/guidedSelling/styledComponents/commonStyled";
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { AddIconButtonStyled, DeleteInputButtonStyled } from "../../../styledComponents/createFormStyled";
import FormContext from "../../../contexts/configureFormContext";
import { CommonSelect } from "../../../../../common/guidedSelling/components/commonSelect";
export class NumberRangeTypeForm extends React.Component {

    static contextType = FormContext;
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {

        const { handleValidateMandatoryInput, questionIndex, sectionIndex, question: { isActive, minValue, maxValue, isMultiplier, multipliers } } = this.props;
        const { formActions, getAllQuestionWithoutNumericField, isSendable } = this.context;
        const QUESTIONS_OPTIONS = getAllQuestionWithoutNumericField();

        const handleChange = (event) => {
            const { target: { checked, name } } = event;
                (multipliers.length === 0) ? formActions['addEmptyMultiplier'](sectionIndex, questionIndex) : formActions['common'](checked, name, sectionIndex, questionIndex);
        };

        const handleLimitChange = (event) => {
            const { target: { value, name } } = event;
            const intValue = parseInt(value);
            if ((intValue >= MIN_NUMERIC_VALUE && intValue <= MAX_INT_VALUE) || value === '') {
                const newValue = !!intValue ? intValue : value;
                formActions['common'](newValue, name, sectionIndex, questionIndex);
            };
        };

        const handleFocus = (event) => event.target.select();

        const handleValidateMinMaxValues = () => {
            if (!isSendable) {
                if (minValue > maxValue) {
                    return MAX_VALUE_ERROR_MESSAGE;
                } else if (minValue === maxValue) {
                    return EQUALS_LIMITS_VALUES_ERROR_MESSAGE;
                };
                return null;
            }
        };


        return <>
                    <Grid key={'form-section-' + sectionIndex + '-question-' + questionIndex} container item xs={12} p={COMMON_GRID_PADDING} justifyContent='space-between' alignItems='flex-start'>
                        <Grid item xs={2} pr='16px'>
                    <CommonInputTextStyled
                    // Display value as empty string if it is 0 to avoid displaying 0 in the input field
                            value={minValue == 0 ? '' : minValue}
                            type='number'
                            disabled={!isActive}
                            id={`min-value-section-${sectionIndex}-question-${questionIndex}`}
                            label='Min value'
                            haslimit={1}
                            minlimit={MIN_NUMERIC_VALUE}
                            maxlimit={MAX_INT_VALUE}
                            name='minValue'
                            onChange={handleLimitChange}
                            onFocus={handleFocus}
                            error={!!handleValidateMinMaxValues() ? !!handleValidateMinMaxValues() : handleValidateMandatoryInput(minValue)}
                            helperText={!!handleValidateMinMaxValues() ? handleValidateMinMaxValues() : handleValidateMandatoryInput(minValue) ? MANDATORY_FIELD_HELPER_TEXT : null}
                            />
                        </Grid>
                        <Grid item xs={2}>
                    <CommonInputTextStyled
                            value={maxValue == 0 ? '' : maxValue}
                            type='number'
                            disabled={!isActive}
                            id={`max-value-section-${sectionIndex}-question-${questionIndex}`}
                            label='Max value'
                            haslimit={1}
                            minlimit={MIN_NUMERIC_VALUE}
                            maxlimit={MAX_INT_VALUE}
                            name='maxValue'
                            onChange={handleLimitChange}
                            onFocus={handleFocus}
                            error={!!handleValidateMinMaxValues() ? !!handleValidateMinMaxValues() :  handleValidateMandatoryInput(maxValue)}
                            helperText={!!handleValidateMinMaxValues() ? handleValidateMinMaxValues() : handleValidateMandatoryInput(maxValue) ? MANDATORY_FIELD_HELPER_TEXT : null}
                            />
                        </Grid>
                        <Grid item container xs={8} justifyContent='center' alignItems='center'>
                        </Grid>
            </Grid>
            <Grid key={'form-section-' + sectionIndex + '-is-multiplier-' + questionIndex} item xs={12} p={COMMON_GRID_PADDING}>
                <FormControlLabel disabled={!isActive} control={<Checkbox name='isMultiplier' checked={isMultiplier} onChange={handleChange} />} label="Update Quantity for Linked Questions Below" />
            </Grid>
            {multipliers.length > 0 && multipliers.map((multiplierSelected, multiplierIndex) => 
                <Grid key={'form-section-' + sectionIndex + 'question' + questionIndex + 'link-section' + multiplierIndex} container item xs={12} p={COMMON_GRID_PADDING} justifyContent='space-between' alignItems='flex-start'>
                    <Grid key={'form-section-' + sectionIndex + 'question' + questionIndex + 'link-select-container' + multiplierIndex} item xs={10.6}>
                        <CommonSelect id={'form-section-' + sectionIndex + 'question' + questionIndex + 'link-select' + multiplierIndex} value={multiplierSelected.questionSortOrder} error={handleValidateMandatoryInput(multiplierSelected.questionSortOrder)} helperText={handleValidateMandatoryInput(multiplierSelected.questionSortOrder) ? MANDATORY_FIELD_HELPER_TEXT : null} disabled={!isMultiplier || !isActive} defaultValue='Choose an option' label="Link to Input Label/Question" name='inputLink' handleChange={(event) => formActions['edit-numeric-multipliers'](event.target.value, QUESTIONS_OPTIONS, sectionIndex, questionIndex, multiplierIndex)} options={QUESTIONS_OPTIONS} />
                    </Grid>
                    <Grid key={'form-section-' + sectionIndex + 'question' + questionIndex + 'link' + 'add-button'} container item xs={0.7} pl='8px' justifyContent='center' alignItems='center'>
                        <CustomWidthTooltip title='Add New Link'>
                            <AddIconButtonStyled disabled={!isMultiplier || !isActive} id={`add-link-button-section-${sectionIndex}-question-${questionIndex}-link-${multiplierIndex}`} onClick={() => formActions['addEmptyMultiplier'](sectionIndex, questionIndex)} ><AddIcon /></AddIconButtonStyled>   
                        </CustomWidthTooltip>
                    </Grid>
                    <Grid key={'form-section-' + sectionIndex + 'question' + questionIndex + 'link' + 'delete'} container item xs={0.7} justifyContent='center' alignItems='center'>
                        <CustomWidthTooltip title='Delete Link'>
                            <DeleteInputButtonStyled disabled={!isMultiplier || !isActive} id={`delete-link-button-section-${sectionIndex}-question-${questionIndex}-link-${multiplierIndex}`} onClick={() => formActions['delete-multiplier'](sectionIndex, questionIndex, multiplierIndex)} ><DeleteOutlineOutlinedIcon /></DeleteInputButtonStyled>    
                        </CustomWidthTooltip>
                    </Grid>
                </Grid>
            )
            }
        </>
    }
}