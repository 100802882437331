import { FormPlaceholder } from "js/jsx/src/classes/forms.jsx";
import { QuoteApprovalMain } from "js/jsx/src/classes/quote/quoteApproval.jsx";

export default function approval() {
    if (!app.currentQuote) {
        return;
    }

    quosal.ui.react.render(
        <FormPlaceholder message="Loading Quote Approval..." />
        , document.getElementById('quoteApprovalContainer'));

    var renderApprovalForm = function (renderMethod, triggeredRules, logMessages, approvalId, isApprover, 
        approvalChain, acceptRejectDisable, rulesCurrentUserDoesNotNeedToTakeAction, approvalsRequireComments) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;

        renderMethod(<QuoteApprovalMain quote={app.currentQuote} approvalChain={approvalChain} triggeredApprovalRules={triggeredRules} isApprover={isApprover} logMessages={logMessages}
            acceptRejectDisable={acceptRejectDisable} onUpdate={() => renderApprovalForm()} approvalNotificationId={approvalId} approvalsRequireComments={approvalsRequireComments}
            rulesCurrentUserDoesNotNeedToTakeAction={rulesCurrentUserDoesNotNeedToTakeAction}/>, 
            document.getElementById('quoteApprovalContainer'));
    };

    var loadApprovalForm = function (triggeredRules, logMessages, approvalId, isApprover, approvalChain, acceptRejectDisable, 
        rulesCurrentUserDoesNotNeedToTakeAction, approvalsRequireComments) {
            renderApprovalForm(quosal.ui.react.render, triggeredRules, logMessages, approvalId, isApprover, approvalChain, 
                acceptRejectDisable, rulesCurrentUserDoesNotNeedToTakeAction, approvalsRequireComments);
            $('#pageName').val('quote.approval');
        };
    
    quosal.util.cookie('quotenav', 'approval');

    var getRuleList = quosal.api.approval.GetTriggeredApprovalRules(app.currentQuote.IdQuoteMain);

    getRuleList.finished = function (msg) {
        var notificationDetailsData = JSON.parse(msg.notificationDetailsData);
        var approvalListData = JSON.parse(msg.approvalList);
        var logData = JSON.parse(msg.logs);
        var logs = logData ? logData : notificationDetailsData ? notificationDetailsData.Logs : [];
        var approvalIds = notificationDetailsData && notificationDetailsData.ApprovalNotificationId ? notificationDetailsData.ApprovalNotificationId : [];
        var isApprover = notificationDetailsData && notificationDetailsData.IsApprover ? notificationDetailsData.IsApprover : false;
        var triggeredRules = approvalListData ? approvalListData : notificationDetailsData && notificationDetailsData.TriggeredRules ? notificationDetailsData.TriggeredRules : []; //TODO: check if this disaplyes triggered rules proerly in all cases (isApprover vs standard user. Quote is in approval vs pre approval)
        var approvalChain = notificationDetailsData && notificationDetailsData.ApprovalChains ? notificationDetailsData.ApprovalChains : [];
        var acceptRejectDisable = notificationDetailsData && notificationDetailsData.DisableAcceptRejectButton ? notificationDetailsData.DisableAcceptRejectButton : false;
        var rulesCurrentUserDoesNotNeedToTakeAction = notificationDetailsData && notificationDetailsData.RulesCurrentUserDoesNotNeedToTakeAction ? notificationDetailsData.RulesCurrentUserDoesNotNeedToTakeAction : [];
        var approvalsRequireComments = msg.approvalsRequireComments;
        
        if (msg.asioTaxServiceError) {
            Dialog.open({
                title: 'Error Calculating Taxes',
                message: msg.asioTaxServiceError,
                links: [{title: 'OK', callback: Dialog.closeAll}]
            })            
        }
        if (msg.quote) {
            try {         
                msg.quote.ApprovalStatus = msg.approvalStatus;   
                quosal.sell.quote.updateFromApiResponse(msg);
            }
            catch {}
        }
        else {
            try {
                app.currentQuote.ApprovalStatus = msg.approvalStatus;
                quosal.sell.quote.update(app.currentQuote);
            } catch {}
        }

        loadApprovalForm(triggeredRules, logs, approvalIds, isApprover, approvalChain, acceptRejectDisable, rulesCurrentUserDoesNotNeedToTakeAction, approvalsRequireComments);
    }
    
    getRuleList.call();
}

global.approvalController = approval;