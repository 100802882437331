import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, IconButton, Typography } from "@mui/material";

export const SideFlyout = ({ width = "600px", title, open, onClose, children }) => {
	return (
		<Drawer
			PaperProps={{
				sx: { width: width, padding: "16px" }
			}}
			ModalProps={{
				sx: { zIndex: 9997 }
			}}
			anchor="right"
			open={open}
			onClose={onClose}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginBottom: "16px"
				}}
			>
				<Typography
					sx={{
						fontSize: "20px",
						lineHeight: "28px"
					}}
				>
					{title}
				</Typography>
				<IconButton
					sx={{
						minWidth: "12px",
						minHeight: "12px"
					}}
					size="small"
					onClick={onClose}
					disableRipple={true}
					data-testid='flyout-close-btn'
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			</Box>
			{children}
		</Drawer>
	);
};
