import {CustomizerFieldListItem} from "js/jsx/src/classes/forms/customization.jsx";
import {FormFieldInput} from "js/jsx/src/classes/forms.jsx";

export class OutputGridLayoutEditorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            layouts: []
        }

        this.addNewLayout = this.addNewLayout.bind(this);
        this.initializeColumnSorting = this.initializeColumnSorting.bind(this);
        this.updateColumnSorting = this.updateColumnSorting.bind(this);

        var initializeAPI = quosal.api.data.query({
            table: 'OutputGridLayout',
        });
        initializeAPI.finished = function (msg) {
            var results = msg.resultSets[0];
            results.sort(function (a, b) {
                return (a.Name === b.Name ? 0 : a.Name > b.Name ? 1 : -1);
            });
            this.setState({
                layouts: results,
                initialized: true
            }, this.initializeColumnSorting);
        }.bind(this);
        initializeAPI.call();
    }
    initializeColumnSorting () {
        $.quosal.ui.datagrid(this.refs.table);
    }
    updateColumnSorting () {
        $(this.refs.table).trigger('update');
    }
    addNewLayout () {
        var addLayoutAPI = quosal.api.outputGridLayout.create();
        Dialog.setIsWorking();
        addLayoutAPI.finished = function (msg) {
            this.state.layouts.push(msg.newLayout)
            this.setState({layouts: this.state.layouts}, function () {
                this.updateColumnSorting();
                this.refs[this.state.layouts.length - 1].onEditClick();
            }.bind(this));
            Dialog.setIsWorking(false);
        }.bind(this);
        addLayoutAPI.call();
    }
    updateLayoutTableRowView (index, newValues) {
        var layout = this.state.layouts[index];
        for (var key in newValues) {
            if (newValues.hasOwnProperty(key)) {
                layout[key] = newValues[key];
            }
        }
        this.refs[index].resetInputs(this.updateColumnSorting);
    }
    render() {
        if (!this.state.initialized) {
            return <FormPlaceholder message={'Loading Output Grid Layouts...'} />;
        }
        
        var rows = [];
        for (var i = 0; i < this.state.layouts.length; i++) {
            var loopLayout = this.state.layouts[i];
            rows[i] = <OutputGridLayoutTableRow key={loopLayout.IdOutputGridLayout} ref={i} index={i}
                                                layout={loopLayout}
                                                updateLayoutTableRowView={this.updateLayoutTableRowView.bind(this, i)}
                                                parent={this} ></OutputGridLayoutTableRow>
        }

        var titleChildren = (<div className="toolbar right"><Button onClick={this.addNewLayout} >Add</Button></div>);

        var panelExtraProps = {};
        if (this.state.indexOfCurrentEditingLayout != null) {
            panelExtraProps.style = { display: 'none' };
        }
        return (
            <div>
                { this.state.indexOfCurrentEditingLayout == null ? null :
                    <OutputGridLayoutEditor layout={this.state.layouts[this.state.indexOfCurrentEditingLayout]}
                                            updateLayoutTableRowView={this.updateLayoutTableRowView.bind(this, this.state.indexOfCurrentEditingLayout)}
                                            parent={this} />
                }
                <Panel title="Output Grid Layout" titleChildren={titleChildren} {...panelExtraProps} >
                    <PanelContent>
                        <table ref="table" className="datagrid editable" cellPadding="0" cellSpacing="0" >
                            <thead><tr>
                                <th className="nosort"></th>
                                <th>Active</th>
                                <th>Name</th>
                                <th>Notes</th>
                                <th className="nosort"></th>
                                <th className="nosort"></th>
                            </tr></thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                    </PanelContent>
                </Panel>

            </div>
        );
    }
}

class OutputGridLayoutTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.onEditClick = this.onEditClick.bind(this);
        this.doCopy = this.doCopy.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.confirmAndDelete = this.confirmAndDelete.bind(this);
        this.resetInputs = this.resetInputs.bind(this);
        this.changeField = this.changeField.bind(this);
        this.onIsActiveChange = this.onIsActiveChange.bind(this);
        this.onNameInputBlur = this.onNameInputBlur.bind(this);
        this.onNotesInputBlur = this.onNotesInputBlur.bind(this);
        this.state = {
            isWorking: false
        }
    }
    onEditClick () {
        this.props.parent.setState({
            indexOfCurrentEditingLayout: this.props.index
        });
    }
    doCopy (params) {
        params = params || {};
        this.setState({isWorking: true});
        var copyAPI = quosal.api.outputGridLayout.copy(this.props.layout.IdOutputGridLayout);
        copyAPI.finished = function (msg) {
            var newLayoutList = this.props.parent.state.layouts;
            newLayoutList.push(msg.layout);
            this.setState({isWorking: false});
            var callback = function (finalCallback) {
                this.props.parent.updateColumnSorting();
                if ('function' === typeof finalCallback) {
                    finalCallback()
                }
            }.bind(this, params.callback);
            this.props.parent.setState({
                layouts: newLayoutList
            }, callback);
        }.bind(this);
        copyAPI.call();
    }
    onCopyClick (e) {
        this.doCopy();
    }
    confirmAndDelete (params) {
        params = params || {};

        Dialog.confirmDelete({
            callback: function (params) {
                if ('function' === typeof params.afterDelete) {
                    params.afterDelete();
                }
                Dialog.close();
                this.setState({isWorking: true});
                var deleteAPI = quosal.api.outputGridLayout.delete(this.props.layout.IdOutputGridLayout);
                deleteAPI.finished = function () {
                    var newLayoutList = this.props.parent.state.layouts;
                    newLayoutList.splice(this.props.index, 1);
                    this.props.parent.setState({
                        layouts: newLayoutList
                    });
                }.bind(this);
                deleteAPI.call();
            }.bind(this, params),
            message: 'Are you sure you want to delete this Output Grid Layout, "' + this.props.layout.Name + '"?'
        });
    }
    onDeleteClick (e) {
        this.confirmAndDelete();
    }
    resetInputs (callback) {
        var newState = {
            Name: null,
            Notes: null
        };
        if ('function' === typeof callback) {
            this.setState(newState, callback);
        } else {
            this.setState(newState);
        }
    }
    onIsActiveChange (e) {
        this.changeField('IsActive', e.target.checked);
    }
    onNameInputBlur (e) {
        this.changeField('Name', e.target.value);
    }
    onNotesInputBlur (e) {
        this.changeField('Notes', e.target.value);
    }
    changeField (fieldName, newValue) {
        if (this.props.parent.state.layouts[this.props.index][fieldName] == newValue) {
            return;
        }

        this.setState({isWorking: true});
        var layout = { IdOutputGridLayout: this.props.layout.IdOutputGridLayout };
        layout[fieldName] = newValue;

        var saveAPI = quosal.api.outputGridLayout.save(layout, null);
        saveAPI.finished = function (fieldName, newValue) {
            var newValues = {};
            newValues[fieldName] = newValue;
            this.props.updateLayoutTableRowView(newValues);
            this.setState({isWorking: false});
        }.bind(this, fieldName, newValue);
        saveAPI.call();
    }
    render () {
        var nameValue = (this.state.Name != null) ? this.state.Name : this.props.layout.Name;
        var notesValue = (this.state.Notes != null) ? this.state.Notes : this.props.layout.Notes;
        return (
            <tr>
                <td width={1} ><CwWidgets.CwButton toolTip="Edit Layout" onClick={this.onEditClick} icon="img/svgs/v1.0/Action_Edit.svg" disabled={this.state.isWorking}/></td>
                <td width={1} className="center">
                    <input type="checkbox"
                           onChange={this.onIsActiveChange}
                           checked={this.props.layout.IsActive}
                           disabled={this.state.isWorking} />
                </td>
                <td width={500} className="center"><div className="formfield">
                    <input type="text"
                           style={{width:'100%'}}
                           onChange={function (e) { this.setState({Name: e.target.value}) }.bind(this)}
                           onBlur={this.onNameInputBlur}
                           value={nameValue}
                           maxLength={60}
                           disabled={this.state.isWorking} />
                </div></td>
                <td width={1000} ><div className="formfield">
                    <input type="text"
                           style={{width:'100%'}}
                           onChange={function (e) { this.setState({Notes: e.target.value}) }.bind(this)}
                           onBlur={this.onNotesInputBlur}
                           value={notesValue}
                           disabled={this.state.isWorking} />
                </div></td>
                <td width={1} ><CwWidgets.CwButton toolTip="Copy Layout" onClick={this.onCopyClick} icon="img/svgs/v1.0/Action_Copy.svg" disabled={this.state.isWorking}/></td>
                <td width={1} ><CwWidgets.CwButton toolTip="Delete Layout" onClick={this.onDeleteClick} icon="img/svgs/v1.0/Action_Delete.svg" disabled={this.state.isWorking}/></td>
            </tr>
        );
    }
}

class OutputGridLayoutEditor extends React.Component {
    constructor (props) {
        super(props);

        this.previewLayout = this.previewLayout.bind(this);
        this.onFieldInclusionChanged = this.onFieldInclusionChanged.bind(this);
        this.onLayoutChanged = this.onLayoutChanged.bind(this);
        this.returnToList = this.returnToList.bind(this);
        this.confirmThenLeave = this.confirmThenLeave.bind(this);
        this.saveLayout = this.saveLayout.bind(this);
        this.saveAndClose = this.saveAndClose.bind(this);
        this.deleteAndClose = this.deleteAndClose.bind(this);
        this.copyAndGoToCopy = this.copyAndGoToCopy.bind(this);
        this.initializeSortable = this.initializeSortable.bind(this);
        this.recalculateTotalWidth = this.recalculateTotalWidth.bind(this);

        this.state = {};

        var outputGridInitializeAPI = quosal.api.data.query([
            {
                table: 'OutputGridLayoutColumn',
                where: [{
                    field: 'IdOutputGridLayout',
                    operator: 'Equals',
                    value: this.props.layout.IdOutputGridLayout
                }]
            }
        ]);
        outputGridInitializeAPI.finished = function (msg) {
            var columns = msg.resultSets[0] || [];
            columns.sort((a, b) => (a.SortOrder - b.SortOrder));

            var fieldsIncluded = {};
            for (var i = 0; i < columns.length; i++) {
                fieldsIncluded[columns[i].FieldName] = true;
            }

            var totalWidth = 0;
            for (var i = 0; i < columns.length; i++) {
                totalWidth += (columns[i].Width || OutputGridLayoutColumn.defaultWidth);
            }
            this.setState({
                initialized: true,
                isDirty: false,
                savedColumns: columns,
                currentColumns: quosal.util.clone(columns, 1),
                fieldsIncluded: fieldsIncluded,
                totalWidth: totalWidth
            }, function () {
                $.quosal.quickfilter.wrapfieldsInit();

                var getLabelText = function (e) {
                    return e.children('label').text();
                };
                $.quosal.quickfilter.init('wrapfield', getLabelText);
                this.initializeSortable();
            }.bind(this));
        }.bind(this);
        outputGridInitializeAPI.call();
    }
    previewLayout () {
        var openPreviewDialog = function (){
            Dialog.open({
                title: 'Preview Layout: ' + this.props.layout.Name,
                draggable: true,
                height: '400px',
                width: '500px',
                message: <OutputGridLayoutPreviewDialog IdOutputGridLayout={this.props.layout.IdOutputGridLayout} />,
                links: [Dialog.links.close]
            });
        }.bind(this);
        if (this.state.isDirty) {
            this.saveLayout({callback: openPreviewDialog});
        } else {
            openPreviewDialog();
        }
    }
    initializeSortable () {
        var gridContainer = $(this.refs.gridContainer);
        gridContainer.sortable({
            axis: 'x',
            distance: 10,
            handle: '.column-sorter',
            update: function(e, ui) {
                this.onLayoutChanged();
            }.bind(this)
        });
    }
    onLayoutChanged () {
        this.setState({
            isDirty: true
        });
    }
    returnToList (callback) {
        var newState = {
            indexOfCurrentEditingLayout: null
        };
        if ('function' === typeof callback) {
            this.props.parent.setState(newState, callback);
        } else {
            this.props.parent.setState(newState);
        }
    }
    confirmThenLeave(leaveFunction) {
        if ('function' !== typeof leaveFunction) {
            leaveFunction = this.returnToList
        }

        if (this.state.isDirty) {
            Dialog.confirmDelete({
                title:'Changes will be lost',
                width: '400px',
                links:[
                    {title: 'Save Changes', callback: this.saveLayout.bind(this, {returnToList:leaveFunction})},
                    {title: 'Discard Changes', callback: leaveFunction.bind(this, Dialog.close)},
                    {title: "Cancel", callback: Dialog.close}
                ],
                message: 'You have unsaved changes. Are you sure you want to close the layout editing page?'
            });
        } else {
            leaveFunction();
        }
    }
    saveLayout (params) {
        params = params || {};

        var layoutId = this.props.layout.IdOutputGridLayout;

        var fieldNameToColumnComponent = {};
        for (var i = 0; i < this.state.currentColumns.length; i++) {
            var loopComponent = this.refs[i]
            fieldNameToColumnComponent[loopComponent.props.column.FieldName] = loopComponent;
        }

        var columns = [];
        $('#gridCustomizer .gridcolumn').each(function (outputGridLayoutEditor, index, element) {
            columns.push(fieldNameToColumnComponent[element.id].getData());
        }.bind(this, this));

        var layout = {
            IdOutputGridLayout: layoutId,
            Name: this.refs.layoutName.value,
            IsActive: this.refs.layoutIsActiveCheckbox.checked,
            Notes: this.refs.layoutNotes.value
        }

        var saveAPI = quosal.api.outputGridLayout.save(layout, columns);
        saveAPI.finished = function (layout, columns, returnToList, callback, msg) {
            this.props.updateLayoutTableRowView(layout);
            Dialog.setIsWorking(false);
            if ('function' === typeof returnToList) {
                Dialog.close();
                returnToList();
            } else {
                this.setState({
                    isDirty: false,
                    currentColumns: columns
                }, function () {
                    for(var i = 0; i < this.state.currentColumns.length; i++) {
                        this.refs[i].reset();
                    }
                    if ('function' === typeof callback) {
                        callback();
                    }
                }.bind(this));
            }
        }.bind(this, layout, columns, params.returnToList, params.callback);
        Dialog.setIsWorking();
        saveAPI.call();
    }
    saveAndClose () {
        this.saveLayout({returnToList:this.returnToList});
    }
    deleteAndClose () {
        this.props.parent.refs[this.props.parent.state.indexOfCurrentEditingLayout].confirmAndDelete({afterDelete: this.returnToList})
    }
    copyAndGoToCopy () {
        var doAfterConfirmation = function (callback) {
            if ('function' === typeof callback) {
                callback();
            }
            var doCopy = function (indexOfCurrentEditingLayout) {
                var newLayoutIndex = this.props.parent.state.layouts.length;
                var params = {
                    callback: function (newLayoutIndex) {
                        this.props.parent.setState({indexOfCurrentEditingLayout: newLayoutIndex})
                    }.bind(this, newLayoutIndex)
                };
                this.props.parent.refs[indexOfCurrentEditingLayout].doCopy(params);
            }.bind(this, this.props.parent.state.indexOfCurrentEditingLayout);
            this.returnToList(doCopy);
        }.bind(this)

        this.confirmThenLeave(doAfterConfirmation)
    }
    onFieldInclusionChanged (e) {
        var includeField = e.target.checked;
        var fieldName = e.target.value;

        var currentColumns = this.state.currentColumns;

        if(includeField) {
            this.state.fieldsIncluded[fieldName] = true;
            currentColumns.push({
                FieldName: fieldName,
                DisplayName: this.refs['FIELD_' + fieldName].getDisplayName(),
                TextAlignment: OutputGridLayoutColumn.getDefaultTextAlignment(fieldName)
            })
        } else {
            delete this.state.fieldsIncluded[fieldName]
            currentColumns = currentColumns.filter(x => (x.FieldName != fieldName))
        }

        this.setState({
            currentColumns: currentColumns,
            fieldsIncluded: this.state.fieldsIncluded,
        }, function () {
            this.recalculateTotalWidth();
        }.bind(this));

    }
    recalculateTotalWidth () {
        var totalWidth = 0;
        for (var i = 0; i < this.state.currentColumns.length; i++) {
            totalWidth += this.refs[i].state.Width;
        }
        this.setState({
            totalWidth: totalWidth
        });
        this.onLayoutChanged();
    }
    componentDidMount () {
        $('.menu-level1.selected .menulabel').off('click').click(function (e) {
            e.stopImmediatePropagation();
            this.confirmThenLeave();
        }.bind(this));
    }
    componentWillUnmount () {
        $('.menu-level1.selected .menulabel').off('click').click(function (e) {
            e.stopImmediatePropagation();
        });
    }
    render () {
        if (!this.state.initialized) {
            return <FormPlaceholder message={'Loading Output Grid Layout Editor...'} />;
        }

        var inputDisabled = false;


        var fieldNameToColumnDisplayName = {};
        var outputGridLayoutColumns = [];
        for(var i = 0; i < this.state.currentColumns.length; i++) {
            var column = this.state.currentColumns[i];
            fieldNameToColumnDisplayName[column.FieldName] = column.DisplayName;
            outputGridLayoutColumns.push(
                <OutputGridLayoutColumn ref={i}
                                        index={i}
                                        key={column.FieldName}
                                        column={column}
                                        totalWidth={this.state.totalWidth}
                                        parent={this} />
            );
        }

        var fields = quosal.customization.fields['BusinessObject']['QuoteItems'];

        var gridFields = [];
        
        var allFields = fields.standardFields.concat(fields.additionalFields);
        allFields.sort(function(a,b) { return a.FieldName.charCodeAt(0) - b.FieldName.charCodeAt(0) });
        allFields = [{
            FieldName: 'ProductDetails',
            DisplayName: 'Product Details'
        }].concat(allFields);


        //Render field list
        for(var i = 0; i < allFields.length; i++) {
            var field = allFields[i];
            if(field.IsPrivateField || field.FieldName === 'ItemNotes'){
                continue;
            }
            var fieldConfig = fields.fieldConfigurations[field.FieldName];
            var fieldSelected = this.state.fieldsIncluded[field.FieldName]
            var fieldState = fieldSelected ? 'CHECKED' : '';

            gridFields.push(
                <CustomizerFieldListItem key={'FIELD_' + field.FieldName}
                                         ref={'FIELD_' + field.FieldName}
                                         isDisabled={inputDisabled}
                                         className={fieldState}
                                         onChange={this.onFieldInclusionChanged}
                                         fieldSelected={fieldSelected}
                                         field={field}
                                         fieldConfig={fieldConfig}
                                         overrideRename={fieldNameToColumnDisplayName[field.FieldName] || null}
                                         canCustomizeDropdown={false} />
            );
        }

        var previewButton = (this.state.isDirty ?
            <SaveButton style={{float:'right', marginRight:10}} onClick={this.previewLayout} text="Preview" /> :
            <Button style={{float:'right', marginRight:10}} onClick={this.previewLayout} >Preview</Button> )
        
        return(
            <div>
                <Panel>
                    <PanelToolbar style={{display:'block'}}>
                        <SaveButton text="Save and Close" style={{float:'right'}} disabled={!this.state.isDirty} isSaving={Dialog.isWorking()} onClick={this.saveAndClose} />
                        <SaveButton style={{float:'right', marginRight:10}} disabled={!this.state.isDirty} isSaving={Dialog.isWorking()} onClick={this.saveLayout} />
                        <Button type="cancel" style={{float:'right', marginRight:10}} onClick={this.confirmThenLeave}>Cancel</Button>
                        <Button style={{float:'right', marginRight:10}} onClick={this.deleteAndClose} >Delete</Button>
                        <Button style={{float:'right', marginRight:10}} onClick={this.copyAndGoToCopy} >Copy</Button>
                        {previewButton}
                    </PanelToolbar>
                </Panel>
                <Panel title="Layout Metadata" >
                    <PanelContent>
                        <div className="formcolumn">
                            <div className="formfieldlabel"><label htmlFor="layoutName" className="standardformlabel">Layout Name</label></div>
                            <div className="formfield">
                                <input type="text" ref="layoutName" id="layoutName" disabled={inputDisabled} title="Layout Name" maxLength={60} defaultValue={this.props.layout.Name}
                                       onChange={this.onLayoutChanged} />
                            </div>
                        </div>
                        <div className="formcolumn">
                            <div className="formcheckboxwrapper">
                                <input ref="layoutIsActiveCheckbox" id="layoutIsActiveCheckbox" type="checkbox" defaultChecked={this.props.layout.IsActive} disabled={inputDisabled}
                                       onChange={this.onLayoutChanged} />
                                <label htmlFor="layoutIsActiveCheckbox" className="formfieldlabel">Active</label>
                            </div>
                        </div>
                        <div className="formcolumn">
                            <div className="formfieldlabel"><label htmlFor="layoutNotes" className="standardformlabel">Notes</label></div>
                            <div className="formfield">
                                <input type="text" ref="layoutNotes" id="layoutNotes" disabled={inputDisabled} title="Notes" defaultValue={this.props.layout.Notes}
                                       onChange={this.onLayoutChanged} />
                            </div>
                        </div>
                    </PanelContent>
                </Panel>
                <Panel>
                    <PanelTitle>
                        <span style={{marginLeft:5}}>Edit Layout</span>
                        <input type="text" disabled={inputDisabled} className="quicksearch_wrapfields" />
                    </PanelTitle>

                    <PanelContent>
                        <div id="gridCustomizer" ref="gridContainer">
                            {outputGridLayoutColumns}
                        </div>
                        <div id="itemfields">
                            {gridFields}
                        </div>
                    </PanelContent>
                </Panel>
            </div>
        );
    }
}

class OutputGridLayoutColumn extends React.Component {
    constructor(props) {
        super(props);

        this.resetColumnSize = this.resetColumnSize.bind(this);
        this.initialState = this.initialState.bind(this);
        this.makeColumnResizable = this.makeColumnResizable.bind(this);
        this.reset = this.reset.bind(this);
        this.getData = this.getData.bind(this);
        this.getDisplayName = this.getDisplayName.bind(this);
        this.getFallbackDisplayName = this.getFallbackDisplayName.bind(this);
        this.startRename = this.startRename.bind(this);
        this.finishRename = this.finishRename.bind(this);
        this.onRenameInputKeyPress = this.onRenameInputKeyPress.bind(this);
        this.openDetailedColumnEditModal = this.openDetailedColumnEditModal.bind(this);

        this.state = this.initialState();
    }
    initialState() {
        return {
            DisplayName: this.props.column.DisplayName || this.props.column.FieldName,
            Width: this.props.column.Width || OutputGridLayoutColumn.defaultWidth,
            TextAlignment: this.props.column.TextAlignment,
            IsBold: this.props.column.IsBold,
            IsItalic: this.props.column.IsItalic,
            IsUnderline: this.props.column.IsUnderline
        };
    }
    static percentDisplay(columnWidth, totalWidth) {
        var widthText = '' + Math.round(columnWidth * 1000 / totalWidth) + '%';
        widthText = widthText.slice(0, -2) + '.' + widthText.slice(-2);
        return widthText
    }
    static getDefaultTextAlignment(fieldName) {
        if (fieldName === "Quantity" || fieldName === "PackageQty") {
            return 'center';
        }

        var field = quosal.schema.BusinessObject.QuoteItems[fieldName];
        var type = (field && field.DataType) || null;

        if (type == null) {
            return '';
        } else if (type == 'Int32') {
            return 'center';
        } else if (type == 'String') {
            return 'left';
        } else if (type === 'DateTime' || type === 'Double') {
            return 'right';
        }

        return '';
    }
    reset () {
        this.setState(this.initialState());
    }
    makeColumnResizable () {
        $(this.refs.root).resizable({
            handles: 'e',
            resize: function(e, ui) {
                this.setState({Width: ui.size.width}, function () {
                    this.props.parent.recalculateTotalWidth();
                }.bind(this));
            }.bind(this),
        });
    }
    componentDidMount () {
        this.makeColumnResizable();
    }
    resetColumnSize () {
        this.setState({
            Width: OutputGridLayoutColumn.defaultWidth
        }, function () {
            this.props.parent.recalculateTotalWidth();
        }.bind(this));
    }
    getData () {
        return {
            FieldName: this.props.column.FieldName,
            DisplayName: this.state.DisplayName,
            Width: this.state.Width,
            TextAlignment: this.state.TextAlignment,
            IsBold: this.state.IsBold,
            IsItalic: this.state.IsItalic,
            IsUnderline: this.state.IsUnderline
        };
    }
    getDisplayName () {
        return this.state.DisplayName || this.props.column.FieldName;
    }
    getFallbackDisplayName () {
        return this.props.parent.refs['FIELD_' + this.props.column.FieldName].getDisplayNameBeforeOverride();
    }
    startRename () {
        this.setState({
            nameEditMode: true
        }, function () {
            this.refs.renameInput.select();
        }.bind(this));
    }
    finishRename (e) {
        var newValue = e.target.value || this.getFallbackDisplayName();

        this.setState({
            DisplayName: newValue,
            nameEditMode: false
        }, function () {
            this.props.parent.state.currentColumns[this.props.index].DisplayName = this.state.DisplayName;
            this.props.parent.setState({
                currentColumns: this.props.parent.state.currentColumns
            });
            this.props.parent.onLayoutChanged()
        }.bind(this));
    }
    onRenameInputKeyPress (e) {
        if(e.which == 13) {
            this.finishRename(e);
        }
    }
    openDetailedColumnEditModal () {
        var root = $(this.refs.root);
        var offset = root.offset();
        var top = offset.top;
        var right = offset.left + root.width();

        Dialog.open({
            title: 'Edit Column Attributes',
            width: OutputGridLayoutColumnDetailEditor.width + 'px',
            height: OutputGridLayoutColumnDetailEditor.height + 'px',
            top: top - 60,
            left: right - 240,
            draggable: true,
            message: (
                <OutputGridLayoutColumnDetailEditor column={this.getData()}
                                                    parent={this} />
            ),
            //links: [Dialog.links.cancel]
        });
    }
    render () {
        var col = this.props.column;
        var displayName = this.getDisplayName();
        var fieldLabel = ( this.state.nameEditMode ?
            <div className="formField" style={{height: 17}}>
                <input type="text"
                       ref="renameInput"
                       defaultValue={this.state.DisplayName}
                       onBlur={this.finishRename}
                       onKeyPress={this.onRenameInputKeyPress}
                       maxLength={50}
                       style={{zIndex: 11000, position: 'absolute', left: 15, top: 3}} />
            </div>:
            <div className="columnname" title={displayName} data-field={this.props.column.FieldName}
                 onClick={this.startRename} >{displayName}</div> );

        var updatedWidth = this.state.Width;
        var savedWidth = this.props.column.Width;
        var defaultWidth = OutputGridLayoutColumn.defaultWidth;
        var hasUpdatedWidth = updatedWidth && (updatedWidth != (this.props.column.Width || defaultWidth));

        if (savedWidth) {
            hasUpdatedWidth = (savedWidth !== updatedWidth);
        }


        var colStyle = {
            width: updatedWidth || 60,
            height:  45
        };

        var columnSizeClass = 'columnsize';
        var widthText = this.state.Width;
        if (this.props.totalWidth) {
            widthText = OutputGridLayoutColumn.percentDisplay(this.state.Width, this.props.totalWidth);
        }
        if(hasUpdatedWidth) {
            columnSizeClass += ' updatedValue';
        }

        if(this.props.column.Width && this.props.column.Width != defaultWidth) {
            columnSizeClass += ' userValue';
        }

        //FSP 12/4/18 11097345 - Editing capabilities for additional column details temporarily disabled until OP & form templates are ready. Add this back to the span when ready.
        //onClick={this.openDetailedColumnEditModal} style={{cursor:'pointer'}}

        return (
            <div ref="root" className="gridcolumn" id={this.props.column.FieldName} style={colStyle}>
                <div className={columnSizeClass}>
                    { (updatedWidth != defaultWidth) ? <img className="columnsizeclear" src="img/svgs/sell/Action_Clear.svg" title="Reset to Default Layout Width" onClick={this.resetColumnSize} /> : null }
                    <span    title={this.state.DisplayName + ' relative width is ' + col.Width}>{widthText}</span>
                </div>
                <div className="column-sorter" />
                <div>
                    {fieldLabel}
                    {(this.props.column.FieldName == this.state.DisplayName) ? '' : <div className="columnoriginalname">({this.props.column.FieldName})</div>}
                </div>

            </div>
        );
    }
}
OutputGridLayoutColumn.defaultWidth = 100;

class OutputGridLayoutColumnDetailEditor extends React.Component {
    constructor(props) {
        super(props);

        this.onDisplayNameChange = this.onDisplayNameChange.bind(this);
        this.onWidthChange = this.onWidthChange.bind(this);
        this.onDisplayNameBlur = this.onDisplayNameBlur.bind(this);
        this.onWidthBlur = this.onWidthBlur.bind(this);
        this.onBoldClick = this.onBoldClick.bind(this);
        this.onItalicClick = this.onItalicClick.bind(this);
        this.onUnderlineClick = this.onUnderlineClick.bind(this);
        this.saveAndClose = this.saveAndClose.bind(this);

        this.state = {
            column: quosal.util.clone(this.props.column, 1)
        };
    }
    onDisplayNameChange (e) {
        var newValue = e.target.value;
        this.state.column.DisplayName = newValue;
        this.setState({column: this.state.column});
    }
    onDisplayNameBlur (e) {
        if (!this.state.column.DisplayName) {
            this.state.column.DisplayName = this.props.parent.getFallbackDisplayName();
        }
        this.setState({column: this.state.column});
    }
    onWidthChange (e) {
        var newValue = e.target.value;
        var firstNonNumeralIndex = newValue.search(/[^0-9]/);
        if (firstNonNumeralIndex >= 0) {
            newValue = newValue.substr(0, firstNonNumeralIndex);
        }
        this.state.column.Width = newValue;
        this.setState({column: this.state.column});
    }
    onWidthBlur (e) {
        if (!this.state.column.Width) {
            this.state.column.Width = OutputGridLayoutColumn.defaultWidth;
        }
        this.state.column.Width = 1 * this.state.column.Width;
        this.setState({column: this.state.column});
    }
    onBoldClick () {
        this.state.column.IsBold = !this.state.column.IsBold;
        this.setState({column: this.state.column});
    }
    onItalicClick () {
        this.state.column.IsItalic = !this.state.column.IsItalic;
        this.setState({column: this.state.column});
    }
    onUnderlineClick () {
        this.state.column.IsUnderline = !this.state.column.IsUnderline;
        this.setState({column: this.state.column});
    }
    saveAndClose () {
        this.props.parent.setState(this.state.column, function () {
            this.props.parent.props.parent.recalculateTotalWidth();
        }.bind(this));
        Dialog.close();
    }
    render () {
        var widthExceptThisColumn = this.props.parent.props.parent.state.totalWidth - this.props.column.Width;
        var columnWidth = this.state.column.Width;
        var percentDisplay = OutputGridLayoutColumn.percentDisplay(columnWidth, (1*columnWidth) + widthExceptThisColumn)

        var textAlignment = this.state.column.TextAlignment;

        var setAlignment = function (alignmentToSet) {
            this.state.column.TextAlignment = alignmentToSet;
            this.setState({column: this.state.column});
        }.bind(this);

        var boldButtonStyle = {
            width: 40,
            minWidth: 40,
            fontWeight: 'bold'
        };
        if (this.state.column.IsBold) {
            boldButtonStyle.backgroundColor = OutputGridLayoutColumnDetailEditor.activeBackgroundColor;
        }
        var italicButtonStyle = {
            width: 40,
            minWidth: 40,
            fontStyle: 'italic'
        };
        if (this.state.column.IsItalic) {
            italicButtonStyle.backgroundColor = OutputGridLayoutColumnDetailEditor.activeBackgroundColor;
        }
        var underlineButtonStyle = {
            width: 40,
            minWidth: 40,
            textDecoration: 'underline'
        };
        if (this.state.column.IsUnderline) {
            underlineButtonStyle.backgroundColor = OutputGridLayoutColumnDetailEditor.activeBackgroundColor;
        }

        return (
            <div>

                <div className="formcolumn">
                    <div className="formfieldlabel"><label htmlFor="columnDetailModalDisplayNameInput" className="standardformlabel">Display Name</label></div>
                    <div className="formfield"><input type="text" id="columnDetailModalDisplayNameInput"
                                                      title="Display Name"
                                                      value={this.state.column.DisplayName}
                                                      onChange={this.onDisplayNameChange}
                                                      onBlur={this.onDisplayNameBlur} /></div>

                    <div className="formfieldlabel"><label htmlFor="columnDetailModalWidthInput" className="standardformlabel">Width (Relative Points)</label></div>
                    <div className="formfield" >
                        <input type="number" step={1}
                               id="columnDetailModalWidthInput"
                               style={{width: 100, display: 'inline-block'}}
                               title="Width (Relative Points)"
                               value={this.state.column.Width}
                               onKeyUp={this.onWidthChange}
                               onChange={this.onWidthChange}
                               onBlur={this.onWidthBlur} />
                        <label style={{width: 60, display: 'inline-block', marginLeft: 30}}>{percentDisplay}</label>
                    </div>

                    <div className="formfieldlabel"><label className="standardformlabel">Text Alignment</label></div>
                    <div className="formfield" >
                        <OutputGridLayoutColumnDetailEditorAlignmentButton
                            alignment={'left'}
                            selectedAlignment={textAlignment}
                            setAlignment={setAlignment}
                        />
                        <OutputGridLayoutColumnDetailEditorAlignmentButton
                            alignment={'center'}
                            selectedAlignment={textAlignment}
                            setAlignment={setAlignment}
                        />
                        <OutputGridLayoutColumnDetailEditorAlignmentButton
                            alignment={'right'}
                            selectedAlignment={textAlignment}
                            setAlignment={setAlignment}
                        />
                        <br />
                        <OutputGridLayoutColumnDetailEditorAlignmentButton
                            alignment={'justify'}
                            selectedAlignment={textAlignment}
                            setAlignment={setAlignment}
                        />
                    </div>

                    <div className="formfieldlabel"><label className="standardformlabel">Font Emphasis</label></div>
                    <div className="formfield" >
                        <Button onClick={this.onBoldClick} style={boldButtonStyle} >B</Button>
                        <Button onClick={this.onItalicClick} style={italicButtonStyle} >I</Button>
                        <Button onClick={this.onUnderlineClick} style={underlineButtonStyle} >U</Button>
                    </div>

                    <br />
                    <div className="formfieldlabel"></div>
                    <div className="formfield">
                        <Button onClick={this.saveAndClose}>OK</Button>
                    </div>
                </div>
            </div>
        )
    }
}
OutputGridLayoutColumnDetailEditor.height = 310;
OutputGridLayoutColumnDetailEditor.width = 250;
OutputGridLayoutColumnDetailEditor.activeBackgroundColor = '#f78f1e';

class OutputGridLayoutColumnDetailEditorAlignmentButton extends React.Component {
    onClick (e) {
        if (this.props.selectedAlignment === this.props.alignment) {
            this.props.setAlignment('');
        } else {
            this.props.setAlignment(this.props.alignment);
        }
    }
    render () {
        var textAlignmentButtonStyle = {width: 60, minWidth: 60, display: 'inline-block'};
        if (this.props.selectedAlignment === this.props.alignment) {
            textAlignmentButtonStyle.backgroundColor = OutputGridLayoutColumnDetailEditor.activeBackgroundColor;
        }
        return (
            <Button id={'columnDetailModalTextAlignmentButton_' + this.props.alignment}
                    onClick={this.onClick.bind(this)}
                    style={textAlignmentButtonStyle}
            >{this.props.alignment}</Button>
        );
    }
}

class OutputGridLayoutPreviewDialog extends React.Component {
    constructor (props) {
        super(props);

        this.doPreview = this.doPreview.bind(this);
        this.onQuoteNumberInputChange = this.onQuoteNumberInputChange.bind(this);

        this.state = {
            searchQuoteNumber: ''
        };
    }
    componentDidMount () {
        $(this.refs.quoteForm).focus();
    }
    doPreview () {
        this.setState({
            isWorking: true,
            error: null
        });
        var previewAPI = quosal.api.outputGridLayout.preview(
            this.props.IdOutputGridLayout,
            this.refs.quoteForm.value,
            this.state.searchQuoteNumber,
            this.refs.wholeDigitCount.value,
            this.refs.fractionalDigitCount.value);
        previewAPI.finished = function (msg) {
            var newState = { isWorking: false };
            if (msg.pdfDownloadLink) {
                newState.pdfDownloadLink = msg.pdfDownloadLink;
            }
            if (msg.error) {
                newState.error = msg.error
            }
            if (msg.quoteName) {
                newState.quoteName = msg.quoteName;
            } else {
                newState.quoteName = 'Demo Quote';
                if (msg.pdfDownloadLink && this.state.searchQuoteNumber) {
                    newState.quoteSearchFailedAlert = true;
                }
            }
            this.setState(newState);
        }.bind(this);
        previewAPI.call();
    }
    onQuoteNumberInputChange (e) {
        this.setState({
            searchQuoteNumber: e.target.value || ''
        })
    }
    render () {
        var finished = !!this.state.pdfDownloadLink;
        var disabled = this.state.isWorking || finished;

        var options = FormFieldInput.getOptionListFromEnumConfiguration(quosal.metadata.enums['QuoteFormList'], undefined, null, {includeTitleOnOptionTags: true});

        var selectStyle = { maxHeight: 250, overflowY: 'auto', overflowX: 'hidden', maxWidth: '100%'};

        var wholeDigitOptions = [];
        for (var i = OutputGridLayoutPreviewDialog.WholeDigitUpperLimit; i >= OutputGridLayoutPreviewDialog.WholeDigitLowerLimit; i--) {
            wholeDigitOptions.push(<option key={i} value={i}>{i}</option>);
        }
        var fractionalDigitOptions = [];
        for (var i = OutputGridLayoutPreviewDialog.FractionalDigitUpperLimit; i >= OutputGridLayoutPreviewDialog.FractionalDigitLowerLimit; i--) {
            fractionalDigitOptions.push(<option key={i} value={i}>{i}</option>);
        }
        var digitCountInputClassName = 'formselectfield' + (this.state.searchQuoteNumber ? ' disabled' : '');

        return (
            <div>
                {!disabled &&
                    <div>

                        <div className="formcolumn">
                            <div className="formfieldlabel"><label htmlFor="doglPreviewQuoteFormSelect" className="formlabel">Quote Form</label></div>
                            <div><select ref="quoteForm" id="doglPreviewQuoteFormSelect" size={options.length} style={selectStyle}>
                                {options}
                                </select></div>

                        </div>
                        <div className="formcolumn">
                            <div>Numeric Sample Data Options for Demo Quote:</div>
                            <br />
                            <div className="formfieldlabel"><label htmlFor="doglPreviewWholeDigitCount" className="formlabel">Digits left of decimal point</label></div>
                            <div className="formselectfieldwrapper"><select className={digitCountInputClassName} ref="wholeDigitCount" id="doglPreviewWholeDigitCount"
                                         defaultValue={OutputGridLayoutPreviewDialog.WholeDigitDefault}>
                                {wholeDigitOptions}
                            </select></div>

                            <div className="formfieldlabel"><label htmlFor="doglPreviewFractionalDigitCount" className="formlabel">Digits right of decimal point</label></div>
                            <div className="formselectfieldwrapper"><select className={digitCountInputClassName} ref="fractionalDigitCount" id="doglPreviewFractionalDigitCount"
                                         defaultValue={OutputGridLayoutPreviewDialog.FractionalDigitDefault}>
                                {fractionalDigitOptions}
                            </select></div>

                            <br />

                            <div style={{textAlign:'center'}}><b>- Or -</b></div>
                            <br />
                            <div>Search for an Existing Quote to Preview, by Quote Number:</div>
                            <br />
                            <div className="formfieldlabel"><label htmlFor="doglPreviewSearchQuoteNumber" className="formlabel">Quote Number</label></div>
                            <div className="formfield">
                                <input type="number" ref="searchQuoteNumber" id="doglPreviewSearchQuoteNumber"
                                       onChange={this.onQuoteNumberInputChange}
                                       value={this.state.searchQuoteNumber} /></div>
                        </div>
                    </div>
                }
                <br /><br />
                <div style={{textAlign:'center'}}>
                    {finished && <a href={this.state.pdfDownloadLink} target="_blank" style={{fontSize:16}}>Preview {this.state.quoteName}.pdf</a>}
                    {this.state.isWorking && <Spinner />}
                    {!disabled && <Button id={'pdf-preview-btn'} style={{marginLeft:-25}} onClick={this.doPreview.bind(this)}>Generate Preview PDF</Button>}
                </div>
                {this.state.error && <div><br /><br />
                    <div className="infobox error">
                        <span className="infobox message">Error: {this.state.error}</span>
                    </div>
                </div>}

                { this.state.quoteSearchFailedAlert && <div><br /><br />
                    <div className="infobox notice">
                        <span className="infobox message">The quote search failed to find a result, so the demo quote was used.</span>
                    </div>
                </div>}
            </div>
        );
    }
}
OutputGridLayoutPreviewDialog.WholeDigitUpperLimit = 11;
OutputGridLayoutPreviewDialog.WholeDigitLowerLimit = 0;
OutputGridLayoutPreviewDialog.WholeDigitDefault = 5;
OutputGridLayoutPreviewDialog.FractionalDigitUpperLimit = 4;
OutputGridLayoutPreviewDialog.FractionalDigitLowerLimit = 2;
OutputGridLayoutPreviewDialog.FractionalDigitDefault = 2;