import React, { memo } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import useDataGridBoilerplateRenderer from "./dataGridBoilerplateRenderer";
import useStyle from "./styles";

function DataGridBoilerplate(props) {
	const { tabItem, loading } = props;
	const { mainBox, gridMenu } = useStyle();
	const {
		loadingComponent,
		addRecordComponent,
		gridMenuComponent,
		dataGridComponent,
		priceSourceComponent,
		priceModifierComponent
	} = useDataGridBoilerplateRenderer(props);
	const id = `${tabItem?.IdQuoteTabs}DataGridBoilerplate`;

	return (
		<Box sx={mainBox} id={id} draggable>
			{loading && loadingComponent}

			<Grid container style={gridMenu}>
				{addRecordComponent}
				{gridMenuComponent}
			</Grid>

			{dataGridComponent}
			{priceSourceComponent}
			{priceModifierComponent}
		</Box>
	);
}

export default memo(DataGridBoilerplate);
