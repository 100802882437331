import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledValueSm = styled(Typography)(({ theme, sx }) => ({
	color: theme.palette.text.primary,
	lineHeight: "18px",
	letterSpacing: ".16px",
	minWidth: "auto",
	textAlign: "right",
	...sx
}));

export { StyledValueSm };
