import { PickTemplate } from "js/jsx/src/classes/quote/pickTemplate.jsx";
import { DialogManager } from "js/jsx/src/classes/dialog.jsx";
import { LeftHeader, TopHeader, QuosalHeader } from "js/jsx/src/classes/header.jsx";
export default function pickTemplate() {
    var loadTemplates = function () {
        var getQuoteTemplates = quosal.api.quote.getQuoteTemplates();
        getQuoteTemplates.finished = function (msg) {
            loadPickTempaltePage(msg);
        }.bind(this);
        getQuoteTemplates.call();
        quosal.ui.react.render(
            <FormPlaceholder message="Loading Pick Template..." />
            , document.getElementById('quoteModule'));
    }
    var loadPickTempaltePage = function (templateObj) {
        var headerTheme = app.settings.user.embeddedTheme;
        if (headerTheme === "ConnectWise Business Suite") {
            quosal.ui.react.render(React.createElement(LeftHeader, null), document.getElementById('leftSidebarContainer'));
            quosal.ui.react.render(React.createElement(TopHeader, null), document.getElementById('topHeader'));
        } else if ($.queryString('skiplayout') != 'yes') {
            quosal.ui.react.render(React.createElement(QuosalHeader, {
                quote: app.currentQuote,
                user: app.currentUser
            }), document.getElementById('topHeader'));
        }
        quosal.ui.react.update(React.createElement(DialogManager, null), document.getElementById('dialogManager'));

        quosal.ui.react.render(<PickTemplate favoriteTemplateString={templateObj.favorites} quoteTemplateList={templateObj.quoteTemplateList} />, document.getElementById('quoteModule'));
        $('#pageName').val('quote.picktemplate');
    };

    loadTemplates();
}