export class EditAgreementMapping extends React.Component {
    constructor(props) {
        super(props);
        var mappingToEdit = props.mappingToEdit;
        this.state = {
            isSaving: false,
            currentMapping: {
                id: mappingToEdit ? mappingToEdit.agreementMappingId : null,
                agreementTypeId: mappingToEdit ? mappingToEdit.agreementTypeCrmId : null,
                quoteId: mappingToEdit ? mappingToEdit.idQuote : null,
                tabId: mappingToEdit ? mappingToEdit.idQuoteTab : null
            }
        }

        this.agreementTypeSelected = this.agreementTypeSelected.bind(this);
        this.quoteTemplateSelected = this.quoteTemplateSelected.bind(this);
        this.tabItemSelected = this.tabItemSelected.bind(this);
        this.save = this.save.bind(this);
        this.leaveFunction = this.leaveFunction.bind(this);
        this.confirmThenLeave = this.confirmThenLeave.bind(this);        
        this.displayError = this.displayError.bind(this);

    }
    agreementTypeSelected(e) {
        var selectedAgreementTypeId = e.target.value;
        this.setState({
            isDirty: true,
            currentMapping: {
                agreementTypeId: selectedAgreementTypeId,
                quoteId: this.state.currentMapping.quoteId,
                tabId: this.state.currentMapping.tabId
            }
        });
    }
    quoteTemplateSelected(e) {
        var selectedQuoteId = e.target.value;
        var selectedQuoteTab = null;
        var tabItems = this.props.quoteTabItems;
        if (tabItems && tabItems.length > 0) {
            selectedQuoteTab = tabItems.find(function (tabItem) {
                return tabItem.quoteId === selectedQuoteId;
            });
        }

        this.setState({
            isDirty: true,
            currentMapping: {
                agreementTypeId: this.state.currentMapping.agreementTypeId,
                quoteId: selectedQuoteTab == null ? null : selectedQuoteTab.quoteId,
                tabId: selectedQuoteTab == null ? null : selectedQuoteTab.tabList[0].id
            }
        });

    }
    tabItemSelected(e) {
        var selectedTabId = e.target.value;
        this.setState({
            isDirty: true,
            currentMapping: {
                agreementTypeId: this.state.currentMapping.agreementTypeId,
                quoteId: this.state.currentMapping.quoteId,
                tabId: selectedTabId
            }
        });
    }
    displayError(title, message) {
        Dialog.close();
        Dialog.open({
            title: title,
            message: message,
            links: [Dialog.links.ok]
        });
    }
    save() {
        this.setState({
            isSaving: true
        });

        var displayError = this.displayError;
        if (this.state.currentMapping.agreementTypeId && this.state.currentMapping.quoteId && this.state.currentMapping.tabId) {
            var id = null;
            if (this.props.mappingToEdit)
            {
                id = this.props.mappingToEdit.idAgreementMapping;
            }
            var addVendorAPI = quosal.api.agreementMapping.saveAgreementMapping(id, this.state.currentMapping.agreementTypeId,
                this.state.currentMapping.quoteId, this.state.currentMapping.tabId, false);
            Dialog.setIsWorking();
            addVendorAPI.finished = function (msg) {
                Dialog.setIsWorking(false);
                if (msg.success && msg.savedAgreementMapping) {                    
                    this.setState({
                        isSaving: false, isDirty: false
                    });

                    var agreementType = this.props.agreementTypes.find(function (item) {
                        return item.agreementTypeId.toString() === msg.savedAgreementMapping.AgreementTypeCrmId;
                    });
                    var agreementTypeType = agreementType != null ? agreementType.agreementTypeName : null;

                    var quoteTab = this.props.quoteTabItems.find(function (item) {
                        return item.quoteId === msg.savedAgreementMapping.IdQuoteMain;
                    });
                    var quoteName = quoteTab == null ? null : quoteTab.quoteName;

                    var tab = quoteTab.tabList.find(function (item) {
                        return item.id === msg.savedAgreementMapping.IdQuoteTabs;
                    });
                    var tabName = tab == null ? null : tab.name;

                    var newMapping = {
                        idAgreementMapping: msg.savedAgreementMapping.IdAgreementMapping,
                        agreementTypeCrmId: msg.savedAgreementMapping.AgreementTypeCrmId,
                        idQuote: msg.savedAgreementMapping.IdQuoteMain,
                        idQuoteTab: msg.savedAgreementMapping.IdQuoteTabs,
                        isDefault: msg.savedAgreementMapping.IsDefault,
                        agreementType: agreementTypeType,
                        quoteName: quoteName,
                        tabName: tabName
                    }

                    var mappings = this.props.nonDefaultMappings;
                    if (id) {
                        // replace mapping with new value
                        var mappingToReplace = mappings.find(function (item) {
                            return item.idAgreementMapping === id;
                        });

                        var index = mappings.indexOf(mappingToReplace);
                        mappings.splice(index, 1, newMapping);
                    }
                    else {
                        mappings.push(newMapping);
                    }
                    
                    this.props.updateNonDefaultMappings(mappings);
                    this.leaveFunction(null);
                }
                else {
                    this.setState({ isSaving: false });
                    displayError('Error Saving Agreement Mapping', msg.errorMsg);
                }
            }.bind(this);
            addVendorAPI.call();
        }
        else {
            this.setState({ isSaving: false });
            displayError('Error Saving Agreement Mapping', "An Agreement Type, a Quote Template and an Item Tab must be selected.");
        }
    }    
    leaveFunction() {
        this.props.exitEditMode();
    }
    confirmThenLeave(leaveFunction) {
        if ('function' !== typeof leaveFunction) {
            leaveFunction = this.leaveFunction;
        }

        var save = this.save;

        if (this.state.isDirty) {
            Dialog.confirmDelete({
                title: 'Changes will be lost',
                width: '400px',
                links: [
                    {
                        title: 'Save Changes',
                        callback: function () {
                            Dialog.close();
                            save();
                        }
                    },
                    {
                        title: 'Discard Changes',
                        callback: function () {
                            Dialog.close();
                            leaveFunction();
                        }
                    },
                    {
                        title: "Cancel",
                        callback: Dialog.close
                    }
                ],
                message: 'You have unsaved changes. Are you sure you want to close the layout editing page?'
            });
        } else {
            leaveFunction();
        }
    }
    componentDidMount() {
        $('.menu-level1.selected .menulabel').off('click').click(function (e) {
            e.stopImmediatePropagation();
            this.confirmThenLeave();
        }.bind(this));
    }
    componentWillUnmount() {
        $('.menu-level1.selected .menulabel').off('click').click(function (e) {
            e.stopImmediatePropagation();
        });
    }
    render() {

        var titleChildren = (<PanelToolbar style={{ display: 'block', fontSize: '12px' } }>
            <SaveButton id="topSave" style={{ float: 'right', marginRight: 10 }} disabled={!(this.state.isDirty || this.props.isSaving)} isSaving={this.state.isSaving} onClick={this.save } />
            <Button id="topCancel" style={{ float: 'right', marginRight: 10 }} onClick={this.confirmThenLeave }>Cancel</Button>
        </PanelToolbar>);

        var agreementTypeOptions = [];
        agreementTypeOptions.push(<option key={'option0'} value=''>{''}</option>);
        if (this.props.agreementTypes && this.props.agreementTypes.length > 0) {
            for (var i = 0; i < this.props.agreementTypes.length; i++) {
                agreementTypeOptions.push(<option key={'option' + (i + 1)} value={this.props.agreementTypes[i].agreementTypeId }>{this.props.agreementTypes[i].agreementTypeName}</option>);
            }
        }

       var templateOptions = [];
        templateOptions.push(<option key={'option0'} value=''>{''}</option>);
        if (this.props.quoteTabItems && this.props.quoteTabItems.length > 0) {
            for (var i = 0; i < this.props.quoteTabItems.length; i++) {
                templateOptions.push(<option key={'option' + (i + 1)} value={this.props.quoteTabItems[i].quoteId }>{this.props.quoteTabItems[i].quoteName}</option>);
            }
        }

        var tabOptions = [];
        if (this.state.currentMapping && this.state.currentMapping != null && this.state.currentMapping.quoteId != null &&
            this.props.quoteTabItems && this.props.quoteTabItems.length > 0) {
            var selectedQuoteId = this.state.currentMapping.quoteId;
            var currentQuote = this.props.quoteTabItems.find(function(tabItem) {
                return tabItem.quoteId == selectedQuoteId;
            });

            if (currentQuote && currentQuote != null) {
                for (var i = 0; i < currentQuote.tabList.length; i++) {
                    tabOptions.push(<option key={'option' + (i)} value={currentQuote.tabList[i].id }>{currentQuote.tabList[i].name}</option>);
                }
            }
        }

        return (
            <div>
                <Panel title="Add a Mapping" titleChildren={titleChildren }>
                    <PanelContent>
                        <div class="formcolumn">
                            <div class="formfieldlabel"><label for="AgreementType" class="standardformlabel">Agreement Type</label></div>
                            <div class="formselectfieldwrapper">
                                <select class="formselectfield" type="text" id='ForeignKey_AgreementType' name='AgreementType' 
                                        value={this.state.currentMapping.agreementTypeId}  onKeyPress={this.props.keyPressed} onChange={this.agreementTypeSelected}>
                                    {agreementTypeOptions}
                                </select>
                            </div>
                        </div>

                        <div class="formcolumn">
                            <div class="formfieldlabel"><label for="QuoteTemplate" class="standardformlabel">Quote Template</label></div>
                            <div class="formselectfieldwrapper">
                                <select class="formselectfield" type="text" id='ForeignKey_QuoteTemplate' name='QuoteTemplate' 
                                        value={this.state.currentMapping.quoteId} onKeyPress={this.props.keyPressed} onChange={this.quoteTemplateSelected}>
                                    {templateOptions}
                                </select>
                            </div>
                        </div>

                        <div class="formcolumn">
                            <div class="formfieldlabel"><label for="TabItem" class="standardformlabel">Item Tab</label></div>
                            <div class="formselectfieldwrapper">
                                <select class="formselectfield" type="text" id='ForeignKey_TabItem' name='TabItem' 
                                        value={this.state.currentMapping.tabId} onKeyPress={this.props.keyPressed} onChange={this.tabItemSelected}>
                                    {tabOptions}
                                </select>
                            </div>
                        </div>                     
                    </PanelContent>
                </Panel>
            </div>
        );
    }
}
