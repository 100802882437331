quosal.api.priceModifierHelper = {};

quosal.api.priceModifierHelper.CalculateSellingPrice = function (itemCost, suggestedPrice, basePrice, advancedRoundingEnabled, priceModifierParams) {
    return quosal.api.call('PriceModifierHelper.CalculateSellingPrice', {
        itemCost: itemCost,
        suggestedPrice: suggestedPrice,
        basePrice: basePrice,
        modifierParams: priceModifierParams,
        advancedRoundingEnabled: advancedRoundingEnabled
    });
};

quosal.api.priceModifierHelper.ConvertToPriceModifierText = function (priceModifierParams) {
    return quosal.api.call('PriceModifierHelper.ConvertToPriceModifierText', {
        modifierParams: priceModifierParams
    });
};

quosal.api.priceModifierHelper.ParsePriceModifierText = function (priceModifier) {
    return quosal.api.call('PriceModifierHelper.ParsePriceModifierText', {
        priceModifierText: priceModifier
    });
};