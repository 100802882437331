import { RenameAndCustomizeFields } from "js/jsx/src/classes/settings/customizeFields.jsx";
import { SimpleTab } from "js/jsx/src/classes/tab/simpleTab.jsx";
import { DialogManager } from "js/jsx/src/classes/dialog.jsx";

export default function customizeFields() {

    var loadRenameFieldsPage = function () {

        var tabList = [{ name: "Quote", component: <RenameAndCustomizeFields key={"QuoteMain"} objectName={"QuoteMain"} /> },
        { name: "Tab", component: <RenameAndCustomizeFields key={"QuoteTabs"} objectName={"QuoteTabs"} /> },
        { name: "Item", component: <RenameAndCustomizeFields key={"QuoteItems"} objectName={"QuoteItems"} /> }];

        quosal.ui.react.render(<DialogManager />, document.getElementById('dialogManager'));
        quosal.ui.react.render(<SimpleTab tabList={tabList} />, document.getElementById('currentDocument'));
        $('#pageName').val('customizefields.quosalweb');
    };
    quosal.sell.modules.onAfterLoad.unbind().bind(loadRenameFieldsPage);
}