/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState } from "react";

const PreviewContentContext = React.createContext();
export const usePreviewContentContext = () => React.useContext(PreviewContentContext);

export function PreviewContentProvider({ value, children }) {
    const [isLoading, setIsLoading] = useState(!(value.currentQuote && value.dogleConfigs && value.styleConfigs));
    
    //TODO: Add api call to get currentQuote if not passed in props
    const [currentQuote, setCurrentQuote] = useState(value.currentQuote);
    const [dogleConfigs, setDogleConfigs] = useState(value.dogleConfigs);
    const [styleConfigs, setStyleConfigs] = useState(value.styleConfigs);
    const [kickStyleVar, setKickStyleVar] = useState(1);
    const kickStyleConfig = useCallback( ()=> {setKickStyleVar(kickStyleVar + 1); setStyleConfigs(null)}, [kickStyleVar]);

    useEffect(() => {
        const styleConfigAPI = quosal.api.data.query({
            table: 'QuoteItemsPreviewTableConfig',
            where: [
                {
                    field: "IdQuoteMain",
                    operator: 'Equals',
                    value: currentQuote.IdQuoteMain
                },
            ],
        });

        styleConfigAPI.finished = function (msg) {
            setStyleConfigs(msg.resultSets[0]);
        }

        styleConfigAPI.call();
    }, [kickStyleVar]);

    useEffect(() => {
        const outputGridInitializeAPI = quosal.api.data.query({
            table: 'OutputGridLayoutColumn',
        });

        outputGridInitializeAPI.finished = function (msg) {
            const gridApiData = msg.resultSets[0];
            const editorApiData = gridApiData.map(data => { return { ...data, isEditor: true } });
            setDogleConfigs(editorApiData);
        }

        outputGridInitializeAPI.call();
    }, []);

    useEffect(() => {
        if (currentQuote && dogleConfigs && styleConfigs) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [currentQuote, dogleConfigs, styleConfigs]);

    const internalValue = {
        currentQuote,
        dogleConfigs,
        styleConfigs,
        isLoading,
        kickStyleConfig,
    }

    return (
        <PreviewContentContext.Provider value={{ ...internalValue }}>
            {children}
        </PreviewContentContext.Provider>
    );
}

PreviewContentProvider.Consumer = PreviewContentContext.Consumer;
