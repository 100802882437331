import { useState, useEffect } from "react";

const useDarkMode = () => {
	const [darkMode, setDarkMode] = useState(() => $("#dark-toggle").prop("checked") || app?.currentUser?.HasDarkmodeEnabled);

	useEffect(() => {		
		const handleChange = function () {
			setDarkMode(this.checked);
		};
		setTimeout(() => {
			const $checkbox = $("#dark-toggle[type=checkbox]");
			$checkbox.on("change", handleChange);
		}, 1000);

		return () => {
			const $checkbox = $("#dark-toggle[type=checkbox]");
			$checkbox.off("change", handleChange);
		};
	}, []);

	return darkMode;
};

export default useDarkMode;
