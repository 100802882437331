import { useEffect, useState } from "react";
import { useQuoteContext, useUserSettingsContext } from "../context";
import { addCSSPropery, hideDOMElement, showDOMElement } from "../utils";

export function useDocumentPreview() {
	const userSettings = useUserSettingsContext();
	const quote = useQuoteContext();
	const [documentPreviewEnabled, setDocumentPreviewEnabled] = useState(
		quosal.util.shouldDocumentPreviewBeOpen()
	);

	useEffect(() => {
		const newValue = quosal.util.shouldDocumentPreviewBeOpen();
		setDocumentPreviewEnabled(newValue);
		showDocumentPreviewer(newValue);
	}, [userSettings.documentPreviewDisabled, userSettings.DocumentPreviewVisible, quote.UseStandardPublishOutput]);

	const showDocumentPreviewer = (enabled) => {
		let right = 0;
		let width = "calc(100% - 92px)";
		let visible = !userSettings.documentPreviewDisabled;
		const documentPreviewElementId = "documentPreview";
		const quoteContainerElementId = "quoteContainer";
		if (!enabled) {
			right = -87;
			width = "";
			visible = false;
		}
		if (visible) {
			showDOMElement(documentPreviewElementId);
		}
		addCSSPropery(documentPreviewElementId, "transition", "all 0.5s ease");
		addCSSPropery(quoteContainerElementId, "width", width);
		addCSSPropery(documentPreviewElementId, "right", right);

		if (!visible) {
			hideDOMElement(documentPreviewElementId);
		}
	};

	const toggleDocumentPreviewer = () => {
		// TODO: convert into latest method of api calling
		quosal.settings.saveUserSetting({
			key: "DocumentPreviewVisible",
			value: !documentPreviewEnabled,
			isUserSetting: true
		});  
		showDocumentPreviewer(!documentPreviewEnabled);
		if (!documentPreviewEnabled && document.getElementById("refreshButton")) {
			document.getElementById("refreshButton").click();
		}
		setDocumentPreviewEnabled((prevState) => !prevState);
	};

	useEffect(() => {
		showDocumentPreviewer(documentPreviewEnabled);
	}, []);

	return {
		documentPreviewEnabled,
		showDocumentPreviewer,
		toggleDocumentPreviewer
	};
}
