import { SubmittedFormsPage } from "js/jsx/src/classes/settings/guidedSelling/submittedForms.jsx";
import { DialogManager } from "js/jsx/src/classes/dialog.jsx"

export default function submittedForms() {
    var loadSubmittedForms = function () {
        const renderComponents = () => {
            quosal.ui.react.render(<DialogManager />, document.getElementById('dialogManager'))
            quosal.ui.react.render(<SubmittedFormsPage />, document.getElementById('currentDocument'));
        };

        renderComponents();
        $('#pageName').val('guidedsellingsubmittedforms.quosalweb');
        $('div.menu-level.menu-level1.selected div.menulabel').unbind('click').click((e) => {
            e.stopPropagation(); 
            renderComponents();
        });
    };
    quosal.sell.modules.onAfterLoad.unbind().bind(loadSubmittedForms);
}