import {OpportunitySearchbox} from "js/jsx/src/classes/crm/opportunitySearchbox.jsx";
import {OpportunitySearchTable} from "js/jsx/src/classes/crm/opportunitySearchTable.jsx";

export class CWOpportunitySearch extends React.Component {
    constructor(props) {
        super(props);

        this.headerNames = [
           {name:"", propName:"", width:50, url:true}, 
           {name:"Age", propName:"Age", width:50}, 
           {name:"Name", propName:"OpportunityName", width:130},
           {name:"Account", propName: "CompanyName", width:100}, 
           {name:"Contact", propName:"ContactName", width:100}, 
           {name:"Status", propName:"Status", width:50},
           {name:"Type", propName:"Type", width:120}, 
           {name:"Stage", propName:"StageName", width:80}, 
           {name:"Rep", propName:"PrimarySalesRep", width:80}
        ]

        this.state = {
            criteria: this.getCwSavedSearchCriteria(), 
            isSearching: false,
        };

        // This binding is necessary to make `this` work in the callback
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.submitClick = this.submitClick.bind(this);
        this.getTypeOptions = this.getTypeOptions.bind(this);
        this.getOpportunityStatuses = this.getOpportunityStatuses.bind(this);
        this.getSearchCriteria = this.getSearchCriteria.bind(this);
        this.saveCwSavedSearchCriteria = this.saveCwSavedSearchCriteria.bind(this);
        this.resetCwSavedSearchCriteria = this.resetCwSavedSearchCriteria.bind(this);
    }
    componentDidMount(){
        document.addEventListener("keypress",  this.handleKeyPress)
    }
    componentWillUnmount(){
        document.removeEventListener("keypress",  this.handleKeyPress)
    }
    handleKeyPress(e){
        if(e.keyCode === 13)
        {
           this.submitClick();
        }
    }
    inputChanged(fieldName){
        var value = $("#"+fieldName).val();
        this.state.criteria[fieldName] = value;
        this.forceUpdate();
    }
    submitClick(pageNumberOffset){
        if(quosal.util.isIntegar(pageNumberOffset) && pageNumberOffset == 0){
            this.state.criteria.pageNumber = 0;
        }
        else if(quosal.util.isIntegar(pageNumberOffset))
        {
            this.state.criteria.pageNumber += pageNumberOffset;
        }
       
        this.saveCwSavedSearchCriteria();
        var cwGetOpportunites = quosal.api.crm.connectwise.getOpportunities(this.state.criteria)
        var me = this;
        var onError = function(msg) {
            Dialog.open({
                message: msg.error,
                links: [{
                    title: 'OK',
                    callback: Dialog.close
                }]
            });
            me.setState({isSearching: false})
        
        };
        cwGetOpportunites.error = onError;
        cwGetOpportunites.finished = function (msg) {
            this.setState({opportunities: msg.data.opportunities, pager: msg.data.pager, isSearching: false});
        }.bind(this);
       
        cwGetOpportunites.call();
        this.setState({isSearching: true})
    
    }  
    getTypeOptions(){
        var optionsList = [<option key={"opptype_all"}>ALL</option>]
       optionsList.push( this.props.data.opptypes.map(function(type, index){
            return <option value={type} key={"opptype_" + index}>{type}</option>
        }))
        return optionsList;
    }
    getOpportunityStatuses(){
        var optionsList = [<option key={"oppstatus_all"}>ALL</option>]
       optionsList.push( this.props.data.oppstatuses.map(function(status, index){
            return <option value={status} key={"oppstatus_" + index}>{status}</option>
        }))
        return optionsList;
    }
    getSearchCriteria(){
        return [
            {fieldName: "companyName", displayName:"Company Name", fieldType:"input", value: this.state.criteria["companyName"]},
            {fieldName: "contactName", displayName:"Contact Name", fieldType:"input", value: this.state.criteria["contactName"]},
            {fieldName: "opportunityName", displayName:"Opportunity Name", fieldType:"input", value: this.state.criteria["opportunityName"]},
            {fieldName: "type", displayName:"Type", fieldType:"select", value: this.state.criteria["type"], options: this.getTypeOptions()},
            {fieldName: "stageName", displayName:"Stage Name", fieldType:"input", value: this.state.criteria["stageName"]},
            {fieldName: "status", displayName:"Status", fieldType:"select", value: this.state.criteria["status"], options: this.getOpportunityStatuses()},
            {fieldName: "primarySalesRep", displayName:"Primary Sales Rep", fieldType:"input", value: this.state.criteria["primarySalesRep"]},
            {fieldName: "secondarySalesRep", displayName:"Secondary Sales Rep", fieldType:"input", value: this.state.criteria["secondarySalesRep"]},
        ]

    }
    saveCwSavedSearchCriteria(){
        $.cookie('c_cwopportunitysearch_CompanyName', this.state.criteria.companyName, { expires: 14, path: "/" });
        $.cookie('c_cwopportunitysearch_ContactName', this.state.criteria.contactName, { expires: 14, path: "/" });
        $.cookie('c_cwopportunitysearch_OpportunityName', this.state.criteria.opportunityName, { expires: 14, path: "/" });
        $.cookie('c_cwopportunitysearch_PrimarySalesRep', this.state.criteria.primarySalesRep, { expires: 14, path: "/" });
        $.cookie('c_cwopportunitysearch_SecondarySalesRep', this.state.criteria.secondarySalesRep, { expires: 14, path: "/" });
        $.cookie('c_cwopportunitysearch_StageName', this.state.criteria.stageName, { expires: 14, path: "/" });
        $.cookie('c_cwopportunitysearch_Status', this.state.criteria.status, { expires: 14, path: "/" });
        $.cookie('c_cwopportunitysearch_Type', this.state.criteria.type, { expires: 14, path: "/" });
    }
    resetCookies(){
        $.cookie('c_cwopportunitysearch_CompanyName', "", { expires: 14, path: "/" });
        $.cookie('c_cwopportunitysearch_ContactName',"", { expires: 14, path: "/" });
        $.cookie('c_cwopportunitysearch_OpportunityName', "", { expires: 14, path: "/" });
        $.cookie('c_cwopportunitysearch_PrimarySalesRep', "", { expires: 14, path: "/" });
        $.cookie('c_cwopportunitysearch_SecondarySalesRep', "", { expires: 14, path: "/" });
        $.cookie('c_cwopportunitysearch_StageName', "", { expires: 14, path: "/" });
        $.cookie('c_cwopportunitysearch_Status', "", { expires: 14, path: "/" });
        $.cookie('c_cwopportunitysearch_Type', "", { expires: 14, path: "/" });
    }
    resetCwSavedSearchCriteria(){
       this.resetCookies();
        this.setState({criteria: this.getCwSavedSearchCriteria(), opportunities: null})
    }
    getCwSavedSearchCriteria(){
        var cookie = {}
        try{ 
            cookie.companyName = quosal.util.cookie('c_cwopportunitysearch_CompanyName');
            cookie.companyName = cookie.companyName == "Company Name" ? "" : cookie.companyName;

            cookie.contactName = quosal.util.cookie('c_cwopportunitysearch_ContactName');
            cookie.contactName = cookie.contactName == "Contact Name" ? "" : cookie.contactName;

            cookie.opportunityName = quosal.util.cookie('c_cwopportunitysearch_OpportunityName');
            cookie.opportunityName = cookie.opportunityName == "Opportunity Name" ? "" : cookie.opportunityName;

            cookie.primarySalesRep = quosal.util.cookie('c_cwopportunitysearch_PrimarySalesRep');
            cookie.primarySalesRep = cookie.primarySalesRep == "Primary Sales Rep" ? "" : cookie.primarySalesRep;

            cookie.secondarySalesRep = quosal.util.cookie('c_cwopportunitysearch_SecondarySalesRep');
            cookie.secondarySalesRep = cookie.secondarySalesRep == "Secondary Sales Rep" ? "" : cookie.secondarySalesRep;

            cookie.stageName = quosal.util.cookie('c_cwopportunitysearch_StageName');
            cookie.stageName = cookie.stageName == "Stage Name" ? "" : cookie.stageName;
            
            cookie.status = quosal.util.cookie('c_cwopportunitysearch_Status');
            cookie.status = cookie.status == "Status" ? "" : cookie.status;

            cookie.type = quosal.util.cookie('c_cwopportunitysearch_Type');
            cookie.type = cookie.type == "Type" ? "" : cookie.type;
        }
       catch(e){
        this.resetCookies();
        cookie.companyName = "";
        cookie.contactName = "";
        cookie.opportunityName = ""
        cookie.primarySalesRep = "";
        cookie.secondarySalesRep = "";
        cookie.stageName = "";
        cookie.status = "";
        cookie.type = "";
       }
        
        cookie.pageSize = 100;
        cookie.pageNumber = 0;
        return cookie;
    }
    render(){
        return(
            <div id="ManageOpportunitySearchId">
                <OpportunitySearchbox searchCriteria={this.getSearchCriteria()} resetClick={this.resetCwSavedSearchCriteria} submitClick={this.submitClick} inputChanged={this.inputChanged} />
                <OpportunitySearchTable submitClick={this.submitClick} isSearching={this.state.isSearching} opportunities={this.state.opportunities} pager={this.state.pager} headerNames={this.headerNames} />
            </div>
        )
    }
}