quosal.api.settings = {};

// FSP 9/9/16: WARNING this method is currently unsafe to use with any user settings (non-global settings).
quosal.api.settings.getSettings = function (keys) {
    if (!(keys instanceof Array))
        keys = [keys];

    return quosal.api.call('Settings.GetSettings', {
        keys: keys
    });
};

// FSP 9/9/16: WARNING this method is currently unsafe to use with any user settings (non-global settings).
quosal.api.settings.saveSettings = function (settings) {
    if (!(settings instanceof Array))
        settings = [settings];

    return quosal.api.call('Settings.SaveSettings', {
        settings: settings
    });
};

quosal.api.settings.saveUserSettings = function (settings) {
    if (!(settings instanceof Array))
        settings = [settings];

    return quosal.api.call('Settings.SaveUserSettings', {
        settings: settings
    });
};

quosal.api.settings.getUserSettings = function (keys) {
    if (!(keys instanceof Array))
        keys = [keys];

    return quosal.api.call('Settings.GetUserSettings', {
        keys: keys
    });
};