import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const FormControlLabelCheckbox = ({ id, name, label, sx = {}, ...props }) => {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => {
				const { onChange } = field;
				return (
					<FormControlLabel
						{...props}
						sx={{ ...sx, py: "5px" }}
						control={<Checkbox {...field} checked={field.value} />}
						label={label}
						onChange={async (_, data) => {
							await onChange(data);
							props.onChange && props.onChange(data);
							return data;
						}}
					/>
				);
			}}
			id={id}
		/>
	);
};

export { FormControlLabelCheckbox };
