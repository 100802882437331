/*ColorPicker
prop colorCssSelector - used as a css selector (i.e. 'tabcolor')
prop colorChoices - array of color names by string (['red','blue'])
    add colors to css matching className and color name (.tabcolor.red)
prop selectedColor - the string of the color that is currently selected 
prop colorChangeCallback - function to alert parent of selected color change
optional prop defaultColor - the no color / default color option 
*/
export class ColorPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.selectColorOption = this.selectColorOption.bind(this);
        
    }
    selectColorOption(color){
        this.props.colorChangeCallback(color);
    }
    render(){
        var me = this;
        var mappedColorChoices = this.props.colorChoices.map(function(color, index){
            var optionStyle = {height:'25px', width:'25px', margin:'3px', display:'flex' };
            var className = 'colorpicker ' + me.props.colorCssSelector + " " + color;
            if(me.props.selectedColor == color){
                className += ' selected';
            }
            return (<div key={'colorChoice_' + index} className={className} onClick={me.selectColorOption.bind(this, color)} style={optionStyle} />)
        })
        var defaultSelected = ''
        if(!this.props.selectedColor || this.props.selectedColor == this.props.defaultColor ){
            defaultSelected += ' selected'
        }
        var textDivStyle = {display:'inline-block', verticalAlign:'top', textAlign: 'center', lineHeight: '25px', marginLeft:"30px", cursor: 'default', userSelect:'none'};
        return (
            <div>
                <div style={{display:'flex', flexWrap:'wrap', marginRight: '50px'}}>
                    {mappedColorChoices} 
                    {this.props.defaultColor && 
                        <div  onClick={this.selectColorOption.bind(this, this.props.defaultColor)}>
                            <div className={'colorpicker ' + this.props.colorCssSelector + ' ' + this.props.defaultColor + defaultSelected} style={{height:'25px', width:'25px', margin:'3px', display:'inline-block'}}>
                                <span style={textDivStyle}>No Color</span>
                            </div>
                        </div> 
                    }
                </div>

            </div>
        )
    }
}