export const getDesignTokens = (mode) => ({
    typography: {
        fontFamily: `"Inter", sans-serif`,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'Inter';
                }
              `,
        },
    },
    palette: {
        mode,
        button_primary: {
            ...(mode === 'light'
                ? LIGHT_PALETTE.button_primary
                : DARK_PALETTE.button_primary ),
        },
        button_danger: {
            ...(mode === 'light'
                ? LIGHT_PALETTE.button_danger
                : DARK_PALETTE.button_danger),
        },
        text: {
            ...(mode === 'light'
                ? LIGHT_PALETTE.text
                : DARK_PALETTE.text),
        },
        background: {
            ...(mode === 'light'
                ? LIGHT_PALETTE.background
                : DARK_PALETTE.background),
        },
        layer: {
            ...(mode === 'light'
                ? LIGHT_PALETTE.layer
                : DARK_PALETTE.layer),
        },
        link_primary: {
            ...(mode === 'light'
                ? LIGHT_PALETTE.link_primary
                : DARK_PALETTE.link_primary),
        },
        link_secondary: {
            ...(mode === 'light'
                ? LIGHT_PALETTE.link_secondary
                : DARK_PALETTE.link_secondary),
        },
        border: {
            ...(mode === 'light'
                ? LIGHT_PALETTE.border
                : DARK_PALETTE.border),
        },
        chip: {
            ...(mode === 'light'
                ? LIGHT_PALETTE.chip
                : DARK_PALETTE.chip),
        },
        icon: {
            ...(mode === 'light'
                ? LIGHT_PALETTE.icon
                : DARK_PALETTE.icon),
        }
    }
});


const LIGHT_PALETTE = {
    button_primary: {
        main: '#2E3F80',
        hover: '#222E5E',
        active: '#394E9E',
        disable: '#c6c6c6',
    },
    button_danger: {
        main: '#D32F2F',
        secondary: '#EF5350',
        hover: '#C62828',
        active: '#aa241d',
    },
    text: {
        main: '#101120',
        secondary: '#767786',
        placeholder: '#A3A5AF',
        on_color: '#F1F1F2',
        helper: '#767786',
        error: '#D32F2F',
        inverse: '#FFFFFF',
        disabled: '#CAC9CF',
        on_color_disable: '#CAC9CF',
    },
    background: {
        main: '#ffffff',
        secondary: '#F4F4F4',
        blue: '#FAFAFB',
        active: '#92939C',
        hover: '#99999C',
        selected: '#ECECEE',
        selected_hover: '#212124',
        brand: '#006A8E',
        midnight: '#2E3F80',
        midnight_light: '#4D5F80',
        midnight_dark: '#181C35',
        midnight_hover: '#222E5E',
        inverse: '#121212',
        inverse_hover: '#434343',
        ghost: "#F6F6F9",
        accordian_summary:'#ECECF0',
        accordian_details:'#FAFAFB',
        accordian:'#ffffff',
    },
    layer: {
        layer1: '#F6F6F9',
        layer2: '#ffffff',
        layer3: '#ECECF0',
        layer_hover1: '#F8F8F9',
        layer_hover2: '#E6E7EC',
        layer_hover3: '#E7E7EA',
        layer_active1: '#C0BFC8',
        layer_active2: '#C3C4CA',
        layer_active3: '#C5C6CB',
        layer_selected1: '#F3F6F8',
        layer_selected2: '#E4E5E7',
        layer_selected3: '#E3E3E6',
        layer_selected_hover1: '#EEEEF0',
        layer_selected_hover2: '#CECED3',
        layer_selected_hover3: '#CECED3',
        layer_selected_disable: '#8B8E98',
    },
    link_primary: {
        main: '#006A8E',
        hover: '#005673',
        inverse: '#758BFD',
        visited: '#0D44D4',
    },
    link_secondary: {
        main: '#2E3F80',
        hover: '#222E5E',
    },
    border: {
        border_subtle: '#F6F6F7',
        border_subtle1: '#F4F4F5',
        border_subtle2: '#ECECEE',
        border_subtle3: '#E4E4E7',
        border_subtle_selected1: '#D1D2D7',
        border_subtle_selected2: '#CBCCD2',
        border_subtle_selected3: '#C3C4CB',
        border_strong1: '#84828E',
        border_strong2: '#5A5D65',
        border_strong3: '#898994',
        border_inverse: '#1E1E22',
        border_interactive: '#394E9E',
        border_disabled: '#C6C6CC',
        border_accordian:'#E4E4E7',
    },
    chip: {
        sand_background: '#FFCD49',
        sand_text: '#151515',
        sand_hover: '#F0B929',
        green_background: '#BAE966',
        green_text: '#151515',
        green_hover: '#A7DB4B',
        peach_background: '#FF8E4F',
        peach_text: '#151515',
        peach_hover: '#EC7837',
        indigo_background: '#857AF5',
        indigo_text: '#FFF',
        indigo_hover: '#5A4BF1',
        salmon_background: '#EE5A56',
        salmon_text: '#FFF',
        salmon_hover: '#DA433F',
        yellow_background: '#F2D91E',
        yellow_text: '#121619',
        yellow_hover: '#CFB80C',
    },
    icon: {
        main: '#27366D',
        secondary: '#4C5780',
        inverse: '#FAFAFA',
    }
};

const DARK_PALETTE = {
    button_primary: {
        main: '#3AAACF',
        hover: '#41accf',
        active: '#3AAACF',
        disable: '#999999',
    },
    button_danger: {
        main: '#EF7B74',
        secondary: '#EF5350',
        hover: '#F18E88',
        active: '#EF7B74',
    },
    text: {
        main: '#FFFFFF',
        secondary: '#CCCCCC',
        placeholder: '#CCCCCC',
        on_color: '#FFFFFF',
        helper: '#333333',
        error: '#EF7B74',
        inverse: '#F1F1F2',
        disabled: '#CCCCCC',
        on_color_disable: '#F6F6F7',
    },
    background: {
        main: '#333333',
        secondary: '#333333',
        blue: '#0A1023',
        active: '#92939C59',
        hover: '#99999C29',
        selected: '#99999C29',
        selected_hover: '#212124',
        brand: '#006A8E',
        midnight: '#2E3F80',
        midnight_light: '#4D5F80',
        midnight_dark: '#181C35',
        midnight_hover: '#10162F',
        inverse: '#EDEEF1',
        inverse_hover: '#FFFFFF',
        ghost: "#434343",
        accordian_summary:'#595959',
        accordian_details:'#404040',
        accordian:'#404040',
    },
    layer: {
        layer1: '#333333',
        layer2: '#434343',
        layer3: '#29345B',
        layer_hover1: '#333333',
        layer_hover2: '#2B355F',
        layer_hover3: '#3F3E55',
        layer_active1: '#213374',
        layer_active2: '#1E3586',
        layer_active3: '#253A89',
        layer_selected1: '#1F2642',
        layer_selected2: '#1C233F',
        layer_selected3: '#1F2642',
        layer_selected_hover1: '#2C418D',
        layer_selected_hover2: '#1F368A',
        layer_selected_hover3: '#263B8D',
        layer_selected_disable: '#40424B',
    },
    link_primary: {
        main: '#3AAACF',
        hover: '#4CB1D2',
        inverse: '#006A8E',
        visited: '#557DE4',
    },
    link_secondary: {
        main: '#66A6BB',
        hover: '#579CB2',
    },
    border: {
        border_subtle: '#1D2853',
        border_subtle1: '#323B5C',
        border_subtle2: '#CCCCCC',
        border_subtle3: '#434F79',
        border_subtle_selected1: '#47537C',
        border_subtle_selected2: '#515E8A',
        border_subtle_selected3: '#586592',
        border_strong1: '#CCCCCC',
        border_strong2: '#505F93',
        border_strong3: '#5A6A9E',
        border_inverse: '#2F356C',
        border_interactive: '#394E9E',
        border_disabled: '#323B5C',
        border_accordian:'#989898',
    },
    chip: {
        sand_background: '#FFCD49',
        sand_text: '#151515',
        sand_hover: '#F0B929',
        green_background: '#BAE966',
        green_text: '#151515',
        green_hover: '#A7DB4B',
        peach_background: '#FF8E4F',
        peach_text: '#151515',
        peach_hover: '#EC7837',
        indigo_background: '#857AF5',
        indigo_text: '#FFF',
        indigo_hover: '#5A4BF1',
        salmon_background: '#EE5A56',
        salmon_text: '#FFF',
        salmon_hover: '#DA433F',
        yellow_background: '#F2D91E',
        yellow_text: '#121619',
        yellow_hover: '#CFB80C',
    },
    icon: {
        main: '#879CE9',
        secondary: '#9FB0EC',
        inverse: '#FAFAFA',
    }
};