import { Autocomplete, TextField, useTheme } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export function ComboBox({
	id,
	name,
	label,
	options = [],
	disabled = false,
	disableClearable = false,
	required = false,
	sx = {},
	...props
}) {
	const { control } = useFormContext();
	const theme = useTheme();

	return (
		<Controller
			id={id}
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => {
				const { onChange, onBlur } = field;
				return (
					<Autocomplete
						{...field}
						sx={{
							width: "276px",
							"& .MuiOutlinedInput-root": {
								padding: "1px 16px"
							},
							"& .MuiAutocomplete-inputRoot": {
								"> .MuiAutocomplete-input": {
									paddingLeft: "0px"
								}
							},
							"& .MuiAutocomplete-endAdornment": {
								">button": {
									minWidth: "28px",
									minHeight: "28px"
								}
							},
							"& .Mui-focused .MuiInputBase-input": {
								boxShadow: "none"
							},
							"& .MuiInputBase-input.Mui-disabled": {
								color: theme.palette.text.disabled,
								backgroundColor: theme.palette.background.default
							},
							...sx
						}}
						slotProps={{
							popper: {
								sx: {
									zIndex: 9998
								}
							}
						}}
						options={options}
						getOptionLabel={(option) =>
							typeof option === "string"
								? option
								: props.getOptionLabel
								? (option) => props.getOptionLabel(option)
								: undefined
						}
						disabled={disabled}
						renderOption={
							props.renderOption
								? (props, option, state, ownerState) =>
										props.renderOption(props, option, state, ownerState)
								: undefined
						}
						isOptionEqualToValue={(option, value) =>
							typeof option === "string" ? option === value : option.id === value.id
						}
						renderInput={(params) => (
							<TextField
								{...params}
								error={!!error}
								helperText={error ? error.message : props.helperText}
								label={label}
								variant="outlined"
								fullWidth
								inputProps={{
									...params.inputProps,
									"data-testid": id,
									autoComplete: "disabled" // disable autocomplete & autofill popup
								}}
								InputLabelProps={{
									shrink: true
								}}
								FormHelperTextProps={{
									sx: {
										marginLeft: 0,
										marginRight: 0
									}
								}}
								required={required}
							/>
						)}
						onChange={async (_, data) => {
							await onChange(data);
							props.onChange && props.onChange(data);
							return data;
						}}
						onBlur={async (e) => {
							await onBlur();
							props.onBlur && props.onBlur(e);
						}}
						disableClearable={!!error || disableClearable}
						{...props}
					/>
				);
			}}
		/>
	);
}
