// ******************************************************************************************
// ******************************************************************************************
import { format } from 'js/lib/timeago.min.js';
const greenApprovalSvg = <svg data-cy={"approved"} className="approvalSVG" fill="green" width="24px" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 48 48" focusable="false" viewBox="0 0 48 48"><g><path d="M0 0h48v48H0z" fill="none" /><path d="M24 4C13 4 4 13 4 24s9 20 20 20 20-9 20-20S35 4 24 4zm0 36c-8.8 0-16-7.2-16-16S15.2 8 24 8s16 7.2 16 16-7.2 16-16 16zm7.9-21.7c.8.8.8 2 0 2.8L22 31c-.4.4-.9.6-1.4.6s-1-.2-1.4-.6l-4.5-4.5c-.8-.8-.8-2 0-2.8.8-.8 2-.8 2.8 0l3.1 3.1 8.5-8.5c.8-.8 2-.8 2.8 0z" /></g></svg>;
const blackApprovalSvg = <svg data-cy={"notApproved"} className="approvalSVG" fill="#b0b0b0" width="24px" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 48 48" focusable="false" viewBox="0 0 48 48"><g><path d="M0 0h48v48H0z" fill="none" /><path d="M24 4C13 4 4 13 4 24s9 20 20 20 20-9 20-20S35 4 24 4zm0 36c-8.8 0-16-7.2-16-16S15.2 8 24 8s16 7.2 16 16-7.2 16-16 16zm7.9-21.7c.8.8.8 2 0 2.8L22 31c-.4.4-.9.6-1.4.6s-1-.2-1.4-.6l-4.5-4.5c-.8-.8-.8-2 0-2.8.8-.8 2-.8 2.8 0l3.1 3.1 8.5-8.5c.8-.8 2-.8 2.8 0z" /></g></svg>;

const ApprovalContext = React.createContext();

export class QuoteApprovalMain extends React.Component {
    constructor(props) {
        super(props);
        this.updateVisibleRules = this.updateVisibleRules.bind(this);
        this.updateLogEntries = this.updateLogEntries.bind(this);
        this.updateApprovalNotificationId = this.updateApprovalNotificationId.bind(this);
        this.updateIsApprover = this.updateIsApprover.bind(this);
        this.updateUI = this.updateUI.bind(this);
        this.updateParentNotificationDetailData = this.updateParentNotificationDetailData.bind(this);

        this.state = {
            visibleApproverTriggeredRulesSecondPanel: this.props.rulesCurrentUserDoesNotNeedToTakeAction,
            visibleTriggeredRules: this.props.triggeredApprovalRules,
            logEntries: this.props.logMessages,
            approvalNotificationId: this.props.approvalNotificationId,
            isApprover: this.props.isApprover,
            approvalChain: this.props.approvalChain,
            acceptRejectDisable: this.props.acceptRejectDisable,
            approvalsRequireComments: this.props.approvalsRequireComments
        }
    }

    componentDidMount() {
        this.componentIsMounted = true;
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    updateApprovalNotificationId(approvalId) {
        this.setState({
            approvalNotificationId: approvalId
        })
    }

    updateVisibleRules(msg) {
        try {
            if (msg.notificationDetailsData) {
                var filteredRules = JSON.parse(msg.notificationDetailsData);
                //Create second panel, and show first panel
                if (filteredRules.RulesCurrentUserDoesNotNeedToTakeAction && filteredRules.RulesCurrentUserDoesNotNeedToTakeAction.length != 0) {
                    this.setState({
                        visibleTriggeredRules: filteredRules.TriggeredRules, visibleApproverTriggeredRulesSecondPanel: filteredRules.RulesCurrentUserDoesNotNeedToTakeAction,
                        acceptRejectDisable: filteredRules.DisableAcceptRejectButton
                    });
                }
                else { //update rules of single panel
                    this.setState({ visibleTriggeredRules: filteredRules.TriggeredRules, visibleApproverTriggeredRulesSecondPanel: null, acceptRejectDisable: filteredRules.DisableAcceptRejectButton });
                }
            }
            else {
                var allRules = JSON.parse(msg.approvalList);
                this.setState({ visibleTriggeredRules: allRules, visibleApproverTriggeredRulesSecondPanel: null });
            }
        } catch {

        }
    }

    updateLogEntries(logs) {
        try {
            this.setState({ logEntries: logs });
        } catch{ }
    }

    updateIsApprover(isApprover) {
        try {
            this.setState({ isApprover: isApprover });
        } catch{ }
    }

    updateParentNotificationDetailData(data) {
        try {
            this.setState({ isApprover: data.IsApprover, logEntries: data.Logs, approvalChain: data.ApprovalChains, visibleApproverTriggeredRulesSecondPanel: data.RulesCurrentUserDoesNotNeedToTakeAction });
        } catch{ }
    }

    updateUI() {
        this.forceUpdate();
    }
    render() {
        var emptyFeedMessage = "This is where you'll see who has approved or rejected your quote along with any notes."
        var title = "Rules Requiring My Approval";
        if (app.currentQuote.ApprovalStatus == "" || app.currentQuote.ApprovalStatus == "Rejected") {
            title = "Triggered Rules"
        }
        return (
            <ApprovalContext.Provider value={this}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ flex: 1, minWidth: "500px" }}>
                        <ApprovalActionPanel isApprover={this.state.isApprover} acceptRejectDisable={this.state.acceptRejectDisable}
                            triggeredApprovalRules={this.state.visibleTriggeredRules} approvalNotificationId={this.state.approvalNotificationId}
                            approvalsRequireComments={this.state.approvalsRequireComments} />
                        <RequireApprovalPanel title={title} triggeredApprovalRules={this.state.visibleTriggeredRules}
                            approvalChain={this.state.approvalChain} approvalNotificationId={this.state.approvalNotificationId} />
                        {this.state.visibleApproverTriggeredRulesSecondPanel && this.state.visibleApproverTriggeredRulesSecondPanel.length > 0
                            ? <RequireApprovalPanel title={"Triggered Rules"} approvalChain={this.state.approvalChain} triggeredApprovalRules={this.state.visibleApproverTriggeredRulesSecondPanel} /> : null}
                    </div>
                    <div style={{ flex: 1, minWidth: "500px" }}>
                        <FeedDisplay title="Activity" className="feedDisplay" emptyFeedMessage={emptyFeedMessage} logEntries={this.state.logEntries} />
                    </div>
                </div>
            </ApprovalContext.Provider>
        )
    }
}

class ApprovalPanelButton extends React.Component {
    constructor(props) {
        super(props);
        this.openApprovalDialog = this.openApprovalDialog.bind(this);
        this.approvalResponse = this.approvalResponse.bind(this);
        Object.assign(this, ApprovalTypeSettings[this.props.approvalType]);
    }


    approvalResponse(message) {
        var approvalNotificationId = this.context && this.context.state && this.context.state.approvalNotificationId;
        const api = this.api(app.currentQuote.IdQuoteMain, message, approvalNotificationId, false);

        api.finished = function (msg) {
            Dialog.setIsWorking(false);

            try {
                if (msg.approvalAcceptResponse) {
                    var response = JSON.parse(msg.approvalAcceptResponse);
                    if (response.Status != "Error") {
                        var notificationResonse = JSON.parse(msg.notificationDetailsData);
                        this.context.updateParentNotificationDetailData(notificationResonse);
                        app.currentQuote.ApprovalStatus = msg.approvalStatus;
                        quosal.sell.quote.update(app.currentQuote);
                        this.context.updateUI();
                    }
                }

                if (msg.approvalRejectResponse) {
                    var response = JSON.parse(msg.approvalRejectResponse);
                    if (response.Status != "Error") {
                        var logData = JSON.parse(msg.logs);
                        this.context.updateLogEntries(logData)

                        app.currentQuote.IsLocked = msg.isLocked;
                        app.currentQuote.ApprovalStatus = msg.approvalStatus;
                        quosal.sell.quote.update(app.currentQuote);

                        this.context.updateIsApprover(response.IsApprover);
                    }
                }

                if (msg.approvalRequestResponse) {
                    var response = JSON.parse(msg.approvalRequestResponse);
                    if (response.Status != "Error") {
                        app.currentQuote.IsLocked = msg.isLocked;
                        app.currentQuote.ApprovalStatus = msg.approvalStatus;
                        quosal.sell.quote.update(app.currentQuote);

                        var notificationResonse = JSON.parse(msg.notificationDetailsData);
                        this.context.updateApprovalNotificationId(response.ApprovalNotificationId);
                        this.context.updateParentNotificationDetailData(notificationResonse);
                    }
                }

                if (msg.cancelApprovalRequestResponse) {
                    var response = JSON.parse(msg.cancelApprovalRequestResponse);
                    if (response.Status != "Error") {
                        var logData = JSON.parse(msg.logs);

                        app.currentQuote.IsLocked = msg.isLocked;
                        app.currentQuote.ApprovalStatus = msg.approvalStatus;
                        quosal.sell.quote.update(app.currentQuote);

                        this.context.updateLogEntries(logData);
                        this.context.updateIsApprover(response.IsApprover);
                    }
                }

                if (response.Status == "Success") {
                    this.context.updateVisibleRules(msg);
                    Dialog.close();
                }
                else if (response.Status == "Warning") {
                    this.context.updateVisibleRules(msg);
                    Dialog.open({
                        message: response.Message,
                        closeRequiresButton: true,
                        links: [{ title: 'CLOSE', callback: Dialog.closeAll }],
                    });
                }
                else {
                    Dialog.open({
                        message: response.Message,
                        closeRequiresButton: true,
                        links: [{ title: 'CLOSE', callback: Dialog.closeAll }],
                        width: '40%'
                    });
                }
            } catch{
                Dialog.open({
                    message: "An unknown error has occurred. If this continues to happen, please contact ConnectWise Support.",
                    closeRequiresButton: true,
                    links: [{ title: 'CLOSE', callback: Dialog.closeAll }],
                });
            }

        }.bind(this);

        api.call();
        Dialog.setIsWorking();
    }

    openApprovalDialog() {
        Dialog.open({
            closeRequiresButton: true,
            title: this.dialogTitle,
            message: <ApprovalRequestBox saveChanges={this.approvalResponse} requesttype={this.props.approvalType} requireComments={this.props.approvalsRequireComments} ></ApprovalRequestBox>
        })
    }

    render() {
        var approvalClass = this.className;
        if (this.props.disabled) {
            approvalClass += " disabled"
        }

        return (
            <PanelToolbar style={{ paddingLeft: "7px" }}>
                 <div data-cy={this.cyName} className={approvalClass}>
                    <CwWidgets.CwButton value={this.buttonText} toolTip={this.buttonToolTip} disabled={this.props.disabled}
                        onClick={this.openApprovalDialog} style={this.cwButtonStyle} isStandard={this.cwIsStandard} />
                </div>
            </PanelToolbar>
        )
    }
}
ApprovalPanelButton.contextType = ApprovalContext;

const ApprovalTypeSettings = {
    request: {
        api: (...args) => quosal.api.approval.submitApprovalRequest(...args),
        dialogTitle: "Request Approval",
        dialogButtonText: "",
        buttonText: "REQUEST",
        buttonToolTip: "Request Approval",
        cyName: "approvalPanelButton",
        className: "",
        cwIsStandard: true,

    },
    cancelapproval: {
        api: (...args) => quosal.api.approval.submitCancelApprovalRequest(...args),
        dialogTitle: "Cancel Requested Approvals",
        buttonText: "CANCEL REQUEST",
        buttonToolTip: "Cancel Requested Approvals",
        cyName: "cancelApprovalPanelButton",
        className: "",
        cwIsStandard: true,
    },
    approve: {
        api: (...args) => quosal.api.approval.submitApprovalAccept(...args),
        dialogTitle: "Approve Request",
        buttonText: "APPROVE",
        buttonToolTip: "Approve Request",
        cyName: "acceptApprovalPanelButton",
        className: "approvalWhiteText acceptButton",
        cwButtonStyle: { paddingLeft: "9px", width: "96px", borderRadius: "4px" }
    },
    reject: {
        api: (...args) => quosal.api.approval.submitApprovalReject(...args),
        dialogTitle: "Reject Request",
        buttonText: "REJECT",
        buttonToolTip: "Reject Request",
        cyName: "rejectApprovalPanelButton",
        className: "approvalWhiteText rejectButton",
        cwButtonStyle: { width: "89px", paddingLeft: "15px", borderRadius: "4px" },

    },
}

class ApprovalActionPanel extends React.Component {
    constructor(props) {
        super(props);
        this.getVisibleButtons = this.getVisibleButtons.bind(this);
    }

    getVisibleButtons() {
        var cancelRequestDisable = app.currentUser.IdUsers != app.currentQuote.Owner.IdUsers && app.currentUser.IdUsers != app.currentQuote.InsideRep.IdUsers;
        var buttonArray = [];

        if (this.props.isApprover) { //add logic to check if approval processes is sequental and that the user should have access to approve reject yet
            buttonArray.push(<ApprovalPanelButton approvalType="reject" key="RejectApprovalPanelButton"
                disabled={app.currentQuote.ApprovalStatus == "Approved" || this.props.acceptRejectDisable ? true : false} approvalsRequireComments={this.props.approvalsRequireComments} />)
            buttonArray.push(<ApprovalPanelButton approvalType="approve" dialogTitle="Approve Request" key="AcceptApprovalPanelButton"
                disabled={app.currentQuote.ApprovalStatus == "Approved" || this.props.acceptRejectDisable ? true : false} approvalsRequireComments={this.props.approvalsRequireComments} />)
        }
        if (app.currentQuote.ApprovalStatus == 'Request Approval' && !app.currentUser.IsAdministrator && cancelRequestDisable) {
            buttonArray.push(<ApprovalPanelButton approvalType="cancelapproval" key='CancelApprovalPanelButton' disabled={true} approvalsRequireComments={this.props.approvalsRequireComments} />);
        }
        else if (app.currentQuote.ApprovalStatus == 'Request Approval') {
            buttonArray.push(<ApprovalPanelButton approvalType="cancelapproval" key='CancelApprovalPanelButton' approvalsRequireComments={this.props.approvalsRequireComments} />);
        }
        else if (!this.props.triggeredApprovalRules || (this.props.triggeredApprovalRules && this.props.triggeredApprovalRules.length == 0)) {
            buttonArray.push(<ApprovalPanelButton approvalType="request" key="RequestApprovalPanelButton" disabled={true} approvalsRequireComments={this.props.approvalsRequireComments} />);
        } else {
            buttonArray.push(<ApprovalPanelButton approvalType="request" key="RequestApprovalPanelButton" disabled={app.currentQuote.ApprovalStatus == "Approved" ? true : false} approvalsRequireComments={this.props.approvalsRequireComments} />);
        }

        return buttonArray;
    }

    render() {
        return (
            <Panel className="approvalActionPanel" style={{ display: "flex", justifyContent: "flex-end" }} beforeTitle={app.currentQuote.QuoteStatus != 'Archived' ? this.getVisibleButtons(): null} />
        )
    }

}
global.ApprovalActionPanel = ApprovalActionPanel;
ApprovalActionPanel.contextType = ApprovalContext;

export class RequireApprovalPanel extends React.Component {
    constructor(props) {
        super(props);
        this.getApprovalRows = this.getApprovalRows.bind(this);
        this.getAllApproverChainWithRuleID = this.getAllApproverChainWithRuleID.bind(this);
    }

    getAllApproverChainWithRuleID(ruleId) {
        if (this.props && this.props.approvalChain) {
            return this.props.approvalChain.filter(chainApprover => chainApprover.ApprovalRuleId == ruleId);
        } else {
            return [];
        }
    }

    getApprovalRows() {
        return this.props.triggeredApprovalRules.map(function (item, index) {
            var filteredChain = this.getAllApproverChainWithRuleID(item.Rule.RuleId);
            return (
                <RequireApprovalRow key={'approvalRow' + index} approvalChain={filteredChain} isSequential={item.Rule.ApproveSequentially} approvalRule={item} />
            )
        }, this)
    }

    render() {
        return (
            <Panel title={this.props.title} className="rulesPanel">
                {this.props.triggeredApprovalRules && this.getApprovalRows()}
            </Panel>
        )
    }
}
global.RequireApprovalPanel = RequireApprovalPanel;

export class RequireApprovalRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        }

        this.toggleExpand = this.toggleExpand.bind(this);
    }

    toggleExpand() {
        this.setState({ expanded: !this.state.expanded });
    }

    checkIfRuleHasAnyCriteria() {
        return (this.props.approvalRule &&
            (this.props.approvalRule.QuoteCriteria != null || this.props.approvalRule.QuoteItemsCriteria != null)) ||
            (this.props.approvalRule.Rule &&
                (this.props.approvalRule.Rule.Location != '' ||
                    this.props.approvalRule.Rule.Team != '' ||
                    this.props.approvalRule.Rule.Template != '')
            )
    }

    shouldShowApproverStatus() {
        return app.currentQuote.ApprovalStatus == "Request Approval" || app.currentQuote.ApprovalStatus == "Approved"
    }
    render() {
        var expanderClassName = '';
        var shouldExpand = this.checkIfRuleHasAnyCriteria();
        var expandClick = function () { };
        if (shouldExpand) {
            expanderClassName = 'icon ' + (this.state.expanded ? 'expand' : 'expandRight') + ' icons-action';
            expandClick = this.toggleExpand;
        }

        var rowStyle = { borderBottom: '#EEEEEE solid 1px' };
        this.state.expanded && (rowStyle['paddingBottom'] = 28);
        return (
            <div className='approvalRuleBlockParent' onClick={expandClick} style={rowStyle}>
                <div data-cy='approvalRuleBlock' className='requireApprovalRow' style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center', height: 56, marginTop: 10, marginBottom: 10 }}>
                    <div className={expanderClassName} style={{ width: 40, height: 24, marginLeft: 16, flex: 'none' }} />
                    <div className="rowGroup" style={{ display: 'flex', marginLeft: 21 }}>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div className="approvalRowWrapper">
                                <div className='approvalRowTitle' title={this.props.approvalRule.Rule.Name}>
                                    {this.props.approvalRule.Rule.Name}
                                </div>
                                <div className='approvalRowSubText' title={this.props.approvalRule.Rule.Message}>
                                    {this.props.approvalRule.Rule.Message}
                                </div>
                            </div>
                            <div>
                                {this.shouldShowApproverStatus() && <ApproverStatus isSequential={this.props.isSequential} approvalChain={this.props.approvalChain} />}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.expanded && <ApproverStatusRowInfo ruleDetailCrieria={this.props.approvalRule.Rule} quoteCriteria={this.props.approvalRule.QuoteCriteria} itemCriteria={this.props.approvalRule.QuoteItemsCriteria} />}
            </div>
        )
    }
}

export class ApproverStatusRowInfo extends React.Component {
    constructor(props) {
        super(props);

        this.getCriteriaInfoItems = this.getCriteriaInfoItems.bind(this);
        this.getOperationDataDescription = this.getOperationDataDescription.bind(this);
    }

    getOperationDataDescription(operation) {
        var foundIndex = quosal.util.findWithAttr(quosal.multiLevelApprovals.dataDescriptions, "option", operation);
        if (foundIndex > -1) {
            return quosal.multiLevelApprovals.dataDescriptions[foundIndex].value;
        } else {
            return " ";
        }
    }

    getRuleDetailItems() {
        var ruleDetails = [];

        if (this.props.ruleDetailCrieria.Location != '') {
            ruleDetails.push(
                <tr data-cy='approvalRuleCriteria' key={"approvalRuleCriteria" + ruleDetails.length}>
                    <td data-cy='approvalRuleCriteriaChild'>
                        {"Location = " + this.props.ruleDetailCrieria.Location}
                    </td>
                    <td style={{ paddingLeft: 16 }} data-cy='approvalRuleActuals'>
                        {app && app.currentQuote && app.currentQuote.Location && app.currentQuote.Location.LocationName}
                    </td>
                </tr>
            )
        }

        if (this.props.ruleDetailCrieria.Template != '') {
            ruleDetails.push(
                <tr data-cy='approvalRuleCriteria' key={"approvalRuleCriteria" + ruleDetails.length}>
                    <td data-cy='approvalRuleCriteriaChild'>
                        {"Template = " + this.props.ruleDetailCrieria.Template}
                    </td>
                    <td style={{ paddingLeft: 16 }} data-cy='approvalRuleActuals'>
                        {this.props.ruleDetailCrieria.Template}
                    </td>
                </tr>
            )
        }

        if (this.props.ruleDetailCrieria.Team != '') {
            ruleDetails.push(
                <tr data-cy='approvalRuleCriteria' key={"approvalRuleCriteria" + ruleDetails.length}>
                    <td data-cy='approvalRuleCriteriaChild'>
                        {"Team = " + this.props.ruleDetailCrieria.Team}
                    </td>
                    <td style={{ paddingLeft: 16 }} data-cy='approvalRuleActuals'>
                        {(app && app.currentUser && app.currentUser.TeamName || '')}
                    </td>
                </tr>
            )
        }
        return ruleDetails;
    }

    getCriteriaInfoItems() {
        function makeReferenceElement(item, detail) {
            let referenceClass = 'approvalRuleActuals';
            let mouseOverText = '';
            var tabId = null;
            var mpn = quosal.util.getItemById(app.currentQuote, item.ItemId).ManufacturerPartNumber
            if (mpn && item.ItemId) {
                referenceClass += ' referenceLink';
                var tab = quosal.util.getTabByItemId(app.currentQuote, item.ItemId);
                mouseOverText = 'Tab: ' + tab.TabName;
                tabId = tab.IdQuoteTabs;
            }
            return <div data-cy='triggeredRuleReference' title={mouseOverText} onClick={() => quosal.navigation.goToTab(tabId, item.ItemId)} className={referenceClass}>{item.ItemName}</div>
        }

        return this.props.itemCriteria.map(function (item) {
            return item.CriteriaDetails.map(function (detail, index) {
                return (
                    <tr data-cy='approvalRuleCriteria' key={"approvalRuleCriteria" + index}>
                        <td data-cy='approvalRuleCriteriaChild'>
                            {detail.Field} {this.getOperationDataDescription(detail.Operation)} {detail.CompareValue}
                        </td>
                        <td data-cy='approvalRuleActuals' style={{ paddingLeft: 16 }}>
                            {detail.ActualValue}
                        </td>
                        <td data-cy='approvalRuleMFP' style={{ paddingLeft: 16 }}>
                            {makeReferenceElement(item, detail)}
                        </td>
                    </tr>
                )
            }, this)
        }, this)
    }

    getCriteriaInfoQuote() {
        return this.props.quoteCriteria.CriteriaDetails.map(function (detail, index) {
            return (
                <tr data-cy='approvalRuleCriteria' key={"approvalRuleCriteria" + index} >
                    <td data-cy='approvalRuleCriteriaChild'>
                        {detail.Field} {this.getOperationDataDescription(detail.Operation)} {detail.CompareValue}
                    </td>
                    <td data-cy='approvalRuleActuals' style={{ paddingLeft: 16 }}>
                        {detail.ActualValue}
                    </td>
                </tr>
            )
        }, this)
    }
    render() {
        return (
            <div key="" style={{ marginLeft: 11, marginTop: 24 }}>
                <table className='approverStatusTable' style={{ borderSpacing: '50px 0' }}>
                    <tbody>
                        <tr>
                            <td className='rowInfoStatusTitle'>
                                Criteria
                            </td>
                            <td className='rowInfoStatusTitle' style={{ paddingLeft: 16 }}>
                                Actual Value
                            </td>
                            {
                                this.props.itemCriteria && <td className='rowInfoStatusTitle' style={{ paddingLeft: 16 }}>
                                    MFP#
                                </td>
                            }
                        </tr>
                        {this.props.itemCriteria && this.getCriteriaInfoItems()}
                        {this.props.quoteCriteria && this.getCriteriaInfoQuote()}
                        {this.props.ruleDetailCrieria && this.getRuleDetailItems()}
                    </tbody>
                </table>
            </div>
        )
    }
}

export class FeedDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.getActivityLog = this.getActivityLog.bind(this);
    }

    getActivityLog(activityMessage) {
        if (activityMessage && activityMessage.length > 0) {
            return this.hasActivityMessage(activityMessage);
        } else {
            return this.noActivityMessage();
        }
    }

    hasActivityMessage(activityMessage) {
        return activityMessage.map((message, i) => {

            return (
                <div className="feedrowwrapper" key={"activityMessage" + i} >
                    {message.LogUserImage ?
                        <img className="rounded-rep feedrowimage" src={message.LogUserImage} /> :
                        <div className="feedrowimage" />
                    }
                    <div className="feedrowapprovalcontent">
                        <div className="feedrowapprovalcontenttop">
                            <div className="feedrowuser">
                                {message.LoggedBy}
                            </div>
                            <div className="feedrowtime">
                                • {format(message.LoggedDate)}
                            </div>
                        </div>
                        <div className="feedrowapprovalcontentbottom">
                            {message.Comments}
                        </div>
                    </div>
                    {quosal.multiLevelApprovals.getStatusElement(message.Action)}
                </div>
            );
        });
    }

    noActivityMessage() {
        return (
            <div style={{ marginTop: 60 }}>
                <img src='img/svgs/v1.0/Activity_Feed_Background.svg' style={{ display: 'block', margin: 'auto', marginBottom: 10 }} />
                <div style={{ fontWeight: 'bold', color: '#4a4a4a', fontSize: 14, textAlign: 'center', marginBottom: 8 }}>
                    No activity yet
                    </div>
                <div style={{ color: '#4a4a4a', fontSize: 14, textAlign: 'center', width: 220, margin: 'auto' }}>
                    {this.props.emptyFeedMessage}
                </div>
            </div>
        )
    }

    render() {
        var activityLog = this.getActivityLog(this.props.logEntries);
        return (
            <Panel title={this.props.title} className={this.props.className} style={this.props.style}>
                {activityLog}
            </Panel>
        )
    }
}

global.FeedDisplay = FeedDisplay;

export class ApproverStatus extends React.Component {
    constructor(props) {
        super(props);
        this.getApproverStatusIcon = this.getApproverStatusIcon.bind(this);
        this.getApproverStatusRows = this.getApproverStatusRows.bind(this);
        this.getApproverCount = this.getApproverCount.bind(this);
    }

    getApproverStatusRows() {
        try {
            return this.props.approvalChain.map((approver, index) => {
                return (
                    <div key={"approverStatusRow" + index} className="approverStatusRowWrapper">
                        {this.props.isSequential && <div className="approverStatusNumberCircle">{index + 1}</div>}
                        <img className="approvalStateImage" src={approver.ApproverImage ? approver.ApproverImage : "images/empty.png"} />
                        <div className="approverStatusName">{approver.Group ? approver.Group : approver.ApproverName}</div>
                        {approver.ApprovalStatus == "Approved" ? greenApprovalSvg : blackApprovalSvg}
                    </div>
                )
            })
        } catch{ }
    }

    getApproverStatusIcon() {
        var totalCount = this.props.approvalChain.length;
        var currentlyApproved = this.props.approvalChain.filter(chainItem => chainItem.ApprovalStatus == "Approved").length;
        return currentlyApproved == totalCount ? greenApprovalSvg : blackApprovalSvg
    }

    getApproverCount() {
        var totalCount = this.props.approvalChain.length;
        var currentlyApproved = this.props.approvalChain.filter(chainItem => chainItem.ApprovalStatus == "Approved").length;
        return currentlyApproved + "/" + totalCount;
    }

    render() {
        return (
            <div className="approverStatusWrapper">
                <div className="approverStatusDisplay">
                    {this.getApproverCount()}
                </div>
                {this.getApproverStatusIcon()}
                <Panel className="approverStatusPopup">
                    <div className="approverStatusPopupTitle">Approvers</div>
                    <div className="arrowLeft" />
                    {this.getApproverStatusRows()}
                </Panel>
            </div>
        );
    }
}
global.ApproverStatus = ApproverStatus;

// ******************************************************************************************
// ******************************************************************************************
export class TriggeredApprovalRules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLocked: false,
            isArchive: false,
        };
        this.renderTriggerRule = this.renderTriggerRule.bind(this);
    }
    componentDidMount() {
        this.componentIsMounted = true;
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
    }

    renderTriggeredRules() {
        var getOperationDataDescription = function (operation) {
            var foundIndex = quosal.util.findWithAttr(quosal.multiLevelApprovals.dataDescriptions, "option", operation);
            if (foundIndex > -1) {
                return quosal.multiLevelApprovals.dataDescriptions[foundIndex].value;
            } else {
                return " ";
            }
        }
        if (!this.props.triggeredApprovalRules) {
            return
        }
        return this.props.triggeredApprovalRules.map(function (rule, index) {
            var ruleset = []
            if (!rule.QuoteItemsCriteria && !rule.QuoteCriteria) {
                ruleset.push({
                    crit: "This quote always requires approval"
                });
            }
            rule.QuoteCriteria && rule.QuoteCriteria.CriteriaDetails.forEach(function (crit) {
                ruleset.push({
                    crit: "Quote." + crit.Field + " " +
                        getOperationDataDescription(crit.Operation) +
                        " " + crit.CompareValue,
                    real: crit.ActualValue,
                });
            }, this);

            rule.QuoteItemsCriteria && rule.QuoteItemsCriteria.forEach(function (crit) {
                ruleset.push({
                    crit: "Item." + crit.CriteriaDetails[0].Field + " " +
                        getOperationDataDescription(crit.CriteriaDetails[0].Operation)
                        + " " + crit.CriteriaDetails[0].CompareValue,
                    real: crit.CriteriaDetails[0].ActualValue,
                    related: "MPN: " + quosal.util.getItemById(app.currentQuote, crit.ItemId).ManufacturerPartNumber,
                    itemId: crit.ItemId

                });
            }, this);

            return this.renderTriggerRule(index + 1,
                {
                    name: rule.Rule.Name,
                    notation: rule.Rule.Message,
                    criteria: ruleset

                }, this)
        }, this);
    }

    renderTriggerRule(index, rule) {
        let crits = [];
        let actuals = [];
        let references = [];
        rule && rule.criteria && rule.criteria.forEach(function (crit) {
            crits.push(<div data-cy="approvalRuleCriteria" title={crit.crit} className='approvalRuleCriteria'><span className='approvalRuleCritLabel'>Criteria: </span>{crit.crit}</div>);
            actuals.push(<div data-cy='approvalRuleActuals' title={crit.real} className='approvalRuleActuals'>{crit.real}</div>);
            references.push(makeReferenceElement(crit, this));
        }, this);
        return (
            <div data-cy="approvalRuleBlock" className='approvalRuleBlock'>
                <div className='approvalRuleLeftGroup'>
                    <div className='approvalRuleLeftGroupItemTitle'>
                        <span className='approvalRuleNumbering'>{index}.</span>
                        <span Title={rule.notation} className='approvalRuleLeftHeader'>{rule.notation}</span>
                    </div>
                    {crits}
                    <div className='approvalRuleName'>{rule.name}</div>
                </div>
                <div className='approvalRuleRightGroup'>
                    <div className='approvalRuleRightHead'>Actual Value</div>
                    {actuals}
                </div>
                <div className='approvalRuleRightGroup'>
                    <div className='approvalRuleRightHead'>Reference</div>
                    {references}
                </div>
            </div>
        );

        function makeReferenceElement(crit, parent) {
            let referenceClass = 'approvalRuleActuals';
            let mouseOverText = '';
            var tabId = null;
            if (crit.related && crit.itemId) {
                referenceClass += ' referenceLink';
                var tab = quosal.util.getTabByItemId(app.currentQuote, crit.itemId);
                mouseOverText = 'Tab: ' + tab.TabName;
                tabId = tab.IdQuoteTabs;
            }
            return <div data-cy='triggeredRuleReference' title={mouseOverText} onClick={() => quosal.navigation.goToTab(tabId, crit.ItemId)} className={referenceClass}>{crit.related ? crit.related : '\u00A0'}</div>
        }
    }

    render() {
        return (
            <Panel title='Triggered Rules' className="triggeredRules">
                <div style={{ overflow: "auto" }}>
                    {this.renderTriggeredRules()}
                </div>
            </Panel>
        )
    }
}
global.TriggeredApprovalRules = TriggeredApprovalRules;

export class ApprovalRequestBox extends React.Component {
    constructor(props) {
        super(props);
        this.saveChanges = this.saveChanges.bind(this);
        this.state = {
            triedToSaveWithoutComment: false
        }
    }

    UNSAFE_componentWillMount(e) {
        document.addEventListener("keydown", this._handleKeyDown);

    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown);
    }

    _handleKeyDown(e) {
        switch (e.keyCode) {
            case 13:
                //this.handleEnter();   allow multiple lines?
                break;
            case 27:
                Dialog.close();
                break;
            default:
                break;
        }
    }

    saveChanges() {
        var message = $('#note').val().trim();
        if (this.props.requireComments && !message) {
            this.setState({ triedToSaveWithoutComment: true })
        }
        else {
            this.props.saveChanges(message);
        }
    }

    render() {
        let message;
        if (this.props.requesttype == 'request') {
            message = "You won't be able to make changes to this quote until all approvals have been completed.";
        }
        else if (this.props.requesttype == 'approve' || this.props.requesttype == 'reject' || this.props.requesttype == 'cancelapproval') {
            message = 'not shown';
        }

        let warnstyle = {
            background: '#ffebdd',
            height: '48px',
            lineHeight: '20px',
            marginTop: '6px',
            marginRight: '4px',
            paddding: '6px',
            border: '1px solid #eb7b18'
        };

        let errorstyle = {
            background: '#FDD',
            height: '48px',
            lineHeight: '20px',
            marginTop: '6px',
            marginRight: '4px',
            paddding: '6px',
            border: '1px solid #C00'
        };
        let areastyle = {
            width: 'calc(100% - 8px)',
            height: '170px',
            borderRadius: '5px',
            border: '1px solid',
            resize: 'none'
        }
        let warningiconstyle = {
            height: '32px',
            width: '32px',
            marginLeft: '8px',
            position: 'relative',
            top: '8px',
            filter: 'invert(56%) sepia(15%) saturate(6359%) hue-rotate(355deg) brightness(98%) contrast(88%)'
        };

        let erroriconstyle = {
            height: '32px',
            width: '32px',
            marginLeft: '8px',
            position: 'relative',
            top: '8px',
        };
        let btnText = 'REQUEST';
        let tip = 'Send request to approvers'
        if (this.props.requesttype == 'approve') {
            btnText = 'APPROVE';
            tip = 'Approve the request to deliver the quote';
        }
        if (this.props.requesttype == 'reject') {
            btnText = 'REJECT';
            tip = 'Reject the request to deliver the quote';
        }
        if (this.props.requesttype == 'cancelapproval') {
            btnText = 'CANCEL REQUEST';
            tip = 'Cancel the current approval request(s)';
        }
        let req = <CwWidgets.CwButton ref="request" value={btnText} toolTip={tip} onClick={this.saveChanges} isStandard={true} className="requestRequestButton" />;
        let can = <CwWidgets.CwButton ref="cancel" value='CANCEL' onClick={Dialog.closeAll} className='dialoghelp cancelRequestButton' />;
        let warning = null;
        let requireCommentMessage = null;
        if (this.props.requesttype == 'request') {
            warning = (<div style={warnstyle}>
                <div style={{ float: 'left' }}>
                    <div className='growl-icon warn' style={warningiconstyle} />
                </div>
                <div style={{ lineHeight: '39px', marginTop: '4px', marginRight: '10px', fontSize: '14px', marginLeft: '48px', color: '#282828' }}>{message}</div>
            </div>);
        }
        if (this.state.triedToSaveWithoutComment && this.props.requireComments) {
            requireCommentMessage = (<div style={errorstyle}>
                <div style={{ float: 'left' }}>
                    <div className='growl-icon error' style={erroriconstyle} />
                </div>
                <div style={{ lineHeight: '39px', marginTop: '4px', marginRight: '10px', fontSize: '14px', marginLeft: '48px', color: '#282828' }}>
                    Comments are required.
                </div>
            </div>);
        }
        return (
            <div data-cy="ApprovalRequestBox" style={{ textAlign: "left", minWidth: "615px" }}>
                <textarea style={areastyle} maxLength="255"
                    placeholder='Add a note to approvers...'
                    id="note" defaultValue={''} type='text' />
                {warning}
                {requireCommentMessage}
                <div style={{ float: 'right', marginTop: '12px', marginRight: '4px' }}>{can} {req}</div>
            </div>
        );
    }
}
global.ApprovalRequestBox = ApprovalRequestBox;