import { VendorsPage } from "js/jsx/src/classes/settings/vendors.jsx";
import { DialogManager } from "js/jsx/src/classes/dialog.jsx";

export default function vendors() {

    var loadVendorsPage = function () {

        quosal.ui.react.render(<DialogManager />, document.getElementById('dialogManager'));
        quosal.ui.react.render(<VendorsPage />, document.getElementById('currentDocument'));
        $('#pageName').val('vendors.quosalweb');
    };

    quosal.sell.modules.onAfterLoad.unbind().bind(loadVendorsPage);
}