import { QuoteSearch } from "js/jsx/src/classes/quote/quoteSearch.jsx";
import { DialogManager } from "js/jsx/src/classes/dialog.jsx";

export default function copyitem() {

    var renderSearchForm = function (renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;

        var gridConfiguration = {
            gridName: 'QuoteSearch',
            configurationName: quosal.settings.getValue('QuoteSearch_GridConfiguration') || 'Default',
            fieldList: 'QuoteMain',
            extraConfigurableColumns: {
                QuoteStatusIndicators: {
                    FieldName: 'QuoteStatusIndicators',
                    DisplayName: 'Quote Status Indicators'
                }
            }
        };

        var formConfiguration = {
            formName: 'QuoteSearch',
            configurationName: quosal.settings.getValue('QuoteSearch_FormConfiguration') || 'Default'
        };

        renderMethod(<DialogManager />, document.getElementById('dialogManager'));
        var openQuoteFormConfiguration = quosal.customization.forms['QuoteSearch'].Configurations[quosal.settings.getValue('QuoteSearch_FormConfiguration')]
        if (!openQuoteFormConfiguration.Panels || openQuoteFormConfiguration.Panels.length == 0) {
            formConfiguration = quosal.customization.forms.formConfigurationFix('QuoteSearch', quosal.settings.getValue('QuoteSearch_FormConfiguration'));
        }
        renderMethod(
            <QuoteSearch gridConfiguration={gridConfiguration} formConfiguration={formConfiguration} />,
            document.getElementById('quoteModule')
        );
    };

    var changeGridConfiguration = function (gridConfig) {
        quosal.customization.grids.changeConfig(gridConfig.GridName, gridConfig.ConfigurationName, function () {
            renderSearchForm();
        });
    };

    var updateGridConfiguration = function () {
        renderSearchForm();
    };

    var changeFormConfiguration = function () {
        renderSearchForm();
    };

    var unbindEvents = function () {
        quosal.customization.grids.configurationChanged.unbind(changeGridConfiguration);
        quosal.customization.grids.configurationUpdated.unbind(updateGridConfiguration);
        quosal.customization.forms.configurationChanged.unbind(changeFormConfiguration);
        quosal.customization.forms.configurationUpdated.unbind(changeFormConfiguration);
        quosal.navigation.onNavigate.unbind(unbindEvents);
    };

    quosal.navigation.onNavigate.bind(unbindEvents);
    quosal.customization.grids.configurationChanged.bind(changeGridConfiguration);
    quosal.customization.grids.configurationUpdated.bind(updateGridConfiguration);
    quosal.customization.forms.configurationChanged.bind(changeFormConfiguration);
    quosal.customization.forms.configurationUpdated.bind(changeFormConfiguration);

    var loadSearchForm = function () {
        renderSearchForm(quosal.ui.react.render);
        $('#pageName').val('quote.copyitem');
    };

    loadSearchForm();
}