import { Panel, PanelContent } from "js/jsx/src/classes/panels.jsx";

export class ArrowTermPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hardwareTotal: "0",
            softwareTotal: "0",
            totalProductValue: "0",
            enterPoints: '0',
            blindDiscount: '0',
            offerOptions: [
                { name: 'Monthly Fair Market Value', displayName: 'USA-Technology-Monthly-Fair Market Value', checked: false },
                { name: 'Monthly $1.00 Buy-Out', displayName: 'USA-Technology-Monthly-$1.00 Buy-Out', checked: false },
                { name: 'Monthly 0% to End-User Customer', displayName: 'USA-Technology-Monthly-0% to End-User Customer<br>(Blind Discount does not apply)', checked: false },
                { name: 'Monthly 2.9% to End-User Customer', displayName: 'USA-Technology-Monthly-2.9% to End-User Customer<br>(Blind Discount does not apply)', checked: false },
                { name: 'Annual Fair Market Value', displayName: 'USA-Technology-Annual-Fair Market Value', checked: false },
                { name: 'Annual $1.00 Buy-Out', displayName: 'USA-Technology-Annual-$1.00 Buy-Out', checked: false },
                { name: 'Annual 0% to End-User Customer', displayName: 'USA-Technology-Annual-0% to End-User Customer<br>(Blind Discount does not apply)', checked: false },
                { name: 'Annual 2.9% to End-User Customer', displayName: 'USA-Technology-Annual-2.9% to End-User Customer<br>(Blind Discount does not apply)', checked: false }
            ]
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleImportClick = this.handleImportClick.bind(this);
        this.getQuoteValues = this.getQuoteValues.bind(this);
        this.validateSetting = this.validateSetting.bind(this);
    }

    componentDidMount () {
        this.getQuoteValues();
    };

    handleInputChange (event) {
        const { name, value } = event.target;

        if (this.isCurrencyField(name))
        {
            this.setState({
                [name]: value,
            });
        } else
        {
            const newValue = value.replace(/^0+/, '');
            this.setState({ [name]: newValue });
        }
    }

    handleInputBlur (event) {
        const { name, value } = event.target;

        if (this.isCurrencyField(name))
        {
            if (value === '' || isNaN(Number(value)))
            {
                this.setState({
                    [name]: "$0.00",
                });
            } else
            {
                this.setState({
                    [name]: this.formatCurrency(value),
                });
            }
        }
    }

    handleCheckboxChange (optionName) {
        this.setState((prevState) => {
            const updatedOptions = prevState.offerOptions.map((option) => {
                if (option.name === optionName)
                {
                    return { ...option, checked: !option.checked };
                }
                return option;
            });
            return { offerOptions: updatedOptions };
        });
    };

    handleImportClick () {
        const isValid = this.validateSetting();

        if (!isValid)
        {
            return true;
        }
        const selectedOptions = this.state.offerOptions
            .filter((option) => option.checked)
            .map((option) => option.name);

        Dialog.setIsWorking();
        const apiCall = quosal.api.arrowQuote.importTerm(
            this.state.hardwareTotal,
            this.state.softwareTotal,
            this.state.enterPoints,
            this.state.blindDiscount,
            selectedOptions
        );

        apiCall.finished = function (msg) {
            Dialog.setIsWorking(false);

            if (msg.success === true)
            {
                quosal.sell.quote.update(msg.quote);
                const url = quosal.util.url('editquote.quosalweb', 'nav=terms', 'idquotemain=' + app.currentQuote.IdQuoteMain);
                quosal.navigation.navigate(url);
            } else
            {
                Dialog.open({
                    title: 'Error',
                    message: msg.error,
                    links: [Dialog.links.close]
                });
            }
        }.bind(this);
        apiCall.call();
    };

    getQuoteValues () {
        let hardwareTotal = 0;
        let softwareTotal = 0;
        const items = app.currentQuote.Items;

        items.forEach(item => {
            if (item.IsTotalsIncluded)
            {
                const productType = item.ProductType ? item.ProductType.trim() : '';
                const productClass = item.ProductClass ? item.ProductClass.trim() : '';
                const productSubCategory = item.ProductSubCategory ? item.ProductSubCategory.trim() : '';
                const extendedPrice = item.ExtendedPrice ? Math.round(Number(item.ExtendedPrice) * 100) : 0;

                if (productClass !== 'service' || productSubCategory !== 'Shipping' || item.IsTotalsIncluded === false)
                {
                    if (productType.toLowerCase() === 'software' || productSubCategory === 'software' || '')
                    {
                        softwareTotal += extendedPrice;
                    } else
                    {
                        hardwareTotal += extendedPrice;
                    }
                }
            }
        });

        hardwareTotal /= 100;
        softwareTotal /= 100;

        this.setState({
            hardwareTotal: this.formatCurrency(hardwareTotal),
            softwareTotal: this.formatCurrency(softwareTotal),
            totalProductValue: app.currentQuote.Subtotal ? this.formatCurrency(app.currentQuote.Subtotal) : 0
        });
    };

    validateSetting () {
        var isValid = true;
        $.quosal.validation.clearField($('#enterPoints'));
        $.quosal.validation.clearField($('#blindDiscount'));

        if (this.state.enterPoints.trim().length === 0 || isNaN(Number(this.state.enterPoints)) || Number(this.state.enterPoints) < 0 || Number(this.state.enterPoints) >= 6)
        {
            $.quosal.validation.validateField($('#enterPoints'), 'error', 'Value must be greater than or equal to 0 and less than or equal to 5');
            isValid = false;
        }
        if (this.state.blindDiscount.trim().length === 0 || isNaN(Number(this.state.blindDiscount)) || Number(this.state.blindDiscount) < 0)
        {
            $.quosal.validation.validateField($('#blindDiscount'), 'error', 'Value must be greater than or equal to 0');
            isValid = false;
        }
        return isValid;
    };

    formatCurrency (value) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(value);
    }

    isCurrencyField (fieldName) {
        return ['hardwareTotal', 'softwareTotal', 'totalProductValue'].includes(fieldName);
    }

    render () {
        const { offerOptions } = this.state;
        return (
            <div id="Term">
                <Panel id="arrowTermPanel" title="Import Financing Offers">
                    <PanelContent>
                        <div className="formcolumn" style={{ width: 350 }}>
                            <div className="formfieldlabel"><label htmlFor="HardwareTotal" className="standardformlabel">Hardware Total</label></div>
                            <div className="formfield" ><input type="text" name="hardwareTotal" id="hardwareTotal" title="Hardware Total" value={this.state.hardwareTotal} onChange={this.handleInputChange} onBlur={this.handleInputBlur} /></div>

                            <div className="formfieldlabel"><label htmlFor="SoftwareTotal" className="standardformlabel">Software Total</label></div>
                            <div className="formfield"><input type="text" name="softwareTotal" id="softwareTotal" title="Software Total" value={this.state.softwareTotal} onChange={this.handleInputChange} onBlur={this.handleInputBlur} /></div>

                            <div className="formfieldlabel"><label htmlFor="TotalProductValue" className="standardformlabel">Total Product Value</label></div>
                            <div className="formfield" ><input type="text" name="totalProductValue" id="totalProductValue" title="Total Product Value" value={this.state.totalProductValue} onChange={this.handleInputChange} onBlur={this.handleInputBlur} /></div>

                            <div className="formfieldlabel"><label htmlFor="enterPoints" className="standardformlabel">Enter Points</label></div>
                            <br />
                            <div className="formfieldlabel"><label className="standardformlabel">Value must be greater than or equal to 0 and less than or equal to 5</label></div>
                            <div className="formfield" ><input type="text" name="enterPoints" id="enterPoints" title="Enter Points" value={this.state.enterPoints} onChange={this.handleInputChange} /></div>

                            <div className="formfieldlabel"><label htmlFor="blindDiscount" className="standardformlabel">Blind Discount</label></div>
                            <br />
                            <div className="formfieldlabel"><label className="standardformlabel">Value must be greater than or equal to 0</label></div>
                            <div className="formfield" ><input type="text" name="blindDiscount" id="blindDiscount" title="Blind Discount" value={this.state.blindDiscount} onChange={this.handleInputChange} /></div>
                        </div>

                        <div className="formcolumn" style={{ "width": "350px" }}>
                            {offerOptions.map((option) => (
                                <div key={option.name} className="formcheckboxwrapper">
                                    <input type="checkbox" checked={option.checked} onChange={() => this.handleCheckboxChange(option.name)} />
                                    <label className="formlabel"> <span dangerouslySetInnerHTML={{ __html: option.displayName }} /></label>
                                </div>
                            ))}
                        </div>
                    </PanelContent>
                    <PanelContent>
                        <div className="formcolumn" style={{ float: 'right', width: 120 }}>
                            <div className="formfieldlabel"></div>
                            <div className="formfield">
                                <Button type="save" id="arrowTermImport" onClick={this.handleImportClick}>Import Terms</Button>
                            </div>
                        </div>
                    </PanelContent>
                </Panel>
            </div>
        );
    }
}
