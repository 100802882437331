import { DialogManager } from "js/jsx/src/classes/dialog.jsx";
import { LeftHeader, TopHeader, QuosalHeader } from "js/jsx/src/classes/header.jsx";
import { HomePage } from "js/jsx/src/classes/home.jsx";

export default function home() {
    const loadHome = () => {
        loadHomePage();
    };

    const loadHomePage = () => {
        let headerTheme = app.settings.user.embeddedTheme;
        if (headerTheme === "ConnectWise Business Suite") {
            quosal.ui.react.render(React.createElement(LeftHeader, null), document.getElementById('leftSidebarContainer'));
            quosal.ui.react.render(React.createElement(TopHeader, null), document.getElementById('topHeader'));
        } else if ($.queryString('skiplayout') != 'yes') {
            quosal.ui.react.render(React.createElement(QuosalHeader, {
                quote: app.currentQuote,
                user: app.currentUser
            }), document.getElementById('topHeader'));
        }

        const renderHomePage = (layoutObj) => {
            quosal.ui.react.render(
                <ErrorBoundary renderError={() => { renderHomePage(); return null; }}>
                    <HomePage
                        key={`rc_homepage_${Date.now()}`}
                        reloadComponent={(layoutObj) => renderHomePage(layoutObj)}
                        isEditing={layoutObj && layoutObj.isEditing ? layoutObj.isEditing : null}
                        layoutName={layoutObj && layoutObj.layoutName ? layoutObj.layoutName : null}/>
                </ErrorBoundary>, document.getElementById('quoteModule'));
        }
        quosal.ui.react.update(React.createElement(DialogManager, null), document.getElementById('dialogManager'));
        quosal.ui.react.render(<HomePage key="rc_homepage" reloadComponent={renderHomePage} layoutName="" />, document.getElementById('quoteModule'));
        quosal.sell.data.preloadQuoteSearch();
        quosal.ui.views.parse(document.getElementById('pagecontainer'));
        $('#pageName').val('home');
    };

    const impersonateUser = (name, deviceId, removeOldSessions) => {
        let getRedirectUrl = quosal.api.user.impersonateUser(name, deviceId, removeOldSessions);
        getRedirectUrl.finished = function (msg) {
            if (msg && msg.impersonate) {
                let redirectUrl = quosal.util.updateQueryString({});
                redirectUrl = redirectUrl.replace('impersonate', 'impersonating');
                window.location.href = redirectUrl;
            } else {
                loadHome();
            }
        }
        getRedirectUrl.call();
    }

    if (window.location.search.includes('impersonate')) {
        let name = $.queryString('impersonate');
        let deviceId = app.deviceId;
        if (!app.currentUser.UserName.toLowerCase().includes('quosaladmin')) {
            let checkActiveSession = quosal.api.user.checkAllowImpersonateUser(name, deviceId);
            checkActiveSession.finished = function (msg) {
                if (!msg.AllowImpersonation) {
                    quosal.ui.react.update(React.createElement(DialogManager, null), document.getElementById('dialogManager'));
                    Dialog.open({
                        closeRequiresButton: true,
                        message: 'You are about to impersonate a user that has reached the max session amount. By selecting "Yes", the oldest session for this user will be removed. Do you wish to continue?',
                        links: [{
                            title: 'Yes',
                            callback: function () {
                                impersonateUser(name, deviceId, true)
                            }
                        }, {
                            title: 'No',
                            callback: function () {
                                quosal.navigation.navigate(quosal.util.url('adminuserslist.quosalweb'));
                            }
                        }]
                    });
                }
                else {
                    impersonateUser(name, deviceId, false);
                }
            };
            checkActiveSession.call();
        }
        else {
            impersonateUser(name, deviceId, false);
        }
    } else {
        let start = new Date().getTime();
        loadHome();
        quosal.util.calculateAndTrackPageLoadTimeInAppInsights(start, "Load -  " + app.productBranding + " Dashboard");
    }
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // can further handle errors the in future
    }

    render() {
        if (this.state.hasError) {
            return this.props.renderError(this.state.error);
        }

        return this.props.children;
    }
}