const ABORT_REQUEST_CONTROLLERS = new Map();

const fetchData = async (url, api, ...restArgs) => {
	const getUrl = new URL(document.URL);
	const searchParams = new URLSearchParams(getUrl.search);
	const csrf_token = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content");

	let signalKey;

	const requestOptions = () => {
		const mainBodyParams = {
			idQuoteMain: searchParams.get("idquotemain"),
			action: "Api",
			api,
			accesskey: searchParams.get("accesskey"),
			idquotemain: searchParams.get("idquotemain"),
			idquotetabs: searchParams.get("idquotetabs"),
			submodules: searchParams.get("submodules"),
			opId: quosal.api.generateOpId(),
			sources:
				JSON.parse(sessionStorage.getItem("priceSources")) || quosal.sell.product.sources,
			timestamp: new Date()
		};
		if (restArgs.length) {
			for (const arg of restArgs) {
				for (const prop in arg) {
					if (prop === "signalKey") {
						signalKey = arg[prop];
					} else {
						mainBodyParams[prop] = arg[prop];
					}
				}
			}
		}
		return {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Anti-CSRF-Token": csrf_token
			},
			body: JSON.stringify(mainBodyParams)
		};
	};

	try {
		const params = requestOptions();
		const response = await fetch(url, {
			...(signalKey && { signal: abortAndGetSignalSafe(signalKey) }),
			...params
		});
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`);
		} else {
			return response.json();
		}
	} catch (error) {
		console.warn("Some error happened ---> ", error);

		if (error.name === "AbortError") {
			return new Response(JSON.stringify({}), {
				status: 499, // Client Closed Request
				statusText: error.message || "Client Closed Request"
			});
		}
		return new Response(JSON.stringify({}), {
			status: 599, // Network Connect Timeout Error
			statusText: error.message || "Network Connect Timeout Error"
		});
	}
};

function abortRequestSafe(key, reason = "CANCELLED") {
	ABORT_REQUEST_CONTROLLERS.get(key)?.abort?.(reason);
}

function abortAndGetSignalSafe(key) {
	abortRequestSafe(key); // abort previous request, if any
	const newController = new AbortController();
	ABORT_REQUEST_CONTROLLERS.set(key, newController);
	return newController.signal;
}

export { fetchData, abortRequestSafe };
