import React, { memo } from "react";
import { useResizerContainer } from "./ResizerContainer";

function Resizer({ onResize }) {
	const { leftResizeRef, handleMouseDown } = useResizerContainer(onResize);

	return <div className="resizePanel leftResize" onMouseDown={handleMouseDown} ref={leftResizeRef} />;
}

export const ResizerComponent = memo(Resizer);
