export class TriCheckbox extends React.Component {
    componentDidMount() {
        if (this.props.state === "indeterminate") {
            this._setIndeterminate(true);
        }
    }

    componentDidUpdate(previousProps) {
        this._setIndeterminate(this.props.state === "indeterminate");
    }

    _setIndeterminate(indeterminate) {
        const node = ReactDOM.findDOMNode(this);
        node.indeterminate = indeterminate;
    }

    render() {
        const { indeterminate, type, ...props } = this.props;
        return (
            <input
                type="checkbox"
                onChange={this.props.onChange}
                checked={this.props.state === "checked"}
                indeterminate={this.props.state === "indeterminate"}
            />
        );
    }
}