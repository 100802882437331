export class PickTabTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabData: [],
            isSearching: false
        };
        this.getTabTempaltes = this.getTabTempaltes.bind(this);
        this.resetSearch = this.resetSearch.bind(this);
        this.attachingTab = this.attachingTab.bind(this);
        this.goToTab = this.goToTab.bind(this);
    } 
    attachingTab(tabId, isAttaching) {
        var index = this.state.tabData.findIndex(item => item.idQuote_Tabs == tabId);
        this.state.tabData[index].isAttaching = isAttaching;
        if(isAttaching == false){
            this.state.tabData[index].isAttached = true;
        }
        this.setState({tabData: this.state.tabData});
        
    }
    componentDidMount(){
        this.getTabTempaltes();
    }
    getTabTempaltes(){
        var value = $("#tabname").val();
        var getTabTemplates = quosal.api.tab.getTabTemplates(value);
        getTabTemplates.finished = function (msg) {
           this.setState({tabData: JSON.parse(msg.tabData), isSearching: false})

        }.bind(this);
        this.setState({tabData: [], isSearching: true});
        getTabTemplates.call();
    }
    goToTab(tabName){
        this.props.goToTab(tabName)
    }
    resetSearch(){
        this.setState({tabData: []});
        $("#tabname").val("");
        this.getTabTempaltes();
    }
    render(){
        return (
            <div className="fluid">
                <TabTemplateSearchbox reset={this.resetSearch} search={this.getTabTempaltes}/>
                <TabTemplateDisplayTable goToTab={this.goToTab} attachingTab={this.attachingTab} isSearching={this.state.isSearching} tabData={this.state.tabData}/>
            </div>
        )
    }  
}

class TabTemplateSearchbox extends React.Component {
    constructor(props) {
        super(props);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    } 

    componentDidMount(){
        document.addEventListener("keypress",  this.handleKeyPress)
    }
    componentWillUnmount(){
        document.removeEventListener("keypress",  this.handleKeyPress)
    }
    handleKeyPress(e){
        if(e.keyCode === 13)
        {
            this.props.search();
        }
    }
    render(){
        return (
            <div className="grid col4x1">
            <div className="panel">
                <div className="title">Search Criteria</div>   

                <div className="content">
                    <div>
                        <div className="standardformfieldlabel"><label htmlFor="tabname" className="standardformlabel">Tab Name</label></div>
                        <input className="searchinput" type="text" name="tabname" id="tabname" title="Tab Name"/> 
                    </div>  
                    <br />
                    <br />
                    <button onClick={this.props.search} className="btn submit" style={{width:"90px"}} help="search-criteria-bottom">Search</button>
                    <button onClick={this.props.reset} className="btn" id="reset" style={{width:"90px", marginLeft: "12px"}} help="search-criteria-bottom"><div/>Reset</button>
                    <input type="hidden" id="resetFlag" name="resetFlag" defaultValue="false" />
                </div>
            </div>
            </div>
        )
    }  
}

class TabTemplateDisplayTable extends React.Component {
    constructor(props) {
        super(props);
        this.attachTab = this.attachTab.bind(this);
        this.getDisplayTabTemplates = this.getDisplayTabTemplates.bind(this);
        this.getDisplayHeaders = this.getDisplayHeaders.bind(this);
    } 

    attachTab(idQuoteTabs) {
        var userInfo = quosal.util.getUserEmailAndRole();
        var start = new Date().getTime();

        var duplicateApi = quosal.api.quote.duplicateTab(app.currentQuote.IdQuoteMain, idQuoteTabs, false, true);
        duplicateApi.finished = function (msg) {
            quosal.sell.quote.updateFromApiResponse(msg);
            this.props.attachingTab(idQuoteTabs, false);
            if (window.appInsights) {
                var elapsed = new Date().getTime() - start;
                window.appInsights.trackPageView("Prepare Content - Add Tab", window.location.href, {
                    EmailAddress: userInfo.email,
                    UserRole: userInfo.role
                }, null, elapsed);
            }
        }.bind(this);
        this.props.attachingTab(idQuoteTabs, true);
        duplicateApi.call();
    }
    getDisplayHeaders(){
        return (
            <tr>
                <th width="55" className="nosort"></th>
                <th width="55" className="nosort"></th>
                <th className="nosort">Name</th>
                <th className="nosort">Description</th>
                <th className="nosort" width="100">Amount</th>
                {app.currentUser.IsAdministrator &&
                <th className="nosort" width="10" title={"Visiting the original quote is the only way to edit a tab template. Default templates have no source quote."}>Source Quote
                </th>}
            </tr>
        )
    }
    getDisplayTabTemplates(){
        var me = this;
        return this.props.tabData.map(function(tab, idx){
            var dashboardModule = quosal.sell.modules.find('quote.dashboard');
            var dashUrlQuery = 'idquotemain=' + tab.idQuote_Main + '&idquotetabs='+ tab.idQuote_Tabs;
            var url = dashboardModule.getUrl({ query: dashUrlQuery })
            return(
                <tr key={"tab_"+idx} style={{height: "20px"}}>
                    <td className="content center"> {tab.isAttached && <a href="javascript:void(0)" onClick={me.props.goToTab.bind(me, tab.tab_name)}>Select Tab</a>}</td>
                    <td className="content center">
                        {tab.isAttaching ? <Spinner style={{height:"0px", width: "0px", marginRight: "20px", marginTop:"-10px"}} args={{lines: 10, length: 2, radius: 1}}/> :
                        <a href="javascript:void(0)" onClick={me.attachTab.bind(me, tab.idQuote_Tabs)}>Attach</a>}
                    </td>
                    <td className="content center">{tab.tab_name}</td>
                    <td className="content center">{tab.tab_description}</td>
                    <td className="content right">{tab.tab_total}</td>
                    {app.currentUser.IsAdministrator &&
                    <td className="content right"><a href={url}>{tab.quote_readable_id}</a></td>
                    }

                </tr>
            )
        })
    }
    render(){
        return (
            <div className="grid col4x3">
                <div>
                    <div className="panel paging" help="search-results">
                        <div className="title">
                            <span>Search Results</span>
                        </div>   
                        <div className="content">
                            <center>
                            {this.props.isSearching && <FormPlaceholder message="Searching..." />}
                            {!this.props.isSearching &&
                                <table cellPadding="0" cellSpacing="0" className="datagrid">
                                <thead>
                                    {this.getDisplayHeaders()}
                                </thead>
                                <tbody>
                                    {this.getDisplayTabTemplates()}
                                </tbody>
                                </table>
                            }
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
