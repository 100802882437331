import { useState, useCallback, cloneElement } from "react";
import { createPortal } from "react-dom";

import { IdentifyProvider } from "./IdentifyContext";
import { AppQuoteProvider } from "./AppQuoteContext";

export const AppGlobalContainer = () => {

    const [children, setChildren] = useState([]);

    const updateChildren = useCallback(() => {
        setChildren((prevChildren) => {
            const newChildren = [...prevChildren];
            newChildren.forEach((child) => {
                child.key = Math.random();
            });
            return newChildren;
        });
    }, [children]);

    const removeAllChildren = useCallback(() => {
        setChildren([]);
    }, []);

    const removeChild = useCallback((domNode) => {
        if (!domNode) {
            return;
        }
        setChildren((children) => {
            const childrenToRemove = [];
            for (var i = 0; i < children.length; i++) {
                var parent = children[i].domNode;
                while (parent != null) {

                    if (parent == domNode) {
                        childrenToRemove.push(children[i]);
                        try {
                            if (children[i].component && children[i].component.dispose) {
                                children[i].component.dispose();
                            }
                        } catch (ex) {
                            quosal.log.error(ex);
                        }
                        break;
                    }
                    parent = parent.parentElement;
                }
            }
            const newChildren = [...children];
            childrenToRemove.forEach((child) => {
                const index = newChildren.findIndex((c) => c === child);
                if (index !== -1) {
                    newChildren.splice(index, 1);
                }
            });
            return newChildren;
        });
    }, []);


    const renderChild = useCallback((jsx, domNode, reload = false) => {
        let updatedChild = null;
        if (!domNode) {
            return;
        }

        setChildren((prevChildren) => {
            const index = prevChildren.findIndex((child) => child.domNode === domNode)
            if (index === -1) {
                return [...prevChildren, { jsx, domNode, key: Math.random() }];
            } else {
                const child = prevChildren[index];
                child.component?.forceUpdate();
                const newChildren = [...prevChildren];
                child.jsx = jsx;
                if (reload) {
                    child.key = Math.random();
                }
                updatedChild = child.component;
                return newChildren;
            }
        });
        return updatedChild;
    }, []);

    quosal.ui.react.renderChild = renderChild;
    quosal.ui.react.removeChild = removeChild;
    quosal.ui.react.removeAlChildren = removeAllChildren;

    return (
        <IdentifyProvider updateChildren={updateChildren}>
            <AppQuoteProvider>
                {children.map((child) => {
                    const jsx = cloneElement(child.jsx, {
                        ref: (comp) => {
                            child.component = comp;
                        }
                    });
                    return createPortal(jsx, child.domNode, child.key)
                }
                )}
            </AppQuoteProvider>
        </IdentifyProvider>
    )
}