quosal.api.approval = {};

quosal.api.approval.submitApprovalRequest = function (idQuoteMain, approvalNote, approvalNotificationId, isEditorOutput) {
    return quosal.api.call('Approvals.SubmitApprovalRequest', {
        idQuoteMain: idQuoteMain,
        approvalNote: approvalNote,
        approvalNotificationId: approvalNotificationId,
        isEditorOutput: isEditorOutput
    });
};

quosal.api.approval.submitCancelApprovalRequest = function (idQuoteMain, cancelNote, approvalNotificationId) {
    return quosal.api.call('Approvals.SubmitCancelApprovalRequest', {
        idQuoteMain: idQuoteMain,
        cancelNote: cancelNote,
        approvalNotificationId: approvalNotificationId
    });
};

quosal.api.approval.submitApprovalAccept = function (idQuoteMain, approvalNote, approvalNotificationId) {
    return quosal.api.call('Approvals.SubmitApprovalAccept', {
        idQuoteMain: idQuoteMain,
        approvalNote: approvalNote,
        approvalNotificationId: approvalNotificationId
    });
};

quosal.api.approval.submitApprovalReject = function (idQuoteMain, approvalNote, approvalNotificationId) {
    return quosal.api.call('Approvals.SubmitApprovalReject', {
        idQuoteMain: idQuoteMain,
        rejectNote: approvalNote,
        approvalNotificationId: approvalNotificationId
    });
};

quosal.api.approval.GetTriggeredApprovalRules = function (idQuoteMain) {
    return quosal.api.call('Approvals.GetTriggeredApprovalRules', {
        idQuoteMain: idQuoteMain
    });
};

quosal.api.approval.InitApprovalSetup = function () {
    return quosal.api.call('Approvals.InitApprovalSetup', {});
};

quosal.api.approval.SaveApprovalSetup = function (approvalMode, communicateMode, requireComments, allowMLAManualWinBeforeApproval) {
    return quosal.api.call('Approvals.SaveApprovalSetup', {
        approvalMode: approvalMode,
        communicateMode: communicateMode,
        requireComments: requireComments,
        allowMLAManualWinBeforeApproval: allowMLAManualWinBeforeApproval
        
    });
};

quosal.api.approval.RemoveUserFromGroup = function (userId, deleteUsers = false) {
    return quosal.api.call('Approvals.RemoveUserFromGroup', {
        userId: userId,
        deleteUsers: deleteUsers
    });
}

quosal.api.approval.RemoveTemplateFromApprovalRule = function (approvalRuleTemplates, templateId) {
    return quosal.api.call('Approvals.RemoveTemplateFromApprovalRule', {
        approvalRuleTemplates: approvalRuleTemplates,
        templateId: templateId
    });
}

    
