import { useState, useEffect} from 'react';
import { fetchData } from "../services/fetchData.js"

export function useTargetTabsService() {
    const [targetTabs, setTargetTabs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getTargetTabs = () => {
        setIsLoading(true);
            fetchData(
            "quosalweb/quosalapi/Quote.QuoteDetails",
            "Quote.QuoteDetails").then((resp) => {
                const { response0: { tabs }} = resp;
                const tabsData = Array.isArray(tabs) && tabs.map((tab) => ({
                    value: tab.IdQuoteTabs,
                    label: tab.TabName
                }));
                setTargetTabs(tabsData);
                setIsLoading(false)
        });
    };
    useEffect(() => {
        getTargetTabs();
    }, [])

    return { targetTabs, isLoading };
}