import { createContext, useState, useRef, useCallback, useContext } from "react";
import { useAppQuoteContext } from "../../../../../../context/AppQuoteContext";

const SelectedRowContext = createContext();
export const useSelectedRowContext = () => useContext(SelectedRowContext);

export const SelectedRowProvider = ({ children }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [cutRows, setCutRows] = useState([]);
    const [copiedRows, setCopiedRows] = useState([]);
    const {quote, updateQuoteItems} = useAppQuoteContext();
    const hasCutOrCopiedRows = cutRows.length > 0 || copiedRows.length > 0;

    const pasteRows = useCallback((activeTabId, selectedRow) => {
        const isCut = cutRows.length > 0;
        let selectedItem = quosal.util.getItemById(quote, selectedRow);
        if(selectedItem.IsPackageItem) {
            selectedItem = selectedItem.parentRow;
        }
        const onOrBeforeItemId = selectedItem.IdQuoteItems;
        let insertIndex = selectedItem.SortOrder != null ? selectedItem.SortOrder : quosal.util.getItemsByTabId(quote)?.[activeTabId]?.length || 0;
      
        const idQuoteMain = quote.IdQuoteMain; 
        const rowsToPaste = isCut ? cutRows : copiedRows
        const pasteApi = quosal.api.product.paste(idQuoteMain, activeTabId, rowsToPaste, onOrBeforeItemId); 
        pasteApi.finished = function (msg) {
            if(isCut) {
                quosal.sell.quote.updateFromApiResponse(msg);
                var deleteApi = quosal.api.data.delete({
                    table: 'QuoteItems',
                    where: [{
                        field: 'IdQuoteItems',
                        operator: 'In',
                        value: cutRows
                    }]
                }, idQuoteMain, true);
                deleteApi.finished = function (msg) {
                    quosal.sell.quote.updateFromApiResponse(msg);
                }.bind(this);
                deleteApi.call();
                setCutRows([]);
            } else {
                setCopiedRows([]);
                if (msg.quote) {
                    quosal.sell.quote.update(msg.quote);
                }
            }
        };
        pasteApi.call();
        const newTempRows = [];
        const currentSortOrder = insertIndex - 1;
        let subSortOrder = .0001;
        for(const row of rowsToPaste) {
            const newItem = {...quosal.util.getItemById(quote, row)};
            newItem.id = quosal.util.generateGuid();
            newItem.IdQuoteItems = newItem.id;
            newItem.isUnsaved = true;
            newItem.IdQuoteTabs = activeTabId;
            newItem.SortOrder = currentSortOrder + subSortOrder;
            subSortOrder += 0.0001;
            newTempRows.push(newItem);
        }
        updateQuoteItems(items => items.concat(newTempRows).sort((a, b) => (a.SortOrder - b.SortOrder)));
    }, [ cutRows, copiedRows, quote ]);

    const cutOrCopyRows = useCallback((rows, isCut) => {
        let updatedRows = [];
        rows.forEach(row => {
            const item = quosal.util.getItemById(quote, row);
            if(!item || updatedRows.includes(item.IdQuoteItems)) return;
            if(item.IsPackageItem) {
                const parentItem = quosal.util.getItemById(quote, item.ParentQuoteItem);
                updatedRows.push(parentItem.IdQuoteItems);
                updatedRows = updatedRows.concat(quosal.util.getPackageItems(quote, parentItem.IdQuoteItems).map(item => item.IdQuoteItems));
            } else {
                updatedRows.push(item.IdQuoteItems);
                if(item.IsPackageHeader) {
                    updatedRows = updatedRows.concat(quosal.util.getPackageItems(quote, item.IdQuoteItems).map(item => item.IdQuoteItems));
                }
            }
        });
        if(isCut) {
            setCutRows(updatedRows);
            setCopiedRows([]);
        } else {
            setCopiedRows(updatedRows);
            setCutRows([]);
        }
        setSelectedRows([]);
        
    });
    
    return <SelectedRowContext.Provider value={{
        cutRows,
        cutOrCopyRows,
        copiedRows,
        pasteRows,
        selectedRows, 
        setSelectedRows,
        hasCutOrCopiedRows,
    }}>
        {children}
    </SelectedRowContext.Provider>;
};

SelectedRowProvider.Consumer = SelectedRowContext.Consumer;


