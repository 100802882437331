/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext } from "react";

import { useSelectedProducts } from "./useSelectedProducts";
import { useTargetTabsService } from "./useTargetTabsService";
import { useNavigationService } from "./useNavigationService";
import { useCategoryMenuService } from "./useCategoryMenuService";
import { useSearchService } from "./useSearchService";
import { useAddToQuote } from "./useAddToQuote";
import { useNotifications } from "./useNotifications";
import { useProductDetails } from "./useProductDetails";
import { useUpSellCrossService } from "../services/useUpSellCrossServise";
import Notification from "../commonComponents/notification";

const defaultValue = {
	selectedProducts: [],
	addProduct: () => {},
	addProducts: () => {},
	deleteProduct: () => {},
	targetTabs: [],
	isLoading: false,
	navigateToPage: () => {},
	currentPage: { SEARCH_RESULT_PAGE: true },
	pathInfo: { navigatedFrom: "", navigateTO: "" },
	productDetails: {}
};
export const AppDataContext = createContext(defaultValue);
export const useAppDataContext = () => useContext(AppDataContext);

// eslint-disable-next-line react/function-component-definition
export const AppDataProvider = ({ children }) => {
	const navigation = useNavigationService();
	const catalogs = useCategoryMenuService();
	const selectedProductsScope = useSelectedProducts();
	const targetTabs = useTargetTabsService();
	const addToQuote = useAddToQuote();
	const notifications = useNotifications();
	const search = useSearchService();
	const productDetails = useProductDetails();
	const upSellCrossProducts = useUpSellCrossService();

	return (
		<AppDataContext.Provider
			value={{
				...navigation,
				...catalogs,
				...selectedProductsScope,
				...targetTabs,
				...addToQuote,
				...search,
				...notifications,
				...productDetails,
				...upSellCrossProducts
			}}
		>
			<Notification {...notifications.notification} />
			{children}
		</AppDataContext.Provider>
	);
};

AppDataProvider.Consumer = AppDataContext.Consumer;
