import { Box } from "@mui/material";
import { useQuoteContext, useUserContext } from "../context";
import { emptyImageGrey } from "../styles/customColors";
import { ImageEditor } from "../../../../imageEditor";
import emptyQuoteImg from "../../../../../../../../images/emptyQuoteImg.png";

const EmptyQuoteImg = ({ isDisabled, showEditor }) => {
	return (
		<Box
			justifyContent="center"
			alignItems="center"
			sx={{
				width: 42,
				height: 41,
				display: "flex",
				backgroundColor: emptyImageGrey,
				borderRadius: "8px",
				cursor: isDisabled ? "default" : "pointer"
			}}
			onClick={isDisabled ? null : showEditor}
		>
			<img src={emptyQuoteImg} alt="quote image" style={{ width: 14, height: 11 }} />
		</Box>
	);
};

export const QuoteImage = () => {
	const quote = useQuoteContext();
	const user = useUserContext();
	const quoteImageSrc = quote.OrderPorterPreviewPicture;
	const isQuoteImageEmpty = quoteImageSrc === "images/empty.png";
	const quoteId = quote.IdQuoteMain;
	const quoteImgSizeInPixels = 42;
	const isQuoteImageEditingDisabled = quote?.IsLocked || user?.IsReadOnly;
	const quoteImageStyles = {
		width: quoteImgSizeInPixels,
		height: 41,
		borderRadius: 8,
		cursor: isQuoteImageEditingDisabled ? "default" : "pointer",
		marginRight:"8px"
	};

	const showEditor = () => {
		const editor = (
			<ImageEditor
				src={quoteImageSrc}
				IdQuoteMain={quoteId}
				sizeInPixels={quoteImgSizeInPixels}
				disabled={isQuoteImageEditingDisabled}
				imageId="QuotePicture"
				norescale={true}
			/>
		);
		Dialog.open({
			title: "Edit Image",
			height: "620px",
			width: "760px",
			resizable: true,
			draggable: true,
			onClose: Dialog.close,
			links: [{ title: "Cancel", callback: Dialog.close }],
			message: editor
		});
	};

	return (
		<>
			{isQuoteImageEmpty ? (
				<EmptyQuoteImg isDisabled={isQuoteImageEditingDisabled} showEditor={showEditor} />
			) : (
				<img
					src={quoteImageSrc}
					alt="quote image"
					style={quoteImageStyles}
					onClick={isQuoteImageEditingDisabled ? null : showEditor}
					loading="lazy"
				/>
			)}
		</>
	);
};
