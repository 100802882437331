quosal.api.electronicOrders = {};


//==========================================================//
//* Electronic Orders Actions                              *//
//==========================================================//
//==========================================================//

quosal.api.electronicOrders.updateShipments = function (idQuoteMain) {
    return quosal.api.call('ElectronicOrders.UpdateShipments', {
        idQuoteMain: idQuoteMain
    });
};

quosal.api.electronicOrders.shippingSearch = function (params) {
    return quosal.api.call('ElectronicOrders.ShippingSearch', {
        searchParams: params
    });
};

quosal.api.electronicOrders.getShipmentItems = function (params) {
    return quosal.api.call('ElectronicOrders.GetShipmentItems', {
        idElectronicOrderShipment: params.idElectronicOrderShipment
    });
};

quosal.api.electronicOrders.updatePoShipmentInfo = function (params) {
    return quosal.api.call('ElectronicOrders.UpdatePoShipmentInfo', {
        idElectronicOrderShipment: params.idElectronicOrderShipment
    });
};

quosal.api.electronicOrders.updateMultiplePoShipmentInfo = function (params) {
    return quosal.api.call('ElectronicOrders.UpdateMultiplePoShipmentInfo', {
        electronicOrderShipments: params.electronicOrderShipments
    });
};

quosal.api.electronicOrders.updateVendorOrderNumbers = function (params) {
    return quosal.api.call('ElectronicOrders.UpdateVendorOrderNumbers', {
        orderParams: params
    });
};

quosal.api.electronicOrders.updatePoNumber = function (eoId, poNumber) {
    return quosal.api.call('ElectronicOrders.UpdatePoNumber', {
        eoId: eoId,
        poNumber: poNumber
    });
};

quosal.api.electronicOrders.submitElectronicOrder = function (params) {
    return quosal.api.call('ElectronicOrders.SubmitElectronicOrder', {
        orderParams: params
    });
};

quosal.api.electronicOrders.updateEoItems = function (params) {
    return quosal.api.call('ElectronicOrders.UpdateEoItems', {
        orderParams: params
    });
};

quosal.api.electronicOrders.detachPurchaseOrders = function (idQuoteMain) {
    return quosal.api.call('ElectronicOrders.DetachPurchaseOrders', {
        idQuoteMain: idQuoteMain
    });
};
